//lib
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import { putVisitUpdateVisitNotes } from "../../../../../actions/visitOrderActions";
import noEnterSubmitForm from "../../../../../components/functions/noEnterSubmitForm";
import PageSubmitWBackAction from "../../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../../components/PageOnlyBackAction";
import OrderDetailPage from "../../OrderDetailPage";
import SubmissionNotificationDialog from "../../../../../components/SubmissionNotificationDialog";
//style//style
export default function EditVisitNotesDetailsDialog({
  editDiagnosisOpen,
  setEditDiagnosisOpen,
  visitNotesDetails,
  setVisitNotesDetails,
  storeHeaderId,
  orderDetailInfo,
  setOrderDetailInfo,
  setOrderDetailView,
  uploadFileObjects,
  setUploadFileObjects,
}) {
  const { handleSubmit } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const prevUpdateVisitNotesStatus = useRef();
  const [visitNotesDetailsDialog, setVisitNotesDetailsDialog] =
    useState(visitNotesDetails);

  const [uploadFileObjectsDialog, setUploadFileObjectsDialog] =
    useState(uploadFileObjects);

  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitSuccessView, setSubmitSuccessView] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [editVisitNoteViewOnly, setEditVisitNoteViewOnly] = useState(true);
  const [msgAlarm, setMsgAlarm] = useState(null);
  const visitOrder = useSelector((state) => state.visitOrder);

  const onSubmit = () => {
    const submitJson = {
      store_header: {
        id: storeHeaderId,
        visit_notes_details: [visitNotesDetailsDialog],
        visit_attachments: uploadFileObjectsDialog,
      },
    };
    dispatch(putVisitUpdateVisitNotes.pending(submitJson));
  };

  const onConfirmSubmit = () => {
    setConfirmSubmitOpen(true);
  };

  useEffect(() => {
    prevUpdateVisitNotesStatus.current = false;
  }, []);

  useEffect(() => {
    setSubmitSuccessView(!editDiagnosisOpen);
  }, [editDiagnosisOpen, setSubmitSuccessView]);

  useEffect(() => {
    if (
      visitOrder.isLoadingVisitUpdateVisitNotes !==
      prevUpdateVisitNotesStatus.current
    ) {
      //check the previous ref with current state
      prevUpdateVisitNotesStatus.current =
        visitOrder.isLoadingVisitUpdateVisitNotes;

      if (
        visitOrder.putVisitUpdateVisitNotesIsSuccess === true &&
        visitOrder.isLoadingVisitUpdateVisitNotes === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        setSubmitSuccessView(true);
        setVisitNotesDetails(
          visitOrder.visitUpdateVisitNotes.store_header?.visit_notes_details
            .length
            ? visitOrder.visitUpdateVisitNotes.store_header
                ?.visit_notes_details[0]
            : []
        );
        setEditVisitNoteViewOnly(false);

        setUploadFileObjects(
          visitOrder.visitUpdateVisitNotes.store_header?.visit_attachments.map(
            (item) => {
              return { ...item, file: { name: item.original_filename } };
            }
          )
        );
        // setVisitNotesDetailsDialog(
        //   visitOrder.visitUpdateVisitNotes.store_header?.visit_notes_details
        //     .length
        //     ? visitOrder.visitUpdateVisitNotes.store_header
        //         ?.visit_notes_details[0]
        //     : []
        // );
      }
      if (
        visitOrder.putVisitUpdateVisitNotesIsSuccess === false &&
        visitOrder.isLoadingVisitUpdateVisitNotes === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(visitOrder.error.visitUpdateVisitNotesError);
      }
    } else {
      setSubmitErrorAlarm(false);
      setSubmitSuccessAlarm(false);
    }
  }, [visitOrder, setVisitNotesDetails, setUploadFileObjects]);

  return (
    <Dialog
      open={editDiagnosisOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setEditDiagnosisOpen(false);
        }
      }}
      aria-labelledby="confirm-dialog"
      fullWidth
      maxWidth="lg">
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => setEditDiagnosisOpen(false)}
      />
      {visitOrder.isLoadingVisitUpdateVisitNotes !== true && (
        <form
          onSubmit={handleSubmit(onConfirmSubmit)}
          onKeyDown={(e) => noEnterSubmitForm(e)}
          style={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}>
          <DialogContent>
            {/* <EditVisitNotesDetails
              visitNotesDetails={visitNotesDetailsDialog}
              setVisitNotesDetails={setVisitNotesDetailsDialog}
            />
            <EditMcIssued
              visitNotesDetails={visitNotesDetailsDialog}
              setVisitNotesDetails={setVisitNotesDetailsDialog}
            /> */}
            <SubmissionNotificationDialog open={submitErrorAlarm}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSubmitErrorAlarm(false);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {msgAlarm}
              </Alert>
            </SubmissionNotificationDialog>
            <SubmissionNotificationDialog open={submitSuccessAlarm}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSubmitSuccessAlarm(false);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {t("translation:Diagnosis has been submitted")}
              </Alert>
            </SubmissionNotificationDialog>
            <ConfirmDialog
              Yes={t("translation:Yes")}
              No={t("translation:No")}
              title={t("translation:Confirmation")}
              open={confirmSubmitOpen}
              setOpen={setConfirmSubmitOpen}
              onConfirm={onSubmit}>
              {t("translation:Are you sure you want to proceed?")}
            </ConfirmDialog>
            <OrderDetailPage
              orderDetailInfo={orderDetailInfo}
              setOrderDetailInfo={setOrderDetailInfo}
              setOrderDetailView={setOrderDetailView}
              editVisitNoteView={true}
              editVisitNoteViewOnly={editVisitNoteViewOnly}
              visitNotesDetailsDialog={visitNotesDetailsDialog}
              setVisitNotesDetailsDialog={setVisitNotesDetailsDialog}
              uploadFileObjectsDialog={uploadFileObjectsDialog}
              setUploadFileObjectsDialog={setUploadFileObjectsDialog}
            />
          </DialogContent>
          <DialogActions>
            {submitSuccessView ? (
              <PageOnlyBackAction
                setView={() => {
                  setEditDiagnosisOpen(false);
                }}
              />
            ) : (
              <PageSubmitWBackAction
                setView={() => {
                  setEditDiagnosisOpen(false);
                }}
              />
            )}
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}
