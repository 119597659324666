//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
// import IconButton from "@mui/material/IconButton";
// import Alert from "@mui/material/Alert";
// import Collapse from "@mui/material/Collapse";
// import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
//action
import { postSubmitPurchaseOrder } from "../../../actions/purchaseFormActions";
//components
import ConfirmDialog from "../../../components/ConfirmDialog";
import RequiredNote from "../../../components/RequiredNote";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import PageSubmitWBackAction from "../../../components/PageSubmitWBackAction";
import * as generalConstants from "../../../_constants/generalConstants";
import PurchaseOrderField from "./components/PurchaseOrderField";
import MedicineListTable from "./components/MedicineListTable";
import organisationPriceRounding from "../../../components/functions/organisationPriceRounding";
import AmountTotal from "./components/AmountTotal";
import Panel from "../../../components/Panel";
import onlyFixedNumerics from "../../../components/functions/onlyFixedNumerics";
const ProcessPurchaseOrderPage = ({
  setView,
  infoData = {},
  setInfoData = () => null,
  viewMode = false,
}) => {
  //variables
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const purchaseOrder = useSelector((state) => state.purchaseOrder);

  const curUser = useSelector((state) => state.curUser);

  const defaultOrganisation =
    curUser.organisationInfo?.user_organisations?.find(
      (item) => item.is_default === true
    );

  const prevSubmitPurchaseOrderStatus = useRef();
  const { handleSubmit } = useForm();

  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [manualCalculaion, setManualCalculaion] = useState(
    infoData.purchase_order_header?.is_manual_calculation
      ? infoData.purchase_order_header?.is_manual_calculation
      : false
  );
  const [costPriceMutiplier, setCostPriceMutiplier] = useState(null);

  const [supplierReferenceNo, setSupplierReferenceNo] = useState(
    infoData.purchase_order_header.supplier_reference_number
      ? infoData.purchase_order_header.supplier_reference_number
      : null
  );

  const supplier = infoData.purchase_order_header
    ? infoData.purchase_order_header.supplier
    : null;
  const supplierGst = infoData.purchase_order_header
    ? {
        gst_value: infoData.purchase_order_header.gst_value,
        is_gst_enable: infoData.purchase_order_header.is_gst_enable,
        is_gst_inclusive: infoData.purchase_order_header.is_gst_inclusive,
      }
    : null;
  const [medicineList, setMedicineList] = useState([]);
  const price = infoData.purchase_order_header
    ? {
        amt_with_gst: infoData.purchase_order_header.amt_with_gst,
        amt_wo_gst: infoData.purchase_order_header.amt_wo_gst,
        gst_amt: infoData.purchase_order_header.gst_amt,
      }
    : null;
  //functions

  const onSubmit = () => {
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = () => {
    //reset submit error

    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const purchaseOrderDetails = medicineList.map(
      ({ newReceivedDetail, ...item }) => {
        const receivedResult = {
          ...item,
          qty_received_details: [
            {
              ...newReceivedDetail,
              medicine_in_out: {
                new_unit_cost_price: Number(
                  organisationPriceRounding(
                    newReceivedDetail.medicine_in_out.new_unit_cost_price,
                    generalConstants.ROUND_OFF_RMT,
                    generalConstants.TEN_THOUSANDTH_RPVT
                  )
                ),
                new_unit_selling_price: defaultOrganisation.organisations
                  ?.is_po_multiplier_required
                  ? Number(
                      organisationPriceRounding(
                        newReceivedDetail.medicine_in_out
                          .new_unit_selling_price,
                        generalConstants.ROUND_OFF_RMT,
                        generalConstants.TEN_THOUSANDTH_RPVT
                      )
                    )
                  : Number(
                      organisationPriceRounding(
                        newReceivedDetail.medicine_in_out
                          .old_unit_selling_price,
                        generalConstants.ROUND_OFF_RMT,
                        generalConstants.TEN_THOUSANDTH_RPVT
                      )
                    ),
              },
            },
          ],
        };
        return receivedResult;
      }
    );
    const jsonText = {
      purchase_order_header: {
        ...price,
        purchase_order_details: purchaseOrderDetails,
        id: infoData.purchase_order_header?.id,
        supplier_reference_number: supplierReferenceNo,
        status_type: generalConstants.PENDING_STATUS_TYPE,
      },
    };
    dispatch(postSubmitPurchaseOrder.pending(jsonText));
  };

  const setMedicineData = (rowIndex, columnID, value) => {
    let purchaseOrderDetails = [...medicineList];

    const reveicedQuantityPrice = onlyFixedNumerics(
      purchaseOrderDetails[rowIndex].price * Number(value)
    );

    const newUnitCostPrice = onlyFixedNumerics(
      onlyFixedNumerics(
        onlyFixedNumerics(
          purchaseOrderDetails[rowIndex].newReceivedDetail.medicine_in_out
            .old_unit_cost_price *
            purchaseOrderDetails[rowIndex].branch_medicine.quantity
        ) + reveicedQuantityPrice
      ) /
        //if no old_unit_price
        (purchaseOrderDetails[rowIndex].newReceivedDetail.medicine_in_out
          .old_unit_cost_price
          ? onlyFixedNumerics(
              purchaseOrderDetails[rowIndex].branch_medicine.quantity +
                onlyFixedNumerics(
                  Number(value) * purchaseOrderDetails[rowIndex].unit_conversion
                )
            )
          : onlyFixedNumerics(
              Number(value) * purchaseOrderDetails[rowIndex].unit_conversion
            ))
    );

    const oldUnitSellingPrice =
      purchaseOrderDetails[rowIndex].newReceivedDetail.medicine_in_out
        .old_unit_selling_price;

    const newUnitSellingPrice = defaultOrganisation.organisations
      ?.is_po_multiplier_required
      ? onlyFixedNumerics(Number(newUnitCostPrice) * Number(costPriceMutiplier))
      : oldUnitSellingPrice;

    const changePercent = defaultOrganisation.organisations
      ?.is_po_multiplier_required
      ? oldUnitSellingPrice
        ? (
            ((Number(newUnitSellingPrice) - Number(oldUnitSellingPrice)) /
              Number(oldUnitSellingPrice)) *
            100
          ).toFixed(2)
        : "NA"
      : (0).toFixed(2);

    purchaseOrderDetails[
      rowIndex
    ].newReceivedDetail.medicine_in_out.new_unit_cost_price =
      Number(newUnitCostPrice);

    purchaseOrderDetails[
      rowIndex
    ].newReceivedDetail.medicine_in_out.new_unit_selling_price =
      Number(newUnitSellingPrice);

    purchaseOrderDetails[rowIndex].newReceivedDetail.changePercent =
      changePercent;

    //set received
    purchaseOrderDetails[rowIndex].newReceivedDetail.qty_received = value;
    setMedicineList(purchaseOrderDetails);
  };

  const setMultiplierData = (value) => {
    let purchaseOrderDetails = [...medicineList];

    const purchaseOrderDetailsWMultipler = purchaseOrderDetails.map(
      (item, index) => {
        const reveicedQuantityPrice = onlyFixedNumerics(
          item.price * item.newReceivedDetail.qty_received
        );

        const newUnitCostPrice = onlyFixedNumerics(
          onlyFixedNumerics(
            onlyFixedNumerics(
              item.newReceivedDetail.medicine_in_out.old_unit_cost_price *
                item.branch_medicine.quantity
            ) + reveicedQuantityPrice
          ) / //if no old_unit_price
            (item.newReceivedDetail.medicine_in_out.old_unit_cost_price
              ? onlyFixedNumerics(
                  item.branch_medicine.quantity +
                    onlyFixedNumerics(
                      item.newReceivedDetail.qty_received * item.unit_conversion
                    )
                )
              : onlyFixedNumerics(
                  item.newReceivedDetail.qty_received * item.unit_conversion
                ))
        );

        const oldUnitSellingPrice =
          item.newReceivedDetail.medicine_in_out.old_unit_selling_price;
        const newUnitSellingPrice = defaultOrganisation.organisations
          ?.is_po_multiplier_required
          ? onlyFixedNumerics(Number(newUnitCostPrice) * Number(value))
          : oldUnitSellingPrice;

        const changePercent = defaultOrganisation.organisations
          ?.is_po_multiplier_required
          ? oldUnitSellingPrice
            ? (
                ((newUnitSellingPrice - oldUnitSellingPrice) /
                  oldUnitSellingPrice) *
                100
              ).toFixed(2)
            : "NA"
          : (0).toFixed(2);

        item.newReceivedDetail.medicine_in_out.new_unit_cost_price =
          Number(newUnitCostPrice);

        item.newReceivedDetail.medicine_in_out.new_unit_selling_price =
          Number(newUnitSellingPrice);

        item.newReceivedDetail.changePercent = changePercent;

        return item;
      }
    );
    setMedicineList(purchaseOrderDetailsWMultipler);
    setCostPriceMutiplier(value);
  };

  //useEffect

  useEffect(() => {
    //set ref when first render
    prevSubmitPurchaseOrderStatus.current = false;
  }, []);

  // useEffect(() => {
  //   if (
  //     purchaseOrder.isLoadingPostSubmitPurchaseOrder !==
  //     prevSubmitPurchaseOrderStatus.current
  //   ) {
  //     //check the previous ref with current state
  //     prevSubmitPurchaseOrderStatus.current =
  //       purchaseOrder.isLoadingPostSubmitPurchaseOrder;

  //     if (
  //       purchaseOrder.postSubmitPurchaseOrderIsSuccess === false &&
  //       purchaseOrder.isLoadingPostSubmitPurchaseOrder === false
  //     ) {
  //       setErrorAlarm(true);
  //       setMsgAlarm(purchaseOrder.error.postSubmitPurchaseOrderError);
  //       window.scrollTo(0, 0);
  //     }
  //   }
  // }, [purchaseOrder]);

  const medicineListWoDeleted = () => {
    return medicineList.filter(
      (item) => item.status_type !== generalConstants.DELETED_STATUS_TYPE
    );
  };

  const memoMedicineListWoDeleted = React.useMemo(medicineListWoDeleted, [
    medicineList,
  ]);

  useEffect(() => {
    if (infoData.purchase_order_header.purchase_order_details.length) {
      const purchase_order_details =
        infoData.purchase_order_header.purchase_order_details.map((item) => {
          const totalQuantityReceived = item.qty_received_details.reduce(
            (sum, li) => {
              if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
                return onlyFixedNumerics(sum + li.qty_received);
              }
              return onlyFixedNumerics(sum);
            },
            0
          );
          // if (item.qty_received_details.length) {
          //   const totalQuantityReceived = item.qty_received_details.reduce(
          //     (sum, li) => {
          //       if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          //         return parseFloat(sum + li.qty_received);
          //       }
          //       return parseFloat(sum);
          //     },
          //     0
          //   );

          //   const newReceivedDetail = {
          //     id: 0,
          //     medicine_in_out: {
          //       old_unit_cost_price:
          //         item.qty_received_details[
          //           item.qty_received_details.length - 1
          //         ].medicine_in_out.new_unit_cost_price,
          //       old_unit_selling_price:
          //         item.qty_received_details[
          //           item.qty_received_details.length - 1
          //         ].medicine_in_out.new_unit_selling_price,
          //       new_unit_cost_price: 0,
          //       new_unit_selling_price: 0,
          //     },
          //     qty_received: null,
          //     totalQtyReceived: totalQuantityReceived,
          //     changePercent: 0,
          //   };

          //   return { ...item, newReceivedDetail: newReceivedDetail };
          // }
          //form a self json
          const newReceivedDetail = {
            id: 0,
            medicine_in_out: {
              old_unit_cost_price: Number(
                item.branch_medicine.medicines.unit_cost_price
              ),
              old_unit_selling_price: Number(
                item.branch_medicine.medicines.unit_selling_price
              ),
              new_unit_cost_price: 0,
              new_unit_selling_price: 0,
            },
            qty_received: null,
            totalQtyReceived: totalQuantityReceived,
            changePercent: 0,
          };
          return { ...item, newReceivedDetail: newReceivedDetail };
        });

      setMedicineList(purchase_order_details);
    }
  }, [infoData]);

  //useEffect(() => {}, [medicineList, costPriceMutiplier]);
  return (
    <>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <br />

      <Typography display="block" variant="h4">
        {infoData.purchase_order_header?.order_number}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <PurchaseOrderField
          supplier={supplier}
          supplierReferenceNo={supplierReferenceNo}
          setSupplierReferenceNo={setSupplierReferenceNo}
          viewOnly={viewMode}
          manualCalculaion={manualCalculaion}
          setManualCalculaion={setManualCalculaion}
          costPriceMutiplier={costPriceMutiplier}
          setCostPriceMutiplier={setMultiplierData}
          showMutliplier={
            defaultOrganisation.organisations?.is_po_multiplier_required
          }
        />

        <Panel>
          <MedicineListTable
            t={t}
            data={memoMedicineListWoDeleted}
            setData={setMedicineData}
            viewOnly={viewMode}
          />
        </Panel>
        {price ? (
          <AmountTotal amountHeader={price} gstValue={supplierGst.gst_value} />
        ) : (
          <></>
        )}
        <RequiredNote />
        {viewMode && <PageOnlyBackAction setView={() => setView()} />}
        {!viewMode && <PageSubmitWBackAction setView={() => setView()} />}
      </form>
    </>
  );
};

export default ProcessPurchaseOrderPage;
