//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alert from "@mui/material/Alert";
//actions
import { getOrganisationPhysicianUserList } from "../../../../../actions/organisationPhysicianActions";

//components
import { MultiFieldSortingSearchTable } from "../../../../../components/MultiFieldSortingSearchTable";

import * as pageConfigure from "../../../../../_constants/pageConstants";

import { useStyles } from "../../../../../components/globalStyles";

import { ReactComponent as DetailLogo } from "../../../../../assets/coolicons/file/file_blank_outline.svg";

import { UserDetailPage } from "../../UserSummary/UserDetailPage";

import StyledRadioCheckbox from "../../../../../components/StyledRadioCheckbox";

import { SortingSearchTable } from "../../../../../components/SortingSearchTable";

export default function AdvanceUserSearch({
  user,
  setUser,
  defaultUserSearchState,
  setDefaultUserSearchState,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { t } = useTranslation();

  const columns = [
    {
      Header: "",
      accessor: "checkOrganisationPhysician",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <>
            <StyledRadioCheckbox
              checked={
                user !== null && user.id === row.original.id ? true : false
              }
              name="checkOrganisationPhysician"
              onChange={(e) => handleOrganisationPhysicianCheck(e, row)}
            />
          </>
        );
      },
    },

    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Contact Number"),
      accessor: "contact_number",
    },
    {
      Header: t("translation:Identification Number"),
      accessor: "identification_number",
    },
  ];
  const viewColumns = [
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Contact Number"),
      accessor: "contact_number",
    },
    {
      Header: t("translation:Identification Number"),
      accessor: "identification_number",
    },
  ];

  //variables
  const organisationPhysician = useSelector(
    (state) => state.organisationPhysician
  );

  const [userDetailView, setUserDetailView] = React.useState(false);
  const [userDetailInfo, setUserDetailInfo] = React.useState([]);

  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,

    search_contact_number_value: null,
    search_date_of_birth_value: null,
    search_identification_number_value: null,
    search_name_value: null,
    search_username_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
  });

  const [searchValue, setSearchValue] = useState({
    name: null,
    contactNo: null,
    dateofBirth: null,
    identificationNo: null,
  });

  //functions

  const handleOrganisationPhysicianCheck = (e, row) => {
    if (e.target.checked) {
      setUser(row.original);
    } else {
      setUser(null);
    }
  };

  const handleSearch = (event) => {
    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      search_contact_number_value: searchValue.contactNo,
      search_date_of_birth_value: searchValue.dateofBirth,
      search_identification_number_value: searchValue.identificationNo,
      search_name_value: searchValue.name,
    });
  };

  useEffect(() => {
    if (defaultUserSearchState) {
      //set the default search value and added a new user value
      setSearchValue({
        name: defaultUserSearchState.name,
        contactNo: defaultUserSearchState.contact_number,
        dateofBirth: defaultUserSearchState.date_of_birth
          ? defaultUserSearchState.date_of_birth
          : null,
        identificationNo: defaultUserSearchState.identification_number,
      });
      setDefaultUserSearchState(null);
      // setPageInfo({
      //   ...pageInfo,
      //   search_contact_number_value: defaultUserSearchState.contactNo,
      //   search_date_of_birth_value: defaultUserSearchState.dateofBirth,
      //   search_identification_number_value:
      //     defaultUserSearchState.identificationNo,
      //   search_name_value: defaultUserSearchState.name,
      // });
    }
  }, [defaultUserSearchState, setDefaultUserSearchState]);

  useEffect(() => {
    dispatch(getOrganisationPhysicianUserList.pending(pageInfo));
  }, [dispatch, pageInfo]);

  if (
    organisationPhysician.getOrganisationPhysicianUserListIsSuccess === false &&
    organisationPhysician.isLoadingGetOrganisationPhysicianUserList === false
  ) {
    return (
      <Alert severity="error">
        {organisationPhysician.error.getOrganisationPhysicianUserListError}
      </Alert>
    );
  } else if (!userDetailView) {
    return (
      <>
        {user && (
          <Grid item xs={12}>
            <SortingSearchTable
              disabledSearch
              columns={viewColumns}
              data={[user]}
              pageInfo={viewPageInfo}
              setPageInfo={setViewPageInfo}
              totalItems={1}
              disabledFooter
            />
          </Grid>
        )}
        <MultiFieldSortingSearchTable
          searchPlaceholder={""}
          columns={columns}
          data={
            organisationPhysician.getOrganisationPhysicianUserList
              ? organisationPhysician.getOrganisationPhysicianUserList.users
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            organisationPhysician.getOrganisationPhysicianUserList
              ? organisationPhysician.getOrganisationPhysicianUserList
                  .total_pages
              : 0
          }
          loading={false}
          totalItems={
            organisationPhysician.getOrganisationPhysicianUserList
              ? organisationPhysician.getOrganisationPhysicianUserList
                  .total_items
              : 0
          }
          handleSearch={handleSearch}>
          <TextField
            className={classes.userFieldColor}
            label={t("translation:Name")}
            variant="outlined"
            value={searchValue.name ? searchValue.name : ""}
            onChange={(e) =>
              setSearchValue({ ...searchValue, name: e.target.value })
            }
          />

          <TextField
            className={classes.userFieldColor}
            label={t("translation:Contact Number")}
            variant="outlined"
            value={searchValue.contactNo ? searchValue.contactNo : ""}
            onChange={(e) =>
              setSearchValue({ ...searchValue, contactNo: e.target.value })
            }
          />

          <TextField
            className={classes.userFieldColor}
            label={t("translation:Identification Number")}
            variant="outlined"
            autoComplete="off"
            value={
              searchValue.identificationNo ? searchValue.identificationNo : ""
            }
            onChange={(e) =>
              setSearchValue({
                ...searchValue,
                identificationNo: e.target.value,
              })
            }
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className={classes.userFieldColor}
              inputVariant="outlined"
              id="date"
              label={t("translation:Date of Birth")}
              autoOk={true}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              value={
                searchValue.dateofBirth ? dayjs(searchValue.dateofBirth) : null
              }
              format="DD MMM YYYY"
              onChange={(e) =>
                setSearchValue({
                  ...searchValue,
                  dateofBirth: dayjs(e, true).isValid()
                    ? dayjs(e).format("YYYY-MM-DD")
                    : null,
                })
              }
            />
          </LocalizationProvider>
        </MultiFieldSortingSearchTable>
      </>
    );
  } else if (organisationPhysician.visitUserAdvancedSearch && userDetailView) {
    return (
      <UserDetailPage
        user={userDetailInfo}
        setUserDetailView={() => {
          setUserDetailView(false);
        }}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
