import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/branchPhysicianScheduleConstants";

export const getBranchPhysicianScheduleList = {
  pending: (data) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULELIST_ASYNC.ERROR, { error }),
};

export const getBranchPhysicianScheduleById = {
  pending: (data) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULEBYID_ASYNC.ERROR, { error }),
};

export const postBranchPhysicianScheduleByBranchIdPhysicianId = {
  pending: (data) =>
    createAction(
      types.POST_BRANCHPHYSICIANSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.POST_BRANCHPHYSICIANSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.POST_BRANCHPHYSICIANSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const getBranchPhysicianScheduleBranchList = {
  pending: (data) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULEBRANCHLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULEBRANCHLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULEBRANCHLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getBranchPhysicianSchedulePhysicianList = {
  pending: (data) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULEPHYSICIANLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULEPHYSICIANLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_BRANCHPHYSICIANSCHEDULEPHYSICIANLIST_ASYNC.ERROR, {
      error,
    }),
};
