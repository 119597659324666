import React from "react";
import * as generalConstants from "../_constants/generalConstants";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import MapIcon from "@mui/icons-material/Map";
//import LocalPharmacy from "@mui/icons-material/LocalHospital";
//import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
//import FeaturedPlayListSharp from "@mui/icons-material/FeaturedPlayListSharp";
//import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";

import { ReactComponent as StoreLogo } from "../../src/assets/coolicons/basic/credit_card_alt.svg";
import { ReactComponent as AddStoreLogo } from "../../src/assets/coolicons/file/file_new.svg";
import { ReactComponent as DispensaryLogo } from "../../src/assets/coolicons/edit/list_checklist_alt.svg";
import { ReactComponent as BrewingLogo } from "../../src/assets/coolicons/system/filter_outline.svg";
import { ReactComponent as SettingLogo } from "../../src/assets/coolicons/basic/settings.svg";
import { ReactComponent as DashboardLogo } from "../../src/assets/coolicons/grid/dashboard.svg";
import { ReactComponent as AppointmentLogo } from "../../src/assets/coolicons/calendar/calendar_check.svg";
import { ReactComponent as ConsultLogo } from "../../src/assets/coolicons/user/user_voice.svg";
import { ReactComponent as AddVisitLogo } from "../../src/assets/coolicons/user/user_plus.svg";
import { ReactComponent as ScheduleLogo } from "../../src/assets/coolicons/basic/alarm.svg";
import { ReactComponent as InvoiceLogo } from "../../src/assets/coolicons/attention/info_square_outline.svg";
import { ReactComponent as InvoiceMedicineCostingLogo } from "../../src/assets/coolicons/attention/info_square.svg";
// import { ReactComponent as MedicationLogo } from "../../src/assets/coolicons/file/folder_plus.svg";
import { ReactComponent as InventoryLogo } from "../../src/assets/coolicons/basic/layers.svg";

//import { ReactComponent as HCSyncLogo } from "../../src/assets/coolicons/file/cloud_down.svg";
import { ReactComponent as ReportLogo } from "../../src/assets/coolicons/chart/bar_chart_horizontal.svg";
//import { ReactComponent as OrderFormLogo } from "../../src/assets/coolicons/edit/list_checklist.svg";
//import { ReactComponent as PurchaseOrderLogo } from "../../src/assets/coolicons/edit/list_check.svg";

// import { ReactComponent as BranchLogo } from "../../src/assets/coolicons/home/building.svg";

//way for lang etraction because of hook cant be called here and t(`translation:${name}`)} cant be read by etraction

// ADMIN_USER = 1
// ADMIN_ROLE = 2
// ADMIN_USER_ROLE = 3
// ADMIN_ROLE_RIGHTS = 4
// ADMIN_MEDICINE_TYPE = 5
// ADMIN_MEDICINE = 6
// ADMIN_PAYMENT_METHOD = 7
// ADMIN_PAYMENT_TERM = 8
// ADMIN_BRANCH = 9
// ADMIN_BRANCH_MEDICINE = 10
// ADMIN_ORGANISATION = 11
// ADMIN_ORGANISATION_BRANCH = 12
// ADMIN_ORGANISATION_USER = 13
// ADMIN_USER_ORGANISATION = 14
// WORK_ORDER_STORE = 15
// ADMIN_COURIER = 16
// ADMIN_BRANCH_COURIER = 17
// WORK_ORDER_DISPENSARY = 18
// WORK_ORDER_BREWING = 19
// WORK_ORDER_DELIVERY = 20
// MAINTENANCE_MODE_ACCESS = 21
// ADMIN_ALLIANCE = 22
// ADMIN_ALLIANCE_ORGANISATION = 23
// ADMIN_FORMULA = 24
// ADMIN_FORMULA_MEDICINE = 25
// WORK_ORDER_VISIT = 26
// ADMIN_ACUPOINT = 27
// ADMIN_TREATMENT = 28
// WORK_ORDER_INVOICE = 29
// WORK_ORDER_INVOICE_MEDICINE_COSTING = 30
// ADMIN_ORGANISATION_PHYSICIAN = 31
// ADMIN_USER_EDUCATION = 32
// Appointment [20220429]
// WORK_ORDER_APPOINTMENT = 33
// ADMIN_PHYSICIAN = 34
// ADMIN_BRANCH_SCHEDULE = 35
// ADMIN_BRANCH_PHYSICIAN_SCHEDULE = 36
// ADMIN_BRANCH_ADHOC_SCHEDULE = 37
// ADMIN_BRANCH_PHYSICIAN_ADHOC_SCHEDULE = 38
// ADMIN_EMAIL_GROUP = 39
// ADMIN_EMAIL_GROUP_USER = 40
// ADMIN_TREATMENT_TYPE = 41
// ADMIN_USER_CREDIT = 42;
// ADMIN_ORGANISATION_STRIPE = 43;
// WORK_ORDER_RECEIPT = 44;
// ADMIN_SUPPLIER = 45;
// WORK_ORDER_ORDER_FORM = 46;
// WORK_ORDER_PURCHASE_ORDER = 47;
// ADMIN_SUPPLIER_PAYMENT_METHOD = 48;
// ADMIN_SUPPLIER_PAYMENT_TERM = 49;
// DASHBOARD = 50
// HOMECARE_SYNC = 51
// REPORT_WORK_ORDER = 52
function t() {}
t("translation:DASHBOARD");
t("translation:BRANCH");
t("translation:USER");
t("translation:USER SUMMARY");
t("translation:USER ROLE SUMMARY");
t("translation:ROLE SUMMARY");
t("translation:ROLE RIGHTS SUMMARY");
t("translation:APPOINTMENT");
t("translation:VISIT");
t("translation:MEDICATION");
t("translation:INVENTORY");
t("translation:TASKLIST");
t("translation:SETTINGS");
t("translation:ORDER");
t("translation:ADD ORDER");
t("translation:DISPENSARY");
t("translation:BREWING");
t("translation:DELIVERY");
t("translation:APPOINTMENT");
t("translation:MEDICINE TYPE");
t("translation:SCHEDULE");
t("translation:BRANCH SCHEDULE SUMMARY");
t("translation:BRANCH ADHOC SCHEDULE SUMMARY");
t("translation:PHYSICIAN SCHEDULE SUMMARY");
t("translation:PHYSICIAN ADHOC SCHEDULE SUMMARY");
t("translation:INVOICE SUMMARY");
t("translation:INVOICE MEDICINE COSTING SUMMARY");
t("translation:USER CREDIT SUMMARY");
t("translation:PURCHASE REQUISITION SUMMARY");
t("translation:PURCHASE RECEIVING SUMMARY");
t("translation:BRANCH MEDICINE SUMMARY");
t("translation:HOMECARE SYNC");
t("translation:REPORT");
t("translation:INVOICE");
t("translation:INVOICE MEDICINE COSTING");
t("translation:USER CREDIT");
t("translation:PURCHASE REQUISITION");
t("translation:PURCHASE RECEIVING");
t("translation:BRANCH MEDICINE");
t("translation:BRANCH PHYSICIAN SCHEDULE SUMMARY");
t("translation:BRANCH PHYSICIAN ADHOC SCHEDULE SUMMARY");
t("translation:STORE INVOICE REPORT");
t("translation:STORE COST INVOICE REPORT");
t("translation:VISIT INVOICE REPORT");
t("translation:VISIT COST INVOICE REPORT");
t("translation:ADD VISIT");
t("translation:PRESCRIPTION MESSAGE");
t("translation:SYSTEM SCHEDULE");
t("translation:MEDICINE");
t("translation:MEDICINE SUMMARY");
t("translation:ACUPOINT");
t("translation:ACUPOINT SUMMARY");
t("translation:TREATMENT");
t("translation:TREATMENT SUMMARY");
t("translation:TREATMENT TYPE");
t("translation:TREATMENT TYPE SUMMARY");
t("translation:STORE MEDICINE HEADER REPORT");
t("translation:MEDICINE IN OUT REPORT");
t("translation:PHYSICIAN");
t("translation:FORMULA");
t("translation:FORMULA MEDICINE");
t("translation:ORGANISATION PHYSICIAN");
t("translation:MEDICINE REPORT");
t("translation:FORMULAS REPORT");
t("translation:FORMULA MEDICINE REPORT");
t("translation:ACUPOINT REPORT");
t("translation:TREATMENT REPORT");
t("translation:STORE MEDICINE REPORT");
t("translation:BRANCH MEDICINE REPORT");
t("translation:VISIT REPORT");
t("translation:USER MFA");
t("translation:POSTAL CODE SURCHARGE");
t("translation:EMAIL STORE");
t("translation:EMAIL STORE ACCOUNT");
t("translation:EMAIL STORE SENDER");
t("translation:DELIVERY RECIPIENT REPORT");
t("translation:ORDER DETAILS REPORT");
t("translation:MEDICINE FIXED SELLING PRICE");
t("translation:USER MEDICINE FIXED SELLING PRICE");
t("translation:MEDICINE FIXED SELLING PRICE DETAILS");
t("translation:MEDICINE FIXED SELLING PRICE REPORT");
t("translation:USER CREDITS REPORT");
t("translation:WIKI DATA");
t("translation:FULFILLMENT");
t("translation:PACKING");
t("translation:PURCHASE RECEIVING REPORT");
t("translation:NON BUSINESS DAY");
t("translation:DELIVERY TRACKING");
t("translation:COURIER");
t("translation:BRANCH COURIER");
t("translation:USER DEVICE KEY");
t("translation:DELIVERY TRACKING REPORT");
t("translation:APPOINTMENT REPORT");
t("translation:DELIVERY CUT OFF SCHEDULE");
t("translation:SMS Message In Out REPORT");
t("translation:DISPENSARY REPORT");

export const menuItems = {
  data: [
    {
      name: "DASHBOARD",
      url: "/dashboard",
      icon: <DashboardLogo />,
      title: "DASHBOARD",
      max_permission_type: generalConstants.NO_PERMISSION_TYPE,
      right_type: generalConstants.NO_RIGHT_TYPE,
    },
    // {
    //   name: "BRANCH",
    //   url: "/branch",
    //   icon: <BranchLogo />,
    // },
    // {
    //   name: "USER",
    //   icon: <AssignmentIndIcon />,
    //   children: [
    //     {
    //       name: "USER SUMMARY",
    //       url: "/user/UserSummary",
    //     },
    //     {
    //       name: "USER ROLE SUMMARY",
    //       url: "/user/UserRoleSummary",
    //     },
    //     {
    //       name: "ROLE RIGHTS SUMMARY",
    //       url: "/user/RoleRightSummary",
    //     },
    //   ],
    // },

    {
      name: "ADD ORDER",
      cy_data: "addOrderMenuBtn",
      url: "/store/AddOrderSummary",
      right_type: generalConstants.WORK_ORDER_STORE_RIGHT_TYPE,
      max_permission_type: generalConstants.CREATE_PERMISSION_TYPE,
      icon: <AddStoreLogo />,
      title: "ADD ORDER",
    },

    {
      name: "ORDER",
      cy_data: "orderMenuBtn",
      right_type: generalConstants.WORK_ORDER_STORE_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      url: "/store/OrderSummary",
      icon: <StoreLogo />,
      title: "ORDER",
    },
    {
      name: "APPOINTMENT",
      cy_data: "appointmentMenuBtn",
      url: "/appointment/appointmentSummary",
      icon: <AppointmentLogo />,
      title: "APPOINTMENT",
      max_permission_type: generalConstants.NO_PERMISSION_TYPE,
      right_type: generalConstants.NO_RIGHT_TYPE,
    },
    {
      name: "ADD VISIT",
      url: "/visit/AddConsultationOrderSummary",
      icon: <AddVisitLogo />,
      right_type: generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE,
      max_permission_type: generalConstants.CREATE_PERMISSION_TYPE,
      title: "ADD VISIT",
    },
    {
      name: "VISIT",
      cy_data: "consultationMenuBtn",
      url: "/visit/ConsultationOrderSummary",
      icon: <ConsultLogo />,
      right_type: generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      title: "VISIT",
    },
    {
      name: "INVOICE",
      cy_data: "invoiceMenuBtn",
      url: "/invoice/InvoiceSummary",
      right_type: generalConstants.WORK_ORDER_INVOICE_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      icon: <InvoiceLogo />,
      title: "INVOICE",
    },

    {
      name: "FULFILLMENT",
      cy_data: "FulfillmentMenuBtn",
      url: "/store/FulfillmentSummary",
      right_type: generalConstants.WORK_ORDER_FULFILLMENT_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      icon: <AssignmentIcon />,
      title: "FULFILLMENT",
    },

    {
      name: "DISPENSARY",
      cy_data: "dispensaryMenuBtn",
      url: "/store/DispensarySummary",
      right_type: generalConstants.WORK_ORDER_DISPENSARY_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      icon: <DispensaryLogo />,
      title: "DISPENSARY",
    },
    {
      name: "BREWING",
      cy_data: "brewingMenuBtn",
      url: "/store/BrewingSummary",
      right_type: generalConstants.WORK_ORDER_BREWING_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      icon: <BrewingLogo />,
      title: "BREWING",
    },

    {
      name: "PACKING",
      cy_data: "packingMenuBtn",
      url: "/store/PackingSummary",
      right_type: generalConstants.WORK_ORDER_PACKING_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      icon: <CardGiftcardIcon />,
      title: "PACKING",
    },
    {
      name: "DELIVERY",
      cy_data: "deliveryMenuBtn",
      url: "/store/DeliverySummary",
      right_type: generalConstants.WORK_ORDER_DELIVERY_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      icon: <LocalShippingSharpIcon />,
      title: "DELIVERY",
    },
    {
      name: "DELIVERY TRACKING",
      cy_data: "deliveryTrackingMenuBtn",
      url: "/store/DeliveryTrackingSummary",
      right_type: generalConstants.WORK_ORDER_DELIVERY_TRACKING_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      icon: <MapIcon />,
      title: "DELIVERY TRACKING",
    },

    {
      name: "INVOICE MEDICINE COSTING",
      cy_data: "invoiceMedicineCostingMenuBtn",
      url: "/invoice/InvoiceMedicineCostingSummary",
      right_type:
        generalConstants.WORK_ORDER_INVOICE_MEDICINE_COSTING_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      icon: <InvoiceMedicineCostingLogo />,
      title: "INVOICE MEDICINE COSTING",
    },

    {
      name: "USER",
      dropDownName: "USER",
      cy_data: "userMenuBtn",
      icon: <AssignmentIndIcon />,
      title: "USER",
      children: [
        {
          name: "USER SUMMARY",
          dropDownName: "USER",
          title: "USER SUMMARY",
          cy_data: "userSummaryMenuBtn",
          url: "/user/UserSummary",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.ADMIN_USER_RIGHT_TYPE,
        },
        {
          name: "ROLE SUMMARY",
          url: "/user/RoleSummary",
          dropDownName: "USER",
          title: "ROLE SUMMARY",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.ADMIN_ROLE_RIGHT_TYPE,
        },
        {
          name: "USER ROLE SUMMARY",
          cy_data: "userRoleSummaryMenuBtn",
          title: "USER ROLE SUMMARY",
          url: "/user/UserRoleSummary",
          dropDownName: "USER",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.ADMIN_USER_ROLE_RIGHT_TYPE,
        },
        {
          name: "ROLE RIGHTS SUMMARY",
          cy_data: "roleRightsMenuBtn",
          title: "ROLE RIGHTS SUMMARY",
          url: "/user/RoleRightSummary",
          dropDownName: "USER",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.ADMIN_ROLE_RIGHTS_RIGHT_TYPE,
        },
        {
          name: "ORGANISATION PHYSICIAN",
          cy_data: "physicianMenuBtn",
          url: "/user/OrganisationPhysicianSummary",
          right_type: generalConstants.ADMIN_PHYSICIAN_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "ORGANISATION PHYSICIAN",
          dropDownName: "USER",
        },
        {
          name: "USER CREDIT",
          cy_data: "userCreditMenuBtn",
          url: "/userCredit/userCreditSummary",
          right_type: generalConstants.ADMIN_USER_CREDIT_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "USER CREDIT",
          dropDownName: "USER",
        },
        {
          name: "USER MFA",
          cy_data: "userMFAMenuBtn",
          url: "/user/UserMfaSummary",
          right_type: generalConstants.ADMIN_USER_MFA_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "USER MFA",
          dropDownName: "USER",
        },

        // {
        //   name: "USER DEVICE KEY",
        //   cy_data: "userMFAMenuBtn",
        //   url: "/userDeviceKey/UserDeviceKeySummary",
        //   right_type: generalConstants.ADMIN_USER_DEVICE_KEY_RIGHT_TYPE,
        //   max_permission_type: generalConstants.READ_PERMISSION_TYPE,
        //   title: "USER DEVICE KEY",
        //   dropDownName: "USER",
        // },
      ],
    },
    {
      name: "SCHEDULE",
      dropDownName: "SCHEDULE",
      cy_data: "scheduleMenuBtn",
      icon: <ScheduleLogo />,
      title: "SCHEDULE",
      children: [
        {
          name: "BRANCH SCHEDULE SUMMARY",
          dropDownName: "SCHEDULE",
          title: "BRANCH SCHEDULE SUMMARY",
          cy_data: "branchSchuduleMenuBtn",
          url: "/schedule/BranchScheduleSummary",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.ADMIN_BRANCH_SCHEDULE_RIGHT_TYPE,
        },
        {
          name: "BRANCH ADHOC SCHEDULE SUMMARY",
          dropDownName: "SCHEDULE",
          title: "BRANCH ADHOC SCHEDULE SUMMARY",
          cy_data: "branchAdhocSchuduleMenuBtn",
          url: "/schedule/BranchAdhocScheduleSummary",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.ADMIN_BRANCH_ADHOC_SCHEDULE_RIGHT_TYPE,
        },
        {
          name: "BRANCH PHYSICIAN SCHEDULE SUMMARY",
          dropDownName: "SCHEDULE",
          title: "BRANCH PHYSICIAN SCHEDULE SUMMARY",
          cy_data: "physicianSchuduleMenuBtn",
          url: "/schedule/BranchPhysicianScheduleSummary",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type:
            generalConstants.ADMIN_BRANCH_PHYSICIAN_SCHEDULE_RIGHT_TYPE,
        },
        {
          name: "BRANCH PHYSICIAN ADHOC SCHEDULE SUMMARY",
          dropDownName: "SCHEDULE",
          title: "BRANCH PHYSICIAN ADHOC SCHEDULE SUMMARY",
          cy_data: "physicianAdhocSchuduleMenuBtn",
          url: "/schedule/BranchPhysicianAdhocScheduleSummary",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type:
            generalConstants.ADMIN_BRANCH_PHYSICIAN_ADHOC_SCHEDULE_RIGHT_TYPE,
        },
      ],
    },
    {
      name: "REPORT",
      dropDownName: "REPORT",
      cy_data: "reportMenuBtn",
      icon: <ReportLogo />,
      title: "REPORT",
      children: [
        {
          name: "STORE INVOICE REPORT",
          dropDownName: "REPORT",
          title: "STORE INVOICE REPORT",
          cy_data: "SIDRMenuBtn",
          url: "/report/SIR",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_WORK_ORDER_INVOICE_RIGHT_TYPE,
        },
        {
          name: "STORE COST INVOICE REPORT",
          dropDownName: "REPORT",
          title: "STORE COST INVOICE REPORT",
          cy_data: "SIDRMenuBtn",
          url: "/report/SMCIR",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type:
            generalConstants.REPORT_WORK_ORDER_INVOICE_MEDICINE_COSTING_RIGHT_TYPE,
        },

        {
          name: "VISIT INVOICE REPORT",
          dropDownName: "REPORT",
          title: "VISIT INVOICE REPORT",
          cy_data: "VIRMenuBtn",
          url: "/report/VIR",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_WORK_ORDER_INVOICE_RIGHT_TYPE,
        },
        {
          name: "VISIT COST INVOICE REPORT",
          dropDownName: "REPORT",
          title: "VISIT COST INVOICE REPORT",
          cy_data: "VMCIRMenuBtn",
          url: "/report/VMCIR",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type:
            generalConstants.REPORT_WORK_ORDER_INVOICE_MEDICINE_COSTING_RIGHT_TYPE,
        },

        {
          name: "DELIVERY RECIPIENT REPORT",
          dropDownName: "REPORT",
          title: "DELIVERY RECIPIENT REPORT",
          cy_data: "DRRMenuBtn",
          url: "/report/deliveryRecipient",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_DELIVERY_RECIPIENT_RIGHT_TYPE,
        },

        {
          name: "ORDER DETAILS REPORT",
          dropDownName: "REPORT",
          title: "ORDER DETAILS REPORT",
          cy_data: "ORRMenuBtn",
          url: "/report/orderDetails",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_ORDER_DETAILS_RIGHT_TYPE,
        },
        {
          name: "BRANCH MEDICINE REPORT",
          dropDownName: "REPORT",
          title: "BRANCH MEDICINE REPORT",
          cy_data: "BranchMedicineReportMenuBtn",
          url: "/report/BranchMedicine",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_BRANCH_MEDICINE_RIGHT_TYPE,
        },

        {
          name: "MEDICINE REPORT",
          dropDownName: "REPORT",
          title: "MEDICINE REPORT",
          cy_data: "MedicineMenuBtn",
          url: "/report/Medicine",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_MEDICINE_RIGHT_TYPE,
        },

        {
          name: "MEDICINE FIXED SELLING PRICE REPORT",
          dropDownName: "REPORT",
          title: "MEDICINE FIXED SELLING PRICE REPORT",
          cy_data: "FixedMedicinePriceMenuBtn",
          url: "/report/FixedMedicinePrice",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type:
            generalConstants.REPORT_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE,
        },

        {
          name: "FORMULAS REPORT",
          dropDownName: "REPORT",
          title: "FORMULAS REPORT",
          cy_data: "FormulasMenuBtn",
          url: "/report/Formulas",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_FORMULA_RIGHT_TYPE,
        },

        {
          name: "FORMULA MEDICINE REPORT",
          dropDownName: "REPORT",
          title: "FORMULA MEDICINE REPORT",
          cy_data: "FormulaMedicineMenuBtn",
          url: "/report/FormulaMedicine",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_FORMULA_MEDICINE_RIGHT_TYPE,
        },

        {
          name: "ACUPOINT REPORT",
          dropDownName: "REPORT",
          title: "ACUPOINT REPORT",
          cy_data: "AcupointMenuBtn",
          url: "/report/Acupoint",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_ACUPOINT_RIGHT_TYPE,
        },
        {
          name: "TREATMENT REPORT",
          dropDownName: "REPORT",
          title: "TREATMENT REPORT",
          cy_data: "TreatmentMenuBtn",
          url: "/report/Treatment",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_TREATMENT_RIGHT_TYPE,
        },
        {
          name: "SMS Message In Out REPORT",
          dropDownName: "REPORT",
          title: "SMS Message In Out REPORT",
          url: "/report/SMSMessageInOut",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_SMS_MESSAGE_IN_OUT_RIGHT_TYPE,
        },
        {
          name: "APPOINTMENT REPORT",
          dropDownName: "REPORT",
          title: "APPOINTMENT REPORT",
          url: "/report/Appointment",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_WORK_ORDER_APPOINTMENT_RIGHT_TYPE,
        },

        {
          name: "VISIT REPORT",
          dropDownName: "REPORT",
          title: "VISIT REPORT",
          url: "/report/Visit",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_WORK_ORDER_VISIT_RIGHT_TYPE,
        },
        {
          name: "DISPENSARY REPORT",
          dropDownName: "REPORT",
          title: "DISPENSARY REPORT",
          url: "/report/Dispensary",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_WORK_ORDER_DISPENSARY_RIGHT_TYPE,
        },

        {
          name: "DELIVERY TRACKING REPORT",
          dropDownName: "REPORT",
          title: "DELIVERY TRACKING REPORT",
          url: "/report/DeliveryTracking",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type:
            generalConstants.REPORT_WORK_ORDER_DELIVERY_TRACKING_RIGHT_TYPE,
        },
        {
          name: "USER CREDITS REPORT",
          dropDownName: "REPORT",
          title: "USER CREDITS REPORT",
          cy_data: "UserCreditsReportMenuBtn",
          url: "/report/UserCredits",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type: generalConstants.REPORT_USER_CREDIT_RIGHT_TYPE,
        },

        {
          name: "PURCHASE RECEIVING REPORT",
          dropDownName: "REPORT",
          title: "PURCHASE RECEIVING REPORT",
          cy_data: "PurchaseOrderReportMenuBtn",
          url: "/report/PurchaseOrder",
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          right_type:
            generalConstants.REPORT_WORK_ORDER_PURCHASE_ORDER_RIGHT_TYPE,
        },
      ],
    },
    {
      name: "INVENTORY",
      dropDownName: "INVENTORY",
      cy_data: "userMenuBtn",
      icon: <InventoryLogo />,
      title: "MEDICATION",
      children: [
        {
          name: "BRANCH MEDICINE",
          dropDownName: "INVENTORY",
          cy_data: "branchMedicineMenuBtn",
          url: "/branchMedicine/branchMedicineSummary",
          right_type: generalConstants.ADMIN_BRANCH_MEDICINE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "BRANCH MEDICINE",
        },
        {
          name: "PURCHASE REQUISITION",
          dropDownName: "INVENTORY",
          cy_data: "orderFormMenuBtn",
          url: "/orderForm/orderFormSummary",
          right_type: generalConstants.WORK_ORDER_ORDER_FORM_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "PURCHASE REQUISITION",
        },
        {
          name: "PURCHASE RECEIVING",
          dropDownName: "INVENTORY",
          cy_data: "purchaseOrderMenuBtn",
          url: "/purchaseOrder/purchaseOrderSummary",
          right_type: generalConstants.WORK_ORDER_PURCHASE_ORDER_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "PURCHASE RECEIVING",
        },
      ],
    },
    {
      name: "EMAIL STORE",
      cy_data: "emailStoreMenuBtn",
      url: "/emailStore/emailStoreSummary",
      right_type: generalConstants.WORK_ORDER_EMAIL_STORE_RIGHT_TYPE,
      max_permission_type: generalConstants.READ_PERMISSION_TYPE,
      icon: <MailOutlineIcon />,
      title: "EMAIL STORE",
    },

    {
      name: "SETTINGS",
      dropDownName: "SETTINGS",
      cy_data: "userMenuBtn",
      icon: <SettingLogo />,
      title: "SETTINGS",
      children: [
        {
          name: "MEDICINE TYPE",
          dropDownName: "SETTINGS",
          cy_data: "userMenuBtn",
          url: "/medicine/MedicineTypeSummary",
          right_type: generalConstants.ADMIN_MEDICINE_TYPE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "MEDICINE TYPE",
        },
        {
          name: "MEDICINE",
          dropDownName: "SETTINGS",
          cy_data: "medicineMenuBtn",
          url: "/medicine/medicineSummary",
          right_type: generalConstants.ADMIN_MEDICINE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "MEDICINE",
        },
        {
          name: "MEDICINE FIXED SELLING PRICE",
          dropDownName: "SETTINGS",
          cy_data: "medicineMenuBtn",
          url: "/medicineFixedSellingPrice/medicineFixedSellingPriceSummary",
          right_type:
            generalConstants.ADMIN_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "MEDICINE FIXED SELLING PRICE",
        },
        {
          name: "MEDICINE FIXED SELLING PRICE DETAILS",
          dropDownName: "SETTINGS",
          cy_data: "medicineMenuBtn",
          url: "/medicineFixedSellingPriceDetails/medicineFixedSellingPriceDetailsSummary",
          right_type:
            generalConstants.ADMIN_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "MEDICINE FIXED SELLING PRICE DETAILS",
        },
        {
          name: "USER MEDICINE FIXED SELLING PRICE",
          dropDownName: "SETTINGS",
          cy_data: "medicineMenuBtn",
          url: "/userMedicineFixedSellingPrice/userMedicineFixedSellingPriceSummary",
          right_type:
            generalConstants.ADMIN_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "USER MEDICINE FIXED SELLING PRICE",
        },
        {
          name: "ACUPOINT",
          dropDownName: "SETTINGS",
          cy_data: "acupointMenuBtn",
          url: "/acupoint/acupointSummary",
          right_type: generalConstants.ADMIN_ACUPOINT_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "ACUPOINT",
        },
        {
          name: "TREATMENT TYPE",
          dropDownName: "SETTINGS",
          cy_data: "treatmentMenuBtn",
          url: "/treatment/treatmentTypeSummary",
          right_type: generalConstants.ADMIN_TREATMENT_TYPE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "TREATMENT TYPE",
        },
        {
          name: "TREATMENT",
          dropDownName: "SETTINGS",
          cy_data: "treatmentMenuBtn",
          url: "/treatment/treatmentSummary",
          right_type: generalConstants.ADMIN_TREATMENT_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "TREATMENT",
        },
        {
          name: "FORMULA",
          dropDownName: "SETTINGS",
          cy_data: "formulaMenuBtn",
          url: "/formula/formulaSummary",
          right_type: generalConstants.ADMIN_FORMULA_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "FORMULA",
        },
        {
          name: "FORMULA MEDICINE",
          dropDownName: "SETTINGS",
          cy_data: "formulaMedicineMenuBtn",
          url: "/formulaMedicine/formulaMedicineSummary",
          right_type: generalConstants.ADMIN_FORMULA_MEDICINE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "FORMULA MEDICINE",
        },
        {
          name: "PRESCRIPTION MESSAGE",
          dropDownName: "SETTINGS",
          url: "/prescriptionMsg/prescriptionMsgSummary",
          right_type: generalConstants.ADMIN_PRESCRIPTION_MESSAGE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "PRESCRIPTION MESSAGE",
        },
        {
          name: "POSTAL CODE SURCHARGE",
          dropDownName: "SETTINGS",
          url: "/postalCodeSurcharge/postalCodeSurchargeSummary",
          right_type: generalConstants.ADMIN_POSTAL_CODE_SURCHARGE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "POSTAL CODE SURCHARGE",
        },
        {
          name: "EMAIL STORE ACCOUNT",
          dropDownName: "SETTINGS",
          url: "/emailStoreAccount/emailStoreAccountSummary",
          right_type: generalConstants.ADMIN_EMAIL_STORE_ACCOUNT_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "EMAIL STORE ACCOUNT",
        },
        {
          name: "EMAIL STORE SENDER",
          dropDownName: "SETTINGS",
          url: "/emailStoreSender/emailStoreSenderSummary",
          right_type: generalConstants.ADMIN_EMAIL_STORE_SENDER_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "EMAIL STORE SENDER",
        },
        {
          name: "SYSTEM SCHEDULE",
          dropDownName: "SETTINGS",
          url: "/systemSchedule/systemScheduleSummary",
          right_type: generalConstants.ADMIN_SYSTEM_SCHEDULE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "SYSTEM SCHEDULE",
        },
        {
          name: "WIKI DATA",
          dropDownName: "SETTINGS",
          cy_data: "wikiDataMenuBtn",
          url: "/wikiData/wikiDataSummary",
          right_type: generalConstants.ADMIN_WIKI_DATA_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "WIKI DATA",
        },
        {
          name: "DELIVERY CUT OFF SCHEDULE",
          dropDownName: "SETTINGS",
          cy_data: "deliveryCutoffScheduleMenuBtn",
          url: "/deliveryCutoffSchedule/deliveryCutoffScheduleSummary",
          right_type:
            generalConstants.ADMIN_DELIVERY_CUTOFF_SCHEDULE_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "NON BUSINESS DAY",
        },
        {
          name: "NON BUSINESS DAY",
          dropDownName: "SETTINGS",
          cy_data: "nonBusinessDayMenuBtn",
          url: "/nonBusinessDay/nonBusinessDaySummary",
          right_type: generalConstants.ADMIN_NON_BUSINESS_DAY_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "NON BUSINESS DAY",
        },
        {
          name: "COURIER",
          dropDownName: "SETTINGS",
          cy_data: "courierMenuBtn",
          url: "/courier/courierSummary",
          right_type: generalConstants.ADMIN_COURIER_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "courier",
        },
        {
          name: "BRANCH COURIER",
          dropDownName: "SETTINGS",
          cy_data: "branchcourierMenuBtn",
          url: "/branchCourier/branchCourierSummary",
          right_type: generalConstants.ADMIN_BRANCH_COURIER_RIGHT_TYPE,
          max_permission_type: generalConstants.READ_PERMISSION_TYPE,
          title: "branchCourier",
        },
      ],
    },
  ],
};
