//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
//import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//components
import DeliveryListTable from "./DeliveryListTable";
import AddDeliveryPage from "./AddDeliveryPage";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

import * as generalConstants from "../../../_constants/generalConstants";
import { useStyles } from "../../../components/globalStyles";
//actions

const DeliverySummaryPage = () => {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  const [deliveryOrderListView, setDeliveryOrderListView] =
    React.useState(true);
  const [deliveryOrderInformationView, setDeliveryOrderInformationView] =
    React.useState(false);

  const curUser = useSelector((state) => state.curUser);
  const addAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_DELIVERY_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    : false;
  //useEffects

  //const workOrder = useSelector((state) => state.workOrder);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!deliveryOrderInformationView && (
        <Grid container direction="row" alignItems="center">
          {deliveryOrderListView ? (
            <>
              <Grid item xs={11}>
                <Typography className={classes.title} variant="h3" gutterBottom>
                  {t("translation:Delivery Order Summary")}
                </Typography>
              </Grid>
              {addAccess && (
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonColor}
                    onClick={() =>
                      setDeliveryOrderListView(!deliveryOrderListView)
                    }>
                    {t("translation:Add")}
                  </Button>
                </Grid>
              )}
            </>
          ) : (
            <Typography className={classes.title} variant="h3" gutterBottom>
              {t("translation:Add Delivery Order")}
            </Typography>
          )}
        </Grid>
      )}
      <hr />
      {!deliveryOrderListView && (
        <AddDeliveryPage setDeliveryOrderListView={setDeliveryOrderListView} />
      )}
      {deliveryOrderListView && (
        <DeliveryListTable
          setDeliveryOrderInformationView={setDeliveryOrderInformationView}
        />
      )}
    </Paper>
  );
};

export default DeliverySummaryPage;
