import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Grid, TextField, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { useTranslation } from "react-i18next";

import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";

import ConfirmDialog from "../../../../../components/ConfirmDialog";

//actions

import { postVisitChangeBranch } from "../../../../../actions/visitOrderActions";

//components
import { useStyles } from "../../../../../components/globalStyles";

import Note from "../../../../workOrder/Store/components/addOrder/Note";

import * as generalConstants from "../../../../../_constants/generalConstants";

const ChangeBranchMedicine = ({
  order,
  memoSelectedGroup,
  getMedicineTypesAlliance,
  memoSelectedGroupIndex,
  setGroupOption,
  memoGroupOption,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const [alarm, setAlarm] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const prevChangeBranchStatus = useRef();
  const [selectedGroup, setSelectedGroup] = useState(memoSelectedGroup);

  const onConfirmSubmit = (event, action) => {
    let store_medicine_details = selectedGroup?.store_medicine_details?.map(
      (store_medicine_detailsItem) => {
        let medicineList = store_medicine_detailsItem;
        medicineList = {
          ...medicineList,
          medicine_name: medicineList.branch_medicine.medicines.name,
          dosage: medicineList.branch_medicine.medicines.dosage,
          unit_selling_price:
            medicineList.branch_medicine.medicines.unit_selling_price,
        };
        return medicineList;
      }
    );
    const submisionJson = {
      store_header: {
        store_medicine_headers: [
          { ...selectedGroup, store_medicine_details: store_medicine_details },
        ],
      },
    };
    dispatch(postVisitChangeBranch.pending(submisionJson));
  };

  const handleSetOrganisationBranchChoice = (event, option) => {
    //make a copy of group option
    let newArray = { ...selectedGroup };

    newArray = {
      ...newArray,
      organisation_branch: option ? option : null,
      medicine_type: null,
    };

    setSelectedGroup(newArray);

    dispatch(getMedicineTypesAlliance.pending(option.organisation.id));
  };

  const handleSetMedicineTypeChoice = (event, option) => {
    let newArray = { ...selectedGroup };
    newArray = {
      ...newArray,
      medicine_type: option ? option : null,
      is_brewing_services: false,
      is_delivery_services: false,
      delivery_incl_prescription: false,
      delivery_incl_receipt: false,
      delivery_owned: newArray.delivery_owned?.id
        ? {
            id: newArray.delivery_owned.id,
            delivery_linked_store_medicine_header: null,
            status_type: generalConstants.DELETED_STATUS_TYPE,
          }
        : null,
    };

    setSelectedGroup(newArray);
  };

  const handleCloseChangedBranch = () => {
    setOpen(false);
    if (memoSelectedGroup?.organisation_branch?.organisation?.id) {
      dispatch(
        getMedicineTypesAlliance.pending(
          memoSelectedGroup.organisation_branch.organisation.id
        )
      );
    }
  };

  useEffect(() => {
    prevChangeBranchStatus.current = false;
  }, []);

  useEffect(() => {
    if (open) {
      setSelectedGroup(memoSelectedGroup);
    }
  }, [memoSelectedGroup, open]);

  useEffect(() => {
    if (open) {
      setAlarm(false);
    }
  }, [open]);

  useEffect(() => {
    if (
      order.isLoadingPostVisitChangeBranch !== prevChangeBranchStatus.current
    ) {
      //check the previous ref with current state
      prevChangeBranchStatus.current = order.isLoadingPostVisitChangeBranch;
      if (
        order.postVisitChangeBranchIsSuccess === true &&
        order.isLoadingPostVisitChangeBranch === false
      ) {
        const elementsIndex = memoSelectedGroupIndex;
        //make a copy of group option
        let oldArray = [...memoGroupOption];
        oldArray[elementsIndex] = {
          ...order.postVisitChangeBranch.store_medicine_headers[0],
        };

        setGroupOption(oldArray);
        setErrorType(false);
        setAlarm(true);
        setMsgAlarm(t("translation:Dispensary Branch has been changed"));
      }
      if (
        order.postVisitChangeBranchIsSuccess === false &&
        order.isLoadingPostVisitChangeBranch === false
      ) {
        setErrorType(true);
        setAlarm(true);
        setMsgAlarm(order.error.postVisitChangeBranchError);
      }
    }
  }, [
    order.postVisitChangeBranchIsSuccess,
    order.isLoadingPostVisitChangeBranch,
    order.error,
    order.postVisitChangeBranch,
    memoGroupOption,
    setGroupOption,
    memoSelectedGroupIndex,
    t,
  ]);

  return <>
    <ConfirmDialog
      Yes={t("translation:Yes")}
      No={t("translation:No")}
      title={t("translation:Confirmation")}
      open={open}
      setOpen={() => {
        handleCloseChangedBranch();
      }}
      onConfirm={onConfirmSubmit}
      submitWithClose={false}>
      <Collapse in={alarm}>
        <Alert
          severity={errorType ? "error" : "success"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <Grid container>
        <Grid item xs={6} className={classes.userInfoField}>
          <Autocomplete
            value={selectedGroup ? selectedGroup.organisation_branch : null}
            disableClearable
            required
            name="branch"
            id="branch"
            options={
              order.dispensaryBranchesAlliance &&
              Object.keys(
                order.dispensaryBranchesAlliance.organisation_branch
              ).length !== 0
                ? order.dispensaryBranchesAlliance.organisation_branch
                : []
            }
            getOptionLabel={(option) =>
              option.branch ? option.branch.name : ""
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            groupBy={(option) => option.organisation?.name}
            style={{ width: "100%" }}
            onChange={(e, option) =>
              handleSetOrganisationBranchChoice(e, option)
            }
            renderInput={(params) => (
              <TextField
                required
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Dispensary Branch")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6} className={classes.userInfoField}>
          <Autocomplete
            value={selectedGroup ? selectedGroup.medicine_type : null}
            disableClearable
            required
            name="medicineType"
            id="medicineType"
            options={
              order.medicineTypesAlliance
                ? order.medicineTypesAlliance.medicine_types
                : []
            }
            getOptionLabel={(option) => (option.name ? option.name : "")}
            style={{ width: "100%" }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, option) => handleSetMedicineTypeChoice(e, option)}
            renderInput={(params) => (
              <TextField
                className={classes.userFieldColor}
                required
                {...params}
                label={t("translation:Medicine Type")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
      <Note reselectDeliveryandBrewingService />
    </ConfirmDialog>
    <Grid item xs={12}>
      <Button
        className={classes.boldMaroon075}
        style={{ textTransform: "none" }}
        onClick={() => setOpen(!open)}>
        {t("translation:Change Dispensary Branch")}
      </Button>
    </Grid>
  </>;
};
export default ChangeBranchMedicine;
