export const setUserSessionStorage = (cname, cvalue) => {
  try {
    var cur = JSON.parse(sessionStorage.getItem(cname)) || [];

    const curLength = cur.length;
    if (curLength > 500) {
      cur = cur.slice(400, curLength);
    }

    cur.push({ message: cvalue, dateTime: Date() });

    sessionStorage.setItem(cname, JSON.stringify(cur));
  } catch (error) {
    sessionStorage.setItem(cname + "Error", error);
  }
};

export const setUserSessionStorageOff = (cname, cvalue) => {};
