import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getUserMfaList,
  getUserMfaById,
  putUpdateUserMfa,
  getRandomMfaSecretKey,
  postVerifyMfaSecretKey,
  getOtpProvisioningUrl,
  postVerifyOtpToken,
  getUserMfaUserList,
} from "../actions/userMfaActions";
import * as types from "../_constants/userMfaConstants";
import * as notification from "../_constants/notificationConstants";
import userMfaApis from "../_apis/userMfaApis";
export default function* userMfaSagas() {
  yield all([
    takeLatest(types.GET_USERMFALIST_ASYNC.PENDING, getUserMfaListCall),
  ]);
  yield all([
    takeLatest(types.GET_USERMFABYID_ASYNC.PENDING, getUserMfaByIdCall),
  ]);
  yield all([
    takeLatest(types.PUT_UPDATEUSERMFA_ASYNC.PENDING, putUpdateUserMfaCall),
  ]);
  yield all([
    takeLatest(
      types.GET_RANDOMMFASECRETKEY_ASYNC.PENDING,
      getRandomMfaSecretKeyCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_VERIFYMFASECRETKEY_ASYNC.PENDING,
      postVerifyMfaSecretKeyCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_OTPPROVISIONINGURL_ASYNC.PENDING,
      getOtpProvisioningUrlCall
    ),
  ]);

  yield all([
    takeLatest(types.POST_VERIFYOTPTOKEN_ASYNC.PENDING, postVerifyOtpTokenCall),
  ]);

  yield all([
    takeLatest(types.GET_USERMFAUSERLIST_ASYNC.PENDING, getUserMfaUserListCall),
  ]);
}

function* getUserMfaListCall(searchModel) {
  try {
    const data = yield call(userMfaApis.getUserMfaListApi, searchModel.data);
    yield put(getUserMfaList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserMfaList.error(error.response?.data?.message));
    else yield put(getUserMfaList.error(notification.CONST_NOINTERNET));
  }
}

function* getUserMfaByIdCall(searchModel) {
  try {
    const data = yield call(userMfaApis.getUserMfaByIdApi, searchModel.data);
    yield put(getUserMfaById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserMfaById.error(error.response?.data?.message));
    else yield put(getUserMfaById.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateUserMfaCall(searchModel) {
  try {
    const data = yield call(userMfaApis.putUpdateUserMfaApi, searchModel.data);
    yield put(putUpdateUserMfa.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateUserMfa.error(error.response?.data?.message));
    else yield put(putUpdateUserMfa.error(notification.CONST_NOINTERNET));
  }
}

function* getRandomMfaSecretKeyCall(searchModel) {
  try {
    const data = yield call(
      userMfaApis.getRandomMfaSecretKeyApi,
      searchModel.data
    );
    yield put(getRandomMfaSecretKey.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getRandomMfaSecretKey.error(error.response?.data?.message));
    else yield put(getRandomMfaSecretKey.error(notification.CONST_NOINTERNET));
  }
}

function* postVerifyMfaSecretKeyCall(searchModel) {
  try {
    const data = yield call(
      userMfaApis.postVerifyMfaSecretKeyApi,
      searchModel.data
    );
    yield put(postVerifyMfaSecretKey.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVerifyMfaSecretKey.error(error.response?.data?.message));
    else yield put(postVerifyMfaSecretKey.error(notification.CONST_NOINTERNET));
  }
}

function* getOtpProvisioningUrlCall(searchModel) {
  try {
    const data = yield call(
      userMfaApis.getOtpProvisioningUrlApi,
      searchModel.data
    );
    yield put(getOtpProvisioningUrl.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getOtpProvisioningUrl.error(error.response?.data?.message));
    else yield put(getOtpProvisioningUrl.error(notification.CONST_NOINTERNET));
  }
}

function* postVerifyOtpTokenCall(searchModel) {
  try {
    const data = yield call(
      userMfaApis.postVerifyOtpTokenApi,
      searchModel.data
    );
    yield put(postVerifyOtpToken.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVerifyOtpToken.error(error.response?.data?.message));
    else yield put(postVerifyOtpToken.error(notification.CONST_NOINTERNET));
  }
}

function* getUserMfaUserListCall(searchModel) {
  try {
    const data = yield call(
      userMfaApis.getUserMfaUserListApi,
      searchModel.data
    );
    yield put(getUserMfaUserList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserMfaUserList.error(error.response?.data?.message));
    else yield put(getUserMfaUserList.error(notification.CONST_NOINTERNET));
  }
}
