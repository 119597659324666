import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Link } from "@mui/material";

import { postExportDeliveryOrders } from "../../../../actions/deliveryOrderActions";
import ConfirmDialog from "../../../../components/ConfirmDialog";

//style
import { useStyles } from "../../../../components/globalStyles";
export default function ExportDeliveryOrders({ orderInfo = null }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleConfirm = () => {
    const pageInfo = {
      is_pagination: true,
      order_by_direction: null,
      order_by_field: null,
      page_number: null,
      page_size: null,
      search_courier_name_value: null,
      search_delivery_order_id_value: orderInfo.id,
      search_delivery_order_number_value: null,
      search_end_date_value: null,
      search_end_timestamp_value: null,
      search_start_date_value: null,
      search_start_timestamp_value: null,
      search_status_type_value: null,
    };
    dispatch(postExportDeliveryOrders.pending(pageInfo));
  };

  return (
    <>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <Link
        className={classes.boldMaroon075}
        onClick={() => setConfirmOpen(true)}>
        <Button className={classes.boldMaroon075} name="synButton">
          {t("translation:Download CSV FILE")}
        </Button>
      </Link>
    </>
  );
}
