import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getBranchScheduleList,
  getBranchScheduleById,
  postBranchScheduleByBranchId,
  getBranchScheduleBranchList,
} from "../actions/branchScheduleActions";
import * as types from "../_constants/branchScheduleConstants";
import * as notification from "../_constants/notificationConstants";
import branchScheduleApis from "../_apis/branchScheduleApis";
export default function* branchScheduleSagas() {
  yield all([
    takeLatest(
      types.GET_BRANCHSCHEDULELIST_ASYNC.PENDING,
      getBranchScheduleListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_BRANCHSCHEDULEBYID_ASYNC.PENDING,
      getBranchScheduleByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHSCHEDULEBYBRANCHID_ASYNC.PENDING,
      postBranchScheduleByBranchIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_BRANCHSCHEDULEBRANCHLIST_ASYNC.PENDING,
      getBranchScheduleBranchListCall
    ),
  ]);
}

function* getBranchScheduleListCall(Model) {
  try {
    const data = yield call(
      branchScheduleApis.getBranchScheduleListApi,
      Model.data
    );
    yield put(getBranchScheduleList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getBranchScheduleList.error(error.response?.data?.message));
    else yield put(getBranchScheduleList.error(notification.CONST_NOINTERNET));
  }
}

function* getBranchScheduleByIdCall(Model) {
  try {
    const data = yield call(
      branchScheduleApis.getBranchScheduleByIdApi,
      Model.data
    );
    yield put(getBranchScheduleById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getBranchScheduleById.error(error.response?.data?.message));
    else yield put(getBranchScheduleById.error(notification.CONST_NOINTERNET));
  }
}

function* postBranchScheduleByBranchIdCall(Model) {
  try {
    const data = yield call(
      branchScheduleApis.postBranchScheduleByBranchIdApi,
      Model.data
    );
    yield put(postBranchScheduleByBranchId.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchScheduleByBranchId.error(error.response?.data?.message)
      );
    else
      yield put(
        postBranchScheduleByBranchId.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getBranchScheduleBranchListCall(Model) {
  try {
    const data = yield call(
      branchScheduleApis.getBranchScheduleBranchListApi,
      Model.data
    );
    yield put(getBranchScheduleBranchList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchScheduleBranchList.error(error.response?.data?.message)
      );
    else
      yield put(
        getBranchScheduleBranchList.error(notification.CONST_NOINTERNET)
      );
  }
}
