import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/purchaseOrderConstants";

export const getPurchaseOrderList = {
  pending: (data) =>
    createAction(types.GET_PURCHASEORDERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PURCHASEORDERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_PURCHASEORDERLIST_ASYNC.ERROR, { error }),
};

export const getPurchaseOrderById = {
  pending: (data) =>
    createAction(types.GET_PURCHASEORDERBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PURCHASEORDERBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_PURCHASEORDERBYID_ASYNC.ERROR, { error }),
};

export const getPurchaseOrderMedicineTypes = {
  pending: (data) =>
    createAction(types.GET_PURCHASEORDERMEDICINETYPES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PURCHASEORDERMEDICINETYPES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_PURCHASEORDERMEDICINETYPES_ASYNC.ERROR, { error }),
};

export const postPurchaseOrderMedicines = {
  pending: (data) =>
    createAction(types.POST_PURCHASEORDERMEDICINES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_PURCHASEORDERMEDICINES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_PURCHASEORDERMEDICINES_ASYNC.ERROR, { error }),
};

export const postSubmitPurchaseOrder = {
  pending: (data) =>
    createAction(types.POST_SUBMITPURCHASEORDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITPURCHASEORDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITPURCHASEORDER_ASYNC.ERROR, { error }),
};

export const putPurchaseOrderWIPMode = {
  pending: (data) =>
    createAction(types.PUT_PURCHASEORDERWIPMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_PURCHASEORDERWIPMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_PURCHASEORDERWIPMODE_ASYNC.ERROR, { error }),
};

export const putPurchaseOrderCancelled = {
  pending: (data) =>
    createAction(types.PUT_PURCHASEORDERCANCELLED_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_PURCHASEORDERCANCELLED_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_PURCHASEORDERCANCELLED_ASYNC.ERROR, { error }),
};

export const putPurchaseOrderPrintedDate = {
  pending: (data) =>
    createAction(types.PUT_PURCHASEORDERPRINTEDDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_PURCHASEORDERPRINTEDDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_PURCHASEORDERPRINTEDDATE_ASYNC.ERROR, { error }),
};
