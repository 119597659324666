//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
//Actions

import { postBranchMedicineBranchList } from "../../../../actions/branchMedicineActions";

//Component
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";
import ProcessPage from "./ProcessPage";

export default function BranchMedicineListTable({
  setInformationView,
  viewInfo,
  setViewInfo,
  editView,
  setEditView,
  detailView,
  setDetailView,
}) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_BRANCH_MEDICINE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;

  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowEdit(row.original)}>
            <EditLogo title={t("translation:Edit")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Name"),
      accessor: "name",

      sortable: false,
    },
    {
      Header: t("translation:Description"),
      accessor: "desc",

      sortable: false,
    },
  ];

  const columnsViewOnly = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Name"),
      accessor: "name",

      sortable: false,
    },
    {
      Header: t("translation:Description"),
      accessor: "desc",

      sortable: false,
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_desc_value: null,
    search_id_value: null,
    search_name_value: null,
    search_status_type_value: null,
  });

  const dispatch = useDispatch();

  const branchMedicine = useSelector((state) => state.branchMedicine);
  const common = useSelector((state) => state.common);
  //Init

  const [searchValue, setSearchValue] = React.useState("");
  const [branchId, setBranchId] = React.useState("");
  //Onclick event
  const handleRowEdit = (value) => {
    setViewInfo(null);
    setBranchId(value.id);
    setEditView(true);
  };

  const handleRowDetail = (value) => {
    setViewInfo(null);
    setDetailView(true);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: null,
      });
    }
  };

  //useEffects

  useEffect(() => {
    if (detailView === true || editView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [detailView, editView, setInformationView]);

  useEffect(() => {
    if (!editView) {
      dispatch(postBranchMedicineBranchList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editView]);

  if (
    branchMedicine.postBranchMedicineBranchListIsSuccess === false &&
    branchMedicine.isLoadingPostBranchMedicineBranchList === false
  ) {
    return (
      <Alert severity="error">
        {branchMedicine.error.postBranchMedicineBranchListError}
      </Alert>
    );
  } else if (
    common.getGeneralStatusIsSuccess === true &&
    !editView &&
    !detailView
  ) {
    return (
      <>
        <SortingSearchTable
          tableHeading={""}
          searchPlaceholder={t("translation:Name")}
          searchValue={searchValue}
          columns={updateAccess ? columns : columnsViewOnly}
          data={
            branchMedicine.branchMedicineBranchList?.branches !== undefined
              ? branchMedicine.branchMedicineBranchList?.branches
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            branchMedicine.branchMedicineBranchList
              ? branchMedicine.branchMedicineBranchList.total_pages
              : 0
          }
          loading={branchMedicine.isLoadingGetBranchMedicineList}
          totalItems={
            branchMedicine.branchMedicineBranchList
              ? branchMedicine.branchMedicineBranchList.total_items
              : 0
          }
          handleSearch={handleSearch}
        />
      </>
    );
  } else if (detailView) {
    return (
      <ProcessPage
        infoData={viewInfo}
        viewMode={true}
        setView={() => setDetailView(false)}
        branchId={branchId}
      />
    );
  } else if (editView) {
    return (
      <ProcessPage
        setView={() => setEditView(false)}
        infoData={viewInfo}
        branchId={branchId}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
