import organisationPriceRounding from "./organisationPriceRounding";
import onlyFixedNumerics from "./onlyFixedNumerics";
const amountRounding = (value, roundingRequired = false) => {
  if (roundingRequired) {
    const onlyFixedNumericsValue = onlyFixedNumerics(value);
    return (
      Math.round(0.05 * Math.round(onlyFixedNumericsValue / 0.05) * 100) / 100
    );
  }
  return value;
};

const organisationPriceRoundingWGst = (
  value,
  currentOrganistion,
  discount = 0
) => {
  //variable
  const amt_before_discount_w_rounding = organisationPriceRounding(
    onlyFixedNumerics(value),
    currentOrganistion.amt_before_discount_in_percent_rmt,
    currentOrganistion.amt_before_discount_in_percent_rpvt
  );
  const discountPercent = (onlyFixedNumerics(value) * discount) / 100;

  const amt_after_discount =
    onlyFixedNumerics(amt_before_discount_w_rounding) -
    onlyFixedNumerics(discountPercent);

  const amt_after_discount_w_rounding = organisationPriceRounding(
    onlyFixedNumerics(amt_after_discount),
    currentOrganistion.amt_after_discount_in_percent_rmt,
    currentOrganistion.amt_after_discount_in_percent_rpvt
  );

  let storeHeaderWPrice = {
    amt_after_discount: 0,
    amt_before_discount: 0,
    amt_with_gst: 0,
    amt_wo_gst: 0,
    gst_amt: 0,
    amt_rounding: 0,
  };

  if (currentOrganistion.is_gst_enable && currentOrganistion.is_gst_inclusive) {
    const amt_with_gst_w_rounding = onlyFixedNumerics(
      amt_after_discount_w_rounding
    );
    const gst_amt_wo_rounding =
      (onlyFixedNumerics(amt_with_gst_w_rounding) /
        (currentOrganistion.gst_value + 100)) *
      currentOrganistion.gst_value;

    const amt_with_gst_w_amt_rounding = amountRounding(
      amt_with_gst_w_rounding,
      currentOrganistion.is_rounding_required
    );

    const amt_rounding = onlyFixedNumerics(
      amt_with_gst_w_amt_rounding - amt_with_gst_w_rounding
    );

    const amt_rounding_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(amt_rounding),
      currentOrganistion.amt_wo_gst_rmt,
      currentOrganistion.amt_wo_gst_rpvt
    );
    const gst_amt_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(gst_amt_wo_rounding),
      currentOrganistion.gst_amt_rmt,
      currentOrganistion.gst_amt_rpvt
    );

    const amt_wo_gst_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(amt_with_gst_w_rounding) -
        onlyFixedNumerics(gst_amt_w_rounding),
      currentOrganistion.amt_wo_gst_rmt,
      currentOrganistion.amt_wo_gst_rpvt
    );

    const inclusiveGstPrice = {
      ...storeHeaderWPrice,
      amt_after_discount: onlyFixedNumerics(amt_after_discount_w_rounding),
      amt_before_discount: onlyFixedNumerics(amt_before_discount_w_rounding),
      amt_with_gst: onlyFixedNumerics(amt_with_gst_w_amt_rounding),
      amt_wo_gst: onlyFixedNumerics(amt_wo_gst_w_rounding),
      gst_amt: onlyFixedNumerics(gst_amt_w_rounding),
      amt_rounding: onlyFixedNumerics(amt_rounding_w_rounding),
    };

    return inclusiveGstPrice;
  } else if (currentOrganistion.is_gst_enable) {
    const amt_wo_gst_w_rounding = onlyFixedNumerics(
      amt_after_discount_w_rounding
    );
    const gst_amt_wo_rounding =
      (onlyFixedNumerics(amt_wo_gst_w_rounding) *
        onlyFixedNumerics(currentOrganistion.gst_value)) /
      100;
    const gst_amt_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(gst_amt_wo_rounding),
      currentOrganistion.gst_amt_rmt,
      currentOrganistion.gst_amt_rpvt
    );

    const amt_with_gst_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(amt_after_discount_w_rounding) +
        onlyFixedNumerics(gst_amt_w_rounding),
      currentOrganistion.amt_with_gst_rmt,
      currentOrganistion.amt_with_gst_rpvt
    );

    const amt_with_gst_w_amt_rounding = amountRounding(
      amt_with_gst_w_rounding,
      currentOrganistion.is_rounding_required
    );

    const amt_rounding = onlyFixedNumerics(
      amt_with_gst_w_amt_rounding - amt_with_gst_w_rounding
    );

    const amt_rounding_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(amt_rounding),
      currentOrganistion.amt_wo_gst_rmt,
      currentOrganistion.amt_wo_gst_rpvt
    );
    const gstPrice = {
      ...storeHeaderWPrice,
      amt_after_discount: onlyFixedNumerics(amt_after_discount_w_rounding),
      amt_before_discount: onlyFixedNumerics(amt_before_discount_w_rounding),
      amt_with_gst: onlyFixedNumerics(amt_with_gst_w_amt_rounding),
      amt_wo_gst: onlyFixedNumerics(amt_wo_gst_w_rounding),
      gst_amt: onlyFixedNumerics(gst_amt_w_rounding),
      amt_rounding: onlyFixedNumerics(amt_rounding_w_rounding),
    };

    return gstPrice;
  } else {
    const amt_with_gst_w_amt_rounding = amountRounding(
      amt_after_discount_w_rounding,
      currentOrganistion.is_rounding_required
    );
    const amt_rounding = onlyFixedNumerics(
      amt_with_gst_w_amt_rounding - amt_after_discount_w_rounding
    );
    const amt_rounding_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(amt_rounding),
      currentOrganistion.amt_wo_gst_rmt,
      currentOrganistion.amt_wo_gst_rpvt
    );

    const noGstPrice = {
      ...storeHeaderWPrice,
      amt_after_discount: onlyFixedNumerics(amt_after_discount_w_rounding),
      amt_before_discount: onlyFixedNumerics(amt_before_discount_w_rounding),
      amt_wo_gst: onlyFixedNumerics(amt_with_gst_w_amt_rounding),
      amt_with_gst: onlyFixedNumerics(amt_with_gst_w_amt_rounding),
      amt_rounding: onlyFixedNumerics(amt_rounding_w_rounding),
    };
    return noGstPrice;
  }
};

export default organisationPriceRoundingWGst;
