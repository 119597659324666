import organisationPriceRounding from "./organisationPriceRounding";
import * as generalConstants from "../../_constants/generalConstants";
import onlyFixedNumerics from "./onlyFixedNumerics";
///no rounding amount
const priceWGst = (
  value = 0,
  gstValue = 0,
  gstEnable = false,
  gstInclusive = false
) => {
  //variable
  const totalAmt = onlyFixedNumerics(value);

  let headerWPrice = {
    amt_with_gst: 0,
    amt_wo_gst: 0,
    gst_amt: 0,
  };

  if (gstEnable && gstInclusive) {
    const amt_with_gst = totalAmt;

    const amt_with_gst_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(amt_with_gst),
      generalConstants.ROUND_OFF_RMT,
      generalConstants.HUNDREDTH_RPVT
    );

    const gst_amt =
      (onlyFixedNumerics(amt_with_gst_w_rounding) /
        (onlyFixedNumerics(gstValue) + 100)) *
      onlyFixedNumerics(gstValue);

    const gst_amt_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(gst_amt),
      generalConstants.ROUND_OFF_RMT,
      generalConstants.HUNDREDTH_RPVT
    );

    const amt_wo_gst =
      onlyFixedNumerics(amt_with_gst_w_rounding) -
      onlyFixedNumerics(gst_amt_w_rounding);

    const amt_wo_gst_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(amt_wo_gst),
      generalConstants.ROUND_OFF_RMT,
      generalConstants.HUNDREDTH_RPVT
    );

    const inclusiveGstPrice = {
      ...headerWPrice,
      amt_with_gst: onlyFixedNumerics(amt_with_gst_w_rounding),
      amt_wo_gst: onlyFixedNumerics(amt_wo_gst_w_rounding),
      gst_amt: onlyFixedNumerics(gst_amt_w_rounding),
    };

    return inclusiveGstPrice;
  } else if (gstEnable) {
    const amt_wo_gst = totalAmt;

    const amt_wo_gst_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(amt_wo_gst),
      generalConstants.ROUND_OFF_RMT,
      generalConstants.HUNDREDTH_RPVT
    );

    const gst_amt =
      (onlyFixedNumerics(amt_wo_gst_w_rounding) * onlyFixedNumerics(gstValue)) /
      100;

    const gst_amt_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(gst_amt),
      generalConstants.ROUND_OFF_RMT,
      generalConstants.HUNDREDTH_RPVT
    );

    const amt_with_gst =
      onlyFixedNumerics(amt_wo_gst_w_rounding) +
      onlyFixedNumerics(gst_amt_w_rounding);

    const amt_with_gst_w_rounding = organisationPriceRounding(
      onlyFixedNumerics(amt_with_gst),
      generalConstants.ROUND_OFF_RMT,
      generalConstants.HUNDREDTH_RPVT
    );

    const gstPrice = {
      ...headerWPrice,
      amt_with_gst: onlyFixedNumerics(amt_with_gst_w_rounding),
      amt_wo_gst: onlyFixedNumerics(amt_wo_gst_w_rounding),
      gst_amt: onlyFixedNumerics(gst_amt_w_rounding),
    };

    return gstPrice;
  } else {
    const gstPrice = {
      ...headerWPrice,
      amt_with_gst: onlyFixedNumerics(totalAmt),
      amt_wo_gst: onlyFixedNumerics(totalAmt),
      gst_amt: onlyFixedNumerics(0),
    };

    return gstPrice;
  }
};

export default priceWGst;
