import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//components

import * as generalConstants from "../../../_constants/generalConstants";

import SocketNotification from "./SocketNotification";

import ArrivalListTable from "./ArrivalListTable";

//styles
export default function BrewingTable() {
  const { t } = useTranslation();

  //variable
  const [refreshTimeStamp, setRefreshTimeStamp] = useState(null);

  return (
    <SocketNotification
      cellTitle={t("translation:Waiting Room")}
      sockectWorkTypeId={generalConstants.COUNTER_VIEW_TYPE}
      setRefreshTimeStamp={setRefreshTimeStamp}
      localStorageName="arrivalListTableNotification">
      <ArrivalListTable
        refreshTimeStamp={refreshTimeStamp}
        dashboardView={true}
      />
    </SocketNotification>
  );
}
