import * as types from "../_constants/fulfillmentConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_FULFILLMENTSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getFulfillmentSummaryListIsSuccess: false,
        isLoadingGetFulfillmentSummaryList: true,
        getFulfillmentSummaryList: null,
      };

    case types.GET_FULFILLMENTSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getFulfillmentSummaryListIsSuccess: false,
        isLoadingGetFulfillmentSummaryList: false,
        getFulfillmentSummaryList: null,
        error: { ...state.error, getFulfillmentSummaryListError: action.error },
      };

    case types.GET_FULFILLMENTSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getFulfillmentSummaryListIsSuccess: true,
        isLoadingGetFulfillmentSummaryList: false,
        getFulfillmentSummaryList: action.data,
      };
    case types.PUT_FULFILLMENTCOLOURHEXCODE_ASYNC.PENDING:
      return {
        ...state,
        putFulfillmentColourHexCodeIsSuccess: false,
        isLoadingPutFulfillmentColourHexCode: true,
        putFulfillmentColourHexCode: null,
      };

    case types.PUT_FULFILLMENTCOLOURHEXCODE_ASYNC.ERROR:
      return {
        ...state,
        putFulfillmentColourHexCodeIsSuccess: false,
        isLoadingPutFulfillmentColourHexCode: false,
        putFulfillmentColourHexCode: null,
        error: {
          ...state.error,
          putFulfillmentColourHexCodeError: action.error,
        },
      };

    case types.PUT_FULFILLMENTCOLOURHEXCODE_ASYNC.SUCCESS:
      return {
        ...state,
        putFulfillmentColourHexCodeIsSuccess: true,
        isLoadingPutFulfillmentColourHexCode: false,
        putFulfillmentColourHexCode: action.data,
      };

    default:
      return state;
  }
};
