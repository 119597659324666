//lib
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Grid } from "@mui/material";
import Panel from "../../../../components/Panel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
//components
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { useStyles } from "../../../../components/globalTableStyles";
import StyledCheckbox from "../../../../components/StyledCheckbox";
//global functions

export const RoleRightDetailPage = ({
  roleRight,
  common,
  setRoleRightDetailView,
}) => {
  //variables
  const permissionList = common.permissionList.permission;
  const rightList = common.rightList.rights;
  const viewNeedsList = common.viewNeedsList.view_needs;

  const classes = useStyles();
  const { control } = useForm({
    defaultValues: { role_rights: roleRight.role_rights },
  });
  const [checkedValues] = useState(roleRight.role_rights);

  //functions

  //useEffects

  return (
    <Panel heading={roleRight.role.name}>
      <form>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <MaUTable>
                <TableHead className={classes.headStyle}>
                  <TableRow key="headerRow">
                    <TableCell key="actionHeader"></TableCell>
                    {permissionList.map((headerGroup) => (
                      <TableCell
                        key={headerGroup.name}
                        className={classes.headCellStyle}>
                        {headerGroup.name}
                        &nbsp; &nbsp; &nbsp;
                      </TableCell>
                    ))}
                    {viewNeedsList.map((headerGroup) => (
                      <TableCell
                        key={headerGroup.name}
                        className={classes.headCellStyle}>
                        {headerGroup.name}
                        &nbsp; &nbsp; &nbsp;
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rightList.map((right, i) => (
                    <TableRow
                      key={right.name + i + "row"}
                      className={i % 2 ? classes.rowBackground : ""}>
                      <TableCell key={right.name + i}>{right.name}</TableCell>

                      {permissionList.map((permission) => (
                        <TableCell key={permission.name + i}>
                          <FormControlLabel
                            control={
                              <span className={classes.defualtCursor}>
                                <StyledCheckbox
                                  disabled
                                  checked={
                                    checkedValues.find(
                                      (item2) =>
                                        item2.rights_type === right.id &&
                                        item2.permission_type === permission.id
                                    )
                                      ? true
                                      : false
                                  }
                                />
                              </span>
                            }
                            key={permission.id}
                          />
                        </TableCell>
                      ))}

                      {viewNeedsList.map((need) => (
                        <TableCell key={need.name + i}>
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                disabled={true}
                                checked={
                                  checkedValues.find(
                                    (item2) =>
                                      item2.rights_type === right.id &&
                                      item2.view_needs_type === need.id
                                  )
                                    ? true
                                    : false
                                }
                              />
                            }
                            key={need.id}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </MaUTable>
            </TableContainer>
            <br />
            <PageOnlyBackAction setView={() => setRoleRightDetailView(false)} />
          </Grid>
        </Grid>
      </form>
    </Panel>
  );
};
