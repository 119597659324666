import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/deliveryCutOffConstants";

export const getDeliveryCutoffScheduleList = {
  pending: (data) =>
    createAction(types.GET_DELIVERYCUTOFFSCHEDULELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYCUTOFFSCHEDULELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DELIVERYCUTOFFSCHEDULELIST_ASYNC.ERROR, { error }),
};

export const getDeliveryCutoffScheduleById = {
  pending: (data) =>
    createAction(types.GET_DELIVERYCUTOFFSCHEDULEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYCUTOFFSCHEDULEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DELIVERYCUTOFFSCHEDULEBYID_ASYNC.ERROR, { error }),
};

export const putUpdateDeliveryCutoffSchedule = {
  pending: (data) =>
    createAction(types.PUT_UPDATEDELIVERYCUTOFFSCHEDULE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_UPDATEDELIVERYCUTOFFSCHEDULE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_UPDATEDELIVERYCUTOFFSCHEDULE_ASYNC.ERROR, { error }),
};

export const postSearchDeliveryCutoffScheduleBranch = {
  pending: (data) =>
    createAction(types.POST_SEARCHDELIVERYCUTOFFSCHEDULEBRANCH_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_SEARCHDELIVERYCUTOFFSCHEDULEBRANCH_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_SEARCHDELIVERYCUTOFFSCHEDULEBRANCH_ASYNC.ERROR, {
      error,
    }),
};
