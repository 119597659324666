import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  putInvoiceWIPMode,
  putInvoicePendingStatus,
  getInvoiceList,
  getInvoiceSummaryList,
  getInvoiceById,
  getInvoiceByOrderNum,
  postSubmitInvoice,
  postCreateInvoiceById,
  putInvoicePrintDate,
  postOverrideInvoiceAmount,
  postInvoiceWhatsappPDFWTemplate,
  postInvoiceWhatsappAttachments,
  postInvoiceWhatsappAttachmentsWTemplate,
  getInvoiceCurrentOrganisation,
  getInvoiceOrganisationTwilio,
  getInvoiceTwillioTemplate,
  getInvoiceFileAttachmentNotification,
  postInvoiceS3CopyObject,
} from "../actions/invoiceActions";
import * as types from "../_constants/invoiceConstants";
import * as notification from "../_constants/notificationConstants";
import invoiceApis from "../_apis/invoiceApis";
export default function* invoiceSagas() {
  yield all([
    takeLatest(types.PUT_INVOICEWIPMODE_ASYNC.PENDING, putInvoiceWIPModeCall),
  ]);

  yield all([
    takeLatest(
      types.PUT_INVOICEPENDINGSTATUS_ASYNC.PENDING,
      putInvoicePendingStatusCall
    ),
  ]);
  yield all([
    takeLatest(types.GET_INVOICELIST_ASYNC.PENDING, getInvoiceListCall),
  ]);

  yield all([
    takeLatest(
      types.GET_INVOICESUMMARYLIST_ASYNC.PENDING,
      getInvoiceSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_INVOICEBYID_ASYNC.PENDING, getInvoiceByIdCall),
  ]);
  yield all([
    takeLatest(
      types.GET_INVOICEBYORDERNUM_ASYNC.PENDING,
      getInvoiceByOrderNumCall
    ),
  ]);
  yield all([
    takeLatest(types.POST_SUBMITINVOICE_ASYNC.PENDING, postSubmitInvoiceCall),
  ]);

  yield all([
    takeLatest(
      types.POST_CREATEINVOICEBYID_ASYNC.PENDING,
      postCreateInvoiceByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_INVOICEPRINTDATE_ASYNC.PENDING,
      putInvoicePrintDateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_OVERRIDEINVOICEAMOUNT_ASYNC.PENDING,
      postOverrideInvoiceAmountCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_INVOICEWHATSAPPPDFWTEMPLATE_ASYNC.PENDING,
      postInvoiceWhatsappPDFWTemplateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_INVOICEWHATSAPPATTACHMENTS_ASYNC.PENDING,
      postInvoiceWhatsappAttachmentsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_INVOICEWHATSAPPATTACHMENTSWTEMPLATE_ASYNC.PENDING,
      postInvoiceWhatsappAttachmentsWTemplateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_INVOICECURRENTORGANISATION_ASYNC.PENDING,
      getInvoiceCurrentOrganisationCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_INVOICEORGANISATIONTWILIO_ASYNC.PENDING,
      getInvoiceOrganisationTwilioCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_INVOICEFILEATTACHMENTNOTIFICATION_ASYNC.PENDING,
      getInvoiceFileAttachmentNotificationCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_INVOICETWILLIOTEMPLATE_ASYNC.PENDING,
      getInvoiceTwillioTemplateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_INVOICES3COPYOBJECT_ASYNC.PENDING,
      postInvoiceS3CopyObjectCall
    ),
  ]);
}

function* putInvoiceWIPModeCall(Model) {
  try {
    const data = yield call(invoiceApis.putInvoiceWIPModeApi, Model.data);
    yield put(putInvoiceWIPMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putInvoiceWIPMode.error(error.response?.data?.message));
    else yield put(putInvoiceWIPMode.error(notification.CONST_NOINTERNET));
  }
}

function* putInvoicePendingStatusCall(Model) {
  try {
    const data = yield call(invoiceApis.putInvoicePendingStatusApi, Model.data);
    yield put(putInvoicePendingStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putInvoicePendingStatus.error(error.response?.data?.message));
    else
      yield put(putInvoicePendingStatus.error(notification.CONST_NOINTERNET));
  }
}

function* getInvoiceListCall(Model) {
  try {
    const data = yield call(invoiceApis.getInvoiceListApi, Model.data);
    yield put(getInvoiceList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getInvoiceList.error(error.response?.data?.message));
    else yield put(getInvoiceList.error(notification.CONST_NOINTERNET));
  }
}

function* getInvoiceSummaryListCall(Model) {
  try {
    const data = yield call(invoiceApis.getInvoiceSummaryListApi, Model.data);
    yield put(getInvoiceSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getInvoiceSummaryList.error(error.response?.data?.message));
    else yield put(getInvoiceSummaryList.error(notification.CONST_NOINTERNET));
  }
}

function* getInvoiceByIdCall(Model) {
  try {
    const data = yield call(invoiceApis.getInvoiceByIdApi, Model.data);
    yield put(getInvoiceById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getInvoiceById.error(error.response?.data?.message));
    else yield put(getInvoiceById.error(notification.CONST_NOINTERNET));
  }
}

function* getInvoiceByOrderNumCall(Model) {
  try {
    const data = yield call(invoiceApis.getInvoiceByOrderNumApi, Model.data);
    yield put(getInvoiceByOrderNum.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getInvoiceByOrderNum.error(error.response?.data?.message));
    else yield put(getInvoiceByOrderNum.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitInvoiceCall(Model) {
  try {
    const data = yield call(invoiceApis.postSubmitInvoiceApi, Model.data);
    yield put(postSubmitInvoice.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitInvoice.error(error.response?.data?.message));
    else yield put(postSubmitInvoice.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateInvoiceByIdCall(Model) {
  try {
    const data = yield call(invoiceApis.postCreateInvoiceByIdApi, Model.data);
    yield put(postCreateInvoiceById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateInvoiceById.error(error.response?.data?.message));
    else yield put(postCreateInvoiceById.error(notification.CONST_NOINTERNET));
  }
}

function* putInvoicePrintDateCall(Model) {
  try {
    const data = yield call(invoiceApis.putInvoicePrintDateApi, Model.data);
    yield put(putInvoicePrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putInvoicePrintDate.error(error.response?.data?.message));
    else yield put(putInvoicePrintDate.error(notification.CONST_NOINTERNET));
  }
}

function* postOverrideInvoiceAmountCall(Model) {
  try {
    const data = yield call(
      invoiceApis.postOverrideInvoiceAmountApi,
      Model.data
    );
    yield put(postOverrideInvoiceAmount.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postOverrideInvoiceAmount.error(error.response?.data?.message));
    else
      yield put(postOverrideInvoiceAmount.error(notification.CONST_NOINTERNET));
  }
}

function* postInvoiceWhatsappPDFWTemplateCall(Model) {
  try {
    const data = yield call(
      invoiceApis.postInvoiceWhatsappPDFWTemplateApi,
      Model.data
    );
    yield put(postInvoiceWhatsappPDFWTemplate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postInvoiceWhatsappPDFWTemplate.error(error.response?.data?.message)
      );
    else
      yield put(
        postInvoiceWhatsappPDFWTemplate.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postInvoiceWhatsappAttachmentsCall(Model) {
  try {
    const data = yield call(
      invoiceApis.postInvoiceWhatsappAttachmentsApi,
      Model.data
    );
    yield put(postInvoiceWhatsappAttachments.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postInvoiceWhatsappAttachments.error(error.response?.data?.message)
      );
    else
      yield put(
        postInvoiceWhatsappAttachments.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postInvoiceWhatsappAttachmentsWTemplateCall(Model) {
  try {
    const data = yield call(
      invoiceApis.postInvoiceWhatsappAttachmentsWTemplateApi,
      Model.data
    );
    yield put(postInvoiceWhatsappAttachmentsWTemplate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postInvoiceWhatsappAttachmentsWTemplate.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postInvoiceWhatsappAttachmentsWTemplate.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getInvoiceOrganisationTwilioCall(Model) {
  try {
    const data = yield call(
      invoiceApis.getInvoiceOrganisationTwilioApi,
      Model.data
    );
    yield put(getInvoiceOrganisationTwilio.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getInvoiceOrganisationTwilio.error(error.response?.data?.message)
      );
    else
      yield put(
        getInvoiceOrganisationTwilio.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getInvoiceTwillioTemplateCall(Model) {
  try {
    const data = yield call(
      invoiceApis.getInvoiceTwillioTemplateApi,
      Model.data
    );
    yield put(getInvoiceTwillioTemplate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getInvoiceTwillioTemplate.error(error.response?.data?.message));
    else
      yield put(getInvoiceTwillioTemplate.error(notification.CONST_NOINTERNET));
  }
}

function* getInvoiceFileAttachmentNotificationCall(Model) {
  try {
    const data = yield call(
      invoiceApis.getInvoiceFileAttachmentNotificationApi,
      Model.data
    );
    yield put(getInvoiceFileAttachmentNotification.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getInvoiceFileAttachmentNotification.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getInvoiceFileAttachmentNotification.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getInvoiceCurrentOrganisationCall(Model) {
  try {
    const data = yield call(
      invoiceApis.getInvoiceCurrentOrganisationApi,
      Model.data
    );
    yield put(getInvoiceCurrentOrganisation.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getInvoiceCurrentOrganisation.error(error.response?.data?.message)
      );
    else
      yield put(
        getInvoiceCurrentOrganisation.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postInvoiceS3CopyObjectCall(Model) {
  try {
    const data = yield call(invoiceApis.postInvoiceS3CopyObjectApi, Model.data);
    yield put(postInvoiceS3CopyObject.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postInvoiceS3CopyObject.error(error.response?.data?.message));
    else
      yield put(postInvoiceS3CopyObject.error(notification.CONST_NOINTERNET));
  }
}
