//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//components
import AddOrderPage from "./AddOrderPage";
import StoreListTable from "./StoreListTable";
import * as generalConstants from "../../../_constants/generalConstants";
import { useStyles } from "../../../components/globalStyles";
//actions

const OrderSummaryPage = () => {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  const [storeListView, setStoreListView] = React.useState(true);
  const [storeInformationView, setStoreInformationView] = React.useState(false);

  const curUser = useSelector((state) => state.curUser);
  const addOrderAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_STORE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    : false;
  //useEffects

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!storeInformationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            {storeListView ? (
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Order Summary")}
              </Typography>
            ) : (
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Add Order")}
              </Typography>
            )}
          </Grid>

          {storeListView && addOrderAccess ? (
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonColor}
                onClick={() => setStoreListView(!storeListView)}>
                {t("translation:Add")}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={1}></Grid>
          )}
        </Grid>
      )}
      <hr />
      {!storeListView && <AddOrderPage setStoreListView={setStoreListView} />}
      {storeListView && (
        <StoreListTable setStoreInformationView={setStoreInformationView} />
      )}
    </Paper>
  );
};

export default OrderSummaryPage;
