//lib
import React, { useEffect } from "react";

import { usePDF } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

import moment from "moment";

import * as generalConstants from "../../_constants/generalConstants";

import onlyFixedNumerics from "../functions/onlyFixedNumerics";
// //components
// import NotoSansHansRegular from "./NotoSansHans-Regular-2.ttf";

//style

// Font.register({
//   family: "NotoSansSChinese",
//   fonts: [{ src: NotoSansHansRegular, fontWeight: 300 }],
// });
// Font.registerHyphenationCallback((word) =>
//   word.length === 1 ? [word] : Array.from(word).map((char) => char)
// );

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  flexRow: {
    flexDirection: "row",
  },
  flexRow1: {
    flexDirection: "row",

    marginTop: 20,
  },
  flexColumn: { flexDirection: "column" },
  flexColumn1: { flexDirection: "column", width: "40%", paddingLeft: 5 },
  flexColumn2: {
    flexDirection: "column",
    width: "33.33%",
    paddingRight: "3mm",
    paddingBottom: "3mm",
  },
  text: {
    fontSize: "3mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "50%",
    paddingRight: "3mm",
  },
  text1: {
    fontSize: "3mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "100%",
  },
  text2: {
    fontSize: "2.5mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
  },
  text3: {
    fontSize: "3mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "100%",
  },
  text4: {
    fontSize: "3mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "100%",
  },
  text5: {
    fontSize: "3mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "50%",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  pageNumber: {
    fontSize: "3mm",
    right: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  pagePrinted: {
    fontSize: "3mm",
    left: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  table: {
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    width: "100%",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: "35%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol3: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol4: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol5: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol6: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCell: {
    fontSize: "3mm",
    fontFamily: "NotoSansSChinese",
    bottom: "-1mm",
    margin: "0.2mm",
  },

  maroon075: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,0.75)",
  },
  grey: {
    fontWeight: "bold",
    color: "rgba(77, 77, 77)",
  },

  totalWeightPerDayText: {
    fontSize: "4mm",
    //SUMUP width percent of tableCol1 to tableCol15
    left: "45%",
    position: "absolute",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
  totalWeightText: {
    fontSize: "4mm",
    //SUMUP width percent of tableCol1 to tableCol15
    left: "75%",
    position: "absolute",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
});

const MedicineTable = ({
  storeHeader,
  measureUnit,
  storeMedicineHeader,
  dispensaryMedicineHeader,
}) => {
  let totalWeightPerDay = 0;
  const medicineRows = dispensaryMedicineHeader
    ? dispensaryMedicineHeader
        .filter(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
        .sort((a, b) => a.row_no - b.row_no)
    : storeMedicineHeader.store_medicine_details
        .filter(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
        .sort((a, b) => a.row_no - b.row_no);

  let Rows = [];
  if (!dispensaryMedicineHeader) {
    Rows = medicineRows.map((item, index) => {
      if (item.status_type === generalConstants.APPROVED_STATUS_TYPE) {
        totalWeightPerDay = onlyFixedNumerics(
          onlyFixedNumerics(
            Number(item.quantity_per_day) + Number(totalWeightPerDay)
          )
        ).toFixed(2);
        const measureUnitValue = measureUnit.find(
          (unit) => unit.id === item.branch_medicine.medicines.measure_unit
        );

        return (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>{index + 1}</Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>
                {item.medicine_name} {item.alternative_name}
              </Text>
            </View>

            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                {item.quantity_per_day.toFixed(2)}
              </Text>
            </View>
            <View style={styles.tableCol4}>
              <Text style={styles.tableCell}>
                {measureUnitValue ? measureUnitValue.name : ""}
              </Text>
            </View>
            <View style={styles.tableCol5}>
              <Text style={styles.tableCell}>
                {(
                  item.quantity_per_day * storeMedicineHeader.number_of_days
                ).toFixed(2)}
              </Text>
            </View>

            <View style={styles.tableCol6}>
              <Text style={styles.tableCell}>{item.default_instruction}</Text>
            </View>
          </View>
        );
      } else {
        return <></>;
      }
    });
  }

  if (dispensaryMedicineHeader) {
    Rows = medicineRows.map((item, index) => {
      if (
        item.store_medicine_details.status_type ===
        generalConstants.APPROVED_STATUS_TYPE
      ) {
        totalWeightPerDay = onlyFixedNumerics(
          onlyFixedNumerics(
            Number(item.store_medicine_details.quantity_per_day) +
              Number(totalWeightPerDay)
          )
        ).toFixed(2);
        const measureUnitValue = measureUnit.find(
          (unit) =>
            unit.id ===
            item.store_medicine_details.branch_medicine.medicines.measure_unit
        );

        return (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>{index + 1}</Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>
                {item.store_medicine_details.medicine_name}{" "}
                {item.store_medicine_details.alternative_name}
              </Text>
            </View>

            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                {item.store_medicine_details.quantity_per_day.toFixed(2)}
              </Text>
            </View>
            <View style={styles.tableCol4}>
              <Text style={styles.tableCell}>
                {measureUnitValue ? measureUnitValue.name : ""}
              </Text>
            </View>
            <View style={styles.tableCol5}>
              <Text style={styles.tableCell}>
                {(
                  item.store_medicine_details.quantity_per_day *
                  storeMedicineHeader.number_of_days
                ).toFixed(2)}
              </Text>
            </View>
            <View style={styles.tableCol6}>
              <Text style={styles.tableCell}>
                {item.store_medicine_details.default_instruction}
              </Text>
            </View>
          </View>
        );
      } else {
        return <></>;
      }
    });
  }

  return (
    <View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>序号</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>药名</Text>
          </View>

          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>数量 / 天</Text>
          </View>
          <View style={styles.tableCol4}>
            <Text style={styles.tableCell}>单位</Text>
          </View>
          <View style={styles.tableCol5}>
            <Text style={styles.tableCell}>总量</Text>
          </View>
          <View style={styles.tableCol6}>
            <Text style={styles.tableCell}>用法</Text>
          </View>
        </View>
        {Rows}
        {/* <View>
          <Text style={styles.totalWeightPerDayText}>{totalWeightPerDay}</Text>
        </View> */}
        <View>
          <Text style={styles.totalWeightText}>
            {onlyFixedNumerics(
              onlyFixedNumerics(
                Number(totalWeightPerDay) *
                  Number(storeMedicineHeader.number_of_days)
              )
            ).toFixed(2)}
          </Text>
        </View>
      </View>
      <View style={styles.flexRow1}>
        <Text style={styles.text3}>
          Medicine Group: {storeMedicineHeader.name}
        </Text>
      </View>
      <View style={styles.flexRow}>
        <Text style={styles.text5}>
          No of Days: {storeMedicineHeader.number_of_days} Days
        </Text>
        <Text style={styles.text5}>
          Expiry Date:{" "}
          {moment
            .utc(storeHeader.submitted_on)
            .local()
            .add(1, "M")
            .format("DD-MMM-YYYY")}
        </Text>
      </View>

      <Text style={styles.text1}>Prescription Instructions:</Text>
      <Text style={styles.text4}>
        {storeMedicineHeader.patient_instructions}
      </Text>

      <View style={styles.flexRow}>
        <View style={styles.flexColumn}>
          <Text style={styles.text2}>
            Note: Only limited to dispensary in this clinic, not applicable for
            third party dispensary!
          </Text>
          <Text style={styles.text2}>
            仅限于本诊所药房, 不适用于第三方药房!
          </Text>
        </View>
      </View>
    </View>
  );
};

const AddressHeader = ({ storeHeader, storeMedicineHeader }) => {
  if (storeHeader.work_order_type === generalConstants.STORE_WORK_ORDER_TYPE) {
    if (storeHeader.organisation.is_freelance_organisation === true) {
      return (
        <View style={styles.flexColumn1} fixed>
          <Text style={styles.text2} fixed>
            {storeHeader.staff.name}
          </Text>

          {storeHeader.staff.postal_code &&
          storeHeader.staff.blk_no &&
          storeHeader.staff.road_name ? (
            <Text style={styles.text2} fixed>
              {storeHeader.staff.blk_no} {storeHeader.staff.road_name}{" "}
              {storeHeader.staff.building
                ? storeHeader.staff.building + " "
                : null}
              {storeHeader.staff.address_details} SINGAPORE{" "}
              {storeHeader.staff.postal_code}
            </Text>
          ) : (
            <Text style={styles.text2} fixed>
              {storeHeader.staff.address} {storeHeader.staff.address_details}
            </Text>
          )}
          <Text style={styles.text2} fixed>
            Tel No: {storeHeader.staff.contact_number}
          </Text>
        </View>
      );
    } else {
      return (
        <View style={styles.flexColumn1} fixed>
          <Text style={styles.text2} fixed>
            {storeHeader.organisation.desc}
          </Text>

          {storeHeader.organisation.gst_reg_no !== null && (
            <Text style={styles.text2} fixed>
              GST No: {storeHeader.organisation.gst_reg_no}
            </Text>
          )}
        </View>
      );
    }
  }

  if (storeHeader.work_order_type === generalConstants.VISIT_WORK_ORDER_TYPE) {
    if (storeHeader.organisation.is_freelance_organisation === true) {
      return (
        <View style={styles.flexColumn1} fixed>
          <Text style={styles.text2} fixed>
            {storeHeader.physician.name}
          </Text>

          {storeHeader.physician.postal_code &&
          storeHeader.physician.blk_no &&
          storeHeader.physician.road_name ? (
            <Text style={styles.text2} fixed>
              {storeHeader.physician.blk_no} {storeHeader.physician.road_name}{" "}
              {storeHeader.physician.building
                ? storeHeader.physician.building + " "
                : null}
              {storeHeader.physician.address_details} SINGAPORE{" "}
              {storeHeader.physician.postal_code}
            </Text>
          ) : (
            <Text style={styles.text2} fixed>
              {storeHeader.physician.address}{" "}
              {storeHeader.physician.address_details}
            </Text>
          )}
          <Text style={styles.text2} fixed>
            Tel No: {storeHeader.physician.contact_number}
          </Text>
        </View>
      );
    } else {
      return (
        <View style={styles.flexColumn1} fixed>
          <Text style={styles.text2} fixed>
            {storeHeader.visit_branch.desc}
          </Text>

          {storeHeader.visit_branch.postal_code &&
          storeHeader.visit_branch.blk_no &&
          storeHeader.visit_branch.road_name ? (
            <Text style={styles.text2} fixed>
              {storeHeader.visit_branch.blk_no}{" "}
              {storeHeader.visit_branch.road_name}{" "}
              {storeHeader.visit_branch.building
                ? storeHeader.visit_branch.building + " "
                : null}
              {storeHeader.visit_branch.address_details} SINGAPORE{" "}
              {storeHeader.visit_branch.postal_code}
            </Text>
          ) : (
            <Text style={styles.text2} fixed>
              {storeHeader.visit_branch.address}{" "}
              {storeHeader.visit_branch.address_details}
            </Text>
          )}
          <Text style={styles.text2} fixed>
            Tel No: {storeHeader.visit_branch.contact_number}
          </Text>
          {storeHeader.organisation.gst_reg_no !== null && (
            <Text style={styles.text2} fixed>
              GST No: {storeHeader.organisation.gst_reg_no}
            </Text>
          )}
        </View>
      );
    }
  }
  return <View style={styles.flexColumn1} fixed></View>;
};

const PDFPage = ({
  storeHeader,
  storeMedicineHeader,
  measureUnit,
  TCMLogo,
  dispensaryMedicineHeader,
}) => {
  const NRIC = storeHeader.staff_identification_number;
  const NRICLength = NRIC ? NRIC.length : 0;
  let NRICLastFourChar = NRIC ? NRIC.substring(NRICLength - 4) : null;
  return (
    <Document>
      <Page size="A5" orientation="portrait" style={styles.body}>
        <View style={styles.flexRow} fixed>
          <View style={styles.flexColumn2} fixed>
            <Image
              src={TCMLogo}
              style={{ height: "50px", width: "100px" }}
              fixed
            />
          </View>
          <AddressHeader
            storeHeader={storeHeader}
            storeMedicineHeader={storeMedicineHeader}
          />
        </View>
        <View style={styles.flexRow} fixed>
          <Text style={styles.text} fixed>
            Name: {storeHeader.staff_name}
          </Text>

          <Text style={styles.text} fixed>
            Date:{" "}
            {moment.utc(storeHeader.submitted_on).local().format("DD-MMM-YYYY")}
          </Text>
        </View>
        <View style={styles.flexRow} fixed>
          <Text style={styles.text} fixed>
            NRIC: {NRICLastFourChar}
          </Text>

          {storeHeader.physician_name && (
            <Text style={styles.text} fixed>
              Physician Name: {storeHeader.physician_name}
            </Text>
          )}
        </View>

        <MedicineTable
          storeHeader={storeHeader}
          storeMedicineHeader={storeMedicineHeader}
          dispensaryMedicineHeader={dispensaryMedicineHeader}
          measureUnit={measureUnit}
        />

        <Text style={styles.pagePrinted} fixed>
          {`${
            storeHeader.first_print_on === null
              ? "Printed on:"
              : "Reprinted on:"
          } ${moment().format("DD-MMM-YYYY")}`}
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
let printNum = 0;
const PatientPrescriptionPDF = ({
  storeHeader,
  storeMedicineHeader,
  t,
  measureUnit,
  TCMLogo,
  print,
  dispensaryMedicineHeader = null,
  setPrintingDate = () => null,
  allowPrint,
  setAllowPrint = () => null,
}) => {
  const [instance, updateInstance] = usePDF({
    document: (
      <PDFPage
        TCMLogo={TCMLogo}
        storeHeader={storeHeader}
        storeMedicineHeader={storeMedicineHeader}
        dispensaryMedicineHeader={dispensaryMedicineHeader}
        measureUnit={measureUnit}
      />
    ),
  });

  useEffect(() => {
    if (
      !instance.loading &&
      instance.url &&
      print !== printNum &&
      !allowPrint
    ) {
      printNum = print;
      //window.open(instance.url, "_blank", "noreferrer");

      setPrintingDate();
    }
  }, [instance.loading, instance.url, printNum, print, allowPrint]);

  useEffect(() => {
    if (!instance.loading && instance.url && allowPrint) {
      //make sure update print date and allow to print
      window.open(instance.url, "_blank", "noreferrer");
      setAllowPrint(false);
    }
  }, [instance.loading, instance.url, allowPrint]);
  return <></>;
  // if (print !== printNum) {
  //   return (
  //     <BlobProvider
  //       document={
  //         <PDFPage
  //           TCMLogo={TCMLogo}
  //           storeHeader={storeHeader}
  //           storeMedicineHeader={storeMedicineHeader}
  //           dispensaryMedicineHeader={dispensaryMedicineHeader}
  //           measureUnit={measureUnit}
  //         />
  //       }>
  //       {({ blob, url, loading, error }) => {
  //         // var file = new blob();
  //         var fileURL = [];
  //         if (!loading && blob && print !== printNum) {
  //           printNum = print;
  //           fileURL = URL.createObjectURL(blob);
  //           window.open(fileURL, "_blank", "noreferrer");
  //           setPrintingDate();
  //         }

  //         // Do whatever you need with blob here
  //         return <></>;
  //       }}
  //     </BlobProvider>
  //   );
  // } else {
  //   return <></>;
  // }
};

export default PatientPrescriptionPDF;
