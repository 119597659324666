import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Panel from "../../../../../components/Panel";
import * as generalConstants from "../../../../../_constants/generalConstants";
import { useStyles } from "../../../../../components/globalStyles";

function TotalPricePanel({
  classes,
  t,
  item,
  currentOrganistion,
  price_rmt,
  price_rpvt,
}) {
  return (
    <>
      {item.amt_with_gst !== 0 && item.amt_with_gst && (
        <Panel
          heading={
            item.organisation_branch.branch.name +
            " " +
            t("translation:Total Fee") +
            " $" +
            Number(item.amt_with_gst).toFixed(2)
          }>
          <Grid container>
            <Grid xs={3} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={Number(item.branch_medicine_price).toFixed(2)}
                disabled
                name="totalPrice"
                label={t("translation:Total Medicine Fee")}
                variant="outlined"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    fontWeight: "bold",
                    color: "rgba(149, 0, 0,0.75)",
                  },
                }}
              />
            </Grid>

            {item.isShowBrewing && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(item.branch_brewing_price).toFixed(2)}
                  disabled
                  name="brewingFee"
                  label={t("translation:Brewing Fee")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}

            {item.isShowDelivery && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(item.branch_delivery_price).toFixed(2)}
                  disabled
                  name="deliveryFee"
                  label={t("translation:Delivery Fee")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}

            {item.organisation_branch.organisation.id !==
              currentOrganistion.id && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(item.gst_amt).toFixed(2)}
                  disabled
                  name="taxAmmount"
                  label={t("translation:Tax Amount")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Panel>
      )}
    </>
  );
}

export default function ViewAllianceTotalPrice({
  memoGroupOption,
  price,
  price_rmt,
  price_rpvt,
  costingHeader,
  setCostingHeader,
  currentOrganistion,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showDeliveryPrice, setShowDeliveryPrice] = useState(false);
  const [showBrewingPrice, setShowBrewingPrice] = useState(false);

  //useEffects

  useEffect(() => {
    const isDelivery = memoGroupOption.some(
      (item) =>
        item.is_delivery_services === true &&
        item.medicine_type &&
        item.organisation_branch &&
        Object.keys(item.store_medicine_details).length !== 0
    );

    setShowDeliveryPrice(isDelivery);
  }, [memoGroupOption]);

  useEffect(() => {
    const isBrewing = memoGroupOption.some(
      (item) =>
        item.is_brewing_services === true &&
        item.medicine_type &&
        item.organisation_branch &&
        Object.keys(item.store_medicine_details).length !== 0
    );

    setShowBrewingPrice(isBrewing);
  }, [memoGroupOption]);

  useEffect(() => {
    const groupOptionWMedicineDetails = memoGroupOption.filter(
      (option) =>
        option.store_medicine_details &&
        Object.keys(option.store_medicine_details).length !== 0 &&
        option.store_medicine_details.some(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
    );

    //grouping by organisation_branch and show brewing price
    var result = [];
    groupOptionWMedicineDetails.reduce(function (res, value) {
      if (!res[value.organisation_branch.id]) {
        const isBrewing = groupOptionWMedicineDetails.some(
          (item) =>
            item.is_brewing_services === true &&
            item.organisation_branch.id === value.organisation_branch.id
        );
        //
        //
        res[value.organisation_branch.id] = {
          organisation_branch: value.organisation_branch,
          isShowBrewing: isBrewing,
        };
        result.push(res[value.organisation_branch.id]);
      }

      return res;
    }, {});
    setCostingHeader((memoCostingHeader) => {
      let tempCostingHeader = [...memoCostingHeader];
      if (
        Object.keys(result).length === 0 &&
        Object.keys(tempCostingHeader).length !== 0
      ) {
        const disabledDelivery = tempCostingHeader.map((item) => {
          return { ...item, isShowBrewing: false };
        });
        tempCostingHeader = [...disabledDelivery];
      }
      //
      result.map((resultItem) => {
        const resultItemIndex = tempCostingHeader.findIndex(
          (indexItem) =>
            indexItem.organisation_branch.id ===
            resultItem.organisation_branch.id
        );

        if (resultItemIndex !== -1) {
          tempCostingHeader[resultItemIndex] = {
            ...tempCostingHeader[resultItemIndex],
            isShowBrewing: resultItem.isShowBrewing,
          };
        }
        return "";
      });
      return tempCostingHeader;
    });
  }, [memoGroupOption, setCostingHeader]);

  useEffect(() => {
    // get the array of the delivery

    const groupOptionWMedicineDetails = memoGroupOption.filter(
      (option) =>
        option.store_medicine_details &&
        Object.keys(option.store_medicine_details).length !== 0 &&
        option.store_medicine_details.some(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
    );

    //grouping by organisation_branch and show delivery price
    var result = [];
    groupOptionWMedicineDetails.reduce(function (res, value) {
      if (!res[value.organisation_branch.id]) {
        const isDelivery = groupOptionWMedicineDetails.some(
          (item) =>
            item.is_delivery_services === true &&
            item.organisation_branch.id === value.organisation_branch.id
        );
        //
        //
        res[value.organisation_branch.id] = {
          organisation_branch: value.organisation_branch,
          isShowDelivery: isDelivery,
        };
        result.push(res[value.organisation_branch.id]);
      }

      return res;
    }, {});
    setCostingHeader((memoCostingHeader) => {
      let tempCostingHeader = [...memoCostingHeader];
      if (
        Object.keys(result).length === 0 &&
        Object.keys(tempCostingHeader).length !== 0
      ) {
        const disabledDelivery = tempCostingHeader.map((item) => {
          return { ...item, isShowDelivery: false };
        });
        tempCostingHeader = [...disabledDelivery];
      }
      //
      result.map((resultItem) => {
        const resultItemIndex = tempCostingHeader.findIndex(
          (indexItem) =>
            indexItem.organisation_branch.id ===
            resultItem.organisation_branch.id
        );

        if (resultItemIndex !== -1) {
          tempCostingHeader[resultItemIndex] = {
            ...tempCostingHeader[resultItemIndex],
            isShowDelivery: resultItem.isShowDelivery,
          };
        }
        return "";
      });
      return tempCostingHeader;
    });
  }, [memoGroupOption, setCostingHeader]);

  return (
    <>
      {costingHeader.map((item) => (
        <TotalPricePanel
          key={item.organisation_branch.branch.id}
          classes={classes}
          t={t}
          item={item}
          currentOrganistion={currentOrganistion}
          price_rmt={price_rmt}
          price_rpvt={price_rpvt}
        />
      ))}

      <Panel
        heading={
          t("translation:Total Fee") +
          " $" +
          Number(
            Number(price.medicineFee) +
              Number(price.brewingFee) +
              Number(price.deliveryFee)
          ).toFixed(2)
        }>
        <Grid container>
          <Grid xs={3} item className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={Number(price.medicineFee).toFixed(2)}
              disabled
              name="totalPrice"
              label={t("translation:Total Medicine Fee")}
              variant="outlined"
              inputProps={{
                style: { textAlign: "right" },
              }}
              InputLabelProps={{
                shrink: true,

                style: {
                  fontWeight: "bold",
                  color: "rgba(149, 0, 0,0.75)",
                },
              }}
            />
          </Grid>
          {showBrewingPrice && (
            <Grid xs={3} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={Number(price.brewingFee).toFixed(2)}
                disabled
                name="brewingFee"
                label={t("translation:Brewing Fee")}
                variant="outlined"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    fontWeight: "bold",
                    color: "rgba(149, 0, 0,0.75)",
                  },
                }}
              />
            </Grid>
          )}
          {showDeliveryPrice && (
            <Grid xs={3} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={Number(price.deliveryFee).toFixed(2)}
                disabled
                name="deliveryFee"
                label={t("translation:Delivery Fee")}
                variant="outlined"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    fontWeight: "bold",
                    color: "rgba(149, 0, 0,0.75)",
                  },
                }}
              />
            </Grid>
          )}
        </Grid>
      </Panel>
    </>
  );
}
