import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getDeliveryOrderSummaryList,
  getDeliveryOrderById,
  getDeliveryOrderList,
  postSaveDeliveryOrder,
  postSubmitDeliveryOrder,
  postSaveDeliveryOrderWCheck,
  postSubmitDeliveryOrderWCheck,
  postSubmitAllDelivery,
  putDeliveryOrderWIPMode,
  putDeliveryOrderEditMode,
  putDeliveryOrderPendingMode,
  getDeliveryCourierList,
  getDeliveryCourierByBranch,
  getDeliveryOrderByStoreHeaderId,
  getStoreHeaderList,
  getStoreHeaderSummaryList,
  putDeliveryOrderPrintDate,
  putDeliveryOrderUpdateStoreDeliveryAddress,
  postExportDeliveryOrders,
  postExportDeliveryOrdersV2,
  getRoutingServicePayload,
  getGeometryValuePayload,
  getRouteByGridPayload,
  getAreaClusterPayload,
  getOnemapStaticMapPayload,
  getDeliveryOrderPostalCodes,
  getGoogleStaticMapPayload,
  getDeliveryOptimizedRouting,
  getDeliveryOptimizedRoutingMap,
  getDeliveryVerifyAddress,
  putUpdateAllStoreDeliveryAddress,
  postSearchCsvCourierDeliveryAddress,
  postSearchCourierDeliveryAddress,
  putUpdateDeliveryTrackingRowNo,
  getDeliveryOrderVerifyAddressList,
  getDeliveryOrderDeliveryTrackingList,
} from "../actions/deliveryOrderActions";
import * as types from "../_constants/deliveryOrderConstants";
import * as notification from "../_constants/notificationConstants";
import deliveryOrderApis from "../_apis/deliveryOrderApis";
export default function* deliveryOrderSagas() {
  yield all([
    takeLatest(
      types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.PENDING,
      getDeliveryOrderSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYORDERBYID_ASYNC.PENDING,
      getDeliveryOrderByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYORDERLIST_ASYNC.PENDING,
      getDeliveryOrderListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SAVEDELIVERYORDER_ASYNC.PENDING,
      postSaveDeliveryOrderCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SUBMITDELIVERYORDER_ASYNC.PENDING,
      postSubmitDeliveryOrderCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_SAVEDELIVERYORDERWCHECK_ASYNC.PENDING,
      postSaveDeliveryOrderWCheckCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SUBMITDELIVERYORDERWCHECK_ASYNC.PENDING,
      postSubmitDeliveryOrderWCheckCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SUBMITALLDELIVERY_ASYNC.PENDING,
      postSubmitAllDeliveryCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_DELIVERYORDERWIPMODE_ASYNC.PENDING,
      putDeliveryOrderWIPModeCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_DELIVERYORDEREDITMODE_ASYNC.PENDING,
      putDeliveryOrderEditModeCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.PENDING,
      putDeliveryOrderPendingCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_DELIVERYCOURIERLIST_ASYNC.PENDING,
      getDeliveryCourierListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.PENDING,
      getDeliveryCourierByBranchCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.PENDING,
      getDeliveryOrderByStoreHeaderIdCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_STOREHEADERLIST_ASYNC.PENDING, getStoreHeaderListCall),
  ]);

  yield all([
    takeLatest(
      types.GET_STOREHEADERSUMMARYLIST_ASYNC.PENDING,
      getStoreHeaderSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_DELIVERYORDERPRINTDATE_ASYNC.PENDING,
      putDeliveryOrderPrintDateCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.PENDING,
      putDeliveryOrderUpdateStoreDeliveryAddressCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_EXPORTDELIVERYORDERS_ASYNC.PENDING,
      postExportDeliveryOrdersCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_EXPORTDELIVERYORDERSV2_ASYNC.PENDING,
      postExportDeliveryOrdersV2Call
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_ROUTINGSERVICEPAYLOAD_ASYNC.PENDING,
      getRoutingServicePayloadCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_GEOMETRYVALUEPAYLOAD_ASYNC.PENDING,
      getGeometryValuePayloadCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_ROUTEBYGRIDPAYLOAD_ASYNC.PENDING,
      getRouteByGridPayloadCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_AREACLUSTERPAYLOAD_ASYNC.PENDING,
      getAreaClusterPayloadCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_ONEMAPSTATICMAPPAYLOAD_ASYNC.PENDING,
      getOnemapStaticMapPayloadCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYORDERPOSTALCODES_ASYNC.PENDING,
      getDeliveryOrderPostalCodesCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_GOOGLESTATICMAPPAYLOAD_ASYNC.PENDING,
      getGoogleStaticMapPayloadCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYOPTIMIZEDROUTING_ASYNC.PENDING,
      getDeliveryOptimizedRoutingCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYOPTIMIZEDROUTINGMAP_ASYNC.PENDING,
      getDeliveryOptimizedRoutingMapCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYVERIFYADDRESS_ASYNC.PENDING,
      getDeliveryVerifyAddressCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_UPDATEALLSTOREDELIVERYADDRESS_ASYNC.PENDING,
      putUpdateAllStoreDeliveryAddressCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_SEARCHCSVCOURIERDELIVERYADDRESS_ASYNC.PENDING,
      postSearchCsvCourierDeliveryAddressCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_SEARCHCOURIERDELIVERYADDRESS_ASYNC.PENDING,
      postSearchCourierDeliveryAddressCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_UPDATEDELIVERYTRACKINGROWNO_ASYNC.PENDING,
      putUpdateDeliveryTrackingRowNoCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYORDERVERIFYADDRESSLIST_ASYNC.PENDING,
      getDeliveryOrderVerifyAddressListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYORDERDELIVERYTRACKINGLIST_ASYNC.PENDING,
      getDeliveryOrderDeliveryTrackingListCall
    ),
  ]);
}

function* getDeliveryOrderSummaryListCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOrderSummaryListApi,
      model.data
    );
    yield put(getDeliveryOrderSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryOrderSummaryList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryOrderSummaryList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryOrderByIdCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOrderByIdApi,
      model.data
    );
    yield put(getDeliveryOrderById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDeliveryOrderById.error(error.response?.data?.message));
    else yield put(getDeliveryOrderById.error(notification.CONST_NOINTERNET));
  }
}

function* getDeliveryOrderListCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOrderListApi,
      model.data
    );
    yield put(getDeliveryOrderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDeliveryOrderList.error(error.response?.data?.message));
    else yield put(getDeliveryOrderList.error(notification.CONST_NOINTERNET));
  }
}

function* postSaveDeliveryOrderCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.postSaveDeliveryOrderApi,
      model.data
    );
    yield put(postSaveDeliveryOrder.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSaveDeliveryOrder.error(error.response?.data));
    else yield put(postSaveDeliveryOrder.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitDeliveryOrderCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.postSubmitDeliveryOrderApi,
      model.data
    );
    yield put(postSubmitDeliveryOrder.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitDeliveryOrder.error(error.response?.data));
    else
      yield put(postSubmitDeliveryOrder.error(notification.CONST_NOINTERNET));
  }
}

function* postSaveDeliveryOrderWCheckCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.postSaveDeliveryOrderWCheckApi,
      model.data
    );
    yield put(postSaveDeliveryOrderWCheck.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSaveDeliveryOrderWCheck.error(error.response?.data));
    else
      yield put(
        postSaveDeliveryOrderWCheck.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postSubmitDeliveryOrderWCheckCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.postSubmitDeliveryOrderWCheckApi,
      model.data
    );
    yield put(postSubmitDeliveryOrderWCheck.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitDeliveryOrderWCheck.error(error.response?.data));
    else
      yield put(
        postSubmitDeliveryOrderWCheck.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postSubmitAllDeliveryCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.postSubmitAllDeliveryApi,
      model.data
    );
    yield put(postSubmitAllDelivery.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitAllDelivery.error(error.response?.data?.message));
    else yield put(postSubmitAllDelivery.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryOrderWIPModeCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.putDeliveryOrderWIPModeApi,
      model.data
    );
    yield put(putDeliveryOrderWIPMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putDeliveryOrderWIPMode.error(error.response?.data?.message));
    else
      yield put(putDeliveryOrderWIPMode.error(notification.CONST_NOINTERNET));
  }
}
function* putDeliveryOrderEditModeCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.putDeliveryOrderEditModeApi,
      model.data
    );
    yield put(putDeliveryOrderEditMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putDeliveryOrderEditMode.error(error.response?.data?.message));
    else
      yield put(putDeliveryOrderEditMode.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryOrderPendingCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.putDeliveryOrderPendingModeApi,
      Model.data
    );
    yield put(putDeliveryOrderPendingMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDeliveryOrderPendingMode.error(error.response?.data?.message)
      );
    else
      yield put(
        putDeliveryOrderPendingMode.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryCourierListCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryCourierListApi,
      model.data
    );
    yield put(getDeliveryCourierList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDeliveryCourierList.error(error.response?.data?.message));
    else yield put(getDeliveryCourierList.error(notification.CONST_NOINTERNET));
  }
}

function* getDeliveryCourierByBranchCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryCourierByBranchApi,
      model.data
    );
    yield put(getDeliveryCourierByBranch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryCourierByBranch.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryCourierByBranch.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryOrderByStoreHeaderIdCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOrderByStoreHeaderIdApi,
      model.data
    );
    yield put(getDeliveryOrderByStoreHeaderId.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryOrderByStoreHeaderId.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryOrderByStoreHeaderId.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getStoreHeaderListCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getStoreHeaderListApi,
      model.data
    );
    yield put(getStoreHeaderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getStoreHeaderList.error(error.response?.data?.message));
    else yield put(getStoreHeaderList.error(notification.CONST_NOINTERNET));
  }
}

function* getStoreHeaderSummaryListCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getStoreHeaderSummaryListApi,
      model.data
    );
    yield put(getStoreHeaderSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getStoreHeaderSummaryList.error(error.response?.data?.message));
    else
      yield put(getStoreHeaderSummaryList.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryOrderPrintDateCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.putDeliveryOrderPrintDateApi,
      Model.data
    );
    yield put(putDeliveryOrderPrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putDeliveryOrderPrintDate.error(error.response?.data?.message));
    else
      yield put(putDeliveryOrderPrintDate.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryOrderUpdateStoreDeliveryAddressCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.putDeliveryOrderUpdateStoreDeliveryAddressApi,
      Model.data
    );
    yield put(putDeliveryOrderUpdateStoreDeliveryAddress.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDeliveryOrderUpdateStoreDeliveryAddress.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        putDeliveryOrderUpdateStoreDeliveryAddress.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* postExportDeliveryOrdersCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.postExportDeliveryOrdersApi,
      Model.data
    );
    yield put(postExportDeliveryOrders.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postExportDeliveryOrders.error(error.response?.data?.message));
    else
      yield put(postExportDeliveryOrders.error(notification.CONST_NOINTERNET));
  }
}

function* postExportDeliveryOrdersV2Call(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.postExportDeliveryOrdersV2Api,
      Model.data
    );
    yield put(postExportDeliveryOrdersV2.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postExportDeliveryOrdersV2.error(error.response?.data?.message)
      );
    else
      yield put(
        postExportDeliveryOrdersV2.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getRoutingServicePayloadCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getRoutingServicePayloadApi,
      Model.data
    );
    yield put(getRoutingServicePayload.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getRoutingServicePayload.error(error.response?.data?.message));
    else
      yield put(getRoutingServicePayload.error(notification.CONST_NOINTERNET));
  }
}

function* getGeometryValuePayloadCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getGeometryValuePayloadApi,
      Model.data
    );
    yield put(getGeometryValuePayload.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getGeometryValuePayload.error(error.response?.data?.message));
    else
      yield put(getGeometryValuePayload.error(notification.CONST_NOINTERNET));
  }
}

function* getRouteByGridPayloadCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getRouteByGridPayloadApi,
      Model.data
    );
    yield put(getRouteByGridPayload.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getRouteByGridPayload.error(error.response?.data?.message));
    else yield put(getRouteByGridPayload.error(notification.CONST_NOINTERNET));
  }
}

function* getAreaClusterPayloadCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getAreaClusterPayloadApi,
      Model.data
    );
    yield put(getAreaClusterPayload.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAreaClusterPayload.error(error.response?.data?.message));
    else yield put(getAreaClusterPayload.error(notification.CONST_NOINTERNET));
  }
}

function* getOnemapStaticMapPayloadCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getOnemapStaticMapPayloadApi,
      Model.data
    );
    yield put(getOnemapStaticMapPayload.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getOnemapStaticMapPayload.error(error.response?.data?.message));
    else
      yield put(getOnemapStaticMapPayload.error(notification.CONST_NOINTERNET));
  }
}

function* getDeliveryOrderPostalCodesCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOrderPostalCodesApi,
      Model.data
    );
    yield put(getDeliveryOrderPostalCodes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryOrderPostalCodes.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryOrderPostalCodes.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getGoogleStaticMapPayloadCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getGoogleStaticMapPayloadApi,
      Model.data
    );
    yield put(getGoogleStaticMapPayload.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getGoogleStaticMapPayload.error(error.response?.data?.message));
    else
      yield put(getGoogleStaticMapPayload.error(notification.CONST_NOINTERNET));
  }
}

function* getDeliveryOptimizedRoutingCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOptimizedRoutingApi,
      Model.data
    );
    yield put(getDeliveryOptimizedRouting.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryOptimizedRouting.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryOptimizedRouting.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryOptimizedRoutingMapCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOptimizedRoutingMapApi,
      Model.data
    );
    yield put(getDeliveryOptimizedRoutingMap.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryOptimizedRoutingMap.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryOptimizedRoutingMap.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryVerifyAddressCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryVerifyAddressApi,
      Model.data
    );
    yield put(getDeliveryVerifyAddress.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDeliveryVerifyAddress.error(error.response?.data?.message));
    else
      yield put(getDeliveryVerifyAddress.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateAllStoreDeliveryAddressCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.putUpdateAllStoreDeliveryAddressApi,
      Model.data
    );
    yield put(putUpdateAllStoreDeliveryAddress.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateAllStoreDeliveryAddress.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdateAllStoreDeliveryAddress.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postSearchCsvCourierDeliveryAddressCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.postSearchCsvCourierDeliveryAddressApi,
      Model.data
    );
    yield put(postSearchCsvCourierDeliveryAddress.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postSearchCsvCourierDeliveryAddress.error(error.response?.data?.message)
      );
    else
      yield put(
        postSearchCsvCourierDeliveryAddress.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postSearchCourierDeliveryAddressCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.postSearchCourierDeliveryAddressApi,
      Model.data
    );
    yield put(postSearchCourierDeliveryAddress.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postSearchCourierDeliveryAddress.error(error.response?.data?.message)
      );
    else
      yield put(
        postSearchCourierDeliveryAddress.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateDeliveryTrackingRowNoCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.putUpdateDeliveryTrackingRowNoApi,
      Model.data
    );
    yield put(putUpdateDeliveryTrackingRowNo.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateDeliveryTrackingRowNo.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdateDeliveryTrackingRowNo.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryOrderVerifyAddressListCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOrderVerifyAddressListApi,
      Model.data
    );
    yield put(getDeliveryOrderVerifyAddressList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryOrderVerifyAddressList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryOrderVerifyAddressList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryOrderDeliveryTrackingListCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOrderDeliveryTrackingListApi,
      Model.data
    );
    yield put(getDeliveryOrderDeliveryTrackingList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryOrderDeliveryTrackingList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getDeliveryOrderDeliveryTrackingList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}
