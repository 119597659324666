import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SystemScheduleSummaryPage from "./SystemScheduleSummary/SystemScheduleSummaryPage";
export default function SystemSchedulePage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/systemSchedule"
        to="/systemSchedule/systemScheduleSummary"
      />
      <Route
        path="/systemSchedule/systemScheduleSummary"
        component={SystemScheduleSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
