import * as types from "../_constants/prescriptionMsgConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_PRESCRIPTIONMSGBYID_ASYNC.PENDING:
      return {
        ...state,
        getPrescriptionMsgByIdIsSuccess: false,
        isLoadingGetPrescriptionMsgById: true,
        getPrescriptionMsgById: null,
      };

    case types.GET_PRESCRIPTIONMSGBYID_ASYNC.ERROR:
      return {
        ...state,
        getPrescriptionMsgByIdIsSuccess: false,
        isLoadingGetPrescriptionMsgById: false,
        getPrescriptionMsgById: null,
        error: { ...state.error, getPrescriptionMsgByIdError: action.error },
      };

    case types.GET_PRESCRIPTIONMSGBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getPrescriptionMsgByIdIsSuccess: true,
        isLoadingGetPrescriptionMsgById: false,
        getPrescriptionMsgById: action.data,
      };

    case types.GET_PRESCRIPTIONMSGLIST_ASYNC.PENDING:
      return {
        ...state,
        getPrescriptionMsgListIsSuccess: false,
        isLoadingGetPrescriptionMsgList: true,
        getPrescriptionMsgList: null,
      };

    case types.GET_PRESCRIPTIONMSGLIST_ASYNC.ERROR:
      return {
        ...state,
        getPrescriptionMsgListIsSuccess: false,
        isLoadingGetPrescriptionMsgList: false,
        getPrescriptionMsgList: null,
        error: { ...state.error, getPrescriptionMsgListError: action.error },
      };

    case types.GET_PRESCRIPTIONMSGLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getPrescriptionMsgListIsSuccess: true,
        isLoadingGetPrescriptionMsgList: false,
        getPrescriptionMsgList: action.data,
      };

    case types.POST_CREATEPRESCRIPTIONMSG_ASYNC.PENDING:
      return {
        ...state,
        postCreatePrescriptionMsgIsSuccess: false,
        isLoadingPostCreatePrescriptionMsg: true,
        postCreatePrescriptionMsg: null,
      };

    case types.POST_CREATEPRESCRIPTIONMSG_ASYNC.ERROR:
      return {
        ...state,
        postCreatePrescriptionMsgIsSuccess: false,
        isLoadingPostCreatePrescriptionMsg: false,
        postCreatePrescriptionMsg: null,
        error: { ...state.error, postCreatePrescriptionMsgError: action.error },
      };

    case types.POST_CREATEPRESCRIPTIONMSG_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePrescriptionMsgIsSuccess: true,
        isLoadingPostCreatePrescriptionMsg: false,
        postCreatePrescriptionMsg: action.data,
      };

    case types.PUT_UPDATEPRESCRIPTIONMSG_ASYNC.PENDING:
      return {
        ...state,
        putUpdatePrescriptionMsgIsSuccess: false,
        isLoadingPutUpdatePrescriptionMsg: true,
        putUpdatePrescriptionMsg: null,
      };

    case types.PUT_UPDATEPRESCRIPTIONMSG_ASYNC.ERROR:
      return {
        ...state,
        putUpdatePrescriptionMsgIsSuccess: false,
        isLoadingPutUpdatePrescriptionMsg: false,
        putUpdatePrescriptionMsg: null,
        error: { ...state.error, putUpdatePrescriptionMsgError: action.error },
      };

    case types.PUT_UPDATEPRESCRIPTIONMSG_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdatePrescriptionMsgIsSuccess: true,
        isLoadingPutUpdatePrescriptionMsg: false,
        putUpdatePrescriptionMsg: action.data,
      };

    default:
      return state;
  }
};
