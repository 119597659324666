import * as types from "../_constants/userCreditConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_USERCREDITLIST_ASYNC.PENDING:
      return {
        ...state,
        getUserCreditListSuccess: false,
        isLoadingUserCreditList: true,
        userCreditList: null,
      };

    case types.GET_USERCREDITLIST_ASYNC.ERROR:
      return {
        ...state,
        getUserCreditListSuccess: false,
        isLoadingUserCreditList: false,
        userCreditList: null,
        error: { ...state.error, userCreditListError: action.error },
      };

    case types.GET_USERCREDITLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserCreditListSuccess: true,
        isLoadingUserCreditList: false,
        userCreditList: action.data,
      };

    case types.POST_CREATEUSERCREDIT_ASYNC.PENDING:
      return {
        ...state,
        postCreateUserCreditSuccess: false,
        isLoadingPostCreateUserCredit: true,
        postCreateUserCredit: null,
      };

    case types.POST_CREATEUSERCREDIT_ASYNC.ERROR:
      return {
        ...state,
        postCreateUserCreditSuccess: false,
        isLoadingPostCreateUserCredit: false,
        postCreateUserCredit: null,
        error: { ...state.error, postCreateUserCreditError: action.error },
      };

    case types.POST_CREATEUSERCREDIT_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateUserCreditSuccess: true,
        isLoadingPostCreateUserCredit: false,
        postCreateUserCredit: action.data,
      };

    case types.GET_USERCREDITUSERLIST_ASYNC.PENDING:
      return {
        ...state,
        getUserCreditUserListSuccess: false,
        isLoadingUserCreditUserList: true,
        userCreditUserList: null,
      };

    case types.GET_USERCREDITUSERLIST_ASYNC.ERROR:
      return {
        ...state,
        getUserCreditUserListSuccess: false,
        isLoadingUserCreditUserList: false,
        userCreditUserList: null,
        error: { ...state.error, userCreditUserListError: action.error },
      };

    case types.GET_USERCREDITUSERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserCreditUserListSuccess: true,
        isLoadingUserCreditUserList: false,
        userCreditUserList: action.data,
      };
    case types.GET_USERCREDITBYID_ASYNC.PENDING:
      return {
        ...state,
        getUserCreditByIdIsSuccess: false,
        isLoadingUserCreditById: true,
        userCreditById: null,
      };

    case types.GET_USERCREDITBYID_ASYNC.ERROR:
      return {
        ...state,
        getUserCreditByIdIsSuccess: false,
        isLoadingUserCreditById: false,
        userCreditById: null,
        error: { ...state.error, userCreditByIdError: action.error },
      };

    case types.GET_USERCREDITBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getUserCreditByIdIsSuccess: true,
        isLoadingUserCreditById: false,
        userCreditById: action.data,
      };

    case types.GET_USERCREDITBALANCEBYID_ASYNC.PENDING:
      return {
        ...state,
        getUserCreditBalanceByIdIsSuccess: false,
        isLoadingUserCreditBalanceById: true,
        userCreditBalanceById: null,
      };

    case types.GET_USERCREDITBALANCEBYID_ASYNC.ERROR:
      return {
        ...state,
        getUserCreditBalanceByIdIsSuccess: false,
        isLoadingUserCreditBalanceById: false,
        userCreditBalanceById: null,
        error: { ...state.error, userCreditBalanceByIdError: action.error },
      };

    case types.GET_USERCREDITBALANCEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getUserCreditBalanceByIdIsSuccess: true,
        isLoadingUserCreditBalanceById: false,
        userCreditBalanceById: action.data,
      };
    default:
      return state;
  }
};
