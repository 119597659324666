//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
//Actions
import {
  getOrderFormList,
  getOrderFormById,
  putApproveOrderForm,
  putOrderFormEditMode,
} from "../../../actions/orderFormActions";

//Component
import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import { ReactComponent as ProceedLogo } from "../../../assets/coolicons/media/fast_forward.svg";
import { ReactComponent as NewLogo } from "../../../assets/coolicons/media/fast_rewind.svg";
import * as pageConfigure from "../../../_constants/pageConstants";
import * as generalConstants from "../../../_constants/generalConstants";
import * as pageConstants from "../../../_constants/pageConstants";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ProcessOrderFormPage from "./ProcessOrderFormPage";

export default function OrderFormListTable({
  setInformationView,
  viewInfo,
  setViewInfo,
  editView,
  setEditView,
  detailView,
  setDetailView,
  copyMode,
  setCopyMode,
}) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_ORDER_FORM_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const approvalPermission = curUser.allRightAccess
    ? curUser.allRightAccess.user_add_permissions.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_ORDER_FORM_RIGHT_TYPE &&
          item.add_permission_type ===
            generalConstants.APPROVAL_ADD_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.status_type === generalConstants.NEW_STATUS_TYPE) {
          return (
            <>
              <Button onClick={() => handleRowEdit(row.original)}>
                <EditLogo title={t("translation:Edit")} />
              </Button>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: "",
      accessor: "New",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        if (
          row.original.status_type > generalConstants.NEW_STATUS_TYPE &&
          row.original.status_type < generalConstants.WIP_STATUS_TYPE
        ) {
          return (
            <>
              <Button onClick={() => handleRowEditMode(row.original)}>
                <NewLogo title={t("translation:New")} />
              </Button>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: "",
      accessor: "Approval",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        if (
          approvalPermission &&
          row.original.status_type === generalConstants.PENDING_STATUS_TYPE
        ) {
          return (
            <>
              <Button onClick={() => handleRowApproval(row.original)}>
                <ProceedLogo title={t("translation:Approve")} />
              </Button>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: t("translation:OF Order No"),
      accessor: "order_number",
    },

    {
      Header: t("translation:Supplier"),
      accessor: "supplier_name",

      sortable: false,
    },
    {
      Header: t("translation:Creation Date"),
      sortable: false,
      accessor: (d) => {
        if (d.created_on)
          return moment.utc(d.created_on).local().format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const columnsViewOnly = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "Approval",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        if (
          approvalPermission &&
          row.original.status_type === generalConstants.PENDING_STATUS_TYPE
        ) {
          return (
            <>
              <Button onClick={() => handleRowApproval(row.original)}>
                <ProceedLogo title={t("translation:Approve")} />
              </Button>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: t("translation:OF Order No"),
      accessor: "order_number",
    },

    {
      Header: t("translation:Supplier"),
      accessor: "supplier_name",

      sortable: false,
    },
    {
      Header: t("translation:Creation Date"),
      sortable: false,
      accessor: (d) => {
        if (d.created_on)
          return moment.utc(d.created_on).local().format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_end_date_value: null,
    search_end_timestamp_value: null,
    search_order_form_id_value: null,
    search_order_form_number_value: null,
    search_start_date_value: null,
    search_start_timestamp_value: null,
    search_status_type_value: null,
  });

  const dispatch = useDispatch();

  const orderForm = useSelector((state) => state.orderForm);
  const common = useSelector((state) => state.common);
  //Init

  const [confirmApprovalOpen, setConfirmApprovalOpen] = useState(false);
  const [confirmEditModeOpen, setConfirmEditModeOpen] = useState(false);

  const [searchValue, setSearchValue] = React.useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [approvalSuccessAlarm, setApprovalSuccessAlarm] = useState(false);
  const [approvalErrorAlarm, setApprovalErrorAlarm] = useState(false);
  const [approvalMsgAlarm, setApprovalMsgAlarm] = React.useState(null);
  const [editModeSuccessAlarm, setEditModeSuccessAlarm] = useState(false);
  const [editModeErrorAlarm, setEditModeErrorAlarm] = useState(false);
  const [editModeMsgAlarm, setEditModeMsgAlarm] = React.useState(null);

  //const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  //const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  // const [submitlMsgAlarm, setSubmitMsgAlarm] = React.useState(null);

  const prevGetOrderFormByIdStatus = useRef();
  const prevPutApprovalStatus = useRef();
  const prevPutEditModeStatus = useRef();
  //const prevPostSubmitStatus = useRef();

  //Onclick event
  const handleRowEdit = (value) => {
    dispatch(getOrderFormById.pending(value.id));
    setEditView(true);
    setViewInfo(null);
  };

  const handleRowApproval = (value) => {
    setConfirmApprovalOpen(true);
    setSelectedValue(value);
    setViewInfo(null);
  };

  const handleConfirmApproval = () => {
    const jsonText = {
      order_form_header: { id: selectedValue.id },
    };

    dispatch(putApproveOrderForm.pending(jsonText));
  };

  const handleRowEditMode = (value) => {
    setConfirmEditModeOpen(true);
    setSelectedValue(value);
    setViewInfo(null);
  };

  const handleConfirmEditMode = () => {
    const jsonText = {
      order_form_header: { id: selectedValue.id },
    };

    dispatch(putOrderFormEditMode.pending(jsonText));
  };

  const handleRowDetail = (value) => {
    dispatch(getOrderFormById.pending(value.id));
    setDetailView(true);
    setViewInfo(null);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_order_form_number_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_form_number_value: null,
      });
    }
  };

  //useEffects

  useEffect(() => {
    //set ref when first render
    prevGetOrderFormByIdStatus.current = false;
    prevPutApprovalStatus.current = false;
    prevPutEditModeStatus.current = false;
    //prevPostSubmitStatus.current = false;
  }, []);

  useEffect(() => {
    if (detailView === true || editView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [detailView, editView, setInformationView]);

  useEffect(() => {
    if (!detailView && !editView) {
      setApprovalSuccessAlarm(false);
      setApprovalErrorAlarm(false);
      setEditModeSuccessAlarm(false);
      setEditModeErrorAlarm(false);
    }
  }, [detailView, editView]);

  useEffect(() => {
    if (!editView && !detailView) {
      dispatch(getOrderFormList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editView, detailView]);

  useEffect(() => {
    if (
      orderForm.isLoadingGetOrderFormById !== prevGetOrderFormByIdStatus.current
    ) {
      //check the previous ref with current state
      prevGetOrderFormByIdStatus.current = orderForm.isLoadingGetOrderFormById;

      if (
        orderForm.getOrderFormByIdIsSuccess === true &&
        orderForm.isLoadingGetOrderFormById === false
      ) {
        setViewInfo(orderForm.orderFormById);
      }
      if (
        orderForm.getOrderFormByIdIsSuccess === false &&
        orderForm.isLoadingGetOrderFormById === false
      ) {
        setEditView(false);
        setDetailView(false);
        setViewInfo(null);
      }
    }
  }, [orderForm, setDetailView, setEditView, setViewInfo]);

  useEffect(() => {
    if (
      orderForm.isLoadingPutApproveOrderForm !== prevPutApprovalStatus.current
    ) {
      //check the previous ref with current state
      prevPutApprovalStatus.current = orderForm.isLoadingPutApproveOrderForm;

      if (
        orderForm.putApproveOrderFormIsSuccess === true &&
        orderForm.isLoadingPutApproveOrderForm === false
      ) {
        setApprovalSuccessAlarm(true);
        dispatch(getOrderFormList.pending(pageInfo));
        window.scrollTo(0, 0);
      }
      if (
        orderForm.putApproveOrderFormIsSuccess === false &&
        orderForm.isLoadingPutApproveOrderForm === false
      ) {
        setApprovalMsgAlarm(orderForm.error.putApproveOrderFormError);
        setApprovalErrorAlarm(true);
        window.scrollTo(0, 0);
      }
    }
  }, [orderForm, dispatch, pageInfo]);

  useEffect(() => {
    if (
      orderForm.isLoadingPutOrderFormEditMode !== prevPutEditModeStatus.current
    ) {
      //check the previous ref with current state
      prevPutEditModeStatus.current = orderForm.isLoadingPutOrderFormEditMode;

      if (
        orderForm.putOrderFormEditModeIsSuccess === true &&
        orderForm.isLoadingPutOrderFormEditMode === false
      ) {
        setEditModeSuccessAlarm(true);
        dispatch(getOrderFormList.pending(pageInfo));
        window.scrollTo(0, 0);
      }
      if (
        orderForm.putOrderFormEditModeIsSuccess === false &&
        orderForm.isLoadingPutOrderFormEditMode === false
      ) {
        setEditModeMsgAlarm(orderForm.error.putOrderFormEditModeError);
        setEditModeErrorAlarm(true);
        window.scrollTo(0, 0);
      }
    }
  }, [orderForm, dispatch, pageInfo]);

  // useEffect(() => {
  //   if (
  //     orderForm.isLoadingPostSubmitOrderForm !== prevPostSubmitStatus.current
  //   ) {
  //     //check the previous ref with current state
  //     prevPostSubmitStatus.current = orderForm.isLoadingPostSubmitOrderForm;

  //     if (
  //       orderForm.postSubmitOrderFormIsSuccess === true &&
  //       orderForm.isLoadingPostSubmitOrderForm === false
  //     ) {
  //       setSubmitSuccessAlarm(true);
  //       window.scrollTo(0, 0);
  //       if (
  //         orderForm.submitOrderForm?.order_form_header?.status_type ===
  //         generalConstants.NEW_STATUS_TYPE
  //       ) {
  //         setSubmitMsgAlarm(
  //           t("translation:Purchase Requisition has been saved")
  //         );
  //       }

  //       if (
  //         orderForm.submitOrderForm?.order_form_header?.status_type ===
  //         generalConstants.PENDING_STATUS_TYPE
  //       ) {
  //         setSubmitMsgAlarm(
  //           t("translation:Purchase Requisition has been submitted")
  //         );
  //       }
  //     }
  //     // if (
  //     //   orderForm.postSubmitOrderFormIsSuccess === false &&
  //     //   orderForm.isLoadingPostSubmitOrderForm === false
  //     // ) {
  //     //   //setApprovalMsgAlarm(orderForm.error.putApproveOrderFormError);
  //     //   //setApprovalErrorAlarm(true);
  //     // }
  //   }
  // }, [orderForm, dispatch, t]);

  if (
    orderForm.getOrderFormListIsSuccess === false &&
    orderForm.isLoadingGetOrderFormList === false
  ) {
    return (
      <Alert severity="error">{orderForm.error.getOrderFormListError}</Alert>
    );
  } else if (
    common.getGeneralStatusIsSuccess === true &&
    !editView &&
    !detailView
  ) {
    return (
      <>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmApprovalOpen}
          setOpen={setConfirmApprovalOpen}
          onConfirm={handleConfirmApproval}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        <Collapse in={approvalSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setApprovalSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.APPROVED_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={approvalErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setApprovalErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {approvalMsgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={editModeSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setEditModeSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Purchase Requisition status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.NEW_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={editModeErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setEditModeErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {editModeMsgAlarm}
          </Alert>
        </Collapse>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmEditModeOpen}
          setOpen={setConfirmEditModeOpen}
          onConfirm={handleConfirmEditMode}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <SortingSearchTable
          tableHeading={""}
          searchPlaceholder={t("translation:OF Order No")}
          statusPlaceholder={t("translation:Order Status")}
          statusOption={common.generalStatusList.status_general}
          searchValue={searchValue}
          columns={updateAccess ? columns : columnsViewOnly}
          data={
            orderForm.orderFormList?.order_form_headers !== undefined
              ? orderForm.orderFormList?.order_form_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            orderForm.orderFormList ? orderForm.orderFormList.total_pages : 0
          }
          loading={orderForm.isLoadingGetOrderFormList}
          totalItems={
            orderForm.orderFormList ? orderForm.orderFormList.total_items : 0
          }
          handleSearch={handleSearch}
          banStatusIdList={[pageConstants.DETELED_GENERAL_STATUS]}
        />
      </>
    );
  } else if (viewInfo && detailView) {
    return (
      <>
        <ProcessOrderFormPage
          infoData={viewInfo}
          viewMode={true}
          setView={() => setDetailView(false)}
          copyMode={copyMode}
          setCopyMode={setCopyMode}
        />
      </>
    );
  } else if (viewInfo && editView) {
    return (
      <ProcessOrderFormPage
        setView={() => setEditView(false)}
        infoData={viewInfo}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
