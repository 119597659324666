import { createAsyncTypes } from "../_helper/Utilities";

export const GET_BRANCHPHYSICIANADHOCSCHEDULELIST_ASYNC = createAsyncTypes(
  "GET_BRANCHPHYSICIANADHOCSCHEDULELIST"
);

export const GET_BRANCHPHYSICIANADHOCSCHEDULEBYID_ASYNC = createAsyncTypes(
  "GET_BRANCHPHYSICIANADHOCSCHEDULEBYID"
);

export const POST_BRANCHPHYSICIANADHOCSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC =
  createAsyncTypes("POST_BRANCHPHYSICIANADHOCSCHEDULEBYBRANCHIDPHYSICIANID");

export const GET_BRANCHPHYSICIANADHOCSCHEDULEBRANCHLIST_ASYNC =
  createAsyncTypes("GET_BRANCHPHYSICIANADHOCSCHEDULEBRANCHLIST");

export const GET_BRANCHPHYSICIANADHOCSCHEDULEPHYSICIANLIST_ASYNC =
  createAsyncTypes("GET_BRANCHPHYSICIANADHOCSCHEDULEPHYSICIANLIST");
