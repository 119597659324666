import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

import { useStyles } from "../../../../components/globalStyles";

export default function AmountTotal({
  amountHeader,
  setAmountHeader,
  paidAmount,
  editAble = false,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <form>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={!editAble}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={
              !editAble
                ? Number(amountHeader.discount_in_percent).toFixed(2)
                : amountHeader.discount_in_percent !== null
                ? amountHeader.discount_in_percent
                : ""
            }
            onChange={(e, option) => {
              if (e.target.value <= 100) {
                setAmountHeader((p) => ({
                  ...p,
                  discount_in_percent: Number(e.target.value),
                }));
              }
            }}
            name="discount_in_percent"
            label={t("translation:Discount %")}
            variant="outlined"
          />
        </Grid>
        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={!editAble}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={
              !editAble
                ? Number(amountHeader.amt_wo_gst).toFixed(2)
                : amountHeader.amt_wo_gst !== null
                ? amountHeader.amt_wo_gst
                : ""
            }
            onChange={(e, option) => {
              setAmountHeader((p) => ({
                ...p,
                amt_wo_gst: Number(e.target.value),
              }));
            }}
            id="amt_wo_gst"
            name="amt_wo_gst"
            label={t("translation:Amount Before Tax")}
            variant="outlined"
          />
        </Grid>
        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={!editAble}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={
              !editAble
                ? Number(amountHeader.gst_amt).toFixed(2)
                : amountHeader.gst_amt !== null
                ? amountHeader.gst_amt
                : ""
            }
            onChange={(e, option) => {
              setAmountHeader((p) => ({
                ...p,
                gst_amt: Number(e.target.value),
              }));
            }}
            id="tax_amt"
            name="tax_amt"
            label={
              t("translation:Tax Amount") + " (" + amountHeader.gst_value + "%)"
            }
            variant="outlined"
          />
        </Grid>
        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={!editAble}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={
              !editAble
                ? Number(amountHeader.amt_after_discount).toFixed(2)
                : amountHeader.amt_after_discount !== null
                ? amountHeader.amt_after_discount
                : ""
            }
            onChange={(e, option) => {
              setAmountHeader((p) => ({
                ...p,
                amt_after_discount: Number(e.target.value),
              }));
            }}
            name="amt_after_discount"
            label={t("translation:Amount After Discount")}
            variant="outlined"
          />
        </Grid>
        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={!editAble}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={
              !editAble
                ? amountHeader.amt_rounding
                  ? amountHeader.amt_rounding.toFixed(2)
                  : (0).toFixed(2)
                : amountHeader.amt_rounding !== null
                ? amountHeader.amt_rounding
                : ""
            }
            onChange={(e, option) => {
              setAmountHeader((p) => ({
                ...p,
                amt_rounding: Number(e.target.value),
              }));
            }}
            id="roundAmt"
            name="roundAmt"
            label={t("translation:Amount Rounding")}
            variant="outlined"
          />
        </Grid>
        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={!editAble}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={
              !editAble
                ? Number(amountHeader.amt_with_gst).toFixed(2)
                : amountHeader.amt_with_gst !== null
                ? amountHeader.amt_with_gst
                : ""
            }
            onChange={(e, option) => {
              setAmountHeader((p) => ({
                ...p,
                amt_with_gst: Number(e.target.value),
              }));
            }}
            id="amt_afk_tax"
            name="amt_afk_tax"
            label={t("translation:Amount After Tax")}
            variant="outlined"
          />
        </Grid>
        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={true}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={paidAmount.toFixed(2)}
            id="paidAmt"
            name="paidAmt"
            label={t("translation:Paid Amount")}
            variant="outlined"
          />
        </Grid>

        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={true}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={Number(
              Number(amountHeader.amt_with_gst.toFixed(2)) -
                Number(paidAmount.toFixed(2))
            ).toFixed(2)}
            id="paidBalance"
            name="paidBalance"
            label={t("translation:Balance")}
            variant="outlined"
          />
        </Grid>

        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={!editAble}
            value={
              amountHeader.referral_code !== null
                ? amountHeader.referral_code
                : ""
            }
            onChange={(e, option) => {
              setAmountHeader((p) => ({
                ...p,
                referral_code: e.target.value,
              }));
            }}
            id="referral_code"
            name="referral_code"
            label={t("translation:Referral Code")}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </form>
  );
}
