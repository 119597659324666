import * as types from "../_constants/deliveryCutOffConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_DELIVERYCUTOFFSCHEDULELIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryCutoffScheduleListIsSuccess: false,
        isLoadingGetDeliveryCutoffScheduleList: true,
        getDeliveryCutoffScheduleList: null,
      };

    case types.GET_DELIVERYCUTOFFSCHEDULELIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryCutoffScheduleListIsSuccess: false,
        isLoadingGetDeliveryCutoffScheduleList: false,
        getDeliveryCutoffScheduleList: null,
        error: {
          ...state.error,
          getDeliveryCutoffScheduleListError: action.error,
        },
      };

    case types.GET_DELIVERYCUTOFFSCHEDULELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryCutoffScheduleListIsSuccess: true,
        isLoadingGetDeliveryCutoffScheduleList: false,
        getDeliveryCutoffScheduleList: action.data,
      };

    case types.GET_DELIVERYCUTOFFSCHEDULEBYID_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryCutoffScheduleByIdIsSuccess: false,
        isLoadingGetDeliveryCutoffScheduleById: true,
        getDeliveryCutoffScheduleById: null,
      };

    case types.GET_DELIVERYCUTOFFSCHEDULEBYID_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryCutoffScheduleByIdIsSuccess: false,
        isLoadingGetDeliveryCutoffScheduleById: false,
        getDeliveryCutoffScheduleById: null,
        error: {
          ...state.error,
          getDeliveryCutoffScheduleByIdError: action.error,
        },
      };

    case types.GET_DELIVERYCUTOFFSCHEDULEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryCutoffScheduleByIdIsSuccess: true,
        isLoadingGetDeliveryCutoffScheduleById: false,
        getDeliveryCutoffScheduleById: action.data,
      };
    case types.PUT_UPDATEDELIVERYCUTOFFSCHEDULE_ASYNC.PENDING:
      return {
        ...state,
        putUpdateDeliveryCutoffScheduleIsSuccess: false,
        isLoadingPutUpdateDeliveryCutoffSchedule: true,
        putUpdateDeliveryCutoffSchedule: null,
      };

    case types.PUT_UPDATEDELIVERYCUTOFFSCHEDULE_ASYNC.ERROR:
      return {
        ...state,
        putUpdateDeliveryCutoffScheduleIsSuccess: false,
        isLoadingPutUpdateDeliveryCutoffSchedule: false,
        putUpdateDeliveryCutoffSchedule: null,
        error: {
          ...state.error,
          putUpdateDeliveryCutoffScheduleError: action.error,
        },
      };

    case types.PUT_UPDATEDELIVERYCUTOFFSCHEDULE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateDeliveryCutoffScheduleIsSuccess: true,
        isLoadingPutUpdateDeliveryCutoffSchedule: false,
        putUpdateDeliveryCutoffSchedule: action.data,
      };

    case types.POST_SEARCHDELIVERYCUTOFFSCHEDULEBRANCH_ASYNC.PENDING:
      return {
        ...state,
        postSearchDeliveryCutoffScheduleBranchIsSuccess: false,
        isLoadingPostSearchDeliveryCutoffScheduleBranch: true,
        postSearchDeliveryCutoffScheduleBranch: null,
      };

    case types.POST_SEARCHDELIVERYCUTOFFSCHEDULEBRANCH_ASYNC.ERROR:
      return {
        ...state,
        postSearchDeliveryCutoffScheduleBranchIsSuccess: false,
        isLoadingPostSearchDeliveryCutoffScheduleBranch: false,
        postSearchDeliveryCutoffScheduleBranch: null,
        error: {
          ...state.error,
          postSearchDeliveryCutoffScheduleBranchError: action.error,
        },
      };

    case types.POST_SEARCHDELIVERYCUTOFFSCHEDULEBRANCH_ASYNC.SUCCESS:
      return {
        ...state,
        postSearchDeliveryCutoffScheduleBranchIsSuccess: true,
        isLoadingPostSearchDeliveryCutoffScheduleBranch: false,
        postSearchDeliveryCutoffScheduleBranch: action.data,
      };

    default:
      return state;
  }
};
