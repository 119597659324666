import userSagas from "./userSagas";
import roleSagas from "./roleSagas";
import roleRightSagas from "./roleRightSagas";
import userRoleSagas from "./userRoleSagas";
import authSagas from "./authSagas";
import commonSagas from "./commonSagas";
import curUserSagas from "./curUserSagas";
import workOrderSagas from "./workOrderSagas";
import dispensarySagas from "./dispensarySagas";
import brewingSagas from "./brewingSagas";
import visitOrderSagas from "./visitOrderSagas";
import deliverySagas from "./deliverySagas";
import deliveryOrderSagas from "./deliveryOrderSagas";
import medicineTypeSagas from "./medicineTypeSagas";

import appointmentSagas from "./appointmentSagas";
import dashboardSagas from "./dashboardSagas";
import branchAdhocScheduleSagas from "./branchAdhocScheduleSagas";
import branchPhysicianAdhocScheduleSagas from "./branchPhysicianAdhocScheduleSagas";
import branchPhysicianScheduleSagas from "./branchPhysicianScheduleSagas";
import branchScheduleSagas from "./branchScheduleSagas";

import invoiceSagas from "./invoiceSagas";
import invoiceMedicineCostingSagas from "./invoiceMedicineCostingSagas";

import userCreditSagas from "./userCreditSagas";

import orderFormSagas from "./orderFormSagas";
import purchaseOrderSagas from "./purchaseOrderSagas";
import branchMedicineSagas from "./branchMedicineSagas";
import homecareSagas from "./homecareSagas";
import reportingSagas from "./reportingSagas";

import prescriptionMsgSagas from "./prescriptionMsgSagas";

import systemScheduleSagas from "./systemScheduleSagas";

import medicineSagas from "./medicineSagas";

import acupointSagas from "./acupointSagas";

import treatmentSagas from "./treatmentSagas";

import treatmentTypeSagas from "./treatmentTypeSagas";

import cloudStorageSagas from "./cloudStorageSagas";

import organisationPhysicianSagas from "./organisationPhysicianSagas";

import formulaSagas from "./formulaSagas";

import formulaMedicineSagas from "./formulaMedicineSagas";

import userMfaSagas from "./userMfaSagas";

import postalCodeSurchargeSagas from "./postalCodeSurchargeSagas";

import notificationSagas from "./notificationSagas";

import notificationGroupSagas from "./notificationGroupSagas";

import filterRuleSagas from "./filterRuleSagas";

import emailStoreAccountSagas from "./emailStoreAccountSagas";

import emailStoreSenderSagas from "./emailStoreSenderSagas";

import emailStoreRecipientSagas from "./emailStoreRecipientSagas";

import emailStoreSagas from "./emailStoreSagas";

import medicineFixedSellingPriceSagas from "./medicineFixedSellingPriceSagas";

import medicineFixedSellingPriceDetailsSagas from "./medicineFixedSellingPriceDetailsSagas";

import userMedicineFixedSellingPriceSagas from "./userMedicineFixedSellingPriceSagas";

import wikiDataSagas from "./wikiDataSagas";

import fulfillmentSagas from "./fulfillmentSagas";

import packingSagas from "./packingSagas";

import nonBusinessDaySagas from "./nonBusinessDaySagas";

import externalApiSagas from "./externalApiSagas";

import deliveryCutoffScheduleSagas from "./deliveryCutoffScheduleSagas";

import deliveryTrackingSagas from "./deliveryTrackingSagas";

import courierSagas from "./courierSagas";

import branchCourierSagas from "./branchCourierSagas";

import userDeviceKeySagas from "./userDeviceKeySagas";

const sagas = [
  userSagas,
  authSagas,
  commonSagas,
  userRoleSagas,
  roleSagas,
  roleRightSagas,
  curUserSagas,
  workOrderSagas,
  dispensarySagas,
  brewingSagas,
  visitOrderSagas,
  deliverySagas,
  deliveryOrderSagas,
  medicineTypeSagas,
  appointmentSagas,
  dashboardSagas,
  branchAdhocScheduleSagas,
  branchPhysicianAdhocScheduleSagas,
  branchPhysicianScheduleSagas,
  branchScheduleSagas,
  invoiceSagas,
  invoiceMedicineCostingSagas,
  userCreditSagas,
  orderFormSagas,
  purchaseOrderSagas,
  branchMedicineSagas,
  homecareSagas,
  reportingSagas,
  prescriptionMsgSagas,
  systemScheduleSagas,
  medicineSagas,
  acupointSagas,
  treatmentSagas,
  treatmentTypeSagas,
  cloudStorageSagas,
  organisationPhysicianSagas,
  formulaSagas,
  formulaMedicineSagas,
  userMfaSagas,
  postalCodeSurchargeSagas,
  notificationSagas,
  notificationGroupSagas,
  filterRuleSagas,
  emailStoreAccountSagas,
  emailStoreSenderSagas,
  emailStoreRecipientSagas,
  emailStoreSagas,
  medicineFixedSellingPriceSagas,
  medicineFixedSellingPriceDetailsSagas,
  userMedicineFixedSellingPriceSagas,
  wikiDataSagas,
  fulfillmentSagas,
  packingSagas,
  nonBusinessDaySagas,
  externalApiSagas,
  deliveryCutoffScheduleSagas,
  deliveryTrackingSagas,
  courierSagas,
  branchCourierSagas,
  userDeviceKeySagas,
];

export const initSagas = (sagaMiddleware) =>
  sagas.forEach(sagaMiddleware.run.bind(sagaMiddleware));
