import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getUserDeviceKeyList,
  getUserDeviceKeyById,
  postCreateUserDeviceKey,
  putUpdateUserDeviceKey,
} from "../actions/userDeviceKeyActions";
import * as types from "../_constants/userDeviceKeyConstants";
import * as notification from "../_constants/notificationConstants";
import userDeviceKeyApis from "../_apis/userDeviceKeyApis";
export default function* userdevicekeySagas() {
  yield all([
    takeLatest(
      types.GET_USERDEVICEKEYLIST_ASYNC.PENDING,
      getUserDeviceKeyListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_USERDEVICEKEYBYID_ASYNC.PENDING,
      getUserDeviceKeyByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATEUSERDEVICEKEY_ASYNC.PENDING,
      postCreateUserDeviceKeyCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEUSERDEVICEKEY_ASYNC.PENDING,
      putUpdateUserDeviceKeyCall
    ),
  ]);
}

function* getUserDeviceKeyListCall(searchModel) {
  try {
    const data = yield call(
      userDeviceKeyApis.getUserDeviceKeyListApi,
      searchModel.data
    );
    yield put(getUserDeviceKeyList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserDeviceKeyList.error(error.response?.data?.message));
    else yield put(getUserDeviceKeyList.error(notification.CONST_NOINTERNET));
  }
}

function* getUserDeviceKeyByIdCall(searchModel) {
  try {
    const data = yield call(
      userDeviceKeyApis.getUserDeviceKeyByIdApi,
      searchModel.data
    );
    yield put(getUserDeviceKeyById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserDeviceKeyById.error(error.response?.data?.message));
    else yield put(getUserDeviceKeyById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateUserDeviceKeyCall(searchModel) {
  try {
    const data = yield call(
      userDeviceKeyApis.postCreateUserDeviceKeyApi,
      searchModel.data
    );
    yield put(postCreateUserDeviceKey.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateUserDeviceKey.error(error.response?.data?.message));
    else
      yield put(postCreateUserDeviceKey.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateUserDeviceKeyCall(searchModel) {
  try {
    const data = yield call(
      userDeviceKeyApis.putUpdateUserDeviceKeyApi,
      searchModel.data
    );
    yield put(putUpdateUserDeviceKey.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateUserDeviceKey.error(error.response?.data?.message));
    else yield put(putUpdateUserDeviceKey.error(notification.CONST_NOINTERNET));
  }
}
