import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const userApis = {
  getUserListApi,
  getUserByIdApi,
  getAllUserListApi,
  getRefreshTokenApi,
  putEditUserApi,
  postCreateUserApi,
  putPhysicianLicenseNoByIdApi,
  postFailedLoginCountApi,
  getLoginAttemptMaxApi,
};

async function getUserListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(apiConstants.apiGetUserList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getUserByIdApi(model) {
  const url = apiConstants.apiGetUserById + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllUserListApi() {
  return await axios
    .get(apiConstants.apiGetAllUserList, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getRefreshTokenApi() {
  return await axios
    .get(apiConstants.apiGetRefreshToken)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putEditUserApi(userModel) {
  const url = apiConstants.apiPutEditUserById + userModel.userId;

  const requestOptions = JSON.stringify(userModel);
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putPhysicianLicenseNoByIdApi(userModel) {
  const url = apiConstants.apiPutPhysicianLicenseNoById + userModel.id;

  const requestOptions = JSON.stringify(userModel);
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreateUserApi(userModel) {
  const requestOptions = JSON.stringify(userModel);
  return await axios
    .post(apiConstants.apiPostCreateUser, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postFailedLoginCountApi(userModel) {
  const requestOptions = JSON.stringify(userModel);
  return await axios
    .put(apiConstants.apiPostFailedLoginCount, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getLoginAttemptMaxApi() {
  return await axios
    .get(apiConstants.apiGetLoginAttemptMax)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

/*
function getUserList() {
    const requestOptions = {
       
        method: "GET"
    };

    return fetch(apiConstants.apiDomain + apiConstants.apiGetUserList,requestOptions)
    .then((response) => handleResponse(response, requestOptions, apiConstants.apiDomain + apiConstants.apiGetUserList))
    .then(data => {
        return data;
    });


*/

// async function postRegistrationApi(registrationModel) {
//     const requestOptions = JSON.stringify(registrationModel);
//     const options = {
//         headers: {'Content-Type': 'application/json','X-CSRF-Token': csrf_token_value, 'Referer': apiConstants.apiDomain + apiConstants.apiRegistration}
//       };
//      return await axios.post(apiConstants.apiDomain + apiConstants.apiRegistration,requestOptions,options).then((response) => {
//         const allRepos = response.data;
//          return allRepos;

//       }).catch(error => {
//         return Promise.reject(error);
//     });
// }

export default userApis;
