import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const medicineApis = {
  getMedicineListApi,
  getMedicineByIdApi,
  postCreateMedicineApi,
  putUpdateMedicineApi,
  getMedicineMedicineTypesApi,
};

async function getMedicineListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(apiConstants.apiGetMedicineList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineByIdApi(model) {
  const url = apiConstants.apiGetMedicineById + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreateMedicineApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiPostCreateMedicine, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateMedicineApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(apiConstants.apiPutUpdateMedicine, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineMedicineTypesApi(model) {
  const url = apiConstants.apiGetMedicineMedicineTypes;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default medicineApis;
