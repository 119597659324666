import React, { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Grid, RadioGroup, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import "moment/locale/zh-cn";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import esLocale from "@fullcalendar/core/locales/es-us";
import cnLocale from "@fullcalendar/core/locales/zh-cn";
import WarningrIcon from "@mui/icons-material/WarningRounded";

//components
import Panel from "../../../components/Panel";
import ConfirmDialog from "../../../components/ConfirmDialog";
import TimeSlotDialog from "../AppointmentSummary/components/TimeSlotDialog";
import PatientField from "../AppointmentSummary/components/PatientField";
import SearchExistingPatient from "./components/SearchExistingPatient";
import SearchTemporaryPatient from "./components/SearchTemporaryPatient";
import StyledRadioButton from "../../../components/StyledRadioButton";
import PageCustomAction from "../../../components/PageCustomAction";

import RequiredNote from "../../../components/RequiredNote";
import { useStyles } from "../../../components/globalStyles";
//actions
import {
  getAppointmentList,
  getAppointmentTimeslotList,
  postSubmitAppointment,
  getAppointmentBranch,
  getAppointmentBranchPhysician,
} from "../../../actions/appointmentActions";

import * as generalConstants from "../../../_constants/generalConstants";
//variable
const CALENDAR_DATE_FORMAT = "dddd, Do MMMM YYYY";
const CALENDAR_TIME_FORMAT = "HH:mm:ss";
const DISPAY_TIME_FORMAT = "HH:mm";
const API_DATE_FORMAT = "YYYY-MM-DD";
const CLOSED_ALL_DAY = "ClosedAllDay";

const AppointmentSummaryPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const calendarRef = React.createRef();

  const curUser = useSelector((state) => state.curUser);

  const appointment = useSelector((state) => state.appointment);
  const userAuth = useSelector((state) => state.userAuth);

  //calender
  const [currentLocate, setCurrentLocate] = useState("en");
  const [calendarMonth, setCalendarMonth] = useState(moment().month() + 1); //e.g. January=0, February=1, etc.
  const [calendarYear, setCalendarYear] = useState(moment().year());
  const [calendarStartTime, setCalendarStartTime] = useState("00:00:00");
  const [calendarEndTime, setCalendarEndTime] = useState("23:00:00");
  const [slotDuration, setSlotDuration] = useState("00:30:00");

  // Appointment
  const [physician, setPhysician] = useState(null);
  const [branch, setBranch] = useState(null);
  const [appointmentOrderNumber, setAppointmentOrderNumber] = useState(null);
  const [appointmentId, setAppointmentId] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isNewAppointment, setIsNewAppointment] = useState(true);
  const [branchPhysicianAppointmentList, setBranchPhysicianAppointmentList] =
    useState([]);

  //timeslot
  const [monthSchedule, setMonthSchedule] = useState([]);
  const [showTimeSlotDialog, setShowTimeSlotDialog] = useState(false);

  //Patient
  const [patientId, setPatientId] = useState(null);
  const [patientName, setPatientName] = useState(null);
  const [patientNric, setPatientNric] = useState(null);
  const [patientMobileNumber, setPatientMobileNumber] = useState(null);
  const [patientApptDescription, setPatientApptDescription] = useState(null);
  const [appointmentMobileNumber, setAppointmentMobileNumber] = useState(null);
  const [isTempPatient, setIsTempPatient] = useState(false);

  const [isNewTempPatient, setIsNewTempPatient] = useState(false);

  //other
  const prevSubmissionAppointmentStatus = useRef();
  const prevGetOrganisationBranchStatus = useRef();
  const prevGetAppointmentPhysiciansStatus = useRef();
  const [isClosed, setIsClosed] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showSuccessAlertMsg, setShowSuccessAlertMsg] = useState(null);
  const [confirmBookOpen, setConfirmBookOpen] = useState(false);
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [confirmUpdateOpen, setConfirmUpdateOpen] = useState(false);

  //function
  const handleSelectPatientRadioBtn = () => {
    setIsTempPatient(!isTempPatient);
    setIsNewTempPatient(!isTempPatient);

    resetPatientInfoField();
  };

  const handleBookAppointmentOnConfirm = (event) => {
    event.currentTarget.form.reportValidity();
    var checkVal = event.currentTarget.form.checkValidity();
    const dateOnly = moment(selectedDate, CALENDAR_DATE_FORMAT).format(
      API_DATE_FORMAT
    );

    const validDate = moment(dateOnly, "YYYY-MM-DD").isValid();
    const validStartTime = moment(selectedStartTime, "HH:mm:ss").isValid();
    const validEndTime = moment(selectedEndTime, "HH:mm:ss").isValid();

    if (validDate && validStartTime && validEndTime) {
      if (checkVal) {
        setConfirmBookOpen(true);
      }
    } else {
      setShowErrorAlertMsg(t("translation:Please Select Date & Time"));
      setShowErrorAlert(true);
    }
  };

  const bookAppointmentOnSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (isTempPatient) {
      const dateOnly = moment(selectedDate, CALENDAR_DATE_FORMAT).format(
        API_DATE_FORMAT
      );

      const appointmentJSON = {
        appointment: {
          branch: {
            id: branch.branch.id,
          },
          desc: patientApptDescription,
          full_date: dateOnly,
          id: 0,
          physician: {
            id: physician.user.id,
          },
          primary_contact_no: appointmentMobileNumber,
          staff: null,
          temp_user: {
            id: isNewTempPatient ? 0 : patientId,
            name: patientName,
            contact_number: patientMobileNumber,
            identification_number: patientNric,
          },
          start_time: selectedStartTime,
          end_time: selectedEndTime,
          status_type: generalConstants.PENDING_STATUS_TYPE,
        },
      };

      dispatch(postSubmitAppointment.pending(appointmentJSON));
    } else {
      const dateOnly = moment(selectedDate, CALENDAR_DATE_FORMAT).format(
        API_DATE_FORMAT
      );

      const appointmentJSON = {
        appointment: {
          branch: {
            id: branch.branch.id,
          },
          desc: patientApptDescription,
          full_date: dateOnly,
          id: 0,
          physician: {
            id: physician.user.id,
          },
          primary_contact_no: appointmentMobileNumber,
          staff: {
            id: patientId,
          },
          temp_user: null,
          start_time: selectedStartTime,
          end_time: selectedEndTime,
          status_type: generalConstants.PENDING_STATUS_TYPE,
        },
      };

      dispatch(postSubmitAppointment.pending(appointmentJSON));
    }
    setShowSuccessAlertMsg(t("translation:Appointment has been booked"));
  };
  const cancelAppointmentOnSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const dateOnly = moment(selectedDate, CALENDAR_DATE_FORMAT).format(
      API_DATE_FORMAT
    );
    if (isTempPatient) {
      const appointmentJSON = {
        appointment: {
          branch: {
            id: branch.branch.id,
          },
          desc: patientApptDescription,
          full_date: dateOnly,
          id: appointmentId,
          order_number: appointmentOrderNumber,
          physician: {
            id: physician.user.id,
          },
          primary_contact_no: appointmentMobileNumber,
          staff: null,
          temp_user: {
            id: patientId,
            name: patientName,
            contact_number: patientMobileNumber,
            identification_number: patientNric,
          },
          start_time: selectedStartTime,
          end_time: selectedEndTime,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        },
      };

      dispatch(postSubmitAppointment.pending(appointmentJSON));
    } else {
      const appointmentJSON = {
        appointment: {
          branch: {
            id: branch.branch.id,
          },
          desc: patientApptDescription,
          full_date: dateOnly,
          id: appointmentId,
          order_number: appointmentOrderNumber,
          physician: {
            id: physician.user.id,
          },
          primary_contact_no: appointmentMobileNumber,
          staff: {
            id: patientId,
          },
          temp_user: null,
          start_time: selectedStartTime,
          end_time: selectedEndTime,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        },
      };

      dispatch(postSubmitAppointment.pending(appointmentJSON));
    }
    setShowSuccessAlertMsg(t("translation:Appointment has been cancenlled"));
  };

  const updateAppointmentOnSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const dateOnly = moment(selectedDate, CALENDAR_DATE_FORMAT).format(
      API_DATE_FORMAT
    );
    if (isTempPatient) {
      const appointmentJSON = {
        appointment: {
          branch: {
            id: branch.branch.id,
          },
          desc: patientApptDescription,
          full_date: dateOnly,
          id: appointmentId,
          order_number: appointmentOrderNumber,
          physician: {
            id: physician.user.id,
          },
          primary_contact_no: appointmentMobileNumber,
          staff: null,
          temp_user: {
            id: patientId,
            name: patientName,
            contact_number: patientMobileNumber,
            identification_number: patientNric,
          },
          start_time: selectedStartTime,
          end_time: selectedEndTime,
          status_type: generalConstants.PENDING_STATUS_TYPE,
        },
      };

      dispatch(postSubmitAppointment.pending(appointmentJSON));
    } else {
      const appointmentJSON = {
        appointment: {
          branch: {
            id: branch.branch.id,
          },
          desc: patientApptDescription,
          full_date: dateOnly,
          id: appointmentId,
          order_number: appointmentOrderNumber,
          physician: {
            id: physician.user.id,
          },
          primary_contact_no: appointmentMobileNumber,
          staff: {
            id: patientId,
          },
          temp_user: null,
          start_time: selectedStartTime,
          end_time: selectedEndTime,
          status_type: generalConstants.PENDING_STATUS_TYPE,
        },
      };

      dispatch(postSubmitAppointment.pending(appointmentJSON));
    }

    setShowSuccessAlertMsg(t("translation:Appointment has been updated"));
  };

  const setPatientInfoField = ({
    staff,
    temp_user,
    primary_contact_no,
    desc,
  }) => {
    if (temp_user) {
      setIsTempPatient(true);
      setPatientId(temp_user.id);
      setPatientName(temp_user.name);
      setPatientNric(temp_user.identification_number);
      setPatientMobileNumber(temp_user.contact_number);
      setAppointmentMobileNumber(primary_contact_no);
      setPatientApptDescription(desc);
    } else {
      setIsTempPatient(false);
      setPatientId(staff.id);
      setPatientName(staff.name);
      setPatientNric(staff.identification_number);
      setPatientMobileNumber(staff.contact_number);
      setAppointmentMobileNumber(primary_contact_no);
      setPatientApptDescription(desc);
    }
  };

  const resetPatientInfoField = () => {
    setPatientName(null);
    setPatientNric(null);
    setPatientMobileNumber(null);
    setAppointmentMobileNumber(null);
    setPatientApptDescription(null);
  };

  const handleSelectedTimeSlot = (selectedTime) => {
    if (selectedTime) {
      const endTime = moment(selectedTime, "HH:mm:ss")
        .add(slotDuration, "minutes")
        .format("HH:mm:00");

      setSelectedStartTime(selectedTime);
      setSelectedEndTime(endTime);
    } else {
      setSelectedStartTime(null);
      setSelectedEndTime(null);
    }
  };

  const updateCalendarMonthYear = (calendarDate) => {
    const selectedMonth = moment(calendarDate).format("M");
    const selectedYear = moment(calendarDate).format("YYYY");
    if (parseInt(selectedMonth) !== calendarMonth) {
      setCalendarMonth(selectedMonth);
    }
    if (parseInt(selectedYear) !== calendarYear) {
      setCalendarYear(selectedYear);
    }
  };

  const handleDateOnClick = (info) => {
    // return, if selected date is before current date
    if (moment(info.dateStr).isBefore(moment(), "day")) {
      return;
    }

    setSelectedDate(formattedDate(info.dateStr));

    //if click from month view, get timeslot and show timeslot dialog
    // else, click from week & day view, set the selected time directly
    if (info.allDay) {
      //update calendarMonth/calendarYear with the selected Month/Year - used to keep track of which month/year the calendar is displaying
      updateCalendarMonthYear(info.dateStr);
      setShowTimeSlotDialog(true);
      setSelectedStartTime(null);
      setSelectedEndTime(null);
    }

    //resetPatientInfoField();

    setIsNewAppointment(true);
    setIsClosed(false);
  };

  const handleEventOnClick = ({ event }) => {
    if (event.groupId === CLOSED_ALL_DAY) {
      setIsClosed(true);
      return;
    }

    setPatientInfoField(event.extendedProps);
    setAppointmentId(event.extendedProps.id);
    setAppointmentOrderNumber(event.extendedProps.order_number);
    const eventDate = formattedDate(event.start);
    const eventStartTime = formattedTime(event.start);
    const eventEndTime = formattedTime(event.end);

    setSelectedDate(eventDate);
    setSelectedStartTime(eventStartTime);
    setSelectedEndTime(eventEndTime);

    setIsNewAppointment(false);
    setIsClosed(false);
  };

  const clearAppointmentsState = () => {
    resetDateTime();
    setIsTempPatient(false);
    setIsNewAppointment(true);
    setAppointmentMobileNumber(null);
    setPatientApptDescription(null);
  };

  const resetDateTime = () => {
    setSelectedDate(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
  };

  const handleUpdateApptDateTime = (date) => {
    resetDateTime();
    setSelectedDate(formattedDate(date));
    updateCalendarMonthYear(date);
    calendarRef.current.getApi().gotoDate(moment(date).format(API_DATE_FORMAT));
    setShowTimeSlotDialog(true);
  };

  const formattedDate = (date) => {
    return moment(date).format(CALENDAR_DATE_FORMAT);
  };

  const formattedTime = (time) => {
    return moment(time).format(CALENDAR_TIME_FORMAT);
  };

  //useEffects
  useEffect(() => {
    prevSubmissionAppointmentStatus.current = false;
    prevGetOrganisationBranchStatus.current = false;
    prevGetAppointmentPhysiciansStatus.current = false;
  }, []);
  useEffect(() => {
    // if (i18n.language === "zh-cn") {
    //   setCurrentLocate("zh-cn");
    // } else {
    //   setCurrentLocate("en");
    // }

    setCurrentLocate("en");
  }, [i18n.language]);

  useEffect(() => {
    if (!isTempPatient) {
      if (userAuth.systemUserInfo) {
        setPatientId(userAuth.systemUserInfo.id);
        setPatientName(userAuth.systemUserInfo.name);
        setPatientNric(userAuth.systemUserInfo.identification_number);
        setPatientMobileNumber(userAuth.systemUserInfo.contact_number);
      }
    }
  }, [isTempPatient, userAuth]);

  useEffect(() => {
    if (isDefault) {
      if (
        appointment.isLoadingGetAppointmentBranch !==
        prevGetOrganisationBranchStatus.current
      ) {
        //check the previous ref with current state
        prevGetOrganisationBranchStatus.current =
          appointment.isLoadingGetAppointmentBranch;
        if (
          appointment.getAppointmentBranchIsSuccess === true &&
          appointment.isLoadingGetAppointmentBranch === false
        ) {
          if (
            curUser.organisationInfo &&
            curUser.organisationInfo.user_organisations !== null
          ) {
            const defaultOrganisationBranch =
              curUser.organisationInfo.user_organisations.find(
                (o) => o.is_default === true
              ) || curUser.organisationInfo.user_organisations[0];

            const defaultAppointmentOrganisationBranch =
              appointment.getAppointmentBranch.organisation_branch.find(
                (item) =>
                  item.id === defaultOrganisationBranch?.organisation_branch?.id
              );
            if (defaultAppointmentOrganisationBranch) {
              setBranch(defaultAppointmentOrganisationBranch);
            } else {
              setBranch(null);
            }
          }
        }

        if (
          appointment.getAppointmentBranchIsSuccess === false &&
          appointment.isLoadingGetAppointmentBranch === false
        ) {
          setBranch(null);
        }
      }
    }
  }, [curUser.organisationInfo, appointment, isDefault]);

  useEffect(() => {
    if (isDefault) {
      if (
        appointment.isLoadingAppointmentBranchPhysician !==
        prevGetAppointmentPhysiciansStatus.current
      ) {
        //check the previous ref with current state
        prevGetAppointmentPhysiciansStatus.current =
          appointment.isLoadingAppointmentBranchPhysician;
        if (
          appointment.getAppointmentBranchPhysicianIsSuccess === true &&
          appointment.isLoadingAppointmentBranchPhysician === false
        ) {
          const defaultPhysician =
            appointment.appointmentBranchPhysician.organisation_physician.find(
              (item) => item.user.id === userAuth.systemUserInfo.id
            );
          if (defaultPhysician) {
            setPhysician(defaultPhysician);
          } else {
            setPhysician(null);
          }
        }

        if (
          appointment.getAppointmentBranchPhysicianIsSuccess === false &&
          appointment.isLoadingAppointmentBranchPhysician === false
        ) {
          setPhysician(null);
        }
      }
    }
  }, [userAuth.systemUserInfo, appointment, isDefault]);

  useEffect(() => {
    dispatch(getAppointmentBranch.pending());
  }, [dispatch]);

  useEffect(() => {
    if (branch) {
      dispatch(
        getAppointmentBranchPhysician.pending({
          id: branch.branch.id,
        })
      );
    }
  }, [dispatch, branch]);
  useEffect(() => {
    if (physician && branch) {
      const calenderMonthYear = `${calendarYear}-${calendarMonth}`;

      const startOfMonth = moment(calenderMonthYear, "YYYY-MM")
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(calenderMonthYear, "YYYY-MM")
        .clone()
        .endOf("month")
        .format("YYYY-MM-DD");
      dispatch(
        getAppointmentTimeslotList.pending({
          search_branch_id_value: branch.branch.id,

          search_physician_id_value: physician.user.id,
          search_start_date_value: startOfMonth,
          search_end_date_value: endOfMonth,
        })
      );
      dispatch(
        getAppointmentList.pending({
          search_branch_id_value: branch.branch.id,
          search_physician_id_value: physician.user.id,
          search_start_date_value: startOfMonth,
          search_end_date_value: endOfMonth,
          is_pagination: false,
        })
      );
    }
  }, [dispatch, physician, branch, calendarYear, calendarMonth]);

  useEffect(() => {
    if (
      appointment.isLoadingSubmitAppointment !==
      prevSubmissionAppointmentStatus.current
    ) {
      //check the previous ref with current state
      prevSubmissionAppointmentStatus.current =
        appointment.isLoadingSubmitAppointment;

      if (
        appointment.postSubmitAppointmentIsSuccess === true &&
        appointment.isLoadingSubmitAppointment === false
      ) {
        const calenderMonthYear = `${calendarYear}-${calendarMonth}`;

        const startOfMonth = moment(calenderMonthYear, "YYYY-MM")
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        const endOfMonth = moment(calenderMonthYear, "YYYY-MM")
          .clone()
          .endOf("month")
          .format("YYYY-MM-DD");
        dispatch(
          getAppointmentList.pending({
            search_branch_id_value: branch.branch.id,

            search_physician_id_value: physician.user.id,
            search_start_date_value: startOfMonth,
            search_end_date_value: endOfMonth,
            is_pagination: false,
          })
        );

        dispatch(
          getAppointmentTimeslotList.pending({
            search_branch_id_value: branch.branch.id,

            search_physician_id_value: physician.user.id,
            search_start_date_value: startOfMonth,
            search_end_date_value: endOfMonth,
          })
        );

        setShowErrorAlert(false);

        setShowSuccessAlert(true);
        setSelectedDate(null);
        setSelectedStartTime(null);
        setSelectedEndTime(null);
        setIsTempPatient(false);
        setIsNewAppointment(true);
        setAppointmentMobileNumber(null);
        setPatientApptDescription(null);
      }

      if (
        appointment.postSubmitAppointmentIsSuccess === false &&
        appointment.isLoadingSubmitAppointment === false
      ) {
        setShowErrorAlertMsg(appointment.error.submitAppointmentError);

        setShowErrorAlert(true);
        setShowSuccessAlert(false);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, appointment, calendarMonth, calendarYear, physician, branch]);

  useEffect(() => {
    if (
      appointment.appointmentList &&
      Object.keys(appointment.appointmentList.appointments).length !== 0
    ) {
      const appintmentList = appointment.appointmentList.appointments.map(
        (appointment, index) => {
          const databaseDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
          return {
            id: appointment.id,
            title: appointment.staff
              ? appointment.staff.name
              : appointment.temp_user.name,
            start: moment(
              `${appointment.full_date} ${appointment.start_time}`,
              databaseDateTimeFormat
            ).format(),
            end: moment(
              `${appointment.full_date} ${appointment.end_time}`,
              databaseDateTimeFormat
            ).format(),
            extendedProps: appointment,
          };
        }
      );

      setBranchPhysicianAppointmentList(appintmentList);
    } else {
      setBranchPhysicianAppointmentList([]);
    }
  }, [appointment.appointmentList]);

  // useEffect(() => {
  //   setMonthSchedule(
  //     appointment.appointmentTimeslotList
  //       ? appointment.appointmentTimeslotList
  //       : []
  //   );

  //   if (appointment.appointmentTimeslotList) {
  //     const longestMonthTimeslot = appointment.appointmentTimeslotList.reduce(
  //       (arr, current) => {
  //         if (current.schedule_timeslot.length > arr.schedule_timeslot.length) {
  //           return current;
  //         }
  //         return arr;
  //       },
  //       { schedule_timeslot: [] }
  //     );
  //     if (longestMonthTimeslot.schedule_timeslot.length) {
  //       const fistTimeslop = longestMonthTimeslot.schedule_timeslot[0];
  //       const lastTimeslop =
  //         longestMonthTimeslot.schedule_timeslot[
  //           longestMonthTimeslot.schedule_timeslot.length - 1
  //         ];

  //       setCalendarEndTime(lastTimeslop.schedule_end_time);
  //       setCalendarStartTime(fistTimeslop.schedule_start_time);
  //     }
  //   }
  // }, [appointment.appointmentTimeslotList]);

  useEffect(() => {
    setMonthSchedule(
      appointment.appointmentTimeslotList
        ? appointment.appointmentTimeslotList
        : []
    );

    if (appointment.appointmentTimeslotList) {
      const monthTimeslot = appointment.appointmentTimeslotList.reduce(
        (arr, current) => {
          if (current.schedule_timeslot.length) {
            current.schedule_timeslot.map((item) => {
              if (
                !arr.minTimeslot ||
                (item.schedule_start_time &&
                  item.schedule_start_time < arr.minTimeslot)
              ) {
                return (arr.minTimeslot = item.schedule_start_time);
              }

              if (
                !arr.maxTimeslot ||
                (item.schedule_end_time &&
                  item.schedule_end_time > arr.maxTimeslot)
              ) {
                return (arr.maxTimeslot = item.schedule_end_time);
              }

              return arr;
            });
          }
          return arr;
        },
        { minTimeslot: "", maxTimeslot: "" }
      );
      if (monthTimeslot.minTimeslot && monthTimeslot.maxTimeslot) {
        setCalendarStartTime(monthTimeslot.minTimeslot);
        setCalendarEndTime(monthTimeslot.maxTimeslot);
      }
    }
  }, [appointment.appointmentTimeslotList]);

  useEffect(() => {
    if (physician) {
      const timeSlotDurationCalendarFormat = moment()
        .startOf("day")
        .add(physician.appt_time_block, "m")
        .format(CALENDAR_TIME_FORMAT);
      setSlotDuration(timeSlotDurationCalendarFormat);
    }
  }, [physician]);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmBookOpen}
        setOpen={setConfirmBookOpen}
        onConfirm={bookAppointmentOnSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <WarningrIcon />
            {t("translation:Cancellation Confirmation")}
          </Grid>
        }
        open={confirmCancelOpen}
        setOpen={setConfirmCancelOpen}
        onConfirm={cancelAppointmentOnSubmit}>
        {t("translation:Are you sure you would like to cancel?")}
      </ConfirmDialog>

      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmUpdateOpen}
        setOpen={setConfirmUpdateOpen}
        onConfirm={updateAppointmentOnSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <Typography className={classes.title} variant="h3" gutterBottom>
        {t("translation:Appointment")}
      </Typography>
      <form>
        <Panel heading={t("translation:Select Date & Time")}>
          <Grid container>
            <Grid xs={12} item>
              <Collapse in={showErrorAlert}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setShowErrorAlert(false);
                      }}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  {showErrorAlertMsg}
                </Alert>
              </Collapse>
            </Grid>
            <Grid xs={12} item>
              <Collapse in={showSuccessAlert}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setShowSuccessAlert(false);
                      }}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  {showSuccessAlertMsg}
                </Alert>
              </Collapse>
            </Grid>

            <Grid xs={6} item className={classes.userInfoField}>
              <Autocomplete
                name="Branch"
                disableClearable
                value={branch}
                options={
                  appointment.getAppointmentBranch &&
                  Object.keys(
                    appointment.getAppointmentBranch.organisation_branch
                  ).length !== 0
                    ? appointment.getAppointmentBranch.organisation_branch
                    : []
                }
                getOptionLabel={(option) =>
                  option.branch.name ? option.branch.name : ""
                }
                isOptionEqualToValue={(option, value) => {
                  if (option.id === value.id) return option;
                }}
                style={{ width: "100%" }}
                onChange={(e, option) => {
                  resetDateTime();
                  setPhysician(null);
                  setIsNewAppointment(true);
                  setIsDefault(false);
                  setBranch(option);

                  updateCalendarMonthYear(moment().format("YYYY-MM-DD"));
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Branch")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid xs={6} item className={classes.userInfoField}>
              <Autocomplete
                name="Physician"
                disableClearable
                disabled={branch ? false : true}
                value={physician}
                options={
                  appointment.appointmentBranchPhysician &&
                  Object.keys(
                    appointment.appointmentBranchPhysician
                      .organisation_physician
                  ).length !== 0
                    ? appointment.appointmentBranchPhysician.organisation_physician.sort(
                        (a, b) => -b.user.name.localeCompare(a.user.name)
                      )
                    : []
                }
                getOptionLabel={(option) =>
                  option.user.name ? option.user.name : ""
                }
                isOptionEqualToValue={(option, value) => {
                  if (option.id === value.id) return option;
                }}
                style={{ width: "100%" }}
                onChange={(e, option) => {
                  setPhysician(option);
                  resetDateTime();
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Physician")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            {physician && (
              <>
                <Grid
                  className={classes.autoCompletePopper2}
                  item
                  xs={12}
                  lg={9}>
                  <FullCalendar
                    locales={[esLocale, cnLocale]}
                    locale={currentLocate}
                    firstDay={0}
                    ref={calendarRef}
                    customButtons={{
                      prev: {
                        click: function () {
                          // this will move the calendar to prev month
                          calendarRef.current.getApi().prev();
                          const calendarDate = calendarRef.current
                            .getApi()
                            .getDate();
                          updateCalendarMonthYear(calendarDate);
                        },
                      },
                      next: {
                        click: function () {
                          // this will move the calendar to next month
                          calendarRef.current.getApi().next();
                          const calendarDate = calendarRef.current
                            .getApi()
                            .getDate();
                          updateCalendarMonthYear(calendarDate);
                        },
                      },
                    }}
                    headerToolbar={{
                      left: "prev,next",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    // initialDate={initialDate}
                    initialView="dayGridMonth"
                    height={650}
                    views={{
                      dayGridMonth: {
                        eventTimeFormat: {
                          hour: "2-digit",
                          minute: "2-digit",
                          meridiem: "short",
                        },
                      },
                    }}
                    showNonCurrentDates={false}
                    slotDuration={slotDuration}
                    slotMaxTime={calendarEndTime}
                    slotMinTime={calendarStartTime}
                    unselectAuto={false}
                    dragScroll={false}
                    selectable={true}
                    selectAllow={(info) =>
                      moment().format("YYYY-MM-DD") <= info.startStr
                    }
                    dateClick={(info) => handleDateOnClick(info)}
                    eventClick={(info) => handleEventOnClick(info)}
                    eventColor="rgba(149, 0, 0,0.75)"
                    dayMaxEventRows={3}
                    events={branchPhysicianAppointmentList}
                    eventMouseEnter={(info) =>
                      (info.el.title = info.event.title)
                    }
                    progressiveEventRendering={
                      appointment.isLoadingAppointmentList
                    }
                  />
                </Grid>
                <Grid
                  className={classes.appointmentSummaryContainer}
                  item
                  xs={12}
                  lg={3}>
                  {isClosed && (
                    <Typography gutterBottom variant={"h6"}>
                      Branch is closed.
                    </Typography>
                  )}
                  {
                    <>
                      {isNewAppointment && (
                        <Grid item xs={12}>
                          <Typography gutterBottom variant={"caption"}>
                            {t("translation:Date")}
                          </Typography>
                          <Typography gutterBottom variant={"h6"}>
                            {selectedDate || "-"}
                          </Typography>
                        </Grid>
                      )}
                      {selectedDate && !isNewAppointment && (
                        <Grid item xs={12} className={classes.userInfoField}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              required
                              className={classes.userFieldColor}
                              inputVariant="outlined"
                              variant="inline"
                              id="date"
                              label={t("translation:Date")}
                              format={CALENDAR_DATE_FORMAT}
                              value={moment(selectedDate, CALENDAR_DATE_FORMAT)}
                              onChange={(date) => {
                                if (moment(date).isValid()) {
                                  handleUpdateApptDateTime(date);
                                }
                              }}
                              labelFunc={function (dateValue) {
                                return formattedDate(dateValue);
                              }}
                              autoOk={true}
                            />
                          </LocalizationProvider>
                        </Grid>
                      )}

                      <Grid>
                        {selectedStartTime && isNewAppointment && (
                          <Grid item xs={12}>
                            <Typography gutterBottom variant={"caption"}>
                              {t("translation:Time")}
                            </Typography>
                            <Typography gutterBottom variant={"h6"}>
                              {moment(
                                selectedStartTime,
                                CALENDAR_TIME_FORMAT
                              ).format(DISPAY_TIME_FORMAT)}{" "}
                              {t("translation:To")}{" "}
                              {moment(
                                selectedEndTime,
                                CALENDAR_TIME_FORMAT
                              ).format(DISPAY_TIME_FORMAT)}
                            </Typography>
                          </Grid>
                        )}

                        {selectedStartTime && !isNewAppointment && (
                          <Grid item xs={12} className={classes.userInfoField}>
                            <TextField
                              required
                              className={classes.userFieldColor}
                              id="patient-appt-time"
                              value={
                                moment(
                                  selectedStartTime,
                                  CALENDAR_TIME_FORMAT
                                ).format(DISPAY_TIME_FORMAT) +
                                " " +
                                t("translation:To") +
                                " " +
                                moment(
                                  selectedEndTime,
                                  CALENDAR_TIME_FORMAT
                                ).format(DISPAY_TIME_FORMAT)
                              }
                              label={t("translation:Time")}
                              variant="outlined"
                              onClick={() => setShowTimeSlotDialog(true)}
                            />
                          </Grid>
                        )}
                        {!selectedStartTime && (
                          <Grid item xs={12}>
                            <Typography gutterBottom variant={"caption"}>
                              {t("translation:Time")}
                            </Typography>
                            <Typography gutterBottom variant={"h6"}>
                              {"-"}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      {isNewAppointment && (
                        <RadioGroup
                          row
                          value={isTempPatient}
                          onChange={() => handleSelectPatientRadioBtn()}>
                          <FormControlLabel
                            value="existing-patient"
                            control={
                              <StyledRadioButton checked={!isTempPatient} />
                            }
                            label={t("translation:Existing Patient")}
                          />

                          <FormControlLabel
                            value="new-patient"
                            control={
                              <StyledRadioButton checked={isTempPatient} />
                            }
                            label={t("translation:Self-defined Patient")}
                          />
                        </RadioGroup>
                      )}

                      {isNewAppointment && !isTempPatient && (
                        <SearchExistingPatient
                          setPatientInfoField={setPatientInfoField}
                        />
                      )}

                      {isNewAppointment && isTempPatient && (
                        <SearchTemporaryPatient
                          setIsNewTempPatient={setIsNewTempPatient}
                          setPatientInfoField={setPatientInfoField}
                        />
                      )}

                      <PatientField
                        isNewAppointment={isNewAppointment}
                        isTempPatient={isTempPatient}
                        isNewTempPatient={isNewTempPatient}
                        setIsNewTempPatient={setIsNewTempPatient}
                        patientName={patientName}
                        setPatientName={setPatientName}
                        patientNric={patientNric}
                        setPatientNric={setPatientNric}
                        patientMobileNumber={patientMobileNumber}
                        setPatientMobileNumber={setPatientMobileNumber}
                        appointmentMobileNumber={appointmentMobileNumber}
                        setAppointmentMobileNumber={setAppointmentMobileNumber}
                        patientApptDescription={patientApptDescription}
                        setPatientApptDescription={setPatientApptDescription}
                        setConfirmCancelOpen={setConfirmCancelOpen}
                        physician={physician}
                      />
                    </>
                  }
                </Grid>
              </>
            )}
            {Object.keys(monthSchedule).length !== 0 && selectedDate && (
              <TimeSlotDialog
                showTimeSlotDialog={showTimeSlotDialog}
                selectedDate={selectedDate}
                monthSchedule={monthSchedule}
                handleSelectedTimeSlot={handleSelectedTimeSlot}
                setShowTimeSlotDialog={setShowTimeSlotDialog}
                CALENDAR_DATE_FORMAT={CALENDAR_DATE_FORMAT}
                API_DATE_FORMAT={API_DATE_FORMAT}
                CALENDAR_TIME_FORMAT={CALENDAR_TIME_FORMAT}
                DISPAY_TIME_FORMAT={DISPAY_TIME_FORMAT}
              />
            )}
          </Grid>
        </Panel>

        <Grid item xs={12}>
          {t(
            "translation:Please use all-day field to select time slot for the week of calendar and day of calendar"
          )}
          .
        </Grid>

        <RequiredNote />
        {physician && isNewAppointment && (
          <PageCustomAction
            button1Name="bookAppointment"
            button1Click={handleBookAppointmentOnConfirm}
            button1label={t("translation:SUBMIT")}
          />
        )}

        {physician && !isNewAppointment && (
          <PageCustomAction
            button1Name="updateAppointment"
            button1Click={() => setConfirmUpdateOpen(true)}
            button1label={t("translation:SUBMIT")}
            button2Name="backAppointment"
            button2Click={() => {
              clearAppointmentsState();
              setIsNewAppointment(true);
            }}
            button2label={t("translation:BACK")}
          />
        )}
      </form>
    </Paper>
  );
};

export default AppointmentSummaryPage;
