import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getDeliveryTrackingList,
  getDeliveryTrackingSummaryList,
  getDeliveryTrackingById,
  getDeliveryTrackingCourierList,
  getDeliveryTrackingCourierByBranch,
  putDeliveryTrackingWIPMode,
  postDeliveryTracking,
  putDeliveryTrackingPendingStatus,
  putDeliveryTrackingCloseStatus,
  putDeliveryTrackingPrintDate,
  putDeliveryTrackingUploadFile,
} from "../actions/deliveryTrackingActions";
import * as types from "../_constants/deliveryTrackingConstants";
import * as notification from "../_constants/notificationConstants";
import deliveryTrackingApis from "../_apis/deliveryTrackingApis";
export default function* deliveryTrackingSagas() {
  yield all([
    takeLatest(
      types.GET_DELIVERYTRACKINGLIST_ASYNC.PENDING,
      getDeliveryTrackingListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYTRACKINGSUMMARYLIST_ASYNC.PENDING,
      getDeliveryTrackingSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYTRACKINGBYID_ASYNC.PENDING,
      getDeliveryTrackingByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYTRACKINGCOURIERLIST_ASYNC.PENDING,
      getDeliveryTrackingCourierListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYTRACKINGCOURIERBYBRANCH_ASYNC.PENDING,
      getDeliveryTrackingCourierByBranchCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_DELIVERYTRACKINGWIPMODE_ASYNC.PENDING,
      putDeliveryTrackingWIPModeCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_DELIVERYTRACKING_ASYNC.PENDING,
      postDeliveryTrackingCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_DELIVERYTRACKINGPENDINGSTATUS_ASYNC.PENDING,
      putDeliveryTrackingPendingStatusCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_DELIVERYTRACKINGCLOSESTATUS_ASYNC.PENDING,
      putDeliveryTrackingCloseStatusCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_DELIVERYTRACKINGPRINTDATE_ASYNC.PENDING,
      putDeliveryTrackingPrintDateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_DELIVERYTRACKINGUPLOADFILE_ASYNC.PENDING,
      putDeliveryTrackingUploadFileCall
    ),
  ]);
}

function* getDeliveryTrackingListCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.getDeliveryTrackingListApi,
      searchModel.data
    );
    yield put(getDeliveryTrackingList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDeliveryTrackingList.error(error.response?.data?.message));
    else
      yield put(getDeliveryTrackingList.error(notification.CONST_NOINTERNET));
  }
}

function* getDeliveryTrackingSummaryListCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.getDeliveryTrackingSummaryListApi,
      searchModel.data
    );
    yield put(getDeliveryTrackingSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryTrackingSummaryList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryTrackingSummaryList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryTrackingByIdCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.getDeliveryTrackingByIdApi,
      searchModel.data
    );
    yield put(getDeliveryTrackingById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDeliveryTrackingById.error(error.response?.data?.message));
    else
      yield put(getDeliveryTrackingById.error(notification.CONST_NOINTERNET));
  }
}

function* getDeliveryTrackingCourierListCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.getDeliveryTrackingCourierListApi,
      searchModel.data
    );
    yield put(getDeliveryTrackingCourierList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryTrackingCourierList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryTrackingCourierList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryTrackingCourierByBranchCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.getDeliveryTrackingCourierByBranchApi,
      searchModel.data
    );
    yield put(getDeliveryTrackingCourierByBranch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryTrackingCourierByBranch.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryTrackingCourierByBranch.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putDeliveryTrackingWIPModeCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.putDeliveryTrackingWIPModeApi,
      searchModel.data
    );
    yield put(putDeliveryTrackingWIPMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDeliveryTrackingWIPMode.error(error.response?.data?.message)
      );
    else
      yield put(
        putDeliveryTrackingWIPMode.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postDeliveryTrackingCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.postDeliveryTrackingApi,
      searchModel.data
    );
    yield put(postDeliveryTracking.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postDeliveryTracking.error(error.response?.data?.message));
    else yield put(postDeliveryTracking.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryTrackingPendingStatusCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.putDeliveryTrackingPendingStatusApi,
      searchModel.data
    );
    yield put(putDeliveryTrackingPendingStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDeliveryTrackingPendingStatus.error(error.response?.data?.message)
      );
    else
      yield put(
        putDeliveryTrackingPendingStatus.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putDeliveryTrackingCloseStatusCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.putDeliveryTrackingCloseStatusApi,
      searchModel.data
    );
    yield put(putDeliveryTrackingCloseStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDeliveryTrackingCloseStatus.error(error.response?.data?.message)
      );
    else
      yield put(
        putDeliveryTrackingCloseStatus.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putDeliveryTrackingPrintDateCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.putDeliveryTrackingPrintDateApi,
      searchModel.data
    );
    yield put(putDeliveryTrackingPrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDeliveryTrackingPrintDate.error(error.response?.data?.message)
      );
    else
      yield put(
        putDeliveryTrackingPrintDate.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putDeliveryTrackingUploadFileCall(searchModel) {
  try {
    const data = yield call(
      deliveryTrackingApis.putDeliveryTrackingUploadFileApi,
      searchModel.data
    );
    yield put(putDeliveryTrackingUploadFile.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDeliveryTrackingUploadFile.error(error.response?.data?.message)
      );
    else
      yield put(
        putDeliveryTrackingUploadFile.error(notification.CONST_NOINTERNET)
      );
  }
}
