import * as types from "../_constants/treatmentTypeConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_TREATMENTTYPEBYID_ASYNC.PENDING:
      return {
        ...state,
        getTreatmentTypeByIdIsSuccess: false,
        isLoadingGetTreatmentTypeById: true,
        getTreatmentTypeById: null,
      };

    case types.GET_TREATMENTTYPEBYID_ASYNC.ERROR:
      return {
        ...state,
        getTreatmentTypeByIdIsSuccess: false,
        isLoadingGetTreatmentTypeById: false,
        getTreatmentTypeById: null,
        error: { ...state.error, getTreatmentTypeByIdError: action.error },
      };

    case types.GET_TREATMENTTYPEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getTreatmentTypeByIdIsSuccess: true,
        isLoadingGetTreatmentTypeById: false,
        getTreatmentTypeById: action.data,
      };

    case types.GET_TREATMENTTYPELIST_ASYNC.PENDING:
      return {
        ...state,
        getTreatmentTypeListIsSuccess: false,
        isLoadingGetTreatmentTypeList: true,
        getTreatmentTypeList: null,
      };

    case types.GET_TREATMENTTYPELIST_ASYNC.ERROR:
      return {
        ...state,
        getTreatmentTypeListIsSuccess: false,
        isLoadingGetTreatmentTypeList: false,
        getTreatmentTypeList: null,
        error: { ...state.error, getTreatmentTypeListError: action.error },
      };

    case types.GET_TREATMENTTYPELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getTreatmentTypeListIsSuccess: true,
        isLoadingGetTreatmentTypeList: false,
        getTreatmentTypeList: action.data,
      };

    case types.POST_CREATETREATMENTTYPE_ASYNC.PENDING:
      return {
        ...state,
        postCreateTreatmentTypeIsSuccess: false,
        isLoadingPostCreateTreatmentType: true,
        postCreateTreatmentType: null,
      };

    case types.POST_CREATETREATMENTTYPE_ASYNC.ERROR:
      return {
        ...state,
        postCreateTreatmentTypeIsSuccess: false,
        isLoadingPostCreateTreatmentType: false,
        postCreateTreatmentType: null,
        error: { ...state.error, postCreateTreatmentTypeError: action.error },
      };

    case types.POST_CREATETREATMENTTYPE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateTreatmentTypeIsSuccess: true,
        isLoadingPostCreateTreatmentType: false,
        postCreateTreatmentType: action.data,
      };

    case types.PUT_UPDATETREATMENTTYPE_ASYNC.PENDING:
      return {
        ...state,
        putUpdateTreatmentTypeIsSuccess: false,
        isLoadingPutUpdateTreatmentType: true,
        putUpdateTreatmentType: null,
      };

    case types.PUT_UPDATETREATMENTTYPE_ASYNC.ERROR:
      return {
        ...state,
        putUpdateTreatmentTypeIsSuccess: false,
        isLoadingPutUpdateTreatmentType: false,
        putUpdateTreatmentType: null,
        error: { ...state.error, putUpdateTreatmentTypeError: action.error },
      };

    case types.PUT_UPDATETREATMENTTYPE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateTreatmentTypeIsSuccess: true,
        isLoadingPutUpdateTreatmentType: false,
        putUpdateTreatmentType: action.data,
      };

    default:
      return state;
  }
};
