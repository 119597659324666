//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
// import IconButton from "@mui/material/IconButton";
// import Alert from "@mui/material/Alert";
// import Collapse from "@mui/material/Collapse";
// import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Button, Link } from "@mui/material";
//action
import { postSubmitOrderForm } from "../../../actions/orderFormActions";
//components
import ConfirmDialog from "../../../components/ConfirmDialog";
import RequiredNote from "../../../components/RequiredNote";
import PageAction from "../../../components/PageAction";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import * as generalConstants from "../../../_constants/generalConstants";
import OrderFormField from "./components/OrderFormField";
import MedicineListTable from "./components/MedicineListTable";
import priceWGst from "../../../components/functions/priceWGst";
import organisationPriceRounding from "../../../components/functions/organisationPriceRounding";
import AmountTotal from "./components/AmountTotal";
import { useStyles } from "../../../components/globalStyles";
import Panel from "../../../components/Panel";

const ProcessOrderFormPage = ({
  setView,
  infoData = {},
  viewMode = false,
  copyMode = false,
  setCopyMode = () => null,
}) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [successAlarm, setSuccessAlarm] = useState(false);
  // const [errorAlarm, setErrorAlarm] = useState(false);
  // const [msgAlarm, setMsgAlarm] = useState("");
  const orderForm = useSelector((state) => state.orderForm);
  const prevPostMedicineTypeStatus = useRef();
  const prevSubmitOrderFormStatus = useRef();
  const { handleSubmit } = useForm();

  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [medicineList, setMedicineList] = useState(
    infoData.order_form_header?.order_form_details
      ? infoData.order_form_header?.order_form_details
      : []
  );

  const [price, setPrice] = useState(
    infoData.order_form_header
      ? {
          amt_with_gst: infoData.order_form_header.amt_with_gst,
          amt_wo_gst: infoData.order_form_header.amt_wo_gst,
          gst_amt: infoData.order_form_header.gst_amt,
        }
      : null
  );

  const [supplier, setSupplier] = useState(
    infoData.order_form_header?.supplier
      ? infoData.order_form_header?.supplier
      : null
  );
  const [supplierGst, setSupplierGst] = useState(
    infoData.order_form_header
      ? {
          gst_value: infoData.order_form_header.gst_value,
          is_gst_enable: infoData.order_form_header.is_gst_enable,
          is_gst_inclusive: infoData.order_form_header.is_gst_inclusive,
        }
      : null
  );
  //functions

  const submitJson = (statusID) => {
    //reset submit error
    // setErrorAlarm(false);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    // const checkMedicineList = medicineList.length
    //   ? medicineList.some(
    //       (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    //     )
    //   : false;

    const jsonText = {
      order_form_header: {
        ...price,
        ...supplierGst,
        order_form_details: medicineList,
        id:
          !copyMode && infoData.order_form_header?.id
            ? infoData.order_form_header?.id
            : 0,
        status_type: statusID,
        supplier: supplier,
      },
    };
    return jsonText;
  };
  const onSave = (event) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    event.currentTarget.form.reportValidity();
    var checkVal = event.currentTarget.form.checkValidity();
    if (checkVal) {
      const jsonText = submitJson(generalConstants.NEW_STATUS_TYPE);
      dispatch(postSubmitOrderForm.pending(jsonText));
    }
  };

  const onSubmit = () => {
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = () => {
    const jsonText = submitJson(generalConstants.PENDING_STATUS_TYPE);
    dispatch(postSubmitOrderForm.pending(jsonText));
  };

  //set table data
  const setMedicineData = (rowIndex, columnID, value) => {
    let orderFormDetails = [...medicineList];

    if (columnID === "delete") {
      let result = [...orderFormDetails];
      //check whether is a current existing treatment

      const currentMedicineWoDelete = orderFormDetails.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentMedicineVerified = currentMedicineWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentMedicineVerified) {
        const currentMedicineIndex = result.findIndex(
          (e) => e.id === currentMedicineVerified.id
        );
        result[currentMedicineIndex] = {
          ...currentMedicineVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddMedicineVerified = currentMedicineWoDelete.find(
          (e, index) => index === rowIndex && e.id === 0
        );

        result = result.filter(
          (e) => e.medicine.id !== newAddMedicineVerified.medicine.id
        );
      }

      setMedicineList(result);
    } else {
      const currentTreatmentWoDelete = orderFormDetails.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );
      const selectedVisitTreatment = currentTreatmentWoDelete.map(
        (item, index) => {
          if (index === rowIndex && columnID !== "delete") {
            const newValue = {
              ...item,
              [columnID]: value,
            };

            const subTotalRound = organisationPriceRounding(
              Number(newValue.price) * Number(newValue.quantity),
              generalConstants.ROUND_OFF_RMT,
              generalConstants.HUNDREDTH_RPVT
            );

            const newValueWSubtotal = {
              ...newValue,
              sub_total: Number(subTotalRound),
            };
            return newValueWSubtotal;
          }
          return item;
        }
      );

      const currentTreatmentWDelete = orderFormDetails.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const mergeSelectedWDeletedTreatment = [
        ...currentTreatmentWDelete,
        ...selectedVisitTreatment,
      ];

      setMedicineList(mergeSelectedWDeletedTreatment);
    }
  };

  useEffect(() => {
    //set ref when first render
    prevPostMedicineTypeStatus.current = false;
    prevSubmitOrderFormStatus.current = false;
  }, []);

  // useEffect(() => {
  //   if (
  //     orderForm.isLoadingPostSubmitOrderForm !==
  //     prevSubmitOrderFormStatus.current
  //   ) {
  //     //check the previous ref with current state
  //     prevSubmitOrderFormStatus.current =
  //       orderForm.isLoadingPostSubmitOrderForm;

  //     if (
  //       orderForm.postSubmitOrderFormIsSuccess === true &&
  //       orderForm.isLoadingPostSubmitOrderForm === false
  //     ) {
  //       if (
  //         orderForm.submitOrderForm?.order_form_header.status_type ===
  //         generalConstants.NEW_STATUS_TYPE
  //       ) {
  //         setMsgAlarm(t("translation:Purchase Requisition has been saved"));
  //       }

  //       if (
  //         orderForm.submitOrderForm?.order_form_header.status_type ===
  //         generalConstants.PENDING_STATUS_TYPE
  //       ) {
  //         setMsgAlarm(t("translation:Purchase Requisition has been submitted"));
  //       }
  //       setSuccessAlarm(true);
  //       window.scrollTo(0, 0);
  //     }

  //     if (
  //       orderForm.postSubmitOrderFormIsSuccess === false &&
  //       orderForm.isLoadingPostSubmitOrderForm === false
  //     ) {
  //       setErrorAlarm(true);
  //       setMsgAlarm(orderForm.error.postSubmitOrderFormError);
  //       window.scrollTo(0, 0);
  //     }
  //   }
  // }, [orderForm, t]);
  useEffect(() => {
    if (supplierGst) {
      const totalAmount = medicineList.reduce((sum, li) => {
        if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          return parseFloat(Number(sum + li.sub_total).toFixed(2));
        }
        return parseFloat(sum.toFixed(2));
      }, 0);
      setPrice(
        priceWGst(
          totalAmount,
          supplierGst.gst_value,
          supplierGst.is_gst_enable,
          supplierGst.is_gst_inclusive
        )
      );
    }
  }, [medicineList, supplierGst]);

  const medicineListWoDeleted = () => {
    return medicineList.filter(
      (item) => item.status_type !== generalConstants.DELETED_STATUS_TYPE
    );
  };

  const memoMedicineListWoDeleted = React.useMemo(medicineListWoDeleted, [
    medicineList,
  ]);
  useEffect(() => {
    if (copyMode) {
      const orderFromDetail = infoData.order_form_header?.order_form_details
        .filter(
          (item) => item.status_type !== generalConstants.DELETED_STATUS_TYPE
        )
        .map((item) => {
          return { ...item, id: 0 };
        });
      setMedicineList(orderFromDetail);
    }
  }, [infoData, copyMode]);

  useEffect(() => {
    //update supplier info if there is DB is updated
    if (
      infoData.order_form_header?.supplier &&
      orderForm.orderFormSuppliers &&
      !viewMode
    ) {
      const orderFromSupplier = orderForm.orderFormSuppliers.suppliers.find(
        (item) => item.id === infoData.order_form_header?.supplier.id
      );
      setSupplierGst({
        is_gst_enable: orderFromSupplier.is_gst_enable,
        is_gst_inclusive: orderFromSupplier.is_gst_inclusive,
        gst_value: orderFromSupplier.gst_value,
      });
    }
  }, [infoData, orderForm.orderFormSuppliers, viewMode]);

  return (
    <>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      {/* <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>

      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse> */}
      <br />

      {!copyMode && viewMode && (
        <Link className={classes.boldMaroon075}>
          <Button
            className={classes.boldMaroon075}
            onClick={() => setCopyMode(true)}>
            {t("translation:Copy")}
          </Button>
        </Link>
      )}

      {!copyMode && (
        <Typography display="block" variant="h4">
          {infoData.order_form_header?.order_number}
        </Typography>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <OrderFormField
          medicineList={medicineList}
          setMedicineList={setMedicineList}
          viewMode={viewMode}
          supplier={supplier}
          setSupplier={setSupplier}
          supplierGst={supplierGst}
          setSupplierGst={setSupplierGst}
          copyMode={copyMode}
        />

        {Object.keys(memoMedicineListWoDeleted).length !== 0 && (
          <Panel>
            <MedicineListTable
              data={memoMedicineListWoDeleted}
              setData={setMedicineData}
              t={t}
              viewOnly={!copyMode && viewMode}
            />
          </Panel>
        )}

        {price ? (
          <AmountTotal amountHeader={price} gstValue={supplierGst.gst_value} />
        ) : (
          <></>
        )}
        <RequiredNote />
        {!copyMode && viewMode && (
          <PageOnlyBackAction setView={() => setView()} />
        )}
        {(copyMode || !viewMode) && (
          <PageAction setView={() => setView()} onSave={onSave} />
        )}
      </form>
    </>
  );
};

export default ProcessOrderFormPage;
