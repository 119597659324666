import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getWaterAmount } from "../../../../../actions/commonActions";
import StyledCheckbox from "../../../../../components/StyledCheckbox";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../components/globalStyles";

const ViewBrewing = ({ memoSelectedGroup }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  // const [groupBrewingPack, setGroupBrewingPack] = useState(0);
  const common = useSelector((state) => state.common);

  useEffect(() => {
    if (!common.waterAmountList && !common.isLoadingWaterAmount) {
      dispatch(getWaterAmount.pending());
    }
  }, [dispatch, common.waterAmountList, common.isLoadingWaterAmount]);

  const getWaterAmountOpObj = (option) => {
    if (!option.id)
      option = common.waterAmountList.water_amount_select_field.find(
        (op) => op.id === option
      );
    return option;
  };

  // useEffect(() => {
  //   let temBrewingPack = 0;

  //   if (memoSelectedGroup.number_of_times && memoSelectedGroup.number_of_days) {
  //     temBrewingPack =
  //       memoSelectedGroup.number_of_times * memoSelectedGroup.number_of_days;
  //   }

  //   setGroupBrewingPack(temBrewingPack);
  // }, [memoSelectedGroup]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.userInfoField}>
        <FormControlLabel
          control={
            <StyledCheckbox
              disabled
              checked={memoSelectedGroup.is_brewing_services ? true : false}
              name="is_brewing_services"
            />
          }
          label={t("translation:Brewing Services")}
        />
      </Grid>

      <Grid item xs={12} className={classes.userInfoField}>
        {common.waterAmountList && (
          <Autocomplete
            disabled
            name="water_amount"
            options={common.waterAmountList.water_amount_select_field}
            disableClearable
            value={memoSelectedGroup.water_amount}
            getOptionLabel={(option) => getWaterAmountOpObj(option).name}
            isOptionEqualToValue={(option, value) => {
              if (option.id === value) return option;
            }}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                required
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Amount of Water (CC)")}
                variant="outlined"
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled
          className={classes.userFieldColor}
          value={
            memoSelectedGroup.brewing_instructions
              ? memoSelectedGroup.brewing_instructions
              : ""
          }
          multiline
          rows={3}
          name="brewing_instructions"
          label={t("translation:Brewing Instructions")}
          variant="outlined"
        />
      </Grid>
      {/* <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end">
        <Grid xs={3} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            value={groupBrewingPack}
            disabled
            name="brewingPacks"
            label={t("translation:Brewing Packs / Day")}
            variant="outlined"
            inputProps={{
              style: { textAlign: "right" },
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
          />
        </Grid>
      </Grid> */}
    </Grid>
  );
};
export default ViewBrewing;
