import { createAsyncTypes } from "../_helper/Utilities";

/* REMEMBER TO ADD config whitelist of redux-state-sync in order to share state between mutliple tab */
export const PUT_USERINFO_ASYNC = createAsyncTypes("PUT_USERINFO");

export const GET_ORGANISATIONINFO_ASYNC = createAsyncTypes(
  "GET_ORGANISATIONINFO"
);

export const GET_ORGANISATIONBRANCHLIST_ASYNC = createAsyncTypes(
  "GET_ORGANISATIONBRANCHLIST"
);

export const GET_ORGANISATIONBRANCHLISTBYID_ASYNC = createAsyncTypes(
  "GET_ORGANISATIONBRANCHLISTBYID"
);

export const PUT_ORGANISATIONINFO_ASYNC = createAsyncTypes(
  "PUT_ORGANISATIONINFO"
);

export const GET_ORGANISATION_ASYNC = createAsyncTypes("GET_ORGANISATION");

export const PUT_USERLANG_ASYNC = createAsyncTypes("PUT_USERLANG");

export const GET_CURROLERIGHT_ASYNC = createAsyncTypes("GET_CURROLERIGHT");

export const GET_ALLRIGHTACCESS_ASYNC = createAsyncTypes("GET_ALLRIGHTACCESS");

export const GET_DEFAULTBRANCH_ASYNC = createAsyncTypes("GET_DEFAULTBRANCH");

export const POST_TOPUPCREDIT_ASYNC = createAsyncTypes("POST_TOPUPCREDIT");

export const GET_CREDITBALANCE_ASYNC = createAsyncTypes("GET_CREDITBALANCE");

export const GET_ALLALLIANCEORGANISATION_ASYNC = createAsyncTypes(
  "GET_ALLALLIANCEORGANISATION"
);

export const GET_ALLORGANISATIONSTRIPE_ASYNC = createAsyncTypes(
  "GET_ALLORGANISATIONSTRIPE"
);

export const GET_DEFAULTALLIANCE_ASYNC = createAsyncTypes(
  "GET_DEFAULTALLIANCE"
);

export const GET_ORGANISATIONLOGO_ASYNC = createAsyncTypes(
  "GET_ORGANISATIONLOGO"
);

export const GET_CURRENTUSERUSERCREDITLIST_ASYNC = createAsyncTypes(
  "GET_CURRENTUSERUSERCREDITLIST"
);

/* REMEMBER TO ADD config whitelist of redux-state-sync in order to share state between mutliple tab */
