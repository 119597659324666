import React from "react";
import "./index.css";
import App from "./App";
import "./translations/i18n";
import { createRoot } from "react-dom/client";
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

/**
 * Inject interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

//disable console if environment not in development mode
if (process.env.NODE_ENV !== "development") console.log = () => {};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <App />
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

//in localhost register service work, socket io unable to connect

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
