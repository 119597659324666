import * as types from "../_constants/appointmentConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_APPOINTMENTLIST_ASYNC.PENDING:
      return {
        ...state,
        getAppointmentListIsSuccess: false,
        isLoadingAppointmentList: true,
        appointmentList: null,
      };

    case types.GET_APPOINTMENTLIST_ASYNC.ERROR:
      return {
        ...state,
        getAppointmentListIsSuccess: false,
        isLoadingAppointmentList: false,
        appointmentList: null,
        error: { ...state.error, appointmentListError: action.error },
      };

    case types.GET_APPOINTMENTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getAppointmentListIsSuccess: true,
        isLoadingAppointmentList: false,
        appointmentList: action.data,
      };

    case types.POST_SUBMITAPPOINTMENT_ASYNC.PENDING:
      return {
        ...state,
        postSubmitAppointmentIsSuccess: false,
        isLoadingSubmitAppointment: true,
        submitAppointment: null,
      };

    case types.POST_SUBMITAPPOINTMENT_ASYNC.ERROR:
      return {
        ...state,
        postSubmitAppointmentIsSuccess: false,
        isLoadingSubmitAppointment: false,
        submitAppointment: null,
        error: { ...state.error, submitAppointmentError: action.error },
      };

    case types.POST_SUBMITAPPOINTMENT_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitAppointmentIsSuccess: true,
        isLoadingSubmitAppointment: false,
        submitAppointment: action.data,
      };

    case types.POST_APPOINTMENTPHYSICIANS_ASYNC.PENDING:
      return {
        ...state,
        postAppointmentPhysiciansIsSuccess: false,
        isLoadingPostAppointmentPhysicians: true,
        postAppointmentPhysicians: null,
      };

    case types.POST_APPOINTMENTPHYSICIANS_ASYNC.ERROR:
      return {
        ...state,
        postAppointmentPhysiciansIsSuccess: false,
        isLoadingPostAppointmentPhysicians: false,
        postAppointmentPhysicians: null,
        error: { ...state.error, postAppointmentPhysiciansError: action.error },
      };

    case types.POST_APPOINTMENTPHYSICIANS_ASYNC.SUCCESS:
      return {
        ...state,
        postAppointmentPhysiciansIsSuccess: true,
        isLoadingPostAppointmentPhysicians: false,
        postAppointmentPhysicians: action.data,
      };

    case types.GET_APPOINTMENTBRANCH_ASYNC.PENDING:
      return {
        ...state,
        getAppointmentBranchIsSuccess: false,
        isLoadingGetAppointmentBranch: true,
        getAppointmentBranch: null,
      };

    case types.GET_APPOINTMENTBRANCH_ASYNC.ERROR:
      return {
        ...state,
        getAppointmentBranchIsSuccess: false,
        isLoadingGetAppointmentBranch: false,
        getAppointmentBranch: null,
        error: { ...state.error, getAppointmentBranchError: action.error },
      };

    case types.GET_APPOINTMENTBRANCH_ASYNC.SUCCESS:
      return {
        ...state,
        getAppointmentBranchIsSuccess: true,
        isLoadingGetAppointmentBranch: false,
        getAppointmentBranch: action.data,
      };

    case types.GET_APPOINTMENTTIMESLOTLIST_ASYNC.PENDING:
      return {
        ...state,
        getAppointmentTimeslotIsSuccess: false,
        isLoadingAppointmentTimeslotList: true,
        appointmentTimeslotList: null,
      };

    case types.GET_APPOINTMENTTIMESLOTLIST_ASYNC.ERROR:
      return {
        ...state,
        getAppointmentTimeslotIsSuccess: false,
        isLoadingAppointmentTimeslotList: false,
        appointmentTimeslotList: null,
        error: {
          ...state.error,
          getAppointmentTimeslotListError: action.error,
        },
      };

    case types.GET_APPOINTMENTTIMESLOTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getAppointmentTimeslotIsSuccess: true,
        isLoadingAppointmentTimeslotList: false,
        appointmentTimeslotList: action.data,
      };

    case types.GET_APPOINTMENTBRANCHPHYSICIAN_ASYNC.PENDING:
      return {
        ...state,
        getAppointmentBranchPhysicianIsSuccess: false,
        isLoadingAppointmentBranchPhysician: true,
        appointmentBranchPhysician: null,
      };

    case types.GET_APPOINTMENTBRANCHPHYSICIAN_ASYNC.ERROR:
      return {
        ...state,
        getAppointmentBranchPhysicianIsSuccess: false,
        isLoadingAppointmentBranchPhysician: false,
        appointmentBranchPhysician: null,
        error: {
          ...state.error,
          getAppointmentBranchPhysicianError: action.error,
        },
      };

    case types.GET_APPOINTMENTBRANCHPHYSICIAN_ASYNC.SUCCESS:
      return {
        ...state,
        getAppointmentBranchPhysicianIsSuccess: true,
        isLoadingAppointmentBranchPhysician: false,
        appointmentBranchPhysician: action.data,
      };

    case types.GET_APPOINTMENTUSERLIST_ASYNC.PENDING:
      return {
        ...state,
        getAppointmentUserListIsSuccess: false,
        isLoadingAppointmentUserList: true,
        appointmentUserList: null,
      };

    case types.GET_APPOINTMENTUSERLIST_ASYNC.ERROR:
      return {
        ...state,
        getAppointmentUserListIsSuccess: false,
        isLoadingAppointmentUserList: false,
        appointmentUserList: null,
        error: {
          ...state.error,
          appointmentUserListError: action.error,
        },
      };

    case types.GET_APPOINTMENTUSERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getAppointmentUserListIsSuccess: true,
        isLoadingAppointmentUserList: false,
        appointmentUserList: action.data,
      };

    case types.GET_APPOINTMENTTEMPUSERLIST_ASYNC.PENDING:
      return {
        ...state,
        getAppointmentTempUserListIsSuccess: false,
        isLoadingAppointmentTempUserList: true,
        appointmentTempUserList: null,
      };

    case types.GET_APPOINTMENTTEMPUSERLIST_ASYNC.ERROR:
      return {
        ...state,
        getAppointmentTempUserListIsSuccess: false,
        isLoadingAppointmentTempUserList: false,
        appointmentTempUserList: null,
        error: {
          ...state.error,
          appointmentTempUserListError: action.error,
        },
      };

    case types.GET_APPOINTMENTTEMPUSERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getAppointmentTempUserListIsSuccess: true,
        isLoadingAppointmentTempUserList: false,
        appointmentTempUserList: action.data,
      };

    default:
      return state;
  }
};
