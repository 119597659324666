import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/medicineFixedSellingPriceConstants";

export const getMedicineFixedSellingPriceById = {
  pending: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEBYID_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEBYID_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEBYID_ASYNC.ERROR, {
      error,
    }),
};

export const getMedicineFixedSellingPriceList = {
  pending: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICELIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICELIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICELIST_ASYNC.ERROR, {
      error,
    }),
};

export const getMedicineFixedSellingPriceSummaryList = {
  pending: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.ERROR, {
      error,
    }),
};

export const postCreateMedicineFixedSellingPrice = {
  pending: (data) =>
    createAction(types.POST_CREATEMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_CREATEMEDICINEFIXEDSELLINGPRICE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_CREATEMEDICINEFIXEDSELLINGPRICE_ASYNC.ERROR, {
      error,
    }),
};

export const putUpdateMedicineFixedSellingPrice = {
  pending: (data) =>
    createAction(types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICE_ASYNC.ERROR, {
      error,
    }),
};
