import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

import { ReactComponent as EditLogo } from "../../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as RemoveLogo } from "../../../../../assets/coolicons/basic/trash_empty.svg";
import * as generalConstants from "../../../../../_constants/generalConstants";
import {
  getDispensaryBranchesAlliance,
  getMedicineTypesAlliance,
  postFormulasAlliance,
  postMedicineFormulasAlliance,
  postBranchMedicines,
} from "../../../../../actions/workOrderActions";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import NotificationDialog from "../../../../../components/NotificationDialog";

//style
import { useStyles } from "../../../../../components/globalStyles";

export default function GroupOption({
  memoGroupOption,
  groupOption,
  setGroupOption,
  group,
  setGroup,
  memoSelectedGroupIndex,
  medicineList,
  setMedicineList,
  memoSelectedGroup,
  setShowBrewing,
  setShowDelivery,
  setIsRawHerb,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = createFilterOptions();
  const { t } = useTranslation();

  const [confirmDeleteGroupOpen, setConfirmDeleteGroupOpen] = useState(false);
  const [confirmEditGroupNameOpen, setConfirmEditGroupNameOpen] =
    useState(false);
  const [disableBranchs, setDisableBranchs] = useState(true);
  const [disableMedicineTypes, setDisableMedicineTypes] = useState(true);
  const [disableBranchMedicines, setDisableBranchMedicines] = useState(true);
  const [editGroupNameOpen, setEditGroupNameOpen] = useState(false);
  const [selectedMedItem, setSelectedMedItem] = useState("");
  const [selectedFormula, setSelectedFormula] = useState("");
  const prevMedicineFormulasAllianceStatus = useRef();
  const prevbranchchoiceStatus = useRef();
  const prevMedicineTypechoiceStatus = useRef();
  const workOrder = useSelector((state) => state.workOrder);
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    prevMedicineFormulasAllianceStatus.current = false;
    prevMedicineTypechoiceStatus.current = null;
    prevbranchchoiceStatus.current = null;
  }, []);

  useEffect(() => {
    dispatch(getDispensaryBranchesAlliance.pending());
  }, [dispatch]);

  useEffect(() => {
    if (
      workOrder.isLoadingMedicineFormulasAlliance !==
      prevMedicineFormulasAllianceStatus.current
    ) {
      //check the previous ref with current state
      prevMedicineFormulasAllianceStatus.current =
        workOrder.isLoadingMedicineFormulasAlliance;

      if (
        workOrder.getMedicineFormulasAllianceIsSuccess === true &&
        workOrder.isLoadingMedicineFormulasAlliance === false
      ) {
        const elementsIndex = memoSelectedGroupIndex;
        //make a copy of group option
        let oldArray = [...memoGroupOption];

        let storeMedicineDetail = oldArray[elementsIndex].store_medicine_details
          ? oldArray[elementsIndex].store_medicine_details
          : [];

        workOrder.medicineFormulasAlliance.branch_medicines.map(
          (option, index) => {
            const selectedstoreMedicine = {
              id: 0,
              // create quantity
              quantity_per_day: option.medicines.default_quantity_per_day,
              medicine_name: option.medicines.name,
              default_instruction: option.medicines.default_instruction,
              desc: option.medicines.desc,
              dosage: option.medicines.dosage,
              measure_unit: option.medicines.measure_unit,
              remarks: option.medicines.remarks,
              unit_selling_price: option.medicines.unit_selling_price,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
              branch_medicine: option,
            };

            if (Object.keys(storeMedicineDetail).length !== 0) {
              //cant use id to search because it is null for no available medicine
              const repeatMedicine = storeMedicineDetail.some(
                (item) =>
                  item.branch_medicine.medicines.name === option.medicines.name
              );
              if (!repeatMedicine) {
                storeMedicineDetail.push(selectedstoreMedicine);
              }
            } else {
              storeMedicineDetail = [selectedstoreMedicine];
            }

            return "";
          }
        );
        let resultWDelete = storeMedicineDetail.filter(
          (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
        );
        let resultWoDelete = storeMedicineDetail.filter(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        );
        resultWDelete = resultWDelete.map((x, index) => {
          return { ...x, row_no: null };
        });
        resultWoDelete = resultWoDelete.map((x, index) => {
          return { ...x, row_no: index + 1 };
        });

        //setMedicineList(storeMedicineDetail);
        oldArray[elementsIndex] = {
          ...oldArray[elementsIndex],
          store_medicine_details: [...resultWoDelete, ...resultWDelete],
        };
        setGroupOption(oldArray);
      }
      // if (
      //   workOrder.getMedicineFormulasAllianceIsSuccess === false &&
      //   workOrder.isLoadingMedicineFormulasAlliance === false
      // ) {
      //   setSubmitErrorAlarm(true);
      //   setSubmitSuccessAlarm(false);
      //   setMsgAlarm(workOrder.error.submitStoreOrderError);
      // }
    }
  }, [
    workOrder.getMedicineFormulasAllianceIsSuccess,
    workOrder.isLoadingMedicineFormulasAlliance,
    workOrder.error,
    workOrder.medicineFormulasAlliance,
    memoSelectedGroupIndex,
    memoGroupOption,
    setGroupOption,
    setMedicineList,
  ]);

  useEffect(() => {
    if (group && memoSelectedGroup) {
      const selectedGroupResults = memoSelectedGroup;

      if (selectedGroupResults.store_medicine_details) {
        const selectedstoreMedicine =
          selectedGroupResults.store_medicine_details;

        setMedicineList(selectedstoreMedicine);
      } else {
        setMedicineList([]);
        setDisableBranchs(false);
      }
    } else {
      setDisableBranchs(true);
    }
  }, [group, memoSelectedGroup, setMedicineList]);

  useEffect(() => {
    if (memoSelectedGroup) {
      //disable selection of branch and medicine type when there is no group or there is medicine been selected
      if (
        memoSelectedGroup.organisation_branch !== null &&
        disableBranchs === false
      ) {
        dispatch(
          getMedicineTypesAlliance.pending(
            memoSelectedGroup.organisation_branch.organisation.id
          )
        );

        setDisableMedicineTypes(false);
      } else {
        setDisableMedicineTypes(true);
      }
      if (
        memoSelectedGroup.organisation_branch === null ||
        memoSelectedGroup.medicine_type === null
      ) {
        setDisableBranchMedicines(true);

        setShowBrewing(false);
        setShowDelivery(false);
      } else {
        setDisableBranchMedicines(false);
        if (
          memoSelectedGroup.organisation_branch.branch.is_brewing_centre ===
            true &&
          memoSelectedGroup.medicine_type.is_for_brewing === true
        ) {
          setShowBrewing(true);
        } else {
          setShowBrewing(false);
        }
        if (memoSelectedGroup.medicine_type.is_for_brewing === true) {
          setIsRawHerb(true);
        } else {
          setIsRawHerb(false);
        }
        if (
          memoSelectedGroup.organisation_branch.branch.is_dispensary_centre ===
          true
        ) {
          setShowDelivery(true);
        } else {
          setShowDelivery(false);
        }
      }
    }
  }, [
    dispatch,
    memoSelectedGroup,
    disableBranchs,
    setIsRawHerb,
    setShowBrewing,
    setShowDelivery,
  ]);

  useEffect(() => {
    if (memoSelectedGroup) {
      if (
        prevMedicineTypechoiceStatus.current !==
          memoSelectedGroup.medicine_type ||
        prevbranchchoiceStatus.current !== memoSelectedGroup.organisation_branch
      ) {
        prevMedicineTypechoiceStatus.current = memoSelectedGroup.medicine_type;
        prevbranchchoiceStatus.current = memoSelectedGroup.organisation_branch;
        if (
          memoSelectedGroup.organisation_branch !== null &&
          memoSelectedGroup.medicine_type !== null
        ) {
          const choices = {
            medicine_type: memoSelectedGroup.medicine_type,
            organisation_branch: memoSelectedGroup.organisation_branch,
          };
          dispatch(postBranchMedicines.pending(choices));
          dispatch(postFormulasAlliance.pending(choices));
        }
      }
    }
  }, [dispatch, memoSelectedGroup]);

  useEffect(() => {
    //disable selection of branch and medicine type when there is no group or there is medicine been selected
    if (Object.keys(medicineList).length !== 0 || !group) {
      setDisableMedicineTypes(true);
      setDisableBranchs(true);
    } else {
      setDisableBranchs(false);
    }
  }, [medicineList, group]);

  useEffect(() => {
    //disable medicine if there is no group
    if (!group) {
      setDisableBranchMedicines(true);
    }
  }, [group]);

  const handleGroupChange = (event, newValue) => {
    //
    //
    if (newValue !== null) {
      if (typeof newValue === "string") {
        //get current existing groups
        const currentGroup = memoGroupOption.some(
          (item) => item.name === newValue
        );
        //
        if (!currentGroup) {
          setGroupOption((groupOption) => [
            ...groupOption,
            {
              id: 0,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
              price: 0,
              name: newValue,
              medicine_type: null,
              organisation_branch: null,
              is_brewing_services: null,
              is_delivery_services: null,
              number_of_days: null,
              number_of_times: null,
              when_consume: null,
              dispensary_instructions: null,
              patient_instructions: null,
              brewing_instructions: null,
              delivery_postal_code: null,
              delivery_address_details: null,
              delivery_address: null,
              delivery_contact_number: null,
              delivery_name: null,
              delivery_remarks: null,
              delivery_incl_prescription: null,
              delivery_incl_receipt: null,
              is_packs_override: null,
              no_of_packs: null,
            },
          ]);
        }

        setGroup(newValue);
      } else if (newValue.inputValue) {
        setGroupOption((groupOption) => [
          ...groupOption,
          {
            id: 0,
            status_type: generalConstants.APPROVED_STATUS_TYPE,
            price: 0,
            name: newValue.inputValue,
            medicine_type: null,
            organisation_branch: null,
            is_brewing_services: null,
            is_delivery_services: null,
            number_of_days: null,
            number_of_times: null,
            when_consume: null,
            dispensary_instructions: null,
            patient_instructions: null,
            brewing_instructions: null,
            delivery_postal_code: null,
            delivery_address_details: null,
            delivery_address: null,
            delivery_contact_number: null,
            delivery_name: null,
            delivery_remarks: null,
            delivery_incl_prescription: null,
            delivery_incl_receipt: null,
            is_packs_override: null,
            no_of_packs: null,
          },
        ]);

        setGroup(newValue.inputValue);
      } else {
        setGroup(newValue.name);
      }
    } else {
      setGroup(newValue);
    }
  };
  const handleSelectedBranchMedicine = (event, newValue) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let oldArray = [...memoGroupOption];

    const selectedstoreMedicine = {
      id: 0,
      // create quantity
      quantity_per_day: newValue.medicines.default_quantity_per_day,
      medicine_name: newValue.medicines.name,
      default_instruction: newValue.medicines.default_instruction,
      desc: newValue.medicines.desc,
      dosage: newValue.medicines.dosage,
      measure_unit: newValue.medicines.measure_unit,
      remarks: newValue.medicines.remarks,
      unit_selling_price: newValue.medicines.unit_selling_price,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
      branch_medicine: newValue,
    };
    let storeMedicineDetail;

    if (oldArray[elementsIndex].store_medicine_details) {
      const repeatMedicine = oldArray[
        elementsIndex
      ].store_medicine_details.some(
        (item) =>
          item.branch_medicine.medicines.name === newValue.medicines.name
      );
      if (repeatMedicine) {
        storeMedicineDetail = [
          ...oldArray[elementsIndex].store_medicine_details,
        ];
      } else {
        storeMedicineDetail = [
          ...oldArray[elementsIndex].store_medicine_details,
          selectedstoreMedicine,
        ];
      }
    } else {
      storeMedicineDetail = [selectedstoreMedicine];
    }

    let resultWDelete = storeMedicineDetail.filter(
      (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
    );
    let resultWoDelete = storeMedicineDetail.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
    resultWDelete = resultWDelete.map((x, index) => {
      return { ...x, row_no: null };
    });
    resultWoDelete = resultWoDelete.map((x, index) => {
      return { ...x, row_no: index + 1 };
    });

    // setMedicineList(storeMedicineDetail);
    oldArray[elementsIndex] = {
      ...oldArray[elementsIndex],
      store_medicine_details: [...resultWoDelete, ...resultWDelete],
    };

    setGroupOption(oldArray);
  };

  const handleSelectedFormulas = (event, newValue) => {
    const formula = {
      id: newValue.id,
      medicine_type: memoSelectedGroup.medicine_type,
      organisation_branch: memoSelectedGroup.organisation_branch,
    };

    dispatch(postMedicineFormulasAlliance.pending(formula));
  };

  const handleTriggerEditNameWindow = (value) => {
    setConfirmEditGroupNameOpen(value);
    setTextValue("");
  };

  const handleRemoveGroup = () => {
    const result = memoGroupOption.filter((item) => item.name !== group);

    setGroupOption(result);
    setGroup("");
    setMedicineList([]);
  };

  const handleEditGroupName = (value) => {
    setTextValue("");
    const trimValue = value.trim();
    const repeatedName = memoGroupOption.some(
      (item) => item.name === trimValue
    );
    if (repeatedName || trimValue === "") {
      setConfirmEditGroupNameOpen(true);
      setEditGroupNameOpen(true);
    } else {
      const elementsIndex = memoGroupOption.findIndex(
        (item) => item.name === group
      );
      //make a copy of group option
      let newArray = [...memoGroupOption];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        name: trimValue,
      };
      setGroupOption(newArray);
      setGroup(trimValue);
    }
  };

  const handleSetOrganisationBranchChoice = (event, option) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      organisation_branch: option ? option : null,
      medicine_type: null,
    };

    setGroupOption(newArray);
  };

  const handleSetMedicineTypeChoice = (event, option) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      medicine_type: option ? option : null,
      is_brewing_services: false,
      is_delivery_services: false,
      // water_amount: null,
      // brewing_instructions: null,

      // delivery_address: null,
      // delivery_address_details: null,
      // delivery_postal_code: null,
      // delivery_contact_number: null,
      // delivery_name: null,
      // delivery_remarks: null,
      // delivery_incl_prescription: false,
    };

    setGroupOption(newArray);
  };

  return (
    <Grid container>
      <NotificationDialog
        ok={t("translation:Ok")}
        title={t("translation:Error")}
        open={editGroupNameOpen}
        setOpen={setEditGroupNameOpen}>
        {t("translation:Please use a different name")}
      </NotificationDialog>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Delete Group")}
        open={confirmDeleteGroupOpen}
        setOpen={setConfirmDeleteGroupOpen}
        onConfirm={handleRemoveGroup}>
        {t("translation:Are you sure you want to delete this group?")}
      </ConfirmDialog>

      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Edit Group Name")}
        open={confirmEditGroupNameOpen}
        setOpen={handleTriggerEditNameWindow}
        onConfirm={handleEditGroupName}
        requiredText={true}
        textLabel={t("translation:Group")}
        textValue={textValue}
        setTextValue={setTextValue}>
        {t("translation:Are you sure you want to edit this group name?")}
      </ConfirmDialog>
      <Grid item xs={6} className={classes.userInfoField}>
        <Autocomplete
          onChange={handleGroupChange}
          filterOptions={(options, params) => {
            let filtered = filter(options, params);
            let trimValue = null;
            if (params.inputValue) {
              trimValue = params.inputValue.trim();
              params.inputValue = trimValue;
              filtered = filter(options, params);
            }
            // Suggest the creation of a new value
            //search any contain of input
            const group = options.some((item) => item.name === trimValue);
            if (!group) {
              if (trimValue) {
                filtered.push({
                  inputValue: trimValue,
                  name: `${t("translation:Add")} "${trimValue}"`,
                });
              }
            }

            return filtered;
          }}
          value={group}
          blurOnSelect
          selectOnFocus
          clearOnBlur={true}
          handleHomeEndKeys
          disableClearable
          id="Group"
          name="Group"
          cy_data="addOrderGroup"
          options={groupOption}
          getOptionLabel={(option, value) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            if (option.name) {
              return option.name;
            }
            return "";
          }}
          isOptionEqualToValue={(option, value) => option.name === value}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.name}>
                {option.name}
              </li>
            );
          }}
          style={{ width: "100%" }}
          freeSolo
          renderInput={(params) => (
            <TextField
              className={classes.userFieldColor}
              {...params}
              label={t("translation:Group")}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {group && (
                      <InputAdornment position="end">
                        <Button
                          onClick={() => setConfirmEditGroupNameOpen(true)}>
                          <EditLogo title={t("translation:Edit")} />
                        </Button>
                        <Button onClick={() => setConfirmDeleteGroupOpen(true)}>
                          <RemoveLogo />
                        </Button>
                      </InputAdornment>
                    )}

                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={3} className={classes.userInfoField}>
        {workOrder.dispensaryBranchesAlliance && (
          <Autocomplete
            disabled={disableBranchs}
            value={
              memoSelectedGroup ? memoSelectedGroup.organisation_branch : null
            }
            disableClearable
            name="organisation_branch"
            cy_data="addOrderOrganisationBranch"
            id="organisation_branch"
            options={workOrder.dispensaryBranchesAlliance.organisation_branch}
            getOptionLabel={(option) =>
              option.branch ? option.branch.name : ""
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            groupBy={(option) => option.organisation?.name}
            style={{ width: "100%" }}
            onChange={(e, option) =>
              handleSetOrganisationBranchChoice(e, option)
            }
            renderInput={(params) => (
              <TextField
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Dispensary Branch")}
                variant="outlined"
              />
            )}
          />
        )}
      </Grid>

      <Grid item xs={3} className={classes.userInfoField}>
        <Autocomplete
          disabled={disableMedicineTypes}
          value={memoSelectedGroup ? memoSelectedGroup.medicine_type : null}
          disableClearable
          name="medicine_type"
          cy_data="addOrderMedicineType"
          id="medicine_type"
          options={
            workOrder.medicineTypesAlliance
              ? workOrder.medicineTypesAlliance.medicine_types
              : []
          }
          getOptionLabel={(option) => (option.name ? option.name : "")}
          style={{ width: "100%" }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, option) => handleSetMedicineTypeChoice(e, option)}
          renderInput={(params) => (
            <TextField
              className={classes.userFieldColor}
              {...params}
              label={t("translation:Medicine Type")}
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={
          workOrder.formulasAlliance &&
          Object.keys(workOrder.formulasAlliance.formulas).length !== 0
            ? 6
            : 12
        }
        className={classes.userInfoField}>
        <Autocomplete
          disabled={disableBranchMedicines}
          inputValue={selectedMedItem}
          value={selectedMedItem === null ? "" : selectedMedItem}
          name="branchMedicines"
          id="branchMedicines"
          cy_data="addOrderBranchMedicine"
          options={
            workOrder.branchMedicines
              ? workOrder.branchMedicines.branch_medicines
              : []
          }
          getOptionLabel={(option) =>
            option.medicines ? option.medicines.name : ""
          }
          style={{ width: "100%" }}
          isOptionEqualToValue={() => {
            return true;
          }}
          disableClearable
          onChange={(e, option) => {
            handleSelectedBranchMedicine(e, option);
            setSelectedMedItem("");
          }}
          disablePortal
          sx={{
            "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
              '&[aria-selected="true"]': {
                backgroundColor: "rgba(255,255,255)",
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              //clear the field to empty
              onChange={(event) => {
                setSelectedMedItem(event.target.value);
              }}
              className={classes.userFieldColor}
              label={<span>{t("translation:Medicine")}</span>}
              variant="outlined"
            />
          )}
        />
      </Grid>

      {workOrder.formulasAlliance &&
        Object.keys(workOrder.formulasAlliance.formulas).length !== 0 && (
          <Grid item xs={6} className={classes.userInfoField}>
            <Autocomplete
              disabled={disableBranchMedicines}
              inputValue={selectedFormula}
              value={selectedFormula === null ? "" : selectedFormula}
              name="formulas"
              id="formulas"
              options={
                workOrder.formulasAlliance
                  ? workOrder.formulasAlliance.formulas
                  : []
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              groupBy={(option) => option.organisation?.name}
              style={{ width: "100%" }}
              isOptionEqualToValue={() => {
                return true;
              }}
              disableClearable
              onChange={(e, option) => {
                handleSelectedFormulas(e, option);
                setSelectedFormula("");
              }}
              disablePortal
              sx={{
                "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                  '&[aria-selected="true"]': {
                    backgroundColor: "rgba(255,255,255)",
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //clear the field to empty
                  onChange={(event) => {
                    setSelectedFormula(event.target.value);
                  }}
                  className={classes.userFieldColor}
                  label={<span>{t("translation:Formulas")}</span>}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end">
        <Grid xs={3} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            value={groupOption.length ? groupOption.length : 0}
            disabled
            name="totalofgroup"
            label={t("translation:Total Groups")}
            variant="outlined"
            inputProps={{
              style: { textAlign: "right" },
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
