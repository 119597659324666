//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//component

import AddUserMfaPage from "./AddUserMfaPage";
import UserMfaListTable from "./UserMfaListTable";
import * as generalConstants from "../../../../_constants/generalConstants";

import { useStyles } from "../../../../components/globalStyles";

const UserMfaSummaryPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const [informationView, setInformationView] = React.useState(false);
  const [listView, setListView] = React.useState(true); //setUserListView
  const curUser = useSelector((state) => state.curUser);
  const addAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.ADMIN_USER_MFA_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    : false;

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!informationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            {listView ? (
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:User MFA Summary")}
              </Typography>
            ) : (
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Add User MFA")}
              </Typography>
            )}
          </Grid>

          {listView && addAccess ? (
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonColor}
                onClick={() => setListView(!listView)}>
                {t("translation:Add")}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={1}></Grid>
          )}
        </Grid>
      )}
      <hr />
      {!listView && <AddUserMfaPage setListView={setListView} />}
      {listView && <UserMfaListTable setInformationView={setInformationView} />}
    </Paper>
  );
};

export default UserMfaSummaryPage;
