import React from "react";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useStyles } from "../../../components/globalTableStyles";

import { Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

//components
import EditableCell from "../../../components/EditableCell";

function Table({ columns, data, updateMyData, disablePageResetOnDataChange }) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        updateMyData,
        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    <TableCell className={paginationClasses.narrowCellStyle}>
                      {i + 1}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={paginationClasses.narrowCellStyle}
                          {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: cell.column.editable,
                            editableNum: cell.column.editableNum,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const MedicineListTable = ({
  data = {},
  setData,
  onSubmitDispensary,
  measureUnit,
  t,
  isRawHerb = false,
}) => {
  const classes = useStyles();
  const columns = [
    {
      Header: t("translation:Medicine Name"),
      accessor: "store_medicine_details.medicine_name",
    },
    {
      Header: t("translation:Dosage"),
      accessor: "store_medicine_details.dosage",
      Cell: ({ cell }) => {
        return <>{cell.value ? cell.value : ""}</>;
      },
    },
    {
      Header: t("translation:Quantity / Day"),
      accessor: "store_medicine_details.quantity_per_day",
    },

    {
      Header: t("translation:Measure Unit"),
      accessor: "store_medicine_details.measure_unit",
      Cell: ({ cell }) => {
        const cellValue = measureUnit.find((item) => item.id === cell.value);

        return cellValue ? cellValue.name : "";
      },
    },
    {
      Header: t("translation:Dispensed Quantity"),
      accessor: "dispensedDispensary",
    },
    {
      editableNum: true,
      Header: t("translation:Dispensing Quantity"),
      accessor: "dispensingQuantity",
    },
    {
      id: "Submit",
      textAlign: "right",
      accessor: "store_medicine_details",
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ cell }) => (
        <>
          <Link className={classes.maroon075}>
            <Button
              className={classes.maroon075}
              onClick={() => onSubmitDispensary(cell)}>
              {t("translation:SUBMIT")}
            </Button>
          </Link>
        </>
      ),
    },
  ];

  const rawHerbColumns = [
    {
      Header: t("translation:Medicine Name"),
      accessor: "store_medicine_details.medicine_name",
    },
    {
      Header: t("translation:Instruction"),
      accessor: "store_medicine_details.default_instruction",
      Cell: ({ cell }) => {
        return <>{cell.value ? cell.value : ""}</>;
      },
    },
    {
      Header: t("translation:Quantity / Day"),
      accessor: "store_medicine_details.quantity_per_day",
    },

    {
      Header: t("translation:Measure Unit"),
      accessor: "store_medicine_details.measure_unit",
      Cell: ({ cell }) => {
        const cellValue = measureUnit.find((item) => item.id === cell.value);

        return cellValue ? cellValue.name : "";
      },
    },
    {
      Header: t("translation:Dispensed Quantity"),
      accessor: "dispensedDispensary",
    },
    {
      editableNum: true,
      Header: t("translation:Dispensing Quantity"),
      accessor: "dispensingQuantity",
    },
    {
      id: "Submit",
      textAlign: "right",
      accessor: "store_medicine_details",
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ cell }) => (
        <>
          <Link className={classes.maroon075}>
            <Button
              className={`${classes.maroon075} ${classes.bold}`}
              onClick={() => onSubmitDispensary(cell)}>
              {t("translation:SUBMIT")}
            </Button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <Table
      columns={isRawHerb ? rawHerbColumns : columns}
      data={data}
      updateMyData={setData}
    />
  );
};

export default MedicineListTable;
