//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
//component

import EmailStoreSenderListTable from "./EmailStoreSenderListTable";

import { useStyles } from "../../../../components/globalStyles";

const EmailStoreSenderSummaryPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const [informationView, setInformationView] = React.useState(false);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!informationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title} variant="h3" gutterBottom>
              {t("translation:Email Store Sender Summary")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <hr />

      <EmailStoreSenderListTable setInformationView={setInformationView} />
    </Paper>
  );
};

export default EmailStoreSenderSummaryPage;
