//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
//component
import { useStyles } from "../../../../components/globalStyles";
import BranchAdhocScheduleListTable from "./BranchAdhocScheduleListTable";

const BranchAdhocScheduleSummaryPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const [informationView, setInformationView] = React.useState(false);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!informationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <Typography className={classes.title} variant="h3" gutterBottom>
              {t("translation:Branch Adhoc Schedule Summary")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <hr />

      <BranchAdhocScheduleListTable setInformationView={setInformationView} />
    </Paper>
  );
};

export default BranchAdhocScheduleSummaryPage;
