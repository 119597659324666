import { Button, Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../components/globalStyles";

export default function CopyAction({
  handleCopyOnClick = () => null,
  orderDetailInfo,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const copyList = [
    {
      name: "ALL",
      buttonName: "copyAllButton",
      title: t("translation:COPY ALL"),
    },
    {
      name: "DIAGNOSIS",
      buttonName: "copyDiagnosisButton",
      title: t("translation:COPY DIAGNOSIS"),
    },
    {
      name: "MEDICINE",
      buttonName: "copyMedicineButton",
      title: t("translation:COPY MEDICINE"),
    },
    {
      name: "ACUPUNCTURE",
      buttonName: "copyAcupunctureButton",
      title: t("translation:COPY ACUPUNCTURE"),
    },
    {
      name: "TREATMENT",
      buttonName: "copyTreatmentButton",
      title: t("translation:COPY TREATMENT"),
    },
    {
      name: "MISC",
      buttonName: "copyMiscButton",
      title: t("translation:COPY MISC"),
    },
  ];
  return (
    <Grid item xs={12}>
      {copyList.map((item, index) => {
        return (
          <Link key={index} className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              name={item.buttonName}
              onClick={() => {
                handleCopyOnClick(item.name, orderDetailInfo);
              }}>
              {t(`translation:${item.title}`)}
            </Button>
          </Link>
        );
      })}
    </Grid>
  );
}
