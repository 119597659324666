import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const medicineTypeApis = {
  getMedicineTypeListApi,
  putEditMedicineTypeApi,
  getMedicineTypeApi,
  createMedicineTypeApi,
};

async function getMedicineTypeListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetMedicineTypeList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putEditMedicineTypeApi(userModel) {
  const requestOptions = JSON.stringify(userModel);
  return await axios
    .put(apiConstants.apiPutMedicineType,requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineTypeApi(model) {
  const url = apiConstants.apiGetMedicineType + model;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function createMedicineTypeApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiCreateMedicineType, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}


export default medicineTypeApis;
