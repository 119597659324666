import { createContext, useState } from "react";
import socketio from "socket.io-client";

export const SocketContext = createContext();

const SocketProvider = (props) => {
  // this state will be shared with all components
  const [userTokenCookie, setUserTokenCookie] = useState("");

  const socket = socketio("/dashboard", {
    protocol: ["http", "https"],
    autoConnect: false,
    //disable socket to connect
    //autoConnect: false,
    // reconnection: false,
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionDelayMax: 1000,
    timeout: 1000,
    reconnectionAttempts: 2,
    //transports: ["websocket"],
    //transports: ["polling"],
    extraHeaders: {
      "X-USER-XSRF-TOKEN": userTokenCookie,
    },
  });

  return (
    // this is the provider providing state
    <SocketContext.Provider
      value={{
        socket,
        userTokenCookie: [userTokenCookie, setUserTokenCookie],
      }}>
      {props.children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
