import React from "react";
import Typography from "@mui/material/Typography";
import { useStyles } from "../../components/globalStyles";
import Logo403 from "../../assets/403.png";

const OrganisationChanged = ({ history }) => {
  const classes = useStyles();

  return (
    <div className={classes.notificationRoot}>
      <div className={classes.notificationTitleContainer}>
        <Typography
          className={classes.notificationTitle}
          variant="h3"
          gutterBottom>
          <img src={Logo403} height="300px" alt="website logo" />
        </Typography>
        <Typography
          className={classes.notificationTitle}
          variant="h3"
          gutterBottom>
          401
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Organisation has been changed. Please log out and log in again.
        </Typography>
        <Typography variant="subtitle2" gutterBottom></Typography>
        公司已被更改。请从新登录。
      </div>
    </div>
  );
};

export default OrganisationChanged;
