//lib
import React, { useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
//import Marquee from "react-fast-marquee";
//components
import { SocketContext } from "../../components/functions/SocketProvider";
import StoreOrderTable from "./Components/StoreOrderTable";
import DispensaryTable from "./Components/DispensaryTable";
import BrewingTable from "./Components/BrewingTable";
import VisitOrderTable from "./Components/VisitOrderTable";
import DeliveryOrderTable from "./Components/DeliveryOrderTable";
import AppointmentTable from "./Components/AppointmentTable";
import ArrivalTable from "./Components/ArrivalTable";
import InvoiceTable from "./Components/InvoiceTable";
import InvoiceMedicineCostTable from "./Components/InvoiceMedicineCostTable";
import { getCookie } from "../../components/functions/cookie";
import * as generalConstants from "../../_constants/generalConstants";
import { useStyles } from "../../components/globalStyles";
//Actions

export const DefaultHomePage = () => {
  const classes = useStyles();
  const { socket, userTokenCookie } = useContext(SocketContext);

  const [stateUserTokenCookie, setStateUserTokenCookie] = userTokenCookie;

  const curUser = useSelector((state) => state.curUser);

  const common = useSelector((state) => state.common);
  const [dashboardOpen, setDashboardOpen] = useState(null);
  useEffect(() => {
    let dashboardCall = false;
    let dashboardViewNeeds = null;
    let visitViewNeeds = null;
    let appointmentViewNeeds = null;

    curUser.allRightAccess?.user_rights.map((item) => {
      if (item.rights_type === generalConstants.DASHBOARD_RIGHT_TYPE) {
        dashboardViewNeeds = item.min_view_needs;
      }
      if (item.rights_type === generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE) {
        visitViewNeeds = item.min_view_needs;
      }
      if (
        item.rights_type === generalConstants.WORK_ORDER_APPOINTMENT_RIGHT_TYPE
      ) {
        appointmentViewNeeds = item.min_view_needs;
      }
      return item.min_view_needs;
    });

    if (
      dashboardViewNeeds !== null &&
      dashboardViewNeeds === visitViewNeeds &&
      dashboardViewNeeds === appointmentViewNeeds
    ) {
      dashboardCall = true;
    }
    setDashboardOpen(dashboardCall);
  }, [curUser.allRightAccess]);

  useEffect(() => {
    const userTokencookie = getCookie(
      generalConstants.USER_XSRF_TOKEN_COOKIE_NAME
    );
    setStateUserTokenCookie(userTokencookie);
  }, [setStateUserTokenCookie]);

  useEffect(() => {
    let timerId;

    timerId = setTimeout(() => {
      if (!socket.connected && stateUserTokenCookie) {
        socket.connect();
      }
    }, 5000);

    return () => clearTimeout(timerId);
  }, [socket, stateUserTokenCookie]);

  return (
    <Paper className={classes.paper}>
      {/* <Collapse in={true}>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
           <Marquee gradient={false} direction="right" speed={50} pauseOnHover>
            this is a news flash....
          </Marquee>
        </Alert>
      </Collapse> */}
      {common.generalStatusList && curUser.allRightAccess && (
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead></TableHead>
            <TableBody>
              {dashboardOpen && <ArrivalTable />}
              <AppointmentTable />
              {curUser.allRightAccess.user_rights.some(
                (item) =>
                  item.rights_type ===
                  generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE
              ) && <VisitOrderTable />}
              {curUser.allRightAccess.user_rights.some(
                (item) =>
                  item.rights_type ===
                  generalConstants.WORK_ORDER_STORE_RIGHT_TYPE
              ) && <StoreOrderTable />}

              {curUser.allRightAccess.user_rights.some(
                (item) =>
                  item.rights_type ===
                  generalConstants.WORK_ORDER_DISPENSARY_RIGHT_TYPE
              ) && <DispensaryTable />}
              {curUser.allRightAccess.user_rights.some(
                (item) =>
                  item.rights_type ===
                  generalConstants.WORK_ORDER_BREWING_RIGHT_TYPE
              ) && <BrewingTable />}
              {curUser.allRightAccess.user_rights.some(
                (item) =>
                  item.rights_type ===
                  generalConstants.WORK_ORDER_DELIVERY_RIGHT_TYPE
              ) && <DeliveryOrderTable />}

              {curUser.allRightAccess.user_rights.some(
                (item) =>
                  item.rights_type ===
                  generalConstants.WORK_ORDER_INVOICE_RIGHT_TYPE
              ) && <InvoiceTable />}

              {curUser.allRightAccess.user_rights.some(
                (item) =>
                  item.rights_type ===
                  generalConstants.WORK_ORDER_INVOICE_MEDICINE_COSTING_RIGHT_TYPE
              ) && <InvoiceMedicineCostTable />}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};
