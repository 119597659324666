import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/systemScheduleConstants";

export const getSystemScheduleById = {
  pending: (data) =>
    createAction(types.GET_SYSTEMSCHEDULEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_SYSTEMSCHEDULEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_SYSTEMSCHEDULEBYID_ASYNC.ERROR, { error }),
};

export const getSystemScheduleList = {
  pending: (data) =>
    createAction(types.GET_SYSTEMSCHEDULELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_SYSTEMSCHEDULELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_SYSTEMSCHEDULELIST_ASYNC.ERROR, { error }),
};

export const postCreateSystemSchedule = {
  pending: (data) =>
    createAction(types.POST_CREATESYSTEMSCHEDULE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATESYSTEMSCHEDULE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATESYSTEMSCHEDULE_ASYNC.ERROR, { error }),
};

export const putUpdateSystemSchedule = {
  pending: (data) =>
    createAction(types.PUT_UPDATESYSTEMSCHEDULE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATESYSTEMSCHEDULE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATESYSTEMSCHEDULE_ASYNC.ERROR, { error }),
};
