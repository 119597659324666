//lib
import React, { useState, useRef, useEffect } from "react";
import { Grid, TextField } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import Info from "@mui/icons-material/InfoRounded";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";

//components
import { useStyles } from "../../../components/globalTableStyles";
import { useStyles as useStylesGlobal } from "../../../components/globalStyles";
import organisationPriceRounding from "../../../components/functions/organisationPriceRounding";
import * as generalConstants from "../../../_constants/generalConstants";

import ConfirmDialog from "../../../components/ConfirmDialog";
//action
import { getStoreMedicineInfo } from "../../../actions/workOrderActions";

import { getVisitMedicineInfo } from "../../../actions/visitOrderActions";

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({ columns, data, disablePageResetOnDataChange }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,

        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();
  const { t } = useTranslation();
  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    <TableCell className={paginationClasses.narrowCellStyle}>
                      {i + 1}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={paginationClasses.narrowCellStyle}
                          {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: cell.column.editable,
                            editableNum: cell.column.editableNum,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const ViewMedicineListTable = ({
  data = {},
  isRawHerb = false,
  measureUnit,
  t,
  med_details_price_rmt = generalConstants.ROUND_OFF_RMT,
  med_details_price_rpvt = generalConstants.TEN_THOUSANDTH_RPVT,
  infoModule = null,
}) => {
  const dispatch = useDispatch();
  const prevGetInfoStatus = useRef();
  const [infoOpen, setInfoOpen] = useState(false);
  const [medicineInfo, setMedicineInfo] = useState(null);
  const [medicineInfoError, setMedicineInfoError] = useState(null);
  const classes = useStylesGlobal();
  const workOrder = useSelector((state) => state.workOrder);
  const visitOrder = useSelector((state) => state.visitOrder);

  //useEffects
  useEffect(() => {
    prevGetInfoStatus.current = false;
  }, []);

  useEffect(() => {
    if (workOrder.isLoadingGetStoreMedicineInfo !== prevGetInfoStatus.current) {
      //check the previous ref with current state
      prevGetInfoStatus.current = workOrder.isLoadingGetStoreMedicineInfo;

      if (
        workOrder.getStoreMedicineInfoIsSuccess === true &&
        workOrder.isLoadingGetStoreMedicineInfo === false
      ) {
        setMedicineInfo(workOrder.getStoreMedicineInfo.medicines[0]);
        setMedicineInfoError(null);
      }
      if (
        workOrder.getStoreMedicineInfoIsSuccess === false &&
        workOrder.isLoadingGetStoreMedicineInfo === false
      ) {
        setMedicineInfo(null);
        setMedicineInfoError(workOrder.error.getStoreMedicineInfoError);
      }
    }
  }, [
    workOrder.isLoadingGetStoreMedicineInfo,
    workOrder.getStoreMedicineInfoIsSuccess,
    workOrder.getStoreMedicineInfo,
    workOrder.error,
  ]);

  useEffect(() => {
    if (
      visitOrder.isLoadingGetVisitMedicineInfo !== prevGetInfoStatus.current
    ) {
      //check the previous ref with current state
      prevGetInfoStatus.current = visitOrder.isLoadingGetVisitMedicineInfo;

      if (
        visitOrder.getVisitMedicineInfoIsSuccess === true &&
        visitOrder.isLoadingGetVisitMedicineInfo === false
      ) {
        setMedicineInfo(visitOrder.getVisitMedicineInfo.medicines[0]);
        setMedicineInfoError(null);
      }
      if (
        visitOrder.getVisitMedicineInfoIsSuccess === false &&
        visitOrder.isLoadingGetVisitMedicineInfo === false
      ) {
        setMedicineInfo(null);
        setMedicineInfoError(visitOrder.error.getVisitMedicineInfoError);
      }
    }
  }, [
    visitOrder.isLoadingGetVisitMedicineInfo,
    visitOrder.getVisitMedicineInfoIsSuccess,
    visitOrder.getVisitMedicineInfo,
    visitOrder.error,
  ]);

  const handleInfoOnClick = (item) => {
    if (infoModule === "STORE") {
      const submisionJSON = {
        is_pagination: false,
        order_by_direction: null,
        order_by_field: null,
        page_number: null,
        page_size: null,
        search_desc_value: null,
        search_id_value: item.row.original.branch_medicine.medicines.id,
        search_medicine_type_id_value: null,
        search_name_value: null,
        search_status_type_value: null,
      };
      setInfoOpen(true);
      dispatch(getStoreMedicineInfo.pending(submisionJSON));
    }
    if (infoModule === "VISIT") {
      const submisionJSON = {
        is_pagination: false,
        order_by_direction: null,
        order_by_field: null,
        page_number: null,
        page_size: null,
        search_desc_value: null,
        search_id_value: item.row.original.branch_medicine.medicines.id,
        search_medicine_type_id_value: null,
        search_name_value: null,
        search_status_type_value: null,
      };
      setInfoOpen(true);
      dispatch(getVisitMedicineInfo.pending(submisionJSON));
    }
  };

  const rawHerbcolumns = [
    {
      Header: t("translation:Medicine Name"),
      accessor: "medicine_name",
      Cell: ({ cell }) => {
        return (
          <>
            {cell.value}
            {infoModule !== null && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => handleInfoOnClick(cell)}>
                <Info fontSize="inherit" />
              </IconButton>
            )}
          </>
        );
      },
    },
    {
      Header: t("translation:Instruction"),
      accessor: "default_instruction",
    },
    {
      Header: t("translation:Quantity / Day"),
      accessor: "quantity_per_day",
    },

    {
      Header: t("translation:Measure Unit"),
      accessor: "measure_unit",
      Cell: ({ cell }) => {
        const cellValue = measureUnit.find((item) => item.id === cell.value);

        return cellValue ? cellValue.name : "";
      },
    },

    {
      Header: t("translation:Subtotal / Day"),
      Cell: ({ row }) => {
        if (
          row.original.quantity_per_day === null ||
          row.original.quantity_per_day === 0
        ) {
          const subTotalRound = organisationPriceRounding(
            0,
            med_details_price_rmt,
            med_details_price_rpvt
          );
          return <>{subTotalRound}</>;
        } else {
          const subTotalRound = organisationPriceRounding(
            row.original.unit_selling_price * row.original.quantity_per_day,
            med_details_price_rmt,
            med_details_price_rpvt
          );
          return <>{subTotalRound}</>;
        }
      },
    },
  ];

  const columns = [
    {
      Header: t("translation:Medicine Name"),
      accessor: "medicine_name",
      Cell: ({ cell }) => {
        return (
          <>
            {cell.value}
            {infoModule !== null && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => handleInfoOnClick(cell)}>
                <Info fontSize="inherit" />
              </IconButton>
            )}
          </>
        );
      },
    },
    {
      Header: t("translation:Dosage"),
      accessor: "dosage",
      Cell: ({ cell }) => {
        return <>{cell.value ? cell.value : ""}</>;
      },
    },
    {
      Header: t("translation:Quantity / Day"),
      accessor: "quantity_per_day",
    },

    {
      Header: t("translation:Measure Unit"),
      accessor: "measure_unit",
      Cell: ({ cell }) => {
        const cellValue = measureUnit.find((item) => item.id === cell.value);

        return cellValue ? cellValue.name : "";
      },
    },

    {
      Header: t("translation:Subtotal / Day"),
      Cell: ({ row }) => {
        if (
          row.original.quantity_per_day === null ||
          row.original.quantity_per_day === 0
        ) {
          return (
            <>
              {organisationPriceRounding(
                Number(0),
                med_details_price_rmt,
                med_details_price_rpvt
              )}
            </>
          );
        } else {
          const subTotalRound = organisationPriceRounding(
            row.original.unit_selling_price * row.original.quantity_per_day,
            med_details_price_rmt,
            med_details_price_rpvt
          );
          return <>{subTotalRound}</>;
        }
      },
    },
  ];

  return (
    <>
      {" "}
      <ConfirmDialog
        open={infoOpen}
        setOpen={setInfoOpen}
        title={t("translation:Info")}
        maxWidth="md">
        {medicineInfoError !== null && (
          <Alert severity="error">{medicineInfoError}</Alert>
        )}
        {medicineInfo !== null && (
          <Grid className={classes.gridRoot} container>
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                required
                name="name"
                label={t("translation:Name")}
                variant="outlined"
                value={medicineInfo?.name}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                name="info1"
                label={t("translation:Info 1")}
                variant="outlined"
                value={medicineInfo?.info1}
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                name="info2"
                label={t("translation:Info 2")}
                variant="outlined"
                value={medicineInfo?.info2}
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                name="info3"
                label={t("translation:Info 3")}
                variant="outlined"
                value={medicineInfo?.info3}
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                name="info_functionality"
                label={t("translation:Info Functionality")}
                variant="outlined"
                value={medicineInfo?.info_functionality}
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                name="info_precaution"
                label={t("translation:Info Precaution")}
                variant="outlined"
                value={medicineInfo?.info_precaution}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        )}
      </ConfirmDialog>
      <Table columns={isRawHerb ? rawHerbcolumns : columns} data={data} t={t} />
    </>
  );
};

export default ViewMedicineListTable;
