import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
//components

import * as generalConstants from "../../../_constants/generalConstants";

import SocketNotification from "./SocketNotification";

import DeliveryListTable from "../../workOrder/Delivery/DeliveryListTable";

//styles
export default function DeliveryOrderTable() {
  const { t } = useTranslation();

  //variable
  const [refreshTimeStamp, setRefreshTimeStamp] = useState(moment());

  return (
    <SocketNotification
      cellTitle={t("translation:Delivery")}
      setRefreshTimeStamp={setRefreshTimeStamp}
      sockectWorkTypeId={generalConstants.DELIVERY_WORK_ORDER_TYPE}
      localStorageName="deliveryListTableNotification">
      <DeliveryListTable
        refreshTimeStamp={refreshTimeStamp}
        dashboardView={true}
        setRefreshTimeStamp={setRefreshTimeStamp}
      />
    </SocketNotification>
  );
}
