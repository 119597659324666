import * as types from "../_constants/userMedicineFixedSellingPriceConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_USERMEDICINEFIXEDSELLINGPRICEBYID_ASYNC.PENDING:
      return {
        ...state,
        getUserMedicineFixedSellingPriceByIdIsSuccess: false,
        isLoadingGetUserMedicineFixedSellingPriceById: true,
        getUserMedicineFixedSellingPriceById: null,
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICEBYID_ASYNC.ERROR:
      return {
        ...state,
        getUserMedicineFixedSellingPriceByIdIsSuccess: false,
        isLoadingGetUserMedicineFixedSellingPriceById: false,
        getUserMedicineFixedSellingPriceById: null,
        error: {
          ...state.error,
          getUserMedicineFixedSellingPriceByIdError: action.error,
        },
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getUserMedicineFixedSellingPriceByIdIsSuccess: true,
        isLoadingGetUserMedicineFixedSellingPriceById: false,
        getUserMedicineFixedSellingPriceById: action.data,
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICELIST_ASYNC.PENDING:
      return {
        ...state,
        getUserMedicineFixedSellingPriceListIsSuccess: false,
        isLoadingGetUserMedicineFixedSellingPriceList: true,
        getUserMedicineFixedSellingPriceList: null,
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICELIST_ASYNC.ERROR:
      return {
        ...state,
        getUserMedicineFixedSellingPriceListIsSuccess: false,
        isLoadingGetUserMedicineFixedSellingPriceList: false,
        getUserMedicineFixedSellingPriceList: null,
        error: {
          ...state.error,
          getUserMedicineFixedSellingPriceListError: action.error,
        },
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserMedicineFixedSellingPriceListIsSuccess: true,
        isLoadingGetUserMedicineFixedSellingPriceList: false,
        getUserMedicineFixedSellingPriceList: action.data,
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getUserMedicineFixedSellingPriceSummaryListIsSuccess: false,
        isLoadingGetUserMedicineFixedSellingPriceSummaryList: true,
        getUserMedicineFixedSellingPriceSummaryList: null,
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getUserMedicineFixedSellingPriceSummaryListIsSuccess: false,
        isLoadingGetUserMedicineFixedSellingPriceSummaryList: false,
        getUserMedicineFixedSellingPriceSummaryList: null,
        error: {
          ...state.error,
          getUserMedicineFixedSellingPriceSummaryListError: action.error,
        },
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserMedicineFixedSellingPriceSummaryListIsSuccess: true,
        isLoadingGetUserMedicineFixedSellingPriceSummaryList: false,
        getUserMedicineFixedSellingPriceSummaryList: action.data,
      };

    case types.POST_CREATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING:
      return {
        ...state,
        postCreateUserMedicineFixedSellingPriceIsSuccess: false,
        isLoadingPostCreateUserMedicineFixedSellingPrice: true,
        postCreateUserMedicineFixedSellingPrice: null,
      };

    case types.POST_CREATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.ERROR:
      return {
        ...state,
        postCreateUserMedicineFixedSellingPriceIsSuccess: false,
        isLoadingPostCreateUserMedicineFixedSellingPrice: false,
        postCreateUserMedicineFixedSellingPrice: null,
        error: {
          ...state.error,
          postCreateUserMedicineFixedSellingPriceError: action.error,
        },
      };

    case types.POST_CREATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateUserMedicineFixedSellingPriceIsSuccess: true,
        isLoadingPostCreateUserMedicineFixedSellingPrice: false,
        postCreateUserMedicineFixedSellingPrice: action.data,
      };

    case types.PUT_UPDATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING:
      return {
        ...state,
        putUpdateUserMedicineFixedSellingPriceIsSuccess: false,
        isLoadingPutUpdateUserMedicineFixedSellingPrice: true,
        putUpdateUserMedicineFixedSellingPrice: null,
      };

    case types.PUT_UPDATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.ERROR:
      return {
        ...state,
        putUpdateUserMedicineFixedSellingPriceIsSuccess: false,
        isLoadingPutUpdateUserMedicineFixedSellingPrice: false,
        putUpdateUserMedicineFixedSellingPrice: null,
        error: {
          ...state.error,
          putUpdateUserMedicineFixedSellingPriceError: action.error,
        },
      };

    case types.PUT_UPDATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateUserMedicineFixedSellingPriceIsSuccess: true,
        isLoadingPutUpdateUserMedicineFixedSellingPrice: false,
        putUpdateUserMedicineFixedSellingPrice: action.data,
      };

    case types
      .GET_USERMEDICINEFIXEDSELLINGPRICEMEDICINEFIXEDSELLINGPRICELIST_ASYNC
      .PENDING:
      return {
        ...state,
        getUserMedicineFixedSellingPriceMedicineFixedSellingPriceListIsSuccess: false,
        isLoadingGetUserMedicineFixedSellingPriceMedicineFixedSellingPriceList: true,
        getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList: null,
      };

    case types
      .GET_USERMEDICINEFIXEDSELLINGPRICEMEDICINEFIXEDSELLINGPRICELIST_ASYNC
      .ERROR:
      return {
        ...state,
        getUserMedicineFixedSellingPriceMedicineFixedSellingPriceListIsSuccess: false,
        isLoadingGetUserMedicineFixedSellingPriceMedicineFixedSellingPriceList: false,
        getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList: null,
        error: {
          ...state.error,
          getUserMedicineFixedSellingPriceMedicineFixedSellingPriceListError:
            action.error,
        },
      };

    case types
      .GET_USERMEDICINEFIXEDSELLINGPRICEMEDICINEFIXEDSELLINGPRICELIST_ASYNC
      .SUCCESS:
      return {
        ...state,
        getUserMedicineFixedSellingPriceMedicineFixedSellingPriceListIsSuccess: true,
        isLoadingGetUserMedicineFixedSellingPriceMedicineFixedSellingPriceList: false,
        getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList:
          action.data,
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICEUSERLIST_ASYNC.PENDING:
      return {
        ...state,
        getUserMedicineFixedSellingPriceUserListIsSuccess: false,
        isLoadingGetUserMedicineFixedSellingPriceUserList: true,
        getUserMedicineFixedSellingPriceUserList: null,
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICEUSERLIST_ASYNC.ERROR:
      return {
        ...state,
        getUserMedicineFixedSellingPriceUserListIsSuccess: false,
        isLoadingGetUserMedicineFixedSellingPriceUserList: false,
        getUserMedicineFixedSellingPriceUserList: null,
        error: {
          ...state.error,
          getUserMedicineFixedSellingPriceUserListError: action.error,
        },
      };

    case types.GET_USERMEDICINEFIXEDSELLINGPRICEUSERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserMedicineFixedSellingPriceUserListIsSuccess: true,
        isLoadingGetUserMedicineFixedSellingPriceUserList: false,
        getUserMedicineFixedSellingPriceUserList: action.data,
      };

    default:
      return state;
  }
};
