import { createAsyncTypes } from "../_helper/Utilities";

export const GET_BRANCHMEDICINELIST_ASYNC = createAsyncTypes(
  "GET_BRANCHMEDICINELIST"
);

export const GET_BRANCHMEDICINEBYID_ASYNC = createAsyncTypes(
  "GET_BRANCHMEDICINEBYID"
);

export const PUT_UPDATEBRANCHMEDICINE_ASYNC = createAsyncTypes(
  "PUT_UPDATEBRANCHMEDICINE"
);

export const POST_BRANCHMEDICINEBRANCHLIST_ASYNC = createAsyncTypes(
  "POST_BRANCHMEDICINEBRANCHLIST"
);

export const POST_BRANCHMEDICINEMEDICINETYPELIST_ASYNC = createAsyncTypes(
  "POST_BRANCHMEDICINEMEDICINETYPELIST"
);

export const POST_BRANCHMEDICINEMEDICINELIST_ASYNC = createAsyncTypes(
  "POST_BRANCHMEDICINEMEDICINELIST"
);

export const POST_BRANCHMEDICINEBRANCHMEDICINEHISTORYLIST_ASYNC =
  createAsyncTypes("POST_BRANCHMEDICINEBRANCHMEDICINEHISTORYLIST");
