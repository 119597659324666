//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
//Actions
import {
  getEmailStoreOrderList,
  getEmailStoreOrderById,
  putEmailStorePendingStatus,
  putProcessEmailStorePendingStatus,
} from "../../../actions/emailStoreActions";

//Component
import ConfirmDialog from "../../../components/ConfirmDialog";
import { EditEmailStorePage } from "./EditEmailStorePage";
import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import { ReactComponent as ProceedLogo } from "../../../assets/coolicons/media/fast_forward.svg";
import { ReactComponent as ProceedAllLogo } from "../../../assets/coolicons/media/skip_next.svg";
import * as pageConfigure from "../../../_constants/pageConstants";
import * as generalConstants from "../../../_constants/generalConstants";

export default function EmailStoreListTable({ setInformationView }) {
  //variables
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const prevGetEmailStoreOrderByIdOnStatus = useRef();
  const prevGetReadEmailStatus = useRef();
  const prevWorkOrderPendingStatus = useRef();
  const prevWorkOrderProcessPendingStatus = useRef();
  const emailStoreData = useSelector((state) => state.emailStore);
  const common = useSelector((state) => state.common);
  const curUser = useSelector((state) => state.curUser);
  const [selectedValue, setSelectedValue] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmProcessAllOpen, setConfirmProcessAllOpen] = useState(false);
  const [pendingSuccessAlarm, setPendingSuccessAlarm] = useState(false);
  const [pendingErrorAlarm, setPendingErrorAlarm] = useState(false);
  const [pendingMsgAlarm, setPendingMsgAlarm] = React.useState(null);
  const [fetchErrorAlarm, setFetchErrorAlarm] = useState(false);
  const [fetchErrorMsg, setFetchErrorMsg] = useState(null);
  const [processPendingSuccessAlarm, setProcessPendingSuccessAlarm] =
    useState(false);
  const [processPendingErrorAlarm, setProcessPendingErrorAlarm] =
    useState(false);
  const [processPendingMsgAlarm, setProcessPendingMsgAlarm] =
    React.useState(null);

  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_EMAIL_STORE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.NEW_STATUS_TYPE ||
        (row.original.status_type === generalConstants.PENDING_STATUS_TYPE &&
          updateAccess) ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: "",
      accessor: "statusAction",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) => {
        if (
          row.original.status_type === generalConstants.NEW_STATUS_TYPE &&
          updateAccess
        ) {
          return (
            <Button onClick={() => handleRowProcess(row.original)}>
              <ProceedLogo title={t("translation:Proceed")} />
            </Button>
          );
        } else if (
          row.original.status_type === generalConstants.PENDING_STATUS_TYPE &&
          updateAccess
        ) {
          return (
            <Button onClick={() => handleRowAllProcess(row.original)}>
              <ProceedAllLogo title={t("translation:Close")} />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: t("translation:Sender Email"),
      accessor: "sender_email",
    },
    {
      Header: t("translation:Email Subject"),
      accessor: "subject",
    },
    {
      Header: t("translation:Medicine Type"),
      accessor: "medicine_type.name",
      sortable: false,
    },
    {
      Header: t("translation:Received Date"),
      accessor: "email_received_on",
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.email_received_on)
          return moment
            .utc(row.original.email_received_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_name_value: "",
  });

  //Init
  const [editEmailStoreView, setEditEmailStoreView] = React.useState(false);
  const [emailStoreDetailView, setEmailStoreDetailView] = React.useState(false);
  const [emailStoreInfo, setEmailStoreInfo] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");

  //Onclick event

  const handleConfirmProcess = () => {
    dispatch(putEmailStorePendingStatus.pending(selectedValue.id));
    //clear value
    setSelectedValue(null);
  };

  const handleConfirmAllProcess = () => {
    dispatch(putProcessEmailStorePendingStatus.pending(selectedValue.id));
    //clear value
    setSelectedValue(null);
  };

  const handleRowProcess = (value) => {
    setConfirmOpen(true);
    setSelectedValue(value);
  };

  const handleRowAllProcess = (value) => {
    setConfirmProcessAllOpen(true);
    setSelectedValue(value);
  };

  const handleRowEdit = (value) => {
    dispatch(getEmailStoreOrderById.pending(value));
    setEditEmailStoreView(true);
    setEmailStoreInfo(null);
  };

  const handleRowDetail = (value) => {
    dispatch(getEmailStoreOrderById.pending(value));
    setEmailStoreDetailView(true);
    setEmailStoreInfo(null);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_sender_email_value: value,
        search_subject_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_sender_email_value: "",
        search_subject_value: "",
      });
    }
  };

  //useEffects

  useEffect(() => {
    prevGetEmailStoreOrderByIdOnStatus.current = false;
    prevGetReadEmailStatus.current = false;
    prevWorkOrderPendingStatus.current = false;
    prevWorkOrderProcessPendingStatus.current = false;
  }, []);

  useEffect(() => {
    if (emailStoreDetailView === true || editEmailStoreView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [emailStoreDetailView, editEmailStoreView, setInformationView]);

  useEffect(() => {
    if (!editEmailStoreView) {
      dispatch(getEmailStoreOrderList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editEmailStoreView]);

  useEffect(() => {
    if (
      emailStoreData.isLoadingGetEmailStoreOrderById !==
      prevGetEmailStoreOrderByIdOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevGetEmailStoreOrderByIdOnStatus.current =
        emailStoreData.isLoadingGetEmailStoreOrderById;

      if (
        emailStoreData.getEmailStoreOrderByIdIsSuccess === true &&
        emailStoreData.isLoadingGetEmailStoreOrderById === false
      ) {
        setEmailStoreInfo(
          emailStoreData.getEmailStoreOrderById.email_store_header
        );
      }
    }
  }, [emailStoreData, setEmailStoreInfo]);

  useEffect(() => {
    if (
      emailStoreData.isLoadingPostEmailStoreReadEmail !==
      prevGetReadEmailStatus.current
    ) {
      //
      //check the previous ref with current state
      prevGetReadEmailStatus.current =
        emailStoreData.isLoadingPostEmailStoreReadEmail;

      if (
        emailStoreData.postEmailStoreReadEmailIsSuccess === true &&
        emailStoreData.isLoadingPostEmailStoreReadEmail === false
      ) {
        dispatch(getEmailStoreOrderList.pending(pageInfo));
        setFetchErrorAlarm(false);
      }

      if (
        emailStoreData.postEmailStoreReadEmailIsSuccess === false &&
        emailStoreData.isLoadingPostEmailStoreReadEmail === false
      ) {
        setFetchErrorAlarm(true);
        setFetchErrorMsg(emailStoreData.error.postEmailStoreReadEmailError);
      }
    }
  }, [emailStoreData, dispatch]);

  useEffect(() => {
    if (
      emailStoreData.isLoadingPutEmailStorePendingStatus !==
      prevWorkOrderPendingStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderPendingStatus.current =
        emailStoreData.isLoadingPutEmailStorePendingStatus;

      if (
        emailStoreData.putEmailStorePendingStatusIsSuccess === true &&
        emailStoreData.isLoadingPutEmailStorePendingStatus === false
      ) {
        setPendingErrorAlarm(false);
        setPendingSuccessAlarm(true);
        dispatch(getEmailStoreOrderList.pending(pageInfo));
        window.scrollTo(0, 0);
      }

      if (
        emailStoreData.putEmailStorePendingStatusIsSuccess === false &&
        emailStoreData.isLoadingPutEmailStorePendingStatus === false
      ) {
        setPendingErrorAlarm(true);
        setPendingSuccessAlarm(false);
        setPendingMsgAlarm(
          emailStoreData.error.putEmailStorePendingStatusError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, emailStoreData, pageInfo]);

  useEffect(() => {
    if (
      emailStoreData.isLoadingPutProcessEmailStorePendingStatus !==
      prevWorkOrderProcessPendingStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderProcessPendingStatus.current =
        emailStoreData.isLoadingPutProcessEmailStorePendingStatus;

      if (
        emailStoreData.putProcessEmailStorePendingStatusIsSuccess === true &&
        emailStoreData.isLoadingPutProcessEmailStorePendingStatus === false
      ) {
        setProcessPendingErrorAlarm(false);
        setProcessPendingSuccessAlarm(true);
        dispatch(getEmailStoreOrderList.pending(pageInfo));
        window.scrollTo(0, 0);
      }

      if (
        emailStoreData.putProcessEmailStorePendingStatusIsSuccess === false &&
        emailStoreData.isLoadingPutProcessEmailStorePendingStatus === false
      ) {
        setProcessPendingErrorAlarm(true);
        setProcessPendingSuccessAlarm(false);
        setProcessPendingMsgAlarm(
          emailStoreData.error.putProcessEmailStorePendingStatusError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, emailStoreData, pageInfo]);
  if (
    emailStoreData.getEmailStoreOrderListIsSuccess === false &&
    emailStoreData.isLoadingGetEmailStoreOrderList === false
  ) {
    return (
      <Alert severity="error">
        {emailStoreData.error.getEmailStoreOrderListError}
      </Alert>
    );
  } else if (
    common.generalStatusList &&
    !editEmailStoreView &&
    !emailStoreDetailView
  ) {
    return (
      <>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmProcessAllOpen}
          setOpen={setConfirmProcessAllOpen}
          onConfirm={handleConfirmAllProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        <Collapse in={pendingSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.PENDING_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={pendingErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {pendingMsgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={processPendingSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setProcessPendingSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.CLOSED_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>
        <Collapse in={processPendingErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setProcessPendingErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {processPendingMsgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={fetchErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setFetchErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {fetchErrorMsg}
          </Alert>
        </Collapse>
        <SortingSearchTable
          tableHeading={""}
          searchPlaceholder={t("translation:Sender Email/ Email Subject")}
          statusPlaceholder={t("translation:Order Status")}
          statusOption={common.generalStatusList.status_general}
          searchValue={searchValue}
          columns={columns}
          data={
            emailStoreData.getEmailStoreOrderList?.email_store_headers !==
            undefined
              ? emailStoreData.getEmailStoreOrderList?.email_store_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            emailStoreData.getEmailStoreOrderList
              ? emailStoreData.getEmailStoreOrderList.total_pages
              : 0
          }
          loading={emailStoreData.isLoadingEmailStoreList}
          totalItems={
            emailStoreData.getEmailStoreOrderList
              ? emailStoreData.getEmailStoreOrderList.total_items
              : 0
          }
          handleSearch={handleSearch}
        />
      </>
    );
  }
  //  else if (
  //   emailStoreData.getEmailStoreById &&
  //   emailStoreDetailView &&
  //   emailStoreData.getEmailStoreByIdIsSuccess === true
  // ) {
  //   return (
  //     <EditEmailStorePage
  //       emailStore={emailStoreData.getEmailStoreById.email_store_headers}
  //       setEditEmailStoreView={setEmailStoreDetailView}
  //       viewOnly
  //     />
  //   );
  // } else if (
  //   emailStoreData.getEmailStoreById &&
  //   editEmailStoreView &&
  //   emailStoreData.getEmailStoreByIdIsSuccess === true
  // ) {
  //   return (
  //     <EditEmailStorePage
  //       emailStore={emailStoreData.getEmailStoreById.email_store_headers}
  //       setEditEmailStoreView={setEditEmailStoreView}
  //     />
  //   );
  // }
  else if (emailStoreDetailView && emailStoreInfo) {
    return (
      <EditEmailStorePage
        emailStore={emailStoreInfo}
        setEditEmailStoreView={setEmailStoreDetailView}
        viewOnly
      />
    );
  } else if (editEmailStoreView && emailStoreInfo) {
    return (
      <EditEmailStorePage
        emailStore={emailStoreInfo}
        setEmailStoreInfo={setEmailStoreInfo}
        setEditEmailStoreView={setEditEmailStoreView}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
