import { createAsyncTypes } from "../_helper/Utilities";

export const GET_USERDEVICEKEYBYID_ASYNC = createAsyncTypes(
  "GET_USERDEVICEKEYBYID"
);

export const GET_USERDEVICEKEYLIST_ASYNC = createAsyncTypes(
  "GET_USERDEVICEKEYLIST"
);

export const POST_CREATEUSERDEVICEKEY_ASYNC = createAsyncTypes(
  "POST_CREATEUSERDEVICEKEY"
);

export const PUT_UPDATEUSERDEVICEKEY_ASYNC = createAsyncTypes(
  "PUT_UPDATEUSERDEVICEKEY"
);
