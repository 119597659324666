import onlyFixedNumerics from "./onlyFixedNumerics";

const amountInCentToDollarNum = (amount) => {
  const amountInNum = onlyFixedNumerics(amount);

  const amountInDollar = (amountInNum / 100).toFixed(2);

  return amountInDollar;
};

export default amountInCentToDollarNum;
