import * as types from "../_constants/cloudStorageConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.POST_CREATEPRESIGNEDURL_ASYNC.PENDING:
      return {
        ...state,
        postCreatePresignedUrlIsSuccess: false,
        isLoadingPostCreatePresignedUrl: true,
        postCreatePresignedUrl: null,
        error: { ...state.error, postCreatePresignedUrlError: null },
      };

    case types.POST_CREATEPRESIGNEDURL_ASYNC.ERROR:
      return {
        ...state,
        postCreatePresignedUrlIsSuccess: false,
        isLoadingPostCreatePresignedUrl: false,
        postCreatePresignedUrl: null,
        error: { ...state.error, postCreatePresignedUrlError: action.error },
      };

    case types.POST_CREATEPRESIGNEDURL_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePresignedUrlIsSuccess: true,
        isLoadingPostCreatePresignedUrl: false,
        postCreatePresignedUrl: action.data,
        error: { ...state.error, postCreatePresignedUrlError: null },
      };

    case types.POST_CREATEPRESIGNEDJSON_ASYNC.PENDING:
      return {
        ...state,
        postCreatePresignedJsonIsSuccess: false,
        isLoadingPostCreatePresignedJson: true,
        postCreatePresignedJson: null,
        error: { ...state.error, postCreatePresignedJsonError: null },
      };

    case types.POST_CREATEPRESIGNEDJSON_ASYNC.ERROR:
      return {
        ...state,
        postCreatePresignedJsonIsSuccess: false,
        isLoadingPostCreatePresignedJson: false,
        postCreatePresignedJson: null,
        error: { ...state.error, postCreatePresignedJsonError: action.error },
      };

    case types.POST_CREATEPRESIGNEDJSON_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePresignedJsonIsSuccess: true,
        isLoadingPostCreatePresignedJson: false,
        postCreatePresignedJson: action.data,
        error: { ...state.error, postCreatePresignedJsonError: null },
      };

    case types.POST_CREATEPRESIGNEDURLPHOTO_ASYNC.PENDING:
      return {
        ...state,
        postCreatePresignedUrlPhotoIsSuccess: false,
        isLoadingPostCreatePresignedUrlPhoto: true,
        postCreatePresignedUrlPhoto: null,
        error: { ...state.error, postCreatePresignedUrlPhotoError: null },
      };

    case types.POST_CREATEPRESIGNEDURLPHOTO_ASYNC.ERROR:
      return {
        ...state,
        postCreatePresignedUrlPhotoIsSuccess: false,
        isLoadingPostCreatePresignedUrlPhoto: false,
        postCreatePresignedUrlPhoto: null,
        error: {
          ...state.error,
          postCreatePresignedUrlPhotoError: action.error,
        },
      };

    case types.POST_CREATEPRESIGNEDURLPHOTO_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePresignedUrlPhotoIsSuccess: true,
        isLoadingPostCreatePresignedUrlPhoto: false,
        postCreatePresignedUrlPhoto: action.data,
        error: { ...state.error, postCreatePresignedUrlPhotoError: null },
      };

    case types.POST_CREATEPRESIGNEDJSONPHOTO_ASYNC.PENDING:
      return {
        ...state,
        postCreatePresignedJsonPhotoIsSuccess: false,
        isLoadingPostCreatePresignedJsonPhoto: true,
        postCreatePresignedJsonPhoto: null,
        error: { ...state.error, postCreatePresignedJsonPhotoError: null },
      };

    case types.POST_CREATEPRESIGNEDJSONPHOTO_ASYNC.ERROR:
      return {
        ...state,
        postCreatePresignedJsonPhotoIsSuccess: false,
        isLoadingPostCreatePresignedJsonPhoto: false,
        postCreatePresignedJsonPhoto: null,
        error: {
          ...state.error,
          postCreatePresignedJsonPhotoError: action.error,
        },
      };

    case types.POST_CREATEPRESIGNEDJSONPHOTO_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePresignedJsonPhotoIsSuccess: true,
        isLoadingPostCreatePresignedJsonPhoto: false,
        postCreatePresignedJsonPhoto: action.data,
        error: { ...state.error, postCreatePresignedJsonPhotoError: null },
      };

    case types.POST_CREATEPRESIGNEDURLSIGNATURE_ASYNC.PENDING:
      return {
        ...state,
        postCreatePresignedUrlSignatureIsSuccess: false,
        isLoadingPostCreatePresignedUrlSignature: true,
        postCreatePresignedUrlSignature: null,
        error: { ...state.error, postCreatePresignedUrlSignatureError: null },
      };

    case types.POST_CREATEPRESIGNEDURLSIGNATURE_ASYNC.ERROR:
      return {
        ...state,
        postCreatePresignedUrlSignatureIsSuccess: false,
        isLoadingPostCreatePresignedUrlSignature: false,
        postCreatePresignedUrlSignature: null,
        error: {
          ...state.error,
          postCreatePresignedUrlSignatureError: action.error,
        },
      };

    case types.POST_CREATEPRESIGNEDURLSIGNATURE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePresignedUrlSignatureIsSuccess: true,
        isLoadingPostCreatePresignedUrlSignature: false,
        postCreatePresignedUrlSignature: action.data,
        error: { ...state.error, postCreatePresignedUrlSignatureError: null },
      };

    case types.POST_CREATEPRESIGNEDJSONSIGNATURE_ASYNC.PENDING:
      return {
        ...state,
        postCreatePresignedJsonSignatureIsSuccess: false,
        isLoadingPostCreatePresignedJsonSignature: true,
        postCreatePresignedJsonSignature: null,
        error: { ...state.error, postCreatePresignedJsonSignatureError: null },
      };

    case types.POST_CREATEPRESIGNEDJSONSIGNATURE_ASYNC.ERROR:
      return {
        ...state,
        postCreatePresignedJsonSignatureIsSuccess: false,
        isLoadingPostCreatePresignedJsonSignature: false,
        postCreatePresignedJsonSignature: null,
        error: {
          ...state.error,
          postCreatePresignedJsonSignatureError: action.error,
        },
      };

    case types.POST_CREATEPRESIGNEDJSONSIGNATURE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePresignedJsonSignatureIsSuccess: true,
        isLoadingPostCreatePresignedJsonSignature: false,
        postCreatePresignedJsonSignature: action.data,
        error: { ...state.error, postCreatePresignedJsonSignatureError: null },
      };

    case types.POST_CREATEPRESIGNEDURLORGANISATIONLOGO_ASYNC.PENDING:
      return {
        ...state,
        postCreatePresignedUrlOrganisationLogoIsSuccess: false,
        isLoadingPostCreatePresignedUrlOrganisationLogo: true,
        postCreatePresignedUrlOrganisationLogo: null,
        error: {
          ...state.error,
          postCreatePresignedUrlOrganisationLogoError: null,
        },
      };

    case types.POST_CREATEPRESIGNEDURLORGANISATIONLOGO_ASYNC.ERROR:
      return {
        ...state,
        postCreatePresignedUrlOrganisationLogoIsSuccess: false,
        isLoadingPostCreatePresignedUrlOrganisationLogo: false,
        postCreatePresignedUrlOrganisationLogo: null,
        error: {
          ...state.error,
          postCreatePresignedUrlOrganisationLogoError: action.error,
        },
      };

    case types.POST_CREATEPRESIGNEDURLORGANISATIONLOGO_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePresignedUrlOrganisationLogoIsSuccess: true,
        isLoadingPostCreatePresignedUrlOrganisationLogo: false,
        postCreatePresignedUrlOrganisationLogo: action.data,
        error: {
          ...state.error,
          postCreatePresignedUrlOrganisationLogoError: null,
        },
      };

    case types.POST_CREATEPRESIGNEDJSONDELIVERYTRACKING_ASYNC.PENDING:
      return {
        ...state,
        postCreatePresignedJsonDeliveryTrackingIsSuccess: false,
        isLoadingPostCreatePresignedJsonDeliveryTracking: true,
        postCreatePresignedJsonDeliveryTracking: null,
        error: {
          ...state.error,
          postCreatePresignedJsonDeliveryTrackingError: null,
        },
      };

    case types.POST_CREATEPRESIGNEDJSONDELIVERYTRACKING_ASYNC.ERROR:
      return {
        ...state,
        postCreatePresignedJsonDeliveryTrackingIsSuccess: false,
        isLoadingPostCreatePresignedJsonDeliveryTracking: false,
        postCreatePresignedJsonDeliveryTracking: null,
        error: {
          ...state.error,
          postCreatePresignedJsonDeliveryTrackingError: action.error,
        },
      };

    case types.POST_CREATEPRESIGNEDJSONDELIVERYTRACKING_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePresignedJsonDeliveryTrackingIsSuccess: true,
        isLoadingPostCreatePresignedJsonDeliveryTracking: false,
        postCreatePresignedJsonDeliveryTracking: action.data,
        error: {
          ...state.error,
          postCreatePresignedJsonDeliveryTrackingError: null,
        },
      };

    case types.POST_CREATEPRESIGNEDJSONINVOICE_ASYNC.PENDING:
      return {
        ...state,
        postCreatePresignedJsonInvoiceIsSuccess: false,
        isLoadingPostCreatePresignedJsonInvoice: true,
        postCreatePresignedJsonInvoice: null,
        error: {
          ...state.error,
          postCreatePresignedJsonInvoiceError: null,
        },
      };

    case types.POST_CREATEPRESIGNEDJSONINVOICE_ASYNC.ERROR:
      return {
        ...state,
        postCreatePresignedJsonInvoiceIsSuccess: false,
        isLoadingPostCreatePresignedJsonInvoice: false,
        postCreatePresignedJsonInvoice: null,
        error: {
          ...state.error,
          postCreatePresignedJsonInvoiceError: action.error,
        },
      };

    case types.POST_CREATEPRESIGNEDJSONINVOICE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePresignedJsonInvoiceIsSuccess: true,
        isLoadingPostCreatePresignedJsonInvoice: false,
        postCreatePresignedJsonInvoice: action.data,
        error: {
          ...state.error,
          postCreatePresignedJsonInvoiceError: null,
        },
      };

    default:
      return state;
  }
};
