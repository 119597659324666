import { createAsyncTypes } from "../_helper/Utilities";

export const GET_TREATMENTBYID_ASYNC = createAsyncTypes("GET_TREATMENTBYID");

export const GET_TREATMENTLIST_ASYNC = createAsyncTypes("GET_TREATMENTLIST");

export const POST_CREATETREATMENT_ASYNC = createAsyncTypes(
  "POST_CREATETREATMENT"
);

export const PUT_UPDATETREATMENT_ASYNC = createAsyncTypes(
  "PUT_UPDATETREATMENT"
);

export const GET_TREATMENTTREATMENTTYPES_ASYNC = createAsyncTypes(
  "GET_TREATMENTTREATMENTTYPES"
);
