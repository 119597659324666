import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FormulaSummaryPage from "./FormulaSummary/FormulaSummaryPage";
export default function FormulaPage() {
  return (
    <Switch>
      <Redirect exact={true} from="/formula" to="/formula/formulaSummary" />
      <Route path="/formula/formulaSummary" component={FormulaSummaryPage} />

      <Redirect to="/error" />
    </Switch>
  );
}
