import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/branchAdhocScheduleConstants";

export const getBranchAdhocScheduleList = {
  pending: (data) =>
    createAction(types.GET_BRANCHADHOCSCHEDULELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHADHOCSCHEDULELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHADHOCSCHEDULELIST_ASYNC.ERROR, { error }),
};

export const getBranchAdhocScheduleById = {
  pending: (data) =>
    createAction(types.GET_BRANCHADHOCSCHEDULEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHADHOCSCHEDULEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHADHOCSCHEDULEBYID_ASYNC.ERROR, { error }),
};

export const postBranchAdhocScheduleByBranchId = {
  pending: (data) =>
    createAction(types.POST_BRANCHADHOCSCHEDULEBYBRANCHID_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_BRANCHADHOCSCHEDULEBYBRANCHID_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_BRANCHADHOCSCHEDULEBYBRANCHID_ASYNC.ERROR, {
      error,
    }),
};

export const getBranchAdhocScheduleBranchList = {
  pending: (data) =>
    createAction(types.GET_BRANCHADHOCSCHEDULEBRANCHLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_BRANCHADHOCSCHEDULEBRANCHLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_BRANCHADHOCSCHEDULEBRANCHLIST_ASYNC.ERROR, {
      error,
    }),
};
