import { createAsyncTypes } from "../_helper/Utilities";

export const GET_EMAILSTORORDEREBYID_ASYNC = createAsyncTypes(
  "GET_EMAILSTORORDEREBYID"
);

export const GET_EMAILSTOREORDERLIST_ASYNC = createAsyncTypes(
  "GET_EMAILSTOREORDERLIST"
);

export const POST_EMAILSTOREREADEMAIL_ASYNC = createAsyncTypes(
  "POST_EMAILSTOREREADEMAIL"
);

export const PUT_EMAILSTOREPENDINGSTATUS_ASYNC = createAsyncTypes(
  "PUT_EMAILSTOREPENDINGSTATUS"
);

export const PUT_PROCESSEMAILSTOREPENDINGSTATUS_ASYNC = createAsyncTypes(
  "PUT_PROCESSEMAILSTOREPENDINGSTATUS"
);

export const PUT_UPDATEEMAILSTORE_ASYNC = createAsyncTypes(
  "PUT_UPDATEEMAILSTORE"
);

export const GET_EMAILSTOREBRANCHMEDICINE_ASYNC = createAsyncTypes(
  "GET_EMAILSTOREBRANCHMEDICINE"
);
