import * as types from "../_constants/formulaConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_FORMULABYID_ASYNC.PENDING:
      return {
        ...state,
        getFormulaByIdIsSuccess: false,
        isLoadingGetFormulaById: true,
        getFormulaById: null,
      };

    case types.GET_FORMULABYID_ASYNC.ERROR:
      return {
        ...state,
        getFormulaByIdIsSuccess: false,
        isLoadingGetFormulaById: false,
        getFormulaById: null,
        error: { ...state.error, getFormulaByIdError: action.error },
      };

    case types.GET_FORMULABYID_ASYNC.SUCCESS:
      return {
        ...state,
        getFormulaByIdIsSuccess: true,
        isLoadingGetFormulaById: false,
        getFormulaById: action.data,
      };

    case types.GET_FORMULALIST_ASYNC.PENDING:
      return {
        ...state,
        getFormulaListIsSuccess: false,
        isLoadingGetFormulaList: true,
        getFormulaList: null,
      };

    case types.GET_FORMULALIST_ASYNC.ERROR:
      return {
        ...state,
        getFormulaListIsSuccess: false,
        isLoadingGetFormulaList: false,
        getFormulaList: null,
        error: { ...state.error, getFormulaListError: action.error },
      };

    case types.GET_FORMULALIST_ASYNC.SUCCESS:
      return {
        ...state,
        getFormulaListIsSuccess: true,
        isLoadingGetFormulaList: false,
        getFormulaList: action.data,
      };

    case types.POST_CREATEFORMULA_ASYNC.PENDING:
      return {
        ...state,
        postCreateFormulaIsSuccess: false,
        isLoadingPostCreateFormula: true,
        postCreateFormula: null,
      };

    case types.POST_CREATEFORMULA_ASYNC.ERROR:
      return {
        ...state,
        postCreateFormulaIsSuccess: false,
        isLoadingPostCreateFormula: false,
        postCreateFormula: null,
        error: { ...state.error, postCreateFormulaError: action.error },
      };

    case types.POST_CREATEFORMULA_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateFormulaIsSuccess: true,
        isLoadingPostCreateFormula: false,
        postCreateFormula: action.data,
      };

    case types.PUT_UPDATEFORMULA_ASYNC.PENDING:
      return {
        ...state,
        putUpdateFormulaIsSuccess: false,
        isLoadingPutUpdateFormula: true,
        putUpdateFormula: null,
      };

    case types.PUT_UPDATEFORMULA_ASYNC.ERROR:
      return {
        ...state,
        putUpdateFormulaIsSuccess: false,
        isLoadingPutUpdateFormula: false,
        putUpdateFormula: null,
        error: { ...state.error, putUpdateFormulaError: action.error },
      };

    case types.PUT_UPDATEFORMULA_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateFormulaIsSuccess: true,
        isLoadingPutUpdateFormula: false,
        putUpdateFormula: action.data,
      };

    case types.GET_FORMULADISPENSARYBRANCH_ASYNC.PENDING:
      return {
        ...state,
        getFormulaDispensaryBranchIsSuccess: false,
        isLoadingGetFormulaDispensaryBranch: true,
        getFormulaDispensaryBranch: null,
      };

    case types.GET_FORMULADISPENSARYBRANCH_ASYNC.ERROR:
      return {
        ...state,
        getFormulaDispensaryBranchIsSuccess: false,
        isLoadingGetFormulaDispensaryBranch: false,
        getFormulaDispensaryBranch: null,
        error: {
          ...state.error,
          getFormulaDispensaryBranchError: action.error,
        },
      };

    case types.GET_FORMULADISPENSARYBRANCH_ASYNC.SUCCESS:
      return {
        ...state,
        getFormulaDispensaryBranchIsSuccess: true,
        isLoadingGetFormulaDispensaryBranch: false,
        getFormulaDispensaryBranch: action.data,
      };

    case types.GET_FORMULAMEDICINETYPEBYID_ASYNC.PENDING:
      return {
        ...state,
        getFormulaMedicineTypeByIdIsSuccess: false,
        isLoadingGetFormulaMedicineTypeById: true,
        getFormulaMedicineTypeById: null,
      };

    case types.GET_FORMULAMEDICINETYPEBYID_ASYNC.ERROR:
      return {
        ...state,
        getFormulaMedicineTypeByIdIsSuccess: false,
        isLoadingGetFormulaMedicineTypeById: false,
        getFormulaMedicineTypeById: null,
        error: {
          ...state.error,
          getFormulaMedicineTypeByIdError: action.error,
        },
      };

    case types.GET_FORMULAMEDICINETYPEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getFormulaMedicineTypeByIdIsSuccess: true,
        isLoadingGetFormulaMedicineTypeById: false,
        getFormulaMedicineTypeById: action.data,
      };

    default:
      return state;
  }
};
