import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Link, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { postUpdateHomecareManualHc2cms } from "../../../../actions/homecareActions";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import Panel from "../../../../components/Panel";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
//style
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
export default function SyncVisitOrderByStoreOrderNo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [storeOrderNo, setStoreOrderNo] = useState(null);
  const [syncSuccessAlarm, setSyncSuccessAlarm] = useState(false);
  const [syncErrorAlarm, setSyncErrorAlarm] = useState(false);
  const [syncMsgAlarm, setSyncMsgAlarm] = React.useState(null);
  const prevSyncStatus = useRef();
  const homecare = useSelector((state) => state.homecare);
  //const common = useSelector((state) => state.common);

  const onSubmit = (data) => {
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const jsonText = {
      search_order_number_value: storeOrderNo,
    };

    dispatch(postUpdateHomecareManualHc2cms.pending(jsonText));
  };

  useEffect(() => {
    //set ref when first render
    prevSyncStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      homecare.isLoadingPostUpdateHomecareManualHc2cms !==
      prevSyncStatus.current
    ) {
      //check the previous ref with current state
      prevSyncStatus.current = homecare.isLoadingPostUpdateHomecareManualHc2cms;

      if (
        homecare.postUpdateHomecareManualHc2cmsSuccess === true &&
        homecare.isLoadingPostUpdateHomecareManualHc2cms === false
      ) {
        setSyncSuccessAlarm(true);
      }
      if (
        homecare.postUpdateHomecareManualHc2cmsSuccess === false &&
        homecare.isLoadingPostUpdateHomecareManualHc2cms === false
      ) {
        setSyncMsgAlarm(homecare.error.updateHomecareManualHc2cmsError);
        setSyncErrorAlarm(true);
      }
    }
  }, [homecare]);

  return (
    <Panel>
      <Collapse in={syncSuccessAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSyncSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Sync has been submitted")}
        </Alert>
      </Collapse>

      <Collapse in={syncErrorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSyncErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {syncMsgAlarm}
        </Alert>
      </Collapse>

      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12}>
          <Grid xs={12} item className={classes.subTotalInfoField}>
            <TextField
              className={classes.userFieldColor}
              required
              id="amt_bf_tax"
              name="amt_bf_tax"
              label={t("translation:Store Order No")}
              variant="outlined"
              value={storeOrderNo}
              onChange={(e) => setStoreOrderNo(e.target.value)}
            />
          </Grid>
          <RequiredNote />
          <Grid item xs={12} className={classes.relativePositionWrapper}>
            <Grid item xs={12} className={classes.rightAbsolutePosition}>
              <Link className={classes.boldMaroon075}>
                <Button
                  className={classes.boldMaroon075}
                  type="submit"
                  name="synButton">
                  {t("translation:Sync Visit Order By Store Order No")}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Panel>
  );
}
