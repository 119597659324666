import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/treatmentConstants";

export const getTreatmentById = {
  pending: (data) =>
    createAction(types.GET_TREATMENTBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_TREATMENTBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_TREATMENTBYID_ASYNC.ERROR, { error }),
};

export const getTreatmentList = {
  pending: (data) =>
    createAction(types.GET_TREATMENTLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_TREATMENTLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_TREATMENTLIST_ASYNC.ERROR, { error }),
};

export const postCreateTreatment = {
  pending: (data) =>
    createAction(types.POST_CREATETREATMENT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATETREATMENT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATETREATMENT_ASYNC.ERROR, { error }),
};

export const putUpdateTreatment = {
  pending: (data) =>
    createAction(types.PUT_UPDATETREATMENT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATETREATMENT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATETREATMENT_ASYNC.ERROR, { error }),
};

export const getTreatmentTreatmentTypes = {
  pending: (data) =>
    createAction(types.GET_TREATMENTTREATMENTTYPES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_TREATMENTTREATMENTTYPES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_TREATMENTTREATMENTTYPES_ASYNC.ERROR, { error }),
};
