//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { FormControl, Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//action
import {
  getGender,
  getNationality,
  getMarital,
  getRace,
  getLanguage,
} from "../../../../actions/commonActions";
import { postCreateUser } from "../../../../actions/userActions";
import { postVisitCreateUser } from "../../../../actions/visitOrderActions";
//components
import Panel from "../../../../components/Panel";
import externetApis from "../../../../_apis/externetApis";
import allowOnlyNumericsOrDigits from "../../../../components/functions/allowOnlyNumericsOrDigits";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
const AddUserPage = ({
  setUserListView,
  isPatientEdit = false,
  setScroll,
  defaultUserSearchState,
  setDefaultUserSearchState,
}) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState(null);
  const [age, setAge] = React.useState(null);
  const common = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);
  const visitOrder = useSelector((state) => state.visitOrder);
  const prevPostUserStatus = useRef();
  const prevPostVisitUserStatus = useRef();
  const {
    register,
    handleSubmit,
    setValue,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: {
      username: null,
      name: null,
      identification_number: null,
      gender_type: null,
      date_of_birth: null,
      marital_type: null,

      email: null,
      postal_code: null,
      address_details: null,
      address: null,
      blk_no: null,
      building: null,
      road_name: null,
      contact_number: null,
      occupation: null,
      race: null,
      nationality: null,
      language: null,
      allergy: null,
      nok_contact_name: null,
      nok_contact_number: null,
    },
  });
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open;
  const [postcodeSearchPageNum, setPostcodeSearchPageNum] = useState(null);
  const [postcodeSearch, setPostcodeSearch] = useState(null);
  const [addressDisabled, setAddressDisabled] = React.useState(false);
  const [selectedPostalCode, setSelectedPostalCode] = useState(null);

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  //functions

  const handlePostalCodeChange = (event) => {
    setSelectedPostalCode(event.target.value);
    if (event.target.value.length === 6) {
      setOpen(true);
      setPostcodeSearch(event.target.value);
      setOptions([]);
      setPostcodeSearchPageNum(1);
    }
    setAddressDisabled(false);
    setValue("address", null);
    setValue("blk_no", null);
    setValue("road_name", null);
    setValue("building", null);
    setValue("postal_code", null);
  };

  const handleSelectedPostalCode = (event) => {
    if (addressDisabled) {
      setSelectedPostalCode(event.target.value);
    } else {
      setSelectedPostalCode(null);
    }
  };

  const handleAddressChange = (option) => {
    if (option) {
      setAddressDisabled(true);

      // const addressWithoutPostalIndex = option.ADDRESS.lastIndexOf("SINGAPORE");
      // const addressWithoutPostal = option.ADDRESS.substring(
      //   0,
      //   addressWithoutPostalIndex - 1
      // );

      setValue("address", option.ADDRESS);
      setValue("blk_no", option.BLK_NO);
      setValue("road_name", option.ROAD_NAME);
      setValue("building", option.BUILDING !== "NIL" ? option.BUILDING : null);
      setSelectedPostalCode(option.POSTAL);
      setValue("postal_code", option.POSTAL);
    }
  };

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (isPatientEdit) {
      setScroll(true);
      dispatch(postVisitCreateUser.pending({ user: data }));
    } else {
      dispatch(postCreateUser.pending(data));
    }
  };

  const handleDateOfBirth = (value) => {
    var birthYear = dayjs(value).format("YYYY-MM-DD");
    var userAge = Math.floor(moment().diff(birthYear, "years", true));
    setAge(userAge ? userAge : 0); // set age through reat hook form because it need to initial.
    setValue("date_of_birth", birthYear);
  };
  const getGenderOpObj = (option) => {
    if (!option.id)
      option = common.genderList.gender.find((op) => op.id === option);
    return option;
  };

  const getMaritalOpObj = (option) => {
    if (!option.id)
      option = common.maritalList.marital.find((op) => op.id === option);
    return option;
  };

  //useEffects

  useEffect(() => {
    dispatch(getRace.pending());
    dispatch(getMarital.pending());
    dispatch(getNationality.pending());
    dispatch(getGender.pending());
    dispatch(getLanguage.pending());
    register("username");
    register("name");
    register("identification_number");
    register("gender_type");
    register("date_of_birth");
    register("email");
    register("postal_code");
    register("address_details");
    register("address");
    register("contact_number");
    register("occupation");
    register("race");
    register("marital_type");
    register("nationality");

    register("language");
    register("allergy");
  }, [dispatch, register]);

  useEffect(() => {
    //set ref when first render

    prevPostUserStatus.current = false;
    prevPostVisitUserStatus.current = false;
  }, []);

  useEffect(() => {
    if (user.isLoadingCreateUser !== prevPostUserStatus.current) {
      //check the previous ref with current state
      prevPostUserStatus.current = user.isLoadingCreateUser;

      if (
        user.postCreateUserSuccess === true &&
        user.isLoadingCreateUser === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        window.scrollTo(0, 0);
      }
      if (
        user.postCreateUserSuccess === false &&
        user.isLoadingCreateUser === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(user.error.CreateUserError);
        window.scrollTo(0, 0);
      }
    }
  }, [user]);

  useEffect(() => {
    if (
      visitOrder.isLoadingVisitCreateUser !== prevPostVisitUserStatus.current
    ) {
      //check the previous ref with current state
      prevPostVisitUserStatus.current = visitOrder.isLoadingVisitCreateUser;

      if (
        visitOrder.postVisitCreateUserIsSuccess === true &&
        visitOrder.isLoadingVisitCreateUser === false
      ) {
        window.scrollTo(0, 0);
        setSuccessAlarm(true);
        setErrorAlarm(false);
        setDefaultUserSearchState({
          name: visitOrder.visitCreateUser.user.name,
          contact_number: visitOrder.visitCreateUser.user.contact_number,
          date_of_birth: visitOrder.visitCreateUser.user.date_of_birth,
          identification_number:
            visitOrder.visitCreateUser.user.identification_number,
        });
      }
      if (
        visitOrder.postVisitCreateUserIsSuccess === false &&
        visitOrder.isLoadingVisitCreateUser === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(visitOrder.error.visitCreateUserError);
        window.scrollTo(0, 0);
      }
    }
  }, [visitOrder, setDefaultUserSearchState]);

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }
    if (postcodeSearch) {
      (async () => {
        const response = await externetApis.getPostalCodeListApi(
          postcodeSearch,
          postcodeSearchPageNum
        );

        if (response.status === 200) {
          const addressResults = response.data;
          if (addressResults.results && addressResults.results.length > 0) {
            if (addressResults.totalNumPages > addressResults.pageNum) {
              setPostcodeSearchPageNum(addressResults.pageNum + 1);
            }

            if (addressResults.pageNum === 1) {
              setOptions(addressResults.results);
            } else {
              setOptions((optionsResult) => {
                return [...optionsResult, ...addressResults.results];
              });
            }
          } else {
            setOpen(false);
          }
        } else {
          setOpen(false);
        }
      })();
    }
  }, [loading, postcodeSearch, postcodeSearchPageNum]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  return (
    <Panel heading={t("translation:User Detail")}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:User has been created")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.root} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              name="username"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  className={classes.userFieldColor}
                  label={t("translation:Username")}
                  autoComplete="off"
                  variant="outlined"
                  onChange={(e) => {
                    field.onChange(e.target.value ? e.target.value : null);
                  }}
                />
              )}
              control={control}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  className={classes.userFieldColor}
                  label={t("translation:Name")}
                  variant="outlined"
                  onChange={(e) => {
                    field.onChange(e.target.value ? e.target.value : null);
                  }}
                />
              )}
              control={control}
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <Controller
              name="identification_number"
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.userFieldColor}
                  label={t("translation:Identification Number")}
                  autoComplete="off"
                  variant="outlined"
                  onChange={(e) => {
                    field.onChange(e.target.value ? e.target.value : null);
                  }}
                />
              )}
              control={control}
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            {common.genderList && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={common.genderList.gender}
                    isOptionEqualToValue={(option, value) => {
                      if (option.id === value) return option;
                    }}
                    getOptionLabel={(option) => getGenderOpObj(option).name}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Gender")}
                        variant="outlined"
                      />
                    )}
                    onChange={(_, data) =>
                      field.onChange(data ? data.id : null)
                    }
                  />
                )}
                name="gender_type"
                control={control}
              />
            )}
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                render={({ field }) => (
                  <DatePicker
                    value={
                      dayjs(field.value, true).isValid()
                        ? dayjs(field.value)
                        : null
                    }
                    className={classes.userFieldColor}
                    inputVariant="outlined"
                    label={t("translation:Date of Birth")}
                    autoOk={true}
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    disableFuture
                    format="DD MMM YYYY"
                    onChange={(e) => {
                      if (dayjs(e, true).isValid() === true) {
                        handleDateOfBirth(e);
                      }
                      if (e === null) {
                        setValue("age", ""); // set age through reat hook form because it need to initial.
                        setValue("date_of_birth", null);
                      }
                    }}
                  />
                )}
                name="date_of_birth"
                control={control}
                //defaultValue={user.date_of_birth}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              variant="outlined"
              name="Age"
              label={t("translation:Age")}
              autoComplete="off"
              value={age}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <FormControl variant="outlined">
              <Controller
                name="email"
                rules={{
                  pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className={classes.userFieldColor}
                    label={t("translation:Email Address")}
                    variant="outlined"
                    onChange={(e) => {
                      field.onChange(e.target.value ? e.target.value : null);
                    }}
                  />
                )}
                control={control}
              />
              {errors.email && (
                <p className={classes.p}>
                  {t("translation:Email is not valid")}
                </p>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  style={{ width: "100%" }}
                  name="postal_code"
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                  isOptionEqualToValue={() => {
                    return true;
                  }}
                  inputValue={selectedPostalCode ? selectedPostalCode : ""}
                  getOptionLabel={(option) => (option ? option.ADDRESS : "")}
                  onChange={(e, option) => handleAddressChange(option)}
                  disableClearable
                  options={options}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      name="postal_code"
                      className={classes.userFieldColor}
                      autoComplete="off"
                      onInput={allowOnlyNumericsOrDigits}
                      {...params}
                      onChange={handlePostalCodeChange}
                      onBlur={handleSelectedPostalCode}
                      label={t("translation:Postal Code")}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              )}
              name="postal_code"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.userInfoField}>
            <Controller
              name="address_details"
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.userFieldColor}
                  autoComplete="off"
                  placeholder={t("translation:Floor/ Unit Number")}
                  label={t("translation:Address Details")}
                  variant="outlined"
                  onChange={(e) => {
                    field.onChange(e.target.value ? e.target.value : null);
                  }}
                />
              )}
              control={control}
            />
          </Grid>

          <Controller
            name="blk_no"
            render={({ field }) => {
              if (field.value) {
                return (
                  <Grid item xs={12} className={classes.userInfoField}>
                    <TextField
                      {...field}
                      className={classes.userFieldColor}
                      autoComplete="off"
                      InputProps={{
                        readOnly: true,
                        className: "Mui-disabled",
                      }}
                      classes={{ root: "Mui-disabled" }}
                      name="blk_no"
                      label={t("translation:Block No")}
                      variant="outlined"
                      {...register("blk_no")}
                      InputLabelProps={{
                        shrink: field.value ? true : false,
                      }}
                    />
                  </Grid>
                );
              }
            }}
            control={control}
          />

          <Controller
            name="road_name"
            render={({ field }) => {
              if (field.value) {
                return (
                  <Grid item xs={12} className={classes.userInfoField}>
                    <TextField
                      className={classes.userFieldColor}
                      autoComplete="off"
                      InputProps={{
                        readOnly: true,
                        className: "Mui-disabled",
                      }}
                      classes={{ root: "Mui-disabled" }}
                      name="road_name"
                      label={t("translation:Road Name")}
                      variant="outlined"
                      {...register("road_name")}
                      InputLabelProps={{
                        shrink: field.value ? true : false,
                      }}
                    />
                  </Grid>
                );
              }
            }}
            control={control}
          />

          <Controller
            name="building"
            render={({ field }) => {
              if (field.value) {
                return (
                  <Grid item xs={12} className={classes.userInfoField}>
                    <TextField
                      autoComplete="off"
                      className={classes.userFieldColor}
                      InputProps={{
                        readOnly: true,
                        className: "Mui-disabled",
                      }}
                      classes={{ root: "Mui-disabled" }}
                      name="building"
                      label={t("translation:Building")}
                      variant="outlined"
                      {...register("building")}
                      InputLabelProps={{
                        shrink: field.value ? true : false,
                      }}
                    />
                  </Grid>
                );
              }
            }}
            control={control}
          />

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              name="address"
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.userFieldColor}
                  autoComplete="off"
                  InputProps={{
                    readOnly: addressDisabled,
                    className: addressDisabled ? "Mui-disabled" : undefined,
                  }}
                  classes={addressDisabled ? { root: "Mui-disabled" } : null}
                  multiline
                  minRows={3}
                  name="address"
                  label={t("translation:Address")}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    field.onChange(e.target.value ? e.target.value : null);
                  }}
                />
              )}
              control={control}
            />
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            {common.maritalList && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={common.maritalList.marital}
                    isOptionEqualToValue={(option, value) => {
                      if (option.id === value) return option;
                    }}
                    getOptionLabel={(option) => getMaritalOpObj(option).name}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Marital Status")}
                        variant="outlined"
                      />
                    )}
                    onChange={(_, data) =>
                      field.onChange(data ? data.id : null)
                    }
                  />
                )}
                name="marital_type"
                control={control}
              />
            )}
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            <Controller
              name="contact_number"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  className={classes.userFieldColor}
                  label={t("translation:Contact Number")}
                  variant="outlined"
                  onChange={(e) => {
                    field.onChange(e.target.value ? e.target.value : null);
                  }}
                />
              )}
              control={control}
            />
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            <Controller
              name="occupation"
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.userFieldColor}
                  label={t("translation:Occupation")}
                  variant="outlined"
                  onChange={(e) => {
                    field.onChange(e.target.value ? e.target.value : null);
                  }}
                />
              )}
              control={control}
            />
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            {common.nationalityList && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    name="nationality"
                    options={common.nationalityList.nationalities.sort((a, b) =>
                      a.desc.localeCompare(b.desc)
                    )}
                    getOptionLabel={(option) => option.desc}
                    style={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, option) =>
                      field.onChange(option == null ? null : option)
                    }
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Nationality")}
                        variant="outlined"
                      />
                    )}
                  />
                )}
                name="nationality"
                control={control}
              />
            )}
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            {common.raceList && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    name="race"
                    id="race"
                    options={common.raceList.races}
                    getOptionLabel={(option) => option.desc}
                    style={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, option) =>
                      field.onChange(option == null ? null : option)
                    }
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Race")}
                        variant="outlined"
                      />
                    )}
                  />
                )}
                name="race"
                control={control}
              />
            )}
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            {common.languageList && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    name="language"
                    id="language"
                    options={common.languageList.languages}
                    getOptionLabel={(option) => option.desc}
                    style={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, option) =>
                      field.onChange(option == null ? null : option)
                    }
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Language")}
                        variant="outlined"
                      />
                    )}
                  />
                )}
                name="language"
                control={control}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              name="nok_contact_name"
              label={t("translation:Next of Kin")}
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("nok_contact_name")}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              name="nok_contact_number"
              label={t("translation:Next of Kin's Contact Number")}
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("nok_contact_number")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              name="allergy"
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.userFieldColor}
                  multiline
                  minRows={3}
                  name="allergy"
                  label={t("translation:Allergy")}
                  variant="outlined"
                  onChange={(e) => {
                    field.onChange(e.target.value ? e.target.value : null);
                  }}
                />
              )}
              control={control}
            />
          </Grid>

          <>
            <RequiredNote />
            <PageSubmitWBackAction setView={() => setUserListView(true)} />
          </>
        </Grid>
      </form>
    </Panel>
  );
};

export default AddUserPage;
