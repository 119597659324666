import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getUserList,
  getUserById,
  getAllUserList,
  putEditUser,
  postCreateUser,
  putPhysicianLicenseNoById,
  postFailedLoginCount,
  getLoginAttemptMax,
} from "../actions/userActions";
import * as types from "../_constants/userConstants";
import * as notification from "../_constants/notificationConstants";
import userApis from "../_apis/userApis";
export default function* userSagas() {
  yield all([takeLatest(types.GET_USERLIST_ASYNC.PENDING, getUserListCall)]);
  yield all([takeLatest(types.PUT_EDITUSER_ASYNC.PENDING, putEditUserCall)]);
  yield all([
    takeLatest(types.POST_CREATEUSER_ASYNC.PENDING, postCreateUserCall),
  ]);
  yield all([
    takeLatest(
      types.PUT_PHYSICIANLICENSENOBYID_ASYNC.PENDING,
      putPhysicianLicenseNoByIdCall
    ),
  ]);

  yield all([takeLatest(types.GET_USERBYID_ASYNC.PENDING, getUserByIdCall)]);

  yield all([
    takeLatest(types.GET_ALLUSERLIST_ASYNC.PENDING, getAllUserListCall),
  ]);

  yield all([
    takeLatest(
      types.POST_FAILEDLOGINCOUNT_ASYNC.PENDING,
      postFailedLoginCountCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_LOGINATTEMPTMAX_ASYNC.PENDING, getLoginAttemptMaxCall),
  ]);
}

function* getUserListCall(searchModel) {
  try {
    const data = yield call(userApis.getUserListApi, searchModel.data);
    yield put(getUserList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserList.error(error.response?.data?.message));
    else yield put(getUserList.error(notification.CONST_NOINTERNET));
  }
}

function* getUserByIdCall(searchModel) {
  try {
    const data = yield call(userApis.getUserByIdApi, searchModel.data);
    yield put(getUserById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserById.error(error.response?.data?.message));
    else yield put(getUserById.error(notification.CONST_NOINTERNET));
  }
}

function* getAllUserListCall() {
  try {
    const data = yield call(userApis.getAllUserListApi);
    yield put(getAllUserList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAllUserList.error(error.response?.data?.message));
    else yield put(getAllUserList.error(notification.CONST_NOINTERNET));
  }
}

function* putEditUserCall(userModel) {
  try {
    const data = yield call(userApis.putEditUserApi, userModel.data);
    yield put(putEditUser.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putEditUser.error(error.response?.data?.message));
    else yield put(putEditUser.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateUserCall(userModel) {
  try {
    const data = yield call(userApis.postCreateUserApi, userModel.data);
    yield put(postCreateUser.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateUser.error(error.response?.data?.message));
    else yield put(postCreateUser.error(notification.CONST_NOINTERNET));
  }
}

function* putPhysicianLicenseNoByIdCall(userModel) {
  try {
    const data = yield call(
      userApis.putPhysicianLicenseNoByIdApi,
      userModel.data
    );
    yield put(putPhysicianLicenseNoById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putPhysicianLicenseNoById.error(error.response?.data?.message));
    else
      yield put(putPhysicianLicenseNoById.error(notification.CONST_NOINTERNET));
  }
}

function* postFailedLoginCountCall(userModel) {
  try {
    const data = yield call(userApis.postFailedLoginCountApi, userModel.data);
    yield put(postFailedLoginCount.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postFailedLoginCount.error(error.response?.data?.message));
    else yield put(postFailedLoginCount.error(notification.CONST_NOINTERNET));
  }
}

function* getLoginAttemptMaxCall(userModel) {
  try {
    const data = yield call(userApis.getLoginAttemptMaxApi, userModel.data);
    yield put(getLoginAttemptMax.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getLoginAttemptMax.error(error.response?.data?.message));
    else yield put(getLoginAttemptMax.error(notification.CONST_NOINTERNET));
  }
}
