import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getMedicineList,
  getMedicineById,
  postCreateMedicine,
  putUpdateMedicine,
  getMedicineMedicineTypes,
} from "../actions/medicineActions";
import * as types from "../_constants/medicineConstants";
import * as notification from "../_constants/notificationConstants";
import medicineApis from "../_apis/medicineApis";
export default function* medicineSagas() {
  yield all([
    takeLatest(types.GET_MEDICINELIST_ASYNC.PENDING, getMedicineListCall),
  ]);
  yield all([
    takeLatest(types.GET_MEDICINEBYID_ASYNC.PENDING, getMedicineByIdCall),
  ]);
  yield all([
    takeLatest(types.POST_CREATEMEDICINE_ASYNC.PENDING, postCreateMedicineCall),
  ]);
  yield all([
    takeLatest(types.PUT_UPDATEMEDICINE_ASYNC.PENDING, putUpdateMedicineCall),
  ]);

  yield all([
    takeLatest(
      types.GET_MEDICINEMEDICINETYPES_ASYNC.PENDING,
      getMedicineMedicineTypesCall
    ),
  ]);
}

function* getMedicineListCall(searchModel) {
  try {
    const data = yield call(medicineApis.getMedicineListApi, searchModel.data);
    yield put(getMedicineList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getMedicineList.error(error.response?.data?.message));
    else yield put(getMedicineList.error(notification.CONST_NOINTERNET));
  }
}

function* getMedicineByIdCall(searchModel) {
  try {
    const data = yield call(medicineApis.getMedicineByIdApi, searchModel.data);
    yield put(getMedicineById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getMedicineById.error(error.response?.data?.message));
    else yield put(getMedicineById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateMedicineCall(searchModel) {
  try {
    const data = yield call(
      medicineApis.postCreateMedicineApi,
      searchModel.data
    );
    yield put(postCreateMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateMedicine.error(error.response?.data?.message));
    else yield put(postCreateMedicine.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateMedicineCall(searchModel) {
  try {
    const data = yield call(
      medicineApis.putUpdateMedicineApi,
      searchModel.data
    );
    yield put(putUpdateMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateMedicine.error(error.response?.data?.message));
    else yield put(putUpdateMedicine.error(notification.CONST_NOINTERNET));
  }
}

function* getMedicineMedicineTypesCall(searchModel) {
  try {
    const data = yield call(
      medicineApis.getMedicineMedicineTypesApi,
      searchModel.data
    );
    yield put(getMedicineMedicineTypes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getMedicineMedicineTypes.error(error.response?.data?.message));
    else
      yield put(getMedicineMedicineTypes.error(notification.CONST_NOINTERNET));
  }
}
