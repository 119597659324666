import { createAsyncTypes } from "../_helper/Utilities";

export const POST_REGISTRATION_ASYNC = createAsyncTypes("POST_REGISTRATION");

export const POST_SELFREGISTRATION_ASYNC = createAsyncTypes(
  "POST_SELFREGISTRATION"
);

export const POST_LOGIN_ASYNC = createAsyncTypes("POST_LOGIN");

export const POST_LOGOUT_ASYNC = createAsyncTypes("POST_LOGOUT");

export const POST_RESETPASSWORD_ASYNC = createAsyncTypes("POST_RESETPASSWORD");

export const POST_RESETPASSWORDRQ_ASYNC = createAsyncTypes(
  "POST_RESETPASSWORDRQ"
);

export const PUT_CHANGEPASSWORD_ASYNC = createAsyncTypes("PUT_CHANGEPASSWORD");

export const POST_VERIFYEMAILRQ_ASYNC = createAsyncTypes("POST_VERIFYEMAILRQ");

export const POST_VERIFYEMAIL_ASYNC = createAsyncTypes("POST_VERIFYEMAIL");

export const GET_PROVIDERAUTHORIZE_ASYNC = createAsyncTypes(
  "GET_PROVIDERAUTHORIZE"
);

export const POST_PROVIDERACCESSTOKEN_ASYNC = createAsyncTypes(
  "POST_PROVIDERACCESSTOKEN"
);

export const POST_OTPCALLBACK_ASYNC = createAsyncTypes("POST_OTPCALLBACK");
