import { createAsyncTypes } from "../_helper/Utilities";

export const GET_NOTIFICATIONGROUPBYID_ASYNC = createAsyncTypes(
  "GET_NOTIFICATIONGROUPBYID"
);

export const GET_NOTIFICATIONGROUPLIST_ASYNC = createAsyncTypes(
  "GET_NOTIFICATIONGROUPLIST"
);

export const POST_CREATENOTIFICATIONGROUP_ASYNC = createAsyncTypes(
  "POST_CREATENOTIFICATIONGROUP"
);

export const PUT_UPDATENOTIFICATIONGROUP_ASYNC = createAsyncTypes(
  "PUT_UPDATENOTIFICATIONGROUP"
);
