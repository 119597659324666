import * as types from "../_constants/emailStoreRecipientConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_EMAILSTORERECIPIENTBYID_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreRecipientByIdIsSuccess: false,
        isLoadingGetEmailStoreRecipientById: true,
        getEmailStoreRecipientById: null,
      };

    case types.GET_EMAILSTORERECIPIENTBYID_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreRecipientByIdIsSuccess: false,
        isLoadingGetEmailStoreRecipientById: false,
        getEmailStoreRecipientById: null,
        error: {
          ...state.error,
          getEmailStoreRecipientByIdError: action.error,
        },
      };

    case types.GET_EMAILSTORERECIPIENTBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreRecipientByIdIsSuccess: true,
        isLoadingGetEmailStoreRecipientById: false,
        getEmailStoreRecipientById: action.data,
      };

    case types.GET_EMAILSTORERECIPIENTLIST_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreRecipientListIsSuccess: false,
        isLoadingGetEmailStoreRecipientList: true,
        getEmailStoreRecipientList: null,
      };

    case types.GET_EMAILSTORERECIPIENTLIST_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreRecipientListIsSuccess: false,
        isLoadingGetEmailStoreRecipientList: false,
        getEmailStoreRecipientList: null,
        error: {
          ...state.error,
          getEmailStoreRecipientListError: action.error,
        },
      };

    case types.GET_EMAILSTORERECIPIENTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreRecipientListIsSuccess: true,
        isLoadingGetEmailStoreRecipientList: false,
        getEmailStoreRecipientList: action.data,
      };

    case types.POST_CREATEEMAILSTORERECIPIENT_ASYNC.PENDING:
      return {
        ...state,
        postCreateEmailStoreRecipientIsSuccess: false,
        isLoadingPostCreateEmailStoreRecipient: true,
        postCreateEmailStoreRecipient: null,
      };

    case types.POST_CREATEEMAILSTORERECIPIENT_ASYNC.ERROR:
      return {
        ...state,
        postCreateEmailStoreRecipientIsSuccess: false,
        isLoadingPostCreateEmailStoreRecipient: false,
        postCreateEmailStoreRecipient: null,
        error: {
          ...state.error,
          postCreateEmailStoreRecipientError: action.error,
        },
      };

    case types.POST_CREATEEMAILSTORERECIPIENT_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateEmailStoreRecipientIsSuccess: true,
        isLoadingPostCreateEmailStoreRecipient: false,
        postCreateEmailStoreRecipient: action.data,
      };

    case types.PUT_UPDATEEMAILSTORERECIPIENT_ASYNC.PENDING:
      return {
        ...state,
        putUpdateEmailStoreRecipientIsSuccess: false,
        isLoadingPutUpdateEmailStoreRecipient: true,
        putUpdateEmailStoreRecipient: null,
      };

    case types.PUT_UPDATEEMAILSTORERECIPIENT_ASYNC.ERROR:
      return {
        ...state,
        putUpdateEmailStoreRecipientIsSuccess: false,
        isLoadingPutUpdateEmailStoreRecipient: false,
        putUpdateEmailStoreRecipient: null,
        error: {
          ...state.error,
          putUpdateEmailStoreRecipientError: action.error,
        },
      };

    case types.PUT_UPDATEEMAILSTORERECIPIENT_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateEmailStoreRecipientIsSuccess: true,
        isLoadingPutUpdateEmailStoreRecipient: false,
        putUpdateEmailStoreRecipient: action.data,
      };

    default:
      return state;
  }
};
