import { createAsyncTypes } from "../_helper/Utilities";

export const GET_EMAILSTORESENDERBYID_ASYNC = createAsyncTypes(
  "GET_EMAILSTORESENDERBYID"
);

export const GET_EMAILSTORESENDERLIST_ASYNC = createAsyncTypes(
  "GET_EMAILSTORESENDERLIST"
);

export const POST_CREATEEMAILSTORESENDER_ASYNC = createAsyncTypes(
  "POST_CREATEEMAILSTORESENDER"
);

export const PUT_UPDATEEMAILSTORESENDER_ASYNC = createAsyncTypes(
  "PUT_UPDATEEMAILSTORESENDER"
);

export const GET_EMAILSTORESENDERORGANISATIONBRANCH_ASYNC = createAsyncTypes(
  "GET_EMAILSTORESENDERORGANISATIONBRANCH"
);

export const GET_EMAILSTORESENDERMEDICINETYPE_ASYNC = createAsyncTypes(
  "GET_EMAILSTORESENDERMEDICINETYPE"
);

export const GET_EMAILSTORESENDERORGANISATIONPHYSICIAN_ASYNC = createAsyncTypes(
  "GET_EMAILSTORESENDERORGANISATIONPHYSICIAN"
);
