import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  putOrderFormPrintedDate,
  putOrderFormCancelled,
  putOrderFormEditMode,
  putApproveOrderForm,
  postSubmitOrderForm,
  postOrderFormSuppliers,
  postOrderFormMedicines,
  getOrderFormMedicineTypes,
  getOrderFormById,
  getOrderFormList,
} from "../actions/orderFormActions";
import * as types from "../_constants/orderFormConstants";
import * as notification from "../_constants/notificationConstants";
import orderFormApis from "../_apis/orderFormApis";
export default function* orderFormSagas() {
  yield all([
    takeLatest(types.GET_ORDERFORMLIST_ASYNC.PENDING, getOrderFormListCall),
  ]);
  yield all([
    takeLatest(types.GET_ORDERFORMBYID_ASYNC.PENDING, getOrderFormByIdCall),
  ]);
  yield all([
    takeLatest(
      types.GET_ORDERFORMMEDICINETYPES_ASYNC.PENDING,
      getOrderFormMedicineTypesCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_ORDERFORMMEDICINES_ASYNC.PENDING,
      postOrderFormMedicinesCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SUBMITORDERFORM_ASYNC.PENDING,
      postSubmitOrderFormCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_APPROVEORDERFORM_ASYNC.PENDING,
      putApproveOrderFormCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_ORDERFORMEDITMODE_ASYNC.PENDING,
      putOrderFormEditModeCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_ORDERFORMCANCELLED_ASYNC.PENDING,
      putOrderFormCancelledCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_ORDERFORMPRINTEDDATE_ASYNC.PENDING,
      putOrderFormPrintedDateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_ORDERFORMSUPPLIERS_ASYNC.PENDING,
      postOrderFormSuppliersCall
    ),
  ]);
}

function* getOrderFormListCall(Model) {
  try {
    const data = yield call(orderFormApis.getOrderFormListApi, Model.data);
    yield put(getOrderFormList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getOrderFormList.error(error.response?.data?.message));
    else yield put(getOrderFormList.error(notification.CONST_NOINTERNET));
  }
}

function* getOrderFormByIdCall(Model) {
  try {
    const data = yield call(orderFormApis.getOrderFormByIdApi, Model.data);
    yield put(getOrderFormById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getOrderFormById.error(error.response?.data?.message));
    else yield put(getOrderFormById.error(notification.CONST_NOINTERNET));
  }
}

function* getOrderFormMedicineTypesCall(Model) {
  try {
    const data = yield call(
      orderFormApis.getOrderFormMedicineTypesApi,
      Model.data
    );
    yield put(getOrderFormMedicineTypes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getOrderFormMedicineTypes.error(error.response?.data?.message));
    else
      yield put(getOrderFormMedicineTypes.error(notification.CONST_NOINTERNET));
  }
}

function* postOrderFormMedicinesCall(Model) {
  try {
    const data = yield call(
      orderFormApis.postOrderFormMedicinesApi,
      Model.data
    );
    yield put(postOrderFormMedicines.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postOrderFormMedicines.error(error.response?.data?.message));
    else yield put(postOrderFormMedicines.error(notification.CONST_NOINTERNET));
  }
}

function* postOrderFormSuppliersCall(Model) {
  try {
    const data = yield call(
      orderFormApis.postOrderFormSuppliersApi,
      Model.data
    );
    yield put(postOrderFormSuppliers.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postOrderFormSuppliers.error(error.response?.data?.message));
    else yield put(postOrderFormSuppliers.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitOrderFormCall(Model) {
  try {
    const data = yield call(orderFormApis.postSubmitOrderFormApi, Model.data);
    yield put(postSubmitOrderForm.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitOrderForm.error(error.response?.data?.message));
    else yield put(postSubmitOrderForm.error(notification.CONST_NOINTERNET));
  }
}

function* putApproveOrderFormCall(Model) {
  try {
    const data = yield call(orderFormApis.putApproveOrderFormApi, Model.data);
    yield put(putApproveOrderForm.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putApproveOrderForm.error(error.response?.data?.message));
    else yield put(putApproveOrderForm.error(notification.CONST_NOINTERNET));
  }
}

function* putOrderFormEditModeCall(Model) {
  try {
    const data = yield call(orderFormApis.putOrderFormEditModeApi, Model.data);
    yield put(putOrderFormEditMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putOrderFormEditMode.error(error.response?.data?.message));
    else yield put(putOrderFormEditMode.error(notification.CONST_NOINTERNET));
  }
}

function* putOrderFormCancelledCall(Model) {
  try {
    const data = yield call(orderFormApis.putOrderFormCancelledApi, Model.data);
    yield put(putOrderFormCancelled.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putOrderFormCancelled.error(error.response?.data?.message));
    else yield put(putOrderFormCancelled.error(notification.CONST_NOINTERNET));
  }
}

function* putOrderFormPrintedDateCall(Model) {
  try {
    const data = yield call(
      orderFormApis.putOrderFormPrintedDateApi,
      Model.data
    );
    yield put(putOrderFormPrintedDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putOrderFormPrintedDate.error(error.response?.data?.message));
    else
      yield put(putOrderFormPrintedDate.error(notification.CONST_NOINTERNET));
  }
}
