//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconButton, Grid, Collapse, Button } from "@mui/material";

import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import moment from "moment";
//actions
import {
  postBranchAdhocScheduleByBranchId,
  getBranchAdhocScheduleList,
} from "../../../../actions/branchAdhocScheduleActions";
//components
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { ReactComponent as RemoveLogo } from "../../../../assets/coolicons/basic/trash_empty.svg";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import * as pageConfigure from "../../../../_constants/pageConstants";
import AddAdhocModal from "../components/AddAdhocModal";
import { useStyles } from "../../../../components/globalStyles";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import * as generalConstant from "../../../../_constants/generalConstants";

export const EditBranchAdhocSchedulePage = ({ branchId, setEditView }) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const prevBranchAdhocScheduleStatus = useRef();
  const [openModal, setOpenModal] = useState(false);
  const branchAdhocSchedule = useSelector((state) => state.branchAdhocSchedule);
  const common = useSelector((state) => state.common);
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [editAdhocSchedule, setEditAdhocSchedule] = useState(null);
  const [msgAlarm, setMsgAlarm] = useState("");
  const [confirmDeleteSubmit, setConfirtemDeleSubmit] = useState(false);
  const [deleteAdhocSchedule, setDeleteAdhocSchedule] = useState(null);

  const [searchValue, setSearchValue] = React.useState("");

  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,

    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_branch_desc_value: null,
    search_branch_id_value: branchId,
    search_branch_name_value: null,
    search_desc_value: null,
    search_name_value: null,
  });

  const columns = [
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <Button onClick={() => handleRowEdit(row.original)}>
          <EditLogo title={t("translation:Edit")} />
        </Button>
      ),
    },
    {
      Header: "",
      accessor: "Delete",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          {row.original.status_type !== generalConstant.DELETED_STATUS_TYPE && (
            <Button onClick={() => onDeleteSubmit(row.original)}>
              <RemoveLogo title={t("translation:Delete")} />
            </Button>
          )}
        </>
      ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Description"),
      accessor: "desc",
    },
    {
      Header: t("translation:Closed All Day"),
      sortable: false,
      accessor: (d) => {
        if (d.is_closed_all_day) return "Yes";
        else return "";
      },
    },
    {
      Header: t("translation:Start Time"),
      sortable: false,
      accessor: (d) => {
        if (d.start_time)
          return moment(d.start_time, "HH:mm:ss").format("HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:End Time"),
      sortable: false,
      accessor: (d) => {
        if (d.end_time) return moment(d.end_time, "HH:mm:ss").format("HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Adhoc Date"),
      sortable: false,
      accessor: (d) => {
        if (d.adhoc_date) return moment(d.adhoc_date).format("DD-MMM-YYYY");
        else return "";
      },
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];
  //functions

  const handlePostBranchAdhocSchedule = (result) => {
    const jsonText = {
      branch_adhoc_schedules: result,
      branchId: branchId,
    };

    dispatch(postBranchAdhocScheduleByBranchId.pending(jsonText));
  };

  const handleRowEdit = (value) => {
    setEditAdhocSchedule(value);
    setOpenModal(true);
  };

  const onDeleteSubmit = (value) => {
    setConfirtemDeleSubmit(true);
    setDeleteAdhocSchedule(value);
  };

  const handleRowDelete = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const jsonText = {
      branch_adhoc_schedules: [
        {
          ...deleteAdhocSchedule,
          status_type: generalConstant.DELETED_STATUS_TYPE,
        },
      ],
      branchId: branchId,
    };

    dispatch(postBranchAdhocScheduleByBranchId.pending(jsonText));
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_name_value: value,
        search_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
        search_desc_value: "",
      });
    }
  };

  useEffect(() => {
    //set ref when first render
    prevBranchAdhocScheduleStatus.current = false;
  }, []);

  useEffect(() => {
    dispatch(getBranchAdhocScheduleList.pending(pageInfo));
  }, [pageInfo, dispatch]);

  useEffect(() => {
    if (
      branchAdhocSchedule.isLoadingPostBranchAdhocScheduleByBranchId !==
      prevBranchAdhocScheduleStatus.current
    ) {
      //check the previous ref with current state
      prevBranchAdhocScheduleStatus.current =
        branchAdhocSchedule.isLoadingPostBranchAdhocScheduleByBranchId;

      if (
        branchAdhocSchedule.postBranchAdhocScheduleByBranchIdIdIsSuccess ===
          true &&
        branchAdhocSchedule.isLoadingPostBranchAdhocScheduleByBranchId === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        dispatch(getBranchAdhocScheduleList.pending(pageInfo));
        window.scrollTo(0, 0);
      }
      if (
        branchAdhocSchedule.postBranchAdhocScheduleByBranchIdIdIsSuccess ===
          false &&
        branchAdhocSchedule.isLoadingPostBranchAdhocScheduleByBranchId === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(
          branchAdhocSchedule.error.postBranchAdhocScheduleByBranchIdError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [branchAdhocSchedule, dispatch, pageInfo]);

  return (
    <Panel heading={t("translation:Edit Branch Adhoc Schedule")}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmDeleteSubmit}
        setOpen={setConfirtemDeleSubmit}
        onConfirm={handleRowDelete}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Branch Adhoc Schedule has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <Grid className={classes.root} container justifyContent="flex-end">
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonColor}
            onClick={() => {
              setOpenModal(true);
            }}>
            {t("translation:Add")}
          </Button>
        </Grid>
      </Grid>
      {openModal && (
        <AddAdhocModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          branchId={branchId}
          editAdhocSchedule={editAdhocSchedule}
          setEditAdhocSchedule={setEditAdhocSchedule}
          handlePostBranchAdhocSchedule={handlePostBranchAdhocSchedule}
        />
      )}
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name/ Description")}
        searchValue={searchValue}
        columns={columns}
        data={
          branchAdhocSchedule.branchAdhocScheduleList
            ?.branch_adhoc_schedules !== undefined
            ? branchAdhocSchedule.branchAdhocScheduleList
                ?.branch_adhoc_schedules
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          branchAdhocSchedule.branchAdhocScheduleList
            ? branchAdhocSchedule.branchAdhocScheduleList.total_pages
            : 0
        }
        loading={branchAdhocSchedule.isLoadingBranchAdhocScheduleList}
        totalItems={
          branchAdhocSchedule.branchAdhocScheduleList
            ? branchAdhocSchedule.branchAdhocScheduleList.total_items
            : 0
        }
        handleSearch={handleSearch}
      />
      <Grid className={classes.root} container>
        <PageOnlyBackAction setView={() => setEditView(false)} />
      </Grid>
    </Panel>
  );
};
