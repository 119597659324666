import React from "react";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import Close from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useStyles } from "./globalStyles";
import TextField from "@mui/material/TextField";

const NotificationDialog = (props) => {
  const classes = useStyles();
  const {
    ok,
    title,
    children,
    open,
    setOpen,
    requiredText,
    textLabel,
    textValue,
    setTextValue,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setOpen(false);
        }
      }}
      aria-labelledby="confirm-dialog">
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => setOpen(false)}
      />
      <form>
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>
          {children}
          {requiredText && (
            <TextField
              value={textValue}
              required
              variant="outlined"
              margin="normal"
              name={textLabel}
              label={textLabel}
              onChange={(e) => setTextValue(e.target.value)}
              fullWidth
            />
          )}
        </DialogContent>
        <DialogActions>
          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              onClick={() => setOpen(false)}>
              {ok}
            </Button>
          </Link>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NotificationDialog;
