import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/medicineFixedSellingPriceDetailsConstants";

export const getMedicineFixedSellingPriceDetailsById = {
  pending: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSBYID_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSBYID_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSBYID_ASYNC.ERROR, {
      error,
    }),
};

export const getMedicineFixedSellingPriceDetails = {
  pending: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getMedicineFixedSellingPriceHeaderDetailsList = {
  pending: (data) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEHEADERDETAILSLIST_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEHEADERDETAILSLIST_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEHEADERDETAILSLIST_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const getMedicineFixedSellingPriceDetailsSummaryList = {
  pending: (data) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSSUMMARYLIST_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSSUMMARYLIST_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSSUMMARYLIST_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const putUpdateMedicineFixedSellingPriceDetails = {
  pending: (data) =>
    createAction(
      types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getMedicineFixedSellingPriceDetailsMedicineTypes = {
  pending: (data) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINETYPES_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINETYPES_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINETYPES_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const getMedicineFixedSellingPriceDetailsMedicineInfo = {
  pending: (data) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINEINFO_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINEINFO_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINEINFO_ASYNC.ERROR,
      {
        error,
      }
    ),
};
