import { createAsyncTypes } from "../_helper/Utilities";

export const GET_USERLIST_ASYNC = createAsyncTypes("GET_USERLIST");

export const GET_USERBYID_ASYNC = createAsyncTypes("GET_USERBYID");

export const GET_ALLUSERLIST_ASYNC = createAsyncTypes("GET_ALLUSERLIST");

export const PUT_EDITUSER_ASYNC = createAsyncTypes("PUT_EDITUSER");

export const POST_CREATEUSER_ASYNC = createAsyncTypes("POST_CREATEUSER");

export const PUT_PHYSICIANLICENSENOBYID_ASYNC = createAsyncTypes(
  "PUT_PHYSICIANLICENSENOBYID"
);

export const POST_FAILEDLOGINCOUNT_ASYNC = createAsyncTypes(
  "POST_FAILEDLOGINCOUNT"
);

export const GET_LOGINATTEMPTMAX_ASYNC = createAsyncTypes(
  "GET_LOGINATTEMPTMAX"
);
