import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  postLogin,
  postRegistration,
  postLogOut,
  postResetPassword,
  postResetPasswordRq,
  putChangePassword,
  postVerifyEmailRq,
  postVerifyEmail,
  postSelfRegistration,
  getProviderAuthorize,
  postProviderAccessToken,
  postOtpCallback,
} from "../actions/authActions";
import * as types from "../_constants/authConstants";
import * as notification from "../_constants/notificationConstants";
import authApis from "../_apis/authApis";
export default function* authSagas() {
  yield all([
    takeLatest(types.POST_LOGIN_ASYNC.PENDING, postLoginCall),
    takeLatest(types.POST_REGISTRATION_ASYNC.PENDING, postRegistrationCall),
    takeLatest(
      types.POST_SELFREGISTRATION_ASYNC.PENDING,
      postSelfRegistrationCall
    ),
    takeLatest(types.POST_LOGOUT_ASYNC.PENDING, postLogOutCall),
    takeLatest(types.POST_RESETPASSWORD_ASYNC.PENDING, postResetPasswordCall),
    takeLatest(
      types.POST_RESETPASSWORDRQ_ASYNC.PENDING,
      postResetPasswordRqCall
    ),
    takeLatest(types.PUT_CHANGEPASSWORD_ASYNC.PENDING, putChangePasswordCall),
    // takeLatest(curUserTypes.PUT_USERINFO_ASYNC.PENDING, putUserInfoCall),
    takeLatest(types.POST_VERIFYEMAILRQ_ASYNC.PENDING, postVerifyEmailRqCall),
    takeLatest(types.POST_VERIFYEMAIL_ASYNC.PENDING, postVerifyEmailCall),
    takeLatest(
      types.GET_PROVIDERAUTHORIZE_ASYNC.PENDING,
      getProviderAuthorizeCall
    ),
    takeLatest(
      types.POST_PROVIDERACCESSTOKEN_ASYNC.PENDING,
      postProviderAccessTokenCall
    ),
    takeLatest(types.POST_OTPCALLBACK_ASYNC.PENDING, postOtpCallbackCall),
  ]);
}

// function* putUserInfoCall(userModel) {
//   try {
//     const data = yield call(curUserApis.putUserInfoApi, userModel.data);
//     yield put(putUserInfo.success(data));
//   } catch (error) {
//     if (error.response?.data?.message)
//       yield put(putUserInfo.error(error.response?.data?.message));
//     else yield put(putUserInfo.error(notification.CONST_NOINTERNET));
//   }
// }

function* postLoginCall(userModel) {
  try {
    const data = yield call(authApis.postUserLogInApi, userModel.data);
    yield put(postLogin.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postLogin.error(error.response?.data?.message));
    else yield put(postLogin.error(notification.CONST_NOINTERNET));
  }
}

function* postSelfRegistrationCall(userModel) {
  try {
    const data = yield call(authApis.postSelfRegistrationApi, userModel.data);
    yield put(postSelfRegistration.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSelfRegistration.error(error.response?.data?.message));
    else yield put(postSelfRegistration.error(notification.CONST_NOINTERNET));
  }
}

function* postRegistrationCall(userModel) {
  try {
    const data = yield call(authApis.postRegistrationApi, userModel.data);
    yield put(postRegistration.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postRegistration.error(error.response?.data?.message));
    else yield put(postRegistration.error(notification.CONST_NOINTERNET));
  }
}

function* postLogOutCall() {
  try {
    const data = yield call(authApis.postUserLogOutApi);
    yield put(postLogOut.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postLogOut.error(error.response?.data?.message));
    else yield put(postLogOut.error(notification.CONST_NOINTERNET));
  }
}

function* postResetPasswordCall(userModel) {
  try {
    const data = yield call(authApis.postUserResetPasswordApi, userModel.data);
    yield put(postResetPassword.success(data.message));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postResetPassword.error(error.response?.data?.message));
    else yield put(postResetPassword.error(notification.CONST_NOINTERNET));
  }
}

function* postResetPasswordRqCall(userModel) {
  try {
    const data = yield call(
      authApis.postUserResetPasswordRqApi,
      userModel.data
    );
    yield put(postResetPasswordRq.success(data.message));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postResetPasswordRq.error(error.response?.data?.message));
    else yield put(postResetPasswordRq.error(notification.CONST_NOINTERNET));
  }
}

function* putChangePasswordCall(model) {
  try {
    const data = yield call(authApis.putUserChangePasswordApi, model.data);
    yield put(putChangePassword.success(data.message));
  } catch (error) {
    if (error.response?.data?.message || error.response?.data?.msg)
      yield put(
        putChangePassword.error(
          error.response?.data?.message || error.response?.data?.msg
        )
      );
    else yield put(putChangePassword.error(notification.CONST_NOINTERNET));
  }
}

function* postVerifyEmailRqCall(model) {
  try {
    const data = yield call(authApis.postVerifyEmailRqApi, model.data);
    yield put(postVerifyEmailRq.success(data.message));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVerifyEmailRq.error(error.response?.data?.message));
    else yield put(postVerifyEmailRq.error(notification.CONST_NOINTERNET));
  }
}

function* postVerifyEmailCall(model) {
  try {
    const data = yield call(authApis.postVerifyEmailApi, model.data);
    yield put(postVerifyEmail.success(data.message));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVerifyEmail.error(error.response?.data?.message));
    else yield put(postVerifyEmail.error(notification.CONST_NOINTERNET));
  }
}

function* getProviderAuthorizeCall(model) {
  try {
    const data = yield call(authApis.getProviderAuthorizeApi, model.data);
    yield put(getProviderAuthorize.success(data.message));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getProviderAuthorize.error(error.response?.data?.message));
    else yield put(getProviderAuthorize.error(notification.CONST_NOINTERNET));
  }
}

function* postProviderAccessTokenCall(model) {
  try {
    const data = yield call(authApis.postProviderAccessTokenApi, model.data);
    yield put(postProviderAccessToken.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postProviderAccessToken.error(error.response?.data?.message));
    else
      yield put(postProviderAccessToken.error(notification.CONST_NOINTERNET));
  }
}

function* postOtpCallbackCall(model) {
  try {
    const data = yield call(authApis.postOtpCallbackApi, model.data);
    yield put(postOtpCallback.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postOtpCallback.error(error.response?.data?.message));
    else yield put(postOtpCallback.error(notification.CONST_NOINTERNET));
  }
}
