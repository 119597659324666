//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
//actions
import {
  putUpdateEmailStoreSender,
  getEmailStoreSenderOrganisationBranch,
  getEmailStoreSenderMedicineType,
  getEmailStoreSenderOrganisationPhysician,
} from "../../../../actions/emailStoreSenderActions";
import { getWorkOrderType } from "../../../../actions/commonActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import NotificationDialog from "../../../../components/NotificationDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import * as generalConstants from "../../../../_constants/generalConstants";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";

export const EditEmailStoreSenderPage = ({
  emailStoreSender,
  setEditEmailStoreSenderView,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();
  const filter = createFilterOptions();
  const classes = useStyles();

  const { register, handleSubmit, reset, control, setValue, watch } = useForm({
    defaultValues: {
      id: emailStoreSender?.id,
      name: emailStoreSender?.name,
      email_store_senders: emailStoreSender?.email_store_senders,
      status_type: emailStoreSender?.status_type,
    },
  });

  const {
    fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    replace,
    update,
  } = useFieldArray({
    control,
    keyName: "uuid",
    name: "email_store_senders",
  });
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  const [msgAlarm, setMsgAlarm] = useState("");
  const emailStoreSenderData = useSelector((state) => state.emailStoreSender);
  const common = useSelector((state) => state.common);
  const prevSubmitStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  const [selectedEmail, setSelectedEmail] = useState(null);
  const [textValue, setTextValue] = useState("");
  const [confirmEditSenderEmailOpen, setConfirmEditSenderEmailOpen] =
    useState(false);
  const [editSenderEmailOpen, setEditSenderEmailOpen] = useState(false);
  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const getOpBranchObj = (option, list) => {
    if (!option.id && list) {
      option = list.find((op) => op.branch.id === option);
    }
    return option;
  };

  const getOpUserObj = (option, list) => {
    if (!option.id && list) {
      option = list.find((op) => op.user.id === option);
    }
    return option;
  };

  const getOpBranchList = (option) => {
    if (option) {
      option = option.map((op) => {
        return { ...op.branch, organisationName: op.organisation.name };
      });
      option = option.sort(
        (a, b) => -b.organisationName.localeCompare(a.organisationName)
      );
    }
    return option;
  };

  const getOpPhysicianList = (option) => {
    if (option) {
      option = option.map((op) => {
        return { ...op.user, organisationName: op.organisation.name };
      });
      option = option.sort(
        (a, b) => -b.organisationName.localeCompare(a.organisationName)
      );
    }
    return option;
  };

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJson = {
      email_store_account: { ...emailStoreSender, ...data },
    };
    dispatch(putUpdateEmailStoreSender.pending(submitJson));
  };

  const handleEmailChange = (newValue) => {
    if (newValue !== null) {
      if (typeof newValue === "string") {
        //get current existing emails
        const currentEmail = fields.some(
          (item) => item.sender_email === newValue
        );
        //
        if (!currentEmail) {
          append({
            id: 0,
            status_type: generalConstants.APPROVED_STATUS_TYPE,
            sender_email: newValue,
            dispensary_branch: null,
            dispensary_medicine_type: null,
            dispensary_organisation: null,
            physician: null,
            sender_branch: null,
            sender_organisation: null,
            staff: null,
            work_order_type: null,
          });
        }

        setSelectedEmail(newValue);
      } else if (newValue.inputValue) {
        append({
          id: 0,
          status_type: generalConstants.APPROVED_STATUS_TYPE,
          sender_email: newValue.inputValue,
          dispensary_branch: null,
          dispensary_medicine_type: null,
          dispensary_organisation: null,
          physician: null,
          sender_branch: null,
          sender_organisation: null,
          staff: null,
          work_order_type: null,
        });
        setSelectedEmail(newValue.inputValue);
      } else {
        setSelectedEmail(newValue.sender_email);
      }
    } else {
      setSelectedEmail(newValue);
    }
  };

  const handleTriggerEditEmailWindow = (value) => {
    setConfirmEditSenderEmailOpen(value);
    setTextValue("");
  };

  const handleEditSenderEmail = (value) => {
    setTextValue("");
    const trimValue = value.trim();

    const senderList = watch().email_store_senders;
    const repeatedEmail = senderList.some(
      (item) => item.sender_email === trimValue
    );

    if (repeatedEmail || trimValue === "") {
      setConfirmEditSenderEmailOpen(true);
      setEditSenderEmailOpen(true);
    } else {
      const elementsIndex = senderList.findIndex(
        (item) => item.sender_email === selectedEmail
      );

      const copyItem = senderList.find(
        (item) => item.sender_email === selectedEmail
      );
      //ignore uuid generated by useFieldArray
      delete copyItem.uuid;
      setSelectedEmail(trimValue);
      update(elementsIndex, {
        ...copyItem,
        sender_email: trimValue,
      });

      //setValue(`email_store_senders.${elementsIndex}.sender_email`, trimValue);
    }
  };
  useEffect(() => {
    //set ref when first render
    prevSubmitStatus.current = false;
  }, []);

  useEffect(() => {
    dispatch(getWorkOrderType.pending());
    dispatch(
      getEmailStoreSenderOrganisationBranch.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );

    dispatch(
      getEmailStoreSenderOrganisationPhysician.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );
  }, []);

  useEffect(() => {
    if (selectedEmail) {
      const senderList = watch().email_store_senders;
      const selectedSender = senderList.find(
        (item) => item.sender_email === selectedEmail
      );
      if (selectedSender.dispensary_organisation) {
        dispatch(
          getEmailStoreSenderMedicineType.pending({
            is_pagination: false,
            search_organisation_id_value:
              selectedSender.dispensary_organisation.id,
            search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
          })
        );
      }
    }
  }, [selectedEmail]);

  useEffect(() => {
    if (
      emailStoreSenderData.isLoadingPutUpdateEmailStoreSender !==
      prevSubmitStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitStatus.current =
        emailStoreSenderData.isLoadingPutUpdateEmailStoreSender;

      if (
        emailStoreSenderData.putUpdateEmailStoreSenderIsSuccess === true &&
        emailStoreSenderData.isLoadingPutUpdateEmailStoreSender === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...emailStoreSenderData.putUpdateEmailStoreSender.email_store_sender,
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        emailStoreSenderData.putUpdateEmailStoreSenderIsSuccess === false &&
        emailStoreSenderData.isLoadingPutUpdateEmailStoreSender === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(emailStoreSenderData.error.putUpdateEmailStoreSenderError);
        window.scrollTo(0, 0);
      }
    }
  }, [emailStoreSenderData, reset]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:Email Store Sender Details")
          : t("translation:Edit Email Store Sender")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <NotificationDialog
        ok={t("translation:Ok")}
        title={t("translation:Error")}
        open={editSenderEmailOpen}
        setOpen={setEditSenderEmailOpen}>
        {t("translation:Please use a different Email")}
      </NotificationDialog>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Edit Email")}
        open={confirmEditSenderEmailOpen}
        setOpen={handleTriggerEditEmailWindow}
        onConfirm={handleEditSenderEmail}
        requiredText={true}
        textLabel={t("translation:Email")}
        textValue={textValue}
        setTextValue={setTextValue}>
        {t("translation:Are you sure you want to edit this Email?")}
      </ConfirmDialog>

      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Email Store Sender has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={true}
          className={classes.userFieldColor}
          name="name"
          label={t("translation:Account Name")}
          variant="outlined"
          {...register("name")}
        />
      </Grid>

      {selectedEmail === null && (
        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            disabled={temViewOnly}
            value={selectedEmail}
            id="sender_email"
            required
            filterOptions={(options, params) => {
              let filtered = filter(options, params);
              let trimValue = null;
              if (params.inputValue) {
                trimValue = params.inputValue.trim();
                params.inputValue = trimValue;
                filtered = filter(options, params);
              }
              // Suggest the creation of a new value
              //search any contain of input
              const email = options.some(
                (item) => item.sender_email === trimValue
              );
              if (!email) {
                if (trimValue) {
                  filtered.push({
                    inputValue: trimValue,
                    sender_email: `${t("translation:Add")} "${trimValue}"`,
                  });
                }
              }

              return filtered;
            }}
            options={fields}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              if (option.sender_email) {
                return option.sender_email;
              }
              return "";
            }}
            style={{ width: "100%" }}
            isOptionEqualToValue={(option, value) =>
              option.sender_email === value
            }
            renderOption={(props, option) => {
              return (
                <li {...props} key={props.id}>
                  {option.sender_email}
                </li>
              );
            }}
            onChange={(e, option) => handleEmailChange(option)}
            freeSolo
            blurOnSelect
            selectOnFocus
            clearOnBlur={true}
            handleHomeEndKeys
            disableClearable
            renderInput={(params) => (
              <TextField
                disabled={temViewOnly}
                required
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Sender Email")}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {selectedEmail && (
                        <InputAdornment position="end">
                          <Button
                            onClick={() => setConfirmEditSenderEmailOpen(true)}>
                            <EditLogo title={t("translation:Edit")} />
                          </Button>
                        </InputAdornment>
                      )}

                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <input hidden readOnly {...register("id")} />

        <Grid className={classes.root} container>
          {fields.map((item, index) => {
            return (
              <Grid item xs={12} key={item.uuid}>
                {item.sender_email === selectedEmail && (
                  <>
                    <Grid item xs={12} className={classes.userInfoField}>
                      <Controller
                        render={({ field }) => (
                          <Autocomplete
                            disabled={temViewOnly}
                            {...field}
                            id="sender_email"
                            required
                            filterOptions={(options, params) => {
                              let filtered = filter(options, params);
                              let trimValue = null;
                              if (params.inputValue) {
                                trimValue = params.inputValue.trim();
                                params.inputValue = trimValue;
                                filtered = filter(options, params);
                              }
                              // Suggest the creation of a new value
                              //search any contain of input
                              const email = options.some(
                                (item) => item.sender_email === trimValue
                              );
                              if (!email) {
                                if (trimValue) {
                                  filtered.push({
                                    inputValue: trimValue,
                                    sender_email: `${t(
                                      "translation:Add"
                                    )} "${trimValue}"`,
                                  });
                                }
                              }

                              return filtered;
                            }}
                            options={fields}
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === "string") {
                                return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              // Regular option
                              if (option.sender_email) {
                                return option.sender_email;
                              }
                              return "";
                            }}
                            style={{ width: "100%" }}
                            isOptionEqualToValue={(option, value) =>
                              option.sender_email === value
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={props.id}>
                                  {option.sender_email}
                                </li>
                              );
                            }}
                            onChange={(e, option) => handleEmailChange(option)}
                            freeSolo
                            blurOnSelect
                            selectOnFocus
                            clearOnBlur={true}
                            handleHomeEndKeys
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                disabled={temViewOnly}
                                required
                                className={classes.userFieldColor}
                                {...params}
                                label={t("translation:Sender Email")}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {selectedEmail && (
                                        <InputAdornment position="end">
                                          <Button
                                            onClick={() =>
                                              setConfirmEditSenderEmailOpen(
                                                true
                                              )
                                            }>
                                            <EditLogo
                                              title={t("translation:Edit")}
                                            />
                                          </Button>
                                        </InputAdornment>
                                      )}

                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        )}
                        name={`email_store_senders.${index}.sender_email`}
                        control={control}
                      />
                    </Grid>
                    {common.getWorkOrderType && (
                      <Grid item xs={12} className={classes.userInfoField}>
                        <Controller
                          render={({ field }) => (
                            <Autocomplete
                              disabled={temViewOnly}
                              {...field}
                              id="work_order_type"
                              required
                              options={
                                common.getWorkOrderType
                                  ?.work_order_type_select_field
                                  ? common.getWorkOrderType
                                      ?.work_order_type_select_field
                                  : []
                              }
                              getOptionLabel={(option) =>
                                getOpObj(
                                  option,
                                  common.getWorkOrderType
                                    ?.work_order_type_select_field
                                ).name
                              }
                              style={{ width: "100%" }}
                              isOptionEqualToValue={(option, value) => {
                                if (option.id === value) {
                                  return option;
                                }
                              }}
                              onChange={(e, option) => {
                                // field.onChange(
                                //   option == null ? null : option.id
                                // );

                                const copyItem = { ...item };
                                //ignore uuid generated by useFieldArray
                                delete copyItem.uuid;
                                update(index, {
                                  ...copyItem,
                                  staff: null,
                                  physician: null,
                                  sender_organisation: null,
                                  work_order_type: option.id,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  disabled={temViewOnly}
                                  required
                                  className={classes.userFieldColor}
                                  {...params}
                                  label={t("translation:Work Order Type")}
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                          name={`email_store_senders.${index}.work_order_type`}
                          control={control}
                        />
                      </Grid>
                    )}
                    {emailStoreSenderData.getEmailStoreSenderOrganisationBranch && (
                      <Grid item xs={12} className={classes.userInfoField}>
                        <Controller
                          render={({ field }) => (
                            <Autocomplete
                              disabled={temViewOnly}
                              {...field}
                              id="dispensary_branch"
                              required
                              options={
                                emailStoreSenderData
                                  .getEmailStoreSenderOrganisationBranch
                                  ?.organisation_branch
                                  ? getOpBranchList(
                                      emailStoreSenderData
                                        .getEmailStoreSenderOrganisationBranch
                                        ?.organisation_branch
                                    )
                                  : []
                              }
                              groupBy={(option) => option.organisationName}
                              getOptionLabel={(option) =>
                                getOpBranchObj(
                                  option,
                                  emailStoreSenderData
                                    .getEmailStoreSenderOrganisationBranch
                                    ?.organisation_branch
                                ).name
                              }
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    {option.name}
                                  </li>
                                );
                              }}
                              style={{ width: "100%" }}
                              isOptionEqualToValue={(option, value) => {
                                if (option.id === value.id) {
                                  return option;
                                }
                              }}
                              onChange={(e, option) => {
                                //field.onChange(option == null ? null : option);

                                const oraganisationSelete =
                                  emailStoreSenderData.getEmailStoreSenderOrganisationBranch?.organisation_branch.find(
                                    (item) => item.branch.id === option.id
                                  );
                                const copyItem = { ...item };
                                //ignore uuid generated by useFieldArray
                                delete copyItem.uuid;
                                update(index, {
                                  ...copyItem,
                                  dispensary_branch:
                                    option == null ? null : option,
                                  dispensary_organisation: oraganisationSelete
                                    ? oraganisationSelete.organisation
                                    : null,
                                  dispensary_medicine_type: null,
                                });

                                dispatch(
                                  getEmailStoreSenderMedicineType.pending({
                                    is_pagination: false,
                                    search_status_type_value:
                                      generalConstants.APPROVED_STATUS_TYPE,
                                    search_organisation_id_value:
                                      oraganisationSelete
                                        ? oraganisationSelete.organisation.id
                                        : null,
                                  })
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  disabled={temViewOnly}
                                  required
                                  className={classes.userFieldColor}
                                  {...params}
                                  label={t("translation:Dispensary Branch")}
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                          name={`email_store_senders.${index}.dispensary_branch`}
                          control={control}
                        />
                      </Grid>
                    )}
                    {emailStoreSenderData.getEmailStoreSenderMedicineType &&
                      item.dispensary_organisation !== null && (
                        <Grid item xs={12} className={classes.userInfoField}>
                          <Controller
                            render={({ field }) => (
                              <Autocomplete
                                disabled={temViewOnly}
                                {...field}
                                id="medicine_type"
                                required
                                options={
                                  emailStoreSenderData
                                    .getEmailStoreSenderMedicineType
                                    ?.medicine_types
                                    ? emailStoreSenderData
                                        .getEmailStoreSenderMedicineType
                                        ?.medicine_types
                                    : []
                                }
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                  return (
                                    <li {...props} key={option.id}>
                                      {option.name}
                                    </li>
                                  );
                                }}
                                style={{ width: "100%" }}
                                isOptionEqualToValue={(option, value) => {
                                  if (option.id === value.id) {
                                    return option;
                                  }
                                }}
                                onChange={(e, option) => {
                                  //field.onChange(option == null ? null : option);

                                  const copyItem = { ...item };
                                  //ignore uuid generated by useFieldArray
                                  delete copyItem.uuid;
                                  update(index, {
                                    ...copyItem,
                                    dispensary_medicine_type:
                                      option == null ? null : option,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    disabled={temViewOnly}
                                    required
                                    className={classes.userFieldColor}
                                    {...params}
                                    label={t(
                                      "translation:Dispensary Medicine Type"
                                    )}
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                            name={`email_store_senders.${index}.dispensary_medicine_type`}
                            control={control}
                          />
                        </Grid>
                      )}
                    {emailStoreSenderData.getEmailStoreSenderOrganisationPhysician &&
                      item.work_order_type ===
                        generalConstants.STORE_WORK_ORDER_TYPE && (
                        <Grid item xs={12} className={classes.userInfoField}>
                          <Controller
                            render={({ field }) => (
                              <Autocomplete
                                disabled={temViewOnly}
                                {...field}
                                id="staff"
                                required
                                options={
                                  emailStoreSenderData
                                    .getEmailStoreSenderOrganisationPhysician
                                    ?.organisation_physician
                                    ? getOpPhysicianList(
                                        emailStoreSenderData
                                          .getEmailStoreSenderOrganisationPhysician
                                          ?.organisation_physician
                                      )
                                    : []
                                }
                                groupBy={(option) => option.organisationName}
                                getOptionLabel={(option) =>
                                  getOpUserObj(
                                    option,
                                    emailStoreSenderData
                                      .getEmailStoreSenderOrganisationPhysician
                                      ?.organisation_physician
                                  ).name
                                }
                                renderOption={(props, option) => {
                                  return (
                                    <li
                                      {...props}
                                      key={option.id + option.organisationName}>
                                      {option.name}
                                    </li>
                                  );
                                }}
                                style={{ width: "100%" }}
                                isOptionEqualToValue={(option, value) => {
                                  if (option.id === value.id) {
                                    return option;
                                  }
                                }}
                                onChange={(e, option) => {
                                  // field.onChange(option == null ? null : option);

                                  const oraganisationSelete =
                                    emailStoreSenderData.getEmailStoreSenderOrganisationPhysician?.organisation_physician.find(
                                      (item) => item.user.id === option.id
                                    );
                                  const copyItem = { ...item };
                                  //ignore uuid generated by useFieldArray
                                  delete copyItem.uuid;
                                  update(index, {
                                    ...copyItem,
                                    staff: option == null ? null : option,
                                    physician: null,
                                    sender_organisation: oraganisationSelete
                                      ? oraganisationSelete.organisation
                                      : null,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    disabled={temViewOnly}
                                    required
                                    className={classes.userFieldColor}
                                    {...params}
                                    label={t("translation:Staff Name")}
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                            name={`email_store_senders.${index}.staff`}
                            control={control}
                          />
                        </Grid>
                      )}
                    {emailStoreSenderData.getEmailStoreSenderOrganisationPhysician &&
                      item.work_order_type ===
                        generalConstants.VISIT_WORK_ORDER_TYPE && (
                        <Grid item xs={12} className={classes.userInfoField}>
                          <Controller
                            render={({ field }) => (
                              <Autocomplete
                                disabled={temViewOnly}
                                {...field}
                                id="physician"
                                required
                                groupBy={(option) => option.organisationName}
                                options={
                                  emailStoreSenderData
                                    .getEmailStoreSenderOrganisationPhysician
                                    ?.organisation_physician
                                    ? getOpPhysicianList(
                                        emailStoreSenderData
                                          .getEmailStoreSenderOrganisationPhysician
                                          ?.organisation_physician
                                      )
                                    : []
                                }
                                getOptionLabel={(option) =>
                                  getOpUserObj(
                                    option,
                                    emailStoreSenderData
                                      .getEmailStoreSenderOrganisationPhysician
                                      ?.organisation_physician
                                  ).name
                                }
                                renderOption={(props, option) => {
                                  return (
                                    <li
                                      {...props}
                                      key={option.id + option.organisationName}>
                                      {option.name}
                                    </li>
                                  );
                                }}
                                style={{ width: "100%" }}
                                isOptionEqualToValue={(option, value) => {
                                  if (option.id === value.id) {
                                    return option;
                                  }
                                }}
                                onChange={(e, option) => {
                                  // field.onChange(option == null ? null : option);

                                  const oraganisationSelete =
                                    emailStoreSenderData.getEmailStoreSenderOrganisationPhysician?.organisation_physician.find(
                                      (item) => item.user.id === option.id
                                    );
                                  const copyItem = { ...item };
                                  //ignore uuid generated by useFieldArray
                                  delete copyItem.uuid;
                                  update(index, {
                                    ...copyItem,
                                    staff: null,
                                    physician: option,
                                    sender_organisation: oraganisationSelete
                                      ? oraganisationSelete.organisation
                                      : null,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    disabled={temViewOnly}
                                    required
                                    className={classes.userFieldColor}
                                    {...params}
                                    label={t("translation:Physician")}
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                            name={`email_store_senders.${index}.physician`}
                            control={control}
                          />
                        </Grid>
                      )}
                    {emailStoreSenderData.getEmailStoreSenderOrganisationBranch &&
                      item.work_order_type ===
                        generalConstants.VISIT_WORK_ORDER_TYPE && (
                        <Grid item xs={12} className={classes.userInfoField}>
                          <Controller
                            render={({ field }) => (
                              <Autocomplete
                                disabled={temViewOnly}
                                {...field}
                                id="sender_branch"
                                required
                                options={
                                  emailStoreSenderData
                                    .getEmailStoreSenderOrganisationBranch
                                    ?.organisation_branch
                                    ? getOpBranchList(
                                        emailStoreSenderData
                                          .getEmailStoreSenderOrganisationBranch
                                          ?.organisation_branch
                                      )
                                    : []
                                }
                                groupBy={(option) => option.organisationName}
                                getOptionLabel={(option) =>
                                  getOpBranchObj(
                                    option,
                                    emailStoreSenderData
                                      .getEmailStoreSenderOrganisationBranch
                                      ?.organisation_branch
                                  ).name
                                }
                                renderOption={(props, option) => {
                                  return (
                                    <li {...props} key={option.id}>
                                      {option.name}
                                    </li>
                                  );
                                }}
                                style={{ width: "100%" }}
                                isOptionEqualToValue={(option, value) => {
                                  if (option.id === value.id) {
                                    return option;
                                  }
                                }}
                                onChange={(e, option) => {
                                  // field.onChange(
                                  //   option == null ? null : option
                                  // );

                                  const oraganisationSelete =
                                    emailStoreSenderData.getEmailStoreSenderOrganisationBranch?.organisation_branch.find(
                                      (item) => item.branch.id === option?.id
                                    );
                                  const copyItem = { ...item };
                                  //ignore uuid generated by useFieldArray
                                  delete copyItem.uuid;
                                  update(index, {
                                    ...copyItem,
                                    sender_branch: option,
                                    sender_organisation: oraganisationSelete
                                      ? oraganisationSelete.organisation
                                      : null,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    disabled={temViewOnly}
                                    required
                                    className={classes.userFieldColor}
                                    {...params}
                                    label={t("translation:Sender Branch")}
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                            name={`email_store_senders.${index}.sender_branch`}
                            control={control}
                          />
                        </Grid>
                      )}
                    <Grid item xs={12} className={classes.userInfoField}>
                      <Controller
                        render={({ field }) => (
                          <Autocomplete
                            disabled={temViewOnly}
                            {...field}
                            id="status_type"
                            required
                            options={
                              common.generalStatusList.status_general
                                ? common.generalStatusList.status_general
                                : []
                            }
                            getOptionLabel={(option) =>
                              getOpObj(
                                option,
                                common.generalStatusList.status_general
                              ).name
                            }
                            style={{ width: "100%" }}
                            isOptionEqualToValue={(option, value) => {
                              if (option.id === value) {
                                return option;
                              }
                            }}
                            onChange={(e, option) =>
                              field.onChange(option == null ? null : option.id)
                            }
                            renderInput={(params) => (
                              <TextField
                                disabled={temViewOnly}
                                required
                                className={classes.userFieldColor}
                                {...params}
                                label={t("translation:Status Type")}
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                        name={`email_store_senders.${index}.status_type`}
                        control={control}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            );
          })}

          <>
            <RequiredNote />

            {temViewOnly ? (
              <PageOnlyBackAction
                setView={() => setEditEmailStoreSenderView(false)}
              />
            ) : (
              <PageSubmitWBackAction
                setView={() => setEditEmailStoreSenderView(false)}
              />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
