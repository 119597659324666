import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Panel from "../../../../../components/Panel";
import { useSelector } from "react-redux";
import organisationPriceRounding from "../../../../../components/functions/organisationPriceRounding";
import * as generalConstants from "../../../../../_constants/generalConstants";
import organisationPriceRoundingWGst from "../../../../../components/functions/organisationPriceRoundingWGst";
import { useStyles } from "../../../../../components/globalStyles";

function TotalPricePanel({ classes, t, item, currentOrganistion }) {
  return (
    <>
      {item.amt_with_gst !== 0 && item.amt_with_gst && (
        <Panel
          heading={
            item.organisation_branch.branch.name +
            " " +
            t("translation:Total Fee") +
            " $" +
            Number(item.amt_with_gst).toFixed(2)
          }>
          <Grid container>
            <Grid xs={3} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={Number(item.branch_medicine_price).toFixed(2)}
                disabled
                name="totalPrice"
                label={t("translation:Total Medicine Fee")}
                variant="outlined"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    fontWeight: "bold",
                    color: "rgba(149, 0, 0,0.75)",
                  },
                }}
              />
            </Grid>

            {item.isShowBrewing && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(item.branch_brewing_price).toFixed(2)}
                  disabled
                  name="brewingFee"
                  label={t("translation:Brewing Fee")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}

            {item.isShowDelivery && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(item.branch_delivery_price).toFixed(2)}
                  disabled
                  name="deliveryFee"
                  label={t("translation:Delivery Fee")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}

            {item.organisation_branch.organisation.id !==
              currentOrganistion.id && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(item.gst_amt).toFixed(2)}
                  disabled
                  name="taxAmmount"
                  label={t("translation:Tax Amount")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Panel>
      )}
    </>
  );
}

export default function AllianceTotalPrice({
  medicineList,
  memoGroupOption,
  group,
  setGroupOption,
  price,
  setPrice,
  memoSelectedGroupIndex,
  memoSelectedGroup,
  setCostingHeader,
  costingHeader,
  userOrganisations,
  storeOrderMode = false,
}) {
  const [showDeliveryPrice, setShowDeliveryPrice] = useState(false);
  const [showBrewingPrice, setShowBrewingPrice] = useState(false);
  const workOrder = useSelector((state) => state.workOrder);
  const visitOrder = useSelector((state) => state.visitOrder);

  const classes = useStyles();
  const { t } = useTranslation();

  //useEffects

  useEffect(() => {
    const sum_gst_amt = costingHeader.reduce((sum, li) => {
      if (
        li.organisation_branch.organisation.is_gst_enable &&
        li.organisation_branch.organisation.is_gst_inclusive !== true
      ) {
        return Number(sum) + Number(li.gst_amt);
      } else {
        return Number(sum);
      }
    }, 0);

    const sum_gst_amt_rounding = organisationPriceRounding(
      Number(sum_gst_amt),
      workOrder.currentOrganistion.organisation.medicine_price_for_costing_rmt,
      workOrder.currentOrganistion.organisation.medicine_price_for_costing_rpvt
    );

    const sum_branch_medicine_price = costingHeader.reduce((sum, li) => {
      return Number(sum) + Number(li.branch_medicine_price);
    }, 0);

    const sum_branch_medicine_price_rounding = organisationPriceRounding(
      Number(sum_branch_medicine_price),
      workOrder.currentOrganistion.organisation.medicine_price_for_costing_rmt,
      workOrder.currentOrganistion.organisation.medicine_price_for_costing_rpvt
    );

    const sum_branch_brewing_price = costingHeader.reduce((sum, li) => {
      return Number(sum) + Number(li.branch_brewing_price);
    }, 0);

    const sum_branch_delivery_price = costingHeader.reduce((sum, li) => {
      return Number(sum) + Number(li.branch_delivery_price);
    }, 0);

    const medicineTab_rounding = organisationPriceRounding(
      sum_branch_medicine_price_rounding,
      workOrder.currentOrganistion.organisation.order_cat_amt_rmt,
      workOrder.currentOrganistion.organisation.order_cat_amt_rpvt
    );

    setPrice((p) => ({
      ...p,
      deliveryFee: sum_branch_delivery_price,
      brewingFee: sum_branch_brewing_price,
      medicineFee: sum_branch_medicine_price_rounding,
      medicineTab: medicineTab_rounding,
      vendorGstAmt: sum_gst_amt_rounding,
    }));
  }, [costingHeader, workOrder.currentOrganistion, setPrice]);
  useEffect(() => {
    let subTotal = 0;
    let total = 0;

    const groupOptionWMedicineDetails = memoGroupOption.filter(
      (option) =>
        option.store_medicine_details &&
        Object.keys(option.store_medicine_details).length !== 0 &&
        option.store_medicine_details.some(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
    );

    const groupTotalCount = groupOptionWMedicineDetails.map((option, index) => {
      let userOrganisationMutiplier = null;
      if (userOrganisations && storeOrderMode) {
        userOrganisationMutiplier = userOrganisations.find(
          (item) =>
            item.organisations.id === option.organisation_branch.organisation.id
        )?.medicine_cost_multiplier;
      }
      const temSubTotal = option.store_medicine_details.reduce((sum, li) => {
        if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          let temSubTotalRound = 0;
          if (userOrganisationMutiplier) {
            temSubTotalRound = organisationPriceRounding(
              userOrganisationMutiplier *
                li.branch_medicine.medicines.unit_selling_price,
              option.organisation_branch.organisation.med_details_price_rmt,
              option.organisation_branch.organisation.med_details_price_rpvt
            );
          }

          temSubTotalRound = organisationPriceRounding(
            userOrganisationMutiplier
              ? li.quantity_per_day * temSubTotalRound
              : li.quantity_per_day *
                  li.branch_medicine.medicines.unit_selling_price,
            option.organisation_branch.organisation.med_details_price_rmt,
            option.organisation_branch.organisation.med_details_price_rpvt
          );

          return Number((Number(sum) + Number(temSubTotalRound)).toFixed(7)); //toPrecision is to prevent 0.1 + 0.2 = 0.30000000000000004
        } else {
          return Number(sum);
        }
      }, 0);
      const temTotalWtDays = Number(
        (Number(temSubTotal) * Number(option.number_of_days)).toFixed(7)
      );

      //validation organisation calcultaion
      let validSubTotal = organisationPriceRounding(
        temTotalWtDays,
        option.organisation_branch.organisation.med_header_price_rmt,
        option.organisation_branch.organisation.med_header_price_rpvt
      );
      if (
        memoSelectedGroup &&
        option.name === memoSelectedGroup.name &&
        option.id === memoSelectedGroup.id
      ) {
        subTotal = validSubTotal;
      }
      //for medicine costing
      total = Number((Number(total) + Number(validSubTotal)).toFixed(7));

      return {
        organisation_branch: option.organisation_branch,
        branch_medicine_price: Number(validSubTotal),
      };
    });

    //sum up price based on organisation_branch
    var result = [];
    groupTotalCount.reduce(function (res, value) {
      if (!res[value.organisation_branch.id]) {
        res[value.organisation_branch.id] = {
          organisation_branch: value.organisation_branch,
          branch_medicine_price: 0,
        };
        result.push(res[value.organisation_branch.id]);
      }

      res[value.organisation_branch.id].branch_medicine_price = Number(
        (
          res[value.organisation_branch.id].branch_medicine_price +
          value.branch_medicine_price
        ).toFixed(7)
      );
      return res;
    }, {});

    if (Object.keys(result).length !== 0) {
      setCostingHeader((memoCostingHeader) => {
        // let tempCostingHeader = result.map((totalPriceItem) => {
        //   return {
        //     ...totalPriceItem,
        //     id: 0,
        //     status_type: generalConstants.APPROVED_STATUS_TYPE,
        //   };
        // });

        // return tempCostingHeader;
        let tempCostingHeader = [...memoCostingHeader];
        if (Object.keys(tempCostingHeader).length !== 0) {
          tempCostingHeader.map((item) => {
            //// check available organisation_branch in result, if no found then delete
            const branchAvailable = result.some(
              (resultItem) =>
                resultItem.organisation_branch.id ===
                item.organisation_branch.id
            );
            if (!branchAvailable) {
              tempCostingHeader = tempCostingHeader.filter(
                (filterItem) =>
                  filterItem.organisation_branch.id !==
                  item.organisation_branch.id
              );
            }
            return "";
          });
        }
        //count GST Details
        result.map((resultItem) => {
          const resultItemIndex = tempCostingHeader.findIndex(
            (indexItem) =>
              indexItem.organisation_branch.id ===
              resultItem.organisation_branch.id
          );

          let branch_medicine_price_rounding = organisationPriceRounding(
            resultItem.branch_medicine_price,
            resultItem.organisation_branch.organisation.order_cat_amt_rmt,
            resultItem.organisation_branch.organisation.order_cat_amt_rpvt
          );

          //self organisation no need to round the costing medicine with order_cat_amt
          if (
            resultItem.organisation_branch.organisation.id ===
            workOrder.currentOrganistion.organisation.id
          ) {
            branch_medicine_price_rounding = organisationPriceRounding(
              resultItem.branch_medicine_price,
              resultItem.organisation_branch.organisation
                .medicine_price_for_costing_rmt,
              resultItem.organisation_branch.organisation
                .medicine_price_for_costing_rpvt
            );

            //repeated organisation_branch
            if (resultItemIndex !== -1) {
              tempCostingHeader[resultItemIndex] = {
                ...tempCostingHeader[resultItemIndex],
                gst_amt: 0,
                branch_medicine_price: resultItem.branch_medicine_price,
              };
            } else {
              tempCostingHeader.push({
                id: 0,
                gst_amt: 0,
                branch_brewing_price: 0,
                branch_delivery_price: 0,
                branch_medicine_price: resultItem.branch_medicine_price,
                organisation_branch: resultItem.organisation_branch,
                status_type: generalConstants.APPROVED_STATUS_TYPE,
              });
            }
          } else {
            if (resultItemIndex !== -1) {
              const organisationPriceRoundingWGsts =
                organisationPriceRoundingWGst(
                  Number(branch_medicine_price_rounding) +
                    Number(
                      tempCostingHeader[resultItemIndex].branch_brewing_price
                    ) +
                    Number(
                      tempCostingHeader[resultItemIndex].branch_delivery_price
                    ),
                  resultItem.organisation_branch.organisation
                );
              tempCostingHeader[resultItemIndex] = {
                ...tempCostingHeader[resultItemIndex],
                ...organisationPriceRoundingWGsts,
                branch_medicine_price: resultItem.branch_medicine_price,
              };
            } else {
              const organisationPriceRoundingWGsts =
                organisationPriceRoundingWGst(
                  Number(branch_medicine_price_rounding),
                  resultItem.organisation_branch.organisation
                );

              tempCostingHeader.push({
                id: 0,
                branch_brewing_price: 0,
                branch_delivery_price: 0,
                ...organisationPriceRoundingWGsts,
                branch_medicine_price: resultItem.branch_medicine_price,
                organisation_branch: resultItem.organisation_branch,
                status_type: generalConstants.APPROVED_STATUS_TYPE,
              });
            }
          }

          return "";
        });
        return tempCostingHeader;
      });
    } else {
      setCostingHeader([]);
    }

    setPrice((p) => ({
      ...p,
      medicineGroupTotal: subTotal,
    }));
  }, [
    medicineList,
    memoGroupOption,
    memoSelectedGroup,
    setPrice,
    setCostingHeader,
    workOrder.currentOrganistion,
    storeOrderMode,
    userOrganisations,
  ]);

  useEffect(() => {
    const elementsIndex = memoSelectedGroupIndex;
    if (price.medicineGroupTotal) {
      //make a copy of group option
      let newArray = memoGroupOption;
      if (newArray.length > 0 && newArray[elementsIndex]) {
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          price: price.medicineGroupTotal,
        };

        setGroupOption(newArray);
      }
    }
  }, [
    group,
    price.medicineGroupTotal,
    memoSelectedGroupIndex,
    memoGroupOption,
    setGroupOption,
    setCostingHeader,
  ]);

  useEffect(() => {
    const isDelivery = memoGroupOption.some(
      (item) =>
        item.is_delivery_services === true &&
        item.medicine_type &&
        item.organisation_branch &&
        Object.keys(item.store_medicine_details).length !== 0
    );

    setShowDeliveryPrice(isDelivery);
  }, [memoGroupOption]);

  useEffect(() => {
    const isBrewing = memoGroupOption.some(
      (item) =>
        item.is_brewing_services === true &&
        item.medicine_type &&
        item.organisation_branch &&
        Object.keys(item.store_medicine_details).length !== 0
    );

    setShowBrewingPrice(isBrewing);
  }, [memoGroupOption]);

  const brewingWCount = () => {
    const groupBrewing = memoGroupOption.filter(
      (item) =>
        item.is_brewing_services === true &&
        item.medicine_type &&
        item.organisation_branch &&
        Object.keys(item.store_medicine_details).length !== 0
    );

    const countBranchBrewing = [
      ...groupBrewing
        .reduce((r, { number_of_days, number_of_times, ...o }) => {
          const key = o.organisation_branch.id;

          const itemWBrewingPrice =
            r.get(key) ||
            Object.assign(
              {},
              {
                totalBrewingCharge: 0,
              },
              {
                organisation_branch: o.organisation_branch,
                add_pack_charge: o.organisation_branch.branch.add_pack_charge,
                min_brewing_packs:
                  o.organisation_branch.branch.min_brewing_packs,
                min_brewing_charge:
                  o.organisation_branch.branch.min_brewing_charge,
              }
            );

          let currectGroupBrewingCharge = 0;
          if (
            Number(number_of_days * number_of_times) <
            itemWBrewingPrice.min_brewing_packs
          ) {
            currectGroupBrewingCharge = itemWBrewingPrice.min_brewing_charge;
          } else {
            const additionalBrewingFee =
              (Number(number_of_days * number_of_times) -
                itemWBrewingPrice.min_brewing_packs) *
                itemWBrewingPrice.add_pack_charge +
              itemWBrewingPrice.min_brewing_charge;

            currectGroupBrewingCharge = additionalBrewingFee;
          }

          return r.set(key, {
            ...itemWBrewingPrice,
            totalBrewingCharge:
              Number(itemWBrewingPrice.totalBrewingCharge) +
              Number(currectGroupBrewingCharge),
          });
        }, new Map())
        .values(),
    ];

    return countBranchBrewing;
  };

  const memoBrewingWCount = React.useMemo(brewingWCount, [memoGroupOption]);

  // useEffect(() => {
  //   const brewingResult = memoBrewingWCount;

  //   if (brewingResult === 0) {
  //     setPrice((p) => ({
  //       ...p,
  //       brewingFee: 0,
  //     }));
  //   } else {
  //     setPrice((p) => ({
  //       ...p,
  //       brewingFee: brewingResult,
  //     }));
  //   }
  // }, [memoBrewingWCount, setPrice]);

  useEffect(() => {
    setCostingHeader((memoCostingHeader) => {
      let tempCostingHeader = [...memoCostingHeader];

      if (
        Object.keys(memoBrewingWCount).length === 0 &&
        Object.keys(tempCostingHeader).length !== 0
      ) {
        const emptyBrewingWCount = tempCostingHeader.map((item) => {
          return { ...item, branch_brewing_price: 0 };
        });
        tempCostingHeader = [...emptyBrewingWCount];
      }
      if (
        Object.keys(memoBrewingWCount).length !== 0 &&
        Object.keys(memoBrewingWCount).length <
          Object.keys(tempCostingHeader).length
      ) {
        //remove extra fee when memoBrewingWCount is clear
        const removeBrewingWCount = tempCostingHeader.map((item) => {
          const brewingItemIndex = memoBrewingWCount.findIndex(
            (indexItem) =>
              indexItem.organisation_branch.id === item.organisation_branch.id
          );
          if (brewingItemIndex === -1) {
            return { ...item, branch_brewing_price: 0 };
          } else {
            return { ...item };
          }
        });
        tempCostingHeader = [...removeBrewingWCount];
      }

      memoBrewingWCount.map((brewingItem) => {
        const brewingItemIndex = tempCostingHeader.findIndex(
          (indexItem) =>
            indexItem.organisation_branch.id ===
            brewingItem.organisation_branch.id
        );
        //count brewing Charge
        // let totalBrewingCharge = 0;
        // if (brewingItem.number_of_packs < brewingItem.min_brewing_packs) {
        //   totalBrewingCharge = brewingItem.min_brewing_charge;
        // } else {
        //   const additionalBrewingFee =
        //     (brewingItem.number_of_packs - brewingItem.min_brewing_packs) *
        //       brewingItem.add_pack_charge +
        //     brewingItem.min_brewing_charge;

        //   totalBrewingCharge = additionalBrewingFee;
        // }
        //check any empty or same organisation branch

        if (brewingItemIndex !== -1) {
          tempCostingHeader[brewingItemIndex] = {
            ...tempCostingHeader[brewingItemIndex],
            branch_brewing_price: brewingItem.totalBrewingCharge,
          };
        } else {
          tempCostingHeader.push({
            id: 0,
            branch_brewing_price: brewingItem.totalBrewingCharge,
            organisation_branch: brewingItem.organisation_branch,
            status_type: generalConstants.APPROVED_STATUS_TYPE,
          });
        }
        return "";
      });

      return tempCostingHeader;
    });
  }, [memoBrewingWCount, setCostingHeader]);

  //showing brewing
  useEffect(() => {
    const groupOptionWMedicineDetails = memoGroupOption.filter(
      (option) =>
        option.store_medicine_details &&
        Object.keys(option.store_medicine_details).length !== 0 &&
        option.store_medicine_details.some(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
    );

    //grouping by organisation_branch and show brewing price
    var result = [];
    groupOptionWMedicineDetails.reduce(function (res, value) {
      if (!res[value.organisation_branch.id]) {
        const isBrewing = groupOptionWMedicineDetails.some(
          (item) =>
            item.is_brewing_services === true &&
            item.organisation_branch.id === value.organisation_branch.id
        );
        //
        //
        res[value.organisation_branch.id] = {
          organisation_branch: value.organisation_branch,
          isShowBrewing: isBrewing,
        };
        result.push(res[value.organisation_branch.id]);
      }

      return res;
    }, {});
    setCostingHeader((memoCostingHeader) => {
      let tempCostingHeader = [...memoCostingHeader];
      if (
        Object.keys(result).length === 0 &&
        Object.keys(tempCostingHeader).length !== 0
      ) {
        const disabledDelivery = tempCostingHeader.map((item) => {
          return { ...item, isShowBrewing: false };
        });
        tempCostingHeader = [...disabledDelivery];
      }
      //
      result.map((resultItem) => {
        const resultItemIndex = tempCostingHeader.findIndex(
          (indexItem) =>
            indexItem.organisation_branch.id ===
            resultItem.organisation_branch.id
        );

        if (resultItemIndex !== -1) {
          tempCostingHeader[resultItemIndex] = {
            ...tempCostingHeader[resultItemIndex],
            isShowBrewing: resultItem.isShowBrewing,
          };
        }
        return "";
      });
      return tempCostingHeader;
    });
  }, [memoGroupOption, setCostingHeader]);

  const deliveryWCount = () => {
    //check the selected group get update and proceed

    const postalCodeSurchargeList = storeOrderMode
      ? workOrder?.getStorePostalCodeSurchargeList?.postal_code_surcharges
      : visitOrder?.getVisitPostalCodeSurchargeList?.postal_code_surcharges;

    if (memoSelectedGroup) {
      //pending
    }
    let deliveryGroupOption = memoGroupOption.filter((item) => {
      return (
        item.is_delivery_services === true &&
        item.medicine_type &&
        item.organisation_branch &&
        Object.keys(item.store_medicine_details).length !== 0
      );
    });

    const countDeliveryAddress = [
      ...deliveryGroupOption
        .reduce((r, { ...o }) => {
          const postalCodeSurcharge = postalCodeSurchargeList?.find(
            (item) =>
              item.organisation.id === o.organisation_branch.organisation.id &&
              item.postal_code === o.delivery_postal_code
          );

          const key =
            o.organisation_branch.id +
            "-" +
            o.delivery_postal_code +
            "-" +
            o.delivery_address_details +
            "-" +
            o.delivery_address;

          const itemWDeliveryPrice =
            r.get(key) ||
            Object.assign(
              {},
              { freeDeliveryFee: 0, ...o },
              {
                deliveryCharge: postalCodeSurcharge
                  ? Number(postalCodeSurcharge.surcharge) +
                    Number(o.organisation_branch.branch.delivery_charge)
                  : Number(o.organisation_branch.branch.delivery_charge),
                organisation_branch: o.organisation_branch,
              }
            );

          return r.set(key, {
            ...itemWDeliveryPrice,
            freeDeliveryFee:
              Number(itemWDeliveryPrice.freeDeliveryFee) + Number(o.price),
            deliveryCharge:
              itemWDeliveryPrice.organisation_branch.branch
                .min_medicine_spend_for_free_delivery >
              Number(itemWDeliveryPrice.freeDeliveryFee) + Number(o.price)
                ? Number(itemWDeliveryPrice.deliveryCharge)
                : 0,
          });
        }, new Map())
        .values(),
    ];

    // const totalDeliveyCharge = countDeliveryAddress.reduce(
    //   (sum, li) => sum + li.deliveryCharge,
    //   0
    // );
    //sum up delivery price based on organisation_branch
    var result = [];
    countDeliveryAddress.reduce(function (res, value) {
      if (!res[value.organisation_branch.Id]) {
        res[value.organisation_branch.Id] = {
          organisation_branch: value.organisation_branch,
          deliveryCharge: 0,
        };
        result.push(res[value.organisation_branch.Id]);
      }
      res[value.organisation_branch.Id].deliveryCharge += value.deliveryCharge;
      return res;
    }, {});

    return result;
  };

  const memoDeliveryWCount = React.useMemo(deliveryWCount, [
    memoGroupOption,
    memoSelectedGroup,
    storeOrderMode,
    workOrder?.getStorePostalCodeSurchargeList,
    visitOrder?.getVisitPostalCodeSurchargeList,
  ]);

  // useEffect(() => {
  //   if (memoDeliveryWCount === 0) {
  //     setPrice((p) => ({
  //       ...p,
  //       deliveryFee: 0,
  //     }));
  //   } else {
  //     setPrice((p) => ({
  //       ...p,
  //       deliveryFee: memoDeliveryWCount,
  //     }));
  //   }
  // }, [memoDeliveryWCount, setPrice]);

  useEffect(() => {
    setCostingHeader((memoCostingHeader) => {
      let tempCostingHeader = [...memoCostingHeader];
      if (
        Object.keys(memoDeliveryWCount).length === 0 &&
        Object.keys(tempCostingHeader).length !== 0
      ) {
        const emptyDeliveryWCount = tempCostingHeader.map((item) => {
          return { ...item, branch_delivery_price: 0 };
        });
        tempCostingHeader = [...emptyDeliveryWCount];
      }
      if (
        Object.keys(memoDeliveryWCount).length !== 0 &&
        Object.keys(memoDeliveryWCount).length <
          Object.keys(tempCostingHeader).length
      ) {
        //remove extra fee when memoDeliveryWCount is clear
        const removeDeliveryWCount = tempCostingHeader.map((item) => {
          const deliveryItemIndex = memoDeliveryWCount.findIndex(
            (indexItem) =>
              indexItem.organisation_branch.id === item.organisation_branch.id
          );
          if (deliveryItemIndex === -1) {
            return { ...item, branch_delivery_price: 0 };
          } else {
            return { ...item };
          }
        });
        tempCostingHeader = [...removeDeliveryWCount];
      }

      memoDeliveryWCount.map((deliveryItem) => {
        const deliveryItemIndex = tempCostingHeader.findIndex(
          (indexItem) =>
            indexItem.organisation_branch.id ===
            deliveryItem.organisation_branch.id
        );

        //check any empty or same organisation branch
        if (deliveryItemIndex !== -1) {
          tempCostingHeader[deliveryItemIndex] = {
            ...tempCostingHeader[deliveryItemIndex],
            branch_delivery_price: deliveryItem.deliveryCharge,
          };
        } else {
          tempCostingHeader.push({
            id: 0,
            branch_delivery_price: deliveryItem.deliveryCharge,
            organisation_branch: deliveryItem.organisation_branch,
            status_type: generalConstants.APPROVED_STATUS_TYPE,
          });
        }
        return "";
      });
      return tempCostingHeader;
    });
  }, [memoDeliveryWCount, setCostingHeader]);

  //showing delivery
  useEffect(() => {
    // get the array of the delivery

    const groupOptionWMedicineDetails = memoGroupOption.filter(
      (option) =>
        option.store_medicine_details &&
        Object.keys(option.store_medicine_details).length !== 0 &&
        option.store_medicine_details.some(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
    );

    //grouping by organisation_branch and show delivery price
    var result = [];
    groupOptionWMedicineDetails.reduce(function (res, value) {
      if (!res[value.organisation_branch.id]) {
        const isDelivery = groupOptionWMedicineDetails.some(
          (item) =>
            item.is_delivery_services === true &&
            item.organisation_branch.id === value.organisation_branch.id
        );
        //
        //
        res[value.organisation_branch.id] = {
          organisation_branch: value.organisation_branch,
          isShowDelivery: isDelivery,
        };
        result.push(res[value.organisation_branch.id]);
      }

      return res;
    }, {});
    setCostingHeader((memoCostingHeader) => {
      let tempCostingHeader = [...memoCostingHeader];
      if (
        Object.keys(result).length === 0 &&
        Object.keys(tempCostingHeader).length !== 0
      ) {
        const disabledDelivery = tempCostingHeader.map((item) => {
          return { ...item, isShowDelivery: false };
        });
        tempCostingHeader = [...disabledDelivery];
      }
      //
      result.map((resultItem) => {
        const resultItemIndex = tempCostingHeader.findIndex(
          (indexItem) =>
            indexItem.organisation_branch.id ===
            resultItem.organisation_branch.id
        );

        if (resultItemIndex !== -1) {
          tempCostingHeader[resultItemIndex] = {
            ...tempCostingHeader[resultItemIndex],
            isShowDelivery: resultItem.isShowDelivery,
          };
        }
        return "";
      });
      return tempCostingHeader;
    });
  }, [memoGroupOption, setCostingHeader]);

  if (workOrder.getCurrentOrganisationIsSuccess === true) {
    const currentOrganistion = workOrder.currentOrganistion.organisation;
    return (
      <>
        {costingHeader.map((item) => (
          <TotalPricePanel
            key={item.organisation_branch.branch.id}
            price={price}
            classes={classes}
            t={t}
            item={item}
            currentOrganistion={currentOrganistion}
          />
        ))}

        <Panel
          heading={
            t("translation:Total Fee") +
            " $" +
            (
              Number(price.medicineFee) +
              Number(price.brewingFee) +
              Number(price.deliveryFee)
            ).toFixed(2)
          }>
          <Grid container>
            <Grid xs={3} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={Number(price.medicineFee).toFixed(2)}
                disabled
                name="overAlltotalMedicalPrice"
                cy_data="overAlltotalMedicalPrice"
                label={t("translation:Total Medicine Fee")}
                variant="outlined"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    fontWeight: "bold",
                    color: "rgba(149, 0, 0,0.75)",
                  },
                }}
              />
            </Grid>
            {showBrewingPrice && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(price.brewingFee).toFixed(2)}
                  disabled
                  name="overAllbrewingFee"
                  cy_data="overAllbrewingFee"
                  label={t("translation:Brewing Fee")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}
            {showDeliveryPrice && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(price.deliveryFee).toFixed(2)}
                  disabled
                  name="overAlldeliveryFee"
                  cy_data="overAlldeliveryFee"
                  label={t("translation:Delivery Fee")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}
            {price.vendorGstAmt > 0 && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(price.vendorGstAmt).toFixed(2)}
                  disabled
                  name="vendorGstAmmount"
                  cy_data="vendorGstAmmount"
                  label={t("translation:Tax Amount")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Panel>
      </>
    );
  } else return <></>;
}
