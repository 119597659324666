//lib
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
//components
import DropZoneDialog from "../../../../components/DropZoneDialog";
import { useStyles } from "../../../../components/globalStyles";
import * as generalConstants from "../../../../_constants/generalConstants";
//action

import { postCreatePresignedJsonDeliveryTracking } from "../../../../actions/cloudStorageActions";

import externetApis from "../../../../_apis/externetApis";

export default function EditAttachment({
  organisationCode = "default",
  folderPath = "delivery_tracking_attachment",
  uploadFileObjects = [],
  setUploadFileObjects = () => null,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const prevCreatePresignedJsonStatus = useRef();
  const cloudStorage = useSelector((state) => state.cloudStorage);
  const curUser = useSelector((state) => state.curUser);
  const EDIT_FILE_UPLOAD_TYPE = curUser.allRightAccess
    ? curUser.allRightAccess.user_add_permissions.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_DELIVERY_TRACKING_RIGHT_TYPE &&
          item.add_permission_type === generalConstants.EDIT_FILE_UPLOAD_TYPE
      )
    : false;
  const [fileObjects, setFileObjects] = React.useState([]);

  const [fileObjectIndex, setFileObjectIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [msgAlarm, setMsgAlarm] = React.useState(null);
  const [alertMsg, setAlertMsg] = React.useState(false);
  const [alertType, setAlertType] = React.useState(null);

  const uploadFileOnHandle = () => {
    setAlertMsg(false);
    setMsgAlarm(null);
    setAlertType(null);
    if (fileObjects.length > 0) {
      setFileObjectIndex(fileObjects.length - 1);
      const submitJson = {
        object_name: fileObjects[fileObjects.length - 1].name,
      };
      dispatch(postCreatePresignedJsonDeliveryTracking.pending(submitJson));
    }
  };

  //useEffects

  useEffect(() => {
    if (!open) {
      setFileObjectIndex(0);
      setFileObjects([]);
      setAlertMsg(false);
      setMsgAlarm(null);
      setAlertType(null);
    }
  }, [open]);

  useEffect(() => {
    prevCreatePresignedJsonStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      cloudStorage.isLoadingPostCreatePresignedJsonDeliveryTracking !==
      prevCreatePresignedJsonStatus.current
    ) {
      //check the previous ref with current state
      prevCreatePresignedJsonStatus.current =
        cloudStorage.isLoadingPostCreatePresignedJsonDeliveryTracking;

      if (
        cloudStorage.postCreatePresignedJsonDeliveryTrackingIsSuccess ===
          true &&
        cloudStorage.isLoadingPostCreatePresignedJsonDeliveryTracking === false
      ) {
        let cloudStrogeKeyWithFile = fileObjects.find(
          (keyItem) =>
            keyItem.name ===
            cloudStorage.postCreatePresignedJsonDeliveryTracking.fields.key
        );
        const cloudStrogePresignedJson = {
          ...cloudStorage.postCreatePresignedJsonDeliveryTracking.fields,
          postUrl: cloudStorage.postCreatePresignedJsonDeliveryTracking.url,
        };
        cloudStrogeKeyWithFile = {
          ...cloudStrogeKeyWithFile,
          ...cloudStrogePresignedJson,
        };

        (async () => {
          const response = await externetApis.postS3FileApi(
            cloudStrogeKeyWithFile
          );
          if (response.status === 204) {
            if (fileObjectIndex > 0) {
              const submitJson = {
                object_name: fileObjects[fileObjectIndex - 1].name,
              };
              dispatch(
                postCreatePresignedJsonDeliveryTracking.pending(submitJson)
              );
              setFileObjectIndex(fileObjectIndex - 1);
            }

            if (fileObjectIndex === 0) {
              setMsgAlarm(t("translation:File has been uploaded"));
              setAlertMsg(true);
              setAlertType("success");

              setFileObjectIndex(0);
              setFileObjects([]);
            }

            const fileObject = {
              id: 0,
              file: { name: cloudStrogeKeyWithFile.file.name },
              name: cloudStrogeKeyWithFile.name,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
              original_filename: cloudStrogeKeyWithFile.file.name,
              url: cloudStrogeKeyWithFile.postUrl + cloudStrogeKeyWithFile.key,
            };

            setUploadFileObjects((item) => [...item, fileObject]);
          } else {
            setMsgAlarm(t("translation:File failed to upload"));
            setAlertMsg(true);
            setAlertType("error");
          }
        })();
      }
      if (
        cloudStorage.postCreatePresignedJsonDeliveryTrackingIsSuccess ===
          false &&
        cloudStorage.isLoadingPostCreatePresignedJsonDeliveryTracking === false
      ) {
        setMsgAlarm(
          cloudStorage.error.postCreatePresignedJsonDeliveryTrackingError
        );
        setAlertMsg(true);
        setAlertType("error");
      }
    }
  }, [
    cloudStorage,
    fileObjects,
    dispatch,
    fileObjectIndex,
    setUploadFileObjects,
    t,
  ]);

  return (
    <>
      {EDIT_FILE_UPLOAD_TYPE && (
        <Link className={classes.boldMaroon075}>
          <Button
            className={classes.boldMaroon075}
            name="uploadButton"
            onClick={() => setOpen(true)}>
            {t("translation:UPLOAD ATTACHMENTS")}
          </Button>
        </Link>
      )}
      <DropZoneDialog
        dialogTitle={<span>{t("translation:Upload")}</span>}
        fileObjects={[
          ...uploadFileObjects.filter(
            (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
          ),
          ...fileObjects,
        ]}
        fullWidth
        cancelButtonText={t("translation:BACK")}
        submitButtonText={t("translation:UPLOAD")}
        acceptedFiles={[".png,.jpg,.jpeg"]}
        supportedFileType={".png, .jpg, .jpeg, .pdf"}
        maxFileSize={5000000}
        filesLimit={10}
        dropzoneText={t("translation:Drag and drop a file here or click")}
        previewText={""}
        maxWidth="lg"
        open={open}
        showAlerts={false}
        useChipsForPreview={true}
        onAdd={(newFileObjs) => {
          setAlertMsg(false);
          setMsgAlarm("");

          let newAddFileObjs = newFileObjs.reduce((array, item) => {
            const sameNameItem = uploadFileObjects.some(
              (existingItem) =>
                existingItem.status_type ===
                  generalConstants.APPROVED_STATUS_TYPE &&
                existingItem.original_filename === item.file.name
            );

            const sameNewNameItem = fileObjects.some(
              (existingItem) => existingItem.file.name === item.file.name
            );

            if (!sameNameItem && !sameNewNameItem) {
              const extensionIndex = item.file.type.lastIndexOf("/");
              const extensionformat = item.file.type.substring(
                extensionIndex + 1
              );
              const name = uuidv4();
              const yearNumber = dayjs().get("year");
              const monthNumber = dayjs().get("month") + 1; // start 0
              const dateNumber = dayjs().get("date");
              const newObj = {
                ...item,
                name:
                  organisationCode +
                  "/" +
                  folderPath +
                  "/" +
                  yearNumber +
                  "/" +
                  monthNumber +
                  "/" +
                  dateNumber +
                  "/" +
                  name +
                  "." +
                  extensionformat,
              };
              array.push(newObj);
            }
            return array;
          }, []);

          setFileObjects([].concat(fileObjects, newAddFileObjs));
        }}
        onDelete={(deleteFileObj) => {
          if (deleteFileObj.id) {
            const deleteduploadfileObject = uploadFileObjects.find(
              (item) => item.name === deleteFileObj.name
            );
            const filterDeleteduploadfileObjects = uploadFileObjects.filter(
              (item) => item.name !== deleteFileObj.name
            );
            const newUploadfileObjects = [
              ...filterDeleteduploadfileObjects,
              {
                ...deleteduploadfileObject,
                status_type: generalConstants.DELETED_STATUS_TYPE,
              },
            ];
            setUploadFileObjects(newUploadfileObjects);
          } else {
            setUploadFileObjects(
              uploadFileObjects.filter(
                (item) => item.name !== deleteFileObj.name
              )
            );
            setFileObjects(
              fileObjects.filter((item) => item.name !== deleteFileObj.name)
            );
          }
        }}
        onClose={() => setOpen(false)}
        onSave={() => {
          uploadFileOnHandle();
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
        msgAlarm={msgAlarm}
        alertMsg={alertMsg}
        setAlertMsg={setAlertMsg}
        alertType={alertType}
        getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
          setMsgAlarm(rejectedFile.name + " " + t("translation:is rejected"));
          setAlertMsg(true);
          setAlertType("warning");
        }}
        getFileLimitExceedMessage={(filesLimit) => {
          setMsgAlarm(
            t("translation:more than") +
              " " +
              filesLimit +
              " " +
              t("translation:file(s)")
          );
          setAlertMsg(true);
          setAlertType("warning");
        }}
      />
    </>
  );
}
