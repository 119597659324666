//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
//import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//components
import DeliveryTrackingListTable from "./DeliveryTrackingListTable";
import { useStyles } from "../../../components/globalStyles";

//actions

const DeliveryTrackingSummaryPage = () => {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  //display and hide the "deliverytracking summary" table
  const [deliverytrackingInformationView, setDeliveryTrackingInformationView] =
    React.useState(false);

  //useEffects

  // const workOrder = useSelector((state) => state.workOrder);

  return (
    <>
      <Paper className={classes.summaryPaper} elevation={3}>
        {!deliverytrackingInformationView && (
          <Grid container direction="row" alignItems="center">
            <Grid item xs={11}>
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Delivery Tracking Summary")}
              </Typography>
            </Grid>
          </Grid>
        )}
        <hr />

        <DeliveryTrackingListTable
          setDeliveryTrackingInformationView={
            setDeliveryTrackingInformationView
          }
        />
      </Paper>
    </>
  );
};

export default DeliveryTrackingSummaryPage;
