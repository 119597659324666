import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const packingApis = {
  putBrewingWIPModeApi,
  putBrewingPendingStatusApi,
  getPackingListApi,
  getPackingSummaryListApi,
  getPackingByIdApi,
  getPackingByOrderNumApi,
  postSubmitPackingApi,
  putPackingWIPModeApi,
  putPackingPendingStatusApi,
  putPackingCloseModeApi,
};

async function putBrewingWIPModeApi(model) {
  const url = apiConstants.apiPutBrewingWIPMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function putBrewingPendingStatusApi(model) {
  const url = apiConstants.apiPutBrewingPendingStatus + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getPackingListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetPackingList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getPackingSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetPackingSummaryList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getPackingByIdApi(model) {
  const url = apiConstants.apiGetPackingById + model;

  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getPackingByOrderNumApi(model) {
  const url = apiConstants.apiGetPackingByOrderNum + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitPackingApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostSubmitPacking, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putPackingWIPModeApi(model) {
  const url = apiConstants.apiPutPackingWIPMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putPackingPendingStatusApi(model) {
  const url = apiConstants.apiPutPackingPendingStatus + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putPackingCloseModeApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(apiConstants.apiPutPackingCloseMode, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default packingApis;
