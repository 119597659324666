import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const medicineFixedSellingPriceDetailsApis = {
  getMedicineFixedSellingPriceDetailsApi,
  getMedicineFixedSellingPriceHeaderDetailsListApi,
  getMedicineFixedSellingPriceDetailsSummaryListApi,
  getMedicineFixedSellingPriceDetailsByIdApi,
  putUpdateMedicineFixedSellingPriceDetailsApi,
  getMedicineFixedSellingPriceDetailsMedicineTypesApi,
  getMedicineFixedSellingPriceDetailsMedicineInfoApi,
};

async function getMedicineFixedSellingPriceDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(
      apiConstants.apiGetMedicineFixedSellingPriceDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineFixedSellingPriceHeaderDetailsListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(
      apiConstants.apiGetMedicineFixedSellingPriceHeaderDetailsList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineFixedSellingPriceDetailsSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(
      apiConstants.apiGetMedicineFixedSellingPriceDetailsSummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineFixedSellingPriceDetailsByIdApi(model) {
  const url =
    apiConstants.apiGetMedicineFixedSellingPriceDetailsById + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateMedicineFixedSellingPriceDetailsApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(
      apiConstants.apiPutUpdateMedicineFixedSellingPriceDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineFixedSellingPriceDetailsMedicineTypesApi(model) {
  const url = apiConstants.apiGetMedicineFixedSellingPriceDetailsMedicineTypes;
  return await axios
    .get(url)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineFixedSellingPriceDetailsMedicineInfoApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(
      apiConstants.apiGetMedicineFixedSellingPriceDetailsMedicineInfo,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default medicineFixedSellingPriceDetailsApis;
