//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
//import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//components
import PackingListTable from "./PackingListTable";
import { useStyles } from "../../../components/globalStyles";

//actions

const PackingSummaryPage = () => {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  //display and hide the "packing summary" table
  const [packingInformationView, setPackingInformationView] =
    React.useState(false);

  //useEffects

  // const workOrder = useSelector((state) => state.workOrder);

  return (
    <>
      <Paper className={classes.summaryPaper} elevation={3}>
        {!packingInformationView && (
          <Grid container direction="row" alignItems="center">
            <Grid item xs={11}>
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Packing Summary")}
              </Typography>
            </Grid>
          </Grid>
        )}
        <hr />

        <PackingListTable
          setPackingInformationView={setPackingInformationView}
        />
      </Paper>
    </>
  );
};

export default PackingSummaryPage;
