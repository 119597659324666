import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getEmailStoreSenderList,
  getEmailStoreSenderById,
  postCreateEmailStoreSender,
  putUpdateEmailStoreSender,
  getEmailStoreSenderOrganisationBranch,
  getEmailStoreSenderMedicineType,
  getEmailStoreSenderOrganisationPhysician,
} from "../actions/emailStoreSenderActions";
import * as types from "../_constants/emailStoreSenderConstants";
import * as notification from "../_constants/notificationConstants";
import emailStoreSenderApis from "../_apis/emailStoreSenderApis";
export default function* emailStoreSenderSagas() {
  yield all([
    takeLatest(
      types.GET_EMAILSTORESENDERLIST_ASYNC.PENDING,
      getEmailStoreSenderListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_EMAILSTORESENDERBYID_ASYNC.PENDING,
      getEmailStoreSenderByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATEEMAILSTORESENDER_ASYNC.PENDING,
      postCreateEmailStoreSenderCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEEMAILSTORESENDER_ASYNC.PENDING,
      putUpdateEmailStoreSenderCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_EMAILSTORESENDERORGANISATIONBRANCH_ASYNC.PENDING,
      getEmailStoreSenderOrganisationBranchCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_EMAILSTORESENDERMEDICINETYPE_ASYNC.PENDING,
      getEmailStoreSenderMedicineTypeCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_EMAILSTORESENDERORGANISATIONPHYSICIAN_ASYNC.PENDING,
      getEmailStoreSenderOrganisationPhysicianCall
    ),
  ]);
}

function* getEmailStoreSenderListCall(searchModel) {
  try {
    const data = yield call(
      emailStoreSenderApis.getEmailStoreSenderListApi,
      searchModel.data
    );
    yield put(getEmailStoreSenderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getEmailStoreSenderList.error(error.response?.data?.message));
    else
      yield put(getEmailStoreSenderList.error(notification.CONST_NOINTERNET));
  }
}

function* getEmailStoreSenderByIdCall(searchModel) {
  try {
    const data = yield call(
      emailStoreSenderApis.getEmailStoreSenderByIdApi,
      searchModel.data
    );
    yield put(getEmailStoreSenderById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getEmailStoreSenderById.error(error.response?.data?.message));
    else
      yield put(getEmailStoreSenderById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateEmailStoreSenderCall(searchModel) {
  try {
    const data = yield call(
      emailStoreSenderApis.postCreateEmailStoreSenderApi,
      searchModel.data
    );
    yield put(postCreateEmailStoreSender.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postCreateEmailStoreSender.error(error.response?.data?.message)
      );
    else
      yield put(
        postCreateEmailStoreSender.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateEmailStoreSenderCall(searchModel) {
  try {
    const data = yield call(
      emailStoreSenderApis.putUpdateEmailStoreSenderApi,
      searchModel.data
    );
    yield put(putUpdateEmailStoreSender.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateEmailStoreSender.error(error.response?.data?.message));
    else
      yield put(putUpdateEmailStoreSender.error(notification.CONST_NOINTERNET));
  }
}

function* getEmailStoreSenderOrganisationBranchCall(searchModel) {
  try {
    const data = yield call(
      emailStoreSenderApis.getEmailStoreSenderOrganisationBranchApi,
      searchModel.data
    );
    yield put(getEmailStoreSenderOrganisationBranch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getEmailStoreSenderOrganisationBranch.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getEmailStoreSenderOrganisationBranch.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getEmailStoreSenderMedicineTypeCall(searchModel) {
  try {
    const data = yield call(
      emailStoreSenderApis.getEmailStoreSenderMedicineTypeApi,
      searchModel.data
    );
    yield put(getEmailStoreSenderMedicineType.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getEmailStoreSenderMedicineType.error(error.response?.data?.message)
      );
    else
      yield put(
        getEmailStoreSenderMedicineType.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getEmailStoreSenderOrganisationPhysicianCall(searchModel) {
  try {
    const data = yield call(
      emailStoreSenderApis.getEmailStoreSenderOrganisationPhysicianApi,
      searchModel.data
    );
    yield put(getEmailStoreSenderOrganisationPhysician.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getEmailStoreSenderOrganisationPhysician.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getEmailStoreSenderOrganisationPhysician.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}
