//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
//action
import {
  getRoleRightList,
  getRoleRight,
} from "../../../../actions/roleRightActions";

import {
  getPermission,
  getRight,
  getViewNeeds,
} from "../../../../actions/commonActions";
//component
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { RoleRightDetailPage } from "./RoleRightDetailPage";
import { EditRoleRightPage } from "./EditRoleRightPage";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";

export default function RoleListTable({ setInformationView }) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.ADMIN_ROLE_RIGHTS_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Description"),
      accessor: "desc",
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    search_name_value: "",
    search_desc_value: "",

    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });

  const dispatch = useDispatch();

  const commonData = useSelector((state) => state.common);

  const roleRightdata = useSelector((state) => state.roleRight);
  //

  const [editRoleRightView, setEditRoleRightView] = React.useState(false);

  const [roleRightDetailView, setRoleRightDetailView] = React.useState(false);

  const [searchValue, setSearchValue] = React.useState("");
  //functions
  const handleRowEdit = (value) => {
    dispatch(getRoleRight.pending(value.id));
    dispatch(getPermission.pending());
    dispatch(getRight.pending());
    dispatch(getViewNeeds.pending());

    setEditRoleRightView(true);
  };

  const handleRowDetail = (value) => {
    dispatch(getRoleRight.pending(value.id));
    dispatch(getPermission.pending());
    dispatch(getRight.pending());
    dispatch(getViewNeeds.pending());
    setRoleRightDetailView(true);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: value,
        search_desc_value: "",
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
        search_desc_value: "",
      });
    }
  };

  //useEffects
  useEffect(() => {
    if (roleRightDetailView === true || editRoleRightView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [roleRightDetailView, editRoleRightView, setInformationView]);

  useEffect(() => {
    dispatch(getRoleRightList.pending(pageInfo));
  }, [dispatch, pageInfo]);
  if (
    roleRightdata.getRoleRightListIsSuccess === false &&
    roleRightdata.isLoadingRoleRightList === false
  ) {
    return (
      <Alert severity="error">{roleRightdata.error.roleRightListError}</Alert>
    );
  } else if (!editRoleRightView && !roleRightDetailView) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name")}
        searchValue={searchValue}
        columns={columns}
        data={
          roleRightdata.roleRightList ? roleRightdata.roleRightList.roles : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          roleRightdata.roleRightList
            ? roleRightdata.roleRightList.total_pages
            : 0
        }
        loading={roleRightdata.isLoadingRoleRightList}
        totalItems={
          roleRightdata.roleRightList
            ? roleRightdata.roleRightList.total_items
            : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (
    roleRightDetailView &&
    roleRightdata.getRoleRightIsSuccess === true &&
    commonData.getPermissionIsSuccess === true &&
    commonData.getRightIsSuccess === true &&
    commonData.getViewNeedsIsSuccess === true
  ) {
    return (
      <RoleRightDetailPage
        roleRight={roleRightdata.roleRight}
        common={commonData}
        setRoleRightDetailView={setRoleRightDetailView}
      />
    );
  } else if (
    editRoleRightView &&
    roleRightdata.getRoleRightIsSuccess === true &&
    commonData.getPermissionIsSuccess === true &&
    commonData.getRightIsSuccess === true &&
    commonData.getViewNeedsIsSuccess === true
  ) {
    return (
      <EditRoleRightPage
        roleRight={roleRightdata.roleRight}
        common={commonData}
        setEditRoleRightView={setEditRoleRightView}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
