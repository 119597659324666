import * as types from "../_constants/courierConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_COURIERBYID_ASYNC.PENDING:
      return {
        ...state,
        getCourierByIdIsSuccess: false,
        isLoadingGetCourierById: true,
        getCourierById: null,
      };

    case types.GET_COURIERBYID_ASYNC.ERROR:
      return {
        ...state,
        getCourierByIdIsSuccess: false,
        isLoadingGetCourierById: false,
        getCourierById: null,
        error: { ...state.error, getCourierByIdError: action.error },
      };

    case types.GET_COURIERBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getCourierByIdIsSuccess: true,
        isLoadingGetCourierById: false,
        getCourierById: action.data,
      };

    case types.GET_COURIERLIST_ASYNC.PENDING:
      return {
        ...state,
        getCourierListIsSuccess: false,
        isLoadingGetCourierList: true,
        getCourierList: null,
      };

    case types.GET_COURIERLIST_ASYNC.ERROR:
      return {
        ...state,
        getCourierListIsSuccess: false,
        isLoadingGetCourierList: false,
        getCourierList: null,
        error: { ...state.error, getCourierListError: action.error },
      };

    case types.GET_COURIERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getCourierListIsSuccess: true,
        isLoadingGetCourierList: false,
        getCourierList: action.data,
      };

    case types.POST_CREATECOURIER_ASYNC.PENDING:
      return {
        ...state,
        postCreateCourierIsSuccess: false,
        isLoadingPostCreateCourier: true,
        postCreateCourier: null,
      };

    case types.POST_CREATECOURIER_ASYNC.ERROR:
      return {
        ...state,
        postCreateCourierIsSuccess: false,
        isLoadingPostCreateCourier: false,
        postCreateCourier: null,
        error: { ...state.error, postCreateCourierError: action.error },
      };

    case types.POST_CREATECOURIER_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateCourierIsSuccess: true,
        isLoadingPostCreateCourier: false,
        postCreateCourier: action.data,
      };

    case types.PUT_UPDATECOURIER_ASYNC.PENDING:
      return {
        ...state,
        putUpdateCourierIsSuccess: false,
        isLoadingPutUpdateCourier: true,
        putUpdateCourier: null,
      };

    case types.PUT_UPDATECOURIER_ASYNC.ERROR:
      return {
        ...state,
        putUpdateCourierIsSuccess: false,
        isLoadingPutUpdateCourier: false,
        putUpdateCourier: null,
        error: { ...state.error, putUpdateCourierError: action.error },
      };

    case types.PUT_UPDATECOURIER_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateCourierIsSuccess: true,
        isLoadingPutUpdateCourier: false,
        putUpdateCourier: action.data,
      };

    default:
      return state;
  }
};
