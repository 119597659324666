import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MedicineFixedSellingPriceSummaryPage from "./MedicineFixedSellingPriceSummary/MedicineFixedSellingPriceSummaryPage";
export default function MedicineFixedSellingPricePage() {
  return (
    <Switch>
      <Route
        path="/medicineFixedSellingPrice/medicineFixedSellingPriceSummary"
        component={MedicineFixedSellingPriceSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
