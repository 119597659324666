import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAllVisitByLastModified,
  getVerifyMedicine,
  postUpdateHomecareMedicine,
  getAllVisitByTimestampRange,
  getUpdateHCVisitOrderByStoreId,
  postUpdateHomecareManualHc2cms,
} from "../actions/homecareActions";
import * as types from "../_constants/homecareConstants";
import * as notification from "../_constants/notificationConstants";
import homecareApis from "../_apis/homecareApis";
export default function* homecareSagas() {
  yield all([
    takeLatest(
      types.GET_ALLVISITBYLASTMODIFIED_ASYNC.PENDING,
      getAllVisitByLastModifiedCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_VERIFYMEDICINE_ASYNC.PENDING, getVerifyMedicineCall),
  ]);
  yield all([
    takeLatest(
      types.POST_UPDATEHOMECAREMEDICINE_ASYNC.PENDING,
      postUpdateHomecareMedicineCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_ALLVISITBYTIMESTAMPRANGE_ASYNC.PENDING,
      getAllVisitByTimestampRangeCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_UPDATEHCVISITORDERBYSTOREID_ASYNC.PENDING,
      getUpdateHCVisitOrderByStoreIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_UPDATEHOMECAREMANUALHC2CMS_ASYNC.PENDING,
      postUpdateHomecareManualHc2cmsCall
    ),
  ]);
}

function* getAllVisitByLastModifiedCall(Model) {
  try {
    const data = yield call(
      homecareApis.getAllVisitByLastModifiedApi,
      Model.data
    );
    yield put(getAllVisitByLastModified.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAllVisitByLastModified.error(error.response?.data?.message));
    else
      yield put(getAllVisitByLastModified.error(notification.CONST_NOINTERNET));
  }
}

function* getVerifyMedicineCall(Model) {
  try {
    const data = yield call(homecareApis.getVerifyMedicineApi, Model.data);
    yield put(getVerifyMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVerifyMedicine.error(error.response?.data?.message));
    else yield put(getVerifyMedicine.error(notification.CONST_NOINTERNET));
  }
}

function* postUpdateHomecareMedicineCall(Model) {
  try {
    const data = yield call(
      homecareApis.postUpdateHomecareMedicineApi,
      Model.data
    );
    yield put(postUpdateHomecareMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postUpdateHomecareMedicine.error(error.response?.data?.message)
      );
    else
      yield put(
        postUpdateHomecareMedicine.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getAllVisitByTimestampRangeCall(Model) {
  try {
    const data = yield call(
      homecareApis.getAllVisitByTimestampRangeApi,
      Model.data
    );
    yield put(getAllVisitByTimestampRange.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getAllVisitByTimestampRange.error(error.response?.data?.message)
      );
    else
      yield put(
        getAllVisitByTimestampRange.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getUpdateHCVisitOrderByStoreIdCall(Model) {
  try {
    const data = yield call(
      homecareApis.getUpdateHCVisitOrderByStoreIdApi,
      Model.data
    );
    yield put(getUpdateHCVisitOrderByStoreId.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getUpdateHCVisitOrderByStoreId.error(error.response?.data?.message)
      );
    else
      yield put(
        getUpdateHCVisitOrderByStoreId.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postUpdateHomecareManualHc2cmsCall(Model) {
  try {
    const data = yield call(
      homecareApis.postUpdateHomecareManualHc2cmsApi,
      Model.data
    );
    yield put(postUpdateHomecareManualHc2cms.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postUpdateHomecareManualHc2cms.error(error.response?.data?.message)
      );
    else
      yield put(
        postUpdateHomecareManualHc2cms.error(notification.CONST_NOINTERNET)
      );
  }
}
