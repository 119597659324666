import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/orderFormConstants";

export const getOrderFormList = {
  pending: (data) =>
    createAction(types.GET_ORDERFORMLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ORDERFORMLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ORDERFORMLIST_ASYNC.ERROR, { error }),
};

export const getOrderFormById = {
  pending: (data) =>
    createAction(types.GET_ORDERFORMBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ORDERFORMBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ORDERFORMBYID_ASYNC.ERROR, { error }),
};

export const getOrderFormMedicineTypes = {
  pending: (data) =>
    createAction(types.GET_ORDERFORMMEDICINETYPES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ORDERFORMMEDICINETYPES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ORDERFORMMEDICINETYPES_ASYNC.ERROR, { error }),
};

export const postOrderFormMedicines = {
  pending: (data) =>
    createAction(types.POST_ORDERFORMMEDICINES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_ORDERFORMMEDICINES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_ORDERFORMMEDICINES_ASYNC.ERROR, { error }),
};

export const postOrderFormSuppliers = {
  pending: (data) =>
    createAction(types.POST_ORDERFORMSUPPLIERS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_ORDERFORMSUPPLIERS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_ORDERFORMSUPPLIERS_ASYNC.ERROR, { error }),
};

export const postSubmitOrderForm = {
  pending: (data) =>
    createAction(types.POST_SUBMITORDERFORM_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITORDERFORM_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITORDERFORM_ASYNC.ERROR, { error }),
};

export const putApproveOrderForm = {
  pending: (data) =>
    createAction(types.PUT_APPROVEORDERFORM_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_APPROVEORDERFORM_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_APPROVEORDERFORM_ASYNC.ERROR, { error }),
};

export const putOrderFormEditMode = {
  pending: (data) =>
    createAction(types.PUT_ORDERFORMEDITMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_ORDERFORMEDITMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_ORDERFORMEDITMODE_ASYNC.ERROR, { error }),
};

export const putOrderFormCancelled = {
  pending: (data) =>
    createAction(types.PUT_ORDERFORMCANCELLED_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_ORDERFORMCANCELLED_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_ORDERFORMCANCELLED_ASYNC.ERROR, { error }),
};

export const putOrderFormPrintedDate = {
  pending: (data) =>
    createAction(types.PUT_ORDERFORMPRINTEDDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_ORDERFORMPRINTEDDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_ORDERFORMPRINTEDDATE_ASYNC.ERROR, { error }),
};
