import { createAsyncTypes } from "../_helper/Utilities";

export const GET_PACKINGSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_PACKINGSUMMARYLIST"
);

export const GET_PACKINGLIST_ASYNC = createAsyncTypes("GET_PACKINGLIST");

export const GET_PACKINGBYID_ASYNC = createAsyncTypes("GET_PACKINGBYID");

export const GET_PACKINGBYORDERNUM_ASYNC = createAsyncTypes(
  "GET_PACKINGBYORDERNUM"
);

export const POST_SUBMITPACKING_ASYNC = createAsyncTypes("POST_SUBMITPACKING");

export const PUT_PACKINGWIPMODE_ASYNC = createAsyncTypes("PUT_PACKINGWIPMODE");

export const PUT_PACKINGPENDINGSTATUS_ASYNC = createAsyncTypes(
  "PUT_PACKINGPENDINGSTATUS"
);

export const PUT_PACKINGCLOSEMODE_ASYNC = createAsyncTypes(
  "PUT_PACKINGCLOSEMODE"
);
