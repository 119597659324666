import * as types from "../_constants/acupointConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_ACUPOINTBYID_ASYNC.PENDING:
      return {
        ...state,
        getAcupointByIdIsSuccess: false,
        isLoadingGetAcupointById: true,
        getAcupointById: null,
      };

    case types.GET_ACUPOINTBYID_ASYNC.ERROR:
      return {
        ...state,
        getAcupointByIdIsSuccess: false,
        isLoadingGetAcupointById: false,
        getAcupointById: null,
        error: { ...state.error, getAcupointByIdError: action.error },
      };

    case types.GET_ACUPOINTBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getAcupointByIdIsSuccess: true,
        isLoadingGetAcupointById: false,
        getAcupointById: action.data,
      };

    case types.GET_ACUPOINTLIST_ASYNC.PENDING:
      return {
        ...state,
        getAcupointListIsSuccess: false,
        isLoadingGetAcupointList: true,
        getAcupointList: null,
      };

    case types.GET_ACUPOINTLIST_ASYNC.ERROR:
      return {
        ...state,
        getAcupointListIsSuccess: false,
        isLoadingGetAcupointList: false,
        getAcupointList: null,
        error: { ...state.error, getAcupointListError: action.error },
      };

    case types.GET_ACUPOINTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getAcupointListIsSuccess: true,
        isLoadingGetAcupointList: false,
        getAcupointList: action.data,
      };

    case types.POST_CREATEACUPOINT_ASYNC.PENDING:
      return {
        ...state,
        postCreateAcupointIsSuccess: false,
        isLoadingPostCreateAcupoint: true,
        postCreateAcupoint: null,
      };

    case types.POST_CREATEACUPOINT_ASYNC.ERROR:
      return {
        ...state,
        postCreateAcupointIsSuccess: false,
        isLoadingPostCreateAcupoint: false,
        postCreateAcupoint: null,
        error: { ...state.error, postCreateAcupointError: action.error },
      };

    case types.POST_CREATEACUPOINT_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateAcupointIsSuccess: true,
        isLoadingPostCreateAcupoint: false,
        postCreateAcupoint: action.data,
      };

    case types.PUT_UPDATEACUPOINT_ASYNC.PENDING:
      return {
        ...state,
        putUpdateAcupointIsSuccess: false,
        isLoadingPutUpdateAcupoint: true,
        putUpdateAcupoint: null,
      };

    case types.PUT_UPDATEACUPOINT_ASYNC.ERROR:
      return {
        ...state,
        putUpdateAcupointIsSuccess: false,
        isLoadingPutUpdateAcupoint: false,
        putUpdateAcupoint: null,
        error: { ...state.error, putUpdateAcupointError: action.error },
      };

    case types.PUT_UPDATEACUPOINT_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateAcupointIsSuccess: true,
        isLoadingPutUpdateAcupoint: false,
        putUpdateAcupoint: action.data,
      };

    default:
      return state;
  }
};
