import React, { useState, forwardRef } from "react";

import classNames from "classnames";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { postLogOut } from "../actions/authActions";
import { NavLink as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { Divider } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
//components

import { menuItems } from "./sideBarItems";
import MenuItems from "./Components/MenuItems";
import {
  getCreditBalance,
  getDefaultAlliance,
  getAllOrganisationStripe,
} from "../actions/curUserActions";

import { useStyles } from "../components/globalMenuStyles";

import * as generalConstants from "../_constants/generalConstants";

const NewLayout = ({ children, accessRight }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [menu, setMenu] = useState({});
  const dispatch = useDispatch();
  //const [openMenu, setMenuOpen] = React.useState(false);
  const [anchorEl, setanchorEl] = React.useState("");

  const [AppAnchorEl, setAppAnchorEl] = React.useState("");

  const curUser = useSelector((state) => state.curUser);
  const userAuth = useSelector((state) => state.userAuth);
  const onLogOut = () => {
    dispatch(postLogOut.pending());
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleBarMenu = (event) => {
    setAppAnchorEl(event.currentTarget);
    dispatch(getCreditBalance.pending());
    dispatch(getDefaultAlliance.pending());
    dispatch(getAllOrganisationStripe.pending());
  };
  const handleClose = () => {
    setAppAnchorEl(null);
  };

  const CustomRouterLink = forwardRef((children, ref) => (
    <span ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...children} />
    </span>
  ));

  //useEffects
  //  useEffect(() => {}, [curUser.creditBalance, curUser.allOrganisationStripe]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters={true}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classes.menuButton}
            size="large">
            <MenuIcon
              classes={{
                root: open
                  ? classes.menuButtonIconOpen
                  : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
          <Hidden lgDown>
            <Typography variant="h6" className={classes.grow} noWrap>
              {Object.keys(curUser).length !== 0 &&
              curUser.organisationInfo &&
              Object.keys(curUser.organisationInfo.user_organisations)
                .length !== 0
                ? curUser.organisationInfo.user_organisations.find(
                    (o) => o.is_default === true
                  )
                  ? curUser.organisationInfo.user_organisations.find(
                      (o) => o.is_default === true
                    ).organisations.desc
                  : curUser.organisationInfo.user_organisations[0].organisations
                      .desc
                : ""}
            </Typography>
          </Hidden>
          <Typography
            variant="h6"
            className={classes.userName}
            style={{ fontWeight: 600 }}
            noWrap>
            {userAuth.systemUserInfo ? userAuth.systemUserInfo.name : ""}
          </Typography>

          <React.Fragment>
            <IconButton
              color="inherit"
              className={classes.helpIcon}
              onClick={() => {
                window.open(generalConstants.FAQURL, "_blank", "noreferrer");
              }}>
              <HelpOutlineIcon />
            </IconButton>
            <IconButton
              className={classes.userIcon}
              cy_data="profileMenuButton"
              aria-owns={open ? "menu-appbar" : null}
              aria-haspopup="true"
              onClick={handleBarMenu}
              color="inherit">
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={AppAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={AppAnchorEl ? true : false}
              onClose={handleClose}>
              <MenuItem
                cy_data="profilePageButton"
                component={CustomRouterLink}
                to="/profile"
                onClick={handleClose}>
                {t("translation:Profile")}
              </MenuItem>
              <MenuItem cy_data="userLogOutButton" onClick={onLogOut}>
                {" "}
                {t("translation:Logout")}
              </MenuItem>
              {/*menu top up button only display if is_medicine_cost_prepaid is true*/}
              {curUser.defaultAlliance?.alliance?.alliance_organisation_associations?.some(
                (item) => item.is_medicine_cost_prepaid === true
              ) &&
                curUser.allOrganisationStripe &&
                curUser.allOrganisationStripe.organisation_stripes.length !==
                  0 && <Divider />}
              {curUser.defaultAlliance?.alliance?.alliance_organisation_associations?.some(
                (item) => item.is_medicine_cost_prepaid === true
              ) &&
                curUser.allOrganisationStripe &&
                curUser.allOrganisationStripe.organisation_stripes.length !==
                  0 && (
                  <MenuItem
                    cy_data="profilePageButton"
                    component={CustomRouterLink}
                    to="/currentUserCredit/topUp"
                    onClick={handleClose}>
                    {t("translation:Top Up")}
                  </MenuItem>
                )}
              {curUser.creditBalance &&
                curUser.creditBalance.user_credits.length === 1 &&
                curUser.creditBalance.user_credits.map((item, index) => {
                  return (
                    <div key={index}>
                      <MenuItem disabled>{t("translation:Balance")}</MenuItem>
                      <MenuItem disabled>
                        $ {item.credit_balance.toFixed(2)}
                      </MenuItem>
                    </div>
                  );
                })}
              {curUser.creditBalance &&
                curUser.creditBalance.user_credits.length > 1 && (
                  <MenuItem disabled>{t("translation:Balance")}</MenuItem>
                )}
              {curUser.creditBalance &&
                curUser.creditBalance.user_credits.length > 1 &&
                curUser.creditBalance.user_credits.map((item, index) => {
                  return (
                    <div key={index}>
                      <MenuItem disabled>
                        $ {item.credit_balance.toFixed(2)}
                      </MenuItem>
                    </div>
                  );
                })}
            </Menu>
          </React.Fragment>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}>
        <div className={classes.toolbar} />
        <List key="mainMenu" className={classes.rootSideBar}>
          {menuItems.data.map((items, index) => {
            const level = 1;
            return (
              <MenuItems
                key={index}
                items={items}
                index={index}
                level={level}
                accessRight={accessRight}
                anchorEl={anchorEl}
                setanchorEl={setanchorEl}
                menu={menu}
                setMenu={setMenu}
              />
            );
          })}
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default NewLayout;
