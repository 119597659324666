import { createAsyncTypes } from "../_helper/Utilities";

export const GET_USERMFALIST_ASYNC = createAsyncTypes("GET_USERMFALIST");

export const GET_USERMFABYID_ASYNC = createAsyncTypes("GET_USERMFABYID");

export const PUT_UPDATEUSERMFA_ASYNC = createAsyncTypes("PUT_UPDATEUSERMFA");

export const GET_RANDOMMFASECRETKEY_ASYNC = createAsyncTypes(
  "GET_RANDOMMFASECRETKEY"
);

export const POST_VERIFYMFASECRETKEY_ASYNC = createAsyncTypes(
  "POST_VERIFYMFASECRETKEY"
);

export const GET_OTPPROVISIONINGURL_ASYNC = createAsyncTypes(
  "GET_OTPPROVISIONINGURL"
);

export const POST_VERIFYOTPTOKEN_ASYNC = createAsyncTypes(
  "POST_VERIFYOTPTOKEN"
);

export const GET_USERMFAUSERLIST_ASYNC = createAsyncTypes(
  "GET_USERMFAUSERLIST"
);
