import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getMedicineTypeList,
  putEditMedicineType,
  getMedicineType,
  createMedicineType,
} from "../actions/medicineTypeActions";
import * as types from "../_constants/medicineTypeConstants";
import * as notification from "../_constants/notificationConstants";
import medicineTypeApis from "../_apis/medicineTypeApis";

export default function* medicineTypeSagas() {
  yield all([
    takeLatest(
      types.GET_MEDICINETYPELIST_ASYNC.PENDING,
      getMedicineTypeListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_EDITMEDICINETYPE_ASYNC.PENDING,
      putEditMedicineTypeCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_MEDICINETYPE_ASYNC.PENDING, getMedicineTypeCall),
  ]);

  yield all([
    takeLatest(types.CREATE_MEDICINETYPE_ASYNC.PENDING, createMedicineTypeCall),
  ]);
}

function* getMedicineTypeListCall(searchModel) {
  try {
    const data = yield call(
      medicineTypeApis.getMedicineTypeListApi,
      searchModel.data
    );
    yield put(getMedicineTypeList.success(data));
  } catch (error) {
    if (error.response?.data?.message) {
      yield put(getMedicineTypeList.error(error.response?.data?.message));
    } else {
      yield put(getMedicineTypeList.error(notification.CONST_NOINTERNET));
    }
  }
}

function* putEditMedicineTypeCall(medicineTypeModel) {
  try {
    const data = yield call(
      medicineTypeApis.putEditMedicineTypeApi,
      medicineTypeModel.data
    );
    yield put(putEditMedicineType.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putEditMedicineType.error(error.response?.data?.message));
    else yield put(putEditMedicineType.error(notification.CONST_NOINTERNET));
  }
}

function* getMedicineTypeCall(searchModel) {
  try {
    const data = yield call(
      medicineTypeApis.getMedicineTypeApi,
      searchModel.data
    );
    yield put(getMedicineType.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getMedicineType.error(error.response?.data?.message));
    else yield put(getMedicineType.error(notification.CONST_NOINTERNET));
  }
}

function* createMedicineTypeCall(searchModel) {
  try {
    const data = yield call(
      medicineTypeApis.createMedicineTypeApi,
      searchModel.data
    );
    yield put(createMedicineType.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(createMedicineType.error(error.response?.data?.message));
    else yield put(createMedicineType.error(notification.CONST_NOINTERNET));
  }
}
