import { createAsyncTypes } from "../_helper/Utilities";

export const GET_ALLROLELIST_ASYNC = createAsyncTypes("GET_ALLROLELIST");

export const GET_ROLELIST_ASYNC = createAsyncTypes("GET_ROLELIST");

export const GET_ROLEBYID_ASYNC = createAsyncTypes("GET_ROLEBYID");

export const POST_CREATEROLE_ASYNC = createAsyncTypes("POST_CREATEROLE");

export const PUT_UPDATEROLE_ASYNC = createAsyncTypes("PUT_UPDATEROLE");
