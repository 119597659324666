import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import OrderSummaryPage from "./Consultation/OrderSummaryPage";
import AddOrderSummaryPage from "./Consultation/AddOrderSummaryPage";

export default function VisitOrderPage() {
  return (
    <Switch>
      <Route
        path="/visit/ConsultationOrderSummary"
        component={OrderSummaryPage}
      />

      <Route
        path="/visit/AddConsultationOrderSummary"
        component={AddOrderSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
