//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//actions

//components
//import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { useStyles } from "../../../../components/globalStyles";
import { MultiFieldSortingSearchTable } from "../../../../components/MultiFieldSortingSearchTable";
import { getUserList, getUserById } from "../../../../actions/userActions";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import { EditUserPage } from "./EditUserPage";
import { UserDetailPage } from "./UserDetailPage";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";

import { getTableDefaultLocalStorage } from "../../../../components/functions/localStorage";

export default function UserListTable({ setInformationView }) {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.ADMIN_USER_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Username"),
      accessor: "username",
    },

    {
      Header: t("translation:Contact Number"),
      accessor: "contact_number",
    },
    {
      Header: t("translation:Date of Birth"),
      accessor: "date_of_birth",

      Cell: ({ row }) => {
        if (row.original.date_of_birth)
          return moment
            .utc(row.original.date_of_birth)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },

      // accessor: (d) => {
      //   return moment(d.date_of_birth)
      //     .toDate()
      //     .toLocaleDateString(context.locale, options);
      // },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,

    search_contact_number_value: null,
    search_date_of_birth_value: null,
    search_identification_number_value: null,
    search_name_value: null,
    search_username_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) => size === parseInt(getTableDefaultLocalStorage("userListTable"))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage("userListTable"))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
  });

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const [editUserView, setEditUserView] = React.useState(false);
  const [userDetailView, setUserDetailView] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState({
    name: null,
    contactNo: null,
    dateofBirth: null,
    identificationNo: null,
  });
  const [editUserInfo, setEditUserInfo] = React.useState([]);
  const [userDetailInfo, setUserDetailInfo] = React.useState([]);
  //functions
  const handleRowEdit = (value) => {
    //if search by id wont work when NRIC advanced search on summary page
    //dispatch(getUserById.pending(value));
    setEditUserInfo(value);
    setEditUserView(true);
  };

  const handleRowDetail = (value) => {
    //if search by id wont work when NRIC advanced search on summary page
    //dispatch(getUserById.pending(value));
    setUserDetailInfo(value);
    setUserDetailView(true);
  };

  //useEffects
  useEffect(() => {
    if (userDetailView === true || editUserView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [userDetailView, editUserView, setInformationView]);
  useEffect(() => {
    if (!editUserView && !userDetailView) {
      dispatch(getUserList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editUserView, userDetailView]);

  const handleSearch = (value) => {
    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      search_contact_number_value: searchValue.contactNo,
      search_date_of_birth_value: searchValue.dateofBirth,
      search_identification_number_value: searchValue.identificationNo,
      search_name_value: searchValue.name,
    });
  };

  if (user.getUserListIsSuccess === false && user.isLoadingUserList === false) {
    return <Alert severity="error">{user.error.userListError}</Alert>;
  } else if (!editUserView && !userDetailView) {
    return (
      // <SortingSearchTable
      //   tableHeading={""}
      //   searchPlaceholder={t(
      //     "translation:Name/ Username/ Contact Number/ Identification Number"
      //   )}
      //   searchValue={searchValue}
      //   columns={columns}
      //   data={user.userList ? user.userList.users : []}
      //   pageInfo={pageInfo}
      //   setPageInfo={setPageInfo}
      //   pageCount={user.userList ? user.userList.total_pages : 0}
      //   loading={user.isLoadingUserList}
      //   totalItems={user.userList ? user.userList.total_items : 0}
      //   handleSearch={handleSearch}
      // />

      <MultiFieldSortingSearchTable
        searchPlaceholder={""}
        columns={columns}
        data={user.userList ? user.userList.users : []}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={user.userList ? user.userList.total_pages : 0}
        loading={user.isLoadingUserList}
        totalItems={user.userList ? user.userList.total_items : 0}
        handleSearch={handleSearch}
        localStorageName="userListTable">
        <TextField
          className={classes.userFieldColor}
          label={t("translation:Name")}
          variant="outlined"
          value={searchValue.name ? searchValue.name : ""}
          onChange={(e) =>
            setSearchValue({ ...searchValue, name: e.target.value })
          }
        />

        <TextField
          className={classes.userFieldColor}
          label={t("translation:Contact Number")}
          variant="outlined"
          value={searchValue.contactNo ? searchValue.contactNo : ""}
          onChange={(e) =>
            setSearchValue({ ...searchValue, contactNo: e.target.value })
          }
        />

        <TextField
          className={classes.userFieldColor}
          label={t("translation:Identification Number")}
          variant="outlined"
          autoComplete="off"
          value={
            searchValue.identificationNo ? searchValue.identificationNo : ""
          }
          onChange={(e) =>
            setSearchValue({
              ...searchValue,
              identificationNo: e.target.value,
            })
          }
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className={classes.userFieldColor}
            inputVariant="outlined"
            id="date"
            label={t("translation:Date of Birth")}
            autoOk={true}
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
            }}
            value={
              searchValue.dateofBirth ? dayjs(searchValue.dateofBirth) : null
            }
            format="DD MMM YYYY"
            onChange={(e) =>
              setSearchValue({
                ...searchValue,
                dateofBirth: dayjs(e, true).isValid()
                  ? dayjs(e).format("YYYY-MM-DD")
                  : null,
              })
            }
          />
        </LocalizationProvider>
      </MultiFieldSortingSearchTable>
    );
  } else if (user.userList && userDetailView) {
    return (
      <UserDetailPage
        user={userDetailInfo}
        setUserDetailView={setUserDetailView}
      />
    );
  } else if (user.userList && editUserView) {
    return (
      <EditUserPage user={editUserInfo} setEditUserView={setEditUserView} />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
