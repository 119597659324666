import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getUserCreditList,
  postCreateUserCredit,
  getUserCreditUserList,
  getUserCreditById,
  getUserCreditBalanceById,
} from "../actions/userCreditActions";
import * as types from "../_constants/userCreditConstants";
import * as notification from "../_constants/notificationConstants";
import userCreditApis from "../_apis/userCreditApis";
export default function* userCreditSagas() {
  yield all([
    takeLatest(types.GET_USERCREDITLIST_ASYNC.PENDING, getUserCreditListCall),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATEUSERCREDIT_ASYNC.PENDING,
      postCreateUserCreditCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_USERCREDITUSERLIST_ASYNC.PENDING,
      getUserCreditUserListCall
    ),
  ]);
  yield all([
    takeLatest(types.GET_USERCREDITBYID_ASYNC.PENDING, getUserCreditByIdCall),
  ]);
  yield all([
    takeLatest(
      types.GET_USERCREDITBALANCEBYID_ASYNC.PENDING,
      getUserCreditBalanceByIdCall
    ),
  ]);
}

function* getUserCreditListCall(Model) {
  try {
    const data = yield call(userCreditApis.getUserCreditListApi, Model.data);
    yield put(getUserCreditList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserCreditList.error(error.response?.data?.message));
    else yield put(getUserCreditList.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateUserCreditCall(Model) {
  try {
    const data = yield call(userCreditApis.postCreateUserCreditApi, Model.data);
    yield put(postCreateUserCredit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateUserCredit.error(error.response?.data?.message));
    else yield put(postCreateUserCredit.error(notification.CONST_NOINTERNET));
  }
}

function* getUserCreditUserListCall(Model) {
  try {
    const data = yield call(
      userCreditApis.getUserCreditUserListApi,
      Model.data
    );
    yield put(getUserCreditUserList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserCreditUserList.error(error.response?.data?.message));
    else yield put(getUserCreditUserList.error(notification.CONST_NOINTERNET));
  }
}

function* getUserCreditByIdCall(Model) {
  try {
    const data = yield call(userCreditApis.getUserCreditByIdApi, Model.data);
    yield put(getUserCreditById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserCreditById.error(error.response?.data?.message));
    else yield put(getUserCreditById.error(notification.CONST_NOINTERNET));
  }
}

function* getUserCreditBalanceByIdCall(Model) {
  try {
    const data = yield call(
      userCreditApis.getUserCreditBalanceByIdApi,
      Model.data
    );
    yield put(getUserCreditBalanceById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserCreditBalanceById.error(error.response?.data?.message));
    else
      yield put(getUserCreditBalanceById.error(notification.CONST_NOINTERNET));
  }
}
