//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

//components
import AddUserPage from "./AddUserPage";
import UserListTable from "./UserListTable";
import { useTranslation } from "react-i18next";

import * as generalConstants from "../../../../_constants/generalConstants";
import { useStyles } from "../../../../components/globalStyles";

const UserSummaryPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const [userListView, setUserListView] = React.useState(true);
  const [informationView, setInformationView] = React.useState(false);
  const curUser = useSelector((state) => state.curUser);
  const addAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.ADMIN_USER_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    : false;

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!informationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            {userListView ? (
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:User Summary")}
              </Typography>
            ) : (
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Add User")}
              </Typography>
            )}
          </Grid>

          {userListView && addAccess ? (
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonColor}
                onClick={() => setUserListView(!userListView)}>
                {t("translation:Add")}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={1}></Grid>
          )}
        </Grid>
      )}
      <hr />
      {!userListView && <AddUserPage setUserListView={setUserListView} />}
      {userListView && (
        <UserListTable setInformationView={setInformationView} />
      )}
    </Paper>
  );
};

export default UserSummaryPage;
