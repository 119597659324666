import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { TextField, Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import Close from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
//components
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import StripePowered from "../../../../components/StripePowered";
import { getCreditBalance } from "../../../../actions/curUserActions";
import amountInCentToDollarString from "../../../../components/functions/amountInCentToDollarString";
import { useStyles } from "../../../../components/globalStyles";

export default function CheckoutForm({ clientSecret, setStripeView }) {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isHidePayButton, setIsHidePayButton] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { handleSubmit } = useForm();
  useEffect(() => {
    //set ref when first render
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setEmail(
        paymentIntent.receipt_email ? paymentIntent.receipt_email : null
      );
    });
  }, [stripe, elements, clientSecret]);

  const onConfirmSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,

      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/paymentSuccess`,
        receipt_email: email,
      },
      //disable redirect
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setSuccessMessage(null);
        setErrorMessage(error.message);
      } else {
        setSuccessMessage(null);
        setErrorMessage("An unexpected error occurred.");
      }
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      const topUpAmountInDollar = amountInCentToDollarString(
        paymentIntent.amount
      );
      switch (paymentIntent.status) {
        case "succeeded":
          setSuccessMessage(
            t("translation:Payment ID ") +
              paymentIntent.id +
              t("translation: and ") +
              t("translation:amount ") +
              topUpAmountInDollar +
              paymentIntent.currency +
              t("translation: succeeded ") +
              "!"
          );
          setErrorMessage(null);
          setIsHidePayButton(true);
          dispatch(getCreditBalance.pending());
          break;
        case "processing":
          setSuccessMessage(
            t("translation:Payment ID ") +
              paymentIntent.id +
              t("translation: and ") +
              t("translation:amount ") +
              topUpAmountInDollar +
              paymentIntent.currency +
              t("translation: is processing ") +
              "."
          );
          setErrorMessage(null);
          break;
        case "requires_payment_method":
          setSuccessMessage(null);
          setErrorMessage(
            t("translation:Payment ID ") +
              paymentIntent.id +
              t("translation: and ") +
              t("translation:amount ") +
              topUpAmountInDollar +
              paymentIntent.currency +
              t("translation: was not successful ") +
              "," +
              t("translation: please try again ") +
              "."
          );
          break;
        default:
          setSuccessMessage(null);
          setErrorMessage(t("translation:Something went wrong") + ".");
          break;
      }
    });

    setIsLoading(false);
  };

  const onSubmit = () => {
    setConfirmSubmit(true);
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => setStripeView(false)}
      />
      <DialogContent>
        <Collapse in={successMessage ? true : false}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessMessage(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {successMessage}
          </Alert>
        </Collapse>
        <Collapse in={errorMessage ? true : false}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {errorMessage}
          </Alert>
        </Collapse>
        <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
          <ConfirmDialog
            Yes={t("translation:Yes")}
            No={t("translation:No")}
            title={t("translation:Confirmation")}
            open={confirmSubmit}
            setOpen={setConfirmSubmit}
            onConfirm={onConfirmSubmit}>
            {t("translation:Are you sure you want to proceed?")}
          </ConfirmDialog>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              variant="outlined"
              id="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="Email"
              label={t("translation:Email")}
            />
          </Grid>
          <PaymentElement id="payment-element" />

          {!isHidePayButton && (
            <PageSubmitWBackAction
              setView={() => {
                setStripeView(false);
              }}
              disabled={isLoading}
            />
          )}
          {isHidePayButton && (
            <PageOnlyBackAction
              setView={() => {
                setStripeView(false);
              }}
            />
          )}
          <StripePowered />
        </form>
      </DialogContent>
    </Dialog>
  );
}
