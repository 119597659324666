//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";

import Panel from "../../../../components/Panel";
import { useTranslation } from "react-i18next";
import moment from "moment";
//actions
import { getBranchAdhocScheduleList } from "../../../../actions/branchAdhocScheduleActions";
//components
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import * as pageConfigure from "../../../../_constants/pageConstants";
import { useStyles } from "../../../../components/globalStyles";

export const BranchAdhocScheduleDetailPage = ({ branchId, setDetailView }) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const branchAdhocSchedule = useSelector((state) => state.branchAdhocSchedule);

  const [searchValue, setSearchValue] = React.useState("");

  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,

    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_branch_desc_value: null,
    search_branch_id_value: branchId,
    search_branch_name_value: null,
    search_desc_value: null,
    search_name_value: null,
  });

  const columns = [
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Description"),
      accessor: "desc",
    },
    {
      Header: t("translation:Closed All Day"),
      sortable: false,
      accessor: (d) => {
        if (d.is_closed_all_day) return "Yes";
        else return "";
      },
    },
    {
      Header: t("translation:Start Time"),
      sortable: false,
      accessor: (d) => {
        if (d.start_time)
          return moment(d.start_time, "HH:mm:ss").format("HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:End Time"),
      sortable: false,
      accessor: (d) => {
        if (d.end_time) return moment(d.end_time, "HH:mm:ss").format("HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Adhoc Date"),
      sortable: false,
      accessor: (d) => {
        if (d.adhoc_date) return moment(d.adhoc_date).format("DD-MMM-YYYY");
        else return "";
      },
    },
  ];
  //functions

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_name_value: value,
        search_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
        search_desc_value: "",
      });
    }
  };

  useEffect(() => {
    dispatch(getBranchAdhocScheduleList.pending(pageInfo));
  }, [pageInfo, dispatch]);

  return (
    <Panel heading={t("translation:Edit Branch Adhoc Schedule")}>
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name/ Description")}
        searchValue={searchValue}
        columns={columns}
        data={
          branchAdhocSchedule.branchAdhocScheduleList
            ?.branch_adhoc_schedules !== undefined
            ? branchAdhocSchedule.branchAdhocScheduleList
                ?.branch_adhoc_schedules
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          branchAdhocSchedule.branchAdhocScheduleList
            ? branchAdhocSchedule.branchAdhocScheduleList.total_pages
            : 0
        }
        loading={branchAdhocSchedule.isLoadingBranchAdhocScheduleList}
        totalItems={
          branchAdhocSchedule.branchAdhocScheduleList
            ? branchAdhocSchedule.branchAdhocScheduleList.total_items
            : 0
        }
        handleSearch={handleSearch}
      />
      <Grid className={classes.root} container>
        <PageOnlyBackAction setView={() => setDetailView(false)} />
      </Grid>
    </Panel>
  );
};
