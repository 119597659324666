import * as generalConstants from "../../../../../_constants/generalConstants";
const copyMics = (orderInfo, copyInfo) => {
  const curMiscResultWODelete = orderInfo.visit_misc_details.filter(
    (item) => item.id !== 0
  );

  let curMiscResult = curMiscResultWODelete.map((item) => {
    const result = {
      ...item,
      status_type: generalConstants.DELETED_STATUS_TYPE,
    };
    return result;
  });

  const tempMiscResultWODelete = copyInfo.visit_misc_details.filter(
    (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  );
  const tempMiscResult = tempMiscResultWODelete.map((item) => {
    const repeatMisc = curMiscResult.find(
      (repeatItem) => repeatItem.name === item.name
    );

    if (repeatMisc) {
      curMiscResult = curMiscResult.filter(
        (filterItem) => filterItem.name !== repeatMisc.name
      );

      //replace it
      const result = {
        ...item,
        id: repeatMisc.id,
      };
      return result;
    } else {
      const result = { ...item, id: 0 };
      return result;
    }
  });
  const copyMicsInfo = {
    ...orderInfo,
    visit_misc_details: [...curMiscResult, ...tempMiscResult],
  };

  return copyMicsInfo;
};

export default copyMics;
