import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import TreatmentSummary from "./TreatmentSummary/TreatmentSummaryPage";
import TreatmentTypeSummary from "./TreatmentTypeSummary/TreatmentTypeSummaryPage";
export default function TreatmentPage() {
  return (
    <Switch>
      <Route path="/treatment/TreatmentSummary" component={TreatmentSummary} />

      <Route
        path="/treatment/TreatmentTypeSummary"
        component={TreatmentTypeSummary}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
