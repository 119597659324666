//lib
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { FormControl, Grid, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import { useTranslation } from "react-i18next";
//actions
import {
  getGender,
  getNationality,
  getMarital,
  getRace,
  getLanguage,
  getMfaType,
} from "../../../../actions/commonActions";
//components
import Panel from "../../../../components/Panel";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//style
const DarkerDisabledTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)", // (default alpha is 0.38)
    },
  },
})(TextField);

export const UserDetailPage = ({ user, setUserDetailView }) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);

  const {
    register,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: user.id,
      username: user.username,
      email: user.email,
      name: user.name,
      identification_number: user.identification_number,
      physician_license_no: user.physician_license_no,
      postal_code: user.postal_code,
      blk_no: user.blk_no,
      building: user.building,
      road_name: user.road_name,
      address_details: user.address_details,
      address: user.address,
      contact_number: user.contact_number,
      occupation: user.occupation,
      allergy: user.allergy,
      medical_history: user.medical_history,
      gender_type: user.gender_type,
      marital_type: user.marital_type,
      date_of_birth: user.date_of_birth ? dayjs(user.date_of_birth) : null,
      age: user.date_of_birth
        ? Math.floor(moment().diff(user.date_of_birth, "years", true))
        : " ",
      race: user.race,
      nationality: user.nationality,
      language: user.language,
      nok_contact_name: user.nok_contact_name,
      nok_contact_number: user.nok_contact_number,
      mfa_type: user.mfa_type,
    },
  });

  //functions

  const getMfaTypelOpObj = (option) => {
    if (!option.id)
      option = common.getMfaType.mfa_type.find((op) => op.id === option);
    return option;
  };
  const getGenderOpObj = (option) => {
    if (!option.id)
      option = common.genderList.gender.find((op) => op.id === option);
    return option;
  };

  const getMaritalOpObj = (option) => {
    if (!option.id)
      option = common.maritalList.marital.find((op) => op.id === option);
    return option;
  };

  //useEffects
  useEffect(() => {
    dispatch(getRace.pending());
    dispatch(getLanguage.pending());
    dispatch(getMarital.pending());
    dispatch(getNationality.pending());
    dispatch(getGender.pending());
    dispatch(getMfaType.pending());
  }, [dispatch]);

  return (
    <Panel heading={t("translation:User Detail")}>
      <form>
        <input hidden readOnly {...register("userId")} />

        <Grid className={classes.root} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <DarkerDisabledTextField
              className={classes.userFieldColor}
              disabled
              required
              name="username"
              label={t("translation:Username")}
              autoComplete="off"
              variant="outlined"
              {...register("username")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <DarkerDisabledTextField
              className={classes.userFieldColor}
              required
              disabled
              id="name"
              name="name"
              label={t("translation:Name")}
              variant="outlined"
              {...register("name")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <DarkerDisabledTextField
              className={classes.userFieldColor}
              disabled
              name="physician_license_no"
              label={t("translation:Physician License Number")}
              variant="outlined"
              {...register("physician_license_no")}
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <DarkerDisabledTextField
              className={classes.userFieldColor}
              disabled
              name="identification_number"
              label={t("translation:Identification Number")}
              autoComplete="off"
              variant="outlined"
              {...register("identification_number")}
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            {common.genderList && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled
                    options={common.genderList.gender}
                    isOptionEqualToValue={(option, value) => {
                      if (option.id === value) return option;
                    }}
                    getOptionLabel={(option) => getGenderOpObj(option).name}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Gender")}
                        variant="outlined"
                      />
                    )}
                  />
                )}
                name="gender_type"
                control={control}
                defaultValue={user.gender_type}
              />
            )}
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    className={classes.userFieldColor}
                    inputVariant="outlined"
                    label={t("translation:Date of Birth")}
                    autoOk={true}
                    disableFuture
                    format="DD MMM YYYY"
                    disabled
                    // onChange={(e) => handleDateOfBirth(e)}
                  />
                )}
                name="date_of_birth"
                control={control}
                defaultValue={user.date_of_birth}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <DarkerDisabledTextField
              disabled
              className={classes.userFieldColor}
              variant="outlined"
              name="age"
              label={t("translation:Age")}
              autoComplete="off"
              {...register("age")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <FormControl variant="outlined">
              <DarkerDisabledTextField
                disabled
                className={classes.userFieldColor}
                name="email"
                label={t("translation:Email Address")}
                variant="outlined"
                {...register("email", {
                  pattern: {
                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    message: t("translation:Email is not valid"),
                  },
                })}
              />
              {errors.email && (
                <p className={classes.p}>{errors.email.message}</p>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <DarkerDisabledTextField
              disabled
              className={classes.userFieldColor}
              name="postal_code"
              label={t("translation:Postal Code")}
              variant="outlined"
              {...register("postal_code")}
            />
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <DarkerDisabledTextField
              disabled
              className={classes.userFieldColor}
              name="address_details"
              label={t("translation:Address Details")}
              variant="outlined"
              {...register("address_details")}
            />
          </Grid>
          <Controller
            name="blk_no"
            render={({ field }) => {
              if (field.value) {
                return (
                  <Grid item xs={12} className={classes.userInfoField}>
                    <DarkerDisabledTextField
                      {...field}
                      className={classes.userFieldColor}
                      disabled
                      name="blk_no"
                      label={t("translation:Block No")}
                      variant="outlined"
                      {...register("blk_no")}
                      InputLabelProps={{
                        shrink: field.value ? true : false,
                      }}
                    />
                  </Grid>
                );
              }
            }}
            control={control}
          />

          <Controller
            name="road_name"
            render={({ field }) => {
              if (field.value) {
                return (
                  <Grid item xs={12} className={classes.userInfoField}>
                    <DarkerDisabledTextField
                      className={classes.userFieldColor}
                      disabled
                      name="road_name"
                      label={t("translation:Road Name")}
                      variant="outlined"
                      {...register("road_name")}
                      InputLabelProps={{
                        shrink: field.value ? true : false,
                      }}
                    />
                  </Grid>
                );
              }
            }}
            control={control}
          />

          <Controller
            name="building"
            render={({ field }) => {
              if (field.value) {
                return (
                  <Grid item xs={12} className={classes.userInfoField}>
                    <DarkerDisabledTextField
                      className={classes.userFieldColor}
                      disabled
                      name="building"
                      label={t("translation:Building")}
                      variant="outlined"
                      {...register("building")}
                      InputLabelProps={{
                        shrink: field.value ? true : false,
                      }}
                    />
                  </Grid>
                );
              }
            }}
            control={control}
          />

          <Grid item xs={12} className={classes.userInfoField}>
            <DarkerDisabledTextField
              disabled
              className={classes.userFieldColor}
              multiline
              minRows={3}
              name="address"
              label={t("translation:Address")}
              variant="outlined"
              {...register("address")}
            />
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            {common.maritalList && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled
                    options={common.maritalList.marital}
                    isOptionEqualToValue={(option, value) => {
                      if (option.id === value) return option;
                    }}
                    getOptionLabel={(option) => getMaritalOpObj(option).name}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Marital Status")}
                        variant="outlined"
                      />
                    )}
                  />
                )}
                name="marital_type"
                control={control}
                defaultValue={user.marital_type}
              />
            )}
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            <DarkerDisabledTextField
              disabled
              required
              className={classes.userFieldColor}
              name="contact_number"
              label={t("translation:Contact Number")}
              variant="outlined"
              {...register("contact_number")}
            />
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            <DarkerDisabledTextField
              disabled
              className={classes.userFieldColor}
              name="occupation"
              label={t("translation:Occupation")}
              variant="outlined"
              {...register("occupation")}
            />
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            {common.nationalityList && (
              <Autocomplete
                freeSolo
                disabled
                name="nationalities"
                options={common.nationalityList.nationalities.sort((a, b) =>
                  a.desc.localeCompare(b.desc)
                )}
                getOptionLabel={(option) => option.desc}
                style={{ width: "100%" }}
                defaultValue={
                  user.nationality
                    ? user.nationality
                    : {
                        id: 0,
                        desc: " ",
                      }
                }
                renderInput={(params) => (
                  <DarkerDisabledTextField
                    disabled
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Nationality")}
                    variant="outlined"
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            {common.raceList && (
              <Autocomplete
                freeSolo
                disabled
                name="races"
                id="races"
                options={common.raceList.races}
                getOptionLabel={(option) => option.desc}
                style={{ width: "100%" }}
                defaultValue={
                  user.race
                    ? user.race
                    : {
                        id: 0,
                        desc: " ",
                      }
                }
                renderInput={(params) => (
                  <DarkerDisabledTextField
                    disabled
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Race")}
                    variant="outlined"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={4} className={classes.userInfoField}>
            {common.languageList && (
              <Autocomplete
                freeSolo
                disabled
                name="language"
                id="language"
                options={common.languageList.languages}
                getOptionLabel={(option) => option.desc}
                style={{ width: "100%" }}
                defaultValue={
                  user.language
                    ? user.language
                    : {
                        id: 0,
                        desc: " ",
                      }
                }
                renderInput={(params) => (
                  <DarkerDisabledTextField
                    disabled
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Language")}
                    variant="outlined"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.userInfoField}>
            <DarkerDisabledTextField
              className={classes.userFieldColor}
              disabled
              name="nok_contact_name"
              label={t("translation:Next of Kin")}
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("nok_contact_name")}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.userInfoField}>
            <DarkerDisabledTextField
              className={classes.userFieldColor}
              disabled
              name="nok_contact_number"
              label={t("translation:Next of Kin's Contact Number")}
              variant="outlined"
              margin="normal"
              fullWidth
              {...register("nok_contact_number")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <DarkerDisabledTextField
              disabled
              className={classes.userFieldColor}
              multiline
              minRows={3}
              name="allergy"
              label={t("translation:Allergy")}
              variant="outlined"
              {...register("allergy")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <DarkerDisabledTextField
              disabled
              className={classes.userFieldColor}
              multiline
              minRows={3}
              name="medical_history"
              label={t("translation:Medical History")}
              variant="outlined"
              {...register("medical_history")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            {common.getMfaType && (
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    id="mfa_type"
                    {...field}
                    disabled
                    options={common.getMfaType.mfa_type}
                    isOptionEqualToValue={(option, value) => {
                      if (option.id === value) return option;
                    }}
                    getOptionLabel={(option) => getMfaTypelOpObj(option).name}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:MFA Type")}
                        variant="outlined"
                      />
                    )}
                  />
                )}
                name="mfa_type"
                control={control}
                defaultValue={user.mfa_type}
              />
            )}
          </Grid>
          <>
            <RequiredNote />
            <PageOnlyBackAction setView={() => setUserDetailView(false)} />
          </>
        </Grid>
      </form>
    </Panel>
  );
};
