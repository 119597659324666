import React from "react";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useStyles } from "../../../../../components/globalStyles";

import Panel from "../../../../../components/Panel";

import StyledCheckbox from "../../../../../components/StyledCheckbox";

export default function BranchCourierField({
  branchCouriers = null,
  setBranchCouriers = () => null,
  viewMode,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const common = useSelector((state) => state.common);
  const branchCourier = useSelector((state) => state.branchCourier);

  const getStatusOpObj = (option) => {
    if (!option.id)
      option = common.generalStatusList.status_general.find(
        (op) => op.id === option
      );
    return option;
  };
  return (
    <Panel>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        <Grid xs={12} item className={classes.userInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={viewMode}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            value={
              branchCouriers && branchCouriers.delivery_charge !== null
                ? branchCouriers.delivery_charge
                : ""
            }
            name="deliveryCharge"
            label={t("translation:Delivery Charge")}
            variant="outlined"
            onChange={(e) => {
              setBranchCouriers((p) => ({
                ...p,
                delivery_charge: e.target.value,
              }));
            }}
          />
        </Grid>

        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            disableClearable
            name="userDeviceKey"
            id="userDeviceKey"
            options={
              branchCourier.postBranchCourierUserDeviceKeyList
                ? branchCourier.postBranchCourierUserDeviceKeyList
                    .user_device_keys
                : []
            }
            value={branchCouriers ? branchCouriers.user_device_key : null}
            disabled={viewMode}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            style={{ width: "100%" }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, option) => {
              setBranchCouriers((p) => ({
                ...p,
                user_device_key: option,
              }));
            }}
            renderInput={(params) => (
              <TextField
                className={classes.userFieldColor}
                {...params}
                label={t("translation:User Device Key")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} className={classes.userInfoField}>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={
                  branchCouriers.is_notify_tracking_allowed ? true : false
                }
                disabled={viewMode}
                onClick={(e) => {
                  setBranchCouriers({
                    ...branchCouriers,
                    is_notify_tracking_allowed: e.target.checked
                      ? e.target.checked
                      : null,
                  });
                }}
                name="is_notify_tracking_allowed"
              />
            }
            label={t("translation:Notify tracking")}
          />
        </Grid>
        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            name="Order Status"
            disabled={viewMode}
            options={common.generalStatusList.status_general}
            disableClearable
            value={branchCouriers ? branchCouriers.status_type : null}
            isOptionEqualToValue={(option, value) => option.id === value}
            getOptionLabel={(option) => getStatusOpObj(option)?.name}
            style={{ width: "100%" }}
            onChange={(e, option) => {
              setBranchCouriers((p) => ({
                ...p,
                status_type: option.id,
              }));
            }}
            renderInput={(params) => (
              <TextField
                required
                className={classes.userFieldColor}
                label={t("translation:Status")}
                {...params}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </Panel>
  );
}
