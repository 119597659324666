import * as types from "../_constants/deliveryConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_DELIVERYLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryListIsSuccess: false,
        isLoadingDeliveryList: true,
        deliveryList: null,
      };

    case types.GET_DELIVERYLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryListIsSuccess: false,
        isLoadingDeliveryList: false,
        deliveryList: null,
        error: { ...state.error, deliveryListError: action.error },
      };

    case types.GET_DELIVERYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryListIsSuccess: true,
        isLoadingDeliveryList: false,
        deliveryList: action.data,
      };

    case types.POST_SUBMITDELIVERY_ASYNC.PENDING:
      return {
        ...state,
        postSubmitDeliverySuccess: false,
        isLoadingSubmitDelivery: true,
        submitDelivery: null,
      };

    case types.POST_SUBMITDELIVERY_ASYNC.ERROR:
      return {
        ...state,
        postSubmitDeliverySuccess: false,
        isLoadingSubmitDelivery: false,
        submitDelivery: null,
        error: {
          ...state.error,
          submitDeliveryError: action.error,
        },
      };

    case types.POST_SUBMITDELIVERY_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitDeliverySuccess: true,
        isLoadingSubmitDelivery: false,
        submitDelivery: action.data,
      };
    case types.PUT_DELIVERYPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryPrintDateIsSuccess: false,
        isLoadingDeliveryPrintDate: true,
        deliveryPrintDate: null,
      };

    case types.PUT_DELIVERYPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryPrintDateIsSuccess: false,
        isLoadingDeliveryPrintDate: false,
        deliveryPrintDate: null,
        error: { ...state.error, deliveryPrintDateError: action.error },
      };

    case types.PUT_DELIVERYPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryPrintDateIsSuccess: true,
        isLoadingDeliveryPrintDate: false,
        deliveryPrintDate: action.data,
      };

    case types.GET_DELIVERYDISTRICTDETAILSLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryDistrictDetailsListIsSuccess: false,
        isLoadingGetDeliveryDistrictDetailsList: true,
        getDeliveryDistrictDetailsList: null,
      };

    case types.GET_DELIVERYDISTRICTDETAILSLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryDistrictDetailsListIsSuccess: false,
        isLoadingGetDeliveryDistrictDetailsList: false,
        getDeliveryDistrictDetailsList: null,
        error: {
          ...state.error,
          getDeliveryDistrictDetailsListError: action.error,
        },
      };

    case types.GET_DELIVERYDISTRICTDETAILSLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryDistrictDetailsListIsSuccess: true,
        isLoadingGetDeliveryDistrictDetailsList: false,
        getDeliveryDistrictDetailsList: action.data,
      };

    case types.GET_EXPORTDELIVERYDISTRICTDETAILSLIST_ASYNC.PENDING:
      return {
        ...state,
        getExportDeliveryDistrictDetailsListIsSuccess: false,
        isLoadingGetExportDeliveryDistrictDetailsList: true,
        getExportDeliveryDistrictDetailsList: null,
      };

    case types.GET_EXPORTDELIVERYDISTRICTDETAILSLIST_ASYNC.ERROR:
      return {
        ...state,
        getExportDeliveryDistrictDetailsListIsSuccess: false,
        isLoadingGetExportDeliveryDistrictDetailsList: false,
        getExportDeliveryDistrictDetailsList: null,
        error: {
          ...state.error,
          getExportDeliveryDistrictDetailsListError: action.error,
        },
      };

    case types.GET_EXPORTDELIVERYDISTRICTDETAILSLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getExportDeliveryDistrictDetailsListIsSuccess: true,
        isLoadingGetExportDeliveryDistrictDetailsList: false,
        getExportDeliveryDistrictDetailsList: action.data,
      };

    case types.GET_DELIVERYDISTRICTDETAILSSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryDistrictDetailsSummaryListIsSuccess: false,
        isLoadingGetDeliveryDistrictDetailsSummaryList: true,
        getDeliveryDistrictDetailsSummaryList: null,
      };

    case types.GET_DELIVERYDISTRICTDETAILSSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryDistrictDetailsSummaryListIsSuccess: false,
        isLoadingGetDeliveryDistrictDetailsSummaryList: false,
        getDeliveryDistrictDetailsSummaryList: null,
        error: {
          ...state.error,
          getDeliveryDistrictDetailsSummaryListError: action.error,
        },
      };

    case types.GET_DELIVERYDISTRICTDETAILSSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryDistrictDetailsSummaryListIsSuccess: true,
        isLoadingGetDeliveryDistrictDetailsSummaryList: false,
        getDeliveryDistrictDetailsSummaryList: action.data,
      };

    case types.GET_EXPORTDELIVERYDISTRICTDETAILSSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getExportDeliveryDistrictDetailsSummaryListIsSuccess: false,
        isLoadingGetExportDeliveryDistrictDetailsSummaryList: true,
        getExportDeliveryDistrictDetailsSummaryList: null,
      };

    case types.GET_EXPORTDELIVERYDISTRICTDETAILSSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getExportDeliveryDistrictDetailsSummaryListIsSuccess: false,
        isLoadingGetExportDeliveryDistrictDetailsSummaryList: false,
        getExportDeliveryDistrictDetailsSummaryList: null,
        error: {
          ...state.error,
          getExportDeliveryDistrictDetailsSummaryListError: action.error,
        },
      };

    case types.GET_EXPORTDELIVERYDISTRICTDETAILSSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getExportDeliveryDistrictDetailsSummaryListIsSuccess: true,
        isLoadingGetExportDeliveryDistrictDetailsSummaryList: false,
        getExportDeliveryDistrictDetailsSummaryList: action.data,
      };

    case types.GET_DELIVERYPOSTALCODEDISTRICTLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryPostalcodeDistrictListIsSuccess: false,
        isLoadingGetDeliveryPostalcodeDistrictList: true,
        getDeliveryPostalcodeDistrictList: null,
      };

    case types.GET_DELIVERYPOSTALCODEDISTRICTLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryPostalcodeDistrictListIsSuccess: false,
        isLoadingGetDeliveryPostalcodeDistrictList: false,
        getDeliveryPostalcodeDistrictList: null,
        error: {
          ...state.error,
          getDeliveryPostalcodeDistrictListError: action.error,
        },
      };

    case types.GET_DELIVERYPOSTALCODEDISTRICTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryPostalcodeDistrictListIsSuccess: true,
        isLoadingGetDeliveryPostalcodeDistrictList: false,
        getDeliveryPostalcodeDistrictList: action.data,
      };

    default:
      return state;
  }
};
