import * as types from "../_constants/branchAdhocScheduleConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_BRANCHADHOCSCHEDULELIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchAdhocScheduleListIsSuccess: false,
        isLoadingBranchAdhocScheduleList: true,
        branchAdhocScheduleList: null,
      };

    case types.GET_BRANCHADHOCSCHEDULELIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchAdhocScheduleListIsSuccess: false,
        isLoadingBranchAdhocScheduleList: false,
        branchAdhocScheduleList: null,
        error: { ...state.error, branchAdhocScheduleListError: action.error },
      };

    case types.GET_BRANCHADHOCSCHEDULELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchAdhocScheduleListIsSuccess: true,
        isLoadingBranchAdhocScheduleList: false,
        branchAdhocScheduleList: action.data,
      };

    case types.GET_BRANCHADHOCSCHEDULEBYID_ASYNC.PENDING:
      return {
        ...state,
        getBranchAdhocScheduleByIdIsSuccess: false,
        isLoadingBranchAdhocScheduleById: true,
        branchAdhocScheduleById: null,
      };

    case types.GET_BRANCHADHOCSCHEDULEBYID_ASYNC.ERROR:
      return {
        ...state,
        getBranchAdhocScheduleByIdIsSuccess: false,
        isLoadingBranchAdhocScheduleById: false,
        branchAdhocScheduleById: null,
        error: { ...state.error, branchAdhocScheduleByIdError: action.error },
      };

    case types.GET_BRANCHADHOCSCHEDULEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchAdhocScheduleByIdIsSuccess: true,
        isLoadingBranchAdhocScheduleById: false,
        branchAdhocScheduleById: action.data,
      };

    case types.POST_BRANCHADHOCSCHEDULEBYBRANCHID_ASYNC.PENDING:
      return {
        ...state,
        postBranchAdhocScheduleByBranchIdIdIsSuccess: false,
        isLoadingPostBranchAdhocScheduleByBranchId: true,
        postBranchAdhocScheduleByBranchId: null,
      };

    case types.POST_BRANCHADHOCSCHEDULEBYBRANCHID_ASYNC.ERROR:
      return {
        ...state,
        postBranchAdhocScheduleByBranchIdIdIsSuccess: false,
        isLoadingPostBranchAdhocScheduleByBranchId: false,
        postBranchAdhocScheduleByBranchId: null,
        error: {
          ...state.error,
          postBranchAdhocScheduleByBranchIdError: action.error,
        },
      };

    case types.POST_BRANCHADHOCSCHEDULEBYBRANCHID_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchAdhocScheduleByBranchIdIdIsSuccess: true,
        isLoadingPostBranchAdhocScheduleByBranchId: false,
        postBranchAdhocScheduleByBranchId: action.data,
      };

    case types.GET_BRANCHADHOCSCHEDULEBRANCHLIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchAdhocScheduleBracnhListIsSuccess: false,
        isLoadingBranchAdhocScheduleBranchList: true,
        branchAdhocScheduleBranchList: null,
      };

    case types.GET_BRANCHADHOCSCHEDULEBRANCHLIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchAdhocScheduleBracnhListIsSuccess: false,
        isLoadingBranchAdhocScheduleBranchList: false,
        branchAdhocScheduleBranchList: null,
        error: {
          ...state.error,
          branchAdhocScheduleBranchListError: action.error,
        },
      };

    case types.GET_BRANCHADHOCSCHEDULEBRANCHLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchAdhocScheduleBracnhListIsSuccess: true,
        isLoadingBranchAdhocScheduleBranchList: false,
        branchAdhocScheduleBranchList: action.data,
      };

    default:
      return state;
  }
};
