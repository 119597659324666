import onlyFixedNumerics from "./onlyFixedNumerics";
const organisationPriceRounding = (value, roundingMethod, roundPlacing) => {
  const numberVal = onlyFixedNumerics(value);
  let total = 0;
  //round up
  if (roundingMethod === 1) {
    switch (roundPlacing) {
      case 1:
        total = Math.ceil(onlyFixedNumerics(numberVal / 100)) * 100;
        break;
      case 2:
        total = Math.ceil(onlyFixedNumerics(numberVal / 10)) * 10;
        break;
      case 3:
        total = Math.ceil(numberVal);
        break;
      case 4:
        total = Math.ceil(onlyFixedNumerics(numberVal * 10)) / 10;
        break;
      case 5:
        total = Math.ceil(onlyFixedNumerics(numberVal * 100)) / 100;
        break;
      case 6:
        total = Math.ceil(onlyFixedNumerics(numberVal * 1000)) / 1000;
        break;
      case 7:
        total = Math.ceil(onlyFixedNumerics(numberVal * 10000)) / 10000;
        break;
      default:
        total = value;
    }
  }
  //round off
  else if (roundingMethod === 2) {
    switch (roundPlacing) {
      case 1:
        total = Math.round(onlyFixedNumerics(numberVal / 100)) * 100;
        break;
      case 2:
        total = Math.round(onlyFixedNumerics(numberVal / 10)) * 10;
        break;
      case 3:
        total = Math.round(numberVal);
        break;
      case 4:
        total = (Math.round(onlyFixedNumerics(numberVal * 10)) / 10).toFixed(1);
        break;
      case 5:
        total = (Math.round(onlyFixedNumerics(numberVal * 100)) / 100).toFixed(
          2
        );
        break;
      case 6:
        total = (
          Math.round(onlyFixedNumerics(numberVal * 1000)) / 1000
        ).toFixed(3);
        break;
      case 7:
        total = (
          Math.round(onlyFixedNumerics(numberVal * 10000)) / 10000
        ).toFixed(4);
        break;
      default:
        total = value;
    }
  }
  //round down
  else if (roundingMethod === 3) {
    switch (roundPlacing) {
      case 1:
        total = Math.floor(onlyFixedNumerics(numberVal / 100)) * 100;
        break;
      case 2:
        total = Math.floor(onlyFixedNumerics(numberVal / 10)) * 10;
        break;
      case 3:
        total = Math.floor(numberVal);
        break;
      case 4:
        total = (Math.floor(onlyFixedNumerics(numberVal * 10)) / 10).toFixed(1);
        break;
      case 5:
        total = (Math.floor(onlyFixedNumerics(numberVal * 100)) / 100).toFixed(
          2
        );
        break;
      case 6:
        total = (
          Math.floor(onlyFixedNumerics(numberVal * 1000)) / 1000
        ).toFixed(3);
        break;
      case 7:
        total = (
          Math.floor(onlyFixedNumerics(numberVal * 10000)) / 10000
        ).toFixed(4);
        break;
      default:
        total = value;
    }
  } else {
    return onlyFixedNumerics(value);
  }

  return total;
};

export default organisationPriceRounding;
