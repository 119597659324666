import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Button, TextField } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import SearchIcon from "@mui/icons-material/Search";

//components
import * as pageConfigure from "../../../_constants/pageConstants";
import * as generalConstants from "../../../_constants/generalConstants";

import { SortingSearchTable } from "../../../components/SortingSearchTable";

import { ReactComponent as ArrivalLogo } from "../../../assets/coolicons/home/home_check.svg";
import { ReactComponent as DepartureLogo } from "../../../assets/coolicons/home/home_x.svg";

import { ReactComponent as ConsultLogo } from "../../../assets/coolicons/home/home_alt_check.svg";
import { ReactComponent as ResetLogo } from "../../../assets/coolicons/basic/refresh_02.svg";

import { ReactComponent as ProceedAllLogo } from "../../../assets/coolicons/media/skip_next.svg";

import { useStyles } from "../../../components/globalStyles";

import ConfirmDialog from "../../../components/ConfirmDialog";

import VisitOrderDialog from "./VisitOrderDialog";

import AppointmentDialog from "./AppointmentDialog";

import allowOnlyNumericsOrDigits from "../../../components/functions/allowOnlyNumericsOrDigits";

import { getTableDefaultLocalStorage } from "../../../components/functions/localStorage";
//Actions

import { getDashboardCounterView } from "../../../actions/dashboardActions";
import {
  putVisitSetDepartureOn,
  putVisitSetConsultOn,
  putVisitSetQueueNumber,
  putVisitSetArrivalOn,
} from "../../../actions/visitOrderActions";

export default function ArrivalListTable({
  refreshTimeStamp = null,
  dashboardView = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  //const common = useSelector((state) => state.common);
  const curUser = useSelector((state) => state.curUser);
  const visitOrder = useSelector((state) => state.visitOrder);
  const dashboard = useSelector((state) => state.dashboard);
  const invoice = useSelector((state) => state.invoice);

  //variable
  const prevArrivalOnStatus = useRef();
  const prevDepartureOnStatus = useRef();
  const prevConsultOnStatus = useRef();
  const prevSetQueueNoStatus = useRef();
  const prevRefreshTimeStamp = useRef();

  const prevDepartureStatus = useRef();

  const prevSubmitStatus = useRef();

  const prevSaveStatus = useRef();

  const prevSubmitInvoiceStatus = useRef();

  const prevNewStatus = useRef();
  const [confirmDepartureOpen, setConfirmDepartureOpen] = useState(false);
  const [confirmArrivalOpen, setConfirmArrivalOpen] = useState(false);
  const [confirmConsultOpen, setConfirmConsultOpen] = useState(false);
  const [editOrderInfo, setEditOrderInfo] = useState([]);
  const [confirmEditQueueNo, setConfirmEditQueueNo] = useState(false);
  const [confirmQueueNoRow, setEditQueueNoRow] = useState(null);
  const [editQueueNoRowDesc, setEditQueueNoRowDesc] = useState(null);
  const [textValue, setTextValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const localStorageNameKey = "arrivalListTable";
  const [pageInfo, setPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) =>
        size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
    search_end_date_value: moment().format("YYYY-MM-DD"),
    search_no_departure_date_value: true,
    search_start_date_value: moment().format("YYYY-MM-DD"),
    is_pagination: true,
    search_physician_name_value: null,
    search_staff_name_value: null,
  });
  const [arrivalSuccessAlarm, setArrivalSuccessAlarm] = useState(false);
  const [arrivalErrorAlarm, setArrivalErrorAlarm] = useState(false);
  const [arrivalMsgAlarm, setArrivalMsgAlarm] = useState(null);

  const [departureSuccessAlarm, setDepartureSuccessAlarm] = useState(false);
  const [departureErrorAlarm, setDepartureErrorAlarm] = useState(false);
  const [departureMsgAlarm, setDepartureMsgAlarm] = useState(null);
  const [consultSuccessAlarm, setConsultSuccessAlarm] = useState(false);
  const [consultErrorAlarm, setConsultErrorAlarm] = useState(false);
  const [consultMsgAlarm, setConsultMsgAlarm] = useState(null);
  const [queueSuccessAlarm, setQueueSuccessAlarm] = useState(false);
  const [queueErrorAlarm, setQueueErrorAlarm] = useState(false);
  const [queueMsgAlarm, setQueueMsgAlarm] = useState(null);
  const [openVisitOrderDialog, setOpenVisitOrderDialog] = useState(false);
  const [visitOrderNo, setVisitOrderNo] = useState(null);
  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
  const [appointmentNo, setAppointmentNo] = useState(null);

  //using visit right because it update the visit module
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;

  const createAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    : false;

  const columns = [
    {
      textAlign: "left",
      id: "AppointmentOrVisit",
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (Object.keys(row.original.store_header).length !== 0) {
          return (
            <Button
              className={classes.boldMaroon075}
              onClick={() =>
                handleRowVisitOrderView(row.original.store_header)
              }>
              <SearchIcon
                className={classes.iconColor}
                title={t("translation:Visit Order")}
              />
            </Button>
          );
        }

        return (
          <Button
            className={classes.boldMaroon075}
            onClick={() => handleRowAppointmentView(row.original.appointment)}>
            <ProceedAllLogo title={t("translation:Appointment")} />
          </Button>
        );
      },
    },
    {
      textAlign: "left",
      id: "ArriveOrConsult",
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (
          JSON.stringify(row.original.store_header.visit_queue_details) ===
            "{}" ||
          row.original.store_header.visit_queue_details?.arrival_on === null
        ) {
          return (
            <Button
              onClick={() =>
                handleRowArrivalProcess(row.original.store_header)
              }>
              <ArrivalLogo title={t("translation:Arrive")} />
            </Button>
          );
        }
        if (
          row.original.store_header.visit_queue_details?.arrival_on &&
          row.original.store_header.visit_queue_details?.consult_on === null
        ) {
          return (
            <Button
              onClick={() =>
                handleRowConsultProcess(row.original.store_header)
              }>
              <ConsultLogo title={t("translation:Consulted")} />
            </Button>
          );
        }
        return <></>;
      },
    },

    {
      textAlign: "left",
      id: "Departure",
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (
          row.original.store_header.visit_queue_details?.arrival_on &&
          row.original.store_header.visit_queue_details?.departure_on === null
        ) {
          return (
            <Button
              onClick={() =>
                handleRowDepartureProcess(row.original.store_header)
              }>
              <DepartureLogo title={t("translation:Departure")} />
            </Button>
          );
        }
        return <></>;
      },
    },

    {
      textAlign: "left",
      id: "Queue",
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (row.original.store_header.visit_queue_details?.visit_queue_no) {
          return (
            <Button
              onClick={() =>
                handleTriggerEditQueueNo(row.original.store_header)
              }>
              <ResetLogo title={t("translation:Queue")} />
            </Button>
          );
        }
        return <></>;
      },
    },
    {
      Header: t("translation:Start Time"),
      accessor: "appointment.start_time",
      sortable: false,
      Cell: ({ cell }) => {
        return (
          <>
            {cell.value ? moment(cell.value, "HH:mm:ss").format("HH:mm") : ""}
          </>
        );
      },
    },
    {
      Header: t("translation:End Time"),
      accessor: "appointment.end_time",
      sortable: false,
      Cell: ({ cell }) => {
        return (
          <>
            {cell.value ? moment(cell.value, "HH:mm:ss").format("HH:mm") : ""}
          </>
        );
      },
    },
    {
      id: "physician",
      Header: t("translation:Physician"),
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.store_header.physician_name) {
          return <>{row.original.store_header.physician_name}</>;
        } else if (row.original.appointment.physician.name) {
          return <>{row.original.appointment.physician.name}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      id: "patientName",
      Header: t("translation:Name"),
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.store_header.staff_name) {
          return <>{row.original.store_header.staff_name}</>;
        } else if (row.original.appointment.staff.name) {
          return <>{row.original.appointment.staff.name}</>;
        } else if (row.original.appointment.temp_user.name) {
          return <>{row.original.appointment.temp_user.name}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      id: "desc",
      Header: t("translation:Comment"),
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.store_header?.id) {
          return (
            <>
              {row.original.store_header?.visit_queue_details
                ?.visit_queue_comment
                ? row.original.store_header?.visit_queue_details
                    ?.visit_queue_comment
                : ""}
            </>
          );
        } else if (row.original.appointment.appointment_desc) {
          return <>{row.original.appointment.appointment_desc}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      Header: t("translation:Queue No"),
      accessor: "store_header.visit_queue_details.visit_queue_no",
      sortable: false,
      Cell: ({ cell }) => {
        if (cell.value) {
          const str = cell.value.split("-");
          //in case of str[str.length - 1] === undefined
          return <>{str[str.length - 1] || cell.value}</>;
        }

        return <></>;
      },
    },

    {
      id: "arrivalStatus",
      Header: t("translation:Arrival"),
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (row.original.store_header.visit_queue_details?.arrival_on) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.greenCircle} />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },

    {
      id: "consultStatus",
      Header: t("translation:Consulted"),
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (
          row.original.store_header.visit_queue_details?.arrival_on &&
          row.original.store_header.visit_queue_details?.consult_on === null
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.yellowCircle} />
            </Button>
          );
        } else if (
          row.original.store_header.visit_queue_details?.consult_on &&
          row.original.store_header.visit_queue_details?.arrival_on
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.greenCircle} />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      id: "dispensaryStatus",
      Header: t("translation:Dispensary"),
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (
          row.original.store_header.local_dispensary_headers?.length &&
          row.original.store_header.local_dispensary_headers.some(
            (item) => item.status_type !== generalConstants.CLOSED_STATUS_TYPE
          )
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.yellowCircle} />
            </Button>
          );
        } else if (
          row.original.store_header.local_dispensary_headers?.length &&
          row.original.store_header.local_dispensary_headers.every(
            (item) => item.status_type === generalConstants.CLOSED_STATUS_TYPE
          )
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.greenCircle} />
            </Button>
          );
        } else if (
          row.original.store_header.submitted_on ||
          (row.original.store_header.visit_queue_details?.consult_on &&
            row.original.store_header.status_type !==
              generalConstants.NEW_STATUS_TYPE)
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },

    {
      id: "paymentStatus",
      Header: t("translation:Payment"),
      textAlign: "center",
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (
          row.original.store_header?.invoice_headers?.length &&
          row.original.store_header.invoice_headers.some(
            (item) => item.status_type !== generalConstants.CLOSED_STATUS_TYPE
          )
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.yellowCircle} />
            </Button>
          );
        } else if (
          row.original.store_header?.invoice_headers?.length &&
          row.original.store_header.invoice_headers.every(
            (item) => item.status_type === generalConstants.CLOSED_STATUS_TYPE
          )
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.greenCircle} />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  const columnsViewOnly = [
    {
      textAlign: "left",
      id: "AppointmentOrVisit",
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (createAccess) {
          if (Object.keys(row.original.store_header).length !== 0) {
            return (
              <Button
                className={classes.boldMaroon075}
                onClick={() =>
                  handleRowVisitOrderView(row.original.store_header)
                }>
                <SearchIcon
                  className={classes.iconColor}
                  title={t("translation:Visit Order")}
                />
              </Button>
            );
          }

          return (
            <Button
              className={classes.boldMaroon075}
              onClick={() =>
                handleRowAppointmentView(row.original.appointment)
              }>
              <ProceedAllLogo title={t("translation:Appointment")} />
            </Button>
          );
        }
        return <></>;
      },
    },

    {
      Header: t("translation:Start Time"),
      accessor: "appointment.start_time",
      sortable: false,
      Cell: ({ cell }) => {
        return (
          <>
            {cell.value ? moment(cell.value, "HH:mm:ss").format("HH:mm") : ""}
          </>
        );
      },
    },
    {
      Header: t("translation:End Time"),
      accessor: "appointment.end_time",
      sortable: false,
      Cell: ({ cell }) => {
        return (
          <>
            {cell.value ? moment(cell.value, "HH:mm:ss").format("HH:mm") : ""}
          </>
        );
      },
    },
    {
      id: "physician",
      Header: t("translation:Physician"),
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.store_header.physician_name) {
          return <>{row.original.store_header.physician_name}</>;
        } else if (row.original.appointment.physician.name) {
          return <>{row.original.appointment.physician.name}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      id: "patientName",
      Header: t("translation:Name"),
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.store_header.staff_name) {
          return <>{row.original.store_header.staff_name}</>;
        } else if (row.original.appointment.staff.name) {
          return <>{row.original.appointment.staff.name}</>;
        } else if (row.original.appointment.temp_user.name) {
          return <>{row.original.appointment.temp_user.name}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      id: "desc",
      Header: t("translation:Comment"),
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.store_header?.id) {
          return (
            <>
              {row.original.store_header?.visit_queue_details
                ?.visit_queue_comment
                ? row.original.store_header?.visit_queue_details
                    ?.visit_queue_comment
                : ""}
            </>
          );
        } else if (row.original.appointment.appointment_desc) {
          return <>{row.original.appointment.appointment_desc}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      Header: t("translation:Queue No"),
      accessor: "store_header.visit_queue_details.visit_queue_no",
      sortable: false,
      Cell: ({ cell }) => {
        if (cell.value) {
          const str = cell.value.split("-");
          //in case of str[str.length - 1] === undefined
          return <>{str[str.length - 1] || cell.value}</>;
        }

        return <></>;
      },
    },

    {
      id: "arrivalStatus",
      Header: t("translation:Arrival"),
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (row.original.store_header.visit_queue_details?.arrival_on) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.greenCircle} />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },

    {
      id: "consultStatus",
      Header: t("translation:Consulted"),
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (
          row.original.store_header.visit_queue_details?.arrival_on &&
          row.original.store_header.visit_queue_details?.consult_on === null
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.yellowCircle} />
            </Button>
          );
        } else if (
          row.original.store_header.visit_queue_details?.consult_on &&
          row.original.store_header.visit_queue_details?.arrival_on
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.greenCircle} />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      id: "dispensaryStatus",
      Header: t("translation:Dispensary"),
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (
          row.original.store_header.local_dispensary_headers?.length &&
          row.original.store_header.local_dispensary_headers.some(
            (item) => item.status_type !== generalConstants.CLOSED_STATUS_TYPE
          )
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.yellowCircle} />
            </Button>
          );
        } else if (
          row.original.store_header.local_dispensary_headers?.length &&
          row.original.store_header.local_dispensary_headers.every(
            (item) => item.status_type === generalConstants.CLOSED_STATUS_TYPE
          )
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.greenCircle} />
            </Button>
          );
        } else if (
          row.original.store_header.submitted_on ||
          (row.original.store_header.visit_queue_details?.consult_on &&
            row.original.store_header.status_type !==
              generalConstants.NEW_STATUS_TYPE)
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },

    {
      id: "paymentStatus",
      Header: t("translation:Payment"),
      textAlign: "center",
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (
          row.original.store_header?.invoice_headers?.length &&
          row.original.store_header.invoice_headers.some(
            (item) => item.status_type !== generalConstants.CLOSED_STATUS_TYPE
          )
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.yellowCircle} />
            </Button>
          );
        } else if (
          row.original.store_header?.invoice_headers?.length &&
          row.original.store_header.invoice_headers.every(
            (item) => item.status_type === generalConstants.CLOSED_STATUS_TYPE
          )
        ) {
          return (
            <Button disabled>
              <FiberManualRecordIcon className={classes.greenCircle} />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  const refreshTable = () => {
    dispatch(getDashboardCounterView.pending(pageInfo));
  };

  const handleRowAppointmentView = (value) => {
    //
    setOpenAppointmentDialog(true);
    setAppointmentNo(value.order_number);
  };

  const handleRowVisitOrderView = (value) => {
    //
    setOpenVisitOrderDialog(true);
    setVisitOrderNo(value.order_number);
  };

  const handleRowDepartureProcess = (value) => {
    //
    setConfirmDepartureOpen(true);
    setEditOrderInfo(value);
  };

  const handleRowArrivalProcess = (value) => {
    //
    setConfirmArrivalOpen(true);
    setEditOrderInfo(value);
  };

  const handleRowConsultProcess = (value) => {
    //
    setConfirmConsultOpen(true);
    setEditOrderInfo(value);
  };

  const handleConfirmArrivalProcess = () => {
    const result = {
      store_header: {
        id: editOrderInfo.id,
      },
    };
    dispatch(putVisitSetArrivalOn.pending(result));
  };

  const handleConfirmDepartureProcess = () => {
    const result = {
      store_header: {
        id: editOrderInfo.id,
      },
    };
    dispatch(putVisitSetDepartureOn.pending(result));
  };

  const handleConfirmConsultProcess = () => {
    const result = {
      store_header: {
        id: editOrderInfo.id,
      },
    };
    dispatch(putVisitSetConsultOn.pending(result));
  };

  const handleTriggerEditQueueNo = (value) => {
    setConfirmEditQueueNo(true);

    const str = value.visit_queue_details.visit_queue_no.split("-");

    setEditQueueNoRowDesc(value.visit_queue_details.visit_queue_comment);

    setEditQueueNoRow(value);
    setTextValue(str[str.length - 1]);
  };

  const handleEditQueueNo = () => {
    let result = {
      store_header: {
        id: confirmQueueNoRow.id,
        visit_queue_details: {
          visit_queue_no: null,
          visit_queue_comment: editQueueNoRowDesc,
        },
      },
    };
    const trimValue = textValue.trim();
    if (trimValue) {
      const defaultUserOrganisation =
        curUser.organisationInfo.user_organisations.find(
          (o) => o.is_default === true
        ) || curUser.organisationInfo.user_organisations[0];

      const SelfDefinedQueueNo = `${defaultUserOrganisation.organisations.id}-${
        defaultUserOrganisation.organisation_branch.branch.id
      }-${generalConstants.VISIT_WORK_ORDER_TYPE}-${moment().format(
        "YY-MM-DD"
      )}-${trimValue}`;

      result.store_header.visit_queue_details.visit_queue_no =
        SelfDefinedQueueNo;
    }

    dispatch(putVisitSetQueueNumber.pending(result));
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_physician_name_value: value,
        search_staff_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_physician_name_value: null,
        search_staff_name_value: null,
      });
    }
  };

  //useEffect
  useEffect(() => {
    prevArrivalOnStatus.current = false;
    prevDepartureOnStatus.current = false;
    prevConsultOnStatus.current = false;
    prevSetQueueNoStatus.current = false;

    prevDepartureStatus.current = false;
    prevSaveStatus.current = false;
    prevNewStatus.current = false;
    prevSubmitStatus.current = false;
    prevSubmitInvoiceStatus.current = false;
    prevRefreshTimeStamp.current = moment();
  }, []);

  // useEffect(() => {
  //   if (
  //     !openAppointmentDialog &&
  //     !openVisitOrderDialog &&
  //     !confirmEditQueueNo
  //   ) {
  //     dispatch(getDashboardCounterView.pending(pageInfo));
  //   }
  // }, [
  //   pageInfo,
  //   dispatch,
  //   openVisitOrderDialog,
  //   openAppointmentDialog,
  //   confirmEditQueueNo,
  // ]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp !== prevRefreshTimeStamp.current) {
      //check the previous ref with current state
      prevRefreshTimeStamp.current = refreshTimeStamp;

      if (
        !openAppointmentDialog &&
        !openVisitOrderDialog &&
        !confirmEditQueueNo
      ) {
        dispatch(getDashboardCounterView.pending(pageInfo));
      }
    }
  }, [
    dashboardView,
    refreshTimeStamp,
    dispatch,
    pageInfo,
    openVisitOrderDialog,
    openAppointmentDialog,
    confirmEditQueueNo,
  ]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp === prevRefreshTimeStamp.current) {
      //update the newest time after calling api
      prevRefreshTimeStamp.current = moment();
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  useEffect(() => {
    if (visitOrder.isLoadingSaveStoreOrder !== prevSaveStatus.current) {
      //check the previous ref with current state
      prevSaveStatus.current = visitOrder.isLoadingSaveStoreOrder;

      if (
        visitOrder.postVisitSaveStoreOrderIsSuccess === true &&
        visitOrder.isLoadingSaveStoreOrder === false
      ) {
        if (
          !openAppointmentDialog &&
          !openVisitOrderDialog &&
          !confirmEditQueueNo
        ) {
          dispatch(getDashboardCounterView.pending(pageInfo));
        }
      }
    }
  }, [
    visitOrder,
    dispatch,
    pageInfo,
    openVisitOrderDialog,
    openAppointmentDialog,
    confirmEditQueueNo,
  ]);

  useEffect(() => {
    if (visitOrder.isLoadingStockEditMode !== prevNewStatus.current) {
      //check the previous ref with current state
      prevNewStatus.current = visitOrder.isLoadingStockEditMode;

      if (
        visitOrder.putVisitStockEditModeIsSuccess === true &&
        visitOrder.isLoadingStockEditMode === false
      ) {
        if (
          !openAppointmentDialog &&
          !openVisitOrderDialog &&
          !confirmEditQueueNo
        ) {
          dispatch(getDashboardCounterView.pending(pageInfo));
        }
      }
    }
  }, [
    visitOrder,
    dispatch,
    pageInfo,
    openVisitOrderDialog,
    openAppointmentDialog,
    confirmEditQueueNo,
  ]);

  useEffect(() => {
    if (visitOrder.isLoadingSubmitStoreOrder !== prevSubmitStatus.current) {
      //check the previous ref with current state
      prevSubmitStatus.current = visitOrder.isLoadingSubmitStoreOrder;

      if (
        visitOrder.postVisitSubmitStoreOrderIsSuccess === true &&
        visitOrder.isLoadingSubmitStoreOrder === false
      ) {
        if (
          !openAppointmentDialog &&
          !openVisitOrderDialog &&
          !confirmEditQueueNo
        ) {
          dispatch(getDashboardCounterView.pending(pageInfo));
        }
      }
    }
  }, [
    visitOrder,
    dispatch,
    pageInfo,
    openVisitOrderDialog,
    openAppointmentDialog,
    confirmEditQueueNo,
  ]);

  useEffect(() => {
    if (invoice.isLoadingSubmitInvoice !== prevSubmitInvoiceStatus.current) {
      //check the previous ref with current state
      prevSubmitInvoiceStatus.current = invoice.isLoadingSubmitInvoice;

      if (
        invoice.postSubmitInvoiceSuccess === true &&
        invoice.isLoadingSubmitInvoice === false
      ) {
        if (
          !openAppointmentDialog &&
          !openVisitOrderDialog &&
          !confirmEditQueueNo
        ) {
          dispatch(getDashboardCounterView.pending(pageInfo));
        }
      }
    }
  }, [
    invoice,
    dispatch,
    pageInfo,
    openVisitOrderDialog,
    openAppointmentDialog,
    confirmEditQueueNo,
  ]);

  useEffect(() => {
    if (visitOrder.isLoadingVisitSetArrivalOn !== prevArrivalOnStatus.current) {
      //check the previous ref with current state
      prevArrivalOnStatus.current = visitOrder.isLoadingVisitSetArrivalOn;

      if (
        visitOrder.putVisitSetArrivalOnIsSuccess === true &&
        visitOrder.isLoadingVisitSetArrivalOn === false
      ) {
        if (
          !openAppointmentDialog &&
          !openVisitOrderDialog &&
          !confirmEditQueueNo
        ) {
          dispatch(getDashboardCounterView.pending(pageInfo));
        }
        setArrivalSuccessAlarm(true);
        setArrivalErrorAlarm(false);
      }
      if (
        visitOrder.putVisitSetDepartureOnIsSuccess === false &&
        visitOrder.isLoadingVisitSetDepartureOn === false
      ) {
        setArrivalSuccessAlarm(false);
        setArrivalErrorAlarm(true);
        setArrivalMsgAlarm(visitOrder.error.visitSetArrivalOnError);
      }
    }
  }, [
    visitOrder,
    dispatch,
    pageInfo,
    openVisitOrderDialog,
    openAppointmentDialog,
    confirmEditQueueNo,
  ]);

  useEffect(() => {
    if (
      visitOrder.isLoadingVisitSetDepartureOn !== prevDepartureOnStatus.current
    ) {
      //check the previous ref with current state
      prevDepartureOnStatus.current = visitOrder.isLoadingVisitSetDepartureOn;

      if (
        visitOrder.putVisitSetDepartureOnIsSuccess === true &&
        visitOrder.isLoadingVisitSetDepartureOn === false
      ) {
        setDepartureSuccessAlarm(true);
        setDepartureErrorAlarm(false);
        if (
          !openAppointmentDialog &&
          !openVisitOrderDialog &&
          !confirmEditQueueNo
        ) {
          dispatch(getDashboardCounterView.pending(pageInfo));
        }
      }

      if (
        visitOrder.putVisitSetDepartureOnIsSuccess === false &&
        visitOrder.isLoadingVisitSetDepartureOn === false
      ) {
        setDepartureSuccessAlarm(false);
        setDepartureErrorAlarm(true);
        setDepartureMsgAlarm(visitOrder.error.visitSetDepartureOnError);
      }
    }
  }, [
    visitOrder,
    dispatch,
    pageInfo,
    openVisitOrderDialog,
    openAppointmentDialog,
    confirmEditQueueNo,
  ]);

  useEffect(() => {
    if (visitOrder.isLoadingVisitSetConsultOn !== prevConsultOnStatus.current) {
      //check the previous ref with current state
      prevConsultOnStatus.current = visitOrder.isLoadingVisitSetConsultOn;

      if (
        visitOrder.putVisitSetConsultOnIsSuccess === true &&
        visitOrder.isLoadingVisitSetConsultOn === false
      ) {
        if (
          !openAppointmentDialog &&
          !openVisitOrderDialog &&
          !confirmEditQueueNo
        ) {
          dispatch(getDashboardCounterView.pending(pageInfo));
        }
        setConsultSuccessAlarm(true);
        setConsultErrorAlarm(false);
      }

      if (
        visitOrder.putVisitSetConsultOnIsSuccess === false &&
        visitOrder.isLoadingVisitSetConsultOn === false
      ) {
        setConsultSuccessAlarm(false);
        setConsultErrorAlarm(true);
        setConsultMsgAlarm(visitOrder.error.visitSetConsultOnError);
      }
    }
  }, [
    visitOrder,
    dispatch,
    pageInfo,
    openVisitOrderDialog,
    openAppointmentDialog,
    confirmEditQueueNo,
  ]);

  useEffect(() => {
    if (
      visitOrder.isLoadingVisitSetQueueNumber !== prevSetQueueNoStatus.current
    ) {
      //check the previous ref with current state
      prevSetQueueNoStatus.current = visitOrder.isLoadingVisitSetQueueNumber;

      if (
        visitOrder.putVisitSetQueueNumberIsSuccess === true &&
        visitOrder.isLoadingVisitSetQueueNumber === false
      ) {
        if (
          !openAppointmentDialog &&
          !openVisitOrderDialog &&
          !confirmEditQueueNo
        ) {
          dispatch(getDashboardCounterView.pending(pageInfo));
        }
        setQueueSuccessAlarm(true);
        setQueueErrorAlarm(false);
      }

      if (
        visitOrder.putVisitSetQueueNumberIsSuccess === false &&
        visitOrder.isLoadingVisitSetQueueNumber === false
      ) {
        setQueueSuccessAlarm(false);
        setQueueErrorAlarm(true);
        setQueueMsgAlarm(visitOrder.error.visitSetQueueNumberError);
      }
    }
  }, [
    visitOrder,
    dispatch,
    pageInfo,
    openVisitOrderDialog,
    openAppointmentDialog,
    confirmEditQueueNo,
  ]);

  if (
    dashboard.getDashboardCounterViewIsSuccess === false &&
    dashboard.isLoadingGetDashboardCounterView === false
  ) {
    return (
      <Alert severity="error">
        {dashboard.error.getDashboardCounterViewError}
      </Alert>
    );
  } else if (
    dashboard.getDashboardCounterViewIsSuccess === true &&
    dashboard.isLoadingGetDashboardCounterView === false
  ) {
    return (
      <>
        <Collapse in={arrivalSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setArrivalSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Arrival time has been updated")}
          </Alert>
        </Collapse>

        <Collapse in={arrivalErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setArrivalErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {arrivalMsgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={departureSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setDepartureSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Departure time has been updated")}
          </Alert>
        </Collapse>

        <Collapse in={departureErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setDepartureErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {departureMsgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={consultSuccessAlarm}>
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setConsultSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Consult time has been updated")}
          </Alert>
        </Collapse>

        <Collapse in={consultErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setConsultErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {consultMsgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={queueSuccessAlarm}>
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setQueueSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Queue No has been updated")}
          </Alert>
        </Collapse>

        <Collapse in={queueErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setQueueErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {queueMsgAlarm}
          </Alert>
        </Collapse>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmDepartureOpen}
          setOpen={setConfirmDepartureOpen}
          onConfirm={handleConfirmDepartureProcess}>
          {editOrderInfo.status_type === generalConstants.NEW_STATUS_TYPE
            ? `${t("translation:Consult still in processing")}`
            : ""}
          {editOrderInfo.local_dispensary_headers?.length &&
          editOrderInfo.local_dispensary_headers.some(
            (item) => item.status_type !== generalConstants.CLOSED_STATUS_TYPE
          )
            ? `${t("translation:Dispensary still in processing")}`
            : ""}
          {editOrderInfo.approved_invoice_headers?.length &&
          editOrderInfo.approved_invoice_headers.some(
            (item) => item.status_type !== generalConstants.CLOSED_STATUS_TYPE
          )
            ? `${t("translation:Payment still in processing")}`
            : ""}
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmArrivalOpen}
          setOpen={setConfirmArrivalOpen}
          onConfirm={handleConfirmArrivalProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmConsultOpen}
          setOpen={setConfirmConsultOpen}
          onConfirm={handleConfirmConsultProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Edit Queue No")}
          open={confirmEditQueueNo}
          setOpen={setConfirmEditQueueNo}
          onConfirm={handleEditQueueNo}>
          {t(
            "translation:Are you sure you want to edit this queue no and comment?"
          )}
          {t(
            "translation:Queue no will be reset if you confirm with empty value"
          )}
          .
          <TextField
            value={textValue}
            variant="outlined"
            margin="normal"
            name="QueueNo"
            label={t("translation:Queue No")}
            type="text"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                minLength: 4,
                maxLength: 4,
              },
            }}
            onInput={allowOnlyNumericsOrDigits}
            onChange={(e) => {
              setTextValue(e.target.value);
            }}
            fullWidth
          />
          <TextField
            value={editQueueNoRowDesc}
            variant="outlined"
            margin="normal"
            name="Comment"
            label={t("translation:Comment")}
            onChange={(e) => {
              setEditQueueNoRowDesc(e.target.value ? e.target.value : null);
            }}
            fullWidth
          />
        </ConfirmDialog>

        <SortingSearchTable
          columns={updateAccess ? columns : columnsViewOnly}
          data={
            dashboard.dashboardCounterView
              ? dashboard.dashboardCounterView.store_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            dashboard.dashboardCounterView
              ? dashboard.dashboardCounterView.total_pages
              : 0
          }
          loading={dashboard.isLoadingGetDashboardCounterView}
          totalItems={
            dashboard.dashboardCounterView
              ? dashboard.dashboardCounterView.total_items
              : 0
          }
          handleSearch={handleSearch}
          searchValue={searchValue}
          searchPlaceholder={t("Physician/ Patient")}
          localStorageName={localStorageNameKey}
        />

        <VisitOrderDialog
          open={openVisitOrderDialog}
          visitOrderNo={visitOrderNo}
          setOpen={() => {
            setOpenVisitOrderDialog(false);
            refreshTable();
          }}
        />

        <AppointmentDialog
          open={openAppointmentDialog}
          appointmentNo={appointmentNo}
          setOpen={() => {
            setOpenAppointmentDialog(false);
            refreshTable();
          }}
        />
      </>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
