//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
//actions

import {
  getDeliveryOrderList,
  getDeliveryOrderSummaryList,
  putDeliveryOrderEditMode,
  putDeliveryOrderWIPMode,
  postSubmitAllDelivery,
  getDeliveryOrderByStoreHeaderId,
  getDeliveryOrderById,
} from "../../../actions/deliveryOrderActions";

//components

import { SortingSearchTable } from "../../../components/SortingSearchTable";
import { ReactComponent as NewLogo } from "../../../assets/coolicons/media/fast_rewind.svg";
import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import { ReactComponent as ProceedLogo } from "../../../assets/coolicons/media/fast_forward.svg";
import { ReactComponent as ProceedAllLogo } from "../../../assets/coolicons/media/skip_next.svg";
import DetailDeliveryPage from "./DetailDeliveryPage";
import EditDeliveryPage from "./EditDeliveryPage";
import StoreOrderDialog from "./viewStoreOrder/StoreOrderDialog";
import * as pageConfigure from "../../../_constants/pageConstants";
import ConfirmDialog from "../../../components/ConfirmDialog";
import * as generalConstants from "../../../_constants/generalConstants";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import { getTableDefaultLocalStorage } from "../../../components/functions/localStorage";
import DeliveryOptimizedDialog from "./components/DeliveryOptimizedDialog";

export default function DeliveryListTable({
  setDeliveryOrderInformationView = () => null,
  setRefreshTimeStamp = () => null,
  refreshTimeStamp = null,
  dashboardView = false,
}) {
  //variables

  const { t } = useTranslation();
  const localStorageNameKey = "deliveryListTable";
  const curUser = useSelector((state) => state.curUser);
  const updateOrderAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_DELIVERY_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;

  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: t("translation:Delivery Order No"),
      accessor: "order_number",
    },
    {
      Header: t("translation:Courier"),
      sortable: false,
      accessor: "courier_name",
    },
    {
      Header: t("translation:Creation Date"),
      sortable: false,
      accessor: (d) => {
        if (d.created_on)
          return moment.utc(d.created_on).local().format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const columnsWUpdateAccess = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "statusAction",
      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) => {
        if (row.original.status_type === generalConstants.PENDING_STATUS_TYPE) {
          return (
            <Button onClick={() => handleRowProcess(row.original)}>
              <ProceedLogo title={t("translation:Proceed")} />
            </Button>
          );
        } else if (
          row.original.status_type === generalConstants.NEW_STATUS_TYPE
        ) {
          return (
            <>
              <Button onClick={() => handleRowEdit(row.original)}>
                <EditLogo title={t("translation:Edit")} />
              </Button>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: "",
      accessor: "New",
      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ||
        row.original.status_type === generalConstants.PENDING_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowEditProcess(row.original)}>
              <NewLogo title={t("translation:New")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: "",
      accessor: "processAllAction",
      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) =>
        curUser?.defaultBranch?.branch
          ?.is_work_order_delivery_tracking_required !== true &&
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowProcessAll(row.original)}>
              <ProceedAllLogo title={t("translation:Close")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },

    {
      Header: t("translation:Delivery Order No"),
      accessor: "order_number",
    },

    {
      Header: t("translation:Courier"),
      sortable: false,
      accessor: "courier_name",
    },

    {
      Header: t("translation:Creation Date"),
      sortable: false,
      accessor: (d) => {
        if (d.created_on)
          return moment.utc(d.created_on).local().format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    search_delivery_order_number_value: "",
    search_store_order_number_value: "",
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) =>
        size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
    search_courier_name_value: null,
  });

  const dispatch = useDispatch();

  const deliveryOrder = useSelector((state) => state.deliveryOrder);

  const common = useSelector((state) => state.common);

  //
  const [orderEditView, setOrderEditView] = useState(false);
  const [orderDetailView, setOrderDetailView] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [storeOrderSearchId, setStoreOrderSearchId] = useState(null);

  const [confirmEditOpen, setConfirmEditOpen] = useState(false);
  const [confirmProceedAllOpen, setConfirmProceedAllOpen] = useState(false);

  const [confirmWIPOpen, setConfirmWIPOpen] = useState(false);

  const [orderInfo, setOrderInfo] = useState([]);
  const [newStatusErrorAlarm, setNewStatusErrorAlarm] = useState(false);
  const [newStatusSuccessAlarm, setNewStatusSuccessAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = React.useState([]);
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [submitmsgAlarm, setSubmitMsgAlarm] = React.useState([]);
  const [WIPSuccessAlarm, setWIPSuccessAlarm] = useState(false);
  const [WIPErrorAlarm, setWIPErrorAlarm] = useState(false);

  const [WIPmsgAlarm, setWIPMsgAlarm] = React.useState([]);

  const [DOByStoreHeaderIdErrorAlarm, setDOByStoreHeaderIdErrorAlarm] =
    useState(false);
  const [DOByStoreHeaderIdMsgAlarm, setDOByStoreHeaderIdMsgAlarm] =
    React.useState([]);

  const [groupOrder, setGroupOrder] = useState([]);

  const prevDeliveryOrderEditStatus = useRef();
  const prevSubmitAllDeliveryStatus = useRef();
  const prevDeliveryOrderWIPStatus = useRef();
  const prevDeliveryOrderByStoreHeaderIdStatus = useRef();
  const prevDeliveryOrderListStatus = useRef();
  const prevDeliveryOrderIdStatus = useRef();

  const prevRefreshTimeStamp = useRef();

  //functions
  const addressGrouping = (deliveryOrderDetailInfo) => {
    const deliveryOrderDetailInfoWoDeleted = deliveryOrderDetailInfo.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );

    deliveryOrderDetailInfoWoDeleted.map((item, index) => {
      if (item.delivery_header.store_medicine_header.delivery_owned !== null) {
        deliveryOrderDetailInfoWoDeleted[
          index
        ].delivery_header.store_medicine_header.delivery_name =
          item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_name;

        deliveryOrderDetailInfoWoDeleted[
          index
        ].delivery_header.store_medicine_header.delivery_remarks =
          item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_remarks;

        deliveryOrderDetailInfoWoDeleted[
          index
        ].delivery_header.store_medicine_header.delivery_address =
          item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_address;

        deliveryOrderDetailInfoWoDeleted[
          index
        ].delivery_header.store_medicine_header.delivery_address_details =
          item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_address_details;

        deliveryOrderDetailInfoWoDeleted[
          index
        ].delivery_header.store_medicine_header.delivery_postal_code =
          item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_postal_code;

        deliveryOrderDetailInfoWoDeleted[
          index
        ].delivery_header.store_medicine_header.delivery_contact_number =
          item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_contact_number;
      }
    });

    const result = Object.groupBy(
      deliveryOrderDetailInfoWoDeleted,
      ({ delivery_header }) =>
        delivery_header.store_medicine_header.delivery_address +
        delivery_header.store_medicine_header.delivery_address_details +
        delivery_header.store_medicine_header.delivery_postal_code
    );

    const addressKeys = Object.entries(result);

    const addrDifferentList = addressKeys.map((item, index) => {
      return {
        delivery_address:
          item[1][0].delivery_header.store_medicine_header.delivery_address,
        delivery_address_details:
          item[1][0].delivery_header.store_medicine_header
            .delivery_address_details,

        delivery_contact_number:
          item[1][0].delivery_header.store_medicine_header
            .delivery_contact_number,
        delivery_name:
          item[1][0].delivery_header.store_medicine_header.delivery_name,
        delivery_postal_code:
          item[1][0].delivery_header.store_medicine_header.delivery_postal_code,
        delivery_remarks:
          item[1][0].delivery_header.store_medicine_header.delivery_remarks,
        id: 0,
        row_no: index + 1,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
      };
    });
    setGroupOrder(addrDifferentList);
    return addrDifferentList;
  };

  const handleRowEditProcess = (value) => {
    setConfirmEditOpen(true);
    setSelectedValue(value);
  };

  const handleRowProcessAll = (value) => {
    setConfirmProceedAllOpen(true);
    setSelectedValue(value);
  };

  const handleRowProcess = (value) => {
    dispatch(getDeliveryOrderById.pending(value.id));
    setConfirmOpen(true);
    setSelectedValue(value);
  };

  const handleRowEdit = (value) => {
    const submission = {
      is_pagination: false,
      search_delivery_order_id_value: value.id,
    };
    dispatch(getDeliveryOrderList.pending(submission));
    setOrderInfo(null);
    setOrderEditView(true);
    setNewStatusSuccessAlarm(false);
    setNewStatusErrorAlarm(false);
    setWIPSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setSubmitErrorAlarm(false);
  };

  const handleRowDetail = (value) => {
    const submission = {
      is_pagination: false,
      search_delivery_order_id_value: value.id,
    };
    dispatch(getDeliveryOrderList.pending(submission));
    setOrderInfo(null);
    setOrderDetailView(true);
    setNewStatusSuccessAlarm(false);
    setNewStatusErrorAlarm(false);
    setWIPSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setSubmitErrorAlarm(false);
  };

  const onSubmitDelivery = (e) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const submission = {
      delivery_order_header: selectedValue,
    };
    dispatch(postSubmitAllDelivery.pending(submission));
  };

  const handleConfirmProcess = () => {
    const submissionJSON = {
      delivery_order_header: {
        approved_delivery_tracking_headers: groupOrder,
        id: selectedValue.id,
        order_number: selectedValue.order_number,
      },
    };
    dispatch(putDeliveryOrderWIPMode.pending(submissionJSON));
  };
  const handleConfirmEditProcess = () => {
    dispatch(putDeliveryOrderEditMode.pending(selectedValue.id));
  };

  //useEffects
  useEffect(() => {
    prevDeliveryOrderListStatus.current = false;
    prevDeliveryOrderEditStatus.current = false;
    prevDeliveryOrderWIPStatus.current = false;
    prevSubmitAllDeliveryStatus.current = false;
    prevDeliveryOrderByStoreHeaderIdStatus.current = false;
    prevDeliveryOrderIdStatus.current = false;
    prevRefreshTimeStamp.current = null;
  }, []);
  useEffect(() => {
    if (
      dashboardView &&
      refreshTimeStamp !== prevRefreshTimeStamp.current &&
      !orderDetailView &&
      !orderEditView
    ) {
      //check the previous ref with current state
      prevRefreshTimeStamp.current = refreshTimeStamp;
      dispatch(getDeliveryOrderSummaryList.pending(pageInfo));
    }
  }, [
    dashboardView,
    refreshTimeStamp,
    dispatch,
    pageInfo,
    orderDetailView,
    orderEditView,
  ]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp === prevRefreshTimeStamp.current) {
      //update the newest time after calling api
      prevRefreshTimeStamp.current = moment();
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingGetDeliveryOrderById !==
      prevDeliveryOrderIdStatus.current
    ) {
      //check the previous ref with current state
      prevDeliveryOrderIdStatus.current =
        deliveryOrder.isLoadingGetDeliveryOrderById;

      if (
        deliveryOrder.getDeliveryOrderByIdIsSuccess === true &&
        deliveryOrder.isLoadingGetDeliveryOrderById === false
      ) {
        addressGrouping(
          deliveryOrder.getDeliveryOrderById.delivery_order_header
            .delivery_order_details
        );
      }

      if (
        deliveryOrder.getDeliveryOrderByIdIsSuccess === false &&
        deliveryOrder.isLoadingGetDeliveryOrderById === false
      ) {
        setGroupOrder([]);
      }
    }
  }, [dispatch, deliveryOrder]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingDeliveryOrderList !==
      prevDeliveryOrderListStatus.current
    ) {
      //check the previous ref with current state
      prevDeliveryOrderListStatus.current =
        deliveryOrder.isLoadingDeliveryOrderList;

      if (
        deliveryOrder.getDeliveryOrderListSuccess === true &&
        deliveryOrder.isLoadingDeliveryOrderList === false
      ) {
        setOrderInfo(deliveryOrder.deliveryOrderList.delivery_order_headers[0]);
      }

      if (
        deliveryOrder.getDeliveryOrderListSuccess === false &&
        deliveryOrder.isLoadingDeliveryOrderList === false
      ) {
        setOrderInfo(null);
        setOrderEditView(false);
        setOrderDetailView(false);
      }
    }
  }, [dispatch, deliveryOrder]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingDeliveryOrderEditMode !==
      prevDeliveryOrderEditStatus.current
    ) {
      //check the previous ref with current state
      prevDeliveryOrderEditStatus.current =
        deliveryOrder.isLoadingDeliveryOrderEditMode;

      if (
        deliveryOrder.putDeliveryOrderEditModeSuccess === true &&
        deliveryOrder.isLoadingDeliveryOrderEditMode === false
      ) {
        storeOrderSearchId
          ? dispatch(
              getDeliveryOrderByStoreHeaderId.pending(storeOrderSearchId)
            )
          : dispatch(getDeliveryOrderSummaryList.pending(pageInfo));
        setNewStatusErrorAlarm(false);
        setNewStatusSuccessAlarm(true);
        window.scrollTo(0, 0);
      }

      if (
        deliveryOrder.putDeliveryOrderEditModeSuccess === false &&
        deliveryOrder.isLoadingDeliveryOrderEditMode === false
      ) {
        setMsgAlarm(deliveryOrder.error.deliveryOrderEditModeError);

        setNewStatusErrorAlarm(true);
        setNewStatusSuccessAlarm(false);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, deliveryOrder, pageInfo, storeOrderSearchId]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingSubmitAllDelivery !==
      prevSubmitAllDeliveryStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitAllDeliveryStatus.current =
        deliveryOrder.isLoadingSubmitAllDelivery;

      if (
        deliveryOrder.postSubmitAllDeliveryIsSuccess === true &&
        deliveryOrder.isLoadingSubmitAllDelivery === false
      ) {
        storeOrderSearchId
          ? dispatch(
              getDeliveryOrderByStoreHeaderId.pending(storeOrderSearchId)
            )
          : dispatch(getDeliveryOrderSummaryList.pending(pageInfo));
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        window.scrollTo(0, 0);
      }

      if (
        deliveryOrder.postSubmitAllDeliveryIsSuccess === false &&
        deliveryOrder.isLoadingSubmitAllDelivery === false
      ) {
        setSubmitMsgAlarm(deliveryOrder.error.postSubmitAllDelivery);

        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, deliveryOrder, pageInfo, storeOrderSearchId]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingSubmitAllDelivery !==
      prevSubmitAllDeliveryStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitAllDeliveryStatus.current =
        deliveryOrder.isLoadingSubmitAllDelivery;

      if (
        deliveryOrder.postSubmitAllDeliveryIsSuccess === true &&
        deliveryOrder.isLoadingSubmitAllDelivery === false
      ) {
        storeOrderSearchId
          ? dispatch(
              getDeliveryOrderByStoreHeaderId.pending(storeOrderSearchId)
            )
          : dispatch(getDeliveryOrderSummaryList.pending(pageInfo));
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        window.scrollTo(0, 0);
      }

      if (
        deliveryOrder.postSubmitAllDeliveryIsSuccess === false &&
        deliveryOrder.isLoadingSubmitAllDelivery === false
      ) {
        setSubmitMsgAlarm(deliveryOrder.error.postSubmitAllDelivery);

        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, deliveryOrder, pageInfo, storeOrderSearchId]);
  useEffect(() => {
    if (
      deliveryOrder.isLoadingDeliveryOrderWIPMode !==
      prevDeliveryOrderWIPStatus.current
    ) {
      //check the previous ref with current state
      prevDeliveryOrderWIPStatus.current =
        deliveryOrder.isLoadingDeliveryOrderWIPMode;

      if (
        deliveryOrder.putDeliveryOrderWIPModeSuccess === true &&
        deliveryOrder.isLoadingDeliveryOrderWIPMode === false
      ) {
        storeOrderSearchId
          ? dispatch(
              getDeliveryOrderByStoreHeaderId.pending(storeOrderSearchId)
            )
          : dispatch(getDeliveryOrderSummaryList.pending(pageInfo));
        setWIPErrorAlarm(false);
        setWIPSuccessAlarm(true);
        window.scrollTo(0, 0);
      }

      if (
        deliveryOrder.putDeliveryOrderWIPModeSuccess === false &&
        deliveryOrder.isLoadingDeliveryOrderWIPMode === false
      ) {
        setWIPMsgAlarm(deliveryOrder.error.deliveryOrderWIPModeError);

        setWIPErrorAlarm(true);
        setWIPSuccessAlarm(false);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, deliveryOrder, pageInfo, storeOrderSearchId]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingDeliveryOrderListByStoreHeaderId !==
      prevDeliveryOrderByStoreHeaderIdStatus.current
    ) {
      //check the previous ref with current state
      prevDeliveryOrderByStoreHeaderIdStatus.current =
        deliveryOrder.isLoadingDeliveryOrderListByStoreHeaderId;

      if (
        deliveryOrder.getDeliveryOrderListByStoreHeaderIdSuccess === true &&
        deliveryOrder.isLoadingDeliveryOrderListByStoreHeaderId === false
      ) {
        setDOByStoreHeaderIdErrorAlarm(false);
        window.scrollTo(0, 0);
      }

      if (
        deliveryOrder.getDeliveryOrderListByStoreHeaderIdSuccess === false &&
        deliveryOrder.isLoadingDeliveryOrderListByStoreHeaderId === false
      ) {
        setDOByStoreHeaderIdMsgAlarm(
          deliveryOrder.error.deliveryOrderListByStoreHeaderIdError
        );

        setDOByStoreHeaderIdErrorAlarm(true);
        window.scrollTo(0, 0);
      }
    }
  }, [deliveryOrder]);

  useEffect(() => {
    if (orderDetailView === true || orderEditView === true) {
      setDeliveryOrderInformationView(true);
    } else {
      setDeliveryOrderInformationView(false);
    }
  }, [orderEditView, orderDetailView, setDeliveryOrderInformationView]);

  useEffect(() => {
    if (!orderEditView && !orderDetailView) {
      if (storeOrderSearchId !== null) {
        dispatch(getDeliveryOrderByStoreHeaderId.pending(storeOrderSearchId));
      } else {
        if (!dashboardView) {
          dispatch(getDeliveryOrderSummaryList.pending(pageInfo));
        }
      }
    }
  }, [
    dispatch,
    pageInfo,
    orderEditView,
    orderDetailView,
    storeOrderSearchId,
    dashboardView,
  ]);
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_delivery_order_number_value: value,
        search_store_order_number_value: value,
        search_courier_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_delivery_order_number_value: null,
        search_store_order_number_value: null,
        search_courier_name_value: null,
      });
    }
  };
  if (
    deliveryOrder.getDeliveryOrderSummaryListSuccess === false &&
    deliveryOrder.isLoadingDeliveryOrderSummaryList === false
  )
    return (
      <Alert severity="error">
        {deliveryOrder.error.deliveryOrderSummaryListError}
      </Alert>
    );
  else if (
    common.getGeneralStatusIsSuccess === true &&
    !orderEditView &&
    !orderDetailView
  ) {
    return (
      <>
        <Collapse in={DOByStoreHeaderIdErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setDOByStoreHeaderIdErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {DOByStoreHeaderIdMsgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={newStatusSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setNewStatusSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.NEW_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={newStatusErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setNewStatusErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={submitSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            cy_data="alertSubmitOrder">
            {t("translation:Order has been submitted")}
          </Alert>
        </Collapse>

        <Collapse in={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {submitmsgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={WIPSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.WIP_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={WIPErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {WIPmsgAlarm}
          </Alert>
        </Collapse>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmEditOpen}
          setOpen={setConfirmEditOpen}
          onConfirm={handleConfirmEditProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmProceedAllOpen}
          setOpen={setConfirmProceedAllOpen}
          onConfirm={onSubmitDelivery}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmWIPOpen}
          setOpen={setConfirmWIPOpen}
          onConfirm={handleConfirmProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <DeliveryOptimizedDialog
          isLoading={deliveryOrder.isLoadingGetDeliveryOrderById}
          selectedValue={selectedValue}
          confirmOpen={confirmOpen}
          setConfirmOpen={setConfirmOpen}
          handleConfirmProcess={() => setConfirmWIPOpen(true)}
          groupOrder={groupOrder}
          setGroupOrder={setGroupOrder}
        />

        <StoreOrderDialog
          storeOrderSearchId={storeOrderSearchId}
          setStoreOrderSearchId={setStoreOrderSearchId}
        />
        {storeOrderSearchId === null && (
          <SortingSearchTable
            searchPlaceholder={t("translation:Delivery Order No/ Courrier")}
            statusPlaceholder={t("translation:Order Status")}
            searchValue={searchValue}
            statusOption={common.generalStatusList.status_general}
            columns={updateOrderAccess ? columnsWUpdateAccess : columns}
            data={
              deliveryOrder.deliveryOrderSummaryList
                ? deliveryOrder.deliveryOrderSummaryList.delivery_order_headers
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              deliveryOrder.deliveryOrderSummaryList
                ? deliveryOrder.deliveryOrderSummaryList.total_pages
                : 0
            }
            loading={deliveryOrder.isLoadingDeliveryOrderSummaryList}
            totalItems={
              deliveryOrder.deliveryOrderSummaryList
                ? deliveryOrder.deliveryOrderSummaryList.total_items
                : 0
            }
            handleSearch={handleSearch}
            searchDate={true}
            searchDatePlaceholder={t("translation:Creation Date")}
            localStorageName={localStorageNameKey}
          />
        )}

        {storeOrderSearchId !== null && (
          <SortingSearchTable
            disabledSearch={true}
            disabledFooter={true}
            searchValue={searchValue}
            statusOption={common.generalStatusList.status_general}
            columns={updateOrderAccess ? columnsWUpdateAccess : columns}
            data={
              deliveryOrder.getDeliveryOrderListByStoreHeaderId
                ? deliveryOrder.getDeliveryOrderListByStoreHeaderId
                    .delivery_order_headers
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              deliveryOrder.getDeliveryOrderListByStoreHeaderId
                ? deliveryOrder.getDeliveryOrderListByStoreHeaderId.total_pages
                : 0
            }
            loading={deliveryOrder.isLoadingDeliveryOrderListByStoreHeaderId}
            totalItems={
              deliveryOrder.getDeliveryOrderListByStoreHeaderId
                ? deliveryOrder.getDeliveryOrderListByStoreHeaderId.total_items
                : 0
            }
            handleSearch={handleSearch}
            localStorageName={localStorageNameKey}
          />
        )}

        {storeOrderSearchId && (
          <PageOnlyBackAction setView={() => setStoreOrderSearchId(null)} />
        )}
      </>
    );
  } else if (orderInfo && orderDetailView) {
    return (
      <DetailDeliveryPage
        orderInfo={orderInfo}
        setDeliveryOrderListView={() => {
          setOrderDetailView(false);
          setRefreshTimeStamp(moment());
        }}
      />
    );
  } else if (orderInfo && orderEditView) {
    return (
      <EditDeliveryPage
        orderInfo={orderInfo}
        setDeliveryOrderListView={() => {
          setOrderEditView(false);
          setRefreshTimeStamp(moment());
        }}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
