import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getCourierList,
  getCourierById,
  postCreateCourier,
  putUpdateCourier,
} from "../actions/courierActions";
import * as types from "../_constants/courierConstants";
import * as notification from "../_constants/notificationConstants";
import courierApis from "../_apis/courierApis";
export default function* courierSagas() {
  yield all([
    takeLatest(types.GET_COURIERLIST_ASYNC.PENDING, getCourierListCall),
  ]);
  yield all([
    takeLatest(types.GET_COURIERBYID_ASYNC.PENDING, getCourierByIdCall),
  ]);
  yield all([
    takeLatest(types.POST_CREATECOURIER_ASYNC.PENDING, postCreateCourierCall),
  ]);
  yield all([
    takeLatest(types.PUT_UPDATECOURIER_ASYNC.PENDING, putUpdateCourierCall),
  ]);
}

function* getCourierListCall(searchModel) {
  try {
    const data = yield call(courierApis.getCourierListApi, searchModel.data);
    yield put(getCourierList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getCourierList.error(error.response?.data?.message));
    else yield put(getCourierList.error(notification.CONST_NOINTERNET));
  }
}

function* getCourierByIdCall(searchModel) {
  try {
    const data = yield call(courierApis.getCourierByIdApi, searchModel.data);
    yield put(getCourierById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getCourierById.error(error.response?.data?.message));
    else yield put(getCourierById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateCourierCall(searchModel) {
  try {
    const data = yield call(courierApis.postCreateCourierApi, searchModel.data);
    yield put(postCreateCourier.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateCourier.error(error.response?.data?.message));
    else yield put(postCreateCourier.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateCourierCall(searchModel) {
  try {
    const data = yield call(courierApis.putUpdateCourierApi, searchModel.data);
    yield put(putUpdateCourier.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateCourier.error(error.response?.data?.message));
    else yield put(putUpdateCourier.error(notification.CONST_NOINTERNET));
  }
}
