//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
//Actions
import { getMedicineFixedSellingPriceDetailsSummaryList } from "../../../../actions/medicineFixedSellingPriceDetailsActions";

//Component

import { EditMedicineFixedSellingPriceDetailsPage } from "./EditMedicineFixedSellingPriceDetailsPage";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";

export default function MedicineFixedSellingPriceDetailsListTable({
  setInformationView,
}) {
  //variables
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const medicineFixedSellingPriceDetailsData = useSelector(
    (state) => state.medicineFixedSellingPriceDetails
  );
  const curUser = useSelector((state) => state.curUser);
  const common = useSelector((state) => state.common);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    search_name_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
  });
  const [searchValue, setSearchValue] = React.useState("");

  //Init
  const [
    editMedicineFixedSellingPriceDetailsView,
    setEditMedicineFixedSellingPriceDetailsView,
  ] = React.useState(false);
  const [
    medicineFixedSellingPriceDetailsDetailView,
    setMedicineFixedSellingPriceDetailsDetailView,
  ] = React.useState(false);

  const [headerInfo, setHeaderInfo] = React.useState(null);

  //Onclick event
  const handleRowEdit = (value) => {
    setHeaderInfo(value);
    setEditMedicineFixedSellingPriceDetailsView(true);
  };

  const handleRowDetail = (value) => {
    setHeaderInfo(value);
    setMedicineFixedSellingPriceDetailsDetailView(true);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: null,
      });
    }
  };

  //useEffects
  useEffect(() => {
    if (
      medicineFixedSellingPriceDetailsDetailView === true ||
      editMedicineFixedSellingPriceDetailsView === true
    ) {
      setInformationView(true);
    } else {
      setInformationView(false);
      setHeaderInfo(null);
    }
  }, [
    medicineFixedSellingPriceDetailsDetailView,
    editMedicineFixedSellingPriceDetailsView,
    setInformationView,
  ]);

  useEffect(() => {
    dispatch(getMedicineFixedSellingPriceDetailsSummaryList.pending(pageInfo));
  }, [dispatch, pageInfo]);
  if (
    medicineFixedSellingPriceDetailsData?.getMedicineFixedSellingPriceDetailsSummaryListIsSuccess ===
      false &&
    medicineFixedSellingPriceDetailsData?.isLoadingGetMedicineFixedSellingPriceDetailsSummaryList ===
      false
  ) {
    return (
      <Alert severity="error">
        {
          medicineFixedSellingPriceDetailsData.error
            .getMedicineFixedSellingPriceDetailsSummaryListError
        }
      </Alert>
    );
  } else if (
    common.generalStatusList &&
    !editMedicineFixedSellingPriceDetailsView &&
    !medicineFixedSellingPriceDetailsDetailView
  ) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name")}
        searchValue={searchValue}
        columns={columns}
        data={
          medicineFixedSellingPriceDetailsData
            .getMedicineFixedSellingPriceDetailsSummaryList
            ?.medicine_fixed_selling_price_headers !== undefined
            ? medicineFixedSellingPriceDetailsData
                .getMedicineFixedSellingPriceDetailsSummaryList
                ?.medicine_fixed_selling_price_headers
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          medicineFixedSellingPriceDetailsData.getMedicineFixedSellingPriceDetailsSummaryList
            ? medicineFixedSellingPriceDetailsData
                .getMedicineFixedSellingPriceDetailsSummaryList.total_pages
            : 0
        }
        loading={
          medicineFixedSellingPriceDetailsData.isLoadingMedicineFixedSellingPriceDetailsList
        }
        totalItems={
          medicineFixedSellingPriceDetailsData.getMedicineFixedSellingPriceDetailsSummaryList
            ? medicineFixedSellingPriceDetailsData
                .getMedicineFixedSellingPriceDetailsSummaryList.total_items
            : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (headerInfo && medicineFixedSellingPriceDetailsDetailView) {
    return (
      <EditMedicineFixedSellingPriceDetailsPage
        headerInfo={headerInfo}
        setEditMedicineFixedSellingPriceDetailsView={
          setMedicineFixedSellingPriceDetailsDetailView
        }
        viewOnly
      />
    );
  } else if (headerInfo && editMedicineFixedSellingPriceDetailsView) {
    return (
      <EditMedicineFixedSellingPriceDetailsPage
        headerInfo={headerInfo}
        setEditMedicineFixedSellingPriceDetailsView={
          setEditMedicineFixedSellingPriceDetailsView
        }
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
