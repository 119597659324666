//lib
import React, { useEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//component

import ProcessOrderFormPage from "./ProcessOrderFormPage";
import OrderFormListTable from "./OrderFormListTable";
import * as generalConstants from "../../../_constants/generalConstants";

import { useStyles } from "../../../components/globalStyles";

const OrderFormSummaryPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const [informationView, setInformationView] = React.useState(false);
  const [listView, setListView] = React.useState(true);
  const [viewInfo, setViewInfo] = React.useState(null);
  const [editView, setEditView] = React.useState(false);
  const [detailView, setDetailView] = React.useState(false);
  const [copyMode, setCopyMode] = React.useState(false);
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const prevSubmitOrderFormStatus = useRef();
  const orderForm = useSelector((state) => state.orderForm);
  const curUser = useSelector((state) => state.curUser);
  const addAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_ORDER_FORM_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    : false;

  useEffect(() => {
    //set ref when first render
    prevSubmitOrderFormStatus.current = false;
  }, []);

  // useEffect(() => {
  //   if (
  //     orderForm.isLoadingPostSubmitOrderForm !==
  //     prevSubmitOrderFormStatus.current
  //   ) {
  //     //check the previous ref with current state
  //     prevSubmitOrderFormStatus.current =
  //       orderForm.isLoadingPostSubmitOrderForm;

  //     if (
  //       orderForm.postSubmitOrderFormIsSuccess === true &&
  //       orderForm.isLoadingPostSubmitOrderForm === false
  //     ) {
  //       setEditView(false);
  //       setDetailView(true);
  //       setListView(true);
  //       setCopyMode(false);
  //       setViewInfo(orderForm.submitOrderForm);
  //     }
  //   }
  //}, [orderForm]);

  useEffect(() => {
    if (
      orderForm.isLoadingPostSubmitOrderForm !==
      prevSubmitOrderFormStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitOrderFormStatus.current =
        orderForm.isLoadingPostSubmitOrderForm;

      if (
        orderForm.postSubmitOrderFormIsSuccess === true &&
        orderForm.isLoadingPostSubmitOrderForm === false
      ) {
        if (
          orderForm.submitOrderForm?.order_form_header.status_type ===
          generalConstants.NEW_STATUS_TYPE
        ) {
          setMsgAlarm(t("translation:Purchase Requisition has been saved"));
        }

        if (
          orderForm.submitOrderForm?.order_form_header.status_type ===
          generalConstants.PENDING_STATUS_TYPE
        ) {
          setMsgAlarm(t("translation:Purchase Requisition has been submitted"));
        }

        setEditView(false);
        setDetailView(true);
        setListView(true);
        setCopyMode(false);
        setViewInfo(orderForm.submitOrderForm);
        setSuccessAlarm(true);
        window.scrollTo(0, 0);
      }

      if (
        orderForm.postSubmitOrderFormIsSuccess === false &&
        orderForm.isLoadingPostSubmitOrderForm === false
      ) {
        setErrorAlarm(true);
        setMsgAlarm(orderForm.error.postSubmitOrderFormError);
        window.scrollTo(0, 0);
      }
    }
  }, [orderForm, t]);

  useEffect(() => {
    if (!detailView) {
      setSuccessAlarm(false);
      setErrorAlarm(false);
      setCopyMode(false);
    }
  }, [detailView]);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!informationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            {listView ? (
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Purchase Requisition Summary")}
              </Typography>
            ) : (
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Add Purchase Requisition")}
              </Typography>
            )}
          </Grid>

          {listView && addAccess ? (
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonColor}
                onClick={() => setListView(!listView)}>
                {t("translation:Add")}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={1}></Grid>
          )}
        </Grid>
      )}
      <hr />

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>

      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>

      {!listView && <ProcessOrderFormPage setView={() => setListView(true)} />}

      {listView && (
        <OrderFormListTable
          setInformationView={setInformationView}
          viewInfo={viewInfo}
          setViewInfo={setViewInfo}
          editView={editView}
          setEditView={setEditView}
          detailView={detailView}
          setDetailView={setDetailView}
          copyMode={copyMode}
          setCopyMode={setCopyMode}
        />
      )}
    </Paper>
  );
};

export default OrderFormSummaryPage;
