import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/emailStoreConstants";

export const getEmailStoreOrderList = {
  pending: (data) =>
    createAction(types.GET_EMAILSTOREORDERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_EMAILSTOREORDERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_EMAILSTOREORDERLIST_ASYNC.ERROR, { error }),
};

export const getEmailStoreOrderById = {
  pending: (data) =>
    createAction(types.GET_EMAILSTORORDEREBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_EMAILSTORORDEREBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_EMAILSTORORDEREBYID_ASYNC.ERROR, { error }),
};

export const postEmailStoreReadEmail = {
  pending: (data) =>
    createAction(types.POST_EMAILSTOREREADEMAIL_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_EMAILSTOREREADEMAIL_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_EMAILSTOREREADEMAIL_ASYNC.ERROR, { error }),
};

export const putEmailStorePendingStatus = {
  pending: (data) =>
    createAction(types.PUT_EMAILSTOREPENDINGSTATUS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_EMAILSTOREPENDINGSTATUS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_EMAILSTOREPENDINGSTATUS_ASYNC.ERROR, { error }),
};

export const putProcessEmailStorePendingStatus = {
  pending: (data) =>
    createAction(types.PUT_PROCESSEMAILSTOREPENDINGSTATUS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_PROCESSEMAILSTOREPENDINGSTATUS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_PROCESSEMAILSTOREPENDINGSTATUS_ASYNC.ERROR, {
      error,
    }),
};

export const putUpdateEmailStore = {
  pending: (data) =>
    createAction(types.PUT_UPDATEEMAILSTORE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEEMAILSTORE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEEMAILSTORE_ASYNC.ERROR, { error }),
};

export const getEmailStoreBranchMedicine = {
  pending: (data) =>
    createAction(types.GET_EMAILSTOREBRANCHMEDICINE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_EMAILSTOREBRANCHMEDICINE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_EMAILSTOREBRANCHMEDICINE_ASYNC.ERROR, { error }),
};
