import apiConstants from "../_constants/apiConstants";
import { headerWithauth, headerWithauthFormData } from "../_helper/apiHeader";
import axios from "axios";

const reportingApis = {
  getReportingInvoiceDetailsListByVisitApi,
  getReportingInvoiceSummaryListByVisitApi,
  getReportingInvoiceMedicineCostingDetailsListByVisitApi,
  getReportingInvoiceMedicineCostingListByVisitApi,
  getReportingInvoiceDetailsListByStoreApi,
  getReportingInvoiceSummaryListByStoreApi,
  getReportingInvoiceMedicineCostingDetailsListByStoreApi,
  getReportingInvoiceMedicineCostingListByStoreApi,
  getReportingExportInvoiceDetailsListByVisitApi,
  getReportingExportInvoiceSummaryListByVisitApi,
  getReportingExportInvoiceMedicineCostingDetailsListByVisitApi,
  getReportingExportInvoiceMedicineCostingListByVisitApi,
  getReportingExportInvoiceDetailsListByStoreApi,
  getReportingExportInvoiceSummaryListByStoreApi,
  getReportingExportInvoiceMedicineCostingDetailsListByStoreApi,
  getReportingExportInvoiceMedicineCostingListByStoreApi,
  getReportingExportStoreMedicineHeaderApi,
  getReportingExportMedicineInOutApi,
  getReportingExportMedicineInOutDetailsApi,
  getReportingExportTreatmentsApi,
  postReportingVerifyCSVTreatmentsApi,
  postReportingImportCSVTreatmentsApi,
  getReportingExportAcupointsApi,
  postReportingVerifyCSVAcupointsApi,
  postReportingImportCSVAcupointsApi,
  getReportingExportMedicinesApi,
  postReportingVerifyCSVMedicinesApi,
  postReportingImportCSVMedicinesApi,
  getReportingExportFormulasApi,
  postReportingVerifyCSVFormulasApi,
  postReportingImportCSVFormulasApi,
  getReportingExportFormulaMedicineApi,
  postReportingVerifyCSVFormulaMedicineApi,
  postReportingImportCSVFormulaMedicineApi,
  getReportingExportStoreMedicineDetailsApi,
  getReportingExportVisitNotesDetailsApi,
  getReportingExportBranchMedicineApi,
  postReportingVerifyCSVBranchMedicineApi,
  postReportingImportCSVBranchMedicineApi,
  getReportingExportFixedMedicinePriceApi,
  postReportingVerifyCSVFixedMedicinePriceApi,
  postReportingImportCSVFixedMedicinePriceApi,
  postReportingVerifyFixedMedicinePriceSyncApi,
  postReportingImportFixedMedicinePriceSyncApi,
  getMedicineFixedSellingPriceHeaderApi,
  getReportingExportUserCreditsApi,
  getReportingExportUserCreditsStatementApi,
  getReportingExportBillAbleInvoiceByStoreApi,
  getReportingBillAbleInvoiceByStoreApi,
  getReportingExportBillAbleInvoiceByVisitApi,
  getReportingBillAbleInvoiceByVisitApi,
  getReportingExportBillAbleIMCByStoreApi,
  getReportingBillAbleIMCByStoreApi,
  getReportingExportBillAbleIMCByVisitApi,
  getReportingBillAbleIMCByVisitApi,
  getReportingPurchaseOrderSummaryApi,
  getReportingExportPurchaseOrderSummaryApi,
  getReportingPurchaseOrderDetailsApi,
  getReportingExportPurchaseOrderDetailsApi,
  getReportingDeliveryTrackingDetailsApi,
  getReportingExportDeliveryTrackingDetailsApi,
  getReportingExportDeliveryTrackingSummaryApi,
  getReportingExportAppointmentDetailsApi,
  getReportingExportAppointmentSummaryApi,
  getReportingExportDispensaryDetailsApi,
  getReportingExportDispensarySummaryApi,
  getReportingExportSMSMessageInOutDetailsApi,
  getReportingExportSMSMessageInOutSummaryApi,
};

async function getReportingInvoiceDetailsListByVisitApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingInvoiceDetailsListByVisit,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingInvoiceSummaryListByVisitApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingInvoiceSummaryListByVisit,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingInvoiceMedicineCostingDetailsListByVisitApi(
  searchModel
) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingInvoiceMedicineCostingDetailsListByVisit,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingInvoiceMedicineCostingListByVisitApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingInvoiceMedicineCostingListByVisit,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingInvoiceDetailsListByStoreApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingInvoiceDetailsListByStore,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingInvoiceSummaryListByStoreApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingInvoiceSummaryListByStore,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingInvoiceMedicineCostingDetailsListByStoreApi(
  searchModel
) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingInvoiceMedicineCostingDetailsListByStore,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingInvoiceMedicineCostingListByStoreApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingInvoiceMedicineCostingListByStore,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportInvoiceDetailsListByVisitApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportInvoiceDetailsListByVisit,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportInvoiceSummaryListByVisitApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportInvoiceSummaryListByVisit,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportInvoiceMedicineCostingDetailsListByVisitApi(
  searchModel
) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportInvoiceMedicineCostingDetailsListByVisit,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportInvoiceMedicineCostingListByVisitApi(
  searchModel
) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportInvoiceMedicineCostingListByVisit,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportInvoiceDetailsListByStoreApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportInvoiceDetailsListByStore,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportInvoiceSummaryListByStoreApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportInvoiceSummaryListByStore,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportInvoiceMedicineCostingDetailsListByStoreApi(
  searchModel
) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportInvoiceMedicineCostingDetailsListByStore,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportInvoiceMedicineCostingListByStoreApi(
  searchModel
) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportInvoiceMedicineCostingListByStore,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportStoreMedicineHeaderApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportStoreMedicineHeader,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportMedicineInOutApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportMedicineInOut,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportMedicineInOutDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportMedicineInOutDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportTreatmentsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportTreatments,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingVerifyCSVTreatmentsApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingVerifyCSVTreatments,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingImportCSVTreatmentsApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingImportCSVTreatments,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportAcupointsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportAcupoints,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingVerifyCSVAcupointsApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingVerifyCSVAcupoints,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingImportCSVAcupointsApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingImportCSVAcupoints,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportMedicinesApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportMedicines,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingVerifyCSVMedicinesApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingVerifyCSVMedicines,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingImportCSVMedicinesApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingImportCSVMedicines,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportFormulasApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportFormulas,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingVerifyCSVFormulasApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingVerifyCSVFormulas,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingImportCSVFormulasApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingImportCSVFormulas,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportFormulaMedicineApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportFormulaMedicine,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingVerifyCSVFormulaMedicineApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingVerifyCSVFormulaMedicine,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingImportCSVFormulaMedicineApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingImportCSVFormulaMedicine,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportStoreMedicineDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportStoreMedicineDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportVisitNotesDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportVisitNotesDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportBranchMedicineApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .get(apiConstants.apiGetReportingExportBranchMedicine)
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingImportCSVBranchMedicineApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingImportCSVBranchMedicine,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportFixedMedicinePriceApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportFixedMedicinePrice,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingVerifyCSVBranchMedicineApi(file) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingVerifyCSVBranchMedicine,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingVerifyCSVFixedMedicinePriceApi({ file, id }) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingVerifyCSVFixedMedicinePrice + id,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingImportCSVFixedMedicinePriceApi({ file, id }) {
  var formData = new FormData();

  formData.append("file", file[0].file);

  return await axios
    .post(
      apiConstants.apiPostReportingImportCSVFixedMedicinePrice + id,
      formData,
      headerWithauthFormData
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingVerifyFixedMedicinePriceSyncApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostReportingVerifyFixedMedicinePriceSync,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postReportingImportFixedMedicinePriceSyncApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostReportingImportFixedMedicinePriceSync,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineFixedSellingPriceHeaderApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(
      apiConstants.apiGetMedicineFixedSellingPriceHeader,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportUserCreditsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportUserCredits,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportUserCreditsStatementApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportUserCreditsStatement,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportBillAbleInvoiceByStoreApi(searchModel) {
  return await axios
    .get(
      apiConstants.apiGetReportingExportBillAbleInvoiceByStore,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingBillAbleInvoiceByStoreApi() {
  return await axios
    .get(apiConstants.apiGetReportingBillAbleInvoiceByStore, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportBillAbleInvoiceByVisitApi(searchModel) {
  return await axios
    .get(
      apiConstants.apiGetReportingExportBillAbleInvoiceByVisit,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingBillAbleInvoiceByVisitApi() {
  return await axios
    .get(apiConstants.apiGetReportingBillAbleInvoiceByVisit, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportBillAbleIMCByStoreApi(searchModel) {
  return await axios
    .get(apiConstants.apiGetReportingExportBillAbleIMCByStore, headerWithauth)
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingBillAbleIMCByStoreApi() {
  return await axios
    .get(apiConstants.apiGetReportingBillAbleIMCByStore, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportBillAbleIMCByVisitApi(searchModel) {
  return await axios
    .get(apiConstants.apiGetReportingExportBillAbleIMCByVisit, headerWithauth)
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingBillAbleIMCByVisitApi() {
  return await axios
    .get(apiConstants.apiGetReportingBillAbleIMCByVisit, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingPurchaseOrderSummaryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingPurchaseOrderSummary,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportPurchaseOrderSummaryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportPurchaseOrderSummary,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingPurchaseOrderDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingPurchaseOrderDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportPurchaseOrderDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportPurchaseOrderDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportDeliveryTrackingDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportDeliveryTrackingDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingDeliveryTrackingDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingDeliveryTrackingDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportDeliveryTrackingSummaryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportDeliveryTrackingSummary,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportAppointmentDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportAppointmentDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportAppointmentSummaryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportAppointmentSummary,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getReportingExportDispensaryDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportDispensaryDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportDispensarySummaryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportDispensarySummary,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportSMSMessageInOutDetailsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportSMSMessageInOutDetails,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getReportingExportSMSMessageInOutSummaryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetReportingExportSMSMessageInOutSummary,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default reportingApis;
