import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import TRANSLATION_ZH from "./zh/translation.json";

import TRANSLATION_EN from "./en/translation.json";
import * as lang from "../_constants/langConstants";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ["translation"],
    defaultNS: "translation",
    fallbackLng: lang.CONST_LANGUAGE_ENGLISH,
    debug: false,

    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: TRANSLATION_EN,
      },
      zh: {
        translation: TRANSLATION_ZH,
      },
    },
  });

export default i18n;
