import * as types from "../_constants/treatmentConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_TREATMENTBYID_ASYNC.PENDING:
      return {
        ...state,
        getTreatmentByIdIsSuccess: false,
        isLoadingGetTreatmentById: true,
        getTreatmentById: null,
      };

    case types.GET_TREATMENTBYID_ASYNC.ERROR:
      return {
        ...state,
        getTreatmentByIdIsSuccess: false,
        isLoadingGetTreatmentById: false,
        getTreatmentById: null,
        error: { ...state.error, getTreatmentByIdError: action.error },
      };

    case types.GET_TREATMENTBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getTreatmentByIdIsSuccess: true,
        isLoadingGetTreatmentById: false,
        getTreatmentById: action.data,
      };

    case types.GET_TREATMENTLIST_ASYNC.PENDING:
      return {
        ...state,
        getTreatmentListIsSuccess: false,
        isLoadingGetTreatmentList: true,
        getTreatmentList: null,
      };

    case types.GET_TREATMENTLIST_ASYNC.ERROR:
      return {
        ...state,
        getTreatmentListIsSuccess: false,
        isLoadingGetTreatmentList: false,
        getTreatmentList: null,
        error: { ...state.error, getTreatmentListError: action.error },
      };

    case types.GET_TREATMENTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getTreatmentListIsSuccess: true,
        isLoadingGetTreatmentList: false,
        getTreatmentList: action.data,
      };

    case types.POST_CREATETREATMENT_ASYNC.PENDING:
      return {
        ...state,
        postCreateTreatmentIsSuccess: false,
        isLoadingPostCreateTreatment: true,
        postCreateTreatment: null,
      };

    case types.POST_CREATETREATMENT_ASYNC.ERROR:
      return {
        ...state,
        postCreateTreatmentIsSuccess: false,
        isLoadingPostCreateTreatment: false,
        postCreateTreatment: null,
        error: { ...state.error, postCreateTreatmentError: action.error },
      };

    case types.POST_CREATETREATMENT_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateTreatmentIsSuccess: true,
        isLoadingPostCreateTreatment: false,
        postCreateTreatment: action.data,
      };

    case types.PUT_UPDATETREATMENT_ASYNC.PENDING:
      return {
        ...state,
        putUpdateTreatmentIsSuccess: false,
        isLoadingPutUpdateTreatment: true,
        putUpdateTreatment: null,
      };

    case types.PUT_UPDATETREATMENT_ASYNC.ERROR:
      return {
        ...state,
        putUpdateTreatmentIsSuccess: false,
        isLoadingPutUpdateTreatment: false,
        putUpdateTreatment: null,
        error: { ...state.error, putUpdateTreatmentError: action.error },
      };

    case types.PUT_UPDATETREATMENT_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateTreatmentIsSuccess: true,
        isLoadingPutUpdateTreatment: false,
        putUpdateTreatment: action.data,
      };

    case types.GET_TREATMENTTREATMENTTYPES_ASYNC.PENDING:
      return {
        ...state,
        getTreatmentTreatmentTypesIsSuccess: false,
        isLoadingGetTreatmentTreatmentTypes: true,
        getTreatmentTreatmentTypes: null,
      };

    case types.GET_TREATMENTTREATMENTTYPES_ASYNC.ERROR:
      return {
        ...state,
        getTreatmentTreatmentTypesIsSuccess: false,
        isLoadingGetTreatmentTreatmentTypes: false,
        getTreatmentTreatmentTypes: null,
        error: {
          ...state.error,
          getTreatmentTreatmentTypesError: action.error,
        },
      };

    case types.GET_TREATMENTTREATMENTTYPES_ASYNC.SUCCESS:
      return {
        ...state,
        getTreatmentTreatmentTypesIsSuccess: true,
        isLoadingGetTreatmentTreatmentTypes: false,
        getTreatmentTreatmentTypes: action.data,
      };

    default:
      return state;
  }
};
