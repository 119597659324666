import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
//components
import * as generalConstants from "../../../_constants/generalConstants";

import SocketNotification from "./SocketNotification";

import InvoiceListTable from "../../invoice/Invoice/InvoiceListTable";

//styles
export default function InvoiceTable() {
  const { t } = useTranslation();

  //variable
  const [refreshTimeStamp, setRefreshTimeStamp] = useState(moment());

  return (
    <SocketNotification
      setRefreshTimeStamp={setRefreshTimeStamp}
      cellTitle={t("translation:Invoice")}
      sockectWorkTypeId={generalConstants.INVOICE_WORK_ORDER_TYPE}
      localStorageName="invoiceListTableNotification">
      <InvoiceListTable
        refreshTimeStamp={refreshTimeStamp}
        dashboardView={true}
        setRefreshTimeStamp={setRefreshTimeStamp}
      />
    </SocketNotification>
  );
}
