import React, { forwardRef } from "react";
import MenuItems from "./MenuItems";
import List from "@mui/material/List";
const Dropdown = forwardRef((props, ref) => {
  const { children, level, accessRight, anchorEl, setanchorEl, menu, setMenu } =
    props;

  const dropDownLevel = level + 1;
  return (
    <List>
      {children.map((children, index) => (
        <MenuItems
          key={index}
          items={children}
          index={index}
          level={dropDownLevel}
          accessRight={accessRight}
          anchorEl={anchorEl}
          setanchorEl={setanchorEl}
          menu={menu}
          setMenu={setMenu}
          ref={ref}
        />
      ))}
    </List>
  );
});

export default Dropdown;
