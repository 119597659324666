import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/notificationGroupConstants";

export const getNotificationGroupById = {
  pending: (data) =>
    createAction(types.GET_NOTIFICATIONGROUPBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_NOTIFICATIONGROUPBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_NOTIFICATIONGROUPBYID_ASYNC.ERROR, { error }),
};

export const getNotificationGroupList = {
  pending: (data) =>
    createAction(types.GET_NOTIFICATIONGROUPLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_NOTIFICATIONGROUPLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_NOTIFICATIONGROUPLIST_ASYNC.ERROR, { error }),
};

export const postCreateNotificationGroup = {
  pending: (data) =>
    createAction(types.POST_CREATENOTIFICATIONGROUP_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATENOTIFICATIONGROUP_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATENOTIFICATIONGROUP_ASYNC.ERROR, { error }),
};

export const putUpdateNotificationGroup = {
  pending: (data) =>
    createAction(types.PUT_UPDATENOTIFICATIONGROUP_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATENOTIFICATIONGROUP_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATENOTIFICATIONGROUP_ASYNC.ERROR, { error }),
};
