import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Button, Paper } from "@mui/material";

import Alert from "@mui/material/Alert";
//components
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import * as pageConfigure from "../../../_constants/pageConstants";
import * as generalConstants from "../../../_constants/generalConstants";

import { ReactComponent as ProceedAllLogo } from "../../../assets/coolicons/media/skip_next.svg";

import { useStyles } from "../../../components/globalStyles";

import { getTableDefaultLocalStorage } from "../../../components/functions/localStorage";
//Actions

import { getAppointmentList } from "../../../actions/appointmentActions";
import AddOrderPage from "../../visit/Consultation/AddOrderPage";
//styles
export default function AppointmentListTable({
  refreshTimeStamp = null,
  disabledTableSearch = false,
  appointmentNo = null,
  dashboardView = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const localStorageNameKey = "appointmentListTable";
  const appointment = useSelector((state) => state.appointment);
  const common = useSelector((state) => state.common);
  const curUser = useSelector((state) => state.curUser);

  // const visitOrder = useSelector((state) => state.visitOrder);
  const [searchValue, setSearchValue] = useState("");

  const prevSaveVisitStoreStatus = useRef();
  const prevRefreshTimeStamp = useRef();
  //variable

  const addAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      //using visit right, go to create a visit
      Header: "",
      accessor: "processAllAction",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        addAccess &&
        row.original.status_type !== generalConstants.CLOSED_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowProcessAll(row.original)}>
              <ProceedAllLogo title={t("translation:Close")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Appointment Order No"),
      accessor: "order_number",
      sortable: false,
    },
    {
      Header: t("translation:Name"),
      accessor: "staff.name",
      sortable: false,
    },
    {
      Header: t("translation:Self-defined Name"),
      accessor: "temp_user.name",
      sortable: false,
    },
    {
      Header: t("translation:Physician"),
      accessor: "physician.name",
      sortable: false,
    },
    {
      Header: t("translation:Date"),
      accessor: "full_date",
      Cell: ({ cell }) => {
        return cell.value
          ? moment(cell.value, "YYYY-MM-DD").format("DD-MMM-YYYY")
          : "";
      },
    },
    {
      Header: t("translation:Start Time"),
      accessor: "start_time",
      Cell: ({ cell }) => {
        return cell.value ? moment(cell.value, "HH:mm:ss").format("HH:mm") : "";
      },
    },
    {
      Header: t("translation:End Time"),
      accessor: "end_time",
      Cell: ({ cell }) => {
        return cell.value ? moment(cell.value, "HH:mm:ss").format("HH:mm") : "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];
  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) =>
        size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
    search_appointment_order_id_value: null,
    search_appointment_order_number_value: appointmentNo,
    search_branch_id_value: curUser.defaultBranch?.branch?.id,
    search_end_date_value: disabledTableSearch
      ? null
      : moment().format("YYYY-MM-DD"),
    search_end_timestamp_value: null,
    search_physician_id_value: null,
    search_start_date_value: disabledTableSearch
      ? null
      : moment().format("YYYY-MM-DD"),
    search_start_timestamp_value: null,
    search_physician_name_value: null,
    search_staff_name_value: null,
  });

  const [confirmAddOrderOpen, setConfirmAddOrderOpen] = useState(false);

  const [selectedValue, setSelectedValue] = useState(false);

  //const [saveOrderSuccessAlarm, setSaveOrderSuccessAlarm] = useState(false);

  const handleRowProcessAll = (value) => {
    setConfirmAddOrderOpen(true);
    setSelectedValue(value);
  };

  const handleCloseConfirmAddandRefresh = (value) => {
    setConfirmAddOrderOpen(false);
    setSelectedValue(null);
    dispatch(getAppointmentList.pending(pageInfo));
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_staff_name_value: value,
        search_physician_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_physician_name_value: null,
        search_staff_name_value: null,
      });
    }
  };

  //useEffect
  useEffect(() => {
    prevSaveVisitStoreStatus.current = false;
    prevRefreshTimeStamp.current = null;
  }, []);

  useEffect(() => {
    if (!dashboardView) {
      dispatch(getAppointmentList.pending(pageInfo));
    }
  }, [pageInfo, dispatch, dashboardView]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp !== prevRefreshTimeStamp.current) {
      //check the previous ref with current state
      prevRefreshTimeStamp.current = refreshTimeStamp;
      dispatch(getAppointmentList.pending(pageInfo));
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp === prevRefreshTimeStamp.current) {
      //update the newest time after calling api
      prevRefreshTimeStamp.current = moment();
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  // useEffect(() => {
  //   if (
  //     visitOrder.isLoadingSaveStoreOrder !== prevSaveVisitStoreStatus.current
  //   ) {
  //     //check the previous ref with current state
  //     prevSaveVisitStoreStatus.current = visitOrder.isLoadingSaveStoreOrder;

  //     if (
  //       visitOrder.postVisitSaveStoreOrderIsSuccess === true &&
  //       visitOrder.isLoadingSaveStoreOrder === false
  //     ) {
  //       setSaveOrderSuccessAlarm(true);
  //     }

  //     if (
  //       visitOrder.postVisitSaveStoreOrderIsSuccess === false &&
  //       visitOrder.isLoadingSaveStoreOrder === false
  //     ) {
  //       setSaveOrderSuccessAlarm(false);
  //     }
  //   }
  // }, [visitOrder]);

  if (
    appointment.getAppointmentListIsSuccess === false &&
    appointment.isLoadingAppointmentList === false
  ) {
    return (
      <Alert severity="error">{appointment.error.appointmentListError}</Alert>
    );
  } else if (
    appointment.getAppointmentListIsSuccess === true &&
    appointment.isLoadingAppointmentList === false
  ) {
    return (
      <>
        {/* <Collapse in={saveOrderSuccessAlarm}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSaveOrderSuccessAlarm(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {t("translation:Order has been saved")}
        </Alert>
      </Collapse> */}

        {confirmAddOrderOpen && (
          <Paper className={classes.paper2} elevation={3}>
            <AddOrderPage
              confirmAddOrderOpen={confirmAddOrderOpen}
              setConfirmAddOrderOpen={handleCloseConfirmAddandRefresh}
              defaultPatientSearch={
                selectedValue.staff
                  ? selectedValue.staff
                  : selectedValue.temp_user
              }
              appointmentmentId={selectedValue.id}
              defaultPhysician={selectedValue.physician}
              accessMode={true}
            />
          </Paper>
        )}

        {!confirmAddOrderOpen && (
          <SortingSearchTable
            searchPlaceholder={t("translation:Name/ Physician")}
            columns={columns}
            searchValue={searchValue}
            data={
              appointment.appointmentList
                ? appointment.appointmentList.appointments
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              appointment.appointmentList
                ? appointment.appointmentList.total_pages
                : 0
            }
            loading={appointment.isLoadingAppointmentList}
            totalItems={
              appointment.appointmentList
                ? appointment.appointmentList.total_items
                : 0
            }
            statusPlaceholder={t("translation:Order Status")}
            statusOption={common.generalStatusList.status_general}
            handleSearch={handleSearch}
            disabledSearch={disabledTableSearch}
            searchDate={true}
            searchDatePlaceholder={t("translation:Date")}
            localStorageName={localStorageNameKey}
          />
        )}
      </>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
