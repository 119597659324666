import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Link } from "@mui/material";
import Alert from "@mui/material/Alert";
import Print from "@mui/icons-material/Print";
import {
  getReportingExportBillAbleIMCByVisit,
  getReportingBillAbleIMCByVisit,
} from "../../../../actions/reportingActions";
import Panel from "../../../../components/Panel";

import BillAbleMedicineCostingInvoiceReportPDF from "../../../../components/PDF/BillAbleMedicineCostingInvoiceReportPDF";
//style
import { useStyles } from "../../../../components/globalStyles";
export default function ExportMedicineCostingInvoiceSummaryByVisit() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reporting = useSelector((state) => state.reporting);

  const common = useSelector((state) => state.common);
  const [printDetail, setPrintDetail] = useState(0);

  const prevSyncStatus = useRef();

  useEffect(() => {
    //set ref when first render
    prevSyncStatus.current = false;
  }, []);

  return (
    <Panel heading={t("translation:Billable Visit Cost Invoice Report")}>
      <Grid item xs={12}>
        {reporting.getReportingExportBillAbleIMCByVisitIsSuccess === false &&
          reporting.isLoadingGetReportingExportBillAbleIMCByVisit === false && (
            <Alert severity="error">
              {reporting.error.getReportingExportBillAbleIMCByVisitError}
            </Alert>
          )}

        {reporting.getReportingBillAbleIMCByVisitIsSuccess === false &&
          reporting.isLoadingGetReportingBillAbleIMCByVisit === false && (
            <Alert severity="error">
              {reporting.error.getReportingBillAbleIMCByVisitError}
            </Alert>
          )}

        {reporting.isLoadingGetReportingBillAbleIMCByVisit &&
        common.generalStatusList ? (
          <>{t("translation:Loading")}... </>
        ) : (
          <Link
            className={classes.boldMaroon075}
            onClick={() => dispatch(getReportingBillAbleIMCByVisit.pending())}>
            <Button
              title={t("translation:PRINT REPORT")}
              onClick={() => {
                setPrintDetail(new Date().valueOf());
              }}
              className={classes.grey}>
              <Print />

              {printDetail !== 0 && (
                <BillAbleMedicineCostingInvoiceReportPDF
                  orderDetailInfo={reporting.getReportingBillAbleIMCByVisit}
                  title={"Billable Visit Cost Invoice Receipt Report"}
                  t={t}
                  statusOption={common.generalStatusList.status_general}
                  print={printDetail}
                />
              )}
            </Button>
          </Link>
        )}

        {reporting.isLoadingGetReportingExportBillAbleIMCByVisit ? (
          <>{t("translation:Loading")}... </>
        ) : (
          <Link
            className={classes.boldMaroon075}
            onClick={() =>
              dispatch(getReportingExportBillAbleIMCByVisit.pending())
            }>
            <Button className={classes.boldMaroon075} name="synButton">
              {t("translation:Download CSV FILE")}
            </Button>
          </Link>
        )}
      </Grid>
    </Panel>
  );
}
