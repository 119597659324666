import React from "react";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import {
  useStyles,
  noAvailableColorStyle,
} from "../../../components/globalTableStyles";
import EditableCell from "../../../components/EditableCell";
import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
//components

function Table({ columns, data, viewOnly }) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    <TableCell
                      className={paginationClasses.narrowCellStyle}
                      style={
                        row.original.branch_medicine || viewOnly
                          ? {}
                          : noAvailableColorStyle
                      }>
                      {i + 1}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={paginationClasses.narrowCellStyle}
                          style={
                            row.original.branch_medicine || viewOnly
                              ? {}
                              : noAvailableColorStyle
                          }
                          {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: cell.column.editable,
                            editableNum: cell.column.editableNum,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>

          <caption>
            <span className={paginationClasses.noAvailableColorCaption}>
              {t("translation:Blue - Not available")}{" "}
            </span>
          </caption>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const MedicineListTable = ({
  data = {},
  setSelectedValue,
  setEditMedicineDialog,
  measureUnit,
  isRawHerb = false,
  viewOnly,
}) => {
  const { t } = useTranslation();
  const handleRowEdit = (value) => {
    setSelectedValue(value);
    setEditMedicineDialog(true);
  };
  const columns = [
    {
      Header: "",
      accessor: "statusAction",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) => {
        if (viewOnly) {
          return <></>;
        } else {
          return (
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          );
        }
      },
    },
    {
      Header: t("translation:Order Medicine Name"),
      accessor: "medicine_name",
    },
    {
      Header: t("translation:Medicine Name"),
      accessor: "branch_medicine.medicines.name",
      Cell: ({ cell }) => {
        return cell.value ? cell.value : "";
      },
    },
    {
      Header: t("translation:Quantity / Day"),
      accessor: "quantity_per_day",
    },
  ];

  return <Table columns={columns} data={data} viewOnly={viewOnly} />;
};

export default MedicineListTable;
