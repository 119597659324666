import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FormulaMedicineSummaryPage from "./FormulaMedicineSummary/FormulaMedicineSummaryPage";
export default function FormulaMedicinePage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/formulaMedicine"
        to="/formulaMedicine/formulaMedicineSummary"
      />
      <Route
        path="/formulaMedicine/formulaMedicineSummary"
        component={FormulaMedicineSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
