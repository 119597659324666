export const CONST_NOINTERNET =
  "No Internet Connection. Please Check Internet Connection and Try Again.";

export const CONST_NOINTERNET_CH = "无法连接网络. 请检查网络后再尝试.";

export const CONST_SOMETHINGWRONG = "Something went wrong. Please Try Again.";

export const CONST_SOMETHINGWRONG_CH = "出了些问题. 请再尝试.";

export const CONST_RELOGIN = "Please Re-sign In and Try Again.";
