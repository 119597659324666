//lib
import React from "react";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Close from "@mui/icons-material/Close";

//components
import EditableCell from "../../../../components/EditableCell";
import * as generalConstants from "../../../../_constants/generalConstants";
import { useStyles } from "../../../../components/globalTableStyles";
import organisationPriceRounding from "../../../../components/functions/organisationPriceRounding";

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({ columns, data, t }) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();

  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    <TableCell className={paginationClasses.narrowCellStyle}>
                      {i + 1}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={paginationClasses.narrowCellStyle}
                          {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: cell.column.editable,
                            editableNum: cell.column.editableNum,
                            editableWholeNum: cell.column.editableWholeNum,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const MedicineListDetailTable = ({
  details_price_rmt = generalConstants.ROUND_OFF_RMT,
  details_price_rpvt = generalConstants.TEN_THOUSANDTH_RPVT,

  data = [],
  t,
  open,
  setOpen,
}) => {
  const columns = [
    {
      Header: t("translation:New Cost Price/ Unit"),
      sortable: false,
      accessor: "medicine_in_out.new_unit_cost_price",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },
    {
      Header: t("translation:New Selling Price/ Unit"),
      sortable: false,
      accessor: "medicine_in_out.new_unit_selling_price",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },

    {
      Header: t("translation:Old Cost Price/ Unit"),
      sortable: false,
      accessor: "medicine_in_out.old_unit_cost_price",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },
    {
      Header: t("translation:Old Selling Price/ Unit"),
      sortable: false,
      accessor: "medicine_in_out.old_unit_selling_price",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },

    {
      Header: t("translation:Received Quantity"),
      sortable: false,
      accessor: "qty_received",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : "";
      },
    },
    {
      Header: t("translation:Quantity after Change"),
      sortable: false,
      accessor: "medicine_in_out.qty_after_change",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : "";
      },
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setOpen(false);
        }
      }}
      aria-labelledby="confirm-dialog"
      fullWidth
      maxWidth="xl">
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => setOpen(false)}
      />
      <DialogContent>
        <Table columns={columns} data={data} t={t} />{" "}
      </DialogContent>
    </Dialog>
  );
};

export default MedicineListDetailTable;
