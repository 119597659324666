import * as types from "../_constants/userMfaConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_USERMFALIST_ASYNC.PENDING:
      return {
        ...state,
        getUserMfaListIsSuccess: false,
        isLoadingGetUserMfaListApi: true,
        getUserMfaList: null,
      };

    case types.GET_USERMFALIST_ASYNC.ERROR:
      return {
        ...state,
        getUserMfaListIsSuccess: false,
        isLoadingGetUserMfaListApi: false,
        getUserMfaList: null,
        error: { ...state.error, getUserMfaListError: action.error },
      };

    case types.GET_USERMFALIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserMfaListIsSuccess: true,
        isLoadingGetUserMfaListApi: false,
        getUserMfaList: action.data,
      };

    case types.GET_USERMFABYID_ASYNC.PENDING:
      return {
        ...state,
        getUserMfaByIdIsSuccess: false,
        isLoadingGetUserMfaById: true,
        getUserMfaById: null,
      };

    case types.GET_USERMFABYID_ASYNC.ERROR:
      return {
        ...state,
        getUserMfaByIdIsSuccess: false,
        isLoadingGetUserMfaById: false,
        getUserMfaById: null,
        error: { ...state.error, getUserMfaByIdError: action.error },
      };

    case types.GET_USERMFABYID_ASYNC.SUCCESS:
      return {
        ...state,
        getUserMfaByIdIsSuccess: true,
        isLoadingGetUserMfaById: false,
        getUserMfaById: action.data,
      };

    case types.PUT_UPDATEUSERMFA_ASYNC.PENDING:
      return {
        ...state,
        putUpdateUserMfaIsSuccess: false,
        isLoadingPutUpdateUserMfa: true,
        putUpdateUserMfa: null,
      };

    case types.PUT_UPDATEUSERMFA_ASYNC.ERROR:
      return {
        ...state,
        putUpdateUserMfaIsSuccess: false,
        isLoadingPutUpdateUserMfa: false,
        putUpdateUserMfa: null,
        error: { ...state.error, putUpdateUserMfaError: action.error },
      };

    case types.PUT_UPDATEUSERMFA_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateUserMfaIsSuccess: true,
        isLoadingPutUpdateUserMfa: false,
        putUpdateUserMfa: action.data,
      };

    case types.GET_RANDOMMFASECRETKEY_ASYNC.PENDING:
      return {
        ...state,
        getRandomMfaSecretKeyIsSuccess: false,
        isLoadingGetRandomMfaSecretKey: true,
        getRandomMfaSecretKey: null,
      };

    case types.GET_RANDOMMFASECRETKEY_ASYNC.ERROR:
      return {
        ...state,
        getRandomMfaSecretKeyIsSuccess: false,
        isLoadingGetRandomMfaSecretKey: false,
        getRandomMfaSecretKey: null,
        error: { ...state.error, getRandomMfaSecretKeyError: action.error },
      };

    case types.GET_RANDOMMFASECRETKEY_ASYNC.SUCCESS:
      return {
        ...state,
        getRandomMfaSecretKeyIsSuccess: true,
        isLoadingGetRandomMfaSecretKey: false,
        getRandomMfaSecretKey: action.data,
      };

    case types.POST_VERIFYMFASECRETKEY_ASYNC.PENDING:
      return {
        ...state,
        postVerifyMfaSecretKeyIsSuccess: false,
        isLoadingPostVerifyMfaSecretKey: true,
        postVerifyMfaSecretKey: null,
      };

    case types.POST_VERIFYMFASECRETKEY_ASYNC.ERROR:
      return {
        ...state,
        postVerifyMfaSecretKeyIsSuccess: false,
        isLoadingPostVerifyMfaSecretKey: false,
        postVerifyMfaSecretKey: null,
        error: { ...state.error, postVerifyMfaSecretKeyError: action.error },
      };

    case types.POST_VERIFYMFASECRETKEY_ASYNC.SUCCESS:
      return {
        ...state,
        postVerifyMfaSecretKeyIsSuccess: true,
        isLoadingPostVerifyMfaSecretKey: false,
        postVerifyMfaSecretKey: action.data,
      };

    case types.GET_OTPPROVISIONINGURL_ASYNC.PENDING:
      return {
        ...state,
        getOtpProvisioningUrlIsSuccess: false,
        isLoadingGetOtpProvisioningUrl: true,
        getOtpProvisioningUrl: null,
      };

    case types.GET_OTPPROVISIONINGURL_ASYNC.ERROR:
      return {
        ...state,
        getOtpProvisioningUrlIsSuccess: false,
        isLoadingGetOtpProvisioningUrl: false,
        getOtpProvisioningUrl: null,
        error: { ...state.error, getOtpProvisioningUrlError: action.error },
      };

    case types.GET_OTPPROVISIONINGURL_ASYNC.SUCCESS:
      return {
        ...state,
        getOtpProvisioningUrlIsSuccess: true,
        isLoadingGetOtpProvisioningUrl: false,
        getOtpProvisioningUrl: action.data,
      };

    case types.POST_VERIFYOTPTOKEN_ASYNC.PENDING:
      return {
        ...state,
        postVerifyOtpTokenIsSuccess: false,
        isLoadingPostVerifyOtpToken: true,
        postVerifyOtpToken: null,
      };

    case types.POST_VERIFYOTPTOKEN_ASYNC.ERROR:
      return {
        ...state,
        postVerifyOtpTokenIsSuccess: false,
        isLoadingPostVerifyOtpToken: false,
        postVerifyOtpToken: null,
        error: { ...state.error, postVerifyOtpTokenError: action.error },
      };

    case types.POST_VERIFYOTPTOKEN_ASYNC.SUCCESS:
      return {
        ...state,
        postVerifyOtpTokenIsSuccess: true,
        isLoadingPostVerifyOtpToken: false,
        postVerifyOtpToken: action.data,
      };

    case types.GET_USERMFAUSERLIST_ASYNC.PENDING:
      return {
        ...state,
        getUserMfaUserListIsSuccess: false,
        isLodingGetUserMfaUserList: true,
        getUserMfaUserList: null,
      };

    case types.GET_USERMFAUSERLIST_ASYNC.ERROR:
      return {
        ...state,
        getUserMfaUserListIsSuccess: false,
        isLodingGetUserMfaUserList: false,
        getUserMfaUserList: null,
        error: { ...state.error, getUserMfaUserListError: action.error },
      };

    case types.GET_USERMFAUSERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserMfaUserListIsSuccess: true,
        isLodingGetUserMfaUserList: false,
        getUserMfaUserList: action.data,
      };

    default:
      return state;
  }
};
