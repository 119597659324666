//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button, Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
//Actions
import {
  getNonBusinessDayList,
  putUpdateNonBusinessDay,
} from "../../../../actions/nonBusinessDayActions";

//Component
import Panel from "../../../../components/Panel";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { useStyles } from "../../../../components/globalStyles";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";
import AddAdhocModal from "./components/AddAdhocModal";
import SingaporeHolidayListTable from "./components/SingaporeHolidayListTable";
import ConfirmDialog from "../../../../components/ConfirmDialog";
export default function NonBusinessDayBranchPage({
  branchId,
  setSummaryView,
  viewOnly = false,
}) {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const prevPutUpdate = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [editNonBusinessDay, setEditNonBusinessDay] = useState(null);
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const [open, setOpen] = useState(false);
  const curUser = useSelector((state) => state.curUser);
  const common = useSelector((state) => state.common);
  const [confirmSubmissionList, setConfirmSubmissionList] = useState(null);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_NON_BUSINESS_DAY_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: t("translation:Name"),
      accessor: "name",
      sortable: false,
    },
    {
      Header: t("translation:Closed Date"),
      accessor: "closed_date",
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.closed_date)
          return moment
            .utc(row.original.closed_date)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },
    },
    {
      Header: t("translation:Closed Day"),
      accessor: "closed_day",
      sortable: false,
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const updateColumns = [
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
      sortable: false,
    },
    {
      Header: t("translation:Closed Date"),
      accessor: "closed_date",
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.closed_date)
          return moment
            .utc(row.original.closed_date)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },
    },
    {
      Header: t("translation:Closed Day"),
      accessor: "closed_day",
      sortable: false,
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_branch_desc_value: null,
    search_branch_id_value: branchId,
    search_branch_name_value: null,
    search_desc_value: null,
    search_id_value: null,
    search_name_value: null,
    search_organisation_id_value: null,
    search_organisation_name_value: null,
    search_status_type_value: null,
  });

  const dispatch = useDispatch();

  const nonBusinessDay = useSelector((state) => state.nonBusinessDay);

  //Init
  const [searchValue, setSearchValue] = React.useState("");

  //Onclick event
  const handleRowEdit = (value) => {
    setEditNonBusinessDay(value);
    setOpenModal(true);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: null,
      });
    }
  };

  const handlePostResult = (result) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    dispatch(putUpdateNonBusinessDay.pending(result));
  };

  const onConfirmSubmit = () => {
    setOpen(false);
    const confirmJson = confirmSubmissionList.map((item) => {
      return {
        id: 0,
        closed_date: item.date,
        closed_day: item.day,
        name: item.holiday,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
      };
    });

    const submitJson = {
      branch: { id: branchId, non_business_days: confirmJson },
    };
    dispatch(putUpdateNonBusinessDay.pending(submitJson));
  };
  useEffect(() => {
    //set ref when first render
    prevPutUpdate.current = false;
  }, []);

  useEffect(() => {
    if (
      nonBusinessDay.isLoadingPutUpdateNonBusinessDay !== prevPutUpdate.current
    ) {
      //check the previous ref with current state
      prevPutUpdate.current = nonBusinessDay.isLoadingPutUpdateNonBusinessDay;

      if (
        nonBusinessDay.putUpdateNonBusinessDayIsSuccess === true &&
        nonBusinessDay.isLoadingPutUpdateNonBusinessDay === false
      ) {
        dispatch(getNonBusinessDayList.pending(pageInfo));
        setSuccessAlarm(true);
        setErrorAlarm(false);
        window.scrollTo(0, 0);
      }
      if (
        nonBusinessDay.putUpdateNonBusinessDayIsSuccess === false &&
        nonBusinessDay.isLoadingPutUpdateNonBusinessDay === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(nonBusinessDay.error.putUpdateNonBusinessDayError);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, pageInfo, nonBusinessDay]);

  useEffect(() => {
    dispatch(getNonBusinessDayList.pending(pageInfo));
  }, [dispatch, pageInfo]);

  if (
    nonBusinessDay.getNonBusinessDayListIsSuccess === false &&
    nonBusinessDay.isLoadingGetNonBusinessDayList === false
  ) {
    return (
      <Alert severity="error">
        {nonBusinessDay.error.getNonBusinessDayListError}
      </Alert>
    );
  } else if (
    common.generalStatusList &&
    nonBusinessDay.getNonBusinessDayListIsSuccess === true &&
    nonBusinessDay.isLoadingGetNonBusinessDayList === false
  ) {
    return (
      <>
        <Collapse in={successAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Non Business Day has been updated")}
          </Alert>
        </Collapse>

        <Collapse in={errorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        {openModal && (
          <AddAdhocModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            branchId={branchId}
            editNonBusinessDay={editNonBusinessDay}
            setEditNonBusinessDay={setEditNonBusinessDay}
            handleUpdate={handlePostResult}
          />
        )}
        <Panel heading={t("translation:Edit Non Business Day")}>
          <Grid className={classes.root} container justifyContent="flex-end">
            {!viewOnly && (
              <Grid item xs={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonColor}
                  onClick={() => {
                    setEditNonBusinessDay(null);
                    setOpenModal(true);
                  }}>
                  {t("translation:Add")}
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                onClick={() => setOpen(true)}>
                {t("translation:Singapore Holiday")}
              </Button>
            </Link>
          </Grid>

          {open && (
            <ConfirmDialog
              Yes={t("translation:UPDATE")}
              No={t("translation:BACK")}
              title={t("translation:Singapore Holiday")}
              open={open}
              setOpen={setOpen}
              maxWidth="lg"
              onConfirm={onConfirmSubmit}
              submitWithClose={false}>
              <SingaporeHolidayListTable
                setConfirmSubmissionList={setConfirmSubmissionList}
              />
            </ConfirmDialog>
          )}

          <SortingSearchTable
            tableHeading={""}
            searchPlaceholder={t("translation:Name")}
            searchValue={searchValue}
            columns={viewOnly ? columns : updateColumns}
            statusPlaceholder={t("translation:Status Type")}
            statusOption={common.generalStatusList.status_general}
            banStatusIdList={[]}
            data={
              nonBusinessDay.getNonBusinessDayList?.non_business_days !==
              undefined
                ? nonBusinessDay.getNonBusinessDayList?.non_business_days
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              nonBusinessDay.getNonBusinessDayList
                ? nonBusinessDay.getNonBusinessDayList.total_pages
                : 0
            }
            loading={nonBusinessDay.isLoadingGetNonBusinessDayList}
            totalItems={
              nonBusinessDay.getNonBusinessDayList
                ? nonBusinessDay.getNonBusinessDayList.total_items
                : 0
            }
            handleSearch={handleSearch}
          />
          <Grid className={classes.root} container>
            <PageOnlyBackAction setView={() => setSummaryView(false)} />
          </Grid>
        </Panel>
      </>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
