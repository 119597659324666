//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
//import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//components
import InvoiceMedicineCostingListTable from "./InvoiceMedicineCostingListTable";
import { useStyles } from "../../../components/globalStyles";
//actions

const InvoiceMedicineCostingSummaryPage = () => {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  //display and hide the "brewing summary" table
  const [informationView, setInformationView] = React.useState(false);

  //useEffects

  // const workOrder = useSelector((state) => state.workOrder);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!informationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title} variant="h3" gutterBottom>
              {t("translation:Invoice Medicine Costing Summary")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <hr />

      <InvoiceMedicineCostingListTable
        setInformationView={setInformationView}
      />
    </Paper>
  );
};

export default InvoiceMedicineCostingSummaryPage;
