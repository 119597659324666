import React from "react";
import { Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import StyledCheckbox from "../../../../../components/StyledCheckbox";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../components/globalStyles";
import EditDeliveryAddressDialog from "../../../Delivery/components/EditDeliveryAddressDialog";
import * as generalConstants from "../../../../../_constants/generalConstants";
const ViewDelivery = ({
  memoSelectedGroup,
  editDeliveryOrder = false,
  orderDetailInfo = null,
  onlyViewDeliveryRemarks = false,
  setGroupOption = () => null,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container>
      {editDeliveryOrder && (
        <EditDeliveryAddressDialog
          memoSelectedGroup={memoSelectedGroup}
          orderDetailInfo={orderDetailInfo}
          setGroupOption={setGroupOption}
        />
      )}

      {memoSelectedGroup.is_delivery_services === true && (
        <Grid item xs={12} className={classes.userInfoField}>
          <FormControlLabel
            control={
              <StyledCheckbox
                disabled
                checked={true}
                name="is_delivery_services"
              />
            }
            label={t("translation:Delivery Services")}
          />
        </Grid>
      )}
      {!onlyViewDeliveryRemarks && (
        <>
          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_name
                  ? memoSelectedGroup.delivery_name
                  : ""
              }
              name="delivery_name"
              label={t("translation:Recipient")}
              required
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_contact_number
                  ? memoSelectedGroup.delivery_contact_number
                  : ""
              }
              name="delivery_contact_number"
              required
              label={t("translation:Contact Number")}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_postal_code
                  ? memoSelectedGroup.delivery_postal_code
                  : ""
              }
              name="delivery_postal_code"
              label={t("translation:Postal Code")}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_address_details
                  ? memoSelectedGroup.delivery_address_details
                  : ""
              }
              name="delivery_address_details"
              placeholder={t("translation:Floor/ Unit Number")}
              variant="outlined"
              label={t("translation:Address Details")}
            />
          </Grid>

          {memoSelectedGroup.delivery_blk_no ? (
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                disabled
                value={memoSelectedGroup.delivery_blk_no}
                name="blk_no"
                label={t("translation:Block No")}
                variant="outlined"
              />
            </Grid>
          ) : (
            <></>
          )}

          {memoSelectedGroup.delivery_road_name ? (
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                disabled
                value={memoSelectedGroup.delivery_road_name}
                name="road_name"
                label={t("translation:Road Name")}
                variant="outlined"
              />
            </Grid>
          ) : (
            <></>
          )}

          {memoSelectedGroup.delivery_building ? (
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                disabled
                value={memoSelectedGroup.delivery_building}
                name="building"
                label={t("translation:Building")}
                variant="outlined"
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_address
                  ? memoSelectedGroup.delivery_address
                  : ""
              }
              required
              multiline
              rows={3}
              name="delivery_address"
              label={t("translation:Address")}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled
          className={classes.userFieldColor}
          value={
            memoSelectedGroup.delivery_remarks
              ? memoSelectedGroup.delivery_remarks
              : ""
          }
          multiline
          rows={3}
          name="delivery_remarks"
          label={t("translation:Remarks")}
          variant="outlined"
        />
      </Grid>
      {/* <Grid item xs={3} className={classes.userInfoField}>
        <FormControlLabel
          control={
            <StyledCheckbox
              disabled
              checked={
                memoSelectedGroup.delivery_incl_prescription ? true : false
              }
              name="delivery_incl_prescription"
            />
          }
          label={t("translation:Print Prescription")}
        />
      </Grid> */}

      {memoSelectedGroup?.delivery_owner?.some(
        (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
      ) && (
        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            options={[]}
            disabled
            multiple
            value={memoSelectedGroup?.delivery_owner?.filter(
              (item) =>
                item.status_type === generalConstants.APPROVED_STATUS_TYPE
            )}
            style={{ width: "100%" }}
            renderTags={(value, props) =>
              value.map((option, index) => (
                <Chip
                  label={
                    option.store_medicine_header.store_header.order_number +
                    "/" +
                    option.store_medicine_header.name
                  }
                  key={index}
                  clickable={false}
                />
              ))
            }
            renderInput={(params) => {
              const InputProps = { ...params.InputProps };
              InputProps.endAdornment = null;
              return (
                <TextField
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Linked from order/ group")}
                  variant="outlined"
                  InputProps={InputProps}
                />
              );
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default ViewDelivery;
