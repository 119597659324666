//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@mui/material";
//actions
import {
  putDeliveryTrackingWIPMode,
  getDeliveryTrackingSummaryList,
  putDeliveryTrackingPendingStatus,
  getDeliveryTrackingById,
  putDeliveryTrackingCloseStatus,
} from "../../../actions/deliveryTrackingActions";
//components
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import { ReactComponent as ProceedLogo } from "../../../assets/coolicons/media/fast_forward.svg";
import { ReactComponent as PendingLogo } from "../../../assets/coolicons/media/fast_rewind.svg";
import { ReactComponent as ProceedAllLogo } from "../../../assets/coolicons/media/skip_next.svg";
import OrderDetailPage from "./OrderDetailPage";
import * as pageConfigure from "../../../_constants/pageConstants";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import * as generalConstants from "../../../_constants/generalConstants";

import { getTableDefaultLocalStorage } from "../../../components/functions/localStorage";

export default function DeliveryTrackingListTable({
  setDeliveryTrackingInformationView = () => null,
  setRefreshTimeStamp = () => null,
  refreshTimeStamp = null,
  dashboardView = false,
}) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const dispatch = useDispatch();
  const prevGetDeliveryTrackingByIdOnStatus = useRef();
  const deliveryTracking = useSelector((state) => state.deliveryTracking);
  const common = useSelector((state) => state.common);
  const localStorageNameKey = "deliveryTrackingListTable";
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_DELIVERY_TRACKING_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;

  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: t("translation:Delivery Order No"),
      accessor: "delivery_order_header.order_number",
    },
    {
      Header: t("translation:Recipient"),
      accessor: "delivery_name",
    },
    {
      Header: t("translation:Courier Name"),
      accessor: "delivery_order_header.courier_name",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const columnsWUpdateAccess = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "statusAction",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) => {
        if (row.original.status_type === generalConstants.PENDING_STATUS_TYPE) {
          return (
            <Button onClick={() => handleRowProcess(row.original)}>
              <ProceedLogo title={t("translation:Proceed")} />
            </Button>
          );
        } else {
          return row.original.status_type ===
            generalConstants.WIP_STATUS_TYPE ? (
            <Button onClick={() => handleRowProcessDetail(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          ) : (
            <></>
          );
        }
      },
    },
    {
      Header: "",
      accessor: "Pending",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowPending(row.original)}>
              <PendingLogo title={t("translation:Pending")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },

    {
      Header: "",
      accessor: "ProcessAll",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowProcessAll(row.original)}>
              <ProceedAllLogo title={t("translation:Close")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Delivery Order No"),
      accessor: "delivery_order_header.order_number",
    },
    {
      Header: t("translation:Recipient"),
      accessor: "delivery_name",
    },
    {
      Header: t("translation:Courier Name"),
      accessor: "delivery_order_header.courier_name",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) =>
        size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
    search_courier_id_value: null,
    search_courier_name_value: null,
    search_delivery_order_id_value: null,
    search_delivery_order_number_value: null,
    search_delivery_tracking_order_id_value: null,
    search_end_date_value: null,
    search_end_timestamp_value: null,
    search_start_date_value: null,
    search_start_timestamp_value: null,
    search_status_type_value: null,
  });

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmPendingOpen, setConfirmPendingOpen] = useState(false);
  const [confirmProcessAllOpen, setConfirmProceeAllOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [orderDetailView, setOrderDetailView] = React.useState(false);
  const [orderProcessView, setOrderProcessView] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [orderDetailInfo, setOrderDetailInfo] = React.useState(null);

  const prevWorkOrderWIPStatus = useRef();
  const prevWorkOrderPendingStatus = useRef();
  const prevSubmitDeliveryTrackingStatus = useRef();
  const prevWorkOrderCloseStatus = useRef();
  const prevRefreshTimeStamp = useRef();
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = React.useState([]);
  const [WIPSuccessAlarm, setWIPSuccessAlarm] = useState(false);
  const [WIPErrorAlarm, setWIPErrorAlarm] = useState(false);
  const [WIPmsgAlarm, setWIPMsgAlarm] = React.useState([]);
  const [PendingSuccessAlarm, setPendingSuccessAlarm] = useState(false);
  const [PendingErrorAlarm, setPendingErrorAlarm] = useState(false);
  const [PendingmsgAlarm, setPendingMsgAlarm] = React.useState([]);

  //functions
  const handleRowProcess = (value) => {
    setConfirmOpen(true);
    setSelectedValue(value);
  };

  const handleRowPending = (value) => {
    setConfirmPendingOpen(true);
    setSelectedValue(value);
  };

  const handleRowProcessAll = (value) => {
    setConfirmProceeAllOpen(true);
    setSelectedValue(value);
  };

  const onSubmitDeliveryTracking = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJSON = {
      delivery_tracking_header: {
        delivery_order_header: {
          id: selectedValue.delivery_order_header.id,
        },
        id: selectedValue.id,
      },
    };

    dispatch(putDeliveryTrackingCloseStatus.pending(submitJSON));
  };

  const handleConfirmProcess = () => {
    dispatch(putDeliveryTrackingWIPMode.pending(selectedValue.id));
  };

  const handleConfirmPending = () => {
    dispatch(putDeliveryTrackingPendingStatus.pending(selectedValue.id));
  };

  const handleRowDetail = (value) => {
    //
    //setOrderDetailInfo(value);
    dispatch(getDeliveryTrackingById.pending(value.id));
    setOrderDetailView(true);
    setSubmitErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setWIPSuccessAlarm(false);
    setPendingErrorAlarm(false);
    setPendingSuccessAlarm(false);
  };

  const handleRowProcessDetail = (value) => {
    dispatch(getDeliveryTrackingById.pending(value.id));
    setOrderProcessView(true);
    setSubmitErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setWIPSuccessAlarm(false);
    setPendingErrorAlarm(false);
    setPendingSuccessAlarm(false);
  };

  //useEffects
  useEffect(() => {
    prevWorkOrderWIPStatus.current = false;
    prevWorkOrderPendingStatus.current = false;
    prevSubmitDeliveryTrackingStatus.current = false;
    prevGetDeliveryTrackingByIdOnStatus.current = false;
    prevWorkOrderCloseStatus.current = false;
    prevRefreshTimeStamp.current = null;
  }, []);

  useEffect(() => {
    if (
      refreshTimeStamp !== prevRefreshTimeStamp.current &&
      dashboardView &&
      !orderDetailView
    ) {
      //check the previous ref with current state
      prevRefreshTimeStamp.current = refreshTimeStamp;
      dispatch(getDeliveryTrackingSummaryList.pending(pageInfo));
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView, orderDetailView]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp === prevRefreshTimeStamp.current) {
      //update the newest time after calling api
      prevRefreshTimeStamp.current = moment();
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  useEffect(() => {
    if (
      deliveryTracking.isLoadingPutDeliveryTrackingPendingStatus !==
      prevWorkOrderPendingStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderPendingStatus.current =
        deliveryTracking.isLoadingPutDeliveryTrackingPendingStatus;

      if (
        deliveryTracking.putDeliveryTrackingPendingStatusIsSuccess === true &&
        deliveryTracking.isLoadingPutDeliveryTrackingPendingStatus === false
      ) {
        window.scrollTo(0, 0);
        setPendingErrorAlarm(false);
        setPendingSuccessAlarm(true);
        dispatch(getDeliveryTrackingSummaryList.pending(pageInfo));
      }

      if (
        deliveryTracking.putDeliveryTrackingPendingStatusIsSuccess === false &&
        deliveryTracking.isLoadingPutDeliveryTrackingPendingStatus === false
      ) {
        window.scrollTo(0, 0);
        setPendingErrorAlarm(true);
        setPendingSuccessAlarm(false);
        setPendingMsgAlarm(
          deliveryTracking.error.putDeliveryTrackingPendingStatusError
        );
      }
    }
  }, [dispatch, deliveryTracking, pageInfo]);

  useEffect(() => {
    if (
      deliveryTracking.isLoadingPutDeliveryTrackingWIPMode !==
      prevWorkOrderWIPStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderWIPStatus.current =
        deliveryTracking.isLoadingPutDeliveryTrackingWIPMode;

      if (
        deliveryTracking.putDeliveryTrackingWIPModeIsSuccess === true &&
        deliveryTracking.isLoadingPutDeliveryTrackingWIPMode === false
      ) {
        window.scrollTo(0, 0);
        setWIPErrorAlarm(false);
        setWIPSuccessAlarm(true);
        dispatch(getDeliveryTrackingSummaryList.pending(pageInfo));
      }

      if (
        deliveryTracking.putDeliveryTrackingWIPModeIsSuccess === false &&
        deliveryTracking.isLoadingPutDeliveryTrackingWIPMode === false
      ) {
        window.scrollTo(0, 0);
        setWIPErrorAlarm(true);
        setWIPSuccessAlarm(false);
        setWIPMsgAlarm(deliveryTracking.error.putDeliveryTrackingWIPModeError);
      }
    }
  }, [dispatch, deliveryTracking, pageInfo]);
  //

  useEffect(() => {
    if (
      deliveryTracking.isLoadingPutDeliveryTrackingCloseStatus !==
      prevWorkOrderCloseStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderCloseStatus.current =
        deliveryTracking.isLoadingPutDeliveryTrackingCloseStatus;

      if (
        deliveryTracking.putDeliveryTrackingCloseStatusIsSuccess === true &&
        deliveryTracking.isLoadingPutDeliveryTrackingCloseStatus === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        dispatch(getDeliveryTrackingSummaryList.pending(pageInfo));
      }

      if (
        deliveryTracking.putDeliveryTrackingCloseStatusIsSuccess === false &&
        deliveryTracking.isLoadingPutDeliveryTrackingCloseStatus === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(deliveryTracking.error.putDeliveryTrackingCloseStatusError);
      }
    }
  }, [dispatch, deliveryTracking, pageInfo]);

  useEffect(() => {
    if (
      deliveryTracking.isLoadingGetDeliveryTrackingById !==
      prevGetDeliveryTrackingByIdOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevGetDeliveryTrackingByIdOnStatus.current =
        deliveryTracking.isLoadingGetDeliveryTrackingById;

      if (
        deliveryTracking.getDeliveryTrackingByIdIsSuccess === true &&
        deliveryTracking.isLoadingGetDeliveryTrackingById === false
      ) {
        setOrderDetailInfo(
          deliveryTracking.getDeliveryTrackingById.delivery_tracking_header
        );
      }

      if (
        deliveryTracking.getDeliveryTrackingByIdIsSuccess === false &&
        deliveryTracking.isLoadingGetDeliveryTrackingById === false
      ) {
        setOrderDetailInfo([]);
      }
    }
  }, [dispatch, deliveryTracking]);

  useEffect(() => {
    if (orderDetailView === true || orderProcessView === true) {
      setDeliveryTrackingInformationView(true);
    } else {
      setDeliveryTrackingInformationView(false);
    }
  }, [orderDetailView, orderProcessView, setDeliveryTrackingInformationView]);

  useEffect(() => {
    //dispatch(getDispensaryList.pending(pageInfo));

    if (!orderProcessView && !orderDetailView && !dashboardView) {
      dispatch(getDeliveryTrackingSummaryList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, orderDetailView, orderProcessView, dashboardView]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_delivery_order_number_value: value,
        search_courier_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_delivery_order_number_value: null,
        search_courier_name_value: null,
      });
    }
  };

  if (
    deliveryTracking.getDeliveryTrackingSummaryListIsSuccess === false &&
    deliveryTracking.isLoadingDeliveryTrackingSummaryList === false
  ) {
    return (
      <Alert severity="error">
        {deliveryTracking.error.deliveryTrackingSummaryListError}
      </Alert>
    );
  } else if (
    deliveryTracking.getDeliveryTrackingByIdSuccess === false &&
    deliveryTracking.isLoadingDeliveryTrackingById === false &&
    (orderDetailView || orderDetailView)
  ) {
    return (
      <Alert severity="error">
        {deliveryTracking.error.deliveryTrackingByIdError}
      </Alert>
    );
  } else if (
    common.getGeneralStatusIsSuccess === true &&
    !orderDetailView &&
    !orderProcessView
  ) {
    return (
      <>
        <Collapse in={submitSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Delivery Tracking has been closed")}
          </Alert>
        </Collapse>

        <Collapse in={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={WIPSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.WIP_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={WIPErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {WIPmsgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={PendingSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.PENDING_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={PendingErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {PendingmsgAlarm}
          </Alert>
        </Collapse>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmPendingOpen}
          setOpen={setConfirmPendingOpen}
          onConfirm={handleConfirmPending}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmProcessAllOpen}
          setOpen={setConfirmProceeAllOpen}
          onConfirm={onSubmitDeliveryTracking}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <SortingSearchTable
          tableHeading=""
          searchPlaceholder={t(
            "translation:Delivery Tracking Order No/ Courier name"
          )}
          statusPlaceholder={t("translation:Order Status")}
          statusOption={common.generalStatusList.status_general}
          searchValue={searchValue}
          columns={updateAccess ? columnsWUpdateAccess : columns}
          data={
            deliveryTracking.getDeliveryTrackingSummaryList
              ? deliveryTracking.getDeliveryTrackingSummaryList
                  .delivery_tracking_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            deliveryTracking.getDeliveryTrackingSummaryList
              ? deliveryTracking.getDeliveryTrackingSummaryList.total_pages
              : 0
          }
          loading={deliveryTracking.isLoadingGetDeliveryTrackingSummaryList}
          totalItems={
            deliveryTracking.getDeliveryTrackingSummaryList
              ? deliveryTracking.getDeliveryTrackingSummaryList.total_items
              : 0
          }
          handleSearch={handleSearch}
          searchDate={true}
          searchDatePlaceholder={t("translation:Creation Date")}
          localStorageName={localStorageNameKey}
        />
      </>
    );
  } else if (orderDetailView && orderDetailInfo !== null) {
    return (
      <OrderDetailPage
        orderDetailInfo={orderDetailInfo}
        setOrderDetailView={() => {
          setOrderDetailView(false);
          setOrderDetailInfo(null);
          setRefreshTimeStamp(moment());
        }}
      />
    );
  } else if (orderProcessView && orderDetailInfo !== null) {
    return (
      <OrderDetailPage
        orderDetailInfo={orderDetailInfo}
        editMode={true}
        setOrderDetailView={() => {
          setOrderProcessView(false);
          setOrderDetailInfo(null);
          setRefreshTimeStamp(moment());
        }}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
