//lib
import React, { useState, useEffect } from "react";
import { FormControlLabel } from "@mui/material";
import StyledCheckbox from "../components/StyledCheckbox";
import { useTranslation } from "react-i18next";
import { setUserLocalStorage } from "../components/functions/localStorage";
import { getTableDefaultLocalStorage } from "../components/functions/localStorage";
export default function SoundNotification({ audio, play, localStorageName }) {
  const { t } = useTranslation();
  const [handleSoundNotification, setHandleSoundNotification] = useState(
    getTableDefaultLocalStorage(localStorageName)
      ? getTableDefaultLocalStorage(localStorageName)
      : false
  );
  useEffect(() => {
    if (play && handleSoundNotification) {
      const player = new Audio(audio);
      player.play();
    }
  }, [play, handleSoundNotification, audio]);

  return (
    <>
      <FormControlLabel
        control={
          <StyledCheckbox
            checked={
              Boolean(handleSoundNotification)
                ? Boolean(handleSoundNotification)
                : false
            }
            name="checkSoundNotification"
            onChange={() => {
              setHandleSoundNotification(!handleSoundNotification);
              if (localStorageName) {
                setUserLocalStorage(
                  localStorageName,
                  Number(!handleSoundNotification)
                );
              }
            }}
          />
        }
        label={t("translation:Sound Notification")}
      />
    </>
  );
}
