import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
//components
import * as generalConstants from "../../../_constants/generalConstants";

import SocketNotification from "./SocketNotification";

import DispensaryListTable from "../../workOrder/Dispensary/DispensaryListTable";

//styles
export default function DispensaryTable() {
  const { t } = useTranslation();
  //variable

  //variable
  const [refreshTimeStamp, setRefreshTimeStamp] = useState(moment());

  //useEffect

  return (
    <SocketNotification
      cellTitle={t("translation:Dispensary")}
      sockectWorkTypeId={generalConstants.DISPENSARY_WORK_ORDER_TYPE}
      setRefreshTimeStamp={setRefreshTimeStamp}
      localStorageName="dispensaryListTableNotification">
      <DispensaryListTable
        refreshTimeStamp={refreshTimeStamp}
        dashboardView={true}
        setRefreshTimeStamp={setRefreshTimeStamp}
      />
    </SocketNotification>
  );
}
