import * as types from "../_constants/notificationsConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_NOTIFICATIONBYID_ASYNC.PENDING:
      return {
        ...state,
        getNotificationByIdIsSuccess: false,
        isLoadingGetNotificationById: true,
        getNotificationById: null,
      };

    case types.GET_NOTIFICATIONBYID_ASYNC.ERROR:
      return {
        ...state,
        getNotificationByIdIsSuccess: false,
        isLoadingGetNotificationById: false,
        getNotificationById: null,
        error: {
          ...state.error,
          getNotificationByIdError: action.error,
        },
      };

    case types.GET_NOTIFICATIONBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getNotificationByIdIsSuccess: true,
        isLoadingGetNotificationById: false,
        getNotificationById: action.data,
      };

    case types.GET_NOTIFICATIONLIST_ASYNC.PENDING:
      return {
        ...state,
        getNotificationListIsSuccess: false,
        isLoadingGetNotificationList: true,
        getNotificationList: null,
      };

    case types.GET_NOTIFICATIONLIST_ASYNC.ERROR:
      return {
        ...state,
        getNotificationListIsSuccess: false,
        isLoadingGetNotificationList: false,
        getNotificationList: null,
        error: {
          ...state.error,
          getNotificationListError: action.error,
        },
      };

    case types.GET_NOTIFICATIONLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getNotificationListIsSuccess: true,
        isLoadingGetNotificationList: false,
        getNotificationList: action.data,
      };

    case types.POST_CREATENOTIFICATION_ASYNC.PENDING:
      return {
        ...state,
        postCreateNotificationIsSuccess: false,
        isLoadingPostCreateNotification: true,
        postCreateNotification: null,
      };

    case types.POST_CREATENOTIFICATION_ASYNC.ERROR:
      return {
        ...state,
        postCreateNotificationIsSuccess: false,
        isLoadingPostCreateNotification: false,
        postCreateNotification: null,
        error: {
          ...state.error,
          postCreateNotificationError: action.error,
        },
      };

    case types.POST_CREATENOTIFICATION_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateNotificationIsSuccess: true,
        isLoadingPostCreateNotification: false,
        postCreateNotification: action.data,
      };

    case types.PUT_UPDATENOTIFICATION_ASYNC.PENDING:
      return {
        ...state,
        putUpdateNotificationIsSuccess: false,
        isLoadingPutUpdateNotification: true,
        putUpdateNotification: null,
      };

    case types.PUT_UPDATENOTIFICATION_ASYNC.ERROR:
      return {
        ...state,
        putUpdateNotificationIsSuccess: false,
        isLoadingPutUpdateNotification: false,
        putUpdateNotification: null,
        error: {
          ...state.error,
          putUpdateNotificationError: action.error,
        },
      };

    case types.PUT_UPDATENOTIFICATION_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateNotificationIsSuccess: true,
        isLoadingPutUpdateNotification: false,
        putUpdateNotification: action.data,
      };

    default:
      return state;
  }
};
