import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getUserRoleList,
  putEditUserRole,
  getUserRole,
  getUserRoleAllRole,
} from "../actions/userRoleActions";
import * as types from "../_constants/userRoleConstants";
import * as notification from "../_constants/notificationConstants";
import userRoleApis from "../_apis/userRoleApis";
export default function* userRoleSagas() {
  yield all([
    takeLatest(types.GET_USERROLELIST_ASYNC.PENDING, getUserRoleListCall),
  ]);

  yield all([
    takeLatest(types.PUT_EDITUSERROLE_ASYNC.PENDING, putEditUserRoleCall),
  ]);

  yield all([takeLatest(types.GET_USERROLE_ASYNC.PENDING, getUserRoleCall)]);

  yield all([
    takeLatest(types.GET_USERROLEALLROLE_ASYNC.PENDING, getUserRoleAllRoleCall),
  ]);
}

function* getUserRoleListCall(searchModel) {
  try {
    const data = yield call(userRoleApis.getUserRoleListApi, searchModel.data);
    yield put(getUserRoleList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserRoleList.error(error.response?.data?.message));
    else yield put(getUserRoleList.error(notification.CONST_NOINTERNET));
  }
}

function* putEditUserRoleCall(userRoleModel) {
  try {
    const data = yield call(
      userRoleApis.putEditUserRoleApi,
      userRoleModel.data
    );
    yield put(putEditUserRole.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putEditUserRole.error(error.response?.data?.message));
    else yield put(putEditUserRole.error(notification.CONST_NOINTERNET));
  }
}

function* getUserRoleAllRoleCall(searchModel) {
  try {
    const data = yield call(
      userRoleApis.getUserRoleAllRoleApi,
      searchModel.data
    );
    yield put(getUserRoleAllRole.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserRoleAllRole.error(error.response?.data?.message));
    else yield put(getUserRoleAllRole.error(notification.CONST_NOINTERNET));
  }
}

function* getUserRoleCall(searchModel) {
  try {
    const data = yield call(userRoleApis.getUserRoleApi, searchModel.data);
    yield put(getUserRole.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserRole.error(error.response?.data?.message));
    else yield put(getUserRole.error(notification.CONST_NOINTERNET));
  }
}
