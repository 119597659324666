import Radio from "@mui/material/Radio";

//styles

export default function StyledRadioButton(props) {
  return (
    <Radio
      size="small"
      disableRipple
      style={{
        color: "rgba(149, 0, 0,0.9)",
      }}
      inputProps={{ "aria-label": "decorative radiobutton" }}
      {...props}
    />
  );
}
