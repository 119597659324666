import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const formulaApis = {
  getFormulaListApi,
  getFormulaByIdApi,
  postCreateFormulaApi,
  putUpdateFormulaApi,
  getFormulaDispensaryBranchApi,
  getFormulaMedicineTypeByIdApi,
};

async function getFormulaListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(apiConstants.apiGetFormulaList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getFormulaByIdApi(model) {
  const url = apiConstants.apiGetFormulaById + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreateFormulaApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiPostCreateFormula, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateFormulaApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(apiConstants.apiPutUpdateFormula, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getFormulaDispensaryBranchApi() {
  const url = apiConstants.apiGetFormulaDispensaryBranch;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getFormulaMedicineTypeByIdApi(model) {
  const url = apiConstants.apiGetFormulaMedicineTypeById + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default formulaApis;
