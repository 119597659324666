//lib
import React from "react";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../components/globalTableStyles";

//components

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({ columns, data, updateMyData, disablePageResetOnDataChange }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        updateMyData,
        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();
  const { t } = useTranslation();
  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              return (
                prepareRow(row) || (
                  <React.Fragment key={"empty" + rowIndex}>
                    <TableRow
                      key={"empty1" + rowIndex}
                      className={
                        rowIndex % 2 ? paginationClasses.rowBackground : ""
                      }>
                      <TableCell className={paginationClasses.narrowCellStyle}>
                        {rowIndex + 1}
                      </TableCell>
                      {row.cells.map((cell) => {
                        return (
                          <TableCell
                            className={paginationClasses.narrowCellStyle}
                            {...cell.getCellProps()}>
                            {cell.render("Cell", {
                              editable: cell.column.editable,
                              editableNum: cell.column.editableNum,
                            })}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {row.original.detail &&
                      row.original.detail.map((rowCell, detailRowIndex) => {
                        return (
                          <React.Fragment key={"rowCell" + detailRowIndex}>
                            <TableRow
                              key={"rowCells" + detailRowIndex}
                              className={paginationClasses.rowDetailBackground}>
                              {row.cells.map((cell, detailRowCellIndex) => {
                                return (
                                  <TableCell
                                    key={"detailRowCell" + detailRowCellIndex}
                                    className={
                                      paginationClasses.narrowCellStyle
                                    }>
                                    {rowCell[cell.column.id]}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                )
              );
            })}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const PaymentTable = ({ price, setPaymentData, t }) => {
  const discountData = [
    {
      id: "medicineCosting",
      name: t("translation:Medicine"),
      amount: price.medicineCosting,
    },
    {
      id: "deliveryFee",
      name: t("translation:Delivery Fee"),
      amount: price.deliveryFee,
    },
    {
      id: "brewingFee",
      name: t("translation:Brewing Fee"),
      amount: price.brewingFee,
    },
  ];

  const columnsViewOnly = [
    {
      Header: t("translation:Charges"),
      accessor: "name",
    },
    {
      Header: t("translation:Amount"),
      accessor: "amount",
      Cell: ({ cell }) => {
        return (
          <>
            {cell.value !== null && cell.value !== undefined
              ? cell.value.toFixed(2)
              : ""}
          </>
        );
      },
    },
  ];

  return (
    <Table
      columns={columnsViewOnly}
      data={discountData}
      updateMyData={setPaymentData}
      t={t}
    />
  );
};

export default PaymentTable;
