import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getFilterRuleList,
  getFilterRuleById,
  postCreateFilterRule,
  putUpdateFilterRule,
} from "../actions/filterRuleActions";
import * as types from "../_constants/filterRuleConstants";
import * as notification from "../_constants/notificationConstants";
import filterRuleApis from "../_apis/filterRuleApis";
export default function* filterRuleSagas() {
  yield all([
    takeLatest(types.GET_FILTERRULELIST_ASYNC.PENDING, getFilterRuleListCall),
  ]);
  yield all([
    takeLatest(types.GET_FILTERRULEBYID_ASYNC.PENDING, getFilterRuleByIdCall),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATEFILTERRULE_ASYNC.PENDING,
      postCreateFilterRuleCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEFILTERRULE_ASYNC.PENDING,
      putUpdateFilterRuleCall
    ),
  ]);
}

function* getFilterRuleListCall(searchModel) {
  try {
    const data = yield call(
      filterRuleApis.getFilterRuleListApi,
      searchModel.data
    );
    yield put(getFilterRuleList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getFilterRuleList.error(error.response?.data?.message));
    else yield put(getFilterRuleList.error(notification.CONST_NOINTERNET));
  }
}

function* getFilterRuleByIdCall(searchModel) {
  try {
    const data = yield call(
      filterRuleApis.getFilterRuleByIdApi,
      searchModel.data
    );
    yield put(getFilterRuleById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getFilterRuleById.error(error.response?.data?.message));
    else yield put(getFilterRuleById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateFilterRuleCall(searchModel) {
  try {
    const data = yield call(
      filterRuleApis.postCreateFilterRuleApi,
      searchModel.data
    );
    yield put(postCreateFilterRule.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateFilterRule.error(error.response?.data?.message));
    else yield put(postCreateFilterRule.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateFilterRuleCall(searchModel) {
  try {
    const data = yield call(
      filterRuleApis.putUpdateFilterRuleApi,
      searchModel.data
    );
    yield put(putUpdateFilterRule.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateFilterRule.error(error.response?.data?.message));
    else yield put(putUpdateFilterRule.error(notification.CONST_NOINTERNET));
  }
}
