import { createAsyncTypes } from "../_helper/Utilities";

export const GET_DELIVERYCUTOFFSCHEDULELIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYCUTOFFSCHEDULELIST"
);

export const GET_DELIVERYCUTOFFSCHEDULEBYID_ASYNC = createAsyncTypes(
  "GET_DELIVERYCUTOFFSCHEDULEBYID"
);

export const PUT_UPDATEDELIVERYCUTOFFSCHEDULE_ASYNC = createAsyncTypes(
  "PUT_UPDATEDELIVERYCUTOFFSCHEDULE"
);

export const POST_SEARCHDELIVERYCUTOFFSCHEDULEBRANCH_ASYNC = createAsyncTypes(
  "POST_SEARCHDELIVERYCUTOFFSCHEDULEBRANCH"
);
