//lib
import React, { useEffect } from "react";

import { usePDF } from "@react-pdf/renderer";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";
import * as generalConstants from "../../_constants/generalConstants";
//import NotoSansHansRegular from "./NotoSansHans-Regular-2.ttf";
//style

// Font.register({
//   family: "NotoSansSChinese",
//   fonts: [{ src: NotoSansHansRegular, fontWeight: 300 }],
// });

// Font.registerHyphenationCallback((word) =>
//   word.length === 1 ? [word] : Array.from(word).map((char) => char)
// );

const styles = StyleSheet.create({
  body: {
    maxWidth: "78mm",
    maxHeight: "90mm",
  },
  flexRow: {
    flexDirection: "row",
    padding: 5,
  },
  flexColumn: {
    flexDirection: "column",
    width: "100%",
    left: "3mm",
    top: "3mm",
  },
  flexColumn1: {
    flexDirection: "column",
    width: "90%",

    left: "3mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
  },
  text: {
    fontSize: "3.5mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",

    paddingRight: "5mm",
  },

  text1: {
    fontSize: "2mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  pageNumber: {
    fontSize: "2.5mm",
    right: "3mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    fontFamily: "NotoSansSChinese",
  },
  grey: {
    fontWeight: "bold",
    color: "rgba(77, 77, 77)",
  },
});

const PDFPage = ({
  deliverOrderDetails,
  dowo,
  courier,
  doNumber,
  deliveryOwned,
}) => {
  const mixedAddr = dowo.store_medicine_header.is_delivery_services
    ? dowo.store_medicine_header.delivery_address_details +
      dowo.store_medicine_header.delivery_address +
      dowo.store_medicine_header.delivery_postal_code
    : deliveryOwned.delivery_linked_store_medicine_header
        ?.delivery_address_details +
      deliveryOwned.delivery_linked_store_medicine_header?.delivery_address +
      deliveryOwned.delivery_linked_store_medicine_header?.delivery_postal_code;

  var totalParcel = [];

  deliverOrderDetails.map((item) => {
    if (item.status_type === generalConstants.APPROVED_STATUS_TYPE) {
      if (item.delivery_header.store_medicine_header.is_delivery_services) {
        const tempMixedAddr =
          item.delivery_header.store_medicine_header.delivery_address_details +
          item.delivery_header.store_medicine_header.delivery_address +
          item.delivery_header.store_medicine_header.delivery_postal_code;

        if (mixedAddr === tempMixedAddr) {
          totalParcel.push(item.delivery_header.store_medicine_header);
        }
      } else {
        const tempMixedAddr =
          item.delivery_header.store_medicine_header.delivery_owned
            .delivery_linked_store_medicine_header?.delivery_address_details +
          item.delivery_header.store_medicine_header.delivery_owned
            .delivery_linked_store_medicine_header?.delivery_address +
          item.delivery_header.store_medicine_header.delivery_owned
            .delivery_linked_store_medicine_header?.delivery_postal_code;

        if (mixedAddr === tempMixedAddr) {
          totalParcel.push(item.delivery_header.store_medicine_header);
        }
      }
    } else {
      totalParcel = totalParcel;
    }
  });

  const totalParcelIndex = totalParcel.findIndex(
    (item) => item.id === dowo.store_medicine_header.id
  );

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.flexRow}>
          <View style={styles.flexColumn}>
            <Text style={styles.text}>{doNumber}</Text>
            <Text style={styles.text}>{dowo.store_header.order_number}</Text>
            <Text style={styles.text}>{dowo.store_medicine_header.name}</Text>
            <View style={{ paddingTop: "3mm" }}></View>
            {dowo.store_medicine_header.is_delivery_services === true && (
              <>
                <Text style={styles.text}></Text>
                <Text style={styles.text1}>RECIPIENT:</Text>
                <Text style={styles.text}>
                  {dowo.store_medicine_header.delivery_name}
                </Text>

                <Text style={styles.text1}>CONTACT:</Text>
                <Text style={styles.text}>
                  {dowo.store_medicine_header.delivery_contact_number}
                </Text>

                {dowo.store_medicine_header.delivery_postal_code &&
                dowo.store_medicine_header.delivery_blk_no &&
                dowo.store_medicine_header.delivery_road_name ? (
                  <Text style={styles.text}>
                    {dowo.store_medicine_header.delivery_blk_no}{" "}
                    {dowo.store_medicine_header.delivery_road_name}{" "}
                    {dowo.store_medicine_header.delivery_building
                      ? dowo.store_medicine_header.delivery_building + " "
                      : null}
                    {dowo.store_medicine_header.delivery_address_details}{" "}
                    SINGAPORE {dowo.store_medicine_header.delivery_postal_code}
                  </Text>
                ) : (
                  <Text style={styles.text}>
                    {dowo.store_medicine_header.delivery_address}{" "}
                    {dowo.store_medicine_header.delivery_address_details}
                  </Text>
                )}
                <Text style={styles.text}>
                  {dowo.store_medicine_header.delivery_postal_code}
                </Text>
                <View style={{ paddingTop: "3mm" }}></View>
                <Text style={styles.text1}>REMARKS:</Text>
                <Text style={styles.text}>
                  {dowo.store_medicine_header.delivery_remarks}
                </Text>
              </>
            )}

            {dowo.store_medicine_header.is_delivery_services !== true && (
              <>
                <Text style={styles.text}></Text>
                <Text style={styles.text1}>RECIPIENT:</Text>
                <Text style={styles.text}>
                  {
                    deliveryOwned.delivery_linked_store_medicine_header
                      ?.delivery_name
                  }
                </Text>

                <Text style={styles.text1}>CONTACT:</Text>
                <Text style={styles.text}>
                  {
                    deliveryOwned.delivery_linked_store_medicine_header
                      ?.delivery_contact_number
                  }
                </Text>

                {deliveryOwned.delivery_linked_store_medicine_header
                  ?.delivery_postal_code &&
                deliveryOwned.delivery_linked_store_medicine_header
                  ?.delivery_blk_no &&
                deliveryOwned.delivery_linked_store_medicine_header
                  ?.delivery_road_name ? (
                  <Text style={styles.text}>
                    {
                      deliveryOwned.delivery_linked_store_medicine_header
                        ?.delivery_blk_no
                    }{" "}
                    {
                      deliveryOwned.delivery_linked_store_medicine_header
                        ?.delivery_road_name
                    }{" "}
                    {deliveryOwned.delivery_linked_store_medicine_header
                      ?.delivery_building
                      ? deliveryOwned.delivery_linked_store_medicine_header
                          ?.delivery_building + " "
                      : null}
                    {
                      deliveryOwned.delivery_linked_store_medicine_header
                        ?.delivery_address_details
                    }{" "}
                    SINGAPORE{" "}
                    {
                      deliveryOwned.delivery_linked_store_medicine_header
                        ?.delivery_postal_code
                    }
                  </Text>
                ) : (
                  <Text style={styles.text}>
                    {
                      deliveryOwned.delivery_linked_store_medicine_header
                        ?.delivery_address
                    }{" "}
                    {
                      deliveryOwned.delivery_linked_store_medicine_header
                        ?.delivery_address_details
                    }
                  </Text>
                )}

                <Text style={styles.text}>
                  {
                    deliveryOwned.delivery_linked_store_medicine_header
                      ?.delivery_postal_code
                  }
                </Text>
                <View style={{ paddingTop: "3mm" }}></View>
                <Text style={styles.text1}>REMARKS:</Text>
                <Text style={styles.text}>
                  {
                    deliveryOwned.delivery_linked_store_medicine_header
                      ?.delivery_remarks
                  }
                </Text>
              </>
            )}
          </View>
        </View>
        <View style={styles.flexColumn1}>
          <Text style={styles.text}>
            {courier.name} | Parcel {totalParcelIndex + 1} /{" "}
            {totalParcel.length}
          </Text>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
let printNum = 0;
const DeliveryLabelPDF = ({
  t,
  deliverOrderDetails,
  dowo,
  courier,
  doNumber,
  print,
  deliveryOwned,
}) => {
  const [instance, updateInstance] = usePDF({
    document: (
      <PDFPage
        deliverOrderDetails={deliverOrderDetails}
        dowo={dowo}
        courier={courier}
        doNumber={doNumber}
        deliveryOwned={deliveryOwned}
      />
    ),
  });

  useEffect(() => {
    if (!instance.loading && instance.url && print !== printNum) {
      printNum = print;
      window.open(instance.url, "_blank", "noreferrer");
    }
  }, [instance.loading, instance.url, printNum, print]);
  return <></>;
  // if (print !== printNum) {
  //   return (
  //     <BlobProvider
  //       document={
  //         <PDFPage
  //           dowo={dowo}
  //           courier={courier}
  //           doNumber={doNumber}
  //           deliveryOwned={deliveryOwned}
  //         />
  //       }>
  //       {({ blob, url, loading, error }) => {
  //         // var file = new blob();
  //         var fileURL = [];
  //         if (!loading && blob && print !== printNum) {
  //           printNum = print;
  //           fileURL = URL.createObjectURL(blob);
  //           window.open(fileURL, "_blank", "noreferrer");
  //         }

  //         // Do whatever you need with blob here
  //         return <></>;
  //       }}
  //     </BlobProvider>
  //   );
  // } else return <></>;
};

export default DeliveryLabelPDF;
