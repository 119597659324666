//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import Panel from "../../../../components/Panel";
import { useTranslation } from "react-i18next";
import moment from "moment";
//actions
import {
  getBranchPhysicianAdhocScheduleList,
  getBranchPhysicianAdhocSchedulePhysicianList,
} from "../../../../actions/branchPhysicianAdhocScheduleActions";
//components
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import * as pageConfigure from "../../../../_constants/pageConstants";
import { useStyles } from "../../../../components/globalStyles";
import * as generalConstants from "../../../../_constants/generalConstants";

export const BranchPhysicianAdhocScheduleDetailPage = ({
  branchId,
  setDetailView,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const branchPhysicianAdhocSchedule = useSelector(
    (state) => state.branchPhysicianAdhocSchedule
  );
  const [physician, setPhysician] = useState(null);
  const [searchValue, setSearchValue] = React.useState("");

  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,

    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_branch_desc_value: null,
    search_branch_id_value: branchId,
    search_branch_name_value: null,
    search_desc_value: null,
    search_name_value: null,
    search_physician_id_value: null,
    search_physician_name_value: null,
    search_status_type_value: null,
  });

  const columns = [
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Description"),
      accessor: "desc",
    },
    {
      Header: t("translation:Closed All Day"),
      sortable: false,
      accessor: (d) => {
        if (d.is_closed_all_day) return "Yes";
        else return "";
      },
    },
    {
      Header: t("translation:Start Time"),
      sortable: false,
      accessor: (d) => {
        if (d.start_time)
          return moment(d.start_time, "HH:mm:ss").format("HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:End Time"),
      sortable: false,
      accessor: (d) => {
        if (d.end_time) return moment(d.end_time, "HH:mm:ss").format("HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Adhoc Date"),
      sortable: false,
      accessor: (d) => {
        if (d.adhoc_date) return moment(d.adhoc_date).format("DD-MMM-YYYY");
        else return "";
      },
    },
  ];
  //functions
  const handleOnChangePhysician = (value) => {
    setPhysician(value);
    setPageInfo({
      ...pageInfo,
      search_physician_id_value: value.user.id,
    });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_name_value: value,
        search_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
        search_desc_value: "",
      });
    }
  };

  useEffect(() => {
    if (physician) {
      dispatch(getBranchPhysicianAdhocScheduleList.pending(pageInfo));
    }
  }, [physician, pageInfo, dispatch]);

  useEffect(() => {
    dispatch(
      getBranchPhysicianAdhocSchedulePhysicianList.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );
  }, [dispatch]);

  return (
    <Panel heading={t("translation:Branch Physician Adhoc Schedule Details")}>
      {branchPhysicianAdhocSchedule.getBranchPhysicianAdhocSchedulePhysicianListIsSuccess && (
        <Grid xs={12} item className={classes.userInfoField}>
          <Autocomplete
            name="Physician"
            disableClearable
            value={physician}
            options={
              branchPhysicianAdhocSchedule.branchPhysicianAdhocSchedulePhysicianList &&
              Object.keys(
                branchPhysicianAdhocSchedule
                  .branchPhysicianAdhocSchedulePhysicianList
                  .organisation_physician
              ).length !== 0
                ? branchPhysicianAdhocSchedule.branchPhysicianAdhocSchedulePhysicianList.organisation_physician.sort(
                    (a, b) => -b.user.name.localeCompare(a.user.name)
                  )
                : []
            }
            getOptionLabel={(option) =>
              option.user.name ? option.user.name : ""
            }
            isOptionEqualToValue={(option, value) => {
              if (option.id === value.id) return option;
            }}
            style={{ width: "100%" }}
            onChange={(e, option) => {
              handleOnChangePhysician(option);
            }}
            renderInput={(params) => (
              <TextField
                required
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Physician")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )}
      {physician && (
        <>
          <SortingSearchTable
            tableHeading={""}
            searchPlaceholder={t("translation:Name/ Description")}
            searchValue={searchValue}
            columns={columns}
            data={
              branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                ?.branch_physician_adhoc_schedules !== undefined
                ? branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                    ?.branch_physician_adhoc_schedules
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                ? branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                    .total_pages
                : 0
            }
            loading={
              branchPhysicianAdhocSchedule.isLoadingBranchPhysicianAdhocScheduleList
            }
            totalItems={
              branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                ? branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                    .total_items
                : 0
            }
            handleSearch={handleSearch}
          />
        </>
      )}
      <Grid className={classes.root} container>
        <PageOnlyBackAction setView={() => setDetailView(false)} />
      </Grid>
    </Panel>
  );
};
