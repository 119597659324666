import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const filterRuleApis = {
  getFilterRuleListApi,
  getFilterRuleByIdApi,
  postCreateFilterRuleApi,
  putUpdateFilterRuleApi,
  getAllModelNamesApi,
};

async function getFilterRuleListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(apiConstants.apiGetFilterRuleList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getFilterRuleByIdApi(model) {
  const url = apiConstants.apiGetFilterRuleById + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreateFilterRuleApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiPostCreateFilterRule, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateFilterRuleApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(apiConstants.apiPutUpdateFilterRule, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllModelNamesApi() {
  return await axios
    .get(apiConstants.apiGetAllModelNames, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default filterRuleApis;
