//lib
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Close from "@mui/icons-material/Close";
import { Button, Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import StoreOrderList from "./StoreOrderList";
import { useStyles } from "../../../../components/globalStyles";
//style//style
export default function StoreOrderDialog({ setStoreOrderSearchId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Grid item xs={12}>
        <Link className={classes.boldMaroon075}>
          <Button
            className={classes.boldMaroon075}
            onClick={() => setOpen(true)}>
            {t("translation:Store Order")}
          </Button>
        </Link>
      </Grid>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpen(false);
          }
        }}
        aria-labelledby="confirm-dialog"
        fullWidth
        maxWidth="xl">
        <Close
          style={{ position: "absolute", right: "15px" }}
          onClick={() => setOpen(false)}
        />
        <DialogContent>
          <StoreOrderList
            onClose={() => setOpen(false)}
            setStoreOrderSearchId={setStoreOrderSearchId}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
