//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Popper from "@mui/material/Popper";

//actions
import {
  putUpdateFormulaMedicineFormula,
  getFormulaMedicineMedicineList,
} from "../../../../actions/formulaMedicineActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import MedicineListTable from "./components/MedicineListTable";
import * as generalConstants from "../../../../_constants/generalConstants";

export const EditFormulaMedicinePage = ({
  formulaEdit,
  selectedValue,
  setDetailView,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const [selectedMedItem, setSelectedMedItem] = useState("");
  const formulaMedicine = useSelector((state) => state.formulaMedicine);
  const prevPutUpdateStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [medicineList, setMedicineList] = useState(
    formulaEdit ? formulaEdit.formula_medicine_associations : []
  );
  const [JsonText, setJsonText] = useState(null);

  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  //functions

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const setMedicineData = (rowIndex, columnID, value) => {
    let visitMedicineeDetail = [...medicineList];

    if (columnID === "delete") {
      let result = [...visitMedicineeDetail];
      //check whether is a current existing medicine

      const currentMedicineneWoDelete = visitMedicineeDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentMedicineVerified = currentMedicineneWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentMedicineVerified) {
        const currentMedicineIndex = result.findIndex(
          (e) => e.id === currentMedicineVerified.id
        );
        result[currentMedicineIndex] = {
          ...currentMedicineVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddMedicineVerified = currentMedicineneWoDelete.find(
          (e, index) => index === rowIndex && e.id === 0
        );

        result = result.filter(
          (e) => e.medicine.id !== newAddMedicineVerified.medicine.id
        );
      }

      setMedicineList(result);
    } else {
      const currentMedicineWoDelete = visitMedicineeDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );
      const selectedVisitMedicine = currentMedicineWoDelete.map(
        (item, index) => {
          if (index === rowIndex && columnID !== "delete") {
            return {
              ...item,
              [columnID]: value,
            };
          }
          return item;
        }
      );

      const currentMedicineWDelete = visitMedicineeDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const mergeSelectedWDeletedMedicine = [
        ...currentMedicineWDelete,
        ...selectedVisitMedicine,
      ];

      setMedicineList(mergeSelectedWDeletedMedicine);
    }
  };

  const handleSelectedMedicine = (event, newValue) => {
    const selectedVisitMedicine = {
      id: 0,
      // create quantity
      default_quantity_per_day: null,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
      medicine: newValue,
    };
    let visitMedicineDetail;

    //checking same medicine and dont allow same medicine exist in the same group
    const repeatMedicine = medicineList.some(
      (item) =>
        item.medicine.id === newValue.id &&
        item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
    //find array that status_type === 7 (deleted)
    const repeatDeletedMedicineIndex = medicineList.findIndex(
      (item) =>
        item.medicine.id === newValue.id &&
        item.status_type === generalConstants.DELETED_STATUS_TYPE
    );

    if (repeatMedicine) {
      visitMedicineDetail = [...medicineList];
    } else if (repeatDeletedMedicineIndex !== -1) {
      visitMedicineDetail = [...medicineList];

      visitMedicineDetail[repeatDeletedMedicineIndex] = {
        ...visitMedicineDetail[repeatDeletedMedicineIndex],
        //id: (use back existing)
        // create quantity
        default_quantity_per_day: null,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
        medicine: newValue,
      };
      //move new item into last item
      visitMedicineDetail.push(
        visitMedicineDetail.splice(repeatDeletedMedicineIndex, 1)[0]
      );
    } else {
      visitMedicineDetail = [...medicineList, selectedVisitMedicine];
    }

    setMedicineList(visitMedicineDetail);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const formulas = {
      id: selectedValue.id,
      formula_medicine_associations: medicineList,
    };
    const submitJson = {
      formulas: [formulas],
    };

    dispatch(putUpdateFormulaMedicineFormula.pending(submitJson));
  };

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      id: selectedValue.id,
      name: selectedValue.name,

      medicine_type: selectedValue.medicine_type,
    },
  });

  useEffect(() => {
    //set ref when first render
    prevPutUpdateStatus.current = false;
    register("medicine_type");
  }, [register]);

  //useEffects
  useEffect(() => {
    if (selectedValue.medicine_type) {
      dispatch(
        getFormulaMedicineMedicineList.pending({
          search_medicine_type_id_value: selectedValue.medicine_type.id,
          search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
          is_pagination: false,
        })
      );
    }
  }, [dispatch, selectedValue]);

  useEffect(() => {
    if (
      formulaMedicine.isLoadingPutUpdateFormulaMedicineFormula !==
      prevPutUpdateStatus.current
    ) {
      //check the previous ref with current state
      prevPutUpdateStatus.current =
        formulaMedicine.isLoadingPutUpdateFormulaMedicineFormula;

      if (
        formulaMedicine.putUpdateFormulaMedicineFormulaIsSuccess === true &&
        formulaMedicine.isLoadingPutUpdateFormulaMedicineFormula === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset(formulaMedicine.putUpdateFormulaMedicineFormula.formula);
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        formulaMedicine.putUpdateFormulaMedicineFormulaIsSuccess === false &&
        formulaMedicine.isLoadingPutUpdateFormulaMedicineFormula === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(formulaMedicine.error.putUpdateFormulaMedicineFormulaError);
        window.scrollTo(0, 0);
      }
    }
  }, [formulaMedicine, reset]);

  const medicineListWODelete = () => {
    //filter deleted medicine
    return medicineList.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
  };
  const memoMedicineListWODelete = React.useMemo(medicineListWODelete, [
    medicineList,
  ]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:Formula Medicine Details")
          : t("translation:Edit Formula Medicine")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Formula has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.root} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={true}
              className={classes.userFieldColor}
              required
              name="name"
              label={t("translation:Name")}
              variant="outlined"
              {...register("name")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  required
                  disabled={true}
                  name="medicine_type"
                  id="medicine_type"
                  options={[]}
                  getOptionLabel={(option) => option.name}
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option)
                  }
                  renderInput={(params) => (
                    <TextField
                      className={classes.userFieldColor}
                      {...params}
                      required
                      label={t("translation:Medicine Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="medicine_type"
              control={control}
            />
          </Grid>

          {!temViewOnly && (
            <Grid item xs={12} className={classes.userInfoField}>
              <Autocomplete
                disabled={temViewOnly}
                inputValue={selectedMedItem}
                value={selectedMedItem === null ? "" : selectedMedItem}
                name="medicines"
                id="medicines"
                cy_data="addMedicine"
                options={
                  formulaMedicine.getFormulaMedicineMedicineList
                    ? formulaMedicine.getFormulaMedicineMedicineList.medicines
                    : []
                }
                getOptionLabel={(option) => (option.name ? option.name : "")}
                style={{ width: "100%" }}
                isOptionEqualToValue={() => {
                  return true;
                }}
                disableClearable
                onChange={(e, option) => {
                  handleSelectedMedicine(e, option);
                  setSelectedMedItem("");
                }}
                disablePortal
                sx={{
                  "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                    '&[aria-selected="true"]': {
                      backgroundColor: "rgba(255,255,255)",
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //clear the field to empty
                    onChange={(event) => {
                      setSelectedMedItem(event.target.value);
                    }}
                    className={classes.userFieldColor}
                    label={<span>{t("translation:Medicine")}</span>}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}

          {Object.keys(memoMedicineListWODelete).length !== 0 && (
            <Grid item xs={12}>
              <Panel>
                <MedicineListTable
                  viewOnly={temViewOnly}
                  data={memoMedicineListWODelete}
                  setData={setMedicineData}
                  t={t}
                />
              </Panel>
            </Grid>
          )}
          <>
            <RequiredNote />
            {temViewOnly ? (
              <PageOnlyBackAction setView={() => setDetailView(false)} />
            ) : (
              <PageSubmitWBackAction setView={() => setDetailView(false)} />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
