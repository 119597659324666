import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getOrganisationPhysicianList,
  getOrganisationPhysicianById,
  putUpdateOrganisationPhysician,
  getOrganisationPhysicianUserList,
} from "../actions/organisationPhysicianActions";
import * as types from "../_constants/organisationPhysicianConstants";
import * as notification from "../_constants/notificationConstants";
import organisationPhysicianApis from "../_apis/organisationPhysicianApis";
export default function* organisationPhysicianSagas() {
  yield all([
    takeLatest(
      types.GET_ORGANISATIONPHYSICIANLIST_ASYNC.PENDING,
      getOrganisationPhysicianListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_ORGANISATIONPHYSICIANBYID_ASYNC.PENDING,
      getOrganisationPhysicianByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_UPDATEORGANISATIONPHYSICIAN_ASYNC.PENDING,
      putUpdateOrganisationPhysicianCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_ORGANISATIONPHYSICIANUSERLIST_ASYNC.PENDING,
      getOrganisationPhysicianUserListCall
    ),
  ]);
}

function* getOrganisationPhysicianListCall(searchModel) {
  try {
    const data = yield call(
      organisationPhysicianApis.getOrganisationPhysicianListApi,
      searchModel.data
    );
    yield put(getOrganisationPhysicianList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getOrganisationPhysicianList.error(error.response?.data?.message)
      );
    else
      yield put(
        getOrganisationPhysicianList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getOrganisationPhysicianByIdCall(searchModel) {
  try {
    const data = yield call(
      organisationPhysicianApis.getOrganisationPhysicianByIdApi,
      searchModel.data
    );
    yield put(getOrganisationPhysicianById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getOrganisationPhysicianById.error(error.response?.data?.message)
      );
    else
      yield put(
        getOrganisationPhysicianById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateOrganisationPhysicianCall(searchModel) {
  try {
    const data = yield call(
      organisationPhysicianApis.putUpdateOrganisationPhysicianApi,
      searchModel.data
    );
    yield put(putUpdateOrganisationPhysician.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateOrganisationPhysician.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdateOrganisationPhysician.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getOrganisationPhysicianUserListCall(searchModel) {
  try {
    const data = yield call(
      organisationPhysicianApis.getOrganisationPhysicianUserListApi,
      searchModel.data
    );
    yield put(getOrganisationPhysicianUserList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getOrganisationPhysicianUserList.error(error.response?.data?.message)
      );
    else
      yield put(
        getOrganisationPhysicianUserList.error(notification.CONST_NOINTERNET)
      );
  }
}
