import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getReportingInvoiceMedicineCostingListByVisit,
  getReportingInvoiceMedicineCostingDetailsListByVisit,
  getReportingInvoiceSummaryListByVisit,
  getReportingInvoiceDetailsListByVisit,
  getReportingInvoiceMedicineCostingListByStore,
  getReportingInvoiceMedicineCostingDetailsListByStore,
  getReportingInvoiceSummaryListByStore,
  getReportingInvoiceDetailsListByStore,
  getReportingExportInvoiceMedicineCostingListByVisit,
  getReportingExportInvoiceMedicineCostingDetailsListByVisit,
  getReportingExportInvoiceSummaryListByVisit,
  getReportingExportInvoiceDetailsListByVisit,
  getReportingExportInvoiceMedicineCostingListByStore,
  getReportingExportInvoiceMedicineCostingDetailsListByStore,
  getReportingExportInvoiceSummaryListByStore,
  getReportingExportInvoiceDetailsListByStore,
  getReportingExportStoreMedicineHeader,
  getReportingExportMedicineInOut,
  getReportingExportMedicineInOutDetails,
  getReportingExportAcupoints,
  postReportingVerifyCSVAcupoints,
  postReportingImportCSVAcupoints,
  getReportingExportTreatments,
  postReportingVerifyCSVTreatments,
  postReportingImportCSVTreatments,
  getReportingExportMedicines,
  postReportingVerifyCSVMedicines,
  postReportingImportCSVMedicines,
  getReportingExportFormulas,
  postReportingVerifyCSVFormulas,
  postReportingImportCSVFormulas,
  getReportingExportFormulaMedicine,
  postReportingVerifyCSVFormulaMedicine,
  postReportingImportCSVFormulaMedicine,
  getReportingExportStoreMedicineDetails,
  getReportingExportVisitNotesDetails,
  getReportingExportBranchMedicine,
  postReportingVerifyCSVBranchMedicine,
  postReportingImportCSVBranchMedicine,
  getReportingExportFixedMedicinePrice,
  postReportingVerifyCSVFixedMedicinePrice,
  postReportingImportCSVFixedMedicinePrice,
  postReportingVerifyFixedMedicinePriceSync,
  postReportingImportFixedMedicinePriceSync,
  getMedicineFixedSellingPriceHeader,
  getReportingExportUserCredits,
  getReportingExportUserCreditsStatement,
  getReportingBillAbleInvoiceByStore,
  getReportingExportBillAbleInvoiceByStore,
  getReportingBillAbleInvoiceByVisit,
  getReportingExportBillAbleInvoiceByVisit,
  getReportingBillAbleIMCByStore,
  getReportingExportBillAbleIMCByStore,
  getReportingBillAbleIMCByVisit,
  getReportingExportBillAbleIMCByVisit,
  getReportingPurchaseOrderSummary,
  getReportingExportPurchaseOrderSummary,
  getReportingPurchaseOrderDetails,
  getReportingExportPurchaseOrderDetails,
  getReportingDeliveryTrackingDetails,
  getReportingExportDeliveryTrackingDetails,
  getReportingExportDeliveryTrackingSummary,
  getReportingExportAppointmentDetails,
  getReportingExportAppointmentSummary,
  getReportingExportDispensaryDetails,
  getReportingExportDispensarySummary,
  getReportingExportSMSMessageInOutDetails,
  getReportingExportSMSMessageInOutSummary,
} from "../actions/reportingActions";
import * as types from "../_constants/reportingConstants";
import * as notification from "../_constants/notificationConstants";
import reportingApis from "../_apis/reportingApis";
export default function* reportingSagas() {
  yield all([
    takeLatest(
      types.GET_REPORTINGINVOICEDETAILSLISTBYSTORE_ASYNC.PENDING,
      getReportingInvoiceDetailsListByStoreCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_REPORTINGINVOICESUMMARYLISTBYSTORE_ASYNC.PENDING,
      getReportingInvoiceSummaryListByStoreCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC.PENDING,
      getReportingInvoiceMedicineCostingDetailsListByStoreCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.PENDING,
      getReportingInvoiceMedicineCostingListByStoreCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGINVOICEDETAILSLISTBYVISIT_ASYNC.PENDING,
      getReportingInvoiceDetailsListByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGINVOICESUMMARYLISTBYVISIT_ASYNC.PENDING,
      getReportingInvoiceSummaryListByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC.PENDING,
      getReportingInvoiceMedicineCostingDetailsListByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.PENDING,
      getReportingInvoiceMedicineCostingListByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYSTORE_ASYNC.PENDING,
      getReportingExportInvoiceDetailsListByStoreCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYSTORE_ASYNC.PENDING,
      getReportingExportInvoiceSummaryListByStoreCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC
        .PENDING,
      getReportingExportInvoiceMedicineCostingDetailsListByStoreCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.PENDING,
      getReportingExportInvoiceMedicineCostingListByStoreCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYVISIT_ASYNC.PENDING,
      getReportingExportInvoiceDetailsListByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYVISIT_ASYNC.PENDING,
      getReportingExportInvoiceSummaryListByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC
        .PENDING,
      getReportingExportInvoiceMedicineCostingDetailsListByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.PENDING,
      getReportingExportInvoiceMedicineCostingListByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTSTOREMEDICINEHEADER_ASYNC.PENDING,
      getReportingExportStoreMedicineHeaderCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTMEDICINEINOUT_ASYNC.PENDING,
      getReportingExportMedicineInOutCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTMEDICINEINOUTDETAILS_ASYNC.PENDING,
      getReportingExportMedicineInOutDetailsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTACUPOINTS_ASYNC.PENDING,
      getReportingExportAcupointsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGVERIFYCSVACUPOINTS_ASYNC.PENDING,
      postReportingVerifyCSVAcupointsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGIMPORTCSVACUPOINTS_ASYNC.PENDING,
      postReportingImportCSVAcupointsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTTREATMENTS_ASYNC.PENDING,
      getReportingExportTreatmentsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGVERIFYCSVTREATMENTS_ASYNC.PENDING,
      postReportingVerifyCSVTreatmentsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGIMPORTCSVTREATMENTS_ASYNC.PENDING,
      postReportingImportCSVTreatmentsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTMEDICINES_ASYNC.PENDING,
      getReportingExportMedicinesCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGVERIFYCSVMEDICINES_ASYNC.PENDING,
      postReportingVerifyCSVMedicinesCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGIMPORTCSVMEDICINES_ASYNC.PENDING,
      postReportingImportCSVMedicinesCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTFORMULAS_ASYNC.PENDING,
      getReportingExportFormulasCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGVERIFYCSVFORMULAS_ASYNC.PENDING,
      postReportingVerifyCSVFormulasCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGIMPORTCSVFORMULAS_ASYNC.PENDING,
      postReportingImportCSVFormulasCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTFORMULAMEDICINE_ASYNC.PENDING,
      getReportingExportFormulaMedicineCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGVERIFYCSVFORMULAMEDICINE_ASYNC.PENDING,
      postReportingVerifyCSVFormulaMedicineCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGIMPORTCSVFORMULAMEDICINE_ASYNC.PENDING,
      postReportingImportCSVFormulaMedicineCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTSTOREMEDICINEDETAILS_ASYNC.PENDING,
      getReportingExportStoreMedicineDetailsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTVISITNOTESDETAILS_ASYNC.PENDING,
      getReportingExportVisitNotesDetailsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTBRANCHMEDICINE_ASYNC.PENDING,
      getReportingExportBranchMedicineCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGVERIFYCSVBRANCHMEDICINE_ASYNC.PENDING,
      postReportingVerifyCSVBranchMedicineCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGIMPORTCSVBRANCHMEDICINE_ASYNC.PENDING,
      postReportingImportCSVBranchMedicineCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTFIXEDMEDICINEPRICE_ASYNC.PENDING,
      getReportingExportFixedMedicinePriceCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGVERIFYCSVFIXEDMEDICINEPRICE_ASYNC.PENDING,
      postReportingVerifyCSVFixedMedicinePriceCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGIMPORTCSVFIXEDMEDICINEPRICE_ASYNC.PENDING,
      postReportingImportCSVFixedMedicinePriceCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGVERIFYFIXEDMEDICINEPRICESYNC_ASYNC.PENDING,
      postReportingVerifyFixedMedicinePriceSyncCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_REPORTINGIMPORTFIXEDMEDICINEPRICESYNC_ASYNC.PENDING,
      postReportingImportFixedMedicinePriceSyncCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_MEDICINEFIXEDSELLINGPRICEHEADER_ASYNC.PENDING,
      getMedicineFixedSellingPriceHeaderCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTUSERCREDITS_ASYNC.PENDING,
      getReportingExportUserCreditsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTUSERCREDITSSTATEMENT_ASYNC.PENDING,
      getReportingExportUserCreditsStatementCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGBILLABLEIMCBYSTORE_ASYNC.PENDING,
      getReportingBillAbleIMCByStoreCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINEXPORTGBILLABLEIMCBYSTORE_ASYNC.PENDING,
      getReportingExportBillAbleIMCByStoreCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGBILLABLEIMCBYVISIT_ASYNC.PENDING,
      getReportingBillAbleIMCByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINEXPORTGBILLABLEIMCBYVISIT_ASYNC.PENDING,
      getReportingExportBillAbleIMCByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGBILLABLEINVOICEBYSTORE_ASYNC.PENDING,
      getReportingBillAbleInvoiceByStoreCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINEXPORTGBILLABLEINVOICEBYSTORE_ASYNC.PENDING,
      getReportingExportBillAbleInvoiceByStoreCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGBILLABLEINVOICEBYVISIT_ASYNC.PENDING,
      getReportingBillAbleInvoiceByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTBILLABLEINVOICEBYVISIT_ASYNC.PENDING,
      getReportingExportBillAbleInvoiceByVisitCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGPURCHASEORDERSUMMARY_ASYNC.PENDING,
      getReportingPurchaseOrderSummaryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTPURCHASEORDERSUMMARY_ASYNC.PENDING,
      getReportingExportPurchaseOrderSummaryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGPURCHASEORDERDETAILS_ASYNC.PENDING,
      getReportingPurchaseOrderDetailsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTPURCHASEORDERDETAILS_ASYNC.PENDING,
      getReportingExportPurchaseOrderDetailsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGDELIVERYTRACKINGDETAILS_ASYNC.PENDING,
      getReportingDeliveryTrackingDetailsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTDELIVERYTRACKINGDETAILS_ASYNC.PENDING,
      getReportingExportDeliveryTrackingDetailsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTDELIVERYTRACKINGSUMMARY_ASYNC.PENDING,
      getReportingExportDeliveryTrackingSummaryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTAPPOINTMENTDETAILS_ASYNC.PENDING,
      getReportingExportAppointmentDetailsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTAPPOINTMENTSUMMARY_ASYNC.PENDING,
      getReportingExportAppointmentSummaryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTDISPENSARYDETAILS_ASYNC.PENDING,
      getReportingExportDispensaryDetailsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTDISPENSARYSUMMARY_ASYNC.PENDING,
      getReportingExportDispensarySummaryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTSMSMESSAGEINOUTDETAILS_ASYNC.PENDING,
      getReportingExportSMSMessageInOutDetailsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_REPORTINGEXPORTSMSMESSAGEINOUTSUMMARY_ASYNC.PENDING,
      getReportingExportSMSMessageInOutSummaryCall
    ),
  ]);
}

function* getReportingInvoiceDetailsListByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingInvoiceDetailsListByStoreApi,
      Model.data
    );
    yield put(getReportingInvoiceDetailsListByStore.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingInvoiceDetailsListByStore.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingInvoiceDetailsListByStore.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingInvoiceSummaryListByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingInvoiceSummaryListByStoreApi,
      Model.data
    );
    yield put(getReportingInvoiceSummaryListByStore.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingInvoiceSummaryListByStore.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingInvoiceSummaryListByStore.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingInvoiceMedicineCostingDetailsListByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingInvoiceMedicineCostingDetailsListByStoreApi,
      Model.data
    );
    yield put(
      getReportingInvoiceMedicineCostingDetailsListByStore.success(data)
    );
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingInvoiceMedicineCostingDetailsListByStore.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingInvoiceMedicineCostingDetailsListByStore.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingInvoiceMedicineCostingListByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingInvoiceMedicineCostingListByStoreApi,
      Model.data
    );
    yield put(getReportingInvoiceMedicineCostingListByStore.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingInvoiceMedicineCostingListByStore.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingInvoiceMedicineCostingListByStore.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingInvoiceDetailsListByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingInvoiceDetailsListByVisitApi,
      Model.data
    );
    yield put(getReportingInvoiceDetailsListByVisit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingInvoiceDetailsListByVisit.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingInvoiceDetailsListByVisit.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingInvoiceSummaryListByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingInvoiceSummaryListByVisitApi,
      Model.data
    );
    yield put(getReportingInvoiceSummaryListByVisit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingInvoiceSummaryListByVisit.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingInvoiceSummaryListByVisit.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingInvoiceMedicineCostingDetailsListByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingInvoiceMedicineCostingDetailsListByVisitApi,
      Model.data
    );
    yield put(
      getReportingInvoiceMedicineCostingDetailsListByVisit.success(data)
    );
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingInvoiceMedicineCostingDetailsListByVisit.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingInvoiceMedicineCostingDetailsListByVisit.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingInvoiceMedicineCostingListByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingInvoiceMedicineCostingListByVisitApi,
      Model.data
    );
    yield put(getReportingInvoiceMedicineCostingListByVisit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingInvoiceMedicineCostingListByVisit.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingInvoiceMedicineCostingListByVisit.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportInvoiceDetailsListByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportInvoiceDetailsListByStoreApi,
      Model.data
    );
    yield put(getReportingExportInvoiceDetailsListByStore.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportInvoiceDetailsListByStore.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportInvoiceDetailsListByStore.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportInvoiceSummaryListByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportInvoiceSummaryListByStoreApi,
      Model.data
    );
    yield put(getReportingExportInvoiceSummaryListByStore.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportInvoiceSummaryListByStore.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportInvoiceSummaryListByStore.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportInvoiceMedicineCostingDetailsListByStoreCall(
  Model
) {
  try {
    const data = yield call(
      reportingApis.getReportingExportInvoiceMedicineCostingDetailsListByStoreApi,
      Model.data
    );
    yield put(
      getReportingExportInvoiceMedicineCostingDetailsListByStore.success(data)
    );
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportInvoiceMedicineCostingDetailsListByStore.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportInvoiceMedicineCostingDetailsListByStore.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportInvoiceMedicineCostingListByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportInvoiceMedicineCostingListByStoreApi,
      Model.data
    );
    yield put(
      getReportingExportInvoiceMedicineCostingListByStore.success(data)
    );
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportInvoiceMedicineCostingListByStore.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportInvoiceMedicineCostingListByStore.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportInvoiceDetailsListByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportInvoiceDetailsListByVisitApi,
      Model.data
    );
    yield put(getReportingExportInvoiceDetailsListByVisit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportInvoiceDetailsListByVisit.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportInvoiceDetailsListByVisit.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportInvoiceSummaryListByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportInvoiceSummaryListByVisitApi,
      Model.data
    );
    yield put(getReportingExportInvoiceSummaryListByVisit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportInvoiceSummaryListByVisit.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportInvoiceSummaryListByVisit.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportInvoiceMedicineCostingDetailsListByVisitCall(
  Model
) {
  try {
    const data = yield call(
      reportingApis.getReportingExportInvoiceMedicineCostingDetailsListByVisitApi,
      Model.data
    );
    yield put(
      getReportingExportInvoiceMedicineCostingDetailsListByVisit.success(data)
    );
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportInvoiceMedicineCostingDetailsListByVisit.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportInvoiceMedicineCostingDetailsListByVisit.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportInvoiceMedicineCostingListByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportInvoiceMedicineCostingListByVisitApi,
      Model.data
    );
    yield put(
      getReportingExportInvoiceMedicineCostingListByVisit.success(data)
    );
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportInvoiceMedicineCostingListByVisit.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportInvoiceMedicineCostingListByVisit.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportStoreMedicineHeaderCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportStoreMedicineHeaderApi,
      Model.data
    );
    yield put(getReportingExportStoreMedicineHeader.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportStoreMedicineHeader.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportStoreMedicineHeader.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportMedicineInOutCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportMedicineInOutApi,
      Model.data
    );
    yield put(getReportingExportMedicineInOut.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportMedicineInOut.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportMedicineInOut.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportMedicineInOutDetailsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportMedicineInOutDetailsApi,
      Model.data
    );
    yield put(getReportingExportMedicineInOutDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportMedicineInOutDetails.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportMedicineInOutDetails.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportTreatmentsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportTreatmentsApi,
      Model.data
    );
    yield put(getReportingExportTreatments.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportTreatments.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportTreatments.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postReportingVerifyCSVTreatmentsCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingVerifyCSVTreatmentsApi,
      Model.data
    );
    yield put(postReportingVerifyCSVTreatments.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingVerifyCSVTreatments.error(error.response?.data?.message)
      );
    else
      yield put(
        postReportingVerifyCSVTreatments.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postReportingImportCSVTreatmentsCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingImportCSVTreatmentsApi,
      Model.data
    );
    yield put(postReportingImportCSVTreatments.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingImportCSVTreatments.error(error.response?.data?.message)
      );
    else
      yield put(
        postReportingImportCSVTreatments.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportAcupointsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportAcupointsApi,
      Model.data
    );
    yield put(getReportingExportAcupoints.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportAcupoints.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportAcupoints.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postReportingVerifyCSVAcupointsCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingVerifyCSVAcupointsApi,
      Model.data
    );
    yield put(postReportingVerifyCSVAcupoints.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingVerifyCSVAcupoints.error(error.response?.data?.message)
      );
    else
      yield put(
        postReportingVerifyCSVAcupoints.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postReportingImportCSVAcupointsCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingImportCSVAcupointsApi,
      Model.data
    );
    yield put(postReportingImportCSVAcupoints.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingImportCSVAcupoints.error(error.response?.data?.message)
      );
    else
      yield put(
        postReportingImportCSVAcupoints.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportMedicinesCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportMedicinesApi,
      Model.data
    );
    yield put(getReportingExportMedicines.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportMedicines.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportMedicines.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postReportingVerifyCSVMedicinesCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingVerifyCSVMedicinesApi,
      Model.data
    );
    yield put(postReportingVerifyCSVMedicines.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingVerifyCSVMedicines.error(error.response?.data?.message)
      );
    else
      yield put(
        postReportingVerifyCSVMedicines.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postReportingImportCSVMedicinesCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingImportCSVMedicinesApi,
      Model.data
    );
    yield put(postReportingImportCSVMedicines.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingImportCSVMedicines.error(error.response?.data?.message)
      );
    else
      yield put(
        postReportingImportCSVMedicines.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportFormulasCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportFormulasApi,
      Model.data
    );
    yield put(getReportingExportFormulas.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportFormulas.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportFormulas.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postReportingVerifyCSVFormulasCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingVerifyCSVFormulasApi,
      Model.data
    );
    yield put(postReportingVerifyCSVFormulas.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingVerifyCSVFormulas.error(error.response?.data?.message)
      );
    else
      yield put(
        postReportingVerifyCSVFormulas.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postReportingImportCSVFormulasCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingImportCSVFormulasApi,
      Model.data
    );
    yield put(postReportingImportCSVFormulas.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingImportCSVFormulas.error(error.response?.data?.message)
      );
    else
      yield put(
        postReportingImportCSVFormulas.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportFormulaMedicineCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportFormulaMedicineApi,
      Model.data
    );
    yield put(getReportingExportFormulaMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportFormulaMedicine.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportFormulaMedicine.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postReportingVerifyCSVFormulaMedicineCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingVerifyCSVFormulaMedicineApi,
      Model.data
    );
    yield put(postReportingVerifyCSVFormulaMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingVerifyCSVFormulaMedicine.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postReportingVerifyCSVFormulaMedicine.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* postReportingImportCSVFormulaMedicineCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingImportCSVFormulaMedicineApi,
      Model.data
    );
    yield put(postReportingImportCSVFormulaMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingImportCSVFormulaMedicine.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postReportingImportCSVFormulaMedicine.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportStoreMedicineDetailsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportStoreMedicineDetailsApi,
      Model.data
    );
    yield put(getReportingExportStoreMedicineDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportStoreMedicineDetails.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportStoreMedicineDetails.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportVisitNotesDetailsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportVisitNotesDetailsApi,
      Model.data
    );
    yield put(getReportingExportVisitNotesDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportVisitNotesDetails.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportVisitNotesDetails.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportBranchMedicineCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportBranchMedicineApi,
      Model.data
    );
    yield put(getReportingExportBranchMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportBranchMedicine.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportBranchMedicine.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postReportingVerifyCSVBranchMedicineCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingVerifyCSVBranchMedicineApi,
      Model.data
    );
    yield put(postReportingVerifyCSVBranchMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingVerifyCSVBranchMedicine.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postReportingVerifyCSVBranchMedicine.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* postReportingImportCSVBranchMedicineCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingImportCSVBranchMedicineApi,
      Model.data
    );
    yield put(postReportingImportCSVBranchMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingImportCSVBranchMedicine.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postReportingImportCSVBranchMedicine.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportFixedMedicinePriceCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportFixedMedicinePriceApi,
      Model.data
    );
    yield put(getReportingExportFixedMedicinePrice.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportFixedMedicinePrice.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportFixedMedicinePrice.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* postReportingVerifyCSVFixedMedicinePriceCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingVerifyCSVFixedMedicinePriceApi,
      Model.data
    );
    yield put(postReportingVerifyCSVFixedMedicinePrice.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingVerifyCSVFixedMedicinePrice.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postReportingVerifyCSVFixedMedicinePrice.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* postReportingImportCSVFixedMedicinePriceCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingImportCSVFixedMedicinePriceApi,
      Model.data
    );
    yield put(postReportingImportCSVFixedMedicinePrice.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingImportCSVFixedMedicinePrice.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postReportingImportCSVFixedMedicinePrice.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* postReportingVerifyFixedMedicinePriceSyncCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingVerifyFixedMedicinePriceSyncApi,
      Model.data
    );
    yield put(postReportingVerifyFixedMedicinePriceSync.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingVerifyFixedMedicinePriceSync.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postReportingVerifyFixedMedicinePriceSync.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* postReportingImportFixedMedicinePriceSyncCall(Model) {
  try {
    const data = yield call(
      reportingApis.postReportingImportFixedMedicinePriceSyncApi,
      Model.data
    );
    yield put(postReportingImportFixedMedicinePriceSync.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postReportingImportFixedMedicinePriceSync.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postReportingImportFixedMedicinePriceSync.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getMedicineFixedSellingPriceHeaderCall(Model) {
  try {
    const data = yield call(
      reportingApis.getMedicineFixedSellingPriceHeaderApi,
      Model.data
    );
    yield put(getMedicineFixedSellingPriceHeader.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getMedicineFixedSellingPriceHeader.error(error.response?.data?.message)
      );
    else
      yield put(
        getMedicineFixedSellingPriceHeader.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportUserCreditsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportUserCreditsApi,
      Model.data
    );
    yield put(getReportingExportUserCredits.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportUserCredits.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportUserCredits.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportUserCreditsStatementCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportUserCreditsStatementApi,
      Model.data
    );
    yield put(getReportingExportUserCreditsStatement.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportUserCreditsStatement.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportUserCreditsStatement.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingBillAbleInvoiceByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingBillAbleInvoiceByStoreApi,
      Model.data
    );
    yield put(getReportingBillAbleInvoiceByStore.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingBillAbleInvoiceByStore.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingBillAbleInvoiceByStore.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportBillAbleInvoiceByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportBillAbleInvoiceByStoreApi,
      Model.data
    );
    yield put(getReportingExportBillAbleInvoiceByStore.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportBillAbleInvoiceByStore.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportBillAbleInvoiceByStore.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingBillAbleInvoiceByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingBillAbleInvoiceByVisitApi,
      Model.data
    );
    yield put(getReportingBillAbleInvoiceByVisit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingBillAbleInvoiceByVisit.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingBillAbleInvoiceByVisit.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportBillAbleInvoiceByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportBillAbleInvoiceByVisitApi,
      Model.data
    );
    yield put(getReportingExportBillAbleInvoiceByVisit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportBillAbleInvoiceByVisit.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportBillAbleInvoiceByVisit.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingBillAbleIMCByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingBillAbleIMCByStoreApi,
      Model.data
    );
    yield put(getReportingBillAbleIMCByStore.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingBillAbleIMCByStore.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingBillAbleIMCByStore.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportBillAbleIMCByStoreCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportBillAbleIMCByStoreApi,
      Model.data
    );
    yield put(getReportingExportBillAbleIMCByStore.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportBillAbleIMCByStore.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportBillAbleIMCByStore.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingBillAbleIMCByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingBillAbleIMCByVisitApi,
      Model.data
    );
    yield put(getReportingBillAbleIMCByVisit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingBillAbleIMCByVisit.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingBillAbleIMCByVisit.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportBillAbleIMCByVisitCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportBillAbleIMCByVisitApi,
      Model.data
    );
    yield put(getReportingExportBillAbleIMCByVisit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportBillAbleIMCByVisit.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportBillAbleIMCByVisit.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingPurchaseOrderSummaryCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingPurchaseOrderSummaryApi,
      Model.data
    );
    yield put(getReportingPurchaseOrderSummary.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingPurchaseOrderSummary.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingPurchaseOrderSummary.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportPurchaseOrderSummaryCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportPurchaseOrderSummaryApi,
      Model.data
    );
    yield put(getReportingExportPurchaseOrderSummary.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportPurchaseOrderSummary.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportPurchaseOrderSummary.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingPurchaseOrderDetailsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingPurchaseOrderDetailsApi,
      Model.data
    );
    yield put(getReportingPurchaseOrderDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingPurchaseOrderDetails.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingPurchaseOrderDetails.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportPurchaseOrderDetailsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportPurchaseOrderDetailsApi,
      Model.data
    );
    yield put(getReportingExportPurchaseOrderDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportPurchaseOrderDetails.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportPurchaseOrderDetails.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingDeliveryTrackingDetailsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingDeliveryTrackingDetailsApi,
      Model.data
    );
    yield put(getReportingDeliveryTrackingDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingDeliveryTrackingDetails.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingDeliveryTrackingDetails.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportDeliveryTrackingDetailsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportDeliveryTrackingDetailsApi,
      Model.data
    );
    yield put(getReportingExportDeliveryTrackingDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportDeliveryTrackingDetails.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportDeliveryTrackingDetails.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportDeliveryTrackingSummaryCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportDeliveryTrackingSummaryApi,
      Model.data
    );
    yield put(getReportingExportDeliveryTrackingSummary.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportDeliveryTrackingSummary.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportDeliveryTrackingSummary.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}
function* getReportingExportAppointmentDetailsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportAppointmentDetailsApi,
      Model.data
    );
    yield put(getReportingExportAppointmentDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportAppointmentDetails.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportAppointmentDetails.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportAppointmentSummaryCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportAppointmentSummaryApi,
      Model.data
    );
    yield put(getReportingExportAppointmentSummary.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportAppointmentSummary.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportAppointmentSummary.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportDispensaryDetailsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportDispensaryDetailsApi,
      Model.data
    );
    yield put(getReportingExportDispensaryDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportDispensaryDetails.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportDispensaryDetails.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportDispensarySummaryCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportDispensarySummaryApi,
      Model.data
    );
    yield put(getReportingExportDispensarySummary.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportDispensarySummary.error(error.response?.data?.message)
      );
    else
      yield put(
        getReportingExportDispensarySummary.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getReportingExportSMSMessageInOutDetailsCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportSMSMessageInOutDetailsApi,
      Model.data
    );
    yield put(getReportingExportSMSMessageInOutDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportSMSMessageInOutDetails.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportSMSMessageInOutDetails.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getReportingExportSMSMessageInOutSummaryCall(Model) {
  try {
    const data = yield call(
      reportingApis.getReportingExportSMSMessageInOutSummaryApi,
      Model.data
    );
    yield put(getReportingExportSMSMessageInOutSummary.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getReportingExportSMSMessageInOutSummary.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getReportingExportSMSMessageInOutSummary.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}
