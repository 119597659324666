import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const treatmentTypeApis = {
  getTreatmentTypeListApi,
  getTreatmentTypeByIdApi,
  postCreateTreatmentTypeApi,
  putUpdateTreatmentTypeApi,
};

async function getTreatmentTypeListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(apiConstants.apiGetTreatmentTypeList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getTreatmentTypeByIdApi(model) {
  const url = apiConstants.apiGetTreatmentTypeById + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreateTreatmentTypeApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostCreateTreatmentType,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateTreatmentTypeApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(apiConstants.apiPutUpdateTreatmentType, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default treatmentTypeApis;
