//lib
import React, { useState, useEffect, useRef } from "react";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  IconButton,
  Typography,
  Box,
  Link,
  CssBaseline,
  Collapse,
  Container,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
//actions
import { postLogin } from "../../../actions/authActions";
import { getSystemScheduleList } from "../../../actions/systemScheduleActions";
import { getSystemSettings } from "../../../actions/commonActions";
import { postProviderAccessToken } from "../../../actions/authActions";
//components
import { useStyles } from "../../../components/globalStyles";
import CopyRight from "../../../components/CopyRight";
import * as generalConstants from "../../../_constants/generalConstants";
import versionControl from "../../../../package.json";

import azureProviderSignIn from "./components/azureProviderSignIn";
//import { ReactComponent as MicrosoftLoginLogo } from "../../../assets/ms-symbollockup_signin_light.svg";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import StyledRadioCheckbox from "../../../components/StyledRadioCheckbox";

import * as pageConfigure from "../../../_constants/pageConstants";

// const msalConfig = {
//   auth: {
//     clientId: "9031796b-93f7-4cb3-97bc-4277f0f2b356",
//     authority:
//       "https://login.microsoftonline.com/85210ac4-576d-4583-b503-510f94c96993",
//     redirectUri: "/",
//   },
// };
// const msalInstance = new PublicClientApplication(msalConfig);
// await msalInstance.initialize();
export default function SignIn() {
  //variables

  const classes = useStyles();
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.userAuth);
  const systemSchedule = useSelector((state) => state.systemSchedule);

  const common = useSelector((state) => state.common);
  const [errorAlert, setErrorAlert] = useState(false);
  const [disableLogin, setDisableLogin] = useState(false);
  const [msgAlert, setMsgAlert] = useState("");

  const [errorVersionAlert, setErrorVersionAlert] = useState(false);
  const [msgVersionAlert, setMsgVersionAlert] = useState("");
  const prevSystemSettingsStatus = useRef();
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });
  const [TCMLogo, setTCMLOGO] = useState(null);
  const [loginUserList, setLoginUserList] = useState(null);
  const [loginUserListDialog, setLoginUserListDialog] = useState(false);
  const [user, setUser] = useState(null);
  const [userAccessToken, setUserAccessToken] = useState(null);
  const [missingUserError, setMissingUserError] = useState(false);

  const columns = [
    {
      Header: "",
      accessor: "checkVisitOrder",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <>
            <StyledRadioCheckbox
              checked={
                user !== null && user.id === row.original.id ? true : false
              }
              name="checkVisitOrder"
              onChange={(e) => handleVisitOrderCheck(e, row)}
            />
          </>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
  ];

  //useEffect
  useEffect(() => {
    const hostname = window.location.hostname.replace(/[^a-z0-9]/gi, "_");

    if (hostname) {
      let isMounted = true;
      import("../../../assets/" + hostname + "/TCMLogo.svg").then((image) => {
        if (isMounted) {
          setTCMLOGO(image.default);
        }
      });

      return () => {
        isMounted = false;
      };
    }
  }, []);

  useEffect(() => {
    //only display 10 msg
    dispatch(
      getSystemScheduleList.pending({
        is_pagination: true,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );

    dispatch(getSystemSettings.pending());

    //dispatch(getProviderAuthorize.pending("google"));

    //window.open("/api/v2/auth/authorize/google", "_self");

    // window.open(
    //   "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=221244284918-8qa9tvioqk30lr91ogpchjdlkufksgvo.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2Fapi%2Fv2%2Fauth%2Fcallback%2Fgoogle&scope=openid+email+profile",
    //   "_blank",
    //   "noreferrer"
    // );
  }, [dispatch]);

  useEffect(() => {
    if (
      common.isLoadingGetSystemSettings !== prevSystemSettingsStatus.current
    ) {
      //check the previous ref with current state
      prevSystemSettingsStatus.current = common.isLoadingGetSystemSettings;
      if (
        common.getSystemSettingsIsSuccess === true &&
        common.isLoadingGetSystemSettings === false
      ) {
        const packageUIVersion = versionControl.version
          .slice(1, 6)
          .toLowerCase();

        const packageVersion =
          common.getSystemSettings?.system_settings?.api_version
            ?.slice(0, 5)
            .toLowerCase();
        if (packageVersion !== packageUIVersion) {
          setErrorVersionAlert(true);
          setDisableLogin(true);
          setMsgVersionAlert(
            "A newer version is found, please reopen browser and refresh page again. 已找到更新版本，请重新打开浏览器并刷新页面。"
          );
        }

        if (packageVersion === packageUIVersion) {
          setErrorVersionAlert(false);
          setDisableLogin(false);
        }
      }
    }
  }, [common]);

  //function

  const handleVisitOrderCheck = (e, row) => {
    if (e.target.checked) {
      setUser(row.original);
    } else {
      setUser(null);
    }
  };
  const handleProviderLogin = async (provider) => {
    if ((provider = "azure")) {
      const response = await azureProviderSignIn();
      if (response.accessToken) {
        setUserAccessToken(response.accessToken);
        dispatch(
          postProviderAccessToken.pending({
            provider: "azure",
            access_token: response.accessToken,
          })
        );
      }
    }
  };

  const onConfirmSubmit = (event, action) => {
    if (userAccessToken && user) {
      const submissionJson = {
        provider: "azure",
        access_token: userAccessToken,
        user: {
          id: user.id,
        },
      };

      dispatch(postProviderAccessToken.pending(submissionJson));
    } else {
      setMissingUserError(true);
    }
  };

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  //function
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    dispatch(postLogin.pending(data));
  };

  //useEffects
  useEffect(() => {
    prevSystemSettingsStatus.current = false;
  }, []);
  useEffect(() => {
    setErrorAlert(false);

    if (userAuth.logInSuccess === false && userAuth.isLoadinglogIn === false) {
      if (userAuth.systemUserInfo === null) {
        setErrorAlert(true);
        setMsgAlert(userAuth.error?.logInError);
        if (
          userAuth.postOtpCallbackIsSuccess === false &&
          userAuth.isLodingPostOtpCallback === false
        ) {
          setMsgAlert("Invalid OTP Token or Password");
        }
      }
      if (userAuth.systemUserInfo?.users?.length > 1 && userAccessToken) {
        setLoginUserList(userAuth.systemUserInfo?.users);
        setLoginUserListDialog(true);
        setMissingUserError(false);
      }
    }
  }, [userAuth]);
  return (
    <Container component="main">
      {loginUserListDialog && (
        <ConfirmDialog
          Yes={"Yes"}
          No={"No"}
          title={"Confirmation"}
          textValue={""}
          open={loginUserListDialog}
          setOpen={setLoginUserListDialog}
          onConfirm={onConfirmSubmit}
          submitWithClose={false}>
          {missingUserError && (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMissingUserError(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              Missing User
            </Alert>
          )}
          <SortingSearchTable
            disabledSearch
            columns={columns}
            data={loginUserList}
            pageInfo={viewPageInfo}
            setPageInfo={setViewPageInfo}
            totalItems={1}
            disabledFooter
          />
        </ConfirmDialog>
      )}
      <CssBaseline />

      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}

      {systemSchedule.getSystemScheduleList?.system_schedules !== undefined &&
        systemSchedule.getSystemScheduleList?.system_schedules.map(
          (item, i) => (
            <Alert
              severity="warning"
              className={classes.notificationCenterView}
              key={i}>
              <Typography>
                {item.start_timestamp
                  ? moment
                      .utc(item.start_timestamp)
                      .local()
                      .format("DD-MMM-YYYY HH:mm")
                  : ""}{" "}
                {item.start_timestamp && item.end_timestamp && <>to </>}
                {item.end_timestamp
                  ? moment
                      .utc(item.end_timestamp)
                      .local()
                      .format("DD-MMM-YYYY HH:mm")
                  : ""}{" "}
              </Typography>
              {item.message}
            </Alert>
          )
        )}
      <div className={classes.paper}>
        <img src={TCMLogo} height="200px" weight="400px" alt="website logo" />
        <br />

        {!userAuth.isLodingPostProviderAccessToken &&
          !userAuth.isLodingPostOtpCallback && (
            <>
              <Typography
                className={classes.maroon100}
                component="h1"
                variant="h5">
                User Sign In
              </Typography>

              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                {!disableLogin && (
                  <Collapse in={errorAlert}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setErrorAlert(false);
                          }}>
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }>
                      {msgAlert}
                    </Alert>
                  </Collapse>
                )}

                <Collapse in={errorVersionAlert}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setErrorVersionAlert(false);
                        }}>
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }>
                    {msgVersionAlert}
                  </Alert>
                </Collapse>
                <br />
                {!disableLogin && (
                  <>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="Username"
                      name="username"
                      cy_data="username"
                      autoComplete="off"
                      {...register("username")}
                      autoFocus
                    />

                    <FormControl
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Password">
                      <InputLabel required htmlFor="password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        required
                        {...register("password")}
                        autoComplete="off"
                        cy_data="password"
                        id="password"
                        name="password"
                        label="Password"
                        type={values.showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large">
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <div className={classes.warningColor}>
                        {errors.password && <p>{errors.password.message}</p>}
                      </div>
                    </FormControl>
                    {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                    <Button
                      cy_data="loginInBtn"
                      id="loginInBtn"
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.buttonColor}>
                      Sign In
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Link
                          cy_data="forgotPasswordLink"
                          className={classes.maroon075}
                          href="/ResetRq"
                          variant="body2"
                          underline="hover">
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link
                          className={classes.maroon075}
                          cy_data="registrationLink"
                          href="/registration"
                          variant="body2"
                          underline="hover">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid>
                  </>
                )}
              </form>

              {/* <Button onClick={() => handleProviderLogin("azure")}>
                <MicrosoftLoginLogo title="Sign in with Microsoft" />
              </Button> */}
            </>
          )}
        {(userAuth.isLodingPostProviderAccessToken ||
          userAuth.isLodingPostOtpCallback) && <>Loading...</>}
        <Box mt={5}>
          <CopyRight />
        </Box>
      </div>
    </Container>
  );
}
