import * as types from "../_constants/notificationGroupConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_NOTIFICATIONGROUPBYID_ASYNC.PENDING:
      return {
        ...state,
        getNotificationGroupByIdIsSuccess: false,
        isLoadingGetNotificationGroupById: true,
        getNotificationGroupById: null,
      };

    case types.GET_NOTIFICATIONGROUPBYID_ASYNC.ERROR:
      return {
        ...state,
        getNotificationGroupByIdIsSuccess: false,
        isLoadingGetNotificationGroupById: false,
        getNotificationGroupById: null,
        error: {
          ...state.error,
          getNotificationGroupByIdError: action.error,
        },
      };

    case types.GET_NOTIFICATIONGROUPBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getNotificationGroupByIdIsSuccess: true,
        isLoadingGetNotificationGroupById: false,
        getNotificationGroupById: action.data,
      };

    case types.GET_NOTIFICATIONGROUPLIST_ASYNC.PENDING:
      return {
        ...state,
        getNotificationGroupListIsSuccess: false,
        isLoadingGetNotificationGroupList: true,
        getNotificationGroupList: null,
      };

    case types.GET_NOTIFICATIONGROUPLIST_ASYNC.ERROR:
      return {
        ...state,
        getNotificationGroupListIsSuccess: false,
        isLoadingGetNotificationGroupList: false,
        getNotificationGroupList: null,
        error: {
          ...state.error,
          getNotificationGroupListError: action.error,
        },
      };

    case types.GET_NOTIFICATIONGROUPLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getNotificationGroupListIsSuccess: true,
        isLoadingGetNotificationGroupList: false,
        getNotificationGroupList: action.data,
      };

    case types.POST_CREATENOTIFICATIONGROUP_ASYNC.PENDING:
      return {
        ...state,
        postCreateNotificationGroupIsSuccess: false,
        isLoadingPostCreateNotificationGroup: true,
        postCreateNotificationGroup: null,
      };

    case types.POST_CREATENOTIFICATIONGROUP_ASYNC.ERROR:
      return {
        ...state,
        postCreateNotificationGroupIsSuccess: false,
        isLoadingPostCreateNotificationGroup: false,
        postCreateNotificationGroup: null,
        error: {
          ...state.error,
          postCreateNotificationGroupError: action.error,
        },
      };

    case types.POST_CREATENOTIFICATIONGROUP_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateNotificationGroupIsSuccess: true,
        isLoadingPostCreateNotificationGroup: false,
        postCreateNotificationGroup: action.data,
      };

    case types.PUT_UPDATENOTIFICATIONGROUP_ASYNC.PENDING:
      return {
        ...state,
        putUpdateNotificationGroupIsSuccess: false,
        isLoadingPutUpdateNotificationGroup: true,
        putUpdateNotificationGroup: null,
      };

    case types.PUT_UPDATENOTIFICATIONGROUP_ASYNC.ERROR:
      return {
        ...state,
        putUpdateNotificationGroupIsSuccess: false,
        isLoadingPutUpdateNotificationGroup: false,
        putUpdateNotificationGroup: null,
        error: {
          ...state.error,
          putUpdateNotificationGroupError: action.error,
        },
      };

    case types.PUT_UPDATENOTIFICATIONGROUP_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateNotificationGroupIsSuccess: true,
        isLoadingPutUpdateNotificationGroup: false,
        putUpdateNotificationGroup: action.data,
      };

    default:
      return state;
  }
};
