import { createAsyncTypes } from "../_helper/Utilities";

export const GET_BRANCHADHOCSCHEDULELIST_ASYNC = createAsyncTypes(
  "GET_BRANCHADHOCSCHEDULELIST"
);

export const GET_BRANCHADHOCSCHEDULEBYID_ASYNC = createAsyncTypes(
  "GET_BRANCHADHOCSCHEDULEBYID"
);

export const POST_BRANCHADHOCSCHEDULEBYBRANCHID_ASYNC = createAsyncTypes(
  "POST_BRANCHADHOCSCHEDULEBYBRANCHID"
);

export const GET_BRANCHADHOCSCHEDULEBRANCHLIST_ASYNC = createAsyncTypes(
  "GET_BRANCHADHOCSCHEDULEBRANCHLIST"
);
