//lib
import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  IconButton,
  Typography,
  Box,
  Link,
  CssBaseline,
  Collapse,
  Container,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import debounce from "debounce";
//actions
import { postResetPassword } from "../../../actions/authActions";
//components
import { useStyles } from "../../../components/globalStyles";

import CopyRight from "../../../components/CopyRight";

export default function ResetPasswordRequest() {
  //variables
  const classes = useStyles();
  const dispatch = useDispatch();
  const parsed = queryString.parse(window.location.search);
  const userAuth = useSelector((state) => state.userAuth);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);

  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      token: parsed.token,
    },
  });
  //functions
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    dispatch(postResetPassword.pending(data));
  };

  const onConfirmDebounce = debounce(handleSubmit(onSubmit), 2000, true);
  const onConfirmDebounceCallBack = React.useCallback(onConfirmDebounce, [
    onConfirmDebounce,
  ]);
  //useEffects
  useEffect(() => {
    if (timeoutCountdown > 0 && disabledButton === true) {
      const timeout = setTimeout(() => {
        setTimeoutCountdown(timeoutCountdown - 1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [timeoutCountdown, disabledButton]);

  useEffect(() => {
    if (timeoutCountdown === 0) {
      setDisabledButton(false);
    }
  }, [timeoutCountdown]);

  useEffect(() => {
    setErrorAlert(false);
    setSuccessAlert(false);
    if (
      userAuth.resetPasswordSuccess === true &&
      userAuth.isLoadingResetPassword === false
    ) {
      setSuccessAlert(true);
      setMsgAlert(userAuth.userResetPassword);
      setDisabledButton(true);

      setTimeoutCountdown(10);
    }

    if (
      userAuth.resetPasswordSuccess === false &&
      userAuth.isLoadingResetPassword === false
    ) {
      setErrorAlert(true);
      setMsgAlert(userAuth.error.resetPasswordError);
    }
  }, [userAuth]);

  const [TCMLogo, setTCMLOGO] = React.useState(null);
  React.useEffect(() => {
    const hostname = window.location.hostname.replace(/[^a-z0-9]/gi, "_");

    if (hostname) {
      let isMounted = true;
      import("../../../assets/" + hostname + "/TCMLogo.svg").then((image) => {
        if (isMounted) {
          setTCMLOGO(image.default);
        }
      });

      return () => {
        isMounted = false;
      };
    }
  }, []);

  return (
    <Container component="main">
      <CssBaseline />
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}

      <div className={classes.paper}>
        <img src={TCMLogo} height="200px" width="400px" alt="website logo" />

        <Typography className={classes.maroon100} component="h1" variant="h5">
          Reset Password
        </Typography>

        <form className={classes.form}>
          <input hidden readOnly {...register("token")} />
          <Collapse in={successAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setSuccessAlert(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlert}
            </Alert>
          </Collapse>

          <Collapse in={errorAlert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlert(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlert}
            </Alert>
          </Collapse>
          <br />
          <Grid item>
            <FormControl variant="outlined" margin="normal" required fullWidth>
              <InputLabel required htmlFor="password">
                New Password
              </InputLabel>
              <OutlinedInput
                required
                {...register("password")}
                autoComplete="off"
                label="Password"
                name="password"
                type={values.showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large">
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div className={classes.warningColor}>
                {errors.password && <p>{errors.password.message}</p>}
              </div>
            </FormControl>
          </Grid>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.buttonColor}
            disabled={disabledButton}
            onClick={(e) => {
              e.currentTarget.form.reportValidity();
              var checkVal = e.currentTarget.form.checkValidity();
              if (checkVal) {
                onConfirmDebounceCallBack(e);
              }
            }}>
            Confirm {disabledButton && timeoutCountdown}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                className={classes.maroon075}
                href="/signIn"
                variant="body2"
                underline="hover">
                Already have an account? Sign in
              </Link>
            </Grid>
            <Grid item>
              <Link
                className={classes.maroon075}
                href="/registration"
                variant="body2"
                underline="hover">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <CopyRight />
          </Box>
        </form>
      </div>
    </Container>
  );
}
