//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Grid, TextField, Link, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";

//actions
import {
  putUpdateWikiData,
  getWikiDataSubNodeList,
} from "../../../../actions/wikiDataActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import SubNodeTable from "./Components/SubNodeTable";
import NodeItemTable from "./Components/NodeItemTable";
import * as pageConfigure from "../../../../_constants/pageConstants";
import StyledCheckbox from "../../../../components/StyledCheckbox";
import AddSubnodeDialog from "./Components/AddSubnodeDialog";

export const EditWikiDataPage = ({
  wikiData,
  setEditWikiDataView,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);

  const [openAddSubnodeDialog, setOpenAddSubnodeDialog] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  const [subnodesList, setSubnodesList] = useState(wikiData.subnodes);
  const [msgAlarm, setMsgAlarm] = useState("");
  const wikiDataData = useSelector((state) => state.wikiData);
  const common = useSelector((state) => state.common);
  const prevSubmitStatus = useRef();
  const prevEditSubNodeStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);
  const [temWikiData, setTemWikiData] = useState(wikiData);

  const [pathList, setPathList] = useState([
    { id: wikiData.id, name: wikiData.name },
  ]);

  const [confirmRedirect, setConfirmRedirect] = useState(false);

  const [redirectJsonText, setRedirectJsonText] = useState(null);
  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };
  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    delete temWikiData.id;
    delete temWikiData.desc;
    delete temWikiData.name;
    delete temWikiData.status_type;
    delete temWikiData.subnodes;
    const submitJson = {
      wiki_data: {
        ...wikiData,
        ...data,
        ...temWikiData,
        subnodes: subnodesList,
      },
    };
    dispatch(putUpdateWikiData.pending(submitJson));
  };

  const handleSubNodes = (subValue, action) => {
    if (action === "delete") {
      setSubnodesList((value) => value.filter((item) => item.id !== subValue));
    }

    if (action === "add") {
      setSubnodesList((value) =>
        value.filter((item) => item.id !== subValue.id)
      );
      setSubnodesList((value) => [...value, subValue]);
    }
  };

  const handleRedirectSubNode = (value, index) => {
    setRedirectJsonText({ value: value, index: index });
    if (temViewOnly) {
      const redirectItem = { value: value, index: index };
      confirmRedirectSubNode(redirectItem);
    } else {
      setConfirmRedirect(true);
    }
  };

  const confirmRedirectSubNode = (redirectItem) => {
    let callRedirectItem = null;
    if (temViewOnly) {
      callRedirectItem = redirectItem;
    } else {
      callRedirectItem = redirectJsonText;
    }
    setPathList((item) => item.slice(0, callRedirectItem.index + 1));

    dispatch(
      getWikiDataSubNodeList.pending({
        order_by_direction: "Asc",
        order_by_field: "name",
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        is_pagination: true,
        search_desc_value: null,
        search_id_value: callRedirectItem.value.id,
        search_name_value: "",
        search_root_node: null,
        search_status_type_value: null,
        search_subnode_value: null,
        view_format_value: pageConfigure.WIKI_LEVEL_VIEW,
      })
    );
    // setEditWikiDataView(true);
  };

  const { register, handleSubmit, reset, control, watch } = useForm({
    defaultValues: {
      id: wikiData?.id,
      name: wikiData?.name,
      is_root_node: wikiData?.is_root_node,
      status_type: wikiData?.status_type,
    },
  });
  useEffect(() => {
    //set ref when first render
    prevSubmitStatus.current = false;
    prevEditSubNodeStatus.current = false;
  }, []);

  useEffect(() => {
    if (wikiDataData.isLoadingPutUpdateWikiData !== prevSubmitStatus.current) {
      //check the previous ref with current state
      prevSubmitStatus.current = wikiDataData.isLoadingPutUpdateWikiData;

      if (
        wikiDataData.putUpdateWikiDataIsSuccess === true &&
        wikiDataData.isLoadingPutUpdateWikiData === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...wikiDataData.putUpdateWikiData.wiki_data,
        });
        setSubnodesList(wikiDataData.putUpdateWikiData?.wiki_data.subnodes);
        setTemWikiData({
          ...wikiDataData.putUpdateWikiData?.wiki_data,
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        wikiDataData.putUpdateWikiDataIsSuccess === false &&
        wikiDataData.isLoadingPutUpdateWikiData === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(wikiDataData.error.putUpdateWikiDataError);
        window.scrollTo(0, 0);
      }
    }
  }, [wikiDataData, reset]);

  useEffect(() => {
    if (
      wikiDataData.isLoadingGetWikiDataSubNodeList !==
      prevEditSubNodeStatus.current
    ) {
      //check the previous ref with current state
      prevEditSubNodeStatus.current =
        wikiDataData.isLoadingGetWikiDataSubNodeList;

      if (
        wikiDataData.getWikiDataSubNodeListIsSuccess === true &&
        wikiDataData.isLoadingGetWikiDataSubNodeList === false
      ) {
        reset({
          ...wikiDataData.getWikiDataSubNodeList?.wiki_datas[0],
        });
        setSubnodesList(
          wikiDataData.getWikiDataSubNodeList?.wiki_datas[0].subnodes
        );
        setTemWikiData({
          ...wikiDataData.getWikiDataSubNodeList?.wiki_datas[0],
        });
      }
      if (
        wikiDataData.getWikiDataSubNodeListIsSuccess === false &&
        wikiDataData.isLoadingGetWikiDataSubNodeList === false
      ) {
      }
    }
  }, [wikiDataData, reset]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:Wiki Data Details")
          : t("translation:Edit Wiki Data")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={redirectJsonText}
        open={confirmRedirect}
        setOpen={setConfirmRedirect}
        onConfirm={confirmRedirectSubNode}>
        {t(
          "translation:Please make sure you have submitted your changes before go to another node."
        )}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Wiki Data has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />
      <AddSubnodeDialog
        openAddSubnodeDialog={openAddSubnodeDialog}
        setOpenAddSubnodeDialog={setOpenAddSubnodeDialog}
        setData={handleSubNodes}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <input hidden readOnly {...register("id")} />

        <Grid className={classes.root} container>
          {pathList.map((value, index) => (
            <span key={index}>
              <Link className={classes.boldMaroon075}>
                <Button
                  className={classes.boldMaroon075}
                  onClick={() => handleRedirectSubNode(value, index)}
                  sx={{ textTransform: "none" }}>
                  {value.name} /
                </Button>
              </Link>
            </span>
          ))}
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              required
              name="name"
              label={t("translation:Name")}
              variant="outlined"
              autoComplete="off"
              {...register("name")}
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      disabled={true}
                      checked={field.value}
                      onChange={(e, option) => {
                        field.onChange(option);
                        setSubnodesList([]);
                      }}
                      name="is_root_node"
                    />
                  }
                  label={t("translation:Is Root Node")}
                />
              )}
              name="is_root_node"
              control={control}
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly || !watch().is_root_node}
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <NodeItemTable
              data={temWikiData}
              setData={setTemWikiData}
              temViewOnly={temViewOnly}
            />
          </Grid>
          <Grid className={classes.root} container>
            <Grid item xs={11}></Grid>
            {!temViewOnly && (
              <Grid item className={classes.userInfoField} xs={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonColor}
                  onClick={() => setOpenAddSubnodeDialog(true)}>
                  {t("translation:ADD SUBNODE")}
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <SubNodeTable
              data={subnodesList}
              setData={handleSubNodes}
              setPathList={setPathList}
              temViewOnly={temViewOnly}
            />
          </Grid>

          <>
            <RequiredNote />

            {temViewOnly ? (
              <PageOnlyBackAction setView={() => setEditWikiDataView(false)} />
            ) : (
              <PageSubmitWBackAction
                setView={() => setEditWikiDataView(false)}
              />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
