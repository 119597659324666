import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getBranchAdhocScheduleList,
  getBranchAdhocScheduleById,
  postBranchAdhocScheduleByBranchId,
  getBranchAdhocScheduleBranchList,
} from "../actions/branchAdhocScheduleActions";
import * as types from "../_constants/branchAdhocScheduleConstants";
import * as notification from "../_constants/notificationConstants";
import branchAdhocScheduleApis from "../_apis/branchAdhocScheduleApis";
export default function* branchAdhocScheduleSagas() {
  yield all([
    takeLatest(
      types.GET_BRANCHADHOCSCHEDULELIST_ASYNC.PENDING,
      getBranchAdhocScheduleListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_BRANCHADHOCSCHEDULEBYID_ASYNC.PENDING,
      getBranchAdhocScheduleByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHADHOCSCHEDULEBYBRANCHID_ASYNC.PENDING,
      postBranchAdhocScheduleByBranchIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_BRANCHADHOCSCHEDULEBRANCHLIST_ASYNC.PENDING,
      getBranchAdhocScheduleBranchListCall
    ),
  ]);
}

function* getBranchAdhocScheduleListCall(Model) {
  try {
    const data = yield call(
      branchAdhocScheduleApis.getBranchAdhocScheduleListApi,
      Model.data
    );
    yield put(getBranchAdhocScheduleList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchAdhocScheduleList.error(error.response?.data?.message)
      );
    else
      yield put(
        getBranchAdhocScheduleList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getBranchAdhocScheduleByIdCall(Model) {
  try {
    const data = yield call(
      branchAdhocScheduleApis.getBranchAdhocScheduleByIdApi,
      Model.data
    );
    yield put(getBranchAdhocScheduleById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchAdhocScheduleById.error(error.response?.data?.message)
      );
    else
      yield put(
        getBranchAdhocScheduleById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postBranchAdhocScheduleByBranchIdCall(Model) {
  try {
    const data = yield call(
      branchAdhocScheduleApis.postBranchAdhocScheduleByBranchIdApi,
      Model.data
    );
    yield put(postBranchAdhocScheduleByBranchId.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchAdhocScheduleByBranchId.error(error.response?.data?.message)
      );
    else
      yield put(
        postBranchAdhocScheduleByBranchId.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getBranchAdhocScheduleBranchListCall(Model) {
  try {
    const data = yield call(
      branchAdhocScheduleApis.getBranchAdhocScheduleBranchListApi,
      Model.data
    );
    yield put(getBranchAdhocScheduleBranchList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchAdhocScheduleBranchList.error(error.response?.data?.message)
      );
    else
      yield put(
        getBranchAdhocScheduleBranchList.error(notification.CONST_NOINTERNET)
      );
  }
}
