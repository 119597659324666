import * as types from "../_constants/branchCourierConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_BRANCHCOURIERBYID_ASYNC.PENDING:
      return {
        ...state,
        getBranchCourierByIdIsSuccess: false,
        isLoadingGetBranchCourierById: true,
        getBranchCourierById: null,
      };

    case types.GET_BRANCHCOURIERBYID_ASYNC.ERROR:
      return {
        ...state,
        getBranchCourierByIdIsSuccess: false,
        isLoadingGetBranchCourierById: false,
        getBranchCourierById: null,
        error: { ...state.error, getBranchCourierByIdError: action.error },
      };

    case types.GET_BRANCHCOURIERBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchCourierByIdIsSuccess: true,
        isLoadingGetBranchCourierById: false,
        getBranchCourierById: action.data,
      };

    case types.GET_BRANCHCOURIERLIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchCourierListIsSuccess: false,
        isLoadingGetBranchCourierList: true,
        getBranchCourierList: null,
      };

    case types.GET_BRANCHCOURIERLIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchCourierListIsSuccess: false,
        isLoadingGetBranchCourierList: false,
        getBranchCourierList: null,
        error: { ...state.error, getBranchCourierListError: action.error },
      };

    case types.GET_BRANCHCOURIERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchCourierListIsSuccess: true,
        isLoadingGetBranchCourierList: false,
        getBranchCourierList: action.data,
      };

    case types.PUT_UPDATEBRANCHCOURIER_ASYNC.PENDING:
      return {
        ...state,
        putUpdateBranchCourierIsSuccess: false,
        isLoadingPutUpdateBranchCourier: true,
        putUpdateBranchCourier: null,
      };

    case types.PUT_UPDATEBRANCHCOURIER_ASYNC.ERROR:
      return {
        ...state,
        putUpdateBranchCourierIsSuccess: false,
        isLoadingPutUpdateBranchCourier: false,
        putUpdateBranchCourier: null,
        error: { ...state.error, putUpdateBranchCourierError: action.error },
      };

    case types.PUT_UPDATEBRANCHCOURIER_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateBranchCourierIsSuccess: true,
        isLoadingPutUpdateBranchCourier: false,
        putUpdateBranchCourier: action.data,
      };

    case types.POST_BRANCHCOURIERCOURIERLIST_ASYNC.PENDING:
      return {
        ...state,
        postBranchCourierCourierListIsSuccess: false,
        isLoadingPostBranchCourierCourierList: true,
        postBranchCourierCourierList: null,
      };

    case types.POST_BRANCHCOURIERCOURIERLIST_ASYNC.ERROR:
      return {
        ...state,
        postBranchCourierCourierListIsSuccess: false,
        isLoadingPostBranchCourierCourierList: false,
        postBranchCourierCourierList: null,
        error: {
          ...state.error,
          postBranchCourierCourierListError: action.error,
        },
      };

    case types.POST_BRANCHCOURIERCOURIERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchCourierCourierListIsSuccess: true,
        isLoadingPostBranchCourierCourierList: false,
        postBranchCourierCourierList: action.data,
      };
    case types.POST_BRANCHCOURIERBRANCHLIST_ASYNC.PENDING:
      return {
        ...state,
        postBranchCourierBranchListIsSuccess: false,
        isLoadingPostBranchCourierBranchList: true,
        postBranchCourierBranchList: null,
      };

    case types.POST_BRANCHCOURIERBRANCHLIST_ASYNC.ERROR:
      return {
        ...state,
        postBranchCourierBranchListIsSuccess: false,
        isLoadingPostBranchCourierBranchList: false,
        postBranchCourierBranchList: null,
        error: {
          ...state.error,
          postBranchCourierBranchListError: action.error,
        },
      };

    case types.POST_BRANCHCOURIERBRANCHLIST_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchCourierBranchListIsSuccess: true,
        isLoadingPostBranchCourierBranchList: false,
        postBranchCourierBranchList: action.data,
      };

    case types.POST_BRANCHCOURIERUSERDEVICEKEYLIST_ASYNC.PENDING:
      return {
        ...state,
        postBranchCourierUserDeviceKeyListIsSuccess: false,
        isLoadingPostBranchCourierUserDeviceKeyList: true,
        postBranchCourierUserDeviceKeyList: null,
      };

    case types.POST_BRANCHCOURIERUSERDEVICEKEYLIST_ASYNC.ERROR:
      return {
        ...state,
        postBranchCourierUserDeviceKeyListIsSuccess: false,
        isLoadingPostBranchCourierUserDeviceKeyList: false,
        postBranchCourierUserDeviceKeyList: null,
        error: {
          ...state.error,
          postBranchCourierUserDeviceKeyListError: action.error,
        },
      };

    case types.POST_BRANCHCOURIERUSERDEVICEKEYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchCourierUserDeviceKeyListIsSuccess: true,
        isLoadingPostBranchCourierUserDeviceKeyList: false,
        postBranchCourierUserDeviceKeyList: action.data,
      };
    default:
      return state;
  }
};
