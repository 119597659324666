import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//components
import { useStyles } from "../../../../../components/globalStyles";
export default function ViewProceedDay({ memoSelectedGroup }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.userInfoField}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className={classes.userFieldColor}
          inputVariant="outlined"
          id="date"
          label={t("translation:Proceed Date")}
          autoOk={true}
          disabled
          readOnly
          isRequired
          value={
            memoSelectedGroup &&
            dayjs(memoSelectedGroup.proceed_on, true).isValid()
              ? dayjs(memoSelectedGroup.proceed_on)
              : null
          }
          slotProps={{
            textField: { required: true },
          }}
          format="DD MMM YYYY"
        />
      </LocalizationProvider>
    </Grid>
  );
}
