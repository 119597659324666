//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

//Actions
import {
  getMedicineFixedSellingPriceSummaryList,
  getMedicineFixedSellingPriceById,
} from "../../../../actions/medicineFixedSellingPriceActions";

//Component
import { EditMedicineFixedSellingPricePage } from "./EditMedicineFixedSellingPricePage";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";

export default function MedicineFixedSellingPriceListTable({
  setInformationView,
}) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_name_value: "",
  });

  const dispatch = useDispatch();

  const medicineFixedSellingPriceData = useSelector(
    (state) => state.medicineFixedSellingPrice
  );

  const common = useSelector((state) => state.common);
  //Init
  const [
    editMedicineFixedSellingPriceView,
    setEditMedicineFixedSellingPriceView,
  ] = React.useState(false);
  const [
    medicineFixedSellingPriceDetailView,
    setMedicineFixedSellingPriceDetailView,
  ] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  //Onclick event
  const handleRowEdit = (value) => {
    dispatch(getMedicineFixedSellingPriceById.pending(value));
    setEditMedicineFixedSellingPriceView(true);
  };

  const handleRowDetail = (value) => {
    dispatch(getMedicineFixedSellingPriceById.pending(value));
    setMedicineFixedSellingPriceDetailView(true);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
      });
    }
  };

  //useEffects
  useEffect(() => {
    if (
      medicineFixedSellingPriceDetailView === true ||
      editMedicineFixedSellingPriceView === true
    ) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [
    medicineFixedSellingPriceDetailView,
    editMedicineFixedSellingPriceView,
    setInformationView,
  ]);

  useEffect(() => {
    if (!editMedicineFixedSellingPriceView) {
      dispatch(getMedicineFixedSellingPriceSummaryList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editMedicineFixedSellingPriceView]);

  if (
    medicineFixedSellingPriceData.getMedicineFixedSellingPriceSummaryListIsSuccess ===
      false &&
    medicineFixedSellingPriceData.isLoadingGetMedicineFixedSellingPriceSummaryList ===
      false
  ) {
    return (
      <Alert severity="error">
        {
          medicineFixedSellingPriceData.error
            .getMedicineFixedSellingPriceSummaryListError
        }
      </Alert>
    );
  } else if (
    common.generalStatusList &&
    !editMedicineFixedSellingPriceView &&
    !medicineFixedSellingPriceDetailView
  ) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name")}
        searchValue={searchValue}
        columns={columns}
        data={
          medicineFixedSellingPriceData.getMedicineFixedSellingPriceSummaryList
            ?.medicine_fixed_selling_price_headers !== undefined
            ? medicineFixedSellingPriceData
                .getMedicineFixedSellingPriceSummaryList
                ?.medicine_fixed_selling_price_headers
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          medicineFixedSellingPriceData.getMedicineFixedSellingPriceSummaryList
            ? medicineFixedSellingPriceData
                .getMedicineFixedSellingPriceSummaryList.total_pages
            : 0
        }
        loading={
          medicineFixedSellingPriceData.isLoadingMedicineFixedSellingPriceList
        }
        totalItems={
          medicineFixedSellingPriceData.getMedicineFixedSellingPriceSummaryList
            ? medicineFixedSellingPriceData
                .getMedicineFixedSellingPriceSummaryList.total_items
            : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (
    medicineFixedSellingPriceData.getMedicineFixedSellingPriceById &&
    medicineFixedSellingPriceDetailView &&
    medicineFixedSellingPriceData.getMedicineFixedSellingPriceByIdIsSuccess ===
      true
  ) {
    return (
      <EditMedicineFixedSellingPricePage
        medicineFixedSellingPrice={
          medicineFixedSellingPriceData.getMedicineFixedSellingPriceById
            .medicine_fixed_selling_price_header
        }
        setEditMedicineFixedSellingPriceView={
          setMedicineFixedSellingPriceDetailView
        }
        viewOnly
      />
    );
  } else if (
    medicineFixedSellingPriceData.getMedicineFixedSellingPriceById &&
    editMedicineFixedSellingPriceView &&
    medicineFixedSellingPriceData.getMedicineFixedSellingPriceByIdIsSuccess ===
      true
  ) {
    return (
      <EditMedicineFixedSellingPricePage
        medicineFixedSellingPrice={
          medicineFixedSellingPriceData.getMedicineFixedSellingPriceById
            .medicine_fixed_selling_price_header
        }
        setEditMedicineFixedSellingPriceView={
          setEditMedicineFixedSellingPriceView
        }
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
