//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//component

import AddMedicineTypePage from "./AddMedicineTypePage";
import MedicineTypeListTable from "./MedicineTypeListTable";
import * as generalConstants from "../../../../_constants/generalConstants";

import { useStyles } from "../../../../components/globalStyles";

const MedicineTypeSummaryPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const [informationView, setInformationView] = React.useState(false);
  const [medicineTypeListView, setMedicineTypeListView] = React.useState(true); //setUserListView
  const curUser = useSelector((state) => state.curUser);
  const addAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_MEDICINE_TYPE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    : false;

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!informationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            {medicineTypeListView ? (
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Medicine Type Summary")}
              </Typography>
            ) : (
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Add Medicine Type")}
              </Typography>
            )}
          </Grid>

          {medicineTypeListView && addAccess ? (
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonColor}
                onClick={() => setMedicineTypeListView(!medicineTypeListView)}>
                {t("translation:Add")}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={1}></Grid>
          )}
        </Grid>
      )}
      <hr />
      {!medicineTypeListView && (
        <AddMedicineTypePage
          setMedicineTypeListView={setMedicineTypeListView}
        />
      )}
      {medicineTypeListView && (
        <MedicineTypeListTable setInformationView={setInformationView} />
      )}
    </Paper>
  );
};

export default MedicineTypeSummaryPage;
