//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  IconButton,
  Typography,
  Box,
  Link,
  CssBaseline,
  Collapse,
  Container,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
// import { useTranslation } from "react-i18next";

//actions
import { postSelfRegistration } from "../../../actions/authActions";
import {
  getGender,
  getNationality,
  getMarital,
  getRace,
  getLanguage,
} from "../../../actions/commonActions";

//components
import { useStyles } from "../../../components/globalStyles";
import allowOnlyNumericsOrDigits from "../../../components/functions/allowOnlyNumericsOrDigits";
import externetApis from "../../../_apis/externetApis";
import CopyRight from "../../../components/CopyRight";

import * as organisationCodeConstants from "../../../_constants/organisationCodeConstants";

export default function Registration() {
  // const { t } = useTranslation();
  // {t("translation:welcome2")}

  //variables

  const classes = useStyles();
  const dispatch = useDispatch();
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState("");
  const [values, setValues] = React.useState({
    showPassword: false,
    age: "",
  });

  const currDate = moment().format("YYYY-MM-DD");
  const common = useSelector((state) => state.common);
  const prevPostNewUser = useRef();
  const userAuth = useSelector((state) => state.userAuth);
  const {
    register,
    handleSubmit,
    setValue,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: {
      marital_type: null,
      gender_type: null,
      races: {},
      nationalities: {},
      language: {},
    },
  });
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open;
  const [postcodeSearchPageNum, setPostcodeSearchPageNum] = useState(null);
  const [postcodeSearch, setPostcodeSearch] = useState(null);
  const [addressDisabled, setAddressDisabled] = React.useState(false);
  const [selectedPostalCode, setSelectedPostalCode] = useState("");
  //functions

  const handlePostalCodeChange = (event) => {
    setSelectedPostalCode(event.target.value);
    if (event.target.value.length === 6) {
      setOpen(true);
      setPostcodeSearch(event.target.value);
      setOptions([]);
      setPostcodeSearchPageNum(1);
    }
    setAddressDisabled(false);
    setValue("address", null);
    setValue("blk_no", null);
    setValue("road_name", null);
    setValue("building", null);
    setValue("postal_code", null);
  };

  const handleSelectedPostalCode = (event) => {
    if (addressDisabled) {
      setSelectedPostalCode(event.target.value);
    } else {
      setSelectedPostalCode("");
    }
  };

  const handleAddressChange = (option) => {
    if (option) {
      setAddressDisabled(true);

      // const addressWithoutPostalIndex = option.ADDRESS.lastIndexOf("SINGAPORE");
      // const addressWithoutPostal = option.ADDRESS.substring(
      //   0,
      //   addressWithoutPostalIndex - 1
      // );

      setValue("address", option.ADDRESS);
      setValue("blk_no", option.BLK_NO);
      setValue("road_name", option.ROAD_NAME);
      setValue("building", option.BUILDING !== "NIL" ? option.BUILDING : null);
      setSelectedPostalCode(option.POSTAL);
      setValue("postal_code", option.POSTAL);
    }
  };

  const getGenderOpObj = (option) => {
    if (!option.id)
      option = common.genderList.gender.find((op) => op.id === option);
    return option;
  };

  const getMaritalOpObj = (option) => {
    if (!option.id)
      option = common.maritalList.marital.find((op) => op.id === option);
    return option;
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleDateOfBirth = (event) => {
    var birthYear = moment(event.target.value, "YYYY-MM-DD");
    var userAge = Math.floor(moment().diff(birthYear, "years", true));

    setValues({ ...values, age: userAge ? userAge : 0 });
    setValue("date_of_birth", event.target.value);
  };

  const onSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const hostname = window.location.hostname.replace(/[^a-z0-9]/gi, "_");

    const submitJson = {
      organisation: {
        codename: organisationCodeConstants[hostname + "_code"],
      },
      ...data,
    };
    dispatch(postSelfRegistration.pending(submitJson));
  };

  //useEffects
  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }
    if (postcodeSearch) {
      (async () => {
        const response = await externetApis.getPostalCodeListApi(
          postcodeSearch,
          postcodeSearchPageNum
        );

        if (response.status === 200) {
          const addressResults = response.data;
          if (addressResults.results && addressResults.results.length > 0) {
            if (addressResults.totalNumPages > addressResults.pageNum) {
              setPostcodeSearchPageNum(addressResults.pageNum + 1);
            }

            if (addressResults.pageNum === 1) {
              setOptions(addressResults.results);
            } else {
              setOptions((optionsResult) => {
                return [...optionsResult, ...addressResults.results];
              });
            }
          } else {
            setOpen(false);
          }
        } else {
          setOpen(false);
        }
      })();
    }
  }, [loading, postcodeSearch, postcodeSearchPageNum]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  useEffect(() => {
    dispatch(getRace.pending());
    dispatch(getMarital.pending());
    dispatch(getNationality.pending());
    dispatch(getGender.pending());
    dispatch(getLanguage.pending());
    register("race");
    register("marital_type");
    register("nationality");
    register("gender_type");
    register("language");
  }, [register, dispatch]);

  useEffect(() => {
    //set ref when first render

    prevPostNewUser.current = false;
  }, []);

  useEffect(() => {
    if (userAuth.isLoadingSelfRegistration !== prevPostNewUser.current) {
      //check the previous ref with current state
      prevPostNewUser.current = userAuth.isLoadingSelfRegistration;
      if (
        userAuth.postSelfRegistrationSuccess === true &&
        userAuth.isLoadingSelfRegistration === false
      ) {
        setSuccessAlert(true);
        setErrorAlert(false);
        window.scrollTo(0, 0);
      }
      if (
        userAuth.postSelfRegistrationSuccess === false &&
        userAuth.isLoadingSelfRegistration === false
      ) {
        setErrorAlert(true);
        setSuccessAlert(false);
        setMsgAlert(userAuth.error.selfRegistrationError);
        window.scrollTo(0, 0);
      }
    }
  }, [userAuth]);

  const [TCMLogo, setTCMLOGO] = React.useState(null);
  React.useEffect(() => {
    const hostname = window.location.hostname.replace(/[^a-z0-9]/gi, "_");

    if (hostname) {
      let isMounted = true;
      import("../../../assets/" + hostname + "/TCMLogo.svg").then((image) => {
        if (isMounted) {
          setTCMLOGO(image.default);
        }
      });

      return () => {
        isMounted = false;
      };
    }
  }, []);

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={TCMLogo} height="200px" width="400px" alt="website logo" />
        <Typography className={classes.maroon100} component="h1" variant="h5">
          User Registration
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Collapse in={successAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setSuccessAlert(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              Account has been created
            </Alert>
          </Collapse>

          <Collapse in={errorAlert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlert(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlert}
            </Alert>
          </Collapse>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="off"
                {...register("username")}
                autoFocus
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                margin="normal"
                required
                fullWidth>
                <InputLabel required htmlFor="password">
                  Password
                </InputLabel>
                <OutlinedInput
                  required
                  {...register("password")}
                  autoComplete="off"
                  label="Password"
                  name="password"
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large">
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <div className={classes.warningColor}>
                  {errors.password && <p>{errors.password.message}</p>}
                </div>
              </FormControl>
            </Grid>
          </Grid>

          <Box mt={5}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="name"
                  label="Name"
                  autoComplete="off"
                  {...register("name")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="identification_number"
                  label="Identification Number"
                  autoComplete="off"
                  {...register("identification_number")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {common.genderList && (
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={common.genderList.gender}
                        isOptionEqualToValue={(option, value) => {
                          if (option.id === value) return option;
                        }}
                        getOptionLabel={(option) => getGenderOpObj(option).name}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Gender"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                        onChange={(_, data) =>
                          field.onChange(data ? data.id : null)
                        }
                      />
                    )}
                    name="gender_type"
                    control={control}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type="date"
                  {...register("date_of_birth")}
                  name="date_of_birth"
                  label="Date of Birth"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  onChange={handleDateOfBirth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { max: currDate },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="Age"
                  label="Age"
                  autoComplete="off"
                  value={values.age}
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" margin="normal" fullWidth>
                  <TextField
                    name="email"
                    label="Email Address"
                    variant="outlined"
                    {...register("email", {
                      pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: "Email is not valid",
                      },
                    })}
                  />

                  {errors.email && (
                    <div className={classes.warningColor}>
                      <p>{errors.email.message}</p>
                    </div>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                {/* <Autocomplete
                      name="postal_code"
                      options={common.raceList.races}
                      getOptionLabel={(option) => option.desc}
                      style={{ width: "100%" }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(e, option) =>
                        setValue("race", option == null ? {} : option)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Race"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    /> */}
                <Autocomplete
                  style={{ width: "100%" }}
                  name="postal_code"
                  disableClearable
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                  isOptionEqualToValue={() => {
                    return true;
                  }}
                  inputValue={selectedPostalCode ? selectedPostalCode : ""}
                  getOptionLabel={(option) => (option ? option.ADDRESS : "")}
                  onChange={(e, option) => handleAddressChange(option)}
                  options={options}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      name="postal_code"
                      autoComplete="off"
                      onInput={allowOnlyNumericsOrDigits}
                      {...register("postal_code")}
                      {...params}
                      onChange={handlePostalCodeChange}
                      onBlur={handleSelectedPostalCode}
                      label="Postal Code"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                {/* <Autocomplete
                    name="postal_code"
                    options={address}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.ADDRESS
                    }
                    filterOptions={(x) => x}
                    style={{ width: "100%" }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        onClick={handleAddress}
                        {...params}
                        label="Postal Code"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  /> */}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="address_details"
                  placeholder="Floor/ Unit Number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Address Details"
                  autoComplete="off"
                  {...register("address_details")}
                />
              </Grid>

              <Controller
                name="blk_no"
                render={({ field }) => {
                  if (field.value) {
                    return (
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          {...field}
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled",
                          }}
                          classes={{ root: "Mui-disabled" }}
                          name="blk_no"
                          label={"Block No"}
                          autoComplete="off"
                          {...register("blk_no")}
                          InputLabelProps={{
                            shrink: field.value ? true : false,
                          }}
                        />
                      </Grid>
                    );
                  }
                }}
                control={control}
              />

              <Controller
                name="road_name"
                render={({ field }) => {
                  if (field.value) {
                    return (
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled",
                          }}
                          classes={{ root: "Mui-disabled" }}
                          name="road_name"
                          autoComplete="off"
                          label={"Road Name"}
                          {...register("road_name")}
                          InputLabelProps={{
                            shrink: field.value ? true : false,
                          }}
                        />
                      </Grid>
                    );
                  }
                }}
                control={control}
              />

              <Controller
                name="building"
                render={({ field }) => {
                  if (field.value) {
                    return (
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            className: "Mui-disabled",
                          }}
                          classes={{ root: "Mui-disabled" }}
                          autoComplete="off"
                          name="building"
                          label={"Building"}
                          {...register("building")}
                          InputLabelProps={{
                            shrink: field.value ? true : false,
                          }}
                        />
                      </Grid>
                    );
                  }
                }}
                control={control}
              />
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  InputProps={{
                    readOnly: addressDisabled,
                    className: addressDisabled ? "Mui-disabled" : undefined,
                  }}
                  autoComplete="off"
                  classes={addressDisabled ? { root: "Mui-disabled" } : {}}
                  minRows={3}
                  name="address"
                  label="Address"
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("address")}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                {common.maritalList && (
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={common.maritalList.marital}
                        isOptionEqualToValue={(option, value) => {
                          if (option.id === value) return option;
                        }}
                        getOptionLabel={(option) =>
                          getMaritalOpObj(option).name
                        }
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Marital Status"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                        onChange={(_, data) =>
                          field.onChange(data ? data.id : null)
                        }
                      />
                    )}
                    name="marital_type"
                    control={control}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  name="contact_number"
                  label="Contact Number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  {...register("contact_number")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="occupation"
                  label="Occupation"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  {...register("occupation")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {common.nationalityList && (
                  <Autocomplete
                    name="nationalities"
                    options={common.nationalityList.nationalities.sort((a, b) =>
                      a.desc.localeCompare(b.desc)
                    )}
                    getOptionLabel={(option) => option.desc}
                    style={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, option) =>
                      setValue("nationality", option == null ? {} : option)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nationality"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                {common.raceList && (
                  <Autocomplete
                    name="race"
                    options={common.raceList.races}
                    getOptionLabel={(option) => option.desc}
                    style={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, option) =>
                      setValue("race", option == null ? {} : option)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Race"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                {common.languageList && (
                  <Autocomplete
                    name="language"
                    id="language"
                    options={common.languageList.languages}
                    getOptionLabel={(option) => option.desc}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    style={{ width: "100%" }}
                    onChange={(e, option) =>
                      setValue("language", option == null ? {} : option)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Language"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="nok_contact_name"
                  label="Next of Kin"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  {...register("nok_contact_name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="nok_contact_number"
                  label="Next of Kin's Contact Number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  {...register("nok_contact_number")}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mt={5}>
            <Grid item xs={12}>
              <TextField
                multiline
                minRows={3}
                name="allergy"
                label="Allergy"
                variant="outlined"
                margin="normal"
                fullWidth
                {...register("allergy")}
              />
            </Grid>
            <Grid item xs={12}>
              Required fields in this form are marked{" "}
              <span className={classes.maroon075}>*</span>.
            </Grid>
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.buttonColor}>
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                className={classes.maroon075}
                href="/signIn"
                variant="body2"
                underline="hover">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <CopyRight />
      </Box>
    </Container>
  );
}
