import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/courierConstants";

export const getCourierById = {
  pending: (data) =>
    createAction(types.GET_COURIERBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_COURIERBYID_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_COURIERBYID_ASYNC.ERROR, { error }),
};

export const getCourierList = {
  pending: (data) =>
    createAction(types.GET_COURIERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_COURIERLIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_COURIERLIST_ASYNC.ERROR, { error }),
};

export const postCreateCourier = {
  pending: (data) =>
    createAction(types.POST_CREATECOURIER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATECOURIER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATECOURIER_ASYNC.ERROR, { error }),
};

export const putUpdateCourier = {
  pending: (data) =>
    createAction(types.PUT_UPDATECOURIER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATECOURIER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATECOURIER_ASYNC.ERROR, { error }),
};
