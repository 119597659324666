import { createAsyncTypes } from "../_helper/Utilities";

export const GET_TREATMENTTYPEBYID_ASYNC = createAsyncTypes(
  "GET_TREATMENTTYPEBYID"
);

export const GET_TREATMENTTYPELIST_ASYNC = createAsyncTypes(
  "GET_TREATMENTTYPELIST"
);

export const POST_CREATETREATMENTTYPE_ASYNC = createAsyncTypes(
  "POST_CREATETREATMENTTYPE"
);

export const PUT_UPDATETREATMENTTYPE_ASYNC = createAsyncTypes(
  "PUT_UPDATETREATMENTTYPE"
);
