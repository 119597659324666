import React from "react";
import Typography from "@mui/material/Typography";
import Logo403 from "../../assets/403.png";
import { useStyles } from "../../components/globalStyles";
import * as notification from "../../_constants/notificationConstants";

const NoInternet = ({ noInternetCode }) => {
  const classes = useStyles();

  return (
    <div className={classes.notificationRoot}>
      <div className={classes.notificationTitleContainer}>
        <Typography
          className={classes.notificationTitle}
          variant="h3"
          gutterBottom>
          <img src={Logo403} height="300px" width="300px" alt="website logo" />
        </Typography>
        <Typography
          className={classes.notificationTitle}
          variant="h3"
          gutterBottom>
          {noInternetCode}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {notification.CONST_SOMETHINGWRONG}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {notification.CONST_SOMETHINGWRONG_CH}
        </Typography>
      </div>
    </div>
  );
};

export default NoInternet;
