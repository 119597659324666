import * as generalConstants from "../../../../../_constants/generalConstants";
import { v4 as uuidv4 } from "uuid";

//actions
import { postVisitChangeBranchAcupointOnly } from "../../../../../actions/visitOrderActions";

function onConfirmSubmitChangeBranch(
  curAcupunctureResult,
  tempTreatmentGroupResult,
  dispatch
) {
  const submisionJson = {
    store_header: {
      visit_acupuncture_headers: [
        ...curAcupunctureResult,
        ...tempTreatmentGroupResult,
      ],
    },
  };
  dispatch(postVisitChangeBranchAcupointOnly.pending(submisionJson));
}

const copyAcupuncture = (orderInfo, copyInfo, dispatch) => {
  const curAcupunctureResultWODelete =
    orderInfo.visit_acupuncture_headers.filter((item) => item.id !== 0);

  const differentOrganisation =
    copyInfo.organisation.id !== orderInfo.organisation.id;

  let curAcupunctureResult = curAcupunctureResultWODelete.map((item) => {
    const tempAcupunctureDetailResult = item.visit_acupuncture_details?.map(
      (itemDetail) => {
        const detailResult = {
          ...itemDetail,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
        return detailResult;
      }
    );

    const tempAcupunctureDetailResultWONew = tempAcupunctureDetailResult.filter(
      (item) => item.id !== 0
    );

    const result = {
      ...item,
      status_type: generalConstants.DELETED_STATUS_TYPE,

      visit_acupuncture_details: tempAcupunctureDetailResultWONew,
    };

    return result;
  });

  const tempAcupunctureResultWODelete =
    copyInfo.visit_acupuncture_headers.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );

  //add new group
  const tempTreatmentGroupResult = tempAcupunctureResultWODelete.map((item) => {
    const repeatDeletedAcupointIndex = curAcupunctureResult.findIndex(
      (indexItem) => indexItem.name === item.name
    );
    if (repeatDeletedAcupointIndex !== -1) {
      //change repeat deleted group name
      curAcupunctureResult[repeatDeletedAcupointIndex].name = uuidv4();
    }

    //filter group details
    const tempAcupunctureDetailResultWODelete =
      item.visit_acupuncture_details.filter(
        (itemDetail) =>
          itemDetail.status_type === generalConstants.APPROVED_STATUS_TYPE
      );

    const tempAcupunctureDetailResult = tempAcupunctureDetailResultWODelete.map(
      (itemDetail) => {
        const detailResult = {
          ...itemDetail,
          id: 0,
          acupoint_name: itemDetail.acupoint.name,
          desc: itemDetail.acupoint.desc,
        };
        return detailResult;
      }
    );

    //result with details
    const result = {
      ...item,
      id: 0,
      visit_acupuncture_details: tempAcupunctureDetailResult,
    };
    return result;
  });

  const copyAcupunctureInfo = {
    ...orderInfo,
    visit_acupuncture_headers: [
      ...curAcupunctureResult,
      ...tempTreatmentGroupResult,
    ],
  };

  if (
    differentOrganisation &&
    copyAcupunctureInfo.visit_acupuncture_headers.length !== 0
  ) {
    onConfirmSubmitChangeBranch(
      curAcupunctureResult,
      tempTreatmentGroupResult,
      dispatch
    );
  }

  return copyAcupunctureInfo;
};

export default copyAcupuncture;
