import { createAsyncTypes } from "../_helper/Utilities";

export const GET_MEDICINEBYID_ASYNC = createAsyncTypes("GET_MEDICINEBYID");

export const GET_MEDICINELIST_ASYNC = createAsyncTypes("GET_MEDICINELIST");

export const POST_CREATEMEDICINE_ASYNC = createAsyncTypes(
  "POST_CREATEMEDICINE"
);

export const PUT_UPDATEMEDICINE_ASYNC = createAsyncTypes("PUT_UPDATEMEDICINE");

export const GET_MEDICINEMEDICINETYPES_ASYNC = createAsyncTypes(
  "GET_MEDICINEMEDICINETYPES"
);
