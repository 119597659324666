import * as types from "../_constants/dispensaryConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.PUT_STOREORDERSTATUS_ASYNC.PENDING:
      return {
        ...state,
        putStoreOrderStatusSuccess: false,
        isLoadingStoreOrderStatus: true,
        putStoreOrderStatus: null,
      };

    case types.PUT_STOREORDERSTATUS_ASYNC.ERROR:
      return {
        ...state,
        putStoreOrderStatusSuccess: false,
        isLoadingStoreOrderStatus: false,
        putStoreOrderStatus: null,
        error: { ...state.error, storeOrderStatusError: action.error },
      };

    case types.PUT_STOREORDERSTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        putStoreOrderStatusSuccess: true,
        isLoadingStoreOrderStatus: false,
        putStoreOrderStatus: action.data,
      };
    case types.PUT_DISPENSARYWIPMODE_ASYNC.PENDING:
      return {
        ...state,
        putDispensaryWIPModeSuccess: false,
        isLoadingDispensaryWIPMode: true,
        putDispensaryWIPMode: null,
      };

    case types.PUT_DISPENSARYWIPMODE_ASYNC.ERROR:
      return {
        ...state,
        putDispensaryWIPModeSuccess: false,
        isLoadingDispensaryWIPMode: false,
        putDispensaryWIPMode: null,
        error: { ...state.error, dispensaryWIPModeError: action.error },
      };

    case types.PUT_DISPENSARYWIPMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putDispensaryWIPModeSuccess: true,
        isLoadingDispensaryWIPMode: false,
        putDispensaryWIPMode: action.data,
      };

    case types.PUT_DISPENSARYPENDINGSTATUS_ASYNC.PENDING:
      return {
        ...state,
        putDispensaryPendingStatusSuccess: false,
        isLoadingDispensaryPendingStatus: true,
        brewingPendingStatus: null,
      };

    case types.PUT_DISPENSARYPENDINGSTATUS_ASYNC.ERROR:
      return {
        ...state,
        putDispensaryPendingStatusSuccess: false,
        isLoadingDispensaryPendingStatus: false,
        brewingPendingStatus: null,
        error: { ...state.error, dispensaryPendingStatusError: action.error },
      };

    case types.PUT_DISPENSARYPENDINGSTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        putDispensaryPendingStatusSuccess: true,
        isLoadingDispensaryPendingStatus: false,
        brewingPendingStatus: action.data,
      };

    case types.GET_DISPENSARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getDispensaryListSuccess: false,
        isLoadingDispensaryList: true,
        //may have issue to submit order
        dispensaryList: null,
      };

    case types.GET_DISPENSARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getDispensaryListSuccess: false,
        isLoadingDispensaryList: false,
        dispensaryList: null,
        error: { ...state.error, dispensaryListError: action.error },
      };

    case types.GET_DISPENSARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDispensaryListSuccess: true,
        isLoadingDispensaryList: false,
        dispensaryList: action.data,
      };

    case types.GET_DISPENSARYSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getDispensarySummaryListSuccess: false,
        isLoadingDispensarySummaryList: true,
        //may have issue to submit order
        dispensarySummaryList: null,
      };

    case types.GET_DISPENSARYSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getDispensarySummaryListSuccess: false,
        isLoadingDispensarySummaryList: false,
        dispensarySummaryList: null,
        error: { ...state.error, dispensarySummaryListError: action.error },
      };

    case types.GET_DISPENSARYSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDispensarySummaryListSuccess: true,
        isLoadingDispensarySummaryList: false,
        dispensarySummaryList: action.data,
      };

    case types.GET_DISPENSARYBYID_ASYNC.PENDING:
      return {
        ...state,
        getDispensaryByIdSuccess: false,
        isLoadingDispensaryById: true,
        //may have issue to submit order
        dispensaryById: null,
      };

    case types.GET_DISPENSARYBYID_ASYNC.ERROR:
      return {
        ...state,
        getDispensaryByIdSuccess: false,
        isLoadingDispensaryById: false,
        dispensaryById: null,
        error: { ...state.error, dispensaryByIdError: action.error },
      };

    case types.GET_DISPENSARYBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getDispensaryByIdSuccess: true,
        isLoadingDispensaryById: false,
        dispensaryById: action.data,
      };

    case types.POST_SUBMITDISPENSARY_ASYNC.PENDING:
      return {
        ...state,
        postSubmitDispensaryIsSuccess: false,
        isLoadingSubmitDispensary: true,
        submitDispensary: null,
      };

    case types.POST_SUBMITDISPENSARY_ASYNC.ERROR:
      return {
        ...state,
        postSubmitDispensaryIsSuccess: false,
        isLoadingSubmitDispensary: false,
        submitDispensary: null,
        error: { ...state.error, postDispensarySubmitDispensary: action.error },
      };

    case types.POST_SUBMITDISPENSARY_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitDispensaryIsSuccess: true,
        isLoadingSubmitDispensary: false,
        submitDispensary: action.data,
      };
    case types.PUT_DISPENSARYPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putDispensaryPrintDateIsSuccess: false,
        isLoadingPutDispensaryPrintDate: true,
        putDispensaryPrintDate: null,
      };

    case types.PUT_DISPENSARYPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putDispensaryPrintDateIsSuccess: false,
        isLoadingPutDispensaryPrintDate: false,
        putDispensaryPrintDate: null,
        error: { ...state.error, putDispensaryPrintDateError: action.error },
      };

    case types.PUT_DISPENSARYPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putDispensaryPrintDateIsSuccess: true,
        isLoadingPutDispensaryPrintDate: false,
        putDispensaryPrintDate: action.data,
      };

    case types.PUT_DISPENSARYVISITPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putDispensaryVisitPrintDateIsSuccess: false,
        isLoadingPutDispensaryVisitPrintDate: true,
        putDispensaryVisitPrintDate: null,
      };

    case types.PUT_DISPENSARYVISITPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putDispensaryVisitPrintDateIsSuccess: false,
        isLoadingPutDispensaryVisitPrintDate: false,
        putDispensaryVisitPrintDate: null,
        error: {
          ...state.error,
          putDispensaryVisitPrintDateError: action.error,
        },
      };

    case types.PUT_DISPENSARYVISITPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putDispensaryVisitPrintDateIsSuccess: true,
        isLoadingPutDispensaryVisitPrintDate: false,
        putDispensaryVisitPrintDate: action.data,
      };

    case types.PUT_DISPENSARYSTOREPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putDispensaryStorePrintDateIsSuccess: false,
        isLoadingPutDispensaryStorePrintDate: true,
        putDispensaryStorePrintDate: null,
      };

    case types.PUT_DISPENSARYSTOREPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putDispensaryStorePrintDateIsSuccess: false,
        isLoadingPutDispensaryStorePrintDate: false,
        putDispensaryStorePrintDate: null,
        error: {
          ...state.error,
          putDispensaryStorePrintDateError: action.error,
        },
      };

    case types.PUT_DISPENSARYSTOREPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putDispensaryStorePrintDateIsSuccess: true,
        isLoadingPutDispensaryStorePrintDate: false,
        putDispensaryStorePrintDate: action.data,
      };

    case types.GET_DISPENSARYINVOICEORDER_ASYNC.PENDING:
      return {
        ...state,
        getDispensaryInvoiceOrderIsSuccess: false,
        isLoadingGetDispensaryInvoiceOrder: true,
        getDispensaryInvoiceOrder: null,
      };

    case types.GET_DISPENSARYINVOICEORDER_ASYNC.ERROR:
      return {
        ...state,
        getDispensaryInvoiceOrderIsSuccess: false,
        isLoadingGetDispensaryInvoiceOrder: false,
        getDispensaryInvoiceOrder: null,
        error: {
          ...state.error,
          getDispensaryInvoiceOrderError: action.error,
        },
      };

    case types.GET_DISPENSARYINVOICEORDER_ASYNC.SUCCESS:
      return {
        ...state,
        getDispensaryInvoiceOrderIsSuccess: true,
        isLoadingGetDispensaryInvoiceOrder: false,
        getDispensaryInvoiceOrder: action.data,
      };

    case types.PUT_DISPENSARYINVOICEPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putDispensaryInvoicePrintDateIsSuccess: false,
        isLoadingPutDispensaryInvoicePrintDate: true,
        putDispensaryInvoicePrintDate: null,
      };

    case types.PUT_DISPENSARYINVOICEPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putDispensaryInvoicePrintDateIsSuccess: false,
        isLoadingPutDispensaryInvoicePrintDate: false,
        putDispensaryInvoicePrintDate: null,
        error: {
          ...state.error,
          putDispensaryInvoicePrintDateError: action.error,
        },
      };

    case types.PUT_DISPENSARYINVOICEPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putDispensaryInvoicePrintDateIsSuccess: true,
        isLoadingPutDispensaryInvoicePrintDate: false,
        putDispensaryInvoicePrintDate: action.data,
      };

    case types.PUT_DISPENSARYSTOREPROCEEDON_ASYNC.PENDING:
      return {
        ...state,
        putDispensaryStoreProceedOnIsSuccess: false,
        isLoadingPutDispensaryStoreProceedOn: true,
        putDispensaryStoreProceedOn: null,
      };

    case types.PUT_DISPENSARYSTOREPROCEEDON_ASYNC.ERROR:
      return {
        ...state,
        putDispensaryStoreProceedOnIsSuccess: false,
        isLoadingPutDispensaryStoreProceedOn: false,
        putDispensaryStoreProceedOn: null,
        error: {
          ...state.error,
          putDispensaryInvoicePrintDateError: action.error,
        },
      };

    case types.PUT_DISPENSARYSTOREPROCEEDON_ASYNC.SUCCESS:
      return {
        ...state,
        putDispensaryStoreProceedOnIsSuccess: true,
        isLoadingPutDispensaryStoreProceedOn: false,
        putDispensaryStoreProceedOn: action.data,
      };

    default:
      return state;
  }
};
