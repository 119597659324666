import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
//action
import { getStoreAvailableProceedOn } from "../../../../../actions/workOrderActions";
import { getVisitAvailableProceedOn } from "../../../../../actions/visitOrderActions";
//components
import { useStyles } from "../../../../../components/globalStyles";
import Panel from "../../../../../components/Panel";
import * as generalConstants from "../../../../../_constants/generalConstants";

export default function ProceedDay({
  memoSelectedGroup,
  memoGroupOption,
  module,
  setIsSaveLoading = () => null,
  setGroupOption,
  memoSelectedGroupIndex,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const prevBrewingStatus = useRef();
  const prevDispensaryBranchStatus = useRef();
  const prevSearchDateStatus = useRef();
  const prevGroupName = useRef();
  const getStoreAvailableProceedOnSatus = useRef();
  const getVisitAvailableProceedOnSatus = useRef();
  const prevLinkDeliveryStatus = useRef();

  const workOrder = useSelector((state) => state.workOrder);
  const visitOrder = useSelector((state) => state.visitOrder);
  const [pageInfo, setPageInfo] = useState({
    is_brewing_services: null,
    search_branch_id_value: null,
    search_proceed_on_value: null,
  });

  const [isProceedOnLoading, setIsProceedOnLoading] = useState(true);
  const [alertMsg, setAlertMsg] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState(null);

  //useEffects
  useEffect(() => {
    prevBrewingStatus.current = null;
    prevDispensaryBranchStatus.current = null;
    prevSearchDateStatus.current = null;
    prevGroupName.current = null;
    prevLinkDeliveryStatus.current = null;
    getStoreAvailableProceedOnSatus.current = false;
    getVisitAvailableProceedOnSatus.current = false;
  }, []);

  useEffect(() => {
    setIsSaveLoading(isProceedOnLoading);
  }, [isProceedOnLoading]);

  useEffect(() => {
    if (
      pageInfo.is_brewing_services !== null &&
      pageInfo.search_branch_id_value !== null &&
      (prevBrewingStatus.current !== pageInfo.is_brewing_services ||
        prevSearchDateStatus.current !== pageInfo.search_proceed_on_value ||
        prevDispensaryBranchStatus.current !==
          pageInfo.search_branch_id_value ||
        prevGroupName.current !== memoSelectedGroup.name ||
        prevLinkDeliveryStatus.current !== memoSelectedGroup.delivery_owned)
    ) {
      if (
        module === "VISIT" &&
        (!memoSelectedGroup.delivery_owned ||
          memoSelectedGroup.delivery_owned.status_type ===
            generalConstants.DELETED_STATUS_TYPE)
      ) {
        if (prevGroupName.current !== memoSelectedGroup.name) {
          setPageInfo({
            is_brewing_services: memoSelectedGroup.is_brewing_services
              ? memoSelectedGroup.is_brewing_services
              : false,
            search_branch_id_value:
              memoSelectedGroup.organisation_branch.branch.id,
            search_proceed_on_value: memoSelectedGroup.proceed_on
              ? memoSelectedGroup.proceed_on
              : moment.utc(new Date()).local().format("YYYY-MM-DD"),
          });

          dispatch(
            getVisitAvailableProceedOn.pending({
              is_brewing_services: memoSelectedGroup.is_brewing_services
                ? memoSelectedGroup.is_brewing_services
                : false,
              search_branch_id_value:
                memoSelectedGroup.organisation_branch.branch.id,
              search_proceed_on_value: memoSelectedGroup.proceed_on
                ? memoSelectedGroup.proceed_on
                : moment.utc(new Date()).local().format("YYYY-MM-DD"),
            })
          );
        } else {
          dispatch(getVisitAvailableProceedOn.pending(pageInfo));
        }
      }
      if (
        module === "STORE" &&
        (!memoSelectedGroup.delivery_owned ||
          memoSelectedGroup.delivery_owned.status_type ===
            generalConstants.DELETED_STATUS_TYPE)
      ) {
        if (prevGroupName.current !== memoSelectedGroup.name) {
          setPageInfo({
            is_brewing_services: memoSelectedGroup.is_brewing_services
              ? memoSelectedGroup.is_brewing_services
              : false,
            search_branch_id_value:
              memoSelectedGroup.organisation_branch.branch.id,
            search_proceed_on_value: memoSelectedGroup.proceed_on
              ? memoSelectedGroup.proceed_on
              : moment.utc(new Date()).local().format("YYYY-MM-DD"),
          });
          dispatch(
            getStoreAvailableProceedOn.pending({
              is_brewing_services: memoSelectedGroup.is_brewing_services
                ? memoSelectedGroup.is_brewing_services
                : false,
              search_branch_id_value:
                memoSelectedGroup.organisation_branch.branch.id,
              search_proceed_on_value: memoSelectedGroup.proceed_on
                ? memoSelectedGroup.proceed_on
                : moment.utc(new Date()).local().format("YYYY-MM-DD"),
            })
          );
        } else {
          dispatch(getStoreAvailableProceedOn.pending(pageInfo));
        }
      }

      prevBrewingStatus.current = pageInfo.is_brewing_services;
      prevSearchDateStatus.current = pageInfo.search_proceed_on_value;
      prevDispensaryBranchStatus.current = pageInfo.search_branch_id_value;
      prevGroupName.current = memoSelectedGroup.name;
      prevLinkDeliveryStatus.current = memoSelectedGroup.delivery_owned;

      setIsProceedOnLoading(true);
    }
  }, [dispatch, pageInfo, module]);

  useEffect(() => {
    if (memoSelectedGroup && memoSelectedGroup.organisation_branch) {
      setPageInfo({
        ...pageInfo,
        search_proceed_on_value: memoSelectedGroup.proceed_on
          ? memoSelectedGroup.proceed_on
          : moment.utc(new Date()).local().format("YYYY-MM-DD"),

        is_brewing_services: memoSelectedGroup.is_brewing_services
          ? true
          : false,

        search_branch_id_value: memoSelectedGroup.organisation_branch.branch.id,
      });
    }
  }, [memoSelectedGroup, memoGroupOption]);

  useEffect(() => {
    if (
      getStoreAvailableProceedOnSatus.current !==
      workOrder.isLoadingGetStoreAvailableProceedOn
    ) {
      getStoreAvailableProceedOnSatus.current =
        workOrder.isLoadingGetStoreAvailableProceedOn;
      if (
        workOrder.isLoadingGetStoreAvailableProceedOn === false &&
        workOrder.getStoreAvailableProceedOnIsSuccess === true &&
        isProceedOnLoading === true &&
        module === "STORE"
      ) {
        if (
          workOrder.getStoreAvailableProceedOn.length &&
          workOrder.getStoreAvailableProceedOn[0]?.schedule_date !==
            memoSelectedGroup.proceed_on
        ) {
          let tempUpdateGroupOption = memoGroupOption;
          tempUpdateGroupOption[memoSelectedGroupIndex].proceed_on =
            workOrder.getStoreAvailableProceedOn[0]?.schedule_date;
          setGroupOption(tempUpdateGroupOption);

          if (
            workOrder.getStoreAvailableProceedOn[0]?.schedule_date >
            pageInfo.search_proceed_on_value
          ) {
            setAlertMsg(true);
            setMsgAlarm(
              t("translation:Next available date is") +
                " " +
                dayjs(
                  workOrder.getStoreAvailableProceedOn[0]?.schedule_date
                ).format("DD MMM YYYY")
            );
          }
        }
        setIsProceedOnLoading(false);
      }
    }
  }, [
    workOrder.isLoadingGetStoreAvailableProceedOn,
    workOrder.getStoreAvailableProceedOnIsSuccess,
    isProceedOnLoading,
    module,
  ]);

  useEffect(() => {
    if (
      getVisitAvailableProceedOnSatus.current !==
      visitOrder.isLoadingGetVisitAvailableProceedOn
    ) {
      getVisitAvailableProceedOnSatus.current =
        visitOrder.isLoadingGetVisitAvailableProceedOn;
      if (
        visitOrder.isLoadingGetVisitAvailableProceedOn === false &&
        visitOrder.getVisitAvailableProceedOnIsSuccess === true &&
        isProceedOnLoading === true &&
        module === "VISIT"
      ) {
        if (
          visitOrder.getVisitAvailableProceedOn.length &&
          visitOrder.getVisitAvailableProceedOn[0]?.schedule_date !==
            memoSelectedGroup.proceed_on
        ) {
          let tempUpdateGroupOption = memoGroupOption;
          tempUpdateGroupOption[memoSelectedGroupIndex].proceed_on =
            visitOrder.getVisitAvailableProceedOn[0]?.schedule_date;
          setGroupOption(tempUpdateGroupOption);

          if (
            visitOrder.getVisitAvailableProceedOn[0]?.schedule_date >
            pageInfo.search_proceed_on_value
          ) {
            setAlertMsg(true);
            setMsgAlarm(
              t("translation:Next available date is") +
                " " +
                dayjs(
                  visitOrder.getVisitAvailableProceedOn[0]?.schedule_date
                ).format("DD MMM YYYY")
            );
          }
          setIsProceedOnLoading(false);
        }
      }
    }
  }, [
    visitOrder.isLoadingGetVisitAvailableProceedOn,
    visitOrder.getVisitAvailableProceedOnIsSuccess,
    isProceedOnLoading,
    module,
  ]);
  return (
    <>
      {(workOrder.getStoreAvailableProceedOn?.length > 0 ||
        visitOrder.getVisitAvailableProceedOn?.length > 0) &&
        memoSelectedGroup &&
        memoSelectedGroup.organisation_branch && (
          <Panel>
            <Collapse in={alertMsg}>
              <Alert
                severity={"warning"}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertMsg(false);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {msgAlarm}
              </Alert>
            </Collapse>
            <Grid item xs={12} className={classes.userInfoField}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={classes.userFieldColor}
                  inputVariant="outlined"
                  id="date"
                  label={t("translation:Proceed Date")}
                  autoOk={true}
                  disabled={
                    memoSelectedGroup?.delivery_owned?.status_type ===
                    generalConstants.APPROVED_STATUS_TYPE
                  }
                  disablePast
                  isRequired
                  value={dayjs(memoSelectedGroup.proceed_on)}
                  slotProps={{
                    textField: {
                      required: true,
                      value: dayjs(memoSelectedGroup.proceed_on),
                    },
                  }}
                  format="DD MMM YYYY"
                  onChange={(e) => {
                    if (dayjs(e, true).isValid()) {
                      prevSearchDateStatus.current = null;
                      setPageInfo({
                        ...pageInfo,
                        search_proceed_on_value: dayjs(e, true).isValid()
                          ? dayjs(e).format("YYYY-MM-DD")
                          : null,
                      });
                    }
                    if (e === null) {
                      setPageInfo({
                        ...pageInfo,
                        search_proceed_on_value: null,
                      });
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Panel>
        )}
    </>
  );
}
