//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
//component

import { useStyles } from "../../../components/globalStyles";

import ExportInvoiceDetailsByStore from "./components/ExportInvoiceDetailsByStore";

import ExportInvoiceDetailsBySummaryStore from "./components/ExportInvoiceDetailsBySummaryStore";

import ExportBillableInvoiceSummaryByStore from "./components/ExportBillableInvoiceSummaryByStore";

const StoreInvoiceReportPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={11}>
          <Typography className={classes.title} variant="h3" gutterBottom>
            {t("translation:Store Invoice Report")}
          </Typography>
        </Grid>
      </Grid>

      <hr />

      <ExportInvoiceDetailsByStore />
      <ExportInvoiceDetailsBySummaryStore />
      <ExportBillableInvoiceSummaryByStore />
    </Paper>
  );
};

export default StoreInvoiceReportPage;
