//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
//actions
import { putUpdateUserMfa } from "../../../../actions/userMfaActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";

import * as pageConfigure from "../../../../_constants/pageConstants";

export const EditUserMfaPage = ({
  userMfaEdit,
  setDetailView,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const common = useSelector((state) => state.common);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const userMfa = useSelector((state) => state.userMfa);
  const prevPutUpdateStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });

  const [temViewOnly, setTemViewOnly] = useState(viewOnly);

  const viewColumns = [
    {
      Header: t("translation:Name"),
      accessor: "user.name",
      sortable: false,
    },

    {
      Header: t("translation:Username"),
      accessor: "user.username",
      sortable: false,
    },
  ];
  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJson = {
      user_mfa: {
        id: userMfaEdit.id,
        status_type: data.status_type,
        name: data.name.trim(),
        user: {
          id: userMfaEdit.user.id,
        },
      },
    };

    dispatch(putUpdateUserMfa.pending(submitJson));
  };

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      id: userMfaEdit.id,
      name: userMfaEdit.name,
      status_type: userMfaEdit.status_type,
    },
  });

  useEffect(() => {
    //set ref when first render
    prevPutUpdateStatus.current = false;
  }, []);

  //useEffects

  useEffect(() => {
    if (userMfa.isLoadingPutUpdateUserMfa !== prevPutUpdateStatus.current) {
      //check the previous ref with current state
      prevPutUpdateStatus.current = userMfa.isLoadingPutUpdateUserMfa;

      if (
        userMfa.putUpdateUserMfaIsSuccess === true &&
        userMfa.isLoadingPutUpdateUserMfa === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...userMfa.putUpdateUserMfa.user_mfa,
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        userMfa.putUpdateUserMfaIsSuccess === false &&
        userMfa.isLoadingPutUpdateUserMfa === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(userMfa.error.putUpdateUserMfaError);
        window.scrollTo(0, 0);
      }
    }
  }, [userMfa, reset]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:User MFA Details")
          : t("translation:Edit User MFA")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:User MFA has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.root} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              required
              name="name"
              label={t("translation:MFA Name")}
              variant="outlined"
              {...register("name")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly}
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <SortingSearchTable
              disabledSearch
              columns={viewColumns}
              data={[userMfaEdit]}
              pageInfo={viewPageInfo}
              setPageInfo={setViewPageInfo}
              totalItems={1}
              disabledFooter
            />
          </Grid>
          <>
            <RequiredNote />
            {temViewOnly ? (
              <PageOnlyBackAction setView={() => setDetailView(false)} />
            ) : (
              <PageSubmitWBackAction setView={() => setDetailView(false)} />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
