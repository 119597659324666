import * as generalConstants from "../../../../../_constants/generalConstants";
const copyDignosis = (orderInfo, copyInfo) => {
  const tempNotesResultWODelete = copyInfo.visit_notes_details.filter(
    (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  );

  const tempNotesResult = tempNotesResultWODelete.map((item) => {
    //use back the id
    const tempNotesDetailsResultWODelete = orderInfo.visit_notes_details.find(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );

    const result = {
      ...item,
      id: tempNotesDetailsResultWODelete
        ? tempNotesDetailsResultWODelete.id
        : 0,
    };
    return result;
  });

  const copyNotesInfo = {
    ...orderInfo,
    visit_notes_details: tempNotesResult,
  };

  return copyNotesInfo;
};

export default copyDignosis;
