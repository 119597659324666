//lib
import React, { useEffect } from "react";

import { usePDF } from "@react-pdf/renderer";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";

import moment from "moment";

// //components
// import NotoSansHansRegular from "./NotoSansHans-Regular-2.ttf";

//style

// Font.register({
//   family: "NotoSansSChinese",
//   fonts: [{ src: NotoSansHansRegular, fontWeight: 300 }],
// });
// Font.registerHyphenationCallback((word) =>
//   word.length === 1 ? [word] : Array.from(word).map((char) => char)
// );

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  flexRow: {
    flexDirection: "row",
    padding: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  flexRow1: {
    flexDirection: "row",
    alignItems: "center",
  },
  text1: {
    fontSize: "4mm",
    fontFamily: "NotoSansSChinese",
    fontWeight: "bold",
    padding: 5,
  },
  text2: {
    fontSize: "5mm",
    fontFamily: "NotoSansSChinese",
    fontWeight: "bold",
  },
  text3: {
    fontSize: "3.5mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
  },

  pageNumber: {
    fontSize: "3mm",
    right: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  pagePrinted: {
    fontSize: "3mm",
    left: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  table: {
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    width: "100%",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: "4%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCell: {
    fontSize: "2.5mm",
    fontFamily: "NotoSansSChinese",
    bottom: "-1mm",
    margin: "0.2mm",
    padding: "1mm",
  },

  maroon075: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,0.75)",
  },
  grey: {
    fontWeight: "bold",
    color: "rgba(77, 77, 77)",
  },
});

const DetailsTable = ({ orderDetailInfo, statusOption }) => {
  const Rows = orderDetailInfo.map((item, index) => {
    return (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>{index + 1}</Text>
        </View>

        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {item.store_medicine_header.name}
          </Text>
        </View>

        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>{item.store_header.order_number}</Text>
        </View>

        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {item.invoice_medicine_costing_header?.order_number}
          </Text>
        </View>

        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {
              statusOption.find(
                (status) =>
                  status.id ===
                  item.invoice_medicine_costing_header?.status_type
              )?.name
            }
          </Text>
        </View>

        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {
              statusOption.find(
                (status) => status.id === item.dispensary_header?.status_type
              )?.name
            }
          </Text>
        </View>

        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {
              statusOption.find(
                (status) => status.id === item.brewing_header?.status_type
              )?.name
            }
          </Text>
        </View>

        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {
              statusOption.find(
                (status) => status.id === item.packing_header?.status_type
              )?.name
            }
          </Text>
        </View>

        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {
              statusOption.find(
                (status) => status.id === item.delivery_header?.status_type
              )?.name
            }
          </Text>
        </View>
      </View>
    );
  });

  return (
    <View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>No</Text>
          </View>

          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Medicine Group</Text>
          </View>

          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Order No</Text>
          </View>

          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>IMC Order No</Text>
          </View>

          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>IMC Status</Text>
          </View>

          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Dispensary Status</Text>
          </View>

          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Brewing Status</Text>
          </View>

          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Packing Status</Text>
          </View>

          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Delivery Status</Text>
          </View>
        </View>
        {Rows}
      </View>
    </View>
  );
};

const PDFPage = ({ orderDetailInfo, statusOption, title }) => {
  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.body}>
        <View style={styles.flexRow}>
          <Text style={styles.text2}>{title}</Text>
        </View>

        <DetailsTable
          orderDetailInfo={orderDetailInfo}
          statusOption={statusOption}
        />

        <Text style={styles.pagePrinted} fixed>
          {`${"Printed on:"} ${moment().format("DD-MMM-YYYY")}`}
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

let printNum = 0;
const BillAbleMedicineCostingInvoiceReportPDF = ({
  orderDetailInfo,
  statusOption,
  t,
  title,
  print = 0,
}) => {
  if (print !== 0 && print !== printNum) {
    const [instance, updateInstance] = usePDF({
      document: (
        <PDFPage
          orderDetailInfo={orderDetailInfo}
          statusOption={statusOption}
          title={title}
        />
      ),
    });

    useEffect(() => {
      if (!instance.loading && instance.url && print !== printNum) {
        printNum = print;
        window.open(instance.url, "_blank", "noreferrer");
      }
    }, [instance.loading, instance.url, printNum, print]);
    return <></>;
  } else return <></>;
};

export default BillAbleMedicineCostingInvoiceReportPDF;
