import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";

//components
import { useStyles } from "../../../../../components/globalStyles";
import * as generalConstants from "../../../../../_constants/generalConstants";

import StyledCheckbox from "../../../../../components/StyledCheckbox";

const ViewSharedDelivery = ({
  memoSelectedGroup,
  viemOnly = false,
  onlyViewDeliveryRemarks = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(
      memoSelectedGroup?.delivery_owned?.status_type ===
        generalConstants.APPROVED_STATUS_TYPE
    );
  }, [memoSelectedGroup?.delivery_owned]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.userInfoField}>
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={check}
              disabled={viemOnly}
              name="sharedDelivery"
            />
          }
          label={t("translation:Link Delivery Services")}
        />
      </Grid>

      {check === true && (
        <>
          <Grid item xs={12} className={classes.userInfoField}>
            <Autocomplete
              options={[]}
              disabled
              multiple
              value={
                memoSelectedGroup.delivery_owned
                  ?.delivery_linked_store_medicine_header?.store_header
                  .order_number
              }
              style={{ width: "100%" }}
              renderTags={(value, props) => (
                <Chip
                  label={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header?.store_header
                      .order_number +
                    "/" +
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header?.name
                  }
                  clickable={false}
                />
              )}
              renderInput={(params) => {
                const InputProps = { ...params.InputProps };
                InputProps.endAdornment = null;
                return (
                  <TextField
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Linked to order/ group")}
                    variant="outlined"
                    InputProps={InputProps}
                  />
                );
              }}
            />
          </Grid>
          {!onlyViewDeliveryRemarks && (
            <>
              <Grid item xs={6} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header?.delivery_name
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header?.delivery_name
                      : ""
                  }
                  name="delivery_name"
                  label={t("translation:Recipient")}
                  required
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header
                      ?.delivery_contact_number
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header
                          ?.delivery_contact_number
                      : ""
                  }
                  name="delivery_contact_number"
                  required
                  label={t("translation:Contact Number")}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header
                      ?.delivery_postal_code
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header
                          ?.delivery_postal_code
                      : ""
                  }
                  name="delivery_postal_code"
                  label={t("translation:Postal Code")}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header
                      ?.delivery_address_details
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header
                          ?.delivery_address_details
                      : ""
                  }
                  name="delivery_address_details"
                  placeholder={t("translation:Floor/ Unit Number")}
                  variant="outlined"
                  label={t("translation:Address Details")}
                  disabled
                />
              </Grid>
              {memoSelectedGroup.delivery_owned
                ?.delivery_linked_store_medicine_header?.delivery_blk_no ? (
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    disabled
                    value={
                      memoSelectedGroup.delivery_owned
                        ?.delivery_linked_store_medicine_header?.delivery_blk_no
                    }
                    name="blk_no"
                    label={t("translation:Block No")}
                    variant="outlined"
                  />
                </Grid>
              ) : (
                <></>
              )}

              {memoSelectedGroup.delivery_owned
                ?.delivery_linked_store_medicine_header?.delivery_road_name ? (
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    disabled
                    value={
                      memoSelectedGroup.delivery_owned
                        ?.delivery_linked_store_medicine_header
                        ?.delivery_road_name
                    }
                    name="road_name"
                    label={t("translation:Road Name")}
                    variant="outlined"
                  />
                </Grid>
              ) : (
                <></>
              )}

              {memoSelectedGroup.delivery_owned
                ?.delivery_linked_store_medicine_header?.delivery_building ? (
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    disabled
                    value={
                      memoSelectedGroup.delivery_owned
                        ?.delivery_linked_store_medicine_header
                        ?.delivery_building
                    }
                    name="building"
                    label={t("translation:Building")}
                    variant="outlined"
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={
                    memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header?.delivery_address
                      ? memoSelectedGroup.delivery_owned
                          ?.delivery_linked_store_medicine_header
                          ?.delivery_address
                      : ""
                  }
                  required
                  multiline
                  rows={3}
                  name="delivery_address"
                  label={t("translation:Address")}
                  variant="outlined"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_owned
                  ?.delivery_linked_store_medicine_header?.delivery_remarks
                  ? memoSelectedGroup.delivery_owned
                      ?.delivery_linked_store_medicine_header?.delivery_remarks
                  : ""
              }
              multiline
              rows={3}
              name="delivery_remarks"
              label={t("translation:Remarks")}
              variant="outlined"
              disabled
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default ViewSharedDelivery;
