import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  putPackingCloseMode,
  putPackingWIPMode,
  putPackingPendingStatus,
  getPackingList,
  getPackingSummaryList,
  getPackingById,
  getPackingByOrderNum,
  postSubmitPacking,
} from "../actions/packingActions";
import * as types from "../_constants/packingConstants";
import * as notification from "../_constants/notificationConstants";
import packingApis from "../_apis/packingApis";
export default function* packingSagas() {
  yield all([
    takeLatest(
      types.PUT_PACKINGCLOSEMODE_ASYNC.PENDING,
      putPackingCloseModeCall
    ),
  ]);

  yield all([
    takeLatest(types.PUT_PACKINGWIPMODE_ASYNC.PENDING, putPackingWIPModeCall),
  ]);

  yield all([
    takeLatest(
      types.PUT_PACKINGPENDINGSTATUS_ASYNC.PENDING,
      putPackingPendingStatusCall
    ),
  ]);
  yield all([
    takeLatest(types.GET_PACKINGLIST_ASYNC.PENDING, getPackingListCall),
  ]);

  yield all([
    takeLatest(
      types.GET_PACKINGSUMMARYLIST_ASYNC.PENDING,
      getPackingSummaryListCall
    ),
  ]);
  yield all([
    takeLatest(types.GET_PACKINGBYID_ASYNC.PENDING, getPackingByIdCall),
  ]);
  yield all([
    takeLatest(
      types.GET_PACKINGBYORDERNUM_ASYNC.PENDING,
      getPackingByOrderNumCall
    ),
  ]);
  yield all([
    takeLatest(types.POST_SUBMITPACKING_ASYNC.PENDING, postSubmitPackingCall),
  ]);
}

function* putPackingCloseModeCall(Model) {
  try {
    const data = yield call(packingApis.putPackingCloseModeApi, Model.data);
    yield put(putPackingCloseMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putPackingCloseMode.error(error.response?.data?.message));
    else yield put(putPackingCloseMode.error(notification.CONST_NOINTERNET));
  }
}

function* putPackingWIPModeCall(Model) {
  try {
    const data = yield call(packingApis.putPackingWIPModeApi, Model.data);
    yield put(putPackingWIPMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putPackingWIPMode.error(error.response?.data?.message));
    else yield put(putPackingWIPMode.error(notification.CONST_NOINTERNET));
  }
}

function* putPackingPendingStatusCall(Model) {
  try {
    const data = yield call(packingApis.putPackingPendingStatusApi, Model.data);
    yield put(putPackingPendingStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putPackingPendingStatus.error(error.response?.data?.message));
    else
      yield put(putPackingPendingStatus.error(notification.CONST_NOINTERNET));
  }
}

function* getPackingListCall(Model) {
  try {
    const data = yield call(packingApis.getPackingListApi, Model.data);
    yield put(getPackingList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPackingList.error(error.response?.data?.message));
    else yield put(getPackingList.error(notification.CONST_NOINTERNET));
  }
}

function* getPackingSummaryListCall(Model) {
  try {
    const data = yield call(packingApis.getPackingSummaryListApi, Model.data);
    yield put(getPackingSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPackingSummaryList.error(error.response?.data?.message));
    else yield put(getPackingSummaryList.error(notification.CONST_NOINTERNET));
  }
}

function* getPackingByIdCall(Model) {
  try {
    const data = yield call(packingApis.getPackingByIdApi, Model.data);
    yield put(getPackingById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPackingById.error(error.response?.data?.message));
    else yield put(getPackingById.error(notification.CONST_NOINTERNET));
  }
}

function* getPackingByOrderNumCall(Model) {
  try {
    const data = yield call(packingApis.getPackingByOrderNumApi, Model.data);
    yield put(getPackingByOrderNum.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPackingByOrderNum.error(error.response?.data?.message));
    else yield put(getPackingByOrderNum.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitPackingCall(Model) {
  try {
    const data = yield call(packingApis.postSubmitPackingApi, Model.data);
    yield put(postSubmitPacking.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitPacking.error(error.response?.data?.message));
    else yield put(postSubmitPacking.error(notification.CONST_NOINTERNET));
  }
}
