import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import StyledCheckbox from "../../../../../components/StyledCheckbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getMedicineConsumeTime } from "../../../../../actions/commonActions";
import { useStyles } from "../../../../../components/globalStyles";
import { getVisitPrescriptionMsgList } from "../../../../../actions/visitOrderActions";
import { getStorePrescriptionMsgList } from "../../../../../actions/workOrderActions";
export default function GroupMedicineInfo({
  memoGroupOption,
  handleMedicineGroupNumInfo,
  handleMedicineGroupInfo,
  memoSelectedGroupIndex,
  memoSelectedGroup,
  setGroupOption,
  viewOnly = false,
  module = null,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  const workOrder = useSelector((state) => state.workOrder);
  const visitOrder = useSelector((state) => state.visitOrder);

  const [selectedGroup, setSelectedGroup] = useState([]);
  const [prescriptionMsgList, setPrescriptionMsgList] = useState([]);

  const [selectedPrescriptionMsg, setSelectedPrescriptionMsg] = useState("");
  const handleOnChangeKeyIn = (event, value) => {
    setSelectedGroup((p) => ({
      ...p,
      [event.target.name]: event.target.value ? event.target.value : null,
    }));
  };
  //useEffects

  useEffect(() => {
    if (module && !viewOnly) {
      if (
        module === "STORE" &&
        !workOrder.isLoadingGetStorePrescriptionMsgList &&
        !workOrder.getStorePrescriptionMsgList
      ) {
        dispatch(getStorePrescriptionMsgList.pending());
      }
    }
  }, [
    dispatch,
    module,
    viewOnly,
    workOrder.getStorePrescriptionMsgList,
    workOrder.isLoadingGetStorePrescriptionMsgList,
  ]);

  useEffect(() => {
    if (module && !viewOnly) {
      if (
        module === "VISIT" &&
        !visitOrder.isLoadingGetVisitPrescriptionMsgList &&
        !visitOrder.getVisitPrescriptionMsgList
      ) {
        dispatch(getVisitPrescriptionMsgList.pending());
      }
    }
  }, [
    dispatch,
    module,
    viewOnly,
    visitOrder.getVisitPrescriptionMsgList,
    visitOrder.isLoadingGetVisitPrescriptionMsgList,
  ]);

  useEffect(() => {
    if (
      workOrder.getStorePrescriptionMsgListIsSuccess === true &&
      workOrder.isLoadingGetStorePrescriptionMsgList === false
    ) {
      setPrescriptionMsgList(
        workOrder.getStorePrescriptionMsgList?.prescription_messages
      );
    }
    if (
      workOrder.getStorePrescriptionMsgListIsSuccess === false &&
      workOrder.isLoadingGetStorePrescriptionMsgList === false
    ) {
      setPrescriptionMsgList([]);
    }
  }, [
    workOrder.isLoadingGetStorePrescriptionMsgList,
    workOrder.getStorePrescriptionMsgListIsSuccess,
    workOrder.getStorePrescriptionMsgList,
    workOrder.error,
  ]);

  useEffect(() => {
    if (
      visitOrder.getVisitPrescriptionMsgListIsSuccess === true &&
      visitOrder.isLoadingGetVisitPrescriptionMsgList === false
    ) {
      setPrescriptionMsgList(
        visitOrder.getVisitPrescriptionMsgList?.prescription_messages
      );
    }
    if (
      visitOrder.getVisitPrescriptionMsgListIsSuccess === false &&
      visitOrder.isLoadingGetVisitPrescriptionMsgList === false
    ) {
      setPrescriptionMsgList([]);
    }
  }, [
    visitOrder.isLoadingGetVisitPrescriptionMsgList,
    visitOrder.getVisitPrescriptionMsgListIsSuccess,
    visitOrder.getVisitPrescriptionMsgList,
    visitOrder.error,
  ]);

  useEffect(() => {
    setSelectedGroup(memoSelectedGroup);
  }, [memoSelectedGroup, setSelectedGroup]);

  useEffect(() => {
    if (!common.medicineCosumeTimeList && !common.isLoadingMedicineCosumeTime) {
      dispatch(getMedicineConsumeTime.pending());
    }
  }, [
    dispatch,
    common.medicineCosumeTimeList,
    common.isLoadingMedicineCosumeTime,
  ]);

  const getMedicineCosumeTimeOpObj = (option) => {
    if (!option.id)
      option = common.medicineCosumeTimeList.when_consume_select_field.find(
        (op) => op.id === option
      );
    return option;
  };

  const handleWhenConsume = (event, option) => {
    const elementsIndex = memoSelectedGroupIndex;

    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      when_consume: option ? option.id : null,
    };

    setGroupOption(newArray);
  };

  const handleChangePrescription = (event, option) => {
    const prescriptionMsgListWTimes = option.message.replace(
      /<T>/g,
      selectedGroup.number_of_times !== null
        ? selectedGroup.number_of_times
        : ""
    );
    setSelectedGroup((p) => ({
      ...p,
      patient_instructions: prescriptionMsgListWTimes,
    }));

    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      patient_instructions: prescriptionMsgListWTimes,
    };

    setGroupOption(newArray);
  };

  const handlePacksOverrride = (event) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      is_packs_override: event.target.checked,
      no_of_packs: memoSelectedGroup.no_of_packs,
    };
    setGroupOption(newArray);
  };
  return (
    <>
      <Grid item xs={4} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          type="number"
          onWheel={(event) => event.target.blur()}
          InputProps={{
            inputProps: {
              min: 0,
              step: "any",
              style: { textAlign: "right" },
            },
          }}
          onKeyDown={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E" ||
              event?.key === "."
            ) {
              event.preventDefault();
            }
          }}
          value={
            memoSelectedGroup.number_of_days !== null
              ? memoSelectedGroup.number_of_days
              : ""
          }
          onChange={(e) => handleMedicineGroupNumInfo(e)}
          required
          id="Day"
          cy_data="number_of_days"
          onFocus={(event) => {
            event.target.select();
          }}
          name="number_of_days"
          label={t("translation:Day")}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          InputProps={{
            inputProps: {
              min: 0,
              step: "any",
              style: { textAlign: "right" },
            },
          }}
          onKeyDown={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E" ||
              event?.key === "."
            ) {
              event.preventDefault();
            }
          }}
          value={
            memoSelectedGroup.number_of_times !== null
              ? memoSelectedGroup.number_of_times
              : ""
          }
          type="number"
          onWheel={(event) => event.target.blur()}
          onChange={(e) => {
            handleMedicineGroupNumInfo(e);
          }}
          required
          id="Times"
          name="number_of_times"
          cy_data="number_of_times"
          label={t("translation:Times")}
          variant="outlined"
          onFocus={(event) => {
            event.target.select();
          }}
        />
      </Grid>
      <Grid item xs={4} className={classes.userInfoField}>
        {common.medicineCosumeTimeList && (
          <Autocomplete
            disabled={viewOnly}
            name="when_consume"
            cy_data="when_consume"
            disableClearable
            options={common.medicineCosumeTimeList.when_consume_select_field}
            value={memoSelectedGroup.when_consume}
            getOptionLabel={(option) => getMedicineCosumeTimeOpObj(option).name}
            isOptionEqualToValue={(option, value) => {
              if (option.id === value) return option;
            }}
            style={{ width: "100%" }}
            onChange={(e, option) =>
              handleWhenConsume(e, option == null ? null : option)
            }
            renderInput={(params) => (
              <TextField
                required
                className={classes.userFieldColor}
                {...params}
                label={t("translation:When")}
                variant="outlined"
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          value={
            selectedGroup.dispensary_instructions
              ? selectedGroup.dispensary_instructions
              : ""
          }
          multiline
          rows={3}
          name="dispensary_instructions"
          cy_data="dispensary_instructions"
          label={t("translation:Dispensary Instructions")}
          variant="outlined"
          onChange={(e) => handleOnChangeKeyIn(e)}
          onBlur={(e) => handleMedicineGroupInfo(e)}
        />
      </Grid>
      {!viewOnly &&
        (prescriptionMsgList !== null && prescriptionMsgList?.length) !== 0 && (
          <Grid item xs={12} className={classes.userInfoField}>
            <Autocomplete
              disabled={viewOnly}
              name="patient_messages"
              cy_data="patient_messages"
              disableClearable
              inputValue={selectedPrescriptionMsg}
              value={
                selectedPrescriptionMsg === null ? "" : selectedPrescriptionMsg
              }
              options={prescriptionMsgList ? prescriptionMsgList : []}
              getOptionLabel={(option) => (option ? option.message : "")}
              isOptionEqualToValue={() => {
                return true;
              }}
              style={{ width: "100%" }}
              onChange={(e, option) => {
                handleChangePrescription(e, option);
                setSelectedPrescriptionMsg("");
              }}
              disablePortal
              sx={{
                "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                  '&[aria-selected="true"]': {
                    backgroundColor: "rgba(255,255,255)",
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  className={classes.userFieldColor}
                  {...params}
                  onChange={(event) => {
                    setSelectedPrescriptionMsg(event.target.value);
                  }}
                  label={t("translation:Prescription Message")}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          value={
            selectedGroup.patient_instructions
              ? selectedGroup.patient_instructions
              : ""
          }
          multiline
          rows={3}
          name="patient_instructions"
          cy_data="patient_instructions"
          label={t("translation:Prescription Instructions")}
          variant="outlined"
          onChange={(e) => handleOnChangeKeyIn(e)}
          onBlur={(e) => handleMedicineGroupInfo(e)}
        />
      </Grid>

      <Grid item xs={12} className={classes.userInfoField}>
        <FormControlLabel
          control={
            <StyledCheckbox
              disabled={viewOnly}
              checked={memoSelectedGroup.is_packs_override ? true : false}
              onChange={handlePacksOverrride}
              name="overridePacks"
            />
          }
          label={t("translation:Override Number of Packs")}
        />
      </Grid>
    </>
  );
}
