import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getSystemScheduleList,
  getSystemScheduleById,
  postCreateSystemSchedule,
  putUpdateSystemSchedule,
} from "../actions/systemScheduleActions";
import * as types from "../_constants/systemScheduleConstants";
import * as notification from "../_constants/notificationConstants";
import systemScheduleApis from "../_apis/systemScheduleApis";
export default function* systemScheduleSagas() {
  yield all([
    takeLatest(
      types.GET_SYSTEMSCHEDULELIST_ASYNC.PENDING,
      getSystemScheduleListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_SYSTEMSCHEDULEBYID_ASYNC.PENDING,
      getSystemScheduleByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATESYSTEMSCHEDULE_ASYNC.PENDING,
      postCreateSystemScheduleCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATESYSTEMSCHEDULE_ASYNC.PENDING,
      putUpdateSystemScheduleCall
    ),
  ]);
}

function* getSystemScheduleListCall(searchModel) {
  try {
    const data = yield call(
      systemScheduleApis.getSystemScheduleListApi,
      searchModel.data
    );
    yield put(getSystemScheduleList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getSystemScheduleList.error(error.response?.data?.message));
    else yield put(getSystemScheduleList.error(notification.CONST_NOINTERNET));
  }
}

function* getSystemScheduleByIdCall(searchModel) {
  try {
    const data = yield call(
      systemScheduleApis.getSystemScheduleByIdApi,
      searchModel.data
    );
    yield put(getSystemScheduleById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getSystemScheduleById.error(error.response?.data?.message));
    else yield put(getSystemScheduleById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateSystemScheduleCall(searchModel) {
  try {
    const data = yield call(
      systemScheduleApis.postCreateSystemScheduleApi,
      searchModel.data
    );
    yield put(postCreateSystemSchedule.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateSystemSchedule.error(error.response?.data?.message));
    else
      yield put(postCreateSystemSchedule.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateSystemScheduleCall(searchModel) {
  try {
    const data = yield call(
      systemScheduleApis.putUpdateSystemScheduleApi,
      searchModel.data
    );
    yield put(putUpdateSystemSchedule.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateSystemSchedule.error(error.response?.data?.message));
    else
      yield put(putUpdateSystemSchedule.error(notification.CONST_NOINTERNET));
  }
}
