//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { IconButton, Grid, Collapse, TextField } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";

import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
//actions

import {
  postBranchPhysicianScheduleByBranchIdPhysicianId,
  getBranchPhysicianScheduleList,
  getBranchPhysicianSchedulePhysicianList,
} from "../../../../actions/branchPhysicianScheduleActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import OpeningHourPanel from "../components/OpeningHourPanel";
import OpeningHourModal from "../components/OpeningHourModal";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import Panel from "../../../../components/Panel";
import { useStyles } from "../../../../components/globalStyles";
import * as generalConstants from "../../../../_constants/generalConstants";

export const EditBranchPhysicianSchedulePage = ({ branchId, setEditView }) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const branchPhysicianSchedule = useSelector(
    (state) => state.branchPhysicianSchedule
  );
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [dayweek, setDayweek] = useState(null);
  const [physician, setPhysician] = useState(null);
  const [msgAlarm, setMsgAlarm] = useState("");
  const [scheduleHour, setScheduleHour] = useState(null);
  const prevBranchPhysicianScheduleStatus = useRef();
  const prevBranchPhysicianScheduleStatusById = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [endTimeError, setEndTimeError] = useState([]);

  //functions

  const handleOnChangePhysician = (value) => {
    setScheduleHour(null);
    setPhysician(value);
    dispatch(
      getBranchPhysicianScheduleList.pending({
        is_pagination: false,
        order_by_direction: null,
        order_by_field: null,
        page_number: null,
        page_size: null,
        search_branch_desc_value: null,
        search_branch_id_value: branchId,
        search_branch_name_value: null,
        search_physician_id_value: value.user.id,
        search_physician_name_value: null,
        search_desc_value: null,
        search_name_value: null,
      })
    );
  };

  const onSubmit = (data) => {
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (scheduleHour.length > 0) {
      const jsonText = {
        branch_physician_schedules: scheduleHour,
        branchId: branchId,
        physicianId: physician.user.id,
      };
      dispatch(
        postBranchPhysicianScheduleByBranchIdPhysicianId.pending(jsonText)
      );
    } else {
      setErrorAlarm(true);
      setMsgAlarm(t("translation:Missing Schedule"));
    }
  };

  const { handleSubmit } = useForm();

  useEffect(() => {
    //set ref when first render
    prevBranchPhysicianScheduleStatus.current = false;
    prevBranchPhysicianScheduleStatusById.current = false;
  }, []);

  useEffect(() => {
    dispatch(
      getBranchPhysicianSchedulePhysicianList.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );
  }, [dispatch]);
  useEffect(() => {
    if (
      branchPhysicianSchedule.isLoadingBranchPhysicianScheduleList !==
      prevBranchPhysicianScheduleStatus.current
    ) {
      //check the previous ref with current state
      prevBranchPhysicianScheduleStatus.current =
        branchPhysicianSchedule.isLoadingBranchPhysicianScheduleList;

      if (
        branchPhysicianSchedule.getBranchPhysicianScheduleListIsSuccess ===
          true &&
        branchPhysicianSchedule.isLoadingBranchPhysicianScheduleList === false
      ) {
        setScheduleHour(
          branchPhysicianSchedule.branchPhysicianScheduleList
            .branch_physician_schedules
        );
      }
      if (
        branchPhysicianSchedule.getBranchPhysicianScheduleListIsSuccess ===
          false &&
        branchPhysicianSchedule.isLoadingBranchPhysicianScheduleList === false
      ) {
        setScheduleHour([]);
      }
    }
  }, [branchPhysicianSchedule]);

  useEffect(() => {
    if (
      branchPhysicianSchedule.isLoadingPostBranchPhysicianScheduleByBranchId !==
      prevBranchPhysicianScheduleStatusById.current
    ) {
      //check the previous ref with current state
      prevBranchPhysicianScheduleStatusById.current =
        branchPhysicianSchedule.isLoadingPostBranchPhysicianScheduleByBranchId;

      if (
        branchPhysicianSchedule.postBranchPhysicianScheduleByBranchIdIsSuccess ===
          true &&
        branchPhysicianSchedule.isLoadingPostBranchPhysicianScheduleByBranchId ===
          false
      ) {
        setScheduleHour(
          branchPhysicianSchedule.postBranchPhysicianScheduleByBranchId
            .branch_physician_schedules
        );
        setSuccessAlarm(true);
        setErrorAlarm(false);
      }
      if (
        branchPhysicianSchedule.postBranchPhysicianScheduleByBranchIdIsSuccess ===
          false &&
        branchPhysicianSchedule.isLoadingPostBranchPhysicianScheduleByBranchId ===
          false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(
          branchPhysicianSchedule.error
            .postBranchPhysicianScheduleByBranchIdError
        );
      }
    }
  }, [branchPhysicianSchedule]);

  return (
    <Panel heading={t("translation:Edit Branch Physician Schedule")}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Branch Physician Schedule has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid xs={12} item className={classes.userInfoField}>
          {branchPhysicianSchedule.getBranchPhysicianSchedulePhysicianListIsSuccess && (
            <Autocomplete
              name="Physician"
              disableClearable
              value={physician}
              options={
                branchPhysicianSchedule.branchPhysicianSchedulePhysicianList &&
                Object.keys(
                  branchPhysicianSchedule.branchPhysicianSchedulePhysicianList
                    .organisation_physician
                ).length !== 0
                  ? branchPhysicianSchedule.branchPhysicianSchedulePhysicianList.organisation_physician.sort(
                      (a, b) => -b.user.name.localeCompare(a.user.name)
                    )
                  : []
              }
              getOptionLabel={(option) =>
                option.user.name ? option.user.name : ""
              }
              isOptionEqualToValue={(option, value) => {
                if (option.id === value.id) return option;
              }}
              style={{ width: "100%" }}
              onChange={(e, option) => {
                handleOnChangePhysician(option);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Physician")}
                  variant="outlined"
                />
              )}
            />
          )}
        </Grid>
        <br />

        {scheduleHour && (
          <>
            <OpeningHourPanel
              scheduleHour={scheduleHour}
              setScheduleHour={setScheduleHour}
              dayweek={dayweek}
              setDayweek={setDayweek}
              setOpenAddModal={setOpenAddModal}
              endTimeError={endTimeError}
              setEndTimeError={setEndTimeError}
            />
            <OpeningHourModal
              openModal={openAddModal}
              setOpenModal={setOpenAddModal}
              dayweek={dayweek}
              branchId={branchId}
              setScheduleHour={setScheduleHour}
              physician={physician.user}
            />
          </>
        )}

        <Grid className={classes.root} container>
          <RequiredNote />
          <PageSubmitWBackAction setView={setEditView} />
        </Grid>
      </form>
    </Panel>
  );
};
