import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAllRoleList,
  getRoleList,
  getRoleById,
  postCreateRole,
  putUpdateRole,
} from "../actions/roleActions";
import * as types from "../_constants/roleConstants";
import * as notification from "../_constants/notificationConstants";
import roleApis from "../_apis/roleApis";
export default function* userRoleSagas() {
  yield all([
    takeLatest(types.GET_ALLROLELIST_ASYNC.PENDING, getAllRoleListCall),
    takeLatest(types.GET_ROLELIST_ASYNC.PENDING, getRoleListCall),
  ]);

  yield all([takeLatest(types.GET_ROLEBYID_ASYNC.PENDING, getRoleByIdCall)]);
  yield all([
    takeLatest(types.POST_CREATEROLE_ASYNC.PENDING, postCreateRoleCall),
  ]);
  yield all([
    takeLatest(types.PUT_UPDATEROLE_ASYNC.PENDING, putUpdateRoleCall),
  ]);
}

function* getAllRoleListCall() {
  try {
    const data = yield call(roleApis.getAllRoleListApi);
    yield put(getAllRoleList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAllRoleList.error(error.response?.data?.message));
    else yield put(getAllRoleList.error(notification.CONST_NOINTERNET));
  }
}

function* getRoleListCall(searchModel) {
  try {
    const data = yield call(roleApis.getRoleListApi, searchModel.data);
    yield put(getRoleList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getRoleList.error(error.response?.data?.message));
    else yield put(getRoleList.error(notification.CONST_NOINTERNET));
  }
}

function* getRoleByIdCall(searchModel) {
  try {
    const data = yield call(roleApis.getRoleByIdApi, searchModel.data);
    yield put(getRoleById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getRoleById.error(error.response?.data?.message));
    else yield put(getRoleById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateRoleCall(searchModel) {
  try {
    const data = yield call(roleApis.postCreateRoleApi, searchModel.data);
    yield put(postCreateRole.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateRole.error(error.response?.data?.message));
    else yield put(postCreateRole.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateRoleCall(searchModel) {
  try {
    const data = yield call(roleApis.putUpdateRoleApi, searchModel.data);
    yield put(putUpdateRole.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateRole.error(error.response?.data?.message));
    else yield put(putUpdateRole.error(notification.CONST_NOINTERNET));
  }
}
