import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/medicineConstants";

export const getMedicineById = {
  pending: (data) =>
    createAction(types.GET_MEDICINEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_MEDICINEBYID_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_MEDICINEBYID_ASYNC.ERROR, { error }),
};

export const getMedicineList = {
  pending: (data) =>
    createAction(types.GET_MEDICINELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_MEDICINELIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_MEDICINELIST_ASYNC.ERROR, { error }),
};

export const postCreateMedicine = {
  pending: (data) =>
    createAction(types.POST_CREATEMEDICINE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEMEDICINE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEMEDICINE_ASYNC.ERROR, { error }),
};

export const putUpdateMedicine = {
  pending: (data) =>
    createAction(types.PUT_UPDATEMEDICINE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEMEDICINE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEMEDICINE_ASYNC.ERROR, { error }),
};

export const getMedicineMedicineTypes = {
  pending: (data) =>
    createAction(types.GET_MEDICINEMEDICINETYPES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_MEDICINEMEDICINETYPES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_MEDICINEMEDICINETYPES_ASYNC.ERROR, { error }),
};
