import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getDashboardAppointmentList,
  getDashboardBrewingList,
  getDashboardDispensaryList,
  getDashboardDeliveryOrderList,
  getDashboardInvoiceList,
  getDashboardInvoiceMedicineCostingList,
  getDashboardStoreOrderList,
  getDashboardVisitOrderList,
  getDashboardAllApprovedLocalDispensary,
  getDashboardAllStoreWDispensaryList,
  getDashboardCounterView,
} from "../actions/dashboardActions";
import * as types from "../_constants/dashboardConstants";
import * as notification from "../_constants/notificationConstants";
import dashboardApis from "../_apis/dashboardApis";
export default function* dashboardSagas() {
  yield all([
    takeLatest(
      types.GET_DASHBOARDAPPOINTMENTLIST_ASYNC.PENDING,
      getDashboardAppointmentListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_DASHBOARDBREWINGLIST_ASYNC.PENDING,
      getDashboardBrewingListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DASHBOARDDELIVERYORDERLIST_ASYNC.PENDING,
      getDashboardDeliveryOrderListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DASHBOARDDISPENSARYLIST_ASYNC.PENDING,
      getDashboardDispensaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DASHBOARDINVOICELIST_ASYNC.PENDING,
      getDashboardInvoiceListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DASHBOARDINVOICEMEDICINECOSTINGLIST_ASYNC.PENDING,
      getDashboardInvoiceMedicineCostingListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DASHBOARDSTOREORDERLIST_ASYNC.PENDING,
      getDashboardStoreOrderListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DASHBOARDVISITORDERLIST_ASYNC.PENDING,
      getDashboardVisitOrderListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DASHBOARDALLAPPROVEDLOCALDISPENSARY_ASYNC.PENDING,
      getDashboardAllApprovedLocalDispensaryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DASHBOARDALLSTOREWDISPENSARYLIST_ASYNC.PENDING,
      getDashboardAllStoreWDispensaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DASHBOARDCOUNTERVIEW_ASYNC.PENDING,
      getDashboardCounterViewCall
    ),
  ]);
}

function* getDashboardAppointmentListCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardAppointmentListApi,
      Model.data
    );
    yield put(getDashboardAppointmentList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDashboardAppointmentList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDashboardAppointmentList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDashboardBrewingListCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardBrewingListApi,
      Model.data
    );
    yield put(getDashboardBrewingList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDashboardBrewingList.error(error.response?.data?.message));
    else
      yield put(getDashboardBrewingList.error(notification.CONST_NOINTERNET));
  }
}

function* getDashboardDeliveryOrderListCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardDeliveryOrderListApi,
      Model.data
    );
    yield put(getDashboardDeliveryOrderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDashboardDeliveryOrderList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDashboardDeliveryOrderList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDashboardDispensaryListCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardDispensaryListApi,
      Model.data
    );
    yield put(getDashboardDispensaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDashboardDispensaryList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDashboardDispensaryList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDashboardInvoiceListCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardInvoiceListApi,
      Model.data
    );
    yield put(getDashboardInvoiceList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDashboardInvoiceList.error(error.response?.data?.message));
    else
      yield put(getDashboardInvoiceList.error(notification.CONST_NOINTERNET));
  }
}

function* getDashboardInvoiceMedicineCostingListCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardInvoiceMedicineCostingListApi,
      Model.data
    );
    yield put(getDashboardInvoiceMedicineCostingList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDashboardInvoiceMedicineCostingList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getDashboardInvoiceMedicineCostingList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getDashboardStoreOrderListCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardStoreOrderListApi,
      Model.data
    );
    yield put(getDashboardStoreOrderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDashboardStoreOrderList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDashboardStoreOrderList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDashboardVisitOrderListCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardVisitOrderListCallApi,
      Model.data
    );
    yield put(getDashboardVisitOrderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDashboardVisitOrderList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDashboardVisitOrderList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDashboardAllApprovedLocalDispensaryCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardAllApprovedLocalDispensaryApi,
      Model.data
    );
    yield put(getDashboardAllApprovedLocalDispensary.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDashboardAllApprovedLocalDispensary.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getDashboardAllApprovedLocalDispensary.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getDashboardAllStoreWDispensaryListCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardAllStoreWDispensaryListApi,
      Model.data
    );
    yield put(getDashboardAllStoreWDispensaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDashboardAllStoreWDispensaryList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDashboardAllStoreWDispensaryList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDashboardCounterViewCall(Model) {
  try {
    const data = yield call(
      dashboardApis.getDashboardCounterViewApi,
      Model.data
    );
    yield put(getDashboardCounterView.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDashboardCounterView.error(error.response?.data?.message));
    else
      yield put(getDashboardCounterView.error(notification.CONST_NOINTERNET));
  }
}
