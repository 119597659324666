import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getNotificationList,
  getNotificationById,
  postCreateNotification,
  putUpdateNotification,
} from "../actions/notificationActions";
import * as types from "../_constants/notificationsConstants";
import * as notification from "../_constants/notificationConstants";
import notificationApis from "../_apis/notificationApis";
export default function* notificationSagas() {
  yield all([
    takeLatest(
      types.GET_NOTIFICATIONLIST_ASYNC.PENDING,
      getNotificationListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_NOTIFICATIONBYID_ASYNC.PENDING,
      getNotificationByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATENOTIFICATION_ASYNC.PENDING,
      postCreateNotificationCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATENOTIFICATION_ASYNC.PENDING,
      putUpdateNotificationCall
    ),
  ]);
}

function* getNotificationListCall(searchModel) {
  try {
    const data = yield call(
      notificationApis.getNotificationListApi,
      searchModel.data
    );
    yield put(getNotificationList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getNotificationList.error(error.response?.data?.message));
    else yield put(getNotificationList.error(notification.CONST_NOINTERNET));
  }
}

function* getNotificationByIdCall(searchModel) {
  try {
    const data = yield call(
      notificationApis.getNotificationByIdApi,
      searchModel.data
    );
    yield put(getNotificationById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getNotificationById.error(error.response?.data?.message));
    else yield put(getNotificationById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateNotificationCall(searchModel) {
  try {
    const data = yield call(
      notificationApis.postCreateNotificationApi,
      searchModel.data
    );
    yield put(postCreateNotification.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateNotification.error(error.response?.data?.message));
    else yield put(postCreateNotification.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateNotificationCall(searchModel) {
  try {
    const data = yield call(
      notificationApis.putUpdateNotificationApi,
      searchModel.data
    );
    yield put(putUpdateNotification.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateNotification.error(error.response?.data?.message));
    else yield put(putUpdateNotification.error(notification.CONST_NOINTERNET));
  }
}
