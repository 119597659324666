import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";
import { Buffer } from "buffer";

const curUserApis = {
  putOrganisationInfoApi,
  getOrganisationInfoApi,
  getOrganisationApi,
  getOrganisationBranchListApi,
  getOrganisationBranchListByIdApi,
  putUserInfoApi,
  putUserLangApi,
  getCurRoleRightApi,
  getAllRightAccessApi,
  getDefaultBranchApi,
  postTopUpCreditApi,
  getCreditBalanceApi,
  getAllAllianceOrganisationApi,
  getAllOrganisationStripeApi,
  getDefaultAllianceApi,
  getOrganisationLogoApi,
  getCurrentUserUserCreditListApi,
};

async function putOrganisationInfoApi(userModel) {
  const requestOptions = JSON.stringify(userModel);

  return await axios
    .put(apiConstants.apiPutOrganisationInfo, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getOrganisationInfoApi() {
  return await axios
    .get(apiConstants.apiGetOrganisationInfo)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getOrganisationApi(model) {
  const url = apiConstants.apiGetOrganisation + model;
  return await axios
    .get(url)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getOrganisationBranchListApi() {
  return await axios
    .get(apiConstants.apiGetOrganisationBranchList)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getOrganisationBranchListByIdApi(model) {
  const url = apiConstants.apiGetOrganisationBranchListById + model;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUserInfoApi(userModel) {
  const requestOptions = JSON.stringify(userModel);
  return await axios
    .put(apiConstants.apiPutUserInfo, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUserLangApi(userModel) {
  const requestOptions = JSON.stringify(userModel);
  return await axios
    .put(apiConstants.apiPutUserLang, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getCurRoleRightApi() {
  return await axios
    .get(apiConstants.apiGetCurRoleRight)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllRightAccessApi() {
  return await axios
    .get(apiConstants.apiGetAllRightAccess)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDefaultBranchApi() {
  return await axios
    .get(apiConstants.apiGetDefaultBranch)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postTopUpCreditApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiPostTopUpCredit, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getCreditBalanceApi() {
  return await axios
    .get(apiConstants.apiGetCreditBalance)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllAllianceOrganisationApi() {
  return await axios
    .get(apiConstants.apiGetAllAllianceOrganisation)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllOrganisationStripeApi() {
  return await axios
    .get(apiConstants.apiGetAllOrganisationStripe)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDefaultAllianceApi() {
  return await axios
    .get(apiConstants.apiGetDefaultAlliance)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getOrganisationLogoApi(model) {
  const url = apiConstants.apiGetOrganisationLogo + model;
  return await axios
    .get(url, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const allRepos = response.data;
      let base64ImageString = Buffer.from(allRepos, "binary").toString(
        "base64"
      );
      let srcValue = {
        id: model,
        data: "data:image/png;base64," + base64ImageString,
      };
      return srcValue;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getCurrentUserUserCreditListApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiGetCurrentUserUserCreditList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default curUserApis;
