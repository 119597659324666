//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";

//actions
import { putUpdateOrganisationPhysician } from "../../../../actions/organisationPhysicianActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import UploadPhoto from "./UploadPhoto";
import UploadSignature from "./UploadSignature";

export const EditOrganisationPhysicianPage = ({
  organisationPhysician,
  setEditOrganisationPhysicianView,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [uploadPhotoObjects, setUploadPhotoObjects] = useState({
    photo: organisationPhysician?.photo,
    photo_original_filename: organisationPhysician?.photo_original_filename,
  });

  const [uploadSignatureObjects, setUploadSignatureObjects] = useState({
    signature: organisationPhysician?.signature,
    signature_original_filename:
      organisationPhysician?.signature_original_filename,
  });

  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  const [msgAlarm, setMsgAlarm] = useState("");
  const organisationPhysicianData = useSelector(
    (state) => state.organisationPhysician
  );
  const common = useSelector((state) => state.common);
  const prevSubmitStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  const curUser = useSelector((state) => state.curUser);

  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };
  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJson = {
      organisation_physician: [
        {
          ...organisationPhysician,
          ...data,
          user: { id: organisationPhysician.user.id },
          appt_time_block: Number(data.appt_time_block),
          consultation_charge: Number(data.consultation_charge),
          id: Number(data.id),
          multiplier: Number(data.multiplier),

          photo: uploadPhotoObjects.photo,
          photo_original_filename: uploadPhotoObjects.photo_original_filename,
          signature: uploadSignatureObjects.signature,
          signature_original_filename:
            uploadSignatureObjects.signature_original_filename,
        },
      ],
    };

    dispatch(putUpdateOrganisationPhysician.pending(submitJson));
  };

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      id: organisationPhysician?.id,
      user: organisationPhysician?.user,
      appt_time_block: organisationPhysician?.appt_time_block,
      consultation_charge: organisationPhysician?.consultation_charge,
      multiplier: organisationPhysician?.multiplier,
      photo: organisationPhysician?.photo,
      photo_original_filename: organisationPhysician?.photo_original_filename,
      signature: organisationPhysician?.signature,
      signature_original_filename:
        organisationPhysician?.signature_original_filename,
      status_type: organisationPhysician?.status_type,
    },
  });

  useEffect(() => {
    //set ref when first render
    prevSubmitStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      organisationPhysicianData.isLoadingPutUpdateOrganisationPhysician !==
      prevSubmitStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitStatus.current =
        organisationPhysicianData.isLoadingPutUpdateOrganisationPhysician;

      if (
        organisationPhysicianData.putUpdateOrganisationPhysicianIsSuccess ===
          true &&
        organisationPhysicianData.isLoadingPutUpdateOrganisationPhysician ===
          false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...organisationPhysicianData.putUpdateOrganisationPhysician
            .organisation_physician[0],
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        organisationPhysicianData.putUpdateOrganisationPhysicianIsSuccess ===
          false &&
        organisationPhysicianData.isLoadingPutUpdateOrganisationPhysician ===
          false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(
          organisationPhysicianData.error.putUpdateOrganisationPhysicianError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [organisationPhysicianData, reset]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:Organisation Physician Details")
          : t("translation:Edit Organisation Physician")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Organisation Physician has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <input hidden readOnly {...register("id")} />

        <Grid className={classes.root} container>
          <Grid item xs={12} md={6}>
            <UploadPhoto
              organisationCode={
                curUser.organisationInfo?.user_organisations.find(
                  (o) => o.is_default === true
                )?.organisations.codename
              }
              folderPath="photo"
              uploadFileObjects={uploadPhotoObjects}
              setUploadFileObjects={setUploadPhotoObjects}
            />
          </Grid>

          <Grid item xs={6}>
            <UploadSignature
              organisationCode={
                curUser.organisationInfo?.user_organisations.find(
                  (o) => o.is_default === true
                )?.organisations.codename
              }
              folderPath="signature"
              uploadFileObjects={uploadSignatureObjects}
              setUploadFileObjects={setUploadSignatureObjects}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={true}
              readOnly
              className={classes.userFieldColor}
              name="name"
              label={t("translation:Name")}
              variant="outlined"
              {...register("user.name")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E" ||
                  event?.key === "."
                ) {
                  event.preventDefault();
                }
              }}
              {...register("appt_time_block")}
              cy_data="appt_time_block"
              onFocus={(event) => {
                event.target.select();
              }}
              name="appt_time_block"
              label={t("translation:Appointment Time Block")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              {...register("consultation_charge")}
              cy_data="consultation_charge"
              onFocus={(event) => {
                event.target.select();
              }}
              name="consultation_charge"
              label={t("translation:Consultation Charge")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              {...register("multiplier")}
              cy_data="multiplier"
              onFocus={(event) => {
                event.target.select();
              }}
              name="multiplier"
              label={t("translation:Multiplier")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly}
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>

          <>
            <RequiredNote />

            {temViewOnly ? (
              <PageOnlyBackAction
                setView={() => setEditOrganisationPhysicianView(false)}
              />
            ) : (
              <PageSubmitWBackAction
                setView={() => setEditOrganisationPhysicianView(false)}
              />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
