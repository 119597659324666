import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/filterRuleConstants";

export const getFilterRuleById = {
  pending: (data) =>
    createAction(types.GET_FILTERRULEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_FILTERRULEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_FILTERRULEBYID_ASYNC.ERROR, { error }),
};

export const getFilterRuleList = {
  pending: (data) =>
    createAction(types.GET_FILTERRULELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_FILTERRULELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_FILTERRULELIST_ASYNC.ERROR, { error }),
};

export const postCreateFilterRule = {
  pending: (data) =>
    createAction(types.POST_CREATEFILTERRULE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEFILTERRULE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEFILTERRULE_ASYNC.ERROR, { error }),
};

export const putUpdateFilterRule = {
  pending: (data) =>
    createAction(types.PUT_UPDATEFILTERRULE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEFILTERRULE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEFILTERRULE_ASYNC.ERROR, { error }),
};

export const getAllModelNames = {
  pending: (data) =>
    createAction(types.GET_ALLMODELNAMES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ALLMODELNAMES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ALLMODELNAMES_ASYNC.ERROR, { error }),
};
