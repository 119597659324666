import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const workOrderApis = {
  // getDispensaryBranchesApi,
  getMedicineTypesApi,
  postBranchMedicinesApi,
  postFormulasAllianceApi,
  postMedicineFormulasAllianceApi,
  getCurrentOrganisationApi,
  postSaveStoreOrderApi,
  postSubmitStoreOrderApi,
  getStoreOrderListApi,
  getStoreOrderSummaryListApi,
  getStoreOrderByIdApi,
  putStoreEditModeApi,
  getMedicineTypesAllianceApi,
  getDispensaryBranchesAllianceApi,
  putStorePrintDateApi,
  getStorePrescriptionMsgListApi,
  getStoreMedicineInfoApi,
  getStorePostalCodeSurchargeListApi,
  getStoreAvailableProceedOnApi,
  getStoreVerifyAddressApi,
  postStoreChangeBranchApi,
};

// async function getDispensaryBranchesApi() {
//   return await axios
//     .get(apiConstants.apiGetDispensaryBranches, headerWithauth)
//     .then((response) => {
//       const allRepos = response.data;
//       return allRepos;
//     })
//     .catch((error) => {
//       return Promise.reject(error);
//     });
// }

async function getMedicineTypesApi() {
  return await axios
    .get(apiConstants.apiGetMedicineTypes, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDispensaryBranchesAllianceApi() {
  return await axios
    .get(apiConstants.apiGetDispensaryBranchesAlliance, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineTypesAllianceApi(searchModel) {
  const url = apiConstants.apiGetMedicineTypesAlliance + searchModel;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postFormulasAllianceApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostFormulasAlliance, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postMedicineFormulasAllianceApi(searchModel) {
  const url = apiConstants.apiPostMedicineFormulasAlliance + searchModel.id;
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postBranchMedicinesApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostBranchMedicines, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getCurrentOrganisationApi() {
  return await axios
    .get(apiConstants.apiGetCurrentOrganisation, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSaveStoreOrderApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiPostSaveStoreOrder, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitStoreOrderApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiPostSubmitStoreOrder, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStoreOrderListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetStoreOrderList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStoreOrderSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetStoreOrderSummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStoreOrderByIdApi(id) {
  const url = apiConstants.apiGetStoreOrderById + id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putStoreEditModeApi(id) {
  const url = apiConstants.apiPutStoreEditMode + id;
  return await axios
    .put(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putStorePrintDateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(apiConstants.apiPutStorePrintDate, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStorePrescriptionMsgListApi() {
  return await axios
    .get(apiConstants.apiGetStorePrescriptionMsgList, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStoreMedicineInfoApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetStoreMedicineInfo, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStorePostalCodeSurchargeListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetStorePostalCodeSurchargeList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStoreAvailableProceedOnApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetStoreAvailableProceedOn,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStoreVerifyAddressApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetStoreVerifyAddress, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postStoreChangeBranchApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostStoreChangeBranch, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default workOrderApis;
