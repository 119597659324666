import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const deliveryCutoffScheduleApis = {
  getDeliveryCutoffScheduleListApi,
  getDeliveryCutoffScheduleByIdApi,
  putUpdateDeliveryCutoffScheduleApi,
  postSearchDeliveryCutoffScheduleBranchApi,
};

async function getDeliveryCutoffScheduleListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(
      apiConstants.apiGetDeliveryCutoffScheduleList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryCutoffScheduleByIdApi(model) {
  const url = apiConstants.apiGetDeliveryCutoffScheduleById + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateDeliveryCutoffScheduleApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutDeliveryCutoffSchedule,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSearchDeliveryCutoffScheduleBranchApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostDeliveryCutoffScheduleBranch,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default deliveryCutoffScheduleApis;
