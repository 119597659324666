import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getMedicineFixedSellingPriceList,
  getMedicineFixedSellingPriceSummaryList,
  getMedicineFixedSellingPriceById,
  postCreateMedicineFixedSellingPrice,
  putUpdateMedicineFixedSellingPrice,
} from "../actions/medicineFixedSellingPriceActions";
import * as types from "../_constants/medicineFixedSellingPriceConstants";
import * as notification from "../_constants/notificationConstants";
import medicineFixedSellingPriceApis from "../_apis/medicineFixedSellingPriceApis";
export default function* medicineFixedSellingPriceSagas() {
  yield all([
    takeLatest(
      types.GET_MEDICINEFIXEDSELLINGPRICELIST_ASYNC.PENDING,
      getMedicineFixedSellingPriceListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_MEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.PENDING,
      getMedicineFixedSellingPriceSummaryListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_MEDICINEFIXEDSELLINGPRICEBYID_ASYNC.PENDING,
      getMedicineFixedSellingPriceByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATEMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING,
      postCreateMedicineFixedSellingPriceCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING,
      putUpdateMedicineFixedSellingPriceCall
    ),
  ]);
}

function* getMedicineFixedSellingPriceListCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceApis.getMedicineFixedSellingPriceListApi,
      searchModel.data
    );
    yield put(getMedicineFixedSellingPriceList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getMedicineFixedSellingPriceList.error(error.response?.data?.message)
      );
    else
      yield put(
        getMedicineFixedSellingPriceList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getMedicineFixedSellingPriceSummaryListCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceApis.getMedicineFixedSellingPriceSummaryListApi,
      searchModel.data
    );
    yield put(getMedicineFixedSellingPriceSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getMedicineFixedSellingPriceSummaryList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getMedicineFixedSellingPriceSummaryList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getMedicineFixedSellingPriceByIdCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceApis.getMedicineFixedSellingPriceByIdApi,
      searchModel.data
    );
    yield put(getMedicineFixedSellingPriceById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getMedicineFixedSellingPriceById.error(error.response?.data?.message)
      );
    else
      yield put(
        getMedicineFixedSellingPriceById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postCreateMedicineFixedSellingPriceCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceApis.postCreateMedicineFixedSellingPriceApi,
      searchModel.data
    );
    yield put(postCreateMedicineFixedSellingPrice.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postCreateMedicineFixedSellingPrice.error(error.response?.data?.message)
      );
    else
      yield put(
        postCreateMedicineFixedSellingPrice.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateMedicineFixedSellingPriceCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceApis.putUpdateMedicineFixedSellingPriceApi,
      searchModel.data
    );
    yield put(putUpdateMedicineFixedSellingPrice.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateMedicineFixedSellingPrice.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdateMedicineFixedSellingPrice.error(notification.CONST_NOINTERNET)
      );
  }
}
