import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useStyles } from "../../../../../components/globalStyles";

export default function EditVisitNotesDetails({
  visitNotesDetails = [],
  setVisitNotesDetails = () => null,
  viewOnly = false,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedVisitNote, setSelectedVisitNote] = useState([]);
  const handleOnChangeKeyIn = (event, value) => {
    setSelectedVisitNote((p) => ({
      ...p,
      [event.target.name]: event.target.value ? event.target.value : null,
    }));
  };

  const handleOnBlur = (event, value) => {
    setVisitNotesDetails((p) => ({
      ...p,
      [event.target.name]: event.target.value ? event.target.value : null,
    }));
  };

  //useEffects
  useEffect(() => {
    setSelectedVisitNote(visitNotesDetails);
  }, [visitNotesDetails, setSelectedVisitNote]);
  return (
    <>
      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          inputProps={{
            maxLength: 2048,
          }}
          value={
            selectedVisitNote.review_notes ? selectedVisitNote.review_notes : ""
          }
          onChange={(e) => {
            handleOnChangeKeyIn(e);
          }}
          onBlur={(e) => {
            handleOnBlur(e);
          }}
          multiline
          rows={20}
          name="review_notes"
          label={
            t("translation:Review Notes") +
            ` ${
              selectedVisitNote.review_notes
                ? selectedVisitNote.review_notes.length
                : 0
            }/2048`
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          inputProps={{
            maxLength: 512,
          }}
          value={
            selectedVisitNote.tongue_diagnosis
              ? selectedVisitNote.tongue_diagnosis
              : ""
          }
          onChange={(e) => {
            handleOnChangeKeyIn(e);
          }}
          onBlur={(e) => {
            handleOnBlur(e);
          }}
          multiline
          rows={3}
          name="tongue_diagnosis"
          label={
            t("translation:Tongue Diagnosis") +
            ` ${
              selectedVisitNote.tongue_diagnosis
                ? selectedVisitNote.tongue_diagnosis.length
                : 0
            }/512`
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          inputProps={{
            maxLength: 512,
          }}
          value={
            selectedVisitNote.pulse_diagnosis
              ? selectedVisitNote.pulse_diagnosis
              : ""
          }
          onChange={(e) => {
            handleOnChangeKeyIn(e);
          }}
          onBlur={(e) => {
            handleOnBlur(e);
          }}
          multiline
          rows={3}
          name="pulse_diagnosis"
          label={
            t("translation:Pulse Diagnosis") +
            ` ${
              selectedVisitNote.pulse_diagnosis
                ? selectedVisitNote.pulse_diagnosis.length
                : 0
            }/512`
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          inputProps={{
            maxLength: 512,
          }}
          value={
            selectedVisitNote.physician_diagnosis
              ? selectedVisitNote.physician_diagnosis
              : ""
          }
          onChange={(e) => {
            handleOnChangeKeyIn(e);
          }}
          onBlur={(e) => {
            handleOnBlur(e);
          }}
          multiline
          rows={3}
          name="physician_diagnosis"
          label={
            t("translation:Physician Diagnosis") +
            ` ${
              selectedVisitNote.physician_diagnosis
                ? selectedVisitNote.physician_diagnosis.length
                : 0
            }/512`
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          inputProps={{
            maxLength: 512,
          }}
          value={selectedVisitNote.syndrome ? selectedVisitNote.syndrome : ""}
          onChange={(e) => {
            handleOnChangeKeyIn(e);
          }}
          onBlur={(e) => {
            handleOnBlur(e);
          }}
          multiline
          rows={3}
          name="syndrome"
          label={
            t("translation:Syndrome") +
            ` ${
              selectedVisitNote.syndrome ? selectedVisitNote.syndrome.length : 0
            }/512`
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          inputProps={{
            maxLength: 512,
          }}
          value={
            selectedVisitNote.western_diagnosis
              ? selectedVisitNote.western_diagnosis
              : ""
          }
          onChange={(e) => {
            handleOnChangeKeyIn(e);
          }}
          onBlur={(e) => {
            handleOnBlur(e);
          }}
          multiline
          rows={3}
          name="western_diagnosis"
          label={
            t("translation:Diagnosis (Western)") +
            ` ${
              selectedVisitNote.western_diagnosis
                ? selectedVisitNote.western_diagnosis.length
                : 0
            }/512`
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          inputProps={{
            maxLength: 512,
          }}
          value={
            selectedVisitNote.physician_advice
              ? selectedVisitNote.physician_advice
              : ""
          }
          onChange={(e) => {
            handleOnChangeKeyIn(e);
          }}
          onBlur={(e) => {
            handleOnBlur(e);
          }}
          multiline
          rows={3}
          name="physician_advice"
          label={
            t("translation:Physician's Advice") +
            ` ${
              selectedVisitNote.physician_advice
                ? selectedVisitNote.physician_advice.length
                : 0
            }/512`
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          inputProps={{
            maxLength: 512,
          }}
          value={selectedVisitNote.others ? selectedVisitNote.others : ""}
          onChange={(e) => {
            handleOnChangeKeyIn(e);
          }}
          onBlur={(e) => {
            handleOnBlur(e);
          }}
          multiline
          rows={3}
          name="others"
          label={
            t("translation:Others") +
            ` ${
              selectedVisitNote.others ? selectedVisitNote.others.length : 0
            }/512`
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </>
  );
}
