import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import OrderSummaryPage from "./Store/OrderSummaryPage";
import AddOrderSummaryPage from "./Store/AddOrderSummaryPage";
import DispensarySummaryPage from "./Dispensary/DispensarySummaryPage";
import BrewingSummaryPage from "./Brewing/BrewingSummaryPage";
import DeliverySummaryPage from "./Delivery/DeliverySummaryPage";
import DeliveryTrackingSummaryPage from "./DeliveryTracking/DeliveryTrackingSummaryPage";
import FulfillmentSummaryPage from "./Fulfillment/FulfillmentSummaryPage";
import PackingSummaryPage from "./Packing/PackingSummaryPage";

export default function WorkOrderPage() {
  return (
    <Switch>
      <Route path="/store/OrderSummary" component={OrderSummaryPage} />
      <Route path="/store/AddOrderSummary" component={AddOrderSummaryPage} />
      <Route
        path="/store/DispensarySummary"
        component={DispensarySummaryPage}
      />
      <Route path="/store/BrewingSummary" component={BrewingSummaryPage} />
      <Route path="/store/DeliverySummary" component={DeliverySummaryPage} />

      <Route
        path="/store/DeliveryTrackingSummary"
        component={DeliveryTrackingSummaryPage}
      />
      <Route path="/store/PackingSummary" component={PackingSummaryPage} />
      <Route
        path="/store/FulfillmentSummary"
        component={FulfillmentSummaryPage}
      />
      <Redirect to="/error" />
    </Switch>
  );
}
