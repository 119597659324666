//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import Popper from "@mui/material/Popper";
import { useTranslation } from "react-i18next";

//action
import {
  getOrderFormMedicineTypes,
  postOrderFormMedicines,
  postOrderFormSuppliers,
} from "../../../../actions/orderFormActions";

//components
import { useStyles } from "../../../../components/globalStyles";
import * as generalConstants from "../../../../_constants/generalConstants";
import Panel from "../../../../components/Panel";

const OrderFormField = ({
  medicineList,
  setMedicineList,
  supplier,
  setSupplier,
  supplierGst,
  setSupplierGst,
  viewMode = false,
  copyMode = false,
}) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const orderForm = useSelector((state) => state.orderForm);
  const [selectedMedItem, setSelectedMedItem] = useState("");

  const [medicineType, setMedicineType] = useState(null);

  //functions
  const optionAllow = (options) => {
    const result = options.filter(
      (item) => item.status_type !== generalConstants.DELETED_STATUS_TYPE
    );

    return result;
  };
  const handleMedicineTypeOption = (option) => {
    setMedicineType(option);

    const JsonText = {
      is_pagination: false,
      order_by_direction: null,
      order_by_field: null,
      page_number: null,
      page_size: null,
      search_desc_value: null,
      search_id_value: null,
      search_medicine_type_id_value: option.id,
      search_name_value: null,
      search_status_type_value: null,
    };
    dispatch(postOrderFormMedicines.pending(JsonText));
  };

  const handleSelectedMedicine = (newValue) => {
    const selectedMedicine = {
      id: 0,
      // create quantity
      quantity: null,
      package_content: newValue.package_content,
      unit_conversion: newValue.unit_conversion,
      medicine_name: newValue.name,
      price: newValue.unit_conversion
        ? newValue.unit_conversion * newValue.unit_cost_price
        : null,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
      medicine: newValue,
      sub_total: null,
    };
    let orderFormDetails;

    //checking same medicine and dont allow same medicine exist in the same group
    const repeatMedicine = medicineList.some(
      (item) =>
        item.medicine.id === newValue.id &&
        item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
    //find array that status_type === 7 (deleted)
    const repeatDeletedTreatmentIndex = medicineList.findIndex(
      (item) =>
        item.medicine.id === newValue.id &&
        item.status_type === generalConstants.DELETED_STATUS_TYPE
    );

    if (repeatMedicine) {
      orderFormDetails = [...medicineList];
    } else if (repeatDeletedTreatmentIndex !== -1) {
      orderFormDetails = [...medicineList];

      orderFormDetails[repeatDeletedTreatmentIndex] = {
        ...orderFormDetails[repeatDeletedTreatmentIndex],
        //id: (use back existing)
        // create quantity
        quantity: null,
        package_content: newValue.package_content,
        unit_conversion: newValue.unit_conversion,
        medicine_name: newValue.name,
        price: newValue.unit_conversion
          ? newValue.unit_conversion * newValue.unit_cost_price
          : null,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
        medicine: newValue,
        sub_total: null,
      };
    } else {
      orderFormDetails = [...medicineList, selectedMedicine];
    }

    setMedicineList(orderFormDetails);
  };

  //useEffects
  useEffect(() => {
    dispatch(getOrderFormMedicineTypes.pending());
    dispatch(postOrderFormSuppliers.pending({ is_pagination: false }));
  }, [dispatch]);
  return (
    <Panel>
      <Grid className={classes.gridRoot} container>
        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            value={supplier}
            required
            disabled={viewMode && !copyMode}
            disableClearable
            name="suppliers"
            cy_data="suppliers"
            id="suppliers"
            options={
              orderForm.orderFormSuppliers
                ? orderForm.orderFormSuppliers.suppliers
                : []
            }
            getOptionLabel={(option) => (option.name ? option.name : "")}
            style={{ width: "100%" }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, option) => {
              setSupplier(option);
              setSupplierGst({
                is_gst_enable: option.is_gst_enable,
                is_gst_inclusive: option.is_gst_inclusive,
                gst_value: option.gst_value,
              });
            }}
            renderInput={(params) => (
              <TextField
                disabled={viewMode}
                className={classes.userFieldColor}
                {...params}
                required
                label={t("translation:Suppliers")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        {(!viewMode || copyMode) && (
          <>
            <Grid item xs={12} className={classes.userInfoField}>
              <Autocomplete
                value={medicineType}
                disableClearable
                name="medicine_type"
                cy_data="addOrderMedicineType"
                id="medicine_type"
                options={
                  orderForm.orderFormMedicineTypes
                    ? optionAllow(
                        orderForm.orderFormMedicineTypes.medicine_types
                      )
                    : []
                }
                getOptionLabel={(option) => (option.name ? option.name : "")}
                style={{ width: "100%" }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, option) => handleMedicineTypeOption(option)}
                renderInput={(params) => (
                  <TextField
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Medicine Type")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.userInfoField}>
              <Autocomplete
                inputValue={selectedMedItem}
                disabled={medicineType ? false : true}
                value={selectedMedItem === null ? "" : selectedMedItem}
                name="branchMedicines"
                id="branchMedicines"
                cy_data="addOrderBranchMedicine"
                options={
                  orderForm.orderFormMedicines
                    ? orderForm.orderFormMedicines.medicines
                    : []
                }
                getOptionLabel={(option) => (option ? option.name : "")}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                style={{ width: "100%" }}
                isOptionEqualToValue={() => {
                  return true;
                }}
                disableClearable
                onChange={(e, option) => {
                  handleSelectedMedicine(option);
                  setSelectedMedItem("");
                }}
                disablePortal
                sx={{
                  "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                    '&[aria-selected="true"]': {
                      backgroundColor: "rgba(255,255,255)",
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //clear the field to empty
                    onChange={(event) => {
                      setSelectedMedItem(event.target.value);
                    }}
                    className={classes.userFieldColor}
                    label={<span>{t("translation:Medicine")}</span>}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Panel>
  );
};

export default OrderFormField;
