//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//components
import AddOrderPage from "./AddOrderPage";
import StoreListTable from "./StoreListTable";
import { useStyles } from "../../../components/globalStyles";
//actions

const AddOrderSummaryPage = () => {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  const [storeListView, setStoreListView] = React.useState(false);
  const [storeInformationView, setStoreInformationView] = React.useState(false);

  //useEffects

  const common = useSelector((state) => state.common);

  if (common.getGeneralStatusIsSuccess === true) {
    return (
      <Paper className={classes.summaryPaper} elevation={3}>
        {!storeInformationView && (
          <Grid container direction="row" alignItems="center">
            <Grid item xs={11}>
              {storeListView ? (
                <Typography className={classes.title} variant="h3" gutterBottom>
                  {t("translation:Store Summary")}
                </Typography>
              ) : (
                <Typography className={classes.title} variant="h3" gutterBottom>
                  {t("translation:Add Order")}
                </Typography>
              )}
            </Grid>

            {storeListView ? (
              <Grid item xs={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonColor}
                  onClick={() => setStoreListView(!storeListView)}>
                  {t("translation:Add")}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={1}></Grid>
            )}
          </Grid>
        )}
        <hr />
        {storeListView === false && (
          <AddOrderPage setStoreListView={setStoreListView} />
        )}
        {storeListView && (
          <StoreListTable setStoreInformationView={setStoreInformationView} />
        )}
      </Paper>
    );
  } else {
    return <>{t("translation:Loading")}...</>;
  }
};

export default AddOrderSummaryPage;
