import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/curUserConstants";

/*please using putUserInfo from auth actions to change user profile
  in order to update user information from redux
*/
export const putUserInfo = {
  pending: (data) => createAction(types.PUT_USERINFO_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.PUT_USERINFO_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.PUT_USERINFO_ASYNC.ERROR, { error }),
};

export const getOrganisationInfo = {
  pending: (data) =>
    createAction(types.GET_ORGANISATIONINFO_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ORGANISATIONINFO_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ORGANISATIONINFO_ASYNC.ERROR, { error }),
};

export const getOrganisationBranchList = {
  pending: (data) =>
    createAction(types.GET_ORGANISATIONBRANCHLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ORGANISATIONBRANCHLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ORGANISATIONBRANCHLIST_ASYNC.ERROR, { error }),
};

export const getOrganisationBranchListById = {
  pending: (data) =>
    createAction(types.GET_ORGANISATIONBRANCHLISTBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ORGANISATIONBRANCHLISTBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ORGANISATIONBRANCHLISTBYID_ASYNC.ERROR, { error }),
};

export const putOrganisationInfo = {
  pending: (data) =>
    createAction(types.PUT_ORGANISATIONINFO_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_ORGANISATIONINFO_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_ORGANISATIONINFO_ASYNC.ERROR, { error }),
};

export const getOrganisation = {
  pending: (data) =>
    createAction(types.GET_ORGANISATION_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ORGANISATION_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_ORGANISATION_ASYNC.ERROR, { error }),
};

export const putUserLang = {
  pending: (data) => createAction(types.PUT_USERLANG_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.PUT_USERLANG_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.PUT_USERLANG_ASYNC.ERROR, { error }),
};

export const getCurRoleRight = {
  pending: (data) =>
    createAction(types.GET_CURROLERIGHT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_CURROLERIGHT_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_CURROLERIGHT_ASYNC.ERROR, { error }),
};

export const getAllRightAccess = {
  pending: (data) =>
    createAction(types.GET_ALLRIGHTACCESS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ALLRIGHTACCESS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ALLRIGHTACCESS_ASYNC.ERROR, { error }),
};

export const getDefaultBranch = {
  pending: () => createAction(types.GET_DEFAULTBRANCH_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_DEFAULTBRANCH_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DEFAULTBRANCH_ASYNC.ERROR, { error }),
};

export const postTopUpCredit = {
  pending: (data) =>
    createAction(types.POST_TOPUPCREDIT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_TOPUPCREDIT_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.POST_TOPUPCREDIT_ASYNC.ERROR, { error }),
};

export const getCreditBalance = {
  pending: () => createAction(types.GET_CREDITBALANCE_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_CREDITBALANCE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_CREDITBALANCE_ASYNC.ERROR, { error }),
};

export const getAllAllianceOrganisation = {
  pending: () => createAction(types.GET_ALLALLIANCEORGANISATION_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_ALLALLIANCEORGANISATION_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ALLALLIANCEORGANISATION_ASYNC.ERROR, { error }),
};

export const getAllOrganisationStripe = {
  pending: () => createAction(types.GET_ALLORGANISATIONSTRIPE_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_ALLORGANISATIONSTRIPE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ALLORGANISATIONSTRIPE_ASYNC.ERROR, { error }),
};

export const getDefaultAlliance = {
  pending: () => createAction(types.GET_DEFAULTALLIANCE_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_DEFAULTALLIANCE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DEFAULTALLIANCE_ASYNC.ERROR, { error }),
};

export const getOrganisationLogo = {
  pending: (data) =>
    createAction(types.GET_ORGANISATIONLOGO_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ORGANISATIONLOGO_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ORGANISATIONLOGO_ASYNC.ERROR, { error }),
};

export const getCurrentUserUserCreditList = {
  pending: (data) =>
    createAction(types.GET_CURRENTUSERUSERCREDITLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_CURRENTUSERUSERCREDITLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_CURRENTUSERUSERCREDITLIST_ASYNC.ERROR, { error }),
};
