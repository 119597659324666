//lib
import React, { useEffect, useState, useCallback, useRef } from "react";
import { Button, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Font } from "@react-pdf/renderer";
//actions
import { getPaymentMethod } from "../../../../actions/commonActions";

import {
  putDispensaryInvoicePrintDate,
  getDispensaryInvoiceOrder,
} from "../../../../actions/dispensaryActions";
import { postCreatePresignedUrlOrganisationLogo } from "../../../../actions/cloudStorageActions";
//components
import { useStyles } from "../../../../components/globalStyles";
import * as generalConstants from "../../../../_constants/generalConstants";
import InvoicePDF from "../../../../components/PDF/InvoicePDF";
import NotoSansHansRegular from "../../../../components/PDF/NotoSansHans-Regular-2.ttf";

//register font before calling pdf file
Font.register({
  family: "NotoSansSChinese",
  fonts: [{ src: NotoSansHansRegular }],
});

Font.registerHyphenationCallback((word) =>
  word.length === 1 ? [word] : Array.from(word).map((char) => char)
);
const InvoicePDFPage = ({
  organisationCode,
  folderPath,
  organisationLogoUrl,
  TCMLogo,
  invoiceInfo,
  setTCMLOGO,
  setInvoiceInfo,
  setPrintingOrganisationLogo = () => null,
  allowPrint,
  setAllowPrint = () => null,
}) => {
  const [pdfFontLoaded, setPdfFontLoaded] = useState(false);
  const loadFonts = useCallback(async () => {
    await Promise.all([Font.load({ fontFamily: "NotoSansSChinese" })]).then(
      () => {
        setPdfFontLoaded(true);
      }
    );
  }, []);

  useEffect(() => {
    loadFonts();
  }, [loadFonts]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  //variables
  const common = useSelector((state) => state.common);
  const dispensary = useSelector((state) => state.dispensary);
  const [treatmentDetail, setTreatmentDetail] = useState(null);
  const [miscDetail, setMiscDetail] = useState(null);
  const [print, setPrint] = React.useState(0);
  const [paidAmount, setPaidAmount] = React.useState(0);

  const prevSetPrintInvoiceDateOnStatus = useRef();
  const prevSetInvoiceOrderStatus = useRef();
  //function
  const setPrintingLogo = () => {
    setPrint(new Date().valueOf());
    setTCMLOGO(null);

    //const organisationId = invoiceInfo.store_header.organisation.id;

    //dispatch(getOrganisationLogo.pending(organisationId));

    setAllowPrint(false);
    const submitJson = setPrintingOrganisationLogo(
      organisationCode,
      folderPath,
      organisationLogoUrl
    );
    dispatch(postCreatePresignedUrlOrganisationLogo.pending(submitJson));
  };

  const setPrintingDate = () => {
    dispatch(
      putDispensaryInvoicePrintDate.pending({
        dispensary_header: {
          id: dispensary.dispensaryById.dispensary_header.id,
        },
      })
    );
  };

  //useEffect
  //useEffects

  useEffect(() => {
    prevSetPrintInvoiceDateOnStatus.current = false;
    prevSetInvoiceOrderStatus.current = false;
  }, []);

  useEffect(() => {
    dispatch(getPaymentMethod.pending());
  }, [dispatch]);

  useEffect(() => {
    if (invoiceInfo?.invoice_details.length > 0) {
      const temAccAmount = invoiceInfo.invoice_details.reduce((sum, li) => {
        if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          return parseFloat((sum + li.payment_amount).toFixed(2));
        }
        return parseFloat(sum.toFixed(2));
      }, 0);

      setPaidAmount(temAccAmount);
    }
  }, [invoiceInfo]);

  useEffect(() => {
    var result = [];

    if (invoiceInfo?.store_header.visit_treatment_details.length > 0) {
      invoiceInfo.store_header.visit_treatment_details.reduce(function (
        res,
        value
      ) {
        if (value.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          if (!res[value.treatment.treatment_type.id]) {
            res[value.treatment.treatment_type.id] = {
              name: value.treatment_type_name,
              amount: 0,
            };
            result.push(res[value.treatment.treatment_type.id]);
          }
          res[value.treatment.treatment_type.id].amount = (
            Number(res[value.treatment.treatment_type.id].amount) +
            Number(value.quantity * value.unit_selling_price)
          ).toFixed(2);
          return res;
        }
        return res;
      },
      {});
    }
    setTreatmentDetail(result);
  }, [invoiceInfo]);

  useEffect(() => {
    var result = [];

    if (invoiceInfo?.store_header.visit_misc_details.length > 0) {
      const detailsWoDelete =
        invoiceInfo.store_header.visit_misc_details.filter(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        );
      result = detailsWoDelete.map((value) => {
        if (value.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          return {
            ...value,
            amount: Number(value.quantity * value.unit_selling_price).toFixed(
              2
            ),
          };
        }
        return null;
      }, {});
    }

    const resultWoEmpty = result.filter((resultItem) => resultItem !== null);

    setMiscDetail(resultWoEmpty);
  }, [invoiceInfo]);

  useEffect(() => {
    if (
      dispensary.isLoadingPutDispensaryInvoicePrintDate !==
      prevSetPrintInvoiceDateOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetPrintInvoiceDateOnStatus.current =
        dispensary.isLoadingPutDispensaryInvoicePrintDate;

      if (
        dispensary.putDispensaryInvoicePrintDateIsSuccess === true &&
        dispensary.isLoadingPutDispensaryInvoicePrintDate === false
      ) {
        setAllowPrint(true);
        dispatch(
          getDispensaryInvoiceOrder.pending({
            dispensary_header: {
              id: dispensary.dispensaryById.dispensary_header.id,
            },
          })
        );
      }
    }
  }, [dispensary, dispatch]);

  useEffect(() => {
    if (
      dispensary.isLoadingGetDispensaryInvoiceOrder !==
      prevSetInvoiceOrderStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetInvoiceOrderStatus.current =
        dispensary.isLoadingGetDispensaryInvoiceOrder;

      if (
        dispensary.getDispensaryInvoiceOrderIsSuccess === true &&
        dispensary.isLoadingGetDispensaryInvoiceOrder === false
      ) {
        setInvoiceInfo(dispensary.getDispensaryInvoiceOrder.invoice_header);
      }
    }
  }, [dispensary, setInvoiceInfo]);
  return (
    <Link
      onClick={() => {
        setPrintingLogo();
      }}
      className={classes.boldMaroon075}>
      <Button className={classes.boldMaroon075}>
        {t("translation:PRINT INVOICE")}
      </Button>

      {pdfFontLoaded && TCMLogo && common.paymentMethodList && print !== 0 && (
        <InvoicePDF
          t={t}
          TCMLogo={TCMLogo}
          print={print}
          paidAmount={paidAmount}
          invoiveHeader={invoiceInfo}
          storeHeader={invoiceInfo.store_header}
          miscDetail={miscDetail}
          treatmentDetail={treatmentDetail}
          paymentOptions={common.paymentMethodList.payment_methods}
          setPrintingDate={setPrintingDate}
          allowPrint={allowPrint}
          setAllowPrint={setAllowPrint}
        />
      )}
    </Link>
  );
};

export default InvoicePDFPage;
