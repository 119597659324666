import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./globalStyles";

export default function RequiredNote() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12} className={classes.relativePositionWrapper}>
      <Grid item xs={12} className={classes.rightAbsolutePosition}>
        {t("translation:Required fields in this form are marked")}
        <span className={classes.requiredBoldMaroon075}> *</span>.
      </Grid>
    </Grid>
  );
}
