import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const homecareApis = {
  getAllVisitByLastModifiedApi,
  getVerifyMedicineApi,
  postUpdateHomecareMedicineApi,
  getAllVisitByTimestampRangeApi,
  getUpdateHCVisitOrderByStoreIdApi,
  postUpdateHomecareManualHc2cmsApi,
};

async function getAllVisitByLastModifiedApi(model) {
  const url = apiConstants.apiGetAllVisitByLastModified + model.organisationId;

  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function getVerifyMedicineApi() {
  return await axios
    .get(apiConstants.apiGetVerifyMedicine, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postUpdateHomecareMedicineApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostUpdateHomecareMedicine,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getAllVisitByTimestampRangeApi(model) {
  const url =
    apiConstants.apiGetAllVisitByTimestampRange +
    model.organisationId +
    "/" +
    model.visitUTCDateFrom +
    "/" +
    model.visitUTCDateTo;

  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getUpdateHCVisitOrderByStoreIdApi(model) {
  const url = apiConstants.apiGetUpdateHCVisitOrderByStoreId + model.storeId;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postUpdateHomecareManualHc2cmsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostUpdateHomecareManualHc2cms,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default homecareApis;
