import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PostalCodeSurchargeSummary from "./PostalCodeSurchargeSummary/PostalCodeSurchargeSummaryPage";
export default function PostalCodeSurchargePage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/postalCodeSurcharge"
        to="/postalCodeSurcharge/postalCodeSurchargeSummary"
      />

      <Route
        path="/postalCodeSurcharge/postalCodeSurchargeSummary"
        component={PostalCodeSurchargeSummary}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
