//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
//import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//components
import DispensaryListTable from "./DispensaryListTable";
import { useStyles } from "../../../components/globalStyles";
//actions

const DispensarySummaryPage = () => {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  //display and hide the "dispensary summary" table
  const [dispensaryInformationView, setDispensaryInformationView] =
    React.useState(false);

  //useEffects

  //const workOrder = useSelector((state) => state.workOrder);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!dispensaryInformationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title} variant="h3" gutterBottom>
              {t("translation:Dispensary Summary")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <hr />

      <DispensaryListTable
        setDispensaryInformationView={setDispensaryInformationView}
      />
    </Paper>
  );
};

export default DispensarySummaryPage;
