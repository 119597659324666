//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Link,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import externetApis from "../../../_apis/externetApis";
import { BroadcastChannel } from "broadcast-channel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//action
import {
  getGender,
  getNationality,
  getMarital,
  getRace,
  getLanguage,
  getGeneralStatus,
  getMfaType,
} from "../../../actions/commonActions";

import { postVerifyEmailRq } from "../../../actions/authActions";

import { putChangePassword, putUserInfo } from "../../../actions/authActions";

import {
  putOrganisationInfo,
  getOrganisationInfo,
  getOrganisationBranchList,
  getOrganisationBranchListById,
  getDefaultBranch,
  getAllRightAccess,
  getCreditBalance,
  getDefaultAlliance,
} from "../../../actions/curUserActions";

//component
import Panel from "../../../components/Panel";
import * as pageConfigure from "../../../_constants/pageConstants";
import allowOnlyNumericsOrDigits from "../../../components/functions/allowOnlyNumericsOrDigits";
import { useStyles } from "../../../components/globalStyles";
import PageOnlySubmitAction from "../../../components/PageOnlySubmitAction";
import RequiredNote from "../../../components/RequiredNote";
export const EditUserProfilePage = () => {
  //variables

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successEmailVerifyRqAlert, setSuccessEmailVerifyRqAlert] =
    useState(false);
  const [errorEmailVerifyRqAlert, setErrorEmailVerifyRqAlert] = useState(false);
  const [successPasswordChangeAlert, setSuccessPasswordChangeAlert] =
    useState(false);
  const [errorPasswordChangeAlert, setErrorPasswordChangeAlert] =
    useState(false);
  const [successOrganisationChangeAlert, setSuccessOrganisationChangeAlert] =
    useState(false);
  const [errorOrganisationChangeAlert, setErrorOrganisationChangeAlert] =
    useState(false);
  const [msgAlert, setMsgAlert] = useState("");
  const common = useSelector((state) => state.common);
  const userAuth = useSelector((state) => state.userAuth);
  const curUser = useSelector((state) => state.curUser);
  const organizationInfo = curUser.organisationInfo;
  //reducer will change userAuth.systemUserInfo after every update
  const user = userAuth.systemUserInfo;
  const [values, setValues] = React.useState({
    showCurrentPassword: false,
    showPassword: false,
  });

  const [open, setOpen] = React.useState(false);

  const [options, setOptions] = React.useState([]);

  const loading = open;

  const [postcodeSearchPageNum, setPostcodeSearchPageNum] = useState(null);
  const [postcodeSearch, setPostcodeSearch] = useState(null);
  const [addressDisabled, setAddressDisabled] = React.useState(
    user.postal_code ? true : false
  );
  const [selectedPostalCode, setSelectedPostalCode] = useState(
    user.postal_code
  );
  const [allianceOption, setAllianceOption] = React.useState(false);
  //function

  const handlePostalCodeChange = (event) => {
    setSelectedPostalCode(event.target.value);
    if (event.target.value.length === 6) {
      setOpen(true);
      setPostcodeSearch(event.target.value);
      setOptions([]);
      setPostcodeSearchPageNum(1);
    }

    setValue("postal_code", null);

    setAddressDisabled(false);

    setValue("address", null);
    setValue("blk_no", null);
    setValue("road_name", null);
    setValue("building", null);
  };

  const handleSelectedPostalCode = (event) => {
    if (addressDisabled) {
      setSelectedPostalCode(event.target.value);
    } else {
      setSelectedPostalCode("");
    }
  };
  const handleAddressChange = (option) => {
    if (option) {
      setAddressDisabled(true);

      // const addressWithoutPostalIndex = option.ADDRESS.lastIndexOf("SINGAPORE");
      // const addressWithoutPostal = option.ADDRESS.substring(
      //   0,
      //   addressWithoutPostalIndex - 1
      // );

      setValue("address", option.ADDRESS);
      setValue("blk_no", option.BLK_NO);
      setValue("road_name", option.ROAD_NAME);
      setValue("building", option.BUILDING !== "NIL" ? option.BUILDING : null);
      setSelectedPostalCode(option.POSTAL);
      setValue("postal_code", option.POSTAL);
    }
  };

  const handleClickShowCurrentPassword = () => {
    setValues({ ...values, showCurrentPassword: !values.showCurrentPassword });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const prevPasswordChangeStatus = useRef();
  const prevProfileChangeStatus = useRef();
  const prevOrganisationChangeStatus = useRef();
  const prevPutOrganisationChangeStatus = useRef();
  const prevEmailVerifyStatus = useRef();
  const prevDefaultBranch = useRef();
  const prevDefaultAllianceOption = useRef();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: user.id,
      username: user.username,
      email: user.email,
      name: user.name,
      identification_number: user.identification_number,
      physician_license_no: user.physician_license_no,
      postal_code: user.postal_code,
      address_details: user.address_details,
      address: user.address,
      blk_no: user.blk_no,
      building: user.building,
      road_name: user.road_name,
      contact_number: user.contact_number,
      occupation: user.occupation,
      allergy: user.allergy,
      medical_history: user.medical_history,
      gender_type: user.gender_type,
      marital_type: user.marital_type,
      date_of_birth: user.date_of_birth ? dayjs(user.date_of_birth) : null,
      age: user.date_of_birth
        ? Math.floor(moment().diff(user.date_of_birth, "years", true))
        : " ",
      race: user.race,
      nationality: user.nationality,
      language: user.language,
      nok_contact_name: user.nok_contact_name,
      nok_contact_number: user.nok_contact_number,
      mfa_type: user.mfa_type,
      is_mfa_enabled: user.is_mfa_enabled,
    },
  });

  const {
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    control: control2,
  } = useForm();

  const {
    register: register3,
    handleSubmit: handleSubmit3,

    formState: { errors: errors3 },
  } = useForm();

  //functions

  const getGenderOpObj = (option) => {
    if (!option.id)
      option = common.genderList.gender.find((op) => op.id === option);
    return option;
  };

  const getMaritalOpObj = (option) => {
    if (!option.id)
      option = common.maritalList.marital.find((op) => op.id === option);
    return option;
  };

  const getMfaTypelOpObj = (option) => {
    if (!option.id)
      option = common.getMfaType.mfa_type.find((op) => op.id === option);
    return option;
  };

  const onSubmit = (data) => {
    dispatch(putUserInfo.pending(data));
  };

  const onSubmitPassword = (data, event) => {
    dispatch(putChangePassword.pending(data));
  };

  const handleAllianceOption = (selectedBranchOrganisation) => {
    const allAllAllianceOption =
      curUser.allAllianceOrganisation.alliances.filter((item) => {
        const isAlliance = item.alliance_organisation_associations.some(
          (itemResult) =>
            itemResult.organisation.id ===
            selectedBranchOrganisation.organisations.id
        );

        return isAlliance;
      });

    const allAllAllianceOptionWoDuplicate = allAllAllianceOption.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    setAllianceOption(allAllAllianceOptionWoDuplicate);
  };

  const onSubmitOrganisation = (data) => {
    let defaultOrganisation = {};

    //need this because defualt submit unable to get organisation_branch id
    const setOrganisationBranch =
      curUser.organisationBranchList.organisation_branch.find(
        (o) => o.branch.id === data.organisation_branch.branch.id
      );

    defaultOrganisation = {
      user: organizationInfo.user,

      user_organisations: [
        {
          id: data.organisation.id,
          is_default: true,
          organisations: data.organisation.organisations,
          alliance: data.alliance,
          organisation_branch: setOrganisationBranch,
          status_type: data.organisation.status_type,
        },
      ],
    };

    dispatch(putOrganisationInfo.pending(defaultOrganisation));
  };

  const handleVerifyEmail = (data, event) => {
    event.preventDefault();
    const userEmailVerifyInfo = {
      username: data.username,
      email: data.email,
    };
    dispatch(postVerifyEmailRq.pending(userEmailVerifyInfo));
  };

  const handleDateOfBirth = (value) => {
    var birthYear = dayjs(value).format("YYYY-MM-DD");
    var userAge = Math.floor(moment().diff(birthYear, "years", true));
    setValue("age", userAge ? userAge : 0); // set age through reat hook form because it need to initial.
    setValue("date_of_birth", birthYear);
  };

  // const setDefaultOrganisation = () => {
  //   const curOrganisations = organizationInfo.user_organisations.find(
  //     (o) => o.is_default === true
  //   );

  //   if (curOrganisations) {
  //     setValue("organisation", curOrganisations);

  //     return curOrganisations;
  //   }
  //   return null;
  // };

  //useEffects

  useEffect(() => {
    dispatch(getRace.pending());
    dispatch(getMarital.pending());
    dispatch(getNationality.pending());
    dispatch(getGender.pending());
    dispatch(getLanguage.pending());
    dispatch(getMfaType.pending());
    //must call getOrganisationInfo in order to default branch api
    dispatch(getOrganisationInfo.pending());
  }, [dispatch]);

  useEffect(() => {
    register("race");
    register("marital_type");
    register("nationality");
    register("gender_type");
    register("date_of_birth");
    register("language");
  }, [register]);

  useEffect(() => {
    //set ref when first render

    prevPasswordChangeStatus.current = false;
    prevProfileChangeStatus.current = false;
    prevOrganisationChangeStatus.current = false;
    prevPutOrganisationChangeStatus.current = false;
    prevEmailVerifyStatus.current = false;
    prevDefaultBranch.current = false;
    prevDefaultAllianceOption.current = false;
  }, []);

  useEffect(() => {
    if (userAuth.isLodingVerifyEmailRq !== prevEmailVerifyStatus.current) {
      //check the previous ref with current state
      prevEmailVerifyStatus.current = userAuth.isLodingVerifyEmailRq;

      if (
        userAuth.postVerifyEmailRqSuccess === true &&
        userAuth.isLodingVerifyEmailRq === false
      ) {
        setSuccessEmailVerifyRqAlert(true);
        setErrorEmailVerifyRqAlert(false);
        setMsgAlert(userAuth.verifyEmailRq);
      }

      if (
        userAuth.postVerifyEmailRqSuccess === false &&
        userAuth.isLodingVerifyEmailRq === false
      ) {
        setSuccessEmailVerifyRqAlert(false);
        setErrorEmailVerifyRqAlert(true);
        setMsgAlert(userAuth.error.postVerifyEmailRqError);
      }
    }
  }, [userAuth]);

  useEffect(() => {
    if (userAuth.isLodingChangePassword !== prevPasswordChangeStatus.current) {
      //check the previous ref with current state
      prevPasswordChangeStatus.current = userAuth.isLodingChangePassword;

      if (
        userAuth.changePasswordSuccess === true &&
        userAuth.isLodingChangePassword === false
      ) {
        setSuccessPasswordChangeAlert(true);
        setErrorPasswordChangeAlert(false);
      }
      if (
        userAuth.changePasswordSuccess === false &&
        userAuth.isLodingChangePassword === false
      ) {
        setSuccessPasswordChangeAlert(false);
        setErrorPasswordChangeAlert(true);
        if (userAuth.error.changePasswordError === "Fresh token required") {
          setMsgAlert(
            t("translation:Token expired") +
              "." +
              " " +
              t("translation:Please re-login") +
              "."
          );
        } else {
          setMsgAlert(userAuth.error.changePasswordError);
        }
      }
    }
  }, [userAuth]);

  useEffect(() => {
    if (userAuth.isLoadingUserInfo !== prevProfileChangeStatus.current) {
      //check the previous ref with current state
      prevProfileChangeStatus.current = userAuth.isLoadingUserInfo;
      if (
        userAuth.putUserInfoSuccess === true &&
        userAuth.isLoadingUserInfo === false
      ) {
        setSuccessAlert(true);
        setErrorAlert(false);
        //user may change language and status language need to base on changed language.
        dispatch(getGeneralStatus.pending());
      }
      if (
        userAuth.putUserInfoSuccess === false &&
        userAuth.isLoadingUserInfo === false
      ) {
        setSuccessAlert(false);
        setErrorAlert(true);
        setMsgAlert(userAuth.error.putUserInfoError);
      }
    }
  }, [dispatch, userAuth]);

  useEffect(() => {
    reset({
      userId: user.id,
      username: user.username,
      email: user.email,
      name: user.name,
      identification_number: user.identification_number,
      physician_license_no: user.physician_license_no,
      postal_code: user.postal_code,
      address_details: user.address_details,
      address: user.address,
      blk_no: user.blk_no,
      building: user.building,
      road_name: user.road_name,
      contact_number: user.contact_number,
      allergy: user.allergy,
      medical_history: user.medical_history,
      occupation: user.occupation,
      gender_type: user.gender_type,
      marital_type: user.marital_type,
      date_of_birth: user.date_of_birth
        ? moment(user.date_of_birth).format("YYYY-MM-DD")
        : null,
      age: user.date_of_birth
        ? Math.floor(moment().diff(user.date_of_birth, "years", true))
        : " ",
      race: user.race,
      nationality: user.nationality,
      language: user.language,
      nok_contact_name: user.nok_contact_name,
      nok_contact_number: user.nok_contact_number,
      mfa_type: user.mfa_type,
      is_mfa_enabled: user.is_mfa_enabled,
    });
  }, [user, reset]);

  useEffect(() => {
    if (
      curUser.isLoadingPutOrganisationInfo !==
      prevPutOrganisationChangeStatus.current
    ) {
      //check the previous ref with current state
      prevPutOrganisationChangeStatus.current =
        curUser.isLoadingPutOrganisationInfo;

      if (
        curUser.putOrganisationInfoSuccess === true &&
        curUser.isLoadingPutOrganisationInfo === false
      ) {
        setSuccessOrganisationChangeAlert(true);
        setErrorOrganisationChangeAlert(false);
        dispatch(getAllRightAccess.pending());
        dispatch(getDefaultBranch.pending());
        dispatch(getDefaultAlliance.pending());
        dispatch(getCreditBalance.pending());

        const changeOrgnisationChannel = new BroadcastChannel(
          "changeOrgnisation"
        );
        changeOrgnisationChannel.postMessage({ changeOrgnisation: true });
      }
      if (
        curUser.putOrganisationInfoSuccess === false &&
        curUser.isLoadingPutOrganisationInfo === false
      ) {
        setSuccessOrganisationChangeAlert(false);
        setErrorOrganisationChangeAlert(true);
        setMsgAlert(curUser.error.putOrganisationInfoError);
      }
    }
  }, [dispatch, curUser]);
  useEffect(() => {
    if (
      curUser.isLoadingOrganisationInfo !== prevOrganisationChangeStatus.current
    ) {
      //check the previous ref with current state
      prevOrganisationChangeStatus.current = curUser.isLoadingOrganisationInfo;

      if (
        curUser.getOrganisationInfoIsSuccess === true &&
        curUser.isLoadingOrganisationInfo === false &&
        Object.keys(curUser.organisationInfo.user_organisations).length !== 0
      ) {
        dispatch(getOrganisationBranchList.pending());
        dispatch(getDefaultBranch.pending());
        dispatch(getDefaultAlliance.pending());
      }
    }
  }, [dispatch, curUser]);

  // useEffect(() => {
  //   if (curUser.isLoadingDefaultBranch !== prevDefaultBranch.current) {
  //     //check the previous ref with current state
  //     prevDefaultBranch.current = curUser.isLoadingDefaultBranch;

  //     if (
  //       curUser.getDefaultBranchIsSuccess === true &&
  //       curUser.isLoadingDefaultBranch === false
  //     ) {
  //     }
  //   }
  // }, [curUser]);

  useEffect(() => {
    if (curUser.allAllianceOrganisation && organizationInfo) {
      if (prevDefaultAllianceOption.current === false) {
        prevDefaultAllianceOption.current = true;
        const defaultOrganisation =
          organizationInfo.user_organisations.find(
            (o) => o.is_default === true
          ) || organizationInfo.user_organisations[0];

        const allAllAllianceOption =
          curUser.allAllianceOrganisation.alliances.filter((item, i, a) => {
            const isAlliance = item.alliance_organisation_associations.some(
              (itemResult) =>
                itemResult.organisation.id ===
                defaultOrganisation?.organisations.id
            );

            return isAlliance;
          });
        const allAllAllianceOptionWoDuplicate = allAllAllianceOption.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        );
        setAllianceOption(allAllAllianceOptionWoDuplicate);
      }
    }
  }, [curUser.allAllianceOrganisation, organizationInfo]);

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }
    if (postcodeSearch) {
      (async () => {
        const response = await externetApis.getPostalCodeListApi(
          postcodeSearch,
          postcodeSearchPageNum
        );

        if (response.status === 200) {
          const addressResults = response.data;
          if (addressResults.results && addressResults.results.length > 0) {
            if (addressResults.totalNumPages > addressResults.pageNum) {
              setPostcodeSearchPageNum(addressResults.pageNum + 1);
            }

            if (addressResults.pageNum === 1) {
              setOptions(addressResults.results);
            } else {
              setOptions((optionsResult) => {
                return [...optionsResult, ...addressResults.results];
              });
            }
          } else {
            setOpen(false);
          }
        } else {
          setOpen(false);
        }
      })();
    }
  }, [loading, postcodeSearch, postcodeSearchPageNum]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  return (
    <div>
      {organizationInfo &&
        organizationInfo.user_organisations.length > 0 &&
        curUser.defaultBranch &&
        curUser.defaultAlliance && (
          <Panel heading={t("translation:Change Organisation")}>
            <Collapse in={successOrganisationChangeAlert}>
              <Alert
                cy_data="alertChangeOrganisationBranch"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccessOrganisationChangeAlert(false);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {t("translation:Organisation has been changed")}
              </Alert>
            </Collapse>

            <Collapse in={errorOrganisationChangeAlert}>
              <Alert
                cy_data="alertChangeOrganisationBranch"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorOrganisationChangeAlert(false);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {msgAlert}
              </Alert>
            </Collapse>
            <br />
            <form key={2} onSubmit={handleSubmit2(onSubmitOrganisation)}>
              <Grid className={classes.root} container>
                <>
                  <Grid item xs={12} className={classes.userInfoField}>
                    <Controller
                      name="organisation"
                      defaultValue={
                        organizationInfo.user_organisations.find(
                          (o) => o.is_default === true
                        ) || organizationInfo.user_organisations[0]
                      }
                      render={({ field }) => (
                        <Autocomplete
                          id="organisation"
                          cy_data="organisationAutocomplete"
                          disableClearable
                          onChange={(event, item) => {
                            dispatch(
                              getOrganisationBranchListById.pending(
                                item.organisations.id
                              )
                            );
                            handleAllianceOption(item);
                            setValue2("organisation_branch", null);
                            setValue2("alliance", null);
                            field.onChange(item);
                          }}
                          value={field.value}
                          options={organizationInfo.user_organisations}
                          getOptionLabel={(option) =>
                            option ? option.organisations.name : ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            value === undefined ||
                            value === "" ||
                            option.id === value.id
                          }
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              required
                              cy_data="organisationAutocompleteText"
                              className={classes.userFieldColor}
                              {...params}
                              label={t("translation:Organisation")}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control2}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.userInfoField}>
                    <Controller
                      name="alliance"
                      defaultValue={curUser.defaultAlliance.alliance}
                      render={({ field }) => (
                        <Autocomplete
                          id="alliance"
                          cy_data="allianceAutocomplete"
                          disableClearable
                          onChange={(event, item) => {
                            field.onChange(item);
                          }}
                          value={field.value}
                          options={allianceOption ? allianceOption : []}
                          getOptionLabel={(option) =>
                            option ? option.name : ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            value === undefined ||
                            value === "" ||
                            option.id === value.id
                          }
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              required
                              cy_data="allianceAutocompleteText"
                              className={classes.userFieldColor}
                              {...params}
                              label={t("translation:Alliance")}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control2}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.userInfoField}>
                    <Controller
                      name="organisation_branch"
                      defaultValue={curUser.defaultBranch}
                      render={({ field }) => (
                        <Autocomplete
                          id="organisation_branch"
                          cy_data="organisationBranchAutocomplete"
                          disableClearable
                          onChange={(event, item) => {
                            field.onChange(item);
                          }}
                          value={field.value}
                          options={
                            curUser.organisationBranchList
                              ? curUser.organisationBranchList
                                  .organisation_branch
                              : []
                          }
                          getOptionLabel={(option) =>
                            option.branch ? option.branch.name : ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            value === undefined ||
                            value === "" ||
                            value.branch === null ||
                            option.branch.id === value.branch.id
                          }
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              required
                              className={classes.userFieldColor}
                              {...params}
                              label={t("translation:Branch")}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control2}
                    />
                  </Grid>
                </>
                <>
                  <RequiredNote />
                  <PageOnlySubmitAction
                    button1Name="submitButton"
                    button1label={t("translation:SUBMIT")}
                  />
                </>
              </Grid>
            </form>
          </Panel>
        )}
      <Panel heading={t("translation:User Profile")}>
        <Collapse in={successAlert}>
          <Alert
            data-test="alertChangeProfile"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessAlert(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:User Profile has been updated")}
          </Alert>
        </Collapse>

        <Collapse in={errorAlert}>
          <Alert
            data-test="alertChangeProfile"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorAlert(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlert}
          </Alert>
        </Collapse>

        <Collapse in={successEmailVerifyRqAlert}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessEmailVerifyRqAlert(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlert}
          </Alert>
        </Collapse>

        <Collapse in={errorEmailVerifyRqAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorEmailVerifyRqAlert(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlert}
          </Alert>
        </Collapse>
        <br />
        <form key={1} onSubmit={handleSubmit(onSubmit)}>
          <input hidden readOnly {...register("userId")} />

          <Grid className={classes.root} container>
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                required
                id="username"
                data-test="username"
                name="username"
                label={t("translation:Username")}
                autoComplete="off"
                variant="outlined"
                {...register("username")}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                required
                data-test="name"
                id="name"
                name="name"
                label={t("translation:Name")}
                variant="outlined"
                {...register("name")}
              />
            </Grid>
            {user.physician_license_no && (
              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  disabled
                  className={classes.userFieldColor}
                  data-test="physician_license_no"
                  name="physician_license_no"
                  label={t("translation:Physician License Number")}
                  variant="outlined"
                  {...register("physician_license_no")}
                />
              </Grid>
            )}
            <Grid item xs={6} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                data-test="identification_number"
                name="identification_number"
                label={t("translation:Identification Number")}
                autoComplete="off"
                variant="outlined"
                {...register("identification_number")}
              />
            </Grid>

            <Grid item xs={6} className={classes.userInfoField}>
              {common.genderList && (
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      id="gender_type"
                      {...field}
                      options={common.genderList.gender}
                      isOptionEqualToValue={(option, value) => {
                        if (option.id === value) return option;
                      }}
                      getOptionLabel={(option) => getGenderOpObj(option).name}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          className={classes.userFieldColor}
                          {...params}
                          label={t("translation:Gender")}
                          variant="outlined"
                        />
                      )}
                      onChange={(_, data) =>
                        field.onChange(data ? data.id : null)
                      }
                    />
                  )}
                  name="gender_type"
                  control={control}
                  //defaultValue={user.gender_type}
                />
              )}
            </Grid>
            <Grid item xs={6} className={classes.userInfoField}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  render={({ field }) => (
                    <DatePicker
                      value={field.value}
                      className={classes.userFieldColor}
                      inputVariant="outlined"
                      label={t("translation:Date of Birth")}
                      autoOk={true}
                      slotProps={{
                        actionBar: {
                          actions: ["clear"],
                        },
                      }}
                      disableFuture
                      format="DD MMM YYYY"
                      onChange={(e) => {
                        if (dayjs(e, true).isValid() === true) {
                          handleDateOfBirth(e);
                        }
                        if (e === null) {
                          setValue("age", ""); // set age through reat hook form because it need to initial.
                          setValue("date_of_birth", null);
                        }
                      }}
                    />
                  )}
                  name="date_of_birth"
                  control={control}
                  //defaultValue={user.date_of_birth}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                variant="outlined"
                name="age"
                label={t("translation:Age")}
                autoComplete="off"
                {...register("age")}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${classes.userInfoField} ${classes.centerBox}`}>
              <FormControl variant="outlined">
                <TextField
                  className={classes.userFieldColor}
                  name="email"
                  data-test="email"
                  label={t("translation:Email Address")}
                  variant="outlined"
                  {...register("email", {
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: t("translation:Email is not valid"),
                    },
                  })}
                />
                {errors.email && (
                  <p className={classes.p}>{errors.email.message}</p>
                )}
              </FormControl>
              {user.email &&
                user.status_type !==
                  pageConfigure.USER_PROFILE_STATUS_VERIFIED && (
                  <Link className={classes.boldMaroon075}>
                    <Button
                      className={classes.boldMaroon075}
                      onClick={handleSubmit(handleVerifyEmail)}>
                      {t("translation:VERIFY")}
                    </Button>
                  </Link>
                )}
            </Grid>

            <Grid item xs={6} className={classes.userInfoField}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    style={{ width: "100%" }}
                    name="postal_code"
                    open={open}
                    onClose={() => {
                      setOpen(false);
                    }}
                    isOptionEqualToValue={() => {
                      return true;
                    }}
                    inputValue={selectedPostalCode ? selectedPostalCode : ""}
                    getOptionLabel={(option) =>
                      option.ADDRESS ? option.ADDRESS : ""
                    }
                    onChange={(e, option) => handleAddressChange(option)}
                    disableClearable
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        name="postal_code"
                        autoComplete="off"
                        className={classes.userFieldColor}
                        onInput={allowOnlyNumericsOrDigits}
                        {...params}
                        onChange={handlePostalCodeChange}
                        onBlur={handleSelectedPostalCode}
                        label={t("translation:Postal Code")}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
                name="postal_code"
                control={control}
              />
            </Grid>

            <Grid item xs={6} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                name="address_details"
                autoComplete="off"
                placeholder={t("translation:Floor/ Unit Number")}
                variant="outlined"
                label={t("translation:Address Details")}
                {...register("address_details")}
              />
            </Grid>
            <Controller
              name="blk_no"
              render={({ field }) => {
                if (field.value) {
                  return (
                    <Grid item xs={12} className={classes.userInfoField}>
                      <TextField
                        {...field}
                        className={classes.userFieldColor}
                        InputProps={{
                          readOnly: true,
                          className: "Mui-disabled",
                        }}
                        classes={{ root: "Mui-disabled" }}
                        name="blk_no"
                        autoComplete="off"
                        label={t("translation:Block No")}
                        variant="outlined"
                        {...register("blk_no")}
                        InputLabelProps={{
                          shrink: field.value ? true : false,
                        }}
                      />
                    </Grid>
                  );
                }
              }}
              control={control}
            />

            <Controller
              name="road_name"
              render={({ field }) => {
                if (field.value) {
                  return (
                    <Grid item xs={12} className={classes.userInfoField}>
                      <TextField
                        className={classes.userFieldColor}
                        InputProps={{
                          readOnly: true,
                          className: "Mui-disabled",
                        }}
                        classes={{ root: "Mui-disabled" }}
                        name="road_name"
                        autoComplete="off"
                        label={t("translation:Road Name")}
                        variant="outlined"
                        {...register("road_name")}
                        InputLabelProps={{
                          shrink: field.value ? true : false,
                        }}
                      />
                    </Grid>
                  );
                }
              }}
              control={control}
            />

            <Controller
              name="building"
              render={({ field }) => {
                if (field.value) {
                  return (
                    <Grid item xs={12} className={classes.userInfoField}>
                      <TextField
                        className={classes.userFieldColor}
                        InputProps={{
                          readOnly: true,
                          className: "Mui-disabled",
                        }}
                        classes={{ root: "Mui-disabled" }}
                        name="building"
                        autoComplete="off"
                        label={t("translation:Building")}
                        variant="outlined"
                        {...register("building")}
                        InputLabelProps={{
                          shrink: field.value ? true : false,
                        }}
                      />
                    </Grid>
                  );
                }
              }}
              control={control}
            />

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                InputProps={{
                  readOnly: addressDisabled,
                  className: addressDisabled ? "Mui-disabled" : undefined,
                }}
                classes={addressDisabled ? { root: "Mui-disabled" } : {}}
                multiline
                minRows={3}
                name="address"
                autoComplete="off"
                label={t("translation:Address")}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("address")}
              />
            </Grid>

            <Grid item xs={4} className={classes.userInfoField}>
              {common.maritalList && (
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      id="marital_type"
                      {...field}
                      options={common.maritalList.marital}
                      isOptionEqualToValue={(option, value) => {
                        if (option.id === value) return option;
                      }}
                      getOptionLabel={(option) => getMaritalOpObj(option).name}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          className={classes.userFieldColor}
                          {...params}
                          label={t("translation:Marital Status")}
                          variant="outlined"
                        />
                      )}
                      onChange={(_, data) =>
                        field.onChange(data ? data.id : null)
                      }
                    />
                  )}
                  name="marital_type"
                  control={control}
                  defaultValue={user.marital_type}
                />
              )}
            </Grid>

            <Grid item xs={4} className={classes.userInfoField}>
              <TextField
                required
                className={classes.userFieldColor}
                data-test="contact_number"
                name="contact_number"
                label={t("translation:Contact Number")}
                variant="outlined"
                {...register("contact_number")}
              />
            </Grid>

            <Grid item xs={4} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                data-test="occupation"
                name="occupation"
                label={t("translation:Occupation")}
                variant="outlined"
                {...register("occupation")}
              />
            </Grid>

            <Grid item xs={4} className={classes.userInfoField}>
              {common.nationalityList && (
                <Autocomplete
                  id="nationality"
                  name="nationality"
                  options={common.nationalityList.nationalities.sort((a, b) =>
                    a.desc.localeCompare(b.desc)
                  )}
                  getOptionLabel={(option) => option.desc}
                  style={{ width: "100%" }}
                  defaultValue={user.nationality}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, option) =>
                    setValue("nationality", option == null ? {} : option)
                  }
                  renderInput={(params) => (
                    <TextField
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Nationality")}
                      variant="outlined"
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={4} className={classes.userInfoField}>
              {common.raceList && (
                <Autocomplete
                  name="race"
                  id="race"
                  options={common.raceList.races}
                  getOptionLabel={(option) => option.desc}
                  style={{ width: "100%" }}
                  defaultValue={user.race}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, option) =>
                    setValue("race", option == null ? {} : option)
                  }
                  renderInput={(params) => (
                    <TextField
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Race")}
                      variant="outlined"
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={4} className={classes.userInfoField}>
              {common.languageList && (
                <Autocomplete
                  name="language"
                  id="language"
                  options={common.languageList.languages}
                  getOptionLabel={(option) => option.desc}
                  style={{ width: "100%" }}
                  defaultValue={user.language}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, option) =>
                    setValue("language", option == null ? {} : option)
                  }
                  renderInput={(params) => (
                    <TextField
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Language")}
                      variant="outlined"
                    />
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                name="nok_contact_name"
                label={t("translation:Next of Kin")}
                variant="outlined"
                margin="normal"
                fullWidth
                {...register("nok_contact_name")}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                name="nok_contact_number"
                label={t("translation:Next of Kin's Contact Number")}
                variant="outlined"
                margin="normal"
                fullWidth
                {...register("nok_contact_number")}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                multiline
                minRows={3}
                data-test="allergy"
                name="allergy"
                label={t("translation:Allergy")}
                variant="outlined"
                {...register("allergy")}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                disabled={true}
                multiline
                minRows={3}
                data-test="medical_history"
                name="medical_history"
                label={t("translation:Medical History")}
                variant="outlined"
                {...register("medical_history")}
              />
            </Grid>
            {watch().is_mfa_enabled && (
              <Grid item xs={12} className={classes.userInfoField}>
                {common.getMfaType && (
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        id="mfa_type"
                        {...field}
                        options={common.getMfaType.mfa_type}
                        isOptionEqualToValue={(option, value) => {
                          if (option.id === value) return option;
                        }}
                        getOptionLabel={(option) =>
                          getMfaTypelOpObj(option).name
                        }
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            className={classes.userFieldColor}
                            {...params}
                            label={t("translation:MFA Type")}
                            variant="outlined"
                          />
                        )}
                        onChange={(_, data) =>
                          field.onChange(data ? data.id : null)
                        }
                      />
                    )}
                    name="mfa_type"
                    control={control}
                    defaultValue={user.mfa_type}
                  />
                )}
              </Grid>
            )}
            <>
              <RequiredNote />
              <PageOnlySubmitAction
                button1Name="submitButton"
                button1label={t("translation:SUBMIT")}
              />
            </>
          </Grid>
        </form>
      </Panel>

      <Panel heading={t("translation:Change Password")}>
        <Collapse in={successPasswordChangeAlert}>
          <Alert
            data-test="alertChangePassword"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessPasswordChangeAlert(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Password has been updated")}
          </Alert>
        </Collapse>

        <Collapse in={errorPasswordChangeAlert}>
          <Alert
            data-test="alertChangePassword"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorPasswordChangeAlert(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlert}
          </Alert>
        </Collapse>
        <br />
        <form key={3} onSubmit={handleSubmit3(onSubmitPassword)}>
          <Grid className={classes.root} container>
            <Grid item xs={6} className={classes.userInfoField}>
              <FormControl
                variant="outlined"
                margin="normal"
                required
                fullWidth>
                <InputLabel required htmlFor="current_password">
                  {t("translation:Current Password")}
                </InputLabel>
                <OutlinedInput
                  className={classes.userFieldColor}
                  required
                  {...register3("current_password")}
                  autoComplete="off"
                  label="Current Password"
                  name="current_password"
                  type={values.showCurrentPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large">
                        {values.showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <div className={classes.warningColor}>
                  {errors3.current_password && (
                    <p>{errors3.current_password.message}</p>
                  )}
                </div>
              </FormControl>
            </Grid>

            <Grid item xs={6} className={classes.userInfoField}>
              <FormControl
                variant="outlined"
                margin="normal"
                required
                fullWidth>
                <InputLabel required htmlFor="new_password">
                  {t("translation:New Password")}
                </InputLabel>
                <OutlinedInput
                  className={classes.userFieldColor}
                  required
                  {...register3("new_password")}
                  autoComplete="off"
                  label="New Password"
                  name="new_password"
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large">
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <div className={classes.warningColor}>
                  {errors3.new_password && (
                    <p>{errors3.new_password.message}</p>
                  )}
                </div>
              </FormControl>
            </Grid>

            <>
              <RequiredNote />
              <PageOnlySubmitAction
                button1Name="submitButton"
                button1label={t("translation:SUBMIT")}
              />
            </>
          </Grid>
        </form>
      </Panel>
    </div>
  );
};
