//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
//actions
import { putEditMedicineType } from "../../../../actions/medicineTypeActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import StyledCheckbox from "../../../../components/StyledCheckbox";
/* <EditMedicineTypePage
roleList={roledata.allRoleList.roles}
userRoleList={userRoledata.userRole.user_roles}
user={userRoledata.userRole.user}
setEditMedicineTypeView={setEditMedicineTypeView}
/> */

export const EditMedicineTypePage = ({
  medicineType,
  setEditMedicineTypeView,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  const [msgAlarm, setMsgAlarm] = useState("");
  const medicineTypeData = useSelector((state) => state.medicineType);
  const common = useSelector((state) => state.common);
  const prevSubmitStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };
  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    delete medicineType.organisation;
    const submitJson = {
      ...data,
      max_grams_per_pack: data.max_grams_per_pack ? data.max_grams_per_pack : 0,
    };
    dispatch(putEditMedicineType.pending({ ...medicineType, ...submitJson }));
  };

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      id: medicineType?.id,
      name: medicineType?.name,
      desc: medicineType?.desc,
      status_type: medicineType?.status_type,
      is_for_brewing: medicineType?.is_for_brewing,
      max_grams_per_pack: medicineType?.max_grams_per_pack
        ? medicineType?.max_grams_per_pack
        : 0,
    },
  });
  useEffect(() => {
    //set ref when first render
    prevSubmitStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      medicineTypeData.isLoadingEditMedicineType !== prevSubmitStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitStatus.current = medicineTypeData.isLoadingEditMedicineType;

      if (
        medicineTypeData.putEditMedicineTypeIsSuccess === true &&
        medicineTypeData.isLoadingEditMedicineType === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...medicineTypeData.putEditMedicineType.medicine_type,
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        medicineTypeData.putEditMedicineTypeIsSuccess === false &&
        medicineTypeData.isLoadingEditMedicineType === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(medicineTypeData.error.EditMedicineTypeError);
        window.scrollTo(0, 0);
      }
    }
  }, [medicineTypeData, reset]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:Medicine Type Details")
          : t("translation:Edit Medicine Type")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Medicine type has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <input hidden readOnly {...register("id")} />

        <Grid className={classes.root} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              required
              name="name"
              label={t("translation:Medicine Type")}
              variant="outlined"
              {...register("name")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              multiline
              minRows={3}
              name="desc"
              label={t("translation:Description")}
              variant="outlined"
              {...register("desc")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              {...register("max_grams_per_pack")}
              cy_data="max_grams_per_pack"
              onFocus={(event) => {
                event.target.select();
              }}
              name="max_grams_per_pack"
              label={t("translation:Max Grams/ Pack")}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      disabled={temViewOnly}
                      checked={field.value}
                      onChange={(e, option) => field.onChange(option)}
                      name="is_for_brewing"
                    />
                  }
                  label={t("translation:Is Brewing")}
                />
              )}
              name="is_for_brewing"
              control={control}
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly}
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>

          <>
            <RequiredNote />

            {temViewOnly ? (
              <PageOnlyBackAction
                setView={() => setEditMedicineTypeView(false)}
              />
            ) : (
              <PageSubmitWBackAction
                setView={() => setEditMedicineTypeView(false)}
              />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
