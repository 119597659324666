import packageVersion from "../../package.json";
import { Typography } from "@mui/material";

export default function CopyRight() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {process.env.REACT_APP_ORGANISATION_DOMAIN} Website{" "}
      {new Date().getFullYear()} {packageVersion.version}
      {"."}
    </Typography>
  );
}
