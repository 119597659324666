import { makeStyles } from "@mui/styles";
const drawerWidth = 190;
export const useStyles = makeStyles((theme) => ({
  item: {
    padding: "0px",
  },
  geryColor: {
    color: "rgba(237, 242, 242)",
  },
  listItemText: {
    fontSize: "0.9em", //Insert your required size

    color: "rgba(237, 242, 242)",
  },

  subListItemText: {
    backgroundColor: "rgba(149, 0, 0,1)",
  },
  btnRoot: {
    justifyContent: "left !important",
  },
  rootSideBar: {
    justifyContent: "right",
  },
  subMenu: {
    paddingLeft: "15px !important",
  },
  root: {
    display: "flex",

    justifyContent: "left",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(249, 249, 249)",
    color: "rgba(149, 0, 0,1)",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,

    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    backgroundColor: "rgba(149, 0, 0,1)",

    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "rgba(149, 0, 0,1)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  userName: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
    zIndex: "-1",
  },
  userIcon: {
    position: "absolute",
    right: "5px",
  },
  helpIcon: {
    position: "absolute",
    right: "50px",
  },
}));
