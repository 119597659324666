import { Button, Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./globalStyles";

export default function PageSubmitWBackAction({ setView, disabled = false }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12} className={classes.relativePositionWrapper}>
      <Grid item xs={12} className={classes.rightAbsolutePosition}>
        <Link className={classes.boldMaroon075}>
          <Button
            disabled={disabled}
            className={classes.boldMaroon075}
            name="submitButton"
            type="submit">
            {t("translation:SUBMIT")}
          </Button>
        </Link>

        <Link className={classes.boldMaroon075}>
          <Button
            disabled={disabled}
            className={classes.boldMaroon075}
            name="backButton"
            onClick={() => setView(false)}>
            {t("translation:BACK")}
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
