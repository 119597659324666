import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import StyledCheckbox from "../../../../../components/StyledCheckbox";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../components/globalStyles";

const EditMdIssued = ({
  visitNotesDetails = [],
  setVisitNotesDetails = () => null,
  viewOnly = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedVisitNote, setSelectedVisitNote] = useState([]);
  const handleOnChangeKeyIn = (event, value) => {
    setSelectedVisitNote((p) => ({
      ...p,
      [event.target.name]: event.target.value ? event.target.value : null,
    }));
  };
  const handleOnBlur = (event, value) => {
    setVisitNotesDetails((p) => ({
      ...p,
      [event.target.name]: event.target.value ? event.target.value : null,
    }));
  };

  //useEffects
  useEffect(() => {
    setSelectedVisitNote(visitNotesDetails);
  }, [visitNotesDetails, setSelectedVisitNote]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.userInfoField}>
        <FormControlLabel
          control={
            <StyledCheckbox
              disabled={viewOnly}
              checked={
                selectedVisitNote.is_medical_diagnosis_issued ? true : false
              }
              onChange={(e) => {
                setVisitNotesDetails((p) => ({
                  ...p,
                  is_medical_diagnosis_issued: e.target.checked,
                  medical_diagnosis_reason: null,
                }));
              }}
              name="is_medical_diagnosis_issued"
            />
          }
          label={t("translation:Medical Diagnosis")}
        />
      </Grid>

      {selectedVisitNote.is_medical_diagnosis_issued === true && (
        <>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={viewOnly}
              className={classes.userFieldColor}
              inputProps={{
                maxLength: 256,
              }}
              value={
                selectedVisitNote.medical_diagnosis_reason
                  ? selectedVisitNote.medical_diagnosis_reason
                  : ""
              }
              onChange={(e) => {
                handleOnChangeKeyIn(e);
              }}
              onBlur={(e) => {
                handleOnBlur(e);
              }}
              multiline
              rows={3}
              name="medical_diagnosis_reason"
              label={
                t("translation:Medical Disgnosis Reasons") +
                ` ${
                  visitNotesDetails.medical_diagnosis_reason
                    ? visitNotesDetails.medical_diagnosis_reason.length
                    : 0
                }/256`
              }
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default EditMdIssued;
