import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getPrescriptionMsgList,
  getPrescriptionMsgById,
  postCreatePrescriptionMsg,
  putUpdatePrescriptionMsg,
} from "../actions/prescriptionMsgActions";
import * as types from "../_constants/prescriptionMsgConstants";
import * as notification from "../_constants/notificationConstants";
import prescriptionMsgApis from "../_apis/prescriptionMsgApis";
export default function* prescriptionMsgSagas() {
  yield all([
    takeLatest(
      types.GET_PRESCRIPTIONMSGLIST_ASYNC.PENDING,
      getPrescriptionMsgListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_PRESCRIPTIONMSGBYID_ASYNC.PENDING,
      getPrescriptionMsgByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATEPRESCRIPTIONMSG_ASYNC.PENDING,
      postCreatePrescriptionMsgCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEPRESCRIPTIONMSG_ASYNC.PENDING,
      putUpdatePrescriptionMsgCall
    ),
  ]);
}

function* getPrescriptionMsgListCall(searchModel) {
  try {
    const data = yield call(
      prescriptionMsgApis.getPrescriptionMsgListApi,
      searchModel.data
    );
    yield put(getPrescriptionMsgList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPrescriptionMsgList.error(error.response?.data?.message));
    else yield put(getPrescriptionMsgList.error(notification.CONST_NOINTERNET));
  }
}

function* getPrescriptionMsgByIdCall(searchModel) {
  try {
    const data = yield call(
      prescriptionMsgApis.getPrescriptionMsgByIdApi,
      searchModel.data
    );
    yield put(getPrescriptionMsgById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPrescriptionMsgById.error(error.response?.data?.message));
    else yield put(getPrescriptionMsgById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreatePrescriptionMsgCall(searchModel) {
  try {
    const data = yield call(
      prescriptionMsgApis.postCreatePrescriptionMsgApi,
      searchModel.data
    );
    yield put(postCreatePrescriptionMsg.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreatePrescriptionMsg.error(error.response?.data?.message));
    else
      yield put(postCreatePrescriptionMsg.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdatePrescriptionMsgCall(searchModel) {
  try {
    const data = yield call(
      prescriptionMsgApis.putUpdatePrescriptionMsgApi,
      searchModel.data
    );
    yield put(putUpdatePrescriptionMsg.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdatePrescriptionMsg.error(error.response?.data?.message));
    else
      yield put(putUpdatePrescriptionMsg.error(notification.CONST_NOINTERNET));
  }
}
