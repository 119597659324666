import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const visitOrderApis = {
  getVisitDispensaryBranchesApi,
  getVisitMedicineTypesApi,
  postVisitBranchMedicinesApi,
  postVisitFormulasAllianceApi,
  postVisitMedicineFormulasAllianceApi,
  getVisitCurrentOrganisationApi,
  postVisitSaveStoreOrderApi,
  postVisitSubmitStoreOrderApi,
  getVisitStoreOrderListApi,
  getVisitStoreOrderSummaryListApi,
  getPatientVisitStoreOrderListApi,
  putVisitStoreEditModeApi,
  getVisitMedicineTypesAllianceApi,
  getVisitDispensaryBranchesAllianceApi,
  getVisitAcupointsApi,
  getVisitTreatmentTypesApi,
  postVisitTreatmentsApi,
  postVisitEmptyVisitOrderApi,
  getVisitPaymentTermsApi,
  getVisitPaymentMethodsApi,
  getVisitPhysiciansApi,
  postVisitPhysiciansApi,
  getVisitUserListApi,
  postVisitCreateUserApi,
  putVisitUpdateUserApi,
  getVisitUserAdvancedSearchApi,
  getVisitIsUserCurrentOrganisationApi,
  putVisitUpdateUserOrganisationApi,
  putVisitSetArrivalOnApi,
  putVisitSetConsultOnApi,
  putVisitSetDepartureOnApi,
  putVisitSetQueueNumberApi,
  putVisitUpdateVisitNotesApi,
  putVisitPrintDateApi,
  putUpdateUserMedicalHistoryApi,
  getVisitPrescriptionMsgListApi,
  getVisitMedicineInfoApi,
  getVisitAcupointInfoApi,
  getVisitTreatmentInfoApi,
  getVisitStoreOrderByIdApi,
  getVisitStoreOrderByIdPatientHistoryApi,
  postVisitChangeBranchApi,
  putUpdateVisitAcupointsApi,
  getVisitPostalCodeSurchargeListApi,
  getVisitAvailableProceedOnApi,
  getVisitVerifyAddressApi,
};

async function getVisitDispensaryBranchesApi() {
  return await axios
    .get(apiConstants.apiGetVisitDispensaryBranches, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitMedicineTypesApi() {
  return await axios
    .get(apiConstants.apiGetVisitMedicineTypes, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitDispensaryBranchesAllianceApi() {
  return await axios
    .get(apiConstants.apiGetVisitDispensaryBranchesAlliance, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitMedicineTypesAllianceApi(searchModel) {
  const url = apiConstants.apiGetVisitMedicineTypesAlliance + searchModel;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVisitFormulasAllianceApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostVisitFormulasAlliance,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVisitMedicineFormulasAllianceApi(searchModel) {
  const url =
    apiConstants.apiPostVisitMedicineFormulasAlliance + searchModel.id;
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVisitBranchMedicinesApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostVisitBranchMedicines,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitCurrentOrganisationApi() {
  return await axios
    .get(apiConstants.apiGetVisitCurrentOrganisation, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVisitSaveStoreOrderApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostVisitSaveStoreOrder,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVisitSubmitStoreOrderApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostVisitSubmitStoreOrder,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitStoreOrderListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetVisitStoreOrderList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitStoreOrderSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetVisitStoreOrderSummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitStoreOrderByIdApi(searchModel) {
  const url = apiConstants.apiGetVisitStoreOrderById + searchModel;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitStoreOrderByIdPatientHistoryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetSearchPatientVisitStoreOrder,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getPatientVisitStoreOrderListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetPatientVisitStoreOrderList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putVisitStoreEditModeApi(id) {
  const url = apiConstants.apiPutVisitStoreEditMode + id;
  return await axios
    .put(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitAcupointsApi() {
  return await axios
    .get(apiConstants.apiGetVisitAcupoints, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitTreatmentTypesApi() {
  return await axios
    .get(apiConstants.apiGetVisitTreatmentTypes, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVisitTreatmentsApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiPostVisitTreatments, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitPaymentMethodsApi() {
  return await axios
    .get(apiConstants.apiGetVisitPaymentMethods, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitPaymentTermsApi() {
  return await axios
    .get(apiConstants.apiGetVisitPaymentTerms, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVisitEmptyVisitOrderApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostVisitEmptyVisitOrder,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitPhysiciansApi() {
  return await axios
    .get(apiConstants.apiGetVisitPhysicians, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVisitPhysiciansApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostVisitPhysicians, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitUserListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetVisitUserList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitUserAdvancedSearchApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetVisitUserAdvancedSearch,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVisitCreateUserApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostVisitCreateUser, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putVisitUpdateUserApi(searchModel) {
  const url = apiConstants.apiPutVisitUpdateUser + searchModel.user.userId;
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitIsUserCurrentOrganisationApi(searchModel) {
  const url =
    apiConstants.apiGetVisitIsUserCurrentOrganisation + searchModel.userId;

  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putVisitUpdateUserOrganisationApi(searchModel) {
  const url =
    apiConstants.apiPutVisitUpdateUserOrganisation + searchModel.userId;
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putVisitSetArrivalOnApi(searchModel) {
  const url = apiConstants.apiPutVisitSetArrivalOn;
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putVisitSetDepartureOnApi(searchModel) {
  const url = apiConstants.apiPutVisitSetDepartureOn;
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putVisitSetConsultOnApi(searchModel) {
  const url = apiConstants.apiPutVisitSetConsultOn;
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putVisitSetQueueNumberApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(apiConstants.apiPutVisitSetQueueNumber, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putVisitUpdateVisitNotesApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutVisitUpdateVisitNotes,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putVisitPrintDateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(apiConstants.apiPutVisitPrintDate, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateUserMedicalHistoryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutUpdateUserMedicalHistory,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitPrescriptionMsgListApi() {
  return await axios
    .get(apiConstants.apiGetVisitPrescriptionMsgList, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitMedicineInfoApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetVisitMedicineInfo, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitAcupointInfoApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetVisitAcupointInfo, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitTreatmentInfoApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetVisitTreatmentInfo, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVisitChangeBranchApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostVisitChangeBranch, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateVisitAcupointsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutUpdateVisitAcupoints,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitPostalCodeSurchargeListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetVisitPostalCodeSurchargeList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitAvailableProceedOnApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetVisitAvailableProceedOn,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getVisitVerifyAddressApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetVisitVerifyAddress, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default visitOrderApis;
