import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MedicineTypeSummaryPage from "./MedicineTypeSummary/MedicineTypeSummaryPage";
import MedicineSummaryPage from "./MedicineSummary/MedicineSummaryPage";
export default function MedicinePage() {
  return (
    <Switch>
      <Route
        path="/medicine/MedicineTypeSummary"
        component={MedicineTypeSummaryPage}
      />
      <Route path="/medicine/MedicineSummary" component={MedicineSummaryPage} />

      <Redirect to="/error" />
    </Switch>
  );
}
