import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import WikiDataSummaryPage from "./WikiDataSummary/WikiDataSummaryPage";
export default function WikiDataPage() {
  return (
    <Switch>
      <Route path="/wikiData/wikiDataSummary" component={WikiDataSummaryPage} />

      <Redirect to="/error" />
    </Switch>
  );
}
