import { createAsyncTypes } from "../_helper/Utilities";

export const GET_PURCHASEORDERLIST_ASYNC = createAsyncTypes(
  "GET_PURCHASEORDERLIST"
);

export const GET_PURCHASEORDERBYID_ASYNC = createAsyncTypes(
  "GET_PURCHASEORDERBYID"
);

export const GET_PURCHASEORDERMEDICINETYPES_ASYNC = createAsyncTypes(
  "GET_PURCHASEORDERMEDICINETYPES"
);

export const POST_PURCHASEORDERMEDICINES_ASYNC = createAsyncTypes(
  "POST_PURCHASEORDERMEDICINES"
);

export const POST_SUBMITPURCHASEORDER_ASYNC = createAsyncTypes(
  "POST_SUBMITPURCHASEORDER"
);

export const PUT_PURCHASEORDERWIPMODE_ASYNC = createAsyncTypes(
  "PUT_PURCHASEORDERWIPMODE"
);

export const PUT_PURCHASEORDERCANCELLED_ASYNC = createAsyncTypes(
  "PUT_PURCHASEORDERCANCELLED"
);

export const PUT_PURCHASEORDERPRINTEDDATE_ASYNC = createAsyncTypes(
  "PUT_PURCHASEORDERPRINTEDDATE"
);
