import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getSingaporeHolidayList,
  getSingaporeYearlyHoliday,
} from "../actions/extraApiActions";
import * as types from "../_constants/externalApiConstants";
import * as notification from "../_constants/notificationConstants";
import externetApis from "../_apis/externetApis";
export default function* externalApiSagas() {
  yield all([
    takeLatest(
      types.GET_SINGAPOREHOLIDAYLIST_ASYNC.PENDING,
      getSingaporeHolidayListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_SINGAPOREYEARLYHOLIDAY_ASYNC.PENDING,
      getSingaporeYearlyHolidayCall
    ),
  ]);
}

function* getSingaporeHolidayListCall(searchModel) {
  try {
    const data = yield call(
      externetApis.getSingaporeHolidayListApi,
      searchModel.data
    );
    yield put(getSingaporeHolidayList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getSingaporeHolidayList.error(error.response?.data?.message));
    else
      yield put(getSingaporeHolidayList.error(notification.CONST_NOINTERNET));
  }
}

function* getSingaporeYearlyHolidayCall(model) {
  try {
    const data = yield call(
      externetApis.getSingaporeYearlyHolidayApi,
      model.data
    );
    yield put(getSingaporeYearlyHoliday.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getSingaporeYearlyHoliday.error(error.response?.data?.message));
    else
      yield put(getSingaporeYearlyHoliday.error(notification.CONST_NOINTERNET));
  }
}
