import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/userCreditConstants";

export const getUserCreditList = {
  pending: (data) =>
    createAction(types.GET_USERCREDITLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERCREDITLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_USERCREDITLIST_ASYNC.ERROR, { error }),
};

export const postCreateUserCredit = {
  pending: (data) =>
    createAction(types.POST_CREATEUSERCREDIT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEUSERCREDIT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEUSERCREDIT_ASYNC.ERROR, { error }),
};

export const getUserCreditUserList = {
  pending: (data) =>
    createAction(types.GET_USERCREDITUSERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERCREDITUSERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_USERCREDITUSERLIST_ASYNC.ERROR, { error }),
};

export const getUserCreditById = {
  pending: (data) =>
    createAction(types.GET_USERCREDITBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERCREDITBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_USERCREDITBYID_ASYNC.ERROR, { error }),
};

export const getUserCreditBalanceById = {
  pending: (data) =>
    createAction(types.GET_USERCREDITBALANCEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERCREDITBALANCEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_USERCREDITBALANCEBYID_ASYNC.ERROR, { error }),
};
