//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { IconButton, Grid, Collapse } from "@mui/material";

import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
//actions
import { postBranchScheduleByBranchId } from "../../../../actions/branchScheduleActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import OpeningHourPanel from "../components/OpeningHourPanel";
import OpeningHourModal from "../components/OpeningHourModal";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import { useStyles } from "../../../../components/globalStyles";

export const EditBranchSchedulePage = ({ branchId, editData, setEditView }) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const branchSchedule = useSelector((state) => state.branchSchedule);
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [dayweek, setDayweek] = useState(null);
  const [msgAlarm, setMsgAlarm] = useState("");
  const [scheduleHour, setScheduleHour] = useState(editData);
  const prevBranchScheduleStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [endTimeError, setEndTimeError] = useState([]);

  //functions

  const onSubmit = (data) => {
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    if (scheduleHour.length > 0) {
      const jsonText = { branch_schedules: scheduleHour, id: branchId };
      dispatch(postBranchScheduleByBranchId.pending(jsonText));
    } else {
      setErrorAlarm(true);
      setMsgAlarm(t("translation:Missing Schedule"));
    }
  };

  const { handleSubmit } = useForm();

  useEffect(() => {
    //set ref when first render
    prevBranchScheduleStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      branchSchedule.isLoadingPostBranchScheduleByBranchId !==
      prevBranchScheduleStatus.current
    ) {
      //check the previous ref with current state
      prevBranchScheduleStatus.current =
        branchSchedule.isLoadingPostBranchScheduleByBranchId;

      if (
        branchSchedule.postBranchScheduleByBranchIdIsSuccess === true &&
        branchSchedule.isLoadingPostBranchScheduleByBranchId === false
      ) {
        setScheduleHour(
          branchSchedule.postBranchScheduleByBranchId.branch_schedules
        );
        setSuccessAlarm(true);
        setErrorAlarm(false);
        window.scrollTo(0, 0);
      }
      if (
        branchSchedule.postBranchScheduleByBranchIdIsSuccess === false &&
        branchSchedule.isLoadingPostBranchScheduleByBranchId === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(branchSchedule.error.postBranchScheduleByBranchIdError);
        window.scrollTo(0, 0);
      }
    }
  }, [branchSchedule]);

  return (
    <Panel heading={t("translation:Edit Branch Schedule")}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Branch Schedule has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <OpeningHourPanel
          scheduleHour={scheduleHour}
          setScheduleHour={setScheduleHour}
          dayweek={dayweek}
          setDayweek={setDayweek}
          setOpenAddModal={setOpenAddModal}
          endTimeError={endTimeError}
          setEndTimeError={setEndTimeError}
        />
        <OpeningHourModal
          openModal={openAddModal}
          setOpenModal={setOpenAddModal}
          dayweek={dayweek}
          branchId={branchId}
          setScheduleHour={setScheduleHour}
        />

        <Grid className={classes.root} container>
          <RequiredNote />
          <PageSubmitWBackAction setView={setEditView} />
        </Grid>
      </form>
    </Panel>
  );
};
