//lib
import React from "react";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
//mport CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as RemoveLogo } from "../../../../assets/coolicons/basic/trash_empty.svg";
import MaximizeIcon from "@mui/icons-material/Maximize";
import moment from "moment";
//components
import * as generalConstants from "../../../../_constants/generalConstants";
import { useStyles } from "../../../../components/globalStyles";

export default function OpeningHourPanel({
  viewMode = false,
  scheduleHour,
  setScheduleHour,
  setDayweek,
  setOpenAddModal,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const deleteOpeningHours = (openingHour, openingHourIndex) => {
    const result = [...scheduleHour];
    if (openingHour.id) {
      result[openingHourIndex].status_type =
        generalConstants.DELETED_STATUS_TYPE;
    } else {
      result.splice(openingHourIndex, 1);
    }

    setScheduleHour(result);
  };

  const handleAddOpeningHoursModal = (day) => {
    setOpenAddModal(true);
    setDayweek(day);
  };

  const handleStartTimeOnchnage = (openingHourIndex, event) => {
    let result = [...scheduleHour];

    result[openingHourIndex].start_time = event.target.value
      ? moment(event.target.value, "HH:mm").format("HH:mm:ss")
      : null;

    setScheduleHour(result);
  };

  const handleEndTimeOnchnage = (openingHourIndex, event) => {
    let result = [...scheduleHour];

    result[openingHourIndex].end_time = event.target.value
      ? moment(event.target.value, "HH:mm").format("HH:mm:ss")
      : null;

    setScheduleHour(result);
  };

  const handleEndTimeOnBlur = (openingHourIndex, event) => {
    let result = [...scheduleHour];

    if (
      result[openingHourIndex].end_time &&
      result[openingHourIndex].start_time
    ) {
      if (
        result[openingHourIndex].end_time > result[openingHourIndex].start_time
      ) {
        result[openingHourIndex].end_time_error = false;
        setScheduleHour(result);
      } else {
        result[openingHourIndex].end_time_error = true;
        setScheduleHour(result);
      }
    }
  };

  return (
    <Grid container spacing={3} direction="row" alignItems="center">
      {generalConstants.weekdays.map((dayweek, dayweekIndex) => {
        //have atleast one approved
        const openingTimess = scheduleHour.some(
          (dayTimes) =>
            dayTimes.day_of_the_week === dayweek.day &&
            dayTimes.status_type === generalConstants.APPROVED_STATUS_TYPE
        );

        return (
          <Grid key={dayweekIndex} container className={classes.gridPadding}>
            <Grid item xs={1} className={classes.gridPadding}>
              {t(`translation:${dayweek.name}`)}
            </Grid>
            {!viewMode && (
              <Grid
                item
                xs={1}
                container
                direction="column"
                alignItems="center">
                <Button
                  disabled={viewMode}
                  onClick={() => handleAddOpeningHoursModal(dayweek.day)}
                  variant="contained"
                  color="secondary"
                  className={classes.buttonColor}>
                  {t("translation:Add Hours")}
                </Button>
              </Grid>
            )}

            <Grid
              container
              alignItems="center"
              className={classes.gridPadding}
              item
              xs={10}>
              {openingTimess &&
                scheduleHour.map((openingHour, openingHourIndex) =>
                  openingHour.status_type ===
                    generalConstants.APPROVED_STATUS_TYPE &&
                  openingHour.day_of_the_week === dayweek.day ? (
                    <React.Fragment key={openingHourIndex}>
                      <Grid item xs={12} container>
                        {!viewMode && (
                          <Grid
                            item
                            xs={1}
                            container
                            justifyContent="center"
                            direction="column"
                            alignItems="center">
                            <Button
                              disabled={viewMode}
                              onClick={() =>
                                deleteOpeningHours(
                                  openingHour,
                                  openingHourIndex
                                )
                              }>
                              <RemoveLogo />
                            </Button>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={3}
                          className={classes.userInfoFieldWidth70}
                          container
                          justifyContent="center"
                          direction="column"
                          alignItems="center">
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={viewMode}
                            required
                            value={
                              openingHour.start_time
                                ? openingHour.start_time
                                : ""
                            }
                            className={classes.userFieldColor}
                            type="time"
                            variant="outlined"
                            label={t("translation:Start Time")}
                            fullWidth
                            onChange={(e) =>
                              handleStartTimeOnchnage(openingHourIndex, e)
                            }
                            onBlur={(e) =>
                              handleEndTimeOnBlur(openingHourIndex, e)
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          container
                          justifyContent="center"
                          direction="column"
                          alignItems="center">
                          <MaximizeIcon />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={classes.userInfoFieldWidth70}
                          container
                          justifyContent="center"
                          direction="column"
                          alignItems="center">
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={viewMode}
                            required
                            value={
                              openingHour.end_time ? openingHour.end_time : ""
                            }
                            className={classes.userFieldColor}
                            type="time"
                            variant="outlined"
                            label={t("translation:End Time")}
                            fullWidth
                            onChange={(e) =>
                              handleEndTimeOnchnage(openingHourIndex, e)
                            }
                            onBlur={(e) =>
                              handleEndTimeOnBlur(openingHourIndex, e)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        container
                        justifyContent="center"
                        direction="column"
                        alignItems="center">
                        {openingHour.end_time_error && (
                          <div className={classes.warningColor}>
                            <p>
                              {t(
                                "translation:End Time must be greater than Start Time."
                              )}
                            </p>
                          </div>
                        )}
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={openingHourIndex}></React.Fragment>
                  )
                )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
