import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
//actions
import {
  postReportingVerifyFixedMedicinePriceSync,
  postReportingImportFixedMedicinePriceSync,
} from "../../../../actions/reportingActions";
//style
import { useStyles } from "../../../../components/globalStyles";

//components
import * as generalConstants from "../../../../_constants/generalConstants";
import Panel from "../../../../components/Panel";
import UploadCSVDialog from "../../../../components/UploadCSVDialog";
export default function SyncFixedMedicinePrice({ medicineFixedPriceHeader }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reporting = useSelector((state) => state.reporting);
  const curUser = useSelector((state) => state.curUser);

  const importAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.REPORT_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const [confirmUploadOpen, setConfirmUploadOpen] = useState(false);

  const [msgImportAlarm, setMsgImportAlarm] = React.useState(null);
  const [alertMsgImport, setAlertMsgImport] = React.useState(false);
  const [alertTypeImport, setAlertTypeImport] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);
  const [msgAlarm, setMsgAlarm] = React.useState(false);
  const [errorAlarm, setErrorAlarm] = React.useState(false);
  const prevVerifyStatus = useRef();
  const prevImportStatus = useRef();
  const prevSyncStatus = useRef();

  const [verifyInfo, setVerifyInfo] = useState({
    totalItemCount: 0,
    createItem: [],
    createItemCount: 0,
    updateItem: [],
    updateItemCount: 0,
    errorItem: [],
    errorItemCount: 0,
  });

  const verifyFileOnHandle = () => {
    dispatch(
      postReportingVerifyFixedMedicinePriceSync.pending({
        medicine_fixed_selling_price_header: {
          id: medicineFixedPriceHeader.id,
        },
      })
    );
  };

  const uploadFileOnHandle = () => {
    dispatch(
      postReportingImportFixedMedicinePriceSync.pending({
        medicine_fixed_selling_price_header: {
          id: medicineFixedPriceHeader.id,
        },
      })
    );
  };

  useEffect(() => {
    //set ref when first render
    prevVerifyStatus.current = false;
    prevImportStatus.current = false;
    prevSyncStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      reporting.isLoadingPostReportingVerifyFixedMedicinePriceSync !==
      prevVerifyStatus.current
    ) {
      //check the previous ref with current state
      prevVerifyStatus.current =
        reporting.isLoadingPostReportingVerifyFixedMedicinePriceSync;

      if (
        reporting.postReportingVerifyFixedMedicinePriceSyncIsSuccess === true &&
        reporting.isLoadingPostReportingVerifyFixedMedicinePriceSync === false
      ) {
        setVerifyInfo({
          totalItemCount:
            reporting.postReportingVerifyFixedMedicinePriceSync
              .import_total_count,
          createItem:
            reporting.postReportingVerifyFixedMedicinePriceSync
              .import_creation_details,
          createItemCount:
            reporting.postReportingVerifyFixedMedicinePriceSync
              .import_creation_count,
          updateItem:
            reporting.postReportingVerifyFixedMedicinePriceSync
              .import_update_details,
          updateItemCount:
            reporting.postReportingVerifyFixedMedicinePriceSync
              .import_update_count,
          errorItem:
            reporting.postReportingVerifyFixedMedicinePriceSync
              .import_error_details,
          errorItemCount:
            reporting.postReportingVerifyFixedMedicinePriceSync
              .import_error_count,
        });

        setConfirmUploadOpen(true);
      }
      if (
        reporting.postReportingVerifyFixedMedicinePriceSyncIsSuccess ===
          false &&
        reporting.isLoadingPostReportingVerifyFixedMedicinePriceSync === false
      ) {
        setErrorAlarm(true);
        setMsgAlarm(
          reporting.error.postReportingImportFixedMedicinePriceSyncError
        );
      }
    }
  }, [reporting]);

  useEffect(() => {
    if (
      reporting.isLoadingPostReportingImportFixedMedicinePriceSync !==
      prevSyncStatus.current
    ) {
      //check the previous ref with current state
      prevSyncStatus.current =
        reporting.isLoadingPostReportingImportFixedMedicinePriceSync;
      setImportLoading(
        reporting.isLoadingPostReportingImportFixedMedicinePriceSync
      );
      if (
        reporting.postReportingImportFixedMedicinePriceSyncIsSuccess === true &&
        reporting.isLoadingPostReportingImportFixedMedicinePriceSync === false
      ) {
        setVerifyInfo({
          totalItemCount:
            reporting.postReportingImportFixedMedicinePriceSync
              .import_total_count,
          createItem:
            reporting.postReportingImportFixedMedicinePriceSync
              .import_creation_details,
          createItemCount:
            reporting.postReportingImportFixedMedicinePriceSync
              .import_creation_count,
          updateItem:
            reporting.postReportingImportFixedMedicinePriceSync
              .import_update_details,
          updateItemCount:
            reporting.postReportingImportFixedMedicinePriceSync
              .import_update_count,
          errorItem:
            reporting.postReportingImportFixedMedicinePriceSync
              .import_error_details,
          errorItemCount:
            reporting.postReportingImportFixedMedicinePriceSync
              .import_error_count,
        });
        setAlertTypeImport("success");
        setAlertMsgImport(true);
        setMsgImportAlarm(
          t("translation:Medicine Fixed Selling Price has been updated")
        );
      }
      if (
        reporting.postReportingImportFixedMedicinePriceSyncIsSuccess ===
          false &&
        reporting.isLoadingPostReportingImportFixedMedicinePriceSync === false
      ) {
        setAlertTypeImport("error");
        setAlertMsgImport(true);
        setMsgImportAlarm(
          reporting.error.postReportingImportFixedMedicinePriceSyncError
        );
      }
    }
  }, [reporting, t]);

  return (
    <Panel heading={t("translation:Sync Medicine")}>
      <Grid item xs={12}>
        <Collapse in={errorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        {reporting.isLoadingPostReportingVerifyFixedMedicinePriceSync && (
          <>{t("translation:Loading")}...</>
        )}
        {!reporting.isLoadingPostReportingVerifyFixedMedicinePriceSync &&
          importAccess && (
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                name="uploadButton"
                onClick={() => verifyFileOnHandle()}>
                {t("translation:VERIFY")}
              </Button>
            </Link>
          )}

        {confirmUploadOpen && (
          <UploadCSVDialog
            maxWidth="md"
            open={confirmUploadOpen}
            verifyInfo={verifyInfo}
            msgAlarm={msgImportAlarm}
            alertMsg={alertMsgImport}
            alertType={alertTypeImport}
            onClose={() => {
              setConfirmUploadOpen(false);
              setAlertMsgImport(false);
            }}
            onSave={() => {
              uploadFileOnHandle();
            }}
            cancelButtonText={t("translation:BACK")}
            submitButtonText={t("translation:SYNC")}
            dialogLoading={importLoading}
          />
        )}
      </Grid>
    </Panel>
  );
}
