//lib
import React, { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../../components/globalTableStyles";
import { getGender, getMarital } from "../../../actions/commonActions";
//variables

export const PatientInfoTable = ({
  name,
  date_of_birth = null,
  gender_type = null,
  marital_type = null,
  occupation = null,
  allergy = null,
  medical_history = null,
  t,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  const [genderDisplay, setGenderDisplay] = useState("");
  const [maritalDisplay, setMaritalDisplay] = useState("");
  //useEffects

  useEffect(() => {
    if (common.genderList?.gender && gender_type) {
      const gender = common.genderList.gender.find(
        (item) => item.id === gender_type
      );
      setGenderDisplay(gender);
    }
  }, [common.genderList, gender_type]);

  useEffect(() => {
    if (common.maritalList?.marital && marital_type) {
      const marital = common.maritalList.marital.find(
        (item) => item.id === marital_type
      );
      setMaritalDisplay(marital);
    }
  }, [common.maritalList, marital_type]);

  useEffect(() => {
    if (!common.genderList && !common.isLoadinGenderList) {
      dispatch(getGender.pending());
    }
  }, [common.genderList, common.isLoadinGenderList, dispatch]);

  useEffect(() => {
    if (!common.maritalList && !common.isLoadingMarital) {
      dispatch(getMarital.pending());
    }
  }, [common.maritalList, common.isLoadingMarital, dispatch]);
  // const options = {
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // };

  var birthYear = date_of_birth
    ? moment(date_of_birth, "YYYY-MM-DD").format("YYYY-MM-DD")
    : "";
  var userAge = date_of_birth
    ? Math.floor(moment().diff(birthYear, "years", true))
    : "";

  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable>
          <TableHead className={classes.headStyle}>
            <TableRow>
              <TableCell className={classes.userHeadCellStyle}>
                {t("translation:Name")}
              </TableCell>

              <TableCell className={classes.userHeadCellStyle}>
                {t("translation:Age")}
              </TableCell>

              <TableCell className={classes.userHeadCellStyle}>
                {t("translation:Gender")}
              </TableCell>

              <TableCell className={classes.userHeadCellStyle}>
                {t("translation:Marital")}
              </TableCell>

              <TableCell className={classes.userHeadCellStyle}>
                {t("translation:Occupation")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.userBodyCellStyle}>
                {name}
              </TableCell>
              <TableCell className={classes.userBodyCellStyle}>
                {userAge || userAge === 0 ? userAge : ""}
              </TableCell>
              <TableCell className={classes.userBodyCellStyle}>
                {genderDisplay ? genderDisplay.name : ""}
              </TableCell>

              <TableCell className={classes.userBodyCellStyle}>
                {maritalDisplay ? maritalDisplay.name : ""}
              </TableCell>

              <TableCell className={classes.userBodyCellStyle}>
                {occupation}
              </TableCell>
            </TableRow>
          </TableBody>
        </MaUTable>
      </TableContainer>

      <TableContainer component={Paper}>
        <MaUTable>
          <TableHead className={classes.headStyle}>
            <TableRow>
              <TableCell className={classes.userHeadCellStyle}>
                {t("translation:Allergy")}
              </TableCell>
              <TableCell className={classes.userHeadCellStyle}>
                {t("translation:Medical History")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.userBodyCellStyle}>
                {allergy}
              </TableCell>
              <TableCell className={classes.userBodyCellStyle}>
                {medical_history}
              </TableCell>
            </TableRow>
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
};
