import * as types from "../_constants/invoiceMedicineCostingConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.PUT_INVOICEMEDICINECOSTINGWIPMODE_ASYNC.PENDING:
      return {
        ...state,
        putInvoiceMedicineCostingWIPModeSuccess: false,
        isLoadingInvoiceMedicineCostingWIPMode: true,
        invoiceMedicineCostingWIPMode: null,
      };

    case types.PUT_INVOICEMEDICINECOSTINGWIPMODE_ASYNC.ERROR:
      return {
        ...state,
        putInvoiceMedicineCostingWIPModeSuccess: false,
        isLoadingInvoiceMedicineCostingWIPMode: false,
        invoiceMedicineCostingWIPMode: null,
        error: {
          ...state.error,
          invoiceMedicineCostingWIPModeError: action.error,
        },
      };

    case types.PUT_INVOICEMEDICINECOSTINGWIPMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putInvoiceMedicineCostingWIPModeSuccess: true,
        isLoadingInvoiceMedicineCostingWIPMode: false,
        invoiceMedicineCostingWIPMode: action.data,
      };

    case types.PUT_INVOICEMEDICINECOSTINGPENDINGSTATUS_ASYNC.PENDING:
      return {
        ...state,
        putInvoiceMedicineCostingPendingStatusSuccess: false,
        isLoadingInvoiceMedicineCostingPendingStatus: true,
        invoiceMedicineCostingPendingStatus: null,
      };

    case types.PUT_INVOICEMEDICINECOSTINGPENDINGSTATUS_ASYNC.ERROR:
      return {
        ...state,
        putInvoiceMedicineCostingPendingStatusSuccess: false,
        isLoadingInvoiceMedicineCostingPendingStatus: false,
        invoiceMedicineCostingPendingStatus: null,
        error: {
          ...state.error,
          invoiceMedicineCostingPendingStatusError: action.error,
        },
      };

    case types.PUT_INVOICEMEDICINECOSTINGPENDINGSTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        putInvoiceMedicineCostingPendingStatusSuccess: true,
        isLoadingInvoiceMedicineCostingPendingStatus: false,
        invoiceMedicineCostingPendingStatus: action.data,
      };

    case types.GET_INVOICEMEDICINECOSTINGLIST_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceMedicineCostingListSuccess: false,
        isLoadingInvoiceMedicineCostingList: true,
        invoiceMedicineCostingList: null,
      };

    case types.GET_INVOICEMEDICINECOSTINGLIST_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceMedicineCostingListSuccess: false,
        isLoadingInvoiceMedicineCostingList: false,
        invoiceMedicineCostingList: null,
        error: {
          ...state.error,
          invoiceMedicineCostingListError: action.error,
        },
      };

    case types.GET_INVOICEMEDICINECOSTINGLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceMedicineCostingListSuccess: true,
        isLoadingInvoiceMedicineCostingList: false,
        invoiceMedicineCostingList: action.data,
      };

    case types.GET_INVOICEMEDICINECOSTINGSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceMedicineCostingSummaryListSuccess: false,
        isLoadingInvoiceMedicineCostingSummaryList: true,
        invoiceMedicineCostingSummaryList: null,
      };

    case types.GET_INVOICEMEDICINECOSTINGSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceMedicineCostingSummaryListSuccess: false,
        isLoadingInvoiceMedicineCostingSummaryList: false,
        invoiceMedicineCostingSummaryList: null,
        error: {
          ...state.error,
          invoiceMedicineCostingSummaryListError: action.error,
        },
      };

    case types.GET_INVOICEMEDICINECOSTINGSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceMedicineCostingSummaryListSuccess: true,
        isLoadingInvoiceMedicineCostingSummaryList: false,
        invoiceMedicineCostingSummaryList: action.data,
      };

    case types.GET_INVOICEMEDICINECOSTINGBYID_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceMedicineCostingByIdSuccess: false,
        isLoadingInvoiceMedicineCostingById: true,
        invoiceMedicineCostingById: null,
      };

    case types.GET_INVOICEMEDICINECOSTINGBYID_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceMedicineCostingByIdSuccess: false,
        isLoadingInvoiceMedicineCostingById: false,
        invoiceMedicineCostingById: null,
        error: {
          ...state.error,
          invoiceMedicineCostingByIdError: action.error,
        },
      };

    case types.GET_INVOICEMEDICINECOSTINGBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceMedicineCostingByIdSuccess: true,
        isLoadingInvoiceMedicineCostingById: false,
        invoiceMedicineCostingById: action.data,
      };

    case types.GET_INVOICEMEDICINECOSTINGBYORDERNUM_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceMedicineCostingByOrderNumSuccess: false,
        isLoadingInvoiceMedicineCostingByOrderNum: true,
        invoiceMedicineCostingByOrderNum: null,
      };

    case types.GET_INVOICEMEDICINECOSTINGBYORDERNUM_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceMedicineCostingByOrderNumSuccess: false,
        isLoadingInvoiceMedicineCostingByOrderNum: false,
        invoiceMedicineCostingByOrderNum: null,
        error: {
          ...state.error,
          invoiceMedicineCostingByOrderNumError: action.error,
        },
      };

    case types.GET_INVOICEMEDICINECOSTINGBYORDERNUM_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceMedicineCostingByOrderNumSuccess: true,
        isLoadingInvoiceMedicineCostingByOrderNum: false,
        invoiceMedicineCostingByOrderNum: action.data,
      };

    case types.POST_SUBMITINVOICEMEDICINECOSTING_ASYNC.PENDING:
      return {
        ...state,
        postSubmitInvoiceMedicineCostingSuccess: false,
        isLoadingSubmitInvoiceMedicineCosting: true,
        submitInvoiceMedicineCosting: null,
      };

    case types.POST_SUBMITINVOICEMEDICINECOSTING_ASYNC.ERROR:
      return {
        ...state,
        postSubmitInvoiceMedicineCostingSuccess: false,
        isLoadingSubmitInvoiceMedicineCosting: false,
        submitInvoiceMedicineCosting: null,
        error: {
          ...state.error,
          submitInvoiceMedicineCostingError: action.error,
        },
      };

    case types.POST_SUBMITINVOICEMEDICINECOSTING_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitInvoiceMedicineCostingSuccess: true,
        isLoadingSubmitInvoiceMedicineCosting: false,
        submitInvoiceMedicineCosting: action.data,
      };
    case types.PUT_INVOICEMEDICINECOSTINGPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putIMCPrintDateSuccess: false,
        isLoadingPutIMCPrintDate: true,
        putIMCPrintDate: null,
      };

    case types.PUT_INVOICEMEDICINECOSTINGPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putIMCPrintDateSuccess: false,
        isLoadingPutIMCPrintDate: false,
        putIMCPrintDate: null,
        error: { ...state.error, putIMCPrintDateError: action.error },
      };

    case types.PUT_INVOICEMEDICINECOSTINGPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putIMCPrintDateSuccess: true,
        isLoadingPutIMCPrintDate: false,
        putIMCPrintDate: action.data,
      };

    default:
      return state;
  }
};
