//lib
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

//action

import {
  postBranchCourierCourierList,
  getBranchCourierList,
  postBranchCourierUserDeviceKeyList,
} from "../../../../../actions/branchCourierActions";

//components
import { useStyles } from "../../../../../components/globalStyles";
import * as generalConstants from "../../../../../_constants/generalConstants";
import Panel from "../../../../../components/Panel";

const CourierSelectionField = ({
  courier,
  setCourier,
  setBranchCouriers,
  branchId,
}) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const branchCourier = useSelector((state) => state.branchCourier);

  //functions

  //useEffects
  useEffect(() => {
    dispatch(
      postBranchCourierCourierList.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );

    dispatch(
      postBranchCourierUserDeviceKeyList.pending({
        is_pagination: false,
      })
    );
  }, [dispatch]);

  return (
    <Panel>
      <Grid className={classes.gridRoot} container>
        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            value={courier}
            required
            disableClearable
            name="courier"
            cy_data="searchCourier"
            id="courier"
            options={
              branchCourier.postBranchCourierCourierList
                ? branchCourier.postBranchCourierCourierList.couriers
                : []
            }
            getOptionLabel={(option) => (option.name ? option.name : "")}
            style={{ width: "100%" }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, option) => {
              setCourier(option);
              //clear courier branch
              setBranchCouriers(null);
              dispatch(
                getBranchCourierList.pending({
                  is_pagination: false,
                  search_branch_id_value: branchId,
                  search_courier_id_value: option.id,
                })
              );
            }}
            renderInput={(params) => (
              <TextField
                className={classes.userFieldColor}
                required
                {...params}
                label={t("translation:Courier")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </Panel>
  );
};

export default CourierSelectionField;
