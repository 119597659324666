import * as types from "../_constants/purchaseOrderConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_PURCHASEORDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getPurchaseOrderListIsSuccess: false,
        isLoadingGetPurchaseOrderList: true,
        purchaseOrderList: null,
      };

    case types.GET_PURCHASEORDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getPurchaseOrderListIsSuccess: false,
        isLoadingGetPurchaseOrderList: false,
        purchaseOrderList: null,
        error: { ...state.error, getPurchaseOrderListError: action.error },
      };

    case types.GET_PURCHASEORDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getPurchaseOrderListIsSuccess: true,
        isLoadingGetPurchaseOrderList: false,
        purchaseOrderList: action.data,
      };

    case types.GET_PURCHASEORDERBYID_ASYNC.PENDING:
      return {
        ...state,
        getPurchaseOrderByIdIsSuccess: false,
        isLoadingGetPurchaseOrderById: true,
        purchaseOrderById: null,
      };

    case types.GET_PURCHASEORDERBYID_ASYNC.ERROR:
      return {
        ...state,
        getPurchaseOrderByIdIsSuccess: false,
        isLoadingGetPurchaseOrderById: false,
        purchaseOrderById: null,
        error: { ...state.error, getPurchaseOrderByIdError: action.error },
      };

    case types.GET_PURCHASEORDERBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getPurchaseOrderByIdIsSuccess: true,
        isLoadingGetPurchaseOrderById: false,
        purchaseOrderById: action.data,
      };

    case types.GET_PURCHASEORDERMEDICINETYPES_ASYNC.PENDING:
      return {
        ...state,
        getPurchaseOrderMedicineTypesIsSuccess: false,
        isLoadingGetPurchaseOrderMedicineTypes: true,
        purchaseOrderMedicineTypes: null,
      };

    case types.GET_PURCHASEORDERMEDICINETYPES_ASYNC.ERROR:
      return {
        ...state,
        getPurchaseOrderMedicineTypesIsSuccess: false,
        isLoadingGetPurchaseOrderMedicineTypes: false,
        purchaseOrderMedicineTypes: null,
        error: {
          ...state.error,
          getPurchaseOrderMedicineTypesError: action.error,
        },
      };

    case types.GET_PURCHASEORDERMEDICINETYPES_ASYNC.SUCCESS:
      return {
        ...state,
        getPurchaseOrderMedicineTypesIsSuccess: true,
        isLoadingGetPurchaseOrderMedicineTypes: false,
        purchaseOrderMedicineTypes: action.data,
      };

    case types.POST_PURCHASEORDERMEDICINES_ASYNC.PENDING:
      return {
        ...state,
        postPurchaseOrderMedicinesIsSuccess: false,
        isLoadingPostPurchaseOrderMedicines: true,
        purchaseOrderMedicines: null,
      };

    case types.POST_PURCHASEORDERMEDICINES_ASYNC.ERROR:
      return {
        ...state,
        postPurchaseOrderMedicinesIsSuccess: false,
        isLoadingPostPurchaseOrderMedicines: false,
        purchaseOrderMedicines: null,
        error: {
          ...state.error,
          postPurchaseOrderMedicinesError: action.error,
        },
      };

    case types.POST_PURCHASEORDERMEDICINES_ASYNC.SUCCESS:
      return {
        ...state,
        postPurchaseOrderMedicinesIsSuccess: true,
        isLoadingPostPurchaseOrderMedicines: false,
        purchaseOrderMedicines: action.data,
      };

    case types.POST_SUBMITPURCHASEORDER_ASYNC.PENDING:
      return {
        ...state,
        postSubmitPurchaseOrderIsSuccess: false,
        isLoadingPostSubmitPurchaseOrder: true,
        submitPurchaseOrder: null,
      };

    case types.POST_SUBMITPURCHASEORDER_ASYNC.ERROR:
      return {
        ...state,
        postSubmitPurchaseOrderIsSuccess: false,
        isLoadingPostSubmitPurchaseOrder: false,
        submitPurchaseOrder: null,
        error: { ...state.error, postSubmitPurchaseOrderError: action.error },
      };

    case types.POST_SUBMITPURCHASEORDER_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitPurchaseOrderIsSuccess: true,
        isLoadingPostSubmitPurchaseOrder: false,
        submitPurchaseOrder: action.data,
      };
    case types.PUT_PURCHASEORDERWIPMODE_ASYNC.PENDING:
      return {
        ...state,
        putPurchaseOrderWIPModeIsSuccess: false,
        isLoadingPutPurchaseOrderWIPMode: true,
        purchaseOrderWIPMode: null,
      };

    case types.PUT_PURCHASEORDERWIPMODE_ASYNC.ERROR:
      return {
        ...state,
        putPurchaseOrderWIPModeIsSuccess: false,
        isLoadingPutPurchaseOrderWIPMode: false,
        purchaseOrderWIPMode: null,
        error: { ...state.error, putPurchaseOrderWIPModeError: action.error },
      };

    case types.PUT_PURCHASEORDERWIPMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putPurchaseOrderWIPModeIsSuccess: true,
        isLoadingPutPurchaseOrderWIPMode: false,
        purchaseOrderWIPMode: action.data,
      };

    case types.PUT_PURCHASEORDERCANCELLED_ASYNC.PENDING:
      return {
        ...state,
        putPurchaseOrderCancelledIsSuccess: false,
        isLoadingPutPurchaseOrderCancelled: true,
        purchaseOrderCancelled: null,
      };

    case types.PUT_PURCHASEORDERCANCELLED_ASYNC.ERROR:
      return {
        ...state,
        putPurchaseOrderCancelledIsSuccess: false,
        isLoadingPutPurchaseOrderCancelled: false,
        purchaseOrderCancelled: null,
        error: { ...state.error, putPurchaseOrderCancelledError: action.error },
      };

    case types.PUT_PURCHASEORDERCANCELLED_ASYNC.SUCCESS:
      return {
        ...state,
        putPurchaseOrderCancelledIsSuccess: true,
        isLoadingPutPurchaseOrderCancelled: false,
        purchaseOrderCancelled: action.data,
      };

    case types.PUT_PURCHASEORDERPRINTEDDATE_ASYNC.PENDING:
      return {
        ...state,
        putPurchaseOrderPrintedDateIsSuccess: false,
        isLoadingPutPurchaseOrderPrintedDate: true,
        purchaseOrderPrintedDate: null,
      };

    case types.PUT_PURCHASEORDERPRINTEDDATE_ASYNC.ERROR:
      return {
        ...state,
        putPurchaseOrderPrintedDateIsSuccess: false,
        isLoadingPutPurchaseOrderPrintedDate: false,
        purchaseOrderPrintedDate: null,
        error: {
          ...state.error,
          putPurchaseOrderPrintedDateError: action.error,
        },
      };

    case types.PUT_PURCHASEORDERPRINTEDDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putPurchaseOrderPrintedDateIsSuccess: true,
        isLoadingPutPurchaseOrderPrintedDate: false,
        purchaseOrderPrintedDate: action.data,
      };

    default:
      return state;
  }
};
