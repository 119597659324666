import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/homecareConstants";

export const getAllVisitByLastModified = {
  pending: (data) =>
    createAction(types.GET_ALLVISITBYLASTMODIFIED_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ALLVISITBYLASTMODIFIED_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ALLVISITBYLASTMODIFIED_ASYNC.ERROR, { error }),
};

export const getVerifyMedicine = {
  pending: (data) =>
    createAction(types.GET_VERIFYMEDICINE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VERIFYMEDICINE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VERIFYMEDICINE_ASYNC.ERROR, { error }),
};

export const postUpdateHomecareManualHc2cms = {
  pending: (data) =>
    createAction(types.POST_UPDATEHOMECAREMANUALHC2CMS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_UPDATEHOMECAREMANUALHC2CMS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_UPDATEHOMECAREMANUALHC2CMS_ASYNC.ERROR, { error }),
};

export const getAllVisitByTimestampRange = {
  pending: (data) =>
    createAction(types.GET_ALLVISITBYTIMESTAMPRANGE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ALLVISITBYTIMESTAMPRANGE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ALLVISITBYTIMESTAMPRANGE_ASYNC.ERROR, { error }),
};

export const getUpdateHCVisitOrderByStoreId = {
  pending: (data) =>
    createAction(types.GET_UPDATEHCVISITORDERBYSTOREID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_UPDATEHCVISITORDERBYSTOREID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_UPDATEHCVISITORDERBYSTOREID_ASYNC.ERROR, { error }),
};

export const postUpdateHomecareMedicine = {
  pending: (data) =>
    createAction(types.POST_UPDATEHOMECAREMEDICINE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_UPDATEHOMECAREMEDICINE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_UPDATEHOMECAREMEDICINE_ASYNC.ERROR, { error }),
};
