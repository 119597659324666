import { createAsyncTypes } from "../_helper/Utilities";

export const GET_DELIVERYLIST_ASYNC = createAsyncTypes("GET_DELIVERYLIST");

export const POST_SUBMITDELIVERY_ASYNC = createAsyncTypes(
  "POST_SUBMITDELIVERY"
);

export const PUT_DELIVERYPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYPRINTDATE"
);

export const GET_DELIVERYDISTRICTDETAILSLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYDISTRICTDETAILSLIST"
);

export const GET_EXPORTDELIVERYDISTRICTDETAILSLIST_ASYNC = createAsyncTypes(
  "GET_EXPORTDELIVERYDISTRICTDETAILSLIST"
);

export const GET_DELIVERYDISTRICTDETAILSSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYDISTRICTDETAILSSUMMARYLIST"
);

export const GET_EXPORTDELIVERYDISTRICTDETAILSSUMMARYLIST_ASYNC =
  createAsyncTypes("GET_EXPORTDELIVERYDISTRICTDETAILSSUMMARYLIST");

export const GET_DELIVERYPOSTALCODEDISTRICTLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYPOSTALCODEDISTRICTLIST"
);
