import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Panel from "../../../../../components/Panel";
import organisationPriceRounding from "../../../../../components/functions/organisationPriceRounding";
import * as generalConstants from "../../../../../_constants/generalConstants";
import organisationPriceRoundingWGst from "../../../../../components/functions/organisationPriceRoundingWGst";
import { useStyles } from "../../../../../components/globalStyles";

//action

function TotalPricePanel({ classes, t, item, order, currentOrganistion }) {
  let organisationBranch =
    order.dispensaryBranchesAlliance.organisation_branch.find(
      (organisation_branch) =>
        organisation_branch.id === item.organisation_branch.id
    );

  if (!organisationBranch) {
    organisationBranch = item.organisation_branch;
  }
  return (
    <>
      {item.amt_with_gst !== 0 && item.amt_with_gst && item.detailMedicine && (
        <Panel
          heading={
            organisationBranch.branch.name +
            " " +
            t("translation:Total Fee") +
            " $" +
            Number(item.amt_with_gst).toFixed(2)
          }>
          <Grid container>
            <Grid xs={3} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={Number(item.branch_medicine_price).toFixed(2)}
                disabled
                name="totalPrice"
                label={t("translation:Total Medicine Fee")}
                variant="outlined"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    fontWeight: "bold",
                    color: "rgba(149, 0, 0,0.75)",
                  },
                }}
              />
            </Grid>

            {item.isShowBrewing && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(item.branch_brewing_price).toFixed(2)}
                  disabled
                  name="brewingFee"
                  label={t("translation:Brewing Fee")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}

            {item.isShowDelivery && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(item.branch_delivery_price).toFixed(2)}
                  disabled
                  name="deliveryFee"
                  label={t("translation:Delivery Fee")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}

            {organisationBranch.organisation.id !== currentOrganistion.id && (
              <Grid xs={3} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  value={Number(item.gst_amt).toFixed(2)}
                  disabled
                  name="taxAmmount"
                  label={t("translation:Tax Amount")}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  InputLabelProps={{
                    shrink: true,

                    style: {
                      fontWeight: "bold",
                      color: "rgba(149, 0, 0,0.75)",
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Panel>
      )}
    </>
  );
}

export default function AllianceTotalPrice({
  medicineList,
  memoGroupOption,
  group,
  setGroupOption,
  price,
  setPrice,
  memoSelectedGroupIndex,
  memoSelectedGroup,
  order,
  setCostingHeader,
  setIsSaveLoading,
  costingHeader,
  userOrganisations,
  storeOrderMode = false,
}) {
  const [showDeliveryPrice, setShowDeliveryPrice] = useState(false);
  const [showBrewingPrice, setShowBrewingPrice] = useState(false);
  const visitOrder = useSelector((state) => state.visitOrder);
  const workOrder = useSelector((state) => state.workOrder);

  const classes = useStyles();
  const { t } = useTranslation();
  //useEffects

  // useEffect(() => {
  //   let subTotal = 0;
  //   let total = 0;
  //   let validTotal = 0;

  //   const currentOrganistion = order.currentOrganistion.organisation;

  //   memoGroupOption.map((option, index) => {
  //     if (
  //       option.store_medicine_details &&
  //       Object.keys(option.store_medicine_details).length !== 0 &&
  //       option.store_medicine_details.some(
  //         (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  //       )
  //     ) {
  //       const temSubTotal = option.store_medicine_details.reduce((sum, li) => {
  //         if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
  //           let temSubTotalRound = organisationPriceRounding(
  //             li.quantity_per_day *
  //               li.branch_medicine.medicines.unit_selling_price,
  //             currentOrganistion.med_details_price_rmt,
  //             currentOrganistion.med_details_price_rpvt
  //           );

  //           return Number(sum) + Number(temSubTotalRound);
  //         } else {
  //           return Number(sum);
  //         }
  //       }, 0);

  //       const temTotalWtDays = temSubTotal * option.number_of_days;

  //       //validation organisation calcultaion
  //       let validSubTotal = organisationPriceRounding(
  //         temTotalWtDays,
  //         currentOrganistion.med_header_price_rmt,
  //         currentOrganistion.med_header_price_rpvt
  //       );

  //       const temTotalWtDaysWMultiplier = option.multiplier
  //         ? temSubTotal * option.number_of_days * option.multiplier
  //         : temSubTotal * option.number_of_days;

  //       //validation organisation calcultaion
  //       let validSubTotalWMultiplier = organisationPriceRounding(
  //         temTotalWtDaysWMultiplier,
  //         currentOrganistion.med_header_price_rmt,
  //         currentOrganistion.med_header_price_rpvt
  //       );

  //       if (
  //         memoSelectedGroup &&
  //         option.name === memoSelectedGroup.name &&
  //         option.id === memoSelectedGroup.id
  //       ) {
  //         subTotal = validSubTotal;
  //       }
  //       total = Number(total) + Number(validSubTotal);
  //       validTotal = Number(validTotal) + Number(validSubTotalWMultiplier);
  //     }
  //     return null;
  //   });

  //   //validation organisation calcultaion

  //   validTotal = organisationPriceRounding(
  //     validTotal,
  //     currentOrganistion.order_cat_amt_rmt,
  //     currentOrganistion.order_cat_amt_rpvt
  //   );

  //   total = organisationPriceRounding(
  //     total,
  //     currentOrganistion.med_header_price_rmt,
  //     currentOrganistion.med_header_price_rpvt
  //   );

  //   setPrice((p) => ({
  //     ...p,
  //     medicineFee: total,
  //     medicineTab: validTotal,
  //     medicineGroupTotal: subTotal,
  //   }));
  // }, [
  //   medicineList,
  //   order.currentOrganistion,
  //   memoGroupOption,
  //   memoSelectedGroup,
  //   setPrice,
  // ]);

  useEffect(() => {
    //console.log(costingHeader);

    const sum_gst_amt = costingHeader.reduce((sum, li) => {
      if (
        li.organisation_branch.organisation.is_gst_enable &&
        li.organisation_branch.organisation.is_gst_inclusive !== true
      ) {
        return Number(sum) + Number(li.gst_amt);
      } else {
        return Number(sum);
      }
    }, 0);

    const sum_gst_amt_rounding = organisationPriceRounding(
      Number(sum_gst_amt),
      order.currentOrganistion.organisation.gst_amt_rmt,
      order.currentOrganistion.organisation.gst_amt_rpvt
    );

    const sum_branch_medicine_price = costingHeader.reduce((sum, li) => {
      return Number(sum) + Number(li.branch_medicine_price);
    }, 0);

    const sum_branch_medicine_price_rounding = organisationPriceRounding(
      Number(sum_branch_medicine_price),
      order.currentOrganistion.organisation.medicine_price_for_costing_rmt,
      order.currentOrganistion.organisation.medicine_price_for_costing_rpvt
    );

    const sum_branch_brewing_price = costingHeader.reduce((sum, li) => {
      return Number(sum) + Number(li.branch_brewing_price);
    }, 0);

    const sum_branch_delivery_price = costingHeader.reduce((sum, li) => {
      return Number(sum) + Number(li.branch_delivery_price);
    }, 0);

    const sum_multiplierBranchMedicinePrice = costingHeader.reduce(
      (sum, li) => {
        return Number(sum) + Number(li.multiplierBranchMedicinePrice);
      },
      0
    );

    const medicineTab_rounding = organisationPriceRounding(
      sum_multiplierBranchMedicinePrice,
      order.currentOrganistion.organisation.order_cat_amt_rmt,
      order.currentOrganistion.organisation.order_cat_amt_rpvt
    );

    setPrice((p) => ({
      ...p,
      //delivery fee and brewing fee should not rounding
      deliveryFee: sum_branch_delivery_price,
      brewingFee: sum_branch_brewing_price,
      medicineFee: sum_branch_medicine_price_rounding,
      medicineTab: medicineTab_rounding,
      vendorGstAmt: sum_gst_amt_rounding,
    }));
    setIsSaveLoading(false);
  }, [costingHeader, order.currentOrganistion, setPrice, setIsSaveLoading]);

  useEffect(() => {
    let subTotal = 0;
    let total = 0;
    setIsSaveLoading(true);
    if (
      order.dispensaryBranchesAlliance &&
      Object.keys(order.dispensaryBranchesAlliance).length !== 0
    ) {
      //Deleted memoGroupOption need to be included in order to set existing costing_header to "DELETED"
      const groupOptionWMedicineDetails = memoGroupOption.filter(
        (option) =>
          option.store_medicine_details &&
          Object.keys(option.store_medicine_details).length !== 0 &&
          option.store_medicine_details.some(
            (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
          )
      );
      const groupTotalCount = groupOptionWMedicineDetails.map(
        (option, index) => {
          let organisationBranch =
            order.dispensaryBranchesAlliance.organisation_branch.find(
              (organisation_branch) =>
                organisation_branch.id === option.organisation_branch.id
            );

          if (!organisationBranch) {
            organisationBranch = option.organisation_branch;
          }
          let userOrganisationMutiplier = null;
          if (userOrganisations && storeOrderMode) {
            userOrganisationMutiplier = userOrganisations.find(
              (item) =>
                item.organisations.id === organisationBranch.organisation.id
            )?.medicine_cost_multiplier;
          }

          const temSubTotal = option.store_medicine_details.reduce(
            (sum, li) => {
              //Deleted store_medicine_details should no be counted
              if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
                let temSubTotalRound = 0;

                if (userOrganisationMutiplier) {
                  temSubTotalRound = organisationPriceRounding(
                    userOrganisationMutiplier *
                      li.branch_medicine.medicines.unit_selling_price,
                    organisationBranch.organisation.med_details_price_rmt,
                    organisationBranch.organisation.med_details_price_rpvt
                  );
                }
                temSubTotalRound = organisationPriceRounding(
                  userOrganisationMutiplier
                    ? li.quantity_per_day * temSubTotalRound
                    : li.quantity_per_day *
                        li.branch_medicine.medicines.unit_selling_price,
                  organisationBranch.organisation.med_details_price_rmt,
                  organisationBranch.organisation.med_details_price_rpvt
                );

                return Number(
                  (Number(sum) + Number(temSubTotalRound)).toFixed(7)
                ); //toPrecision is to prevent 0.1 + 0.2 = 0.30000000000000004
              } else {
                return Number(sum);
              }
            },
            0
          );
          const temTotalWtDays = Number(
            (Number(temSubTotal) * Number(option.number_of_days)).toFixed(7)
          );

          let validSubTotal = 0;
          //validation organisation calcultaion
          if (organisationBranch.organisation) {
            validSubTotal = organisationPriceRounding(
              temTotalWtDays,
              organisationBranch.organisation.med_header_price_rmt,
              organisationBranch.organisation.med_header_price_rpvt
            );
          }
          if (
            memoSelectedGroup &&
            option.name === memoSelectedGroup.name &&
            option.id === memoSelectedGroup.id
          ) {
            subTotal = validSubTotal;
          }
          //for medicine costing
          total = Number(total) + Number(validSubTotal);

          return {
            organisation_branch: organisationBranch,
            branch_medicine_price: Number(validSubTotal),
            multiplier: option.multiplier,
            status_type: option.status_type,
            detailMedicine: option.store_medicine_details.some(
              (item) =>
                item.status_type === generalConstants.APPROVED_STATUS_TYPE
            ),
          };
        }
      );

      //sum up price based on organisation_branch
      var result = [];
      groupTotalCount.reduce(function (res, value) {
        if (!res[value.organisation_branch.id]) {
          res[value.organisation_branch.id] = {
            organisation_branch: value.organisation_branch,
            branch_medicine_price: 0,
            multiplierBranchMedicinePrice: 0,
            status_type: value.status_type,
            detailMedicine: value.detailMedicine,
          };
          result.push(res[value.organisation_branch.id]);
        }

        res[value.organisation_branch.id].branch_medicine_price = Number(
          (
            res[value.organisation_branch.id].branch_medicine_price +
            value.branch_medicine_price
          ).toFixed(7)
        );

        const multiplierValidValue =
          typeof value.multiplier === "number" && value.multiplier
            ? value.branch_medicine_price * value.multiplier
            : value.branch_medicine_price;
        res[value.organisation_branch.id].multiplierBranchMedicinePrice +=
          multiplierValidValue;

        //set costing header status_type to deleted if similar organisation_branch is APPROVED_STATUS_TYPE
        res[value.organisation_branch.id].status_type ===
        generalConstants.APPROVED_STATUS_TYPE
          ? (res[value.organisation_branch.id].status_type =
              generalConstants.APPROVED_STATUS_TYPE)
          : (res[value.organisation_branch.id].status_type = value.status_type);

        //if detailMedicine is false then no need to show costing header
        res[value.organisation_branch.id].detailMedicine === true
          ? (res[value.organisation_branch.id].detailMedicine = true)
          : (res[value.organisation_branch.id].detailMedicine =
              value.detailMedicine);

        res[value.organisation_branch.id].organisation_branch =
          value.organisation_branch;
        return res;
      }, {});

      if (Object.keys(result).length !== 0) {
        setCostingHeader((memoCostingHeader) => {
          // let tempCostingHeader = result.map((totalPriceItem) => {
          //   return {
          //     ...totalPriceItem,
          //     id: 0,
          //     status_type: generalConstants.APPROVED_STATUS_TYPE,
          //   };
          // });

          // return tempCostingHeader;

          let tempCostingHeader = [...memoCostingHeader];
          if (Object.keys(tempCostingHeader).length !== 0) {
            tempCostingHeader.map((item) => {
              //// check available organisation_branch in result, if no found then delete
              const branchAvailable = result.some(
                (resultItem) =>
                  resultItem.organisation_branch.id ===
                  item.organisation_branch.id
              );
              if (!branchAvailable) {
                tempCostingHeader = tempCostingHeader.filter(
                  (filterItem) =>
                    filterItem.organisation_branch.id !==
                    item.organisation_branch.id
                );
              }
              return "";
            });
          }
          //count GST Details
          result.map((resultItem) => {
            const resultItemIndex = tempCostingHeader.findIndex(
              (indexItem) =>
                indexItem.organisation_branch.id ===
                resultItem.organisation_branch.id
            );

            let branch_medicine_price_rounding = organisationPriceRounding(
              resultItem.branch_medicine_price,
              resultItem.organisation_branch.organisation.order_cat_amt_rmt,
              resultItem.organisation_branch.organisation.order_cat_amt_rpvt
            );

            //self organisation no need to round the costing medicine with order_cat_amt
            if (
              resultItem.organisation_branch.organisation.id ===
              order.currentOrganistion.organisation.id
            ) {
              if (resultItemIndex !== -1) {
                tempCostingHeader[resultItemIndex] = {
                  ...tempCostingHeader[resultItemIndex],
                  gst_amt: 0,
                  branch_medicine_price: resultItem.branch_medicine_price,
                  multiplierBranchMedicinePrice:
                    resultItem.multiplierBranchMedicinePrice,
                  status_type: resultItem.status_type,
                  detailMedicine: resultItem.detailMedicine,
                };
              } else {
                tempCostingHeader.push({
                  id: 0,
                  gst_amt: 0,
                  branch_brewing_price: 0,
                  branch_delivery_price: 0,
                  branch_medicine_price: resultItem.branch_medicine_price,
                  multiplierBranchMedicinePrice:
                    resultItem.multiplierBranchMedicinePrice,
                  organisation_branch: resultItem.organisation_branch,
                  status_type: resultItem.status_type,
                  detailMedicine: resultItem.detailMedicine,
                });
              }
            } else {
              if (resultItemIndex !== -1) {
                const organisationPriceRoundingWGsts =
                  organisationPriceRoundingWGst(
                    Number(branch_medicine_price_rounding) +
                      Number(
                        tempCostingHeader[resultItemIndex].branch_brewing_price
                      ) +
                      Number(
                        tempCostingHeader[resultItemIndex].branch_delivery_price
                      ),
                    resultItem.organisation_branch.organisation
                  );

                tempCostingHeader[resultItemIndex] = {
                  ...tempCostingHeader[resultItemIndex],
                  ...organisationPriceRoundingWGsts,
                  branch_medicine_price: resultItem.branch_medicine_price,
                  multiplierBranchMedicinePrice:
                    resultItem.multiplierBranchMedicinePrice,
                  status_type: resultItem.status_type,
                  detailMedicine: resultItem.detailMedicine,
                };
              } else {
                const organisationPriceRoundingWGsts =
                  organisationPriceRoundingWGst(
                    Number(branch_medicine_price_rounding),
                    resultItem.organisation_branch.organisation
                  );
                tempCostingHeader.push({
                  id: 0,
                  branch_brewing_price: 0,
                  branch_delivery_price: 0,
                  ...organisationPriceRoundingWGsts,
                  branch_medicine_price: resultItem.branch_medicine_price,
                  multiplierBranchMedicinePrice:
                    resultItem.multiplierBranchMedicinePrice,
                  organisation_branch: resultItem.organisation_branch,
                  status_type: resultItem.status_type,
                  detailMedicine: resultItem.detailMedicine,
                });
              }
            }
            return "";
          });
          return tempCostingHeader;
        });
      } else {
        setCostingHeader([]);
      }

      setPrice((p) => ({
        ...p,
        medicineGroupTotal: subTotal,
      }));
    }
  }, [
    medicineList,
    memoGroupOption,
    memoSelectedGroup,
    setPrice,
    setCostingHeader,
    setIsSaveLoading,
    order.currentOrganistion,
    order.dispensaryBranchesAlliance,
    price.deliveryFee,
    price.brewingFee,
    storeOrderMode,
    userOrganisations,
  ]);

  useEffect(() => {
    const elementsIndex = memoSelectedGroupIndex;
    if (price.medicineGroupTotal) {
      //make a copy of group option
      let newArray = memoGroupOption;
      if (newArray.length > 0 && newArray[elementsIndex]) {
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          price: price.medicineGroupTotal,
        };

        setGroupOption(newArray);
      }
    }
  }, [
    group,
    price.medicineGroupTotal,
    memoSelectedGroupIndex,
    memoGroupOption,
    setGroupOption,
    setCostingHeader,
  ]);

  useEffect(() => {
    const isDelivery = memoGroupOption.some(
      (item) =>
        item.is_delivery_services === true &&
        item.medicine_type &&
        item.organisation_branch &&
        item.store_medicine_details.some(
          (detailsItem) =>
            detailsItem.status_type === generalConstants.APPROVED_STATUS_TYPE
        ) &&
        item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );

    setShowDeliveryPrice(isDelivery);
  }, [memoGroupOption]);

  useEffect(() => {
    const isBrewing = memoGroupOption.some(
      (item) =>
        item.is_brewing_services === true &&
        item.medicine_type &&
        item.organisation_branch &&
        item.store_medicine_details.some(
          (detailsItem) =>
            detailsItem.status_type === generalConstants.APPROVED_STATUS_TYPE
        ) &&
        item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );

    setShowBrewingPrice(isBrewing);
  }, [memoGroupOption]);

  const brewingWCount = () => {
    const groupBrewing = memoGroupOption.filter(
      (item) =>
        item.is_brewing_services === true &&
        item.medicine_type &&
        item.organisation_branch &&
        item.status_type === generalConstants.APPROVED_STATUS_TYPE &&
        Object.keys(item.store_medicine_details).length !== 0 &&
        item.store_medicine_details.some(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
    );

    if (
      order.dispensaryBranchesAlliance &&
      Object.keys(order.dispensaryBranchesAlliance).length !== 0
    ) {
      const countBranchBrewing = [
        ...groupBrewing
          .reduce((r, { number_of_days, number_of_times, ...o }) => {
            const organisationBranch =
              order.dispensaryBranchesAlliance.organisation_branch.find(
                (organisation_branch) =>
                  organisation_branch.id === o.organisation_branch.id
              );
            const key = o.organisation_branch.id;

            const itemWBrewingPrice =
              r.get(key) ||
              Object.assign(
                {},
                {
                  totalBrewingCharge: 0,
                },
                {
                  organisation_branch: organisationBranch
                    ? organisationBranch
                    : o.organisation_branch,
                  add_pack_charge: organisationBranch
                    ? organisationBranch.branch.add_pack_charge
                    : 0,
                  min_brewing_packs: organisationBranch
                    ? organisationBranch.branch.min_brewing_packs
                    : 0,
                  min_brewing_charge: organisationBranch
                    ? organisationBranch.branch.min_brewing_charge
                    : 0,
                }
              );

            let currectGroupBrewingCharge = 0;
            if (
              Number(number_of_days * number_of_times) <
              itemWBrewingPrice.min_brewing_packs
            ) {
              currectGroupBrewingCharge = itemWBrewingPrice.min_brewing_charge;
            } else {
              const additionalBrewingFee =
                (Number(number_of_days * number_of_times) -
                  itemWBrewingPrice.min_brewing_packs) *
                  itemWBrewingPrice.add_pack_charge +
                itemWBrewingPrice.min_brewing_charge;

              currectGroupBrewingCharge = additionalBrewingFee;
            }

            return r.set(key, {
              ...itemWBrewingPrice,
              totalBrewingCharge:
                Number(itemWBrewingPrice.totalBrewingCharge) +
                Number(currectGroupBrewingCharge),
            });
          }, new Map())
          .values(),
      ];

      // let totalBrewingCharge = 0;
      // countBranchBrewing.map((li) => {
      //   if (li.number_of_packs < li.min_brewing_packs) {
      //     totalBrewingCharge = totalBrewingCharge + li.min_brewing_charge;
      //   } else {
      //     const additionalBrewingFee =
      //       (li.number_of_packs - li.min_brewing_packs) * li.add_pack_charge +
      //       li.min_brewing_charge;

      //     totalBrewingCharge = totalBrewingCharge + additionalBrewingFee;
      //   }
      //   return "";
      // });

      return countBranchBrewing;
    }
  };

  const memoBrewingWCount = React.useMemo(brewingWCount, [
    memoGroupOption,
    order.dispensaryBranchesAlliance,
  ]);
  // useEffect(() => {
  //   const brewingResult = memoBrewingWCount;

  //   if (brewingResult === 0) {
  //     setPrice((p) => ({
  //       ...p,
  //       brewingFee: 0,
  //     }));
  //   } else {
  //     setPrice((p) => ({
  //       ...p,
  //       brewingFee: brewingResult,
  //     }));
  //   }
  // }, [memoBrewingWCount, setPrice]);

  useEffect(() => {
    if (memoBrewingWCount) {
      setCostingHeader((memoCostingHeader) => {
        let tempCostingHeader = [...memoCostingHeader];

        if (
          Object.keys(memoBrewingWCount).length === 0 &&
          Object.keys(tempCostingHeader).length !== 0
        ) {
          const emptyBrewingWCount = tempCostingHeader.map((item) => {
            return { ...item, branch_brewing_price: 0 };
          });
          tempCostingHeader = [...emptyBrewingWCount];
        }

        if (
          Object.keys(memoBrewingWCount).length !== 0 &&
          Object.keys(memoBrewingWCount).length <
            Object.keys(tempCostingHeader).length
        ) {
          //remove extra fee when memoBrewingWCount is clear
          const removeBrewingWCount = tempCostingHeader.map((item) => {
            const brewingItemIndex = memoBrewingWCount.findIndex(
              (indexItem) =>
                indexItem.organisation_branch.id === item.organisation_branch.id
            );
            if (brewingItemIndex === -1) {
              return { ...item, branch_brewing_price: 0 };
            } else {
              return { ...item };
            }
          });
          tempCostingHeader = [...removeBrewingWCount];
        }

        memoBrewingWCount.map((brewingItem) => {
          const brewingItemIndex = tempCostingHeader.findIndex(
            (indexItem) =>
              indexItem.organisation_branch.id ===
              brewingItem.organisation_branch.id
          );
          //count brewing Charge
          // let totalBrewingCharge = 0;
          // if (brewingItem.number_of_packs < brewingItem.min_brewing_packs) {
          //   totalBrewingCharge = brewingItem.min_brewing_charge;
          // } else {
          //   const additionalBrewingFee =
          //     (brewingItem.number_of_packs - brewingItem.min_brewing_packs) *
          //       brewingItem.add_pack_charge +
          //     brewingItem.min_brewing_charge;

          //   totalBrewingCharge = additionalBrewingFee;
          // }
          //check any empty or same organisation branch

          if (brewingItemIndex !== -1) {
            tempCostingHeader[brewingItemIndex] = {
              ...tempCostingHeader[brewingItemIndex],
              branch_brewing_price: brewingItem.totalBrewingCharge,
            };
          } else {
            tempCostingHeader.push({
              branch_brewing_price: brewingItem.totalBrewingCharge,
              organisation_branch: brewingItem.organisation_branch,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
            });
          }
          return "";
        });

        return tempCostingHeader;
      });
    }
  }, [memoBrewingWCount, setCostingHeader]);

  //show brewing
  useEffect(() => {
    const groupOptionWMedicineDetails = memoGroupOption.filter(
      (option) =>
        option.store_medicine_details &&
        Object.keys(option.store_medicine_details).length !== 0 &&
        option.store_medicine_details.some(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
    );

    //grouping by organisation_branch and show brewing price
    var result = [];
    groupOptionWMedicineDetails.reduce(function (res, value) {
      if (!res[value.organisation_branch.id]) {
        const isBrewing = groupOptionWMedicineDetails.some(
          (item) =>
            item.is_brewing_services === true &&
            item.organisation_branch.id === value.organisation_branch.id
        );
        //
        //
        res[value.organisation_branch.id] = {
          organisation_branch: value.organisation_branch,
          isShowBrewing: isBrewing,
        };
        result.push(res[value.organisation_branch.id]);
      }

      return res;
    }, {});
    setCostingHeader((memoCostingHeader) => {
      let tempCostingHeader = [...memoCostingHeader];
      if (
        Object.keys(result).length === 0 &&
        Object.keys(tempCostingHeader).length !== 0
      ) {
        const disabledDelivery = tempCostingHeader.map((item) => {
          return { ...item, isShowBrewing: false };
        });
        tempCostingHeader = [...disabledDelivery];
      }
      //
      result.map((resultItem) => {
        const resultItemIndex = tempCostingHeader.findIndex(
          (indexItem) =>
            indexItem.organisation_branch.id ===
            resultItem.organisation_branch.id
        );

        if (resultItemIndex !== -1) {
          tempCostingHeader[resultItemIndex] = {
            ...tempCostingHeader[resultItemIndex],
            isShowBrewing: resultItem.isShowBrewing,
          };
        }
        return "";
      });
      return tempCostingHeader;
    });
  }, [memoGroupOption, setCostingHeader]);

  const deliveryWCount = () => {
    const postalCodeSurchargeList = storeOrderMode
      ? workOrder?.getStorePostalCodeSurchargeList?.postal_code_surcharges
      : visitOrder?.getVisitPostalCodeSurchargeList?.postal_code_surcharges;
    if (memoSelectedGroup) {
      //pending
    }

    //check the selected group get update and proceed

    const groupWDelivery = memoGroupOption.filter(
      (item) =>
        item.is_delivery_services === true &&
        item.medicine_type &&
        item.organisation_branch &&
        item.status_type === generalConstants.APPROVED_STATUS_TYPE &&
        Object.keys(item.store_medicine_details).length !== 0 &&
        item.store_medicine_details.some(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
    );
    if (
      order.dispensaryBranchesAlliance &&
      Object.keys(order.dispensaryBranchesAlliance).length !== 0
    ) {
      const countDeliveryAddress = [
        ...groupWDelivery
          .reduce((r, { ...o }) => {
            const branch =
              order.dispensaryBranchesAlliance.organisation_branch.find(
                (organisation_branch) =>
                  organisation_branch.id === o.organisation_branch.id
              );
            const postalCodeSurcharge = postalCodeSurchargeList?.find(
              (item) =>
                item.organisation.id ===
                  o.organisation_branch.organisation.id &&
                item.postal_code === o.delivery_postal_code
            );

            const key =
              o.organisation_branch.id +
              "-" +
              o.delivery_postal_code +
              "-" +
              o.delivery_address_details +
              "-" +
              o.delivery_address;

            const itemWDeliveryPrice =
              r.get(key) ||
              Object.assign(
                {},
                { ...o, freeDeliveryFee: 0 },
                {
                  deliveryCharge: branch
                    ? postalCodeSurcharge
                      ? Number(postalCodeSurcharge.surcharge) +
                        Number(branch.branch.delivery_charge)
                      : Number(branch.branch.delivery_charge)
                    : 0,
                }
              );
            return r.set(key, {
              ...itemWDeliveryPrice,
              freeDeliveryFee:
                Number(itemWDeliveryPrice.freeDeliveryFee) + Number(o.price),
              deliveryCharge: branch
                ? branch.branch.min_medicine_spend_for_free_delivery >
                  Number(itemWDeliveryPrice.freeDeliveryFee) + Number(o.price)
                  ? Number(itemWDeliveryPrice.deliveryCharge)
                  : 0
                : 0,
            });
          }, new Map())
          .values(),
      ];

      // const totalDeliveyCharge = countDeliveryAddress.reduce(
      //   (sum, li) => sum + li.deliveryCharge,
      //   0
      // );

      var result = [];
      countDeliveryAddress.reduce(function (res, value) {
        if (!res[value.organisation_branch.Id]) {
          res[value.organisation_branch.Id] = {
            organisation_branch: value.organisation_branch,
            deliveryCharge: 0,
          };
          result.push(res[value.organisation_branch.Id]);
        }
        res[value.organisation_branch.Id].deliveryCharge +=
          value.deliveryCharge;
        return res;
      }, {});

      return result;
    }
  };

  const memoDeliveryWCount = React.useMemo(deliveryWCount, [
    memoGroupOption,
    memoSelectedGroup,
    order.dispensaryBranchesAlliance,
    storeOrderMode,
    workOrder?.getStorePostalCodeSurchargeList,
    visitOrder?.getVisitPostalCodeSurchargeList,
  ]);

  useEffect(() => {
    if (memoDeliveryWCount) {
      setCostingHeader((memoCostingHeader) => {
        let tempCostingHeader = [...memoCostingHeader];
        if (
          Object.keys(memoDeliveryWCount).length === 0 &&
          Object.keys(tempCostingHeader).length !== 0
        ) {
          const emptyDeliveryWCount = tempCostingHeader.map((item) => {
            return { ...item, branch_delivery_price: 0 };
          });
          tempCostingHeader = [...emptyDeliveryWCount];
        }
        if (
          Object.keys(memoDeliveryWCount).length !== 0 &&
          Object.keys(memoDeliveryWCount).length <
            Object.keys(tempCostingHeader).length
        ) {
          //remove extra fee when memoDeliveryWCount is clear
          const removeDeliveryWCount = tempCostingHeader.map((item) => {
            const deliveryItemIndex = memoDeliveryWCount.findIndex(
              (indexItem) =>
                indexItem.organisation_branch.id === item.organisation_branch.id
            );
            if (deliveryItemIndex === -1) {
              return { ...item, branch_delivery_price: 0 };
            } else {
              return { ...item };
            }
          });
          tempCostingHeader = [...removeDeliveryWCount];
        }
        memoDeliveryWCount.map((deliveryItem) => {
          const deliveryItemIndex = tempCostingHeader.findIndex(
            (indexItem) =>
              indexItem.organisation_branch.id ===
              deliveryItem.organisation_branch.id
          );

          //check any empty or same organisation branch
          if (deliveryItemIndex !== -1) {
            tempCostingHeader[deliveryItemIndex] = {
              ...tempCostingHeader[deliveryItemIndex],
              branch_delivery_price: deliveryItem.deliveryCharge,
            };
          } else {
            tempCostingHeader.push({
              branch_delivery_price: deliveryItem.deliveryCharge,
              organisation_branch: deliveryItem.organisation_branch,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
            });
          }
          return "";
        });
        return tempCostingHeader;
      });
    }
  }, [memoDeliveryWCount, setCostingHeader]);
  //show delivery
  useEffect(() => {
    // get the array of the delivery

    const groupOptionWMedicineDetails = memoGroupOption.filter(
      (option) =>
        option.store_medicine_details &&
        Object.keys(option.store_medicine_details).length !== 0 &&
        option.store_medicine_details.some(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        )
    );

    //grouping by organisation_branch and show delivery price
    var result = [];
    groupOptionWMedicineDetails.reduce(function (res, value) {
      if (!res[value.organisation_branch.id]) {
        const isDelivery = groupOptionWMedicineDetails.some(
          (item) =>
            item.is_delivery_services === true &&
            item.organisation_branch.id === value.organisation_branch.id
        );
        //
        //
        res[value.organisation_branch.id] = {
          organisation_branch: value.organisation_branch,
          isShowDelivery: isDelivery,
        };
        result.push(res[value.organisation_branch.id]);
      }

      return res;
    }, {});
    setCostingHeader((memoCostingHeader) => {
      let tempCostingHeader = [...memoCostingHeader];
      if (
        Object.keys(result).length === 0 &&
        Object.keys(tempCostingHeader).length !== 0
      ) {
        const disabledDelivery = tempCostingHeader.map((item) => {
          return { ...item, isShowDelivery: false };
        });
        tempCostingHeader = [...disabledDelivery];
      }
      //
      result.map((resultItem) => {
        const resultItemIndex = tempCostingHeader.findIndex(
          (indexItem) =>
            indexItem.organisation_branch.id ===
            resultItem.organisation_branch.id
        );

        if (resultItemIndex !== -1) {
          tempCostingHeader[resultItemIndex] = {
            ...tempCostingHeader[resultItemIndex],
            isShowDelivery: resultItem.isShowDelivery,
          };
        }
        return "";
      });
      return tempCostingHeader;
    });
  }, [memoGroupOption, setCostingHeader]);

  // useEffect(() => {
  //   if (memoDeliveryWCount === 0) {
  //     setPrice((p) => ({
  //       ...p,
  //       deliveryFee: 0,
  //     }));
  //   } else {
  //     setPrice((p) => ({
  //       ...p,
  //       deliveryFee: memoDeliveryWCount,
  //     }));
  //   }
  // }, [memoDeliveryWCount, setPrice]);
  // useEffect(() => {
  //   // get the array of the delivery

  //   const groupOptionWMedicineDetails = memoGroupOption.filter(
  //     (option) =>
  //       option.store_medicine_details &&
  //       Object.keys(option.store_medicine_details).length !== 0 &&
  //       option.store_medicine_details.some(
  //         (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  //       )
  //   );

  //   //grouping by organisation_branch and show delivery price
  //   var result = [];
  //   groupOptionWMedicineDetails.reduce(function (res, value) {
  //     if (!res[value.organisation_branch.id]) {
  //       const isDelivery = groupOptionWMedicineDetails.some(
  //         (item) =>
  //           item.is_delivery_services === true &&
  //           item.organisation_branch.id === value.organisation_branch.id
  //       );
  //       //
  //       //
  //       res[value.organisation_branch.id] = {
  //         organisation_branch: value.organisation_branch,
  //         isShowDelivery: isDelivery,
  //       };
  //       result.push(res[value.organisation_branch.id]);
  //     }

  //     return res;
  //   }, {});
  //   setCostingHeader((memoCostingHeader) => {
  //     let tempCostingHeader = [...memoCostingHeader];
  //     if (
  //       Object.keys(result).length === 0 &&
  //       Object.keys(tempCostingHeader).length !== 0
  //     ) {
  //       const disabledDelivery = tempCostingHeader.map((item) => {
  //         return { ...item, isShowDelivery: false };
  //       });
  //       tempCostingHeader = [...disabledDelivery];
  //     }
  //     //
  //     result.map((resultItem) => {
  //       const resultItemIndex = tempCostingHeader.findIndex(
  //         (indexItem) =>
  //           indexItem.organisation_branch.id ===
  //           resultItem.organisation_branch.id
  //       );

  //       if (resultItemIndex !== -1) {
  //         tempCostingHeader[resultItemIndex] = {
  //           ...tempCostingHeader[resultItemIndex],
  //           isShowDelivery: resultItem.isShowDelivery,
  //         };
  //       }
  //     });
  //     return tempCostingHeader;
  //   });
  // }, [memoGroupOption]);

  // useEffect(() => {

  //   const groupOptionWMedicineDetails = memoGroupOption.filter(
  //     (option) =>
  //       option.store_medicine_details &&
  //       Object.keys(option.store_medicine_details).length !== 0 &&
  //       option.store_medicine_details.some(
  //         (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  //       )
  //   );

  //   //grouping by organisation_branch and show brewing price
  //   var result = [];
  //   groupOptionWMedicineDetails.reduce(function (res, value) {
  //     if (!res[value.organisation_branch.id]) {
  //       const isBrewing = groupOptionWMedicineDetails.some(
  //         (item) =>
  //           item.is_brewing_services === true &&
  //           item.organisation_branch.id === value.organisation_branch.id
  //       );
  //       //
  //       //
  //       res[value.organisation_branch.id] = {
  //         organisation_branch: value.organisation_branch,
  //         isShowBrewing: isBrewing,
  //       };
  //       result.push(res[value.organisation_branch.id]);
  //     }

  //     return res;
  //   }, {});
  //   setCostingHeader((memoCostingHeader) => {
  //     let tempCostingHeader = [...memoCostingHeader];
  //     if (
  //       Object.keys(result).length === 0 &&
  //       Object.keys(tempCostingHeader).length !== 0
  //     ) {
  //       const disabledDelivery = tempCostingHeader.map((item) => {
  //         return { ...item, isShowBrewing: false };
  //       });
  //       tempCostingHeader = [...disabledDelivery];
  //     }
  //     //
  //     result.map((resultItem) => {
  //       const resultItemIndex = tempCostingHeader.findIndex(
  //         (indexItem) =>
  //           indexItem.organisation_branch.id ===
  //           resultItem.organisation_branch.id
  //       );

  //       if (resultItemIndex !== -1) {
  //         tempCostingHeader[resultItemIndex] = {
  //           ...tempCostingHeader[resultItemIndex],
  //           isShowBrewing: resultItem.isShowBrewing,
  //         };
  //       }
  //     });
  //     return tempCostingHeader;
  //   });
  // }, [memoGroupOption]);

  // return costingHeader.map((item) => (
  //   <TotalPricePanel
  //     order={order}
  //     price={price}
  //     classes={classes}
  //     t={t}
  //     item={item}
  //   />
  // ));

  const currentOrganistion = order.currentOrganistion.organisation;
  return (
    <>
      {costingHeader.map((item) => (
        <TotalPricePanel
          key={item.organisation_branch.branch.id}
          price={price}
          classes={classes}
          t={t}
          item={item}
          order={order}
          currentOrganistion={currentOrganistion}
        />
      ))}
      <Panel
        heading={
          t("translation:Total Fee") +
          " $" +
          Number(
            Number(price.medicineFee) +
              Number(price.brewingFee) +
              Number(price.deliveryFee)
          ).toFixed(2)
        }>
        <Grid container>
          <Grid xs={3} item className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={Number(price.medicineFee).toFixed(2)}
              disabled
              name="overAlltotalMedicalPrice"
              cy_data="overAlltotalMedicalPrice"
              label={t("translation:Total Medicine Fee")}
              variant="outlined"
              inputProps={{
                style: { textAlign: "right" },
              }}
              InputLabelProps={{
                shrink: true,

                style: {
                  fontWeight: "bold",
                  color: "rgba(149, 0, 0,0.75)",
                },
              }}
            />
          </Grid>
          {showBrewingPrice && (
            <Grid xs={3} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={Number(price.brewingFee).toFixed(2)}
                disabled
                name="overAllbrewingFee"
                cy_data="overAllbrewingFee"
                label={t("translation:Brewing Fee")}
                variant="outlined"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    fontWeight: "bold",
                    color: "rgba(149, 0, 0,0.75)",
                  },
                }}
              />
            </Grid>
          )}
          {showDeliveryPrice && (
            <Grid xs={3} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={Number(price.deliveryFee).toFixed(2)}
                disabled
                name="overAlldeliveryFee"
                cy_data="overAlldeliveryFee"
                label={t("translation:Delivery Fee")}
                variant="outlined"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    fontWeight: "bold",
                    color: "rgba(149, 0, 0,0.75)",
                  },
                }}
              />
            </Grid>
          )}
          {price.vendorGstAmt > 0 && (
            <Grid xs={3} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={Number(price.vendorGstAmt).toFixed(2)}
                disabled
                name="vendorGstAmmount"
                cy_data="vendorGstAmmount"
                label={t("translation:Tax Amount")}
                variant="outlined"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    fontWeight: "bold",
                    color: "rgba(149, 0, 0,0.75)",
                  },
                }}
              />
            </Grid>
          )}
        </Grid>
      </Panel>
    </>
  );
}
