import { createAsyncTypes } from "../_helper/Utilities";

export const GET_BRANCHCOURIERLIST_ASYNC = createAsyncTypes(
  "GET_BRANCHCOURIERLIST"
);

export const GET_BRANCHCOURIERBYID_ASYNC = createAsyncTypes(
  "GET_BRANCHCOURIERBYID"
);

export const PUT_UPDATEBRANCHCOURIER_ASYNC = createAsyncTypes(
  "PUT_UPDATEBRANCHCOURIER"
);

export const POST_BRANCHCOURIERCOURIERLIST_ASYNC = createAsyncTypes(
  "POST_BRANCHCOURIERCOURIERLIST"
);

export const POST_BRANCHCOURIERBRANCHLIST_ASYNC = createAsyncTypes(
  "POST_BRANCHCOURIERBRANCHLIST"
);

export const POST_BRANCHCOURIERUSERDEVICEKEYLIST_ASYNC = createAsyncTypes(
  "POST_BRANCHCOURIERUSERDEVICEKEYLIST"
);
