//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
//action
import {
  getUserRoleList,
  getUserRole,
  getUserRoleAllRole,
} from "../../../../actions/userRoleActions";

//component
import { UserRoleDetailPage } from "./UserRoleDetailPage";
import { EditUserRolePage } from "./EditUserRolePage";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";

export default function UserRoleListTable({ setInformationView }) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.ADMIN_USER_ROLE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Username"),
      accessor: "username",
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    search_name_value: "",
    search_username_value: "",
    search_contact_number_value: "",
    search_identification_number_value: "",
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });

  const dispatch = useDispatch();

  //const data = useSelector((state) => state.user);
  const userRoledata = useSelector((state) => state.userRole);
  //

  //
  //
  const [editUserRoleView, setEditUserRoleView] = React.useState(false);
  const [userRoleDetailView, setUserRoleDetailView] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  //functions
  const handleRowEdit = (value) => {
    dispatch(getUserRole.pending(value.id));
    dispatch(getUserRoleAllRole.pending());

    setEditUserRoleView(true);
  };

  const handleRowDetail = (value) => {
    dispatch(getUserRoleAllRole.pending());
    dispatch(getUserRole.pending(value.id));
    setUserRoleDetailView(true);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: value,
        search_username_value: value,
        search_contact_number_value: value,
        search_identification_number_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
        search_username_value: "",
        search_contact_number_value: "",
        search_identification_number_value: "",
      });
    }
  };

  //useEffects
  useEffect(() => {
    if (userRoleDetailView === true || editUserRoleView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [userRoleDetailView, editUserRoleView, setInformationView]);

  useEffect(() => {
    dispatch(getUserRoleList.pending(pageInfo));
    //dispatch(getUserList.pending(pageInfo));
  }, [dispatch, pageInfo, editUserRoleView]);

  if (
    userRoledata.getUserRoleListIsSuccess === false &&
    userRoledata.isLoadingUserRoleList === false
  ) {
    return (
      <Alert severity="error">{userRoledata.error.userRoleListError}</Alert>
    );
  } else if (!editUserRoleView && !userRoleDetailView) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t(
          "translation:Name/ Username/ Contact Number/ Identification Number"
        )}
        searchValue={searchValue}
        columns={columns}
        data={userRoledata.userRoleList ? userRoledata.userRoleList.users : []}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          userRoledata.userRoleList ? userRoledata.userRoleList.total_pages : 0
        }
        loading={userRoledata.isLoadingUserRoleList}
        totalItems={
          userRoledata.userRoleList ? userRoledata.userRoleList.total_items : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (
    userRoledata.userRole &&
    userRoleDetailView &&
    userRoledata.getUserRoleAllRoleIsSuccess === true &&
    userRoledata.getUserRoleIsSuccess === true
  ) {
    return (
      <UserRoleDetailPage
        roleList={userRoledata.userRoleAllRole.roles}
        userRoleList={userRoledata.userRole.user_roles}
        user={userRoledata.userRole.user}
        setUserRoleDetailView={setUserRoleDetailView}
      />
    );
  } else if (
    userRoledata.userRole &&
    editUserRoleView &&
    userRoledata.getUserRoleAllRoleIsSuccess === true &&
    userRoledata.getUserRoleIsSuccess === true
  ) {
    return (
      <EditUserRolePage
        roleList={userRoledata.userRoleAllRole.roles}
        userRoleList={userRoledata.userRole.user_roles}
        user={userRoledata.userRole.user}
        setEditUserRoleView={setEditUserRoleView}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
