export const getTableDefaultLocalStorage = (LOCALSTORAGE_NAME) => {
  try {
    const rootReducerSession = JSON.parse(
      sessionStorage.getItem("persist:rootReducer")
    );
    const userAuthSession = rootReducerSession
      ? JSON.parse(rootReducerSession?.userAuth)
      : "";

    const localstorageNameWId = `${LOCALSTORAGE_NAME}_${userAuthSession.systemUserInfo?.id}`;

    const defaultLocalStorage = JSON.parse(
      localStorage.getItem(localstorageNameWId)
    );

    return defaultLocalStorage;
  } catch (error) {
    localStorage.setItem(LOCALSTORAGE_NAME + "Error", error);
    return "";
  }
};

export const setUserLocalStorage = (cname, cvalue) => {
  try {
    const rootReducerSession = JSON.parse(
      sessionStorage.getItem("persist:rootReducer")
    );
    const userAuthSession = JSON.parse(rootReducerSession?.userAuth);

    const localstorageNameWId = `${cname}_${userAuthSession.systemUserInfo?.id}`;

    localStorage.setItem(localstorageNameWId, cvalue);
  } catch (error) {
    localStorage.setItem(cname + "Error", error);
    return "";
  }
};
