import { createAsyncTypes } from "../_helper/Utilities";

export const GET_WIKIDATALIST_ASYNC = createAsyncTypes("GET_WIKIDATALIST");

export const GET_WIKIDATAPREVIEWLIST_ASYNC = createAsyncTypes(
  "GET_WIKIDATAPREVIEWLIST"
);

export const GET_WIKIDATAPATHLIST_ASYNC = createAsyncTypes(
  "GET_WIKIDATAPATHLIST"
);

export const GET_WIKIDATASUBNODELIST_ASYNC = createAsyncTypes(
  "GET_WIKIDATASUBNODELIST"
);

export const GET_WIKIDATABYID_ASYNC = createAsyncTypes("GET_WIKIDATABYID");

export const POST_CREATEWIKIDATA_ASYNC = createAsyncTypes(
  "POST_CREATEWIKIDATA"
);

export const PUT_UPDATEWIKIDATA_ASYNC = createAsyncTypes("PUT_UPDATEWIKIDATA");

export const GET_WIKIACUPOINTLIST_ASYNC = createAsyncTypes(
  "GET_WIKIACUPOINTLIST"
);

export const GET_WIKICUSTOMWORDLIST_ASYNC = createAsyncTypes(
  "GET_WIKICUSTOMWORDLIST"
);

export const GET_WIKITREATMENTTYPES_ASYNC = createAsyncTypes(
  "GET_WIKITREATMENTTYPES"
);

export const GET_WIKITREATMENTLIST_ASYNC = createAsyncTypes(
  "GET_WIKITREATMENTLIST"
);

export const GET_WIKIMEDICINETYPES_ASYNC = createAsyncTypes(
  "GET_WIKIMEDICINETYPES"
);

export const GET_WIKIMEDICINELIST_ASYNC = createAsyncTypes(
  "GET_WIKIMEDICINELIST"
);
