import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const deliveryTrackingApis = {
  getDeliveryTrackingListApi,
  getDeliveryTrackingSummaryListApi,
  getDeliveryTrackingByIdApi,
  getDeliveryTrackingCourierListApi,
  getDeliveryTrackingCourierByBranchApi,
  putDeliveryTrackingWIPModeApi,
  postDeliveryTrackingApi,
  putDeliveryTrackingPendingStatusApi,
  putDeliveryTrackingCloseStatusApi,
  putDeliveryTrackingPrintDateApi,
  putDeliveryTrackingUploadFileApi,
};

async function getDeliveryTrackingListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(
      apiConstants.apiGetDeliveryTrackingList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryTrackingSummaryListApi(userModel) {
  const requestOptions = JSON.stringify(userModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryTrackingSummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryTrackingByIdApi(searchModel) {
  const url = apiConstants.apiGetDeliveryTrackingById + searchModel;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryTrackingCourierListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryTrackingCourierList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryTrackingCourierByBranchApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryTrackingCourierByBranch,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryTrackingWIPModeApi(model) {
  const url = apiConstants.apiPutDeliveryTrackingWIPMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postDeliveryTrackingApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostDeliveryTracking, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function putDeliveryTrackingPendingStatusApi(model) {
  const url = apiConstants.apiPutDeliveryTrackingPendingStatus + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryTrackingCloseStatusApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(
      apiConstants.apiPutDeliveryTrackingCloseStatus,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryTrackingPrintDateApi(model) {
  const url = apiConstants.apiPutDeliveryTrackingPrintDate + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryTrackingUploadFileApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(
      apiConstants.apiPutDeliveryTrackingUploadFile,
      requestOptions,
      headerWithauth
    )
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default deliveryTrackingApis;
