//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

//Actions

import {
  getBranchScheduleBranchList,
  getBranchScheduleList,
} from "../../../../actions/branchScheduleActions";

//Component
import { BranchScheduleDetailPage } from "./BranchScheduleDetailPage";
import { EditBranchSchedulePage } from "./EditBranchSchedulePage";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";

export default function BranchScheduleListTable({ setInformationView }) {
  //variables
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const curUser = useSelector((state) => state.curUser);
  const branchSchedule = useSelector((state) => state.branchSchedule);

  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_BRANCH_SCHEDULE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Description"),
      accessor: "desc",
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_branch_desc_value: null,
    search_branch_id_value: null,
    search_branch_name_value: null,
    search_desc_value: null,
    search_name_value: null,
  });

  //Init
  const [editView, setEditView] = React.useState(false);
  const [detailView, setDetailView] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [branchId, setBranchId] = React.useState(null);

  //Onclick event
  const handleRowEdit = (value) => {
    dispatch(
      getBranchScheduleList.pending({
        is_pagination: false,
        order_by_direction: null,
        order_by_field: null,
        page_number: null,
        page_size: null,
        search_branch_desc_value: null,
        search_branch_id_value: value.id,
        search_branch_name_value: null,
        search_desc_value: null,
        search_name_value: null,
      })
    );
    setBranchId(value.id);
    setEditView(true);
  };

  const handleRowDetail = (value) => {
    dispatch(
      getBranchScheduleList.pending({
        is_pagination: false,
        order_by_direction: null,
        order_by_field: null,
        page_number: null,
        page_size: null,
        search_branch_desc_value: null,
        search_branch_id_value: value.id,
        search_branch_name_value: null,
        search_desc_value: null,
        search_name_value: null,
      })
    );
    setDetailView(true);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_name_value: value,
        search_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
        search_desc_value: "",
      });
    }
  };

  //useEffects
  useEffect(() => {
    if (detailView === true || editView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [detailView, editView, setInformationView]);

  useEffect(() => {
    if (!detailView && !editView) {
      dispatch(getBranchScheduleBranchList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, detailView, editView]);

  if (
    branchSchedule.getBranchScheduleBranchListIsSuccess === false &&
    branchSchedule.isLoadingBranchScheduleBranchList === false
  ) {
    return (
      <Alert severity="error">
        {branchSchedule.error.branchScheduleBranchListError}
      </Alert>
    );
  } else if (!editView && !detailView) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name/ Description")}
        searchValue={searchValue}
        columns={columns}
        data={
          branchSchedule.branchScheduleBranchList?.branches !== undefined
            ? branchSchedule.branchScheduleBranchList?.branches
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          branchSchedule.branchScheduleBranchList
            ? branchSchedule.branchScheduleBranchList.total_pages
            : 0
        }
        loading={branchSchedule.isLoadingBranchScheduleBranchList}
        totalItems={
          branchSchedule.branchScheduleBranchList
            ? branchSchedule.branchScheduleBranchList.total_items
            : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (
    branchSchedule.branchScheduleList &&
    detailView &&
    branchSchedule.getBranchScheduleListIsSuccess === true
  ) {
    return (
      <BranchScheduleDetailPage
        detailData={
          branchSchedule.branchScheduleList
            ? branchSchedule.branchScheduleList.branch_schedules
            : []
        }
        setDetailView={setDetailView}
      />
    );
  } else if (
    branchSchedule.branchScheduleList &&
    editView &&
    branchSchedule.getBranchScheduleListIsSuccess === true
  ) {
    return (
      <EditBranchSchedulePage
        editData={
          branchSchedule.branchScheduleList
            ? branchSchedule.branchScheduleList.branch_schedules
            : []
        }
        branchId={branchId}
        setEditView={setEditView}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
