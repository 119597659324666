import React, { useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import allowOnlyNumericsOrDigits from "../../../../../components/functions/allowOnlyNumericsOrDigits";
import { useStyles } from "../../../../../components/globalStyles";

export default function GroupAcupunctureInfo({
  viewOnly = false,
  memoSelectedAcupunctureGroup,
  handleAcupunctureNumInfo,
  acupointList,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [needleQty, setNeedleQty] = React.useState(0);

  useEffect(() => {
    const temNeedleQuantity = acupointList.reduce((sum, li) => {
      if (li.needle_quantity) {
        return parseFloat((sum + li.needle_quantity).toFixed(0));
      }
      return parseFloat(sum.toFixed(0));
    }, 0);

    setNeedleQty(temNeedleQuantity);
  }, [acupointList]);

  return (
    <>
      <Grid item xs={4} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          type="number"
          onWheel={(event) => event.target.blur()}
          InputProps={{
            inputProps: {
              min: 0,
              step: "any",
              style: { textAlign: "right" },
            },
          }}
          onKeyDown={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E"
            ) {
              event.preventDefault();
            }
          }}
          value={
            memoSelectedAcupunctureGroup &&
            memoSelectedAcupunctureGroup.price !== null
              ? memoSelectedAcupunctureGroup.price
              : ""
          }
          onChange={(e) => handleAcupunctureNumInfo(e)}
          id="price"
          onFocus={(event) => {
            event.target.select();
          }}
          name="price"
          label={t("translation:Fee")}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4} className={classes.userInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          type="number"
          onWheel={(event) => event.target.blur()}
          InputProps={{
            inputProps: {
              min: 0,
              step: "any",
              style: { textAlign: "right" },
            },
          }}
          onKeyDown={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E"
            ) {
              event.preventDefault();
            }
          }}
          value={
            memoSelectedAcupunctureGroup &&
            memoSelectedAcupunctureGroup.duration !== null
              ? memoSelectedAcupunctureGroup.duration
              : ""
          }
          onInput={allowOnlyNumericsOrDigits}
          onChange={(e) => handleAcupunctureNumInfo(e)}
          id="duration"
          name="duration"
          label={t("translation:Duration (mins)")}
          variant="outlined"
          onFocus={(event) => {
            event.target.select();
          }}
        />
      </Grid>

      <Grid item xs={4} className={classes.userInfoField}>
        <TextField
          className={classes.userFieldColor}
          disabled={true}
          value={needleQty}
          type="text"
          id="totalNeedles"
          name="totalNeedles"
          label={t("translation:Total Needles")}
          variant="outlined"
          inputProps={{
            style: { textAlign: "right" },
          }}
          InputLabelProps={{
            shrink: true,

            style: {
              fontWeight: "bold",
              color: "rgba(149, 0, 0,0.75)",
            },
          }}
        />
      </Grid>

      <Grid item xs={4} className={classes.userInfoField}>
        <TextField
          className={classes.userFieldColor}
          disabled={true}
          value={Object.keys(acupointList).length}
          type="text"
          onChange={(e) => handleAcupunctureNumInfo(e)}
          id="totalItems"
          name="totalItems"
          label={t("translation:Total Items")}
          variant="outlined"
          inputProps={{
            style: { textAlign: "right" },
          }}
          InputLabelProps={{
            shrink: true,

            style: {
              fontWeight: "bold",
              color: "rgba(149, 0, 0,0.75)",
            },
          }}
        />
      </Grid>
    </>
  );
}
