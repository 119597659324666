import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAcupointList,
  getAcupointById,
  postCreateAcupoint,
  putUpdateAcupoint,
} from "../actions/acupointActions";
import * as types from "../_constants/acupointConstants";
import * as notification from "../_constants/notificationConstants";
import acupointApis from "../_apis/acupointApis";
export default function* acupointSagas() {
  yield all([
    takeLatest(types.GET_ACUPOINTLIST_ASYNC.PENDING, getAcupointListCall),
  ]);
  yield all([
    takeLatest(types.GET_ACUPOINTBYID_ASYNC.PENDING, getAcupointByIdCall),
  ]);
  yield all([
    takeLatest(types.POST_CREATEACUPOINT_ASYNC.PENDING, postCreateAcupointCall),
  ]);
  yield all([
    takeLatest(types.PUT_UPDATEACUPOINT_ASYNC.PENDING, putUpdateAcupointCall),
  ]);
}

function* getAcupointListCall(searchModel) {
  try {
    const data = yield call(acupointApis.getAcupointListApi, searchModel.data);
    yield put(getAcupointList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAcupointList.error(error.response?.data?.message));
    else yield put(getAcupointList.error(notification.CONST_NOINTERNET));
  }
}

function* getAcupointByIdCall(searchModel) {
  try {
    const data = yield call(acupointApis.getAcupointByIdApi, searchModel.data);
    yield put(getAcupointById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAcupointById.error(error.response?.data?.message));
    else yield put(getAcupointById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateAcupointCall(searchModel) {
  try {
    const data = yield call(
      acupointApis.postCreateAcupointApi,
      searchModel.data
    );
    yield put(postCreateAcupoint.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateAcupoint.error(error.response?.data?.message));
    else yield put(postCreateAcupoint.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateAcupointCall(searchModel) {
  try {
    const data = yield call(
      acupointApis.putUpdateAcupointApi,
      searchModel.data
    );
    yield put(putUpdateAcupoint.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateAcupoint.error(error.response?.data?.message));
    else yield put(putUpdateAcupoint.error(notification.CONST_NOINTERNET));
  }
}
