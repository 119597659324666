//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, TextField } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";

import { useTranslation } from "react-i18next";
//actions

import {
  getBranchPhysicianScheduleList,
  getBranchPhysicianSchedulePhysicianList,
} from "../../../../actions/branchPhysicianScheduleActions";
//components
import OpeningHourPanel from "../components/OpeningHourPanel";
import RequiredNote from "../../../../components/RequiredNote";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import Panel from "../../../../components/Panel";
import { useStyles } from "../../../../components/globalStyles";
import * as generalConstants from "../../../../_constants/generalConstants";

export const BranchPhysicianScheduleDetailPage = ({
  branchId,
  setDetailView,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const branchPhysicianSchedule = useSelector(
    (state) => state.branchPhysicianSchedule
  );

  const [dayweek, setDayweek] = useState(null);
  const [physician, setPhysician] = useState(null);

  const [scheduleHour, setScheduleHour] = useState(null);
  const prevBranchPhysicianScheduleStatus = useRef();
  const prevBranchPhysicianScheduleStatusById = useRef();
  const dispatch = useDispatch();

  const [endTimeError, setEndTimeError] = useState([]);

  //functions

  const handleOnChangePhysician = (value) => {
    setScheduleHour(null);
    setPhysician(value);
    dispatch(
      getBranchPhysicianScheduleList.pending({
        is_pagination: false,
        order_by_direction: null,
        order_by_field: null,
        page_number: null,
        page_size: null,
        search_branch_desc_value: null,
        search_branch_id_value: branchId,
        search_branch_name_value: null,
        search_physician_id_value: value.user.id,
        search_physician_name_value: null,
        search_desc_value: null,
        search_name_value: null,
      })
    );
  };

  useEffect(() => {
    //set ref when first render
    prevBranchPhysicianScheduleStatus.current = false;
    prevBranchPhysicianScheduleStatusById.current = false;
  }, []);

  useEffect(() => {
    dispatch(
      getBranchPhysicianSchedulePhysicianList.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      branchPhysicianSchedule.isLoadingBranchPhysicianScheduleList !==
      prevBranchPhysicianScheduleStatus.current
    ) {
      //check the previous ref with current state
      prevBranchPhysicianScheduleStatus.current =
        branchPhysicianSchedule.isLoadingBranchPhysicianScheduleList;

      if (
        branchPhysicianSchedule.getBranchPhysicianScheduleListIsSuccess ===
          true &&
        branchPhysicianSchedule.isLoadingBranchPhysicianScheduleList === false
      ) {
        setScheduleHour(
          branchPhysicianSchedule.branchPhysicianScheduleList
            .branch_physician_schedules
        );
      }
      if (
        branchPhysicianSchedule.getBranchPhysicianScheduleListIsSuccess ===
          false &&
        branchPhysicianSchedule.isLoadingBranchPhysicianScheduleList === false
      ) {
        setScheduleHour([]);
      }
    }
  }, [branchPhysicianSchedule]);

  return (
    <Panel heading={t("translation:Branch Physician Schedule Details")}>
      {branchPhysicianSchedule.getBranchPhysicianSchedulePhysicianListIsSuccess && (
        <Grid xs={12} item className={classes.userInfoField}>
          <Autocomplete
            name="Physician"
            disableClearable
            value={physician}
            options={
              branchPhysicianSchedule.branchPhysicianSchedulePhysicianList &&
              Object.keys(
                branchPhysicianSchedule.branchPhysicianSchedulePhysicianList
                  .organisation_physician
              ).length !== 0
                ? branchPhysicianSchedule.branchPhysicianSchedulePhysicianList.organisation_physician.sort(
                    (a, b) => -b.user.name.localeCompare(a.user.name)
                  )
                : []
            }
            getOptionLabel={(option) =>
              option.user.name ? option.user.name : ""
            }
            isOptionEqualToValue={(option, value) => {
              if (option.id === value.id) return option;
            }}
            style={{ width: "100%" }}
            onChange={(e, option) => {
              handleOnChangePhysician(option);
            }}
            renderInput={(params) => (
              <TextField
                required
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Physician")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )}
      <br />

      {scheduleHour && (
        <>
          <OpeningHourPanel
            viewMode={true}
            scheduleHour={scheduleHour}
            setScheduleHour={setScheduleHour}
            dayweek={dayweek}
            setDayweek={setDayweek}
            endTimeError={endTimeError}
            setEndTimeError={setEndTimeError}
          />
        </>
      )}

      <Grid className={classes.root} container>
        <RequiredNote />
        <PageOnlyBackAction setView={() => setDetailView(false)} />
      </Grid>
    </Panel>
  );
};
