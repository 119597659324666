//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import StyledCheckbox from "../../../../components/StyledCheckbox";
import { useTranslation } from "react-i18next";
import * as generalConstants from "../../../../_constants/generalConstants";
//actions

import { putEditRoleRight } from "../../../../actions/roleRightActions";

//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import { useStyles } from "../../../../components/globalTableStyles";

//global functions

function not(a, b) {
  return a.filter(
    (item1) =>
      !b.find((item2) => {
        return (
          item1.permission_type === item2.permission_type &&
          item1.rights_type === item2.rights_type &&
          item1.view_needs_type === item2.view_needs_type
        );
      })
  );
}

function intersectionOnlyRightPermission(a, b) {
  return a.map((item1) => {
    const notItem2 = b.find((item2) => {
      return (
        item1.permission_type === item2.permission_type &&
        item1.rights_type === item2.rights_type
      );
    });

    if (notItem2)
      return {
        ...item1,
        id: notItem2.id,
      };
    else {
      return { ...item1 };
    }
  });
}

function notOnlyRightPermission(a, b) {
  return a.filter(
    (item1) =>
      !b.find((item2) => {
        return (
          item1.permission_type === item2.permission_type &&
          item1.rights_type === item2.rights_type
        );
      })
  );
}

function intersection(a, b) {
  return a.filter((item1) =>
    b.find((item2) => {
      return (
        item1.permission_type === item2.permission_type &&
        item1.rights_type === item2.rights_type &&
        item1.view_needs_type === item2.view_needs_type
      );
    })
  );
}

// function union(a, b) {
//   return [...a, ...not(b, a)];
// }

export const EditRoleRightPage = ({
  roleRight,
  common,
  setEditRoleRightView,
}) => {
  //variables
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const permissionList = common.permissionList.permission;
  const rightList = common.rightList.rights;

  const viewNeedsList = common.viewNeedsList.view_needs;

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");

  const prevRoleRightStatus = useRef();
  const roleRightData = useSelector((state) => state.roleRight);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { role_rights: roleRight.role_rights },
  });
  const [checkedValues, setCheckedValues] = useState(roleRight.role_rights);

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  //functions
  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const excRole = not(roleRight.role_rights, data.role_rights);
    const interRoleRight = intersection(
      roleRight.role_rights,
      data.role_rights
    );
    //delected role right
    const delCurRoleRight = excRole.map((roleRight) => {
      return {
        id: roleRight.id,
        permission_type: roleRight.permission_type,
        rights_type: roleRight.rights_type,
        view_needs_type: roleRight.view_needs_type,
        status_type: generalConstants.DELETED_STATUS_TYPE,
      };
    });

    //remaining role right
    const selectedCurRoleRight = interRoleRight.map((roleRight) => {
      return {
        id: roleRight.id,
        permission_type: roleRight.permission_type,
        rights_type: roleRight.rights_type,
        view_needs_type: roleRight.view_needs_type,
        status_type: roleRight.status_type,
      };
    });

    //new role right
    const newRoleRight = not(data.role_rights, selectedCurRoleRight);

    //duplicateID

    const duplicateDeleteWoNewId = delCurRoleRight.filter(
      (item1) => item1.id !== 0
    );

    const duplicateID = intersectionOnlyRightPermission(
      newRoleRight,
      duplicateDeleteWoNewId
    );

    const duplicateDelete = notOnlyRightPermission(
      duplicateDeleteWoNewId,
      newRoleRight
    );

    const finalRoles = {
      role: roleRight.role,
      role_rights: [
        ...duplicateID,
        ...duplicateDelete,
        ...selectedCurRoleRight,
      ],
    };
    //filter alliance oragnisation module
    finalRoles.role_rights = finalRoles.role_rights.filter((item1) =>
      rightList.find((item2) => {
        return item1.rights_type === item2.id;
      })
    );

    dispatch(putEditRoleRight.pending(finalRoles));
  };

  function handleSelect(permissionId, rightId) {
    const currentViewNeeds = checkedValues.find(
      (item2) => item2.rights_type === rightId
    );

    var checkedName = [
      {
        id: 0,
        permission_type: permissionId,
        rights_type: rightId,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
        view_needs_type: currentViewNeeds?.view_needs_type
          ? currentViewNeeds?.view_needs_type
          : generalConstants.OWN_VIEW_TYPE,
      },
    ];

    Array.from({ length: permissionId - 1 }, (x, i) => {
      const autoSelectField = {
        id: 0,
        permission_type: i + 1,
        rights_type: rightId,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
        view_needs_type: currentViewNeeds?.view_needs_type
          ? currentViewNeeds?.view_needs_type
          : generalConstants.OWN_VIEW_TYPE,
      };
      checkedName = [...checkedName, autoSelectField];
      return autoSelectField;
    });

    const selectedCheckedName = not(checkedName, checkedValues);

    const newNames = checkedValues?.find(
      (item2) =>
        item2.rights_type === rightId && item2.permission_type === permissionId
    )
      ? checkedValues.filter(
          ({ permission_type, rights_type }) => !(rights_type === rightId)
        )
      : [...(checkedValues ?? []), ...selectedCheckedName];

    setCheckedValues(newNames);

    return newNames;
  }

  function handleNeedSelect(needId, rightId) {
    const abc = checkedValues.map((item) => {
      if (item.rights_type === rightId) {
        return {
          id: item.id,
          permission_type: item.permission_type,
          rights_type: item.rights_type,
          status_type: generalConstants.APPROVED_STATUS_TYPE,
          view_needs_type: needId,
        };
      }

      return item;
    });

    setCheckedValues(abc);
    return abc;
  }

  //useEffects

  useEffect(() => {
    //set ref when first render

    prevRoleRightStatus.current = false;
  }, []);

  useEffect(() => {
    if (roleRightData.isLoadingEditRoleRight !== prevRoleRightStatus.current) {
      //check the previous ref with current state
      if (roleRightData.isLoadingEditRoleRight) {
        prevRoleRightStatus.current = roleRightData.isLoadingEditRoleRight;
      }

      if (
        roleRightData.putEditRoleRightIsSuccess === true &&
        roleRightData.isLoadingEditRoleRight === false
      ) {
        setCheckedValues(
          roleRightData.putEditRoleRight.role_rights.filter(
            (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
          )
        );
        reset({
          role_rights: roleRightData.putEditRoleRight.role_rights.filter(
            (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
          ),
        });
        setSuccessAlarm(true);
        setErrorAlarm(false);
        window.scrollTo(0, 0);
      }
      if (
        roleRightData.putEditRoleRightIsSuccess === false &&
        roleRightData.isLoadingEditRoleRight === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(roleRightData.error.EditRoleRightError);
        window.scrollTo(0, 0);
      }
    }
  }, [roleRightData, reset]);

  return (
    <Panel heading={roleRight.role.name}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Role rights has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <MaUTable>
                <TableHead className={classes.headStyle}>
                  <TableRow key="headerRow">
                    <TableCell key="actionHeader"></TableCell>
                    {permissionList.map((headerGroup) => (
                      <TableCell
                        key={headerGroup.name}
                        className={classes.headCellStyle}>
                        {headerGroup.name}
                        &nbsp; &nbsp; &nbsp;
                      </TableCell>
                    ))}

                    {viewNeedsList.map((headerGroup) => (
                      <TableCell
                        key={headerGroup.name}
                        className={classes.headCellStyle}>
                        {headerGroup.name}
                        &nbsp; &nbsp; &nbsp;
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rightList.map((right, i) => (
                    <TableRow
                      key={right.name + i + "row"}
                      className={i % 2 ? classes.rowBackground : ""}>
                      <TableCell key={right.name + i}>{right.name}</TableCell>

                      {permissionList.map((permission) => (
                        <TableCell key={permission.name + i}>
                          <FormControlLabel
                            control={
                              <Controller
                                name="role_rights"
                                render={({ field }) => {
                                  return (
                                    <StyledCheckbox
                                      checked={
                                        checkedValues.find(
                                          (item2) =>
                                            item2.rights_type === right.id &&
                                            item2.permission_type ===
                                              permission.id
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        field.onChange(
                                          handleSelect(permission.id, right.id)
                                        )
                                      }
                                    />
                                  );
                                }}
                                control={control}
                              />
                            }
                            key={permission.id}
                          />
                        </TableCell>
                      ))}

                      {viewNeedsList.map((need) => (
                        <TableCell key={need.name + i}>
                          <FormControlLabel
                            control={
                              <Controller
                                name="role_rights"
                                render={({ field }) => {
                                  return (
                                    <StyledCheckbox
                                      disabled={
                                        checkedValues.find(
                                          (item2) =>
                                            item2.rights_type === right.id
                                        )
                                          ? false
                                          : true
                                      }
                                      checked={
                                        checkedValues.find(
                                          (item2) =>
                                            item2.rights_type === right.id &&
                                            item2.view_needs_type === need.id
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        field.onChange(
                                          handleNeedSelect(need.id, right.id)
                                        )
                                      }
                                    />
                                  );
                                }}
                                control={control}
                              />
                            }
                            key={need.id}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </MaUTable>
            </TableContainer>
            <br />
            <PageSubmitWBackAction
              setView={() => setEditRoleRightView(false)}
            />
          </Grid>
        </Grid>
      </form>
    </Panel>
  );
};
