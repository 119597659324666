//lib
import React from "react";
// import moment from "moment";
import Paper from "@mui/material/Paper";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { useStyles } from "../../../components/globalTableStyles";

//variables

export const StaffInfoTable = ({ staff, t }) => {
  const classes = useStyles();
  // const genderDisplay = (genderId) => {
  //   const cellValue = common.genderList.gender.find(
  //     (item) => item.id === genderId
  //   );
  //   return cellValue.name;
  // };

  // const options = {
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // };

  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable>
          <TableHead className={classes.headStyle}>
            <TableRow>
              <TableCell className={classes.userHeadCellStyle}>
                {t("translation:Staff Name")}
              </TableCell>

              <TableCell className={classes.userHeadCellStyle}>
                {t("translation:Contact Number")}
              </TableCell>

              <TableCell className={classes.userHeadCellStyle}>
                {t("translation:Address")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.userBodyCellStyle}>
                {staff.staff_name}
              </TableCell>
              <TableCell className={classes.userBodyCellStyle}>
                {staff.staff_contact_number}
              </TableCell>

              <TableCell className={classes.userBodyCellStyle}>
                {staff.staff_address}
              </TableCell>
            </TableRow>
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
};
