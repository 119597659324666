import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getFormulaList,
  getFormulaById,
  postCreateFormula,
  putUpdateFormula,
  getFormulaDispensaryBranch,
  getFormulaMedicineTypeById,
} from "../actions/formulaActions";
import * as types from "../_constants/formulaConstants";
import * as notification from "../_constants/notificationConstants";
import formulaApis from "../_apis/formulaApis";
export default function* formulaSagas() {
  yield all([
    takeLatest(types.GET_FORMULALIST_ASYNC.PENDING, getFormulaListCall),
  ]);
  yield all([
    takeLatest(types.GET_FORMULABYID_ASYNC.PENDING, getFormulaByIdCall),
  ]);
  yield all([
    takeLatest(types.POST_CREATEFORMULA_ASYNC.PENDING, postCreateFormulaCall),
  ]);
  yield all([
    takeLatest(types.PUT_UPDATEFORMULA_ASYNC.PENDING, putUpdateFormulaCall),
  ]);

  yield all([
    takeLatest(
      types.GET_FORMULADISPENSARYBRANCH_ASYNC.PENDING,
      getFormulaDispensaryBranchCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_FORMULAMEDICINETYPEBYID_ASYNC.PENDING,
      getFormulaMedicineTypeByIdCall
    ),
  ]);
}

function* getFormulaListCall(searchModel) {
  try {
    const data = yield call(formulaApis.getFormulaListApi, searchModel.data);
    yield put(getFormulaList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getFormulaList.error(error.response?.data?.message));
    else yield put(getFormulaList.error(notification.CONST_NOINTERNET));
  }
}

function* getFormulaByIdCall(searchModel) {
  try {
    const data = yield call(formulaApis.getFormulaByIdApi, searchModel.data);
    yield put(getFormulaById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getFormulaById.error(error.response?.data?.message));
    else yield put(getFormulaById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateFormulaCall(searchModel) {
  try {
    const data = yield call(formulaApis.postCreateFormulaApi, searchModel.data);
    yield put(postCreateFormula.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateFormula.error(error.response?.data?.message));
    else yield put(postCreateFormula.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateFormulaCall(searchModel) {
  try {
    const data = yield call(formulaApis.putUpdateFormulaApi, searchModel.data);
    yield put(putUpdateFormula.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateFormula.error(error.response?.data?.message));
    else yield put(putUpdateFormula.error(notification.CONST_NOINTERNET));
  }
}

function* getFormulaDispensaryBranchCall(searchModel) {
  try {
    const data = yield call(
      formulaApis.getFormulaDispensaryBranchApi,
      searchModel.data
    );
    yield put(getFormulaDispensaryBranch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getFormulaDispensaryBranch.error(error.response?.data?.message)
      );
    else
      yield put(
        getFormulaDispensaryBranch.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getFormulaMedicineTypeByIdCall(searchModel) {
  try {
    const data = yield call(
      formulaApis.getFormulaMedicineTypeByIdApi,
      searchModel.data
    );
    yield put(getFormulaMedicineTypeById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getFormulaMedicineTypeById.error(error.response?.data?.message)
      );
    else
      yield put(
        getFormulaMedicineTypeById.error(notification.CONST_NOINTERNET)
      );
  }
}
