import organisationPriceRoundingWGst from "../../../../../components/functions/organisationPriceRoundingWGst";
import * as generalConstants from "../../../../../_constants/generalConstants";
const submisionJSON = (
  user,
  currentOrganistion,
  price,
  groupOption,
  costingHeader,
  desc
) => {
  const organisationPriceRoundingWGsts = organisationPriceRoundingWGst(
    Number(price.medicineTab) +
      Number(price.deliveryFee) +
      Number(price.brewingFee) +
      Number(price.vendorGstAmt),
    currentOrganistion
  );
  const submission = {
    store_header: {
      id: 0,
      ...organisationPriceRoundingWGsts,
      desc: desc,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
      medicine_total_price: Number(price.medicineTab),
      medicine_price_for_costing: Number(price.medicineFee),
      brewing_total_price_for_costing: Number(price.brewingFee),
      vendor_total_gst_amount_for_costing: Number(price.vendorGstAmt),
      delivery_total_price_for_costing: Number(price.deliveryFee),
      is_consultation_price_included: false,
      staff: {
        id: user.id,
        status_type: 1,
      },
      store_medicine_headers: groupOption,
      store_medicine_costing_headers: costingHeader,
    },
  };
  return submission;
};

export default submisionJSON;
