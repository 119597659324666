import * as types from "../_constants/branchPhysicianScheduleConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_BRANCHPHYSICIANSCHEDULELIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchPhysicianScheduleListIsSuccess: false,
        isLoadingBranchPhysicianScheduleList: true,
        branchPhysicianScheduleList: null,
      };

    case types.GET_BRANCHPHYSICIANSCHEDULELIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchPhysicianScheduleListIsSuccess: false,
        isLoadingBranchPhysicianScheduleList: false,
        branchPhysicianScheduleList: null,
        error: {
          ...state.error,
          branchPhysicianScheduleListError: action.error,
        },
      };

    case types.GET_BRANCHPHYSICIANSCHEDULELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchPhysicianScheduleListIsSuccess: true,
        isLoadingBranchPhysicianScheduleList: false,
        branchPhysicianScheduleList: action.data,
      };

    case types.GET_BRANCHPHYSICIANSCHEDULEBYID_ASYNC.PENDING:
      return {
        ...state,
        getBranchPhysicianScheduleByIdIsSuccess: false,
        isLoadingBranchPhysicianScheduleById: true,
        branchPhysicianScheduleById: null,
      };

    case types.GET_BRANCHPHYSICIANSCHEDULEBYID_ASYNC.ERROR:
      return {
        ...state,
        getBranchPhysicianScheduleByIdIsSuccess: false,
        isLoadingBranchPhysicianScheduleById: false,
        branchPhysicianScheduleById: null,
        error: {
          ...state.error,
          branchPhysicianScheduleByIdError: action.error,
        },
      };

    case types.GET_BRANCHPHYSICIANSCHEDULEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchPhysicianScheduleByIdIsSuccess: true,
        isLoadingBranchPhysicianScheduleById: false,
        branchPhysicianScheduleById: action.data,
      };

    case types.POST_BRANCHPHYSICIANSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC.PENDING:
      return {
        ...state,
        postBranchPhysicianScheduleByBranchIdIsSuccess: false,
        isLoadingPostBranchPhysicianScheduleByBranchId: true,
        postBranchPhysicianScheduleByBranchId: null,
      };

    case types.POST_BRANCHPHYSICIANSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC.ERROR:
      return {
        ...state,
        postBranchPhysicianScheduleByBranchIdIsSuccess: false,
        isLoadingPostBranchPhysicianScheduleByBranchId: false,
        postBranchPhysicianScheduleByBranchId: null,
        error: {
          ...state.error,
          postBranchPhysicianScheduleByBranchIdError: action.error,
        },
      };

    case types.POST_BRANCHPHYSICIANSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchPhysicianScheduleByBranchIdIsSuccess: true,
        isLoadingPostBranchPhysicianScheduleByBranchId: false,
        postBranchPhysicianScheduleByBranchId: action.data,
      };

    case types.GET_BRANCHPHYSICIANSCHEDULEBRANCHLIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchPhysicianScheduleBranchListIsSuccess: false,
        isLoadingBranchPhysicianScheduleBranchList: true,
        branchPhysicianScheduleBranchList: null,
      };

    case types.GET_BRANCHPHYSICIANSCHEDULEBRANCHLIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchPhysicianScheduleBranchListIsSuccess: false,
        isLoadingBranchPhysicianScheduleBranchList: false,
        branchPhysicianScheduleBranchList: null,
        error: {
          ...state.error,
          branchPhysicianScheduleBranchListError: action.error,
        },
      };

    case types.GET_BRANCHPHYSICIANSCHEDULEBRANCHLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchPhysicianScheduleBranchListIsSuccess: true,
        isLoadingBranchPhysicianScheduleBranchList: false,
        branchPhysicianScheduleBranchList: action.data,
      };

    case types.GET_BRANCHPHYSICIANSCHEDULEPHYSICIANLIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchPhysicianSchedulePhysicianListIsSuccess: false,
        isLoadingBranchPhysicianSchedulePhysicianList: true,
        branchPhysicianSchedulePhysicianList: null,
      };

    case types.GET_BRANCHPHYSICIANSCHEDULEPHYSICIANLIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchPhysicianSchedulePhysicianListIsSuccess: false,
        isLoadingBranchPhysicianSchedulePhysicianList: false,
        branchPhysicianSchedulePhysicianList: null,
        error: {
          ...state.error,
          branchPhysicianSchedulePhysicianListError: action.error,
        },
      };

    case types.GET_BRANCHPHYSICIANSCHEDULEPHYSICIANLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchPhysicianSchedulePhysicianListIsSuccess: true,
        isLoadingBranchPhysicianSchedulePhysicianList: false,
        branchPhysicianSchedulePhysicianList: action.data,
      };

    default:
      return state;
  }
};
