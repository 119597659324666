//lib
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import Close from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

//components
import { useStyles } from "../../../../components/globalStyles";
import * as generalConstants from "../../../../_constants/generalConstants";
//action

import { postCreatePresignedUrl } from "../../../../actions/cloudStorageActions";

export default function DownloadAttachment({
  organisationCode = "default",
  folderPath = "delivery_tracking_attachment",
  uploadFileObjects = [],
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const cloudStorage = useSelector((state) => state.cloudStorage);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const prevCreatePresignedUrlStatus = useRef();
  const [msgAlarm, setMsgAlarm] = React.useState(null);
  const [alertMsg, setAlertMsg] = React.useState(false);
  const [alertType, setAlertType] = React.useState(null);
  const [downloadName, setDownloadName] = React.useState(null);
  const downloadFileOnHandle = (item) => {
    setAlertMsg(false);
    setMsgAlarm(null);
    setAlertType(null);
    const organisationCodePath = organisationCode + "/" + folderPath + "/";

    const extensionIndex = item.url.lastIndexOf(organisationCodePath);
    const objectName = item.url.substring(extensionIndex);
    setDownloadName(item.original_filename);
    const submitJson = {
      object_name: objectName,
    };

    dispatch(postCreatePresignedUrl.pending(submitJson));
  };

  useEffect(() => {
    prevCreatePresignedUrlStatus.current = false;
  }, []);

  useEffect(() => {
    if (!open) {
      setAlertMsg(false);
      setMsgAlarm(null);
      setAlertType(null);
      setDownloadName(null);
    }
  }, [open]);
  useEffect(() => {
    if (
      cloudStorage.isLoadingPostCreatePresignedUrl !==
      prevCreatePresignedUrlStatus.current
    ) {
      //check the previous ref with current state
      prevCreatePresignedUrlStatus.current =
        cloudStorage.isLoadingPostCreatePresignedUrl;

      if (
        cloudStorage.postCreatePresignedUrlIsSuccess === true &&
        cloudStorage.isLoadingPostCreatePresignedUrl === false
      ) {
        // window.open(cloudStorage.postCreatePresignedUrl, "_blank");

        if (downloadName !== null) {
          fetch(cloudStorage.postCreatePresignedUrl)
            .then((res) => {
              if (res.status === 200) {
                return res.blob();
              } else {
                throw new Error(res.statusText);
              }
            })

            .then((blob) => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = url;
              a.download = downloadName;
              document.body.appendChild(a);
              a.click();
              setTimeout((_) => {
                window.URL.revokeObjectURL(url);
              }, 60000);
              a.remove();
              setDownloadName(null);
              setMsgAlarm(t("translation:File has been downloaded"));
              setAlertMsg(true);
              setAlertType("success");
            })
            .catch((err) => {
              setMsgAlarm(err.message);
              setAlertMsg(true);
              setAlertType("error");
            });
        }
      }
      if (
        cloudStorage.postCreatePresignedUrlIsSuccess === false &&
        cloudStorage.isLoadingPostCreatePresignedUrl === false
      ) {
        setMsgAlarm(cloudStorage.error.postCreatePresignedUrlError);
        setAlertMsg(true);
        setAlertType("error");
      }
    }
  }, [cloudStorage, t, downloadName]);
  return (
    <>
      <Link className={classes.boldMaroon075}>
        <Button
          className={classes.boldMaroon075}
          name="downloadButton"
          onClick={() => setOpen(true)}>
          {t("translation:DOWNLOAD ATTACHMENTS")}
        </Button>
      </Link>
      <Dialog
        fullWidth
        open={open}
        maxWidth="lg"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpen(false);
          }
        }}
        aria-labelledby="confirm-dialog">
        <Close
          style={{ position: "absolute", right: "15px" }}
          onClick={() => setOpen(false)}
        />
        {alertType !== null && alertMsg !== null && (
          <Collapse in={alertMsg}>
            <Alert severity={alertType}>{msgAlarm}</Alert>
          </Collapse>
        )}
        <DialogTitle id="confirm-dialog">
          {t("translation:Download")}
        </DialogTitle>

        {uploadFileObjects.length > 0 && (
          <DialogContent>
            {uploadFileObjects.map((item, index) => {
              if (item.status_type === generalConstants.APPROVED_STATUS_TYPE)
                return (
                  <span key={index}>
                    <Chip
                      label={item.original_filename}
                      clickable
                      onClick={() => {
                        downloadFileOnHandle(item);
                      }}
                    />
                  </span>
                );
              return <span key={index}></span>;
            })}
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}
