import React from "react";
import { useStyles } from "./globalStyles";
import { CardContent, CardHeader } from "@mui/material";
import { Paper } from "@mui/material";

const Panel = ({ heading, children, cy_data = {} }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.panelCard} elevation={3}>
      {heading && (
        <CardHeader title={heading} className={classes.panelHeading} />
      )}
      <CardContent cy_data={cy_data}>{children}</CardContent>
    </Paper>
  );
};

export default Panel;
