import { createAsyncTypes } from "../_helper/Utilities";

export const GET_NONBUSINESSDAYLIST_ASYNC = createAsyncTypes(
  "GET_NONBUSINESSDAYLIST"
);

export const GET_NONBUSINESSDAYBYID_ASYNC = createAsyncTypes(
  "GET_NONBUSINESSDAYBYID"
);

export const POST_CREATENONBUSINESSDAY_ASYNC = createAsyncTypes(
  "POST_CREATENONBUSINESSDAY"
);

export const PUT_UPDATENONBUSINESSDAY_ASYNC = createAsyncTypes(
  "PUT_UPDATENONBUSINESSDAY"
);

export const POST_SEARCHNONBUSINESSDAYBRANCH_ASYNC = createAsyncTypes(
  "POST_SEARCHNONBUSINESSDAYBRANCH"
);
