import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import EmailStoreSenderSummaryPage from "./EmailStoreSenderSummary/EmailStoreSenderSummaryPage";
export default function EmailStoreSenderPage() {
  return (
    <Switch>
      <Route
        path="/emailStoreSender/emailStoreSenderSummary"
        component={EmailStoreSenderSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
