import { createAsyncTypes } from "../_helper/Utilities";

export const GET_APPOINTMENTLIST_ASYNC = createAsyncTypes(
  "GET_APPOINTMENTLIST"
);

export const POST_SUBMITAPPOINTMENT_ASYNC = createAsyncTypes(
  "POST_SUBMITAPPOINTMENT"
);

export const POST_APPOINTMENTPHYSICIANS_ASYNC = createAsyncTypes(
  "POST_APPOINTMENTPHYSICIANS"
);

export const GET_APPOINTMENTBRANCH_ASYNC = createAsyncTypes(
  "GET_APPOINTMENTBRANCH"
);

export const GET_APPOINTMENTTIMESLOTLIST_ASYNC = createAsyncTypes(
  "GET_APPOINTMENTTIMESLOTLIST"
);

export const GET_APPOINTMENTBRANCHPHYSICIAN_ASYNC = createAsyncTypes(
  "GET_APPOINTMENTBRANCHPHYSICIAN"
);

export const GET_APPOINTMENTUSERLIST_ASYNC = createAsyncTypes(
  "GET_APPOINTMENTUSERLIST"
);

export const GET_APPOINTMENTTEMPUSERLIST_ASYNC = createAsyncTypes(
  "GET_APPOINTMENTTEMPUSERLIST"
);
