import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/externalApiConstants";

export const getSingaporeHolidayList = {
  pending: (data) =>
    createAction(types.GET_SINGAPOREHOLIDAYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_SINGAPOREHOLIDAYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_SINGAPOREHOLIDAYLIST_ASYNC.ERROR, { error }),
};

export const getSingaporeYearlyHoliday = {
  pending: (data) =>
    createAction(types.GET_SINGAPOREYEARLYHOLIDAY_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_SINGAPOREYEARLYHOLIDAY_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_SINGAPOREYEARLYHOLIDAY_ASYNC.ERROR, { error }),
};
