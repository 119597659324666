//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@mui/material";
//actions
import {
  putPackingWIPMode,
  getPackingSummaryList,
  putPackingPendingStatus,
  postSubmitPacking,
  getPackingById,
  putPackingCloseMode,
} from "../../../actions/packingActions";
//components
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import ConfirmDialog from "../../../components/ConfirmDialog";
//import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import { ReactComponent as ProceedLogo } from "../../../assets/coolicons/media/fast_forward.svg";
import { ReactComponent as PendingLogo } from "../../../assets/coolicons/media/fast_rewind.svg";
import { ReactComponent as ProceedAllLogo } from "../../../assets/coolicons/media/skip_next.svg";
import OrderDetailPage from "./OrderDetailPage";
import * as pageConfigure from "../../../_constants/pageConstants";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import * as generalConstants from "../../../_constants/generalConstants";

import { getTableDefaultLocalStorage } from "../../../components/functions/localStorage";

export default function PackingListTable({
  setPackingInformationView = () => null,
  setRefreshTimeStamp = () => null,
  refreshTimeStamp = null,
  dashboardView = false,
}) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const localStorageNameKey = "packingListTable";
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_PACKING_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;

  // const options = {
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // };
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Store Order No"),
      accessor: "store_header.order_number",
      sortable: false,
    },
    {
      Header: t("translation:Group"),
      accessor: "store_medicine_header.name",
    },
    {
      Header: t("translation:Physician"),
      accessor: "store_header.physician_name",
    },
    {
      Header: t("translation:Staff Name"),
      accessor: "store_header.staff_name",
    },
    {
      Header: t("translation:Order Description"),
      accessor: "store_header.desc",
    },
    {
      Header: t("translation:Physician/ Staff Contact Number"),
      sortable: false,
      accessor: "store_header.staff_contact_number",
      Cell: ({ row }) => {
        if (
          row.original.store_header.work_order_type ===
          generalConstants.VISIT_WORK_ORDER_TYPE
        )
          return row.original.store_header.physician_contact_number;
        if (
          row.original.store_header.work_order_type ===
          generalConstants.STORE_WORK_ORDER_TYPE
        )
          return row.original.store_header.staff_contact_number;
        return "";
      },
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Proceed Date"),
      sortable: false,
      accessor: "store_medicine_header.proceed_on",
      Cell: ({ row }) => {
        if (row.original.store_medicine_header.proceed_on)
          return moment
            .utc(row.original.store_medicine_header.proceed_on)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const columnsWUpdateAccess = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "statusAction",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.PENDING_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowProcess(row.original)}>
              <ProceedLogo title={t("translation:Proceed")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: "",
      accessor: "Pending",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowPending(row.original)}>
              <PendingLogo title={t("translation:Pending")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },

    {
      Header: "",
      accessor: "ProcessAll",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowProcessAll(row.original)}>
              <ProceedAllLogo title={t("translation:Close")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Store Order No"),
      accessor: "store_header.order_number",
      sortable: false,
    },
    {
      Header: t("translation:Group"),
      accessor: "store_medicine_header.name",
    },
    {
      Header: t("translation:Physician"),
      accessor: "store_header.physician_name",
    },
    {
      Header: t("translation:Staff Name"),
      accessor: "store_header.staff_name",
    },
    {
      Header: t("translation:Order Description"),

      accessor: "store_header.desc",
    },
    {
      Header: t("translation:Physician/ Staff Contact Number"),
      sortable: false,
      accessor: "store_header.staff_contact_number",
      Cell: ({ row }) => {
        if (
          row.original.store_header.work_order_type ===
          generalConstants.VISIT_WORK_ORDER_TYPE
        )
          return row.original.store_header.physician_contact_number;
        if (
          row.original.store_header.work_order_type ===
          generalConstants.STORE_WORK_ORDER_TYPE
        )
          return row.original.store_header.staff_contact_number;
        return "";
      },
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Proceed Date"),
      sortable: false,
      accessor: "store_medicine_header.proceed_on",
      Cell: ({ row }) => {
        if (row.original.store_medicine_header.proceed_on)
          return moment
            .utc(row.original.store_medicine_header.proceed_on)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    search_packing_order_number_value: null,
    search_store_order_number_value: null,
    search_status_type_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) =>
        size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
    search_staff_name_value: null,
    search_staff_contact_number_value: null,
    search_store_header_desc_value: null,
    order_by_field: null,
    order_by_direction: null,
    search_proceed_start_value: null,
    search_proceed_end_value: null,
  });

  const dispatch = useDispatch();
  const prevGetPackingByIdOnStatus = useRef();
  const packing = useSelector((state) => state.packing);
  const common = useSelector((state) => state.common);

  // const [editOrderInfo, setEditOrderInfo] = React.useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmPendingOpen, setConfirmPendingOpen] = useState(false);
  const [confirmProcessAllOpen, setConfirmProceeAllOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [orderDetailView, setOrderDetailView] = React.useState(false);
  const [isCompleteAll, setIsCompleteAll] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [orderDetailInfo, setOrderDetailInfo] = React.useState(null);
  const prevWorkOrderWIPStatus = useRef();
  const prevWorkOrderPendingStatus = useRef();
  const prevSubmitPackingStatus = useRef();
  const prevWorkOrderCloseStatus = useRef();
  const prevRefreshTimeStamp = useRef();
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = React.useState([]);
  const [WIPSuccessAlarm, setWIPSuccessAlarm] = useState(false);
  const [WIPErrorAlarm, setWIPErrorAlarm] = useState(false);
  const [WIPmsgAlarm, setWIPMsgAlarm] = React.useState([]);
  const [PendingSuccessAlarm, setPendingSuccessAlarm] = useState(false);
  const [PendingErrorAlarm, setPendingErrorAlarm] = useState(false);
  const [PendingmsgAlarm, setPendingMsgAlarm] = React.useState([]);

  //functions
  const handleRowProcess = (value) => {
    setConfirmOpen(true);
    setSelectedValue(value);
  };

  const handleRowPending = (value) => {
    setConfirmPendingOpen(true);
    setSelectedValue(value);
  };

  const handleRowProcessAll = (value) => {
    setConfirmProceeAllOpen(true);
    setSelectedValue(value);
  };

  const onSubmitPacking = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    setIsCompleteAll(true);
    dispatch(getPackingById.pending(selectedValue.id));

    // const packingHeader = {
    //   packing_header: {
    //     packing_details: [
    //       {
    //         id: 0,
    //       },
    //     ],
    //     id: selectedValue.id,
    //     order_number: selectedValue.order_number,
    //   },
    // };

    // dispatch(postSubmitPacking.pending(packingHeader));
  };

  const handleConfirmProcess = () => {
    dispatch(putPackingWIPMode.pending(selectedValue.id));
  };

  const handleConfirmPending = () => {
    dispatch(putPackingPendingStatus.pending(selectedValue.id));
  };

  const handleRowDetail = (value) => {
    //
    //setOrderDetailInfo(value);
    dispatch(getPackingById.pending(value.id));
    setOrderDetailView(true);
    setSubmitErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setWIPSuccessAlarm(false);
    setPendingErrorAlarm(false);
    setPendingSuccessAlarm(false);
    setIsCompleteAll(false);
  };

  //useEffects
  useEffect(() => {
    prevWorkOrderWIPStatus.current = false;
    prevWorkOrderPendingStatus.current = false;
    prevSubmitPackingStatus.current = false;
    prevGetPackingByIdOnStatus.current = false;
    prevRefreshTimeStamp.current = null;
  }, []);

  useEffect(() => {
    if (
      refreshTimeStamp !== prevRefreshTimeStamp.current &&
      dashboardView &&
      !orderDetailView
    ) {
      //check the previous ref with current state
      prevRefreshTimeStamp.current = refreshTimeStamp;
      dispatch(getPackingSummaryList.pending(pageInfo));
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView, orderDetailView]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp === prevRefreshTimeStamp.current) {
      //update the newest time after calling api
      prevRefreshTimeStamp.current = moment();
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  useEffect(() => {
    if (packing.isLoadingPackingById !== prevGetPackingByIdOnStatus.current) {
      //
      //check the previous ref with current state
      prevGetPackingByIdOnStatus.current = packing.isLoadingPackingById;

      if (
        packing.getPackingByIdSuccess === true &&
        packing.isLoadingPackingById === false
      ) {
        if (isCompleteAll) {
          const packingHeader = {
            packing_header: {
              packing_details: [
                {
                  id: 0,
                },
              ],
              id: selectedValue.id,
              is_order_completed: true,
              order_number: selectedValue.order_number,
            },
          };

          dispatch(postSubmitPacking.pending(packingHeader));
          setIsCompleteAll(false);
        } else {
          setOrderDetailInfo(packing.packingById.packing_header);
        }
      }
    }
  }, [isCompleteAll, packing, selectedValue, dispatch, setOrderDetailInfo]);

  useEffect(() => {
    if (
      packing.isLoadingPackingPendingStatus !==
      prevWorkOrderPendingStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderPendingStatus.current =
        packing.isLoadingPackingPendingStatus;

      if (
        packing.putPackingPendingStatusSuccess === true &&
        packing.isLoadingPackingPendingStatus === false
      ) {
        window.scrollTo(0, 0);
        setPendingErrorAlarm(false);
        setPendingSuccessAlarm(true);
        dispatch(getPackingSummaryList.pending(pageInfo));
      }

      if (
        packing.putPackingPendingStatusSuccess === false &&
        packing.isLoadingPackingPendingStatus === false
      ) {
        window.scrollTo(0, 0);
        setPendingErrorAlarm(true);
        setPendingSuccessAlarm(false);
        setPendingMsgAlarm(packing.error.packingPendingStatusError);
      }
    }
  }, [dispatch, packing, pageInfo]);

  useEffect(() => {
    if (packing.isLoadingPackingWIPMode !== prevWorkOrderWIPStatus.current) {
      //
      //check the previous ref with current state
      prevWorkOrderWIPStatus.current = packing.isLoadingPackingWIPMode;

      if (
        packing.putPackingWIPModeSuccess === true &&
        packing.isLoadingPackingWIPMode === false
      ) {
        window.scrollTo(0, 0);
        setWIPErrorAlarm(false);
        setWIPSuccessAlarm(true);
        dispatch(getPackingSummaryList.pending(pageInfo));
      }

      if (
        packing.putPackingWIPModeSuccess === false &&
        packing.isLoadingPackingWIPMode === false
      ) {
        window.scrollTo(0, 0);
        setWIPErrorAlarm(true);
        setWIPSuccessAlarm(false);
        setWIPMsgAlarm(packing.error.packingWIPModeError);
      }
    }
  }, [dispatch, packing, pageInfo]);
  //
  useEffect(() => {
    if (packing.isLoadingSubmitPacking !== prevSubmitPackingStatus.current) {
      //check the previous ref with current state
      prevSubmitPackingStatus.current = packing.isLoadingSubmitPacking;
      //
      if (
        packing.postSubmitPackingSuccess === true &&
        packing.isLoadingSubmitPacking === false
      ) {
        const packingHeader = {
          packing_header: {
            packing_details: [
              {
                id: 0,
              },
            ],
            id: selectedValue.id,
            is_order_completed: true,
            order_number: selectedValue.order_number,
          },
        };

        dispatch(putPackingCloseMode.pending(packingHeader));

        window.scrollTo(0, 0);
      }
      if (
        packing.postSubmitPackingSuccess === false &&
        packing.isLoadingSubmitPacking === false
      ) {
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(packing.error.submitPackingError);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, packing, pageInfo]);

  useEffect(() => {
    if (
      packing.isLoadingPackingCloseMode !== prevWorkOrderCloseStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderCloseStatus.current = packing.isLoadingPackingCloseMode;

      if (
        packing.putPackingCloseModeSuccess === true &&
        packing.isLoadingPackingCloseMode === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        dispatch(getPackingSummaryList.pending(pageInfo));
      }

      if (
        packing.putPackingCloseModeSuccess === false &&
        packing.isLoadingPackingCloseMode === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(packing.error.packingCloseModeError);
      }
    }
  }, [dispatch, packing, pageInfo]);

  useEffect(() => {
    if (orderDetailView === true) {
      setPackingInformationView(true);
    } else {
      setPackingInformationView(false);
    }
  }, [orderDetailView, setPackingInformationView]);

  useEffect(() => {
    //dispatch(getDispensaryList.pending(pageInfo));

    if (!orderDetailView && !dashboardView) {
      dispatch(getPackingSummaryList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, orderDetailView, dashboardView]);
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_packing_order_number_value: value,
        search_store_order_number_value: value,
        search_staff_name_value: value,
        search_staff_contact_number_value: value,
        search_physician_name_value: value,
        search_store_header_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_packing_order_number_value: null,
        search_store_order_number_value: null,
        search_staff_name_value: null,
        search_staff_contact_number_value: null,
        search_physician_name_value: null,
        search_store_header_desc_value: null,
      });
    }
  };

  if (
    packing.getPackingSummaryListSuccess === false &&
    packing.isLoadingPackingSummaryList === false
  ) {
    return (
      <Alert severity="error">{packing.error.packingSummaryListError}</Alert>
    );
  } else if (
    packing.getPackingByIdSuccess === false &&
    packing.isLoadingPackingById === false &&
    (orderDetailView || orderDetailView)
  ) {
    return <Alert severity="error">{packing.error.packingByIdError}</Alert>;
  } else if (common.getGeneralStatusIsSuccess === true && !orderDetailView) {
    return (
      <>
        <Collapse in={submitSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Packing has been submitted")}
          </Alert>
        </Collapse>

        <Collapse in={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={WIPSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.WIP_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={WIPErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {WIPmsgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={PendingSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.PENDING_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={PendingErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {PendingmsgAlarm}
          </Alert>
        </Collapse>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmPendingOpen}
          setOpen={setConfirmPendingOpen}
          onConfirm={handleConfirmPending}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmProcessAllOpen}
          setOpen={setConfirmProceeAllOpen}
          onConfirm={onSubmitPacking}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <SortingSearchTable
          tableHeading=""
          searchPlaceholder={t(
            "translation:Packing Order No/ Store Order No/ Physician/ Staff Name/ Staff Contact Number/ Description"
          )}
          statusPlaceholder={t("translation:Order Status")}
          statusOption={common.generalStatusList.status_general}
          searchValue={searchValue}
          columns={updateAccess ? columnsWUpdateAccess : columns}
          data={
            packing.packingSummaryList
              ? packing.packingSummaryList.packing_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            packing.packingSummaryList
              ? packing.packingSummaryList.total_pages
              : 0
          }
          loading={packing.isLoadingPackingSummaryList}
          totalItems={
            packing.packingSummaryList
              ? packing.packingSummaryList.total_items
              : 0
          }
          handleSearch={handleSearch}
          searchDate={true}
          searchDatePlaceholder={t("translation:Creation Date")}
          searchDate2={true}
          searchDatePlaceholder2={t("translation:Proceed Date")}
          localStorageName={localStorageNameKey}
        />
      </>
    );
  } else if (orderDetailView && orderDetailInfo !== null) {
    return (
      <OrderDetailPage
        orderDetailInfo={orderDetailInfo}
        setOrderDetailView={() => {
          setOrderDetailView(false);
          setOrderDetailInfo(null);
          setRefreshTimeStamp(moment());
        }}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
