import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/acupointConstants";

export const getAcupointById = {
  pending: (data) =>
    createAction(types.GET_ACUPOINTBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ACUPOINTBYID_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_ACUPOINTBYID_ASYNC.ERROR, { error }),
};

export const getAcupointList = {
  pending: (data) =>
    createAction(types.GET_ACUPOINTLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ACUPOINTLIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_ACUPOINTLIST_ASYNC.ERROR, { error }),
};

export const postCreateAcupoint = {
  pending: (data) =>
    createAction(types.POST_CREATEACUPOINT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEACUPOINT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEACUPOINT_ASYNC.ERROR, { error }),
};

export const putUpdateAcupoint = {
  pending: (data) =>
    createAction(types.PUT_UPDATEACUPOINT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEACUPOINT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEACUPOINT_ASYNC.ERROR, { error }),
};
