import * as types from "../_constants/invoiceConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.PUT_INVOICEWIPMODE_ASYNC.PENDING:
      return {
        ...state,
        putInvoiceWIPModeSuccess: false,
        isLoadingInvoiceWIPMode: true,
        invoiceWIPMode: null,
      };

    case types.PUT_INVOICEWIPMODE_ASYNC.ERROR:
      return {
        ...state,
        putInvoiceWIPModeSuccess: false,
        isLoadingInvoiceWIPMode: false,
        invoiceWIPMode: null,
        error: { ...state.error, invoiceWIPModeError: action.error },
      };

    case types.PUT_INVOICEWIPMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putInvoiceWIPModeSuccess: true,
        isLoadingInvoiceWIPMode: false,
        invoiceWIPMode: action.data,
      };

    case types.PUT_INVOICEPENDINGSTATUS_ASYNC.PENDING:
      return {
        ...state,
        putInvoicePendingStatusSuccess: false,
        isLoadingInvoicePendingStatus: true,
        invoicePendingStatus: null,
      };

    case types.PUT_INVOICEPENDINGSTATUS_ASYNC.ERROR:
      return {
        ...state,
        putInvoicePendingStatusSuccess: false,
        isLoadingInvoicePendingStatus: false,
        invoicePendingStatus: null,
        error: { ...state.error, invoicePendingStatusError: action.error },
      };

    case types.PUT_INVOICEPENDINGSTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        putInvoicePendingStatusSuccess: true,
        isLoadingInvoicePendingStatus: false,
        invoicePendingStatus: action.data,
      };

    case types.GET_INVOICELIST_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceListSuccess: false,
        isLoadingInvoiceList: true,
        invoiceList: null,
      };

    case types.GET_INVOICELIST_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceListSuccess: false,
        isLoadingInvoiceList: false,
        invoiceList: null,
        error: { ...state.error, invoiceListError: action.error },
      };

    case types.GET_INVOICELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceListSuccess: true,
        isLoadingInvoiceList: false,
        invoiceList: action.data,
      };

    case types.GET_INVOICESUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceSummaryListSuccess: false,
        isLoadingInvoiceSummaryList: true,
        invoiceSummaryList: null,
      };

    case types.GET_INVOICESUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceSummaryListSuccess: false,
        isLoadingInvoiceSummaryList: false,
        invoiceSummaryList: null,
        error: { ...state.error, invoiceSummaryListError: action.error },
      };

    case types.GET_INVOICESUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceSummaryListSuccess: true,
        isLoadingInvoiceSummaryList: false,
        invoiceSummaryList: action.data,
      };

    case types.GET_INVOICEBYID_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceByIdSuccess: false,
        isLoadingInvoiceById: true,
        invoiceById: null,
      };

    case types.GET_INVOICEBYID_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceByIdSuccess: false,
        isLoadingInvoiceById: false,
        invoiceById: null,
        error: { ...state.error, invoiceByIdError: action.error },
      };

    case types.GET_INVOICEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceByIdSuccess: true,
        isLoadingInvoiceById: false,
        invoiceById: action.data,
      };

    case types.GET_INVOICEBYORDERNUM_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceByOrderNumSuccess: false,
        isLoadingInvoiceByOrderNum: true,
        invoiceByOrderNum: null,
      };

    case types.GET_INVOICEBYORDERNUM_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceByOrderNumSuccess: false,
        isLoadingInvoiceByOrderNum: false,
        invoiceByOrderNum: null,
        error: { ...state.error, invoiceByOrderNumError: action.error },
      };

    case types.GET_INVOICEBYORDERNUM_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceByOrderNumSuccess: true,
        isLoadingInvoiceByOrderNum: false,
        invoiceByOrderNum: action.data,
      };

    case types.POST_SUBMITINVOICE_ASYNC.PENDING:
      return {
        ...state,
        postSubmitInvoiceSuccess: false,
        isLoadingSubmitInvoice: true,
        submitInvoice: null,
      };

    case types.POST_SUBMITINVOICE_ASYNC.ERROR:
      return {
        ...state,
        postSubmitInvoiceSuccess: false,
        isLoadingSubmitInvoice: false,
        submitInvoice: null,
        error: { ...state.error, submitInvoiceError: action.error },
      };

    case types.POST_SUBMITINVOICE_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitInvoiceSuccess: true,
        isLoadingSubmitInvoice: false,
        submitInvoice: action.data,
      };

    case types.POST_CREATEINVOICEBYID_ASYNC.PENDING:
      return {
        ...state,
        postCreateInvoiceByIdSuccess: false,
        isLoadingCreateInvoiceById: true,
        createInvoiceById: null,
      };

    case types.POST_CREATEINVOICEBYID_ASYNC.ERROR:
      return {
        ...state,
        postCreateInvoiceByIdSuccess: false,
        isLoadingCreateInvoiceById: false,
        createInvoiceById: null,
        error: { ...state.error, createInvoiceByIdError: action.error },
      };

    case types.POST_CREATEINVOICEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateInvoiceByIdSuccess: true,
        isLoadingCreateInvoiceById: false,
        createInvoiceById: action.data,
      };

    case types.PUT_INVOICEPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putInvoicePrintDateSuccess: false,
        isLoadingPutInvoicePrintDate: true,
        putInvoicePrintDate: null,
      };

    case types.PUT_INVOICEPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putInvoicePrintDateSuccess: false,
        isLoadingPutInvoicePrintDate: false,
        putInvoicePrintDate: null,
        error: { ...state.error, putInvoicePrintDateError: action.error },
      };

    case types.PUT_INVOICEPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putInvoicePrintDateSuccess: true,
        isLoadingPutInvoicePrintDate: false,
        putInvoicePrintDate: action.data,
      };

    case types.POST_OVERRIDEINVOICEAMOUNT_ASYNC.PENDING:
      return {
        ...state,
        postOverrideInvoiceAmountIsSuccess: false,
        isLoadingPostOverrideInvoiceAmount: true,
        postOverrideInvoiceAmount: null,
      };

    case types.POST_OVERRIDEINVOICEAMOUNT_ASYNC.ERROR:
      return {
        ...state,
        postOverrideInvoiceAmountIsSuccess: false,
        isLoadingPostOverrideInvoiceAmount: false,
        postOverrideInvoiceAmount: null,
        error: { ...state.error, postOverrideInvoiceAmountError: action.error },
      };

    case types.POST_OVERRIDEINVOICEAMOUNT_ASYNC.SUCCESS:
      return {
        ...state,
        postOverrideInvoiceAmountIsSuccess: true,
        isLoadingPostOverrideInvoiceAmount: false,
        postOverrideInvoiceAmount: action.data,
      };

    case types.POST_INVOICEWHATSAPPPDFWTEMPLATE_ASYNC.PENDING:
      return {
        ...state,
        postInvoiceWhatsappPDFWTemplateIsSuccess: false,
        isLoadingPostInvoiceWhatsappPDFWTemplate: true,
        postInvoiceWhatsappPDFWTemplate: null,
      };

    case types.POST_INVOICEWHATSAPPPDFWTEMPLATE_ASYNC.ERROR:
      return {
        ...state,
        postInvoiceWhatsappPDFWTemplateIsSuccess: false,
        isLoadingPostInvoiceWhatsappPDFWTemplate: false,
        postInvoiceWhatsappPDFWTemplate: null,
        error: {
          ...state.error,
          postInvoiceWhatsappPDFWTemplateError: action.error,
        },
      };

    case types.POST_INVOICEWHATSAPPPDFWTEMPLATE_ASYNC.SUCCESS:
      return {
        ...state,
        postInvoiceWhatsappPDFWTemplateIsSuccess: true,
        isLoadingPostInvoiceWhatsappPDFWTemplate: false,
        postInvoiceWhatsappPDFWTemplate: action.data,
      };

    case types.POST_INVOICEWHATSAPPATTACHMENTS_ASYNC.PENDING:
      return {
        ...state,
        postInvoiceWhatsappAttachmentsIsSuccess: false,
        isLoadingPostInvoiceWhatsappAttachments: true,
        postInvoiceWhatsappAttachments: null,
      };

    case types.POST_INVOICEWHATSAPPATTACHMENTS_ASYNC.ERROR:
      return {
        ...state,
        postInvoiceWhatsappAttachmentsIsSuccess: false,
        isLoadingPostInvoiceWhatsappAttachments: false,
        postInvoiceWhatsappAttachments: null,
        error: {
          ...state.error,
          postInvoiceWhatsappAttachmentsError: action.error,
        },
      };

    case types.POST_INVOICEWHATSAPPATTACHMENTS_ASYNC.SUCCESS:
      return {
        ...state,
        postInvoiceWhatsappAttachmentsIsSuccess: true,
        isLoadingPostInvoiceWhatsappAttachments: false,
        postInvoiceWhatsappAttachments: action.data,
      };

    case types.POST_INVOICEWHATSAPPATTACHMENTSWTEMPLATE_ASYNC.PENDING:
      return {
        ...state,
        postInvoiceWhatsappAttachmentsWTemplateIsSuccess: false,
        isLoadingPostInvoiceWhatsappAttachmentsWTemplate: true,
        postInvoiceWhatsappAttachmentsWTemplate: null,
      };

    case types.POST_INVOICEWHATSAPPATTACHMENTSWTEMPLATE_ASYNC.ERROR:
      return {
        ...state,
        postInvoiceWhatsappAttachmentsWTemplateIsSuccess: false,
        isLoadingPostInvoiceWhatsappAttachmentsWTemplate: false,
        postInvoiceWhatsappAttachmentsWTemplate: null,
        error: {
          ...state.error,
          postInvoiceWhatsappAttachmentsWTemplateError: action.error,
        },
      };

    case types.POST_INVOICEWHATSAPPATTACHMENTSWTEMPLATE_ASYNC.SUCCESS:
      return {
        ...state,
        postInvoiceWhatsappAttachmentsWTemplateIsSuccess: true,
        isLoadingPostInvoiceWhatsappAttachmentsWTemplate: false,
        postInvoiceWhatsappAttachmentsWTemplate: action.data,
      };

    case types.GET_INVOICEORGANISATIONTWILIO_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceOrganisationTwilioIsSuccess: false,
        isLoadingGetInvoiceOrganisationTwilio: true,
        getInvoiceOrganisationTwilio: null,
      };

    case types.GET_INVOICEORGANISATIONTWILIO_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceOrganisationTwilioIsSuccess: false,
        isLoadingGetInvoiceOrganisationTwilio: false,
        getInvoiceOrganisationTwilio: null,
        error: {
          ...state.error,
          getInvoiceOrganisationTwilioError: action.error,
        },
      };

    case types.GET_INVOICEORGANISATIONTWILIO_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceOrganisationTwilioIsSuccess: true,
        isLoadingGetInvoiceOrganisationTwilio: false,
        getInvoiceOrganisationTwilio: action.data,
      };

    case types.GET_INVOICETWILLIOTEMPLATE_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceTwillioTemplateIsSuccess: false,
        isLoadingGetInvoiceTwillioTemplate: true,
        getInvoiceTwillioTemplate: null,
      };

    case types.GET_INVOICETWILLIOTEMPLATE_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceTwillioTemplateIsSuccess: false,
        isLoadingGetInvoiceTwillioTemplate: false,
        getInvoiceTwillioTemplate: null,
        error: {
          ...state.error,
          getInvoiceTwillioTemplateError: action.error,
        },
      };

    case types.GET_INVOICETWILLIOTEMPLATE_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceTwillioTemplateIsSuccess: true,
        isLoadingGetInvoiceTwillioTemplate: false,
        getInvoiceTwillioTemplate: action.data,
      };

    case types.GET_INVOICEFILEATTACHMENTNOTIFICATION_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceFileAttachmentNotificationIsSuccess: false,
        isLoadingGetInvoiceFileAttachmentNotification: true,
        getInvoiceFileAttachmentNotification: null,
      };

    case types.GET_INVOICEFILEATTACHMENTNOTIFICATION_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceFileAttachmentNotificationIsSuccess: false,
        isLoadingGetInvoiceFileAttachmentNotification: false,
        getInvoiceFileAttachmentNotification: null,
        error: {
          ...state.error,
          getInvoiceFileAttachmentNotificationError: action.error,
        },
      };

    case types.GET_INVOICEFILEATTACHMENTNOTIFICATION_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceFileAttachmentNotificationIsSuccess: true,
        isLoadingGetInvoiceFileAttachmentNotification: false,
        getInvoiceFileAttachmentNotification: action.data,
      };

    case types.GET_INVOICECURRENTORGANISATION_ASYNC.PENDING:
      return {
        ...state,
        getInvoiceCurrentOrganisationIsSuccess: false,
        isLoadingGetInvoiceCurrentOrganisation: true,
        getInvoiceCurrentOrganisation: null,
      };

    case types.GET_INVOICECURRENTORGANISATION_ASYNC.ERROR:
      return {
        ...state,
        getInvoiceCurrentOrganisationIsSuccess: false,
        isLoadingGetInvoiceCurrentOrganisation: false,
        getInvoiceCurrentOrganisation: null,
        error: {
          ...state.error,
          getInvoiceCurrentOrganisationError: action.error,
        },
      };

    case types.GET_INVOICECURRENTORGANISATION_ASYNC.SUCCESS:
      return {
        ...state,
        getInvoiceCurrentOrganisationIsSuccess: true,
        isLoadingGetInvoiceCurrentOrganisation: false,
        getInvoiceCurrentOrganisation: action.data,
      };

    case types.POST_INVOICES3COPYOBJECT_ASYNC.PENDING:
      return {
        ...state,
        postInvoiceS3CopyObjectIsSuccess: false,
        isLoadingPostInvoiceS3CopyObject: true,
        postInvoiceS3CopyObject: null,
      };

    case types.POST_INVOICES3COPYOBJECT_ASYNC.ERROR:
      return {
        ...state,
        postInvoiceS3CopyObjectIsSuccess: false,
        isLoadingPostInvoiceS3CopyObject: false,
        postInvoiceS3CopyObject: null,
        error: {
          ...state.error,
          postInvoiceS3CopyObjectError: action.error,
        },
      };

    case types.POST_INVOICES3COPYOBJECT_ASYNC.SUCCESS:
      return {
        ...state,
        postInvoiceS3CopyObjectIsSuccess: true,
        isLoadingPostInvoiceS3CopyObject: false,
        postInvoiceS3CopyObject: action.data,
      };

    default:
      return state;
  }
};
