import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import organisationPriceRounding from "../../../../../components/functions/organisationPriceRounding";

import {
  getPaymentTerm,
  getPaymentMethod,
} from "../../../../../actions/commonActions";
import { useStyles } from "../../../../../components/globalStyles";

export default function PaymentTotal({
  viewOnly = false,
  amountHeader,
  payment,
  setPayment,
  currentOrganistion,
  gst_value,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  useEffect(() => {
    dispatch(getPaymentTerm.pending());
    dispatch(getPaymentMethod.pending());
  }, [dispatch]);

  const handlePaymentMethod = (event, option) => {
    setPayment((p) => ({
      ...p,
      paymentMethod: option ? option : null,
    }));
  };

  const handlePaymentTerm = (event, option) => {
    setPayment((p) => ({
      ...p,
      paymentTerm: option ? option : null,
    }));
  };

  return <>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid item xs={4} className={classes.subTotalInfoField}>
        {common.paymentMethodList && (
          <Autocomplete
            disabled={viewOnly}
            name="Payment_Methods"
            options={common.paymentMethodList.payment_methods}
            value={payment.paymentMethod}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            isOptionEqualToValue={(option, value) => {
              if (option.id === value.id) return option;
            }}
            style={{ width: "100%" }}
            onChange={(e, option) =>
              handlePaymentMethod(e, option == null ? null : option)
            }
            renderInput={(params) => (
              <TextField
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Payment Methods")}
                variant="outlined"
              />
            )}
          />
        )}
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid item xs={4} className={classes.subTotalInfoField}>
        {common.paymentTermList && (
          <Autocomplete
            disabled={viewOnly}
            name="Payment_Terms"
            options={common.paymentTermList.payment_terms}
            value={payment.paymentTerm}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            isOptionEqualToValue={(option, value) => {
              if (option.id === value.id) return option;
            }}
            style={{ width: "100%" }}
            onChange={(e, option) =>
              handlePaymentTerm(e, option == null ? null : option)
            }
            renderInput={(params) => (
              <TextField
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Payment Terms")}
                variant="outlined"
              />
            )}
          />
        )}
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid xs={4} item className={classes.subTotalInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          type="number"
          onWheel={(event) => event.target.blur()}
          InputProps={{
            inputProps: {
              min: 0,
              max: 100,
              step: "any",
              style: { textAlign: "right" },
            },
          }}
          onKeyDown={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E"
            ) {
              event.preventDefault();
            }
          }}
          onChange={(e, option) => {
            if (e.target.value <= 100) {
              setPayment((p) => ({
                ...p,
                discountInPrecent: Number(e.target.value),
              }));
            }
          }}
          value={
            payment.discountInPrecent !== null
              ? payment.discountInPrecent
              : ""
          }
          name="discountInPrecent"
          label={t("translation:Discount %")}
          variant="outlined"
        />
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid xs={4} item className={classes.subTotalInfoField}>
        <TextField
          className={classes.userFieldColor}
          disabled={true}
          type="number"
          onWheel={(event) => event.target.blur()}
          InputProps={{
            inputProps: {
              min: 0,
              step: "any",
              style: { textAlign: "right" },
            },
          }}
          onKeyDown={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E"
            ) {
              event.preventDefault();
            }
          }}
          InputLabelProps={{
            shrink: true,

            style: {
              fontWeight: "bold",
              color: "rgba(149, 0, 0,0.75)",
            },
          }}
          value={organisationPriceRounding(
            Number(amountHeader.amt_after_discount),
            currentOrganistion.amt_after_discount_in_percent_rmt,
            currentOrganistion.amt_after_discount_in_percent_rpvt
          )}
          name="amt_after_discount"
          label={t("translation:Amount After Discount")}
          variant="outlined"
        />
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid xs={4} item className={classes.subTotalInfoField}>
        <TextField
          className={classes.userFieldColor}
          disabled={true}
          type="number"
          onWheel={(event) => event.target.blur()}
          InputProps={{
            inputProps: {
              min: 0,
              step: "any",
              style: { textAlign: "right" },
            },
          }}
          onKeyDown={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E"
            ) {
              event.preventDefault();
            }
          }}
          InputLabelProps={{
            shrink: true,

            style: {
              fontWeight: "bold",
              color: "rgba(149, 0, 0,0.75)",
            },
          }}
          value={organisationPriceRounding(
            Number(amountHeader.amt_wo_gst),
            currentOrganistion.amt_wo_gst_rmt,
            currentOrganistion.amt_wo_gst_rpvt
          )}
          id="amt_bf_tax"
          name="amt_bf_tax"
          label={t("translation:Amount Before Tax")}
          variant="outlined"
        />
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid xs={4} item className={classes.subTotalInfoField}>
        <TextField
          className={classes.userFieldColor}
          disabled={true}
          type="number"
          onWheel={(event) => event.target.blur()}
          InputProps={{
            inputProps: {
              min: 0,
              step: "any",
              style: { textAlign: "right" },
            },
          }}
          onKeyDown={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E"
            ) {
              event.preventDefault();
            }
          }}
          InputLabelProps={{
            shrink: true,

            style: {
              fontWeight: "bold",
              color: "rgba(149, 0, 0,0.75)",
            },
          }}
          value={organisationPriceRounding(
            Number(amountHeader.gst_amt),
            currentOrganistion.gst_amt_rmt,
            currentOrganistion.amt_wo_gst_rpvt
          )}
          id="tax_amt"
          name="tax_amt"
          label={t("translation:Tax Amount") + " (" + gst_value + "%)"}
          variant="outlined"
        />
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid xs={4} item className={classes.subTotalInfoField}>
        <TextField
          className={classes.userFieldColor}
          disabled={true}
          type="number"
          onWheel={(event) => event.target.blur()}
          InputProps={{
            inputProps: {
              min: 0,
              step: "any",
              style: { textAlign: "right" },
            },
          }}
          onKeyDown={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E"
            ) {
              event.preventDefault();
            }
          }}
          InputLabelProps={{
            shrink: true,

            style: {
              fontWeight: "bold",
              color: "rgba(149, 0, 0,0.75)",
            },
          }}
          value={organisationPriceRounding(
            Number(amountHeader.amt_rounding),
            currentOrganistion.amt_with_gst_rmt,
            currentOrganistion.amt_with_gst_rpvt
          )}
          id="amt_afk_tax"
          name="amt_afk_tax"
          label={t("translation:Amount Rounding")}
          variant="outlined"
        />
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid xs={4} item className={classes.subTotalInfoField}>
        <TextField
          className={classes.userFieldColor}
          disabled={true}
          type="number"
          onWheel={(event) => event.target.blur()}
          InputProps={{
            inputProps: {
              min: 0,
              step: "any",
              style: { textAlign: "right" },
            },
          }}
          onKeyDown={(event) => {
            if (
              event?.key === "-" ||
              event?.key === "+" ||
              event?.key === "e" ||
              event?.key === "E"
            ) {
              event.preventDefault();
            }
          }}
          InputLabelProps={{
            shrink: true,

            style: {
              fontWeight: "bold",
              color: "rgba(149, 0, 0,0.75)",
            },
          }}
          value={organisationPriceRounding(
            Number(amountHeader.amt_with_gst),
            currentOrganistion.amt_with_gst_rmt,
            currentOrganistion.amt_with_gst_rpvt
          )}
          id="amt_afk_tax"
          name="amt_afk_tax"
          label={t("translation:Amount After Tax")}
          variant="outlined"
        />
      </Grid>
    </Grid>

    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid xs={4} item className={classes.subTotalInfoField}>
        <TextField
          disabled={viewOnly}
          className={classes.userFieldColor}
          onChange={(e, option) =>
            setPayment((p) => ({
              ...p,
              referral_code: e.target.value,
            }))
          }
          value={payment.referral_code !== null ? payment.referral_code : ""}
          name="referral_code"
          label={t("translation:Referral Code")}
          variant="outlined"
        />
      </Grid>
    </Grid>
  </>;
}
