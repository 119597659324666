import React from "react";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useStyles } from "./globalStyles";
import Close from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { DropzoneAreaBase } from "react-mui-dropzone";
import { useTranslation } from "react-i18next";
import MaxFileSizeAndNumNote from "./MaxFileSizeAndNumNote";
//style

// Split props related to DropzoneDialog from DropzoneArea ones
function splitDropzoneDialogProps(allProps) {
  const {
    cancelButtonText,
    dialogProps,
    dialogTitle,
    fullWidth,
    maxWidth,
    onClose,
    onSave,
    open,
    submitButtonText,
    msgAlarm,
    alertMsg,
    alertType,
    dialogLoading,
    supportedFileType,
    ...dropzoneAreaProps
  } = allProps;

  return [
    {
      cancelButtonText,
      dialogProps,
      dialogTitle,
      fullWidth,
      maxWidth,
      onClose,
      onSave,
      open,
      submitButtonText,
      msgAlarm,
      alertMsg,
      alertType,
      dialogLoading,
      supportedFileType,
    },
    dropzoneAreaProps,
  ];
}

const DropZoneDialog = (props) => {
  const classes = useStyles();

  const [dropzoneDialogProps, dropzoneAreaProps] =
    splitDropzoneDialogProps(props);
  const {
    cancelButtonText,
    dialogProps,
    dialogTitle,
    maxWidth,
    onClose,
    onSave,
    open,
    submitButtonText,
    msgAlarm,
    alertMsg,
    alertType,
    dialogLoading = false,
    supportedFileType,
  } = dropzoneDialogProps;

  // Submit button state
  const submitDisabled = !dropzoneAreaProps.fileObjects.some(
    (item) => item.data && item.data !== null
  );
  const { t } = useTranslation();
  return (
    <Dialog
      {...dialogProps}
      fullWidth
      open={open}
      maxWidth={maxWidth}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      aria-labelledby="confirm-dialog">
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={onClose}
      />

      {alertType !== null && alertMsg !== null && (
        <Collapse in={alertMsg}>
          <Alert severity={alertType}>{msgAlarm}</Alert>
        </Collapse>
      )}
      <DialogTitle id="confirm-dialog">{dialogTitle}</DialogTitle>

      <DialogContent>
        {!dialogLoading && (
          <DropzoneAreaBase
            showPreviewsInDropzone={false}
            {...dropzoneAreaProps}
          />
        )}

        {dialogLoading && <>{t("translation:Loading")}...</>}
        <MaxFileSizeAndNumNote
          maxFileNum={props.filesLimit}
          maxFileSizeMB={props.maxFileSize / 1000000}
          supportedFileType={supportedFileType}
        />
      </DialogContent>

      {!dialogLoading && (
        <DialogActions>
          <Link className={classes.boldMaroon075}>
            <Button
              onClick={onSave}
              disabled={submitDisabled}
              className={classes.boldMaroon075}
              cy_data="confirmButton">
              {submitButtonText}
            </Button>
          </Link>

          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              onClick={onClose}
              cy_data="rejectButton">
              {cancelButtonText}
            </Button>
          </Link>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DropZoneDialog;
