//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button, Grid } from "@mui/material";
//actions
import {
  getPatientVisitStoreOrderList,
  getVisitStoreOrderByIdPatientHistory,
} from "../../../../../actions/visitOrderActions";

//components
import { SortingSearchTable } from "../../../../../components/SortingSearchTable";

import { ReactComponent as DetailLogo } from "../../../../../assets/coolicons/file/file_blank_outline.svg";
import OrderDetailPage from "../../OrderDetailPage";

import * as pageConfigure from "../../../../../_constants/pageConstants";
import { useStyles } from "../../../../../components/globalStyles";

export default function StoreListTablePatientHistory({
  staffId,
  copyableMode = false,
  handleCopyOnClick = () => null,
}) {
  //variables

  const { t } = useTranslation();

  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Creation Date"),
      sortable: true,
      accessor: (d) => {
        if (d.created_on)
          return moment.utc(d.created_on).local().format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
  ];
  const classes = useStyles();
  const [pageInfo, setPageInfo] = useState({
    search_desc_value: null,
    search_end_date_value: null,
    search_name_value: null,
    search_order_number_value: null,
    search_physician_name_value: null,
    search_staff_id_value: staffId,
    search_staff_name_value: null,
    search_start_date_value: null,
    search_status_type_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });

  const dispatch = useDispatch();

  const prevGetVisitOrderStatus = useRef();

  const visitOrder = useSelector((state) => state.visitOrder);

  const common = useSelector((state) => state.common);

  const [orderDetailView, setOrderDetailView] = useState(false);

  const [orderDetailInfo, setOrderDetailInfo] = useState(null);

  const [remountKey, setRemountKey] = useState(0);

  const [highlightOrderId, setHighlightOrderId] = useState(null);

  //useEffects

  useEffect(() => {
    prevGetVisitOrderStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      visitOrder.isLoadingVisitStoreOrderByIdPatientHistory !==
      prevGetVisitOrderStatus.current
    ) {
      //check the previous ref with current state
      prevGetVisitOrderStatus.current =
        visitOrder.isLoadingVisitStoreOrderByIdPatientHistory;

      if (
        visitOrder.getVisitStoreOrderByIdPatientHistoryIsSuccess === true &&
        visitOrder.isLoadingVisitStoreOrderByIdPatientHistory === false
      ) {
        if (visitOrder.storeOrderListByIdPatientHistory.store_header) {
          setOrderDetailInfo(
            visitOrder.storeOrderListByIdPatientHistory.store_header
          );
          setOrderDetailView(true);
        }
      }
      if (
        visitOrder.getVisitStoreOrderByIdPatientHistoryIsSuccess === false &&
        visitOrder.isLoadingVisitStoreOrderByIdPatientHistory === false
      ) {
        setOrderDetailInfo(null);
        setOrderDetailView(false);
      }
    }
  }, [
    visitOrder.getVisitStoreOrderByIdPatientHistoryIsSuccess,
    visitOrder.isLoadingVisitStoreOrderByIdPatientHistory,
    visitOrder.error,
    visitOrder.getVisitStoreOrderByIdPatientHistoryError,
    visitOrder.storeOrderListByIdPatientHistory,
  ]);

  //functions

  const handleRowDetail = (value) => {
    //

    dispatch(
      getVisitStoreOrderByIdPatientHistory.pending({
        is_pagination: false,
        search_staff_id_value: staffId,
        search_store_order_id_value: value.id,
      })
    );
    setHighlightOrderId(value.id);
    setOrderDetailInfo(null);
    setOrderDetailView(false);
    setRemountKey((prev) => prev + 1);
  };

  const handleSearch = (value) => {
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_number_value: value,
        search_staff_name_value: value,
        search_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_number_value: "",
        search_staff_name_value: "",
        search_desc_value: "",
      });
    }
  };

  useEffect(() => {
    dispatch(getPatientVisitStoreOrderList.pending(pageInfo));
  }, [dispatch, pageInfo]);

  if (common.getGeneralStatusIsSuccess === true) {
    return (
      <Grid className={classes.gridRoot} container>
        <Grid item xs={3} className={classes.gridPadding}>
          <SortingSearchTable
            disabledSearch
            statusOption={common.generalStatusList.status_general}
            columns={columns}
            highlightOrderId={highlightOrderId}
            data={
              visitOrder.patientVisitStoreOrderList
                ? visitOrder.patientVisitStoreOrderList.store_headers
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              visitOrder.patientVisitStoreOrderList
                ? visitOrder.patientVisitStoreOrderList.total_pages
                : 0
            }
            loading={visitOrder.isLoadingPatientVisitStoreOrderList}
            totalItems={
              visitOrder.patientVisitStoreOrderList
                ? visitOrder.patientVisitStoreOrderList.total_items
                : 0
            }
            handleSearch={handleSearch}
          />
        </Grid>

        {orderDetailInfo !== null && orderDetailView && (
          <Grid item xs={9} className={classes.gridPadding}>
            <OrderDetailPage
              key={remountKey}
              orderDetailInfo={orderDetailInfo}
              setOrderDetailInfo={setOrderDetailInfo}
              setOrderDetailView={setOrderDetailView}
              handleCopyOnClick={handleCopyOnClick}
              copyableMode={copyableMode}
              historyMode={true}
            />
          </Grid>
        )}
      </Grid>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
