import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
//components
import * as generalConstants from "../../../_constants/generalConstants";

import SocketNotification from "./SocketNotification";

import StoreListTable from "../../workOrder/Store/StoreListTable";
//Actions

//styles
export default function StoreOrderTable() {
  const { t } = useTranslation();

  //variable
  const [refreshTimeStamp, setRefreshTimeStamp] = useState(moment());

  return (
    <SocketNotification
      cellTitle={t("translation:Order")}
      setRefreshTimeStamp={setRefreshTimeStamp}
      sockectWorkTypeId={generalConstants.STORE_WORK_ORDER_TYPE}
      localStorageName="storeListTableNotification">
      <StoreListTable
        refreshTimeStamp={refreshTimeStamp}
        dashboardView={true}
        setRefreshTimeStamp={setRefreshTimeStamp}
      />
    </SocketNotification>
  );
}
