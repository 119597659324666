//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import { Button, TextField, Grid, Link, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
//component
import Panel from "../../../components/Panel";
import CheckoutForm from "./components/CheckoutForm";
import amountInCent from "../../../components/functions/amountInCent";
import amountInCentToDollarNum from "../../../components/functions/amountInCentToDollarNum";
import { useStyles } from "../../../components/globalStyles";
import UserCreditTable from "./components/UserCreditTable";
//actions
import {
  postTopUpCredit,
  getCreditBalance,
  getDefaultAlliance,
  getAllOrganisationStripe,
} from "../../../actions/curUserActions";

const appearance = {
  theme: "stripe",
};

const TopUpPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const preTopUpCreditStatus = useRef();
  const curUser = useSelector((state) => state.curUser);
  const [clientSecret, setClientSecret] = useState(null);
  const [stripeView, setStripeView] = useState(false);
  const [topUpAmount, setTopUpAmount] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [organisationStripe, setOrganisationStripe] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [defaultAlliance, setDefaultAlliance] = useState(null);
  // const stripePubKey =
  //   "pk_test_51LUP4nLSDyIQEmu0ETR9v6sCBsg2hBEUWFs8vXnBbE3hJYjWARUSkuipJ2dxokUa9Nh2kqZne63mxH6CIyTQ2m8X00DkkTnoyO";
  // const stripePromise = loadStripe(stripePubKey);

  function intersection(a, b) {
    return a.filter((item1) =>
      b.find((item2) => {
        return item1.organisation.id === item2.organisation.id;
      })
    );
  }

  useEffect(() => {
    //set ref when first render

    preTopUpCreditStatus.current = false;
  }, []);

  useEffect(() => {
    dispatch(getCreditBalance.pending());
    dispatch(getDefaultAlliance.pending());
    dispatch(getAllOrganisationStripe.pending());
  }, [dispatch]);

  useEffect(() => {
    if (curUser.isLoadingPostTopUpCredit !== preTopUpCreditStatus.current) {
      //check the previous ref with current state
      preTopUpCreditStatus.current = curUser.isLoadingPostTopUpCredit;
      if (
        curUser.postTopUpCreditIsSuccess === true &&
        curUser.isLoadingPostTopUpCredit === false
      ) {
        setClientSecret(curUser.topUpCredit.clientSecret);
        setStripeView(true);
      }
      if (
        curUser.postTopUpCreditIsSuccess === false &&
        curUser.isLoadingPostTopUpCredit === false
      ) {
        setClientSecret(null);
        setStripeView(false);
        setErrorMessage(curUser.error.topUpCreditError);
      }
    }
  }, [curUser]);

  useEffect(() => {
    //is medicine cost and organisation stripe
    if (curUser.defaultAlliance && curUser.allOrganisationStripe) {
      const defaultAlliancePrepaid =
        curUser.defaultAlliance?.alliance?.alliance_organisation_associations?.filter(
          (item) => item.is_medicine_cost_prepaid === true
        );
      const organisationStripes =
        curUser.allOrganisationStripe.organisation_stripes;
      const organisationStripesOptions = intersection(
        organisationStripes,
        defaultAlliancePrepaid
      );

      setDefaultAlliance(organisationStripesOptions);
      setOrganisationStripe(organisationStripesOptions[0]);
    }
  }, [curUser.defaultAlliance, curUser.allOrganisationStripe]);

  async function handleTopUpCreditOnClick() {
    setClientSecret(null);
    const stripe = await loadStripe(organisationStripe?.stripe_publishable_key);
    setStripePromise(stripe);
    const topUpAmountInCent = amountInCent(topUpAmount);
    // const organisationId = organisationStripe.organisation.id;
    const jsonText = {
      amount: topUpAmountInCent,
      organisation: {
        id: organisationStripe?.organisation?.id,
      },
      alliance: { id: curUser.defaultAlliance.alliance.id },
    };
    dispatch(postTopUpCredit.pending(jsonText));
  }

  const handleOrganisationStripe = (option) => {
    setOrganisationStripe(option);
  };

  const options = {
    clientSecret,
    appearance,
  };
  return (
    <Panel heading={t("translation:Top Up")}>
      <Collapse in={successMessage ? true : false}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessMessage(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {successMessage}
        </Alert>
      </Collapse>
      <Collapse in={errorMessage ? true : false}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorMessage(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {errorMessage}
        </Alert>
      </Collapse>
      {organisationStripe && (
        <UserCreditTable organisationId={organisationStripe.organisation.id} />
      )}
      {defaultAlliance && (
        <form>
          <Grid item xs={12} className={classes.userInfoField}>
            <Autocomplete
              name="Organisation"
              disableClearable
              value={organisationStripe}
              options={defaultAlliance}
              getOptionLabel={(option) =>
                option.organisation.name ? option.organisation.name : ""
              }
              isOptionEqualToValue={(option, value) => {
                if (option.id === value.id) return option;
              }}
              style={{ width: "100%" }}
              onChange={(e, option) => {
                handleOrganisationStripe(option);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Organisation")}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          {organisationStripe && (
            <>
              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  disabled={stripeView}
                  required
                  name="Amount"
                  label={t("translation:Amount")}
                  variant="outlined"
                  type="number"
                  onWheel={(event) => event.target.blur()}
                  InputProps={{
                    inputProps: {
                      min: organisationStripe.min_amount_in_cents
                        ? amountInCentToDollarNum(
                            organisationStripe.min_amount_in_cents
                          )
                        : 0,
                      step: "any",
                      style: { textAlign: "right" },
                    },
                  }}
                  onKeyDown={(event) => {
                    if (
                      event?.key === "-" ||
                      event?.key === "+" ||
                      event?.key === "e" ||
                      event?.key === "E"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  value={topUpAmount !== null ? topUpAmount : ""}
                  onChange={(e) => {
                    setTopUpAmount(e.target.value);
                  }}
                />
              </Grid>
              {!stripeView && (
                <Link className={classes.boldMaroon075}>
                  <Button
                    className={classes.boldMaroon075}
                    name="topUpButton"
                    onClick={(e) => {
                      e.currentTarget.form.reportValidity();
                      var checkVal = e.currentTarget.form.checkValidity();
                      if (checkVal) {
                        handleTopUpCreditOnClick();
                      }
                    }}>
                    {t("translation:Top up")}
                  </Button>
                </Link>
              )}
            </>
          )}
        </form>
      )}
      {clientSecret && stripeView && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            clientSecret={clientSecret}
            setStripeView={setStripeView}
          />
        </Elements>
      )}
    </Panel>
  );
};

export default TopUpPage;
