import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/invoiceMedicineCostingConstants";

export const putInvoiceMedicineCostingWIPMode = {
  pending: (data) =>
    createAction(types.PUT_INVOICEMEDICINECOSTINGWIPMODE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_INVOICEMEDICINECOSTINGWIPMODE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_INVOICEMEDICINECOSTINGWIPMODE_ASYNC.ERROR, {
      error,
    }),
};

export const putInvoiceMedicineCostingPendingStatus = {
  pending: (data) =>
    createAction(types.PUT_INVOICEMEDICINECOSTINGPENDINGSTATUS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_INVOICEMEDICINECOSTINGPENDINGSTATUS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_INVOICEMEDICINECOSTINGPENDINGSTATUS_ASYNC.ERROR, {
      error,
    }),
};

export const getInvoiceMedicineCostingList = {
  pending: (data) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGLIST_ASYNC.ERROR, { error }),
};

export const getInvoiceMedicineCostingSummaryList = {
  pending: (data) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGSUMMARYLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGSUMMARYLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGSUMMARYLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getInvoiceMedicineCostingById = {
  pending: (data) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGBYID_ASYNC.ERROR, { error }),
};

export const getInvoiceMedicineCostingByOrderNum = {
  pending: (data) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGBYORDERNUM_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGBYORDERNUM_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_INVOICEMEDICINECOSTINGBYORDERNUM_ASYNC.ERROR, {
      error,
    }),
};

export const postSubmitInvoiceMedicineCosting = {
  pending: (data) =>
    createAction(types.POST_SUBMITINVOICEMEDICINECOSTING_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_SUBMITINVOICEMEDICINECOSTING_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_SUBMITINVOICEMEDICINECOSTING_ASYNC.ERROR, {
      error,
    }),
};

export const putInvoiceMedicineCostingPrintDate = {
  pending: (data) =>
    createAction(types.PUT_INVOICEMEDICINECOSTINGPRINTDATE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_INVOICEMEDICINECOSTINGPRINTDATE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_INVOICEMEDICINECOSTINGPRINTDATE_ASYNC.ERROR, {
      error,
    }),
};
