//lib
import React, { useEffect, useRef, useCallback, useState } from "react";
import { Font } from "@react-pdf/renderer";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Button, Link } from "@mui/material";
// import IconButton from "@mui/material/IconButton";
// import Alert from "@mui/material/Alert";
// import Collapse from "@mui/material/Collapse";
// import CloseIcon from "@mui/icons-material/Close";
//action

import { getPaymentMethod } from "../../../actions/commonActions";
//import { getOrganisationLogo } from "../../../actions/curUserActions";
import { postCreatePresignedUrlOrganisationLogo } from "../../../actions/cloudStorageActions";
//components
import Panel from "../../../components/Panel";

import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import InvoiceDetailsTable from "../Invoice/components/InvoiceDetailsTable";
import * as generalConstants from "../../../_constants/generalConstants";
import NotoSansHansRegular from "../../../components/PDF/NotoSansHans-Regular-2.ttf";

import AmountTotal from "../Invoice/components/AmountTotal";
import PaymentTable from "./components/PaymentTable";

import InvoiceMedicineCostingPDF from "../../../components/PDF/InvoiceMedicineCostingPDF";
import { putInvoiceMedicineCostingPrintDate } from "../../../actions/invoiceMedicineCostingActions";
import { useStyles } from "../../../components/globalStyles";
import S3OrganisationLogo, {
  setPrintingOrganisationLogo,
} from "../../../components/functions/S3OrganisationLogo";
//register font before calling pdf file
Font.register({
  family: "NotoSansSChinese",
  fonts: [{ src: NotoSansHansRegular }],
});

Font.registerHyphenationCallback((word) =>
  word.length === 1 ? [word] : Array.from(word).map((char) => char)
);
//style

const DetailPage = ({
  setOrderProcessView,
  setOrderProcessInfo = () => null,
  orderProcessInfo,
}) => {
  const [pdfFontLoaded, setPdfFontLoaded] = useState(false);
  const classes = useStyles();
  const loadFonts = useCallback(async () => {
    await Promise.all([Font.load({ fontFamily: "NotoSansSChinese" })]).then(
      () => {
        setPdfFontLoaded(true);
      }
    );
  }, []);

  useEffect(() => {
    loadFonts();
  }, [loadFonts]);
  const dispatch = useDispatch();
  const [TCMLogo, setTCMLOGO] = useState(null);
  // const [logoError, setLogoError] = useState(false);
  // const [logoErrorMsg, setLogoErrorMsg] = useState(null);
  const common = useSelector((state) => state.common);
  const { t } = useTranslation();
  const [payment, setPayment] = React.useState([]);
  const [print, setPrint] = React.useState(0);
  const [paidAmount, setPaidAmount] = React.useState(0);
  const [allowPrint, setAllowPrint] = useState(false);
  const prevSetPrintDateOnStatus = useRef();
  const price = {
    deliveryFee: orderProcessInfo.store_medicine_costing_header
      .branch_delivery_price
      ? orderProcessInfo.store_medicine_costing_header.branch_delivery_price
      : 0,
    brewingFee: orderProcessInfo.store_medicine_costing_header
      .branch_brewing_price
      ? orderProcessInfo.store_medicine_costing_header.branch_brewing_price
      : 0,

    medicineCosting: orderProcessInfo.store_medicine_costing_header
      .branch_medicine_price
      ? orderProcessInfo.store_medicine_costing_header.branch_medicine_price
      : 0,
  };

  const curUser = useSelector((state) => state.curUser);
  const invoiceMedicineCosting = useSelector(
    (state) => state.invoiceMedicineCosting
  );

  const invoiceDetailsWoDeleted = () => {
    return orderProcessInfo.invoice_medicine_costing_details.filter(
      (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
    );
  };

  const memoInvoiceDetailsWoDeleted = React.useMemo(invoiceDetailsWoDeleted, [
    orderProcessInfo,
  ]);

  const setPrintingLogo = () => {
    setTCMLOGO(null);
    // const organisationId = orderProcessInfo.organisation.id;

    //dispatch(getOrganisationLogo.pending(organisationId));
    //dispatch(getOrganisation.pending(organisationId));
    setAllowPrint(false);
    setPrint(new Date().valueOf());
    const submitJson = setPrintingOrganisationLogo(
      orderProcessInfo.organisation.codename,
      "organisation",
      orderProcessInfo.organisation.logo
    );
    dispatch(postCreatePresignedUrlOrganisationLogo.pending(submitJson));
  };

  const setPrintingDate = () => {
    dispatch(
      putInvoiceMedicineCostingPrintDate.pending({
        invoice_medicine_costing_header: orderProcessInfo,
      })
    );
  };
  //useEffects
  useEffect(() => {
    prevSetPrintDateOnStatus.current = false;
  }, []);

  useEffect(() => {
    // const deafultOrganisationId =
    //   curUser.organisationInfo.user_organisations?.find(
    //     (o) => o.is_default === true
    //   ).organisations?.id;

    dispatch(getPaymentMethod.pending());
  }, [dispatch]);

  // useEffect(() => {
  //   if (curUser.OrganisationLogo) {
  //     setTCMLOGO(curUser.OrganisationLogo.data);
  //   } else if (curUser.error?.organisationLogoError) {
  //     setLogoError(true);
  //     if (
  //       curUser.error?.organisationLogoError === "Missing Organisation Logo"
  //     ) {
  //       setLogoErrorMsg(t("translation:Missing Organisation Logo"));
  //     } else {
  //       setLogoErrorMsg(curUser.error?.organisationLogoError);
  //     }
  //   } else {
  //     setTCMLOGO(null);
  //   }
  // }, [curUser, t]);
  useEffect(() => {
    if (
      invoiceMedicineCosting.isLoadingPutIMCPrintDate !==
      prevSetPrintDateOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetPrintDateOnStatus.current =
        invoiceMedicineCosting.isLoadingPutIMCPrintDate;

      if (
        invoiceMedicineCosting.putIMCPrintDateSuccess === true &&
        invoiceMedicineCosting.isLoadingPutIMCPrintDate === false
      ) {
        setAllowPrint(true);
        // setOrderProcessInfo(
        //   invoiceMedicineCosting.putIMCPrintDate.invoice_medicine_costing_header
        // );
      }
    }
  }, [invoiceMedicineCosting, setOrderProcessInfo]);

  useEffect(() => {
    if (allowPrint) {
      setOrderProcessInfo(
        invoiceMedicineCosting.putIMCPrintDate.invoice_medicine_costing_header
      );
    }
  }, [invoiceMedicineCosting.putIMCPrintDate, allowPrint, setOrderProcessInfo]);

  useEffect(() => {
    const temAccAmount =
      orderProcessInfo.invoice_medicine_costing_details.reduce((sum, li) => {
        if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          return parseFloat((sum + li.payment_amount).toFixed(2));
        }
        return parseFloat(sum.toFixed(2));
      }, 0);

    setPaidAmount(temAccAmount);
  }, [orderProcessInfo]);

  return (
    <div>
      <S3OrganisationLogo
        organisationCode={orderProcessInfo.organisation.codename}
        folderPath="organisation"
        setPhotoURL={setTCMLOGO}
      />
      {/* <Collapse in={logoError && print ? true : false}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setLogoError(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {logoErrorMsg}
        </Alert>
      </Collapse> */}
      <Typography display="block" variant="h4">
        {orderProcessInfo.order_number}
      </Typography>
      <Link
        onClick={() => {
          setPrintingLogo();
        }}
        className={classes.boldMaroon075}>
        <Button className={classes.boldMaroon075}>
          {t("translation:PRINT INVOICE")}
        </Button>
      </Link>
      {pdfFontLoaded && TCMLogo && common.paymentMethodList && print !== 0 && (
        <InvoiceMedicineCostingPDF
          t={t}
          print={print}
          TCMLogo={TCMLogo}
          paidAmount={paidAmount}
          orderProcessInfo={orderProcessInfo}
          paymentOptions={common.paymentMethodList.payment_methods}
          setPrintingDate={setPrintingDate}
          allowPrint={allowPrint}
        />
      )}
      <Panel>
        <PaymentTable viewOnly={true} price={price} t={t} />
      </Panel>
      <Panel>
        <AmountTotal
          amountHeader={orderProcessInfo.store_medicine_costing_header}
          payment={payment}
          setPayment={setPayment}
          paidAmount={paidAmount}
        />
      </Panel>
      {memoInvoiceDetailsWoDeleted.length > 0 && (
        <Panel>
          <InvoiceDetailsTable
            data={memoInvoiceDetailsWoDeleted}
            t={t}
            viewOnly={true}
          />
        </Panel>
      )}

      <PageOnlyBackAction setView={() => setOrderProcessView(false)} />
    </div>
  );
};

export default DetailPage;
