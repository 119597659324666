import React from "react";
import Dialog from "@mui/material/Dialog";

//style

const SubmissionNotificationDialog = (props) => {
  const { children, open, maxWidth = "sm" } = props;
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      aria-labelledby="confirm-dialog"
      fullWidth>
      {children}
    </Dialog>
  );
};

export default SubmissionNotificationDialog;
