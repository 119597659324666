//lib
import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Link, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";

//components
import * as generalConstants from "../../../_constants/generalConstants";
import ConfirmDialog from "../../../components/ConfirmDialog";
import PageSaveWBackAction from "../../../components/PageSaveWBackAction";
import RequiredNote from "../../../components/RequiredNote";
import submisionJSON from "./components/addOrder/submisionJSON";
import {
  postVisitPhysicians,
  postVisitSaveStoreOrder,
  getVisitCurrentOrganisation,
  getVisitIsUserCurrentOrganisation,
  putVisitUpdateUserOrganisation,
} from "../../../actions/visitOrderActions";
import { getDefaultBranch } from "../../../actions/curUserActions";

import PatientSearch from "./components/userAdminstration/PatientSearch";
import AddPatientDialog from "./components/userAdminstration/AddPatientDialog";
import { useStyles } from "../../../components/globalStyles";

import SubmissionNotificationDialog from "../../../components/SubmissionNotificationDialog";

import { history } from "../../../App";

export default function AddOrderPage({
  confirmAddOrderOpen,
  setConfirmAddOrderOpen,
  setSaveOrderEditView,
  setEditOrderInfo,
  defaultPatientSearch = null,
  appointmentmentId = null,
  defaultPhysician = null,
  accessMode = false,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visitOrder = useSelector((state) => state.visitOrder);
  const curUser = useSelector((state) => state.curUser);

  const [physician, setPhysician] = useState(null);

  const [patient, setPatient] = useState(null);
  const [defaultUserSearchState, setDefaultUserSearchState] =
    useState(defaultPatientSearch);

  const [saveErrorAlarm, setSaveErrorAlarm] = useState(false);
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState(null);
  const [saveOrderErrorAlarm, setSaveOrderErrorAlarm] = useState(false);
  const [disableSummaryView, setDisableSummaryView] = React.useState(false);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const prevSaveVisitStoreStatus = useRef();

  const [
    msgCheckCurrentUserOrganisationAlarm,
    setMsgCheckCurrentUserOrganisationAlarm,
  ] = useState(null);
  const [
    checkCurrentUserOrganisationErrorAlarm,
    setCheckCurrentUserOrganisationErrorAlarm,
  ] = useState(false);
  const prevCheckCurrentUserOrganisationStatus = useRef();

  const [
    msgUpdateCurrentUserOrganisationAlarm,
    setMsgUpdateCurrentUserOrganisationAlarm,
  ] = useState(null);
  const [
    updateCurrentUserOrganisationErrorAlarm,
    setUpdateCurrentUserOrganisationErrorAlarm,
  ] = useState(false);
  const prevUpdateCurrentUserOrganisationStatus = useRef();

  const prevDefaultPhysician = useRef();

  //functions
  const onSave = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    //check whether is it current organisation user
    dispatch(getVisitIsUserCurrentOrganisation.pending({ userId: patient.id }));
    // dispatch(
    //   postVisitSaveStoreOrder.pending(
    //     submisionJSON(
    //       visitOrder.currentOrganistion.organisation,
    //       curUser,
    //       patient,
    //       physician
    //     )
    //   )
    // );
  };

  const handleOnSaveVisitOrder = (event) => {
    event.currentTarget.form.reportValidity();
    var checkVal = event.currentTarget.form.checkValidity();
    if (checkVal) {
      if (physician && patient) {
        onSave(event);
        // setConfirmSubmitOpen(true);
      } else {
        window.scrollTo(0, 1);
        if (!patient) {
          setSaveErrorAlarm(true);
        }
      }
    }
  };

  //useEffects
  useEffect(() => {
    prevSaveVisitStoreStatus.current = false;
    prevCheckCurrentUserOrganisationStatus.current = false;
    prevUpdateCurrentUserOrganisationStatus.current = false;
    prevDefaultPhysician.current = false;
  }, []);

  useEffect(() => {
    if (
      curUser.allRightAccess !== null &&
      !curUser.allRightAccess?.user_rights?.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    ) {
      history.push("/403");
    }
  }, [curUser.allRightAccess]);

  useEffect(() => {
    dispatch(getVisitCurrentOrganisation.pending());

    dispatch(
      postVisitPhysicians.pending({
        is_pagination: false,
        search_user_id_value: null,
        search_user_name_value: null,
        search_username_value: null,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );
    dispatch(getDefaultBranch.pending());
  }, [dispatch]);

  useEffect(() => {
    if (
      defaultPhysician &&
      visitOrder.isLoadingPostVisitPhysicians !== prevDefaultPhysician.current
    ) {
      //check the previous ref with current state
      prevDefaultPhysician.current = visitOrder.isLoadingPostVisitPhysicians;

      if (
        visitOrder.postVisitPhysiciansIsSuccess === true &&
        visitOrder.isLoadingPostVisitPhysicians === false
      ) {
        const defualtUser =
          visitOrder.postPhysiciansList.organisation_physician.find(
            (item) => item.user.id === defaultPhysician.id
          );
        setPhysician(defualtUser);
      }

      if (
        visitOrder.postVisitPhysiciansIsSuccess === false &&
        visitOrder.isLoadingPostVisitPhysicians === false
      ) {
        setPhysician(null);
      }
    }
  }, [visitOrder, physician, defaultPhysician]);

  useEffect(() => {
    if (
      visitOrder.isLoadingSaveStoreOrder !== prevSaveVisitStoreStatus.current
    ) {
      //check the previous ref with current state
      prevSaveVisitStoreStatus.current = visitOrder.isLoadingSaveStoreOrder;

      if (
        visitOrder.postVisitSaveStoreOrderIsSuccess === true &&
        visitOrder.isLoadingSaveStoreOrder === false
      ) {
        if (!accessMode) {
          setEditOrderInfo(visitOrder.saveStoreOrder.store_header);

          setSaveOrderEditView(true);
        }
        setConfirmAddOrderOpen(false);
        window.scrollTo(0, 0);
      }

      if (
        visitOrder.postVisitSaveStoreOrderIsSuccess === false &&
        visitOrder.isLoadingSaveStoreOrder === false
      ) {
        if (!accessMode) {
          setSaveOrderEditView(false);
        }
        setMsgAlarm(visitOrder.error.saveStoreOrderError);

        setSaveOrderErrorAlarm(true);
        setConfirmAddOrderOpen(true);
        window.scrollTo(0, 0);
      }
    }
  }, [
    visitOrder,
    accessMode,
    setConfirmAddOrderOpen,
    setEditOrderInfo,
    setSaveOrderEditView,
  ]);

  useEffect(() => {
    if (
      visitOrder.isLoadingVisitIsUserCurrentOrganisation !==
      prevCheckCurrentUserOrganisationStatus.current
    ) {
      //check the previous ref with current state
      prevCheckCurrentUserOrganisationStatus.current =
        visitOrder.isLoadingVisitIsUserCurrentOrganisation;

      if (
        visitOrder.getVisitIsUserCurrentOrganisationIsSuccess === true &&
        visitOrder.isLoadingVisitIsUserCurrentOrganisation === false
      ) {
        window.scrollTo(0, 0);
        setCheckCurrentUserOrganisationErrorAlarm(false);
        if (visitOrder.visitIsUserCurrentOrganisation.exist === true) {
          //exist and call save visit order api
          //currenct organisation and post order
          dispatch(
            postVisitSaveStoreOrder.pending(
              submisionJSON(
                visitOrder.currentOrganistion.organisation,
                curUser,
                patient,
                physician,
                appointmentmentId,
                accessMode
              )
            )
          );
        } else {
          // no exist and update user organisation

          dispatch(
            putVisitUpdateUserOrganisation.pending({ userId: patient.id })
          );
        }
      }

      if (
        visitOrder.getVisitIsUserCurrentOrganisationIsSuccess === false &&
        visitOrder.isLoadingVisitIsUserCurrentOrganisation === false
      ) {
        window.scrollTo(0, 0);
        setCheckCurrentUserOrganisationErrorAlarm(true);
        setMsgCheckCurrentUserOrganisationAlarm(
          visitOrder.error.visitIsUserCurrentOrganisationError
        );
      }
    }
  }, [
    dispatch,
    visitOrder,
    curUser,
    patient,
    physician,
    accessMode,
    appointmentmentId,
  ]);

  useEffect(() => {
    if (
      visitOrder.isLoadingVisitUpdateUserOrganisation !==
      prevUpdateCurrentUserOrganisationStatus.current
    ) {
      //check the previous ref with current state
      prevUpdateCurrentUserOrganisationStatus.current =
        visitOrder.isLoadingVisitUpdateUserOrganisation;

      if (
        visitOrder.putVisitUpdateUserOrganisationIsSuccess === true &&
        visitOrder.isLoadingVisitUpdateUserOrganisation === false
      ) {
        window.scrollTo(0, 0);
        setUpdateCurrentUserOrganisationErrorAlarm(false);

        //exist and call save visit order api

        dispatch(
          postVisitSaveStoreOrder.pending(
            submisionJSON(
              visitOrder.currentOrganistion.organisation,
              curUser,
              patient,
              physician
            )
          )
        );
      }

      if (
        visitOrder.putVisitUpdateUserOrganisationIsSuccess === false &&
        visitOrder.isLoadingVisitUpdateUserOrganisation === false
      ) {
        window.scrollTo(0, 0);
        setUpdateCurrentUserOrganisationErrorAlarm(true);
        setMsgUpdateCurrentUserOrganisationAlarm(
          visitOrder.error.visitUpdateUserOrganisationError
        );
      }
    }
  }, [dispatch, visitOrder, curUser, patient, physician]);

  return <>
    <SubmissionNotificationDialog
      open={updateCurrentUserOrganisationErrorAlarm}>
      <Alert
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setUpdateCurrentUserOrganisationErrorAlarm(false);
            }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>
        {msgUpdateCurrentUserOrganisationAlarm}
      </Alert>
    </SubmissionNotificationDialog>

    <SubmissionNotificationDialog
      open={checkCurrentUserOrganisationErrorAlarm}>
      <Alert
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setCheckCurrentUserOrganisationErrorAlarm(false);
            }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>
        {msgCheckCurrentUserOrganisationAlarm}
      </Alert>
    </SubmissionNotificationDialog>

    <SubmissionNotificationDialog open={saveOrderErrorAlarm}>
      <Alert
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setSaveOrderErrorAlarm(false);
            }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>
        {msgAlarm}
      </Alert>
    </SubmissionNotificationDialog>

    <ConfirmDialog
      Yes={t("translation:Yes")}
      No={t("translation:No")}
      title={t("translation:Confirmation")}
      open={confirmSubmitOpen}
      setOpen={setConfirmSubmitOpen}
      onConfirm={(event) => onSave(event)}>
      {t("translation:Are you sure you want to proceed?")}
    </ConfirmDialog>

    {!disableSummaryView && addUserOpen && (
      <AddPatientDialog
        defaultUserSearchState={defaultUserSearchState}
        setDefaultUserSearchState={setDefaultUserSearchState}
        setAddUserOpen={setAddUserOpen}
        addUserOpen={addUserOpen}
      />
    )}
    <form>
      <SubmissionNotificationDialog open={saveErrorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSaveErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Missing Patient")}
        </Alert>
      </SubmissionNotificationDialog>

      {!disableSummaryView && (
        <Grid container>
          <Grid item xs={6}>
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                onClick={() => setAddUserOpen(true)}>
                {t("translation:Add User")}
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6}>
            {!disableSummaryView && (
              <PageSaveWBackAction
                onSave={(event) => {
                  handleOnSaveVisitOrder(event);
                }}
                setView={() => setConfirmAddOrderOpen(false)}
              />
            )}
          </Grid>
          <Grid xs={12} item className={classes.userInfoField}>
            <Autocomplete
              name="Physician"
              disableClearable
              value={physician}
              options={
                visitOrder.postPhysiciansList &&
                Object.keys(
                  visitOrder.postPhysiciansList.organisation_physician
                ).length !== 0
                  ? visitOrder.postPhysiciansList.organisation_physician.sort(
                      (a, b) => -b.user.name.localeCompare(a.user.name)
                    )
                  : []
              }
              getOptionLabel={(option) =>
                option.user.name ? option.user.name : ""
              }
              isOptionEqualToValue={(option, value) => {
                if (option.id === value.id) return option;
              }}
              style={{ width: "100%" }}
              onChange={(e, option) => setPhysician(option)}
              renderInput={(params) => (
                <TextField
                  required
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Physician")}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          {/* <Grid item xs={12}>
          <SortingSearchTable
            searchPlaceholder={t(
              "translation:Name/ Contact Number/ Identification Number"
            )}
            searchValue={searchValue}
            columns={columns}
            data={
              visitOrder.visitUserList ? visitOrder.visitUserList.users : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              visitOrder.visitUserList
                ? visitOrder.visitUserList.total_pages
                : 0
            }
            loading={visitOrder.isLoadingVisitStoreOrderList}
            totalItems={
              visitOrder.visitUserList
                ? visitOrder.visitUserList.total_items
                : 0
            }
            handleSearch={handleSearch}
          />
        </Grid> */}
        </Grid>
      )}

      <PatientSearch
        patient={patient}
        setPatient={setPatient}
        setDisableSummaryView={setDisableSummaryView}
        defaultUserSearchState={defaultUserSearchState}
        setDefaultUserSearchState={setDefaultUserSearchState}
      />
      {!disableSummaryView && <RequiredNote />}
      {!disableSummaryView && (
        <PageSaveWBackAction
          onSave={(event) => {
            handleOnSaveVisitOrder(event);
          }}
          setView={() => setConfirmAddOrderOpen(false)}
        />
      )}
    </form>
  </>;
}
