//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";

//actions
import {
  putUpdateMedicineFixedSellingPriceDetails,
  getMedicineFixedSellingPriceDetailsMedicineTypes,
  getMedicineFixedSellingPriceDetailsMedicineInfo,
  getMedicineFixedSellingPriceDetails,
} from "../../../../actions/medicineFixedSellingPriceDetailsActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";

export const EditMedicineFixedSellingPriceDetailsPage = ({
  setEditMedicineFixedSellingPriceDetailsView,
  headerInfo,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const medicineFixedSellingPriceDetailsData = useSelector(
    (state) => state.medicineFixedSellingPriceDetails
  );
  const common = useSelector((state) => state.common);

  const prevSubmitStatus = useRef();
  const prevSeachMedicineStatus = useRef();

  const dispatch = useDispatch();

  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  const [msgAlarm, setMsgAlarm] = useState("");

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  const [medicineTypeValue, setMedicineTypeValue] = useState(null);

  const [medicineValue, setMedicineValue] = useState(null);

  const [unitSellingValue, setUnitSellingValue] = useState(null);

  const [statusTypeValue, setStatusTypeValue] = useState(null);

  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const onHandleSeleteMedicineType = (data) => {
    setMedicineTypeValue(data);
    setMedicineValue(null);
    setStatusTypeValue(null);
    setUnitSellingValue(null);
    if (data) {
      dispatch(
        getMedicineFixedSellingPriceDetailsMedicineInfo.pending({
          is_pagination: false,
          search_medicine_type_id_value: data.id,
        })
      );
    }
  };

  const onHandleSeleteMedicine = (data) => {
    setMedicineValue(data);
    setStatusTypeValue(null);
    setUnitSellingValue(null);
    if (data) {
      dispatch(
        getMedicineFixedSellingPriceDetails.pending({
          is_pagination: false,
          search_medicine_id_value: data.id,
          search_medicine_fixed_selling_price_header_id_value: headerInfo.id,
        })
      );
    }
  };

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJson = {
      medicine_fixed_selling_price_header: {
        id: headerInfo.id,
        medicine_fixed_selling_price_details: [
          {
            medicine: {
              id: medicineValue.id,
            },
            status_type: statusTypeValue,
            unit_selling_price: unitSellingValue,
          },
        ],
        status_type: headerInfo.status_type,
      },
    };
    dispatch(putUpdateMedicineFixedSellingPriceDetails.pending(submitJson));
  };

  const { handleSubmit } = useForm({
    defaultValues: {
      id: 0,
      name: headerInfo.name,
      medicine_fixed_selling_price_header: null,
      status_type: null,
    },
  });

  useEffect(() => {
    //set ref when first render
    prevSubmitStatus.current = false;
    prevSeachMedicineStatus.current = false;
  }, []);

  useEffect(() => {
    dispatch(getMedicineFixedSellingPriceDetailsMedicineTypes.pending());
  }, [dispatch, viewOnly]);

  useEffect(() => {
    if (
      medicineFixedSellingPriceDetailsData.isLoadingGetMedicineFixedSellingPriceDetails !==
      prevSeachMedicineStatus.current
    ) {
      //check the previous ref with current state
      prevSeachMedicineStatus.current =
        medicineFixedSellingPriceDetailsData.isLoadingGetMedicineFixedSellingPriceDetails;

      if (
        medicineFixedSellingPriceDetailsData.getMedicineFixedSellingPriceDetailsIsSuccess ===
          true &&
        medicineFixedSellingPriceDetailsData.isLoadingGetMedicineFixedSellingPriceDetails ===
          false
      ) {
        if (
          medicineFixedSellingPriceDetailsData
            .getMedicineFixedSellingPriceDetails
            ?.medicine_fixed_selling_price_details.length
        ) {
          setStatusTypeValue(
            medicineFixedSellingPriceDetailsData
              .getMedicineFixedSellingPriceDetails
              ?.medicine_fixed_selling_price_details[0].status_type
          );
          setUnitSellingValue(
            medicineFixedSellingPriceDetailsData
              .getMedicineFixedSellingPriceDetails
              ?.medicine_fixed_selling_price_details[0].unit_selling_price
          );
        } else {
          setStatusTypeValue(null);
          setUnitSellingValue(null);
        }
      }
      if (
        medicineFixedSellingPriceDetailsData.getMedicineFixedSellingPriceDetailsIsSuccess ===
          false &&
        medicineFixedSellingPriceDetailsData.isLoadingGetMedicineFixedSellingPriceDetails ===
          false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(
          medicineFixedSellingPriceDetailsData.error
            .getMedicineFixedSellingPriceDetailsError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [medicineFixedSellingPriceDetailsData]);

  useEffect(() => {
    if (
      medicineFixedSellingPriceDetailsData.isLoadingPutUpdateMedicineFixedSellingPriceDetails !==
      prevSubmitStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitStatus.current =
        medicineFixedSellingPriceDetailsData.isLoadingPutUpdateMedicineFixedSellingPriceDetails;

      if (
        medicineFixedSellingPriceDetailsData.putUpdateMedicineFixedSellingPriceDetailsIsSuccess ===
          true &&
        medicineFixedSellingPriceDetailsData.isLoadingPutUpdateMedicineFixedSellingPriceDetails ===
          false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);

        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        medicineFixedSellingPriceDetailsData.putUpdateMedicineFixedSellingPriceDetailsIsSuccess ===
          false &&
        medicineFixedSellingPriceDetailsData.isLoadingPutUpdateMedicineFixedSellingPriceDetails ===
          false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(
          medicineFixedSellingPriceDetailsData.error
            .putUpdateMedicineFixedSellingPriceDetailsError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [medicineFixedSellingPriceDetailsData]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:Medicine Fixed Selling Price Details Details")
          : t("translation:Edit Medicine Fixed Selling Price Details")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:User Medicine Fixed Selling Price has been updated")}
        </Alert>
      </Collapse>
      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.root} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <Autocomplete
              className={classes.userFieldColor}
              value={medicineTypeValue}
              name="medicine_type"
              id="medicine_type"
              required
              options={
                medicineFixedSellingPriceDetailsData
                  .getMedicineFixedSellingPriceDetailsMedicineTypes
                  ?.medicine_types
                  ? medicineFixedSellingPriceDetailsData
                      .getMedicineFixedSellingPriceDetailsMedicineTypes
                      ?.medicine_types
                  : []
              }
              getOptionLabel={(option) => option.name}
              style={{ width: "100%" }}
              isOptionEqualToValue={(option, value) => {
                if (option.id === value.id) {
                  return option;
                }
              }}
              onChange={(e, option) => {
                onHandleSeleteMedicineType(option);
              }}
              renderInput={(params) => (
                <TextField
                  disabled={temViewOnly}
                  required
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Medicine Type")}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <Autocomplete
              className={classes.userFieldColor}
              disabled={!medicineTypeValue}
              value={medicineValue}
              name="medicine"
              id="medicine"
              required
              options={
                medicineFixedSellingPriceDetailsData
                  .getMedicineFixedSellingPriceDetailsMedicineInfo?.medicines
                  ? medicineFixedSellingPriceDetailsData
                      .getMedicineFixedSellingPriceDetailsMedicineInfo
                      ?.medicines
                  : []
              }
              getOptionLabel={(option) => option.name}
              style={{ width: "100%" }}
              isOptionEqualToValue={(option, value) => {
                if (option.id === value.id) {
                  return option;
                }
              }}
              onChange={(e, option) => {
                onHandleSeleteMedicine(option);
              }}
              renderInput={(params) => (
                <TextField
                  disabled={temViewOnly}
                  required
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Medicine")}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly || !medicineValue}
              className={classes.userFieldColor}
              value={unitSellingValue ? unitSellingValue : ""}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => setUnitSellingValue(e.target.value)}
              cy_data="unit_selling_price"
              onFocus={(event) => {
                event.target.select();
              }}
              name="unit_selling_price"
              required
              label={t("translation:Unit Selling Price")}
              variant="outlined"
              InputLabelProps={{
                shrink: !isNaN(unitSellingValue),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <Autocomplete
              disabled={temViewOnly || !medicineValue}
              value={statusTypeValue}
              name="status_type"
              id="status_type"
              required
              options={
                common.generalStatusList.status_general
                  ? common.generalStatusList.status_general
                  : []
              }
              getOptionLabel={(option) =>
                getOpObj(option, common.generalStatusList.status_general).name
              }
              style={{ width: "100%" }}
              isOptionEqualToValue={(option, value) => {
                if (option.id === value) {
                  return option;
                }
              }}
              onChange={(e, option) => setStatusTypeValue(option.id)}
              renderInput={(params) => (
                <TextField
                  disabled={temViewOnly}
                  required
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Status Type")}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <>
            <RequiredNote />

            {temViewOnly ? (
              <PageOnlyBackAction
                setView={() =>
                  setEditMedicineFixedSellingPriceDetailsView(false)
                }
              />
            ) : (
              <PageSubmitWBackAction
                setView={() =>
                  setEditMedicineFixedSellingPriceDetailsView(false)
                }
              />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
