//lib
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Link } from "@mui/material";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";

//actions
import {
  getGoogleStaticMapPayload,
  getDeliveryOrderVerifyAddressList,
  getDeliveryOrderDeliveryTrackingList,
} from "../../../../actions/deliveryOrderActions";
//STYLES
import { useStyles } from "../../../../components/globalStyles";
export default function ViewOptimizedRoutesDialog({
  groupOrder = [],
  setMsgAlarm = () => null,
  setAlertType = () => null,
  setAlertMsg = () => null,
  viewMode = false,
  deliveryOrderId = null,
}) {
  const prevVerifyAddressStatus = useRef();
  const prevViewRouteStatus = useRef();
  const prevGetTrackingStatus = useRef();
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const deliveryOrder = useSelector((state) => state.deliveryOrder);
  const curUser = useSelector((state) => state.curUser);
  const [imageString, setImageString] = useState(null);
  const [viewRoute, setViewRoute] = useState(false);
  const [temGroupOrder, setTemGroupOrder] = useState(groupOrder);
  const onClickViewRoute = (value) => {
    if (value.length - 1 === temGroupOrder.length && temGroupOrder.length) {
      const pointList = value.map((item) => {
        return { latitude: item.latitude, longitude: item.longitude };
      });

      const submission = { points: pointList };
      dispatch(getGoogleStaticMapPayload.pending(submission));
    } else {
      setMsgAlarm(t("translation:Failed to view routes"));
      setAlertType("error");
      setAlertMsg(true);
      setViewRoute(false);
    }
  };

  const onClickVerifyRoute = (value) => {
    const temAddressList = value.filter(
      (item) => item.delivery_postal_code && item.delivery_address
    );
    if (temAddressList.length && temAddressList.length === value.length) {
      const addressList = temAddressList.map((item) => {
        if (item.delivery_postal_code || item.delivery_address) {
          return {
            address: item.delivery_address,
            postal_code: item.delivery_postal_code,
          };
        }
      });

      const submission = { addresses: addressList };
      submission.addresses.splice(0, 0, {
        address: curUser.defaultBranch.branch.address,
        postal_code: curUser.defaultBranch.branch.postal_code,
      });
      setViewRoute(true);
      dispatch(getDeliveryOrderVerifyAddressList.pending(submission));
    } else {
      if (value.length === 0) {
        setMsgAlarm(t("translation:No delivery tracking order is found"));
      } else if (temAddressList.length !== value.length) {
        setMsgAlarm(t("translation:Informal address is found"));
      } else {
        setMsgAlarm(t("translation:Failed to view routes"));
      }
      setAlertType("error");
      setAlertMsg(true);
      setViewRoute(false);
    }
  };

  const onClickGetDeliveryTracking = () => {
    const submission = {
      is_pagination: false,
      order_by_direction: null,
      order_by_field: null,
      page_number: null,
      page_size: null,
      search_courier_id_value: null,
      search_courier_name_value: null,
      search_delivery_order_id_value: deliveryOrderId,
      search_delivery_order_number_value: null,
      search_delivery_tracking_order_id_value: null,
      search_end_date_value: null,
      search_end_timestamp_value: null,
      search_start_date_value: null,
      search_start_timestamp_value: null,
      search_status_type_value: null,
    };
    setViewRoute(true);
    dispatch(getDeliveryOrderDeliveryTrackingList.pending(submission));
  };

  //useEffects
  useEffect(() => {
    prevVerifyAddressStatus.current = false;
    prevViewRouteStatus.current = false;
    prevGetTrackingStatus.current = false;
  }, []);

  useEffect(() => {
    if (!viewMode) {
      setTemGroupOrder(groupOrder);
    }
  }, [groupOrder, viewMode]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingGetDeliveryOrderVerifyAddressList !==
      prevVerifyAddressStatus.current
    ) {
      //check the previous ref with current state
      prevVerifyAddressStatus.current =
        deliveryOrder.isLoadingGetDeliveryOrderVerifyAddressList;

      if (
        deliveryOrder.getDeliveryOrderVerifyAddressListIsSuccess === true &&
        deliveryOrder.isLoadingGetDeliveryOrderVerifyAddressList === false
      ) {
        onClickViewRoute(deliveryOrder.getDeliveryOrderVerifyAddressList);
      }

      if (
        deliveryOrder.getDeliveryOrderVerifyAddressListIsSuccess === false &&
        deliveryOrder.isLoadingGetDeliveryOrderVerifyAddressList === false
      ) {
        setMsgAlarm(t("translation:Failed to view routes"));
        setAlertType("error");
        setAlertMsg(true);
        setViewRoute(false);
      }
    }
  }, [dispatch, deliveryOrder]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingGetGoogleStaticMapPayload !==
      prevViewRouteStatus.current
    ) {
      //check the previous ref with current state
      prevViewRouteStatus.current =
        deliveryOrder.isLoadingGetGoogleStaticMapPayload;

      if (
        deliveryOrder.getGoogleStaticMapPayloadIsSuccess === true &&
        deliveryOrder.isLoadingGetGoogleStaticMapPayload === false
      ) {
        setImageString(deliveryOrder.getGoogleStaticMapPayload.data);
      }

      if (
        deliveryOrder.getGoogleStaticMapPayloadIsSuccess === false &&
        deliveryOrder.isLoadingGetGoogleStaticMapPayload === false
      ) {
        setImageString([]);
        setViewRoute(false);
        setMsgAlarm(deliveryOrder.error.getGoogleStaticMapPayloadError);
        setAlertType("error");
        setAlertMsg(true);
      }
    }
  }, [dispatch, deliveryOrder]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingGetDeliveryOrderDeliveryTrackingList !==
      prevGetTrackingStatus.current
    ) {
      //check the previous ref with current state
      prevGetTrackingStatus.current =
        deliveryOrder.isLoadingGetDeliveryOrderDeliveryTrackingList;

      if (
        deliveryOrder.getDeliveryOrderDeliveryTrackingListIsSuccess === true &&
        deliveryOrder.isLoadingGetDeliveryOrderDeliveryTrackingList === false
      ) {
        const result = [
          ...deliveryOrder.getDeliveryOrderDeliveryTrackingList
            .delivery_tracking_headers,
        ].sort((a, b) => {
          return a.row_no - b.row_no;
        });
        setTemGroupOrder(result);
        onClickVerifyRoute(result);
      }

      if (
        deliveryOrder.getDeliveryOrderDeliveryTrackingListIsSuccess === false &&
        deliveryOrder.isLoadingGetDeliveryOrderDeliveryTrackingList === false
      ) {
        setMsgAlarm(t("translation:Failed to view routes"));
        setAlertType("error");
        setAlertMsg(true);
        setViewRoute(false);
      }
    }
  }, [dispatch, deliveryOrder]);
  return (
    <Link className={classes.boldMaroon075}>
      <ConfirmDialog
        title={t("translation:View Routes")}
        open={viewRoute}
        setOpen={setViewRoute}
        maxWidth="md">
        <Grid item xs={12} container justifyContent="center">
          {imageString !== null && (
            <img
              src={imageString}
              height="640px"
              weight="640px"
              alt="routeMap"
            />
          )}
          {deliveryOrder.isLoadingGetGoogleStaticMapPayload === true &&
            t("translation:Loading") + "..."}
        </Grid>
      </ConfirmDialog>

      <Button
        className={classes.boldMaroon075}
        onClick={() =>
          viewMode
            ? onClickGetDeliveryTracking()
            : onClickVerifyRoute(temGroupOrder)
        }>
        {t("translation:View Routes")}
      </Button>
    </Link>
  );
}
