import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const branchPhysicianScheduleApis = {
  getBranchPhysicianScheduleListApi,
  getBranchPhysicianScheduleByIdApi,
  postBranchPhysicianScheduleByBranchIdPhysicianIdApi,
  getBranchPhysicianScheduleBranchListApi,
  getBranchPhysicianSchedulePhysicianListApi,
};

async function getBranchPhysicianScheduleListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetBranchPhysicianScheduleList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBranchPhysicianScheduleByIdApi(model) {
  const url = apiConstants.apiGetBranchPhysicianScheduleById + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postBranchPhysicianScheduleByBranchIdPhysicianIdApi(model) {
  const url =
    apiConstants.apiPostBranchPhysicianScheduleByBranchIdPhysicianId +
    model.branchId +
    "/" +
    model.physicianId;

  const requestOptions = JSON.stringify(model);
  return await axios
    .post(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBranchPhysicianScheduleBranchListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetBranchPhysicianScheduleBranchList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBranchPhysicianSchedulePhysicianListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetBranchPhysicianSchedulePhysicianList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default branchPhysicianScheduleApis;
