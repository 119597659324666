import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  putUserInfo,
  getOrganisationInfo,
  putOrganisationInfo,
  getOrganisation,
  putUserLang,
  getCurRoleRight,
  getAllRightAccess,
  getOrganisationBranchList,
  getOrganisationBranchListById,
  getDefaultBranch,
  postTopUpCredit,
  getCreditBalance,
  getAllAllianceOrganisation,
  getAllOrganisationStripe,
  getDefaultAlliance,
  getOrganisationLogo,
  getCurrentUserUserCreditList,
} from "../actions/curUserActions";
import * as types from "../_constants/curUserConstants";
import * as notification from "../_constants/notificationConstants";
import curUserApis from "../_apis/curUserApis";
export default function* curUserSagas() {
  yield all([
    takeLatest(
      types.PUT_ORGANISATIONINFO_ASYNC.PENDING,
      putOrganisationInfoCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_ORGANISATIONINFO_ASYNC.PENDING,
      getOrganisationInfoCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_ORGANISATIONBRANCHLIST_ASYNC.PENDING,
      getOrganisationBranchListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_ORGANISATIONBRANCHLISTBYID_ASYNC.PENDING,
      getOrganisationBranchListByIdCall
    ),
  ]);
  yield all([takeLatest(types.PUT_USERINFO_ASYNC.PENDING, putUserInfoCall)]);
  yield all([
    takeLatest(types.GET_CURROLERIGHT_ASYNC.PENDING, getCurRoleRightCall),
  ]);
  yield all([
    takeLatest(types.GET_ALLRIGHTACCESS_ASYNC.PENDING, getAllRightAccessCall),
  ]);
  yield all([takeLatest(types.PUT_USERLANG_ASYNC.PENDING, putUserLangCall)]);
  yield all([
    takeLatest(types.GET_DEFAULTBRANCH_ASYNC.PENDING, getDefaultBranchCall),
  ]);
  yield all([
    takeLatest(types.POST_TOPUPCREDIT_ASYNC.PENDING, postTopUpCreditCall),
  ]);

  yield all([
    takeLatest(types.GET_CREDITBALANCE_ASYNC.PENDING, getCreditBalanceCall),
  ]);

  yield all([
    takeLatest(
      types.GET_ALLALLIANCEORGANISATION_ASYNC.PENDING,
      getAllAllianceOrganisationCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_ALLORGANISATIONSTRIPE_ASYNC.PENDING,
      getAllOrganisationStripeCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_DEFAULTALLIANCE_ASYNC.PENDING, getDefaultAllianceCall),
  ]);

  yield all([
    takeLatest(
      types.GET_ORGANISATIONLOGO_ASYNC.PENDING,
      getOrganisationLogoCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_ORGANISATION_ASYNC.PENDING, getOrganisationCall),
  ]);

  yield all([
    takeLatest(
      types.GET_CURRENTUSERUSERCREDITLIST_ASYNC.PENDING,
      getCurrentUserUserCreditListCall
    ),
  ]);
}

function* putOrganisationInfoCall(userModel) {
  try {
    const data = yield call(curUserApis.putOrganisationInfoApi, userModel.data);
    yield put(putOrganisationInfo.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putOrganisationInfo.error(error.response?.data?.message));
    else yield put(putOrganisationInfo.error(notification.CONST_NOINTERNET));
  }
}

function* getOrganisationInfoCall() {
  try {
    const data = yield call(curUserApis.getOrganisationInfoApi);
    yield put(getOrganisationInfo.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getOrganisationInfo.error(error.response?.data?.message));
    else yield put(getOrganisationInfo.error(notification.CONST_NOINTERNET));
  }
}

function* getOrganisationCall(model) {
  try {
    const data = yield call(curUserApis.getOrganisationApi, model.data);
    yield put(getOrganisation.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getOrganisation.error(error.response?.data?.message));
    else yield put(getOrganisation.error(notification.CONST_NOINTERNET));
  }
}

function* getOrganisationBranchListCall() {
  try {
    const data = yield call(curUserApis.getOrganisationBranchListApi);
    yield put(getOrganisationBranchList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getOrganisationBranchList.error(error.response?.data?.message));
    else
      yield put(getOrganisationBranchList.error(notification.CONST_NOINTERNET));
  }
}

function* getOrganisationBranchListByIdCall(model) {
  try {
    const data = yield call(
      curUserApis.getOrganisationBranchListByIdApi,
      model.data
    );
    yield put(getOrganisationBranchListById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getOrganisationBranchListById.error(error.response?.data?.message)
      );
    else
      yield put(
        getOrganisationBranchListById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUserInfoCall(userModel) {
  try {
    const data = yield call(curUserApis.putUserInfoApi, userModel.data);
    yield put(putUserInfo.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUserInfo.error(error.response?.data?.message));
    else yield put(putUserInfo.error(notification.CONST_NOINTERNET));
  }
}

function* getCurRoleRightCall() {
  try {
    const data = yield call(curUserApis.getCurRoleRightApi);
    yield put(getCurRoleRight.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getCurRoleRight.error(error.response?.data?.message));
    else yield put(getCurRoleRight.error(notification.CONST_NOINTERNET));
  }
}

function* getAllRightAccessCall() {
  try {
    const data = yield call(curUserApis.getAllRightAccessApi);
    yield put(getAllRightAccess.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAllRightAccess.error(error.response?.data?.message));
    else yield put(getAllRightAccess.error(notification.CONST_NOINTERNET));
  }
}

function* putUserLangCall(userModel) {
  try {
    const data = yield call(curUserApis.putUserLangApi, userModel.data);
    yield put(putUserLang.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUserLang.error(error.response?.data?.message));
    else yield put(putUserLang.error(notification.CONST_NOINTERNET));
  }
}

function* getDefaultBranchCall() {
  try {
    const data = yield call(curUserApis.getDefaultBranchApi);
    yield put(getDefaultBranch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDefaultBranch.error(error.response?.data?.message));
    else yield put(getDefaultBranch.error(notification.CONST_NOINTERNET));
  }
}

function* postTopUpCreditCall(model) {
  try {
    const data = yield call(curUserApis.postTopUpCreditApi, model.data);
    yield put(postTopUpCredit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postTopUpCredit.error(error.response?.data?.message));
    else yield put(postTopUpCredit.error(notification.CONST_NOINTERNET));
  }
}

function* getCreditBalanceCall() {
  try {
    const data = yield call(curUserApis.getCreditBalanceApi);
    yield put(getCreditBalance.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getCreditBalance.error(error.response?.data?.message));
    else yield put(getCreditBalance.error(notification.CONST_NOINTERNET));
  }
}

function* getAllAllianceOrganisationCall() {
  try {
    const data = yield call(curUserApis.getAllAllianceOrganisationApi);
    yield put(getAllAllianceOrganisation.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getAllAllianceOrganisation.error(error.response?.data?.message)
      );
    else
      yield put(
        getAllAllianceOrganisation.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getAllOrganisationStripeCall() {
  try {
    const data = yield call(curUserApis.getAllOrganisationStripeApi);
    yield put(getAllOrganisationStripe.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAllOrganisationStripe.error(error.response?.data?.message));
    else
      yield put(getAllOrganisationStripe.error(notification.CONST_NOINTERNET));
  }
}

function* getDefaultAllianceCall() {
  try {
    const data = yield call(curUserApis.getDefaultAllianceApi);
    yield put(getDefaultAlliance.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDefaultAlliance.error(error.response?.data?.message));
    else yield put(getDefaultAlliance.error(notification.CONST_NOINTERNET));
  }
}

function* getOrganisationLogoCall(model) {
  try {
    const data = yield call(curUserApis.getOrganisationLogoApi, model.data);
    yield put(getOrganisationLogo.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getOrganisationLogo.error(error.response?.data?.message));
    if (error.response.status === 404)
      yield put(getOrganisationLogo.error("Missing Organisation Logo"));
    else yield put(getOrganisationLogo.error(notification.CONST_NOINTERNET));
  }
}

function* getCurrentUserUserCreditListCall(model) {
  try {
    const data = yield call(
      curUserApis.getCurrentUserUserCreditListApi,
      model.data
    );
    yield put(getCurrentUserUserCreditList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getCurrentUserUserCreditList.error(error.response?.data?.message)
      );
    else
      yield put(
        getCurrentUserUserCreditList.error(notification.CONST_NOINTERNET)
      );
  }
}
