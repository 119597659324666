import * as types from "../_constants/userConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_USERLIST_ASYNC.PENDING:
      return {
        ...state,
        getUserListIsSuccess: false,
        isLoadingUserList: true,
        userList: null,
      };

    case types.GET_USERLIST_ASYNC.ERROR:
      return {
        ...state,
        getUserListIsSuccess: false,
        isLoadingUserList: false,
        userList: null,
        error: { ...state.error, userListError: action.error },
      };

    case types.GET_USERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserListIsSuccess: true,
        isLoadingUserList: false,
        userList: action.data,
      };
    case types.GET_USERBYID_ASYNC.PENDING:
      return {
        ...state,
        getUserByIdIsSuccess: false,
        isLoadingGetUserById: true,
        getUserById: null,
      };

    case types.GET_USERBYID_ASYNC.ERROR:
      return {
        ...state,
        getUserByIdIsSuccess: false,
        isLoadingGetUserById: false,
        getUserById: null,
        error: { ...state.error, getUserByIdError: action.error },
      };

    case types.GET_USERBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getUserByIdIsSuccess: true,
        isLoadingGetUserById: false,
        getUserById: action.data,
      };

    case types.GET_ALLUSERLIST_ASYNC.PENDING:
      return {
        ...state,
        getAllUserListIsSuccess: false,
        isLoadingAllUserList: true,
        allUserList: null,
      };

    case types.GET_ALLUSERLIST_ASYNC.ERROR:
      return {
        ...state,
        getAllUserListIsSuccess: false,
        isLoadingAllUserList: false,
        allUserList: null,
        error: { ...state.error, allUserListError: action.error },
      };

    case types.GET_ALLUSERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getAllUserListIsSuccess: true,
        isLoadingAllUserList: false,
        allUserList: action.data,
      };

    case types.PUT_EDITUSER_ASYNC.PENDING:
      return {
        ...state,
        putEditUserSuccess: false,
        isLoadingEditUser: true,
        putEditUser: null,
      };

    case types.PUT_EDITUSER_ASYNC.ERROR:
      return {
        ...state,
        putEditUserSuccess: false,
        isLoadingEditUser: false,
        putEditUser: null,
        error: { ...state.error, EditUserError: action.error },
      };

    case types.PUT_EDITUSER_ASYNC.SUCCESS:
      return {
        ...state,
        putEditUserSuccess: true,
        isLoadingEditUser: false,
        putEditUser: action.data,
      };

    case types.POST_CREATEUSER_ASYNC.PENDING:
      return {
        ...state,
        postCreateUserSuccess: false,
        isLoadingCreateUser: true,
        postCreateUser: null,
      };

    case types.POST_CREATEUSER_ASYNC.ERROR:
      return {
        ...state,
        postCreateUserSuccess: false,
        isLoadingCreateUser: false,
        postCreateUser: null,
        error: { ...state.error, CreateUserError: action.error },
      };

    case types.POST_CREATEUSER_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateUserSuccess: true,
        isLoadingCreateUser: false,
        postCreateUser: action.data,
      };

    case types.PUT_PHYSICIANLICENSENOBYID_ASYNC.PENDING:
      return {
        ...state,
        putPhysicianLicenseNoSuccess: false,
        isLoadingPutPhysicianLicenseNo: true,
        putPhysicianLicenseNo: null,
      };

    case types.PUT_PHYSICIANLICENSENOBYID_ASYNC.ERROR:
      return {
        ...state,
        putPhysicianLicenseNoSuccess: false,
        isLoadingPutPhysicianLicenseNo: false,
        putPhysicianLicenseNo: null,
        error: {
          ...state.error,
          putPhysicianLicenseNoError: action.error,
        },
      };

    case types.PUT_PHYSICIANLICENSENOBYID_ASYNC.SUCCESS:
      return {
        ...state,
        putPhysicianLicenseNoSuccess: true,
        isLoadingPutPhysicianLicenseNo: false,
        putPhysicianLicenseNo: action.data,
      };

    case types.POST_FAILEDLOGINCOUNT_ASYNC.PENDING:
      return {
        ...state,
        postFailedLoginCountIsSuccess: false,
        isLoadingPostFailedLoginCount: true,
        postFailedLoginCount: null,
      };

    case types.POST_FAILEDLOGINCOUNT_ASYNC.ERROR:
      return {
        ...state,
        postFailedLoginCountIsSuccess: false,
        isLoadingPostFailedLoginCount: false,
        postFailedLoginCount: null,
        error: {
          ...state.error,
          postFailedLoginCountError: action.error,
        },
      };

    case types.POST_FAILEDLOGINCOUNT_ASYNC.SUCCESS:
      return {
        ...state,
        postFailedLoginCountIsSuccess: true,
        isLoadingPostFailedLoginCount: false,
        postFailedLoginCount: action.data,
      };

    case types.GET_LOGINATTEMPTMAX_ASYNC.PENDING:
      return {
        ...state,
        getLoginAttemptMaxIsSuccess: false,
        isLoadingGetLoginAttemptMax: true,
        getLoginAttemptMax: null,
      };

    case types.GET_LOGINATTEMPTMAX_ASYNC.ERROR:
      return {
        ...state,
        getLoginAttemptMaxIsSuccess: false,
        isLoadingGetLoginAttemptMax: false,
        getLoginAttemptMax: null,
        error: {
          ...state.error,
          getLoginAttemptMaxError: action.error,
        },
      };

    case types.GET_LOGINATTEMPTMAX_ASYNC.SUCCESS:
      return {
        ...state,
        getLoginAttemptMaxIsSuccess: true,
        isLoadingGetLoginAttemptMax: false,
        getLoginAttemptMax: action.data,
      };

    default:
      return state;
  }
};
