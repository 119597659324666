import * as types from "../_constants/roleConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_ALLROLELIST_ASYNC.PENDING:
      return {
        ...state,
        getAllRoleListIsSuccess: false,
        isLoadingAllRoleList: true,
        allRoleList: null,
      };

    case types.GET_ALLROLELIST_ASYNC.ERROR:
      return {
        ...state,
        getAllRoleListIsSuccess: false,
        isLoadingAllRoleList: false,
        allRoleList: null,
        error: { ...state.error, allRoleListError: action.error },
      };

    case types.GET_ALLROLELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getAllRoleListIsSuccess: true,
        isLoadingAllRoleList: false,
        allRoleList: action.data,
      };

    case types.GET_ROLELIST_ASYNC.PENDING:
      return {
        ...state,
        getRoleListIsSuccess: false,
        isLoadingRoleList: true,
        getRoleList: null,
      };

    case types.GET_ROLELIST_ASYNC.ERROR:
      return {
        ...state,
        getRoleListIsSuccess: false,
        isLoadingRoleList: false,
        getRoleList: null,
        error: { ...state.error, getRoleListError: action.error },
      };

    case types.GET_ROLELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getRoleListIsSuccess: true,
        isLoadingRoleList: false,
        getRoleList: action.data,
      };

    case types.GET_ROLEBYID_ASYNC.PENDING:
      return {
        ...state,
        getRoleByIdIsSuccess: false,
        isLoadingGetRoleById: true,
        getRoleById: null,
      };

    case types.GET_ROLEBYID_ASYNC.ERROR:
      return {
        ...state,
        getRoleByIdIsSuccess: false,
        isLoadingGetRoleById: false,
        getRoleById: null,
        error: { ...state.error, getRoleByIdError: action.error },
      };

    case types.GET_ROLEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getRoleByIdIsSuccess: true,
        isLoadingGetRoleById: false,
        getRoleById: action.data,
      };

    case types.POST_CREATEROLE_ASYNC.PENDING:
      return {
        ...state,
        postCreateRoleIsSuccess: false,
        isLoadingPostCreateRole: true,
        postCreateRole: null,
      };

    case types.POST_CREATEROLE_ASYNC.ERROR:
      return {
        ...state,
        postCreateRoleIsSuccess: false,
        isLoadingPostCreateRole: false,
        postCreateRole: null,
        error: { ...state.error, postCreateRoleError: action.error },
      };

    case types.POST_CREATEROLE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateRoleIsSuccess: true,
        isLoadingPostCreateRole: false,
        postCreateRole: action.data,
      };

    case types.PUT_UPDATEROLE_ASYNC.PENDING:
      return {
        ...state,
        putUpdateRoleIsSuccess: false,
        isLoadingPutUpdateRole: true,
        putUpdateRole: null,
      };

    case types.PUT_UPDATEROLE_ASYNC.ERROR:
      return {
        ...state,
        putUpdateRoleIsSuccess: false,
        isLoadingPutUpdateRole: false,
        putUpdateRole: null,
        error: { ...state.error, putUpdateRoleError: action.error },
      };

    case types.PUT_UPDATEROLE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateRoleIsSuccess: true,
        isLoadingPutUpdateRole: false,
        putUpdateRole: action.data,
      };

    default:
      return state;
  }
};
