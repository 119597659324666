import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const userCreditApis = {
  getUserCreditListApi,
  postCreateUserCreditApi,
  getUserCreditUserListApi,
  getUserCreditByIdApi,
  getUserCreditBalanceByIdApi,
};

async function getUserCreditListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetUserCreditList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreateUserCreditApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostCreateUserCredit, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getUserCreditUserListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetUserCreditUserList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getUserCreditByIdApi(model) {
  const url = apiConstants.apiGetUserCreditById + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getUserCreditBalanceByIdApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetUserCreditBalanceById,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default userCreditApis;
