//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
//actions
import {
  putUpdateEmailStore,
  getEmailStoreBranchMedicine,
} from "../../../actions/emailStoreActions";

import { getMedicineMeasureUnit } from "../../../actions/commonActions";

//components
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useStyles } from "../../../components/globalStyles";
import RequiredNote from "../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";

import MedicineListTable from "../components/MedicineListTable";
import * as generalConstants from "../../../_constants/generalConstants";

export const EditEmailStorePage = ({
  emailStore,
  setEditEmailStoreView,
  setEmailStoreInfo,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  const [msgAlarm, setMsgAlarm] = useState("");
  const emailStoreData = useSelector((state) => state.emailStore);

  const common = useSelector((state) => state.common);
  const prevSubmitStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [editMedicineDialog, setEditMedicineDialog] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  const [selectedValue, setSelectedValue] = useState(null);

  //functions

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJson = {
      email_store_header: {
        id: emailStore.id,
        email_store_medicine_details: emailStore.email_store_medicine_details,
      },
    };
    dispatch(putUpdateEmailStore.pending(submitJson));
  };

  const onConfirmEdit = () => {
    const result = emailStore.email_store_medicine_details;
    const editIndex = result.findIndex((item) => item.id === selectedValue.id);

    const detailsList = emailStore;

    detailsList.email_store_medicine_details[editIndex] = selectedValue;

    setEmailStoreInfo(detailsList);

    setSelectedValue(null);
  };

  const { register, handleSubmit, reset, control } = useForm();
  useEffect(() => {
    //set ref when first render
    prevSubmitStatus.current = false;
  }, []);

  useEffect(() => {
    dispatch(
      getEmailStoreBranchMedicine.pending({
        search_medicine_type_id_value: emailStore.medicine_type.id,
        is_pagination: false,
      })
    );
    dispatch(getMedicineMeasureUnit.pending());
  }, [dispatch]);

  useEffect(() => {
    if (
      emailStoreData.isLoadingPutUpdateEmailStore !== prevSubmitStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitStatus.current = emailStoreData.isLoadingPutUpdateEmailStore;

      if (
        emailStoreData.putUpdateEmailStoreIsSuccess === true &&
        emailStoreData.isLoadingPutUpdateEmailStore === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...emailStoreData.putUpdateEmailStore.email_store_header,
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        emailStoreData.putUpdateEmailStoreIsSuccess === false &&
        emailStoreData.isLoadingPutUpdateEmailStore === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(emailStoreData.error.putUpdateEmailStoreError);
        window.scrollTo(0, 0);
      }
    }
  }, [emailStoreData, reset]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:Email Store Details")
          : t("translation:Edit Email Store")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      {selectedValue !== null && emailStoreData.getEmailStoreBranchMedicine && (
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={editMedicineDialog}
          setOpen={setEditMedicineDialog}
          onConfirm={onConfirmEdit}>
          <Grid container>
            <Grid item xs={12} className={classes.subTotalInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={selectedValue.medicine_name}
                label={t("translation:Order Medicine Name")}
                variant="outlined"
                disabled
              />
            </Grid>

            <Grid item xs={12} className={classes.subTotalInfoField}>
              <Autocomplete
                name="branchMedicine"
                disableClearable
                options={
                  emailStoreData.getEmailStoreBranchMedicine?.branch_medicine
                }
                getOptionLabel={(option) =>
                  option.medicines.name ? option.medicines.name : ""
                }
                value={selectedValue.branch_medicine}
                isOptionEqualToValue={(option, value) => {
                  if (option.id === value.id) return option;
                }}
                onChange={(e, option) => {
                  const replaceOption = {
                    ...selectedValue,
                    branch_medicine: option,
                    status_type: generalConstants.APPROVED_STATUS_TYPE,
                  };

                  setSelectedValue(replaceOption);
                }}
                style={{ width: "100%" }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.medicines.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Medicine")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </ConfirmDialog>
      )}
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Email Store has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />
      <Panel>
        <Grid container>
          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={emailStore.medicine_type.name}
              name="medicine_type"
              label={t("translation:Medicine Type")}
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={moment
                .utc(emailStore.email_received_on)
                .local()
                .format("DD-MMM-YYYY HH:mm")}
              name="email_received_on"
              label={t("translation:Received Date")}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
      </Panel>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.root} container>
          <>
            <MedicineListTable
              data={emailStore.email_store_medicine_details}
              setSelectedValue={setSelectedValue}
              setEditMedicineDialog={setEditMedicineDialog}
              viewOnly={temViewOnly}
            />
            <RequiredNote />

            {temViewOnly ? (
              <PageOnlyBackAction
                setView={() => setEditEmailStoreView(false)}
              />
            ) : (
              <PageSubmitWBackAction
                setView={() => setEditEmailStoreView(false)}
              />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
