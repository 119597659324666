import Checkbox from "@mui/material/Checkbox";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

//styles

export default function StyledRadioCheckbox(props) {
  return (
    <Checkbox
      size="small"
      disableRipple
      style={{
        color: "rgba(149, 0, 0,0.9)",
      }}
      checkedIcon={<RadioButtonCheckedIcon />}
      icon={<RadioButtonUncheckedIcon />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
}
