import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getEmailStoreRecipientList,
  getEmailStoreRecipientById,
  postCreateEmailStoreRecipient,
  putUpdateEmailStoreRecipient,
} from "../actions/emailStoreRecipientActions";
import * as types from "../_constants/emailStoreRecipientConstants";
import * as notification from "../_constants/notificationConstants";
import emailStoreRecipientApis from "../_apis/emailStoreRecipientApis";
export default function* emailStoreRecipientSagas() {
  yield all([
    takeLatest(
      types.GET_EMAILSTORERECIPIENTLIST_ASYNC.PENDING,
      getEmailStoreRecipientListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_EMAILSTORERECIPIENTBYID_ASYNC.PENDING,
      getEmailStoreRecipientByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATEEMAILSTORERECIPIENT_ASYNC.PENDING,
      postCreateEmailStoreRecipientCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEEMAILSTORERECIPIENT_ASYNC.PENDING,
      putUpdateEmailStoreRecipientCall
    ),
  ]);
}

function* getEmailStoreRecipientListCall(searchModel) {
  try {
    const data = yield call(
      emailStoreRecipientApis.getEmailStoreRecipientListApi,
      searchModel.data
    );
    yield put(getEmailStoreRecipientList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getEmailStoreRecipientList.error(error.response?.data?.message)
      );
    else
      yield put(
        getEmailStoreRecipientList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getEmailStoreRecipientByIdCall(searchModel) {
  try {
    const data = yield call(
      emailStoreRecipientApis.getEmailStoreRecipientByIdApi,
      searchModel.data
    );
    yield put(getEmailStoreRecipientById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getEmailStoreRecipientById.error(error.response?.data?.message)
      );
    else
      yield put(
        getEmailStoreRecipientById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postCreateEmailStoreRecipientCall(searchModel) {
  try {
    const data = yield call(
      emailStoreRecipientApis.postCreateEmailStoreRecipientApi,
      searchModel.data
    );
    yield put(postCreateEmailStoreRecipient.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postCreateEmailStoreRecipient.error(error.response?.data?.message)
      );
    else
      yield put(
        postCreateEmailStoreRecipient.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateEmailStoreRecipientCall(searchModel) {
  try {
    const data = yield call(
      emailStoreRecipientApis.putUpdateEmailStoreRecipientApi,
      searchModel.data
    );
    yield put(putUpdateEmailStoreRecipient.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateEmailStoreRecipient.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdateEmailStoreRecipient.error(notification.CONST_NOINTERNET)
      );
  }
}
