import * as types from "../_constants/dashboardConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_DASHBOARDAPPOINTMENTLIST_ASYNC.PENDING:
      return {
        ...state,
        getDashboardAppointmentListSuccess: false,
        isLoadingDashboardAppointmentList: true,
        dashboardAppointmentList: null,
      };

    case types.GET_DASHBOARDAPPOINTMENTLIST_ASYNC.ERROR:
      return {
        ...state,
        getDashboardAppointmentListSuccess: false,
        isLoadingDashboardAppointmentList: false,
        dashboardAppointmentList: null,
        error: { ...state.error, dashboardAppointmentListError: action.error },
      };

    case types.GET_DASHBOARDAPPOINTMENTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDashboardAppointmentListSuccess: true,
        isLoadingDashboardAppointmentList: false,
        dashboardAppointmentList: action.data,
      };

    case types.GET_DASHBOARDBREWINGLIST_ASYNC.PENDING:
      return {
        ...state,
        getDashboardBrewingListSuccess: false,
        isLoadingDashboardBrewingList: true,
        dashboardBrewingList: null,
      };

    case types.GET_DASHBOARDBREWINGLIST_ASYNC.ERROR:
      return {
        ...state,
        getDashboardBrewingListSuccess: false,
        isLoadingDashboardBrewingList: false,
        dashboardBrewingList: null,
        error: { ...state.error, dashboardBrewingListError: action.error },
      };

    case types.GET_DASHBOARDBREWINGLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDashboardBrewingListSuccess: true,
        isLoadingDashboardBrewingList: false,
        dashboardBrewingList: action.data,
      };

    case types.GET_DASHBOARDDELIVERYORDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getdashboardDeliveryOrderListSuccess: false,
        isLoadingDashboardDeliveryOrderList: true,
        dashboardDeliveryOrderList: null,
      };

    case types.GET_DASHBOARDDELIVERYORDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getdashboardDeliveryOrderListSuccess: false,
        isLoadingDashboardDeliveryOrderList: false,
        dashboardDeliveryOrderList: null,
        error: {
          ...state.error,
          dashboardDeliveryOrderListError: action.error,
        },
      };

    case types.GET_DASHBOARDDELIVERYORDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getdashboardDeliveryOrderListSuccess: true,
        isLoadingDashboardDeliveryOrderList: false,
        dashboardDeliveryOrderList: action.data,
      };
    case types.GET_DASHBOARDDISPENSARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getdashboardDispensaryListSuccess: false,
        isLoadingDashboardDispensaryList: true,
        dashboardDispensaryList: null,
      };

    case types.GET_DASHBOARDDISPENSARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getdashboardDispensaryListSuccess: false,
        isLoadingDashboardDispensaryList: false,
        dashboardDispensaryList: null,
        error: { ...state.error, dashboardDispensaryListError: action.error },
      };

    case types.GET_DASHBOARDDISPENSARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getdashboardDispensaryListSuccess: true,
        isLoadingDashboardDispensaryList: false,
        dashboardDispensaryList: action.data,
      };

    case types.GET_DASHBOARDINVOICELIST_ASYNC.PENDING:
      return {
        ...state,
        getdashboardInvoiceListSuccess: false,
        isLoadingDashboardInvoiceList: true,
        dashboardInvoiceList: null,
      };

    case types.GET_DASHBOARDINVOICELIST_ASYNC.ERROR:
      return {
        ...state,
        getdashboardInvoiceListSuccess: false,
        isLoadingDashboardInvoiceList: false,
        dashboardInvoiceList: null,
        error: { ...state.error, dashboardInvoiceListError: action.error },
      };

    case types.GET_DASHBOARDINVOICELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getdashboardInvoiceListSuccess: true,
        isLoadingDashboardInvoiceList: false,
        dashboardInvoiceList: action.data,
      };

    case types.GET_DASHBOARDINVOICEMEDICINECOSTINGLIST_ASYNC.PENDING:
      return {
        ...state,
        getDashboardInvoiceMedicineCostingListSuccess: false,
        isLoadingDashboardInvoiceMedicineCostingList: true,
        dashboardInvoiceMedicineCostingList: null,
      };

    case types.GET_DASHBOARDINVOICEMEDICINECOSTINGLIST_ASYNC.ERROR:
      return {
        ...state,
        getDashboardInvoiceMedicineCostingListSuccess: false,
        isLoadingDashboardInvoiceMedicineCostingList: false,
        dashboardInvoiceMedicineCostingList: null,
        error: {
          ...state.error,
          dashboardInvoiceMedicineCostingListError: action.error,
        },
      };

    case types.GET_DASHBOARDINVOICEMEDICINECOSTINGLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDashboardInvoiceMedicineCostingListSuccess: true,
        isLoadingDashboardInvoiceMedicineCostingList: false,
        dashboardInvoiceMedicineCostingList: action.data,
      };

    case types.GET_DASHBOARDSTOREORDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getdashboardStoreOrderListSuccess: false,
        isLoadingDashboardStoreOrderList: true,
        dashboardStoreOrderList: null,
      };

    case types.GET_DASHBOARDSTOREORDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getdashboardStoreOrderListSuccess: false,
        isLoadingDashboardStoreOrderList: false,
        dashboardStoreOrderList: null,
        error: { ...state.error, dashboardStoreOrderListError: action.error },
      };

    case types.GET_DASHBOARDSTOREORDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getdashboardStoreOrderListSuccess: true,
        isLoadingDashboardStoreOrderList: false,
        dashboardStoreOrderList: action.data,
      };

    case types.GET_DASHBOARDVISITORDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getDashboardVisitOrderListSuccess: false,
        isLoadingDashboardVisitOrderList: true,
        dashboardVisitOrderList: null,
      };

    case types.GET_DASHBOARDVISITORDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getDashboardVisitOrderListSuccess: false,
        isLoadingDashboardVisitOrderList: false,
        dashboardVisitOrderList: null,
        error: { ...state.error, dashboardVisitOrderListError: action.error },
      };

    case types.GET_DASHBOARDVISITORDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDashboardVisitOrderListSuccess: true,
        isLoadingDashboardVisitOrderList: false,
        dashboardVisitOrderList: action.data,
      };

    case types.GET_DASHBOARDALLAPPROVEDLOCALDISPENSARY_ASYNC.PENDING:
      return {
        ...state,
        getDashboardAllApprovedLocalDispensarySuccess: false,
        isLoadingDashboardAllApprovedLocalDispensary: true,
        dashboardAllApprovedLocalDispensary: null,
      };

    case types.GET_DASHBOARDALLAPPROVEDLOCALDISPENSARY_ASYNC.ERROR:
      return {
        ...state,
        getDashboardAllApprovedLocalDispensarySuccess: false,
        isLoadingDashboardAllApprovedLocalDispensary: false,
        dashboardAllApprovedLocalDispensary: null,
        error: {
          ...state.error,
          dashboardAllApprovedLocalDispensaryError: action.error,
        },
      };

    case types.GET_DASHBOARDALLAPPROVEDLOCALDISPENSARY_ASYNC.SUCCESS:
      return {
        ...state,
        getDashboardAllApprovedLocalDispensarySuccess: true,
        isLoadingDashboardAllApprovedLocalDispensary: false,
        dashboardAllApprovedLocalDispensary: action.data,
      };

    case types.GET_DASHBOARDALLSTOREWDISPENSARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getDashboardAllStoreWDispensaryListSuccess: false,
        isLoadingDashboardAllStoreWDispensaryList: true,
        dashboardAllStoreWDispensaryList: null,
      };

    case types.GET_DASHBOARDALLSTOREWDISPENSARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getDashboardAllStoreWDispensaryListSuccess: false,
        isLoadingDashboardAllStoreWDispensaryList: false,
        dashboardAllStoreWDispensaryList: null,
        error: {
          ...state.error,
          dashboardAllStoreWDispensaryListError: action.error,
        },
      };

    case types.GET_DASHBOARDALLSTOREWDISPENSARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDashboardAllStoreWDispensaryListSuccess: true,
        isLoadingDashboardAllStoreWDispensaryList: false,
        dashboardAllStoreWDispensaryList: action.data,
      };

    case types.GET_DASHBOARDCOUNTERVIEW_ASYNC.PENDING:
      return {
        ...state,
        getDashboardCounterViewIsSuccess: false,
        isLoadingGetDashboardCounterView: true,
        dashboardCounterView: null,
      };

    case types.GET_DASHBOARDCOUNTERVIEW_ASYNC.ERROR:
      return {
        ...state,
        getDashboardCounterViewIsSuccess: false,
        isLoadingGetDashboardCounterView: false,
        dashboardCounterView: null,
        error: {
          ...state.error,
          getDashboardCounterViewError: action.error,
        },
      };

    case types.GET_DASHBOARDCOUNTERVIEW_ASYNC.SUCCESS:
      return {
        ...state,
        getDashboardCounterViewIsSuccess: true,
        isLoadingGetDashboardCounterView: false,
        dashboardCounterView: action.data,
      };

    default:
      return state;
  }
};
