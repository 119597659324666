import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/userMedicineFixedSellingPriceConstants";

export const getUserMedicineFixedSellingPriceById = {
  pending: (data) =>
    createAction(types.GET_USERMEDICINEFIXEDSELLINGPRICEBYID_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_USERMEDICINEFIXEDSELLINGPRICEBYID_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_USERMEDICINEFIXEDSELLINGPRICEBYID_ASYNC.ERROR, {
      error,
    }),
};

export const getUserMedicineFixedSellingPriceList = {
  pending: (data) =>
    createAction(types.GET_USERMEDICINEFIXEDSELLINGPRICELIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_USERMEDICINEFIXEDSELLINGPRICELIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_USERMEDICINEFIXEDSELLINGPRICELIST_ASYNC.ERROR, {
      error,
    }),
};

export const getUserMedicineFixedSellingPriceSummaryList = {
  pending: (data) =>
    createAction(
      types.GET_USERMEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_USERMEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_USERMEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const postCreateUserMedicineFixedSellingPrice = {
  pending: (data) =>
    createAction(types.POST_CREATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_CREATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_CREATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.ERROR, {
      error,
    }),
};

export const putUpdateUserMedicineFixedSellingPrice = {
  pending: (data) =>
    createAction(types.PUT_UPDATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_UPDATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_UPDATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.ERROR, {
      error,
    }),
};

export const getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList = {
  pending: (data) =>
    createAction(
      types.GET_USERMEDICINEFIXEDSELLINGPRICEMEDICINEFIXEDSELLINGPRICELIST_ASYNC
        .PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_USERMEDICINEFIXEDSELLINGPRICEMEDICINEFIXEDSELLINGPRICELIST_ASYNC
        .SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_USERMEDICINEFIXEDSELLINGPRICEMEDICINEFIXEDSELLINGPRICELIST_ASYNC
        .ERROR,
      {
        error,
      }
    ),
};

export const getUserMedicineFixedSellingPriceUserList = {
  pending: (data) =>
    createAction(
      types.GET_USERMEDICINEFIXEDSELLINGPRICEUSERLIST_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_USERMEDICINEFIXEDSELLINGPRICEUSERLIST_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.GET_USERMEDICINEFIXEDSELLINGPRICEUSERLIST_ASYNC.ERROR, {
      error,
    }),
};
