//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@mui/material";
//actions
import {
  putDispensaryWIPMode,
  putDispensaryPendingStatus,
  getDispensarySummaryList,
  postSubmitDispensary,
  getDispensaryById,
  getDispensaryInvoiceOrder,
} from "../../../actions/dispensaryActions";
//components
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import { ReactComponent as ProceedLogo } from "../../../assets/coolicons/media/fast_forward.svg";
import { ReactComponent as ProceedAllLogo } from "../../../assets/coolicons/media/skip_next.svg";
import { ReactComponent as PendingLogo } from "../../../assets/coolicons/media/fast_rewind.svg";
import OrderDetailPage from "./OrderDetailPage";
import OrderProceedPage from "./OrderProceedPage";
import * as pageConfigure from "../../../_constants/pageConstants";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import * as generalConstants from "../../../_constants/generalConstants";

import { getTableDefaultLocalStorage } from "../../../components/functions/localStorage";

export default function DispensaryListTable({
  setDispensaryInformationView = () => null,
  setRefreshTimeStamp = () => null,
  refreshTimeStamp = null,
  dashboardView = false,
}) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_DISPENSARY_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: t("translation:Store Order No"),
      accessor: "store_header.order_number",
      sortable: false,
    },
    {
      Header: t("translation:Group"),
      accessor: "store_medicine_header.name",
    },
    {
      Header: t("translation:Physician"),
      accessor: "store_header.physician_name",
    },
    {
      Header: t("translation:Staff Name"),
      accessor: "store_header.staff_name",
    },
    {
      Header: t("translation:Order Description"),
      accessor: "store_header.desc",
    },
    {
      Header: t("translation:Physician/ Staff Contact Number"),
      sortable: false,
      accessor: "store_header.staff_contact_number",
      Cell: ({ row }) => {
        if (
          row.original.store_header.work_order_type ===
          generalConstants.VISIT_WORK_ORDER_TYPE
        )
          return row.original.store_header.physician_contact_number;
        if (
          row.original.store_header.work_order_type ===
          generalConstants.STORE_WORK_ORDER_TYPE
        )
          return row.original.store_header.staff_contact_number;
        return "";
      },
    },
    {
      Header: t("translation:Proceed Date"),
      sortable: false,
      accessor: "store_medicine_header.proceed_on",
      Cell: ({ row }) => {
        if (row.original.store_medicine_header.proceed_on)
          return moment
            .utc(row.original.store_medicine_header.proceed_on)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];
  const columnsWUpdateAccess = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "statusAction",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) => {
        if (row.original.status_type === generalConstants.PENDING_STATUS_TYPE) {
          return (
            <Button onClick={() => handleRowProcess(row.original)}>
              <ProceedLogo title={t("translation:Proceed")} />
            </Button>
          );
        } else {
          return row.original.status_type ===
            generalConstants.WIP_STATUS_TYPE ? (
            <Button onClick={() => handleRowProcessDetail(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          ) : (
            <></>
          );
        }
      },
    },
    {
      Header: "",
      accessor: "Pending",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowPending(row.original)}>
              <PendingLogo title={t("translation:Pending")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },

    {
      Header: "",
      accessor: "processAllAction",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowProcessAll(row.original)}>
              <ProceedAllLogo title={t("translation:Close")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },

    {
      Header: t("translation:Store Order No"),
      accessor: "store_header.order_number",
      sortable: false,
    },
    {
      Header: t("translation:Group"),
      accessor: "store_medicine_header.name",
    },
    {
      Header: t("translation:Physician"),
      accessor: "store_header.physician_name",
    },
    {
      Header: t("translation:Staff Name"),
      accessor: "store_header.staff_name",
    },
    {
      Header: t("translation:Order Description"),
      accessor: "store_header.desc",
    },
    {
      Header: t("translation:Physician/ Staff Contact Number"),
      sortable: false,
      accessor: "store_header.staff_contact_number",
      Cell: ({ row }) => {
        if (
          row.original.store_header.work_order_type ===
          generalConstants.VISIT_WORK_ORDER_TYPE
        )
          return row.original.store_header.physician_contact_number;
        if (
          row.original.store_header.work_order_type ===
          generalConstants.STORE_WORK_ORDER_TYPE
        )
          return row.original.store_header.staff_contact_number;
        return "";
      },
    },
    {
      Header: t("translation:Proceed Date"),
      sortable: false,
      accessor: "store_medicine_header.proceed_on",
      Cell: ({ row }) => {
        if (row.original.store_medicine_header.proceed_on)
          return moment
            .utc(row.original.store_medicine_header.proceed_on)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const localStorageNameKey = "dispensaryListTable";

  const [pageInfo, setPageInfo] = useState({
    search_dispensary_order_number_value: null,
    search_store_order_number_value: null,
    search_staff_contact_number_value: null,
    search_store_header_desc_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) =>
        size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
    search_proceed_start_value: null,
    search_proceed_end_value: null,
  });
  //
  const dispatch = useDispatch();
  const prevGetDispensaryByIdOnStatus = useRef();
  const dispensary = useSelector((state) => state.dispensary);
  const common = useSelector((state) => state.common);
  //
  // const [editOrderInfo, setEditOrderInfo] = React.useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmPendingOpen, setConfirmPendingOpen] = useState(false);
  const [confirmProceedAllOpen, setConfirmProceedAllOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [orderDetailView, setOrderDetailView] = React.useState(false);
  const [orderProcessView, setOrderProcessView] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [orderDetailInfo, setOrderDetailInfo] = React.useState(null);
  const [isCompleteAll, setIsCompleteAll] = React.useState(false);
  const prevWorkOrderWIPStatus = useRef();
  const prevWorkOrderPendingStatus = useRef();
  const prevSubmitDispensaryStatus = useRef();
  const prevRefreshTimeStamp = useRef();
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = React.useState([]);
  const [WIPSuccessAlarm, setWIPSuccessAlarm] = useState(false);
  const [WIPErrorAlarm, setWIPErrorAlarm] = useState(false);
  const [WIPmsgAlarm, setWIPMsgAlarm] = React.useState([]);
  const [PendingSuccessAlarm, setPendingSuccessAlarm] = useState(false);
  const [PendingErrorAlarm, setPendingErrorAlarm] = useState(false);
  const [PendingmsgAlarm, setPendingMsgAlarm] = React.useState([]);

  //functions
  const handleRowProcess = (value) => {
    setConfirmOpen(true);
    setSelectedValue(value);
  };

  const handleRowPending = (value) => {
    setConfirmPendingOpen(true);
    setSelectedValue(value);
  };

  const handleRowProcessAll = (value) => {
    setConfirmProceedAllOpen(true);
    setSelectedValue(value);
  };

  const onSubmitDispensary = (e) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    // const numberOfDays = selectedValue.store_medicine_header.number_of_days;
    // const totalDispensary = selectedValue.dispensary_medicine_header.map(
    //   (object) => {
    //     if (Object.keys(object.dispensary_medicine_details).length === 0) {
    //       return {
    //         dispensary_medicine_details: [
    //           {
    //             dispensary_medicine_quantity: parseFloat(
    //               (
    //                 numberOfDays *
    //                 object.store_medicine_details.quantity_per_day
    //               ).toFixed(10)
    //             ),
    //             id: 0,
    //           },
    //         ],
    //         id: object.id,
    //       };
    //     }
    //     const temDispensaryQuantity = object.dispensary_medicine_details.reduce(
    //       (sum, li) => {
    //         return parseFloat(
    //           (sum + li.dispensary_medicine_quantity).toFixed(10)
    //         );
    //       },
    //       0
    //     );
    //     //
    //     return {
    //       dispensary_medicine_details: [
    //         {
    //           dispensary_medicine_quantity: parseFloat(
    //             (
    //               numberOfDays *
    //                 object.store_medicine_details.quantity_per_day -
    //               temDispensaryQuantity
    //             ).toFixed(10)
    //           ),
    //           id: 0,
    //         },
    //       ],
    //       id: object.id,
    //     };
    //   }
    // );
    // const dispensaryHeader = {
    //   dispensary_header: {
    //     dispensary_medicine_header: totalDispensary,
    //     id: selectedValue.id,
    //     order_number: selectedValue.order_number,
    //   },
    // };
    // dispatch(postSubmitDispensary.pending(dispensaryHeader));
    // //

    setIsCompleteAll(true);
    dispatch(getDispensaryById.pending(selectedValue.id));
  };

  const handleConfirmProcess = () => {
    dispatch(putDispensaryWIPMode.pending(selectedValue.id));
  };

  const handleConfirmPending = () => {
    dispatch(putDispensaryPendingStatus.pending(selectedValue.id));
  };

  const handleRowDetail = (value) => {
    dispatch(getDispensaryById.pending(value.id));
    setOrderDetailView(true);
    setIsCompleteAll(false);
    // setOrderDetailInfo(value);
  };

  const handleRowProcessDetail = (value) => {
    dispatch(getDispensaryById.pending(value.id));
    setOrderProcessView(true);
    setSubmitErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setWIPSuccessAlarm(false);
    setPendingErrorAlarm(false);
    setPendingSuccessAlarm(false);
    setIsCompleteAll(false);
  };

  const resetDispensaryListTable = (value) => {
    setOrderDetailView(false);
    setOrderProcessView(false);
    setSubmitErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setWIPSuccessAlarm(false);
    setPendingErrorAlarm(false);
    setPendingSuccessAlarm(false);
    setIsCompleteAll(false);
    setOrderDetailInfo(null);
    setRefreshTimeStamp(moment());
    // dispatch(getDispensarySummaryList.pending(pageInfo));
  };

  //useEffects
  useEffect(() => {
    prevWorkOrderWIPStatus.current = false;
    prevWorkOrderPendingStatus.current = false;
    prevSubmitDispensaryStatus.current = false;
    prevGetDispensaryByIdOnStatus.current = false;
    prevRefreshTimeStamp.current = null;
  }, []);

  useEffect(() => {
    if (
      dispensary.isLoadingDispensaryById !==
      prevGetDispensaryByIdOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevGetDispensaryByIdOnStatus.current =
        dispensary.isLoadingDispensaryById;

      if (
        dispensary.getDispensaryByIdSuccess === true &&
        dispensary.isLoadingDispensaryById === false
      ) {
        if (isCompleteAll) {
          const numberOfDays =
            dispensary.dispensaryById.dispensary_header.store_medicine_header
              .number_of_days;
          const totalDispensary =
            dispensary.dispensaryById.dispensary_header.dispensary_medicine_header.map(
              (object) => {
                if (
                  Object.keys(object.dispensary_medicine_details).length === 0
                ) {
                  return {
                    dispensary_medicine_details: [
                      {
                        dispensary_medicine_quantity: parseFloat(
                          (
                            numberOfDays *
                            object.store_medicine_details.quantity_per_day
                          ).toFixed(10)
                        ),
                        id: 0,
                      },
                    ],
                    id: object.id,
                  };
                }
                const temDispensaryQuantity =
                  object.dispensary_medicine_details.reduce((sum, li) => {
                    return parseFloat(
                      (sum + li.dispensary_medicine_quantity).toFixed(10)
                    );
                  }, 0);
                //
                return {
                  dispensary_medicine_details: [
                    {
                      dispensary_medicine_quantity: parseFloat(
                        (
                          numberOfDays *
                            object.store_medicine_details.quantity_per_day -
                          temDispensaryQuantity
                        ).toFixed(10)
                      ),
                      id: 0,
                    },
                  ],
                  id: object.id,
                };
              }
            );
          const dispensaryHeader = {
            dispensary_header: {
              dispensary_medicine_header: totalDispensary,
              id: selectedValue.id,
              order_number: selectedValue.order_number,
            },
          };
          dispatch(postSubmitDispensary.pending(dispensaryHeader));
          setIsCompleteAll(false);
        } else {
          if (
            dispensary.dispensaryById.dispensary_header.store_medicine_header
              .delivery_incl_receipt
          ) {
            dispatch(
              getDispensaryInvoiceOrder.pending({
                dispensary_header: {
                  id: dispensary.dispensaryById.dispensary_header.id,
                },
              })
            );
          }
          const result = { ...dispensary.dispensaryById.dispensary_header };
          setOrderDetailInfo(result);
        }
      }
    }
  }, [isCompleteAll, dispensary, selectedValue, dispatch, setOrderDetailInfo]);

  useEffect(() => {
    if (
      dashboardView &&
      refreshTimeStamp !== prevRefreshTimeStamp.current &&
      !orderDetailView &&
      !orderProcessView
    ) {
      //check the previous ref with current state
      prevRefreshTimeStamp.current = refreshTimeStamp;
      dispatch(getDispensarySummaryList.pending(pageInfo));
    }
  }, [
    refreshTimeStamp,
    dispatch,
    pageInfo,
    dashboardView,
    orderDetailView,
    orderProcessView,
  ]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp === prevRefreshTimeStamp.current) {
      //update the newest time after calling api
      prevRefreshTimeStamp.current = moment();
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  useEffect(() => {
    if (
      dispensary.isLoadingDispensaryPendingStatus !==
      prevWorkOrderPendingStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderPendingStatus.current =
        dispensary.isLoadingDispensaryPendingStatus;

      if (
        dispensary.putDispensaryPendingStatusSuccess === true &&
        dispensary.isLoadingDispensaryPendingStatus === false
      ) {
        dispatch(getDispensarySummaryList.pending(pageInfo));
        setPendingErrorAlarm(false);
        setPendingSuccessAlarm(true);
        window.scrollTo(0, 0);
      }
      if (
        dispensary.putDispensaryPendingStatusSuccess === false &&
        dispensary.isLoadingDispensaryPendingStatus === false
      ) {
        setPendingErrorAlarm(true);
        setPendingSuccessAlarm(false);
        setPendingMsgAlarm(dispensary.error.dispensaryPendingStatusError);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, dispensary, pageInfo]);

  useEffect(() => {
    if (
      dispensary.isLoadingDispensaryWIPMode !== prevWorkOrderWIPStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderWIPStatus.current = dispensary.isLoadingDispensaryWIPMode;

      if (
        dispensary.putDispensaryWIPModeSuccess === true &&
        dispensary.isLoadingDispensaryWIPMode === false
      ) {
        dispatch(getDispensarySummaryList.pending(pageInfo));
        setWIPErrorAlarm(false);
        setWIPSuccessAlarm(true);
        window.scrollTo(0, 0);
      }
      if (
        dispensary.putDispensaryWIPModeSuccess === false &&
        dispensary.isLoadingDispensaryWIPMode === false
      ) {
        setWIPErrorAlarm(true);
        setWIPSuccessAlarm(false);
        setWIPMsgAlarm(dispensary.error.dispensaryWIPModeError);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, dispensary, pageInfo]);

  useEffect(() => {
    if (
      dispensary.isLoadingSubmitDispensary !==
      prevSubmitDispensaryStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitDispensaryStatus.current = dispensary.isLoadingSubmitDispensary;
      //
      if (
        dispensary.postSubmitDispensaryIsSuccess === true &&
        dispensary.isLoadingSubmitDispensary === false
      ) {
        if (curUser.defaultBranch?.branch?.is_dispensary_checker_required) {
          setSubmitErrorAlarm(true);
          setSubmitSuccessAlarm(true);
          setMsgAlarm(t("translation:Checker is required to close order"));
          window.scrollTo(0, 0);
        } else {
          setSubmitErrorAlarm(false);
          setSubmitSuccessAlarm(true);
          window.scrollTo(0, 0);
        }
        dispatch(getDispensarySummaryList.pending(pageInfo));
      }
      if (
        dispensary.postSubmitDispensaryIsSuccess === false &&
        dispensary.isLoadingSubmitDispensary === false
      ) {
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(dispensary.error.postDispensarySubmitDispensary);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, dispensary, pageInfo]);

  useEffect(() => {
    if (orderDetailView === true || orderProcessView === true) {
      setDispensaryInformationView(true);
    } else {
      setDispensaryInformationView(false);
    }
  }, [orderProcessView, orderDetailView, setDispensaryInformationView]);

  useEffect(() => {
    if (!dashboardView && !orderDetailView && !orderProcessView) {
      dispatch(getDispensarySummaryList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, dashboardView, orderDetailView, orderProcessView]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_dispensary_order_number_value: value,
        search_store_order_number_value: value,
        search_store_header_desc_value: value,
        search_staff_name_value: value,
        search_staff_contact_number_value: value,
        search_physician_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_dispensary_order_number_value: null,
        search_store_order_number_value: null,
        search_staff_name_value: null,
        search_staff_contact_number_value: null,
        search_physician_name_value: null,
        search_store_header_desc_value: null,
      });
    }
  };

  if (
    dispensary.getDispensarySummaryListSuccess === false &&
    dispensary.isLoadingDispensarySummaryList === false
  ) {
    return (
      <Alert severity="error">
        {dispensary.error.dispensarySummaryListError}
      </Alert>
    );
  } else if (
    dispensary.getDispensaryByIdSuccess === false &&
    dispensary.isLoadingDispensaryById === false &&
    (orderDetailView || orderDetailView)
  ) {
    return (
      <Alert severity="error">{dispensary.error.dispensaryByIdError}</Alert>
    );
  } else if (
    common.getGeneralStatusIsSuccess === true &&
    !orderDetailView &&
    !orderProcessView
  ) {
    return (
      <>
        <Collapse in={submitSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Dispensary has been submitted")}
          </Alert>
        </Collapse>

        <Collapse in={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={WIPSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.WIP_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={WIPErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {WIPmsgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={PendingSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.PENDING_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={PendingErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {PendingmsgAlarm}
          </Alert>
        </Collapse>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmPendingOpen}
          setOpen={setConfirmPendingOpen}
          onConfirm={handleConfirmPending}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmProceedAllOpen}
          setOpen={setConfirmProceedAllOpen}
          onConfirm={onSubmitDispensary}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <SortingSearchTable
          tableHeading=""
          searchPlaceholder={t(
            "translation:Dispensary Order No/ Store Order No/ Physician/ Staff Name/ Staff Contact Number/ Description"
          )}
          statusPlaceholder={t("translation:Order Status")}
          statusOption={common.generalStatusList.status_general}
          searchValue={searchValue}
          columns={updateAccess ? columnsWUpdateAccess : columns}
          data={
            dispensary.dispensarySummaryList
              ? dispensary.dispensarySummaryList.dispensary_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            dispensary.dispensarySummaryList
              ? dispensary.dispensarySummaryList.total_pages
              : 0
          }
          loading={dispensary.isLoadingDispensarySummaryList}
          totalItems={
            dispensary.dispensarySummaryList
              ? dispensary.dispensarySummaryList.total_items
              : 0
          }
          handleSearch={handleSearch}
          searchDate={true}
          searchDatePlaceholder={t("translation:Creation Date")}
          searchDate2={true}
          searchDatePlaceholder2={t("translation:Proceed Date")}
          localStorageName={localStorageNameKey}
        />
      </>
    );
  } else if (orderDetailView && orderDetailInfo !== null) {
    return (
      <OrderDetailPage
        orderDetailInfo={orderDetailInfo}
        setOrderDetailInfo={setOrderDetailInfo}
        setOrderDetailView={resetDispensaryListTable}
      />
    );
  } else if (orderProcessView && orderDetailInfo !== null) {
    return (
      <OrderProceedPage
        orderProcessInfo={orderDetailInfo}
        setOrderProcessInfo={setOrderDetailInfo}
        setOrderProcessView={resetDispensaryListTable}
        submitSuccessAlarm={submitSuccessAlarm}
        setSubmitSuccessAlarm={setSubmitSuccessAlarm}
        submitErrorAlarm={submitErrorAlarm}
        setSubmitErrorAlarm={setSubmitErrorAlarm}
        msgAlarm={msgAlarm}
        setMsgAlarm={setMsgAlarm}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
