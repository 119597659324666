//lib
import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SubmissionNotificationDialog from "../../components/SubmissionNotificationDialog";
import DEFAULT_TCMLogo from "../../assets/DEFAULT_TCMLogo.png";
export const setPrintingOrganisationLogo = (
  organisationCode,
  folderPath,
  organisationLogoUrl
) => {
  const organisationCodePath = organisationCode + "/" + folderPath + "/";

  const extensionIndex = organisationLogoUrl?.lastIndexOf(organisationCodePath);
  const objectName = organisationLogoUrl?.substring(extensionIndex);

  const submitJson = {
    object_name: objectName,
  };

  return submitJson;
};
export default function S3OrganisationLogoDialog({
  organisationCode = "default",
  folderPath = "organisation",
  setPhotoURL = () => null,
}) {
  const { t } = useTranslation();
  const prevCreatePresignedUrlStatus = useRef();
  const cloudStorage = useSelector((state) => state.cloudStorage);

  const [msgAlarm, setMsgAlarm] = React.useState(null);
  const [alertMsg, setAlertMsg] = React.useState(false);
  const [alertType, setAlertType] = React.useState(null);

  useEffect(() => {
    prevCreatePresignedUrlStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      cloudStorage.isLoadingPostCreatePresignedUrlOrganisationLogo !==
      prevCreatePresignedUrlStatus.current
    ) {
      //check the previous ref with current state
      prevCreatePresignedUrlStatus.current =
        cloudStorage.isLoadingPostCreatePresignedUrlOrganisationLogo;

      if (
        cloudStorage.postCreatePresignedUrlOrganisationLogoIsSuccess === true &&
        cloudStorage.isLoadingPostCreatePresignedUrlOrganisationLogo === false
      ) {
        const organisationCodePath = organisationCode + "/" + folderPath + "/";

        const extensionIndex =
          cloudStorage.postCreatePresignedUrlOrganisationLogo.lastIndexOf(
            organisationCodePath
          );

        if (extensionIndex !== -1) {
          fetch(cloudStorage.postCreatePresignedUrlOrganisationLogo)
            .then((res) => {
              if (res.status === 200) {
                return res.blob();
              } else {
                throw new Error(res.statusText);
              }
            })
            .then((blob) => {
              var url = window.URL.createObjectURL(blob);
              setPhotoURL(url);
            })
            .catch((err) => {
              setMsgAlarm(err.message);
              setAlertMsg(true);
              setAlertType("error");
              setPhotoURL(DEFAULT_TCMLogo);
            });
        }
      }
      if (
        cloudStorage.postCreatePresignedUrlOrganisationLogoIsSuccess ===
          false &&
        cloudStorage.isLoadingPostCreatePresignedUrlOrganisationLogo === false
      ) {
        setMsgAlarm(t("translation:Missing Organisation Logo"));
        setAlertMsg(true);
        setAlertType("error");
        setPhotoURL(DEFAULT_TCMLogo);
      }
    }
  }, [cloudStorage, folderPath, organisationCode, t]);

  return (
    <>
      {alertType !== null && alertMsg !== null && (
        <SubmissionNotificationDialog open={alertMsg}>
          <Alert
            severity={alertType}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertMsg(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </SubmissionNotificationDialog>
      )}
    </>
  );
}
