import * as types from "../_constants/branchMedicineConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_BRANCHMEDICINELIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchMedicineListIsSuccess: false,
        isLoadingGetBranchMedicineList: true,
        branchMedicineList: null,
      };

    case types.GET_BRANCHMEDICINELIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchMedicineListIsSuccess: false,
        isLoadingGetBranchMedicineList: false,
        branchMedicineList: null,
        error: { ...state.error, getBranchMedicineListError: action.error },
      };

    case types.GET_BRANCHMEDICINELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchMedicineListIsSuccess: true,
        isLoadingGetBranchMedicineList: false,
        branchMedicineList: action.data,
      };

    case types.GET_BRANCHMEDICINEBYID_ASYNC.PENDING:
      return {
        ...state,
        getBranchMedicineByIdIsSuccess: false,
        isLoadingGetBranchMedicineById: true,
        branchMedicineListById: null,
      };

    case types.GET_BRANCHMEDICINEBYID_ASYNC.ERROR:
      return {
        ...state,
        getBranchMedicineByIdIsSuccess: false,
        isLoadingGetBranchMedicineById: false,
        branchMedicineById: null,
        error: { ...state.error, getBranchMedicineByIdError: action.error },
      };

    case types.GET_BRANCHMEDICINEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchMedicineByIdIsSuccess: true,
        isLoadingGetBranchMedicineById: false,
        branchMedicineById: action.data,
      };

    case types.PUT_UPDATEBRANCHMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        putUpdateBranchMedicineIsSuccess: false,
        isLoadingPutUpdateBranchMedicine: true,
        updateBranchMedicine: null,
      };

    case types.PUT_UPDATEBRANCHMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        putUpdateBranchMedicineIsSuccess: false,
        isLoadingPutUpdateBranchMedicine: false,
        updateBranchMedicine: null,
        error: { ...state.error, putUpdateBranchMedicineError: action.error },
      };

    case types.PUT_UPDATEBRANCHMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateBranchMedicineIsSuccess: true,
        isLoadingPutUpdateBranchMedicine: false,
        updateBranchMedicine: action.data,
      };

    case types.POST_BRANCHMEDICINEBRANCHLIST_ASYNC.PENDING:
      return {
        ...state,
        postBranchMedicineBranchListIsSuccess: false,
        isLoadingPostBranchMedicineBranchList: true,
        branchMedicineBranchList: null,
      };

    case types.POST_BRANCHMEDICINEBRANCHLIST_ASYNC.ERROR:
      return {
        ...state,
        postBranchMedicineBranchListIsSuccess: false,
        isLoadingPostBranchMedicineBranchList: false,
        branchMedicineBranchList: null,
        error: {
          ...state.error,
          postBranchMedicineBranchListError: action.error,
        },
      };

    case types.POST_BRANCHMEDICINEBRANCHLIST_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchMedicineBranchListIsSuccess: true,
        isLoadingPostBranchMedicineBranchList: false,
        branchMedicineBranchList: action.data,
      };

    case types.POST_BRANCHMEDICINEMEDICINETYPELIST_ASYNC.PENDING:
      return {
        ...state,
        postBranchMedicineMedicineTypeListIsSuccess: false,
        isLoadingPostBranchMedicineMedicineTypeList: true,
        branchMedicineMedicineTypeList: null,
      };

    case types.POST_BRANCHMEDICINEMEDICINETYPELIST_ASYNC.ERROR:
      return {
        ...state,
        postBranchMedicineMedicineTypeListIsSuccess: false,
        isLoadingPostBranchMedicineMedicineTypeList: false,
        branchMedicineMedicineTypeList: null,
        error: {
          ...state.error,
          postBranchMedicineMedicineTypeListError: action.error,
        },
      };

    case types.POST_BRANCHMEDICINEMEDICINETYPELIST_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchMedicineMedicineTypeListIsSuccess: true,
        isLoadingPostBranchMedicineMedicineTypeList: false,
        branchMedicineMedicineTypeList: action.data,
      };

    case types.POST_BRANCHMEDICINEMEDICINELIST_ASYNC.PENDING:
      return {
        ...state,
        postBranchMedicineMedicineListIsSuccess: false,
        isLoadingPostBranchMedicineMedicineList: true,
        branchMedicineMedicineList: null,
      };

    case types.POST_BRANCHMEDICINEMEDICINELIST_ASYNC.ERROR:
      return {
        ...state,
        postBranchMedicineMedicineListIsSuccess: false,
        isLoadingPostBranchMedicineMedicineList: false,
        branchMedicineMedicineList: null,
        error: {
          ...state.error,
          postBranchMedicineMedicineListError: action.error,
        },
      };

    case types.POST_BRANCHMEDICINEMEDICINELIST_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchMedicineMedicineListIsSuccess: true,
        isLoadingPostBranchMedicineMedicineList: false,
        branchMedicineMedicineList: action.data,
      };
    case types.POST_BRANCHMEDICINEBRANCHMEDICINEHISTORYLIST_ASYNC.PENDING:
      return {
        ...state,
        postBranchMedicineBranchMedicineHistoryListIsSuccess: false,
        isLoadingPostBranchMedicineBranchMedicineHistoryList: true,
        branchMedicineBranchMedicineHistoryList: null,
      };

    case types.POST_BRANCHMEDICINEBRANCHMEDICINEHISTORYLIST_ASYNC.ERROR:
      return {
        ...state,
        postBranchMedicineBranchMedicineHistoryListIsSuccess: false,
        isLoadingPostBranchMedicineBranchMedicineHistoryList: false,
        branchMedicineBranchMedicineHistoryList: null,
        error: {
          ...state.error,
          postBranchMedicineBranchMedicineHistoryListError: action.error,
        },
      };

    case types.POST_BRANCHMEDICINEBRANCHMEDICINEHISTORYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchMedicineBranchMedicineHistoryListIsSuccess: true,
        isLoadingPostBranchMedicineBranchMedicineHistoryList: false,
        branchMedicineBranchMedicineHistoryList: action.data,
      };

    default:
      return state;
  }
};
