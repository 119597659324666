import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getBranchCourierList,
  getBranchCourierById,
  putUpdateBranchCourier,
  postBranchCourierCourierList,
  postBranchCourierBranchList,
  postBranchCourierUserDeviceKeyList,
} from "../actions/branchCourierActions";
import * as types from "../_constants/branchCourierConstants";
import * as notification from "../_constants/notificationConstants";
import branchCourierApis from "../_apis/branchCourierApis";
export default function* branchcourierSagas() {
  yield all([
    takeLatest(
      types.GET_BRANCHCOURIERLIST_ASYNC.PENDING,
      getBranchCourierListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_BRANCHCOURIERBYID_ASYNC.PENDING,
      getBranchCourierByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_UPDATEBRANCHCOURIER_ASYNC.PENDING,
      putUpdateBranchCourierCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHCOURIERCOURIERLIST_ASYNC.PENDING,
      postBranchCourierCourierListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHCOURIERBRANCHLIST_ASYNC.PENDING,
      postBranchCourierBranchListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHCOURIERUSERDEVICEKEYLIST_ASYNC.PENDING,
      postBranchCourierUserDeviceKeyListCall
    ),
  ]);
}

function* getBranchCourierListCall(searchModel) {
  try {
    const data = yield call(
      branchCourierApis.getBranchCourierListApi,
      searchModel.data
    );
    yield put(getBranchCourierList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getBranchCourierList.error(error.response?.data?.message));
    else yield put(getBranchCourierList.error(notification.CONST_NOINTERNET));
  }
}

function* getBranchCourierByIdCall(searchModel) {
  try {
    const data = yield call(
      branchCourierApis.getBranchCourierByIdApi,
      searchModel.data
    );
    yield put(getBranchCourierById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getBranchCourierById.error(error.response?.data?.message));
    else yield put(getBranchCourierById.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateBranchCourierCall(searchModel) {
  try {
    const data = yield call(
      branchCourierApis.putUpdateBranchCourierApi,
      searchModel.data
    );
    yield put(putUpdateBranchCourier.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateBranchCourier.error(error.response?.data?.message));
    else yield put(putUpdateBranchCourier.error(notification.CONST_NOINTERNET));
  }
}

function* postBranchCourierCourierListCall(searchModel) {
  try {
    const data = yield call(
      branchCourierApis.postBranchCourierCourierListApi,
      searchModel.data
    );
    yield put(postBranchCourierCourierList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchCourierCourierList.error(error.response?.data?.message)
      );
    else
      yield put(
        postBranchCourierCourierList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postBranchCourierBranchListCall(searchModel) {
  try {
    const data = yield call(
      branchCourierApis.postBranchCourierBranchListApi,
      searchModel.data
    );
    yield put(postBranchCourierBranchList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchCourierBranchList.error(error.response?.data?.message)
      );
    else
      yield put(
        postBranchCourierBranchList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postBranchCourierUserDeviceKeyListCall(searchModel) {
  try {
    const data = yield call(
      branchCourierApis.postBranchCourierUserDeviceKeyListApi,
      searchModel.data
    );
    yield put(postBranchCourierUserDeviceKeyList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchCourierUserDeviceKeyList.error(error.response?.data?.message)
      );
    else
      yield put(
        postBranchCourierUserDeviceKeyList.error(notification.CONST_NOINTERNET)
      );
  }
}
