import * as types from "../_constants/userRoleConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_USERROLELIST_ASYNC.PENDING:
      return {
        ...state,
        getUserRoleListIsSuccess: false,
        isLoadingUserRoleList: true,
        userRoleList: null,
      };

    case types.GET_USERROLELIST_ASYNC.ERROR:
      return {
        ...state,
        getUserRoleListIsSuccess: false,
        isLoadingUserRoleList: false,
        userRoleList: null,
        error: { ...state.error, userRoleListError: action.error },
      };

    case types.GET_USERROLELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserRoleListIsSuccess: true,
        isLoadingUserRoleList: false,
        userRoleList: action.data,
      };

    case types.PUT_EDITUSERROLE_ASYNC.PENDING:
      return {
        ...state,
        putEditUserRoleIsSuccess: false,
        isLoadingEditUserRole: true,
        putEditUserRole: null,
      };

    case types.PUT_EDITUSERROLE_ASYNC.ERROR:
      return {
        ...state,
        putEditUserRoleIsSuccess: false,
        isLoadingEditUserRole: false,
        putEditUserRole: null,
        error: { ...state.error, EditUserRoleError: action.error },
      };

    case types.PUT_EDITUSERROLE_ASYNC.SUCCESS:
      return {
        ...state,
        putEditUserRoleIsSuccess: true,
        isLoadingEditUserRole: false,
        putEditUserRole: action.data,
      };

    case types.GET_USERROLE_ASYNC.PENDING:
      return {
        ...state,
        getUserRoleIsSuccess: false,
        isLoadingGetUserRole: true,
        userRole: null,
      };

    case types.GET_USERROLE_ASYNC.ERROR:
      return {
        ...state,
        getUserRoleIsSuccess: false,
        isLoadingGetUserRole: false,
        userRole: null,
        error: { ...state.error, UserRoleError: action.error },
      };

    case types.GET_USERROLE_ASYNC.SUCCESS:
      return {
        ...state,
        getUserRoleIsSuccess: true,
        isLoadingGetUserRole: false,
        userRole: action.data,
      };

    case types.GET_USERROLEALLROLE_ASYNC.PENDING:
      return {
        ...state,
        getUserRoleAllRoleIsSuccess: false,
        isLoadingUserRoleAllRole: true,
        userRoleAllRole: null,
      };

    case types.GET_USERROLEALLROLE_ASYNC.ERROR:
      return {
        ...state,
        getUserRoleAllRoleIsSuccess: false,
        isLoadingUserRoleAllRole: false,
        userRoleAllRole: null,
        error: { ...state.error, userRoleAllRoleError: action.error },
      };

    case types.GET_USERROLEALLROLE_ASYNC.SUCCESS:
      return {
        ...state,
        getUserRoleAllRoleIsSuccess: true,
        isLoadingUserRoleAllRole: false,
        userRoleAllRole: action.data,
      };

    default:
      return state;
  }
};
