import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getMedicineFixedSellingPriceDetails,
  getMedicineFixedSellingPriceHeaderDetailsList,
  getMedicineFixedSellingPriceDetailsSummaryList,
  getMedicineFixedSellingPriceDetailsById,
  putUpdateMedicineFixedSellingPriceDetails,
  getMedicineFixedSellingPriceDetailsMedicineTypes,
  getMedicineFixedSellingPriceDetailsMedicineInfo,
} from "../actions/medicineFixedSellingPriceDetailsActions";
import * as types from "../_constants/medicineFixedSellingPriceDetailsConstants";
import * as notification from "../_constants/notificationConstants";
import medicineFixedSellingPriceDetailsApis from "../_apis/medicineFixedSellingPriceDetailsApis";
export default function* medicineFixedSellingPriceDetailsSagas() {
  yield all([
    takeLatest(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.PENDING,
      getMedicineFixedSellingPriceDetailsCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_MEDICINEFIXEDSELLINGPRICEHEADERDETAILSLIST_ASYNC.PENDING,
      getMedicineFixedSellingPriceHeaderDetailsListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSSUMMARYLIST_ASYNC.PENDING,
      getMedicineFixedSellingPriceDetailsSummaryListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSBYID_ASYNC.PENDING,
      getMedicineFixedSellingPriceDetailsByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.PENDING,
      putUpdateMedicineFixedSellingPriceDetailsCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINETYPES_ASYNC.PENDING,
      getMedicineFixedSellingPriceDetailsMedicineTypesCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINEINFO_ASYNC.PENDING,
      getMedicineFixedSellingPriceDetailsMedicineInfoCall
    ),
  ]);
}

function* getMedicineFixedSellingPriceDetailsCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceDetailsApis.getMedicineFixedSellingPriceDetailsApi,
      searchModel.data
    );
    yield put(getMedicineFixedSellingPriceDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getMedicineFixedSellingPriceDetails.error(error.response?.data?.message)
      );
    else
      yield put(
        getMedicineFixedSellingPriceDetails.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getMedicineFixedSellingPriceHeaderDetailsListCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceDetailsApis.getMedicineFixedSellingPriceHeaderDetailsListApi,
      searchModel.data
    );
    yield put(getMedicineFixedSellingPriceHeaderDetailsList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getMedicineFixedSellingPriceHeaderDetailsList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getMedicineFixedSellingPriceHeaderDetailsList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getMedicineFixedSellingPriceDetailsSummaryListCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceDetailsApis.getMedicineFixedSellingPriceDetailsSummaryListApi,
      searchModel.data
    );
    yield put(getMedicineFixedSellingPriceDetailsSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getMedicineFixedSellingPriceDetailsSummaryList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getMedicineFixedSellingPriceDetailsSummaryList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getMedicineFixedSellingPriceDetailsByIdCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceDetailsApis.getMedicineFixedSellingPriceDetailsByIdApi,
      searchModel.data
    );
    yield put(getMedicineFixedSellingPriceDetailsById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getMedicineFixedSellingPriceDetailsById.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getMedicineFixedSellingPriceDetailsById.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* putUpdateMedicineFixedSellingPriceDetailsCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceDetailsApis.putUpdateMedicineFixedSellingPriceDetailsApi,
      searchModel.data
    );
    yield put(putUpdateMedicineFixedSellingPriceDetails.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateMedicineFixedSellingPriceDetails.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        putUpdateMedicineFixedSellingPriceDetails.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getMedicineFixedSellingPriceDetailsMedicineTypesCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceDetailsApis.getMedicineFixedSellingPriceDetailsMedicineTypesApi,
      searchModel.data
    );
    yield put(getMedicineFixedSellingPriceDetailsMedicineTypes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getMedicineFixedSellingPriceDetailsMedicineTypes.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getMedicineFixedSellingPriceDetailsMedicineTypes.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getMedicineFixedSellingPriceDetailsMedicineInfoCall(searchModel) {
  try {
    const data = yield call(
      medicineFixedSellingPriceDetailsApis.getMedicineFixedSellingPriceDetailsMedicineInfoApi,
      searchModel.data
    );
    yield put(getMedicineFixedSellingPriceDetailsMedicineInfo.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getMedicineFixedSellingPriceDetailsMedicineInfo.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getMedicineFixedSellingPriceDetailsMedicineInfo.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}
