import { createAsyncTypes } from "../_helper/Utilities";

export const GET_SYSTEMSCHEDULEBYID_ASYNC = createAsyncTypes(
  "GET_SYSTEMSCHEDULEBYID"
);

export const GET_SYSTEMSCHEDULELIST_ASYNC = createAsyncTypes(
  "GET_SYSTEMSCHEDULELIST"
);

export const POST_CREATESYSTEMSCHEDULE_ASYNC = createAsyncTypes(
  "POST_CREATESYSTEMSCHEDULE"
);

export const PUT_UPDATESYSTEMSCHEDULE_ASYNC = createAsyncTypes(
  "PUT_UPDATESYSTEMSCHEDULE"
);
