import { createAsyncTypes } from "../_helper/Utilities";

export const PUT_BREWINGWIPMODE_ASYNC = createAsyncTypes("PUT_BREWINGWIPMODE");

export const PUT_BREWINGPENDINGSTATUS_ASYNC = createAsyncTypes(
  "PUT_BREWINGPENDINGSTATUS"
);

export const GET_BREWINGLIST_ASYNC = createAsyncTypes("GET_BREWINGLIST");

export const GET_BREWINGSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_BREWINGSUMMARYLIST"
);

export const GET_BREWINGBYID_ASYNC = createAsyncTypes("GET_BREWINGBYID");

export const GET_BREWINGBYORDERNUM_ASYNC = createAsyncTypes(
  "GET_BREWINGBYORDERNUM"
);

export const POST_SUBMITBREWING_ASYNC = createAsyncTypes("POST_SUBMITBREWING");
