//lib
import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Panel from "../../../../components/Panel";
//actions
//components
import OpeningHourPanel from "../components/OpeningHourPanel";
import RequiredNote from "../../../../components/RequiredNote";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { useStyles } from "../../../../components/globalStyles";

export const BranchScheduleDetailPage = ({ detailData, setDetailView }) => {
  //variables
  const classes = useStyles();
  const [dayweek, setDayweek] = useState(null);
  const [scheduleHour, setScheduleHour] = useState(detailData);

  const [endTimeError, setEndTimeError] = useState([]);
  const { t } = useTranslation();
  //functions

  return (
    <Panel heading={t("translation:Branch Schedule Details")}>
      <OpeningHourPanel
        viewMode={true}
        scheduleHour={scheduleHour}
        setScheduleHour={setScheduleHour}
        dayweek={dayweek}
        setDayweek={setDayweek}
        endTimeError={endTimeError}
        setEndTimeError={setEndTimeError}
      />

      <Grid className={classes.root} container>
        <RequiredNote />
        <PageOnlyBackAction setView={() => setDetailView(false)} />
      </Grid>
    </Panel>
  );
};
