import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/branchCourierConstants";

export const getBranchCourierById = {
  pending: (data) =>
    createAction(types.GET_BRANCHCOURIERBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHCOURIERBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHCOURIERBYID_ASYNC.ERROR, { error }),
};

export const getBranchCourierList = {
  pending: (data) =>
    createAction(types.GET_BRANCHCOURIERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHCOURIERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHCOURIERLIST_ASYNC.ERROR, { error }),
};

export const putUpdateBranchCourier = {
  pending: (data) =>
    createAction(types.PUT_UPDATEBRANCHCOURIER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEBRANCHCOURIER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEBRANCHCOURIER_ASYNC.ERROR, { error }),
};

export const postBranchCourierCourierList = {
  pending: (data) =>
    createAction(types.POST_BRANCHCOURIERCOURIERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_BRANCHCOURIERCOURIERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_BRANCHCOURIERCOURIERLIST_ASYNC.ERROR, { error }),
};

export const postBranchCourierBranchList = {
  pending: (data) =>
    createAction(types.POST_BRANCHCOURIERBRANCHLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_BRANCHCOURIERBRANCHLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_BRANCHCOURIERBRANCHLIST_ASYNC.ERROR, { error }),
};

export const postBranchCourierUserDeviceKeyList = {
  pending: (data) =>
    createAction(types.POST_BRANCHCOURIERUSERDEVICEKEYLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_BRANCHCOURIERUSERDEVICEKEYLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_BRANCHCOURIERUSERDEVICEKEYLIST_ASYNC.ERROR, {
      error,
    }),
};
