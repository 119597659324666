import * as types from "../_constants/curUserConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_ALLRIGHTACCESS_ASYNC.PENDING:
      return {
        ...state,
        getAllRightAccessIsSuccess: false,
        isLoadingAllRightAccess: true,
        allRightAccess: null,
      };

    case types.GET_ALLRIGHTACCESS_ASYNC.ERROR:
      return {
        ...state,
        getAllRightAccessIsSuccess: false,
        isLoadingAllRightAccess: false,
        error: { ...state.error, allRightAccessError: action.error },
        allRightAccess: null,
      };

    case types.GET_ALLRIGHTACCESS_ASYNC.SUCCESS:
      return {
        ...state,
        getAllRightAccessIsSuccess: true,
        isLoadingAllRightAccess: false,
        allRightAccess: action.data,
      };
    case types.GET_ORGANISATIONINFO_ASYNC.PENDING:
      return {
        ...state,
        getOrganisationInfoIsSuccess: false,
        isLoadingOrganisationInfo: true,
        organisationInfo: null,
      };

    case types.GET_ORGANISATIONINFO_ASYNC.ERROR:
      return {
        ...state,
        getOrganisationInfoIsSuccess: false,
        isLoadingOrganisationInfo: false,
        organisationInfo: null,
        error: { ...state.error, organisationInfoError: action.error },
      };

    case types.GET_ORGANISATIONINFO_ASYNC.SUCCESS:
      return {
        ...state,
        getOrganisationInfoIsSuccess: true,
        isLoadingOrganisationInfo: false,
        organisationInfo: action.data,
      };

    case types.GET_ORGANISATION_ASYNC.PENDING:
      return {
        ...state,
        getOrganisationIsSuccess: false,
        isLoadingOrganisation: true,
        organisation: null,
      };

    case types.GET_ORGANISATION_ASYNC.ERROR:
      return {
        ...state,
        getOrganisationIsSuccess: false,
        isLoadingOrganisation: false,
        organisation: null,
        error: { ...state.error, organisationError: action.error },
      };

    case types.GET_ORGANISATION_ASYNC.SUCCESS:
      return {
        ...state,
        getOrganisationIsSuccess: true,
        isLoadingOrganisation: false,
        organisation: action.data,
      };

    case types.GET_ORGANISATIONBRANCHLIST_ASYNC.PENDING:
      return {
        ...state,
        getOrganisationBranchListIsSuccess: false,
        isLoadingOrganisationBranchList: true,

        organisationBranchList: null,
      };

    case types.GET_ORGANISATIONBRANCHLIST_ASYNC.ERROR:
      return {
        ...state,
        getOrganisationBranchListIsSuccess: false,
        isLoadingOrganisationBranchList: false,

        organisationBranchList: null,
        error: { ...state.error, organisationBranchListError: action.error },
      };

    case types.GET_ORGANISATIONBRANCHLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getOrganisationBranchListIsSuccess: true,
        isLoadingOrganisationBranchList: false,
        organisationBranchList: action.data,
      };

    case types.GET_ORGANISATIONBRANCHLISTBYID_ASYNC.PENDING:
      return {
        ...state,
        getOrganisationBranchListByIdIsSuccess: false,
        isLoadingOrganisationBranchListById: true,

        organisationBranchList: null,
      };

    case types.GET_ORGANISATIONBRANCHLISTBYID_ASYNC.ERROR:
      return {
        ...state,
        getOrganisationBranchListByIdIsSuccess: false,
        isLoadingOrganisationBranchListById: false,

        organisationBranchList: null,
        error: { ...state.error, organisationBranchListError: action.error },
      };

    case types.GET_ORGANISATIONBRANCHLISTBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getOrganisationBranchListByIdIsSuccess: true,
        isLoadingOrganisationBranchListById: false,
        organisationBranchList: action.data,
      };

    case types.PUT_USERINFO_ASYNC.PENDING:
      return {
        ...state,
        putUserInfoSuccess: false,
        isLoadingUserInfo: true,
        putUserInfo: null,
      };

    case types.PUT_USERINFO_ASYNC.ERROR:
      return {
        ...state,
        putUserInfoSuccess: false,
        isLoadingUserInfo: false,
        putUserInfo: null,
        error: { ...state.error, putUserInfoError: action.error },
      };

    case types.PUT_USERINFO_ASYNC.SUCCESS:
      return {
        ...state,
        putUserInfoSuccess: true,
        isLoadingUserInfo: false,
        putUserInfo: action.data,
      };

    case types.PUT_ORGANISATIONINFO_ASYNC.PENDING:
      return {
        ...state,
        putOrganisationInfoSuccess: false,
        isLoadingPutOrganisationInfo: true,
        //if organisationInfo: null, error msg cant display
        //organisationInfo: null,
      };

    case types.PUT_ORGANISATIONINFO_ASYNC.ERROR:
      return {
        ...state,
        putOrganisationInfoSuccess: false,
        isLoadingPutOrganisationInfo: false,
        //if organisationInfo: null, error msg cant display
        // organisationInfo: null,
        error: { ...state.error, putOrganisationInfoError: action.error },
      };

    case types.PUT_ORGANISATIONINFO_ASYNC.SUCCESS:
      return {
        ...state,
        putOrganisationInfoSuccess: true,
        organisationInfo: action.data,
        isLoadingPutOrganisationInfo: false,
      };

    case types.PUT_USERLANG_ASYNC.PENDING:
      return {
        ...state,
        putUserLangSuccess: false,
        isLoadingUserLang: true,
        putUserLang: null,
      };

    case types.PUT_USERLANG_ASYNC.ERROR:
      return {
        ...state,
        putUserLangSuccess: false,
        isLoadingUserLang: false,
        putUserLang: null,
        error: { ...state.error, putUserLangError: action.error },
      };

    case types.PUT_USERLANG_ASYNC.SUCCESS:
      return {
        ...state,
        putUserLangSuccess: true,
        isLoadingUserLang: false,
        putUserLang: action.data,
      };

    case types.GET_CURROLERIGHT_ASYNC.PENDING:
      return {
        ...state,
        getCurRoleRightIsSuccess: false,
        isLoadingCurRoleRight: true,
        curRoleRight: null,
      };

    case types.GET_CURROLERIGHT_ASYNC.ERROR:
      return {
        ...state,
        getCurRoleRightIsSuccess: false,
        isLoadingCurRoleRight: false,
        curRoleRight: null,
        error: { ...state.error, curRoleRightError: action.error },
      };

    case types.GET_CURROLERIGHT_ASYNC.SUCCESS:
      return {
        ...state,
        getCurRoleRightIsSuccess: true,
        isLoadingCurRoleRight: false,
        curRoleRight: action.data,
      };

    case types.GET_DEFAULTBRANCH_ASYNC.PENDING:
      return {
        ...state,
        getDefaultBranchIsSuccess: false,
        isLoadingDefaultBranch: true,
        defaultBranch: null,
      };

    case types.GET_DEFAULTBRANCH_ASYNC.ERROR:
      return {
        ...state,
        getDefaultBranchIsSuccess: false,
        isLoadingDefaultBranch: false,
        defaultBranch: null,
        error: { ...state.error, defaultBranchError: action.error },
      };

    case types.GET_DEFAULTBRANCH_ASYNC.SUCCESS:
      return {
        ...state,
        getDefaultBranchIsSuccess: true,
        isLoadingDefaultBranch: false,
        defaultBranch: action.data,
      };

    case types.POST_TOPUPCREDIT_ASYNC.PENDING:
      return {
        ...state,
        postTopUpCreditIsSuccess: false,
        isLoadingPostTopUpCredit: true,
        topUpCredit: null,
      };

    case types.POST_TOPUPCREDIT_ASYNC.ERROR:
      return {
        ...state,
        postTopUpCreditIsSuccess: false,
        isLoadingPostTopUpCredit: false,
        topUpCredit: null,
        error: { ...state.error, topUpCreditError: action.error },
      };

    case types.POST_TOPUPCREDIT_ASYNC.SUCCESS:
      return {
        ...state,
        postTopUpCreditIsSuccess: true,
        isLoadingPostTopUpCredit: false,
        topUpCredit: action.data,
      };

    case types.GET_CREDITBALANCE_ASYNC.PENDING:
      return {
        ...state,
        getCreditBalanceIsSuccess: false,
        isLoadingCreditBalance: true,
        creditBalance: null,
      };

    case types.GET_CREDITBALANCE_ASYNC.ERROR:
      return {
        ...state,
        getCreditBalanceIsSuccess: false,
        isLoadingCreditBalance: false,
        creditBalance: null,
        error: { ...state.error, creditBalanceError: action.error },
      };

    case types.GET_CREDITBALANCE_ASYNC.SUCCESS:
      return {
        ...state,
        getCreditBalanceIsSuccess: true,
        isLoadingCreditBalance: false,
        creditBalance: action.data,
      };

    case types.GET_ALLALLIANCEORGANISATION_ASYNC.PENDING:
      return {
        ...state,
        getAllAllianceOrganisationIsSuccess: false,
        isLoadingAllAllianceOrganisation: true,
        allAllianceOrganisation: null,
      };

    case types.GET_ALLALLIANCEORGANISATION_ASYNC.ERROR:
      return {
        ...state,
        getAllAllianceOrganisationIsSuccess: false,
        isLoadingAllAllianceOrganisation: false,
        allAllianceOrganisation: null,
        error: { ...state.error, allAllianceOrganisationError: action.error },
      };

    case types.GET_ALLALLIANCEORGANISATION_ASYNC.SUCCESS:
      return {
        ...state,
        getAllAllianceOrganisationIsSuccess: true,
        isLoadingAllAllianceOrganisation: false,
        allAllianceOrganisation: action.data,
      };
    case types.GET_ALLORGANISATIONSTRIPE_ASYNC.PENDING:
      return {
        ...state,
        getAllOrganisationStripeIsSuccess: false,
        isLoadingAllOrganisationStripe: true,
        allOrganisationStripe: null,
      };

    case types.GET_ALLORGANISATIONSTRIPE_ASYNC.ERROR:
      return {
        ...state,
        getAllOrganisationStripeIsSuccess: false,
        isLoadingAllOrganisationStripe: false,
        allOrganisationStripe: null,
        error: { ...state.error, allOrganisationStripeError: action.error },
      };

    case types.GET_ALLORGANISATIONSTRIPE_ASYNC.SUCCESS:
      return {
        ...state,
        getAllOrganisationStripeIsSuccess: true,
        isLoadingAllOrganisationStripe: false,
        allOrganisationStripe: action.data,
      };

    case types.GET_DEFAULTALLIANCE_ASYNC.PENDING:
      return {
        ...state,
        getDefaultAllianceIsSuccess: false,
        isLoadingDefaultAlliance: true,
        defaultAlliance: null,
      };

    case types.GET_DEFAULTALLIANCE_ASYNC.ERROR:
      return {
        ...state,
        getDefaultAllianceIsSuccess: false,
        isLoadingDefaultAlliance: false,
        defaultAlliance: null,
        error: { ...state.error, defaultAllianceError: action.error },
      };

    case types.GET_DEFAULTALLIANCE_ASYNC.SUCCESS:
      return {
        ...state,
        getDefaultAllianceIsSuccess: true,
        isLoadingDefaultAlliance: false,
        defaultAlliance: action.data,
      };

    case types.GET_ORGANISATIONLOGO_ASYNC.PENDING:
      return {
        ...state,
        getOrganisationLogoIsSuccess: false,
        isLoadingOrganisationLogo: true,
        OrganisationLogo: null,
        error: { ...state.error, organisationLogoError: null },
      };

    case types.GET_ORGANISATIONLOGO_ASYNC.ERROR:
      return {
        ...state,
        getOrganisationLogoIsSuccess: false,
        isLoadingOrganisationLogo: false,
        OrganisationLogo: null,
        error: { ...state.error, organisationLogoError: action.error },
      };

    case types.GET_ORGANISATIONLOGO_ASYNC.SUCCESS:
      return {
        ...state,
        getOrganisationLogoIsSuccess: true,
        isLoadingOrganisationLogo: false,
        OrganisationLogo: action.data,
        error: { ...state.error, organisationLogoError: null },
      };

    case types.GET_CURRENTUSERUSERCREDITLIST_ASYNC.PENDING:
      return {
        ...state,
        getCurrentUserUserCreditListIsSuccess: false,
        isLoadingGetCurrentUserUserCreditList: true,
        getCurrentUserUserCreditList: null,
        error: { ...state.error, getCurrentUserUserCreditListError: null },
      };

    case types.GET_CURRENTUSERUSERCREDITLIST_ASYNC.ERROR:
      return {
        ...state,
        getCurrentUserUserCreditListIsSuccess: false,
        isLoadingGetCurrentUserUserCreditList: false,
        getCurrentUserUserCreditList: null,
        error: {
          ...state.error,
          getCurrentUserUserCreditListError: action.error,
        },
      };

    case types.GET_CURRENTUSERUSERCREDITLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getCurrentUserUserCreditListIsSuccess: true,
        isLoadingGetCurrentUserUserCreditList: false,
        getCurrentUserUserCreditList: action.data,
        error: { ...state.error, getCurrentUserUserCreditListError: null },
      };

    default:
      return state;
  }
};
