import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  putInvoiceMedicineCostingWIPMode,
  putInvoiceMedicineCostingPendingStatus,
  getInvoiceMedicineCostingList,
  getInvoiceMedicineCostingSummaryList,
  getInvoiceMedicineCostingById,
  getInvoiceMedicineCostingByOrderNum,
  postSubmitInvoiceMedicineCosting,
  putInvoiceMedicineCostingPrintDate,
} from "../actions/invoiceMedicineCostingActions";
import * as types from "../_constants/invoiceMedicineCostingConstants";
import * as notification from "../_constants/notificationConstants";
import invoiceMedicineCostingApis from "../_apis/invoiceMedicineCostingApis";
export default function* invoiceMedicineCostingSagas() {
  yield all([
    takeLatest(
      types.PUT_INVOICEMEDICINECOSTINGWIPMODE_ASYNC.PENDING,
      putInvoiceMedicineCostingWIPModeCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_INVOICEMEDICINECOSTINGPENDINGSTATUS_ASYNC.PENDING,
      putInvoiceMedicineCostingPendingStatusCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_INVOICEMEDICINECOSTINGLIST_ASYNC.PENDING,
      getInvoiceMedicineCostingListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_INVOICEMEDICINECOSTINGSUMMARYLIST_ASYNC.PENDING,
      getInvoiceMedicineCostingSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_INVOICEMEDICINECOSTINGBYID_ASYNC.PENDING,
      getInvoiceMedicineCostingByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_INVOICEMEDICINECOSTINGBYORDERNUM_ASYNC.PENDING,
      getInvoiceMedicineCostingByOrderNumCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SUBMITINVOICEMEDICINECOSTING_ASYNC.PENDING,
      postSubmitInvoiceMedicineCostingCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_INVOICEMEDICINECOSTINGPRINTDATE_ASYNC.PENDING,
      putInvoiceMedicineCostingPrintDateCall
    ),
  ]);
}

function* putInvoiceMedicineCostingWIPModeCall(Model) {
  try {
    const data = yield call(
      invoiceMedicineCostingApis.putInvoiceMedicineCostingWIPModeApi,
      Model.data
    );
    yield put(putInvoiceMedicineCostingWIPMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putInvoiceMedicineCostingWIPMode.error(error.response?.data?.message)
      );
    else
      yield put(
        putInvoiceMedicineCostingWIPMode.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putInvoiceMedicineCostingPendingStatusCall(Model) {
  try {
    const data = yield call(
      invoiceMedicineCostingApis.putInvoiceMedicineCostingPendingStatusApi,
      Model.data
    );
    yield put(putInvoiceMedicineCostingPendingStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putInvoiceMedicineCostingPendingStatus.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        putInvoiceMedicineCostingPendingStatus.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getInvoiceMedicineCostingListCall(Model) {
  try {
    const data = yield call(
      invoiceMedicineCostingApis.getInvoiceMedicineCostingListApi,
      Model.data
    );
    yield put(getInvoiceMedicineCostingList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getInvoiceMedicineCostingList.error(error.response?.data?.message)
      );
    else
      yield put(
        getInvoiceMedicineCostingList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getInvoiceMedicineCostingSummaryListCall(Model) {
  try {
    const data = yield call(
      invoiceMedicineCostingApis.getInvoiceMedicineCostingSummaryListApi,
      Model.data
    );
    yield put(getInvoiceMedicineCostingSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getInvoiceMedicineCostingSummaryList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getInvoiceMedicineCostingSummaryList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getInvoiceMedicineCostingByIdCall(Model) {
  try {
    const data = yield call(
      invoiceMedicineCostingApis.getInvoiceMedicineCostingByIdApi,
      Model.data
    );
    yield put(getInvoiceMedicineCostingById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getInvoiceMedicineCostingById.error(error.response?.data?.message)
      );
    else
      yield put(
        getInvoiceMedicineCostingById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getInvoiceMedicineCostingByOrderNumCall(Model) {
  try {
    const data = yield call(
      invoiceMedicineCostingApis.getInvoiceMedicineCostingByOrderNumApi,
      Model.data
    );
    yield put(getInvoiceMedicineCostingByOrderNum.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getInvoiceMedicineCostingByOrderNum.error(error.response?.data?.message)
      );
    else
      yield put(
        getInvoiceMedicineCostingByOrderNum.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postSubmitInvoiceMedicineCostingCall(Model) {
  try {
    const data = yield call(
      invoiceMedicineCostingApis.postSubmitInvoiceMedicineCostingApi,
      Model.data
    );
    yield put(postSubmitInvoiceMedicineCosting.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postSubmitInvoiceMedicineCosting.error(error.response?.data?.message)
      );
    else
      yield put(
        postSubmitInvoiceMedicineCosting.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putInvoiceMedicineCostingPrintDateCall(Model) {
  try {
    const data = yield call(
      invoiceMedicineCostingApis.putInvoiceMedicineCostingPrintDateApi,
      Model.data
    );
    yield put(putInvoiceMedicineCostingPrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putInvoiceMedicineCostingPrintDate.error(error.response?.data?.message)
      );
    else
      yield put(
        putInvoiceMedicineCostingPrintDate.error(notification.CONST_NOINTERNET)
      );
  }
}
