//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import Paper from "@mui/material/Paper";

import { useTranslation } from "react-i18next";
import * as generalConstants from "../../../../_constants/generalConstants";
//actions
import { putEditUserRole } from "../../../../actions/userRoleActions";
//components
import StyledCheckbox from "../../../../components/StyledCheckbox";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";

//global functions

function not(a, b) {
  return a.filter((item1) => !b.find((item2) => item1.id === item2.id));
}

function intersection(a, b) {
  return a.filter((item1) => b.find((item2) => item1.id === item2.id));
}

// function union(a, b) {
//   return [...a, ...not(b, a)];
// }

export const EditUserRolePage = ({
  user,
  setEditUserRoleView,
  roleList,
  userRoleList,
}) => {
  //variables
  const { t } = useTranslation();
  const avaiRoles = roleList.map((role) => {
    const { desc, remarks, organisations, ...rest } = role;
    return rest;
  });

  const curRoles = userRoleList.map((role) => {
    return { userRoleId: role.id, ...role.roles };
  });

  const avaiRole = not(avaiRoles, curRoles);

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const userRoledata = useSelector((state) => state.userRole);
  const prevSubmitStatus = useRef();
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(avaiRole);
  const [right, setRight] = React.useState(curRoles);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  //functions
  const onSubmit = () => {
    setConfirmSubmit(true);
  };
  const onConfirmSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    //except of right choice
    const excRole = not(curRoles, right);
    //intersection of right choice
    const interAvaiRoles = intersection(curRoles, right);
    //delected roles
    const delCurRoles = excRole.map((role) => {
      const { userRoleId, ...rest } = role;
      return {
        id: role.userRoleId,
        roles: rest,
        status_type: generalConstants.DELETED_STATUS_TYPE,
      };
    });
    //remaining roles
    const selectedCurRoles = interAvaiRoles.map((role) => {
      const { userRoleId, ...rest } = role;
      return {
        id: role.userRoleId,
        roles: rest,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
      };
    });
    //new roles
    const newRoles = not(right, selectedCurRoles);
    const selectedRoles = newRoles.map((role) => {
      return {
        id: 0,
        roles: role,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
      };
    });
    const finalRoles = {
      user: {
        id: user.id,
      },
      user_roles: [...delCurRoles, ...selectedCurRoles, ...selectedRoles],
    };

    dispatch(putEditUserRole.pending(finalRoles));
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };
  const customList = (items) => (
    <Paper className={classes.rolePaper}>
      <List dense component="div" role="list">
        {items.map((value, i) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem
              className={i % 2 ? classes.rowBackground : ""}
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}>
              <ListItemIcon>
                <StyledCheckbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={` ${value.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  //useEffects

  useEffect(() => {
    //set ref when first render

    prevSubmitStatus.current = false;
  }, []);

  useEffect(() => {
    if (userRoledata.isLoadingEditUserRole !== prevSubmitStatus.current) {
      //check the previous ref with current state
      prevSubmitStatus.current = userRoledata.isLoadingEditUserRole;

      if (
        userRoledata.putEditUserRoleIsSuccess === true &&
        userRoledata.isLoadingEditUserRole === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        window.scrollTo(0, 0);
      }
      if (
        userRoledata.putEditUserRoleIsSuccess === false &&
        userRoledata.isLoadingEditUserRole === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(userRoledata.error.EditUserRoleError);
        window.scrollTo(0, 0);
      }
    }
  }, [userRoledata]);

  return (
    <Panel heading={user.username}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:User Role Updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}>
          <Grid item>
            <label className={classes.boldCenterMaroon100}>
              {t("translation:Available Roles")}
            </label>
            {customList(left.sort((a, b) => a.id - b.id))}
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllRight}
                disabled={left.length === 0}
                aria-label="move all right">
                ≫
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right">
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left">
                &lt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllLeft}
                disabled={right.length === 0}
                aria-label="move all left">
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <label className={classes.boldCenterMaroon100}>
              {t("translation:Selected Roles")}
            </label>
            {customList(right.sort((a, b) => a.id - b.id))}
          </Grid>
        </Grid>
        <>
          <PageSubmitWBackAction setView={() => setEditUserRoleView(false)} />
        </>
      </form>
    </Panel>
  );
};
