import React, { useEffect } from "react";

import { useTable, useSortBy, usePagination } from "react-table";
import MaUTable from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { Button, IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Panel from "./Panel";
import * as pageConfigure from "../_constants/pageConstants";
import { useStyles } from "./globalTableStyles";
import { useTheme } from "@mui/styles";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export const MultiFieldSortingSearchTableWOData = (props) => {
  const {
    tableHeading,
    columns,
    data,
    pageInfo,
    setPageInfo,
    pageCount: controlledPageCount,
    loading,
    totalItems,
    handleSearch,
    disabledSearch,
    disabledFooter,
    statusOption,
    statusPlaceholder,
  } = props;
  const {
    getTableProps,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { sortBy, pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      manualPagination: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
      initialState: {
        pageIndex: pageInfo.page_number - 1,
        pageSize: pageInfo.page_size,
      },
    },
    useSortBy,
    usePagination
  );

  //variables
  const { t } = useTranslation();

  useEffect(() => {
    if (sortBy.length > 0) {
      setPageInfo((pageInfo) => ({
        ...pageInfo,

        order_by_field: sortBy[0].id,
        order_by_direction: sortBy[0].desc ? "Desc" : "Asc",
      }));
    }
  }, [sortBy, setPageInfo]);

  const handlePreviousPage = () => {
    previousPage();
    setPageInfo({
      ...pageInfo,
      page_number: parseInt(pageIndex),
    });
  };

  const handleNextPage = () => {
    nextPage();
    setPageInfo({
      ...pageInfo,
      page_number: parseInt(pageIndex + 2),
    });
  };
  const handleChangeRowsPerPage = (event) => {
    gotoPage(0);
    setPageSize(parseInt(event.target.value, 10));
    setPageInfo({
      ...pageInfo,
      page_size: parseInt(event.target.value, 10),
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
    });
  };

  const handleChangeFistPage = () => {
    gotoPage(0);
    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
    });
  };
  const handleChangeLastPage = () => {
    gotoPage(pageCount - 1);

    setPageInfo({
      ...pageInfo,
      page_number: parseInt(pageCount, 10),
    });
  };

  const handleChangeSearchBar = (event) => {
    event.currentTarget.form.reportValidity();
    var checkVal = event.currentTarget.form.checkValidity();
    if (checkVal) {
      gotoPage(0);

      handleSearch(event);
    }
  };
  const getStatusOpObj = (option) => {
    if (!option.id) option = statusOption.find((op) => op.id === option);
    return option;
  };
  const handleChangeSearchStatus = (event, option) => {
    gotoPage(0);

    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      search_status_type_value: option ? option.id : null,
    });
  };
  const paginationClasses = useStyles();
  function TablePaginationActions() {
    const theme = useTheme();

    return (
      <span className={paginationClasses.root}>
        <IconButton
          onClick={handleChangeFistPage}
          disabled={!canPreviousPage}
          aria-label="first page"
          size="large">
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handlePreviousPage}
          disabled={!canPreviousPage}
          aria-label="previous page"
          size="large">
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextPage}
          disabled={!canNextPage}
          aria-label="next page"
          size="large">
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleChangeLastPage}
          disabled={!canNextPage}
          aria-label="last page"
          size="large">
          <LastPage />
        </IconButton>
      </span>
    );
  }

  return (
    <Panel heading={tableHeading}>
      {loading && <>{t("translation:Loading")}...</>}
      {!disabledSearch && !loading && (
        <form>
          <Grid container>
            <Grid
              item
              xs={statusOption ? 10 : 12}
              className={paginationClasses.userInfoField}>
              {props.children}
              <Button onClick={(e) => handleChangeSearchBar(e)}>
                <SearchIcon className={paginationClasses.iconColor} />
              </Button>
            </Grid>

            {statusOption && (
              <Grid item xs={2} className={paginationClasses.userInfoField}>
                <Autocomplete
                  name="Order Status"
                  options={statusOption}
                  filterOptions={(options) => {
                    const result = options.filter(
                      (item) =>
                        item.id !== pageConfigure.DETELED_GENERAL_STATUS &&
                        item.id !== pageConfigure.APPROVED_GENERAL_STATUS
                    );

                    return result;
                  }}
                  value={
                    pageInfo.search_status_type_value
                      ? pageInfo.search_status_type_value
                      : null
                  }
                  getOptionLabel={(option) => getStatusOpObj(option).name}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) return option;
                  }}
                  onChange={(e, option) =>
                    handleChangeSearchStatus(e, option == null ? null : option)
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      className={paginationClasses.userFieldColor}
                      label={statusPlaceholder}
                      {...params}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </form>
      )}

      {!loading && (
        <Grid container>
          <Grid item xs={12}>
            <>
              <TableContainer component={Paper}>
                <MaUTable {...getTableProps()}>
                  {!disabledFooter && (
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          onPageChange={handleChangeRowsPerPage}
                          rowsPerPageOptions={pageConfigure.DEFAULT_PAGEOPTIONS}
                          colSpan={12}
                          count={totalItems}
                          rowsPerPage={pageSize}
                          page={pageIndex}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </MaUTable>
              </TableContainer>
            </>
          </Grid>
        </Grid>
      )}
    </Panel>
  );
};
