//lib
import React from "react";
import { Button } from "@mui/material";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { useStyles } from "../../../../components/globalTableStyles";
import { useTranslation } from "react-i18next";
//components
import { ReactComponent as DeleteIcon } from "../../../../assets/coolicons/basic/trash_empty.svg";
import EditableCell from "../../../../components/EditableCell";

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({ columns, data, updateMyData, disablePageResetOnDataChange }) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        updateMyData,
        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();
  const { t } = useTranslation();
  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    <TableCell className={paginationClasses.narrowCellStyle}>
                      {i + 1}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={paginationClasses.narrowCellStyle}
                          {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: cell.column.editable,
                            editableNum: cell.column.editableNum,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const InvoiceDetailsTable = ({
  viewOnly = false,
  data = {},
  setData = () => null,
  t,
}) => {
  const handleRowDel = (rowIndex, action) => {
    setData(rowIndex, action);
  };

  const columns = [
    {
      id: "Delete",
      textAlign: "left",
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDel(row.index, "delete")}>
            <DeleteIcon />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Receipt"),
      accessor: "receipt_header.order_number",
      Cell: ({ cell }) => {
        return <>{cell.value ? cell.value : ""}</>;
      },
    },
    {
      Header: t("translation:Payment Method"),
      sortable: false,
      accessor: (d) => {
        if (d.payment_method_name) return d.payment_method_name;
        else if (d.payment_method && d.payment_method.name)
          return d.payment_method.name;
        else return "";
      },
    },
    {
      Header: t("translation:Payment Amount"),
      accessor: "payment_amount",
      Cell: ({ cell }) => {
        return <>{cell.value !== null ? cell.value.toFixed(2) : ""}</>;
      },
    },
    {
      Header: t("translation:Received Date"),
      sortable: false,
      accessor: (d) => {
        if (d.payment_received_on)
          return moment
            .utc(d.payment_received_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
  ];

  const columnsViewOnly = [
    {
      Header: t("translation:Receipt"),
      accessor: "receipt_header.order_number",
      Cell: ({ cell }) => {
        return <>{cell.value ? cell.value : ""}</>;
      },
    },
    {
      Header: t("translation:Payment Method"),
      sortable: false,
      accessor: (d) => {
        if (d.payment_method_name) return d.payment_method_name;
        else if (d.payment_method && d.payment_method.name)
          return d.payment_method.name;
        else return "";
      },
    },
    {
      Header: t("translation:Payment Amount"),
      accessor: "payment_amount",
      Cell: ({ cell }) => {
        return <>{cell.value !== null ? cell.value.toFixed(2) : ""}</>;
      },
    },
    {
      Header: t("translation:Received Date"),
      sortable: false,
      accessor: (d) => {
        if (d.payment_received_on)
          return moment
            .utc(d.payment_received_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
  ];

  return (
    <Table
      columns={viewOnly ? columnsViewOnly : columns}
      data={data}
      updateMyData={setData}
      t={t}
    />
  );
};

export default InvoiceDetailsTable;
