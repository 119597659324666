import { createAsyncTypes } from "../_helper/Utilities";

export const GET_USERROLELIST_ASYNC = createAsyncTypes("GET_USERROLELIST");

export const PUT_EDITUSERROLE_ASYNC = createAsyncTypes("PUT_EDITUSERROLE");

export const GET_USERROLE_ASYNC = createAsyncTypes("GET_USERROLE");

export const GET_USERROLEALLROLE_ASYNC = createAsyncTypes(
  "GET_USERROLEALLROLE"
);
