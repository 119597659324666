import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DeliveryCutoffScheduleSummaryPage from "./DeliveryCutoffScheduleSummary/DeliveryCutoffScheduleSummaryPage";
export default function DeliveryCutoffSchedulePage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/deliveryCutoffSchedule"
        to="/deliveryCutoffSchedule/deliveryCutoffScheduleSummary"
      />
      <Route
        path="/deliveryCutoffSchedule/deliveryCutoffScheduleSummary"
        component={DeliveryCutoffScheduleSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
