import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CourierSummaryPage from "./CourierSummary/CourierSummaryPage";
export default function CourierPage() {
  return (
    <Switch>
      <Route path="/courier/CourierSummary" component={CourierSummaryPage} />

      <Redirect to="/error" />
    </Switch>
  );
}
