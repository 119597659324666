import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import * as generalConstants from "../../../../../_constants/generalConstants";
import organisationPriceRounding from "../../../../../components/functions/organisationPriceRounding";
import { useStyles } from "../../../../../components/globalStyles";
import onlyFixedNumerics from "../../../../../components/functions/onlyFixedNumerics";
//style

export default function GroupTotal({
  price,
  memoSelectedGroup,
  handleMedicineGroupNumInfo,
  viewOnly = false,
  showMultiplier = false,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [groupWeight, setGroupWeight] = useState(0);

  useEffect(() => {
    const selectedGroupResults = memoSelectedGroup;

    let temGroupWeight = 0;
    let temPackNumber = 0;

    if (
      selectedGroupResults.store_medicine_details &&
      Object.keys(selectedGroupResults.store_medicine_details).length !== 0 &&
      selectedGroupResults.store_medicine_details.some(
        (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
      )
    ) {
      temGroupWeight = selectedGroupResults.store_medicine_details.reduce(
        (sum, li) => {
          if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
            return Number(sum + li.quantity_per_day);
          } else {
            return Number(sum);
          }
        },
        0
      );
    }
    if (viewOnly && selectedGroupResults.max_grams_per_pack) {
      const gramPerTime = onlyFixedNumerics(
        temGroupWeight / memoSelectedGroup.number_of_times
      );
      const packsPerTime = Math.ceil(
        onlyFixedNumerics(gramPerTime / selectedGroupResults.max_grams_per_pack)
      );
      const totalPacks = Math.ceil(
        onlyFixedNumerics(
          packsPerTime *
            memoSelectedGroup.number_of_times *
            memoSelectedGroup.number_of_days
        )
      );
      temPackNumber = totalPacks;
    } else if (
      !viewOnly &&
      selectedGroupResults.medicine_type?.max_grams_per_pack
    ) {
      if (
        memoSelectedGroup.number_of_times &&
        memoSelectedGroup.number_of_days
      ) {
        const gramPerTime = onlyFixedNumerics(
          temGroupWeight / memoSelectedGroup.number_of_times
        );
        const packsPerTime = Math.ceil(
          onlyFixedNumerics(
            gramPerTime / selectedGroupResults.medicine_type?.max_grams_per_pack
          )
        );
        const totalPacks = Math.ceil(
          onlyFixedNumerics(
            packsPerTime *
              memoSelectedGroup.number_of_times *
              memoSelectedGroup.number_of_days
          )
        );
        temPackNumber = totalPacks;
      }
    } else {
      if (selectedGroupResults.is_brewing_services) {
        temPackNumber = Math.ceil(
          onlyFixedNumerics(
            memoSelectedGroup.number_of_days * memoSelectedGroup.number_of_times
          )
        );
      } else {
        temPackNumber = Math.ceil(
          onlyFixedNumerics(memoSelectedGroup.number_of_days)
        );
      }
    }
    //setPackNumber(temPackNumber);
    if (
      !memoSelectedGroup.is_packs_override &&
      memoSelectedGroup.no_of_packs !== temPackNumber &&
      temPackNumber
    ) {
      handleMedicineGroupNumInfo({
        target: { name: "no_of_packs", value: temPackNumber },
      });
    }
    setGroupWeight(temGroupWeight);
  }, [memoSelectedGroup, viewOnly]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end">
      {showMultiplier && (
        <Grid xs={3} item className={classes.subTotalInfoField}>
          <TextField
            disabled={viewOnly}
            className={classes.userFieldColor}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            value={
              memoSelectedGroup.multiplier !== null
                ? memoSelectedGroup.multiplier
                : ""
            }
            onChange={(e) => handleMedicineGroupNumInfo(e)}
            id="multiplier"
            onFocus={(event) => {
              event.target.select();
            }}
            name="multiplier"
            label={t("translation:Multiplier")}
            variant="outlined"
          />
        </Grid>
      )}
      {memoSelectedGroup.is_packs_override ? (
        <Grid xs={3} item className={classes.subTotalInfoField}>
          <TextField
            disabled={viewOnly}
            className={classes.userFieldColor}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E" ||
                event?.key === "."
              ) {
                event.preventDefault();
              }
            }}
            value={
              memoSelectedGroup.no_of_packs !== null
                ? memoSelectedGroup.no_of_packs
                : ""
            }
            required
            onChange={(e) => handleMedicineGroupNumInfo(e)}
            id="no_of_packs"
            onFocus={(event) => {
              event.target.select();
            }}
            name="no_of_packs"
            label={t("translation:Packs")}
            variant="outlined"
          />
        </Grid>
      ) : (
        <Grid xs={3} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            value={
              memoSelectedGroup.no_of_packs !== null
                ? memoSelectedGroup.no_of_packs
                : 0
            }
            disabled
            name="no_of_packs"
            cy_data="no_of_packs"
            label={t("translation:Packs")}
            variant="outlined"
            inputProps={{
              style: { textAlign: "right" },
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
          />
        </Grid>
      )}

      <Grid xs={3} item className={classes.subTotalInfoField}>
        <TextField
          className={classes.userFieldColor}
          value={groupWeight.toFixed(2)}
          disabled
          name="groupWeight"
          cy_data="groupWeight"
          label={t("translation:Group Weight / Day")}
          variant="outlined"
          inputProps={{
            style: { textAlign: "right" },
          }}
          InputLabelProps={{
            shrink: true,

            style: {
              fontWeight: "bold",
              color: "rgba(149, 0, 0,0.75)",
            },
          }}
        />
      </Grid>

      <Grid xs={3} item className={classes.subTotalInfoField}>
        <TextField
          className={classes.userFieldColor}
          value={
            viewOnly
              ? organisationPriceRounding(
                  memoSelectedGroup.price,
                  memoSelectedGroup.price_rmt,
                  memoSelectedGroup.price_rpvt
                )
              : price.medicineGroupTotal
          }
          disabled
          name="groupTotal"
          cy_data="groupTotal"
          label={t("translation:Group Price")}
          variant="outlined"
          inputProps={{
            style: { textAlign: "right" },
          }}
          InputLabelProps={{
            shrink: true,

            style: {
              fontWeight: "bold",
              color: "rgba(149, 0, 0,0.75)",
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
