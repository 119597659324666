import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/authConstants";
import * as curUserTypes from "../_constants/curUserConstants";

//using this putUserInfo to change user profile
export const putUserInfo = {
  pending: (data) =>
    createAction(curUserTypes.PUT_USERINFO_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(curUserTypes.PUT_USERINFO_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(curUserTypes.PUT_USERINFO_ASYNC.ERROR, { error }),
};

export const postLogin = {
  pending: (data) => createAction(types.POST_LOGIN_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.POST_LOGIN_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.POST_LOGIN_ASYNC.ERROR, { error }),
};

export const postRegistration = {
  pending: (data) =>
    createAction(types.POST_REGISTRATION_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_REGISTRATION_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_REGISTRATION_ASYNC.ERROR, { error }),
};

export const postSelfRegistration = {
  pending: (data) =>
    createAction(types.POST_SELFREGISTRATION_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SELFREGISTRATION_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SELFREGISTRATION_ASYNC.ERROR, { error }),
};

export const postLogOut = {
  pending: (data) => createAction(types.POST_LOGOUT_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.POST_LOGOUT_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.POST_LOGOUT_ASYNC.ERROR, { error }),
};

export const postResetPassword = {
  pending: (data) =>
    createAction(types.POST_RESETPASSWORD_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_RESETPASSWORD_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_RESETPASSWORD_ASYNC.ERROR, { error }),
};

export const postResetPasswordRq = {
  pending: (data) =>
    createAction(types.POST_RESETPASSWORDRQ_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_RESETPASSWORDRQ_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_RESETPASSWORDRQ_ASYNC.ERROR, { error }),
};

export const putChangePassword = {
  pending: (data) =>
    createAction(types.PUT_CHANGEPASSWORD_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_CHANGEPASSWORD_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_CHANGEPASSWORD_ASYNC.ERROR, { error }),
};

export const postVerifyEmailRq = {
  pending: (data) =>
    createAction(types.POST_VERIFYEMAILRQ_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VERIFYEMAILRQ_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VERIFYEMAILRQ_ASYNC.ERROR, { error }),
};

export const postVerifyEmail = {
  pending: (data) =>
    createAction(types.POST_VERIFYEMAIL_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VERIFYEMAIL_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.POST_VERIFYEMAIL_ASYNC.ERROR, { error }),
};

export const getProviderAuthorize = {
  pending: (data) =>
    createAction(types.GET_PROVIDERAUTHORIZE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PROVIDERAUTHORIZE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_PROVIDERAUTHORIZE_ASYNC.ERROR, { error }),
};

export const postProviderAccessToken = {
  pending: (data) =>
    createAction(types.POST_PROVIDERACCESSTOKEN_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_PROVIDERACCESSTOKEN_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_PROVIDERACCESSTOKEN_ASYNC.ERROR, { error }),
};

export const postOtpCallback = {
  pending: (data) =>
    createAction(types.POST_OTPCALLBACK_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_OTPCALLBACK_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.POST_OTPCALLBACK_ASYNC.ERROR, { error }),
};
