import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/formulaConstants";

export const getFormulaById = {
  pending: (data) =>
    createAction(types.GET_FORMULABYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_FORMULABYID_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_FORMULABYID_ASYNC.ERROR, { error }),
};

export const getFormulaList = {
  pending: (data) =>
    createAction(types.GET_FORMULALIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_FORMULALIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_FORMULALIST_ASYNC.ERROR, { error }),
};

export const postCreateFormula = {
  pending: (data) =>
    createAction(types.POST_CREATEFORMULA_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEFORMULA_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEFORMULA_ASYNC.ERROR, { error }),
};

export const putUpdateFormula = {
  pending: (data) =>
    createAction(types.PUT_UPDATEFORMULA_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEFORMULA_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEFORMULA_ASYNC.ERROR, { error }),
};

export const getFormulaDispensaryBranch = {
  pending: (data) =>
    createAction(types.GET_FORMULADISPENSARYBRANCH_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_FORMULADISPENSARYBRANCH_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_FORMULADISPENSARYBRANCH_ASYNC.ERROR, { error }),
};

export const getFormulaMedicineTypeById = {
  pending: (data) =>
    createAction(types.GET_FORMULAMEDICINETYPEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_FORMULAMEDICINETYPEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_FORMULAMEDICINETYPEBYID_ASYNC.ERROR, { error }),
};
