//lib
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

//action

import {
  postBranchMedicineMedicineTypeList,
  postBranchMedicineMedicineList,
  getBranchMedicineList,
} from "../../../../../actions/branchMedicineActions";

//components
import { useStyles } from "../../../../../components/globalStyles";
import * as generalConstants from "../../../../../_constants/generalConstants";
import Panel from "../../../../../components/Panel";

const MedicineSelectionField = ({
  medicineType,
  setMedicineType,
  medicine,
  setMedicine,
  setBranchMedicines,
  branchId,
}) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const branchMedicine = useSelector((state) => state.branchMedicine);

  //functions
  const optionAllow = (options) => {
    const result = options.filter(
      (item) => item.status_type !== generalConstants.DELETED_STATUS_TYPE
    );

    return result;
  };
  const handleMedicineTypeOption = (option) => {
    setMedicineType(option);

    const JsonText = {
      is_pagination: false,
      order_by_direction: null,
      order_by_field: null,
      page_number: null,
      page_size: null,
      search_desc_value: null,
      search_id_value: null,
      search_medicine_type_id_value: option.id,
      search_name_value: null,
      search_status_type_value: null,
    };
    dispatch(postBranchMedicineMedicineList.pending(JsonText));
  };

  //useEffects
  useEffect(() => {
    dispatch(
      postBranchMedicineMedicineTypeList.pending({ is_pagination: false })
    );
  }, [dispatch]);

  return (
    <Panel>
      <Grid className={classes.gridRoot} container>
        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            value={medicineType}
            required
            disableClearable
            name="medicine_type"
            cy_data="searchMedicineType"
            id="medicine_type"
            options={
              branchMedicine.branchMedicineMedicineTypeList
                ? optionAllow(
                    branchMedicine.branchMedicineMedicineTypeList.medicine_types
                  )
                : []
            }
            getOptionLabel={(option) => (option.name ? option.name : "")}
            style={{ width: "100%" }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, option) => {
              handleMedicineTypeOption(option);
              //clear medicine
              setMedicine(null);
            }}
            renderInput={(params) => (
              <TextField
                className={classes.userFieldColor}
                required
                {...params}
                label={t("translation:Medicine Type")}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            value={medicine}
            disabled={medicineType ? false : true}
            required
            disableClearable
            name="medicine"
            cy_data="searchMedicine"
            id="medicine"
            options={
              branchMedicine.branchMedicineMedicineList
                ? optionAllow(
                    branchMedicine.branchMedicineMedicineList.medicines
                  )
                : []
            }
            getOptionLabel={(option) => (option.name ? option.name : "")}
            style={{ width: "100%" }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, option) => {
              setMedicine(option);
              //clear medicine branch
              setBranchMedicines(null);
              dispatch(
                getBranchMedicineList.pending({
                  is_pagination: false,
                  search_branch_id_value: branchId,
                  search_medicine_id_value: option.id,
                })
              );
            }}
            renderInput={(params) => (
              <TextField
                className={classes.userFieldColor}
                required
                {...params}
                disabled={medicineType ? false : true}
                label={t("translation:Medicine")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </Panel>
  );
};

export default MedicineSelectionField;
