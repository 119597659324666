//lib
import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Typography,
  Box,
  Link,
  CssBaseline,
  Collapse,
  Container,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
//actions
import { postResetPasswordRq } from "../../../actions/authActions";
//components
import { useStyles } from "../../../components/globalStyles";
import CopyRight from "../../../components/CopyRight";

import * as organisationCodeConstants from "../../../_constants/organisationCodeConstants";

export default function ResetPasswordRequest() {
  //variables
  const classes = useStyles();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.userAuth);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);

  //functions

  const onSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    setDisabledButton(true);
    setTimeoutCountdown(30);

    const hostname = window.location.hostname.replace(/[^a-z0-9]/gi, "_");

    const submitJson = {
      organisation: {
        codename: organisationCodeConstants[hostname + "_code"],
      },
      ...data,
    };
    dispatch(postResetPasswordRq.pending(submitJson));
  };
  const { register, handleSubmit } = useForm();

  //useEffects
  useEffect(() => {
    if (timeoutCountdown > 0 && disabledButton === true) {
      const timeout = setTimeout(() => {
        setTimeoutCountdown(timeoutCountdown - 1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [timeoutCountdown, disabledButton]);

  useEffect(() => {
    if (timeoutCountdown === 0) {
      setDisabledButton(false);
    }
  }, [timeoutCountdown]);
  useEffect(() => {
    setErrorAlert(false);
    setSuccessAlert(false);
    if (
      userAuth.resetPasswordRqSuccess === true &&
      userAuth.isLoadingResetPasswordRq === false
    ) {
      setSuccessAlert(true);
      setMsgAlert(userAuth.userResetPasswordRq);
    }

    if (
      userAuth.resetPasswordRqSuccess === false &&
      userAuth.isLoadingResetPasswordRq === false
    ) {
      setErrorAlert(true);
      setMsgAlert(userAuth.error.resetPasswordRqError);
    }
  }, [userAuth]);

  const [TCMLogo, setTCMLOGO] = React.useState(null);
  React.useEffect(() => {
    const hostname = window.location.hostname.replace(/[^a-z0-9]/gi, "_");

    if (hostname) {
      let isMounted = true;
      import("../../../assets/" + hostname + "/TCMLogo.svg").then((image) => {
        if (isMounted) {
          setTCMLOGO(image.default);
        }
      });

      return () => {
        isMounted = false;
      };
    }
  }, []);

  return (
    <Container component="main">
      <CssBaseline />
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}

      <div className={classes.paper}>
        <img src={TCMLogo} height="200px" witdth="400px" alt="website logo" />

        <Typography className={classes.maroon100} component="h1" variant="h5">
          Reset Password Request
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Collapse in={successAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setSuccessAlert(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlert}
            </Alert>
          </Collapse>

          <Collapse in={errorAlert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlert(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlert}
            </Alert>
          </Collapse>
          <br />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="off"
            {...register("username")}
            autoFocus
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.buttonColor}
            disabled={disabledButton}>
            Confirm {disabledButton && timeoutCountdown}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                className={classes.maroon075}
                href="/signIn"
                variant="body2"
                underline="hover">
                Already have an account? Sign in
              </Link>
            </Grid>
            <Grid item>
              <Link
                className={classes.maroon075}
                href="/registration"
                variant="body2"
                underline="hover">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <CopyRight />
          </Box>
        </form>
      </div>
    </Container>
  );
}
