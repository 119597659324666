import { createAsyncTypes } from "../_helper/Utilities";

export const GET_NOTIFICATIONBYID_ASYNC = createAsyncTypes(
  "GET_NOTIFICATIONBYID"
);

export const GET_NOTIFICATIONLIST_ASYNC = createAsyncTypes(
  "GET_NOTIFICATIONLIST"
);

export const POST_CREATENOTIFICATION_ASYNC = createAsyncTypes(
  "POST_CREATENOTIFICATION"
);

export const PUT_UPDATENOTIFICATION_ASYNC = createAsyncTypes(
  "PUT_UPDATENOTIFICATION"
);
