import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/branchMedicineConstants";

export const getBranchMedicineList = {
  pending: (data) =>
    createAction(types.GET_BRANCHMEDICINELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHMEDICINELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHMEDICINELIST_ASYNC.ERROR, { error }),
};

export const getBranchMedicineById = {
  pending: (data) =>
    createAction(types.GET_BRANCHMEDICINEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHMEDICINEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHMEDICINEBYID_ASYNC.ERROR, { error }),
};

export const putUpdateBranchMedicine = {
  pending: (data) =>
    createAction(types.PUT_UPDATEBRANCHMEDICINE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_UPDATEBRANCHMEDICINE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_UPDATEBRANCHMEDICINE_ASYNC.ERROR, {
      error,
    }),
};

export const postBranchMedicineBranchList = {
  pending: (data) =>
    createAction(types.POST_BRANCHMEDICINEBRANCHLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_BRANCHMEDICINEBRANCHLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_BRANCHMEDICINEBRANCHLIST_ASYNC.ERROR, { error }),
};

export const postBranchMedicineMedicineTypeList = {
  pending: (data) =>
    createAction(types.POST_BRANCHMEDICINEMEDICINETYPELIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_BRANCHMEDICINEMEDICINETYPELIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_BRANCHMEDICINEMEDICINETYPELIST_ASYNC.ERROR, {
      error,
    }),
};

export const postBranchMedicineMedicineList = {
  pending: (data) =>
    createAction(types.POST_BRANCHMEDICINEMEDICINELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_BRANCHMEDICINEMEDICINELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_BRANCHMEDICINEMEDICINELIST_ASYNC.ERROR, { error }),
};

export const postBranchMedicineBranchMedicineHistoryList = {
  pending: (data) =>
    createAction(
      types.POST_BRANCHMEDICINEBRANCHMEDICINEHISTORYLIST_ASYNC.PENDING,
      { data }
    ),
  success: (data) =>
    createAction(
      types.POST_BRANCHMEDICINEBRANCHMEDICINEHISTORYLIST_ASYNC.SUCCESS,
      { data }
    ),
  error: (error) =>
    createAction(
      types.POST_BRANCHMEDICINEBRANCHMEDICINEHISTORYLIST_ASYNC.ERROR,
      { error }
    ),
};
