import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import StyledCheckbox from "../../../../../components/StyledCheckbox";
import externetApis from "../../../../../_apis/externetApis";
import allowOnlyNumericsOrDigits from "../../../../../components/functions/allowOnlyNumericsOrDigits";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { useStyles } from "../../../../../components/globalStyles";
import * as generalConstants from "../../../../../_constants/generalConstants";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
//action
import { getStoreOrderListLinkedDelivery } from "../../../../../actions/workOrderActions";
import { getVisitStoreOrderListLinkedDelivery } from "../../../../../actions/visitOrderActions";
const EditDelivery = ({
  memoSelectedGroupIndex,
  memoSelectedGroup,
  memoGroupOption,
  setGroupOption,
  editOrderInfo,
  showDelivery,
  handleMedicineGroupInfo,
  module,
  confirmSubmitOpen,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workOrder = useSelector((state) => state.workOrder);
  const visitOrder = useSelector((state) => state.visitOrder);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [sameAddressAlarm, setSameAddressAlarm] = React.useState(false);

  const [postcodeSearchPageNum, setPostcodeSearchPageNum] = useState(null);
  const [postcodeSearch, setPostcodeSearch] = useState(null);
  const [addressDisabled, setAddressDisabled] = React.useState(false);
  const [selectedPostalCode, setSelectedPostalCode] = useState(null);
  const [showReminder, setShowReminder] = React.useState(false);
  const handlePostalCodeChange = (event) => {
    setSelectedPostalCode(event.target.value);
    if (event.target.value.length === 6) {
      setOpen(true);
      setPostcodeSearch(event.target.value);
      setOptions([]);
      setPostcodeSearchPageNum(1);
    }

    setAddressDisabled(false);

    const elementsIndex = memoSelectedGroupIndex;
    let newArray = [...memoGroupOption];

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      delivery_address: null,
      delivery_blk_no: null,
      delivery_road_name: null,
      delivery_building: null,
      delivery_postal_code: event.target.value,
    };

    setGroupOption(newArray);
  };

  // useEffect(() => {
  //   //disable delivery service if it is not for dispensary centre
  //   let newArray = memoGroupOption;
  //   if (newArray.length > 0) {
  //     newArray.map((item, index) => {
  //       if (item.organisation_branch?.branch?.is_dispensary_centre === false) {
  //         newArray[index] = {
  //           ...newArray[index],
  //           is_delivery_services: false,
  //         };
  //       }
  //     });
  //     setGroupOption(newArray);
  //   }
  // }, [memoGroupOption, setGroupOption]);

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }
    if (postcodeSearch) {
      (async () => {
        const response = await externetApis.getPostalCodeListApi(
          postcodeSearch,
          postcodeSearchPageNum
        );

        if (response.status === 200) {
          const addressResults = response.data;
          if (addressResults.results && addressResults.results.length > 0) {
            if (addressResults.totalNumPages > addressResults.pageNum) {
              setPostcodeSearchPageNum(addressResults.pageNum + 1);
            }

            if (addressResults.pageNum === 1) {
              setOptions(addressResults.results);
            } else {
              setOptions((optionsResult) => {
                return [...optionsResult, ...addressResults.results];
              });
            }
          } else {
            setOpen(false);
          }
        } else {
          setOpen(false);
        }
      })();
    }
  }, [open, postcodeSearch, postcodeSearchPageNum]);

  React.useEffect(() => {
    if (module === "STORE") {
      dispatch(
        getStoreOrderListLinkedDelivery.pending({
          search_desc_value: "",
          search_order_number_value: "",
          search_orderer_name_value: "",
          page_number: 1,
          page_size: 30,
          order_by_field: null,
          order_by_direction: null,
          excl_visit_order_with_no_delivery_service: true,
          incl_dowo_status_type: [
            generalConstants.NEW_STATUS_TYPE,
            generalConstants.PENDING_STATUS_TYPE,
            generalConstants.APPROVED_STATUS_TYPE,
          ],
        })
      );
    }
    if (module === "VISIT") {
      dispatch(
        getVisitStoreOrderListLinkedDelivery.pending({
          search_desc_value: "",
          search_order_number_value: "",
          search_orderer_name_value: "",
          page_number: 1,
          page_size: 30,
          order_by_field: null,
          order_by_direction: null,
          excl_visit_order_with_no_delivery_service: true,
          incl_dowo_status_type: [
            generalConstants.NEW_STATUS_TYPE,
            generalConstants.PENDING_STATUS_TYPE,
            generalConstants.APPROVED_STATUS_TYPE,
          ],
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (module === "VISIT" && visitOrder.storeOrderListLinkedDelivery) {
      const sameAddress = memoGroupOption.some((groupItem) =>
        visitOrder.storeOrderListLinkedDelivery.store_headers.some((item) =>
          item.store_medicine_headers.some(
            (storeItem) =>
              storeItem.delivery_address?.replaceAll(" ", "") +
                storeItem.delivery_address_details?.replaceAll(" ", "") ===
                groupItem.delivery_address?.replaceAll(" ", "") +
                  groupItem.delivery_address_details?.replaceAll(" ", "") &&
              groupItem.delivery_address
          )
        )
      );

      setSameAddressAlarm(sameAddress);
    }
  }, [memoGroupOption, visitOrder.storeOrderListLinkedDelivery]);

  React.useEffect(() => {
    if (module === "STORE" && workOrder.storeOrderListLinkedDelivery) {
      const sameAddress = memoGroupOption.some((groupItem) =>
        workOrder.storeOrderListLinkedDelivery.store_headers.some((item) =>
          item.store_medicine_headers.some(
            (storeItem) =>
              storeItem.delivery_address?.replaceAll(" ", "") +
                storeItem.delivery_address_details?.replaceAll(" ", "") ===
                groupItem.delivery_address?.replaceAll(" ", "") +
                  groupItem.delivery_address_details?.replaceAll(" ", "") &&
              groupItem.delivery_address
          )
        )
      );

      setSameAddressAlarm(sameAddress);
    }
  }, [memoGroupOption, workOrder.storeOrderListLinkedDelivery]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  useEffect(() => {
    const selectedGroupResults = memoSelectedGroup;

    if (selectedGroupResults.store_medicine_details) {
      setAddressDisabled(
        selectedGroupResults.delivery_postal_code?.length === 6 ? true : false
      );

      setSelectedPostalCode(
        selectedGroupResults.delivery_postal_code
          ? selectedGroupResults.delivery_postal_code
          : ""
      );
    }
  }, [memoSelectedGroup]);

  useEffect(() => {
    const selectedGroupResults = memoSelectedGroup;

    if (selectedGroupResults.store_medicine_details) {
      setAddressDisabled(
        selectedGroupResults.delivery_postal_code?.length === 6 ? true : false
      );

      setSelectedPostalCode(
        selectedGroupResults.delivery_postal_code
          ? selectedGroupResults.delivery_postal_code
          : ""
      );
    }
  }, [memoSelectedGroup]);

  React.useEffect(() => {
    if (sameAddressAlarm && confirmSubmitOpen) {
      setShowReminder(true);
    } else {
      setShowReminder(false);
    }
  }, [sameAddressAlarm, confirmSubmitOpen]);

  const handleSelectedPostalCode = (event) => {
    const elementsIndex = memoSelectedGroupIndex;
    let newArray = [...memoGroupOption];
    if (!addressDisabled) {
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        delivery_postal_code: null,
      };
    }
    setGroupOption(newArray);
  };

  const handleDeliveryService = (event) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option

    setAddressDisabled(editOrderInfo.staff.postal_code ? true : false);
    setSelectedPostalCode(editOrderInfo.staff.postal_code);

    //make a copy of group option
    let newArray = [...memoGroupOption];

    //delete link delivery service
    if (newArray[elementsIndex].delivery_owned?.id) {
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        delivery_owned: {
          id: newArray[elementsIndex].delivery_owned.id,
          delivery_linked_store_medicine_header: null,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        },
      };
    } else {
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        delivery_owned: null,
      };
    }

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      is_delivery_services: event.target.checked,
      delivery_address:
        event.target.checked === true
          ? editOrderInfo.staff.address
            ? editOrderInfo.staff.address
            : null
          : null,

      delivery_blk_no:
        event.target.checked === true
          ? editOrderInfo.staff.blk_no
            ? editOrderInfo.staff.blk_no
            : null
          : null,
      delivery_road_name:
        event.target.checked === true
          ? editOrderInfo.staff.road_name
            ? editOrderInfo.staff.road_name
            : null
          : null,
      delivery_building:
        event.target.checked === true
          ? editOrderInfo.staff.building
            ? editOrderInfo.staff.building
            : null
          : null,
      delivery_address_details:
        event.target.checked === true
          ? editOrderInfo.staff.address_details
            ? editOrderInfo.staff.address_details
            : null
          : null,
      delivery_postal_code:
        event.target.checked === true
          ? editOrderInfo.staff.postal_code
            ? editOrderInfo.staff.postal_code
            : null
          : null,
      delivery_contact_number:
        event.target.checked === true
          ? editOrderInfo.staff.contact_number
          : null,
      delivery_name:
        event.target.checked === true ? editOrderInfo.staff.name : null,
      delivery_remarks: null,
      //delivery_incl_prescription: event.target.checked === true ? false : null,
    };

    setGroupOption(newArray);
  };

  // const handleIncludedPrescription = (event) => {
  //   const elementsIndex = memoSelectedGroupIndex;
  //   //make a copy of group option
  //   let newArray = [...memoGroupOption];
  //   newArray[elementsIndex] = {
  //     ...newArray[elementsIndex],
  //     delivery_incl_prescription: event.target.checked,
  //   };

  //   setGroupOption(newArray);
  // };

  const handleAddressChange = (option) => {
    if (option) {
      setAddressDisabled(true);
      // const addressWithoutPostalIndex = option.ADDRESS.lastIndexOf("SINGAPORE");
      // const addressWithoutPostal = option.ADDRESS.substring(
      //   0,
      //   addressWithoutPostalIndex - 1
      // );

      setSelectedPostalCode(option.POSTAL);
      const elementsIndex = memoSelectedGroupIndex;
      //make a copy of group option
      let newArray = [...memoGroupOption];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        delivery_address: option.ADDRESS,
        delivery_blk_no: option.BLK_NO,
        delivery_road_name: option.ROAD_NAME,
        delivery_building: option.BUILDING !== "NIL" ? option.BUILDING : null,
        delivery_postal_code: option.POSTAL,
      };

      setGroupOption(newArray);
    }
  };

  return (
    <Grid container>
      <ConfirmDialog
        open={showReminder}
        setOpen={setShowReminder}
        title={t("translation:Reminder")}>
        {t(
          "translation:Found at least an order with the same delivery address"
        )}
        .{" "}
        {t(
          "translation:You may use link delivery service to avoid extra delivery fee"
        )}
        .
      </ConfirmDialog>

      {showDelivery === true && (
        <Grid item xs={12} className={classes.userInfoField}>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={memoSelectedGroup.is_delivery_services ? true : false}
                onChange={handleDeliveryService}
                name="is_delivery_services"
              />
            }
            label={t("translation:Delivery Services")}
          />
        </Grid>
      )}

      {showDelivery && memoSelectedGroup.is_delivery_services === true && (
        <>
          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_name
                  ? memoSelectedGroup.delivery_name
                  : ""
              }
              name="delivery_name"
              label={t("translation:Recipient")}
              autoComplete="off"
              required
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleMedicineGroupInfo(e)}
            />
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_contact_number
                  ? memoSelectedGroup.delivery_contact_number
                  : ""
              }
              name="delivery_contact_number"
              required
              label={t("translation:Contact Number")}
              autoComplete="off"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleMedicineGroupInfo(e)}
            />
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <Autocomplete
              style={{ width: "100%" }}
              name="delivery_postal_code"
              disableClearable
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              isOptionEqualToValue={() => {
                return true;
              }}
              inputValue={selectedPostalCode ? selectedPostalCode : ""}
              onChange={(e, option) => handleAddressChange(option)}
              getOptionLabel={(option) =>
                option.ADDRESS ? option.ADDRESS : ""
              }
              options={options}
              loading={open}
              renderInput={(params) => (
                <TextField
                  className={classes.userFieldColor}
                  name="postal_code"
                  onInput={allowOnlyNumericsOrDigits}
                  {...params}
                  onChange={handlePostalCodeChange}
                  onBlur={handleSelectedPostalCode}
                  label={t("translation:Postal Code")}
                  autoComplete="off"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: selectedPostalCode ? true : false,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {open ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_address_details
                  ? memoSelectedGroup.delivery_address_details
                  : ""
              }
              name="delivery_address_details"
              placeholder={t("translation:Floor/ Unit Number")}
              variant="outlined"
              label={t("translation:Address Details")}
              autoComplete="off"
              onChange={(e) => handleMedicineGroupInfo(e)}
            />
          </Grid>

          {memoSelectedGroup.delivery_blk_no ? (
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                disabled
                value={memoSelectedGroup.delivery_blk_no}
                name="blk_no"
                label={t("translation:Block No")}
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
          ) : (
            <></>
          )}

          {memoSelectedGroup.delivery_road_name ? (
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                disabled
                value={memoSelectedGroup.delivery_road_name}
                name="road_name"
                label={t("translation:Road Name")}
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
          ) : (
            <></>
          )}

          {memoSelectedGroup.delivery_building ? (
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                disabled
                value={memoSelectedGroup.delivery_building}
                name="building"
                label={t("translation:Building")}
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_address
                  ? memoSelectedGroup.delivery_address
                  : ""
              }
              InputProps={{
                readOnly: addressDisabled,
                className: addressDisabled ? "Mui-disabled" : undefined,
              }}
              classes={addressDisabled ? { root: "Mui-disabled" } : {}}
              required
              multiline
              rows={3}
              name="delivery_address"
              autoComplete="off"
              label={t("translation:Address")}
              variant="outlined"
              onChange={(e) => handleMedicineGroupInfo(e)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={
                memoSelectedGroup.delivery_remarks
                  ? memoSelectedGroup.delivery_remarks
                  : ""
              }
              multiline
              rows={3}
              name="delivery_remarks"
              label={t("translation:Remarks")}
              variant="outlined"
              onChange={(e) => handleMedicineGroupInfo(e)}
            />
          </Grid>

          {/* <Grid item xs={3} className={classes.userInfoField}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={
                    memoSelectedGroup.delivery_incl_prescription ? true : false
                  }
                  onChange={handleIncludedPrescription}
                  name="delivery_incl_prescription"
                />
              }
              label={t("translation:Print Prescription")}
            />
          </Grid> */}
        </>
      )}
    </Grid>
  );
};
export default EditDelivery;
