import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Link } from "@mui/material";

//actions
import {
  postReportingVerifyCSVFormulas,
  postReportingImportCSVFormulas,
  getReportingExportFormulas,
} from "../../../../actions/reportingActions";
//style
import { useStyles } from "../../../../components/globalStyles";

//components
import * as generalConstants from "../../../../_constants/generalConstants";
import DropZoneDialog from "../../../../components/DropZoneDialog";
import Panel from "../../../../components/Panel";
import UploadCSVDialog from "../../../../components/UploadCSVDialog";
export default function ImportFormula() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reporting = useSelector((state) => state.reporting);
  const curUser = useSelector((state) => state.curUser);
  const exportAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.REPORT_FORMULA_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.READ_PERMISSION_TYPE
      )
    : false;

  const importAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.REPORT_FORMULA_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const [confirmUploadOpen, setConfirmUploadOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [fileObjects, setFileObjects] = React.useState([]);

  const [msgUploadAlarm, setMsgUploadAlarm] = React.useState(null);
  const [alertMsgUpload, setAlertMsgUpload] = React.useState(false);
  const [alertTypeUpload, setAlertTypeUpload] = React.useState(null);

  const [msgImportAlarm, setMsgImportAlarm] = React.useState(null);
  const [alertMsgImport, setAlertMsgImport] = React.useState(false);
  const [alertTypeImport, setAlertTypeImport] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);
  const [verifyLoading, setVerifyLoading] = React.useState(false);
  const prevVerifyStatus = useRef();
  const prevImportStatus = useRef();

  const [verifyInfo, setVerifyInfo] = useState({
    totalItemCount: 0,
    createItem: [],
    createItemCount: 0,
    updateItem: [],
    updateItemCount: 0,
    errorItem: [],
    errorItemCount: 0,
  });

  const verifyFileOnHandle = () => {
    setAlertMsgUpload(false);
    setMsgUploadAlarm(null);
    setAlertTypeUpload(null);
    dispatch(postReportingVerifyCSVFormulas.pending(fileObjects));
  };

  const uploadFileOnHandle = () => {
    dispatch(postReportingImportCSVFormulas.pending(fileObjects));
  };

  const downloadFileOnHandle = () => {
    dispatch(
      getReportingExportFormulas.pending({
        is_pagination: false,
      })
    );
  };

  useEffect(() => {
    //set ref when first render
    prevVerifyStatus.current = false;
    prevImportStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      reporting.isLoadingPostReportingVerifyCSVFormulas !==
      prevVerifyStatus.current
    ) {
      //check the previous ref with current state
      prevVerifyStatus.current =
        reporting.isLoadingPostReportingVerifyCSVFormulas;
      setVerifyLoading(reporting.isLoadingPostReportingVerifyCSVFormulas);
      if (
        reporting.postReportingVerifyCSVFormulasIsSuccess === true &&
        reporting.isLoadingPostReportingVerifyCSVFormulas === false
      ) {
        setVerifyInfo({
          totalItemCount:
            reporting.postReportingVerifyCSVFormulas.import_total_count,
          createItem:
            reporting.postReportingVerifyCSVFormulas.import_creation_details,
          createItemCount:
            reporting.postReportingVerifyCSVFormulas.import_creation_count,
          updateItem:
            reporting.postReportingVerifyCSVFormulas.import_update_details,
          updateItemCount:
            reporting.postReportingVerifyCSVFormulas.import_update_count,
          errorItem:
            reporting.postReportingVerifyCSVFormulas.import_error_details,
          errorItemCount:
            reporting.postReportingVerifyCSVFormulas.import_error_count,
        });

        setConfirmUploadOpen(true);
        setOpen(false);
      }
      if (
        reporting.postReportingVerifyCSVFormulasIsSuccess === false &&
        reporting.isLoadingPostReportingVerifyCSVFormulas === false
      ) {
        setAlertTypeUpload("error");
        setAlertMsgUpload(true);
        setMsgUploadAlarm(reporting.error.postReportingVerifyCSVFormulasError);
      }
    }
  }, [reporting]);

  useEffect(() => {
    if (
      reporting.isLoadingPostReportingImportCSVFormulas !==
      prevImportStatus.current
    ) {
      //check the previous ref with current state
      prevImportStatus.current =
        reporting.isLoadingPostReportingImportCSVFormulas;
      setImportLoading(reporting.isLoadingPostReportingImportCSVFormulas);
      if (
        reporting.postReportingImportCSVFormulasIsSuccess === true &&
        reporting.isLoadingPostReportingImportCSVFormulas === false
      ) {
        setVerifyInfo({
          totalItemCount:
            reporting.postReportingImportCSVFormulas.import_total_count,
          createItem:
            reporting.postReportingImportCSVFormulas.import_creation_details,
          createItemCount:
            reporting.postReportingImportCSVFormulas.import_creation_count,
          updateItem:
            reporting.postReportingImportCSVFormulas.import_update_details,
          updateItemCount:
            reporting.postReportingImportCSVFormulas.import_update_count,
          errorItem:
            reporting.postReportingImportCSVFormulas.import_error_details,
          errorItemCount:
            reporting.postReportingImportCSVFormulas.import_error_count,
        });
        setAlertTypeImport("success");
        setAlertMsgImport(true);
        setMsgImportAlarm(t("translation:Formulas has been updated"));
      }
      if (
        reporting.postReportingImportCSVFormulasIsSuccess === false &&
        reporting.isLoadingPostReportingImportCSVFormulas === false
      ) {
        setAlertTypeImport("error");
        setAlertMsgImport(true);
        setMsgImportAlarm(reporting.error.postReportingVerifyCSVFormulasError);
      }
    }
  }, [reporting, t]);

  return (
    <Panel heading={t("translation:Details")}>
      <Grid item xs={12}>
        {!reporting.isLoadingPostReportingExportCSVFormulas && exportAccess && (
          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              name="downloadButton"
              onClick={() => downloadFileOnHandle()}>
              {t("translation:EXPORT")}
            </Button>
          </Link>
        )}

        {reporting.isLoadingPostReportingExportCSVFormulas && (
          <>{t("translation:Loading")}...</>
        )}
        {importAccess && (
          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              name="uploadButton"
              onClick={() => setOpen(true)}>
              {t("translation:IMPORT")}
            </Button>
          </Link>
        )}

        {open && (
          <DropZoneDialog
            dialogTitle={<span>{t("translation:Upload")}</span>}
            fileObjects={fileObjects}
            fullWidth
            cancelButtonText={t("translation:BACK")}
            submitButtonText={t("translation:VERIFY")}
            acceptedFiles={[".csv"]}
            supportedFileType={".csv"}
            maxFileSize={5000000}
            filesLimit={1}
            dropzoneText={t("translation:Drag and drop a file here or click")}
            previewText={""}
            maxWidth="lg"
            open={open}
            showAlerts={false}
            useChipsForPreview={true}
            onAdd={(newFileObjs) => {
              setAlertMsgUpload(false);
              setMsgUploadAlarm("");

              setFileObjects([...newFileObjs]);
            }}
            onDelete={(deleteFileObj) => {
              setFileObjects([]);
            }}
            onClose={() => {
              setOpen(false);
              setAlertMsgUpload(false);
              setFileObjects([]);
            }}
            onSave={() => {
              verifyFileOnHandle();
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
            msgAlarm={msgUploadAlarm}
            alertMsg={alertMsgUpload}
            setAlertMsg={setAlertMsgUpload}
            alertType={alertTypeUpload}
            dialogLoading={verifyLoading}
            getDropRejectMessage={(
              rejectedFile,
              acceptedFiles,
              maxFileSize
            ) => {
              setMsgUploadAlarm(
                rejectedFile.name + " " + t("translation:is rejected")
              );
              setAlertMsgUpload(true);
              setAlertTypeUpload("warning");
            }}
            getFileLimitExceedMessage={(filesLimit) => {
              setMsgUploadAlarm(
                t("translation:more than") +
                  " " +
                  filesLimit +
                  " " +
                  t("translation:file(s)")
              );
              setAlertMsgUpload(true);
              setAlertTypeUpload("warning");
            }}
          />
        )}

        {confirmUploadOpen && (
          <UploadCSVDialog
            maxWidth="md"
            open={confirmUploadOpen}
            verifyInfo={verifyInfo}
            msgAlarm={msgImportAlarm}
            alertMsg={alertMsgImport}
            alertType={alertTypeImport}
            onClose={() => {
              setConfirmUploadOpen(false);
              setAlertMsgImport(false);
              setFileObjects([]);
            }}
            onSave={() => {
              uploadFileOnHandle();
            }}
            cancelButtonText={t("translation:BACK")}
            submitButtonText={t("translation:IMPORT")}
            dialogLoading={importLoading}
          />
        )}
      </Grid>
    </Panel>
  );
}
