//lib
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Close from "@mui/icons-material/Close";
import AddUserPage from "../../../../administration/user/UserSummary/AddUserPage";

//style//style
export default function AddPatientDialog({
  defaultUserSearchState,
  setDefaultUserSearchState,
  setAddUserOpen,
  addUserOpen,
}) {
  const [scroll, setScroll] = useState(false);

  const ref = React.useRef();

  useEffect(() => {
    if (ref.current && scroll) {
      ref.current.scrollTop = 0;
      setScroll(false);
    }
  }, [ref, scroll]);

  return (
    <>
      <Dialog
        open={addUserOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setAddUserOpen(false);
          }
        }}
        aria-labelledby="confirm-dialog"
        fullWidth
        maxWidth="xl">
        <Close
          style={{ position: "absolute", right: "15px" }}
          onClick={() => setAddUserOpen(false)}
        />
        <DialogContent ref={ref}>
          <AddUserPage
            isPatientEdit={true}
            setScroll={setScroll}
            setUserListView={() => setAddUserOpen(false)}
            defaultUserSearchState={defaultUserSearchState}
            setDefaultUserSearchState={setDefaultUserSearchState}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
