import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import EmailStoreAccountSummaryPage from "./EmailStoreAccountSummary/EmailStoreAccountSummaryPage";
export default function EmailStoreAccountPage() {
  return (
    <Switch>
      <Route
        path="/emailStoreAccount/emailStoreAccountSummary"
        component={EmailStoreAccountSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
