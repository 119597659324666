import * as types from "../_constants/branchScheduleConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_BRANCHSCHEDULELIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchScheduleListIsSuccess: false,
        isLoadingBranchScheduleList: true,
        branchScheduleList: null,
      };

    case types.GET_BRANCHSCHEDULELIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchScheduleListIsSuccess: false,
        isLoadingBranchScheduleList: false,
        branchScheduleList: null,
        error: { ...state.error, branchScheduleListError: action.error },
      };

    case types.GET_BRANCHSCHEDULELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchScheduleListIsSuccess: true,
        isLoadingBranchScheduleList: false,
        branchScheduleList: action.data,
      };

    case types.GET_BRANCHSCHEDULEBYID_ASYNC.PENDING:
      return {
        ...state,
        getBranchScheduleByIdIsSuccess: false,
        isLoadingBranchScheduleById: true,
        branchScheduleById: null,
      };

    case types.GET_BRANCHSCHEDULEBYID_ASYNC.ERROR:
      return {
        ...state,
        getBranchScheduleByIdIsSuccess: false,
        isLoadingBranchScheduleById: false,
        branchScheduleById: null,
        error: { ...state.error, branchScheduleByIdError: action.error },
      };

    case types.GET_BRANCHSCHEDULEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchScheduleByIdIsSuccess: true,
        isLoadingBranchScheduleById: false,
        branchScheduleById: action.data,
      };

    case types.POST_BRANCHSCHEDULEBYBRANCHID_ASYNC.PENDING:
      return {
        ...state,
        postBranchScheduleByBranchIdIsSuccess: false,
        isLoadingPostBranchScheduleByBranchId: true,
        postBranchScheduleByBranchId: null,
      };

    case types.POST_BRANCHSCHEDULEBYBRANCHID_ASYNC.ERROR:
      return {
        ...state,
        postBranchScheduleByBranchIdIsSuccess: false,
        isLoadingPostBranchScheduleByBranchId: false,
        postBranchScheduleByBranchId: null,
        error: {
          ...state.error,
          postBranchScheduleByBranchIdError: action.error,
        },
      };

    case types.POST_BRANCHSCHEDULEBYBRANCHID_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchScheduleByBranchIdIsSuccess: true,
        isLoadingPostBranchScheduleByBranchId: false,
        postBranchScheduleByBranchId: action.data,
      };

    case types.GET_BRANCHSCHEDULEBRANCHLIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchScheduleBranchListIsSuccess: false,
        isLoadingBranchScheduleBranchList: true,
        branchScheduleBranchList: null,
      };

    case types.GET_BRANCHSCHEDULEBRANCHLIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchScheduleBranchListIsSuccess: false,
        isLoadingBranchScheduleBranchList: false,
        branchScheduleBranchList: null,
        error: { ...state.error, branchScheduleBranchListError: action.error },
      };

    case types.GET_BRANCHSCHEDULEBRANCHLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchScheduleBranchListIsSuccess: true,
        isLoadingBranchScheduleBranchList: false,
        branchScheduleBranchList: action.data,
      };

    default:
      return state;
  }
};
