//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
//action
import {
  getMedicineMeasureUnit,
  getMedicineConsumeTime,
} from "../../../actions/commonActions";
import { postSubmitDispensary } from "../../../actions/dispensaryActions";
//components
import Panel from "../../../components/Panel";
import MedicineListTable from "./MedicineListTable";
import { useStyles } from "../../../components/globalStyles";
import RequiredNote from "../../../components/RequiredNote";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";

import ViewDelivery from "../Store/components/viewOrder/ViewDelivery";
import ViewBrewing from "../Store/components/viewOrder/ViewBrewing";
import ViewSharedDelivery from "../Store/components/viewOrder/ViewSharedDelivery";
import * as generalConstants from "../../../_constants/generalConstants";
//style

const OrderProceedPage = ({
  setOrderProcessView,
  orderProcessInfo,
  setOrderProcessInfo,
  submitSuccessAlarm,
  setSubmitSuccessAlarm,
  submitErrorAlarm,
  setSubmitErrorAlarm,
  msgAlarm,
  setMsgAlarm,
}) => {
  //variables
  const defaultMedicineGroup = orderProcessInfo.store_medicine_header;
  const classes = useStyles();
  const prevSubmitDispensaryStatus = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [medicineConsumeInfo] = useState({
    number_of_days: defaultMedicineGroup.number_of_days,
    number_of_times: defaultMedicineGroup.number_of_times,
    when_consume: defaultMedicineGroup.when_consume,
    dispensary_instructions: defaultMedicineGroup.dispensary_instructions,
    patient_instructions: defaultMedicineGroup.patient_instructions,
    // is_brewing_services: defaultMedicineGroup.is_brewing_services,
    // brewing_instructions: defaultMedicineGroup.brewing_instructions,
    // water_amount: defaultMedicineGroup.water_amount,
    // is_delivery_services: defaultMedicineGroup.is_delivery_services,
    // delivery_address: defaultMedicineGroup.delivery_address,
    // delivery_contact_number: defaultMedicineGroup.delivery_contact_number,
    // delivery_name: defaultMedicineGroup.delivery_name,
    // delivery_remarks: defaultMedicineGroup.delivery_remarks,
    // delivery_incl_prescription: defaultMedicineGroup.delivery_incl_prescription,
    // delivery_incl_receipt: defaultMedicineGroup.delivery_incl_receipt,
  });

  const [medicineList, setMedicineList] = useState(
    orderProcessInfo.dispensary_medicine_header.map((object) => {
      if (Object.keys(object.dispensary_medicine_details).length === 0) {
        return { ...object, dispensedDispensary: 0, dispensingQuantity: 0 };
      }
      const temDispensaryQuantity = object.dispensary_medicine_details.reduce(
        (sum, li) => {
          return parseFloat(
            (sum + li.dispensary_medicine_quantity).toFixed(10)
          );
        },
        0
      );
      return {
        ...object,
        dispensedDispensary: temDispensaryQuantity,
        dispensingQuantity: 0,
      };
    })
  );

  //redux variable
  const common = useSelector((state) => state.common);

  const dispensary = useSelector((state) => state.dispensary);

  //functions
  const onSubmitDispensary = (e) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const dispensaryHeader = {
      dispensary_header: {
        dispensary_medicine_header: [
          {
            desc: null,
            dispensary_medicine_details: [
              {
                dispensary_medicine_quantity: e.row.values.dispensingQuantity,
                id: 0,
              },
            ],
            id: e.row.original.id,
          },
        ],
        id: orderProcessInfo.id,
        order_number: orderProcessInfo.order_number,
      },
    };
    //
    dispatch(postSubmitDispensary.pending(dispensaryHeader));
  };

  //set table data
  const setMedicineData = (rowIndex, columnID, value) => {
    let result = [...medicineList];
    //

    const selectedstoreMedicine = result.map((medicine, index) => {
      if (index === rowIndex && columnID !== "delete") {
        return {
          ...medicine,
          [columnID]: value,
        };
      }
      return medicine;
    });
    setMedicineList(selectedstoreMedicine);
    //   if (columnID === "delete") {
    //     let result = [...storeMedicineDetail];
    //     //check whether is a current existing medicine
    //     const currentMedicine = storeMedicineDetail.find(
    //       (e, index) => index === rowIndex && e.id > 0
    //     );
    //     //
    //     if (!currentMedicine) {
    //       result = storeMedicineDetail.filter((e, index) => index !== rowIndex);
    //     } else {
    //       result[rowIndex] = { ...currentMedicine, status_type: 6 };
    //     }
    //     oldArray[elementsIndex] = {
    //       ...oldArray[elementsIndex],
    //       store_medicine_details: result,
    //     };
    //     const medicineListResult = medicineList.filter(
    //       (e, index) => index !== rowIndex
    //     );
    //     setGroupOption(oldArray);
    //   } else {
    //     const selectedstoreMedicine = storeMedicineDetail.map(
    //       (medicine, index) => {
    //         if (index === rowIndex && columnID !== "delete") {
    //           return {
    //             ...medicine,
    //             [columnID]: value,
    //           };
    //         }
    //         return medicine;
    //       }
    //     );
    //     oldArray[elementsIndex] = {
    //       ...oldArray[elementsIndex],
    //       store_medicine_details: selectedstoreMedicine,
    //     };
    //     setGroupOption(oldArray);
    //   }
  };

  const getMedicineCosumeTimeOpObj = (option) => {
    if (!option.id)
      option = common.medicineCosumeTimeList.when_consume_select_field.find(
        (op) => op.id === option
      );
    return option;
  };

  //useEffects

  useEffect(() => {
    prevSubmitDispensaryStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      dispensary.isLoadingSubmitDispensary !==
      prevSubmitDispensaryStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitDispensaryStatus.current = dispensary.isLoadingSubmitDispensary;

      if (
        dispensary.postSubmitDispensaryIsSuccess === true &&
        dispensary.isLoadingSubmitDispensary === false
      ) {
        //
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        setOrderProcessInfo(dispensary.submitDispensary.dispensary_header);
        setMedicineList(
          dispensary.submitDispensary.dispensary_header.dispensary_medicine_header.map(
            (object) => {
              if (
                Object.keys(object.dispensary_medicine_details).length === 0
              ) {
                return {
                  ...object,
                  dispensedDispensary: 0,
                  dispensingQuantity: 0,
                };
              }
              const temDispensaryQuantity =
                object.dispensary_medicine_details.reduce((sum, li) => {
                  return parseFloat(
                    (sum + li.dispensary_medicine_quantity).toFixed(10)
                  );
                }, 0);
              return {
                ...object,
                dispensedDispensary: temDispensaryQuantity,
                dispensingQuantity: 0,
              };
            }
          )
        );
        window.scrollTo(0, 0);
      }
      if (
        dispensary.postSubmitDispensaryIsSuccess === false &&
        dispensary.isLoadingSubmitDispensary === false
      ) {
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(dispensary.error.postDispensarySubmitDispensary);
        window.scrollTo(0, 0);
      }
    }
  }, [
    dispensary,
    setSubmitSuccessAlarm,
    setSubmitErrorAlarm,
    setOrderProcessInfo,
  ]);

  useEffect(() => {
    dispatch(getMedicineConsumeTime.pending());
    dispatch(getMedicineMeasureUnit.pending());
  }, [dispatch]);

  return (
    <div>
      <Collapse in={submitSuccessAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSubmitSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Dispensary has been submitted")}
        </Alert>
      </Collapse>

      <Collapse in={submitErrorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSubmitErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>

      <Typography display="block" variant="h4">
        {orderProcessInfo.store_header?.order_number}
      </Typography>
      <Typography display="block" variant="h4">
        {orderProcessInfo.store_medicine_header?.name}
      </Typography>
      <form>
        <br />
        <Grid>
          <div>
            <Panel>
              <Grid container>
                <Grid item xs={12} className={classes.userInfoField}>
                  <MedicineListTable
                    onSubmitDispensary={onSubmitDispensary}
                    data={medicineList.sort((a, b) => a.row_no - b.row_no)}
                    isRawHerb={
                      orderProcessInfo.store_medicine_header.medicine_type
                        .is_for_brewing
                    }
                    setData={setMedicineData}
                    t={t}
                    measureUnit={
                      common.medicineMeasureUnitList
                        ? common.medicineMeasureUnitList.measure_units
                        : []
                    }
                  />
                </Grid>

                <Grid item xs={4} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    type="number"
                    onWheel={(event) => event.target.blur()}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                        style: { textAlign: "right" },
                      },
                    }}
                    onKeyDown={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "e" ||
                        event?.key === "E"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    value={
                      medicineConsumeInfo.number_of_days !== null
                        ? medicineConsumeInfo.number_of_days
                        : ""
                    }
                    disabled
                    required
                    id="Day"
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    name="number_of_days"
                    label={t("translation:Day")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                        style: { textAlign: "right" },
                      },
                    }}
                    onKeyDown={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "e" ||
                        event?.key === "E"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    value={
                      medicineConsumeInfo.number_of_times !== null
                        ? medicineConsumeInfo.number_of_times
                        : ""
                    }
                    type="number"
                    onWheel={(event) => event.target.blur()}
                    disabled
                    required
                    id="Times"
                    name="number_of_times"
                    label={t("translation:Times")}
                    variant="outlined"
                    onFocus={(event) => {
                      event.target.select();
                    }}
                  />
                </Grid>
                <Grid item xs={4} className={classes.userInfoField}>
                  {common.medicineCosumeTimeList && (
                    <Autocomplete
                      value={medicineConsumeInfo.when_consume}
                      name="when_consume"
                      disabled
                      options={
                        common.medicineCosumeTimeList.when_consume_select_field
                      }
                      getOptionLabel={(option) =>
                        getMedicineCosumeTimeOpObj(option).name
                      }
                      style={{ width: "100%" }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          className={classes.userFieldColor}
                          {...params}
                          name="when_consume"
                          label={t("translation:When")}
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      medicineConsumeInfo.dispensary_instructions
                        ? medicineConsumeInfo.dispensary_instructions
                        : ""
                    }
                    multiline
                    rows={3}
                    name="dispensary_instructions"
                    label={t("translation:Dispensary Instructions")}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      medicineConsumeInfo.patient_instructions
                        ? medicineConsumeInfo.patient_instructions
                        : ""
                    }
                    multiline
                    rows={3}
                    name="patient_instructions"
                    label={t("translation:Prescription Instructions")}
                    variant="outlined"
                    disabled
                  />
                </Grid>
              </Grid>
            </Panel>

            {(defaultMedicineGroup.is_delivery_services === true ||
              defaultMedicineGroup.is_brewing_services === true) && (
              <Panel>
                {defaultMedicineGroup.is_brewing_services && (
                  <ViewBrewing memoSelectedGroup={defaultMedicineGroup} />
                )}
                {defaultMedicineGroup.is_delivery_services && (
                  <ViewDelivery
                    memoSelectedGroup={defaultMedicineGroup}
                    onlyViewDeliveryRemarks={true}
                  />
                )}
                {defaultMedicineGroup.delivery_owned?.status_type ===
                  generalConstants.APPROVED_STATUS_TYPE && (
                  <ViewSharedDelivery
                    memoSelectedGroup={defaultMedicineGroup}
                    viemOnly={true}
                    onlyViewDeliveryRemarks={true}
                  />
                )}
              </Panel>
            )}
          </div>

          <RequiredNote />
          <PageOnlyBackAction setView={() => setOrderProcessView(false)} />
        </Grid>
      </form>
    </div>
  );
};

export default OrderProceedPage;
