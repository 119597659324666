import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import Logo403 from "../../assets/403.png";
import { useStyles } from "../../components/globalStyles";
function ErrorFallbackUI({ errorMessage }) {
  const classes = useStyles();
  return (
    <div className={classes.notificationRoot}>
      <div className={classes.notificationTitleContainer}>
        <Typography
          className={classes.notificationTitle}
          variant="h3"
          gutterBottom>
          <img src={Logo403} height="300px" alt="website logo" />
        </Typography>
        <Typography
          className={classes.notificationTitle}
          variant="h3"
          gutterBottom>
          Error
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Something Went Wrong.Please contact your administrator or email{" "}
          {process.env.REACT_APP_ORGANISATION_EMAIL} for assistance.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          系统出了些问题.请联系你的管理员或发电邮至{" "}
          {process.env.REACT_APP_ORGANISATION_EMAIL} 寻求帮助
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {errorMessage}
        </Typography>
      </div>
    </div>
  );
}

class AppErrorBoundary extends Component {
  state = { error: false, errorMessage: "" };

  static getDerivedStateFromError(error) {
    // Update state to render the fallback UI
    return { error: true, errorMessage: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to an error reporting service like Sentry
    console.log({ error, errorInfo });
  }

  render() {
    const { error, errorMessage } = this.state;
    const { children } = this.props;

    return error ? <ErrorFallbackUI {...{ error, errorMessage }} /> : children;
  }
}

export default AppErrorBoundary;
