import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PurchaseOrderSummaryPage from "./PurchaseOrderSummary/PurchaseOrderSummaryPage";
export default function PurchaseOrderPage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/purchaseOrder"
        to="/purchaseOrder/PurchaseOrderSummary"
      />
      <Route
        path="/purchaseOrder/PurchaseOrderSummary"
        component={PurchaseOrderSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
