export const PAGESIZE_100 = 100;
export const PAGESIZE_50 = 50;
export const PAGESIZE_20 = 20;
export const DEFAULT_PAGESIZE = 10;
export const DEFAULT_PAGENUMBER = 1;
export const DEFAULT_PAGEOPTIONS = [2, 10, 30];

export const USER_PROFILE_STATUS_NEW = 1;
export const USER_PROFILE_STATUS_VERIFIED = 2;

export const PENDING_GENERAL_STATUS = 2;
export const APPROVED_GENERAL_STATUS = 3;

export const DETELED_GENERAL_STATUS = 7;

export const WIKI_FULL_VIEW = 1;
export const WIKI_LEVEL_VIEW = 2;
export const WIKI_ID_VIEW = 3;
