//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

//Actions
import { postSearchDeliveryCutoffScheduleBranch } from "../../../../actions/deliveryCutoffScheduleActions";

//Component

import DeliveryCutoffScheduleBranchPage from "./DeliveryCutoffScheduleBranchPage";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";

export default function DeliveryCutoffScheduleListTable({
  setInformationView,
}) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const common = useSelector((state) => state.common);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_NON_BUSINESS_DAY_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
      sortable: false,
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_desc_value: null,
    search_id_value: null,
    search_name_value: null,
    search_status_type_value: null,
  });

  const dispatch = useDispatch();

  const deliveryCutoffSchedule = useSelector(
    (state) => state.deliveryCutoffSchedule
  );

  //Init
  const [editView, setEditView] = React.useState(false);
  const [detailView, setDetailView] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [branchId, setBranchId] = React.useState(null);

  //Onclick event
  const handleRowEdit = (value) => {
    setEditView(true);
    setBranchId(value.id);
  };

  const handleRowDetail = (value) => {
    setDetailView(true);
    setBranchId(value.id);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: null,
      });
    }
  };

  //useEffects
  useEffect(() => {
    if (detailView === true || editView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [detailView, editView, setInformationView]);

  useEffect(() => {
    if (!editView && !detailView) {
      dispatch(postSearchDeliveryCutoffScheduleBranch.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editView, detailView]);

  if (
    deliveryCutoffSchedule.postSearchDeliveryCutoffScheduleBranchIsSuccess ===
      false &&
    deliveryCutoffSchedule.isLoadingPostSearchDeliveryCutoffScheduleBranch ===
      false
  ) {
    return (
      <Alert severity="error">
        {
          deliveryCutoffSchedule.error
            .postSearchDeliveryCutoffScheduleBranchError
        }
      </Alert>
    );
  } else if (common.generalStatusList && !editView && !detailView) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name")}
        searchValue={searchValue}
        columns={columns}
        statusPlaceholder={t("translation:Status Type")}
        statusOption={common.generalStatusList.status_general}
        banStatusIdList={[]}
        data={
          deliveryCutoffSchedule.postSearchDeliveryCutoffScheduleBranch
            ?.branches !== undefined
            ? deliveryCutoffSchedule.postSearchDeliveryCutoffScheduleBranch
                ?.branches
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          deliveryCutoffSchedule.postSearchDeliveryCutoffScheduleBranch
            ? deliveryCutoffSchedule.postSearchDeliveryCutoffScheduleBranch
                .total_pages
            : 0
        }
        loading={deliveryCutoffSchedule.isLoadingGetDeliveryCutoffScheduleList}
        totalItems={
          deliveryCutoffSchedule.postSearchDeliveryCutoffScheduleBranch
            ? deliveryCutoffSchedule.postSearchDeliveryCutoffScheduleBranch
                .total_items
            : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (detailView) {
    return (
      <DeliveryCutoffScheduleBranchPage
        branchId={branchId}
        setSummaryView={() => {
          setDetailView(false);
        }}
        viewOnly
      />
    );
  } else if (editView) {
    return (
      <DeliveryCutoffScheduleBranchPage
        branchId={branchId}
        setSummaryView={() => {
          setEditView(false);
        }}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
