import * as types from "../_constants/medicineTypeConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case types.GET_MEDICINETYPELIST_ASYNC.PENDING:
      return {
        ...state,
        getMedicineTypeListIsSuccess: false,
        isLoadingMedicineTypeList: true,
        medicineTypeList: action.data,
      };

    case types.GET_MEDICINETYPELIST_ASYNC.ERROR:
      return {
        ...state,
        getMedicineTypeListIsSuccess: false,
        isLoadingMedicineTypeList: false,
        medicineTypeList: null,
        error: { ...state.error, medicineListError: action.error },
      };

    case types.GET_MEDICINETYPELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineTypeListIsSuccess: true,
        isLoadingMedicineTypeList: false,
        medicineTypeList: action.data,
      };
    default:
      return state;

    case types.PUT_EDITMEDICINETYPE_ASYNC.PENDING:
      return {
        ...state,
        putEditMedicineTypeIsSuccess: false,
        isLoadingEditMedicineType: true,
        putEditMedicineType: null,
      };

    case types.PUT_EDITMEDICINETYPE_ASYNC.ERROR:
      return {
        ...state,
        putEditMedicineTypeIsSuccess: false,
        isLoadingEditMedicineType: false,
        putEditMedicineType: null,
        error: { ...state.error, EditMedicineTypeError: action.error },
      };

    case types.PUT_EDITMEDICINETYPE_ASYNC.SUCCESS:
      return {
        ...state,
        putEditMedicineTypeIsSuccess: true,
        isLoadingEditMedicineType: false,
        putEditMedicineType: action.data,
      };

    case types.GET_MEDICINETYPE_ASYNC.PENDING:
      return {
        ...state,
        getMedicineTypeIsSuccess: false,
        isLoadingGetMedicineType: true,
        medicineType: null,
      };

    case types.GET_MEDICINETYPE_ASYNC.ERROR:
      return {
        ...state,
        getMedicineTypeIsSuccess: false,
        isLoadingGetMedicineType: false,
        medicineType: null,
        error: { ...state.error, MedicineTypeError: action.error },
      };

    case types.GET_MEDICINETYPE_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineTypeIsSuccess: true,
        isLoadingGetMedicineType: false,
        medicineType: action.data,
      };

    case types.CREATE_MEDICINETYPE_ASYNC.PENDING:
      return {
        ...state,
        createMedicineTypeIsSuccess: false,
        isLoadingCreateMedicineType: true,
        createMedicineType: null,
      };

    case types.CREATE_MEDICINETYPE_ASYNC.ERROR:
      return {
        ...state,
        createMedicineTypeIsSuccess: false,
        isLoadingCreateMedicineType: false,
        createMedicineType: null,
        error: { ...state.error, CreateMedicineTypeError: action.error },
      };

    case types.CREATE_MEDICINETYPE_ASYNC.SUCCESS:
      return {
        ...state,
        createMedicineTypeIsSuccess: true,
        isLoadingCreateMedicineType: false,
        createMedicineType: action.data,
      };
  }
};
