//lib
import React from "react";
import { Grid } from "@mui/material";

import Panel from "../../../../components/Panel";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
//actions

//components
import { useStyles } from "../../../../components/globalStyles";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";

//global functions
function not(a, b) {
  return a.filter((item1) => !b.find((item2) => item1.id === item2.id));
}

// function intersection(a, b) {
//   return a.filter((item1) => b.find((item2) => item1.id === item2.id));
// }

// function union(a, b) {
//   return [...a, ...not(b, a)];
// }

export const UserRoleDetailPage = ({
  user,
  setUserRoleDetailView,
  roleList,
  userRoleList,
}) => {
  //variables
  const { t } = useTranslation();
  const avaiRoles = roleList.map((role) => {
    const { desc, remarks, organisations, ...rest } = role;
    return rest;
  });
  const curRoles = userRoleList.map((role) => {
    return { userRoleId: role.id, ...role.roles };
  });
  const avaiRole = not(avaiRoles, curRoles);
  const classes = useStyles();

  const [left] = React.useState(avaiRole);
  const [right] = React.useState(curRoles);

  //functions

  const customList = (items) => (
    <Paper className={classes.rolePaper}>
      <List dense component="div" role="list">
        {items.map((value, i) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem
              className={i % 2 ? classes.rowBackground : ""}
              key={value.id}
              role="listitem">
              <ListItemIcon></ListItemIcon>
              <ListItemText id={labelId} primary={` ${value.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Panel heading={user.username}>
      <form>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}>
          <Grid item>
            <label className={classes.boldCenterMaroon100}>
              {t("translation:Available Roles")}
            </label>

            {customList(left.sort((a, b) => a.id - b.id))}
          </Grid>

          <Grid item>
            <label className={classes.boldCenterMaroon100}>
              {t("translation:Selected Roles")}
            </label>
            {customList(right.sort((a, b) => a.id - b.id))}
          </Grid>
        </Grid>

        <>
          <PageOnlyBackAction setView={() => setUserRoleDetailView(false)} />
        </>
      </form>
    </Panel>
  );
};
