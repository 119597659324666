import { createAsyncTypes } from "../_helper/Utilities";

export const GET_BRANCHSCHEDULELIST_ASYNC = createAsyncTypes(
  "GET_BRANCHSCHEDULELIST"
);

export const GET_BRANCHSCHEDULEBYID_ASYNC = createAsyncTypes(
  "GET_BRANCHSCHEDULEBYID"
);

export const POST_BRANCHSCHEDULEBYBRANCHID_ASYNC = createAsyncTypes(
  "POST_BRANCHSCHEDULEBYBRANCHID"
);

export const GET_BRANCHSCHEDULEBRANCHLIST_ASYNC = createAsyncTypes(
  "GET_BRANCHSCHEDULEBRANCHLIST"
);
