//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

//Actions

import { getBranchPhysicianAdhocScheduleBranchList } from "../../../../actions/branchPhysicianAdhocScheduleActions";

//Component
import { BranchPhysicianAdhocScheduleDetailPage } from "./BranchPhysicianAdhocScheduleDetailPage";
import { EditBranchPhysicianAdhocSchedulePage } from "./EditBranchPhysicianAdhocSchedulePage";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";

export default function BranchPhysicianAdhocScheduleListTable({
  setInformationView,
}) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_BRANCH_PHYSICIAN_ADHOC_SCHEDULE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Description"),
      accessor: "desc",
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_branch_desc_value: null,
    search_branch_id_value: null,
    search_branch_name_value: null,
    search_desc_value: null,
    search_name_value: null,
  });

  const dispatch = useDispatch();

  const branchPhysicianAdhocSchedule = useSelector(
    (state) => state.branchPhysicianAdhocSchedule
  );
  //Init
  const [editView, setEditView] = React.useState(false);
  const [detailView, setDetailView] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [branchId, setBranchId] = React.useState(null);
  //Onclick event
  const handleRowEdit = (value) => {
    // dispatch(getBranchScheduleList.pending(value.id));
    setEditView(true);
    setBranchId(value.id);
  };

  const handleRowDetail = (value) => {
    // dispatch(getBranchScheduleList.pending(value.id));
    setDetailView(true);
    setBranchId(value.id);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_name_value: value,
        search_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
        search_desc_value: "",
      });
    }
  };

  //useEffects
  useEffect(() => {
    if (detailView === true || editView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [detailView, editView, setInformationView]);

  useEffect(() => {
    if (!editView && !detailView) {
      dispatch(getBranchPhysicianAdhocScheduleBranchList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editView, detailView]);

  if (
    branchPhysicianAdhocSchedule.getBranchPhysicianAdhocScheduleBranchListIsSuccess ===
      false &&
    branchPhysicianAdhocSchedule.isLoadingBranchPhysicianAdhocScheduleBranchList ===
      false
  ) {
    return (
      <Alert severity="error">
        {
          branchPhysicianAdhocSchedule.error
            .branchPhysicianAdhocScheduleBranchListError
        }
      </Alert>
    );
  } else if (!editView && !detailView) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name/ Description")}
        searchValue={searchValue}
        columns={columns}
        data={
          branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleBranchList
            ?.branches !== undefined
            ? branchPhysicianAdhocSchedule
                .branchPhysicianAdhocScheduleBranchList?.branches
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleBranchList
            ? branchPhysicianAdhocSchedule
                .branchPhysicianAdhocScheduleBranchList.total_pages
            : 0
        }
        loading={
          branchPhysicianAdhocSchedule.isLoadingBranchPhysicianAdhocScheduleBranchList
        }
        totalItems={
          branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleBranchList
            ? branchPhysicianAdhocSchedule
                .branchPhysicianAdhocScheduleBranchList.total_items
            : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (
    branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleBranchList &&
    detailView &&
    branchPhysicianAdhocSchedule.getBranchPhysicianAdhocScheduleBranchListIsSuccess ===
      true
  ) {
    return (
      <BranchPhysicianAdhocScheduleDetailPage
        branchId={branchId}
        setDetailView={setDetailView}
      />
    );
  } else if (
    branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleBranchList &&
    editView &&
    branchPhysicianAdhocSchedule.getBranchPhysicianAdhocScheduleBranchListIsSuccess ===
      true
  ) {
    return (
      <EditBranchPhysicianAdhocSchedulePage
        branchId={branchId}
        setEditView={setEditView}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
