//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Link } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import moment from "moment";
//components
import * as generalConstants from "../../../../../_constants/generalConstants";
import organisationPriceRounding from "../../../../../components/functions/organisationPriceRounding";
import { useStyles as globalStyles } from "../../../../../components/globalStyles";
import { SortingSearchTable } from "../../../../../components/SortingSearchTable";
import * as pageConfigure from "../../../../../_constants/pageConstants";
//action
import { postBranchMedicineBranchMedicineHistoryList } from "../../../../../actions/branchMedicineActions";
import { getInOutType } from "../../../../../actions/commonActions";

const MedicineInOurTable = ({
  details_price_rmt = generalConstants.ROUND_OFF_RMT,
  details_price_rpvt = generalConstants.TEN_THOUSANDTH_RPVT,
  branchMedicineId,
}) => {
  const classes = globalStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const branchMedicine = useSelector((state) => state.branchMedicine);

  const common = useSelector((state) => state.common);
  const columns = [
    {
      Header: t("translation:New Cost Price/ Unit"),
      sortable: false,
      accessor: "new_unit_cost_price",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : "";
      },
    },
    {
      Header: t("translation:New Selling Price/ Unit"),
      sortable: false,
      accessor: "new_unit_selling_price",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : "";
      },
    },

    {
      Header: t("translation:Old Cost Price/ Unit"),
      sortable: false,
      accessor: "old_unit_cost_price",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : "";
      },
    },
    {
      Header: t("translation:Old Selling Price/ Unit"),
      sortable: false,
      accessor: "old_unit_selling_price",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : "";
      },
    },

    {
      Header: t("translation:Quantity Before Change"),
      sortable: false,
      accessor: "qty_bef_change",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : "";
      },
    },
    {
      Header: t("translation:Quantity after Change"),
      sortable: false,
      accessor: "qty_after_change",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : "";
      },
    },
    {
      Header: t("translation:Modified Date"),
      accessor: "modified_on",

      Cell: ({ row }) => {
        if (row.original.modified_on)
          return moment
            .utc(row.original.modified_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:In Out Status"),
      sortable: false,
      accessor: "in_out_type",
      Cell: ({ cell }) => {
        const cellValue = common.inOutType.in_out_type_select_field.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_branch_medicine_id: branchMedicineId,
    search_status_type_value: null,
  });

  useEffect(() => {
    if (open) {
      dispatch(postBranchMedicineBranchMedicineHistoryList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, open]);

  useEffect(() => {
    dispatch(getInOutType.pending());
  }, [dispatch]);
  return (
    <>
      <Grid item xs={12}>
        <Link className={classes.boldMaroon075}>
          <Button
            className={classes.boldMaroon075}
            onClick={() => setOpen(true)}>
            {t("translation:IN OUT HISTORY")}
          </Button>
        </Link>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
        fullWidth
        maxWidth="xl">
        <DialogContent>
          <SortingSearchTable
            columns={columns}
            data={
              branchMedicine.branchMedicineBranchMedicineHistoryList
                ?.medicine_in_out !== undefined
                ? branchMedicine.branchMedicineBranchMedicineHistoryList
                    ?.medicine_in_out
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              branchMedicine.branchMedicineBranchMedicineHistoryList
                ? branchMedicine.branchMedicineBranchMedicineHistoryList
                    .total_pages
                : 0
            }
            loading={
              branchMedicine.isLoadingPostBranchMedicineBranchMedicineHistoryList
            }
            totalItems={
              branchMedicine.branchMedicineBranchMedicineHistoryList
                ? branchMedicine.branchMedicineBranchMedicineHistoryList
                    .total_items
                : 0
            }
            disabledSearch
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MedicineInOurTable;
