import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/branchPhysicianAdhocScheduleConstants";

export const getBranchPhysicianAdhocScheduleList = {
  pending: (data) =>
    createAction(types.GET_BRANCHPHYSICIANADHOCSCHEDULELIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_BRANCHPHYSICIANADHOCSCHEDULELIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_BRANCHPHYSICIANADHOCSCHEDULELIST_ASYNC.ERROR, {
      error,
    }),
};

export const getBranchPhysicianAdhocScheduleById = {
  pending: (data) =>
    createAction(types.GET_BRANCHPHYSICIANADHOCSCHEDULEBYID_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_BRANCHPHYSICIANADHOCSCHEDULEBYID_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_BRANCHPHYSICIANADHOCSCHEDULEBYID_ASYNC.ERROR, {
      error,
    }),
};

export const postBranchPhysicianAdhocScheduleByBranchIdPhysicianId = {
  pending: (data) =>
    createAction(
      types.POST_BRANCHPHYSICIANADHOCSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC
        .PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.POST_BRANCHPHYSICIANADHOCSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC
        .SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.POST_BRANCHPHYSICIANADHOCSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const getBranchPhysicianAdhocScheduleBranchList = {
  pending: (data) =>
    createAction(
      types.GET_BRANCHPHYSICIANADHOCSCHEDULEBRANCHLIST_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_BRANCHPHYSICIANADHOCSCHEDULEBRANCHLIST_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.GET_BRANCHPHYSICIANADHOCSCHEDULEBRANCHLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getBranchPhysicianAdhocSchedulePhysicianList = {
  pending: (data) =>
    createAction(
      types.GET_BRANCHPHYSICIANADHOCSCHEDULEPHYSICIANLIST_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_BRANCHPHYSICIANADHOCSCHEDULEPHYSICIANLIST_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_BRANCHPHYSICIANADHOCSCHEDULEPHYSICIANLIST_ASYNC.ERROR,
      {
        error,
      }
    ),
};
