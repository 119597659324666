import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Close from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import MaximizeIcon from "@mui/icons-material/Maximize";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//components
import * as generalConstants from "../../../../_constants/generalConstants";
import StyledCheckbox from "../../../../components/StyledCheckbox";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import * as generalConstant from "../../../../_constants/generalConstants";

export default function AddAdhocModal({
  openModal,
  setOpenModal,
  branchId,
  editAdhocSchedule,
  setEditAdhocSchedule,
  handlePostBranchAdhocSchedule,
  physician = null,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleSubmit } = useForm();
  const [endTimeError, setEndTimeError] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const common = useSelector((state) => state.common);

  const [adhocModal, setAdhocModal] = useState({
    id: editAdhocSchedule ? editAdhocSchedule.id : 0,
    start_time: editAdhocSchedule ? editAdhocSchedule.start_time : null,
    end_time: editAdhocSchedule ? editAdhocSchedule.end_time : null,
    name: editAdhocSchedule ? editAdhocSchedule.name : null,
    desc: editAdhocSchedule ? editAdhocSchedule.desc : null,
    is_closed_all_day: editAdhocSchedule
      ? editAdhocSchedule.is_closed_all_day
      : null,
    adhoc_date: editAdhocSchedule ? editAdhocSchedule.adhoc_date : null,
    status_type: editAdhocSchedule
      ? editAdhocSchedule.status_type
      : generalConstant.APPROVED_STATUS_TYPE,
  });
  const [errorProps, setErrorProps] = useState({});

  const handleEndTimeOnBlur = () => {
    if (adhocModal.start_time && adhocModal.end_time) {
      if (adhocModal.end_time > adhocModal.start_time) {
        setEndTimeError(false);
      } else {
        setEndTimeError(true);
      }
    }
  };
  const onConfirmSubmit = () => {
    if (
      adhocModal.end_time > adhocModal.start_time ||
      adhocModal.is_closed_all_day
    ) {
      let newResult = [];

      if (physician) {
        newResult = [
          {
            id: adhocModal.id,
            branch: { id: branchId },
            start_time: adhocModal.start_time,
            end_time: adhocModal.end_time,
            name: adhocModal.name,
            desc: adhocModal.desc,
            is_closed_all_day: adhocModal.is_closed_all_day,
            adhoc_date: adhocModal.adhoc_date,
            physician: physician,
            status_type: generalConstants.APPROVED_STATUS_TYPE,
          },
        ];
      } else {
        newResult = [
          {
            id: adhocModal.id,
            branch: { id: branchId },
            start_time: adhocModal.start_time,
            end_time: adhocModal.end_time,
            name: adhocModal.name,
            desc: adhocModal.desc,
            is_closed_all_day: adhocModal.is_closed_all_day,
            adhoc_date: adhocModal.adhoc_date,
            status_type: generalConstants.APPROVED_STATUS_TYPE,
          },
        ];
      }

      handlePostBranchAdhocSchedule(newResult);
      handleResetOpeningHoursModal();
    }
  };

  const handleResetOpeningHoursModal = () => {
    setAdhocModal({
      id: 0,
      start_time: null,
      end_time: null,
      name: null,
      desc: null,
      is_closed_all_day: null,
      adhoc_date: null,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
    });
    setEditAdhocSchedule(null);
    setEndTimeError(false);
    setOpenModal(false);
  };

  const onSubmit = () => {
    setErrorProps(
      adhocModal.adhoc_date === null
        ? {
            helperText: "Input is required",
            error: true,
          }
        : {}
    );
    if (adhocModal.adhoc_date) {
      setConfirmSubmit(true);
    }
  };

  return (
    <Dialog
      open={openModal}
      maxWidth="xs"
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleResetOpeningHoursModal();
        }
      }}
      aria-labelledby="confirm-dialog"
      fullWidth>
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => handleResetOpeningHoursModal()}
      />
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="confirm-dialog">
          {editAdhocSchedule
            ? t("translation:Edit Adhoc Schedule")
            : t("translation:Add Adhoc Schedule")}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              required
              value={adhocModal.name ? adhocModal.name : ""}
              className={classes.userFieldColor}
              variant="outlined"
              label={t("translation:Name")}
              fullWidth
              onChange={(e) =>
                setAdhocModal({
                  ...adhocModal,
                  name: e.target.value ? e.target.value : null,
                })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              value={adhocModal.desc ? adhocModal.desc : ""}
              className={classes.userFieldColor}
              variant="outlined"
              label={t("translation:Description")}
              fullWidth
              onChange={(e) =>
                setAdhocModal({
                  ...adhocModal,
                  desc: e.target.value ? e.target.value : null,
                })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className={classes.userFieldColor}
                inputVariant="outlined"
                id="date"
                label={t("translation:Date")}
                autoOk={true}
                slotProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                  textField: {
                    required: true,
                  },
                }}
                value={
                  adhocModal.adhoc_date ? dayjs(adhocModal.adhoc_date) : null
                }
                {...errorProps}
                format="DD MMM YYYY"
                onChange={(e) =>
                  setAdhocModal({
                    ...adhocModal,
                    adhoc_date: dayjs(e, true).isValid()
                      ? dayjs(e).format("YYYY-MM-DD")
                      : null,
                  })
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <Autocomplete
              name="status"
              options={common.generalStatusList.status_general.filter(
                (item) => item.id === generalConstant.APPROVED_STATUS_TYPE
              )}
              disableClearable
              value={common.generalStatusList.status_general.find(
                (item) => item.id === adhocModal.status_type
              )}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => {
                if (option === value) return option;
              }}
              style={{ width: "100%" }}
              onChange={(e, option) =>
                setAdhocModal({
                  ...adhocModal,
                  status_type: option ? option.id : null,
                })
              }
              renderInput={(params) => (
                <TextField
                  required
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Status")}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={adhocModal.is_closed_all_day ? true : false}
                  onClick={(e) => {
                    setEndTimeError(false);
                    setAdhocModal({
                      ...adhocModal,
                      start_time: null,
                      end_time: null,
                      is_closed_all_day: e.target.checked
                        ? e.target.checked
                        : null,
                    });
                  }}
                  name="is_closed_all_day"
                />
              }
              label={t("translation:Closed All Day")}
            />
          </Grid>
          {!adhocModal.is_closed_all_day && (
            <Grid item xs={12} container>
              <Grid
                item
                xs={5}
                container
                justifyContent="center"
                direction="column"
                alignItems="center"
                className={classes.userInfoField}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  value={adhocModal.start_time ? adhocModal.start_time : ""}
                  className={classes.userFieldColor}
                  type="time"
                  variant="outlined"
                  label={t("translation:Start Time")}
                  fullWidth
                  onBlur={(e) => handleEndTimeOnBlur()}
                  onChange={(e) =>
                    setAdhocModal({
                      ...adhocModal,
                      start_time: e.target.value
                        ? moment(e.target.value, "HH:mm").format("HH:mm:ss")
                        : null,
                    })
                  }
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                justifyContent="center"
                direction="column"
                alignItems="center">
                <MaximizeIcon />
              </Grid>

              <Grid
                item
                xs={5}
                container
                justifyContent="center"
                direction="column"
                alignItems="center"
                className={classes.userInfoField}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  value={adhocModal.end_time ? adhocModal.end_time : ""}
                  className={classes.userFieldColor}
                  type="time"
                  variant="outlined"
                  label={t("translation:End Time")}
                  fullWidth
                  onBlur={(e) => handleEndTimeOnBlur()}
                  onChange={(e) =>
                    setAdhocModal({
                      ...adhocModal,
                      end_time: e.target.value
                        ? moment(e.target.value, "HH:mm").format("HH:mm:ss")
                        : null,
                    })
                  }
                />
              </Grid>
              <Grid
                container
                justifyContent="center"
                direction="column"
                alignItems="center">
                {endTimeError && (
                  <div className={classes.warningColor}>
                    <p>
                      {t(
                        "translation:End Time must be greater than Start Time."
                      )}
                    </p>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <PageSubmitWBackAction
            setView={() => {
              handleResetOpeningHoursModal();
            }}
          />
          {/* <Link className={classes.boldMaroon075}>
            <Button
              onClick={(e) => {
                e.currentTarget.form.reportValidity();
                var checkVal = e.currentTarget.form.checkValidity();
                if (checkVal) {
                  handleAddNewHour();
                }
              }}
              className={classes.boldMaroon075}
            >
              {t("translation:Yes")}
            </Button>
          </Link>

          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              onClick={() => {
                handleResetOpeningHoursModal();
              }}
            >
              {t("translation:No")}
            </Button>
          </Link> */}
        </DialogActions>
      </form>
    </Dialog>
  );
}
