//lib
import React, { useState, useRef, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import Info from "@mui/icons-material/InfoRounded";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
//components
import organisationPriceRounding from "../../../components/functions/organisationPriceRounding";
import { ReactComponent as DeleteIcon } from "../../../assets/coolicons/basic/trash_empty.svg";
import {
  useStyles,
  insufficientColorStyle,
  noAvailableColorStyle,
} from "../../../components/globalTableStyles";

import { useStyles as useStylesGlobal } from "../../../components/globalStyles";

import EditableCell from "../../../components/EditableCell";
import * as generalConstants from "../../../_constants/generalConstants";

import ConfirmDialog from "../../../components/ConfirmDialog";

//action
import { getStoreMedicineInfo } from "../../../actions/workOrderActions";

import { getVisitMedicineInfo } from "../../../actions/visitOrderActions";

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({
  columns,
  data,
  updateMyData,
  disablePageResetOnDataChange,
  number_of_days,
}) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        updateMyData,
        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();
  const { t } = useTranslation();

  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    {row.original.branch_medicine.medicines.id !== null &&
                    row.original.branch_medicine.status_type ===
                      generalConstants.APPROVED_STATUS_TYPE &&
                    row.original.branch_medicine.medicines.status_type ===
                      generalConstants.APPROVED_STATUS_TYPE ? (
                      <TableCell
                        className={paginationClasses.narrowCellStyle}
                        style={
                          row.original.branch_medicine.quantity
                            ? (number_of_days
                                ? row.original.quantity_per_day * number_of_days
                                : row.original.quantity_per_day) +
                                row.original.branch_medicine.reserve_quantity >
                              row.original.branch_medicine.quantity
                              ? insufficientColorStyle
                              : {}
                            : insufficientColorStyle
                        }>
                        {i + 1}
                      </TableCell>
                    ) : (
                      <TableCell
                        className={paginationClasses.narrowCellStyle}
                        style={
                          row.original.branch_medicine.quantity &&
                          row.original.branch_medicine.status_type ===
                            generalConstants.APPROVED_STATUS_TYPE &&
                          row.original.branch_medicine.medicines.status_type ===
                            generalConstants.APPROVED_STATUS_TYPE
                            ? {}
                            : noAvailableColorStyle
                        }>
                        {i + 1}
                      </TableCell>
                    )}

                    {row.cells.map((cell) => {
                      //console.log(cell);

                      if (
                        cell.row.original.branch_medicine.medicines.id !==
                          null &&
                        cell.row.original.branch_medicine.status_type ===
                          generalConstants.APPROVED_STATUS_TYPE &&
                        cell.row.original.branch_medicine.medicines
                          .status_type === generalConstants.APPROVED_STATUS_TYPE
                      ) {
                        return (
                          <TableCell
                            className={paginationClasses.narrowCellStyle}
                            style={
                              row.original.branch_medicine.quantity
                                ? (number_of_days
                                    ? row.original.quantity_per_day *
                                      number_of_days
                                    : row.original.quantity_per_day) +
                                    row.original.branch_medicine
                                      .reserve_quantity >
                                  row.original.branch_medicine.quantity
                                  ? insufficientColorStyle
                                  : {}
                                : insufficientColorStyle
                            }
                            {...cell.getCellProps()}>
                            {cell.row.original.branch_medicine.quantity -
                              cell.row.original.branch_medicine
                                .reserve_quantity >
                              0 ||
                            cell.column.id === "Delete" ||
                            cell.column.id === "branch_medicine.medicines.name"
                              ? cell.render("Cell", {
                                  editable: cell.column.editable,
                                  editableNum: cell.column.editableNum,
                                  excessQuantity:
                                    (number_of_days
                                      ? cell.row.original.quantity_per_day *
                                        number_of_days
                                      : cell.row.original.quantity_per_day) +
                                      cell.row.original.branch_medicine
                                        .reserve_quantity >
                                    cell.row.original.branch_medicine.quantity
                                      ? true
                                      : false,
                                })
                              : cell.render(<></>)}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            className={paginationClasses.narrowCellStyle}
                            style={
                              cell.row.original.branch_medicine.quantity &&
                              cell.row.original.branch_medicine.status_type ===
                                generalConstants.APPROVED_STATUS_TYPE &&
                              cell.row.original.branch_medicine.medicines
                                .status_type ===
                                generalConstants.APPROVED_STATUS_TYPE
                                ? {}
                                : noAvailableColorStyle
                            }
                            {...cell.getCellProps()}>
                            {cell.value !== null &&
                            (cell.column.id === "Delete" ||
                              cell.row.original.branch_medicine)
                              ? cell.render("Cell", {})
                              : cell.render(<></>)}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
          <caption>
            <span className={paginationClasses.insufficientColorCaption}>
              {t("translation:Red - Insufficient stock")}{" "}
            </span>
            <span className={paginationClasses.noAvailableColorCaption}>
              {t("translation:Blue - Not available")}{" "}
            </span>
          </caption>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const MedicineListTable = ({
  data = {},
  setData,
  measureUnit,
  t,
  currentOrganistionBranch,
  isRawHerb = false,
  order,
  number_of_days,
  storeOrderMode = false,
  userOrganisations = null,
  infoModule = null,
}) => {
  const dispatch = useDispatch();
  const prevGetInfoStatus = useRef();
  const [infoOpen, setInfoOpen] = useState(false);
  const [medicineInfo, setMedicineInfo] = useState(null);
  const [medicineInfoError, setMedicineInfoError] = useState(null);
  const paginationClasses = useStyles();
  const classes = useStylesGlobal();
  const workOrder = useSelector((state) => state.workOrder);
  const visitOrder = useSelector((state) => state.visitOrder);

  //useEffects
  useEffect(() => {
    prevGetInfoStatus.current = false;
  }, []);

  useEffect(() => {
    if (workOrder.isLoadingGetStoreMedicineInfo !== prevGetInfoStatus.current) {
      //check the previous ref with current state
      prevGetInfoStatus.current = workOrder.isLoadingGetStoreMedicineInfo;

      if (
        workOrder.getStoreMedicineInfoIsSuccess === true &&
        workOrder.isLoadingGetStoreMedicineInfo === false
      ) {
        setMedicineInfo(workOrder.getStoreMedicineInfo.medicines[0]);
        setMedicineInfoError(null);
      }
      if (
        workOrder.getStoreMedicineInfoIsSuccess === false &&
        workOrder.isLoadingGetStoreMedicineInfo === false
      ) {
        setMedicineInfo(null);
        setMedicineInfoError(workOrder.error.getStoreMedicineInfoError);
      }
    }
  }, [
    workOrder.isLoadingGetStoreMedicineInfo,
    workOrder.getStoreMedicineInfoIsSuccess,
    workOrder.getStoreMedicineInfo,
    workOrder.error,
  ]);

  useEffect(() => {
    if (
      visitOrder.isLoadingGetVisitMedicineInfo !== prevGetInfoStatus.current
    ) {
      //check the previous ref with current state
      prevGetInfoStatus.current = visitOrder.isLoadingGetVisitMedicineInfo;

      if (
        visitOrder.getVisitMedicineInfoIsSuccess === true &&
        visitOrder.isLoadingGetVisitMedicineInfo === false
      ) {
        setMedicineInfo(visitOrder.getVisitMedicineInfo.medicines[0]);
        setMedicineInfoError(null);
      }
      if (
        visitOrder.getVisitMedicineInfoIsSuccess === false &&
        visitOrder.isLoadingGetVisitMedicineInfo === false
      ) {
        setMedicineInfo(null);
        setMedicineInfoError(visitOrder.error.getVisitMedicineInfoError);
      }
    }
  }, [
    visitOrder.isLoadingGetVisitMedicineInfo,
    visitOrder.getVisitMedicineInfoIsSuccess,
    visitOrder.getVisitMedicineInfo,
    visitOrder.error,
  ]);

  const handleInfoOnClick = (item) => {
    setMedicineInfo(null);
    if (infoModule === "STORE") {
      const submisionJSON = {
        is_pagination: false,
        order_by_direction: null,
        order_by_field: null,
        page_number: null,
        page_size: null,
        search_desc_value: null,
        search_id_value: item.row.original.branch_medicine.medicines.id,
        search_medicine_type_id_value: null,
        search_name_value: null,
        search_status_type_value: null,
      };
      setInfoOpen(true);
      dispatch(getStoreMedicineInfo.pending(submisionJSON));
    }
    if (infoModule === "VISIT") {
      const submisionJSON = {
        is_pagination: false,
        order_by_direction: null,
        order_by_field: null,
        page_number: null,
        page_size: null,
        search_desc_value: null,
        search_id_value: item.row.original.branch_medicine.medicines.id,
        search_medicine_type_id_value: null,
        search_name_value: null,
        search_status_type_value: null,
      };
      setInfoOpen(true);
      dispatch(getVisitMedicineInfo.pending(submisionJSON));
    }
  };
  const handleRowDel = (rowIndex, action) => {
    setData(rowIndex, action);
  };

  if (currentOrganistionBranch && measureUnit) {
    //Get latest organisation Info
    const organisationBranch =
      order.dispensaryBranchesAlliance.organisation_branch.find(
        (organisation_branch) =>
          organisation_branch.organisation.id ===
          currentOrganistionBranch.organisation.id
      );

    let userOrganisationMutiplier = null;
    if (userOrganisations && storeOrderMode) {
      userOrganisationMutiplier = userOrganisations.find(
        (item) =>
          item.organisations.id === currentOrganistionBranch.organisation.id
      )?.medicine_cost_multiplier;
    }

    const columns = [
      {
        id: "Delete",
        textAlign: "left",
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <span className={classes.fullWidthField}>
            <IconButton
              aria-label="delete"
              color="inherit"
              onClick={() => handleRowDel(row.index, "delete")}>
              <DeleteIcon />
            </IconButton>
            {row.index !== 0 && (
              <IconButton
                aria-label="up"
                color="inherit"
                onClick={() => handleRowDel(row.index, "up")}>
                <KeyboardDoubleArrowUpIcon fontSize="inherit" />
              </IconButton>
            )}
            {row.index + 1 !== data.length && (
              <IconButton
                aria-label="down"
                color="inherit"
                onClick={() => handleRowDel(row.index, "down")}>
                <KeyboardDoubleArrowDownIcon fontSize="inherit" />
              </IconButton>
            )}
          </span>
        ),
      },

      {
        Header: t("translation:Medicine Name"),
        accessor: "branch_medicine.medicines.name",
        Cell: ({ cell }) => {
          return (
            <>
              {cell.value}
              {infoModule !== null && (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => handleInfoOnClick(cell)}>
                  <Info fontSize="inherit" />
                </IconButton>
              )}
            </>
          );
        },
      },
      {
        Header: t("translation:Dosage"),
        accessor: "branch_medicine.medicines.dosage",
        Cell: ({ cell }) => {
          return <>{cell.value ? cell.value : ""}</>;
        },
      },
      {
        editableNum: true,
        Header: t("translation:Quantity / Day"),
        accessor: "quantity_per_day",
      },

      {
        Header: t("translation:Measure Unit"),
        accessor: "branch_medicine.medicines.measure_unit",
        Cell: ({ cell }) => {
          const cellValue = measureUnit.find((item) => item.id === cell.value);

          return <>{cellValue ? cellValue.name : ""}</>;
        },
      },

      {
        Header: t("translation:Subtotal / Day"),
        Cell: ({ row }) => {
          if (
            !row.original.quantity_per_day ||
            row.original.quantity_per_day === 0
          ) {
            return (
              <>
                {organisationPriceRounding(
                  Number(0),
                  organisationBranch?.organisation?.med_details_price_rmt,
                  organisationBranch?.organisation?.med_details_price_rpvt
                )}
              </>
            );
          } else {
            let subTotalRound = 0;
            if (userOrganisationMutiplier) {
              subTotalRound = organisationPriceRounding(
                userOrganisationMutiplier
                  ? userOrganisationMutiplier *
                      row.original.branch_medicine.medicines.unit_selling_price
                  : organisationBranch?.organisation?.med_details_price_rmt,
                organisationBranch?.organisation?.med_details_price_rpvt
              );
            }
            subTotalRound = organisationPriceRounding(
              userOrganisationMutiplier
                ? subTotalRound * row.original.quantity_per_day
                : row.original.branch_medicine.medicines.unit_selling_price *
                    row.original.quantity_per_day,
              organisationBranch?.organisation?.med_details_price_rmt,
              organisationBranch?.organisation?.med_details_price_rpvt
            );
            return <>{subTotalRound}</>;
          }
        },
      },
    ];

    const rawHerbcolumns = [
      {
        id: "Delete",
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <span className={classes.fullWidthField}>
            <IconButton
              aria-label="delete"
              color="inherit"
              onClick={() => handleRowDel(row.index, "delete")}>
              <DeleteIcon />
            </IconButton>
            {row.index !== 0 && (
              <IconButton
                aria-label="up"
                color="inherit"
                onClick={() => handleRowDel(row.index, "up")}>
                <KeyboardDoubleArrowUpIcon fontSize="inherit" />
              </IconButton>
            )}
            {row.index + 1 !== data.length && (
              <IconButton
                aria-label="down"
                color="inherit"
                onClick={() => handleRowDel(row.index, "down")}>
                <KeyboardDoubleArrowDownIcon fontSize="inherit" />
              </IconButton>
            )}
          </span>
        ),
      },

      {
        Header: t("translation:Medicine Name"),
        accessor: "branch_medicine.medicines.name",
        Cell: ({ cell }) => {
          return (
            <>
              {cell.value}
              {infoModule !== null && (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => handleInfoOnClick(cell)}>
                  <Info fontSize="inherit" />
                </IconButton>
              )}
            </>
          );
        },
      },
      {
        editable: true,
        Header: t("translation:Instruction"),
        accessor: "default_instruction",
      },
      {
        editableNum: true,
        Header: t("translation:Quantity / Day"),
        accessor: "quantity_per_day",
      },

      {
        Header: t("translation:Measure Unit"),
        accessor: "branch_medicine.medicines.measure_unit",
        Cell: ({ cell }) => {
          const cellValue = measureUnit.find((item) => item.id === cell.value);

          return <>{cellValue ? cellValue.name : ""}</>;
        },
      },

      {
        Header: t("translation:Subtotal / Day"),
        Cell: ({ row }) => {
          if (
            !row.original.quantity_per_day ||
            row.original.quantity_per_day === 0
          ) {
            return (
              <>
                {organisationPriceRounding(
                  Number(0),
                  organisationBranch?.organisation?.med_details_price_rmt,
                  organisationBranch?.organisation?.med_details_price_rpvt
                )}
              </>
            );
          } else {
            let subTotalRound = 0;
            if (userOrganisationMutiplier) {
              subTotalRound = organisationPriceRounding(
                userOrganisationMutiplier *
                  row.original.branch_medicine.medicines.unit_selling_price,
                organisationBranch?.organisation?.med_details_price_rmt,
                organisationBranch?.organisation?.med_details_price_rpvt
              );
            }
            subTotalRound = organisationPriceRounding(
              userOrganisationMutiplier
                ? subTotalRound * row.original.quantity_per_day
                : row.original.branch_medicine.medicines.unit_selling_price *
                    row.original.quantity_per_day,
              organisationBranch?.organisation?.med_details_price_rmt,
              organisationBranch?.organisation?.med_details_price_rpvt
            );
            return <>{subTotalRound}</>;
          }
        },
      },
    ];

    return (
      <>
        {" "}
        <ConfirmDialog
          open={infoOpen}
          setOpen={setInfoOpen}
          title={t("translation:Info")}
          maxWidth="md">
          {medicineInfoError !== null && (
            <Alert severity="error">{medicineInfoError}</Alert>
          )}
          {medicineInfo !== null && (
            <Grid className={classes.gridRoot} container>
              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  disabled
                  className={classes.userFieldColor}
                  required
                  name="name"
                  label={t("translation:Name")}
                  variant="outlined"
                  value={medicineInfo?.name}
                />
              </Grid>

              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  disabled
                  className={classes.userFieldColor}
                  name="info1"
                  label={t("translation:Info 1")}
                  variant="outlined"
                  value={medicineInfo?.info1}
                  multiline
                  rows={3}
                />
              </Grid>

              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  disabled
                  className={classes.userFieldColor}
                  name="info2"
                  label={t("translation:Info 2")}
                  variant="outlined"
                  value={medicineInfo?.info2}
                  multiline
                  rows={3}
                />
              </Grid>

              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  disabled
                  className={classes.userFieldColor}
                  name="info3"
                  label={t("translation:Info 3")}
                  variant="outlined"
                  value={medicineInfo?.info3}
                  multiline
                  rows={3}
                />
              </Grid>

              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  disabled
                  className={classes.userFieldColor}
                  name="info_functionality"
                  label={t("translation:Info Functionality")}
                  variant="outlined"
                  value={medicineInfo?.info_functionality}
                  multiline
                  rows={3}
                />
              </Grid>

              <Grid item xs={12} className={classes.userInfoField}>
                <TextField
                  disabled
                  className={classes.userFieldColor}
                  name="info_precaution"
                  label={t("translation:Info Precaution")}
                  variant="outlined"
                  value={medicineInfo?.info_precaution}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          )}
        </ConfirmDialog>
        <Table
          columns={isRawHerb ? rawHerbcolumns : columns}
          data={data}
          updateMyData={setData}
          t={t}
          number_of_days={number_of_days}
        />
      </>
    );
  } else return <></>;
};

export default MedicineListTable;
