import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
//components
import * as generalConstants from "../../../_constants/generalConstants";
import SocketNotification from "./SocketNotification";
import AppointmentListTable from "../../appointment/AppointmentSummary/AppointmentListTable";
//Actions

//styles
export default function AppointmentTable() {
  //variable
  const { t } = useTranslation();
  const [refreshTimeStamp, setRefreshTimeStamp] = useState(moment());

  return (
    <SocketNotification
      cellTitle={t("translation:Appointment")}
      sockectWorkTypeId={generalConstants.APPOINTMENT_WORK_ORDER_TYPE}
      setRefreshTimeStamp={setRefreshTimeStamp}
      localStorageName="appointmentListTableNotification">
      <AppointmentListTable
        refreshTimeStamp={refreshTimeStamp}
        dashboardView={true}
      />
    </SocketNotification>
  );
}
