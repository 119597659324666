import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import BranchMedicineSummaryPage from "./BranchMedicineSummary/BranchMedicineSummaryPage";
export default function BranchMedicinePage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/branchMedicine"
        to="/branchMedicine/BranchMedicineSummary"
      />
      <Route
        path="/branchMedicine/BranchMedicineSummary"
        component={BranchMedicineSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
