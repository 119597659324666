//lib
import React, { useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//component
import BranchMedicineListTable from "./BranchMedicineListTable";

import { useStyles } from "../../../../components/globalStyles";

const BranchMedicneSummaryPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const [informationView, setInformationView] = React.useState(false);
  const [viewInfo, setViewInfo] = React.useState(null);
  const [editView, setEditView] = React.useState(false);
  const [detailView, setDetailView] = React.useState(false);
  const prevUpdateBranchMedicineStatus = useRef();
  const branchMedicine = useSelector((state) => state.branchMedicine);

  useEffect(() => {
    //set ref when first render
    prevUpdateBranchMedicineStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      branchMedicine.isLoadingPutUpdateBranchMedicine !==
      prevUpdateBranchMedicineStatus.current
    ) {
      //check the previous ref with current state
      prevUpdateBranchMedicineStatus.current =
        branchMedicine.isLoadingPutUpdateBranchMedicine;

      if (
        branchMedicine.putUpdateBranchMedicineIsSuccess === true &&
        branchMedicine.isLoadingPutUpdateBranchMedicine === false
      ) {
        setEditView(false);
        setDetailView(true);
        setViewInfo(branchMedicine.updateBranchMedicine);
      }
    }
  }, [branchMedicine]);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!informationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title} variant="h3" gutterBottom>
              {t("translation:Branch Medicine Summary")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <hr />

      <BranchMedicineListTable
        setInformationView={setInformationView}
        viewInfo={viewInfo}
        setViewInfo={setViewInfo}
        editView={editView}
        setEditView={setEditView}
        detailView={detailView}
        setDetailView={setDetailView}
      />
    </Paper>
  );
};

export default BranchMedicneSummaryPage;
