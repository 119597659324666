import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import * as generalConstants from "../../../../../_constants/generalConstants";
import { useStyles } from "../../../../../components/globalStyles";

export default function EditMiscFee({ miscList, setMiscList }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState("");
  const handleSelectedMisc = () => {
    //no allow to add with empty space
    if (value) {
      const trimValue = value.trim();

      if (trimValue) {
        const selectedVisitMisc = {
          id: 0,
          // create quantity
          quantity: null,
          name: trimValue,
          unit_selling_price: null,
          status_type: generalConstants.APPROVED_STATUS_TYPE,
        };

        let visitMiscDetail;

        //checking same medicine and dont allow same medicine exist in the same group
        const repeatMisc = miscList.some(
          (item) =>
            item.name === trimValue &&
            item.status_type === generalConstants.APPROVED_STATUS_TYPE
        );
        //find array that status_type === 7 (deleted)
        const repeatDeletedMiscIndex = miscList.findIndex(
          (item) =>
            item.name === trimValue &&
            item.status_type === generalConstants.DELETED_STATUS_TYPE
        );

        if (repeatMisc) {
          visitMiscDetail = [...miscList];
        } else if (repeatDeletedMiscIndex !== -1) {
          visitMiscDetail = [...miscList];

          visitMiscDetail[repeatDeletedMiscIndex] = {
            ...visitMiscDetail[repeatDeletedMiscIndex],
            //id: (use back existing)
            // create quantity
            quantity: null,
            name: trimValue,
            unit_selling_price: null,
            status_type: generalConstants.APPROVED_STATUS_TYPE,
          };
        } else {
          visitMiscDetail = [...miscList, selectedVisitMisc];
        }

        let resultWDelete = visitMiscDetail.filter(
          (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
        );
        let resultWoDelete = visitMiscDetail.filter(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        );
        resultWDelete = resultWDelete.map((x, index) => {
          return { ...x, row_no: null };
        });
        resultWoDelete = resultWoDelete.map((x, index) => {
          return { ...x, row_no: index + 1 };
        });

        setMiscList([...resultWoDelete, ...resultWDelete]);
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.userInfoField}>
        <FormControl
          className={classes.userFieldColor}
          variant="outlined"
          margin="normal"
          fullWidth>
          <InputLabel htmlFor="miscFee">{t("translation:Misc Fee")}</InputLabel>
          <OutlinedInput
            autoComplete="off"
            name="miscFee"
            label="Misc Fee"
            type="text"
            value={value !== null ? value : ""}
            onChange={(e) => setValue(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSelectedMisc}
                  edge="end"
                  size="large">
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
