import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getRoleRightList,
  putEditRoleRight,
  getRoleRight,
} from "../actions/roleRightActions";
import * as types from "../_constants/roleRightConstants";
import * as notification from "../_constants/notificationConstants";
import roleRightApis from "../_apis/roleRightApis";
export default function* roleRightSagas() {
  yield all([
    takeLatest(types.GET_ROLERIGHTLIST_ASYNC.PENDING, getRoleRightListCall),
  ]);

  yield all([
    takeLatest(types.PUT_EDITROLERIGHT_ASYNC.PENDING, putEditRoleRightCall),
  ]);

  yield all([takeLatest(types.GET_ROLERIGHT_ASYNC.PENDING, getRoleRightCall)]);
}

function* getRoleRightListCall(searchModel) {
  try {
    const data = yield call(
      roleRightApis.getRoleRightListApi,
      searchModel.data
    );
    yield put(getRoleRightList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getRoleRightList.error(error.response?.data?.message));
    else yield put(getRoleRightList.error(notification.CONST_NOINTERNET));
  }
}

function* putEditRoleRightCall(roleRightModel) {
  try {
    const data = yield call(
      roleRightApis.putEditRoleRightApi,
      roleRightModel.data
    );
    yield put(putEditRoleRight.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putEditRoleRight.error(error.response?.data?.message));
    else yield put(putEditRoleRight.error(notification.CONST_NOINTERNET));
  }
}

function* getRoleRightCall(searchModel) {
  try {
    const data = yield call(roleRightApis.getRoleRightApi, searchModel.data);
    yield put(getRoleRight.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getRoleRight.error(error.response?.data?.message));
    else yield put(getRoleRight.error(notification.CONST_NOINTERNET));
  }
}
