import React, { useState, useEffect } from "react";
import { Grid, Link, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useStyles } from "./globalStyles";
import Close from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { useTranslation } from "react-i18next";
//components
import { SortingSearchTable } from "./SortingSearchTable";
import * as pageConfigure from "../_constants/pageConstants";
//style

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    justifyContent: "center",
    "& > span": {
      width: "100%",
      backgroundColor: "rgba(240,240,240)",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    marginRight: theme.spacing(1),
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    color: "rgba(240,240,240)",
    backgroundColor: "rgba(155,29,32,1)",
    borderRadius: "5px!important",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "rgba(240,240,240)",
      opacity: 1,
    },
    "&$selected": {
      color: "rgba(240,240,240)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "rgba(240,240,240)",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const UploadCSVDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    cancelButtonText,
    dialogProps,
    dialogTitle,
    maxWidth,
    onClose,
    onSave,
    open,
    submitButtonText,
    msgAlarm,
    alertMsg,
    alertType,
    verifyInfo,
    dialogLoading = false,
  } = props;

  // Submit button state

  const [tabValue, setTabValues] = useState("0");
  const [createPageInfo, setCreatePageInfo] = useState({
    search_delivery_order_number_value: "",
    search_store_order_number_value: "",
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
    search_courier_name_value: null,
  });

  const [updatePageInfo, setUpdatePageInfo] = useState({
    search_delivery_order_number_value: "",
    search_store_order_number_value: "",
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
    search_courier_name_value: null,
  });

  const [errorPageInfo, setErrorPageInfo] = useState({
    search_delivery_order_number_value: "",
    search_store_order_number_value: "",
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
    search_courier_name_value: null,
  });

  const handleTabChange = (event, newTabValue) => {
    setTabValues(newTabValue);
  };

  const [createData, setCreateData] = useState([]);

  const [updateData, setUpdateData] = useState([]);

  const [errorData, setErrorData] = useState([]);

  const columns = [
    {
      Header: t("translation:Index"),
      accessor: "index",
      sortable: false,
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
      sortable: false,
    },
  ];

  const errorColumns = [
    {
      Header: t("translation:Index"),
      accessor: "index",
      sortable: false,
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
      sortable: false,
    },
    {
      Header: t("translation:Message"),
      accessor: "message",
      sortable: false,
    },
  ];

  useEffect(() => {
    const startIndex =
      (createPageInfo.page_number - 1) * createPageInfo.page_size;
    const endIndex = createPageInfo.page_number * createPageInfo.page_size;

    const slideCreateItem = verifyInfo?.createItem?.slice(startIndex, endIndex);

    setCreateData(slideCreateItem);
  }, [
    createPageInfo.page_number,
    createPageInfo.page_size,
    verifyInfo?.createItem,
  ]);

  useEffect(() => {
    const startIndex =
      (updatePageInfo.page_number - 1) * updatePageInfo.page_size;
    const endIndex = updatePageInfo.page_number * updatePageInfo.page_size;

    const slideUpdateItem = verifyInfo?.updateItem?.slice(startIndex, endIndex);

    setUpdateData(slideUpdateItem);
  }, [
    updatePageInfo.page_number,
    updatePageInfo.page_size,
    verifyInfo?.updateItem,
  ]);

  useEffect(() => {
    const startIndex =
      (errorPageInfo.page_number - 1) * errorPageInfo.page_size;
    const endIndex = errorPageInfo.page_number * errorPageInfo.page_size;

    const slideErrorItem = verifyInfo?.errorItem?.slice(startIndex, endIndex);

    setErrorData(slideErrorItem);
  }, [
    errorPageInfo.page_number,
    errorPageInfo.page_size,
    verifyInfo?.errorItem,
  ]);

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      open={open}
      maxWidth={maxWidth}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      aria-labelledby="confirm-dialog">
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={onClose}
      />

      {alertType !== null && alertMsg !== null && (
        <Collapse in={alertMsg}>
          <Alert severity={alertType}>{msgAlarm}</Alert>
        </Collapse>
      )}
      <DialogTitle id="confirm-dialog">{dialogTitle}</DialogTitle>

      <>
        <DialogContent>
          {!dialogLoading && (
            <TabContext value={tabValue}>
              <Grid item xs={12}>
                <StyledTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth">
                  <StyledTab
                    className={classes.defualtCursor}
                    value="0"
                    label={
                      <span>
                        <Typography display="block" variant="subtitle1">
                          {t("translation:CREATE")}
                          {" ("}
                          {verifyInfo.createItemCount}
                          {")"}
                        </Typography>
                      </span>
                    }></StyledTab>
                  <StyledTab
                    className={classes.defualtCursor}
                    value="1"
                    label={
                      <span>
                        <Typography display="block" variant="subtitle1">
                          {t("translation:UPDATE")}
                          {" ("}
                          {verifyInfo.updateItemCount}
                          {")"}
                        </Typography>
                      </span>
                    }></StyledTab>

                  <StyledTab
                    className={classes.defualtCursor}
                    value="2"
                    label={
                      <span>
                        <Typography display="block" variant="subtitle1">
                          {t("translation:Error")}
                          {" ("}
                          {verifyInfo.errorItemCount}
                          {")"}
                        </Typography>
                      </span>
                    }></StyledTab>
                </StyledTabs>
              </Grid>

              <TabPanel className={classes.gridRoot} value="0">
                <SortingSearchTable
                  disabledSearch={true}
                  searchValue={""}
                  statusOption={[]}
                  columns={columns}
                  data={createData}
                  pageInfo={createPageInfo}
                  setPageInfo={setCreatePageInfo}
                  pageCount={Math.ceil(
                    verifyInfo.createItemCount / createPageInfo.page_size
                  )}
                  loading={false}
                  totalItems={verifyInfo.createItemCount}
                  handleSearch={null}
                />
              </TabPanel>

              <TabPanel className={classes.gridRoot} value="1">
                <SortingSearchTable
                  disabledSearch={true}
                  searchValue={""}
                  statusOption={[]}
                  columns={columns}
                  data={updateData}
                  pageInfo={updatePageInfo}
                  setPageInfo={setUpdatePageInfo}
                  pageCount={Math.ceil(
                    verifyInfo.updateItemCount / updatePageInfo.page_size
                  )}
                  loading={false}
                  totalItems={verifyInfo.updateItemCount}
                  handleSearch={null}
                />
              </TabPanel>

              <TabPanel className={classes.gridRoot} value="2">
                <SortingSearchTable
                  disabledSearch={true}
                  searchValue={""}
                  statusOption={[]}
                  columns={errorColumns}
                  data={errorData}
                  pageInfo={errorPageInfo}
                  setPageInfo={setErrorPageInfo}
                  pageCount={Math.ceil(
                    verifyInfo.errorItemCount / errorPageInfo.page_size
                  )}
                  loading={false}
                  totalItems={verifyInfo.errorItemCount}
                  handleSearch={null}
                />
              </TabPanel>
            </TabContext>
          )}

          {dialogLoading && <>{t("translation:Loading")}...</>}
        </DialogContent>

        {!dialogLoading && (
          <DialogActions>
            <Link className={classes.boldMaroon075}>
              <Button
                onClick={onSave}
                className={classes.boldMaroon075}
                cy_data="confirmButton">
                {submitButtonText}
              </Button>
            </Link>

            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                onClick={onClose}
                cy_data="rejectButton">
                {cancelButtonText}
              </Button>
            </Link>
          </DialogActions>
        )}
      </>
    </Dialog>
  );
};

export default UploadCSVDialog;
