import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/notificationsConstants";

export const getNotificationById = {
  pending: (data) =>
    createAction(types.GET_NOTIFICATIONBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_NOTIFICATIONBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_NOTIFICATIONBYID_ASYNC.ERROR, { error }),
};

export const getNotificationList = {
  pending: (data) =>
    createAction(types.GET_NOTIFICATIONLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_NOTIFICATIONLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_NOTIFICATIONLIST_ASYNC.ERROR, { error }),
};

export const postCreateNotification = {
  pending: (data) =>
    createAction(types.POST_CREATENOTIFICATION_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATENOTIFICATION_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATENOTIFICATION_ASYNC.ERROR, { error }),
};

export const putUpdateNotification = {
  pending: (data) =>
    createAction(types.PUT_UPDATENOTIFICATION_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATENOTIFICATION_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATENOTIFICATION_ASYNC.ERROR, { error }),
};
