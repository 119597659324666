import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./globalStyles";

export default function MaxFileSizeAndNumNote({
  maxFileNum = 3,
  maxFileSizeMB = 0,
  supportedFileType = null,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12} className={classes.relativePositionWrapper}>
        {supportedFileType !== null && (
          <Grid item xs={12} className={classes.rightAbsolutePosition}>
            {t("translation:Supported file type")}: {supportedFileType}
          </Grid>
        )}
        <br />
        <Grid item xs={12} className={classes.rightAbsolutePosition}>
          {t("translation:Maximun file size and number of file")}:{" "}
          {maxFileSizeMB} MB, {maxFileNum}
        </Grid>
      </Grid>
    </>
  );
}
