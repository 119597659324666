import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/userRoleConstants";

export const getUserRoleList = {
  pending: (data) =>
    createAction(types.GET_USERROLELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERROLELIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_USERROLELIST_ASYNC.ERROR, { error }),
};

export const putEditUserRole = {
  pending: (data) =>
    createAction(types.PUT_EDITUSERROLE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_EDITUSERROLE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.PUT_EDITUSERROLE_ASYNC.ERROR, { error }),
};

export const getUserRole = {
  pending: (data) => createAction(types.GET_USERROLE_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.GET_USERROLE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_USERROLE_ASYNC.ERROR, { error }),
};

export const getUserRoleAllRole = {
  pending: (data) =>
    createAction(types.GET_USERROLEALLROLE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERROLEALLROLE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_USERROLEALLROLE_ASYNC.ERROR, { error }),
};
