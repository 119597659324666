import * as types from "../_constants/systemScheduleConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_SYSTEMSCHEDULEBYID_ASYNC.PENDING:
      return {
        ...state,
        getSystemScheduleByIdIsSuccess: false,
        isLoadingGetSystemScheduleById: true,
        getSystemScheduleById: null,
      };

    case types.GET_SYSTEMSCHEDULEBYID_ASYNC.ERROR:
      return {
        ...state,
        getSystemScheduleByIdIsSuccess: false,
        isLoadingGetSystemScheduleById: false,
        getSystemScheduleById: null,
        error: { ...state.error, getSystemScheduleByIdError: action.error },
      };

    case types.GET_SYSTEMSCHEDULEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getSystemScheduleByIdIsSuccess: true,
        isLoadingGetSystemScheduleById: false,
        getSystemScheduleById: action.data,
      };

    case types.GET_SYSTEMSCHEDULELIST_ASYNC.PENDING:
      return {
        ...state,
        getSystemScheduleListIsSuccess: false,
        isLoadingGetSystemScheduleList: true,
        getSystemScheduleList: null,
      };

    case types.GET_SYSTEMSCHEDULELIST_ASYNC.ERROR:
      return {
        ...state,
        getSystemScheduleListIsSuccess: false,
        isLoadingGetSystemScheduleList: false,
        getSystemScheduleList: null,
        error: { ...state.error, getSystemScheduleListError: action.error },
      };

    case types.GET_SYSTEMSCHEDULELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getSystemScheduleListIsSuccess: true,
        isLoadingGetSystemScheduleList: false,
        getSystemScheduleList: action.data,
      };

    case types.POST_CREATESYSTEMSCHEDULE_ASYNC.PENDING:
      return {
        ...state,
        postCreateSystemScheduleIsSuccess: false,
        isLoadingPostCreateSystemSchedule: true,
        postCreateSystemSchedule: null,
      };

    case types.POST_CREATESYSTEMSCHEDULE_ASYNC.ERROR:
      return {
        ...state,
        postCreateSystemScheduleIsSuccess: false,
        isLoadingPostCreateSystemSchedule: false,
        postCreateSystemSchedule: null,
        error: { ...state.error, postCreateSystemScheduleError: action.error },
      };

    case types.POST_CREATESYSTEMSCHEDULE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateSystemScheduleIsSuccess: true,
        isLoadingPostCreateSystemSchedule: false,
        postCreateSystemSchedule: action.data,
      };

    case types.PUT_UPDATESYSTEMSCHEDULE_ASYNC.PENDING:
      return {
        ...state,
        putUpdateSystemScheduleIsSuccess: false,
        isLoadingPutUpdateSystemSchedule: true,
        putUpdateSystemSchedule: null,
      };

    case types.PUT_UPDATESYSTEMSCHEDULE_ASYNC.ERROR:
      return {
        ...state,
        putUpdateSystemScheduleIsSuccess: false,
        isLoadingPutUpdateSystemSchedule: false,
        putUpdateSystemSchedule: null,
        error: { ...state.error, putUpdateSystemScheduleError: action.error },
      };

    case types.PUT_UPDATESYSTEMSCHEDULE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateSystemScheduleIsSuccess: true,
        isLoadingPutUpdateSystemSchedule: false,
        putUpdateSystemSchedule: action.data,
      };

    default:
      return state;
  }
};
