//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import moment from "moment";

//actions

import {
  getSingaporeHolidayList,
  getSingaporeYearlyHoliday,
} from "../../../../../actions/extraApiActions";

//components
import { SortingSearchTable } from "../../../../../components/SortingSearchTable";
import * as pageConfigure from "../../../../../_constants/pageConstants";
import StyledCheckbox from "../../../../../components/StyledCheckbox";

//style

import { useStyles } from "../../../../../components/globalStyles";

export default function SingaporeHolidayListTable({
  setConfirmSubmissionList,
}) {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const prevGetVisitStoreOrderByIdOnStatus = useRef();
  const externalApi = useSelector((state) => state.externalApi);
  const [submissionList, setSubmissionList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  const viewColumns = [
    {
      Header: "",
      accessor: "checkHoliday",
      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <>
            <StyledCheckbox
              checked={submissionList.some((e) => e._id === row.original._id)}
              name="checkHoliday"
              onChange={(e) => handleCheck(e, row)}
            />
          </>
        );
      },
    },
    {
      Header: t("translation:Date"),
      accessor: "date",
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.date)
          return moment.utc(row.original.date).local().format("DD-MMM-YYYY");
        else return "";
      },
    },

    {
      Header: t("translation:Day"),
      accessor: "day",
      sortable: false,
    },
    {
      Header: t("translation:Holiday"),
      accessor: "holiday",
      sortable: false,
    },
  ];
  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });
  //functions

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setSubmissionList((submissionList) => [...submissionList, row.original]);
    } else {
      setSubmissionList((submissionList) => [
        ...submissionList.filter((e) => e._id !== row.original._id),
      ]);
    }
  };

  //useEffect
  useEffect(() => {
    prevGetVisitStoreOrderByIdOnStatus.current = false;
  }, []);

  useEffect(() => {
    setConfirmSubmissionList(submissionList);
  }, [submissionList]);

  useEffect(() => {
    dispatch(getSingaporeHolidayList.pending());
  }, [dispatch]);

  if (
    externalApi.getSingaporeHolidayListIsSuccess === false &&
    externalApi.isLoadingGetSingaporeHolidayList === false
  ) {
    return (
      <Alert severity="error">
        {externalApi.error.getSingaporeHolidayListError}
      </Alert>
    );
  } else if (
    externalApi.getSingaporeHolidayListIsSuccess === true &&
    externalApi.isLoadingGetSingaporeHolidayList === false
  ) {
    return (
      <>
        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            disableClearable
            name="holidayYear"
            cy_data="holidayYear"
            id="holidayYear"
            options={
              externalApi.getSingaporeHolidayList
                ? externalApi.getSingaporeHolidayList?.data.datasetMetadata.sort(
                    (a, b) =>
                      new Date(a.createdAt).setHours(0, 0, 0, 0) -
                      new Date(b.createdAt).setHours(0, 0, 0, 0)
                  )
                : []
            }
            getOptionLabel={(option) => (option.name ? option.name : "")}
            value={selectedYear}
            onChange={(e, option) => {
              setSelectedYear(option);
              dispatch(
                getSingaporeYearlyHoliday.pending({ id: option.datasetId })
              );
            }}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Year")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {selectedYear &&
            externalApi.getSingaporeYearlyHolidayIsSuccess === true &&
            externalApi.isLoadingGetSingaporeYearlyHoliday === false && (
              <SortingSearchTable
                disabledSearch
                columns={viewColumns}
                data={
                  externalApi.getSingaporeYearlyHoliday
                    ? externalApi.getSingaporeYearlyHoliday.result.records
                    : []
                }
                pageInfo={viewPageInfo}
                setPageInfo={setViewPageInfo}
                disabledFooter
              />
            )}

          {selectedYear &&
            externalApi.getSingaporeYearlyHolidayIsSuccess === false &&
            externalApi.isLoadingGetSingaporeYearlyHoliday === false && (
              <Alert severity="error">
                {externalApi.error.getSingaporeYearlyHolidayError}
              </Alert>
            )}

          {selectedYear &&
            externalApi.isLoadingGetSingaporeYearlyHoliday === true && (
              <div>{t("translation:Loading")}...</div>
            )}
        </Grid>
      </>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
