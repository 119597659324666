import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getTreatmentList,
  getTreatmentById,
  postCreateTreatment,
  putUpdateTreatment,
  getTreatmentTreatmentTypes,
} from "../actions/treatmentActions";
import * as types from "../_constants/treatmentConstants";
import * as notification from "../_constants/notificationConstants";
import treatmentApis from "../_apis/treatmentApis";
export default function* treatmentSagas() {
  yield all([
    takeLatest(types.GET_TREATMENTLIST_ASYNC.PENDING, getTreatmentListCall),
  ]);
  yield all([
    takeLatest(types.GET_TREATMENTBYID_ASYNC.PENDING, getTreatmentByIdCall),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATETREATMENT_ASYNC.PENDING,
      postCreateTreatmentCall
    ),
  ]);
  yield all([
    takeLatest(types.PUT_UPDATETREATMENT_ASYNC.PENDING, putUpdateTreatmentCall),
  ]);

  yield all([
    takeLatest(
      types.GET_TREATMENTTREATMENTTYPES_ASYNC.PENDING,
      getTreatmentTreatmentTypesCall
    ),
  ]);
}

function* getTreatmentListCall(searchModel) {
  try {
    const data = yield call(
      treatmentApis.getTreatmentListApi,
      searchModel.data
    );
    yield put(getTreatmentList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getTreatmentList.error(error.response?.data?.message));
    else yield put(getTreatmentList.error(notification.CONST_NOINTERNET));
  }
}

function* getTreatmentByIdCall(searchModel) {
  try {
    const data = yield call(
      treatmentApis.getTreatmentByIdApi,
      searchModel.data
    );
    yield put(getTreatmentById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getTreatmentById.error(error.response?.data?.message));
    else yield put(getTreatmentById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateTreatmentCall(searchModel) {
  try {
    const data = yield call(
      treatmentApis.postCreateTreatmentApi,
      searchModel.data
    );
    yield put(postCreateTreatment.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateTreatment.error(error.response?.data?.message));
    else yield put(postCreateTreatment.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateTreatmentCall(searchModel) {
  try {
    const data = yield call(
      treatmentApis.putUpdateTreatmentApi,
      searchModel.data
    );
    yield put(putUpdateTreatment.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateTreatment.error(error.response?.data?.message));
    else yield put(putUpdateTreatment.error(notification.CONST_NOINTERNET));
  }
}

function* getTreatmentTreatmentTypesCall(searchModel) {
  try {
    const data = yield call(
      treatmentApis.getTreatmentTreatmentTypesApi,
      searchModel.data
    );
    yield put(getTreatmentTreatmentTypes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getTreatmentTreatmentTypes.error(error.response?.data?.message)
      );
    else
      yield put(
        getTreatmentTreatmentTypes.error(notification.CONST_NOINTERNET)
      );
  }
}
