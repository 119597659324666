import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import InvoiceSummaryPage from "./Invoice/InvoiceSummaryPage";
import InvoiceMedicineCostingSummaryPage from "./InvoiceMedicineCosting/InvoiceMedicineCostingSummaryPage";

export default function InvoicePage() {
  return (
    <Switch>
      <Route path="/invoice/InvoiceSummary" component={InvoiceSummaryPage} />
      <Route
        path="/invoice/InvoiceMedicineCostingSummary"
        component={InvoiceMedicineCostingSummaryPage}
      />
      <Redirect to="/error" />
    </Switch>
  );
}
