import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const cloudStorageApis = {
  postCreatePresignedUrlApi,
  postCreatePresignedJsonApi,
  postCreatePresignedUrlPhotoApi,
  postCreatePresignedJsonPhotoApi,
  postCreatePresignedUrlSignatureApi,
  postCreatePresignedJsonSignatureApi,
  postCreatePresignedUrlOrganisationLogoApi,
  postCreatePresignedJsonDeliveryTrackingApi,
  postCreatePresignedJsonInvoiceApi,
};

async function postCreatePresignedUrlApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostCreatePresignedUrl,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreatePresignedJsonApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostVisitCreatePresignedJson,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreatePresignedUrlPhotoApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostCreatePresignedUrl,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreatePresignedJsonPhotoApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostOrganisationPhysicianCreatePresignedJson,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreatePresignedUrlSignatureApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostCreatePresignedUrl,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreatePresignedJsonSignatureApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostOrganisationPhysicianCreatePresignedJson,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreatePresignedUrlOrganisationLogoApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostCreatePresignedUrl,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreatePresignedJsonDeliveryTrackingApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostDeliveryTrakingCreatePresignedJson,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreatePresignedJsonInvoiceApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(
      apiConstants.apiPostInvoiceCreatePresignedJson,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default cloudStorageApis;
