import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import i18n from "i18next";
import { zhCN } from "@mui/material/locale";
import * as lang from "../_constants/langConstants";
export const formLabelsTheme = createTheme({
  components: {
    MuiAlert: { styleOverrides: { message: { width: "100%" } } },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          backgroundColor: "rgba(149, 0, 0,0.75)",
        },
      },
    },
    MuiPickerDTTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor: "rgba(149, 0, 0,1)",
        },
      },
    },
    MuiPickersClockPointer: {
      styleOverrides: {
        thumb: {
          borderColor: "rgba(149, 0, 0,1)",
        },
        point: {
          borderColor: "rgba(149, 0, 0,1)",
        },
        pointer: {
          backgroundColor: "rgba(149, 0, 0,1)",
        },
        noPoint: {
          backgroundColor: "rgba(149, 0, 0,1)",
        },
      },
    },
    MuiPickersClock: {
      styleOverrides: {
        pin: {
          backgroundColor: "rgba(149, 0, 0,1)",
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        daySelected: {
          "&:hover": { backgroundColor: "rgba(149, 0, 0,1)" },
          backgroundColor: "rgba(149, 0, 0,0.75)",
        },
        current: {
          color: "rgba(149, 0, 0,1)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#f50057",
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131 !important",
          "&$error": {
            color: "#db3131 !important",
          },
        },
      },
    },
  },
});

const GlodalThemeWrapper = (props) => {
  const languageAddon = {
    ...formLabelsTheme,
    ...zhCN,
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={
          i18n.language === lang.CONST_LANGUAGE_CHINESE
            ? languageAddon
            : formLabelsTheme
        }>
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default GlodalThemeWrapper;
