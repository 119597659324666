//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
//action
import {
  postCreateMedicine,
  getMedicineMedicineTypes,
} from "../../../../actions/medicineActions";

import { getMedicineMeasureUnit } from "../../../../actions/commonActions";
//components
import Panel from "../../../../components/Panel";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import * as generalConstants from "../../../../_constants/generalConstants";

const AddMedicinePage = ({ setListView }) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const common = useSelector((state) => state.common);
  const medicine = useSelector((state) => state.medicine);

  const prevPostMedicineStatus = useRef();

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      name: null,
      alternative_name: null,
      medicine_type: null,
      measure_unit: null,
      default_instruction: null,
      dosage: null,
      default_quantity_per_day: 0,
      unit_cost_price: 0,
      unit_selling_price: 0,
      package_content: 0,
      expiry_threshold_in_days: 0,
      qty_low_threshold_in_unit_measure: 0,
      weight_loss_threshold_in_percentage: 0,
      info1: null,
      info2: null,
      info3: null,
      info_functionality: null,
      info_precaution: null,
      status_type: null,
    },
  });

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const submitJson = {
      medicine: {
        id: 0,
        status_type: data.status_type,
        name: data.name.trim(),
        alternative_name: data.alternative_name
          ? data.alternative_name.trim()
          : null,
        medicine_type: { id: data.medicine_type },
        measure_unit: data.measure_unit,
        default_instruction: data.default_instruction,
        dosage: data.dosage,
        package_content: data.package_content ? data.package_content : 0,
        default_quantity_per_day: data.default_quantity_per_day
          ? data.default_quantity_per_day
          : 0,

        unit_cost_price: data.unit_cost_price ? data.unit_cost_price : 0,
        unit_selling_price: data.unit_selling_price
          ? data.unit_selling_price
          : 0,
        expiry_threshold_in_days: data.expiry_threshold_in_days
          ? data.expiry_threshold_in_days
          : 0,
        qty_low_threshold_in_unit_measure:
          data.qty_low_threshold_in_unit_measure
            ? data.qty_low_threshold_in_unit_measure
            : 0,
        weight_loss_threshold_in_percentage:
          data.weight_loss_threshold_in_percentage
            ? data.weight_loss_threshold_in_percentage
            : 0,
        info1: data.info1,
        info2: data.info2,
        info3: data.info3,
        info_functionality: data.info_functionality,
        info_precaution: data.info_precaution,
      },
    };
    dispatch(postCreateMedicine.pending(submitJson));
  };

  //useEffects
  useEffect(() => {
    //set ref when first render
    prevPostMedicineStatus.current = false;
  }, []);

  useEffect(() => {
    dispatch(getMedicineMedicineTypes.pending());
    dispatch(getMedicineMeasureUnit.pending());
  }, [dispatch]);

  useEffect(() => {
    if (
      medicine.isLoadingPostCreateMedicine !== prevPostMedicineStatus.current
    ) {
      //check the previous ref with current state
      prevPostMedicineStatus.current = medicine.isLoadingPostCreateMedicine;

      if (
        medicine.postCreateMedicineIsSuccess === true &&
        medicine.isLoadingPostCreateMedicine === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);

        reset({
          ...medicine.postCreateMedicine.medicine,
          medicine_type: medicine.postCreateMedicine.medicine.medicine_type.id,
        });
        window.scrollTo(0, 0);
        setTemViewOnly(true);
      }
      if (
        medicine.postCreateMedicineIsSuccess === false &&
        medicine.isLoadingPostCreateMedicine === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(medicine.error.postCreateMedicineError);
        window.scrollTo(0, 0);
      }
    }
  }, [medicine, reset]);

  return (
    <Panel heading={t("translation:Medicine Details")}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Medicine has been created")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.gridRoot} container>
          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              required
              name="name"
              label={t("translation:Name")}
              variant="outlined"
              {...register("name")}
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="alternative_name"
              label={t("translation:Alternative Name")}
              variant="outlined"
              {...register("alternative_name")}
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              {...register("unit_cost_price")}
              cy_data="unit_cost_price"
              onFocus={(event) => {
                event.target.select();
              }}
              required
              name="unit_cost_price"
              label={t("translation:Unit Cost Price")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              {...register("unit_selling_price")}
              cy_data="unit_selling_price"
              onFocus={(event) => {
                event.target.select();
              }}
              name="unit_selling_price"
              required
              label={t("translation:Unit Selling Price")}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="dosage"
              label={t("translation:Dosage")}
              variant="outlined"
              {...register("dosage")}
            />
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="default_instruction"
              label={t("translation:Default Instruction")}
              variant="outlined"
              {...register("default_instruction")}
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              {...register("default_quantity_per_day")}
              cy_data="default_quantity_per_day"
              onFocus={(event) => {
                event.target.select();
              }}
              name="default_quantity_per_day"
              label={t("translation:Default Quantity/ Day")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              {...register("package_content")}
              cy_data="package_content"
              onFocus={(event) => {
                event.target.select();
              }}
              name="package_content"
              label={t("translation:Package Content")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly}
                  {...field}
                  name="medicine_type"
                  id="medicine_type"
                  required
                  options={
                    medicine.getMedicineMedicineTypes?.medicine_types
                      ? medicine.getMedicineMedicineTypes?.medicine_types.filter(
                          (item) =>
                            item.status_type ===
                            generalConstants.APPROVED_STATUS_TYPE
                        )
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(
                      option,
                      medicine.getMedicineMedicineTypes?.medicine_types
                    ).name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Medicine Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="medicine_type"
              control={control}
            />
          </Grid>

          {common.medicineMeasureUnitList?.measure_units && (
            <Grid item xs={6} className={classes.userInfoField}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    disabled={temViewOnly}
                    {...field}
                    name="measure_unit"
                    id="measure_unit"
                    required
                    options={common.medicineMeasureUnitList?.measure_units}
                    getOptionLabel={(option) =>
                      getOpObj(
                        option,
                        common.medicineMeasureUnitList?.measure_units
                      ).name
                    }
                    style={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) => {
                      if (option.id === value) {
                        return option;
                      }
                    }}
                    onChange={(e, option) =>
                      field.onChange(option == null ? null : option.id)
                    }
                    renderInput={(params) => (
                      <TextField
                        disabled={temViewOnly}
                        required
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Measure Unit")}
                        variant="outlined"
                      />
                    )}
                  />
                )}
                name="measure_unit"
                control={control}
              />
            </Grid>
          )}

          <Grid item xs={4} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              {...register("expiry_threshold_in_days")}
              cy_data="expiry_threshold_in_days"
              onFocus={(event) => {
                event.target.select();
              }}
              name="expiry_threshold_in_days"
              label={t("translation:Expiry Threshold (Days)")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              {...register("qty_low_threshold_in_unit_measure")}
              cy_data="qty_low_threshold_in_unit_measure"
              onFocus={(event) => {
                event.target.select();
              }}
              name="qty_low_threshold_in_unit_measure"
              label={t("translation:Low Quantity Threshold")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={4} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              {...register("weight_loss_threshold_in_percentage")}
              cy_data="weight_loss_threshold_in_percentage"
              onFocus={(event) => {
                event.target.select();
              }}
              name="weight_loss_threshold_in_percentage"
              label={t("translation:Weight Loss Threshold (%)")}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="info1"
              label={t("translation:Info 1")}
              variant="outlined"
              {...register("info1")}
              multiline
              minRows={3}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="info2"
              label={t("translation:Info 2")}
              variant="outlined"
              {...register("info2")}
              multiline
              minRows={3}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="info3"
              label={t("translation:Info 3")}
              variant="outlined"
              {...register("info3")}
              multiline
              minRows={3}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="info_functionality"
              label={t("translation:Info Functionality")}
              variant="outlined"
              {...register("info_functionality")}
              multiline
              minRows={3}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="info_precaution"
              label={t("translation:Info Precaution")}
              variant="outlined"
              {...register("info_precaution")}
              multiline
              minRows={3}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly}
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>

          <>
            <RequiredNote />
            {temViewOnly ? (
              <PageOnlyBackAction setView={() => setListView(true)} />
            ) : (
              <PageSubmitWBackAction setView={() => setListView(true)} />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};

export default AddMedicinePage;
