//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";

//action
import { postCreatePostalCodeSurcharge } from "../../../../actions/postalCodeSurchargeActions";
//components
import Panel from "../../../../components/Panel";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import allowOnlyNumericsOrDigits from "../../../../components/functions/allowOnlyNumericsOrDigits";
const AddPostalCodeSurchargePage = ({ setListView }) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const postalCodeSurchargeData = useSelector(
    (state) => state.postalCodeSurcharge
  );
  const common = useSelector((state) => state.common);
  const prevPostPostalCodeSurchargeStatus = useRef();

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      postal_code: null,
      surcharge: null,
      status_type: null,
    },
  });

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJson = {
      postal_code_surcharge: {
        id: 0,
        ...data,
      },
    };
    dispatch(postCreatePostalCodeSurcharge.pending(submitJson));
  };

  //useEffects
  useEffect(() => {
    //set ref when first render
    prevPostPostalCodeSurchargeStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      postalCodeSurchargeData.isLoadingPostCreatePostalCodeSurcharge !==
      prevPostPostalCodeSurchargeStatus.current
    ) {
      //check the previous ref with current state
      prevPostPostalCodeSurchargeStatus.current =
        postalCodeSurchargeData.isLoadingPostCreatePostalCodeSurcharge;

      if (
        postalCodeSurchargeData.postCreatePostalCodeSurchargeIsSuccess ===
          true &&
        postalCodeSurchargeData.isLoadingPostCreatePostalCodeSurcharge === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        window.scrollTo(0, 0);
        reset({
          ...postalCodeSurchargeData.postCreatePostalCodeSurcharge
            .postal_code_surcharge,
        });
        setTemViewOnly(true);
      }
      if (
        postalCodeSurchargeData.postCreatePostalCodeSurchargeIsSuccess ===
          false &&
        postalCodeSurchargeData.isLoadingPostCreatePostalCodeSurcharge === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(
          postalCodeSurchargeData.error.postCreatePostalCodeSurchargeError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [postalCodeSurchargeData, reset]);

  return (
    <Panel heading={t("translation:Postal Code Surcharge Details")}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Postal Code Surcharge has been created")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.gridRoot} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              required
              name="postal_code"
              InputProps={{
                inputProps: {
                  minLength: 6,
                  maxLength: 6,
                },
              }}
              onInput={allowOnlyNumericsOrDigits}
              label={t("translation:Postal Code")}
              variant="outlined"
              {...register("postal_code")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              required
              name="surcharge"
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "left" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              label={t("translation:Surcharge")}
              variant="outlined"
              {...register("surcharge")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly}
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>

          <>
            <RequiredNote />

            {temViewOnly ? (
              <PageOnlyBackAction setView={() => setListView(true)} />
            ) : (
              <PageSubmitWBackAction setView={() => setListView(true)} />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};

export default AddPostalCodeSurchargePage;
