import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getUserMedicineFixedSellingPriceList,
  getUserMedicineFixedSellingPriceSummaryList,
  getUserMedicineFixedSellingPriceById,
  postCreateUserMedicineFixedSellingPrice,
  putUpdateUserMedicineFixedSellingPrice,
  getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList,
  getUserMedicineFixedSellingPriceUserList,
} from "../actions/userMedicineFixedSellingPriceActions";
import * as types from "../_constants/userMedicineFixedSellingPriceConstants";
import * as notification from "../_constants/notificationConstants";
import userMedicineFixedSellingPriceApis from "../_apis/userMedicineFixedSellingPriceApis";
export default function* userMedicineFixedSellingPriceSagas() {
  yield all([
    takeLatest(
      types.GET_USERMEDICINEFIXEDSELLINGPRICELIST_ASYNC.PENDING,
      getUserMedicineFixedSellingPriceListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_USERMEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.PENDING,
      getUserMedicineFixedSellingPriceSummaryListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_USERMEDICINEFIXEDSELLINGPRICEBYID_ASYNC.PENDING,
      getUserMedicineFixedSellingPriceByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING,
      postCreateUserMedicineFixedSellingPriceCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING,
      putUpdateUserMedicineFixedSellingPriceCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_USERMEDICINEFIXEDSELLINGPRICEMEDICINEFIXEDSELLINGPRICELIST_ASYNC
        .PENDING,
      getUserMedicineFixedSellingPriceMedicineFixedSellingPriceListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_USERMEDICINEFIXEDSELLINGPRICEUSERLIST_ASYNC.PENDING,
      getUserMedicineFixedSellingPriceUserListCall
    ),
  ]);
}

function* getUserMedicineFixedSellingPriceListCall(searchModel) {
  try {
    const data = yield call(
      userMedicineFixedSellingPriceApis.getUserMedicineFixedSellingPriceListApi,
      searchModel.data
    );
    yield put(getUserMedicineFixedSellingPriceList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getUserMedicineFixedSellingPriceList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getUserMedicineFixedSellingPriceList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getUserMedicineFixedSellingPriceSummaryListCall(searchModel) {
  try {
    const data = yield call(
      userMedicineFixedSellingPriceApis.getUserMedicineFixedSellingPriceSummaryListApi,
      searchModel.data
    );
    yield put(getUserMedicineFixedSellingPriceSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getUserMedicineFixedSellingPriceSummaryList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getUserMedicineFixedSellingPriceSummaryList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getUserMedicineFixedSellingPriceByIdCall(searchModel) {
  try {
    const data = yield call(
      userMedicineFixedSellingPriceApis.getUserMedicineFixedSellingPriceByIdApi,
      searchModel.data
    );
    yield put(getUserMedicineFixedSellingPriceById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getUserMedicineFixedSellingPriceById.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getUserMedicineFixedSellingPriceById.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* postCreateUserMedicineFixedSellingPriceCall(searchModel) {
  try {
    const data = yield call(
      userMedicineFixedSellingPriceApis.postCreateUserMedicineFixedSellingPriceApi,
      searchModel.data
    );
    yield put(postCreateUserMedicineFixedSellingPrice.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postCreateUserMedicineFixedSellingPrice.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postCreateUserMedicineFixedSellingPrice.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* putUpdateUserMedicineFixedSellingPriceCall(searchModel) {
  try {
    const data = yield call(
      userMedicineFixedSellingPriceApis.putUpdateUserMedicineFixedSellingPriceApi,
      searchModel.data
    );
    yield put(putUpdateUserMedicineFixedSellingPrice.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateUserMedicineFixedSellingPrice.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        putUpdateUserMedicineFixedSellingPrice.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getUserMedicineFixedSellingPriceMedicineFixedSellingPriceListCall(
  searchModel
) {
  try {
    const data = yield call(
      userMedicineFixedSellingPriceApis.getUserMedicineFixedSellingPriceMedicineFixedSellingPriceListApi,
      searchModel.data
    );
    yield put(
      getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList.success(
        data
      )
    );
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getUserMedicineFixedSellingPriceUserListCall(searchModel) {
  try {
    const data = yield call(
      userMedicineFixedSellingPriceApis.getUserMedicineFixedSellingPriceUserListApi,
      searchModel.data
    );
    yield put(getUserMedicineFixedSellingPriceUserList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getUserMedicineFixedSellingPriceUserList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getUserMedicineFixedSellingPriceUserList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}
