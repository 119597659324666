import * as types from "../_constants/emailStoreAccountConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_EMAILSTOREACCOUNTBYID_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreAccountByIdIsSuccess: false,
        isLoadingGetEmailStoreAccountById: true,
        getEmailStoreAccountById: null,
      };

    case types.GET_EMAILSTOREACCOUNTBYID_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreAccountByIdIsSuccess: false,
        isLoadingGetEmailStoreAccountById: false,
        getEmailStoreAccountById: null,
        error: {
          ...state.error,
          getEmailStoreAccountByIdError: action.error,
        },
      };

    case types.GET_EMAILSTOREACCOUNTBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreAccountByIdIsSuccess: true,
        isLoadingGetEmailStoreAccountById: false,
        getEmailStoreAccountById: action.data,
      };

    case types.GET_EMAILSTOREACCOUNTLIST_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreAccountListIsSuccess: false,
        isLoadingGetEmailStoreAccountList: true,
        getEmailStoreAccountList: null,
      };

    case types.GET_EMAILSTOREACCOUNTLIST_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreAccountListIsSuccess: false,
        isLoadingGetEmailStoreAccountList: false,
        getEmailStoreAccountList: null,
        error: {
          ...state.error,
          getEmailStoreAccountListError: action.error,
        },
      };

    case types.GET_EMAILSTOREACCOUNTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreAccountListIsSuccess: true,
        isLoadingGetEmailStoreAccountList: false,
        getEmailStoreAccountList: action.data,
      };

    case types.POST_CREATEEMAILSTOREACCOUNT_ASYNC.PENDING:
      return {
        ...state,
        postCreateEmailStoreAccountIsSuccess: false,
        isLoadingPostCreateEmailStoreAccount: true,
        postCreateEmailStoreAccount: null,
      };

    case types.POST_CREATEEMAILSTOREACCOUNT_ASYNC.ERROR:
      return {
        ...state,
        postCreateEmailStoreAccountIsSuccess: false,
        isLoadingPostCreateEmailStoreAccount: false,
        postCreateEmailStoreAccount: null,
        error: {
          ...state.error,
          postCreateEmailStoreAccountError: action.error,
        },
      };

    case types.POST_CREATEEMAILSTOREACCOUNT_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateEmailStoreAccountIsSuccess: true,
        isLoadingPostCreateEmailStoreAccount: false,
        postCreateEmailStoreAccount: action.data,
      };

    case types.PUT_UPDATEEMAILSTOREACCOUNT_ASYNC.PENDING:
      return {
        ...state,
        putUpdateEmailStoreAccountIsSuccess: false,
        isLoadingPutUpdateEmailStoreAccount: true,
        putUpdateEmailStoreAccount: null,
      };

    case types.PUT_UPDATEEMAILSTOREACCOUNT_ASYNC.ERROR:
      return {
        ...state,
        putUpdateEmailStoreAccountIsSuccess: false,
        isLoadingPutUpdateEmailStoreAccount: false,
        putUpdateEmailStoreAccount: null,
        error: {
          ...state.error,
          putUpdateEmailStoreAccountError: action.error,
        },
      };

    case types.PUT_UPDATEEMAILSTOREACCOUNT_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateEmailStoreAccountIsSuccess: true,
        isLoadingPutUpdateEmailStoreAccount: false,
        putUpdateEmailStoreAccount: action.data,
      };

    default:
      return state;
  }
};
