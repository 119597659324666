import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
//components
import * as generalConstants from "../../../_constants/generalConstants";

import SocketNotification from "./SocketNotification";

import InvoiceMedicineCostingListTable from "../../invoice/InvoiceMedicineCosting/InvoiceMedicineCostingListTable";

//styles
export default function InvoiceMedicineCostTable() {
  const { t } = useTranslation();

  //variable
  const [refreshTimeStamp, setRefreshTimeStamp] = useState(moment());

  return (
    <SocketNotification
      cellTitle={t("translation:Invoice Medicine Costing")}
      sockectWorkTypeId={
        generalConstants.INVOICE_MEDICINE_COSTING_WORK_ORDER_TYPE
      }
      setRefreshTimeStamp={setRefreshTimeStamp}
      localStorageName="IMCListTableNotification">
      <InvoiceMedicineCostingListTable
        refreshTimeStamp={refreshTimeStamp}
        dashboardView={true}
        setRefreshTimeStamp={setRefreshTimeStamp}
      />
    </SocketNotification>
  );
}
