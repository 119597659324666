import * as types from "../_constants/orderFormConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_ORDERFORMLIST_ASYNC.PENDING:
      return {
        ...state,
        getOrderFormListIsSuccess: false,
        isLoadingGetOrderFormList: true,
        orderFormList: null,
      };

    case types.GET_ORDERFORMLIST_ASYNC.ERROR:
      return {
        ...state,
        getOrderFormListIsSuccess: false,
        isLoadingGetOrderFormList: false,
        orderFormList: null,
        error: { ...state.error, getOrderFormListError: action.error },
      };

    case types.GET_ORDERFORMLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getOrderFormListIsSuccess: true,
        isLoadingGetOrderFormList: false,
        orderFormList: action.data,
      };

    case types.GET_ORDERFORMBYID_ASYNC.PENDING:
      return {
        ...state,
        getOrderFormByIdIsSuccess: false,
        isLoadingGetOrderFormById: true,
        orderFormById: null,
      };

    case types.GET_ORDERFORMBYID_ASYNC.ERROR:
      return {
        ...state,
        getOrderFormByIdIsSuccess: false,
        isLoadingGetOrderFormById: false,
        orderFormById: null,
        error: { ...state.error, getOrderFormByIdError: action.error },
      };

    case types.GET_ORDERFORMBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getOrderFormByIdIsSuccess: true,
        isLoadingGetOrderFormById: false,
        orderFormById: action.data,
      };

    case types.GET_ORDERFORMMEDICINETYPES_ASYNC.PENDING:
      return {
        ...state,
        getOrderFormMedicineTypesIsSuccess: false,
        isLoadingGetOrderFormMedicineTypes: true,
        orderFormMedicineTypes: null,
      };

    case types.GET_ORDERFORMMEDICINETYPES_ASYNC.ERROR:
      return {
        ...state,
        getOrderFormMedicineTypesIsSuccess: false,
        isLoadingGetOrderFormMedicineTypes: false,
        orderFormMedicineTypes: null,
        error: { ...state.error, getOrderFormMedicineTypesError: action.error },
      };

    case types.GET_ORDERFORMMEDICINETYPES_ASYNC.SUCCESS:
      return {
        ...state,
        getOrderFormMedicineTypesIsSuccess: true,
        isLoadingGetOrderFormMedicineTypes: false,
        orderFormMedicineTypes: action.data,
      };

    case types.POST_ORDERFORMMEDICINES_ASYNC.PENDING:
      return {
        ...state,
        postOrderFormMedicinesIsSuccess: false,
        isLoadingPostOrderFormMedicines: true,
        orderFormMedicines: null,
      };

    case types.POST_ORDERFORMMEDICINES_ASYNC.ERROR:
      return {
        ...state,
        postOrderFormMedicinesIsSuccess: false,
        isLoadingPostOrderFormMedicines: false,
        orderFormMedicines: null,
        error: { ...state.error, postOrderFormMedicinesError: action.error },
      };

    case types.POST_ORDERFORMMEDICINES_ASYNC.SUCCESS:
      return {
        ...state,
        postOrderFormMedicinesIsSuccess: true,
        isLoadingPostOrderFormMedicines: false,
        orderFormMedicines: action.data,
      };

    case types.POST_ORDERFORMSUPPLIERS_ASYNC.PENDING:
      return {
        ...state,
        postOrderFormSuppliersIsSuccess: false,
        isLoadingPostOrderFormSuppliers: true,
        orderFormSuppliers: null,
      };

    case types.POST_ORDERFORMSUPPLIERS_ASYNC.ERROR:
      return {
        ...state,
        postOrderFormSuppliersIsSuccess: false,
        isLoadingPostOrderFormSuppliers: false,
        orderFormSuppliers: null,
        error: { ...state.error, postOrderFormSuppliersError: action.error },
      };

    case types.POST_ORDERFORMSUPPLIERS_ASYNC.SUCCESS:
      return {
        ...state,
        postOrderFormSuppliersIsSuccess: true,
        isLoadingPostOrderFormSuppliers: false,
        orderFormSuppliers: action.data,
      };

    case types.POST_SUBMITORDERFORM_ASYNC.PENDING:
      return {
        ...state,
        postSubmitOrderFormIsSuccess: false,
        isLoadingPostSubmitOrderForm: true,
        submitOrderForm: null,
      };

    case types.POST_SUBMITORDERFORM_ASYNC.ERROR:
      return {
        ...state,
        postSubmitOrderFormIsSuccess: false,
        isLoadingPostSubmitOrderForm: false,
        submitOrderForm: null,
        error: { ...state.error, postSubmitOrderFormError: action.error },
      };

    case types.POST_SUBMITORDERFORM_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitOrderFormIsSuccess: true,
        isLoadingPostSubmitOrderForm: false,
        submitOrderForm: action.data,
      };

    case types.PUT_APPROVEORDERFORM_ASYNC.PENDING:
      return {
        ...state,
        putApproveOrderFormIsSuccess: false,
        isLoadingPutApproveOrderForm: true,
        approveOrderForm: null,
      };

    case types.PUT_APPROVEORDERFORM_ASYNC.ERROR:
      return {
        ...state,
        putApproveOrderFormIsSuccess: false,
        isLoadingPutApproveOrderForm: false,
        approveOrderForm: null,
        error: { ...state.error, putApproveOrderFormError: action.error },
      };

    case types.PUT_APPROVEORDERFORM_ASYNC.SUCCESS:
      return {
        ...state,
        putApproveOrderFormIsSuccess: true,
        isLoadingPutApproveOrderForm: false,
        approveOrderForm: action.data,
      };

    case types.PUT_ORDERFORMEDITMODE_ASYNC.PENDING:
      return {
        ...state,
        putOrderFormEditModeIsSuccess: false,
        isLoadingPutOrderFormEditMode: true,
        orderFormEditMode: null,
      };

    case types.PUT_ORDERFORMEDITMODE_ASYNC.ERROR:
      return {
        ...state,
        putOrderFormEditModeIsSuccess: false,
        isLoadingPutOrderFormEditMode: false,
        orderFormEditMode: null,
        error: { ...state.error, putOrderFormEditModeError: action.error },
      };

    case types.PUT_ORDERFORMEDITMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putOrderFormEditModeIsSuccess: true,
        isLoadingPutOrderFormEditMode: false,
        orderFormEditMode: action.data,
      };

    case types.PUT_ORDERFORMCANCELLED_ASYNC.PENDING:
      return {
        ...state,
        putOrderFormCancelledIsSuccess: false,
        isLoadingPutOrderFormCancelled: true,
        orderFormCancelled: null,
      };

    case types.PUT_ORDERFORMCANCELLED_ASYNC.ERROR:
      return {
        ...state,
        putOrderFormCancelledIsSuccess: false,
        isLoadingPutOrderFormCancelled: false,
        orderFormCancelled: null,
        error: { ...state.error, putOrderFormCancelledError: action.error },
      };

    case types.PUT_ORDERFORMCANCELLED_ASYNC.SUCCESS:
      return {
        ...state,
        putOrderFormCancelledIsSuccess: true,
        isLoadingPutOrderFormCancelled: false,
        orderFormCancelled: action.data,
      };

    case types.PUT_ORDERFORMPRINTEDDATE_ASYNC.PENDING:
      return {
        ...state,
        putOrderFormPrintedDateIsSuccess: false,
        isLoadingPutOrderFormPrintedDate: true,
        orderFormPrintedDate: null,
      };

    case types.PUT_ORDERFORMPRINTEDDATE_ASYNC.ERROR:
      return {
        ...state,
        putOrderFormPrintedDateIsSuccess: false,
        isLoadingPutOrderFormPrintedDate: false,
        orderFormPrintedDate: null,
        error: { ...state.error, putOrderFormPrintedDateError: action.error },
      };

    case types.PUT_ORDERFORMPRINTEDDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putOrderFormPrintedDateIsSuccess: true,
        isLoadingPutOrderFormPrintedDate: false,
        orderFormPrintedDate: action.data,
      };
    default:
      return state;
  }
};
