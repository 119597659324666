import { createAsyncTypes } from "../_helper/Utilities";

export const GET_EMAILSTORERECIPIENTBYID_ASYNC = createAsyncTypes(
  "GET_EMAILSTORERECIPIENTBYID"
);

export const GET_EMAILSTORERECIPIENTLIST_ASYNC = createAsyncTypes(
  "GET_EMAILSTORERECIPIENTLIST"
);

export const POST_CREATEEMAILSTORERECIPIENT_ASYNC = createAsyncTypes(
  "POST_CREATEEMAILSTORERECIPIENT"
);

export const PUT_UPDATEEMAILSTORERECIPIENT_ASYNC = createAsyncTypes(
  "PUT_UPDATEEMAILSTORERECIPIENT"
);
