import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getNotificationGroupList,
  getNotificationGroupById,
  postCreateNotificationGroup,
  putUpdateNotificationGroup,
} from "../actions/notificationGroupActions";
import * as types from "../_constants/notificationGroupConstants";
import * as notification from "../_constants/notificationConstants";
import notificationGroupApis from "../_apis/notificationGroupApis";
export default function* notificationGroupSagas() {
  yield all([
    takeLatest(
      types.GET_NOTIFICATIONGROUPLIST_ASYNC.PENDING,
      getNotificationGroupListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_NOTIFICATIONGROUPBYID_ASYNC.PENDING,
      getNotificationGroupByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATENOTIFICATIONGROUP_ASYNC.PENDING,
      postCreateNotificationGroupCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATENOTIFICATIONGROUP_ASYNC.PENDING,
      putUpdateNotificationGroupCall
    ),
  ]);
}

function* getNotificationGroupListCall(searchModel) {
  try {
    const data = yield call(
      notificationGroupApis.getNotificationGroupListApi,
      searchModel.data
    );
    yield put(getNotificationGroupList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getNotificationGroupList.error(error.response?.data?.message));
    else
      yield put(getNotificationGroupList.error(notification.CONST_NOINTERNET));
  }
}

function* getNotificationGroupByIdCall(searchModel) {
  try {
    const data = yield call(
      notificationGroupApis.getNotificationGroupByIdApi,
      searchModel.data
    );
    yield put(getNotificationGroupById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getNotificationGroupById.error(error.response?.data?.message));
    else
      yield put(getNotificationGroupById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateNotificationGroupCall(searchModel) {
  try {
    const data = yield call(
      notificationGroupApis.postCreateNotificationGroupApi,
      searchModel.data
    );
    yield put(postCreateNotificationGroup.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postCreateNotificationGroup.error(error.response?.data?.message)
      );
    else
      yield put(
        postCreateNotificationGroup.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateNotificationGroupCall(searchModel) {
  try {
    const data = yield call(
      notificationGroupApis.putUpdateNotificationGroupApi,
      searchModel.data
    );
    yield put(putUpdateNotificationGroup.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateNotificationGroup.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdateNotificationGroup.error(notification.CONST_NOINTERNET)
      );
  }
}
