import * as types from "../_constants/formulaMedicineConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_FORMULAMEDICINEFORMULABYID_ASYNC.PENDING:
      return {
        ...state,
        getFormulaMedicineFormulaByIdIsSuccess: false,
        isLoadingGetFormulaMedicineFormulaById: true,
        getFormulaMedicineFormulaById: null,
      };

    case types.GET_FORMULAMEDICINEFORMULABYID_ASYNC.ERROR:
      return {
        ...state,
        getFormulaMedicineFormulaByIdIsSuccess: false,
        isLoadingGetFormulaMedicineFormulaById: false,
        getFormulaMedicineFormulaById: null,
        error: {
          ...state.error,
          getFormulaMedicineFormulaByIdError: action.error,
        },
      };

    case types.GET_FORMULAMEDICINEFORMULABYID_ASYNC.SUCCESS:
      return {
        ...state,
        getFormulaMedicineFormulaByIdIsSuccess: true,
        isLoadingGetFormulaMedicineFormulaById: false,
        getFormulaMedicineFormulaById: action.data,
      };

    case types.GET_FORMULAMEDICINEFORMULALIST_ASYNC.PENDING:
      return {
        ...state,
        getFormulaMedicineFormulaListIsSuccess: false,
        isLoadingGetFormulaMedicineFormulaList: true,
        getFormulaMedicineFormulaList: null,
      };

    case types.GET_FORMULAMEDICINEFORMULALIST_ASYNC.ERROR:
      return {
        ...state,
        getFormulaMedicineFormulaListIsSuccess: false,
        isLoadingGetFormulaMedicineFormulaList: false,
        getFormulaMedicineFormulaList: null,
        error: {
          ...state.error,
          getFormulaMedicineFormulaListError: action.error,
        },
      };

    case types.GET_FORMULAMEDICINEFORMULALIST_ASYNC.SUCCESS:
      return {
        ...state,
        getFormulaMedicineFormulaListIsSuccess: true,
        isLoadingGetFormulaMedicineFormulaList: false,
        getFormulaMedicineFormulaList: action.data,
      };

    case types.PUT_UPDATEFORMULAMEDICINEFORMULA_ASYNC.PENDING:
      return {
        ...state,
        putUpdateFormulaMedicineFormulaIsSuccess: false,
        isLoadingPutUpdateFormulaMedicineFormula: true,
        putUpdateFormulaMedicineFormula: null,
      };

    case types.PUT_UPDATEFORMULAMEDICINEFORMULA_ASYNC.ERROR:
      return {
        ...state,
        putUpdateFormulaMedicineFormulaIsSuccess: false,
        isLoadingPutUpdateFormulaMedicineFormula: false,
        putUpdateFormulaMedicineFormula: null,
        error: {
          ...state.error,
          putUpdateFormulaMedicineFormulaError: action.error,
        },
      };

    case types.PUT_UPDATEFORMULAMEDICINEFORMULA_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateFormulaMedicineFormulaIsSuccess: true,
        isLoadingPutUpdateFormulaMedicineFormula: false,
        putUpdateFormulaMedicineFormula: action.data,
      };

    case types.GET_FORMULAMEDICINEMEDICINELIST_ASYNC.PENDING:
      return {
        ...state,
        getFormulaMedicineMedicineListIsSuccess: false,
        isLoadingGetFormulaMedicineMedicineList: true,
        getFormulaMedicineMedicineList: null,
      };

    case types.GET_FORMULAMEDICINEMEDICINELIST_ASYNC.ERROR:
      return {
        ...state,
        getFormulaMedicineMedicineListIsSuccess: false,
        isLoadingGetFormulaMedicineMedicineList: false,
        getFormulaMedicineMedicineList: null,
        error: {
          ...state.error,
          getFormulaMedicineMedicineListError: action.error,
        },
      };

    case types.GET_FORMULAMEDICINEMEDICINELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getFormulaMedicineMedicineListIsSuccess: true,
        isLoadingGetFormulaMedicineMedicineList: false,
        getFormulaMedicineMedicineList: action.data,
      };

    default:
      return state;
  }
};
