import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/branchScheduleConstants";

export const getBranchScheduleList = {
  pending: (data) =>
    createAction(types.GET_BRANCHSCHEDULELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHSCHEDULELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHSCHEDULELIST_ASYNC.ERROR, { error }),
};

export const getBranchScheduleById = {
  pending: (data) =>
    createAction(types.GET_BRANCHSCHEDULEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHSCHEDULEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHSCHEDULEBYID_ASYNC.ERROR, { error }),
};

export const postBranchScheduleByBranchId = {
  pending: (data) =>
    createAction(types.POST_BRANCHSCHEDULEBYBRANCHID_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_BRANCHSCHEDULEBYBRANCHID_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_BRANCHSCHEDULEBYBRANCHID_ASYNC.ERROR, {
      error,
    }),
};

export const getBranchScheduleBranchList = {
  pending: (data) =>
    createAction(types.GET_BRANCHSCHEDULEBRANCHLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BRANCHSCHEDULEBRANCHLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BRANCHSCHEDULEBRANCHLIST_ASYNC.ERROR, { error }),
};
