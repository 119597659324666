import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/formulaMedicineConstants";

export const getFormulaMedicineFormulaById = {
  pending: (data) =>
    createAction(types.GET_FORMULAMEDICINEFORMULABYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_FORMULAMEDICINEFORMULABYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_FORMULAMEDICINEFORMULABYID_ASYNC.ERROR, { error }),
};

export const getFormulaMedicineFormulaList = {
  pending: (data) =>
    createAction(types.GET_FORMULAMEDICINEFORMULALIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_FORMULAMEDICINEFORMULALIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_FORMULAMEDICINEFORMULALIST_ASYNC.ERROR, { error }),
};

export const putUpdateFormulaMedicineFormula = {
  pending: (data) =>
    createAction(types.PUT_UPDATEFORMULAMEDICINEFORMULA_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_UPDATEFORMULAMEDICINEFORMULA_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_UPDATEFORMULAMEDICINEFORMULA_ASYNC.ERROR, { error }),
};

export const getFormulaMedicineMedicineList = {
  pending: (data) =>
    createAction(types.GET_FORMULAMEDICINEMEDICINELIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_FORMULAMEDICINEMEDICINELIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_FORMULAMEDICINEMEDICINELIST_ASYNC.ERROR, { error }),
};
