import { createAsyncTypes } from "../_helper/Utilities";

export const GET_ORDERFORMLIST_ASYNC = createAsyncTypes("GET_ORDERFORMLIST");

export const GET_ORDERFORMBYID_ASYNC = createAsyncTypes("GET_ORDERFORMBYID");

export const GET_ORDERFORMMEDICINETYPES_ASYNC = createAsyncTypes(
  "GET_ORDERFORMMEDICINETYPES"
);

export const POST_ORDERFORMMEDICINES_ASYNC = createAsyncTypes(
  "POST_ORDERFORMMEDICINES"
);

export const POST_ORDERFORMSUPPLIERS_ASYNC = createAsyncTypes(
  "POST_ORDERFORMSUPPLIERS"
);

export const POST_SUBMITORDERFORM_ASYNC = createAsyncTypes(
  "POST_SUBMITORDERFORM"
);

export const PUT_APPROVEORDERFORM_ASYNC = createAsyncTypes(
  "PUT_APPROVEORDERFORM"
);

export const PUT_ORDERFORMEDITMODE_ASYNC = createAsyncTypes(
  "PUT_ORDERFORMEDITMODE"
);

export const PUT_ORDERFORMCANCELLED_ASYNC = createAsyncTypes(
  "PUT_ORDERFORMCANCELLED"
);

export const PUT_ORDERFORMPRINTEDDATE_ASYNC = createAsyncTypes(
  "PUT_ORDERFORMPRINTEDDATE"
);
