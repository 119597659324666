import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const brewingApis = {
  putBrewingWIPModeApi,
  putBrewingPendingStatusApi,
  getBrewingListApi,
  getBrewingSummaryListApi,
  getBrewingByIdApi,
  getBrewingByOrderNumApi,
  postSubmitBrewingApi,
};

async function putBrewingWIPModeApi(model) {
  const url = apiConstants.apiPutBrewingWIPMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function putBrewingPendingStatusApi(model) {
  const url = apiConstants.apiPutBrewingPendingStatus + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBrewingListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetBrewingList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBrewingSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetBrewingSummaryList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBrewingByIdApi(model) {
  const url = apiConstants.apiGetBrewingById + model;

  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBrewingByOrderNumApi(model) {
  const url = apiConstants.apiGetBrewingByOrderNum + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitBrewingApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostSubmitBrewing, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default brewingApis;
