import React from "react";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useStyles } from "./globalStyles";
import TextField from "@mui/material/TextField";
//style

const ConfirmReloadDialog = (props) => {
  const classes = useStyles();
  const {
    ok,
    title,
    children,
    open,
    requiredText,
    textLabel,
    textValue,
    setTextValue,
  } = props;
  return (
    <Dialog open={open} aria-labelledby="confirm-dialog">
      <form>
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>
          {children}
          {requiredText && (
            <TextField
              value={textValue}
              required
              variant="outlined"
              margin="normal"
              name={textLabel}
              label={textLabel}
              onChange={(e) => setTextValue(e.target.value)}
              fullWidth
            />
          )}
        </DialogContent>
        <DialogActions>
          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              onClick={() => window.location.reload(true)}>
              {ok}
            </Button>
          </Link>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfirmReloadDialog;
