import { useCallback } from "react";
import { Button, Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./globalStyles";
import debounce from "debounce";

export default function PageAction({
  isSaveLoading = false,
  onSave = () => null,
  setView,
  submissionTitle = "",
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const onSaveDebounce = debounce(onSave, 2000, true);
  const onSaveDebounceCallBack = useCallback(onSaveDebounce, [onSaveDebounce]);
  return (
    <Grid item xs={12} className={classes.relativePositionWrapper}>
      <Grid item xs={12} className={classes.rightAbsolutePosition}>
        <Link className={classes.boldMaroon075}>
          <Button
            disabled={isSaveLoading}
            cy_data="saveButton"
            name="saveButton"
            className={classes.boldMaroon075}
            onClick={(event) => {
              onSaveDebounceCallBack(event);
            }}>
            {t("translation:SAVE")}
          </Button>
        </Link>
        <Link className={classes.boldMaroon075}>
          <Button
            cy_data="submitButton"
            disabled={isSaveLoading}
            className={classes.boldMaroon075}
            type="submit"
            name="submitButton"
            title={submissionTitle}>
            {t("translation:SUBMIT")}
          </Button>
        </Link>
        <Link className={classes.boldMaroon075}>
          <Button
            cy_data="backButton"
            name="backButton"
            className={classes.boldMaroon075}
            onClick={() => setView(true)}>
            {t("translation:BACK")}
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
