import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

import { ReactComponent as EditLogo } from "../../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as RemoveLogo } from "../../../../../assets/coolicons/basic/trash_empty.svg";
import * as generalConstants from "../../../../../_constants/generalConstants";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import NotificationDialog from "../../../../../components/NotificationDialog";
import ChangeBranchMedicine from "../../../../visit/Consultation/components/editOrder/ChangeBranchMedicine";
//style
import { useStyles } from "../../../../../components/globalStyles";

import { noAvailableColorStyle } from "../../../../../components/globalTableStyles";

export default function GroupOption({
  currentOrganistion,
  memoGroupOption,
  groupOption,
  setGroupOption,
  group,
  setGroup,
  memoSelectedGroupIndex,
  medicineList,
  setMedicineList,
  memoSelectedGroup,
  setShowBrewing,
  setShowDelivery,
  setIsRawHerb,
  setGroupId,
  groupId,
  viewOnly = false,
  getDispensaryBranchesAlliance,
  getMedicineTypesAlliance,
  postFormulasAlliance,
  postMedicineFormulasAlliance,
  postBranchMedicines,
  order,
  showMultiplier = false,
  physicianMultiplier,
  showChangeBranch = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = createFilterOptions();
  const { t } = useTranslation();

  const [confirmDeleteGroupOpen, setConfirmDeleteGroupOpen] = useState(false);
  const [confirmEditGroupNameOpen, setConfirmEditGroupNameOpen] =
    useState(false);
  const [disableBranchs, setDisableBranchs] = useState(true);
  const [disableMedicineTypes, setDisableMedicineTypes] = useState(true);
  const [disableBranchMedicines, setDisableBranchMedicines] = useState(true);
  const [editGroupNameOpen, setEditGroupNameOpen] = useState(false);
  const [selectedMedItem, setSelectedMedItem] = useState("");
  const [selectedFormula, setSelectedFormula] = useState("");
  const prevMedicineFormulasAllianceStatus = useRef();
  const prevbranchchoiceStatus = useRef();
  const prevDispensaryBranchAllianceStatus = useRef();
  const prevMedicineTypechoiceStatus = useRef();

  const [textValue, setTextValue] = useState("");

  // const medicineTypeExist = medicineList.some((item) => {
  //   return (
  //     item.branch_medicine.medicines.medicine_type?.status_type !==
  //     generalConstants.APPROVED_STATUS_TYPE
  //   );
  // });

  const dispensaryBranchExist =
    order?.dispensaryBranchesAlliance?.organisation_branch?.some((item) => {
      if (memoSelectedGroup?.organisation_branch?.id && !viewOnly) {
        return item.id === memoSelectedGroup?.organisation_branch?.id;
      } else {
        return true;
      }
    });

  const medicineTypeExist = order?.medicineTypesAlliance?.medicine_types?.some(
    (item) => {
      if (memoSelectedGroup?.medicine_type?.id && !viewOnly) {
        return item.id === memoSelectedGroup?.medicine_type?.id;
      } else {
        return true;
      }
    }
  );

  useEffect(() => {
    prevMedicineFormulasAllianceStatus.current = false;
    prevMedicineTypechoiceStatus.current = null;
    prevbranchchoiceStatus.current = null;
    prevDispensaryBranchAllianceStatus.current = false;
  }, []);

  useEffect(() => {
    if (!viewOnly) {
      dispatch(getDispensaryBranchesAlliance.pending());
    }
  }, [dispatch, viewOnly, getDispensaryBranchesAlliance]);

  useEffect(() => {
    if (
      order.isLoadingGetDispensaryBranchesAlliance !==
      prevDispensaryBranchAllianceStatus.current
    ) {
      //check the previous ref with current state
      prevDispensaryBranchAllianceStatus.current =
        order.isLoadingGetDispensaryBranchesAlliance;
      if (
        order.getDispensaryBranchesAllianceIsSuccess === true &&
        order.isLoadingGetDispensaryBranchesAlliance === false
      ) {
        if (
          !viewOnly &&
          memoSelectedGroup?.organisation_branch?.organisation?.id
        ) {
          dispatch(
            getMedicineTypesAlliance.pending(
              memoSelectedGroup.organisation_branch.organisation.id
            )
          );
        }
      }
    }
  }, [
    order.getDispensaryBranchesAllianceIsSuccess,
    order.isLoadingGetDispensaryBranchesAlliance,
    viewOnly,
    dispatch,
    getMedicineTypesAlliance,
    memoSelectedGroup,
  ]);

  useEffect(() => {
    if (
      order.isLoadingMedicineFormulasAlliance !==
      prevMedicineFormulasAllianceStatus.current
    ) {
      //check the previous ref with current state
      prevMedicineFormulasAllianceStatus.current =
        order.isLoadingMedicineFormulasAlliance;
      if (
        order.getMedicineFormulasAllianceIsSuccess === true &&
        order.isLoadingMedicineFormulasAlliance === false
      ) {
        const elementsIndex = memoSelectedGroupIndex;
        //make a copy of group option
        let oldArray = [...memoGroupOption];

        let storeMedicineDetail = oldArray[elementsIndex].store_medicine_details
          ? oldArray[elementsIndex].store_medicine_details
          : [];

        order.medicineFormulasAlliance.branch_medicines.map((option, index) => {
          const selectedstoreMedicine = {
            id: 0,
            // create quantity
            quantity_per_day: option.medicines.default_quantity_per_day,
            medicine_name: option.medicines.name,
            default_instruction: option.medicines.default_instruction,
            desc: option.medicines.desc,
            dosage: option.medicines.dosage,
            measure_unit: option.medicines.measure_unit,
            remarks: option.medicines.remarks,
            unit_selling_price: option.medicines.unit_selling_price,
            status_type: generalConstants.APPROVED_STATUS_TYPE,
            branch_medicine: option,
          };

          if (Object.keys(storeMedicineDetail).length !== 0) {
            //cant use id to search because it is null for no available medicine
            const repeatMedicine = storeMedicineDetail.some(
              (item) =>
                item.branch_medicine.medicines.id === option.medicines.id &&
                item.status_type === generalConstants.APPROVED_STATUS_TYPE
            );
            //find array that status_type === 7 (deleted)
            const repeatDeletedMedicineIndex = storeMedicineDetail.findIndex(
              (item) =>
                item.branch_medicine.medicines.id === option.medicines.id &&
                item.status_type === generalConstants.DELETED_STATUS_TYPE
            );

            if (repeatDeletedMedicineIndex !== -1) {
              storeMedicineDetail[repeatDeletedMedicineIndex] = {
                ...selectedstoreMedicine,
                //id: (use back existing)
                id: storeMedicineDetail[repeatDeletedMedicineIndex].id,
              };
            } else {
              if (!repeatMedicine) {
                storeMedicineDetail = [
                  ...storeMedicineDetail,
                  selectedstoreMedicine,
                ];
              }
            }
          } else {
            storeMedicineDetail = [selectedstoreMedicine];
          }
          return "";
        });
        let resultWDelete = storeMedicineDetail.filter(
          (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
        );
        let resultWoDelete = storeMedicineDetail.filter(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        );
        resultWDelete = resultWDelete.map((x, index) => {
          return { ...x, row_no: null };
        });
        resultWoDelete = resultWoDelete.map((x, index) => {
          return { ...x, row_no: index + 1 };
        });

        // setMedicineList(storeMedicineDetail);
        oldArray[elementsIndex] = {
          ...oldArray[elementsIndex],
          store_medicine_details: [...resultWoDelete, ...resultWDelete],
        };
        setGroupOption(oldArray);
      }
      // if (
      //   order.getMedicineFormulasAllianceIsSuccess === false &&
      //   order.isLoadingMedicineFormulasAlliance === false
      // ) {
      //   setSubmitErrorAlarm(true);
      //   setSubmitSuccessAlarm(false);
      //   setMsgAlarm(order.error.submitStoreOrderError);
      // }
    }
  }, [
    order.getMedicineFormulasAllianceIsSuccess,
    order.isLoadingMedicineFormulasAlliance,
    order.error,
    order.medicineFormulasAlliance,
    memoSelectedGroupIndex,
    memoGroupOption,
    setGroupOption,
    setMedicineList,
  ]);

  useEffect(() => {
    //dont display deleted item in table
    if (group && memoSelectedGroup) {
      const selectedGroupResults = memoSelectedGroup;

      if (selectedGroupResults.store_medicine_details) {
        const selectedstoreMedicine =
          selectedGroupResults.store_medicine_details.filter(
            (item) => item.status_type !== generalConstants.DELETED_STATUS_TYPE
          );

        setMedicineList(selectedstoreMedicine);
      } else {
        setMedicineList([]);
        setDisableBranchs(false);
      }
    } else {
      setDisableBranchs(true);
      setDisableMedicineTypes(true);
    }
  }, [group, memoSelectedGroup, setMedicineList]);

  useEffect(() => {
    if (memoSelectedGroup) {
      //disable selection of branch and medicine type when there is no group or there is medicine been selected
      if (
        memoSelectedGroup.organisation_branch !== null &&
        disableBranchs === false
      ) {
        if (!viewOnly) {
          dispatch(
            getMedicineTypesAlliance.pending(
              memoSelectedGroup.organisation_branch.organisation.id
            )
          );
        }

        setDisableMedicineTypes(false);
      } else {
        setDisableMedicineTypes(true);
      }
      if (
        memoSelectedGroup.organisation_branch === null ||
        memoSelectedGroup.medicine_type === null
      ) {
        setDisableBranchMedicines(true);

        setShowBrewing(false);
        setShowDelivery(false);
      } else {
        setDisableBranchMedicines(false);
        if (
          memoSelectedGroup.organisation_branch.branch.is_brewing_centre ===
            true &&
          memoSelectedGroup.medicine_type.is_for_brewing === true
        ) {
          setShowBrewing(true);
        } else {
          setShowBrewing(false);
        }
        if (memoSelectedGroup.medicine_type.is_for_brewing === true) {
          setIsRawHerb(true);
        } else {
          setIsRawHerb(false);
        }
        if (
          memoSelectedGroup.organisation_branch.branch.is_dispensary_centre ===
          true
        ) {
          setShowDelivery(true);
        } else {
          setShowDelivery(false);
        }
      }
    }
  }, [
    dispatch,
    viewOnly,
    memoSelectedGroup,
    disableBranchs,
    setIsRawHerb,
    setShowBrewing,
    setShowDelivery,
    getMedicineTypesAlliance,
  ]);

  useEffect(() => {
    if (memoSelectedGroup && !viewOnly) {
      if (
        prevMedicineTypechoiceStatus.current !==
          memoSelectedGroup.medicine_type ||
        prevbranchchoiceStatus.current !== memoSelectedGroup.organisation_branch
      ) {
        prevMedicineTypechoiceStatus.current = memoSelectedGroup.medicine_type;
        prevbranchchoiceStatus.current = memoSelectedGroup.organisation_branch;
        if (
          memoSelectedGroup.organisation_branch !== null &&
          memoSelectedGroup.medicine_type !== null
        ) {
          const choices = {
            medicine_type: memoSelectedGroup.medicine_type,
            organisation_branch: memoSelectedGroup.organisation_branch,
          };
          dispatch(postBranchMedicines.pending(choices));
          dispatch(postFormulasAlliance.pending(choices));
        }
      }
    }
  }, [
    dispatch,
    memoSelectedGroup,
    viewOnly,
    postBranchMedicines,
    postFormulasAlliance,
  ]);

  useEffect(() => {
    //disable selection of branch and medicine type when there is no group or there is medicine been selected
    if (Object.keys(medicineList).length !== 0 || !group) {
      setDisableMedicineTypes(true);
      setDisableBranchs(true);
    } else {
      setDisableBranchs(false);
    }
  }, [medicineList, group]);

  useEffect(() => {
    //disable medicine if there is no group
    if (!group) {
      setDisableBranchMedicines(true);
    }
  }, [group]);

  const handleGroupChange = (event, newValue) => {
    //
    //
    if (newValue !== null) {
      if (typeof newValue === "string") {
        //get current existing groups
        const currentGroup = memoGroupOption.some(
          (item) => item.name === newValue
        );
        //
        if (!currentGroup) {
          setGroupOption((groupOption) => [
            ...groupOption,
            {
              id: 0,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
              price: 0,
              name: newValue,
              medicine_type: null,
              organisation_branch: null,
              is_brewing_services: null,
              is_delivery_services: null,
              number_of_days: null,
              number_of_times: null,
              when_consume: null,
              dispensary_instructions: null,
              patient_instructions: null,
              brewing_instructions: null,
              delivery_postal_code: null,
              delivery_address_details: null,
              delivery_address: null,
              delivery_contact_number: null,
              delivery_name: null,
              delivery_remarks: null,
              delivery_incl_prescription: null,
              delivery_incl_receipt: null,
              multiplier: showMultiplier
                ? physicianMultiplier
                  ? physicianMultiplier
                  : currentOrganistion.organisation.multiplier
                : null,
              is_packs_override: null,
              no_of_packs: null,
            },
          ]);
        }

        setGroup(newValue);
        setGroupId(0);
      } else if (newValue.inputValue) {
        const repeatedNameIndex = groupOption.findIndex(
          (item) => item.name === newValue.inputValue
        );

        if (repeatedNameIndex !== -1) {
          let newArray = [...groupOption];
          newArray[repeatedNameIndex] = {
            ...newArray[repeatedNameIndex],
            status_type: generalConstants.APPROVED_STATUS_TYPE,
            price: 0,
            name: newValue.inputValue,
            medicine_type: null,
            organisation_branch: null,
            is_brewing_services: null,
            is_delivery_services: null,
            number_of_days: null,
            number_of_times: null,
            when_consume: null,
            dispensary_instructions: null,
            patient_instructions: null,
            brewing_instructions: null,
            delivery_postal_code: null,
            delivery_address_details: null,
            delivery_address: null,
            delivery_contact_number: null,
            delivery_name: null,
            delivery_remarks: null,
            delivery_incl_prescription: null,
            delivery_incl_receipt: null,
            multiplier: showMultiplier
              ? physicianMultiplier
                ? physicianMultiplier
                : currentOrganistion.organisation.multiplier
              : null,
            is_packs_override: null,
            no_of_packs: null,
          };
          setGroup(newValue.inputValue);
          setGroupId(newArray[repeatedNameIndex].id);

          setGroupOption(() => [...newArray]);
        } else {
          setGroupOption((groupOption) => [
            ...groupOption,
            {
              id: 0,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
              price: 0,
              name: newValue.inputValue,
              medicine_type: null,
              organisation_branch: null,
              is_brewing_services: null,
              is_delivery_services: null,
              number_of_days: null,
              number_of_times: null,
              when_consume: null,
              dispensary_instructions: null,
              patient_instructions: null,
              brewing_instructions: null,
              delivery_address: null,
              delivery_contact_number: null,
              delivery_name: null,
              delivery_remarks: null,
              delivery_incl_prescription: null,
              delivery_incl_receipt: null,
              multiplier: showMultiplier
                ? physicianMultiplier
                  ? physicianMultiplier
                  : currentOrganistion.organisation.multiplier
                : null,
            },
          ]);

          setGroup(newValue.inputValue);
          setGroupId(0);
        }
      } else {
        setGroup(newValue.name);
        setGroupId(newValue.id);
      }
    } else {
      setGroup(newValue);
      setGroupId(null);
    }
  };
  const handleSelectedBranchMedicine = (event, newValue) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let oldArray = [...memoGroupOption];

    const selectedstoreMedicine = {
      id: 0,
      // create quantity
      quantity_per_day: newValue.medicines.default_quantity_per_day,
      medicine_name: newValue.medicines.name,
      default_instruction: newValue.medicines.default_instruction,
      desc: newValue.medicines.desc,
      dosage: newValue.medicines.dosage,
      measure_unit: newValue.medicines.measure_unit,
      remarks: newValue.medicines.remarks,
      unit_selling_price: newValue.medicines.unit_selling_price,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
      branch_medicine: newValue,
    };
    let storeMedicineDetail;

    //if store_medicine_details is existing
    if (oldArray[elementsIndex].store_medicine_details) {
      //checking same medicine and dont allow same medicine exist in the same group
      const repeatMedicine = oldArray[
        elementsIndex
      ].store_medicine_details.some(
        (item) =>
          item.branch_medicine.medicines.name === newValue.medicines.name &&
          item.status_type === generalConstants.APPROVED_STATUS_TYPE
      );
      //find array that status_type === 7 (deleted) and it only can rely on ID because medicine name may change
      const repeatDeletedMedicineIndex = oldArray[
        elementsIndex
      ].store_medicine_details.findIndex(
        (item) =>
          item.branch_medicine.id === newValue.id &&
          item.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      if (repeatMedicine) {
        storeMedicineDetail = [
          ...oldArray[elementsIndex].store_medicine_details,
        ];
      } else if (repeatDeletedMedicineIndex !== -1) {
        storeMedicineDetail = [
          ...oldArray[elementsIndex].store_medicine_details,
        ];

        storeMedicineDetail[repeatDeletedMedicineIndex] = {
          ...storeMedicineDetail[repeatDeletedMedicineIndex],
          //id: (use back existing)
          // create quantity
          quantity_per_day: newValue.medicines.default_quantity_per_day,
          medicine_name: newValue.medicines.name,
          default_instruction: newValue.medicines.default_instruction,
          desc: newValue.medicines.desc,
          dosage: newValue.medicines.dosage,
          measure_unit: newValue.medicines.measure_unit,
          remarks: newValue.medicines.remarks,
          unit_selling_price: newValue.medicines.unit_selling_price,
          status_type: generalConstants.APPROVED_STATUS_TYPE,
          branch_medicine: newValue,
        };
        //move new item into last item
        storeMedicineDetail.push(
          storeMedicineDetail.splice(repeatDeletedMedicineIndex, 1)[0]
        );
      } else {
        storeMedicineDetail = [
          ...oldArray[elementsIndex].store_medicine_details,
          selectedstoreMedicine,
        ];
      }
    } else {
      storeMedicineDetail = [selectedstoreMedicine];
    }

    let resultWDelete = storeMedicineDetail.filter(
      (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
    );
    let resultWoDelete = storeMedicineDetail.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
    resultWDelete = resultWDelete.map((x, index) => {
      return { ...x, row_no: null };
    });

    resultWoDelete = resultWoDelete.map((x, index) => {
      return { ...x, row_no: index + 1 };
    });

    // setMedicineList(storeMedicineDetail);
    oldArray[elementsIndex] = {
      ...oldArray[elementsIndex],
      store_medicine_details: [...resultWoDelete, ...resultWDelete],
    };

    setGroupOption(oldArray);
  };

  const handleSelectedFormulas = (event, newValue) => {
    const formula = {
      id: newValue.id,
      medicine_type: memoSelectedGroup.medicine_type,
      organisation_branch: memoSelectedGroup.organisation_branch,
    };

    dispatch(postMedicineFormulasAlliance.pending(formula));
  };

  const handleTriggerEditNameWindow = (value) => {
    setConfirmEditGroupNameOpen(value);
    setTextValue("");
  };

  const handleRemoveGroup = () => {
    //
    if (groupId > 0) {
      const elementsIndex = memoSelectedGroupIndex;
      //make a copy of group option
      let newArray = [...memoGroupOption];

      const deleteMedicineWONewItem = newArray[
        elementsIndex
      ].store_medicine_details.filter((item) => item.id !== 0);
      const deleteMedicine = deleteMedicineWONewItem.map((item) => {
        var temp = Object.assign({}, item);
        if (temp.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          temp.status_type = generalConstants.DELETED_STATUS_TYPE;
        }
        return temp;
      });

      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        status_type: generalConstants.DELETED_STATUS_TYPE,
        delivery_owned: newArray[elementsIndex].delivery_owned
          ? {
              id: newArray[elementsIndex].delivery_owned.id,
              status_type: generalConstants.DELETED_STATUS_TYPE,
            }
          : null,
        // price: 0,
        // medicine_type: null,
        // organisation_branch: null,
        // is_brewing_services: null,
        // is_delivery_services: null,
        // number_of_days: null,
        // number_of_times: null,
        // when_consume: null,
        // dispensary_instructions: null,
        // patient_instructions: null,
        // brewing_instructions: null,
        // delivery_postal_code: null,
        // delivery_address_details: null,
        // delivery_address: null,
        // delivery_contact_number: null,
        // delivery_name: null,
        // delivery_remarks: null,
        // delivery_incl_prescription: null,
        // delivery_incl_receipt: null,
        store_medicine_details: deleteMedicine,
      };

      setGroupOption(newArray);
      setGroup("");
      setGroupId(null);
      setMedicineList([]);
    } else {
      const deletedGroupIndex = memoGroupOption.findIndex(
        (item) => item.name === group && item.id === 0
      );

      const result = memoGroupOption.filter(
        (item, index) => index !== deletedGroupIndex
      );

      setGroupOption(result);
      setGroup("");
      setGroupId(null);
      setMedicineList([]);
    }
  };

  const handleEditGroupName = (value) => {
    //

    const trimValue = value.trim();

    const repeatedName = groupOption.some((item) => item.name === trimValue);

    if (repeatedName || trimValue === "") {
      setConfirmEditGroupNameOpen(true);
      setEditGroupNameOpen(true);
    } else {
      //no new group
      if (groupId > 0) {
        const elementsIndex = groupOption.findIndex(
          (item) => item.name === group && item.id === groupId
        );
        //make a copy of group option
        let newArray = [...groupOption];
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          name: trimValue,
        };
        setGroupOption(newArray);
        setGroup(trimValue);
        setGroupId(groupId);
      } else {
        const elementsIndex = groupOption.findIndex(
          (item) => item.name === group && item.id === 0
        );
        //make a copy of group option
        let newArray = [...groupOption];
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          name: trimValue,
        };
        setGroupOption(newArray);
        setGroup(trimValue);
        setGroupId(0);
      }
    }
  };

  const handleSetOrganisationBranchChoice = (event, option) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      organisation_branch: option ? option : null,
      medicine_type: null,
    };

    setGroupOption(newArray);
  };

  const handleSetMedicineTypeChoice = (event, option) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      medicine_type: option ? option : null,
      is_brewing_services: false,
      is_delivery_services: false,
      // water_amount: null,
      // brewing_instructions: null,

      // delivery_address: null,
      // delivery_address_details: null,
      // delivery_postal_code: null,
      // delivery_contact_number: null,
      // delivery_name: null,
      // delivery_remarks: null,
      // delivery_incl_prescription: false,
    };

    setGroupOption(newArray);
  };

  return (
    <Grid container>
      <NotificationDialog
        ok={t("translation:Ok")}
        title={t("translation:Error")}
        open={editGroupNameOpen}
        setOpen={setEditGroupNameOpen}>
        {t("translation:Please use a different name")}
      </NotificationDialog>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Delete Group")}
        open={confirmDeleteGroupOpen}
        setOpen={setConfirmDeleteGroupOpen}
        onConfirm={handleRemoveGroup}>
        {t("translation:Are you sure you want to delete this group?")}
      </ConfirmDialog>

      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Edit Group Name")}
        open={confirmEditGroupNameOpen}
        setOpen={handleTriggerEditNameWindow}
        onConfirm={handleEditGroupName}
        requiredText={true}
        textLabel={t("translation:Group")}
        textValue={textValue}
        setTextValue={setTextValue}>
        {t("translation:Are you sure you want to edit this group name?")}
      </ConfirmDialog>
      <Grid item xs={6} className={classes.userInfoField}>
        <Autocomplete
          onChange={handleGroupChange}
          filterOptions={(options, params) => {
            let filtered = filter(options, params);
            let trimValue = null;
            if (params.inputValue) {
              trimValue = params.inputValue.trim();

              params.inputValue = trimValue;
              filtered = filter(options, params);
            }
            // Suggest the creation of a new value
            //search any contain of input
            const group = options.some(
              (item) =>
                item.name === trimValue &&
                item.status_type === generalConstants.APPROVED_STATUS_TYPE
            );
            if (!viewOnly && !group) {
              if (trimValue) {
                filtered.push({
                  inputValue: trimValue,
                  name: `${t("translation:Add")} "${trimValue}"`,
                });
              }
            }

            const result = filtered.filter(
              (item) =>
                item.status_type !== generalConstants.DELETED_STATUS_TYPE
            );

            return result;
          }}
          value={group}
          blurOnSelect
          selectOnFocus
          clearOnBlur={true}
          handleHomeEndKeys
          disableClearable
          id="Group"
          name="Group"
          options={groupOption}
          getOptionLabel={(option, value) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            if (option.name) {
              return option.name;
            }
            return "";
          }}
          isOptionEqualToValue={(option, value) => option.name === value}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.name}>
                {option.name}
              </li>
            );
          }}
          style={{ width: "100%" }}
          freeSolo
          renderInput={(params) => (
            <TextField
              className={classes.userFieldColor}
              {...params}
              label={t("translation:Group")}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {!viewOnly && group && (
                      <InputAdornment position="end">
                        <Button
                          onClick={() => setConfirmEditGroupNameOpen(true)}>
                          <EditLogo title={t("translation:Edit")} />
                        </Button>
                        <Button onClick={() => setConfirmDeleteGroupOpen(true)}>
                          <RemoveLogo />
                        </Button>
                      </InputAdornment>
                    )}

                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={3} className={classes.userInfoField}>
        <Autocomplete
          disabled={viewOnly ? viewOnly : disableBranchs}
          value={
            memoSelectedGroup ? memoSelectedGroup.organisation_branch : null
          }
          disableClearable
          name="branch"
          id="branch"
          options={
            order.dispensaryBranchesAlliance &&
            Object.keys(order.dispensaryBranchesAlliance.organisation_branch)
              .length !== 0
              ? order.dispensaryBranchesAlliance.organisation_branch
              : []
          }
          getOptionLabel={(option) => (option.branch ? option.branch.name : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          groupBy={(option) => option.organisation?.name}
          style={{ width: "100%" }}
          onChange={(e, option) => handleSetOrganisationBranchChoice(e, option)}
          renderInput={(params) => (
            <TextField
              className={classes.userFieldColor}
              {...params}
              InputProps={
                dispensaryBranchExist !== false
                  ? { ...params.InputProps }
                  : { ...params.InputProps, style: noAvailableColorStyle }
              }
              label={t("translation:Dispensary Branch")}
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={3} className={classes.userInfoField}>
        <Autocomplete
          disabled={viewOnly ? viewOnly : disableMedicineTypes}
          value={memoSelectedGroup ? memoSelectedGroup.medicine_type : null}
          disableClearable
          name="medicineType"
          id="medicineType"
          options={
            order.medicineTypesAlliance
              ? order.medicineTypesAlliance.medicine_types
              : []
          }
          getOptionLabel={(option) => (option.name ? option.name : "")}
          style={{ width: "100%" }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, option) => handleSetMedicineTypeChoice(e, option)}
          renderInput={(params) => (
            <TextField
              className={classes.userFieldColor}
              {...params}
              InputProps={
                medicineTypeExist !== false
                  ? { ...params.InputProps }
                  : { ...params.InputProps, style: noAvailableColorStyle }
              }
              label={t("translation:Medicine Type")}
              variant="outlined"
            />
          )}
        />
      </Grid>

      {order.formulasAlliance &&
      Object.keys(order.formulasAlliance.formulas).length !== 0
        ? !viewOnly && (
            <>
              <Grid item xs={6} className={classes.userInfoField}>
                <Autocomplete
                  disabled={disableBranchMedicines}
                  inputValue={selectedMedItem}
                  value={selectedMedItem === null ? "" : selectedMedItem}
                  name="branchMedicines"
                  id="branchMedicines"
                  options={
                    order.branchMedicines
                      ? order.branchMedicines.branch_medicines
                      : []
                  }
                  getOptionLabel={(option) =>
                    option.medicines ? option.medicines.name : ""
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={() => {
                    return true;
                  }}
                  disableClearable
                  onChange={(e, option) => {
                    handleSelectedBranchMedicine(e, option);
                    setSelectedMedItem("");
                  }}
                  disablePortal
                  sx={{
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                      '&[aria-selected="true"]': {
                        backgroundColor: "rgba(255,255,255)",
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //clear the field to empty
                      onChange={(event) => {
                        setSelectedMedItem(event.target.value);
                      }}
                      className={classes.userFieldColor}
                      variant="outlined"
                      label={<span>{t("translation:Medicine")}</span>}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} className={classes.userInfoField}>
                <Autocomplete
                  disabled={disableBranchMedicines}
                  inputValue={selectedFormula}
                  value={selectedFormula === null ? "" : selectedFormula}
                  name="formulas"
                  id="formulas"
                  options={
                    order.formulasAlliance
                      ? order.formulasAlliance.formulas
                      : []
                  }
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  groupBy={(option) => option.organisation?.name}
                  style={{ width: "100%" }}
                  isOptionEqualToValue={() => {
                    return true;
                  }}
                  disableClearable
                  onChange={(e, option) => {
                    handleSelectedFormulas(e, option);
                    setSelectedFormula("");
                  }}
                  disablePortal
                  sx={{
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                      '&[aria-selected="true"]': {
                        backgroundColor: "rgba(255,255,255)",
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //clear the field to empty
                      onChange={(event) => {
                        setSelectedFormula(event.target.value);
                      }}
                      className={classes.userFieldColor}
                      label={<span>{t("translation:Formulas")}</span>}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </>
          )
        : !viewOnly && (
            <Grid item xs={12} className={classes.userInfoField}>
              <Autocomplete
                disabled={disableBranchMedicines}
                inputValue={selectedMedItem}
                value={selectedMedItem === null ? "" : selectedMedItem}
                name="branchMedicines"
                id="branchMedicines"
                options={
                  order.branchMedicines
                    ? order.branchMedicines.branch_medicines
                    : []
                }
                getOptionLabel={(option) =>
                  option.medicines ? option.medicines.name : ""
                }
                style={{ width: "100%" }}
                isOptionEqualToValue={() => {
                  return true;
                }}
                disableClearable
                onChange={(e, option) => {
                  handleSelectedBranchMedicine(e, option);
                  setSelectedMedItem("");
                }}
                disablePortal
                sx={{
                  "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                    '&[aria-selected="true"]': {
                      backgroundColor: "rgba(255,255,255)",
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //clear the field to empty
                    onChange={(event) => {
                      setSelectedMedItem(event.target.value);
                    }}
                    className={classes.userFieldColor}
                    label={<span>{t("translation:Medicine")}</span>}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end">
        <Grid xs={3} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            value={
              groupOption.filter(
                (item) =>
                  item.status_type !== generalConstants.DELETED_STATUS_TYPE
              ).length
                ? groupOption.filter(
                    (item) =>
                      item.status_type !== generalConstants.DELETED_STATUS_TYPE
                  ).length
                : 0
            }
            disabled
            name="totalofgroup"
            label={t("translation:Total Groups")}
            variant="outlined"
            inputProps={{
              style: { textAlign: "right" },
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
          />
        </Grid>
      </Grid>
      {showChangeBranch &&
        !viewOnly &&
        disableBranchs &&
        disableMedicineTypes &&
        !disableBranchMedicines && (
          <ChangeBranchMedicine
            order={order}
            memoSelectedGroup={memoSelectedGroup}
            getMedicineTypesAlliance={getMedicineTypesAlliance}
            setGroupOption={setGroupOption}
            memoSelectedGroupIndex={memoSelectedGroupIndex}
            memoGroupOption={memoGroupOption}
          />
        )}
    </Grid>
  );
}
