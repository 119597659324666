//lib
import React from "react";
import { Button } from "@mui/material";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useStyles } from "../../../../components/globalTableStyles";

//components
import { ReactComponent as DeleteIcon } from "../../../../assets/coolicons/basic/trash_empty.svg";
import organisationPriceRounding from "../../../../components/functions/organisationPriceRounding";
import EditableCell from "../../../../components/EditableCell";
import * as generalConstants from "../../../../_constants/generalConstants";

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({
  columns,
  data,
  updateMyData,
  disablePageResetOnDataChange,
  t,
}) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        updateMyData,
        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();

  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    <TableCell className={paginationClasses.narrowCellStyle}>
                      {i + 1}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={paginationClasses.narrowCellStyle}
                          {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: cell.column.editable,
                            editableNum: cell.column.editableNum,
                            editableWholeNum: cell.column.editableWholeNum,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const MedicineListTable = ({
  details_price_rmt = generalConstants.ROUND_OFF_RMT,
  details_price_rpvt = generalConstants.HUNDREDTH_RPVT,
  viewOnly = false,
  data = [],
  setData,
  t,
}) => {
  const handleRowDel = (rowIndex, action) => {
    setData(rowIndex, action);
  };

  const columns = [
    {
      id: "Delete",
      textAlign: "left",
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDel(row.index, "delete")}>
            <DeleteIcon />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Medicine"),
      accessor: "medicine.name",
    },
    {
      editableNum: true,
      Header: t("translation:Package Content"),
      accessor: "package_content",
    },
    {
      editableNum: true,
      Header: t("translation:Unit Conversion"),
      accessor: "unit_conversion",
    },
    {
      editableNum: true,
      Header: t("translation:Quantity"),
      accessor: "quantity",
    },
    {
      editableNum: true,
      Header: t("translation:Price"),
      accessor: "price",
    },
    {
      Header: t("translation:Subtotal"),
      Cell: ({ row }) => {
        if (!row.original.quantity || !row.original.price) {
          const subTotalRound = organisationPriceRounding(
            0,
            details_price_rmt,
            details_price_rpvt
          );
          return <>{subTotalRound}</>;
        } else {
          const subTotalRound = organisationPriceRounding(
            row.original.sub_total,
            details_price_rmt,
            details_price_rpvt
          );
          return <>{subTotalRound}</>;
        }
      },
    },
  ];

  const columnsViewOnly = [
    {
      Header: t("translation:Medicne"),
      accessor: "medicine_name",
    },
    {
      Header: t("translation:Package Content"),
      accessor: "package_content",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? Number(cell.value).toFixed(4)
          : "";
      },
    },
    {
      Header: t("translation:Unit Conversion"),
      accessor: "unit_conversion",

      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? Number(cell.value).toFixed(4)
          : "";
      },
    },
    {
      Header: t("translation:Quantity"),
      accessor: "quantity",
      Cell: ({ cell }) => {
        return <>{cell.value ? Number(cell.value).toFixed(2) : ""}</>;
      },
    },
    {
      Header: t("translation:Price"),
      accessor: "price",
      Cell: ({ cell }) => {
        return <>{cell.value ? Number(cell.value).toFixed(4) : ""}</>;
      },
    },
    {
      Header: t("translation:Subtotal"),
      accessor: "sub_total",
      Cell: ({ cell }) => {
        return <>{cell.value ? Number(cell.value).toFixed(2) : ""}</>;
      },
    },
  ];

  return (
    <Table
      columns={viewOnly ? columnsViewOnly : columns}
      data={data}
      updateMyData={setData}
      t={t}
    />
  );
};

export default MedicineListTable;
