import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import UserCreditSummaryPage from "./UserCreditSummary/UserCreditSummaryPage";
export default function UserCreditPage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/userCredit"
        to="/userCredit/UserCreditSummary"
      />
      <Route
        path="/userCredit/UserCreditSummary"
        component={UserCreditSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
