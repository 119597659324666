import { combineReducers } from "redux";

import { withReduxStateSync } from "redux-state-sync";

//import * as auth from "./auth.reducer";

import * as common from "./common.reducer";

import * as userAuth from "./userAuth.reducer";

import * as user from "./user.reducer";

import * as userRole from "./userRole.reducer";

import * as role from "./role.reducer";

import * as roleRight from "./roleRight.reducer";

import * as curUser from "./curUser.reducer";

import * as workOrder from "./workOrder.reducer";

import * as dispensary from "./dispensary.reducer";

import * as brewing from "./brewing.reducer";

import * as visitOrder from "./visitOrder.reducer";

import * as delivery from "./delivery.reducer";

import * as deliveryOrder from "./deliveryOrder.reducer";

import * as medicineType from "./medicineType.reducer";
import * as appointment from "./appointment.reducer";

import * as dashboard from "./dashboard.reducer";

import * as branchAdhocSchedule from "./branchAdhocSchedule.reducer";
import * as branchPhysicianAdhocSchedule from "./branchPhysicianAdhocSchedule.reducer";
import * as branchPhysicianSchedule from "./branchPhysicianSchedule.reducer";
import * as branchSchedule from "./branchSchedule.reducer";

import * as invoice from "./invoice.reducer";

import * as invoiceMedicineCosting from "./invoiceMedicineCosting.reducer";

import * as userCredit from "./userCredit.reducer";

import * as orderForm from "./orderForm.reducer";

import * as purchaseOrder from "./purchaseOrder.reducer";

import * as branchMedicine from "./branchMedicine.reducer";

import * as homecare from "./homecare.reducer";

import * as reporting from "./reporting.reducer";

import * as prescriptionMsg from "./prescriptionMsg.reducer";

import * as systemSchedule from "./systemSchedule.reducer";

import * as medicine from "./medicine.reducer";

import * as acupoint from "./acupoint.reducer";

import * as treatment from "./treatment.reducer";

import * as treatmentType from "./treatmentType.reducer";

import * as cloudStorage from "./cloudStorage.reducer";

import * as organisationPhysician from "./organisationPhysician.reducer";

import * as formula from "./formula.reducer";

import * as formulaMedicine from "./formulaMedicine.reducer";

import * as userMfa from "./userMfa.reducer";

import * as postalCodeSurcharge from "./postalCodeSurcharge.reducer";

import * as notification from "./notification.reducer";

import * as notificationGroup from "./notificationGroup.reducer";

import * as filterRule from "./filterRule.reducer";

import * as emailStoreAccount from "./emailStoreAccount.reducer";

import * as emailStoreSender from "./emailStoreSender.reducer";

import * as emailStoreRecipient from "./emailStoreRecipient.reducer";

import * as emailStore from "./emailStore.reducer";

import * as medicineFixedSellingPrice from "./medicineFixedSellingPrice.reducer";

import * as medicineFixedSellingPriceDetails from "./medicineFixedSellingPriceDetails.reducer";

import * as userMedicineFixedSellingPrice from "./userMedicineFixedSellingPrice.reducer";

import * as wikiData from "./wikiData.reducer";

import * as fulfillment from "./fulfillment.reducer";

import * as packing from "./packing.reducer";

import * as nonBusinessDay from "./nonBusinessDay.reducer";

import * as externalApi from "./externalApi.reducer";

import * as deliveryCutoffSchedule from "./deliveryCutoffSchedule.reducer";

import * as deliveryTracking from "./deliveryTracking.reducer";

import * as courier from "./courier.reducer";

import * as branchCourier from "./branchCourier.reducer";

import * as userDeviceKey from "./userDeviceKey.reducer";

const rootReducer = combineReducers({
  //auth: auth.reducer,

  common: common.reducer,
  userAuth: userAuth.reducer,
  user: user.reducer,
  curUser: curUser.reducer,
  userRole: userRole.reducer,
  role: role.reducer,
  roleRight: roleRight.reducer,
  workOrder: workOrder.reducer,
  dispensary: dispensary.reducer,
  brewing: brewing.reducer,
  visitOrder: visitOrder.reducer,
  delivery: delivery.reducer,
  deliveryOrder: deliveryOrder.reducer,
  medicineType: medicineType.reducer,

  appointment: appointment.reducer,

  dashboard: dashboard.reducer,

  branchAdhocSchedule: branchAdhocSchedule.reducer,
  branchPhysicianAdhocSchedule: branchPhysicianAdhocSchedule.reducer,
  branchPhysicianSchedule: branchPhysicianSchedule.reducer,
  branchSchedule: branchSchedule.reducer,
  invoice: invoice.reducer,
  invoiceMedicineCosting: invoiceMedicineCosting.reducer,

  userCredit: userCredit.reducer,

  orderForm: orderForm.reducer,

  purchaseOrder: purchaseOrder.reducer,

  branchMedicine: branchMedicine.reducer,

  homecare: homecare.reducer,

  reporting: reporting.reducer,
  prescriptionMsg: prescriptionMsg.reducer,
  systemSchedule: systemSchedule.reducer,
  medicine: medicine.reducer,
  acupoint: acupoint.reducer,

  treatment: treatment.reducer,

  treatmentType: treatmentType.reducer,

  cloudStorage: cloudStorage.reducer,

  organisationPhysician: organisationPhysician.reducer,

  formula: formula.reducer,

  formulaMedicine: formulaMedicine.reducer,

  userMfa: userMfa.reducer,

  postalCodeSurcharge: postalCodeSurcharge.reducer,

  notification: notification.reducer,

  notificationGroup: notificationGroup.reducer,

  filterRule: filterRule.reducer,

  emailStoreAccount: emailStoreAccount.reducer,

  emailStoreSender: emailStoreSender.reducer,

  emailStoreRecipient: emailStoreRecipient.reducer,

  emailStore: emailStore.reducer,

  medicineFixedSellingPrice: medicineFixedSellingPrice.reducer,

  medicineFixedSellingPriceDetails: medicineFixedSellingPriceDetails.reducer,

  userMedicineFixedSellingPrice: userMedicineFixedSellingPrice.reducer,

  wikiData: wikiData.reducer,

  fulfillment: fulfillment.reducer,

  packing: packing.reducer,

  nonBusinessDay: nonBusinessDay.reducer,

  externalApi: externalApi.reducer,

  deliveryCutoffSchedule: deliveryCutoffSchedule.reducer,

  deliveryTracking: deliveryTracking.reducer,

  courier: courier.reducer,

  branchCourier: branchCourier.reducer,

  userDeviceKey: userDeviceKey.reducer,
});

export default withReduxStateSync(rootReducer);
