import organisationPriceRoundingWGst from "../../../../../components/functions/organisationPriceRoundingWGst";
import * as generalConstants from "../../../../../_constants/generalConstants";
const submisionJSON = (
  editOrderInfo,
  currentOrganistion,
  price,
  groupOption,
  costingHeader,
  desc
) => {
  const organisationPriceRoundingWGsts = organisationPriceRoundingWGst(
    Number(price.medicineTab) +
      Number(price.deliveryFee) +
      Number(price.brewingFee) +
      Number(price.vendorGstAmt),
    currentOrganistion
  );

  // storeHeaderCheck update the unit_selling_price and dosage
  const storeHeaderCheck = groupOption.map((groupOptionItem) => {
    let store_medicine_detailsList =
      groupOptionItem?.store_medicine_details?.map(
        (store_medicine_detailsItem) => {
          let medicineList = store_medicine_detailsItem;
          delete medicineList.sub_total;
          medicineList = {
            ...medicineList,
            dosage: medicineList.branch_medicine.medicines.dosage,
            unit_selling_price:
              medicineList.branch_medicine.medicines.unit_selling_price,
          };
          return medicineList;
        }
      );

    return {
      ...groupOptionItem,
      store_medicine_details: store_medicine_detailsList
        ? store_medicine_detailsList
        : [],
    };
  });
  const submission = {
    store_header: {
      //id and order_number is a must
      order_number: editOrderInfo.order_number,
      id: editOrderInfo.id,
      ...organisationPriceRoundingWGsts,
      desc: desc,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
      medicine_total_price: Number(price.medicineTab),
      medicine_price_for_costing: Number(price.medicineFee),
      brewing_total_price_for_costing: Number(price.brewingFee),
      delivery_total_price_for_costing: Number(price.deliveryFee),
      vendor_total_gst_amount_for_costing: Number(price.vendorGstAmt),
      is_consultation_price_included: false,
      staff: {
        id: editOrderInfo.staff.id,
        status_type: 1,
      },

      store_medicine_headers: storeHeaderCheck,
      store_medicine_costing_headers: costingHeader,
    },
  };
  return submission;
};

export default submisionJSON;
