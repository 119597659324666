import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/roleConstants";

export const getAllRoleList = {
  pending: (data) =>
    createAction(types.GET_ALLROLELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ALLROLELIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_ALLROLELIST_ASYNC.ERROR, { error }),
};

export const getRoleList = {
  pending: (data) => createAction(types.GET_ROLELIST_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.GET_ROLELIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_ROLELIST_ASYNC.ERROR, { error }),
};

export const getRoleById = {
  pending: (data) => createAction(types.GET_ROLEBYID_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.GET_ROLEBYID_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_ROLEBYID_ASYNC.ERROR, { error }),
};

export const postCreateRole = {
  pending: (data) =>
    createAction(types.POST_CREATEROLE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEROLE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.POST_CREATEROLE_ASYNC.ERROR, { error }),
};

export const putUpdateRole = {
  pending: (data) => createAction(types.PUT_UPDATEROLE_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.PUT_UPDATEROLE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.PUT_UPDATEROLE_ASYNC.ERROR, { error }),
};
