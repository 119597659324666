//lib
import React from "react";
import Dialog from "@mui/material/Dialog";
import Close from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import AppointmentListTable from "../../appointment/AppointmentSummary/AppointmentListTable";
//style//style
export default function AppointmentDialog({ open, setOpen, appointmentNo }) {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setOpen(false);
        }
      }}
      aria-labelledby="confirm-dialog"
      fullWidth
      maxWidth="xl">
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => setOpen(false)}
      />
      <DialogContent>
        <AppointmentListTable
          appointmentNo={appointmentNo}
          disabledTableSearch={true}
        />
      </DialogContent>
    </Dialog>
  );
}
