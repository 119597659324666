export const headerWithoutauth = {
  headers: {
    "Content-Type": "application/json",
  },
};

//httpOnly  token from cookie
export const headerWithauth = {
  headers: {
    "Content-Type": "application/json",

    withCredentials: true,
  },
};

export const headerWithauthBlob = {
  headers: {
    "Content-Type": "application/json",

    withCredentials: true,
    responseType: "blob",
  },
};

export const headerWithauthFormData = {
  headers: {
    "Content-Type": "multipart/form-data",
    withCredentials: true,
  },
};

export const headerAllowOrigin = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

export const headerAllowAllOrigin = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
    "Access-Control-Allow-Headers":
      "X-Requested-With, content-type, Authorization",
  },
};
