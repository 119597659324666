import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/organisationPhysicianConstants";

export const getOrganisationPhysicianById = {
  pending: (data) =>
    createAction(types.GET_ORGANISATIONPHYSICIANBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ORGANISATIONPHYSICIANBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ORGANISATIONPHYSICIANBYID_ASYNC.ERROR, { error }),
};

export const getOrganisationPhysicianList = {
  pending: (data) =>
    createAction(types.GET_ORGANISATIONPHYSICIANLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ORGANISATIONPHYSICIANLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ORGANISATIONPHYSICIANLIST_ASYNC.ERROR, { error }),
};

export const putUpdateOrganisationPhysician = {
  pending: (data) =>
    createAction(types.PUT_UPDATEORGANISATIONPHYSICIAN_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEORGANISATIONPHYSICIAN_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEORGANISATIONPHYSICIAN_ASYNC.ERROR, { error }),
};

export const getOrganisationPhysicianUserList = {
  pending: (data) =>
    createAction(types.GET_ORGANISATIONPHYSICIANUSERLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_ORGANISATIONPHYSICIANUSERLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_ORGANISATIONPHYSICIANUSERLIST_ASYNC.ERROR, {
      error,
    }),
};
