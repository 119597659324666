import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/treatmentTypeConstants";

export const getTreatmentTypeById = {
  pending: (data) =>
    createAction(types.GET_TREATMENTTYPEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_TREATMENTTYPEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_TREATMENTTYPEBYID_ASYNC.ERROR, { error }),
};

export const getTreatmentTypeList = {
  pending: (data) =>
    createAction(types.GET_TREATMENTTYPELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_TREATMENTTYPELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_TREATMENTTYPELIST_ASYNC.ERROR, { error }),
};

export const postCreateTreatmentType = {
  pending: (data) =>
    createAction(types.POST_CREATETREATMENTTYPE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATETREATMENTTYPE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATETREATMENTTYPE_ASYNC.ERROR, { error }),
};

export const putUpdateTreatmentType = {
  pending: (data) =>
    createAction(types.PUT_UPDATETREATMENTTYPE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATETREATMENTTYPE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATETREATMENTTYPE_ASYNC.ERROR, { error }),
};
