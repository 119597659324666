import { createAsyncTypes } from "../_helper/Utilities";

export const GET_ALLVISITBYLASTMODIFIED_ASYNC = createAsyncTypes(
  "GET_ALLVISITBYLASTMODIFIED"
);

export const GET_VERIFYMEDICINE_ASYNC = createAsyncTypes("GET_VERIFYMEDICINE");

export const POST_UPDATEHOMECAREMEDICINE_ASYNC = createAsyncTypes(
  "POST_UPDATEHOMECAREMEDICINE"
);

export const GET_ALLVISITBYTIMESTAMPRANGE_ASYNC = createAsyncTypes(
  "GET_ALLVISITBYTIMESTAMPRANGE"
);

export const GET_UPDATEHCVISITORDERBYSTOREID_ASYNC = createAsyncTypes(
  "GET_UPDATEHCVISITORDERBYSTOREID"
);

export const POST_UPDATEHOMECAREMANUALHC2CMS_ASYNC = createAsyncTypes(
  "POST_UPDATEHOMECAREMANUALHC2CMS"
);
