//register page using organisation code to register organisation user
//organisation logo need to create at the path src/assets/<key>
export const localhost_code = "BB";
export const test_store_24_09_balancebrew_co_code = "BB";
export const clinicstore_staging_balancebrew_co_code = "BB";
export const clinicstore_balancebrew_co_code = "BB";
export const store_24_09_balancebrew_co_code = "BB";
export const store_staging_zhongjingtcm_com_code = "ZJ";
export const zjtcm_balancebrew_co_code = "ZJ";
export const ordinaryphysician_balancebrew_co_code = "OP";
export const herwill_balancebrew_co_code = "HW";
export const fl_balancebrew_co_code = "FL";
export const jnphealthcare_balancebrew_co = "JNP";
export const icueye_balancebrew_co = "IE";
