import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/brewingConstants";

export const putBrewingWIPMode = {
  pending: (data) =>
    createAction(types.PUT_BREWINGWIPMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_BREWINGWIPMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_BREWINGWIPMODE_ASYNC.ERROR, { error }),
};

export const putBrewingPendingStatus = {
  pending: (data) =>
    createAction(types.PUT_BREWINGPENDINGSTATUS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_BREWINGPENDINGSTATUS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_BREWINGPENDINGSTATUS_ASYNC.ERROR, { error }),
};

export const getBrewingList = {
  pending: (data) =>
    createAction(types.GET_BREWINGLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BREWINGLIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_BREWINGLIST_ASYNC.ERROR, { error }),
};

export const getBrewingSummaryList = {
  pending: (data) =>
    createAction(types.GET_BREWINGSUMMARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BREWINGSUMMARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BREWINGSUMMARYLIST_ASYNC.ERROR, { error }),
};

export const getBrewingById = {
  pending: (data) =>
    createAction(types.GET_BREWINGBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BREWINGBYID_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_BREWINGBYID_ASYNC.ERROR, { error }),
};

export const getBrewingByOrderNum = {
  pending: (data) =>
    createAction(types.GET_BREWINGBYORDERNUM_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_BREWINGBYORDERNUM_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_BREWINGBYORDERNUM_ASYNC.ERROR, { error }),
};

export const postSubmitBrewing = {
  pending: (data) =>
    createAction(types.POST_SUBMITBREWING_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITBREWING_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITBREWING_ASYNC.ERROR, { error }),
};
