import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getEmailStoreOrderList,
  getEmailStoreOrderById,
  postEmailStoreReadEmail,
  putEmailStorePendingStatus,
  putProcessEmailStorePendingStatus,
  putUpdateEmailStore,
  getEmailStoreBranchMedicine,
} from "../actions/emailStoreActions";
import * as types from "../_constants/emailStoreConstants";
import * as notification from "../_constants/notificationConstants";
import emailStoreAccountApis from "../_apis/emailStoreApis";
export default function* emailStoreSagas() {
  yield all([
    takeLatest(
      types.GET_EMAILSTOREORDERLIST_ASYNC.PENDING,
      getEmailStoreListCall
    ),
    takeLatest(
      types.GET_EMAILSTORORDEREBYID_ASYNC.PENDING,
      getEmailStoreOrderByIdCall
    ),
    takeLatest(
      types.POST_EMAILSTOREREADEMAIL_ASYNC.PENDING,
      postEmailStoreReadEmailCall
    ),
    takeLatest(
      types.PUT_EMAILSTOREPENDINGSTATUS_ASYNC.PENDING,
      putEmailStorePendingStatusCall
    ),
    takeLatest(
      types.PUT_PROCESSEMAILSTOREPENDINGSTATUS_ASYNC.PENDING,
      putProcessEmailStorePendingStatusCall
    ),
    takeLatest(
      types.PUT_UPDATEEMAILSTORE_ASYNC.PENDING,
      putUpdateEmailStoreCall
    ),
    takeLatest(
      types.GET_EMAILSTOREBRANCHMEDICINE_ASYNC.PENDING,
      getEmailStoreBranchMedicineCall
    ),
  ]);
}

function* getEmailStoreListCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.getEmailStoreOrderListApi,
      searchModel.data
    );
    yield put(getEmailStoreOrderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getEmailStoreOrderList.error(error.response?.data?.message));
    else yield put(getEmailStoreOrderList.error(notification.CONST_NOINTERNET));
  }
}

function* getEmailStoreOrderByIdCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.getEmailStoreOrderByIdApi,
      searchModel.data
    );
    yield put(getEmailStoreOrderById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getEmailStoreOrderById.error(error.response?.data?.message));
    else yield put(getEmailStoreOrderById.error(notification.CONST_NOINTERNET));
  }
}

function* postEmailStoreReadEmailCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.postEmailStoreReadEmailApi,
      searchModel.data
    );
    yield put(postEmailStoreReadEmail.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postEmailStoreReadEmail.error(error.response?.data?.message));
    else
      yield put(postEmailStoreReadEmail.error(notification.CONST_NOINTERNET));
  }
}

function* putEmailStorePendingStatusCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.putEmailStorePendingStatusApi,
      searchModel.data
    );
    yield put(putEmailStorePendingStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putEmailStorePendingStatus.error(error.response?.data?.message)
      );
    else
      yield put(
        putEmailStorePendingStatus.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putProcessEmailStorePendingStatusCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.putProcessEmailStorePendingStatusApi,
      searchModel.data
    );
    yield put(putProcessEmailStorePendingStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putProcessEmailStorePendingStatus.error(error.response?.data?.message)
      );
    else
      yield put(
        putProcessEmailStorePendingStatus.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateEmailStoreCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.putUpdateEmailStoreApi,
      searchModel.data
    );
    yield put(putUpdateEmailStore.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateEmailStore.error(error.response?.data?.message));
    else yield put(putUpdateEmailStore.error(notification.CONST_NOINTERNET));
  }
}

function* getEmailStoreBranchMedicineCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.getEmailStoreBranchMedicineApi,
      searchModel.data
    );
    yield put(getEmailStoreBranchMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getEmailStoreBranchMedicine.error(error.response?.data?.message)
      );
    else
      yield put(
        getEmailStoreBranchMedicine.error(notification.CONST_NOINTERNET)
      );
  }
}
