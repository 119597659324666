import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as EditLogo } from "../../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as RemoveLogo } from "../../../../../assets/coolicons/basic/trash_empty.svg";
import * as generalConstants from "../../../../../_constants/generalConstants";
import { getVisitAcupoints } from "../../../../../actions/visitOrderActions";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import NotificationDialog from "../../../../../components/NotificationDialog";

//style
import { useStyles } from "../../../../../components/globalStyles";

export default function AcupunctureGroupOption({
  memoAcupunctureGroupOption,
  acupunctureGroupOption,
  setAcupunctureGroupOption,
  acupunctureGroup,
  setAcupunctureGroup,
  memoSelectedAcupunctureGroupIndex,
  setAcupointList,
  memoSelectedAcupunctureGroup,
  acupunctureGroupId,
  setAcupunctureGroupId,
  viewOnly = false,
  editVisitAcupointViewOnly = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = createFilterOptions();
  const { t } = useTranslation();

  const [confirmDeleteGroupOpen, setConfirmDeleteGroupOpen] = useState(false);
  const [confirmEditGroupNameOpen, setConfirmEditGroupNameOpen] =
    useState(false);
  const [disableAcupoint, setDisableAcupoint] = useState(true);
  const [editGroupNameOpen, setEditGroupNameOpen] = useState(false);
  const [selectedAcupointItem, setSelectedAcupointItem] = useState("");
  const visitOrder = useSelector((state) => state.visitOrder);
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    dispatch(getVisitAcupoints.pending());
  }, [dispatch]);

  useEffect(() => {
    //dont display deleted item in table
    if (acupunctureGroup && memoSelectedAcupunctureGroup) {
      const selectedGroupResults = memoSelectedAcupunctureGroup;

      if (selectedGroupResults.visit_acupuncture_details) {
        const selectedstoreMedicine =
          selectedGroupResults.visit_acupuncture_details.filter(
            (item) => item.status_type !== generalConstants.DELETED_STATUS_TYPE
          );

        setAcupointList(selectedstoreMedicine);
      } else {
        setAcupointList([]);
      }
      setDisableAcupoint(false);
    } else {
      setDisableAcupoint(true);
    }
  }, [acupunctureGroup, memoSelectedAcupunctureGroup, setAcupointList]);

  // useEffect(() => {
  //   //disable selection of branch and medicine type when there is no group or there is medicine been selected
  //   if (Object.keys(acupointList).length == 0 || !group) {
  //     setDisableAcupoint(true);
  //   } else {
  //     setDisableAcupoint(false);
  //   }
  // }, [acupointList, group]);

  const handleGroupChange = (event, newValue) => {
    //
    //
    if (newValue !== null) {
      if (typeof newValue === "string") {
        //get current existing groups
        const currentGroup = memoAcupunctureGroupOption.some(
          (item) => item.name === newValue
        );
        //
        if (!currentGroup) {
          setAcupunctureGroupOption((acupunctureGroupOption) => [
            ...acupunctureGroupOption,
            {
              id: 0,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
              price: 0,
              name: newValue,
              duration: null,
            },
          ]);
        }

        setAcupunctureGroup(newValue);
        setAcupunctureGroupId(0);
      } else if (newValue.inputValue) {
        const repeatedNameIndex = acupunctureGroupOption.findIndex(
          (item) => item.name === newValue.inputValue
        );
        if (repeatedNameIndex !== -1) {
          let newArray = [...acupunctureGroupOption];
          newArray[repeatedNameIndex] = {
            ...newArray[repeatedNameIndex],
            status_type: generalConstants.APPROVED_STATUS_TYPE,
            price: 0,
            name: newValue.inputValue,
            duration: null,
          };

          setAcupunctureGroupOption(() => [...newArray]);
          setAcupunctureGroup(newValue.inputValue);
          setAcupunctureGroupId(newArray[repeatedNameIndex].id);
        } else {
          setAcupunctureGroupOption((acupunctureGroupOption) => [
            ...acupunctureGroupOption,
            {
              id: 0,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
              price: 0,
              name: newValue.inputValue,
              duration: null,
            },
          ]);
          setAcupunctureGroup(newValue.inputValue);
          setAcupunctureGroupId(0);
        }
      } else {
        setAcupunctureGroup(newValue.name);
        setAcupunctureGroupId(newValue.id);
      }
    } else {
      setAcupunctureGroup(newValue);
      setAcupunctureGroupId(null);
    }
  };
  const handleSelectedAcupoint = (event, newValue) => {
    event.preventDefault();
    const elementsIndex = memoSelectedAcupunctureGroupIndex;

    //make a copy of group option
    let oldArray = [...memoAcupunctureGroupOption];
    const selectedAcupoint = {
      id: 0,
      // create quantity
      acupoint_name: newValue.name,
      location: null,
      remarks: null,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
      acupoint: newValue,
    };
    let acupointDetail;

    if (oldArray[elementsIndex].visit_acupuncture_details) {
      const repeatAcupoint = oldArray[
        elementsIndex
      ].visit_acupuncture_details.some(
        (item) =>
          item.acupoint.id === newValue.id &&
          item.status_type === generalConstants.APPROVED_STATUS_TYPE
      );
      //find array that status_type === 7 (deleted)
      const repeatDeletedAcupointIndex = oldArray[
        elementsIndex
      ].visit_acupuncture_details.findIndex(
        (item) =>
          item.acupoint.id === newValue.id &&
          item.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      if (repeatAcupoint) {
        acupointDetail = [...oldArray[elementsIndex].visit_acupuncture_details];
      } else if (repeatDeletedAcupointIndex !== -1) {
        acupointDetail = [...oldArray[elementsIndex].visit_acupuncture_details];

        acupointDetail[repeatDeletedAcupointIndex] = {
          ...acupointDetail[repeatDeletedAcupointIndex],
          acupoint_name: newValue.name,
          location: null,
          remarks: null,
          status_type: generalConstants.APPROVED_STATUS_TYPE,
          acupoint: newValue,
        };
      } else {
        acupointDetail = [
          ...oldArray[elementsIndex].visit_acupuncture_details,
          selectedAcupoint,
        ];
      }
    } else {
      acupointDetail = [selectedAcupoint];
    }

    // setAcupointList(
    //   acupointDetail.filter(
    //     (item) => item.status_type !== generalConstants.DELETED_STATUS_TYPE
    //   )
    // );

    let resultWDelete = acupointDetail.filter(
      (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
    );
    let resultWoDelete = acupointDetail.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
    resultWDelete = resultWDelete.map((x, index) => {
      return { ...x, row_no: null };
    });
    resultWoDelete = resultWoDelete.map((x, index) => {
      return { ...x, row_no: index + 1 };
    });

    oldArray[elementsIndex] = {
      ...oldArray[elementsIndex],
      visit_acupuncture_details: [...resultWoDelete, ...resultWDelete],
    };

    setAcupunctureGroupOption(oldArray);
  };

  const handleTriggerEditNameWindow = (value) => {
    setConfirmEditGroupNameOpen(value);
    setTextValue("");
  };

  const handleRemoveGroup = () => {
    if (acupunctureGroupId > 0) {
      const elementsIndex = memoSelectedAcupunctureGroupIndex;
      //make a copy of group option
      let newArray = [...memoAcupunctureGroupOption];

      const deleteAcupunctureWONewItem = newArray[
        elementsIndex
      ].visit_acupuncture_details.filter((item) => item.id !== 0);

      const deleteAcupuncture = deleteAcupunctureWONewItem.map((item) => {
        var temp = Object.assign({}, item);
        if (temp.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          temp.status_type = generalConstants.DELETED_STATUS_TYPE;
        }
        return temp;
      });

      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        status_type: generalConstants.DELETED_STATUS_TYPE,
        visit_acupuncture_details: deleteAcupuncture,
      };

      setAcupunctureGroupOption(newArray);
      setAcupunctureGroup("");
      setAcupunctureGroupId(null);
      setAcupointList([]);
    } else {
      const deletedGroupIndex = memoAcupunctureGroupOption.findIndex(
        (item) => item.name === acupunctureGroup && item.id === 0
      );

      const result = memoAcupunctureGroupOption.filter(
        (item, index) => index !== deletedGroupIndex
      );
      setAcupunctureGroupOption(result);
      setAcupunctureGroup("");
      setAcupunctureGroupId(null);
      setAcupointList([]);
    }
  };

  const handleEditGroupName = (value) => {
    const trimValue = value.trim();
    const repeatedName = memoAcupunctureGroupOption.some(
      (item) => item.name === trimValue
    );
    if (repeatedName || trimValue === "") {
      setConfirmEditGroupNameOpen(true);
      setEditGroupNameOpen(true);
    } else {
      const elementsIndex = memoSelectedAcupunctureGroupIndex;
      //make a copy of group option
      let newArray = [...memoAcupunctureGroupOption];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        name: trimValue,
      };
      setAcupunctureGroupOption(newArray);
      setAcupunctureGroup(trimValue);
      setAcupunctureGroupId(memoSelectedAcupunctureGroup.id);
    }
  };

  return (
    <Grid container>
      <NotificationDialog
        ok={t("translation:Ok")}
        title={t("translation:Error")}
        open={editGroupNameOpen}
        setOpen={setEditGroupNameOpen}>
        {t("translation:Please use a different name")}
      </NotificationDialog>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Delete Group")}
        open={confirmDeleteGroupOpen}
        setOpen={setConfirmDeleteGroupOpen}
        onConfirm={handleRemoveGroup}>
        {t("translation:Are you sure you want to delete this group?")}
      </ConfirmDialog>

      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Edit Group Name")}
        open={confirmEditGroupNameOpen}
        setOpen={handleTriggerEditNameWindow}
        onConfirm={handleEditGroupName}
        requiredText={true}
        textLabel={t("translation:AcupunctureGroup")}
        textValue={textValue}
        setTextValue={setTextValue}>
        {t("translation:Are you sure you want to edit this group name?")}
      </ConfirmDialog>
      <Grid item xs={viewOnly ? 12 : 6} className={classes.userInfoField}>
        <Autocomplete
          onChange={handleGroupChange}
          filterOptions={(options, params) => {
            let filtered = filter(options, params);
            let trimValue = null;
            if (params.inputValue) {
              trimValue = params.inputValue.trim();

              params.inputValue = trimValue;
              filtered = filter(options, params);
            }

            // Suggest the creation of a new value
            //search any contain of input
            const group = options.some(
              (item) =>
                item.name === trimValue &&
                item.status_type === generalConstants.APPROVED_STATUS_TYPE
            );

            if (!viewOnly && !group && !editVisitAcupointViewOnly) {
              if (trimValue) {
                filtered.push({
                  inputValue: trimValue,
                  name: `${t("translation:Add")} "${trimValue}"`,
                });
              }
            }

            const result = filtered.filter(
              (item) =>
                item.status_type !== generalConstants.DELETED_STATUS_TYPE
            );

            return result;
          }}
          value={acupunctureGroup}
          blurOnSelect
          selectOnFocus
          clearOnBlur={true}
          handleHomeEndKeys
          disableClearable
          id="Group"
          name="Group"
          options={acupunctureGroupOption}
          getOptionLabel={(option, value) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }

            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            if (option.name) {
              return option.name;
            }
            return "";
          }}
          isOptionEqualToValue={(option, value) => option.name === value}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.name}>
                {option.name}
              </li>
            );
          }}
          style={{ width: "100%" }}
          freeSolo
          renderInput={(params) => (
            <TextField
              className={classes.userFieldColor}
              {...params}
              label={t("translation:AcupunctureGroup")}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {!viewOnly &&
                      acupunctureGroup &&
                      !editVisitAcupointViewOnly && (
                        <InputAdornment position="end">
                          <Button
                            onClick={() => setConfirmEditGroupNameOpen(true)}>
                            <EditLogo title={t("translation:Edit")} />
                          </Button>
                          <Button
                            onClick={() => setConfirmDeleteGroupOpen(true)}>
                            <RemoveLogo />
                          </Button>
                        </InputAdornment>
                      )}

                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>

      {!viewOnly && (
        <Grid item xs={6} className={classes.userInfoField}>
          <Autocomplete
            disabled={disableAcupoint}
            inputValue={selectedAcupointItem}
            value={selectedAcupointItem == null ? "" : selectedAcupointItem}
            name="acupiont"
            id="acupiont"
            options={
              visitOrder.acupointsList ? visitOrder.acupointsList.acupoints : []
            }
            getOptionLabel={(option) => (option.name ? option.name : "")}
            style={{ width: "100%" }}
            isOptionEqualToValue={() => {
              return true;
            }}
            disableClearable
            onChange={(e, option) => {
              setSelectedAcupointItem("");
              handleSelectedAcupoint(e, option);
            }}
            disablePortal
            sx={{
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                '&[aria-selected="true"]': {
                  backgroundColor: "rgba(255,255,255)",
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                //clear the field to empty
                onChange={(event) => {
                  setSelectedAcupointItem(event.target.value);
                }}
                className={classes.userFieldColor}
                label={<span>{t("translation:Acupoint")}</span>}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end">
        <Grid xs={3} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            value={
              acupunctureGroupOption.filter(
                (item) =>
                  item.status_type !== generalConstants.DELETED_STATUS_TYPE
              ).length
                ? acupunctureGroupOption.filter(
                    (item) =>
                      item.status_type !== generalConstants.DELETED_STATUS_TYPE
                  ).length
                : 0
            }
            disabled
            name="totalofgroup"
            label={t("translation:Total Groups")}
            variant="outlined"
            inputProps={{
              style: { textAlign: "right" },
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
