//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
//actions
import {
  putUpdateFormula,
  getFormulaMedicineTypeById,
} from "../../../../actions/formulaActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import StyledCheckbox from "../../../../components/StyledCheckbox";

export const EditFormulaPage = ({
  formulaEdit,
  setDetailView,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const common = useSelector((state) => state.common);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const formula = useSelector((state) => state.formula);
  const curUser = useSelector((state) => state.curUser);
  const prevPutUpdateStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    delete data.dispensaryOrganisation;
    const submitJson = {
      ...formulaEdit,
      ...data,

      name: data.name.trim(),
    };

    dispatch(putUpdateFormula.pending(submitJson));
  };

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      id: formulaEdit.id,
      name: formulaEdit.name,

      info1: formulaEdit.info1,
      info2: formulaEdit.info2,
      info3: formulaEdit.info3,
      info_functionality: formulaEdit.info_functionality,
      info_precaution: formulaEdit.info_precaution,
      status_type: formulaEdit.status_type,
      is_shared: formulaEdit.is_shared,
      medicine_type: formulaEdit.medicine_type,
      dispensaryOrganisation: formulaEdit.medicine_type,
      created_by_user: formulaEdit.created_by_user,
    },
  });

  useEffect(() => {
    //set ref when first render
    prevPutUpdateStatus.current = false;
    register("medicine_type");
    register("dispensaryOrganisation");
  }, [register]);

  //useEffects
  useEffect(() => {
    if (formulaEdit.medicine_type) {
      dispatch(
        getFormulaMedicineTypeById.pending({
          id: formulaEdit.medicine_type.organisation.id,
        })
      );
    }
  }, [dispatch, formulaEdit]);

  useEffect(() => {
    if (formula.isLoadingPutUpdateFormula !== prevPutUpdateStatus.current) {
      //check the previous ref with current state
      prevPutUpdateStatus.current = formula.isLoadingPutUpdateFormula;

      if (
        formula.putUpdateFormulaIsSuccess === true &&
        formula.isLoadingPutUpdateFormula === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...formula.putUpdateFormula.formula,
          dispensaryOrganisation:
            formula.putUpdateFormula.formula.medicine_type,
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        formula.putUpdateFormulaIsSuccess === false &&
        formula.isLoadingPutUpdateFormula === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(formula.error.putUpdateFormulaError);
        window.scrollTo(0, 0);
      }
    }
  }, [formula, reset]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:Formula Details")
          : t("translation:Edit Formula")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Formula has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.root} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              required
              name="name"
              label={t("translation:Name")}
              variant="outlined"
              {...register("name")}
            />
          </Grid>

          <Grid item xs={6} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  required
                  disabled={true}
                  name="dispensaryOrganisation"
                  id="organisation"
                  options={
                    curUser.defaultAlliance?.alliance
                      ?.alliance_organisation_associations
                      ? curUser.defaultAlliance?.alliance
                          ?.alliance_organisation_associations
                      : []
                  }
                  getOptionLabel={(option) => option.organisation.name}
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) =>
                    option.organisation.id === value.organisation.id
                  }
                  renderInput={(params) => (
                    <TextField
                      className={classes.userFieldColor}
                      disabled={true}
                      {...params}
                      required
                      label={t("translation:Organisation")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="dispensaryOrganisation"
              control={control}
            />
          </Grid>
          <Grid item xs={6} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  required
                  disabled={temViewOnly}
                  name="medicine_type"
                  id="medicine_type"
                  options={
                    formula.getFormulaMedicineTypeById
                      ? formula.getFormulaMedicineTypeById.medicine_types
                      : []
                  }
                  getOptionLabel={(option) => option.name}
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option)
                  }
                  renderInput={(params) => (
                    <TextField
                      className={classes.userFieldColor}
                      {...params}
                      required
                      label={t("translation:Medicine Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="medicine_type"
              control={control}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      disabled={temViewOnly}
                      checked={field.value}
                      onChange={(e, option) => field.onChange(option)}
                      name="is_shared"
                    />
                  }
                  label={t("translation:Is Shared")}
                />
              )}
              name="is_shared"
              control={control}
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="info1"
              label={t("translation:Info 1")}
              variant="outlined"
              {...register("info1")}
              multiline
              minRows={3}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="info2"
              label={t("translation:Info 2")}
              variant="outlined"
              {...register("info2")}
              multiline
              minRows={3}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="info3"
              label={t("translation:Info 3")}
              variant="outlined"
              {...register("info3")}
              multiline
              minRows={3}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="info_functionality"
              label={t("translation:Info Functionality")}
              variant="outlined"
              {...register("info_functionality")}
              multiline
              minRows={3}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              name="info_precaution"
              label={t("translation:Info Precaution")}
              variant="outlined"
              {...register("info_precaution")}
              multiline
              minRows={3}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={true}
              readOnly
              className={classes.userFieldColor}
              name="creator"
              label={t("translation:Creator")}
              variant="outlined"
              {...register("created_by_user.name")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly}
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>
          <>
            <RequiredNote />
            {temViewOnly ? (
              <PageOnlyBackAction setView={() => setDetailView(false)} />
            ) : (
              <PageSubmitWBackAction setView={() => setDetailView(false)} />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
