import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/userConstants";

export const getUserList = {
  pending: (data) => createAction(types.GET_USERLIST_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.GET_USERLIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_USERLIST_ASYNC.ERROR, { error }),
};

export const getUserById = {
  pending: (data) => createAction(types.GET_USERBYID_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.GET_USERBYID_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_USERBYID_ASYNC.ERROR, { error }),
};

export const getAllUserList = {
  pending: (data) =>
    createAction(types.GET_ALLUSERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ALLUSERLIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_ALLUSERLIST_ASYNC.ERROR, { error }),
};

export const putEditUser = {
  pending: (data) => createAction(types.PUT_EDITUSER_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.PUT_EDITUSER_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.PUT_EDITUSER_ASYNC.ERROR, { error }),
};

export const postCreateUser = {
  pending: (data) =>
    createAction(types.POST_CREATEUSER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEUSER_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.POST_CREATEUSER_ASYNC.ERROR, { error }),
};

export const putPhysicianLicenseNoById = {
  pending: (data) =>
    createAction(types.PUT_PHYSICIANLICENSENOBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_PHYSICIANLICENSENOBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_PHYSICIANLICENSENOBYID_ASYNC.ERROR, { error }),
};

export const postFailedLoginCount = {
  pending: (data) =>
    createAction(types.POST_FAILEDLOGINCOUNT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_FAILEDLOGINCOUNT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_FAILEDLOGINCOUNT_ASYNC.ERROR, { error }),
};

export const getLoginAttemptMax = {
  pending: (data) =>
    createAction(types.GET_LOGINATTEMPTMAX_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_LOGINATTEMPTMAX_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_LOGINATTEMPTMAX_ASYNC.ERROR, { error }),
};
