import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const orderFormApis = {
  getOrderFormListApi,
  getOrderFormByIdApi,
  getOrderFormMedicineTypesApi,
  postOrderFormMedicinesApi,
  postOrderFormSuppliersApi,
  postSubmitOrderFormApi,
  putApproveOrderFormApi,
  putOrderFormEditModeApi,

  putOrderFormCancelled,
  putOrderFormPrintedDateApi,
};

async function getOrderFormListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetOrderFormList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getOrderFormByIdApi(model) {
  const url = apiConstants.apiGetOrderFormById + model;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getOrderFormMedicineTypesApi() {
  return await axios
    .get(apiConstants.apiGetOrderFormMedicineTypes)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postOrderFormMedicinesApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostOrderFormMedicines,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postOrderFormSuppliersApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostOrderFormSuppliers,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitOrderFormApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostSubmitOrderForm, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putApproveOrderFormApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(apiConstants.apiPutApproveOrderForm, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putOrderFormEditModeApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(apiConstants.apiPutOrderFormEditMode, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putOrderFormCancelled(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(apiConstants.apiPutOrderFormCancelled, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putOrderFormPrintedDateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutOrderFormPrintedDate,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default orderFormApis;
