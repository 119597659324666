import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/roleRightConstants";

export const getRoleRightList = {
  pending: (data) =>
    createAction(types.GET_ROLERIGHTLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_ROLERIGHTLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_ROLERIGHTLIST_ASYNC.ERROR, { error }),
};

export const putEditRoleRight = {
  pending: (data) =>
    createAction(types.PUT_EDITROLERIGHT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_EDITROLERIGHT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_EDITROLERIGHT_ASYNC.ERROR, { error }),
};

export const getRoleRight = {
  pending: (data) => createAction(types.GET_ROLERIGHT_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.GET_ROLERIGHT_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_ROLERIGHT_ASYNC.ERROR, { error }),
};
