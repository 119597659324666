import * as types from "../_constants/homecareConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_ALLVISITBYLASTMODIFIED_ASYNC.PENDING:
      return {
        ...state,
        getAllVisitByLastModifiedSuccess: false,
        isLoadingGetAllVisitByLastModified: true,
        allVisitByLastModified: null,
      };

    case types.GET_ALLVISITBYLASTMODIFIED_ASYNC.ERROR:
      return {
        ...state,
        getAllVisitByLastModifiedSuccess: false,
        isLoadingGetAllVisitByLastModified: false,
        allVisitByLastModified: null,
        error: { ...state.error, getAllVisitByLastModifiedError: action.error },
      };

    case types.GET_ALLVISITBYLASTMODIFIED_ASYNC.SUCCESS:
      return {
        ...state,
        getAllVisitByLastModifiedSuccess: true,
        isLoadingGetAllVisitByLastModified: false,
        allVisitByLastModified: action.data,
      };

    case types.GET_VERIFYMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        getVerifyMedicineSuccess: false,
        isLoadingGetVerifyMedicine: true,
        verifyMedicine: null,
      };

    case types.GET_VERIFYMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        getVerifyMedicineSuccess: false,
        isLoadingGetVerifyMedicine: false,
        verifyMedicine: null,
        error: { ...state.error, getVerifyMedicineError: action.error },
      };

    case types.GET_VERIFYMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        getVerifyMedicineSuccess: true,
        isLoadingGetVerifyMedicine: false,
        verifyMedicine: action.data,
      };

    case types.POST_UPDATEHOMECAREMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        postUpdateHomecareMedicineSuccess: false,
        isLoadingPostUpdateHomecareMedicine: true,
        updateHomecareMedicine: null,
      };

    case types.POST_UPDATEHOMECAREMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        postUpdateHomecareMedicineSuccess: false,
        isLoadingPostUpdateHomecareMedicine: false,
        updateHomecareMedicine: null,
        error: {
          ...state.error,
          postUpdateHomecareMedicineError: action.error,
        },
      };

    case types.POST_UPDATEHOMECAREMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        postUpdateHomecareMedicineSuccess: true,
        isLoadingPostUpdateHomecareMedicine: false,
        updateHomecareMedicine: action.data,
      };

    case types.GET_ALLVISITBYTIMESTAMPRANGE_ASYNC.PENDING:
      return {
        ...state,
        getAllVisitByTimestampRangeSuccess: false,
        isLoadingGetAllVisitByTimestampRange: true,
        allVisitByTimestampRange: null,
      };

    case types.GET_ALLVISITBYTIMESTAMPRANGE_ASYNC.ERROR:
      return {
        ...state,
        getAllVisitByTimestampRangeSuccess: false,
        isLoadingGetAllVisitByTimestampRange: false,
        allVisitByTimestampRange: null,
        error: {
          ...state.error,
          getAllVisitByTimestampRangeError: action.error,
        },
      };

    case types.GET_ALLVISITBYTIMESTAMPRANGE_ASYNC.SUCCESS:
      return {
        ...state,
        getAllVisitByTimestampRangeSuccess: true,
        isLoadingGetAllVisitByTimestampRange: false,
        allVisitByTimestampRange: action.data,
      };

    case types.GET_UPDATEHCVISITORDERBYSTOREID_ASYNC.PENDING:
      return {
        ...state,
        getUpdateHCVisitOrderByStoreIdSuccess: false,
        isLoadingGetUpdateHCVisitOrderByStoreId: true,
        updateHCVisitOrderByStoreId: null,
      };

    case types.GET_UPDATEHCVISITORDERBYSTOREID_ASYNC.ERROR:
      return {
        ...state,
        getUpdateHCVisitOrderByStoreIdSuccess: false,
        isLoadingGetUpdateHCVisitOrderByStoreId: false,
        updateHCVisitOrderByStoreId: null,
        error: {
          ...state.error,
          getUpdateHCVisitOrderByStoreIdError: action.error,
        },
      };

    case types.GET_UPDATEHCVISITORDERBYSTOREID_ASYNC.SUCCESS:
      return {
        ...state,
        getUpdateHCVisitOrderByStoreIdSuccess: true,
        isLoadingGetUpdateHCVisitOrderByStoreId: false,
        updateHCVisitOrderByStoreId: action.data,
      };

    case types.POST_UPDATEHOMECAREMANUALHC2CMS_ASYNC.PENDING:
      return {
        ...state,
        postUpdateHomecareManualHc2cmsSuccess: false,
        isLoadingPostUpdateHomecareManualHc2cms: true,
        updateHomecareManualHc2cms: null,
      };

    case types.POST_UPDATEHOMECAREMANUALHC2CMS_ASYNC.ERROR:
      return {
        ...state,
        postUpdateHomecareManualHc2cmsSuccess: false,
        isLoadingPostUpdateHomecareManualHc2cms: false,
        updateHomecareManualHc2cms: null,
        error: {
          ...state.error,
          updateHomecareManualHc2cmsError: action.error,
        },
      };

    case types.POST_UPDATEHOMECAREMANUALHC2CMS_ASYNC.SUCCESS:
      return {
        ...state,
        postUpdateHomecareManualHc2cmsSuccess: true,
        isLoadingPostUpdateHomecareManualHc2cms: false,
        updateHomecareManualHc2cms: action.data,
      };

    default:
      return state;
  }
};
