import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/userMfaConstants";

export const getUserMfaList = {
  pending: (data) =>
    createAction(types.GET_USERMFALIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERMFALIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_USERMFALIST_ASYNC.ERROR, { error }),
};

export const getUserMfaById = {
  pending: (data) =>
    createAction(types.GET_USERMFABYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERMFABYID_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_USERMFABYID_ASYNC.ERROR, { error }),
};

export const putUpdateUserMfa = {
  pending: (data) =>
    createAction(types.PUT_UPDATEUSERMFA_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEUSERMFA_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEUSERMFA_ASYNC.ERROR, { error }),
};

export const getRandomMfaSecretKey = {
  pending: (data) =>
    createAction(types.GET_RANDOMMFASECRETKEY_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_RANDOMMFASECRETKEY_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_RANDOMMFASECRETKEY_ASYNC.ERROR, { error }),
};

export const postVerifyMfaSecretKey = {
  pending: (data) =>
    createAction(types.POST_VERIFYMFASECRETKEY_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VERIFYMFASECRETKEY_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VERIFYMFASECRETKEY_ASYNC.ERROR, { error }),
};

export const getOtpProvisioningUrl = {
  pending: (data) =>
    createAction(types.GET_OTPPROVISIONINGURL_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_OTPPROVISIONINGURL_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_OTPPROVISIONINGURL_ASYNC.ERROR, { error }),
};

export const postVerifyOtpToken = {
  pending: (data) =>
    createAction(types.POST_VERIFYOTPTOKEN_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VERIFYOTPTOKEN_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VERIFYOTPTOKEN_ASYNC.ERROR, { error }),
};

export const getUserMfaUserList = {
  pending: (data) =>
    createAction(types.GET_USERMFAUSERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERMFAUSERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_USERMFAUSERLIST_ASYNC.ERROR, { error }),
};
