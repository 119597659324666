import { createAsyncTypes } from "../_helper/Utilities";

export const GET_BRANCHPHYSICIANSCHEDULELIST_ASYNC = createAsyncTypes(
  "GET_BRANCHPHYSICIANSCHEDULELIST"
);

export const GET_BRANCHPHYSICIANSCHEDULEBYID_ASYNC = createAsyncTypes(
  "GET_BRANCHPHYSICIANSCHEDULEBYID"
);

export const POST_BRANCHPHYSICIANSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC =
  createAsyncTypes("POST_BRANCHPHYSICIANSCHEDULEBYBRANCHIDPHYSICIANID");

export const GET_BRANCHPHYSICIANSCHEDULEBRANCHLIST_ASYNC = createAsyncTypes(
  "GET_BRANCHPHYSICIANSCHEDULEBRANCHLIST"
);

export const GET_BRANCHPHYSICIANSCHEDULEPHYSICIANLIST_ASYNC = createAsyncTypes(
  "GET_BRANCHPHYSICIANSCHEDULEBPHYSICIANLIST"
);
