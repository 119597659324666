//lib
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Close from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Button, Grid, Link, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

// import FormControlLabel from "@mui/material/FormControlLabel";

//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { putDeliveryOrderUpdateStoreDeliveryAddress } from "../../../../actions/deliveryOrderActions";
import noEnterSubmitForm from "../../../../components/functions/noEnterSubmitForm";
import { useStyles } from "../../../../components/globalStyles";
// import StyledCheckbox from "../../../../components/StyledCheckbox";
import externetApis from "../../../../_apis/externetApis";
import allowOnlyNumericsOrDigits from "../../../../components/functions/allowOnlyNumericsOrDigits";
import CircularProgress from "@mui/material/CircularProgress";

//style
export default function EditDeliveryAddressDialog({
  memoSelectedGroup,
  orderDetailInfo,
  setGroupOption,
}) {
  const { handleSubmit } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const prevUpdateDeliveryAddressStatus = useRef();

  const [editDeliveryOpen, setEditDeliveryOpen] = useState(false);
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open;
  const [postcodeSearchPageNum, setPostcodeSearchPageNum] = useState(null);
  const [postcodeSearch, setPostcodeSearch] = useState(null);
  const [addressDisabled, setAddressDisabled] = React.useState(false);
  const [selectedPostalCode, setSelectedPostalCode] = useState(null);

  const [selectedGroup, setSelectedGroup] = useState(memoSelectedGroup);

  const handlePostalCodeChange = (event) => {
    setSelectedPostalCode(event.target.value);
    if (event.target.value.length === 6) {
      setOpen(true);
      setPostcodeSearch(event.target.value);
      setOptions([]);
      setPostcodeSearchPageNum(1);
    }

    setAddressDisabled(false);
  };

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }
    if (postcodeSearch) {
      (async () => {
        const response = await externetApis.getPostalCodeListApi(
          postcodeSearch,
          postcodeSearchPageNum
        );

        if (response.status === 200) {
          const addressResults = response.data;
          if (addressResults.results && addressResults.results.length > 0) {
            if (addressResults.totalNumPages > addressResults.pageNum) {
              setPostcodeSearchPageNum(addressResults.pageNum + 1);
            }

            if (addressResults.pageNum === 1) {
              setOptions(addressResults.results);
            } else {
              setOptions((optionsResult) => {
                return [...optionsResult, ...addressResults.results];
              });
            }
          } else {
            setOpen(false);
          }
        } else {
          setOpen(false);
        }
      })();
    }
  }, [loading, postcodeSearch, postcodeSearchPageNum]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  useEffect(() => {
    const selectedGroupResults = selectedGroup;

    if (selectedGroupResults.store_medicine_details) {
      setAddressDisabled(
        selectedGroupResults.delivery_postal_code?.length === 6 ? true : false
      );

      setSelectedPostalCode(
        selectedGroupResults.delivery_postal_code
          ? selectedGroupResults.delivery_postal_code
          : ""
      );
    }
  }, [selectedGroup]);

  useEffect(() => {
    const selectedGroupResults = memoSelectedGroup;

    if (selectedGroupResults.store_medicine_details) {
      setSelectedGroup(memoSelectedGroup);
    }
  }, [memoSelectedGroup]);

  const handleSelectedPostalCode = (event) => {
    if (!addressDisabled) {
      setSelectedGroup({
        ...selectedGroup,
        delivery_postal_code: null,
      });
    }
  };

  // const handleIncludedPrescription = (event) => {
  //   setSelectedGroup({
  //     ...selectedGroup,
  //     delivery_incl_prescription: event.target.checked,
  //   });
  // };

  const handleAddressChange = (option) => {
    if (option) {
      // const addressWithoutPostalIndex = option.ADDRESS.lastIndexOf("SINGAPORE");
      // const addressWithoutPostal = option.ADDRESS.substring(
      //   0,
      //   addressWithoutPostalIndex - 1
      // );

      setAddressDisabled(true);
      setSelectedPostalCode(option.POSTAL);

      setSelectedGroup({
        ...selectedGroup,
        delivery_address: option.ADDRESS,
        delivery_blk_no: option.BLK_NO,
        delivery_road_name: option.ROAD_NAME,
        delivery_building: option.BUILDING !== "NIL" ? option.BUILDING : null,
        delivery_postal_code: option.POSTAL,
      });
    }
  };

  const handleChangeDelivery = (e) => {
    setSelectedGroup({
      ...selectedGroup,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const deliveryOrder = useSelector((state) => state.deliveryOrder);

  const onSubmit = () => {
    const submitJson = {
      store_header: {
        id: orderDetailInfo.id,
        store_medicine_headers: [selectedGroup],
      },
    };
    dispatch(putDeliveryOrderUpdateStoreDeliveryAddress.pending(submitJson));
  };

  const onConfirmSubmit = () => {
    setConfirmSubmitOpen(true);
  };

  useEffect(() => {
    prevUpdateDeliveryAddressStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingDeliveryOrderUpdateStoreAddress !==
      prevUpdateDeliveryAddressStatus.current
    ) {
      //check the previous ref with current state
      prevUpdateDeliveryAddressStatus.current =
        deliveryOrder.isLoadingDeliveryOrderUpdateStoreAddress;

      if (
        deliveryOrder.putDeliveryOrderUpdateStoreAddressIsSuccess === true &&
        deliveryOrder.isLoadingDeliveryOrderUpdateStoreAddress === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);

        setGroupOption(
          deliveryOrder.deliveryOrderUpdateStoreAddress.store_header
            .store_medicine_headers
        );
      }
      if (
        deliveryOrder.putDeliveryOrderUpdateStoreAddressIsSuccess === false &&
        deliveryOrder.isLoadingDeliveryOrderUpdateStoreAddress === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(deliveryOrder.error.deliveryOrderUpdateStoreAddressError);
      }
    }
  }, [deliveryOrder, setGroupOption]);

  return (
    <>
      {" "}
      <Grid item xs={12}>
        <Link className={classes.boldMaroon075}>
          <Button
            className={classes.boldMaroon075}
            onClick={() => setEditDeliveryOpen(true)}>
            {t("translation:Edit Delivery")}
          </Button>
        </Link>
      </Grid>
      <Dialog
        open={editDeliveryOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setEditDeliveryOpen(false);
          }
        }}
        aria-labelledby="confirm-dialog"
        fullWidth
        maxWidth="md">
        <Close
          style={{ position: "absolute", right: "15px" }}
          onClick={() => setEditDeliveryOpen(false)}
        />
        <form
          onSubmit={handleSubmit(onConfirmSubmit)}
          onKeyDown={(e) => noEnterSubmitForm(e)}
          style={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}>
          <Collapse in={submitErrorAlarm}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setSubmitErrorAlarm(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlarm}
            </Alert>
          </Collapse>
          <Collapse in={submitSuccessAlarm}>
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setSubmitSuccessAlarm(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {t("translation:Address has been updated")}
            </Alert>
          </Collapse>
          <ConfirmDialog
            Yes={t("translation:Yes")}
            No={t("translation:No")}
            title={t("translation:Confirmation")}
            open={confirmSubmitOpen}
            setOpen={setConfirmSubmitOpen}
            onConfirm={onSubmit}>
            {t("translation:Are you sure you want to proceed?")}
          </ConfirmDialog>
          {deliveryOrder.isLoadingDeliveryOrderUpdateStoreAddress !== true && (
            <DialogContent>
              <Grid container>
                <Grid item xs={6} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      selectedGroup.delivery_name
                        ? selectedGroup.delivery_name
                        : ""
                    }
                    name="delivery_name"
                    label={t("translation:Recipient")}
                    required
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChangeDelivery(e)}
                  />
                </Grid>
                <Grid item xs={6} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      selectedGroup.delivery_contact_number
                        ? selectedGroup.delivery_contact_number
                        : ""
                    }
                    name="delivery_contact_number"
                    required
                    label={t("translation:Contact Number")}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChangeDelivery(e)}
                  />
                </Grid>
                <Grid item xs={6} className={classes.userInfoField}>
                  <Autocomplete
                    style={{ width: "100%" }}
                    name="delivery_postal_code"
                    disableClearable
                    open={open}
                    onClose={() => {
                      setOpen(false);
                    }}
                    isOptionEqualToValue={() => {
                      return true;
                    }}
                    inputValue={selectedPostalCode ? selectedPostalCode : ""}
                    onChange={(e, option) => handleAddressChange(option)}
                    getOptionLabel={(option) =>
                      option.ADDRESS ? option.ADDRESS : ""
                    }
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        name="postal_code"
                        onInput={allowOnlyNumericsOrDigits}
                        {...params}
                        onChange={handlePostalCodeChange}
                        onBlur={handleSelectedPostalCode}
                        label={t("translation:Postal Code")}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: selectedPostalCode ? true : false,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      selectedGroup.delivery_address_details
                        ? selectedGroup.delivery_address_details
                        : ""
                    }
                    name="delivery_address_details"
                    placeholder={t("translation:Floor/ Unit Number")}
                    variant="outlined"
                    label={t("translation:Address Details")}
                    onChange={(e) => handleChangeDelivery(e)}
                  />
                </Grid>

                {selectedGroup.delivery_blk_no ? (
                  <Grid item xs={12} className={classes.userInfoField}>
                    <TextField
                      className={classes.userFieldColor}
                      disabled
                      value={selectedGroup.delivery_blk_no}
                      name="blk_no"
                      label={t("translation:Block No")}
                      variant="outlined"
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                {selectedGroup.delivery_road_name ? (
                  <Grid item xs={12} className={classes.userInfoField}>
                    <TextField
                      className={classes.userFieldColor}
                      disabled
                      value={selectedGroup.delivery_road_name}
                      name="road_name"
                      label={t("translation:Road Name")}
                      variant="outlined"
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                {selectedGroup.delivery_building ? (
                  <Grid item xs={12} className={classes.userInfoField}>
                    <TextField
                      className={classes.userFieldColor}
                      disabled
                      value={selectedGroup.delivery_building}
                      name="building"
                      label={t("translation:Building")}
                      variant="outlined"
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      selectedGroup.delivery_address
                        ? selectedGroup.delivery_address
                        : ""
                    }
                    InputProps={{
                      readOnly: addressDisabled,
                      className: addressDisabled ? "Mui-disabled" : undefined,
                    }}
                    classes={addressDisabled ? { root: "Mui-disabled" } : {}}
                    required
                    multiline
                    rows={3}
                    name="delivery_address"
                    label={t("translation:Address")}
                    variant="outlined"
                    onChange={(e) => handleChangeDelivery(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      selectedGroup.delivery_remarks
                        ? selectedGroup.delivery_remarks
                        : ""
                    }
                    multiline
                    rows={3}
                    name="delivery_remarks"
                    label={t("translation:Remarks")}
                    variant="outlined"
                    onChange={(e) => handleChangeDelivery(e)}
                  />
                </Grid>

                {/* <Grid item xs={3} className={classes.userInfoField}>
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={
                        selectedGroup.delivery_incl_prescription
                          ? true
                          : false
                      }
                      onChange={handleIncludedPrescription}
                      name="delivery_incl_prescription"
                    />
                  }
                  label={t("translation:Print Prescription")}
                />
              </Grid> */}
              </Grid>
            </DialogContent>
          )}

          <DialogActions>
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                name="submitButton"
                type="submit">
                {t("translation:SUBMIT")}
              </Button>
            </Link>

            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                name="backButton"
                onClick={() => setEditDeliveryOpen(false)}>
                {t("translation:BACK")}
              </Button>
            </Link>
          </DialogActions>
          {/* <DialogActions>
          <PageSubmitWBackAction setView={setEditDeliveryOpen} />
        </DialogActions> */}
        </form>
      </Dialog>
    </>
  );
}
