import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  getNonBusinessDayList,
  getNonBusinessDayById,
  postCreateNonBusinessDay,
  putUpdateNonBusinessDay,
  postSearchNonBusinessDayBranch,
} from "../actions/nonBusinessDayActions";
import * as types from "../_constants/nonBusinessDayConstants";
import * as notification from "../_constants/notificationConstants";
import nonBusinessDayApis from "../_apis/nonBusinessDayApis";
export default function* nonBusinessDaySagas() {
  yield all([
    takeLatest(
      types.GET_NONBUSINESSDAYLIST_ASYNC.PENDING,
      getNonBusinessDayListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_NONBUSINESSDAYBYID_ASYNC.PENDING,
      getNonBusinessDayByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATENONBUSINESSDAY_ASYNC.PENDING,
      postCreateNonBusinessDayCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_UPDATENONBUSINESSDAY_ASYNC.PENDING,
      putUpdateNonBusinessDayCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_SEARCHNONBUSINESSDAYBRANCH_ASYNC.PENDING,
      postSearchNonBusinessDayBranchCall
    ),
  ]);
}

function* getNonBusinessDayListCall(Model) {
  try {
    const data = yield call(
      nonBusinessDayApis.getNonBusinessDayListApi,
      Model.data
    );
    yield put(getNonBusinessDayList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getNonBusinessDayList.error(error.response?.data?.message));
    else yield put(getNonBusinessDayList.error(notification.CONST_NOINTERNET));
  }
}

function* getNonBusinessDayByIdCall(Model) {
  try {
    const data = yield call(
      nonBusinessDayApis.getNonBusinessDayByIdApi,
      Model.data
    );
    yield put(getNonBusinessDayById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getNonBusinessDayById.error(error.response?.data?.message));
    else yield put(getNonBusinessDayById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateNonBusinessDayCall(Model) {
  try {
    const data = yield call(
      nonBusinessDayApis.postCreateNonBusinessDayApi,
      Model.data
    );
    yield put(postCreateNonBusinessDay.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateNonBusinessDay.error(error.response?.data?.message));
    else
      yield put(postCreateNonBusinessDay.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateNonBusinessDayCall(Model) {
  try {
    const data = yield call(
      nonBusinessDayApis.putUpdateNonBusinessDayApi,
      Model.data
    );
    yield put(putUpdateNonBusinessDay.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateNonBusinessDay.error(error.response?.data?.message));
    else
      yield put(putUpdateNonBusinessDay.error(notification.CONST_NOINTERNET));
  }
}

function* postSearchNonBusinessDayBranchCall(Model) {
  try {
    const data = yield call(
      nonBusinessDayApis.postSearchNonBusinessDayBranchApi,
      Model.data
    );
    yield put(postSearchNonBusinessDayBranch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postSearchNonBusinessDayBranch.error(error.response?.data?.message)
      );
    else
      yield put(
        postSearchNonBusinessDayBranch.error(notification.CONST_NOINTERNET)
      );
  }
}
