//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
//import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//components
import BrewingListTable from "./BrewingListTable";
import { useStyles } from "../../../components/globalStyles";

//actions

const BrewingSummaryPage = () => {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  //display and hide the "brewing summary" table
  const [brewingInformationView, setBrewingInformationView] =
    React.useState(false);

  //useEffects

  // const workOrder = useSelector((state) => state.workOrder);

  return (
    <>
      <Paper className={classes.summaryPaper} elevation={3}>
        {!brewingInformationView && (
          <Grid container direction="row" alignItems="center">
            <Grid item xs={11}>
              <Typography className={classes.title} variant="h3" gutterBottom>
                {t("translation:Brewing Summary")}
              </Typography>
            </Grid>
          </Grid>
        )}
        <hr />

        <BrewingListTable
          setBrewingInformationView={setBrewingInformationView}
        />
      </Paper>
    </>
  );
};

export default BrewingSummaryPage;
