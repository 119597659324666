import { createAsyncTypes } from "../_helper/Utilities";

export const GET_POSTALCODESURCHARGEBYID_ASYNC = createAsyncTypes(
  "GET_POSTALCODESURCHARGEBYID"
);

export const GET_POSTALCODESURCHARGELIST_ASYNC = createAsyncTypes(
  "GET_POSTALCODESURCHARGELIST"
);

export const POST_CREATEPOSTALCODESURCHARGE_ASYNC = createAsyncTypes(
  "POST_CREATEPOSTALCODESURCHARGE"
);

export const PUT_UPDATEPOSTALCODESURCHARGE_ASYNC = createAsyncTypes(
  "PUT_UPDATEPOSTALCODESURCHARGE"
);
