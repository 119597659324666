import * as types from "../_constants/filterRuleConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_FILTERRULEBYID_ASYNC.PENDING:
      return {
        ...state,
        getFilterRuleByIdIsSuccess: false,
        isLoadingGetFilterRuleById: true,
        getFilterRuleById: null,
      };

    case types.GET_FILTERRULEBYID_ASYNC.ERROR:
      return {
        ...state,
        getFilterRuleByIdIsSuccess: false,
        isLoadingGetFilterRuleById: false,
        getFilterRuleById: null,
        error: {
          ...state.error,
          getFilterRuleByIdError: action.error,
        },
      };

    case types.GET_FILTERRULEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getFilterRuleByIdIsSuccess: true,
        isLoadingGetFilterRuleById: false,
        getFilterRuleById: action.data,
      };

    case types.GET_FILTERRULELIST_ASYNC.PENDING:
      return {
        ...state,
        getFilterRuleListIsSuccess: false,
        isLoadingGetFilterRuleList: true,
        getFilterRuleList: null,
      };

    case types.GET_FILTERRULELIST_ASYNC.ERROR:
      return {
        ...state,
        getFilterRuleListIsSuccess: false,
        isLoadingGetFilterRuleList: false,
        getFilterRuleList: null,
        error: {
          ...state.error,
          getFilterRuleListError: action.error,
        },
      };

    case types.GET_FILTERRULELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getFilterRuleListIsSuccess: true,
        isLoadingGetFilterRuleList: false,
        getFilterRuleList: action.data,
      };

    case types.POST_CREATEFILTERRULE_ASYNC.PENDING:
      return {
        ...state,
        postCreateFilterRuleIsSuccess: false,
        isLoadingPostCreateFilterRule: true,
        postCreateFilterRule: null,
      };

    case types.POST_CREATEFILTERRULE_ASYNC.ERROR:
      return {
        ...state,
        postCreateFilterRuleIsSuccess: false,
        isLoadingPostCreateFilterRule: false,
        postCreateFilterRule: null,
        error: {
          ...state.error,
          postCreateFilterRuleError: action.error,
        },
      };

    case types.POST_CREATEFILTERRULE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateFilterRuleIsSuccess: true,
        isLoadingPostCreateFilterRule: false,
        postCreateFilterRule: action.data,
      };

    case types.PUT_UPDATEFILTERRULE_ASYNC.PENDING:
      return {
        ...state,
        putUpdateFilterRuleIsSuccess: false,
        isLoadingPutUpdateFilterRule: true,
        putUpdateFilterRule: null,
      };

    case types.PUT_UPDATEFILTERRULE_ASYNC.ERROR:
      return {
        ...state,
        putUpdateFilterRuleIsSuccess: false,
        isLoadingPutUpdateFilterRule: false,
        putUpdateFilterRule: null,
        error: {
          ...state.error,
          putUpdateFilterRuleError: action.error,
        },
      };

    case types.PUT_UPDATEFILTERRULE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateFilterRuleIsSuccess: true,
        isLoadingPutUpdateFilterRule: false,
        putUpdateFilterRule: action.data,
      };

    default:
      return state;
  }
};
