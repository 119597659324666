import { createAsyncTypes } from "../_helper/Utilities";

export const GET_MEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC = createAsyncTypes(
  "GET_MEDICINEFIXEDSELLINGPRICEDETAILS"
);

export const GET_MEDICINEFIXEDSELLINGPRICEHEADERDETAILSLIST_ASYNC =
  createAsyncTypes("GET_MEDICINEFIXEDSELLINGPRICEHEADERDETAILSLIST");

export const GET_MEDICINEFIXEDSELLINGPRICEDETAILSSUMMARYLIST_ASYNC =
  createAsyncTypes("GET_MEDICINEFIXEDSELLINGPRICEDETAILSSUMMARYLIST");

export const GET_MEDICINEFIXEDSELLINGPRICEDETAILSBYID_ASYNC = createAsyncTypes(
  "GET_MEDICINEFIXEDSELLINGPRICEDETAILSBYID"
);

export const PUT_UPDATEMEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC =
  createAsyncTypes("PUT_UPDATEMEDICINEFIXEDSELLINGPRICEDETAILS");

export const GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINETYPES_ASYNC =
  createAsyncTypes("GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINETYPES");

export const GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINEINFO_ASYNC =
  createAsyncTypes("GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINEINFO");
