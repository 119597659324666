import { createAsyncTypes } from "../_helper/Utilities";

export const GET_EMAILSTOREACCOUNTBYID_ASYNC = createAsyncTypes(
  "GET_EMAILSTOREACCOUNTBYID"
);

export const GET_EMAILSTOREACCOUNTLIST_ASYNC = createAsyncTypes(
  "GET_EMAILSTOREACCOUNTLIST"
);

export const POST_CREATEEMAILSTOREACCOUNT_ASYNC = createAsyncTypes(
  "POST_CREATEEMAILSTOREACCOUNT"
);

export const PUT_UPDATEEMAILSTOREACCOUNT_ASYNC = createAsyncTypes(
  "PUT_UPDATEEMAILSTOREACCOUNT"
);
