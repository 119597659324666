import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
//components

import * as generalConstants from "../../../_constants/generalConstants";

import SocketNotification from "./SocketNotification";

import BrewingListTable from "../../workOrder/Brewing/BrewingListTable";

//styles
export default function BrewingTable() {
  const { t } = useTranslation();

  //variable
  const [refreshTimeStamp, setRefreshTimeStamp] = useState(moment());

  return (
    <SocketNotification
      cellTitle={t("translation:Brewing")}
      sockectWorkTypeId={generalConstants.BREWING_WORK_ORDER_TYPE}
      setRefreshTimeStamp={setRefreshTimeStamp}
      localStorageName="brewingListTableNotification">
      <BrewingListTable
        refreshTimeStamp={refreshTimeStamp}
        dashboardView={true}
        setRefreshTimeStamp={setRefreshTimeStamp}
      />
    </SocketNotification>
  );
}
