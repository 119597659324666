//lib
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Close from "@mui/icons-material/Close";
import StoreListTable from "../../visit/Consultation/StoreListTable";

//style//style
export default function VisitOrderDialog({ open, setOpen, visitOrderNo }) {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setOpen(false);
        }
      }}
      aria-labelledby="confirm-dialog"
      fullWidth
      maxWidth="xl">
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => setOpen(false)}
      />
      <DialogContent>
        <StoreListTable
          dashboardVisitOrderNo={visitOrderNo}
          disabledTableSearch={true}
        />
      </DialogContent>
    </Dialog>
  );
}
