import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Button, Grid, TextField, Link } from "@mui/material";
//components
import { useStyles } from "../../../../../components/globalStyles";

import ConfirmDialog from "../../../../../components/ConfirmDialog";

//actions

import {
  postFailedLoginCount,
  getLoginAttemptMax,
} from "../../../../../actions/userActions";

const ResetFailCount = ({
  userId,
  failCount = 0,
  setFailCount = () => null,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const prevSetFailedLoginStatus = useRef();
  const userInfo = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);

  const [alertMsg, setAlertMsg] = useState(false);

  const [alertType, setAlertType] = useState("success");

  const [msgAlarm, setMsgAlarm] = useState(null);

  const [failedLoginCount, setFailedLoginCount] = useState(0);

  const onConfirmSubmit = (event, action) => {
    const submissionJson = {
      user: {
        failed_login_count: failedLoginCount,
        id: userId,
      },
    };

    dispatch(postFailedLoginCount.pending(submissionJson));
  };

  useEffect(() => {
    //set ref when first render
    prevSetFailedLoginStatus.current = false;
  }, []);

  useEffect(() => {
    if (open) {
      dispatch(getLoginAttemptMax.pending());
      setFailedLoginCount(Number(failCount));
    }
  }, [open, failCount, dispatch]);

  useEffect(() => {
    if (
      userInfo.isLoadingPostFailedLoginCount !==
      prevSetFailedLoginStatus.current
    ) {
      //check the previous ref with current state
      prevSetFailedLoginStatus.current = userInfo.isLoadingPostFailedLoginCount;

      if (
        userInfo.postFailedLoginCountIsSuccess === true &&
        userInfo.isLoadingPostFailedLoginCount === false
      ) {
        setAlertMsg(true);
        setAlertType("success");
        setMsgAlarm(t("translation:Login Failed Count has been updated"));
        setFailCount(userInfo.postFailedLoginCount.user.failed_login_count);
      }
      if (
        userInfo.postFailedLoginCountIsSuccess === false &&
        userInfo.isLoadingPostFailedLoginCount === false
      ) {
        setAlertMsg(true);
        setAlertType("error");
        setMsgAlarm(userInfo.error.getLoginAttemptMaxError);
      }
    }
  }, [userInfo, t, setFailCount]);

  return (
    <>
      {open && (
        <ConfirmDialog
          Yes={t("translation:SUBMIT")}
          No={t("translation:BACK")}
          title={t("translation:Reset")}
          open={open}
          setOpen={setOpen}
          maxWidth="sm"
          onConfirm={onConfirmSubmit}
          submitWithClose={false}>
          <Collapse in={alertMsg}>
            <Alert
              severity={alertType}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertMsg(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlarm}
            </Alert>
          </Collapse>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={failedLoginCount}
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "left" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E" ||
                  event?.key === "."
                ) {
                  event.preventDefault();
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setFailedLoginCount(Number(e.target.value))}
              name="failed_login_count"
              label={t("translation:Login Failed Count")}
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={
                userInfo.getLoginAttemptMax
                  ? userInfo.getLoginAttemptMax?.login_attempt_max
                  : 0
              }
              name="login_attempt_max"
              label={t("translation:Max Login Attempts")}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
        </ConfirmDialog>
      )}
      <Link className={classes.boldMaroon075}>
        <Button
          name="resetFailCount"
          className={classes.boldMaroon075}
          onClick={() => setOpen(true)}>
          {t("translation:Reset failed login count")}
        </Button>
      </Link>
    </>
  );
};
export default ResetFailCount;
