//lib
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
//actions
import { postLogOut } from "../../../actions/authActions";

export default function ReSignInPage() {
  //variables
  const dispatch = useDispatch();

  //useEffects
  useEffect(() => {
    dispatch(postLogOut.pending());
  }, [dispatch]);

  return <>loading...</>;
}
