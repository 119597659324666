import * as types from "../_constants/postalCodeSurchargeConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_POSTALCODESURCHARGEBYID_ASYNC.PENDING:
      return {
        ...state,
        getPostalCodeSurchargeByIdIsSuccess: false,
        isLoadingGetPostalCodeSurchargeById: true,
        getPostalCodeSurchargeById: null,
      };

    case types.GET_POSTALCODESURCHARGEBYID_ASYNC.ERROR:
      return {
        ...state,
        getPostalCodeSurchargeByIdIsSuccess: false,
        isLoadingGetPostalCodeSurchargeById: false,
        getPostalCodeSurchargeById: null,
        error: {
          ...state.error,
          getPostalCodeSurchargeByIdError: action.error,
        },
      };

    case types.GET_POSTALCODESURCHARGEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getPostalCodeSurchargeByIdIsSuccess: true,
        isLoadingGetPostalCodeSurchargeById: false,
        getPostalCodeSurchargeById: action.data,
      };

    case types.GET_POSTALCODESURCHARGELIST_ASYNC.PENDING:
      return {
        ...state,
        getPostalCodeSurchargeListIsSuccess: false,
        isLoadingGetPostalCodeSurchargeList: true,
        getPostalCodeSurchargeList: null,
      };

    case types.GET_POSTALCODESURCHARGELIST_ASYNC.ERROR:
      return {
        ...state,
        getPostalCodeSurchargeListIsSuccess: false,
        isLoadingGetPostalCodeSurchargeList: false,
        getPostalCodeSurchargeList: null,
        error: {
          ...state.error,
          getPostalCodeSurchargeListError: action.error,
        },
      };

    case types.GET_POSTALCODESURCHARGELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getPostalCodeSurchargeListIsSuccess: true,
        isLoadingGetPostalCodeSurchargeList: false,
        getPostalCodeSurchargeList: action.data,
      };

    case types.POST_CREATEPOSTALCODESURCHARGE_ASYNC.PENDING:
      return {
        ...state,
        postCreatePostalCodeSurchargeIsSuccess: false,
        isLoadingPostCreatePostalCodeSurcharge: true,
        postCreatePostalCodeSurcharge: null,
      };

    case types.POST_CREATEPOSTALCODESURCHARGE_ASYNC.ERROR:
      return {
        ...state,
        postCreatePostalCodeSurchargeIsSuccess: false,
        isLoadingPostCreatePostalCodeSurcharge: false,
        postCreatePostalCodeSurcharge: null,
        error: {
          ...state.error,
          postCreatePostalCodeSurchargeError: action.error,
        },
      };

    case types.POST_CREATEPOSTALCODESURCHARGE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreatePostalCodeSurchargeIsSuccess: true,
        isLoadingPostCreatePostalCodeSurcharge: false,
        postCreatePostalCodeSurcharge: action.data,
      };

    case types.PUT_UPDATEPOSTALCODESURCHARGE_ASYNC.PENDING:
      return {
        ...state,
        putUpdatePostalCodeSurchargeIsSuccess: false,
        isLoadingPutUpdatePostalCodeSurcharge: true,
        putUpdatePostalCodeSurcharge: null,
      };

    case types.PUT_UPDATEPOSTALCODESURCHARGE_ASYNC.ERROR:
      return {
        ...state,
        putUpdatePostalCodeSurchargeIsSuccess: false,
        isLoadingPutUpdatePostalCodeSurcharge: false,
        putUpdatePostalCodeSurcharge: null,
        error: {
          ...state.error,
          putUpdatePostalCodeSurchargeError: action.error,
        },
      };

    case types.PUT_UPDATEPOSTALCODESURCHARGE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdatePostalCodeSurchargeIsSuccess: true,
        isLoadingPutUpdatePostalCodeSurcharge: false,
        putUpdatePostalCodeSurcharge: action.data,
      };

    default:
      return state;
  }
};
