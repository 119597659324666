//lib
import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Typography,
  CssBaseline,
  Collapse,
  Container,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
//actions
import { postVerifyEmail } from "../../../actions/authActions";
//components
import { useStyles } from "../../../components/globalStyles";

export default function VerifyEmailPage() {
  //variables
  const classes = useStyles();
  const dispatch = useDispatch();
  const parsed = queryString.parse(window.location.search);
  const userAuth = useSelector((state) => state.userAuth);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState("");

  //useEffects
  useEffect(() => {
    dispatch(postVerifyEmail.pending(parsed.token));
  }, [parsed.token, dispatch]);
  useEffect(() => {
    setErrorAlert(false);
    setSuccessAlert(false);
    if (
      userAuth.postVerifyEmailSuccess === true &&
      userAuth.isLodingVerifyEmail === false
    ) {
      setSuccessAlert(true);
      setErrorAlert(false);
      setMsgAlert(userAuth.verifyEmail);
    }

    if (
      userAuth.postVerifyEmailSuccess === false &&
      userAuth.isLodingVerifyEmail === false
    ) {
      setSuccessAlert(false);
      setErrorAlert(true);
      setMsgAlert(userAuth.error.postVerifyEmailError);
    }
  }, [userAuth]);

  const [TCMLogo, setTCMLOGO] = React.useState(null);
  React.useEffect(() => {
    const hostname = window.location.hostname.replace(/[^a-z0-9]/gi, "_");

    if (hostname) {
      let isMounted = true;
      import("../../../assets/" + hostname + "/TCMLogo.svg").then((image) => {
        if (isMounted) {
          setTCMLOGO(image.default);
        }
      });

      return () => {
        isMounted = false;
      };
    }
  }, []);
  return (
    <Container component="main">
      <CssBaseline />
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}

      <div className={classes.paper}>
        <img src={TCMLogo} height="200px" width={"400px"} alt="website logo" />

        <br />
        <Typography className={classes.maroon100} component="h1" variant="h5">
          Email Verification
        </Typography>
        <br />
        <Collapse in={successAlert}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessAlert(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlert}
          </Alert>
        </Collapse>

        <Collapse in={errorAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorAlert(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlert}
          </Alert>
        </Collapse>
      </div>
    </Container>
  );
}
