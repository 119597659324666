import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/medicineTypeConstants";

export const getMedicineTypeList = {
  pending: (data) => createAction(types.GET_MEDICINETYPELIST_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.GET_MEDICINETYPELIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_MEDICINETYPELIST_ASYNC.ERROR, { error }),
};

export const putEditMedicineType = {
  pending: (data) => createAction(types.PUT_EDITMEDICINETYPE_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.PUT_EDITMEDICINETYPE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.PUT_EDITMEDICINETYPE_ASYNC.ERROR, { error }),
};

export const getMedicineType = {
  pending: (data) => createAction(types.GET_MEDICINETYPE_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.GET_MEDICINETYPE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_MEDICINETYPE_ASYNC.ERROR, { error }),
};

export const createMedicineType = {
  pending: (data) => createAction(types.CREATE_MEDICINETYPE_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.CREATE_MEDICINETYPE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.CREATE_MEDICINETYPE_ASYNC.ERROR, { error }),
};

