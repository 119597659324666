import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/packingConstants";

export const putPackingWIPMode = {
  pending: (data) =>
    createAction(types.PUT_PACKINGWIPMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_PACKINGWIPMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_PACKINGWIPMODE_ASYNC.ERROR, { error }),
};

export const getPackingList = {
  pending: (data) =>
    createAction(types.GET_PACKINGLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PACKINGLIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_PACKINGLIST_ASYNC.ERROR, { error }),
};

export const getPackingSummaryList = {
  pending: (data) =>
    createAction(types.GET_PACKINGSUMMARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PACKINGSUMMARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_PACKINGSUMMARYLIST_ASYNC.ERROR, { error }),
};

export const getPackingById = {
  pending: (data) =>
    createAction(types.GET_PACKINGBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PACKINGBYID_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_PACKINGBYID_ASYNC.ERROR, { error }),
};

export const getPackingByOrderNum = {
  pending: (data) =>
    createAction(types.GET_PACKINGBYORDERNUM_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PACKINGBYORDERNUM_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_PACKINGBYORDERNUM_ASYNC.ERROR, { error }),
};

export const postSubmitPacking = {
  pending: (data) =>
    createAction(types.POST_SUBMITPACKING_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITPACKING_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITPACKING_ASYNC.ERROR, { error }),
};

export const putPackingPendingStatus = {
  pending: (data) =>
    createAction(types.PUT_PACKINGPENDINGSTATUS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_PACKINGPENDINGSTATUS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_PACKINGPENDINGSTATUS_ASYNC.ERROR, { error }),
};

export const putPackingCloseMode = {
  pending: (data) =>
    createAction(types.PUT_PACKINGCLOSEMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_PACKINGCLOSEMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_PACKINGCLOSEMODE_ASYNC.ERROR, { error }),
};
