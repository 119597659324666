import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AppointmentSummaryPage from "./AppointmentSummary/AppointmentSummaryPage";

export default function AppointmentPage() {
  return (
    <Switch>
      <Route
        path="/appointment/appointmentSummary"
        component={AppointmentSummaryPage}
      />
      <Redirect to="/error" />
    </Switch>
  );
}
