//lib
import React, { useEffect, useState } from "react";
import { usePDF } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import onlyFixedNumerics from "../functions/onlyFixedNumerics";

//style

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  flexRow: {
    flexDirection: "row",
  },
  flexRow1: {
    marginTop: 20,
  },
  flexColumn: { flexDirection: "column", width: "65%" },
  flexColumn1: { flexDirection: "column", width: "35%", paddingLeft: 5 },
  text: {
    fontSize: "3mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "50%",
    paddingRight: "3mm",
  },
  text1: {
    fontSize: "3mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "100%",
  },
  text2: {
    fontSize: "2.5mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
  },
  text3: {
    fontSize: "3mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "100%",
  },
  text4: {
    fontSize: "4mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "50%",
    paddingRight: "3mm",
  },
  text5: {
    fontSize: "4mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "50%",
    paddingRight: "3mm",
  },
  text6: {
    fontSize: "18mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    width: "80%",
    lineHeight: 1.1,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  qrCodeRow: {
    height: "100px",
    width: "100px",
    right: "5mm",
    top: "5mm",
    position: "absolute",
    textAlign: "center",
  },
  pageNumber: {
    fontSize: "3mm",
    right: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  pagePrinted: {
    fontSize: "3mm",
    left: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  table: {
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    width: "100%",
  },
  table2: {
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    position: "absolute",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1: {
    width: "7%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: "24%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol3: {
    width: "28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol4: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol5: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol6: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol7: {
    width: "11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol8: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    fontSize: "3mm",
    fontFamily: "NotoSansSChinese",
    bottom: "-1mm",
    margin: "0.2mm",
  },
  tableCell2: {
    fontSize: "2.5mm",
    fontFamily: "NotoSansSChinese",
    bottom: "-1mm",
    margin: "auto",
    height: 30,
  },

  totalWeightText: {
    fontSize: "4mm",
    //SUMUP width percent of tableCol1 to tableCol15
    left: "79%",
    position: "absolute",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
  maroon075: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,0.75)",
  },
});

// const CheckTable = () => {
//   return (
//     <View style={styles.table2}>
//       <View style={styles.tableRow}>
//         <View style={styles.tableCol8}>
//           <Text style={styles.tableCell2}>Dispensed by:</Text>
//         </View>
//         <View style={styles.tableCol8}>
//           <Text style={styles.tableCell2}>Checked by:</Text>
//         </View>
//         <View style={styles.tableCol8}>
//           <Text style={styles.tableCell2}>Brewed by:</Text>
//         </View>
//         <View style={styles.tableCol8}>
//           <Text style={styles.tableCell2}>Packed by:</Text>
//         </View>
//       </View>
//     </View>
//   );
// };

const MedicineTable = ({ orderDetailInfo, user, measureUnit, waterAmount }) => {
  let totalWeight = 0;
  let totalWeightPerdDay = 0;
  const [waterAmountValue, setWaterAmountValue] = useState(null);

  const Rows = orderDetailInfo.dispensary_medicine_header
    .map((item, index) => {
      totalWeight = onlyFixedNumerics(
        onlyFixedNumerics(
          item.store_medicine_details.quantity_per_day *
            orderDetailInfo.store_medicine_header.number_of_days
        ) + Number(totalWeight)
      ).toFixed(2);

      totalWeightPerdDay = onlyFixedNumerics(
        onlyFixedNumerics(item.store_medicine_details.quantity_per_day) +
          Number(totalWeightPerdDay)
      ).toFixed(2);

      const measureUnitValue = measureUnit.find(
        (unit) =>
          unit.id ===
          item.store_medicine_details.branch_medicine.medicines.measure_unit
      );

      return (
        <View style={styles.tableRow} key={index}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>{index + 1}</Text>
          </View>
          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>
              {item.store_medicine_details.branch_medicine.physical_location}
            </Text>
          </View>
          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>
              {item.store_medicine_details.medicine_name}
            </Text>
          </View>
          <View style={styles.tableCol4}>
            <Text style={styles.tableCell}>
              {item.store_medicine_details.quantity_per_day.toFixed(2)}
            </Text>
          </View>
          <View style={styles.tableCol5}>
            <Text style={styles.tableCell}>
              {measureUnitValue ? measureUnitValue.name : ""}
            </Text>
          </View>
          <View style={styles.tableCol6}>
            <Text style={styles.tableCell}>
              {(
                item.store_medicine_details.quantity_per_day *
                orderDetailInfo.store_medicine_header.number_of_days
              ).toFixed(2)}
            </Text>
          </View>
          <View style={styles.tableCol7}>
            <Text style={styles.tableCell}>
              {item.store_medicine_details.default_instruction}
            </Text>
          </View>
        </View>
      );
    })
    .sort((a, b) => a.row_no - b.row_no);

  let numberOfPacks = 0;

  if (
    orderDetailInfo.store_medicine_header.max_grams_per_pack &&
    !orderDetailInfo.store_medicine_header.is_packs_override
  ) {
    //poweder
    const gramPerTime = onlyFixedNumerics(
      totalWeightPerdDay / orderDetailInfo.store_medicine_header.number_of_times
    );
    const packsPerTime = Math.ceil(
      onlyFixedNumerics(
        gramPerTime / orderDetailInfo.store_medicine_header.max_grams_per_pack
      )
    );
    const totalPacks = Math.ceil(
      onlyFixedNumerics(
        packsPerTime *
          orderDetailInfo.store_medicine_header.number_of_times *
          orderDetailInfo.store_medicine_header.number_of_days
      )
    );
    numberOfPacks = totalPacks;
  } else if (!orderDetailInfo.store_medicine_header.is_packs_override) {
    if (orderDetailInfo.store_medicine_header.is_brewing_services) {
      //if brew
      numberOfPacks = Math.ceil(
        onlyFixedNumerics(
          orderDetailInfo.store_medicine_header.number_of_days *
            orderDetailInfo.store_medicine_header.number_of_times
        )
      );
    } else {
      numberOfPacks = Math.ceil(
        onlyFixedNumerics(orderDetailInfo.store_medicine_header.number_of_days)
      );
    }
  } else {
    numberOfPacks = Number(orderDetailInfo.store_medicine_header.no_of_packs);
  }
  //useEffects
  useEffect(() => {
    const tempWaterAmountValue = waterAmount.find(
      (unit) => unit.id === orderDetailInfo.store_medicine_header.water_amount
    );
    setWaterAmountValue(tempWaterAmountValue);
  }, [waterAmount, orderDetailInfo]);

  return (
    <View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>序号</Text>
          </View>
          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>位置</Text>
          </View>
          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>药名</Text>
          </View>
          <View style={styles.tableCol4}>
            <Text style={styles.tableCell}>数量 / 天</Text>
          </View>
          <View style={styles.tableCol5}>
            <Text style={styles.tableCell}>单位</Text>
          </View>
          <View style={styles.tableCol6}>
            <Text style={styles.tableCell}>总量</Text>
          </View>
          <View style={styles.tableCol7}>
            <Text style={styles.tableCell}>用法</Text>
          </View>
        </View>
        {Rows}
        <View>
          <Text style={styles.totalWeightText}>{totalWeight}</Text>
        </View>
      </View>
      <View style={styles.flexRow1}>
        <Text style={styles.text5}>
          {orderDetailInfo.store_medicine_header.number_of_days} Days
        </Text>
        {orderDetailInfo.store_medicine_header.is_brewing_services === true ? (
          <Text style={styles.text5}>
            Water Quantity:{" "}
            {waterAmountValue
              ? (
                  Number(totalWeight) * 1.8 +
                  Number(numberOfPacks) *
                    Number(waterAmountValue.name.replace(/[^0-9]/g, ""))
                ).toFixed(2)
              : ""}
          </Text>
        ) : (
          <Text style={styles.text5}>No of Packs: {numberOfPacks}</Text>
        )}
      </View>
      {orderDetailInfo.store_medicine_header.is_brewing_services === true ? (
        <View style={styles.flexRow}>
          <Text style={styles.text5}>
            Per Pack: {waterAmountValue ? waterAmountValue.name : ""}
          </Text>
          <Text style={styles.text5}>No of packs: {numberOfPacks}</Text>
        </View>
      ) : (
        <View style={styles.flexRow}>
          <Text style={styles.text5}>
            Weight Per Pack: {(totalWeight / numberOfPacks).toFixed(2)}
          </Text>
        </View>
      )}

      <Text style={styles.text1}>Prescription Instructions:</Text>
      <Text style={styles.text3}>
        {orderDetailInfo.store_medicine_header.patient_instructions}
      </Text>
      <Text style={styles.text1}>Dispensary Instructions:</Text>
      <Text style={styles.text3}>
        {orderDetailInfo.store_medicine_header.dispensary_instructions}
      </Text>

      {orderDetailInfo.store_medicine_header.is_brewing_services && (
        <>
          <Text style={styles.text1}>Brewing Instructions:</Text>
          <Text style={styles.text3}>
            {orderDetailInfo.store_medicine_header.brewing_instructions}
          </Text>
        </>
      )}

      {orderDetailInfo.store_medicine_header.is_delivery_services && (
        <>
          <Text style={styles.text1}>Delivery Remarks:</Text>
          <Text style={styles.text3}>
            {orderDetailInfo.store_medicine_header.delivery_remarks}
          </Text>
        </>
      )}
    </View>
  );
};

const PDFPage = ({
  orderDetailInfo,
  user,
  measureUnit,
  waterAmount,
  qrCode,
}) => {
  const NRIC = orderDetailInfo.store_header.staff_identification_number;
  const NRICLength = NRIC ? NRIC.length : 0;
  let NRICLastFourChar = NRIC ? NRIC.substring(NRICLength - 4) : null;

  const storeOrderNo = orderDetailInfo?.store_header.order_number;
  const fistOrderNoIndex = storeOrderNo ? storeOrderNo.indexOf("-") : 0;
  const lastOrderNoIndex = storeOrderNo ? storeOrderNo.lastIndexOf("-") : 0;
  const shortStoreOrderNoCode = storeOrderNo.slice(0, fistOrderNoIndex);
  const shortStoreOrderNo = storeOrderNo.slice(lastOrderNoIndex + 1);

  return (
    <Document>
      <Page size="A5" orientation="portrait" style={styles.body}>
        <View style={styles.flexColumn2} fixed></View>

        {qrCode && (
          <Image src={qrCode ? qrCode : ""} style={styles.qrCodeRow} fixed />
        )}
        <View style={styles.flexRow} fixed>
          <Text style={styles.text6}>
            {shortStoreOrderNoCode + shortStoreOrderNo}
          </Text>
        </View>
        <View style={styles.flexRow} fixed>
          <Text style={styles.text}>
            Group Name: {orderDetailInfo.store_medicine_header.name}
          </Text>
          <Text style={styles.text4}>
            {orderDetailInfo.store_medicine_header.medicine_type.name}{" "}
            {orderDetailInfo.store_medicine_header.is_brewing_services ===
              true && <Text style={styles.text4}>煎药</Text>}
          </Text>
        </View>

        <View style={styles.flexRow}>
          <Text style={styles.text}>
            Name: {orderDetailInfo.store_header.staff_name}
            {NRICLastFourChar ? " (" + NRICLastFourChar + ")" : ""}
          </Text>

          <Text style={styles.text}>
            Date:{" "}
            {orderDetailInfo.store_medicine_header.proceed_on
              ? moment
                  .utc(orderDetailInfo.store_medicine_header.proceed_on)
                  .local()
                  .format("DD-MMM-YYYY")
              : moment
                  .utc(orderDetailInfo.store_header.created_on)
                  .local()
                  .format("DD-MMM-YYYY")}
          </Text>
        </View>
        <View style={styles.flexRow}>
          {orderDetailInfo.store_header.visit_branch && (
            <Text style={styles.text}>
              Clinic Name: {orderDetailInfo.store_header.visit_branch.name}
            </Text>
          )}

          {orderDetailInfo.store_header.physician_name && (
            <Text style={styles.text}>
              Physician Name: {orderDetailInfo.store_header.physician_name}
            </Text>
          )}
        </View>

        <MedicineTable
          orderDetailInfo={orderDetailInfo}
          user={user}
          measureUnit={measureUnit}
          waterAmount={waterAmount}
        />
        <View style={styles.pagePrinted} fixed>
          <Text style={styles.text2}>Printed by: {user.name}</Text>
          <Text style={styles.text2}>
            {`${
              orderDetailInfo.first_print_on === null
                ? "Printed on:"
                : "Reprinted on:"
            } ${moment().format("DD-MMM-YYYY")}`}
          </Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
let printNum = 0;
const DispensaryPDF = ({
  orderDetailInfo,
  user,
  t,
  measureUnit,
  waterAmount,
  print,
  setPrintingDate = () => null,
  allowPrint,
  setAllowPrint = () => null,
  qrCode,
}) => {
  const [instance, updateInstance] = usePDF({
    document: (
      <PDFPage
        orderDetailInfo={orderDetailInfo}
        qrCode={qrCode}
        user={user}
        measureUnit={measureUnit}
        waterAmount={waterAmount}
      />
    ),
  });

  useEffect(() => {
    if (
      !instance.loading &&
      instance.url &&
      print !== printNum &&
      !allowPrint
    ) {
      printNum = print;

      setPrintingDate();
    }
  }, [instance.loading, instance.url, printNum, print, allowPrint]);

  useEffect(() => {
    if (!instance.loading && instance.url && allowPrint) {
      //make sure update print date and allow to print
      window.open(instance.url, "_blank", "noreferrer");
      setAllowPrint(false);
    }
  }, [instance.loading, instance.url, allowPrint]);
  return <></>;
  // if (print !== printNum) {
  //   return (
  //     <BlobProvider
  //       document={
  //         <PDFPage
  //           orderDetailInfo={orderDetailInfo}
  //           user={user}
  //           measureUnit={measureUnit}
  //           waterAmount={waterAmount}
  //         />
  //       }>
  //       {({ blob, url, loading, error }) => {
  //         // var file = new blob();
  //         var fileURL = [];
  //         if (!loading && blob && print !== printNum) {
  //           printNum = print;
  //           fileURL = URL.createObjectURL(blob);
  //           window.open(fileURL, "_blank", "noreferrer");
  //           setPrintingDate();
  //         }

  //         // Do whatever you need with blob here
  //         return <></>;
  //       }}
  //     </BlobProvider>
  //   );
  // } else return <></>;
};

export default DispensaryPDF;
