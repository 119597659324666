import * as types from "../_constants/brewingConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.PUT_BREWINGWIPMODE_ASYNC.PENDING:
      return {
        ...state,
        putBrewingWIPModeSuccess: false,
        isLoadingBrewingWIPMode: true,
        brewingWIPMode: null,
      };

    case types.PUT_BREWINGWIPMODE_ASYNC.ERROR:
      return {
        ...state,
        putBrewingWIPModeSuccess: false,
        isLoadingBrewingWIPMode: false,
        brewingWIPMode: null,
        error: { ...state.error, brewingWIPModeError: action.error },
      };

    case types.PUT_BREWINGWIPMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putBrewingWIPModeSuccess: true,
        isLoadingBrewingWIPMode: false,
        brewingWIPMode: action.data,
      };

    case types.PUT_BREWINGPENDINGSTATUS_ASYNC.PENDING:
      return {
        ...state,
        putBrewingPendingStatusSuccess: false,
        isLoadingBrewingPendingStatus: true,
        brewingPendingStatus: null,
      };

    case types.PUT_BREWINGPENDINGSTATUS_ASYNC.ERROR:
      return {
        ...state,
        putBrewingPendingStatusSuccess: false,
        isLoadingBrewingPendingStatus: false,
        brewingPendingStatus: null,
        error: { ...state.error, brewingPendingStatusError: action.error },
      };

    case types.PUT_BREWINGPENDINGSTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        putBrewingPendingStatusSuccess: true,
        isLoadingBrewingPendingStatus: false,
        brewingPendingStatus: action.data,
      };

    case types.GET_BREWINGLIST_ASYNC.PENDING:
      return {
        ...state,
        getBrewingListSuccess: false,
        isLoadingBrewingList: true,
        brewingList: null,
      };

    case types.GET_BREWINGLIST_ASYNC.ERROR:
      return {
        ...state,
        getBrewingListSuccess: false,
        isLoadingBrewingList: false,
        brewingList: null,
        error: { ...state.error, brewingListError: action.error },
      };

    case types.GET_BREWINGLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBrewingListSuccess: true,
        isLoadingBrewingList: false,
        brewingList: action.data,
      };

    case types.GET_BREWINGSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getBrewingSummaryListSuccess: false,
        isLoadingBrewingSummaryList: true,
        brewingSummaryList: null,
      };

    case types.GET_BREWINGSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getBrewingSummaryListSuccess: false,
        isLoadingBrewingSummaryList: false,
        brewingSummaryList: null,
        error: { ...state.error, brewingSummaryListError: action.error },
      };

    case types.GET_BREWINGSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBrewingSummaryListSuccess: true,
        isLoadingBrewingSummaryList: false,
        brewingSummaryList: action.data,
      };

    case types.GET_BREWINGBYID_ASYNC.PENDING:
      return {
        ...state,
        getBrewingByIdSuccess: false,
        isLoadingBrewingById: true,
        brewingById: null,
      };

    case types.GET_BREWINGBYID_ASYNC.ERROR:
      return {
        ...state,
        getBrewingByIdSuccess: false,
        isLoadingBrewingById: false,
        brewingById: null,
        error: { ...state.error, brewingByIdError: action.error },
      };

    case types.GET_BREWINGBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getBrewingByIdSuccess: true,
        isLoadingBrewingById: false,
        brewingById: action.data,
      };

    case types.GET_BREWINGBYORDERNUM_ASYNC.PENDING:
      return {
        ...state,
        getBrewingByOrderNumSuccess: false,
        isLoadingBrewingByOrderNum: true,
        brewingByOrderNum: null,
      };

    case types.GET_BREWINGBYORDERNUM_ASYNC.ERROR:
      return {
        ...state,
        getBrewingByOrderNumSuccess: false,
        isLoadingBrewingByOrderNum: false,
        brewingByOrderNum: null,
        error: { ...state.error, brewingByOrderNumError: action.error },
      };

    case types.GET_BREWINGBYORDERNUM_ASYNC.SUCCESS:
      return {
        ...state,
        getBrewingByOrderNumSuccess: true,
        isLoadingBrewingByOrderNum: false,
        brewingByOrderNum: action.data,
      };

    case types.POST_SUBMITBREWING_ASYNC.PENDING:
      return {
        ...state,
        postSubmitBrewingSuccess: false,
        isLoadingSubmitBrewing: true,
        submitBrewing: null,
      };

    case types.POST_SUBMITBREWING_ASYNC.ERROR:
      return {
        ...state,
        postSubmitBrewingSuccess: false,
        isLoadingSubmitBrewing: false,
        submitBrewing: null,
        error: { ...state.error, submitBrewingError: action.error },
      };

    case types.POST_SUBMITBREWING_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitBrewingSuccess: true,
        isLoadingSubmitBrewing: false,
        submitBrewing: action.data,
      };

    default:
      return state;
  }
};
