import organisationPriceRoundingWGst from "../../../../../components/functions/organisationPriceRoundingWGst";
import organisationPriceRounding from "../../../../../components/functions/organisationPriceRounding";
import * as generalConstants from "../../../../../_constants/generalConstants";
const submisionJSON = (
  currentOrganistion,
  curUser,
  patient,
  physician,
  appointmentmentId,
  accessMode
) => {
  const roundingTemConsultationFee = organisationPriceRounding(
    Number(
      physician.consultation_charge
        ? physician.consultation_charge
        : curUser.defaultBranch.branch.consultation_charge
    ),
    currentOrganistion.order_cat_amt_rmt,
    currentOrganistion.order_cat_amt_rpvt
  );
  const organisationPriceRoundingWGsts = organisationPriceRoundingWGst(
    roundingTemConsultationFee,
    currentOrganistion
  );
  const submission = {
    store_header: {
      id: 0,
      appointment: accessMode ? { id: appointmentmentId } : null,
      staff: patient,
      visit_notes_details: [
        {
          id: 0,
          is_mc_issued: false,
          mc_number_of_days: null,
          mc_reason: null,
          physician_advice: null,
          physician_diagnosis: null,
          pulse_diagnosis: null,
          remarks: null,
          review_notes: null,
          status_type: generalConstants.APPROVED_STATUS_TYPE,
          tongue_diagnosis: null,
          western_diagnosis: null,
        },
      ],
      physician: physician.user,
      consultation_discount_amt: null,
      is_consultation_price_included: true,
      consultation_total_price: Number(roundingTemConsultationFee),
      ...organisationPriceRoundingWGsts,
    },
  };
  return submission;
};

export default submisionJSON;
