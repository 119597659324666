import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/commonConstants";

export const getGender = {
  pending: () => createAction(types.GET_GENDER_ASYNC.PENDING),
  success: (data) => createAction(types.GET_GENDER_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_GENDER_ASYNC.ERROR, { error }),
};

export const getMarital = {
  pending: () => createAction(types.GET_MARITAL_ASYNC.PENDING),
  success: (data) => createAction(types.GET_MARITAL_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_MARITAL_ASYNC.ERROR, { error }),
};

export const getNationality = {
  pending: () => createAction(types.GET_NATIONALITY_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_NATIONALITY_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_NATIONALITY_ASYNC.ERROR, { error }),
};

export const getRace = {
  pending: () => createAction(types.GET_RACE_ASYNC.PENDING),
  success: (data) => createAction(types.GET_RACE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_RACE_ASYNC.ERROR, { error }),
};

export const getPermission = {
  pending: () => createAction(types.GET_PREMISSION_ASYNC.PENDING),
  success: (data) => createAction(types.GET_PREMISSION_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_PREMISSION_ASYNC.ERROR, { error }),
};

export const getViewNeeds = {
  pending: () => createAction(types.GET_VIEWNEEDS_ASYNC.PENDING),
  success: (data) => createAction(types.GET_VIEWNEEDS_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_VIEWNEEDS_ASYNC.ERROR, { error }),
};

export const getRight = {
  pending: () => createAction(types.GET_RIGHTS_ASYNC.PENDING),
  success: (data) => createAction(types.GET_RIGHTS_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_RIGHTS_ASYNC.ERROR, { error }),
};

export const getLanguage = {
  pending: () => createAction(types.GET_LANGUAGE_ASYNC.PENDING),
  success: (data) => createAction(types.GET_LANGUAGE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_LANGUAGE_ASYNC.ERROR, { error }),
};

export const getMedicineConsumeTime = {
  pending: () => createAction(types.GET_MEDICINECONSUMETIME_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_MEDICINECONSUMETIME_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_MEDICINECONSUMETIME_ASYNC.ERROR, { error }),
};

export const getMedicineMeasureUnit = {
  pending: () => createAction(types.GET_MEDICINEMEASUREUNIT_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_MEDICINEMEASUREUNIT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_MEDICINEMEASUREUNIT_ASYNC.ERROR, { error }),
};

export const getGeneralStatus = {
  pending: () => createAction(types.GET_GENERALSTATUS_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_GENERALSTATUS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_GENERALSTATUS_ASYNC.ERROR, { error }),
};

export const getWaterAmount = {
  pending: () => createAction(types.GET_WATERAMOUNT_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_WATERAMOUNT_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_WATERAMOUNT_ASYNC.ERROR, { error }),
};

export const getPaymentMethod = {
  pending: () => createAction(types.GET_PAYMENTMETHOD_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_PAYMENTMETHOD_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_PAYMENTMETHOD_ASYNC.ERROR, { error }),
};

export const getPaymentTerm = {
  pending: () => createAction(types.GET_PAYMENTTERM_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_PAYMENTTERM_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_PAYMENTTERM_ASYNC.ERROR, { error }),
};

export const getUserCreditType = {
  pending: () => createAction(types.GET_USERCREDITTYPE_ASYNC.PENDING),
  success: (data) =>
    createAction(types.GET_USERCREDITTYPE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_USERCREDITTYPE_ASYNC.ERROR, { error }),
};

export const getInOutType = {
  pending: () => createAction(types.GET_INOUTTYPE_ASYNC.PENDING),
  success: (data) => createAction(types.GET_INOUTTYPE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_INOUTTYPE_ASYNC.ERROR, { error }),
};

export const getSystemSettings = {
  pending: (data) =>
    createAction(types.GET_SYSTEMSETTINGS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_SYSTEMSETTINGS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_SYSTEMSETTINGS_ASYNC.ERROR, { error }),
};

export const getMfaType = {
  pending: (data) => createAction(types.GET_MFATYPE_ASYNC.PENDING, { data }),
  success: (data) => createAction(types.GET_MFATYPE_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_MFATYPE_ASYNC.ERROR, { error }),
};

export const getWorkOrderType = {
  pending: (data) =>
    createAction(types.GET_WORKORDERTYPE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_WORKORDERTYPE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_WORKORDERTYPE_ASYNC.ERROR, { error }),
};
