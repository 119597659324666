import * as types from "../_constants/roleRightConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_ROLERIGHTLIST_ASYNC.PENDING:
      return {
        ...state,
        getRoleRightListIsSuccess: false,
        isLoadingRoleRightList: true,
        roleRightList: null,
      };

    case types.GET_ROLERIGHTLIST_ASYNC.ERROR:
      return {
        ...state,
        getRoleRightListIsSuccess: false,
        isLoadingRoleRightList: false,
        roleRightList: null,
        error: { ...state.error, roleRightListError: action.error },
      };

    case types.GET_ROLERIGHTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getRoleRightListIsSuccess: true,
        isLoadingRoleRightList: false,
        roleRightList: action.data,
      };

    case types.PUT_EDITROLERIGHT_ASYNC.PENDING:
      return {
        ...state,
        putEditRoleRightIsSuccess: false,
        isLoadingEditRoleRight: true,
        putEditRoleRight: null,
      };

    case types.PUT_EDITROLERIGHT_ASYNC.ERROR:
      return {
        ...state,
        putEditRoleRightIsSuccess: false,
        isLoadingEditRoleRight: false,
        putEditRoleRight: null,
        error: { ...state.error, EditRoleRightError: action.error },
      };

    case types.PUT_EDITROLERIGHT_ASYNC.SUCCESS:
      return {
        ...state,
        putEditRoleRightIsSuccess: true,
        isLoadingEditRoleRight: false,
        putEditRoleRight: action.data,
      };

    case types.GET_ROLERIGHT_ASYNC.PENDING:
      return {
        ...state,
        getRoleRightIsSuccess: false,
        isLoadingRoleRight: true,
        roleRight: null,
      };

    case types.GET_ROLERIGHT_ASYNC.ERROR:
      return {
        ...state,
        getRoleRightIsSuccess: false,
        isLoadingRoleRight: false,
        roleRight: null,
        error: { ...state.error, roleRightError: action.error },
      };

    case types.GET_ROLERIGHT_ASYNC.SUCCESS:
      return {
        ...state,
        getRoleRightIsSuccess: true,
        isLoadingRoleRight: false,
        roleRight: action.data,
      };

    default:
      return state;
  }
};
