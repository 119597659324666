import { createAsyncTypes } from "../_helper/Utilities";

export const GET_FORMULABYID_ASYNC = createAsyncTypes("GET_FORMULABYID");

export const GET_FORMULALIST_ASYNC = createAsyncTypes("GET_FORMULALIST");

export const POST_CREATEFORMULA_ASYNC = createAsyncTypes("POST_CREATEFORMULA");

export const PUT_UPDATEFORMULA_ASYNC = createAsyncTypes("PUT_UPDATEFORMULA");

export const GET_FORMULADISPENSARYBRANCH_ASYNC = createAsyncTypes(
  "GET_FORMULADISPENSARYBRANCH"
);

export const GET_FORMULAMEDICINETYPEBYID_ASYNC = createAsyncTypes(
  "GET_FORMULAMEDICINETYPEBYID"
);
