import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  postBranchMedicineBranchMedicineHistoryList,
  postBranchMedicineMedicineList,
  postBranchMedicineMedicineTypeList,
  postBranchMedicineBranchList,
  putUpdateBranchMedicine,
  getBranchMedicineById,
  getBranchMedicineList,
} from "../actions/branchMedicineActions";
import * as types from "../_constants/branchMedicineConstants";
import * as notification from "../_constants/notificationConstants";
import branchMedicineApis from "../_apis/branchMedicineApis";
export default function* branchMedicineSagas() {
  yield all([
    takeLatest(
      types.GET_BRANCHMEDICINELIST_ASYNC.PENDING,
      getBranchMedicineListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_BRANCHMEDICINEBYID_ASYNC.PENDING,
      getBranchMedicineByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEBRANCHMEDICINE_ASYNC.PENDING,
      putUpdateBranchMedicineCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHMEDICINEBRANCHLIST_ASYNC.PENDING,
      postBranchMedicineBranchListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHMEDICINEMEDICINETYPELIST_ASYNC.PENDING,
      postBranchMedicineMedicineTypeListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHMEDICINEMEDICINELIST_ASYNC.PENDING,
      postBranchMedicineMedicineListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHMEDICINEBRANCHMEDICINEHISTORYLIST_ASYNC.PENDING,
      postBranchMedicineBranchMedicineHistoryListCall
    ),
  ]);
}

function* getBranchMedicineListCall(Model) {
  try {
    const data = yield call(
      branchMedicineApis.getBranchMedicineListApi,
      Model.data
    );
    yield put(getBranchMedicineList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getBranchMedicineList.error(error.response?.data?.message));
    else yield put(getBranchMedicineList.error(notification.CONST_NOINTERNET));
  }
}

function* getBranchMedicineByIdCall(Model) {
  try {
    const data = yield call(
      branchMedicineApis.getBranchMedicineByIdApi,
      Model.data
    );
    yield put(getBranchMedicineById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getBranchMedicineById.error(error.response?.data?.message));
    else yield put(getBranchMedicineById.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateBranchMedicineCall(Model) {
  try {
    const data = yield call(
      branchMedicineApis.putUpdateBranchMedicineApi,
      Model.data
    );
    yield put(putUpdateBranchMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateBranchMedicine.error(error.response?.data?.message));
    else
      yield put(putUpdateBranchMedicine.error(notification.CONST_NOINTERNET));
  }
}

function* postBranchMedicineBranchListCall(Model) {
  try {
    const data = yield call(
      branchMedicineApis.postBranchMedicineBranchListApi,
      Model.data
    );
    yield put(postBranchMedicineBranchList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchMedicineBranchList.error(error.response?.data?.message)
      );
    else
      yield put(
        postBranchMedicineBranchList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postBranchMedicineMedicineTypeListCall(Model) {
  try {
    const data = yield call(
      branchMedicineApis.postBranchMedicineMedicineTypeListApi,
      Model.data
    );
    yield put(postBranchMedicineMedicineTypeList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchMedicineMedicineTypeList.error(error.response?.data?.message)
      );
    else
      yield put(
        postBranchMedicineMedicineTypeList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postBranchMedicineMedicineListCall(Model) {
  try {
    const data = yield call(
      branchMedicineApis.postBranchMedicineMedicineListApi,
      Model.data
    );
    yield put(postBranchMedicineMedicineList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchMedicineMedicineList.error(error.response?.data?.message)
      );
    else
      yield put(
        postBranchMedicineMedicineList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postBranchMedicineBranchMedicineHistoryListCall(Model) {
  try {
    const data = yield call(
      branchMedicineApis.postBranchMedicineBranchMedicineHistoryListApi,
      Model.data
    );
    yield put(postBranchMedicineBranchMedicineHistoryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchMedicineBranchMedicineHistoryList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postBranchMedicineBranchMedicineHistoryList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}
