import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AcupointSummary from "./AcupointSummary/AcupointSummaryPage";
export default function AcupointPage() {
  return (
    <Switch>
      <Redirect exact={true} from="/acupoint" to="/acupoint/AcupointSummary" />
      <Route path="/acupoint/AcupointSummary" component={AcupointSummary} />

      <Redirect to="/error" />
    </Switch>
  );
}
