import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import * as generalConstants from "../../../../../_constants/generalConstants";
import {
  getVisitTreatmentTypes,
  postVisitTreatments,
} from "../../../../../actions/visitOrderActions";

//style
import { useStyles } from "../../../../../components/globalStyles";

export default function EditTreatment({
  memoGroupOption,
  groupOption,
  setGroupOption,
  group,
  setGroup,
  memoSelectedGroupIndex,
  memoSelectedGroup,
  treatmentList,
  setTreatmentList,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [disableTreatment, setDisableTreatment] = useState(true);
  const [selectedTreatmentItem, setSelectedTreatmentItem] = useState("");
  const [selectedTreatmentTypeItem, setSelectedTreatmentTypeItem] =
    useState(null);
  const visitOrder = useSelector((state) => state.visitOrder);

  useEffect(() => {
    dispatch(getVisitTreatmentTypes.pending());
  }, [dispatch]);

  const handleSelectedTreatment = (event, newValue) => {
    const selectedVisitTreatment = {
      id: 0,
      // create quantity
      quantity: null,
      treatment_name: newValue.name,
      unit_selling_price: newValue.unit_selling_price,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
      treatment: newValue,
    };
    let visitTreatmentDetail;

    //checking same medicine and dont allow same medicine exist in the same group
    const repeatTreatment = treatmentList.some(
      (item) =>
        item.treatment.id === newValue.id &&
        item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
    //find array that status_type === 7 (deleted)
    const repeatDeletedTreatmentIndex = treatmentList.findIndex(
      (item) =>
        item.treatment.id === newValue.id &&
        item.status_type === generalConstants.DELETED_STATUS_TYPE
    );

    if (repeatTreatment) {
      visitTreatmentDetail = [...treatmentList];
    } else if (repeatDeletedTreatmentIndex !== -1) {
      visitTreatmentDetail = [...treatmentList];

      visitTreatmentDetail[repeatDeletedTreatmentIndex] = {
        ...visitTreatmentDetail[repeatDeletedTreatmentIndex],
        //id: (use back existing)
        // create quantity
        quantity: null,
        treatment_name: newValue.name,
        unit_selling_price: newValue.unit_selling_price,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
        treatment: newValue,
      };
    } else {
      visitTreatmentDetail = [...treatmentList, selectedVisitTreatment];
    }

    let resultWDelete = visitTreatmentDetail.filter(
      (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
    );
    let resultWoDelete = visitTreatmentDetail.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
    resultWDelete = resultWDelete.map((x, index) => {
      return { ...x, row_no: null };
    });
    resultWoDelete = resultWoDelete.map((x, index) => {
      return { ...x, row_no: index + 1 };
    });

    setTreatmentList([...resultWoDelete, ...resultWDelete]);
  };

  const handleSetTreatmentTypeChoice = (event, option) => {
    setSelectedTreatmentTypeItem(option);
    const treatType = { treatment_type: option };
    dispatch(postVisitTreatments.pending(treatType));
    setDisableTreatment(false);
  };

  return (
    <Grid container>
      <Grid item xs={6} className={classes.userInfoField}>
        <Autocomplete
          value={selectedTreatmentTypeItem}
          disableClearable
          name="treatment_types"
          id="treatment_types"
          options={
            visitOrder.treatmentTypesList
              ? visitOrder.treatmentTypesList.treatment_types.filter(
                  (item) =>
                    item.status_type === generalConstants.APPROVED_STATUS_TYPE
                )
              : []
          }
          getOptionLabel={(option) => (option.name ? option.name : "")}
          style={{ width: "100%" }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, option) => handleSetTreatmentTypeChoice(e, option)}
          renderInput={(params) => (
            <TextField
              className={classes.userFieldColor}
              {...params}
              label={t("translation:Treatment Type")}
              variant="outlined"
            />
          )}
        />
      </Grid>

      <Grid item xs={6} className={classes.userInfoField}>
        <Autocomplete
          disabled={disableTreatment}
          inputValue={selectedTreatmentItem}
          value={selectedTreatmentItem == null ? "" : selectedTreatmentItem}
          name="treatment"
          id="treatment"
          options={
            visitOrder.visitTreatmentsList
              ? visitOrder.visitTreatmentsList.treatments.filter(
                  (item) =>
                    item.status_type === generalConstants.APPROVED_STATUS_TYPE
                )
              : []
          }
          getOptionLabel={(option) => (option.name ? option.name : "")}
          style={{ width: "100%" }}
          isOptionEqualToValue={() => {
            return true;
          }}
          disableClearable
          onChange={(e, option) => {
            handleSelectedTreatment(e, option);
            setSelectedTreatmentItem("");
          }}
          disablePortal
          sx={{
            "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
              '&[aria-selected="true"]': {
                backgroundColor: "rgba(255,255,255)",
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              //clear the field to empty
              onChange={(event) => {
                setSelectedTreatmentItem(event.target.value);
              }}
              className={classes.userFieldColor}
              label={<span>{t("translation:Treatment")}</span>}
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
