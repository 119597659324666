import { createAsyncTypes } from "../_helper/Utilities";

export const GET_FILTERRULEBYID_ASYNC = createAsyncTypes("GET_FILTERRULEBYID");

export const GET_FILTERRULELIST_ASYNC = createAsyncTypes("GET_FILTERRULELIST");

export const POST_CREATEFILTERRULE_ASYNC = createAsyncTypes(
  "POST_CREATEFILTERRULE"
);

export const PUT_UPDATEFILTERRULE_ASYNC = createAsyncTypes(
  "PUT_UPDATEFILTERRULE"
);

export const GET_ALLMODELNAMES_ASYNC = createAsyncTypes("GET_ALLMODELNAMES");
