import * as types from "../_constants/authConstants";
import * as curUserTypes from "../_constants/curUserConstants";
const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case curUserTypes.PUT_USERINFO_ASYNC.PENDING:
      return {
        ...state,
        putUserInfoSuccess: false,
        isLoadingUserInfo: true,
        //putUserInfo: null,
      };

    case curUserTypes.PUT_USERINFO_ASYNC.ERROR:
      return {
        ...state,
        putUserInfoSuccess: false,
        isLoadingUserInfo: false,
        //putUserInfo: null,
        error: { ...state.error, putUserInfoError: action.error },
      };

    case curUserTypes.PUT_USERINFO_ASYNC.SUCCESS:
      return {
        ...state,
        putUserInfoSuccess: true,
        isLoadingUserInfo: false,
        //putUserInfo: action.data,
        //update userInfo
        systemUserInfo: action.data.user,
      };

    case types.POST_REGISTRATION_ASYNC.PENDING:
      return {
        ...state,
        postRegistrationSuccess: false,
        isLoadingRegistration: true,
        userList: null,
      };

    case types.POST_REGISTRATION_ASYNC.ERROR:
      return {
        ...state,
        postRegistrationSuccess: false,
        isLoadingRegistration: false,
        userList: null,
        error: { ...state.error, registrationError: action.error },
      };

    case types.POST_REGISTRATION_ASYNC.SUCCESS:
      return {
        ...state,
        postRegistrationSuccess: true,
        isLoadingRegistration: false,
        userList: action.data,
      };

    case types.POST_SELFREGISTRATION_ASYNC.PENDING:
      return {
        ...state,
        postSelfRegistrationSuccess: false,
        isLoadingSelfRegistration: true,
        selfRegistration: null,
      };

    case types.POST_SELFREGISTRATION_ASYNC.ERROR:
      return {
        ...state,
        postSelfRegistrationSuccess: false,
        isLoadingSelfRegistration: false,
        selfRegistration: null,
        error: { ...state.error, selfRegistrationError: action.error },
      };

    case types.POST_SELFREGISTRATION_ASYNC.SUCCESS:
      return {
        ...state,
        postSelfRegistrationSuccess: true,
        isLoadingSelfRegistration: false,
        selfRegistration: action.data,
      };

    case types.POST_LOGIN_ASYNC.PENDING:
      return {
        logInSuccess: false,
        isLoadinglogIn: true,
        systemUserInfo: null,
      };

    case types.POST_LOGIN_ASYNC.ERROR:
      return {
        logInSuccess: false,
        isLoadinglogIn: false,
        systemUserInfo: null,
        error: { ...state.error, logInError: action.error },
      };

    case types.POST_LOGIN_ASYNC.SUCCESS:
      return {
        logInSuccess: true,
        isLoadinglogIn: false,
        logOutSuccess: false,
        systemUserInfo: action.data.user ? action.data.user : action.data,
      };

    case types.POST_LOGOUT_ASYNC.PENDING:
      return {
        ...state,
        logOutSuccess: false,
        isLoadinglogOut: true,
        //systemUserInfo: null,
      };

    case types.POST_LOGOUT_ASYNC.ERROR:
      return {
        ...state,
        logOutSuccess: false,
        isLoadinglogOut: false,
        //systemUserInfo: null,
        error: { ...state.error, logInError: action.error },
      };

    case types.POST_LOGOUT_ASYNC.SUCCESS:
      return {
        logOutSuccess: true,
        isLoadinglogOut: false,
        systemUserInfo: null,
      };

    case types.POST_RESETPASSWORD_ASYNC.PENDING:
      return {
        ...state,
        resetPasswordSuccess: false,
        isLoadingResetPassword: true,
        userResetPassword: null,
      };

    case types.POST_RESETPASSWORD_ASYNC.ERROR:
      return {
        ...state,
        resetPasswordSuccess: false,
        isLoadingResetPassword: false,
        userResetPassword: null,
        error: { ...state.error, resetPasswordError: action.error },
      };

    case types.POST_RESETPASSWORD_ASYNC.SUCCESS:
      return {
        resetPasswordSuccess: true,
        isLoadingResetPassword: false,
        userResetPassword: action.data,
      };

    case types.POST_RESETPASSWORDRQ_ASYNC.PENDING:
      return {
        ...state,
        resetPasswordRqSuccess: false,
        isLoadingResetPasswordRq: true,
        userResetPasswordRq: null,
      };

    case types.POST_RESETPASSWORDRQ_ASYNC.ERROR:
      return {
        ...state,
        resetPasswordRqSuccess: false,
        isLoadingResetPasswordRq: false,
        userResetPasswordRq: null,
        error: { ...state.error, resetPasswordRqError: action.error },
      };

    case types.POST_RESETPASSWORDRQ_ASYNC.SUCCESS:
      return {
        resetPasswordRqSuccess: true,
        isLoadingResetPasswordRq: false,
        userResetPasswordRq: action.data,
      };

    case types.PUT_CHANGEPASSWORD_ASYNC.PENDING:
      return {
        ...state,
        changePasswordSuccess: false,
        isLodingChangePassword: true,
        changePassword: null,
      };

    case types.PUT_CHANGEPASSWORD_ASYNC.ERROR:
      return {
        ...state,
        changePasswordSuccess: false,
        isLodingChangePassword: false,
        changePassword: null,
        error: { ...state.error, changePasswordError: action.error },
      };

    case types.PUT_CHANGEPASSWORD_ASYNC.SUCCESS:
      return {
        ...state,
        changePasswordSuccess: true,
        isLodingChangePassword: false,
        changePassword: action.data,
      };

    case types.POST_VERIFYEMAILRQ_ASYNC.PENDING:
      return {
        ...state,
        postVerifyEmailRqSuccess: false,
        isLodingVerifyEmailRq: true,
        verifyEmailRq: null,
      };

    case types.POST_VERIFYEMAILRQ_ASYNC.ERROR:
      return {
        ...state,
        postVerifyEmailRqSuccess: false,
        isLodingVerifyEmailRq: false,
        verifyEmailRq: null,
        error: { ...state.error, postVerifyEmailRqError: action.error },
      };

    case types.POST_VERIFYEMAILRQ_ASYNC.SUCCESS:
      return {
        ...state,
        postVerifyEmailRqSuccess: true,
        isLodingVerifyEmailRq: false,
        verifyEmailRq: action.data,
      };

    case types.POST_VERIFYEMAIL_ASYNC.PENDING:
      return {
        ...state,
        postVerifyEmailSuccess: false,
        isLodingVerifyEmail: true,
        verifyEmail: null,
      };

    case types.POST_VERIFYEMAIL_ASYNC.ERROR:
      return {
        ...state,
        postVerifyEmailSuccess: false,
        isLodingVerifyEmail: false,
        verifyEmail: null,
        error: { ...state.error, postVerifyEmailError: action.error },
      };

    case types.POST_VERIFYEMAIL_ASYNC.SUCCESS:
      return {
        ...state,
        postVerifyEmailSuccess: true,
        isLodingVerifyEmail: false,
        verifyEmail: action.data,
      };

    case types.GET_PROVIDERAUTHORIZE_ASYNC.PENDING:
      return {
        ...state,
        getProviderAuthorizeIsSuccess: false,
        isLodingGetProviderAuthorize: true,
        getProviderAuthorize: null,
      };

    case types.GET_PROVIDERAUTHORIZE_ASYNC.ERROR:
      return {
        ...state,
        getProviderAuthorizeIsSuccess: false,
        isLodingGetProviderAuthorize: false,
        getProviderAuthorize: null,
        error: { ...state.error, getProviderAuthorizeError: action.error },
      };

    case types.GET_PROVIDERAUTHORIZE_ASYNC.SUCCESS:
      return {
        ...state,
        getProviderAuthorizeIsSuccess: true,
        isLodingGetProviderAuthorize: false,
        getProviderAuthorize: action.data,
      };

    case types.POST_PROVIDERACCESSTOKEN_ASYNC.PENDING:
      return {
        ...state,
        logInSuccess: false,
        logOutSuccess: false,
        isLoadinglogIn: true,
        postProviderAccessTokenIsSuccess: false,
        isLodingPostProviderAccessToken: true,
        postProviderAccessToken: null,
        systemUserInfo: null,
      };

    case types.POST_PROVIDERACCESSTOKEN_ASYNC.ERROR:
      return {
        ...state,
        logInSuccess: false,
        logOutSuccess: false,
        isLoadinglogIn: false,
        postProviderAccessTokenIsSuccess: false,
        isLodingPostProviderAccessToken: false,
        postProviderAccessToken: null,
        systemUserInfo: null,
        error: {
          ...state.error,
          postProviderAccessTokenError: action.error,
          logInError: action.error,
        },
      };

    case types.POST_PROVIDERACCESSTOKEN_ASYNC.SUCCESS:
      return {
        ...state,
        logInSuccess: action.data.user ? true : false,
        logOutSuccess: action.data.user ? false : true,
        isLoadinglogIn: false,
        systemUserInfo: action.data.user ? action.data.user : action.data,
        postProviderAccessTokenIsSuccess: true,
        isLodingPostProviderAccessToken: false,
        postProviderAccessToken: action.data,
      };
    case types.POST_OTPCALLBACK_ASYNC.ERROR:
      return {
        ...state,
        logInSuccess: false,
        logOutSuccess: false,
        isLoadinglogIn: false,
        systemUserInfo: null,
        postOtpCallbackIsSuccess: false,
        isLodingPostOtpCallback: false,
        postOtpCallback: null,
        error: {
          ...state.error,
          postOtpCallbackError: action.error,
          logInError: action.error,
        },
      };

    case types.POST_OTPCALLBACK_ASYNC.SUCCESS:
      return {
        ...state,
        logInSuccess: true,
        logOutSuccess: false,
        isLoadinglogIn: false,
        systemUserInfo: action.data.user,
        postOtpCallbackIsSuccess: true,
        isLodingPostOtpCallback: false,
        postOtpCallback: action.data,
      };

    case types.POST_OTPCALLBACK_ASYNC.PENDING:
      return {
        ...state,
        logInSuccess: false,
        logOutSuccess: false,
        isLoadinglogIn: true,
        systemUserInfo: null,
        postOtpCallbackIsSuccess: false,
        isLodingPostOtpCallback: true,
        postOtpCallback: null,
      };
    default:
      return state;
  }
};
