import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const fulfillmentApis = {
  getFulfillmentSummaryListApi,
  putFulfillmentColourHexCodeApi,
};

async function getFulfillmentSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(
      apiConstants.apiGetFulfillmentSummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putFulfillmentColourHexCodeApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(
      apiConstants.apiPutFulfillmentColourHexCode,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default fulfillmentApis;
