import React from "react";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useStyles } from "./globalStyles";
import TextField from "@mui/material/TextField";
import Close from "@mui/icons-material/Close";
import debounce from "debounce";
import noEnterSubmitForm from "../components/functions/noEnterSubmitForm";
//style

const ConfirmDialog = (props) => {
  const classes = useStyles();

  const {
    Yes,
    No,
    title,
    children,
    open,
    setOpen,
    onConfirm = () => null,
    requiredText,
    requiredField = true,
    textLabel,
    textValue,
    setTextValue,
    maxWidth = "sm",
    submitWithClose = true,
  } = props;

  const onConfirmDebounce = debounce(onConfirm, 2000, true);
  const onConfirmDebounceCallBack = React.useCallback(onConfirmDebounce, [
    onConfirmDebounce,
  ]);

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setOpen(false);
        }
      }}
      aria-labelledby="confirm-dialog"
      fullWidth>
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => setOpen(false)}
      />
      <form onKeyDown={(e) => noEnterSubmitForm(e)}>
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>
          {children}
          {requiredText && (
            <TextField
              value={textValue}
              required={requiredField}
              variant="outlined"
              margin="normal"
              name={textLabel}
              label={textLabel}
              onChange={(e) => setTextValue(e.target.value)}
              fullWidth
            />
          )}
        </DialogContent>
        <DialogActions>
          {Yes && (
            <Link className={classes.boldMaroon075}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.currentTarget.form.reportValidity();
                  var checkVal = e.currentTarget.form.checkValidity();
                  if (checkVal) {
                    onConfirmDebounceCallBack(textValue);
                    if (submitWithClose) {
                      setOpen(false);
                    }
                  }
                }}
                className={classes.boldMaroon075}
                cy_data="confirmButton">
                {Yes}
              </Button>
            </Link>
          )}
          {No && (
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                onClick={() => setOpen(false)}
                cy_data="rejectButton">
                {No}
              </Button>
            </Link>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfirmDialog;
