import React from "react";
import TextField from "@mui/material/TextField";

export default function EditableCell({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  editableNum,
  editableWholeNum,
}) {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  const onChangeNum = (e) => {
    setValue(e.target.value);
    //
    updateMyData(
      index,
      id,
      e.target.value && e.target.value !== 0 ? Number(e.target.value) : null
    );
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (editableNum) {
    return (
      <TextField
        value={value !== undefined && value !== null ? value : ""}
        variant="standard"
        onChange={onChangeNum}
        type="number"
        onWheel={(event) => event.target.blur()}
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
            style: { textAlign: "left" },
          },
        }}
        onKeyDown={(event) => {
          if (
            event?.key === "-" ||
            event?.key === "+" ||
            event?.key === "e" ||
            event?.key === "E"
          ) {
            event.preventDefault();
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  if (editableWholeNum) {
    return (
      <TextField
        value={value !== undefined && value !== null ? value : ""}
        variant="standard"
        onChange={onChangeNum}
        type="number"
        onWheel={(event) => event.target.blur()}
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
            style: { textAlign: "left" },
          },
        }}
        onKeyDown={(event) => {
          if (
            event?.key === "-" ||
            event?.key === "+" ||
            event?.key === "e" ||
            event?.key === "E" ||
            event?.key === "."
          ) {
            event.preventDefault();
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  if (!editable) {
    return `${initialValue}`;
  }

  return (
    <TextField
      value={value ? value : ""}
      variant="standard"
      onChange={onChange}
      onBlur={onBlur}
      type="text"
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}
