//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField, Link, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";
//action

import {
  getMedicineMeasureUnit,
  getMedicineConsumeTime,
} from "../../../actions/commonActions";
//components
import Panel from "../../../components/Panel";
import ViewMedicineListTable from "./ViewMedicineListTable";
import { useStyles } from "../../../components/globalStyles";
import RequiredNote from "../../../components/RequiredNote";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import CheckerPhoto from "../Dispensary/components/CheckerPhoto";

const OrderDetailPage = ({ setOrderDetailView, orderDetailInfo }) => {
  //
  //variables
  const defaultMedicineGroup = orderDetailInfo.store_medicine_header;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [medicineConsumeInfo] = useState({
    number_of_days: defaultMedicineGroup.number_of_days,
    number_of_times: defaultMedicineGroup.number_of_times,
    when_consume: defaultMedicineGroup.when_consume,
    dispensary_instructions: defaultMedicineGroup.dispensary_instructions,
    patient_instructions: defaultMedicineGroup.patient_instructions,
    is_brewing_services: defaultMedicineGroup.is_brewing_services,
    brewing_instructions: defaultMedicineGroup.brewing_instructions,
    water_amount: defaultMedicineGroup.water_amount,
    // is_delivery_services: defaultMedicineGroup.is_delivery_services,
    // delivery_address: defaultMedicineGroup.delivery_address,
    // delivery_contact_number: defaultMedicineGroup.delivery_contact_number,
    // delivery_name: defaultMedicineGroup.delivery_name,
    // delivery_remarks: defaultMedicineGroup.delivery_remarks,
    // delivery_incl_prescription: defaultMedicineGroup.delivery_incl_prescription,
    // delivery_incl_receipt: defaultMedicineGroup.delivery_incl_receipt,
  });

  //
  const [medicineList] = useState(
    orderDetailInfo.store_medicine_header.store_medicine_details
  );
  const isRawHerb = useState(
    orderDetailInfo.store_medicine_header.medicine_type.is_for_brewing
  );

  const [open, setOpen] = React.useState(false);
  //redux variable

  const common = useSelector((state) => state.common);

  //functions

  const getMedicineCosumeTimeOpObj = (option) => {
    if (!option.id)
      option = common.medicineCosumeTimeList.when_consume_select_field.find(
        (op) => op.id === option
      );
    return option;
  };
  const getWaterAmountOpObj = (option) => {
    if (!option.id)
      option = common.waterAmountList.water_amount_select_field.find(
        (op) => op.id === option
      );
    return option;
  };
  //useEffects

  useEffect(() => {
    dispatch(getMedicineConsumeTime.pending());
    dispatch(getMedicineMeasureUnit.pending());
  }, [dispatch]);

  return (
    <div>
      <Typography display="block" variant="h4">
        {orderDetailInfo.store_header?.order_number}
      </Typography>

      <Typography display="block" variant="h4">
        {orderDetailInfo.store_medicine_header?.name}
      </Typography>
      <form>
        <br />

        <Grid>
          <div>
            <Panel>
              <Grid container>
                <Link className={classes.boldMaroon075}>
                  <Button
                    className={classes.boldMaroon075}
                    onClick={() => setOpen(true)}
                    cy_data="rejectButton">
                    {t("translation:Check Photo")}
                  </Button>
                </Link>
                {open && (
                  <CheckerPhoto
                    open={open}
                    setOpen={setOpen}
                    organisationCode={orderDetailInfo.organisation.codename}
                    folderPath="packing"
                    uploadFileObjects={orderDetailInfo}
                  />
                )}
                <Grid item xs={12} className={classes.userInfoField}>
                  <ViewMedicineListTable
                    data={medicineList.sort((a, b) => a.row_no - b.row_no)}
                    isRawHerb={isRawHerb}
                    t={t}
                    measureUnit={
                      common.medicineMeasureUnitList
                        ? common.medicineMeasureUnitList.measure_units
                        : []
                    }
                  />
                </Grid>

                <Grid item xs={4} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    type="number"
                    onWheel={(event) => event.target.blur()}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                        style: { textAlign: "right" },
                      },
                    }}
                    onKeyDown={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "e" ||
                        event?.key === "E"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    value={
                      medicineConsumeInfo.number_of_days !== null
                        ? medicineConsumeInfo.number_of_days
                        : ""
                    }
                    disabled
                    required
                    id="Day"
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    name="number_of_days"
                    label={t("translation:Day")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      medicineConsumeInfo.number_of_times !== null
                        ? medicineConsumeInfo.number_of_times
                        : ""
                    }
                    type="number"
                    onWheel={(event) => event.target.blur()}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                        style: { textAlign: "right" },
                      },
                    }}
                    onKeyDown={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "e" ||
                        event?.key === "E"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    disabled
                    required
                    id="Times"
                    name="number_of_times"
                    label={t("translation:Times")}
                    variant="outlined"
                    onFocus={(event) => {
                      event.target.select();
                    }}
                  />
                </Grid>
                <Grid item xs={4} className={classes.userInfoField}>
                  {common.medicineCosumeTimeList && (
                    <Autocomplete
                      value={medicineConsumeInfo.when_consume}
                      name="when_consume"
                      disabled
                      options={
                        common.medicineCosumeTimeList.when_consume_select_field
                      }
                      getOptionLabel={(option) =>
                        getMedicineCosumeTimeOpObj(option).name
                      }
                      style={{ width: "100%" }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          className={classes.userFieldColor}
                          {...params}
                          name="when_consume"
                          label={t("translation:When")}
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={medicineConsumeInfo.dispensary_instructions}
                    multiline
                    rows={3}
                    name="dispensary_instructions"
                    label={t("translation:Dispensary Instructions")}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={medicineConsumeInfo.patient_instructions}
                    multiline
                    rows={3}
                    name="patient_instructions"
                    label={t("translation:Prescription Instructions")}
                    variant="outlined"
                    disabled
                  />
                </Grid>
              </Grid>
            </Panel>
          </div>

          <>
            <RequiredNote />
            <PageOnlyBackAction setView={() => setOrderDetailView(false)} />
          </>
        </Grid>
      </form>
    </div>
  );
};

export default OrderDetailPage;
