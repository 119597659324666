import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  getDeliveryCutoffScheduleList,
  getDeliveryCutoffScheduleById,
  putUpdateDeliveryCutoffSchedule,
  postSearchDeliveryCutoffScheduleBranch,
} from "../actions/deliveryCutoffScheduleActions";
import * as types from "../_constants/deliveryCutOffConstants";
import * as notification from "../_constants/notificationConstants";
import deliveryCutoffScheduleApis from "../_apis/deliveryCutoffScheduleApis";
export default function* deliveryCutoffScheduleSagas() {
  yield all([
    takeLatest(
      types.GET_DELIVERYCUTOFFSCHEDULELIST_ASYNC.PENDING,
      getDeliveryCutoffScheduleListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_DELIVERYCUTOFFSCHEDULEBYID_ASYNC.PENDING,
      getDeliveryCutoffScheduleByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_UPDATEDELIVERYCUTOFFSCHEDULE_ASYNC.PENDING,
      putUpdateDeliveryCutoffScheduleCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_SEARCHDELIVERYCUTOFFSCHEDULEBRANCH_ASYNC.PENDING,
      postSearchDeliveryCutoffScheduleBranchCall
    ),
  ]);
}

function* getDeliveryCutoffScheduleListCall(Model) {
  try {
    const data = yield call(
      deliveryCutoffScheduleApis.getDeliveryCutoffScheduleListApi,
      Model.data
    );
    yield put(getDeliveryCutoffScheduleList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryCutoffScheduleList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryCutoffScheduleList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryCutoffScheduleByIdCall(Model) {
  try {
    const data = yield call(
      deliveryCutoffScheduleApis.getDeliveryCutoffScheduleByIdApi,
      Model.data
    );
    yield put(getDeliveryCutoffScheduleById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryCutoffScheduleById.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryCutoffScheduleById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateDeliveryCutoffScheduleCall(Model) {
  try {
    const data = yield call(
      deliveryCutoffScheduleApis.putUpdateDeliveryCutoffScheduleApi,
      Model.data
    );
    yield put(putUpdateDeliveryCutoffSchedule.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateDeliveryCutoffSchedule.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdateDeliveryCutoffSchedule.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postSearchDeliveryCutoffScheduleBranchCall(Model) {
  try {
    const data = yield call(
      deliveryCutoffScheduleApis.postSearchDeliveryCutoffScheduleBranchApi,
      Model.data
    );
    yield put(postSearchDeliveryCutoffScheduleBranch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postSearchDeliveryCutoffScheduleBranch.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postSearchDeliveryCutoffScheduleBranch.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}
