import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/nonBusinessDayConstants";

export const getNonBusinessDayList = {
  pending: (data) =>
    createAction(types.GET_NONBUSINESSDAYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_NONBUSINESSDAYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_NONBUSINESSDAYLIST_ASYNC.ERROR, { error }),
};

export const getNonBusinessDayById = {
  pending: (data) =>
    createAction(types.GET_NONBUSINESSDAYBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_NONBUSINESSDAYBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_NONBUSINESSDAYBYID_ASYNC.ERROR, { error }),
};

export const postCreateNonBusinessDay = {
  pending: (data) =>
    createAction(types.POST_CREATENONBUSINESSDAY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_CREATENONBUSINESSDAY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_CREATENONBUSINESSDAY_ASYNC.ERROR, {
      error,
    }),
};

export const putUpdateNonBusinessDay = {
  pending: (data) =>
    createAction(types.PUT_UPDATENONBUSINESSDAY_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATENONBUSINESSDAY_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATENONBUSINESSDAY_ASYNC.ERROR, { error }),
};

export const postSearchNonBusinessDayBranch = {
  pending: (data) =>
    createAction(types.POST_SEARCHNONBUSINESSDAYBRANCH_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_SEARCHNONBUSINESSDAYBRANCH_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_SEARCHNONBUSINESSDAYBRANCH_ASYNC.ERROR, {
      error,
    }),
};
