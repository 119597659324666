import React from "react";
import Typography from "@mui/material/Typography";
import Logo403 from "../../assets/403.png";
import { useStyles } from "../../components/globalStyles";

const NoAuthorization = ({ history }) => {
  const classes = useStyles();

  return (
    <div className={classes.notificationRoot}>
      <div className={classes.notificationTitleContainer}>
        <Typography
          className={classes.notificationTitle}
          variant="h3"
          gutterBottom>
          <img src={Logo403} height="300px" alt="website logo" />
        </Typography>
        <Typography
          className={classes.notificationTitle}
          variant="h3"
          gutterBottom>
          403
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Access denied. Please contact your administrator or email{" "}
          {process.env.REACT_APP_ORGANISATION_EMAIL} for assistance.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          权限不足。请联系你的管理员或发电邮至{" "}
          {process.env.REACT_APP_ORGANISATION_EMAIL} 寻求帮助
        </Typography>
      </div>
    </div>
  );
};

export default NoAuthorization;
