import { createAsyncTypes } from "../_helper/Utilities";

export const GET_ACUPOINTBYID_ASYNC = createAsyncTypes("GET_ACUPOINTBYID");

export const GET_ACUPOINTLIST_ASYNC = createAsyncTypes("GET_ACUPOINTLIST");

export const POST_CREATEACUPOINT_ASYNC = createAsyncTypes(
  "POST_CREATEACUPOINT"
);

export const PUT_UPDATEACUPOINT_ASYNC = createAsyncTypes("PUT_UPDATEACUPOINT");
