import React from "react";
import { Button, Grid, Link } from "@mui/material";
import { useStyles } from "./globalStyles";
import debounce from "debounce";

export default function PageCustomAction({
  button1Name = null,
  button1Click = () => null,
  button1label,
  button2Name = null,
  button2Click = () => null,
  button2label,
}) {
  const classes = useStyles();
  const onConfirmDebounce = debounce(button1Click, 2000, true);
  const onConfirmDebounceCallBack = React.useCallback(onConfirmDebounce, [
    onConfirmDebounce,
  ]);
  return (
    <Grid item xs={12} className={classes.relativePositionWrapper}>
      <Grid item xs={12} className={classes.rightAbsolutePosition}>
        {button1Name && (
          <Link className={classes.boldMaroon075}>
            <Button
              name={button1Name}
              className={classes.boldMaroon075}
              onClick={(event) => {
                onConfirmDebounceCallBack(event);
              }}>
              {button1label}
            </Button>
          </Link>
        )}
        {button2Name && (
          <Link className={classes.boldMaroon075}>
            <Button
              name={button2Name}
              className={classes.boldMaroon075}
              onClick={(event) => {
                button2Click(event);
              }}>
              {button2label}
            </Button>
          </Link>
        )}
      </Grid>
    </Grid>
  );
}
