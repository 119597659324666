//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

//Actions
import {
  getOrganisationPhysicianList,
  getOrganisationPhysicianById,
} from "../../../../actions/organisationPhysicianActions";

//Component
import { EditOrganisationPhysicianPage } from "./EditOrganisationPhysicianPage";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";

export default function OrganisationPhysicianListTable({ setInformationView }) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.ADMIN_PHYSICIAN_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "user.name",
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_user_name_value: "",
  });

  const dispatch = useDispatch();

  const organisationPhysicianData = useSelector(
    (state) => state.organisationPhysician
  );
  const common = useSelector((state) => state.common);
  //Init
  const [editOrganisationPhysicianView, setEditOrganisationPhysicianView] =
    React.useState(false);
  const [organisationPhysicianDetailView, setOrganisationPhysicianDetailView] =
    React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  //Onclick event
  const handleRowEdit = (value) => {
    dispatch(getOrganisationPhysicianById.pending(value.user));
    setEditOrganisationPhysicianView(true);
  };

  const handleRowDetail = (value) => {
    dispatch(getOrganisationPhysicianById.pending(value.user));
    setOrganisationPhysicianDetailView(true);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_user_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_user_name_value: "",
      });
    }
  };

  //useEffects
  useEffect(() => {
    if (
      organisationPhysicianDetailView === true ||
      editOrganisationPhysicianView === true
    ) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [
    organisationPhysicianDetailView,
    editOrganisationPhysicianView,
    setInformationView,
  ]);

  useEffect(() => {
    dispatch(getOrganisationPhysicianList.pending(pageInfo));
  }, [dispatch, pageInfo, editOrganisationPhysicianView]);

  if (
    organisationPhysicianData.getOrganisationPhysicianListIsSuccess === false &&
    organisationPhysicianData.isLoadingGetOrganisationPhysicianList === false
  ) {
    return (
      <Alert severity="error">
        {organisationPhysicianData.error.getOrganisationPhysicianListError}
      </Alert>
    );
  } else if (
    common.generalStatusList &&
    !editOrganisationPhysicianView &&
    !organisationPhysicianDetailView
  ) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name")}
        searchValue={searchValue}
        statusPlaceholder={t("translation:Status Type")}
        statusOption={common.generalStatusList.status_general}
        banStatusIdList={[]}
        columns={columns}
        data={
          organisationPhysicianData.getOrganisationPhysicianList
            ?.organisation_physician !== undefined
            ? organisationPhysicianData.getOrganisationPhysicianList
                ?.organisation_physician
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          organisationPhysicianData.getOrganisationPhysicianList
            ? organisationPhysicianData.getOrganisationPhysicianList.total_pages
            : 0
        }
        loading={organisationPhysicianData.isLoadingOrganisationPhysicianList}
        totalItems={
          organisationPhysicianData.getOrganisationPhysicianList
            ? organisationPhysicianData.getOrganisationPhysicianList.total_items
            : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (
    organisationPhysicianData.getOrganisationPhysicianById &&
    organisationPhysicianDetailView &&
    organisationPhysicianData.getOrganisationPhysicianByIdIsSuccess === true
  ) {
    return (
      <EditOrganisationPhysicianPage
        organisationPhysician={
          organisationPhysicianData.getOrganisationPhysicianById
            .organisation_physician
        }
        setEditOrganisationPhysicianView={setOrganisationPhysicianDetailView}
        viewOnly
      />
    );
  } else if (
    organisationPhysicianData.getOrganisationPhysicianById &&
    editOrganisationPhysicianView &&
    organisationPhysicianData.getOrganisationPhysicianByIdIsSuccess === true
  ) {
    return (
      <EditOrganisationPhysicianPage
        organisationPhysician={
          organisationPhysicianData.getOrganisationPhysicianById
            .organisation_physician
        }
        setEditOrganisationPhysicianView={setEditOrganisationPhysicianView}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
