import * as types from "../_constants/medicineFixedSellingPriceConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_MEDICINEFIXEDSELLINGPRICEBYID_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFixedSellingPriceByIdIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceById: true,
        getMedicineFixedSellingPriceById: null,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEBYID_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFixedSellingPriceByIdIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceById: false,
        getMedicineFixedSellingPriceById: null,
        error: {
          ...state.error,
          getMedicineFixedSellingPriceByIdError: action.error,
        },
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFixedSellingPriceByIdIsSuccess: true,
        isLoadingGetMedicineFixedSellingPriceById: false,
        getMedicineFixedSellingPriceById: action.data,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICELIST_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFixedSellingPriceListIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceList: true,
        getMedicineFixedSellingPriceList: null,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICELIST_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFixedSellingPriceListIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceList: false,
        getMedicineFixedSellingPriceList: null,
        error: {
          ...state.error,
          getMedicineFixedSellingPriceListError: action.error,
        },
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFixedSellingPriceListIsSuccess: true,
        isLoadingGetMedicineFixedSellingPriceList: false,
        getMedicineFixedSellingPriceList: action.data,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFixedSellingPriceSummaryListIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceSummaryList: true,
        getMedicineFixedSellingPriceSummaryList: null,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFixedSellingPriceSummaryListIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceSummaryList: false,
        getMedicineFixedSellingPriceSummaryList: null,
        error: {
          ...state.error,
          getMedicineFixedSellingPriceSummaryListError: action.error,
        },
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFixedSellingPriceSummaryListIsSuccess: true,
        isLoadingGetMedicineFixedSellingPriceSummaryList: false,
        getMedicineFixedSellingPriceSummaryList: action.data,
      };

    case types.POST_CREATEMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING:
      return {
        ...state,
        postCreateMedicineFixedSellingPriceIsSuccess: false,
        isLoadingPostCreateMedicineFixedSellingPrice: true,
        postCreateMedicineFixedSellingPrice: null,
      };

    case types.POST_CREATEMEDICINEFIXEDSELLINGPRICE_ASYNC.ERROR:
      return {
        ...state,
        postCreateMedicineFixedSellingPriceIsSuccess: false,
        isLoadingPostCreateMedicineFixedSellingPrice: false,
        postCreateMedicineFixedSellingPrice: null,
        error: {
          ...state.error,
          postCreateMedicineFixedSellingPriceError: action.error,
        },
      };

    case types.POST_CREATEMEDICINEFIXEDSELLINGPRICE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateMedicineFixedSellingPriceIsSuccess: true,
        isLoadingPostCreateMedicineFixedSellingPrice: false,
        postCreateMedicineFixedSellingPrice: action.data,
      };

    case types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICE_ASYNC.PENDING:
      return {
        ...state,
        putUpdateMedicineFixedSellingPriceIsSuccess: false,
        isLoadingPutUpdateMedicineFixedSellingPrice: true,
        putUpdateMedicineFixedSellingPrice: null,
      };

    case types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICE_ASYNC.ERROR:
      return {
        ...state,
        putUpdateMedicineFixedSellingPriceIsSuccess: false,
        isLoadingPutUpdateMedicineFixedSellingPrice: false,
        putUpdateMedicineFixedSellingPrice: null,
        error: {
          ...state.error,
          putUpdateMedicineFixedSellingPriceError: action.error,
        },
      };

    case types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateMedicineFixedSellingPriceIsSuccess: true,
        isLoadingPutUpdateMedicineFixedSellingPrice: false,
        putUpdateMedicineFixedSellingPrice: action.data,
      };

    default:
      return state;
  }
};
