import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/fulfillmentConstants";

export const getFulfillmentSummaryList = {
  pending: (data) =>
    createAction(types.GET_FULFILLMENTSUMMARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_FULFILLMENTSUMMARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_FULFILLMENTSUMMARYLIST_ASYNC.ERROR, { error }),
};

export const putFulfillmentColourHexCode = {
  pending: (data) =>
    createAction(types.PUT_FULFILLMENTCOLOURHEXCODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_FULFILLMENTCOLOURHEXCODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_FULFILLMENTCOLOURHEXCODE_ASYNC.ERROR, { error }),
};
