import { createAsyncTypes } from "../_helper/Utilities";

export const PUT_INVOICEMEDICINECOSTINGWIPMODE_ASYNC = createAsyncTypes(
  "PUT_INVOICEMEDICINECOSTINGWIPMODE"
);

export const PUT_INVOICEMEDICINECOSTINGPENDINGSTATUS_ASYNC = createAsyncTypes(
  "PUT_INVOICEMEDICINECOSTINGPENDINGSTATUS"
);

export const GET_INVOICEMEDICINECOSTINGLIST_ASYNC = createAsyncTypes(
  "GET_INVOICEMEDICINECOSTINGLIST"
);

export const GET_INVOICEMEDICINECOSTINGSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_INVOICEMEDICINECOSTINGSUMMARYLIST"
);

export const GET_INVOICEMEDICINECOSTINGBYID_ASYNC = createAsyncTypes(
  "GET_INVOICEMEDICINECOSTINGBYID"
);

export const GET_INVOICEMEDICINECOSTINGBYORDERNUM_ASYNC = createAsyncTypes(
  "GET_INVOICEMEDICINECOSTINGBYORDERNUM"
);

export const POST_SUBMITINVOICEMEDICINECOSTING_ASYNC = createAsyncTypes(
  "POST_SUBMITINVOICEMEDICINECOSTING"
);

export const PUT_INVOICEMEDICINECOSTINGPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_INVOICEMEDICINECOSTINGPRINTDATE_ASYNC"
);
