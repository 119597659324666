//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Link } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Close from "@mui/icons-material/Close";
//components

import { useStyles as globalStyles } from "../../../../components/globalStyles";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import * as pageConfigure from "../../../../_constants/pageConstants";
//action

import { getCurrentUserUserCreditList } from "../../../../actions/curUserActions";
import { getUserCreditType } from "../../../../actions/commonActions";
const UserCreditTable = ({ organisationId = null }) => {
  const classes = globalStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const curUser = useSelector((state) => state.curUser);
  const common = useSelector((state) => state.common);
  const userAuth = useSelector((state) => state.userAuth);
  const user = userAuth.systemUserInfo;

  const columns = [
    {
      Header: t("translation:Name"),
      accessor: "user.name",
    },

    {
      Header: t("translation:Amount"),
      accessor: "credit_amt",
      sortable: false,
      Cell: ({ cell }) => {
        return cell.value ? Number(cell.value).toFixed(2) : "";
      },
    },

    {
      Header: t("translation:Credit Type"),
      accessor: "user_credit_type",
      sortable: false,
      Cell: ({ cell }) => {
        const cellValue =
          common.userCreditType.user_credit_type_select_field.find(
            (item) => item.id === cell.value
          );

        return cellValue ? cellValue.name : "";
      },
    },
    {
      Header: t("translation:Order No"),
      accessor: "store_header.order_number",
    },
    {
      Header: t("translation:Modified Date"),
      accessor: "modified_on",

      Cell: ({ row }) => {
        if (row.original.modified_on)
          return moment
            .utc(row.original.modified_on)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,

    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_desc_value: null,
    search_id_value: null,
    search_name_value: null,
    search_organisation_id_value: organisationId,
    search_status_type_value: null,
    search_user_credit_type_value: null,
    search_user_id_value: user.id,
  });

  useEffect(() => {
    if (open) {
      dispatch(getCurrentUserUserCreditList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, open]);

  useEffect(() => {
    dispatch(getUserCreditType.pending());
  }, [dispatch]);

  return (
    <>
      <Grid item xs={12}>
        <Link className={classes.boldMaroon075}>
          <Button
            className={classes.boldMaroon075}
            onClick={() => setOpen(true)}>
            {t("translation:USER CREDIT HISTORY")}
          </Button>
        </Link>
      </Grid>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpen(false);
          }
        }}
        aria-labelledby="confirm-dialog"
        fullWidth
        maxWidth="xl">
        <Close
          style={{ position: "absolute", right: "15px" }}
          onClick={() => setOpen(false)}
        />
        <DialogContent>
          <SortingSearchTable
            columns={columns}
            data={
              curUser.getCurrentUserUserCreditList?.user_credits !== undefined
                ? curUser.getCurrentUserUserCreditList?.user_credits
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              curUser.getCurrentUserUserCreditList
                ? curUser.getCurrentUserUserCreditList.total_pages
                : 0
            }
            loading={curUser.isLoadingGetCurrentUserUserCreditList}
            totalItems={
              curUser.getCurrentUserUserCreditList
                ? curUser.getCurrentUserUserCreditList.total_items
                : 0
            }
            disabledSearch
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserCreditTable;
