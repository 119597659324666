import { createAsyncTypes } from "../_helper/Utilities";

export const GET_USERCREDITLIST_ASYNC = createAsyncTypes("GET_USERCREDITLIST");

export const POST_CREATEUSERCREDIT_ASYNC = createAsyncTypes(
  "POST_CREATEUSERCREDIT"
);

export const GET_USERCREDITUSERLIST_ASYNC = createAsyncTypes(
  "GET_USERCREDITUSERLIST"
);

export const GET_USERCREDITBYID_ASYNC = createAsyncTypes("GET_USERCREDITBYID");

export const GET_USERCREDITBALANCEBYID_ASYNC = createAsyncTypes(
  "GET_USERCREDITBALANCEBYID"
);
