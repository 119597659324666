import * as types from "../_constants/wikiDataConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_WIKIDATABYID_ASYNC.PENDING:
      return {
        ...state,
        getWikiDataByIdIsSuccess: false,
        isLoadingGetWikiDataById: true,
        getWikiDataById: null,
      };

    case types.GET_WIKIDATABYID_ASYNC.ERROR:
      return {
        ...state,
        getWikiDataByIdIsSuccess: false,
        isLoadingGetWikiDataById: false,
        getWikiDataById: null,
        error: {
          ...state.error,
          getWikiDataByIdError: action.error,
        },
      };

    case types.GET_WIKIDATABYID_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiDataByIdIsSuccess: true,
        isLoadingGetWikiDataById: false,
        getWikiDataById: action.data,
      };

    case types.GET_WIKIDATALIST_ASYNC.PENDING:
      return {
        ...state,
        getWikiDataListIsSuccess: false,
        isLoadingGetWikiDataList: true,
        getWikiDataList: null,
      };

    case types.GET_WIKIDATALIST_ASYNC.ERROR:
      return {
        ...state,
        getWikiDataListIsSuccess: false,
        isLoadingGetWikiDataList: false,
        getWikiDataList: null,
        error: {
          ...state.error,
          getWikiDataListError: action.error,
        },
      };

    case types.GET_WIKIDATALIST_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiDataListIsSuccess: true,
        isLoadingGetWikiDataList: false,
        getWikiDataList: action.data,
      };

    case types.GET_WIKIDATAPREVIEWLIST_ASYNC.PENDING:
      return {
        ...state,
        getWikiDataPreviewListIsSuccess: false,
        isLoadingGetWikiDataPreviewList: true,
        getWikiDataPreviewList: null,
      };

    case types.GET_WIKIDATAPREVIEWLIST_ASYNC.ERROR:
      return {
        ...state,
        getWikiDataPreviewListIsSuccess: false,
        isLoadingGetWikiDataPreviewList: false,
        getWikiDataPreviewList: null,
        error: {
          ...state.error,
          getWikiDataPreviewListError: action.error,
        },
      };

    case types.GET_WIKIDATAPREVIEWLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiDataPreviewListIsSuccess: true,
        isLoadingGetWikiDataPreviewList: false,
        getWikiDataPreviewList: action.data,
      };

    case types.GET_WIKIDATAPATHLIST_ASYNC.PENDING:
      return {
        ...state,
        getWikiDataPathListIsSuccess: false,
        isLoadingGetWikiDataPathList: true,
        getWikiDataPathList: null,
      };

    case types.GET_WIKIDATAPATHLIST_ASYNC.ERROR:
      return {
        ...state,
        getWikiDataPathListIsSuccess: false,
        isLoadingGetWikiDataPathList: false,
        getWikiDataPathList: null,
        error: {
          ...state.error,
          getWikiDataPathListError: action.error,
        },
      };

    case types.GET_WIKIDATAPATHLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiDataPathListIsSuccess: true,
        isLoadingGetWikiDataPathList: false,
        getWikiDataPathList: action.data,
      };

    case types.GET_WIKIDATASUBNODELIST_ASYNC.PENDING:
      return {
        ...state,
        getWikiDataSubNodeListIsSuccess: false,
        isLoadingGetWikiDataSubNodeList: true,
        getWikiDataSubNodeList: null,
      };

    case types.GET_WIKIDATASUBNODELIST_ASYNC.ERROR:
      return {
        ...state,
        getWikiDataSubNodeListIsSuccess: false,
        isLoadingGetWikiDataSubNodeList: false,
        getWikiDataSubNodeList: null,
        error: {
          ...state.error,
          getWikiDataSubNodeListError: action.error,
        },
      };

    case types.GET_WIKIDATASUBNODELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiDataSubNodeListIsSuccess: true,
        isLoadingGetWikiDataSubNodeList: false,
        getWikiDataSubNodeList: action.data,
      };

    case types.POST_CREATEWIKIDATA_ASYNC.PENDING:
      return {
        ...state,
        postCreateWikiDataIsSuccess: false,
        isLoadingPostCreateWikiData: true,
        postCreateWikiData: null,
      };

    case types.POST_CREATEWIKIDATA_ASYNC.ERROR:
      return {
        ...state,
        postCreateWikiDataIsSuccess: false,
        isLoadingPostCreateWikiData: false,
        postCreateWikiData: null,
        error: {
          ...state.error,
          postCreateWikiDataError: action.error,
        },
      };

    case types.POST_CREATEWIKIDATA_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateWikiDataIsSuccess: true,
        isLoadingPostCreateWikiData: false,
        postCreateWikiData: action.data,
      };

    case types.PUT_UPDATEWIKIDATA_ASYNC.PENDING:
      return {
        ...state,
        putUpdateWikiDataIsSuccess: false,
        isLoadingPutUpdateWikiData: true,
        putUpdateWikiData: null,
      };

    case types.PUT_UPDATEWIKIDATA_ASYNC.ERROR:
      return {
        ...state,
        putUpdateWikiDataIsSuccess: false,
        isLoadingPutUpdateWikiData: false,
        putUpdateWikiData: null,
        error: {
          ...state.error,
          putUpdateWikiDataError: action.error,
        },
      };

    case types.PUT_UPDATEWIKIDATA_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateWikiDataIsSuccess: true,
        isLoadingPutUpdateWikiData: false,
        putUpdateWikiData: action.data,
      };

    case types.GET_WIKIACUPOINTLIST_ASYNC.PENDING:
      return {
        ...state,
        getWikiAcupointListIsSuccess: false,
        isLoadingGetWikiAcupointList: true,
        getWikiAcupointList: null,
      };

    case types.GET_WIKIACUPOINTLIST_ASYNC.ERROR:
      return {
        ...state,
        getWikiAcupointListIsSuccess: false,
        isLoadingGetWikiAcupointList: false,
        getWikiAcupointList: null,
        error: {
          ...state.error,
          getWikiAcupointListError: action.error,
        },
      };

    case types.GET_WIKIACUPOINTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiAcupointListIsSuccess: true,
        isLoadingGetWikiAcupointList: false,
        getWikiAcupointList: action.data,
      };

    case types.GET_WIKICUSTOMWORDLIST_ASYNC.PENDING:
      return {
        ...state,
        getWikiCustomWordListIsSuccess: false,
        isLoadingGetWikiCustomWordList: true,
        getWikiCustomWordList: null,
      };

    case types.GET_WIKICUSTOMWORDLIST_ASYNC.ERROR:
      return {
        ...state,
        getWikiCustomWordListIsSuccess: false,
        isLoadingGetWikiCustomWordList: false,
        getWikiCustomWordList: null,
        error: {
          ...state.error,
          getWikiCustomWordListError: action.error,
        },
      };

    case types.GET_WIKICUSTOMWORDLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiCustomWordListIsSuccess: true,
        isLoadingGetWikiCustomWordList: false,
        getWikiCustomWordList: action.data,
      };

    case types.GET_WIKITREATMENTTYPES_ASYNC.PENDING:
      return {
        ...state,
        getWikiTreatmentTypesIsSuccess: false,
        isLoadingGetWikiTreatmentTypes: true,
        getWikiTreatmentTypes: null,
      };

    case types.GET_WIKITREATMENTTYPES_ASYNC.ERROR:
      return {
        ...state,
        getWikiTreatmentTypesIsSuccess: false,
        isLoadingGetWikiTreatmentTypes: false,
        getWikiTreatmentTypes: null,
        error: {
          ...state.error,
          getWikiTreatmentTypesError: action.error,
        },
      };

    case types.GET_WIKITREATMENTTYPES_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiTreatmentTypesIsSuccess: true,
        isLoadingGetWikiTreatmentTypes: false,
        getWikiTreatmentTypes: action.data,
      };

    case types.GET_WIKITREATMENTLIST_ASYNC.PENDING:
      return {
        ...state,
        getWikiTreatmentListIsSuccess: false,
        isLoadingGetWikiTreatmentList: true,
        getWikiTreatmentList: null,
      };

    case types.GET_WIKITREATMENTLIST_ASYNC.ERROR:
      return {
        ...state,
        getWikiTreatmentListIsSuccess: false,
        isLoadingGetWikiTreatmentList: false,
        getWikiTreatmentList: null,
        error: {
          ...state.error,
          getWikiTreatmentListError: action.error,
        },
      };

    case types.GET_WIKITREATMENTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiTreatmentListIsSuccess: true,
        isLoadingGetWikiTreatmentList: false,
        getWikiTreatmentList: action.data,
      };

    case types.GET_WIKITREATMENTLIST_ASYNC.PENDING:
      return {
        ...state,
        getWikiTreatmentListIsSuccess: false,
        isLoadingGetWikiTreatmentList: true,
        getWikiTreatmentList: null,
      };

    case types.GET_WIKITREATMENTLIST_ASYNC.ERROR:
      return {
        ...state,
        getWikiTreatmentListIsSuccess: false,
        isLoadingGetWikiTreatmentList: false,
        getWikiTreatmentList: null,
        error: {
          ...state.error,
          getWikiTreatmentListError: action.error,
        },
      };

    case types.GET_WIKITREATMENTLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiTreatmentListIsSuccess: true,
        isLoadingGetWikiTreatmentList: false,
        getWikiTreatmentList: action.data,
      };

    case types.GET_WIKIMEDICINETYPES_ASYNC.PENDING:
      return {
        ...state,
        getWikiMedicineTypesIsSuccess: false,
        isLoadingGetWikiMedicineTypes: true,
        getWikiMedicineTypes: null,
      };

    case types.GET_WIKIMEDICINETYPES_ASYNC.ERROR:
      return {
        ...state,
        getWikiMedicineTypesIsSuccess: false,
        isLoadingGetWikiMedicineTypes: false,
        getWikiMedicineTypes: null,
        error: {
          ...state.error,
          getWikiMedicineTypesError: action.error,
        },
      };

    case types.GET_WIKIMEDICINETYPES_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiMedicineTypesIsSuccess: true,
        isLoadingGetWikiMedicineTypes: false,
        getWikiMedicineTypes: action.data,
      };

    case types.GET_WIKIMEDICINELIST_ASYNC.PENDING:
      return {
        ...state,
        getWikiMedicineListIsSuccess: false,
        isLoadingGetWikiMedicineList: true,
        getWikiMedicineList: null,
      };

    case types.GET_WIKIMEDICINELIST_ASYNC.ERROR:
      return {
        ...state,
        getWikiMedicineListIsSuccess: false,
        isLoadingGetWikiMedicineList: false,
        getWikiMedicineList: null,
        error: {
          ...state.error,
          getWikiMedicineListError: action.error,
        },
      };

    case types.GET_WIKIMEDICINELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getWikiMedicineListIsSuccess: true,
        isLoadingGetWikiMedicineList: false,
        getWikiMedicineList: action.data,
      };

    default:
      return state;
  }
};
