import * as types from "../_constants/deliveryTrackingConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_DELIVERYTRACKINGLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryTrackingListIsSuccess: false,
        isLoadingGetDeliveryTrackingList: true,
        getDeliveryTrackingList: null,
      };

    case types.GET_DELIVERYTRACKINGLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryTrackingListIsSuccess: false,
        isLoadingGetDeliveryTrackingList: false,
        getDeliveryTrackingList: null,
        error: { ...state.error, getDeliveryTrackingListError: action.error },
      };

    case types.GET_DELIVERYTRACKINGLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryTrackingListIsSuccess: true,
        isLoadingGetDeliveryTrackingList: false,
        getDeliveryTrackingList: action.data,
      };

    case types.GET_DELIVERYTRACKINGSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryTrackingSummaryListIsSuccess: false,
        isLoadingGetDeliveryTrackingSummaryList: true,
        getDeliveryTrackingSummaryList: null,
      };

    case types.GET_DELIVERYTRACKINGSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryTrackingSummaryListIsSuccess: false,
        isLoadingGetDeliveryTrackingSummaryList: false,
        getDeliveryTrackingSummaryList: null,
        error: {
          ...state.error,
          getDeliveryTrackingSummaryListError: action.error,
        },
      };

    case types.GET_DELIVERYTRACKINGSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryTrackingSummaryListIsSuccess: true,
        isLoadingGetDeliveryTrackingSummaryList: false,
        getDeliveryTrackingSummaryList: action.data,
      };

    case types.GET_DELIVERYTRACKINGBYID_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryTrackingByIdIsSuccess: false,
        isLoadingGetDeliveryTrackingById: true,
        getDeliveryTrackingById: null,
      };

    case types.GET_DELIVERYTRACKINGBYID_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryTrackingByIdIsSuccess: false,
        isLoadingGetDeliveryTrackingById: false,
        getDeliveryTrackingById: null,
        error: {
          ...state.error,
          getDeliveryTrackingByIdError: action.error,
        },
      };

    case types.GET_DELIVERYTRACKINGBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryTrackingByIdIsSuccess: true,
        isLoadingGetDeliveryTrackingById: false,
        getDeliveryTrackingById: action.data,
      };

    case types.GET_DELIVERYTRACKINGCOURIERLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryTrackingCourierListIsSuccess: false,
        isLoadingGetDeliveryTrackingCourierList: true,
        getDeliveryTrackingCourierList: null,
      };

    case types.GET_DELIVERYTRACKINGCOURIERLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryTrackingCourierListIsSuccess: false,
        isLoadingGetDeliveryTrackingCourierList: false,
        getDeliveryTrackingCourierList: null,
        error: {
          ...state.error,
          getDeliveryTrackingCourierListError: action.error,
        },
      };

    case types.GET_DELIVERYTRACKINGCOURIERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryTrackingCourierListIsSuccess: true,
        isLoadingGetDeliveryTrackingCourierList: false,
        getDeliveryTrackingCourierList: action.data,
      };

    case types.GET_DELIVERYTRACKINGCOURIERBYBRANCH_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryTrackingCourierByBranchIsSuccess: false,
        isLoadingGetDeliveryTrackingCourierByBranch: true,
        getDeliveryTrackingCourierByBranch: null,
      };

    case types.GET_DELIVERYTRACKINGCOURIERBYBRANCH_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryTrackingCourierByBranchIsSuccess: false,
        isLoadingGetDeliveryTrackingCourierByBranch: false,
        getDeliveryTrackingCourierByBranch: null,
        error: {
          ...state.error,
          getDeliveryTrackingCourierByBranchError: action.error,
        },
      };

    case types.GET_DELIVERYTRACKINGCOURIERBYBRANCH_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryTrackingCourierByBranchIsSuccess: true,
        isLoadingGetDeliveryTrackingCourierByBranch: false,
        getDeliveryTrackingCourierByBranch: action.data,
      };

    case types.PUT_DELIVERYTRACKINGWIPMODE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryTrackingWIPModeIsSuccess: false,
        isLoadingPutDeliveryTrackingWIPMode: true,
        putDeliveryTrackingWIPMode: null,
      };

    case types.PUT_DELIVERYTRACKINGWIPMODE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryTrackingWIPModeIsSuccess: false,
        isLoadingPutDeliveryTrackingWIPMode: false,
        putDeliveryTrackingWIPMode: null,
        error: {
          ...state.error,
          putDeliveryTrackingWIPModeError: action.error,
        },
      };

    case types.PUT_DELIVERYTRACKINGWIPMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryTrackingWIPModeIsSuccess: true,
        isLoadingPutDeliveryTrackingWIPMode: false,
        putDeliveryTrackingWIPMode: action.data,
      };

    case types.POST_DELIVERYTRACKING_ASYNC.PENDING:
      return {
        ...state,
        postDeliveryTrackingIsSuccess: false,
        isLoadingPostDeliveryTracking: true,
        postDeliveryTracking: null,
      };

    case types.POST_DELIVERYTRACKING_ASYNC.ERROR:
      return {
        ...state,
        postDeliveryTrackingIsSuccess: false,
        isLoadingPostDeliveryTracking: false,
        postDeliveryTracking: null,
        error: {
          ...state.error,
          postDeliveryTrackingError: action.error,
        },
      };

    case types.POST_DELIVERYTRACKING_ASYNC.SUCCESS:
      return {
        ...state,
        postDeliveryTrackingIsSuccess: true,
        isLoadingPostDeliveryTracking: false,
        postDeliveryTracking: action.data,
      };

    case types.PUT_DELIVERYTRACKINGPENDINGSTATUS_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryTrackingPendingStatusIsSuccess: false,
        isLoadingPutDeliveryTrackingPendingStatus: true,
        putDeliveryTrackingPendingStatus: null,
      };

    case types.PUT_DELIVERYTRACKINGPENDINGSTATUS_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryTrackingPendingStatusIsSuccess: false,
        isLoadingPutDeliveryTrackingPendingStatus: false,
        putDeliveryTrackingPendingStatus: null,
        error: {
          ...state.error,
          putDeliveryTrackingPendingStatusError: action.error,
        },
      };

    case types.PUT_DELIVERYTRACKINGPENDINGSTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryTrackingPendingStatusIsSuccess: true,
        isLoadingPutDeliveryTrackingPendingStatus: false,
        putDeliveryTrackingPendingStatus: action.data,
      };

    case types.PUT_DELIVERYTRACKINGCLOSESTATUS_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryTrackingCloseStatusIsSuccess: false,
        isLoadingPutDeliveryTrackingCloseStatus: true,
        putDeliveryTrackingCloseStatus: null,
      };

    case types.PUT_DELIVERYTRACKINGCLOSESTATUS_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryTrackingCloseStatusIsSuccess: false,
        isLoadingPutDeliveryTrackingCloseStatus: false,
        putDeliveryTrackingCloseStatus: null,
        error: {
          ...state.error,
          putDeliveryTrackingCloseStatusError: action.error,
        },
      };

    case types.PUT_DELIVERYTRACKINGCLOSESTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryTrackingCloseStatusIsSuccess: true,
        isLoadingPutDeliveryTrackingCloseStatus: false,
        putDeliveryTrackingCloseStatus: action.data,
      };

    case types.PUT_DELIVERYTRACKINGPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryTrackingPrintDateIsSuccess: false,
        isLoadingPutDeliveryTrackingPrintDate: true,
        putDeliveryTrackingPrintDate: null,
      };

    case types.PUT_DELIVERYTRACKINGPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryTrackingPrintDateIsSuccess: false,
        isLoadingPutDeliveryTrackingPrintDate: false,
        putDeliveryTrackingPrintDate: null,
        error: {
          ...state.error,
          putDeliveryTrackingPrintDateError: action.error,
        },
      };

    case types.PUT_DELIVERYTRACKINGPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryTrackingPrintDateIsSuccess: true,
        isLoadingPutDeliveryTrackingPrintDate: false,
        putDeliveryTrackingPrintDate: action.data,
      };

    case types.PUT_DELIVERYTRACKINGUPLOADFILE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryTrackingUploadFileIsSuccess: false,
        isLoadingPutDeliveryTrackingUploadFile: true,
        putDeliveryTrackingUploadFile: null,
      };

    case types.PUT_DELIVERYTRACKINGUPLOADFILE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryTrackingUploadFileIsSuccess: false,
        isLoadingPutDeliveryTrackingUploadFile: false,
        putDeliveryTrackingUploadFile: null,
        error: {
          ...state.error,
          putDeliveryTrackingUploadFileError: action.error,
        },
      };

    case types.PUT_DELIVERYTRACKINGUPLOADFILE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryTrackingUploadFileIsSuccess: true,
        isLoadingPutDeliveryTrackingUploadFile: false,
        putDeliveryTrackingUploadFile: action.data,
      };

    default:
      return state;
  }
};
