export const getCookie = (COOKIE_NAME) => {
  let name = COOKIE_NAME + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getTableDefaultCookie = (COOKIE_NAME) => {
  const rootReducerSession = JSON.parse(
    sessionStorage.getItem("persist:rootReducer")
  );
  const userAuthSession = JSON.parse(rootReducerSession.userAuth);

  const cookieNameWId = `${COOKIE_NAME}_${userAuthSession.systemUserInfo?.id}`;

  let name = cookieNameWId + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setCookie = (cname, cvalue, exdays = 90) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const setUserCookie = (cname, cvalue, exdays = 90) => {
  const rootReducerSession = JSON.parse(
    sessionStorage.getItem("persist:rootReducer")
  );
  const userAuthSession = JSON.parse(rootReducerSession.userAuth);

  const cookieNameWId = `${cname}_${userAuthSession.systemUserInfo?.id}`;

  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cookieNameWId + "=" + cvalue + ";" + expires + ";path=/";
};
