import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrescriptionMsgSummaryPage from "./PrescriptionMsgSummary/PrescriptionMsgSummaryPage";
export default function PrescriptionMsgPage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/prescriptionMsg"
        to="/prescriptionMsg/prescriptionMsgSummary"
      />
      <Route
        path="/prescriptionMsg/prescriptionMsgSummary"
        component={PrescriptionMsgSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
