//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
//Actions
import {
  getDeliveryCutoffScheduleList,
  putUpdateDeliveryCutoffSchedule,
} from "../../../../actions/deliveryCutoffScheduleActions";

//Component
import Panel from "../../../../components/Panel";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { useStyles } from "../../../../components/globalStyles";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";
import AddAdhocModal from "./components/AddAdhocModal";
export default function DeliveryCutoffScheduleBranchPage({
  branchId,
  setSummaryView,
  viewOnly = false,
}) {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const prevPutUpdate = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [editDeliveryCutoffSchedule, setEditDeliveryCutoffSchedule] =
    useState(null);
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const [open, setOpen] = useState(false);
  const curUser = useSelector((state) => state.curUser);
  const common = useSelector((state) => state.common);
  const [confirmSubmissionList, setConfirmSubmissionList] = useState(null);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_NON_BUSINESS_DAY_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: t("translation:Name"),
      accessor: "name",
      sortable: false,
    },
    {
      Header: t("translation:Day of the week"),
      accessor: "day_of_the_week",
      sortable: false,
    },
    {
      Header: t("translation:Cut Off Time"),
      accessor: "cutoff_time",
      sortable: false,
      Cell: ({ cell }) => {
        const cellValue = moment(cell.value, "hh:mm:ss").format("hh:mm a");

        return cellValue ? cellValue : "";
      },
    },
    {
      Header: t("translation:Brewing Services"),
      accessor: "is_brewing_services",
      sortable: false,
      Cell: ({ cell }) => {
        return cell.value ? t("translation:Yes") : t("translation:No");
      },
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const updateColumns = [
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
      sortable: false,
    },
    {
      Header: t("translation:Day of the week"),
      accessor: "day_of_the_week",
      sortable: false,
    },
    {
      Header: t("translation:Cut Off Time"),
      accessor: "cutoff_time",
      sortable: false,
      Cell: ({ cell }) => {
        const cellValue = moment(cell.value, "hh:mm:ss").format("hh:mm a");

        return cellValue ? cellValue : "";
      },
    },
    {
      Header: t("translation:Brewing Services"),
      accessor: "is_brewing_services",
      sortable: false,
      Cell: ({ cell }) => {
        return cell.value ? t("translation:Yes") : t("translation:No");
      },
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_branch_desc_value: null,
    search_branch_id_value: branchId,
    search_branch_name_value: null,
    search_desc_value: null,
    search_id_value: null,
    search_name_value: null,
    search_organisation_id_value: null,
    search_organisation_name_value: null,
    search_status_type_value: null,
  });

  const dispatch = useDispatch();

  const deliveryCutoffSchedule = useSelector(
    (state) => state.deliveryCutoffSchedule
  );

  //Init
  const [searchValue, setSearchValue] = React.useState("");

  //Onclick event
  const handleRowEdit = (value) => {
    setEditDeliveryCutoffSchedule(value);
    setOpenModal(true);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: null,
      });
    }
  };

  const handlePostResult = (result) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    dispatch(putUpdateDeliveryCutoffSchedule.pending(result));
  };

  useEffect(() => {
    //set ref when first render
    prevPutUpdate.current = false;
  }, []);

  useEffect(() => {
    if (
      deliveryCutoffSchedule.isLoadingPutUpdateDeliveryCutoffSchedule !==
      prevPutUpdate.current
    ) {
      //check the previous ref with current state
      prevPutUpdate.current =
        deliveryCutoffSchedule.isLoadingPutUpdateDeliveryCutoffSchedule;

      if (
        deliveryCutoffSchedule.putUpdateDeliveryCutoffScheduleIsSuccess ===
          true &&
        deliveryCutoffSchedule.isLoadingPutUpdateDeliveryCutoffSchedule ===
          false
      ) {
        dispatch(getDeliveryCutoffScheduleList.pending(pageInfo));
        setSuccessAlarm(true);
        setErrorAlarm(false);
        window.scrollTo(0, 0);
      }
      if (
        deliveryCutoffSchedule.putUpdateDeliveryCutoffScheduleIsSuccess ===
          false &&
        deliveryCutoffSchedule.isLoadingPutUpdateDeliveryCutoffSchedule ===
          false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(
          deliveryCutoffSchedule.error.putUpdateDeliveryCutoffScheduleError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, pageInfo, deliveryCutoffSchedule]);

  useEffect(() => {
    dispatch(getDeliveryCutoffScheduleList.pending(pageInfo));
  }, [dispatch, pageInfo]);

  if (
    deliveryCutoffSchedule.getDeliveryCutoffScheduleListIsSuccess === false &&
    deliveryCutoffSchedule.isLoadingGetDeliveryCutoffScheduleList === false
  ) {
    return (
      <Alert severity="error">
        {deliveryCutoffSchedule.error.getDeliveryCutoffScheduleListError}
      </Alert>
    );
  } else if (
    common.generalStatusList &&
    deliveryCutoffSchedule.getDeliveryCutoffScheduleListIsSuccess === true &&
    deliveryCutoffSchedule.isLoadingGetDeliveryCutoffScheduleList === false
  ) {
    return (
      <>
        <Collapse in={successAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Delivery Cut Off Schedule has been updated")}
          </Alert>
        </Collapse>

        <Collapse in={errorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        {openModal && (
          <AddAdhocModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            branchId={branchId}
            editDeliveryCutoffSchedule={editDeliveryCutoffSchedule}
            setEditDeliveryCutoffSchedule={setEditDeliveryCutoffSchedule}
            handleUpdate={handlePostResult}
          />
        )}
        <Panel heading={t("translation:Edit Delivery Cut Off Schedule")}>
          <Grid className={classes.root} container justifyContent="flex-end">
            {!viewOnly && (
              <Grid item xs={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonColor}
                  onClick={() => {
                    setEditDeliveryCutoffSchedule(null);
                    setOpenModal(true);
                  }}>
                  {t("translation:Add")}
                </Button>
              </Grid>
            )}
          </Grid>

          <SortingSearchTable
            tableHeading={""}
            searchPlaceholder={t("translation:Name")}
            searchValue={searchValue}
            columns={viewOnly ? columns : updateColumns}
            statusPlaceholder={t("translation:Status Type")}
            statusOption={common.generalStatusList.status_general}
            banStatusIdList={[]}
            data={
              deliveryCutoffSchedule.getDeliveryCutoffScheduleList
                ?.delivery_cutoff_schedules !== undefined
                ? deliveryCutoffSchedule.getDeliveryCutoffScheduleList
                    ?.delivery_cutoff_schedules
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              deliveryCutoffSchedule.getDeliveryCutoffScheduleList
                ? deliveryCutoffSchedule.getDeliveryCutoffScheduleList
                    .total_pages
                : 0
            }
            loading={
              deliveryCutoffSchedule.isLoadingGetDeliveryCutoffScheduleList
            }
            totalItems={
              deliveryCutoffSchedule.getDeliveryCutoffScheduleList
                ? deliveryCutoffSchedule.getDeliveryCutoffScheduleList
                    .total_items
                : 0
            }
            handleSearch={handleSearch}
          />
          <Grid className={classes.root} container>
            <PageOnlyBackAction setView={() => setSummaryView(false)} />
          </Grid>
        </Panel>
      </>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
