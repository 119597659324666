import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/emailStoreAccountConstants";

export const getEmailStoreAccountById = {
  pending: (data) =>
    createAction(types.GET_EMAILSTOREACCOUNTBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_EMAILSTOREACCOUNTBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_EMAILSTOREACCOUNTBYID_ASYNC.ERROR, { error }),
};

export const getEmailStoreAccountList = {
  pending: (data) =>
    createAction(types.GET_EMAILSTOREACCOUNTLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_EMAILSTOREACCOUNTLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_EMAILSTOREACCOUNTLIST_ASYNC.ERROR, { error }),
};

export const postCreateEmailStoreAccount = {
  pending: (data) =>
    createAction(types.POST_CREATEEMAILSTOREACCOUNT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEEMAILSTOREACCOUNT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEEMAILSTOREACCOUNT_ASYNC.ERROR, { error }),
};

export const putUpdateEmailStoreAccount = {
  pending: (data) =>
    createAction(types.PUT_UPDATEEMAILSTOREACCOUNT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEEMAILSTOREACCOUNT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEEMAILSTOREACCOUNT_ASYNC.ERROR, { error }),
};
