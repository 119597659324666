import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Link } from "@mui/material";
import Alert from "@mui/material/Alert";
import Print from "@mui/icons-material/Print";

import {
  getReportingExportBillAbleInvoiceByStore,
  getReportingBillAbleInvoiceByStore,
} from "../../../../actions/reportingActions";
import Panel from "../../../../components/Panel";

import BillAbleInvoiceReportPDF from "../../../../components/PDF/BillAbleInvoiceReportPDF";
//style
import { useStyles } from "../../../../components/globalStyles";
export default function ExportBillableInvoiceSummaryByStore() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reporting = useSelector((state) => state.reporting);
  const common = useSelector((state) => state.common);

  const [printDetail, setPrintDetail] = useState(0);
  const prevSyncStatus = useRef();

  useEffect(() => {
    //set ref when first render
    prevSyncStatus.current = false;
  }, []);

  return (
    <Panel heading={t("translation:Billable Store Invoice Report")}>
      <Grid item xs={12}>
        {reporting.getReportingBillAbleInvoiceByStoreIsSuccess === false &&
          reporting.isLoadingGetReportingBillAbleInvoiceByStore === false && (
            <Alert severity="error">
              {reporting.error.getReportingBillAbleInvoiceByStoreError}
            </Alert>
          )}

        {reporting.getReportingExportBillAbleInvoiceByStoreIsSuccess ===
          false &&
          reporting.isLoadingGetReportingExportBillAbleInvoiceByStore ===
            false && (
            <Alert severity="error">
              {reporting.error.getReportingExportBillAbleInvoiceByStoreError}
            </Alert>
          )}

        {reporting.isLoadingGetReportingBillAbleInvoiceByStore &&
        common.generalStatusList ? (
          <>{t("translation:Loading")}... </>
        ) : (
          <Link
            className={classes.boldMaroon075}
            onClick={() =>
              dispatch(getReportingBillAbleInvoiceByStore.pending())
            }>
            <Button
              title={t("translation:PRINT REPORT")}
              onClick={() => {
                setPrintDetail(new Date().valueOf());
              }}
              className={classes.grey}>
              <Print />

              {printDetail !== 0 && (
                <BillAbleInvoiceReportPDF
                  orderDetailInfo={reporting.getReportingBillAbleInvoiceByStore}
                  title={"Billable Store Invoice Receipt Report"}
                  t={t}
                  statusOption={common.generalStatusList.status_general}
                  print={printDetail}
                />
              )}
            </Button>
          </Link>
        )}

        {reporting.isLoadingGetReportingExportBillAbleInvoiceByStore ? (
          <>{t("translation:Loading")}... </>
        ) : (
          <Link
            className={classes.boldMaroon075}
            onClick={() =>
              dispatch(getReportingExportBillAbleInvoiceByStore.pending())
            }>
            <Button className={classes.boldMaroon075} name="synButton">
              {t("translation:Download CSV FILE")}
            </Button>
          </Link>
        )}
      </Grid>
    </Panel>
  );
}
