import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const roleRightApis = {
  getRoleRightApi,
  putEditRoleRightApi,
  getRoleRightListApi,
};

async function getRoleRightApi(searchModel) {
  const url = apiConstants.apiGetRoleRight + searchModel;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putEditRoleRightApi(userModel) {
  const requestOptions = JSON.stringify(userModel);
  return await axios
    .put(apiConstants.apiPutRoleRight, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getRoleRightListApi(userModel) {
  const requestOptions = JSON.stringify(userModel);
  return await axios
    .post(apiConstants.apiGetRoleRightList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default roleRightApis;
