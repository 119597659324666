import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getWikiDataList,
  getWikiDataPreviewList,
  getWikiDataPathList,
  getWikiDataSubNodeList,
  getWikiDataById,
  postCreateWikiData,
  putUpdateWikiData,
  getWikiMedicineList,
  getWikiMedicineTypes,
  getWikiTreatmentList,
  getWikiTreatmentTypes,
  getWikiCustomWordList,
  getWikiAcupointList,
} from "../actions/wikiDataActions";
import * as types from "../_constants/wikiDataConstants";
import * as notification from "../_constants/notificationConstants";
import wikiDataApis from "../_apis/wikiDataApis";
export default function* wikiDataSagas() {
  yield all([
    takeLatest(types.GET_WIKIDATALIST_ASYNC.PENDING, getWikiDataListCall),
  ]);

  yield all([
    takeLatest(
      types.GET_WIKIDATAPREVIEWLIST_ASYNC.PENDING,
      getWikiDataPreviewListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_WIKIDATAPATHLIST_ASYNC.PENDING,
      getWikiDataPathListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_WIKIDATASUBNODELIST_ASYNC.PENDING,
      getWikiDataSubNodeListCall
    ),
  ]);
  yield all([
    takeLatest(types.GET_WIKIDATABYID_ASYNC.PENDING, getWikiDataByIdCall),
  ]);
  yield all([
    takeLatest(types.POST_CREATEWIKIDATA_ASYNC.PENDING, postCreateWikiDataCall),
  ]);
  yield all([
    takeLatest(types.PUT_UPDATEWIKIDATA_ASYNC.PENDING, putUpdateWikiDataCall),
  ]);

  yield all([
    takeLatest(
      types.GET_WIKIACUPOINTLIST_ASYNC.PENDING,
      getWikiAcupointListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_WIKICUSTOMWORDLIST_ASYNC.PENDING,
      getWikiCustomWordListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_WIKITREATMENTTYPES_ASYNC.PENDING,
      getWikiTreatmentTypesCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_WIKITREATMENTLIST_ASYNC.PENDING,
      getWikiTreatmentListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_WIKIMEDICINETYPES_ASYNC.PENDING,
      getWikiMedicineTypesCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_WIKIMEDICINELIST_ASYNC.PENDING,
      getWikiMedicineListCall
    ),
  ]);
}

function* getWikiDataListCall(searchModel) {
  try {
    const data = yield call(wikiDataApis.getWikiDataListApi, searchModel.data);
    yield put(getWikiDataList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiDataList.error(error.response?.data?.message));
    else yield put(getWikiDataList.error(notification.CONST_NOINTERNET));
  }
}

function* getWikiDataPreviewListCall(searchModel) {
  try {
    const data = yield call(wikiDataApis.getWikiDataListApi, searchModel.data);
    yield put(getWikiDataPreviewList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiDataPreviewList.error(error.response?.data?.message));
    else yield put(getWikiDataPreviewList.error(notification.CONST_NOINTERNET));
  }
}

function* getWikiDataPathListCall(searchModel) {
  try {
    const data = yield call(wikiDataApis.getWikiDataListApi, searchModel.data);
    yield put(getWikiDataPathList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiDataPathList.error(error.response?.data?.message));
    else yield put(getWikiDataPathList.error(notification.CONST_NOINTERNET));
  }
}

function* getWikiDataSubNodeListCall(searchModel) {
  try {
    const data = yield call(wikiDataApis.getWikiDataListApi, searchModel.data);
    yield put(getWikiDataSubNodeList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiDataSubNodeList.error(error.response?.data?.message));
    else yield put(getWikiDataSubNodeList.error(notification.CONST_NOINTERNET));
  }
}

function* getWikiDataByIdCall(searchModel) {
  try {
    const data = yield call(wikiDataApis.getWikiDataByIdApi, searchModel.data);
    yield put(getWikiDataById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiDataById.error(error.response?.data?.message));
    else yield put(getWikiDataById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateWikiDataCall(searchModel) {
  try {
    const data = yield call(
      wikiDataApis.postCreateWikiDataApi,
      searchModel.data
    );
    yield put(postCreateWikiData.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateWikiData.error(error.response?.data?.message));
    else yield put(postCreateWikiData.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateWikiDataCall(searchModel) {
  try {
    const data = yield call(
      wikiDataApis.putUpdateWikiDataApi,
      searchModel.data
    );
    yield put(putUpdateWikiData.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateWikiData.error(error.response?.data?.message));
    else yield put(putUpdateWikiData.error(notification.CONST_NOINTERNET));
  }
}

function* getWikiAcupointListCall(searchModel) {
  try {
    const data = yield call(
      wikiDataApis.getWikiAcupointListApi,
      searchModel.data
    );
    yield put(getWikiAcupointList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiAcupointList.error(error.response?.data?.message));
    else yield put(getWikiAcupointList.error(notification.CONST_NOINTERNET));
  }
}

function* getWikiCustomWordListCall(searchModel) {
  try {
    const data = yield call(
      wikiDataApis.getWikiCustomWordListApi,
      searchModel.data
    );
    yield put(getWikiCustomWordList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiCustomWordList.error(error.response?.data?.message));
    else yield put(getWikiCustomWordList.error(notification.CONST_NOINTERNET));
  }
}

function* getWikiTreatmentTypesCall(searchModel) {
  try {
    const data = yield call(
      wikiDataApis.getWikiTreatmentTypesApi,
      searchModel.data
    );
    yield put(getWikiTreatmentTypes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiTreatmentTypes.error(error.response?.data?.message));
    else yield put(getWikiTreatmentTypes.error(notification.CONST_NOINTERNET));
  }
}

function* getWikiTreatmentListCall(searchModel) {
  try {
    const data = yield call(
      wikiDataApis.getWikiTreatmentListApi,
      searchModel.data
    );
    yield put(getWikiTreatmentList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiTreatmentList.error(error.response?.data?.message));
    else yield put(getWikiTreatmentList.error(notification.CONST_NOINTERNET));
  }
}

function* getWikiMedicineTypesCall(searchModel) {
  try {
    const data = yield call(
      wikiDataApis.getWikiMedicineTypesApi,
      searchModel.data
    );
    yield put(getWikiMedicineTypes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiMedicineTypes.error(error.response?.data?.message));
    else yield put(getWikiMedicineTypes.error(notification.CONST_NOINTERNET));
  }
}

function* getWikiMedicineListCall(searchModel) {
  try {
    const data = yield call(
      wikiDataApis.getWikiMedicineListApi,
      searchModel.data
    );
    yield put(getWikiMedicineList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWikiMedicineList.error(error.response?.data?.message));
    else yield put(getWikiMedicineList.error(notification.CONST_NOINTERNET));
  }
}
