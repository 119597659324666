import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";

import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducer";
import { initSagas } from "../saga/rootSaga";

const isDevelopment = process.env.NODE_ENV === "development";
const initialState = window.initialReduxState;
const sagaMiddleware = createSagaMiddleware();
const config = {
  channel: "redux_state_sync",
  whitelist: [
    "POST_LOGIN_SUCCESS",
    "POST_LOGOUT_SUCCESS",
    "PUT_USERINFO_SUCCESS",
    "PUT_USERLANG_SUCCESS",
    "PUT_ORGANISATIONINFO_SUCCESS",
    "GET_ORGANISATIONINFO_SUCCESS",
    "GET_CURROLERIGHT_SUCCESS",
    "GET_ALLRIGHTACCESS_SUCCESS",
    "GET_DEFAULTBRANCH_SUCCESS",
    "POST_PROVIDERACCESSTOKEN_SUCCESS",
    "POST_OTPCALLBACK_SUCCESS",
  ],
};

const enhancers = [];
const persistConfig = {
  key: "rootReducer",
  storage: storageSession,
  whitelist: ["userAuth", "curUser"], // only auth and curUser will be persisted, if not it is unable go to path "/"
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [sagaMiddleware, createStateSyncMiddleware(config)];

if (
  isDevelopment &&
  typeof window !== "undefined" &&
  window.devToolsExtension
) {
  enhancers.push(window.devToolsExtension());
}

const composedEnhancers = compose(applyMiddleware(...middleware));

const store = () => {
  const store = createStore(persistedReducer, initialState, composedEnhancers);
  initSagas(sagaMiddleware);
  initStateWithPrevTab(store);
  return { store, persistor: persistStore(store) };
};

export default store;
/**const store = createStore(rootReducer);


 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object

export const persistor = persistStore(store);

export default store;
 */
