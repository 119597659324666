import { createAsyncTypes } from "../_helper/Utilities";

export const GET_PRESCRIPTIONMSGBYID_ASYNC = createAsyncTypes(
  "GET_PRESCRIPTIONMSGBYID"
);

export const GET_PRESCRIPTIONMSGLIST_ASYNC = createAsyncTypes(
  "GET_PRESCRIPTIONMSGLIST"
);

export const POST_CREATEPRESCRIPTIONMSG_ASYNC = createAsyncTypes(
  "POST_CREATEPRESCRIPTIONMSG"
);

export const PUT_UPDATEPRESCRIPTIONMSG_ASYNC = createAsyncTypes(
  "PUT_UPDATEPRESCRIPTIONMSG"
);
