import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const branchMedicineApis = {
  getBranchMedicineListApi,
  getBranchMedicineByIdApi,
  putUpdateBranchMedicineApi,
  postBranchMedicineBranchListApi,
  postBranchMedicineMedicineTypeListApi,
  postBranchMedicineMedicineListApi,
  postBranchMedicineBranchMedicineHistoryListApi,
};

async function getBranchMedicineListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetBranchMedicineList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBranchMedicineByIdApi(model) {
  const url = apiConstants.apiGetBranchMedicineById + model;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateBranchMedicineApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutUpdateBranchMedicine,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postBranchMedicineBranchListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostBranchMedicineBranchList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postBranchMedicineMedicineTypeListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostBranchMedicineMedicineTypeList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postBranchMedicineMedicineListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostBranchMedicineMedicineList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postBranchMedicineBranchMedicineHistoryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostBranchMedicineBranchMedicineHistoryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default branchMedicineApis;
