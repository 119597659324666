import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Link } from "@mui/material";

import { postSearchCsvCourierDeliveryAddress } from "../../../../actions/deliveryOrderActions";
import ConfirmDialog from "../../../../components/ConfirmDialog";

//style
import { useStyles } from "../../../../components/globalStyles";
export default function ExportCourierDeliveryAddress({ orderInfo = null }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleConfirm = () => {
    const pageInfo = {
      delivery_order_header: { id: orderInfo.id },
    };
    dispatch(postSearchCsvCourierDeliveryAddress.pending(pageInfo));
  };

  return (
    <>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <Link
        className={classes.boldMaroon075}
        onClick={() => setConfirmOpen(true)}>
        <Button className={classes.boldMaroon075} name="synButton">
          {t("translation:Download Courier Delivery Address CSV")}
        </Button>
      </Link>
    </>
  );
}
