import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getBranchPhysicianScheduleList,
  getBranchPhysicianScheduleById,
  postBranchPhysicianScheduleByBranchIdPhysicianId,
  getBranchPhysicianScheduleBranchList,
  getBranchPhysicianSchedulePhysicianList,
} from "../actions/branchPhysicianScheduleActions";
import * as types from "../_constants/branchPhysicianScheduleConstants";
import * as notification from "../_constants/notificationConstants";
import branchPhysicianScheduleApis from "../_apis/branchPhysicianScheduleApis";
export default function* branchPhysicianScheduleSagas() {
  yield all([
    takeLatest(
      types.GET_BRANCHPHYSICIANSCHEDULELIST_ASYNC.PENDING,
      getBranchPhysicianScheduleListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_BRANCHPHYSICIANSCHEDULEBYID_ASYNC.PENDING,
      getBranchPhysicianScheduleByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHPHYSICIANSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC.PENDING,
      postBranchPhysicianScheduleByBranchIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_BRANCHPHYSICIANSCHEDULEBRANCHLIST_ASYNC.PENDING,
      getBranchPhysicianScheduleBranchListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_BRANCHPHYSICIANSCHEDULEPHYSICIANLIST_ASYNC.PENDING,
      getBranchPhysicianSchedulePhysicianListCall
    ),
  ]);
}

function* getBranchPhysicianScheduleListCall(Model) {
  try {
    const data = yield call(
      branchPhysicianScheduleApis.getBranchPhysicianScheduleListApi,
      Model.data
    );
    yield put(getBranchPhysicianScheduleList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchPhysicianScheduleList.error(error.response?.data?.message)
      );
    else
      yield put(
        getBranchPhysicianScheduleList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getBranchPhysicianScheduleByIdCall(Model) {
  try {
    const data = yield call(
      branchPhysicianScheduleApis.getBranchPhysicianScheduleByIdApi,
      Model.data
    );
    yield put(getBranchPhysicianScheduleById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchPhysicianScheduleById.error(error.response?.data?.message)
      );
    else
      yield put(
        getBranchPhysicianScheduleById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postBranchPhysicianScheduleByBranchIdCall(Model) {
  try {
    const data = yield call(
      branchPhysicianScheduleApis.postBranchPhysicianScheduleByBranchIdPhysicianIdApi,
      Model.data
    );
    yield put(postBranchPhysicianScheduleByBranchIdPhysicianId.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchPhysicianScheduleByBranchIdPhysicianId.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postBranchPhysicianScheduleByBranchIdPhysicianId.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getBranchPhysicianScheduleBranchListCall(Model) {
  try {
    const data = yield call(
      branchPhysicianScheduleApis.getBranchPhysicianScheduleBranchListApi,
      Model.data
    );
    yield put(getBranchPhysicianScheduleBranchList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchPhysicianScheduleBranchList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getBranchPhysicianScheduleBranchList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getBranchPhysicianSchedulePhysicianListCall(Model) {
  try {
    const data = yield call(
      branchPhysicianScheduleApis.getBranchPhysicianSchedulePhysicianListApi,
      Model.data
    );
    yield put(getBranchPhysicianSchedulePhysicianList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchPhysicianSchedulePhysicianList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getBranchPhysicianSchedulePhysicianList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}
