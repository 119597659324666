//lib
import React from "react";
import { Grid, TextField } from "@mui/material";

import { useTranslation } from "react-i18next";
//action

//components
import { useStyles } from "../../../../components/globalStyles";
import Panel from "../../../../components/Panel";

const PurchaseOrderField = ({
  supplier = null,
  viewOnly = false,
  showMutliplier = false,
  costPriceMutiplier,
  setCostPriceMutiplier,
  supplierReferenceNo,
  setSupplierReferenceNo,
}) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();

  //functions

  //useEffects

  return (
    <Panel>
      <Grid className={classes.gridRoot} container>
        <Grid item xs={12} className={classes.userInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={true}
            value={supplier.name}
            id="supplier"
            name="supplier"
            label={t("translation:Supplier")}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} className={classes.userInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={viewOnly}
            value={supplierReferenceNo ? supplierReferenceNo : ""}
            onChange={(event) => setSupplierReferenceNo(event.target.value)}
            id="supplier_reference_number"
            name="supplier_reference_number"
            label={t("translation:Supplier Reference Number")}
            variant="outlined"
          />
        </Grid>
        {showMutliplier === true && viewOnly === false && (
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={costPriceMutiplier !== null ? costPriceMutiplier : ""}
              id="multiplier"
              name="multiplier"
              required
              label={t("translation:Multiplier")}
              onChange={(event) => setCostPriceMutiplier(event.target.value)}
              variant="outlined"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "left" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        )}
        {/* <Grid item xs={12} className={classes.userInfoField}>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={manualCalculaion ? true : false}
                onChange={(event) => {
                  event.target.checked === true
                    ? setManualCalculaion(true)
                    : setManualCalculaion(false);
                }}
                name="manualCalculationCheck"
                cy_data="manualCalculationCheck"
              />
            }
            label={t("translation:Manual Calculation")}
          />
        </Grid> */}
      </Grid>
    </Panel>
  );
};

export default PurchaseOrderField;
