import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import NonBusinessDaySummaryPage from "./NonBusinessDaySummary/NonBusinessDaySummaryPage";
export default function NonBusinessDayPage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/nonBusinessDay"
        to="/nonBusinessDay/nonBusinessDaySummary"
      />
      <Route
        path="/nonBusinessDay/nonBusinessDaySummary"
        component={NonBusinessDaySummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
