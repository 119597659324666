import { createAsyncTypes } from "../_helper/Utilities";

export const GET_MEDICINEFIXEDSELLINGPRICELIST_ASYNC = createAsyncTypes(
  "GET_MEDICINEFIXEDSELLINGPRICELIST"
);

export const GET_MEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_MEDICINEFIXEDSELLINGPRICESUMMARYLIST"
);

export const GET_MEDICINEFIXEDSELLINGPRICEBYID_ASYNC = createAsyncTypes(
  "GET_MEDICINEFIXEDSELLINGPRICEBYID"
);

export const POST_CREATEMEDICINEFIXEDSELLINGPRICE_ASYNC = createAsyncTypes(
  "POST_CREATEMEDICINEFIXEDSELLINGPRICE"
);

export const PUT_UPDATEMEDICINEFIXEDSELLINGPRICE_ASYNC = createAsyncTypes(
  "PUT_UPDATEMEDICINEFIXEDSELLINGPRICE"
);
