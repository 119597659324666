import { createAsyncTypes } from "../_helper/Utilities";

const externalApiConstants = {};

export function apiPostSearchPostalCode(postcodeSearch, pageNum = 1) {
  /*One map*/
  //searching postal code
  let apiURL = `/api/common/elastic/search?searchVal=${postcodeSearch}&returnGeom=Y&getAddrDetails=Y&pageNum=${pageNum}`;
  return apiURL;
}

export default externalApiConstants;

export const GET_SINGAPOREHOLIDAYLIST_ASYNC = createAsyncTypes(
  "GET_SINGAPOREHOLIDAYLIST"
);

export const GET_SINGAPOREYEARLYHOLIDAY_ASYNC = createAsyncTypes(
  "GET_SINGAPOREYEARLYHOLIDAY"
);
