import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Close from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//components
import * as generalConstants from "../../../../../_constants/generalConstants";
import PageSubmitWBackAction from "../../../../../components/PageSubmitWBackAction";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import { useStyles } from "../../../../../components/globalStyles";
import * as generalConstant from "../../../../../_constants/generalConstants";

export default function AddAdhocModal({
  openModal,
  setOpenModal,
  branchId,
  editNonBusinessDay,
  setEditNonBusinessDay,
  handleUpdate,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleSubmit } = useForm();
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const common = useSelector((state) => state.common);

  const [adhocModal, setAdhocModal] = useState({
    id: editNonBusinessDay ? editNonBusinessDay.id : 0,
    closed_date: editNonBusinessDay ? editNonBusinessDay.closed_date : null,
    closed_day: editNonBusinessDay ? editNonBusinessDay.closed_day : null,
    name: editNonBusinessDay ? editNonBusinessDay.name : null,
    status_type: editNonBusinessDay
      ? editNonBusinessDay.status_type
      : generalConstant.APPROVED_STATUS_TYPE,
  });
  const [errorProps, setErrorProps] = useState({});

  const onConfirmSubmit = () => {
    let newResult = [];
    newResult = [
      {
        id: adhocModal.id,
        closed_date: adhocModal.closed_date,
        closed_day: adhocModal.closed_day,
        name: adhocModal.name,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
      },
    ];

    const submitJson = {
      branch: { id: branchId, non_business_days: newResult },
    };
    handleUpdate(submitJson);
    handleReset();
  };

  const handleReset = () => {
    setAdhocModal({
      id: 0,
      closed_date: null,
      closed_day: null,
      name: null,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
    });
    setEditNonBusinessDay(null);
    setOpenModal(false);
  };

  const onSubmit = () => {
    setErrorProps(
      adhocModal.closed_date === null
        ? {
            helperText: "Input is required",
            error: true,
          }
        : {}
    );
    if (adhocModal.closed_date) {
      setConfirmSubmit(true);
    }
  };

  return (
    <Dialog
      open={openModal}
      maxWidth="xs"
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleReset();
        }
      }}
      fullWidth>
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => handleReset()}
      />
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="confirm-dialog">
          {editNonBusinessDay
            ? t("translation:Edit Non Business Day")
            : t("translation:Add Non Business Day")}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              required
              value={adhocModal.name ? adhocModal.name : ""}
              className={classes.userFieldColor}
              variant="outlined"
              label={t("translation:Name")}
              fullWidth
              onChange={(e) =>
                setAdhocModal({
                  ...adhocModal,
                  name: e.target.value ? e.target.value : null,
                })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              value={adhocModal.closed_day ? adhocModal.closed_day : ""}
              className={classes.userFieldColor}
              variant="outlined"
              label={t("translation:Closed Day")}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className={classes.userFieldColor}
                inputVariant="outlined"
                id="date"
                label={t("translation:Closed Date")}
                autoOk={true}
                slotProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                  textField: {
                    required: true,
                  },
                }}
                value={
                  adhocModal.closed_date ? dayjs(adhocModal.closed_date) : null
                }
                {...errorProps}
                format="DD MMM YYYY"
                onChange={(e) => {
                  setAdhocModal({
                    ...adhocModal,
                    closed_day: dayjs(e, true).isValid()
                      ? generalConstant.weekdaysOffset.find(
                          (item) => item.day === dayjs(e).day()
                        ).name
                      : null,
                    closed_date: dayjs(e, true).isValid()
                      ? dayjs(e).format("YYYY-MM-DD")
                      : null,
                  });
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <Autocomplete
              name="status"
              options={
                adhocModal.id
                  ? common.generalStatusList.status_general
                  : common.generalStatusList.status_general.filter(
                      (item) => item.id === generalConstant.APPROVED_STATUS_TYPE
                    )
              }
              disableClearable
              value={common.generalStatusList.status_general.find(
                (item) => item.id === adhocModal.status_type
              )}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => {
                if (option === value) return option;
              }}
              style={{ width: "100%" }}
              onChange={(e, option) =>
                setAdhocModal({
                  ...adhocModal,
                  status_type: option ? option.id : null,
                })
              }
              renderInput={(params) => (
                <TextField
                  required
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Status")}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </DialogContent>

        <PageSubmitWBackAction
          setView={() => {
            handleReset();
          }}
        />
      </form>
    </Dialog>
  );
}
