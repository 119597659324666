import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getBranchPhysicianAdhocScheduleList,
  getBranchPhysicianAdhocScheduleById,
  postBranchPhysicianAdhocScheduleByBranchIdPhysicianId,
  getBranchPhysicianAdhocScheduleBranchList,
  getBranchPhysicianAdhocSchedulePhysicianList,
} from "../actions/branchPhysicianAdhocScheduleActions";
import * as types from "../_constants/branchPhysicianAdhocScheduleConstants";
import * as notification from "../_constants/notificationConstants";
import branchPhysicianAdhocScheduleApis from "../_apis/branchPhysicianAdhocScheduleApis";
export default function* branchPhysicianAdhocScheduleSagas() {
  yield all([
    takeLatest(
      types.GET_BRANCHPHYSICIANADHOCSCHEDULELIST_ASYNC.PENDING,
      getBranchPhysicianAdhocScheduleListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_BRANCHPHYSICIANADHOCSCHEDULEBYID_ASYNC.PENDING,
      getBranchPhysicianAdhocScheduleByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_BRANCHPHYSICIANADHOCSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC
        .PENDING,
      postBranchPhysicianAdhocScheduleByBranchIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_BRANCHPHYSICIANADHOCSCHEDULEBRANCHLIST_ASYNC.PENDING,
      getBranchPhysicianAdhocScheduleBranchListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_BRANCHPHYSICIANADHOCSCHEDULEPHYSICIANLIST_ASYNC.PENDING,
      getBranchPhysicianAdhocSchedulePhysicianListCall
    ),
  ]);
}

function* getBranchPhysicianAdhocScheduleListCall(Model) {
  try {
    const data = yield call(
      branchPhysicianAdhocScheduleApis.getBranchPhysicianAdhocScheduleListApi,
      Model.data
    );
    yield put(getBranchPhysicianAdhocScheduleList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchPhysicianAdhocScheduleList.error(error.response?.data?.message)
      );
    else
      yield put(
        getBranchPhysicianAdhocScheduleList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getBranchPhysicianAdhocScheduleByIdCall(Model) {
  try {
    const data = yield call(
      branchPhysicianAdhocScheduleApis.getBranchPhysicianAdhocScheduleByIdApi,
      Model.data
    );
    yield put(getBranchPhysicianAdhocScheduleById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchPhysicianAdhocScheduleById.error(error.response?.data?.message)
      );
    else
      yield put(
        getBranchPhysicianAdhocScheduleById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postBranchPhysicianAdhocScheduleByBranchIdCall(Model) {
  try {
    const data = yield call(
      branchPhysicianAdhocScheduleApis.postBranchPhysicianAdhocScheduleByBranchIdPhysicianIdApi,
      Model.data
    );
    yield put(
      postBranchPhysicianAdhocScheduleByBranchIdPhysicianId.success(data)
    );
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postBranchPhysicianAdhocScheduleByBranchIdPhysicianId.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        postBranchPhysicianAdhocScheduleByBranchIdPhysicianId.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getBranchPhysicianAdhocScheduleBranchListCall(Model) {
  try {
    const data = yield call(
      branchPhysicianAdhocScheduleApis.getBranchPhysicianAdhocScheduleBranchListApi,
      Model.data
    );
    yield put(getBranchPhysicianAdhocScheduleBranchList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchPhysicianAdhocScheduleBranchList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getBranchPhysicianAdhocScheduleBranchList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getBranchPhysicianAdhocSchedulePhysicianListCall(Model) {
  try {
    const data = yield call(
      branchPhysicianAdhocScheduleApis.getBranchPhysicianAdhocSchedulePhysicianListApi,
      Model.data
    );
    yield put(getBranchPhysicianAdhocSchedulePhysicianList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getBranchPhysicianAdhocSchedulePhysicianList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getBranchPhysicianAdhocSchedulePhysicianList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}
