import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
//components
import * as generalConstants from "../../../_constants/generalConstants";

import SocketNotification from "./SocketNotification";

import StoreListTable from "../../visit/Consultation/StoreListTable";
//Actions

//styles
export default function VisitOrderTable() {
  const { t } = useTranslation();

  //variable

  const [refreshTimeStamp, setRefreshTimeStamp] = useState(moment());

  //useEffect

  return (
    <SocketNotification
      cellTitle={t("translation:Visit")}
      sockectWorkTypeId={generalConstants.VISIT_WORK_ORDER_TYPE}
      setRefreshTimeStamp={setRefreshTimeStamp}
      localStorageName="visitListTableNotification">
      <StoreListTable
        refreshTimeStamp={refreshTimeStamp}
        dashboardView={true}
        setRefreshTimeStamp={setRefreshTimeStamp}
      />
    </SocketNotification>
  );
}
