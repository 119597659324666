import { Grid, TextField, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { useStyles } from "../../../../components/globalStyles";
import PageCustomAction from "../../../../components/PageCustomAction";

const PatientField = ({
  isNewAppointment,
  isTempPatient,
  isNewTempPatient,
  setIsNewTempPatient,
  patientName,
  setPatientName,
  patientNric,
  setPatientNric,
  patientMobileNumber,
  setPatientMobileNumber,
  appointmentMobileNumber,
  setAppointmentMobileNumber,
  patientApptDescription,
  setPatientApptDescription,
  setConfirmCancelOpen,
  physician,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <>
      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          required={isTempPatient}
          className={classes.userFieldColor}
          id="patient-name"
          value={patientName ? patientName : ""}
          label={t("translation:Name")}
          variant="outlined"
          onChange={(e) => setPatientName(e.target.value)}
          disabled={!isTempPatient || !isNewAppointment || !isNewTempPatient}
        />
        {isTempPatient && isNewAppointment && !isNewTempPatient && (
          <Button
            onClick={() => setIsNewTempPatient(true)}
            className={classes.iconColor}>
            <EditIcon />
          </Button>
        )}
      </Grid>
      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          className={classes.userFieldColor}
          id="patient-nric"
          value={patientNric ? patientNric : ""}
          label={t("translation:Identification Number")}
          autoComplete="off"
          variant="outlined"
          onChange={(e) => setPatientNric(e.target.value)}
          disabled={!isTempPatient || !isNewAppointment || !isNewTempPatient}
        />
      </Grid>
      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          className={classes.userFieldColor}
          id="patient-mobile-number"
          value={patientMobileNumber ? patientMobileNumber : ""}
          label={t("translation:Contact Number")}
          variant="outlined"
          onChange={(e) => setPatientMobileNumber(e.target.value)}
          disabled={!isTempPatient || !isNewAppointment || !isNewTempPatient}
        />
      </Grid>

      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          className={classes.userFieldColor}
          id="patient-appt-mobile-number"
          value={appointmentMobileNumber ? appointmentMobileNumber : ""}
          label={t("translation:Appointment Contact Number")}
          variant="outlined"
          onChange={(e) => setAppointmentMobileNumber(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} className={classes.userInfoField}>
        <TextField
          multiline
          rows={3}
          className={classes.userFieldColor}
          id="patient-appt-description"
          value={patientApptDescription ? patientApptDescription : ""}
          label={t("translation:Appointment Description")}
          variant="outlined"
          onChange={(e) => setPatientApptDescription(e.target.value)}
        />
      </Grid>

      {physician && !isNewAppointment && (
        <PageCustomAction
          button1Name="cancelAppointment"
          button1Click={() => setConfirmCancelOpen(true)}
          button1label={t("translation:CANCEL")}
        />
      )}
    </>
  );
};

export default PatientField;
