//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//Actions
import {
  getUserMedicineFixedSellingPriceUserList,
  getUserMedicineFixedSellingPriceList,
} from "../../../../actions/userMedicineFixedSellingPriceActions";

//Component
import { MultiFieldSortingSearchTable } from "../../../../components/MultiFieldSortingSearchTable";
import { EditUserMedicineFixedSellingPricePage } from "./EditUserMedicineFixedSellingPricePage";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";
import { useStyles } from "../../../../components/globalStyles";

export default function UserMedicineFixedSellingPriceListTable({
  setInformationView,
}) {
  //variables
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const userMedicineFixedSellingPriceData = useSelector(
    (state) => state.userMedicineFixedSellingPrice
  );
  const curUser = useSelector((state) => state.curUser);
  const common = useSelector((state) => state.common);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    search_contact_number_value: null,
    search_date_of_birth_value: null,
    search_identification_number_value: null,
    search_name_value: null,
    search_username_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
  });
  const [searchValue, setSearchValue] = React.useState({
    name: null,
    contactNo: null,
    dateofBirth: null,
    identificationNo: null,
  });

  //Init
  const [
    editUserMedicineFixedSellingPriceView,
    setEditUserMedicineFixedSellingPriceView,
  ] = React.useState(false);
  const [
    userMedicineFixedSellingPriceDetailView,
    setUserMedicineFixedSellingPriceDetailView,
  ] = React.useState(false);

  const [userInfo, setUserInfo] = React.useState(null);

  //Onclick event
  const handleRowEdit = (value) => {
    dispatch(
      getUserMedicineFixedSellingPriceList.pending({
        is_pagination: false,
        search_user_id_value: value.id,
      })
    );
    setUserInfo(value);
    setEditUserMedicineFixedSellingPriceView(true);
  };

  const handleRowDetail = (value) => {
    dispatch(
      getUserMedicineFixedSellingPriceList.pending({
        is_pagination: false,
        search_user_id_value: value.id,
      })
    );
    setUserInfo(value);
    setUserMedicineFixedSellingPriceDetailView(true);
  };

  //For Search
  const handleSearch = (value) => {
    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      search_contact_number_value: searchValue.contactNo,
      search_date_of_birth_value: searchValue.dateofBirth,
      search_identification_number_value: searchValue.identificationNo,
      search_name_value: searchValue.name,
    });
  };

  //useEffects
  useEffect(() => {
    if (
      userMedicineFixedSellingPriceDetailView === true ||
      editUserMedicineFixedSellingPriceView === true
    ) {
      setInformationView(true);
    } else {
      setInformationView(false);
      setUserInfo(null);
    }
  }, [
    userMedicineFixedSellingPriceDetailView,
    editUserMedicineFixedSellingPriceView,
    setInformationView,
  ]);

  useEffect(() => {
    if (!editUserMedicineFixedSellingPriceView) {
      dispatch(getUserMedicineFixedSellingPriceUserList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editUserMedicineFixedSellingPriceView]);

  if (
    userMedicineFixedSellingPriceData.getUserMedicineFixedSellingPriceUserListIsSuccess ===
      false &&
    userMedicineFixedSellingPriceData.isLoadingGetUserMedicineFixedSellingPriceUserList ===
      false
  ) {
    return (
      <Alert severity="error">
        {
          userMedicineFixedSellingPriceData.error
            .getUserMedicineFixedSellingPriceUserListError
        }
      </Alert>
    );
  } else if (
    common.generalStatusList &&
    !editUserMedicineFixedSellingPriceView &&
    !userMedicineFixedSellingPriceDetailView
  ) {
    return (
      <MultiFieldSortingSearchTable
        searchPlaceholder={""}
        columns={columns}
        data={
          userMedicineFixedSellingPriceData
            .getUserMedicineFixedSellingPriceUserList?.users !== undefined
            ? userMedicineFixedSellingPriceData
                .getUserMedicineFixedSellingPriceUserList?.users
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          userMedicineFixedSellingPriceData.getUserMedicineFixedSellingPriceUserList
            ? userMedicineFixedSellingPriceData
                .getUserMedicineFixedSellingPriceUserList.total_pages
            : 0
        }
        loading={
          userMedicineFixedSellingPriceData.isLoadingUserMedicineFixedSellingPriceList
        }
        totalItems={
          userMedicineFixedSellingPriceData.getUserMedicineFixedSellingPriceUserList
            ? userMedicineFixedSellingPriceData
                .getUserMedicineFixedSellingPriceUserList.total_items
            : 0
        }
        handleSearch={handleSearch}
        localStorageName="userListTable">
        <TextField
          className={classes.userFieldColor}
          label={t("translation:Name")}
          variant="outlined"
          value={searchValue.name ? searchValue.name : ""}
          onChange={(e) =>
            setSearchValue({ ...searchValue, name: e.target.value })
          }
        />

        <TextField
          className={classes.userFieldColor}
          label={t("translation:Contact Number")}
          variant="outlined"
          value={searchValue.contactNo ? searchValue.contactNo : ""}
          onChange={(e) =>
            setSearchValue({ ...searchValue, contactNo: e.target.value })
          }
        />

        <TextField
          className={classes.userFieldColor}
          label={t("translation:Identification Number")}
          variant="outlined"
          autoComplete="off"
          value={
            searchValue.identificationNo ? searchValue.identificationNo : ""
          }
          onChange={(e) =>
            setSearchValue({
              ...searchValue,
              identificationNo: e.target.value,
            })
          }
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className={classes.userFieldColor}
            inputVariant="outlined"
            id="date"
            label={t("translation:Date of Birth")}
            autoOk={true}
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
            }}
            value={
              searchValue.dateofBirth ? dayjs(searchValue.dateofBirth) : null
            }
            format="DD MMM YYYY"
            onChange={(e) =>
              setSearchValue({
                ...searchValue,
                dateofBirth: dayjs(e, true).isValid()
                  ? dayjs(e).format("YYYY-MM-DD")
                  : null,
              })
            }
          />
        </LocalizationProvider>
      </MultiFieldSortingSearchTable>
    );
  } else if (
    userMedicineFixedSellingPriceData.getUserMedicineFixedSellingPriceList &&
    userMedicineFixedSellingPriceDetailView &&
    userMedicineFixedSellingPriceData.getUserMedicineFixedSellingPriceListIsSuccess ===
      true
  ) {
    return (
      <EditUserMedicineFixedSellingPricePage
        userMedicineFixedSellingPrice={
          userMedicineFixedSellingPriceData.getUserMedicineFixedSellingPriceList
            .user_medicine_fixed_selling_price_headers
        }
        userInfo={userInfo}
        setEditUserMedicineFixedSellingPriceView={
          setUserMedicineFixedSellingPriceDetailView
        }
        viewOnly
      />
    );
  } else if (
    userMedicineFixedSellingPriceData.getUserMedicineFixedSellingPriceList &&
    editUserMedicineFixedSellingPriceView &&
    userMedicineFixedSellingPriceData.getUserMedicineFixedSellingPriceListIsSuccess ===
      true
  ) {
    return (
      <EditUserMedicineFixedSellingPricePage
        userMedicineFixedSellingPrice={
          userMedicineFixedSellingPriceData.getUserMedicineFixedSellingPriceList
            .user_medicine_fixed_selling_price_headers
        }
        userInfo={userInfo}
        setEditUserMedicineFixedSellingPriceView={
          setEditUserMedicineFixedSellingPriceView
        }
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
