import * as generalConstants from "../../../../../_constants/generalConstants";

//actions
import { postVisitChangeBranchTreatmentOnly } from "../../../../../actions/visitOrderActions";

function OnConfirmSubmitChangeBranch(
  curTreatmentResult,
  tempTreatmentResult,
  dispatch
) {
  const submisionJson = {
    store_header: {
      visit_treatment_details: [...curTreatmentResult, ...tempTreatmentResult],
    },
  };
  dispatch(postVisitChangeBranchTreatmentOnly.pending(submisionJson));
}

//////change treamtmet id to 0 if organisation id is diferent
const CopyTreatment = (orderInfo, copyInfo, dispatch) => {
  const curTreatmentResultWODelete = orderInfo.visit_treatment_details.filter(
    (item) => item.id !== 0
  );
  const differentOrganisation =
    copyInfo.organisation.id !== orderInfo.organisation.id;

  let curTreatmentResult = curTreatmentResultWODelete.map((item) => {
    const result = {
      ...item,
      status_type: generalConstants.DELETED_STATUS_TYPE,
    };
    return result;
  });

  const tempTreatmentResultWODelete = copyInfo.visit_treatment_details.filter(
    (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  );

  const tempTreatmentResult = tempTreatmentResultWODelete.map((item) => {
    //filter repeat treatment
    const repeatTreatment = curTreatmentResult.find(
      (repeatItem) => repeatItem.treatment.id === item.treatment.id
    );

    if (repeatTreatment) {
      curTreatmentResult = curTreatmentResult.filter(
        (filterItem) => filterItem.treatment.id !== repeatTreatment.treatment.id
      );

      //replace it
      const result = {
        ...item,
        id: repeatTreatment.id,
        treatment_name: item.treatment.name,
        desc: item.treatment.desc,
        treatment_type_name: item.treatment.treatment_type.name,
      };
      return result;
    } else {
      //create new
      const result = {
        ...item,
        id: 0,
        treatment_name: item.treatment.name,
        desc: item.treatment.desc,
        treatment_type_name: item.treatment.treatment_type.name,
      };

      return result;
    }
  });

  const copyTreatmentInfo = {
    ...orderInfo,
    visit_treatment_details: [...curTreatmentResult, ...tempTreatmentResult],
  };

  if (
    differentOrganisation &&
    copyTreatmentInfo.visit_treatment_details.length !== 0
  ) {
    OnConfirmSubmitChangeBranch(
      curTreatmentResult,
      tempTreatmentResult,
      dispatch
    );
  }

  return copyTreatmentInfo;
};

export default CopyTreatment;
