//lib
import React, { useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//component
import BranchCourierListTable from "./BranchCourierListTable";

import { useStyles } from "../../../../components/globalStyles";

const BranchCourierSummaryPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const [informationView, setInformationView] = React.useState(false);
  const [viewInfo, setViewInfo] = React.useState(null);
  const [editView, setEditView] = React.useState(false);
  const [detailView, setDetailView] = React.useState(false);
  const prevUpdateBranchCourierStatus = useRef();
  const branchCourier = useSelector((state) => state.branchCourier);

  useEffect(() => {
    //set ref when first render
    prevUpdateBranchCourierStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      branchCourier.isLoadingPutUpdateBranchCourier !==
      prevUpdateBranchCourierStatus.current
    ) {
      //check the previous ref with current state
      prevUpdateBranchCourierStatus.current =
        branchCourier.isLoadingPutUpdateBranchCourier;

      if (
        branchCourier.putUpdateBranchCourierIsSuccess === true &&
        branchCourier.isLoadingPutUpdateBranchCourier === false
      ) {
        setEditView(false);
        setDetailView(true);
        setViewInfo(branchCourier.updateBranchCourier);
      }
    }
  }, [branchCourier]);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!informationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title} variant="h3" gutterBottom>
              {t("translation:Branch Courier Summary")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <hr />

      <BranchCourierListTable
        setInformationView={setInformationView}
        viewInfo={viewInfo}
        setViewInfo={setViewInfo}
        editView={editView}
        setEditView={setEditView}
        detailView={detailView}
        setDetailView={setDetailView}
      />
    </Paper>
  );
};

export default BranchCourierSummaryPage;
