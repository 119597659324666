import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/dashboardConstants";

export const getDashboardAppointmentList = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDAPPOINTMENTLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DASHBOARDAPPOINTMENTLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DASHBOARDAPPOINTMENTLIST_ASYNC.ERROR, { error }),
};

export const getDashboardBrewingList = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDBREWINGLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DASHBOARDBREWINGLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DASHBOARDBREWINGLIST_ASYNC.ERROR, { error }),
};

export const getDashboardDispensaryList = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDDISPENSARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DASHBOARDDISPENSARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DASHBOARDDISPENSARYLIST_ASYNC.ERROR, { error }),
};

export const getDashboardDeliveryOrderList = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDDELIVERYORDERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DASHBOARDDELIVERYORDERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DASHBOARDDELIVERYORDERLIST_ASYNC.ERROR, { error }),
};

export const getDashboardInvoiceList = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDINVOICELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DASHBOARDINVOICELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DASHBOARDINVOICELIST_ASYNC.ERROR, { error }),
};

export const getDashboardInvoiceMedicineCostingList = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDINVOICEMEDICINECOSTINGLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_DASHBOARDINVOICEMEDICINECOSTINGLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_DASHBOARDINVOICEMEDICINECOSTINGLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getDashboardStoreOrderList = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDSTOREORDERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DASHBOARDSTOREORDERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DASHBOARDSTOREORDERLIST_ASYNC.ERROR, { error }),
};

export const getDashboardVisitOrderList = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDVISITORDERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DASHBOARDVISITORDERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DASHBOARDVISITORDERLIST_ASYNC.ERROR, { error }),
};

export const getDashboardAllApprovedLocalDispensary = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDALLAPPROVEDLOCALDISPENSARY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_DASHBOARDALLAPPROVEDLOCALDISPENSARY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_DASHBOARDALLAPPROVEDLOCALDISPENSARY_ASYNC.ERROR, {
      error,
    }),
};

export const getDashboardAllStoreWDispensaryList = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDALLSTOREWDISPENSARYLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_DASHBOARDALLSTOREWDISPENSARYLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_DASHBOARDALLSTOREWDISPENSARYLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getDashboardCounterView = {
  pending: (data) =>
    createAction(types.GET_DASHBOARDCOUNTERVIEW_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_DASHBOARDCOUNTERVIEW_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_DASHBOARDCOUNTERVIEW_ASYNC.ERROR, {
      error,
    }),
};
