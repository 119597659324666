//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import moment from "moment";

//Actions

import {
  getUserCreditList,
  getUserCreditById,
} from "../../../../actions/userCreditActions";

import { getUserCreditType } from "../../../../actions/commonActions";

//Component
import UserCreditComponentPage from "./UserCreditComponentPage";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
//import * as generalConstants from "../../../../_constants/generalConstants";

export default function UserCreditListTable({ setInformationView }) {
  //variables
  const { t } = useTranslation();

  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: t("translation:User Name"),
      accessor: "user.name",
    },

    {
      Header: t("translation:Amount"),
      accessor: "credit_amt",
      sortable: false,
      Cell: ({ cell }) => {
        return cell.value ? Number(cell.value).toFixed(2) : "";
      },
    },

    {
      Header: t("translation:Credit Type"),
      accessor: "user_credit_type",
      sortable: false,
      Cell: ({ cell }) => {
        const cellValue =
          common.userCreditType.user_credit_type_select_field.find(
            (item) => item.id === cell.value
          );

        return cellValue ? cellValue.name : "";
      },
    },
    {
      Header: t("translation:Order No"),
      accessor: "store_header.order_number",
    },
    {
      Header: t("translation:Modified Date"),
      accessor: "modified_on",

      Cell: ({ row }) => {
        if (row.original.modified_on)
          return moment
            .utc(row.original.modified_on)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },
    },
  ];

  const curUser = useSelector((state) => state.curUser);
  const organizationInfo = curUser.organisationInfo;

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_desc_value: null,
    search_id_value: null,
    search_name_value: null,
    search_status_type_value: null,
    search_user_credit_type_value: null,
    search_user_id_value: null,
    search_user_name_value: null,
  });

  const dispatch = useDispatch();

  const prevGetUserCreditByIdStatus = useRef();

  const common = useSelector((state) => state.common);

  const userCredit = useSelector((state) => state.userCredit);

  //Init

  const [userCreditDetailView, setUserCreditDetailView] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [userCreditInfoById, setUserCreditInfoById] = useState(null);

  const handleRowDetail = (value) => {
    setUserCreditDetailView(true);
    setUserCreditInfoById(null);
    dispatch(getUserCreditById.pending({ id: value.id }));
  };
  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_user_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_user_name_value: "",
      });
    }
  };

  //useEffects
  useEffect(() => {
    //set ref when first render
    prevGetUserCreditByIdStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      userCredit.isLoadingUserCreditById !== prevGetUserCreditByIdStatus.current
    ) {
      //check the previous ref with current state
      prevGetUserCreditByIdStatus.current = userCredit.isLoadingUserCreditById;

      if (
        userCredit.getUserCreditByIdIsSuccess === true &&
        userCredit.isLoadingUserCreditById === false
      ) {
        setUserCreditInfoById(userCredit.userCreditById.user_credit);
      }
    }
  }, [userCredit]);

  useEffect(() => {
    if (userCreditDetailView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [userCreditDetailView, setInformationView]);

  useEffect(() => {
    dispatch(getUserCreditType.pending());
  }, [dispatch]);

  useEffect(() => {
    if (organizationInfo) {
      dispatch(
        getUserCreditList.pending({
          ...pageInfo,
          search_organisation_id_value:
            organizationInfo?.user_organisations.find(
              (o) => o.is_default === true
            )?.organisations.id,
        })
      );
    }
  }, [dispatch, pageInfo, organizationInfo]);

  if (
    userCredit.getUserCreditListSuccess === false &&
    userCredit.isLoadingUserCreditList === false
  ) {
    return (
      <Alert severity="error">{userCredit.error.userCreditListError}</Alert>
    );
  } else if (!userCreditDetailView && common.userCreditType) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:User Name")}
        searchValue={searchValue}
        columns={columns}
        data={
          userCredit.userCreditList
            ? userCredit.userCreditList.user_credits
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          userCredit.userCreditList ? userCredit.userCreditList.total_pages : 0
        }
        loading={false}
        totalItems={
          userCredit.userCreditList ? userCredit.userCreditList.total_items : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (userCreditInfoById && userCreditDetailView) {
    return (
      <UserCreditComponentPage
        userCreditInfoById={userCreditInfoById}
        viewMode={true}
        setListView={() => setUserCreditDetailView(false)}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
