//lib
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
//components
import { useStyles } from "../../../../components/globalStyles";
import DropZoneDialog from "../../../../components/DropZoneDialog";
import * as generalConstants from "../../../../_constants/generalConstants";
import externetApis from "../../../../_apis/externetApis";
//action

import {
  postCreatePresignedUrlPhoto,
  postCreatePresignedJsonPhoto,
} from "../../../../actions/cloudStorageActions";

export default function UploadPhoto({
  organisationCode = "default",
  folderPath = "photo",
  uploadFileObjects = {},
  setUploadFileObjects = () => null,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const prevCreatePresignedUrlStatus = useRef();
  const prevCreatePresignedJsonStatus = useRef();
  const cloudStorage = useSelector((state) => state.cloudStorage);
  const curUser = useSelector((state) => state.curUser);
  const [open, setOpen] = React.useState(false);
  const [fileObjects, setFileObjects] = React.useState([]);

  const [msgAlarm, setMsgAlarm] = React.useState(null);
  const [alertMsg, setAlertMsg] = React.useState(false);
  const [alertType, setAlertType] = React.useState(null);

  const [msgUploadAlarm, setMsgUploadAlarm] = React.useState(null);
  const [alertMsgUpload, setAlertMsgUpload] = React.useState(false);
  const [alertTypeUpload, setAlertTypeUpload] = React.useState(null);

  const [photoURL, setPhotoURL] = React.useState(null);

  const EDIT_FILE_UPLOAD_TYPE = curUser.allRightAccess
    ? curUser.allRightAccess.user_add_permissions.some(
        (item) =>
          item.rights_type === generalConstants.ADMIN_PHYSICIAN_RIGHT_TYPE &&
          item.add_permission_type === generalConstants.EDIT_FILE_UPLOAD_TYPE
      )
    : false;

  const uploadFileOnHandle = () => {
    setAlertMsgUpload(false);
    setMsgUploadAlarm(null);
    setAlertTypeUpload(null);
    if (fileObjects.length > 0) {
      const submitJson = {
        object_name: fileObjects[fileObjects.length - 1].s3Name,
      };
      dispatch(postCreatePresignedJsonPhoto.pending(submitJson));
    }
  };

  useEffect(() => {
    prevCreatePresignedUrlStatus.current = false;
    prevCreatePresignedJsonStatus.current = false;
  }, []);

  useEffect(() => {
    if (uploadFileObjects.photo !== null) {
      setAlertMsg(false);
      setMsgAlarm(null);
      setAlertType(null);
      const organisationCodePath = organisationCode + "/" + folderPath + "/";

      const extensionIndex =
        uploadFileObjects.photo.lastIndexOf(organisationCodePath);
      const objectName = uploadFileObjects.photo.substring(extensionIndex);

      const submitJson = {
        object_name: objectName,
      };

      dispatch(postCreatePresignedUrlPhoto.pending(submitJson));
    }
  }, [uploadFileObjects, folderPath, organisationCode, dispatch]);

  useEffect(() => {
    if (
      cloudStorage.isLoadingPostCreatePresignedUrlPhoto !==
      prevCreatePresignedUrlStatus.current
    ) {
      //check the previous ref with current state
      prevCreatePresignedUrlStatus.current =
        cloudStorage.isLoadingPostCreatePresignedUrlPhoto;

      if (
        cloudStorage.postCreatePresignedUrlPhotoIsSuccess === true &&
        cloudStorage.isLoadingPostCreatePresignedUrlPhoto === false
      ) {
        const organisationCodePath = organisationCode + "/" + folderPath + "/";

        const extensionIndex =
          cloudStorage.postCreatePresignedUrlPhoto.lastIndexOf(
            organisationCodePath
          );

        if (extensionIndex !== -1) {
          fetch(cloudStorage.postCreatePresignedUrlPhoto)
            .then((res) => {
              if (res.status === 200) {
                return res.blob();
              } else {
                throw new Error(res.statusText);
              }
            })
            .then((blob) => {
              var url = window.URL.createObjectURL(blob);
              setPhotoURL(url);
            })
            .catch((err) => {
              setMsgAlarm(err.message);
              setAlertMsg(true);
              setAlertType("error");
            });
        }
      }
      if (
        cloudStorage.postCreatePresignedUrlPhotoIsSuccess === false &&
        cloudStorage.isLoadingPostCreatePresignedUrlPhoto === false
      ) {
        setMsgAlarm(cloudStorage.error.postCreatePresignedUrlPhotoError);
        setAlertMsg(true);
        setAlertType("error");
      }
    }
  }, [cloudStorage, folderPath, organisationCode, t]);

  useEffect(() => {
    if (
      cloudStorage.isLoadingPostCreatePresignedJsonPhoto !==
      prevCreatePresignedJsonStatus.current
    ) {
      //check the previous ref with current state
      prevCreatePresignedJsonStatus.current =
        cloudStorage.isLoadingPostCreatePresignedJsonPhoto;

      if (
        cloudStorage.postCreatePresignedJsonPhotoIsSuccess === true &&
        cloudStorage.isLoadingPostCreatePresignedJsonPhoto === false
      ) {
        const organisationCodePath = organisationCode + "/" + folderPath + "/";

        const extensionIndex =
          cloudStorage.postCreatePresignedJsonPhoto.fields.key.lastIndexOf(
            organisationCodePath
          );

        if (extensionIndex !== -1) {
          let cloudStrogeKeyWithFile = fileObjects.find(
            (keyItem) =>
              keyItem.s3Name ===
              cloudStorage.postCreatePresignedJsonPhoto.fields.key
          );
          const cloudStrogePresignedJson = {
            ...cloudStorage.postCreatePresignedJsonPhoto.fields,
            postUrl: cloudStorage.postCreatePresignedJsonPhoto.url,
          };
          cloudStrogeKeyWithFile = {
            ...cloudStrogeKeyWithFile,
            ...cloudStrogePresignedJson,
          };

          (async () => {
            const response = await externetApis.postS3FileApi(
              cloudStrogeKeyWithFile
            );
            if (response.status === 204) {
              setMsgUploadAlarm(t("translation:File has been uploaded"));
              setAlertMsgUpload(true);
              setAlertTypeUpload("success");

              const fileObject = {
                photo_original_filename: cloudStrogeKeyWithFile.file.name,
                file: { name: cloudStrogeKeyWithFile.file.name },
                photo:
                  cloudStrogeKeyWithFile.postUrl + cloudStrogeKeyWithFile.key,
              };
              setFileObjects([fileObject]);
              setUploadFileObjects(fileObject);
            } else {
              setMsgUploadAlarm(t("translation:File failed to upload"));
              setAlertMsgUpload(true);
              setAlertTypeUpload("error");
            }
          })();
        }
      }
      if (
        cloudStorage.postCreatePresignedJsonPhotoIsSuccess === false &&
        cloudStorage.isLoadingPostCreatePresignedJsonPhoto === false
      ) {
        setMsgUploadAlarm(cloudStorage.error.postCreatePresignedJsonPhotoError);
        setAlertMsgUpload(true);
        setAlertTypeUpload("error");
      }
    }
  }, [
    cloudStorage,
    fileObjects,
    dispatch,
    setUploadFileObjects,
    t,
    folderPath,
    organisationCode,
  ]);
  return (
    <>
      <Grid item xs={12} container justifyContent="center">
        {alertType !== null && alertMsg !== null && (
          <Collapse in={alertMsg}>
            <Alert
              severity={alertType}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertMsg(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlarm}
            </Alert>
          </Collapse>
        )}
        {alertMsg !== true &&
          uploadFileObjects.photo !== null &&
          photoURL === null && <>{t("translation:Loading")}...</>}
        {photoURL !== null && (
          <img src={photoURL} height="200px" weight="400px" alt="physician" />
        )}
      </Grid>
      {EDIT_FILE_UPLOAD_TYPE && (
        <Grid item xs={12} container justifyContent="center">
          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              name="downloadButton"
              onClick={() => setOpen(true)}>
              {t("translation:UPLOAD PHOTO")}
            </Button>
          </Link>
        </Grid>
      )}
      <DropZoneDialog
        dialogTitle={<span>{t("translation:Upload")}</span>}
        fileObjects={fileObjects}
        fullWidth
        cancelButtonText={t("translation:BACK")}
        submitButtonText={t("translation:UPLOAD")}
        acceptedFiles={[".png, .jpg, .jpeg"]}
        supportedFileType={".png, .jpg, .jpeg"}
        maxFileSize={5000000}
        filesLimit={1}
        dropzoneText={t("translation:Drag and drop a file here or click")}
        previewText={""}
        maxWidth="lg"
        open={open}
        showAlerts={false}
        useChipsForPreview={true}
        onAdd={(newFileObjs) => {
          setAlertMsgUpload(false);
          setMsgUploadAlarm("");
          let newAddFileObjs = newFileObjs.map((item) => {
            const extensionIndex = item.file.type.lastIndexOf("/");
            const extensionformat = item.file.type.substring(
              extensionIndex + 1
            );
            const s3Name = uuidv4();
            const newObj = {
              ...item,
              s3Name:
                organisationCode +
                "/" +
                folderPath +
                "/" +
                s3Name +
                "." +
                extensionformat,
            };
            return newObj;
          });

          setFileObjects([...newAddFileObjs]);
        }}
        onDelete={(deleteFileObj) => {
          setFileObjects([]);
        }}
        onClose={() => setOpen(false)}
        onSave={() => {
          uploadFileOnHandle();
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
        msgAlarm={msgUploadAlarm}
        alertMsg={alertMsgUpload}
        setAlertMsg={setAlertMsgUpload}
        alertType={alertTypeUpload}
        getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
          setMsgUploadAlarm(
            rejectedFile.name + " " + t("translation:is rejected")
          );
          setAlertMsgUpload(true);
          setAlertTypeUpload("warning");
        }}
        getFileLimitExceedMessage={(filesLimit) => {
          setMsgUploadAlarm(
            t("translation:more than") +
              " " +
              filesLimit +
              " " +
              t("translation:file(s)")
          );
          setAlertMsgUpload(true);
          setAlertTypeUpload("warning");
        }}
      />
    </>
  );
}
