import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getTreatmentTypeList,
  getTreatmentTypeById,
  postCreateTreatmentType,
  putUpdateTreatmentType,
} from "../actions/treatmentTypeActions";
import * as types from "../_constants/treatmentTypeConstants";
import * as notification from "../_constants/notificationConstants";
import treatmentTypeApis from "../_apis/treatmentTypeApis";
export default function* treatmentTypeSagas() {
  yield all([
    takeLatest(
      types.GET_TREATMENTTYPELIST_ASYNC.PENDING,
      getTreatmentTypeListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_TREATMENTTYPEBYID_ASYNC.PENDING,
      getTreatmentTypeByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATETREATMENTTYPE_ASYNC.PENDING,
      postCreateTreatmentTypeCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATETREATMENTTYPE_ASYNC.PENDING,
      putUpdateTreatmentTypeCall
    ),
  ]);
}

function* getTreatmentTypeListCall(searchModel) {
  try {
    const data = yield call(
      treatmentTypeApis.getTreatmentTypeListApi,
      searchModel.data
    );
    yield put(getTreatmentTypeList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getTreatmentTypeList.error(error.response?.data?.message));
    else yield put(getTreatmentTypeList.error(notification.CONST_NOINTERNET));
  }
}

function* getTreatmentTypeByIdCall(searchModel) {
  try {
    const data = yield call(
      treatmentTypeApis.getTreatmentTypeByIdApi,
      searchModel.data
    );
    yield put(getTreatmentTypeById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getTreatmentTypeById.error(error.response?.data?.message));
    else yield put(getTreatmentTypeById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateTreatmentTypeCall(searchModel) {
  try {
    const data = yield call(
      treatmentTypeApis.postCreateTreatmentTypeApi,
      searchModel.data
    );
    yield put(postCreateTreatmentType.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateTreatmentType.error(error.response?.data?.message));
    else
      yield put(postCreateTreatmentType.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateTreatmentTypeCall(searchModel) {
  try {
    const data = yield call(
      treatmentTypeApis.putUpdateTreatmentTypeApi,
      searchModel.data
    );
    yield put(putUpdateTreatmentType.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateTreatmentType.error(error.response?.data?.message));
    else yield put(putUpdateTreatmentType.error(notification.CONST_NOINTERNET));
  }
}
