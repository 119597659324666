import * as types from "../_constants/organisationPhysicianConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_ORGANISATIONPHYSICIANBYID_ASYNC.PENDING:
      return {
        ...state,
        getOrganisationPhysicianByIdIsSuccess: false,
        isLoadingGetOrganisationPhysicianById: true,
        getOrganisationPhysicianById: null,
      };

    case types.GET_ORGANISATIONPHYSICIANBYID_ASYNC.ERROR:
      return {
        ...state,
        getOrganisationPhysicianByIdIsSuccess: false,
        isLoadingGetOrganisationPhysicianById: false,
        getOrganisationPhysicianById: null,
        error: {
          ...state.error,
          getOrganisationPhysicianByIdError: action.error,
        },
      };

    case types.GET_ORGANISATIONPHYSICIANBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getOrganisationPhysicianByIdIsSuccess: true,
        isLoadingGetOrganisationPhysicianById: false,
        getOrganisationPhysicianById: action.data,
      };

    case types.GET_ORGANISATIONPHYSICIANLIST_ASYNC.PENDING:
      return {
        ...state,
        getOrganisationPhysicianListIsSuccess: false,
        isLoadingGetOrganisationPhysicianList: true,
        getOrganisationPhysicianList: null,
      };

    case types.GET_ORGANISATIONPHYSICIANLIST_ASYNC.ERROR:
      return {
        ...state,
        getOrganisationPhysicianListIsSuccess: false,
        isLoadingGetOrganisationPhysicianList: false,
        getOrganisationPhysicianList: null,
        error: {
          ...state.error,
          getOrganisationPhysicianListError: action.error,
        },
      };

    case types.GET_ORGANISATIONPHYSICIANLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getOrganisationPhysicianListIsSuccess: true,
        isLoadingGetOrganisationPhysicianList: false,
        getOrganisationPhysicianList: action.data,
      };

    case types.PUT_UPDATEORGANISATIONPHYSICIAN_ASYNC.PENDING:
      return {
        ...state,
        putUpdateOrganisationPhysicianIsSuccess: false,
        isLoadingPutUpdateOrganisationPhysician: true,
        putUpdateOrganisationPhysician: null,
      };

    case types.PUT_UPDATEORGANISATIONPHYSICIAN_ASYNC.ERROR:
      return {
        ...state,
        putUpdateOrganisationPhysicianIsSuccess: false,
        isLoadingPutUpdateOrganisationPhysician: false,
        putUpdateOrganisationPhysician: null,
        error: {
          ...state.error,
          putUpdateOrganisationPhysicianError: action.error,
        },
      };

    case types.PUT_UPDATEORGANISATIONPHYSICIAN_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateOrganisationPhysicianIsSuccess: true,
        isLoadingPutUpdateOrganisationPhysician: false,
        putUpdateOrganisationPhysician: action.data,
      };

    case types.GET_ORGANISATIONPHYSICIANUSERLIST_ASYNC.PENDING:
      return {
        ...state,
        getOrganisationPhysicianUserListIsSuccess: false,
        isLoadingGetOrganisationPhysicianUserList: true,
        getOrganisationPhysicianUserList: null,
      };

    case types.GET_ORGANISATIONPHYSICIANUSERLIST_ASYNC.ERROR:
      return {
        ...state,
        getOrganisationPhysicianUserListIsSuccess: false,
        isLoadingGetOrganisationPhysicianUserList: false,
        getOrganisationPhysicianUserList: null,
        error: {
          ...state.error,
          getOrganisationPhysicianUserListError: action.error,
        },
      };

    case types.GET_ORGANISATIONPHYSICIANUSERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getOrganisationPhysicianUserListIsSuccess: true,
        isLoadingGetOrganisationPhysicianUserList: false,
        getOrganisationPhysicianUserList: action.data,
      };

    default:
      return state;
  }
};
