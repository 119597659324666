//lib
import React, { forwardRef } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
//components
import Dropdown from "./Dropdown";
import * as generalConstants from "../../_constants/generalConstants";
import { useStyles } from "../../components/globalMenuStyles";

const MenuItems = forwardRef((props, ref) => {
  const {
    items,
    index,
    level,
    accessRight,
    anchorEl,
    setanchorEl,
    menu,
    setMenu,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = (event, item, level, index) => {
    let newData = { [item]: { level: level, index: index } };
    setMenu(newData);
    setanchorEl({ ...anchorEl, [level]: event.currentTarget });
  };

  const handleCloseMenu = () => {
    setMenu({});
    setanchorEl(null);
  };

  const CustomRouterLink = forwardRef((children, ref) => (
    <span
      ref={ref}
      style={{ flexGrow: 1 }}
      onClick={() => {
        handleCloseMenu();
      }}>
      <RouterLink {...children} />
    </span>
  ));
  //no need right and permission, all user can see
  if (items.right_type === generalConstants.NO_RIGHT_TYPE) {
    if (items.max_permission_type === generalConstants.NO_PERMISSION_TYPE) {
      if (!items.children) {
        return (
          <List
            ref={ref}
            disablePadding
            key={"noChilList" + index + "level" + level}>
            <ListItem
              className={classes.item}
              disableGutters
              key={"noChilListItem" + index + "level" + level}
              title={t(`translation:${items.title}`)}>
              <Button
                key={"noChilBtn" + index + "level" + level}
                id={items.name}
                className={clsx({
                  [classes.btnRoot]: true,
                  [classes.subMenu]: level,
                })}
                component={CustomRouterLink}
                to={items.url}>
                {items.icon && (
                  <ListItemIcon
                    key={"noChilListItemIcon" + index + "level" + level}
                    className={classes.geryColor}>
                    {items.icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  key={"noChilListItemText" + index + "level" + level}
                  classes={{ primary: classes.listItemText }}>
                  {t(`translation:${items.name}`)}
                  {/* {name} */}
                </ListItemText>
              </Button>
            </ListItem>
          </List>
        );
      }
    }

    return <span key={"noShow1" + index + "level" + level}></span>;
  }
  //have right list from API(accessRight)
  if (accessRight) {
    const rightRoleAccess = accessRight.user_rights;
    //find the same right type
    const rightRole = rightRoleAccess.find(
      (item) => item.rights_type === items.right_type
    );
    //same right type and permission must equal or greater
    if (
      rightRole &&
      rightRole.max_permission_type >= items.max_permission_type
    ) {
      if (!items.children) {
        return (
          <List
            ref={ref}
            disablePadding
            key={"rightNoChilList" + index + "level" + level}>
            <ListItem
              className={classes.item}
              disableGutters
              key={"rightNoChilListItem" + index + "level" + level}
              title={t(`translation:${items.title}`)}>
              <Button
                id={items.name}
                key={"rightNoChilBtn" + index + "level" + level}
                className={clsx({
                  [classes.btnRoot]: true,
                  [classes.subMenu]: level,
                })}
                component={CustomRouterLink}
                to={items.url}>
                {items.icon && (
                  <ListItemIcon
                    key={"rightNoChilListItemIcon" + index + "level" + level}
                    className={classes.geryColor}>
                    {items.icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  key={"rightNoChilListItemText" + index + "level" + level}
                  classes={{ primary: classes.listItemText }}>
                  {t(`translation:${items.name}`)}
                  {/* {name} */}
                </ListItemText>
              </Button>
            </ListItem>
          </List>
        );
      }
    }
    //main and no need permission and right type
    if (items.children) {
      let showChildren = false;

      if (items.right_type) {
        let showChildrenItem = rightRoleAccess.some(
          //loop children to get some same right type from API list
          (item) => item.rights_type === items.right_type
        );
        showChildren = showChildrenItem;
      } else {
        items.children.map(({ right_type }) => {
          let showChildrenItem = rightRoleAccess.some(
            //loop children to get some same right type from API list
            (item) => item.rights_type === right_type
          );
          if (showChildrenItem === true) {
            showChildren = true;
          }
          return showChildren;
        });
      }

      if (showChildren) {
        return (
          <List ref={ref} key={"chilList" + index + "level" + level}>
            <ListItem
              className={classes.item}
              disableGutters
              key={"chilListItem" + index + "level" + level}
              title={t(`translation:${items.title}`)}
              onClick={(event) =>
                handleClick(event, items.dropDownName, level, index)
              }>
              <Button
                key={"chilBtn" + index + "level" + level}
                className={clsx({
                  [classes.btnRoot]: true,
                  [classes.subMenu]: level,
                })}>
                {items.icon && (
                  <ListItemIcon
                    key={"chilListItemIcon" + index + "level" + level}
                    className={classes.geryColor}>
                    {items.icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  key={"chilListItemText" + index + "level" + level}
                  classes={{ primary: classes.listItemText }}>
                  {t(`translation:${items.name}`)}
                  {/* {name} */}
                </ListItemText>
                {menu[items.dropDownName] &&
                menu[items.dropDownName].level >= level ? (
                  menu[items.dropDownName].level > level ? (
                    <ExpandLess
                      key={"chilExpandLess" + index + "level" + level}
                      className={classes.geryColor}
                    />
                  ) : menu[items.dropDownName].index === index ? (
                    <ExpandLess
                      key={"chilExpandLess" + index + "level" + level}
                      className={classes.geryColor}
                    />
                  ) : (
                    <ExpandMore
                      key={"chilExpandMore" + index + "level" + level}
                      className={classes.geryColor}
                    />
                  )
                ) : (
                  <ExpandMore
                    key={"chilExpandMore" + index + "level" + level}
                    className={classes.geryColor}
                  />
                )}
              </Button>
            </ListItem>

            {/* menu for children*/}
            <Menu
              key={"chil" + index + "chilMenu level" + level}
              classes={{ paper: classes.subListItemText }}
              id="chilMenu"
              open={
                menu[items.dropDownName] &&
                menu[items.dropDownName].level >= level
                  ? menu[items.dropDownName].level > level
                    ? true
                    : menu[items.dropDownName].index === index
                    ? true
                    : false
                  : false
              }
              anchorEl={anchorEl ? anchorEl[level] : null}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              targetorigin={{ horizontal: "right", vertical: "top" }}
              onClose={(event) => handleCloseMenu()}>
              <Dropdown
                level={level}
                children={items.children}
                accessRight={accessRight}
                anchorEl={anchorEl}
                setanchorEl={setanchorEl}
                menu={menu}
                setMenu={setMenu}
              />
            </Menu>
          </List>
        );
      }
    }
    return <span key={"noShow1" + index + "level" + level}></span>;
  }
  return <span key={"noShow2" + index + "level" + level}></span>;
});

export default MenuItems;
