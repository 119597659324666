import { createAsyncTypes } from "../_helper/Utilities";

export const GET_USERMEDICINEFIXEDSELLINGPRICELIST_ASYNC = createAsyncTypes(
  "GET_USERMEDICINEFIXEDSELLINGPRICELIST"
);

export const GET_USERMEDICINEFIXEDSELLINGPRICESUMMARYLIST_ASYNC =
  createAsyncTypes("GET_USERMEDICINEFIXEDSELLINGPRICESUMMARYLIST");

export const GET_USERMEDICINEFIXEDSELLINGPRICEBYID_ASYNC = createAsyncTypes(
  "GET_USERMEDICINEFIXEDSELLINGPRICEBYID"
);

export const POST_CREATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC = createAsyncTypes(
  "POST_CREATEUSERMEDICINEFIXEDSELLINGPRICE"
);

export const PUT_UPDATEUSERMEDICINEFIXEDSELLINGPRICE_ASYNC = createAsyncTypes(
  "PUT_UPDATEUSERMEDICINEFIXEDSELLINGPRICE"
);

export const GET_USERMEDICINEFIXEDSELLINGPRICEMEDICINEFIXEDSELLINGPRICELIST_ASYNC =
  createAsyncTypes(
    "GET_USERMEDICINEFIXEDSELLINGPRICEMEDICINEFIXEDSELLINGPRICELIST"
  );

export const GET_USERMEDICINEFIXEDSELLINGPRICEUSERLIST_ASYNC = createAsyncTypes(
  "GET_USERMEDICINEFIXEDSELLINGPRICEUSERLIST"
);
