import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  root: {},
  defualtCursor: { cursor: "default" },
  gridRoot: {
    minWidth: "600px",
    width: "100%",
    margin: "0 auto",
  },
  gridPadding: {
    padding: "10px",
  },
  title: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,1)",
  },
  redCircle: {
    color: "rgba(149, 0, 0)",
    borderRadius: "50%",
  },
  greenCircle: {
    color: "rgba(4,106,56)",
    borderRadius: "50%",
  },

  yellowCircle: {
    borderRadius: "50%",
    color: "rgba(254,221,0)",
  },
  buttonColor: {
    fontWeight: "bold",
    backgroundColor: "rgba(149, 0, 0,1)",
    color: "rgba(237, 242, 242)",
  },
  closeDialogButton: {
    position: "absolute !important",
    right: "4px",
    top: "8px",
    color: "lightgray !important",
  },
  autoCompletePopper: {
    zIndex: 2,
    "& .MuiAutocomplete-listbox": {
      "& :hover": {
        backgroundColor: "rgba(237, 242, 242,0.5)",
      },
      "& li": {
        //list item specific styling

        backgroundColor: "rgba(255,255,255)",
      },
    },
  },

  autoCompletePopperGroup: {
    "& .MuiAutocomplete-listbox": {
      "& li": {
        backgroundColor: "rgba(255,255,255)",
      },

      "& ul": {
        //list item specific styling
        "& :hover": {
          backgroundColor: "rgba(237, 242, 242,0.5)",
        },
      },
    },
  },
  userFieldColor: {
    backgroundColor: "rgba(255,255,255)",
  },
  userInfoField: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "10px",
    gap: "10px",

    paddingLeft: "10px",
    paddingRight: "10px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  fullWidthField: {
    width: "100%",
  },
  userInfoFieldWidth70: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "10px",
    paddingTop: "10px",
    paddingRight: "10px",
    "& .MuiFormControl-root": {
      width: "70%",
    },
  },
  subTotalInfoField: {
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  maroon075: {
    color: " rgba(149, 0, 0,0.75)",
  },
  boldMaroon075: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,0.75)",
  },
  bold075: {
    fontWeight: "bold",
  },
  maroon100: {
    color: " rgba(149, 0, 0,1)",
  },
  maroonBackground100: {
    backgroundColor: " rgba(149, 0, 0,1)",
  },
  boldCenterMaroon100: {
    fontWeight: "bold",
    textAlign: "center",
    display: "block",
    color: "rgba(149, 0, 0,1)",
  },
  requiredBoldMaroon075: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,0.75)",
  },
  iconColor: {
    color: " rgba(77, 77, 77,1)",
  },
  warningColor: {
    margin: theme.spacing(3, 0, 2),
    color: "#bf1650",
    "&:before": {
      display: "inline",
      content: "⚠",
    },
  },
  paper: {
    minWidth: 1000,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  paper2: {
    minWidth: 1000,
    padding: theme.spacing(5),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  summaryPaper: {
    padding: theme.spacing(5),
    margin: "20px 0 30px 0",
  },

  appointmentSummaryContainer: {
    paddingTop: "8px",
    paddingLeft: "20px",
  },
  rolePaper: {
    width: 200,
    height: 400,
    overflow: "auto",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  panelCard: {
    marginBottom: "30px",
    backgroundColor: "rgba(237, 242, 242)",
  },
  panelHeading: {
    backgroundColor: "rgba(249, 249, 249)",
    color: "rgba(149, 0, 0,1)",

    textTransform: "uppercase",
  },
  p: {
    color: "#bf1650",
    "&:before": {
      display: "inline",
      content: "⚠",
    },
  },
  rowBackground: {
    backgroundColor: "rgba(229, 194, 195,1)",
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center",
  },
  tabRoot: {
    display: "flex",
  },
  tabPanel: {
    width: "100%",
  },
  notificationRoot: {
    width: "90%",
    margin: "0 auto",
    textAlign: "center",
  },

  notificationCenterView: {
    minWidth: 1000,
    marginBottom: "20px",
    textAlign: "center",
    alignItems: "center",
  },
  notificationTitle: {
    fontWeight: "bold",
  },
  notificationTitleContainer: {
    margin: "20px 0 30px 0",
  },
  autoCompletePopper2: {
    width: "100%",
    minWidth: "600px",
    "& .fc-button": {
      background: "rgba(149, 0, 0,1)",
      backgroundImage: "none",
      outline: "none",
      boxShadow: "none !important",
      border: "0px solid transparent",
    },
    "& .fc-button:hover": {
      background: "#f50057",
    },
    "& .fc-button:disabled": {
      background: "#f50057",
    },
    "& .fc-button.fc-button-primary.fc-button-active": {
      backgroundColor: "rgba(149, 0, 0,0.5)",
      backgroundImage: "none",
      outline: "none",
      boxShadow: "none !important",
      border: "0px solid transparent",
    },
  },
  buttonGroup: {
    marginBottom: "25px",
    justifyContent: "center",

    backgroundImage: "none",
    outline: "none",
    boxShadow: "none !important",
    border: "0px solid transparent",

    "& button:hover": {
      color: "#fff",
      backgroundColor: "#f50057",
    },
  },

  selectedButtonGroup: {
    color: "#fff !important",
    backgroundColor: "rgba(149, 0, 0,0.5) !important",

    backgroundImage: "none",
    outline: "none",
    boxShadow: "none !important",
    border: "0px solid transparent",
  },

  selectedTime: {
    backgroundColor: "rgba(149, 0, 0,0.5) !important",

    backgroundImage: "none",
    outline: "none",
    boxShadow: "none !important",
    border: "0px solid transparent",
  },
  timeSlotDialogDetail: {
    textAlign: "center",
    padding: 8,
  },
  grey: {
    fontWeight: "bold",
    color: "rgba(77, 77, 77)",
  },
  relativePositionWrapper: {
    position: "relative",
    paddingBottom: "30px",
    right: "5px",
  },
  rightAbsolutePosition: {
    position: "absolute",
    right: "5px",
  },
}));
