import * as types from "../_constants/nonBusinessDayConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_NONBUSINESSDAYLIST_ASYNC.PENDING:
      return {
        ...state,
        getNonBusinessDayListIsSuccess: false,
        isLoadingGetNonBusinessDayList: true,
        getNonBusinessDayList: null,
      };

    case types.GET_NONBUSINESSDAYLIST_ASYNC.ERROR:
      return {
        ...state,
        getNonBusinessDayListIsSuccess: false,
        isLoadingGetNonBusinessDayList: false,
        getNonBusinessDayList: null,
        error: {
          ...state.error,
          getNonBusinessDayListError: action.error,
        },
      };

    case types.GET_NONBUSINESSDAYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getNonBusinessDayListIsSuccess: true,
        isLoadingGetNonBusinessDayList: false,
        getNonBusinessDayList: action.data,
      };

    case types.GET_NONBUSINESSDAYBYID_ASYNC.PENDING:
      return {
        ...state,
        getNonBusinessDayByIdIsSuccess: false,
        isLoadingGetNonBusinessDayById: true,
        getNonBusinessDayById: null,
      };

    case types.GET_NONBUSINESSDAYBYID_ASYNC.ERROR:
      return {
        ...state,
        getNonBusinessDayByIdIsSuccess: false,
        isLoadingGetNonBusinessDayById: false,
        getNonBusinessDayById: null,
        error: {
          ...state.error,
          getNonBusinessDayByIdError: action.error,
        },
      };

    case types.GET_NONBUSINESSDAYBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getNonBusinessDayByIdIsSuccess: true,
        isLoadingGetNonBusinessDayById: false,
        getNonBusinessDayById: action.data,
      };

    case types.POST_CREATENONBUSINESSDAY_ASYNC.PENDING:
      return {
        ...state,
        postCreateNonBusinessDayIsSuccess: false,
        isLoadingPostCreateNonBusinessDay: true,
        postCreateNonBusinessDay: null,
      };

    case types.POST_CREATENONBUSINESSDAY_ASYNC.ERROR:
      return {
        ...state,
        postCreateNonBusinessDayIsSuccess: false,
        isLoadingPostCreateNonBusinessDay: false,
        postCreateNonBusinessDay: null,
        error: {
          ...state.error,
          postCreateNonBusinessDayError: action.error,
        },
      };

    case types.POST_CREATENONBUSINESSDAY_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateNonBusinessDayIsSuccess: true,
        isLoadingPostCreateNonBusinessDay: false,
        postCreateNonBusinessDay: action.data,
      };

    case types.PUT_UPDATENONBUSINESSDAY_ASYNC.PENDING:
      return {
        ...state,
        putUpdateNonBusinessDayIsSuccess: false,
        isLoadingPutUpdateNonBusinessDay: true,
        putUpdateNonBusinessDay: null,
      };

    case types.PUT_UPDATENONBUSINESSDAY_ASYNC.ERROR:
      return {
        ...state,
        putUpdateNonBusinessDayIsSuccess: false,
        isLoadingPutUpdateNonBusinessDay: false,
        putUpdateNonBusinessDay: null,
        error: {
          ...state.error,
          putUpdateNonBusinessDayError: action.error,
        },
      };

    case types.PUT_UPDATENONBUSINESSDAY_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateNonBusinessDayIsSuccess: true,
        isLoadingPutUpdateNonBusinessDay: false,
        putUpdateNonBusinessDay: action.data,
      };

    case types.POST_SEARCHNONBUSINESSDAYBRANCH_ASYNC.PENDING:
      return {
        ...state,
        postSearchNonBusinessDayBranchIsSuccess: false,
        isLoadingPostSearchNonBusinessDayBranch: true,
        postSearchNonBusinessDayBranch: null,
      };

    case types.POST_SEARCHNONBUSINESSDAYBRANCH_ASYNC.ERROR:
      return {
        ...state,
        postSearchNonBusinessDayBranchIsSuccess: false,
        isLoadingPostSearchNonBusinessDayBranch: false,
        postSearchNonBusinessDayBranch: null,
        error: {
          ...state.error,
          postSearchNonBusinessDayBranchError: action.error,
        },
      };

    case types.POST_SEARCHNONBUSINESSDAYBRANCH_ASYNC.SUCCESS:
      return {
        ...state,
        postSearchNonBusinessDayBranchIsSuccess: true,
        isLoadingPostSearchNonBusinessDayBranch: false,
        postSearchNonBusinessDayBranch: action.data,
      };

    default:
      return state;
  }
};
