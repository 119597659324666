import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import OrderFormSummaryPage from "./OrderFormSummary/OrderFormSummaryPage";
export default function OrderFormPage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/orderForm"
        to="/orderForm/OrderFormSummary"
      />
      <Route
        path="/orderForm/OrderFormSummary"
        component={OrderFormSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
