//lib
import React, { useState } from "react";
import { useTable, useRowSelect } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

//Actions
import { getWikiDataSubNodeList } from "../../../../../actions/wikiDataActions";

//Component

import { ReactComponent as EditLogo } from "../../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../../assets/coolicons/file/file_blank_outline.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/coolicons/basic/trash_empty.svg";
import { useStyles } from "../../../../../components/globalStyles";
import { useStyles as tableStyles } from "../../../../../components/globalTableStyles";
import * as pageConfigure from "../../../../../_constants/pageConstants";
import * as generalConstants from "../../../../../_constants/generalConstants";
import EditableCell from "../../../../../components/EditableCell";
import AddSubNodeList from "./AddSubNodeList";
import Panel from "../../../../../components/Panel";
import ConfirmDialog from "../../../../../components/ConfirmDialog";

function Table({ columns, data, updateMyData, disablePageResetOnDataChange }) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        updateMyData,
        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = tableStyles();
  const { t } = useTranslation();
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    <TableCell className={paginationClasses.narrowCellStyle}>
                      {i + 1}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={paginationClasses.narrowCellStyle}
                          {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: cell.column.editable,
                            editableNum: cell.column.editableNum,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
}

export default function SubNodeTable({
  data = [],
  setData,
  setPathList,
  temViewOnly,
}) {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.ADMIN_WIKI_DATA_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Edit",
      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            {!temViewOnly && (
              <Button onClick={() => handleRowEdit(row.original)}>
                <EditLogo title={t("translation:Edit")} />
              </Button>
            )}
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: "",
      accessor: "Delete",
      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          {!temViewOnly && (
            <Button onClick={() => handleRowDel(row.original.id, "delete")}>
              <DeleteIcon title={t("translation:Delete")} />
            </Button>
          )}
        </>
      ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const viewColumns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          {temViewOnly && (
            <Button onClick={() => handleRowDetail(row.original)}>
              <DetailLogo title={t("translation:Details")} />
            </Button>
          )}
        </>
      ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo

  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  //Init
  const [addOpen, setAddOpen] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);

  const [JsonText, setJsonText] = useState(null);
  //Onclick event

  const handleRowEdit = (data) => {
    setJsonText(data);
    setConfirmEdit(true);
  };

  const onConfirmEdit = (value) => {
    setPathList((item) => [...item, { id: value.id, name: value.name }]);
    dispatch(
      getWikiDataSubNodeList.pending({
        order_by_direction: "Asc",
        order_by_field: "name",
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        is_pagination: true,
        search_desc_value: null,
        search_id_value: value.id,
        search_name_value: "",
        search_root_node: false,
        search_status_type_value: value.status_type,
        search_subnode_value: null,
        view_format_value: pageConfigure.WIKI_LEVEL_VIEW,
      })
    );
    // setEditWikiDataView(true);
  };

  const handleRowDetail = (value) => {
    setPathList((item) => [...item, { id: value.id, name: value.name }]);
    dispatch(
      getWikiDataSubNodeList.pending({
        order_by_direction: "Asc",
        order_by_field: "name",
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        is_pagination: false,
        search_desc_value: null,
        search_id_value: value.id,
        search_name_value: "",
        search_root_node: false,
        search_status_type_value: value.status_type,
        search_subnode_value: null,
        view_format_value: pageConfigure.WIKI_LEVEL_VIEW,
      })
    );
  };

  const handleRowDel = (rowIndex, action) => {
    setData(rowIndex, action);
  };

  //useEffects

  if ((!temViewOnly && data.length > 0) || (temViewOnly && data.length > 0)) {
    return (
      <Panel>
        {addOpen && (
          <AddSubNodeList
            setAddOpen={setAddOpen}
            addOpen={addOpen}
            setData={setData}
          />
        )}

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          textValue={JsonText}
          open={confirmEdit}
          setOpen={setConfirmEdit}
          onConfirm={onConfirmEdit}>
          {t(
            "translation:Please make sure you have submitted your changes before go to another node."
          )}
        </ConfirmDialog>

        <Grid className={classes.root} container>
          <Grid item xs={11}></Grid>
        </Grid>

        <Grid className={classes.root} container>
          {data.length > 0 && (
            <Grid item className={classes.userInfoField} xs={12}>
              <Table
                columns={temViewOnly ? viewColumns : columns}
                data={data}
                updateMyData={setData}
              />
            </Grid>
          )}
        </Grid>
      </Panel>
    );
  }
  return <></>;
}
