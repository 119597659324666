//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
//component

import ExportPurchaseOrderDetails from "./components/ExportPurchaseOrderDetails";

import ExportPurchaseOrderSummary from "./components/ExportPurchaseOrderSummary";
import { useStyles } from "../../../components/globalStyles";

const PurchaseOrderReportPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={11}>
          <Typography className={classes.title} variant="h3" gutterBottom>
            {t("translation:Purchase Receiving Report")}
          </Typography>
        </Grid>
      </Grid>
      <hr />
      <ExportPurchaseOrderDetails />
      <ExportPurchaseOrderSummary />
    </Paper>
  );
};

export default PurchaseOrderReportPage;
