import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    flexShrink: 0,
  },
  actions: {
    fontSize: "1.3rem",
  },
  rowBackground: {
    backgroundColor: "rgba(229, 194, 195,1)",
  },
  rowDetailBackground: {
    backgroundColor: "rgba(237, 242, 242)",
  },
  headStyle: {
    backgroundColor: "rgba(155, 29, 32,1)",
    maxWidth: 500,
    minWidth: 50,
  },
  headCellStyle: {
    maxWidth: 500,
    minWidth: 50,
    fontWeight: "bold",

    color: "rgba(237, 242, 242)",
  },
  userHeadCellStyle: {
    maxWidth: 500,
    minWidth: 50,
    fontWeight: "bold",
    color: "rgba(237, 242, 242)",
    wordBreak: "break-word",
  },
  userBodyCellStyle: {
    maxWidth: 500,
    minWidth: 50,
    wordBreak: "break-word",
  },
  bodyCellStyle: {
    textAlign: "center",
  },
  iconColor: {
    color: " rgba(77, 77, 77,1)",
  },
  blackColor: {
    color: " rgba(0, 0, 0,1)",
  },
  narrowCellStyle: {
    maxWidth: 300,
    minWidth: 60,
    paddingTop: 8,
    paddingBottom: 8,
  },
  narrowButtonStyle: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  userFieldColor: {
    backgroundColor: "rgba(255,255,255)",
  },
  userFieldHeight: {
    height: "1em",
  },
  userInfoField: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "10px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  searchField: {
    paddingTop: "5px",
  },
  insufficientColorCaption: { color: "red", marginRight: "50px" },
  noAvailableColorCaption: { color: "blue", marginRight: "50px" },
  maroon075: {
    color: " rgba(149, 0, 0,0.75)",
  },
  maroon100: {
    color: " rgba(149, 0, 0,1)",
  },
  whiteBoldFont: {
    color: "rgba(255,255,255)",
    fontSize: "16px",
    fontWeight: "bold",
  },
  collapseIconStyle: {
    width: "62px",
  },
  collapseStyle: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  bold: {
    fontWeight: "bold",
  },
}));

export const insufficientColorStyle = { color: "red" };
export const noAvailableColorStyle = { color: "blue" };
export const highlightColorStyle = "red";
