//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

//Actions
import { getWikiDataList } from "../../../../actions/wikiDataActions";

//Component
import { EditWikiDataPage } from "./EditWikiDataPage";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";

export default function WikiDataListTable({ setInformationView }) {
  //variables
  const { t } = useTranslation();

  const prevGetListStatus = useRef();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.ADMIN_WIKI_DATA_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Root Node"),
      accessor: "is_root_node",
      Cell: ({ cell }) => {
        return cell.value ? t("translation:Yes") : t("translation:No");
      },
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    is_pagination: true,
    search_desc_value: null,
    search_id_value: null,
    search_name_value: "",
    search_root_node: true,
    search_status_type_value: null,
    search_subnode_value: null,
    view_format_value: pageConfigure.WIKI_ID_VIEW,
  });

  const dispatch = useDispatch();

  const wikiDataData = useSelector((state) => state.wikiData);

  const common = useSelector((state) => state.common);
  //Init
  const [editWikiDataView, setEditWikiDataView] = React.useState(false);
  const [wikiDataDetailView, setWikiDataDetailView] = React.useState(false);

  const [editWikiDataInfo, setEditWikiDataInfo] = React.useState(null);
  const [wikiDataDetailInfo, setWikiDataDetailInfo] = React.useState(null);

  const [searchValue, setSearchValue] = React.useState("");
  const [remountKey, setRemountKey] = useState(0);
  //Onclick event
  const handleRowEdit = (value) => {
    setRemountKey((prev) => prev + 1);
    dispatch(
      getWikiDataList.pending({
        order_by_direction: "Asc",
        order_by_field: "name",
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        is_pagination: true,
        search_desc_value: null,
        search_id_value: value.id,
        search_name_value: "",
        search_root_node: true,
        search_status_type_value: null,
        search_subnode_value: null,
        view_format_value: pageConfigure.WIKI_LEVEL_VIEW,
      })
    );
    setEditWikiDataView(true);
  };

  const handleRowDetail = (value) => {
    setRemountKey((prev) => prev + 1);
    dispatch(
      getWikiDataList.pending({
        order_by_direction: "Asc",
        order_by_field: "name",
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        is_pagination: true,
        search_desc_value: null,
        search_id_value: value.id,
        search_name_value: "",
        search_root_node: true,
        search_status_type_value: null,
        search_subnode_value: null,
        view_format_value: pageConfigure.WIKI_LEVEL_VIEW,
      })
    );
    setWikiDataDetailView(true);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
      });
    }
  };

  //useEffects

  useEffect(() => {
    prevGetListStatus.current = false;
  }, []);

  useEffect(() => {
    if (wikiDataDetailView === true || editWikiDataView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
      setWikiDataDetailInfo(null);
      setEditWikiDataInfo(null);
    }
  }, [wikiDataDetailView, editWikiDataView, setInformationView]);

  useEffect(() => {
    if (!editWikiDataView && !wikiDataDetailView) {
      dispatch(getWikiDataList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editWikiDataView, wikiDataDetailView]);

  useEffect(() => {
    if (wikiDataData.isLoadingGetWikiDataList !== prevGetListStatus.current) {
      //check the previous ref with current state
      prevGetListStatus.current = wikiDataData.isLoadingGetWikiDataList;

      if (
        wikiDataData.getWikiDataListIsSuccess === true &&
        wikiDataData.isLoadingGetWikiDataList === false
      ) {
        if (wikiDataDetailView) {
          setWikiDataDetailInfo(wikiDataData.getWikiDataList?.wiki_datas[0]);
        }
        if (editWikiDataView) {
          setEditWikiDataInfo(wikiDataData.getWikiDataList?.wiki_datas[0]);
        }
      }
      if (
        wikiDataData.getWikiDataListIsSuccess === false &&
        wikiDataData.isLoadingGetWikiDataList === false
      ) {
      }
    }
  }, [wikiDataData, wikiDataDetailView, editWikiDataView]);

  if (
    wikiDataData.getWikiDataListIsSuccess === false &&
    wikiDataData.isLoadingGetWikiDataList === false
  ) {
    return (
      <Alert severity="error">{wikiDataData.error.getWikiDataListError}</Alert>
    );
  } else if (
    common.generalStatusList &&
    wikiDataData.isLoadingWikiDataList !== true &&
    !editWikiDataView &&
    !wikiDataDetailView
  ) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name")}
        searchValue={searchValue}
        statusPlaceholder={t("translation:Status")}
        statusOption={common.generalStatusList.status_general}
        banStatusIdList={[]}
        columns={columns}
        data={
          wikiDataData.getWikiDataList?.wiki_datas !== undefined
            ? wikiDataData.getWikiDataList?.wiki_datas
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          wikiDataData.getWikiDataList
            ? wikiDataData.getWikiDataList.total_pages
            : 0
        }
        loading={wikiDataData.isLoadingWikiDataList}
        totalItems={
          wikiDataData.getWikiDataList
            ? wikiDataData.getWikiDataList.total_items
            : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (wikiDataDetailView && wikiDataDetailInfo !== null) {
    return (
      <EditWikiDataPage
        key={remountKey}
        wikiData={wikiDataDetailInfo}
        setEditWikiDataView={setWikiDataDetailView}
        viewOnly
      />
    );
  } else if (editWikiDataView && editWikiDataInfo !== null) {
    return (
      <EditWikiDataPage
        key={remountKey}
        wikiData={editWikiDataInfo}
        setEditWikiDataView={setEditWikiDataView}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
