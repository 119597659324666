import { createAsyncTypes } from "../_helper/Utilities";

export const GET_DELIVERYTRACKINGLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYTRACKINGLIST"
);

export const GET_DELIVERYTRACKINGSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYTRACKINGSUMMARYLIST"
);

export const GET_DELIVERYTRACKINGBYID_ASYNC = createAsyncTypes(
  "GET_DELIVERYTRACKINGBYID"
);

export const GET_DELIVERYTRACKINGCOURIERLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYTRACKINGCOURIERLIST"
);

export const GET_DELIVERYTRACKINGCOURIERBYBRANCH_ASYNC = createAsyncTypes(
  "GET_DELIVERYTRACKINGCOURIERBYBRANCH"
);

export const PUT_DELIVERYTRACKINGWIPMODE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYTRACKINGWIPMODE"
);

export const POST_DELIVERYTRACKING_ASYNC = createAsyncTypes(
  "POST_DELIVERYTRACKING"
);

export const PUT_DELIVERYTRACKINGPENDINGSTATUS_ASYNC = createAsyncTypes(
  "PUT_DELIVERYTRACKINGPENDINGSTATUS"
);

export const PUT_DELIVERYTRACKINGCLOSESTATUS_ASYNC = createAsyncTypes(
  "PUT_DELIVERYTRACKINGCLOSESTATUS"
);

export const PUT_DELIVERYTRACKINGPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYTRACKINGPRINTDATE"
);

export const PUT_DELIVERYTRACKINGUPLOADFILE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYTRACKINGUPLOADFILE"
);
