import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  putBrewingWIPMode,
  putBrewingPendingStatus,
  getBrewingList,
  getBrewingSummaryList,
  getBrewingById,
  getBrewingByOrderNum,
  postSubmitBrewing,
} from "../actions/brewingActions";
import * as types from "../_constants/brewingConstants";
import * as notification from "../_constants/notificationConstants";
import brewingApis from "../_apis/brewingApis";
export default function* brewingSagas() {
  yield all([
    takeLatest(types.PUT_BREWINGWIPMODE_ASYNC.PENDING, putBrewingWIPModeCall),
  ]);

  yield all([
    takeLatest(
      types.PUT_BREWINGPENDINGSTATUS_ASYNC.PENDING,
      putBrewingPendingStatusCall
    ),
  ]);
  yield all([
    takeLatest(types.GET_BREWINGLIST_ASYNC.PENDING, getBrewingListCall),
  ]);

  yield all([
    takeLatest(
      types.GET_BREWINGSUMMARYLIST_ASYNC.PENDING,
      getBrewingSummaryListCall
    ),
  ]);
  yield all([
    takeLatest(types.GET_BREWINGBYID_ASYNC.PENDING, getBrewingByIdCall),
  ]);
  yield all([
    takeLatest(
      types.GET_BREWINGBYORDERNUM_ASYNC.PENDING,
      getBrewingByOrderNumCall
    ),
  ]);
  yield all([
    takeLatest(types.POST_SUBMITBREWING_ASYNC.PENDING, postSubmitBrewingCall),
  ]);
}

function* putBrewingWIPModeCall(Model) {
  try {
    const data = yield call(brewingApis.putBrewingWIPModeApi, Model.data);
    yield put(putBrewingWIPMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putBrewingWIPMode.error(error.response?.data?.message));
    else yield put(putBrewingWIPMode.error(notification.CONST_NOINTERNET));
  }
}

function* putBrewingPendingStatusCall(Model) {
  try {
    const data = yield call(brewingApis.putBrewingPendingStatusApi, Model.data);
    yield put(putBrewingPendingStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putBrewingPendingStatus.error(error.response?.data?.message));
    else
      yield put(putBrewingPendingStatus.error(notification.CONST_NOINTERNET));
  }
}

function* getBrewingListCall(Model) {
  try {
    const data = yield call(brewingApis.getBrewingListApi, Model.data);
    yield put(getBrewingList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getBrewingList.error(error.response?.data?.message));
    else yield put(getBrewingList.error(notification.CONST_NOINTERNET));
  }
}

function* getBrewingSummaryListCall(Model) {
  try {
    const data = yield call(brewingApis.getBrewingSummaryListApi, Model.data);
    yield put(getBrewingSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getBrewingSummaryList.error(error.response?.data?.message));
    else yield put(getBrewingSummaryList.error(notification.CONST_NOINTERNET));
  }
}

function* getBrewingByIdCall(Model) {
  try {
    const data = yield call(brewingApis.getBrewingByIdApi, Model.data);
    yield put(getBrewingById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getBrewingById.error(error.response?.data?.message));
    else yield put(getBrewingById.error(notification.CONST_NOINTERNET));
  }
}

function* getBrewingByOrderNumCall(Model) {
  try {
    const data = yield call(brewingApis.getBrewingByOrderNumApi, Model.data);
    yield put(getBrewingByOrderNum.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getBrewingByOrderNum.error(error.response?.data?.message));
    else yield put(getBrewingByOrderNum.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitBrewingCall(Model) {
  try {
    const data = yield call(brewingApis.postSubmitBrewingApi, Model.data);
    yield put(postSubmitBrewing.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitBrewing.error(error.response?.data?.message));
    else yield put(postSubmitBrewing.error(notification.CONST_NOINTERNET));
  }
}
