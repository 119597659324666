import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import PageCustomAction from "../../../../components/PageCustomAction";

import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";

export default function PaymentTotal({
  viewOnly = false,
  amountHeader,
  payment,
  setPayment,
  paidAmount,
  handlePaymentOnConfirm,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const common = useSelector((state) => state.common);

  const handlePaymentMethod = (event, option) => {
    setPayment((p) => ({
      ...p,
      payment_method: option ? option : null,
    }));
  };

  return (
    <form>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        <Grid item xs={4} className={classes.subTotalInfoField}>
          <Autocomplete
            disabled={viewOnly}
            name="Payment_Methods"
            options={common.paymentMethodList.payment_methods}
            value={
              payment.payment_method
                ? payment.payment_method
                : amountHeader.payment_method
            }
            getOptionLabel={(option) => (option.name ? option.name : "")}
            isOptionEqualToValue={(option, value) => {
              if (option.id === value.id) return option;
            }}
            style={{ width: "100%" }}
            onChange={(e, option) =>
              handlePaymentMethod(e, option == null ? null : option)
            }
            renderInput={(params) => (
              <TextField
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Payment Methods")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        <Grid item xs={4} className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={viewOnly}
            required
            name="Payment Amount"
            label={t("translation:Payment Amount")}
            variant="outlined"
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                max: Number(
                  Number(amountHeader.amt_with_gst.toFixed(2)) -
                    Number(paidAmount.toFixed(2))
                ).toFixed(2),
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            value={
              payment.payment_amount && payment.payment_amount !== null
                ? payment.payment_amount
                : ""
            }
            onChange={(e) =>
              setPayment((p) => ({
                ...p,
                payment_amount: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        {/* <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            disabled={viewOnly}
            className={classes.userFieldColor}
            onChange={(e, option) =>
              setPayment((p) => ({
                ...p,
                referral_code: e.target.value,
              }))
            }
            value={
              payment.referral_code && payment.referral_code !== null
                ? payment.referral_code
                : ""
            }
            name="referral_code"
            label={t("translation:Referral Code")}
            variant="outlined"
          />
        </Grid> */}
      </Grid>
      <RequiredNote />
      {!viewOnly && (
        <PageCustomAction
          button1Name="payButton"
          button1Click={handlePaymentOnConfirm}
          button1label={t("translation:PAY")}
        />
      )}
    </form>
  );
}
