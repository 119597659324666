import apiConstants from "../_constants/apiConstants";
import { headerWithoutauth } from "../_helper/apiHeader";
import axios from "axios";
//control refresh token and resign in
import { handleResponse } from "../_helper/handleResponse";

handleResponse(axios);

const authApis = {
  postRegistrationApi,
  postUserLogInApi,
  postUserLogOutApi,
  postUserResetPasswordApi,
  postUserResetPasswordRqApi,
  putUserChangePasswordApi,
  postVerifyEmailApi,
  postVerifyEmailRqApi,
  postSelfRegistrationApi,
  getProviderAuthorizeApi,
  postProviderAccessTokenApi,
  postOtpCallbackApi,
};

async function postRegistrationApi(registrationModel) {
  const requestOptions = JSON.stringify(registrationModel);

  return await axios
    .post(apiConstants.apiRegistration, requestOptions, headerWithoutauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSelfRegistrationApi(registrationModel) {
  const requestOptions = JSON.stringify(registrationModel);

  return await axios
    .post(
      apiConstants.apiPostSelfRegistration,
      requestOptions,
      headerWithoutauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postUserLogInApi(userModel) {
  const requestOptions = JSON.stringify(userModel);

  return await axios
    .post(apiConstants.apiPostUserLogIn, requestOptions, headerWithoutauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postUserLogOutApi() {
  return await axios
    .post(apiConstants.apiPostUserLogOut)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postUserResetPasswordApi(userModel) {
  const requestOptions = JSON.stringify(userModel);
  const url = apiConstants.apiPostUserResetPassword + userModel.token;
  return await axios
    .post(url, requestOptions, headerWithoutauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postUserResetPasswordRqApi(userModel) {
  const requestOptions = JSON.stringify(userModel);

  return await axios
    .post(
      apiConstants.apiPostUserResetPasswordRq,
      requestOptions,
      headerWithoutauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUserChangePasswordApi(model) {
  const requestOptions = JSON.stringify(model);

  return await axios
    .put(
      apiConstants.apiPutUserChangePassword,
      requestOptions,
      headerWithoutauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVerifyEmailApi(model) {
  const requestOptions = JSON.stringify(model);
  const url = apiConstants.apiPostVerifyEmail + model;
  return await axios
    .post(url, requestOptions, headerWithoutauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postVerifyEmailRqApi(model) {
  const requestOptions = JSON.stringify(model);

  return await axios
    .post(apiConstants.apiPostVerifyEmailRq, requestOptions, headerWithoutauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getProviderAuthorizeApi(model) {
  const url = apiConstants.apiGetProviderAuthorize + model;
  return await axios
    .get(url)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postProviderAccessTokenApi(model) {
  const requestOptions = JSON.stringify(model);
  const url = apiConstants.apiPostProviderAccessToken + model.provider;
  return await axios
    .post(url, requestOptions, headerWithoutauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postOtpCallbackApi(model) {
  const requestOptions = JSON.stringify(model);

  return await axios
    .post(apiConstants.apiPostOtpCallback, requestOptions, headerWithoutauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default authApis;
