import { Button, ButtonGroup, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../../components/ConfirmDialog.js";
import { useStyles } from "../../../../components/globalStyles.js";

const TimeSlotDialog = ({
  showTimeSlotDialog,
  selectedDate,
  monthSchedule,
  handleSelectedTimeSlot,
  setShowTimeSlotDialog,
  CALENDAR_DATE_FORMAT,
  API_DATE_FORMAT,
  CALENDAR_TIME_FORMAT,
  DISPAY_TIME_FORMAT,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const dateOnly = moment(selectedDate, CALENDAR_DATE_FORMAT).format(
    API_DATE_FORMAT
  );

  const [isSelectedAM, setIsSelectedAM] = useState(true);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const timeSlots = monthSchedule.find((date) => {
    return date.schedule_date === dateOnly;
  });

  const timeSlotAM = timeSlots
    ? timeSlots.schedule_timeslot.filter(
        (slot) =>
          moment(slot.schedule_start_time, CALENDAR_TIME_FORMAT).diff(
            moment("12", "HH")
          ) < 0
      )
    : [];
  const timeSlotPM = timeSlots
    ? timeSlots.schedule_timeslot.filter(
        (slot) =>
          moment(slot.schedule_start_time, CALENDAR_TIME_FORMAT).diff(
            moment("12", "HH")
          ) >= 0
      )
    : [];

  useEffect(() => {
    //reset timeslot when dialog is called
    setSelectedTimeSlot(null);
  }, [showTimeSlotDialog]);

  const renderTime = (timeSlot) => {
    if (!timeSlot.length) {
      return (
        <Grid item style={{ width: "100%" }}>
          {t("translation:Not Available Time Slot")}
        </Grid>
      );
    }

    return timeSlot.map((time, index) => {
      return (
        <Grid item lg={3} key={index}>
          <Button
            className={
              time.schedule_start_time === selectedTimeSlot
                ? classes.selectedTime
                : classes.maroonBackground100
            }
            //disable if there is booked
            disabled={time.appt_order_no ? true : false}
            variant="contained"
            color="primary"
            onClick={() => setSelectedTimeSlot(time.schedule_start_time)}>
            {moment(time.schedule_start_time, CALENDAR_TIME_FORMAT).format(
              DISPAY_TIME_FORMAT
            )}
          </Button>
        </Grid>
      );
    });
  };

  return (
    <ConfirmDialog
      open={showTimeSlotDialog}
      title={selectedDate || "-"}
      setOpen={setShowTimeSlotDialog}
      onConfirm={(event) => {
        handleSelectedTimeSlot(event);
      }}
      textValue={selectedTimeSlot}
      Yes={t("translation:Yes")}
      No={t("translation:No")}>
      <div>{t("translation:Please Select a Valid Time")}</div>
      <div className={classes.timeSlotDialogDetail}>
        <ButtonGroup className={classes.buttonGroup}>
          <Button
            className={isSelectedAM ? classes.selectedButtonGroup : ""}
            onClick={() => setIsSelectedAM(true)}>
            {t("translation:AM")}
          </Button>
          <Button
            className={!isSelectedAM ? classes.selectedButtonGroup : ""}
            onClick={() => setIsSelectedAM(false)}>
            {t("translation:PM")}
          </Button>
        </ButtonGroup>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={4}>
          {selectedDate && renderTime(isSelectedAM ? timeSlotAM : timeSlotPM)}
        </Grid>
      </div>
    </ConfirmDialog>
  );
};

export default TimeSlotDialog;
