import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HomecareSummaryPage from "./HomecareSummary/HomecareSummaryPage";
export default function HomecarePage() {
  return (
    <Switch>
      <Redirect exact={true} from="/homecare" to="/homecare/homecareSummary" />
      <Route path="/homecare/HomecareSummary" component={HomecareSummaryPage} />

      <Redirect to="/error" />
    </Switch>
  );
}
