import * as types from "../_constants/externalApiConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_SINGAPOREHOLIDAYLIST_ASYNC.PENDING:
      return {
        ...state,
        getSingaporeHolidayListIsSuccess: false,
        isLoadingGetSingaporeHolidayList: true,
        getSingaporeHolidayList: null,
      };

    case types.GET_SINGAPOREHOLIDAYLIST_ASYNC.ERROR:
      return {
        ...state,
        getSingaporeHolidayListIsSuccess: false,
        isLoadingGetSingaporeHolidayList: false,
        getSingaporeHolidayList: null,
        error: {
          ...state.error,
          getSingaporeHolidayListError: action.error,
        },
      };

    case types.GET_SINGAPOREHOLIDAYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getSingaporeHolidayListIsSuccess: true,
        isLoadingGetSingaporeHolidayList: false,
        getSingaporeHolidayList: action.data,
      };

    case types.GET_SINGAPOREYEARLYHOLIDAY_ASYNC.PENDING:
      return {
        ...state,
        getSingaporeYearlyHolidayIsSuccess: false,
        isLoadingGetSingaporeYearlyHoliday: true,
        getSingaporeYearlyHoliday: null,
      };

    case types.GET_SINGAPOREYEARLYHOLIDAY_ASYNC.ERROR:
      return {
        ...state,
        getSingaporeYearlyHolidayIsSuccess: false,
        isLoadingGetSingaporeYearlyHoliday: false,
        getSingaporeYearlyHoliday: null,
        error: {
          ...state.error,
          getSingaporeYearlyHolidayError: action.error,
        },
      };

    case types.GET_SINGAPOREYEARLYHOLIDAY_ASYNC.SUCCESS:
      return {
        ...state,
        getSingaporeYearlyHolidayIsSuccess: true,
        isLoadingGetSingaporeYearlyHoliday: false,
        getSingaporeYearlyHoliday: action.data,
      };

    default:
      return state;
  }
};
