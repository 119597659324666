import * as types from "../_constants/emailStoreConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_EMAILSTOREORDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreOrderListIsSuccess: false,
        isLoadingGetEmailStoreOrderList: true,
        getEmailStoreOrderList: null,
      };

    case types.GET_EMAILSTOREORDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreOrderListIsSuccess: false,
        isLoadingGetEmailStoreOrderList: false,
        getEmailStoreOrderList: null,
        error: {
          ...state.error,
          getEmailStoreOrderListError: action.error,
        },
      };

    case types.GET_EMAILSTOREORDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreOrderListIsSuccess: true,
        isLoadingGetEmailStoreOrderList: false,
        getEmailStoreOrderList: action.data,
      };

    case types.GET_EMAILSTORORDEREBYID_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreOrderByIdIsSuccess: false,
        isLoadingGetEmailStoreOrderById: true,
        getEmailStoreOrderById: null,
      };

    case types.GET_EMAILSTORORDEREBYID_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreOrderByIdIsSuccess: false,
        isLoadingGetEmailStoreOrderById: false,
        getEmailStoreOrderById: null,
        error: {
          ...state.error,
          getEmailStoreOrderByIdError: action.error,
        },
      };

    case types.GET_EMAILSTORORDEREBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreOrderByIdIsSuccess: true,
        isLoadingGetEmailStoreOrderById: false,
        getEmailStoreOrderById: action.data,
      };

    case types.POST_EMAILSTOREREADEMAIL_ASYNC.PENDING:
      return {
        ...state,
        postEmailStoreReadEmailIsSuccess: false,
        isLoadingPostEmailStoreReadEmail: true,
        postEmailStoreReadEmail: null,
      };

    case types.POST_EMAILSTOREREADEMAIL_ASYNC.ERROR:
      return {
        ...state,
        postEmailStoreReadEmailIsSuccess: false,
        isLoadingPostEmailStoreReadEmail: false,
        postEmailStoreReadEmail: null,
        error: {
          ...state.error,
          postEmailStoreReadEmailError: action.error,
        },
      };

    case types.POST_EMAILSTOREREADEMAIL_ASYNC.SUCCESS:
      return {
        ...state,
        postEmailStoreReadEmailIsSuccess: true,
        isLoadingPostEmailStoreReadEmail: false,
        postEmailStoreReadEmail: action.data,
      };

    case types.PUT_EMAILSTOREPENDINGSTATUS_ASYNC.PENDING:
      return {
        ...state,
        putEmailStorePendingStatusIsSuccess: false,
        isLoadingPutEmailStorePendingStatus: true,
        putEmailStorePendingStatus: null,
      };

    case types.PUT_EMAILSTOREPENDINGSTATUS_ASYNC.ERROR:
      return {
        ...state,
        putEmailStorePendingStatusIsSuccess: false,
        isLoadingPutEmailStorePendingStatus: false,
        putEmailStorePendingStatus: null,
        error: {
          ...state.error,
          putEmailStorePendingStatusError: action.error,
        },
      };

    case types.PUT_EMAILSTOREPENDINGSTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        putEmailStorePendingStatusIsSuccess: true,
        isLoadingPutEmailStorePendingStatus: false,
        putEmailStorePendingStatus: action.data,
      };

    case types.PUT_PROCESSEMAILSTOREPENDINGSTATUS_ASYNC.PENDING:
      return {
        ...state,
        putProcessEmailStorePendingStatusIsSuccess: false,
        isLoadingPutProcessEmailStorePendingStatus: true,
        putProcessEmailStorePendingStatus: null,
      };

    case types.PUT_PROCESSEMAILSTOREPENDINGSTATUS_ASYNC.ERROR:
      return {
        ...state,
        putProcessEmailStorePendingStatusIsSuccess: false,
        isLoadingPutProcessEmailStorePendingStatus: false,
        putProcessEmailStorePendingStatus: null,
        error: {
          ...state.error,
          putProcessEmailStorePendingStatusError: action.error,
        },
      };

    case types.PUT_PROCESSEMAILSTOREPENDINGSTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        putProcessEmailStorePendingStatusIsSuccess: true,
        isLoadingPutProcessEmailStorePendingStatus: false,
        putProcessEmailStorePendingStatus: action.data,
      };

    case types.PUT_UPDATEEMAILSTORE_ASYNC.PENDING:
      return {
        ...state,
        putUpdateEmailStoreIsSuccess: false,
        isLoadingPutUpdateEmailStore: true,
        putUpdateEmailStore: null,
      };

    case types.PUT_UPDATEEMAILSTORE_ASYNC.ERROR:
      return {
        ...state,
        putUpdateEmailStoreIsSuccess: false,
        isLoadingPutUpdateEmailStore: false,
        putUpdateEmailStore: null,
        error: {
          ...state.error,
          putUpdateEmailStoreError: action.error,
        },
      };

    case types.PUT_UPDATEEMAILSTORE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateEmailStoreIsSuccess: true,
        isLoadingPutUpdateEmailStore: false,
        putUpdateEmailStore: action.data,
      };

    case types.GET_EMAILSTOREBRANCHMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreBranchMedicineIsSuccess: false,
        isLoadingGetEmailStoreBranchMedicine: true,
        getEmailStoreBranchMedicine: null,
      };

    case types.GET_EMAILSTOREBRANCHMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreBranchMedicineIsSuccess: false,
        isLoadingGetEmailStoreBranchMedicine: false,
        getEmailStoreBranchMedicine: null,
        error: {
          ...state.error,
          getEmailStoreBranchMedicineError: action.error,
        },
      };

    case types.GET_EMAILSTOREBRANCHMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreBranchMedicineIsSuccess: true,
        isLoadingGetEmailStoreBranchMedicine: false,
        getEmailStoreBranchMedicine: action.data,
      };

    default:
      return state;
  }
};
