import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

import { useStyles } from "../../../../components/globalStyles";

import Panel from "../../../../components/Panel";

export default function AmountTotal({ amountHeader, gstValue }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Panel>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={true}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={
              amountHeader.amt_wo_gst !== null
                ? Number(amountHeader.amt_wo_gst).toFixed(2)
                : (0).toFixed(2)
            }
            id="amt_bf_tax"
            name="amt_bf_tax"
            label={t("translation:Amount Before Tax")}
            variant="outlined"
          />
        </Grid>
        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={true}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={
              amountHeader.gst_amt !== null
                ? Number(amountHeader.gst_amt).toFixed(2)
                : (0).toFixed(2)
            }
            id="tax_amt"
            name="tax_amt"
            label={
              t("translation:Tax Amount") + ` (${gstValue ? gstValue : 0} %)`
            }
            variant="outlined"
          />
        </Grid>
        <Grid xs={4} item className={classes.subTotalInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={true}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            InputLabelProps={{
              shrink: true,

              style: {
                fontWeight: "bold",
                color: "rgba(149, 0, 0,0.75)",
              },
            }}
            value={
              amountHeader.amt_with_gst !== null
                ? Number(amountHeader.amt_with_gst).toFixed(2)
                : (0).toFixed(2)
            }
            name="amt_with_gst"
            label={t("translation:Amount After Tax")}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Panel>
  );
}
