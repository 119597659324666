import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Note({
  groupPrice = false,
  prescriptionTimes = false,
  noOfPacks = false,
  reselectDeliveryandBrewingService = false,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        {t("translation:Note")} ：
      </Grid>

      {groupPrice && (
        <Grid item xs={12}>
          {t("translation:Group Price")} = {t("translation:Subtotal per Day")} *{" "}
          {t("translation:Day")}.
        </Grid>
      )}

      {prescriptionTimes && (
        <Grid item xs={12}>
          {t("translation: <T> = Times")}.{" "}
          {t(
            "translation: Prescription Instructions will not auto populate if there is any change of Times"
          )}
          .{" "}
        </Grid>
      )}

      {noOfPacks && (
        <Grid item xs={12}>
          {t(
            "translation: Number of Pack can be determined by number of Day, number of Times, max grams per pack or brewing services"
          )}
          .{" "}
        </Grid>
      )}

      {reselectDeliveryandBrewingService && (
        <Grid item xs={12}>
          {t(
            "translation:Delivery service and brewing service need to be reselected"
          )}
          .
        </Grid>
      )}
    </>
  );
}
