import * as types from "../_constants/medicineFixedSellingPriceDetailsConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSBYID_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsByIdIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceDetailsById: true,
        getMedicineFixedSellingPriceDetailsById: null,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSBYID_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsByIdIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceDetailsById: false,
        getMedicineFixedSellingPriceDetailsById: null,
        error: {
          ...state.error,
          getMedicineFixedSellingPriceDetailsByIdError: action.error,
        },
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsByIdIsSuccess: true,
        isLoadingGetMedicineFixedSellingPriceDetailsById: false,
        getMedicineFixedSellingPriceDetailsById: action.data,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceDetails: true,
        getMedicineFixedSellingPriceDetails: null,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceDetails: false,
        getMedicineFixedSellingPriceDetails: null,
        error: {
          ...state.error,
          getMedicineFixedSellingPriceDetailsError: action.error,
        },
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsIsSuccess: true,
        isLoadingGetMedicineFixedSellingPriceDetails: false,
        getMedicineFixedSellingPriceDetails: action.data,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEHEADERDETAILSLIST_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFixedSellingPriceHeaderDetailsListIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceHeaderDetailsList: true,
        getMedicineFixedSellingPriceHeaderDetailsList: null,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEHEADERDETAILSLIST_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFixedSellingPriceHeaderDetailsListIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceHeaderDetailsList: false,
        getMedicineFixedSellingPriceHeaderDetailsList: null,
        error: {
          ...state.error,
          getMedicineFixedSellingPriceHeaderDetailsListError: action.error,
        },
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEHEADERDETAILSLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFixedSellingPriceHeaderDetailsListIsSuccess: true,
        isLoadingGetMedicineFixedSellingPriceHeaderDetailsList: false,
        getMedicineFixedSellingPriceHeaderDetailsList: action.data,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsSummaryListIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceDetailsSummaryList: true,
        getMedicineFixedSellingPriceDetailsSummaryList: null,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsSummaryListIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceDetailsSummaryList: false,
        getMedicineFixedSellingPriceDetailsSummaryList: null,
        error: {
          ...state.error,
          getMedicineFixedSellingPriceDetailsSummaryListError: action.error,
        },
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsSummaryListIsSuccess: true,
        isLoadingGetMedicineFixedSellingPriceDetailsSummaryList: false,
        getMedicineFixedSellingPriceDetailsSummaryList: action.data,
      };

    case types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.PENDING:
      return {
        ...state,
        putUpdateMedicineFixedSellingPriceDetailsIsSuccess: false,
        isLoadingPutUpdateMedicineFixedSellingPriceDetails: true,
        putUpdateMedicineFixedSellingPriceDetails: null,
      };

    case types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.ERROR:
      return {
        ...state,
        putUpdateMedicineFixedSellingPriceDetailsIsSuccess: false,
        isLoadingPutUpdateMedicineFixedSellingPriceDetails: false,
        putUpdateMedicineFixedSellingPriceDetails: null,
        error: {
          ...state.error,
          putUpdateMedicineFixedSellingPriceDetailsError: action.error,
        },
      };

    case types.PUT_UPDATEMEDICINEFIXEDSELLINGPRICEDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateMedicineFixedSellingPriceDetailsIsSuccess: true,
        isLoadingPutUpdateMedicineFixedSellingPriceDetails: false,
        putUpdateMedicineFixedSellingPriceDetails: action.data,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINETYPES_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsMedicineTypesIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceDetailsMedicineTypes: true,
        getMedicineFixedSellingPriceDetailsMedicineTypes: null,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINETYPES_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsMedicineTypesIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceDetailsMedicineTypes: false,
        getMedicineFixedSellingPriceDetailsMedicineTypes: null,
        error: {
          ...state.error,
          getMedicineFixedSellingPriceDetailsMedicineTypesError: action.error,
        },
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINETYPES_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsMedicineTypesIsSuccess: true,
        isLoadingGetMedicineFixedSellingPriceDetailsMedicineTypes: false,
        getMedicineFixedSellingPriceDetailsMedicineTypes: action.data,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINEINFO_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsMedicineInfoIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceDetailsMedicineInfo: true,
        getMedicineFixedSellingPriceDetailsMedicineInfo: null,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINEINFO_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsMedicineInfoIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceDetailsMedicineInfo: false,
        getMedicineFixedSellingPriceDetailsMedicineInfo: null,
        error: {
          ...state.error,
          getMedicineFixedSellingPriceDetailsMedicineInfoError: action.error,
        },
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEDETAILSMEDICINEINFO_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFixedSellingPriceDetailsMedicineInfoIsSuccess: true,
        isLoadingGetMedicineFixedSellingPriceDetailsMedicineInfo: false,
        getMedicineFixedSellingPriceDetailsMedicineInfo: action.data,
      };

    default:
      return state;
  }
};
