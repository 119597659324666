import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import BranchCourierSummaryPage from "./BranchCourierSummary/BranchCourierSummaryPage";
export default function BranchCourierPage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/branchCourier"
        to="/branchCourier/BranchCourierSummary"
      />
      <Route
        path="/branchCourier/BranchCourierSummary"
        component={BranchCourierSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
