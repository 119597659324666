//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Close from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

//Actions
import { getWikiDataPathList } from "../../../../../actions/wikiDataActions";

//Components
import * as pageConfigure from "../../../../../_constants/pageConstants";
import * as generalConstants from "../../../../../_constants/generalConstants";

import { SortingSearchTable } from "../../../../../components/SortingSearchTable";
import StyledRadioCheckbox from "../../../../../components/StyledRadioCheckbox";
import PageCustomAction from "../../../../../components/PageCustomAction";
//style
export default function AddSubNodeList({ setAddOpen, addOpen, setData }) {
  //variables
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(false);

  const ref = React.useRef();
  const dispatch = useDispatch();

  const wikiDataData = useSelector((state) => state.wikiData);
  const common = useSelector((state) => state.common);
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: "Asc",
    order_by_field: "name",
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    is_pagination: true,
    search_desc_value: null,
    search_id_value: null,
    search_name_value: "",
    search_root_node: false,
    search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
    search_subnode_value: null,
    view_format_value: pageConfigure.WIKI_ID_VIEW,
  });

  const [searchValue, setSearchValue] = React.useState("");
  const [checkedId, setCheckedId] = useState(null);
  const columns = [
    {
      Header: "",
      accessor: "checkSubNode",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <>
            <StyledRadioCheckbox
              checked={
                checkedId !== null && checkedId.id === row.original.id
                  ? true
                  : false
              }
              name="checkSubNode"
              onChange={(e) => handleCheck(e, row)}
            />
          </>
        );
      },
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Root Node"),
      accessor: "is_root_node",
      Cell: ({ cell }) => {
        return cell.value ? t("translation:Yes") : t("translation:No");
      },
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];
  useEffect(() => {
    if (ref.current && scroll) {
      ref.current.scrollTop = 0;
      setScroll(false);
    }
  }, [ref, scroll]);

  useEffect(() => {
    dispatch(getWikiDataPathList.pending(pageInfo));
  }, [dispatch, pageInfo]);

  //functions

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedId(row.original);
    } else {
      setCheckedId(null);
    }
  };

  //For search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
      });
    }
  };
  return (
    <>
      <Dialog
        open={addOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setAddOpen(false);
          }
        }}
        aria-labelledby="confirm-dialog"
        fullWidth
        maxWidth="xl">
        <Close
          style={{ position: "absolute", right: "15px" }}
          onClick={() => setAddOpen(false)}
        />
        <DialogContent ref={ref}>
          <SortingSearchTable
            tableHeading={""}
            searchPlaceholder={t("translation:Name")}
            searchValue={searchValue}
            columns={columns}
            data={
              wikiDataData.getWikiDataPathList?.wiki_datas !== undefined
                ? wikiDataData.getWikiDataPathList?.wiki_datas
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              wikiDataData.getWikiDataPathList
                ? wikiDataData.getWikiDataPathList.total_pages
                : 0
            }
            loading={wikiDataData.isLoadingGetWikiDataPathList}
            totalItems={
              wikiDataData.getWikiDataPathList
                ? wikiDataData.getWikiDataPathList.total_items
                : 0
            }
            handleSearch={handleSearch}
          />
        </DialogContent>
        <DialogActions>
          <PageCustomAction
            button1Name={"Yes"}
            button2Name={"No"}
            button1label={"Yes"}
            button2label={"No"}
            button1Click={() => {
              setData(checkedId, "add");
              setAddOpen(false);
            }}
            button2Click={() => setAddOpen(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
