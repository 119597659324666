import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/emailStoreRecipientConstants";

export const getEmailStoreRecipientById = {
  pending: (data) =>
    createAction(types.GET_EMAILSTORERECIPIENTBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_EMAILSTORERECIPIENTBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_EMAILSTORERECIPIENTBYID_ASYNC.ERROR, { error }),
};

export const getEmailStoreRecipientList = {
  pending: (data) =>
    createAction(types.GET_EMAILSTORERECIPIENTLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_EMAILSTORERECIPIENTLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_EMAILSTORERECIPIENTLIST_ASYNC.ERROR, { error }),
};

export const postCreateEmailStoreRecipient = {
  pending: (data) =>
    createAction(types.POST_CREATEEMAILSTORERECIPIENT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEEMAILSTORERECIPIENT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEEMAILSTORERECIPIENT_ASYNC.ERROR, { error }),
};

export const putUpdateEmailStoreRecipient = {
  pending: (data) =>
    createAction(types.PUT_UPDATEEMAILSTORERECIPIENT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEEMAILSTORERECIPIENT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEEMAILSTORERECIPIENT_ASYNC.ERROR, { error }),
};
