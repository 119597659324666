//lib

import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Close from "@mui/icons-material/Close";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";

//action
import { postCreateWikiData } from "../../../../../actions/wikiDataActions";
//components

import { useStyles } from "../../../../../components/globalStyles";
import PageOnlyBackAction from "../../../../../components/PageOnlyBackAction";
import Panel from "../../../../../components/Panel";
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import RequiredNote from "../../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../../components/PageSubmitWBackAction";
import * as pageConfigure from "../../../../../_constants/pageConstants";

//style//style
export default function AddSubnodeDialog({
  openAddSubnodeDialog = false,
  setOpenAddSubnodeDialog = () => null,
  setData = () => null,
}) {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const wikiDataData = useSelector((state) => state.wikiData);
  const common = useSelector((state) => state.common);
  const prevPostWikiDataStatus = useRef();

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      name: null,
      is_root_node: false,
      status_type: null,
    },
  });

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJson = {
      wiki_data: {
        id: 0,
        ...data,
      },
    };
    dispatch(postCreateWikiData.pending(submitJson));
  };

  //useEffects
  useEffect(() => {
    //set ref when first render
    prevPostWikiDataStatus.current = false;
  }, []);

  useEffect(() => {
    //set ref when first render
    if (openAddSubnodeDialog) {
      setTemViewOnly(false);
      reset({
        name: null,
        is_root_node: false,
        status_type: null,
      });
      setSuccessAlarm(false);
      setErrorAlarm(false);
    }
  }, [openAddSubnodeDialog]);

  useEffect(() => {
    if (
      wikiDataData.isLoadingPostCreateWikiData !==
      prevPostWikiDataStatus.current
    ) {
      //check the previous ref with current state
      prevPostWikiDataStatus.current = wikiDataData.isLoadingPostCreateWikiData;

      if (
        wikiDataData.postCreateWikiDataIsSuccess === true &&
        wikiDataData.isLoadingPostCreateWikiData === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        window.scrollTo(0, 0);
        reset({
          ...wikiDataData.postCreateWikiData.wiki_data,
        });

        setData(wikiDataData.postCreateWikiData.wiki_data, "add");
        setTemViewOnly(true);
      }
      if (
        wikiDataData.postCreateWikiDataIsSuccess === false &&
        wikiDataData.isLoadingPostCreateWikiData === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(wikiDataData.error.postCreateWikiDataError);
        window.scrollTo(0, 0);
      }
    }
  }, [wikiDataData, reset]);

  return (
    <>
      <Grid className={classes.root} container>
        <Dialog
          open={openAddSubnodeDialog}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setOpenAddSubnodeDialog(false);
            }
          }}
          aria-labelledby="confirm-dialog"
          fullWidth
          maxWidth="xl">
          <Close
            style={{ position: "absolute", right: "15px" }}
            onClick={() => setOpenAddSubnodeDialog(false)}
          />
          <ConfirmDialog
            Yes={t("translation:Yes")}
            No={t("translation:No")}
            title={t("translation:Confirmation")}
            textValue={JsonText}
            open={confirmSubmit}
            setOpen={setConfirmSubmit}
            onConfirm={onConfirmSubmit}>
            {t("translation:Are you sure you want to proceed?")}
          </ConfirmDialog>
          <Collapse in={successAlarm}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setSuccessAlarm(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {t("translation:Wiki Data has been created")}
            </Alert>
          </Collapse>

          <Collapse in={errorAlarm}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlarm(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlarm}
            </Alert>
          </Collapse>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Panel heading={t("translation:Wiki Data Details")}>
                <br />

                <Grid className={classes.gridRoot} container>
                  <Grid item xs={12} className={classes.userInfoField}>
                    <TextField
                      disabled={temViewOnly}
                      className={classes.userFieldColor}
                      required
                      name="name"
                      label={t("translation:Name")}
                      variant="outlined"
                      {...register("name")}
                    />
                  </Grid>

                  {/* <Grid item xs={12} className={classes.userInfoField}>
                    <Controller
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <StyledCheckbox
                              disabled={temViewOnly}
                              checked={field.value}
                              onChange={(e, option) => field.onChange(option)}
                              name="is_root_node"
                            />
                          }
                          label={t("translation:Is Root Node")}
                        />
                      )}
                      name="is_root_node"
                      control={control}
                    />
                  </Grid> */}
                  {common.generalStatusList.status_general && (
                    <Grid item xs={12} className={classes.userInfoField}>
                      <Controller
                        render={({ field }) => (
                          <Autocomplete
                            disabled={temViewOnly}
                            {...field}
                            name="status_type"
                            id="status_type"
                            required
                            filterOptions={(options) => {
                              const result = options.filter(
                                (item) =>
                                  item.id ===
                                  pageConfigure.APPROVED_GENERAL_STATUS
                              );

                              return result;
                            }}
                            options={
                              common.generalStatusList.status_general
                                ? common.generalStatusList.status_general
                                : []
                            }
                            getOptionLabel={(option) =>
                              getOpObj(
                                option,
                                common.generalStatusList.status_general
                              ).name
                            }
                            style={{ width: "100%" }}
                            isOptionEqualToValue={(option, value) => {
                              if (option.id === value) {
                                return option;
                              }
                            }}
                            onChange={(e, option) =>
                              field.onChange(option == null ? null : option.id)
                            }
                            renderInput={(params) => (
                              <TextField
                                disabled={temViewOnly}
                                required
                                className={classes.userFieldColor}
                                {...params}
                                label={t("translation:Status Type")}
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                        name="status_type"
                        control={control}
                      />
                    </Grid>
                  )}
                  <>
                    <RequiredNote />
                  </>
                </Grid>
              </Panel>
            </DialogContent>
            <DialogActions>
              {temViewOnly ? (
                <PageOnlyBackAction
                  setView={() => setOpenAddSubnodeDialog(false)}
                />
              ) : (
                <PageSubmitWBackAction
                  setView={() => setOpenAddSubnodeDialog(false)}
                />
              )}
            </DialogActions>
          </form>
        </Dialog>
      </Grid>
    </>
  );
}
