//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";

//actions
import {
  putUpdateUserMedicineFixedSellingPrice,
  getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList,
} from "../../../../actions/userMedicineFixedSellingPriceActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import * as generalConstants from "../../../../_constants/generalConstants";

export const EditUserMedicineFixedSellingPricePage = ({
  userMedicineFixedSellingPrice,
  setEditUserMedicineFixedSellingPriceView,
  userInfo,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const userMedicineFixedSellingPriceData = useSelector(
    (state) => state.userMedicineFixedSellingPrice
  );
  const common = useSelector((state) => state.common);

  const prevSubmitStatus = useRef();
  const dispatch = useDispatch();

  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  const [msgAlarm, setMsgAlarm] = useState("");

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const onHandleSelete = (data) => {
    const tempHeader = userMedicineFixedSellingPrice.find(
      (item) => item.medicine_fixed_selling_price_header.id === data.id
    );
    if (tempHeader) {
      reset({
        ...tempHeader,
        name: tempHeader.user.name,
      });
    } else {
      reset({
        id: 0,
        name: userInfo.name,
        medicine_fixed_selling_price_header: data,
        status_type: null,
      });
    }
  };

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJson = {
      user_medicine_fixed_selling_price_header: {
        ...userMedicineFixedSellingPrice,
        ...data,
        user: userInfo,
      },
    };
    delete submitJson.name;
    dispatch(putUpdateUserMedicineFixedSellingPrice.pending(submitJson));
  };

  const { register, handleSubmit, reset, control, getValues, watch } = useForm({
    defaultValues: {
      id: 0,
      name: userInfo.name,
      medicine_fixed_selling_price_header: null,
      status_type: null,
    },
  });
  useEffect(() => {
    //set ref when first render
    prevSubmitStatus.current = false;
  }, []);

  useEffect(() => {
    dispatch(
      getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      userMedicineFixedSellingPriceData.isLoadingPutUpdateUserMedicineFixedSellingPrice !==
      prevSubmitStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitStatus.current =
        userMedicineFixedSellingPriceData.isLoadingPutUpdateUserMedicineFixedSellingPrice;

      if (
        userMedicineFixedSellingPriceData.putUpdateUserMedicineFixedSellingPriceIsSuccess ===
          true &&
        userMedicineFixedSellingPriceData.isLoadingPutUpdateUserMedicineFixedSellingPrice ===
          false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...userMedicineFixedSellingPriceData
            .putUpdateUserMedicineFixedSellingPrice
            .user_medicine_fixed_selling_price_header,
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        userMedicineFixedSellingPriceData.putUpdateUserMedicineFixedSellingPriceIsSuccess ===
          false &&
        userMedicineFixedSellingPriceData.isLoadingPutUpdateUserMedicineFixedSellingPrice ===
          false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(
          userMedicineFixedSellingPriceData.error
            .putUpdateUserMedicineFixedSellingPriceError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [userMedicineFixedSellingPriceData, reset]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:User Medicine Fixed Selling Price Details")
          : t("translation:Edit User Medicine Fixed Selling Price")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:User Medicine Fixed Selling Price has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>

      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <input hidden readOnly {...register("id")} />

        <Grid className={classes.root} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={true}
              className={classes.userFieldColor}
              readOnly
              name="name"
              label={t("translation:Name")}
              variant="outlined"
              {...register("name")}
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  name="medicine_fixed_selling_price_header"
                  id="medicine_fixed_selling_price_header"
                  required
                  options={
                    userMedicineFixedSellingPriceData
                      .getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList
                      ?.medicine_fixed_selling_price_headers
                      ? userMedicineFixedSellingPriceData
                          .getUserMedicineFixedSellingPriceMedicineFixedSellingPriceList
                          ?.medicine_fixed_selling_price_headers
                      : []
                  }
                  getOptionLabel={(option) => option.name}
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value.id) {
                      return option;
                    }
                  }}
                  onChange={(e, option) => {
                    field.onChange(option == null ? null : option);
                    onHandleSelete(option);
                  }}
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Medicine Fixed Selling Price Name")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="medicine_fixed_selling_price_header"
              control={control}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={
                    temViewOnly || !watch("medicine_fixed_selling_price_header")
                  }
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>

          <>
            <RequiredNote />

            {temViewOnly ? (
              <PageOnlyBackAction
                setView={() => setEditUserMedicineFixedSellingPriceView(false)}
              />
            ) : (
              <PageSubmitWBackAction
                setView={() => setEditUserMedicineFixedSellingPriceView(false)}
              />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
