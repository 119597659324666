import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import BranchScheduleSummaryPage from "./BranchScheduleSummary/BranchScheduleSummaryPage";
import BranchAdhocScheduleSummaryPage from "./BranchAdhocScheduleSummary/BranchAdhocScheduleSummaryPage";
import BranchPhysicianScheduleSummaryPage from "./BranchPhysicianScheduleSummary/BranchPhysicianScheduleSummaryPage";
import BranchPhysicianAdhocScheduleSummaryPage from "./BranchPhysicianAdhocScheduleSummary/BranchPhysicianAdhocScheduleSummaryPage";
export default function SchedulePage() {
  return (
    <Switch>
      <Route
        path="/schedule/BranchScheduleSummary"
        component={BranchScheduleSummaryPage}
      />
      <Route
        path="/schedule/BranchAdhocScheduleSummary"
        component={BranchAdhocScheduleSummaryPage}
      />

      <Route
        path="/schedule/BranchPhysicianScheduleSummary"
        component={BranchPhysicianScheduleSummaryPage}
      />

      <Route
        path="/schedule/BranchPhysicianAdhocScheduleSummary"
        component={BranchPhysicianAdhocScheduleSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
