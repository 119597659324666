//lib
import React, { useState, useRef, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import Info from "@mui/icons-material/InfoRounded";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

//components
import { ReactComponent as DeleteIcon } from "../../../../../assets/coolicons/basic/trash_empty.svg";
import organisationPriceRounding from "../../../../../components/functions/organisationPriceRounding";
import EditableCell from "../../../../../components/EditableCell";
import {
  useStyles,
  noAvailableColorStyle,
} from "../../../../../components/globalTableStyles";
import { useStyles as useStylesGlobal } from "../../../../../components/globalStyles";
import ConfirmDialog from "../../../../../components/ConfirmDialog";

//action
import { getVisitTreatmentInfo } from "../../../../../actions/visitOrderActions";
// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({ columns, data, updateMyData, disablePageResetOnDataChange }) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        updateMyData,
        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();
  const { t } = useTranslation();
  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    <TableCell
                      className={paginationClasses.narrowCellStyle}
                      style={
                        row.original.treatment.id === null
                          ? noAvailableColorStyle
                          : {}
                      }>
                      {i + 1}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={paginationClasses.narrowCellStyle}
                          style={
                            row.original.treatment.id === null
                              ? noAvailableColorStyle
                              : {}
                          }
                          {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable:
                              row.original.treatment.id !== null
                                ? cell.column.editable
                                : false,
                            editableNum:
                              row.original.treatment.id !== null
                                ? cell.column.editableNum
                                : false,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>

          <caption>
            <span className={paginationClasses.noAvailableColorCaption}>
              {t("translation:Blue - Not available")}{" "}
            </span>
          </caption>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const TreatmentListTable = ({
  med_details_price_rmt,
  med_details_price_rpvt,
  viewOnly = false,
  data = {},
  setData,
  t,
  infoModule,
}) => {
  const dispatch = useDispatch();
  const prevGetInfoStatus = useRef();
  const [infoOpen, setInfoOpen] = useState(false);
  const [treatmentInfo, setTreatmentInfo] = useState(null);
  const [treatmentInfoError, setTreatmentInfoError] = useState(null);
  const classes = useStylesGlobal();
  const visitOrder = useSelector((state) => state.visitOrder);

  //useEffects
  useEffect(() => {
    prevGetInfoStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      visitOrder.isLoadingGetVisitTreatmentInfo !== prevGetInfoStatus.current
    ) {
      //check the previous ref with current state
      prevGetInfoStatus.current = visitOrder.isLoadingGetVisitTreatmentInfo;

      if (
        visitOrder.getVisitTreatmentInfoIsSuccess === true &&
        visitOrder.isLoadingGetVisitTreatmentInfo === false
      ) {
        setTreatmentInfo(visitOrder.getVisitTreatmentInfo.treatments[0]);
        setTreatmentInfoError(null);
      }
      if (
        visitOrder.getVisitTreatmentInfoIsSuccess === false &&
        visitOrder.isLoadingGetVisitTreatmentInfo === false
      ) {
        setTreatmentInfo(null);
        setTreatmentInfoError(visitOrder.error.getVisitTreatmentInfoError);
      }
    }
  }, [
    visitOrder.isLoadingGetVisitTreatmentInfo,
    visitOrder.getVisitTreatmentInfoIsSuccess,
    visitOrder.getVisitTreatmentInfo,
    visitOrder.error,
  ]);

  const handleInfoOnClick = (item) => {
    setTreatmentInfo(null);
    if (infoModule === "VISIT") {
      const submisionJSON = {
        is_pagination: false,
        order_by_direction: null,
        order_by_field: null,
        page_number: null,
        page_size: null,
        search_desc_value: null,
        search_id_value: item.row.original.treatment.id,
      };
      setInfoOpen(true);
      dispatch(getVisitTreatmentInfo.pending(submisionJSON));
    }
  };
  const handleRowDel = (rowIndex, action) => {
    setData(rowIndex, action);
  };

  const columns = [
    {
      id: "Delete",
      textAlign: "left",
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <span className={classes.fullWidthField}>
          <IconButton
            aria-label="delete"
            color="inherit"
            onClick={() => handleRowDel(row.index, "delete")}>
            <DeleteIcon />
          </IconButton>
          {row.index !== 0 && (
            <IconButton
              aria-label="up"
              color="inherit"
              onClick={() => handleRowDel(row.index, "up")}>
              <KeyboardDoubleArrowUpIcon fontSize="inherit" />
            </IconButton>
          )}
          {row.index + 1 !== data.length && (
            <IconButton
              aria-label="down"
              color="inherit"
              onClick={() => handleRowDel(row.index, "down")}>
              <KeyboardDoubleArrowDownIcon fontSize="inherit" />
            </IconButton>
          )}
        </span>
      ),
    },

    {
      Header: t("translation:Name"),
      accessor: "treatment_name",
      Cell: ({ cell }) => {
        return (
          <>
            {cell.value}
            {infoModule !== null && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => handleInfoOnClick(cell)}>
                <Info fontSize="inherit" />
              </IconButton>
            )}
          </>
        );
      },
    },
    {
      editableNum: true,
      Header: t("translation:Quantity"),
      accessor: "quantity",
    },
    {
      editableNum: true,
      Header: t("translation:Price"),
      accessor: "unit_selling_price",
    },
    {
      Header: t("translation:Subtotal"),
      Cell: ({ row }) => {
        if (!row.original.quantity || !row.original.unit_selling_price) {
          const subTotalRound = organisationPriceRounding(
            0,
            med_details_price_rmt,
            med_details_price_rpvt
          );
          return <>{subTotalRound}</>;
        } else {
          const subTotalRound = organisationPriceRounding(
            row.original.unit_selling_price * row.original.quantity,
            med_details_price_rmt,
            med_details_price_rpvt
          );
          return <>{subTotalRound}</>;
        }
      },
    },
  ];

  const columnsViewOnly = [
    {
      Header: t("translation:Name"),
      accessor: "treatment_name",
      Cell: ({ cell }) => {
        return (
          <>
            {cell.value}
            {infoModule !== null && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => handleInfoOnClick(cell)}>
                <Info fontSize="inherit" />
              </IconButton>
            )}
          </>
        );
      },
    },
    {
      Header: t("translation:Quantity"),
      accessor: "quantity",
      Cell: ({ cell }) => {
        return <>{cell.value ? cell.value : ""}</>;
      },
    },
    {
      Header: t("translation:Price"),
      accessor: "unit_selling_price",
      Cell: ({ cell }) => {
        return <>{cell.value ? cell.value : ""}</>;
      },
    },
    {
      Header: t("translation:Subtotal"),
      Cell: ({ row }) => {
        if (!row.original.quantity || !row.original.unit_selling_price) {
          const subTotalRound = organisationPriceRounding(
            0,
            med_details_price_rmt,
            med_details_price_rpvt
          );
          return <>{subTotalRound}</>;
        } else {
          const subTotalRound = organisationPriceRounding(
            row.original.unit_selling_price * row.original.quantity,
            med_details_price_rmt,
            med_details_price_rpvt
          );
          return <>{subTotalRound}</>;
        }
      },
    },
  ];

  return (
    <>
      {" "}
      <ConfirmDialog
        open={infoOpen}
        setOpen={setInfoOpen}
        title={t("translation:Info")}
        maxWidth="md">
        {treatmentInfoError !== null && (
          <Alert severity="error">{treatmentInfoError}</Alert>
        )}
        {treatmentInfo !== null && (
          <Grid className={classes.gridRoot} container>
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                required
                name="name"
                label={t("translation:Name")}
                variant="outlined"
                value={treatmentInfo?.name}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                name="info1"
                label={t("translation:Info 1")}
                variant="outlined"
                value={treatmentInfo?.info1}
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                name="info2"
                label={t("translation:Info 2")}
                variant="outlined"
                value={treatmentInfo?.info2}
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                name="info3"
                label={t("translation:Info 3")}
                variant="outlined"
                value={treatmentInfo?.info3}
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                name="info_functionality"
                label={t("translation:Info Functionality")}
                variant="outlined"
                value={treatmentInfo?.info_functionality}
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled
                className={classes.userFieldColor}
                name="info_precaution"
                label={t("translation:Info Precaution")}
                variant="outlined"
                value={treatmentInfo?.info_precaution}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        )}
      </ConfirmDialog>
      <Table
        columns={viewOnly ? columnsViewOnly : columns}
        data={data}
        updateMyData={setData}
        t={t}
      />
    </>
  );
};

export default TreatmentListTable;
