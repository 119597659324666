//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
//component

import { useStyles } from "../../../components/globalStyles";

//import ExportUserCredits from "./components/ExportUserCredits";

import ExportUserCreditsStatement from "./components/ExportUserCreditsStatement";

const UserCreditsReportPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={11}>
          <Typography className={classes.title} variant="h3" gutterBottom>
            {t("translation:User Credits Report")}
          </Typography>
        </Grid>
      </Grid>

      {/* <hr />
      <ExportUserCredits /> */}

      <hr />
      <ExportUserCreditsStatement />
    </Paper>
  );
};

export default UserCreditsReportPage;
