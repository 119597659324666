import React from "react";
import Typography from "@mui/material/Typography";
import { useStyles } from "../../components/globalStyles";

const NotFound = ({ history }) => {
  const classes = useStyles();

  return (
    <div className={classes.notificationRoot}>
      <div className={classes.notificationTitleContainer}>
        <Typography
          className={classes.notificationTitle}
          variant="h3"
          gutterBottom>
          404
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Nothing to see here
        </Typography>
      </div>
    </div>
  );
};

export default NotFound;
