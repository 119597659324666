//lib
import React, { useRef, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//component

// import AddMedicineTypePage from "./AddMedicineTypePage";
import PurchaseOrderListTable from "./PurchaseOrderListTable";

import { useStyles } from "../../../components/globalStyles";

const PurchaseOrderSummaryPage = () => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const [informationView, setInformationView] = React.useState(false);
  const prevSubmitPurchaseOrderStatus = useRef();
  const [viewInfo, setViewInfo] = React.useState(null);
  const [editView, setEditView] = React.useState(false);
  const [detailView, setDetailView] = React.useState(false);
  const purchaseOrder = useSelector((state) => state.purchaseOrder);

  useEffect(() => {
    //set ref when first render
    prevSubmitPurchaseOrderStatus.current = false;
  }, []);

  // useEffect(() => {
  //   if (
  //     purchaseOrder.isLoadingPostSubmitPurchaseOrder !==
  //     prevSubmitPurchaseOrderStatus.current
  //   ) {
  //     //check the previous ref with current state
  //     prevSubmitPurchaseOrderStatus.current =
  //       purchaseOrder.isLoadingPostSubmitPurchaseOrder;

  //     if (
  //       purchaseOrder.postSubmitPurchaseOrderIsSuccess === true &&
  //       purchaseOrder.isLoadingPostSubmitPurchaseOrder === false
  //     ) {
  //       setEditView(false);
  //       setDetailView(true);
  //       setViewInfo(purchaseOrder.submitPurchaseOrder);
  //     }
  //   }
  // }, [purchaseOrder]);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      {!informationView && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title} variant="h3" gutterBottom>
              {t("translation:Purchase Receiving Summary")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <hr />

      <PurchaseOrderListTable
        setInformationView={setInformationView}
        viewInfo={viewInfo}
        setViewInfo={setViewInfo}
        editView={editView}
        setEditView={setEditView}
        detailView={detailView}
        setDetailView={setDetailView}
      />
    </Paper>
  );
};

export default PurchaseOrderSummaryPage;
