import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getFulfillmentSummaryList,
  putFulfillmentColourHexCode,
} from "../actions/fulfillmentActions";
import * as types from "../_constants/fulfillmentConstants";
import * as notification from "../_constants/notificationConstants";
import fulfillmentApis from "../_apis/fulfillmentApis";
export default function* fulfillmentSagas() {
  yield all([
    takeLatest(
      types.GET_FULFILLMENTSUMMARYLIST_ASYNC.PENDING,
      getFulfillmentSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_FULFILLMENTCOLOURHEXCODE_ASYNC.PENDING,
      putFulfillmentColourHexCodeCall
    ),
  ]);
}

function* getFulfillmentSummaryListCall(searchModel) {
  try {
    const data = yield call(
      fulfillmentApis.getFulfillmentSummaryListApi,
      searchModel.data
    );
    yield put(getFulfillmentSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getFulfillmentSummaryList.error(error.response?.data?.message));
    else
      yield put(getFulfillmentSummaryList.error(notification.CONST_NOINTERNET));
  }
}

function* putFulfillmentColourHexCodeCall(searchModel) {
  try {
    const data = yield call(
      fulfillmentApis.putFulfillmentColourHexCodeApi,
      searchModel.data
    );
    yield put(putFulfillmentColourHexCode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putFulfillmentColourHexCode.error(error.response?.data?.message)
      );
    else
      yield put(
        putFulfillmentColourHexCode.error(notification.CONST_NOINTERNET)
      );
  }
}
