import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/deliveryTrackingConstants";

export const getDeliveryTrackingList = {
  pending: (data) =>
    createAction(types.GET_DELIVERYTRACKINGLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYTRACKINGLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DELIVERYTRACKINGLIST_ASYNC.ERROR, { error }),
};

export const getDeliveryTrackingSummaryList = {
  pending: (data) =>
    createAction(types.GET_DELIVERYTRACKINGSUMMARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYTRACKINGSUMMARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DELIVERYTRACKINGSUMMARYLIST_ASYNC.ERROR, { error }),
};

export const getDeliveryTrackingById = {
  pending: (data) =>
    createAction(types.GET_DELIVERYTRACKINGBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYTRACKINGBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DELIVERYTRACKINGBYID_ASYNC.ERROR, { error }),
};

export const getDeliveryTrackingCourierList = {
  pending: (data) =>
    createAction(types.GET_DELIVERYTRACKINGCOURIERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYTRACKINGCOURIERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DELIVERYTRACKINGCOURIERLIST_ASYNC.ERROR, { error }),
};

export const getDeliveryTrackingCourierByBranch = {
  pending: (data) =>
    createAction(types.GET_DELIVERYTRACKINGCOURIERBYBRANCH_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_DELIVERYTRACKINGCOURIERBYBRANCH_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_DELIVERYTRACKINGCOURIERBYBRANCH_ASYNC.ERROR, {
      error,
    }),
};

export const putDeliveryTrackingWIPMode = {
  pending: (data) =>
    createAction(types.PUT_DELIVERYTRACKINGWIPMODE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_DELIVERYTRACKINGWIPMODE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_DELIVERYTRACKINGWIPMODE_ASYNC.ERROR, {
      error,
    }),
};

export const postDeliveryTracking = {
  pending: (data) =>
    createAction(types.POST_DELIVERYTRACKING_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_DELIVERYTRACKING_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_DELIVERYTRACKING_ASYNC.ERROR, { error }),
};

export const putDeliveryTrackingPendingStatus = {
  pending: (data) =>
    createAction(types.PUT_DELIVERYTRACKINGPENDINGSTATUS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_DELIVERYTRACKINGPENDINGSTATUS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_DELIVERYTRACKINGPENDINGSTATUS_ASYNC.ERROR, {
      error,
    }),
};

export const putDeliveryTrackingCloseStatus = {
  pending: (data) =>
    createAction(types.PUT_DELIVERYTRACKINGCLOSESTATUS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DELIVERYTRACKINGCLOSESTATUS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DELIVERYTRACKINGCLOSESTATUS_ASYNC.ERROR, { error }),
};

export const putDeliveryTrackingPrintDate = {
  pending: (data) =>
    createAction(types.PUT_DELIVERYTRACKINGPRINTDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DELIVERYTRACKINGPRINTDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DELIVERYTRACKINGPRINTDATE_ASYNC.ERROR, { error }),
};

export const putDeliveryTrackingUploadFile = {
  pending: (data) =>
    createAction(types.PUT_DELIVERYTRACKINGUPLOADFILE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DELIVERYTRACKINGUPLOADFILE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DELIVERYTRACKINGUPLOADFILE_ASYNC.ERROR, { error }),
};
