import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/postalCodeSurchargeConstants";

export const getPostalCodeSurchargeById = {
  pending: (data) =>
    createAction(types.GET_POSTALCODESURCHARGEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_POSTALCODESURCHARGEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_POSTALCODESURCHARGEBYID_ASYNC.ERROR, { error }),
};

export const getPostalCodeSurchargeList = {
  pending: (data) =>
    createAction(types.GET_POSTALCODESURCHARGELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_POSTALCODESURCHARGELIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_POSTALCODESURCHARGELIST_ASYNC.ERROR, { error }),
};

export const postCreatePostalCodeSurcharge = {
  pending: (data) =>
    createAction(types.POST_CREATEPOSTALCODESURCHARGE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEPOSTALCODESURCHARGE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEPOSTALCODESURCHARGE_ASYNC.ERROR, { error }),
};

export const putUpdatePostalCodeSurcharge = {
  pending: (data) =>
    createAction(types.PUT_UPDATEPOSTALCODESURCHARGE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEPOSTALCODESURCHARGE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEPOSTALCODESURCHARGE_ASYNC.ERROR, { error }),
};
