import React, { useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { Link } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Close from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import moment from "moment";
import MaximizeIcon from "@mui/icons-material/Maximize";
//components
import * as generalConstants from "../../../../_constants/generalConstants";
import { useStyles } from "../../../../components/globalStyles";

export default function OpeningHourModal({
  openModal,
  setOpenModal,
  dayweek,
  branchId,
  setScheduleHour,
  physician = null,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [endTimeError, setEndTimeError] = useState(false);
  const [openingHoursModal, setOpeningHoursModal] = useState({
    start_time: null,
    end_time: null,
  });
  const handleEndTimeOnBlur = () => {
    if (openingHoursModal.start_time && openingHoursModal.end_time) {
      if (openingHoursModal.end_time > openingHoursModal.start_time) {
        setEndTimeError(false);
      } else {
        setEndTimeError(true);
      }
    }
  };
  const handleAddNewHour = () => {
    if (openingHoursModal.end_time > openingHoursModal.start_time) {
      setScheduleHour((result) => {
        let newResult = [];
        if (physician) {
          newResult = [
            ...result,
            {
              id: 0,
              branch: { id: branchId },
              day_of_the_week: dayweek,
              start_time: openingHoursModal.start_time,
              end_time: openingHoursModal.end_time,
              physician: physician,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
            },
          ];
        } else {
          newResult = [
            ...result,
            {
              id: 0,
              branch: { id: branchId },
              day_of_the_week: dayweek,
              start_time: openingHoursModal.start_time,
              end_time: openingHoursModal.end_time,
              status_type: generalConstants.APPROVED_STATUS_TYPE,
            },
          ];
        }
        return newResult;
      });

      handleResetOpeningHoursModal();
    }
  };

  const handleResetOpeningHoursModal = () => {
    setOpeningHoursModal({
      start_time: null,
      end_time: null,
    });

    setEndTimeError(false);
    setOpenModal(false);
  };

  return (
    <Dialog
      open={openModal}
      maxWidth="xs"
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleResetOpeningHoursModal();
        }
      }}
      aria-labelledby="confirm-dialog"
      fullWidth>
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => handleResetOpeningHoursModal()}
      />
      <form>
        <DialogTitle id="confirm-dialog">
          {t("translation:Add Opening Hours")}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} container>
            <Grid
              item
              xs={5}
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
              className={classes.userInfoField}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                required
                value={
                  openingHoursModal.start_time
                    ? openingHoursModal.start_time
                    : ""
                }
                className={classes.userFieldColor}
                type="time"
                variant="outlined"
                label={t("translation:Start Time")}
                fullWidth
                onBlur={(e) => handleEndTimeOnBlur()}
                onChange={(e) =>
                  setOpeningHoursModal({
                    ...openingHoursModal,
                    start_time: e.target.value
                      ? moment(e.target.value, "HH:mm").format("HH:mm:ss")
                      : null,
                  })
                }
              />
            </Grid>
            <Grid
              item
              xs={2}
              container
              justifyContent="center"
              direction="column"
              alignItems="center">
              <MaximizeIcon />
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
              xs={5}
              className={classes.userInfoField}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                required
                value={
                  openingHoursModal.end_time ? openingHoursModal.end_time : ""
                }
                className={classes.userFieldColor}
                type="time"
                variant="outlined"
                label={t("translation:End Time")}
                fullWidth
                onBlur={(e) => handleEndTimeOnBlur()}
                onChange={(e) =>
                  setOpeningHoursModal({
                    ...openingHoursModal,
                    end_time: e.target.value
                      ? moment(e.target.value, "HH:mm").format("HH:mm:ss")
                      : null,
                  })
                }
              />
            </Grid>
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center">
              {endTimeError && (
                <div className={classes.warningColor}>
                  <p>
                    {t("translation:End Time must be greater than Start Time.")}
                  </p>
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Link className={classes.boldMaroon075}>
            <Button
              onClick={(e) => {
                e.currentTarget.form.reportValidity();
                var checkVal = e.currentTarget.form.checkValidity();
                if (checkVal) {
                  handleAddNewHour();
                }
              }}
              className={classes.boldMaroon075}>
              {t("translation:Yes")}
            </Button>
          </Link>

          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              onClick={() => {
                handleResetOpeningHoursModal();
              }}>
              {t("translation:No")}
            </Button>
          </Link>
        </DialogActions>
      </form>
    </Dialog>
  );
}
