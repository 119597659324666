import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const invoiceApis = {
  putInvoiceWIPModeApi,
  putInvoicePendingStatusApi,
  getInvoiceListApi,
  getInvoiceSummaryListApi,
  getInvoiceByIdApi,
  getInvoiceByOrderNumApi,
  postSubmitInvoiceApi,
  postCreateInvoiceByIdApi,
  putInvoicePrintDateApi,
  postOverrideInvoiceAmountApi,
  postInvoiceWhatsappPDFWTemplateApi,
  postInvoiceWhatsappAttachmentsApi,
  postInvoiceWhatsappAttachmentsWTemplateApi,
  getInvoiceCurrentOrganisationApi,
  getInvoiceOrganisationTwilioApi,
  getInvoiceTwillioTemplateApi,
  getInvoiceFileAttachmentNotificationApi,
  postInvoiceS3CopyObjectApi,
};

async function putInvoiceWIPModeApi(model) {
  const url = apiConstants.apiPutInvoiceWIPMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function putInvoicePendingStatusApi(model) {
  const url = apiConstants.apiPutInvoicePendingStatus + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetInvoiceList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetInvoiceSummaryList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceByIdApi(model) {
  const url = apiConstants.apiGetInvoiceById + model;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceByOrderNumApi(model) {
  const url = apiConstants.apiGetInvoiceByOrderNum + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitInvoiceApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostSubmitInvoice, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreateInvoiceByIdApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostCreateInvoiceById, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putInvoicePrintDateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(apiConstants.apiPutInvoicePrintDate, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postOverrideInvoiceAmountApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostOverrideInvoiceAmount,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postInvoiceWhatsappPDFWTemplateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostInvoiceWhatsappPDFWTemplate,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postInvoiceWhatsappAttachmentsApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostInvoiceWhatsappAttachments,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postInvoiceWhatsappAttachmentsWTemplateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostInvoiceWhatsappAttachmentsWTemplate,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceOrganisationTwilioApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetInvoiceOrganisationTwilio,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceTwillioTemplateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetInvoiceTwillioTemplate,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceFileAttachmentNotificationApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetInvoiceFileAttachmentNotification,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceCurrentOrganisationApi() {
  const url = apiConstants.apiGetInvoiceCurrentOrganisation;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postInvoiceS3CopyObjectApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostInvoiceS3CopyObject,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default invoiceApis;
