import "./App.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./router/routes";
import AppErrorBoundary from "./pages/errors/AppErrorBoundary";
import SocketProvider from "./components/functions/SocketProvider";
import GlodalThemeWrapper from "./components/GlodalThemeWrapper";
// import CacheBuster from "react-cache-buster";
// import { version } from "../package.json";

//import Loading from "./components/Loading";

import configureStore from "./store/store";
//refresh and no go to home page
const { persistor, store } = configureStore();
export const history = createBrowserHistory();

const App = () => (
  // <CacheBuster
  //   currentVersion={version}
  //   isEnabled={true} //If false, the library is disabled.
  //   isVerboseMode={false} //If true, the library writes verbose logs to console.
  //   loadingComponent={<Loading />}
  // >
  //    </CacheBuster>
  <GlodalThemeWrapper>
    <AppErrorBoundary>
      <SocketProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Router history={history}>
              <LastLocationProvider>
                <Routes />
              </LastLocationProvider>
            </Router>
          </PersistGate>
        </Provider>
      </SocketProvider>
    </AppErrorBoundary>
  </GlodalThemeWrapper>
);

export default App;
