//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconButton, Grid, Collapse, Button, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import moment from "moment";
//actions
import {
  postBranchPhysicianAdhocScheduleByBranchIdPhysicianId,
  getBranchPhysicianAdhocScheduleList,
  getBranchPhysicianAdhocSchedulePhysicianList,
} from "../../../../actions/branchPhysicianAdhocScheduleActions";
//components
import Panel from "../../../../components/Panel";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { ReactComponent as RemoveLogo } from "../../../../assets/coolicons/basic/trash_empty.svg";
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstant from "../../../../_constants/generalConstants";
import AddAdhocModal from "../components/AddAdhocModal";
import { useStyles } from "../../../../components/globalStyles";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import * as generalConstants from "../../../../_constants/generalConstants";

export const EditBranchPhysicianAdhocSchedulePage = ({
  branchId,
  setEditView,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const prevBranchPhysicianAdhocScheduleStatus = useRef();
  const [openModal, setOpenModal] = useState(false);
  const branchPhysicianAdhocSchedule = useSelector(
    (state) => state.branchPhysicianAdhocSchedule
  );
  const common = useSelector((state) => state.common);
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [physician, setPhysician] = useState(null);
  const [editAdhocSchedule, setEditAdhocSchedule] = useState(null);
  const [msgAlarm, setMsgAlarm] = useState("");
  const [confirmDeleteSubmit, setConfirtemDeleSubmit] = useState(false);
  const [deleteAdhocSchedule, setDeleteAdhocSchedule] = useState(null);
  const [searchValue, setSearchValue] = React.useState("");

  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,

    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_branch_desc_value: null,
    search_branch_id_value: branchId,
    search_branch_name_value: null,
    search_desc_value: null,
    search_name_value: null,
    search_physician_id_value: null,
    search_physician_name_value: null,
    search_status_type_value: null,
  });

  const columns = [
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <Button onClick={() => handleRowEdit(row.original)}>
          <EditLogo title={t("translation:Edit")} />
        </Button>
      ),
    },
    {
      Header: "",
      accessor: "Delete",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          {row.original.status_type !== generalConstant.DELETED_STATUS_TYPE && (
            <Button onClick={() => onDeleteSubmit(row.original)}>
              <RemoveLogo title={t("translation:Delete")} />
            </Button>
          )}
        </>
      ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Description"),
      accessor: "desc",
    },
    {
      Header: t("translation:Closed All Day"),
      sortable: false,
      accessor: (d) => {
        if (d.is_closed_all_day) return "Yes";
        else return "";
      },
    },
    {
      Header: t("translation:Start Time"),
      sortable: false,
      accessor: (d) => {
        if (d.start_time)
          return moment(d.start_time, "HH:mm:ss").format("HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:End Time"),
      sortable: false,
      accessor: (d) => {
        if (d.end_time) return moment(d.end_time, "HH:mm:ss").format("HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Adhoc Date"),
      sortable: false,
      accessor: (d) => {
        if (d.adhoc_date) return moment(d.adhoc_date).format("DD-MMM-YYYY");
        else return "";
      },
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];
  //functions
  const handleOnChangePhysician = (value) => {
    setPhysician(value);
    setPageInfo({
      ...pageInfo,
      search_physician_id_value: value.user.id,
    });
  };

  const onDeleteSubmit = (value) => {
    setConfirtemDeleSubmit(true);
    setDeleteAdhocSchedule(value);
  };

  const handlePostBranchPhysicianAdhocSchedule = (result) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const jsonText = {
      branch_physician_adhoc_schedules: result,
      branchId: branchId,
      physicianId: physician.user.id,
    };

    dispatch(
      postBranchPhysicianAdhocScheduleByBranchIdPhysicianId.pending(jsonText)
    );
  };

  const handleRowDelete = () => {
    const jsonText = {
      branch_physician_adhoc_schedules: [
        {
          ...deleteAdhocSchedule,
          status_type: generalConstant.DELETED_STATUS_TYPE,
        },
      ],
      branchId: branchId,
      physicianId: physician.user.id,
    };

    dispatch(
      postBranchPhysicianAdhocScheduleByBranchIdPhysicianId.pending(jsonText)
    );
  };

  const handleRowEdit = (value) => {
    setEditAdhocSchedule(value);
    setOpenModal(true);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_name_value: value,
        search_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: "",
        search_desc_value: "",
      });
    }
  };

  useEffect(() => {
    //set ref when first render
    prevBranchPhysicianAdhocScheduleStatus.current = false;
  }, []);

  useEffect(() => {
    if (physician) {
      dispatch(getBranchPhysicianAdhocScheduleList.pending(pageInfo));
    }
  }, [physician, pageInfo, dispatch]);

  useEffect(() => {
    if (
      branchPhysicianAdhocSchedule.isLoadingPostBranchPhysicianAdhocScheduleByBranchId !==
      prevBranchPhysicianAdhocScheduleStatus.current
    ) {
      //check the previous ref with current state
      prevBranchPhysicianAdhocScheduleStatus.current =
        branchPhysicianAdhocSchedule.isLoadingPostBranchPhysicianAdhocScheduleByBranchId;

      if (
        branchPhysicianAdhocSchedule.postBranchPhysicianAdhocScheduleByBranchIdIdIsSuccess ===
          true &&
        branchPhysicianAdhocSchedule.isLoadingPostBranchPhysicianAdhocScheduleByBranchId ===
          false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        dispatch(getBranchPhysicianAdhocScheduleList.pending(pageInfo));
      }
      if (
        branchPhysicianAdhocSchedule.postBranchPhysicianAdhocScheduleByBranchIdIdIsSuccess ===
          false &&
        branchPhysicianAdhocSchedule.isLoadingPostBranchPhysicianAdhocScheduleByBranchId ===
          false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(
          branchPhysicianAdhocSchedule.error
            .postBranchPhysicianAdhocScheduleByBranchIdError
        );
      }
    }
  }, [branchPhysicianAdhocSchedule, dispatch, pageInfo]);

  useEffect(() => {
    dispatch(
      getBranchPhysicianAdhocSchedulePhysicianList.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );
  }, [dispatch]);

  return (
    <Panel heading={t("translation:Edit Branch Physician Adhoc Schedule")}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmDeleteSubmit}
        setOpen={setConfirtemDeleSubmit}
        onConfirm={handleRowDelete}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Branch Physician Adhoc Schedule has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      {branchPhysicianAdhocSchedule.getBranchPhysicianAdhocSchedulePhysicianListIsSuccess && (
        <Grid xs={12} item className={classes.userInfoField}>
          <Autocomplete
            name="Physician"
            disableClearable
            value={physician}
            options={
              branchPhysicianAdhocSchedule.branchPhysicianAdhocSchedulePhysicianList &&
              Object.keys(
                branchPhysicianAdhocSchedule
                  .branchPhysicianAdhocSchedulePhysicianList
                  .organisation_physician
              ).length !== 0
                ? branchPhysicianAdhocSchedule.branchPhysicianAdhocSchedulePhysicianList.organisation_physician.sort(
                    (a, b) => -b.user.name.localeCompare(a.user.name)
                  )
                : []
            }
            getOptionLabel={(option) =>
              option.user.name ? option.user.name : ""
            }
            isOptionEqualToValue={(option, value) => {
              if (option.id === value.id) return option;
            }}
            style={{ width: "100%" }}
            onChange={(e, option) => {
              handleOnChangePhysician(option);
            }}
            renderInput={(params) => (
              <TextField
                required
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Physician")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )}
      {physician && (
        <>
          <Grid className={classes.root} container justifyContent="flex-end">
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonColor}
                onClick={() => {
                  setEditAdhocSchedule(null);
                  setOpenModal(true);
                }}>
                {t("translation:Add")}
              </Button>
            </Grid>
          </Grid>
          {openModal && (
            <AddAdhocModal
              openModal={openModal}
              setOpenModal={setOpenModal}
              physician={physician.user}
              branchId={branchId}
              editAdhocSchedule={editAdhocSchedule}
              setEditAdhocSchedule={setEditAdhocSchedule}
              handlePostBranchAdhocSchedule={
                handlePostBranchPhysicianAdhocSchedule
              }
            />
          )}
          <SortingSearchTable
            tableHeading={""}
            searchPlaceholder={t("translation:Name/ Description")}
            searchValue={searchValue}
            columns={columns}
            data={
              branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                ?.branch_physician_adhoc_schedules !== undefined
                ? branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                    ?.branch_physician_adhoc_schedules
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                ? branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                    .total_pages
                : 0
            }
            loading={
              branchPhysicianAdhocSchedule.isLoadingBranchPhysicianAdhocScheduleList
            }
            totalItems={
              branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                ? branchPhysicianAdhocSchedule.branchPhysicianAdhocScheduleList
                    .total_items
                : 0
            }
            handleSearch={handleSearch}
          />
        </>
      )}
      <Grid className={classes.root} container>
        <PageOnlyBackAction setView={() => setEditView(false)} />
      </Grid>
    </Panel>
  );
};
