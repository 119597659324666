import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import UserMedicineFixedSellingPriceSummaryPage from "./UserMedicineFixedSellingPriceSummary/UserMedicineFixedSellingPriceSummaryPage";
export default function UserMedicineFixedSellingPricePage() {
  return (
    <Switch>
      <Route
        path="/userMedicineFixedSellingPrice/userMedicineFixedSellingPriceSummary"
        component={UserMedicineFixedSellingPriceSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
