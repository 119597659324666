import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const wikiDataApis = {
  getWikiDataListApi,
  getWikiDataByIdApi,
  postCreateWikiDataApi,
  putUpdateWikiDataApi,
  getWikiMedicineListApi,
  getWikiMedicineTypesApi,
  getWikiTreatmentListApi,
  getWikiTreatmentTypesApi,
  getWikiCustomWordListApi,
  getWikiAcupointListApi,
};

async function getWikiDataListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);

  return await axios
    .post(apiConstants.apiGetWikiDataList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getWikiDataByIdApi(model) {
  const url = apiConstants.apiGetWikiDataById + model.id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreateWikiDataApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiPostCreateWikiData, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putUpdateWikiDataApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(apiConstants.apiPutUpdateWikiData, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getWikiAcupointListApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiGetWikiAcupointList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getWikiCustomWordListApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiGetWikiCustomWordList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getWikiTreatmentTypesApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiGetWikiTreatmentTypes, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getWikiTreatmentListApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiGetWikiTreatmentList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getWikiMedicineTypesApi(model) {
  return await axios
    .get(apiConstants.apiGetWikiMedicineTypes, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getWikiMedicineListApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiGetWikiMedicineList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default wikiDataApis;
