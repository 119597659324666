//lib
import React, { useEffect } from "react";

import { usePDF } from "@react-pdf/renderer";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";

import moment from "moment";
// //components
// import NotoSansHansRegular from "./NotoSansHans-Regular-2.ttf";

//style

// Font.register({
//   family: "NotoSansSChinese",
//   fonts: [{ src: NotoSansHansRegular, fontWeight: 300 }],
// });
// Font.registerHyphenationCallback((word) =>
//   word.length === 1 ? [word] : Array.from(word).map((char) => char)
// );

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 40,
  },

  flexRow1: {
    flexDirection: "row",
    alignItems: "center",
  },
  flexRow2: {
    flexDirection: "row",

    left: "3mm",
    right: "3mm",
    bottom: "10mm",
    position: "absolute",
  },
  flexRow3: {
    flexDirection: "row",

    left: "3.5mm",
    right: "3mm",
    bottom: "3mm",
    position: "absolute",
  },
  text1: {
    fontSize: "4mm",
    fontFamily: "NotoSansSChinese",
    fontWeight: "bold",
  },
  text2: {
    fontSize: "5mm",
    fontFamily: "NotoSansSChinese",
    fontWeight: "bold",
  },
  text3: {
    fontSize: "4mm",
    fontFamily: "NotoSansSChinese",
    paddingBottom: "5mm",
  },
  text4: {
    fontSize: "4mm",
    fontFamily: "NotoSansSChinese",
    paddingBottom: "3mm",
  },

  flexColumn1: {
    flexDirection: "column",
    textAlign: "center",
    alignContent: "center",
  },

  flexColumn2: {
    flexDirection: "column",
    textAlign: "center",
    alignContent: "center",
    width: "70%",
  },

  flexColumn3: {
    flexDirection: "column",
    padding: "3mm",
  },

  flexColumn4: {
    flexDirection: "column",
    textAlign: "center",
    alignContent: "center",
    width: "40%",
  },
  flexColumn5: {
    flexDirection: "column",
    textAlign: "center",
    alignContent: "center",
    width: "20%",
  },

  maroon075: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,0.75)",
  },
});

const PDFPage = ({
  orderDetailInfo,

  organisationInfo,
  visitNotesDetails,
}) => {
  const mcDate = orderDetailInfo.arrival_on
    ? moment.utc(orderDetailInfo.arrival_on).local().format("DD-MMM-YYYY")
    : moment.utc(orderDetailInfo.created_on).local().format("DD-MMM-YYYY");

  return (
    <Document>
      <Page size="A5" orientation="landscape" style={styles.body}>
        {orderDetailInfo.organisation.is_freelance_organisation !== true && (
          <View style={styles.flexColumn1} fixed>
            <Text style={styles.text2} fixed>
              {orderDetailInfo.visit_branch.desc}
            </Text>

            {orderDetailInfo.visit_branch.postal_code &&
            orderDetailInfo.visit_branch.blk_no &&
            orderDetailInfo.visit_branch.road_name ? (
              <Text style={styles.text1} fixed>
                {orderDetailInfo.visit_branch.blk_no}{" "}
                {orderDetailInfo.visit_branch.road_name}{" "}
                {orderDetailInfo.visit_branch.building
                  ? orderDetailInfo.visit_branch.building + " "
                  : null}
                {orderDetailInfo.visit_branch.address_details} SINGAPORE{" "}
                {orderDetailInfo.visit_branch.postal_code}
              </Text>
            ) : (
              <Text style={styles.text1} fixed>
                {orderDetailInfo.visit_branch.address}{" "}
                {orderDetailInfo.visit_branch.address_details}
              </Text>
            )}
            <Text style={styles.text1} fixed>
              Tel No: {orderDetailInfo.visit_branch.contact_number}
            </Text>
            {organisationInfo.organisations.gst_reg_no !== null && (
              <Text style={styles.text1} fixed>
                GST No: {organisationInfo.organisations.gst_reg_no}
              </Text>
            )}
          </View>
        )}

        {orderDetailInfo.organisation.is_freelance_organisation === true && (
          <View style={styles.flexColumn1} fixed>
            <Text style={styles.text2} fixed>
              {orderDetailInfo.physician.name}
            </Text>

            {orderDetailInfo.physician.postal_code &&
            orderDetailInfo.physician.blk_no &&
            orderDetailInfo.physician.road_name ? (
              <Text style={styles.text1} fixed>
                {orderDetailInfo.physician.blk_no}{" "}
                {orderDetailInfo.physician.road_name}{" "}
                {orderDetailInfo.physician.building
                  ? orderDetailInfo.physician.building + " "
                  : null}
                {orderDetailInfo.physician.address_details} SINGAPORE{" "}
                {orderDetailInfo.physician.postal_code}
              </Text>
            ) : (
              <Text style={styles.text1} fixed>
                {orderDetailInfo.physician.address}{" "}
                {orderDetailInfo.physician.address_details}
              </Text>
            )}
            <Text style={styles.text1} fixed>
              Tel No: {orderDetailInfo.physician.contact_number}
            </Text>
          </View>
        )}
        <View style={styles.flexRow1}>
          <View style={styles.flexColumn2}>
            <Text style={styles.text2}>诊断证明书 MEDICAL DIAGNOSIS</Text>

            <div
              style={{
                borderTop: "1px solid black",
                marginLeft: 40,
                marginRight: 20,
              }}></div>
          </View>
          <Text style={styles.text2}>No : {orderDetailInfo.order_number}</Text>
        </View>
        <View style={styles.flexColumn3} fixed>
          <Text style={styles.text3} fixed>
            Date :{mcDate}
          </Text>

          <Text style={styles.text4} fixed>
            This is to certify that :
          </Text>
          <Text style={styles.text4} fixed>
            Name{"       "}: {orderDetailInfo.staff_name}
          </Text>
          <Text style={styles.text4} fixed>
            NRIC{"         "}: {orderDetailInfo.staff_identification_number}
          </Text>

          <Text style={styles.text4} fixed>
            Reason{"    "}: {visitNotesDetails.medical_diagnosis_reason}
          </Text>
        </View>
        <View style={styles.flexRow2}>
          <View style={styles.flexColumn4}>
            {orderDetailInfo.visit_branch !== null && (
              <>
                <div
                  style={{
                    borderTop: "1px solid black",
                  }}></div>
                <Text style={styles.text4}>Medical Centre Stamp</Text>
              </>
            )}
          </View>
          <View style={styles.flexColumn5}></View>
          <View style={styles.flexColumn4}>
            <div
              style={{
                borderTop: "1px solid black",
              }}></div>
            <Text style={styles.text4}>
              Issued by : {orderDetailInfo.physician.name}
            </Text>
            <Text style={styles.text4}>
              Reg. No: {orderDetailInfo.physician.physician_license_no}
            </Text>
          </View>
        </View>
        <View style={styles.flexRow3}>
          <Text style={styles.text4}>
            This diagnosis is not valid for absence from court or other Judicial
            Proceedings, unless specifically stated.
          </Text>
        </View>
      </Page>
    </Document>
  );
};
let printNum = 0;
const VisitMdPDF = ({
  t,
  orderDetailInfo,
  visitNotesDetails,
  organisationInfo,
  printMd,
}) => {
  const [instance, updateInstance] = usePDF({
    document: (
      <PDFPage
        orderDetailInfo={orderDetailInfo}
        visitNotesDetails={visitNotesDetails}
        organisationInfo={organisationInfo}
      />
    ),
  });

  useEffect(() => {
    if (!instance.loading && instance.url && printMd !== printNum) {
      printNum = printMd;
      window.open(instance.url, "_blank", "noreferrer");
    }
  }, [instance.loading, instance.url, printNum, printMd]);
  return <></>;
  // if (printMd !== printNum) {
  //   return (
  //     <BlobProvider
  //       document={
  //         <PDFPage
  //           orderDetailInfo={orderDetailInfo}
  //           visitNotesDetails={visitNotesDetails}
  //           organisationInfo={organisationInfo}
  //         />
  //       }>
  //       {({ blob, url, loading, error }) => {
  //         // var file = new blob();
  //         var fileURL = [];
  //         if (!loading && blob && printMd !== printNum) {
  //           printNum = printMd;
  //           fileURL = URL.createObjectURL(blob);
  //           window.open(fileURL, "_blank", "noreferrer");
  //         }

  //         // Do whatever you need with blob here
  //         return <></>;
  //       }}
  //     </BlobProvider>
  //   );
  // } else return <></>;
};

export default VisitMdPDF;
