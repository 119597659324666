import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getDeliveryList,
  postSubmitDelivery,
  putDeliveryPrintDate,
  getDeliveryDistrictDetailsList,
  getExportDeliveryDistrictDetailsList,
  getDeliveryDistrictDetailsSummaryList,
  getExportDeliveryDistrictDetailsSummaryList,
  getDeliveryPostalcodeDistrictList,
} from "../actions/deliveryActions";
import * as types from "../_constants/deliveryConstants";
import * as notification from "../_constants/notificationConstants";
import deliveryApis from "../_apis/deliveryApis";
export default function* deliverySagas() {
  yield all([
    takeLatest(types.GET_DELIVERYLIST_ASYNC.PENDING, getDeliveryListCall),
  ]);
  yield all([
    takeLatest(types.POST_SUBMITDELIVERY_ASYNC.PENDING, postSubmitDeliveryCall),
  ]);
  yield all([
    takeLatest(
      types.PUT_DELIVERYPRINTDATE_ASYNC.PENDING,
      putDeliveryPrintDateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_EXPORTDELIVERYDISTRICTDETAILSLIST_ASYNC.PENDING,
      getExportDeliveryDistrictDetailsListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYDISTRICTDETAILSLIST_ASYNC.PENDING,
      getDeliveryDistrictDetailsListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYDISTRICTDETAILSSUMMARYLIST_ASYNC.PENDING,
      getDeliveryDistrictDetailsSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_EXPORTDELIVERYDISTRICTDETAILSSUMMARYLIST_ASYNC.PENDING,
      getExportDeliveryDistrictDetailsSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYPOSTALCODEDISTRICTLIST_ASYNC.PENDING,
      getDeliveryPostalcodeDistrictListCall
    ),
  ]);
}

function* getDeliveryListCall(searchModel) {
  try {
    const data = yield call(deliveryApis.getDeliveryListApi, searchModel.data);
    yield put(getDeliveryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDeliveryList.error(error.response?.data?.message));
    else yield put(getDeliveryList.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitDeliveryCall(model) {
  try {
    const data = yield call(deliveryApis.postSubmitDeliveryApi, model.data);
    yield put(postSubmitDelivery.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitDelivery.error(error.response?.data?.message));
    else yield put(postSubmitDelivery.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryPrintDateCall(Model) {
  try {
    const data = yield call(deliveryApis.putDeliveryPrintDateApi, Model.data);
    yield put(putDeliveryPrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putDeliveryPrintDate.error(error.response?.data?.message));
    else yield put(putDeliveryPrintDate.error(notification.CONST_NOINTERNET));
  }
}

function* getDeliveryDistrictDetailsListCall(Model) {
  try {
    const data = yield call(
      deliveryApis.getDeliveryDistrictDetailsListApi,
      Model.data
    );
    yield put(getDeliveryDistrictDetailsList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryDistrictDetailsList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryDistrictDetailsList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getExportDeliveryDistrictDetailsListCall(Model) {
  try {
    const data = yield call(
      deliveryApis.getExportDeliveryDistrictDetailsListApi,
      Model.data
    );
    yield put(getExportDeliveryDistrictDetailsList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getExportDeliveryDistrictDetailsList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getExportDeliveryDistrictDetailsList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getDeliveryDistrictDetailsSummaryListCall(Model) {
  try {
    const data = yield call(
      deliveryApis.getDeliveryDistrictDetailsSummaryListApi,
      Model.data
    );
    yield put(getDeliveryDistrictDetailsSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryDistrictDetailsSummaryList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getDeliveryDistrictDetailsSummaryList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getExportDeliveryDistrictDetailsSummaryListCall(Model) {
  try {
    const data = yield call(
      deliveryApis.getExportDeliveryDistrictDetailsSummaryListApi,
      Model.data
    );
    yield put(getExportDeliveryDistrictDetailsSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getExportDeliveryDistrictDetailsSummaryList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getExportDeliveryDistrictDetailsSummaryList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getDeliveryPostalcodeDistrictListCall(Model) {
  try {
    const data = yield call(
      deliveryApis.getDeliveryPostalcodeDistrictListApi,
      Model.data
    );
    yield put(getDeliveryPostalcodeDistrictList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryPostalcodeDistrictList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryPostalcodeDistrictList.error(notification.CONST_NOINTERNET)
      );
  }
}
