import * as types from "../_constants/packingConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.PUT_PACKINGCLOSEMODE_ASYNC.PENDING:
      return {
        ...state,
        putPackingCloseModeSuccess: false,
        isLoadingPackingCloseMode: true,
        packingCloseMode: null,
      };

    case types.PUT_PACKINGCLOSEMODE_ASYNC.ERROR:
      return {
        ...state,
        putPackingCloseModeSuccess: false,
        isLoadingPackingCloseMode: false,
        packingCloseMode: null,
        error: { ...state.error, packingCloseModeError: action.error },
      };

    case types.PUT_PACKINGCLOSEMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putPackingCloseModeSuccess: true,
        isLoadingPackingCloseMode: false,
        packingCloseMode: action.data,
      };

    case types.PUT_PACKINGWIPMODE_ASYNC.PENDING:
      return {
        ...state,
        putPackingWIPModeSuccess: false,
        isLoadingPackingWIPMode: true,
        packingWIPMode: null,
      };

    case types.PUT_PACKINGWIPMODE_ASYNC.ERROR:
      return {
        ...state,
        putPackingWIPModeSuccess: false,
        isLoadingPackingWIPMode: false,
        packingWIPMode: null,
        error: { ...state.error, packingWIPModeError: action.error },
      };

    case types.PUT_PACKINGWIPMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putPackingWIPModeSuccess: true,
        isLoadingPackingWIPMode: false,
        packingWIPMode: action.data,
      };

    case types.PUT_PACKINGPENDINGSTATUS_ASYNC.PENDING:
      return {
        ...state,
        putPackingPendingStatusSuccess: false,
        isLoadingPackingPendingStatus: true,
        packingPendingStatus: null,
      };

    case types.PUT_PACKINGPENDINGSTATUS_ASYNC.ERROR:
      return {
        ...state,
        putPackingPendingStatusSuccess: false,
        isLoadingPackingPendingStatus: false,
        packingPendingStatus: null,
        error: { ...state.error, packingPendingStatusError: action.error },
      };

    case types.PUT_PACKINGPENDINGSTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        putPackingPendingStatusSuccess: true,
        isLoadingPackingPendingStatus: false,
        packingPendingStatus: action.data,
      };

    case types.GET_PACKINGLIST_ASYNC.PENDING:
      return {
        ...state,
        getPackingListSuccess: false,
        isLoadingPackingList: true,
        packingList: null,
      };

    case types.GET_PACKINGLIST_ASYNC.ERROR:
      return {
        ...state,
        getPackingListSuccess: false,
        isLoadingPackingList: false,
        packingList: null,
        error: { ...state.error, packingListError: action.error },
      };

    case types.GET_PACKINGLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getPackingListSuccess: true,
        isLoadingPackingList: false,
        packingList: action.data,
      };

    case types.GET_PACKINGSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getPackingSummaryListSuccess: false,
        isLoadingPackingSummaryList: true,
        packingSummaryList: null,
      };

    case types.GET_PACKINGSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getPackingSummaryListSuccess: false,
        isLoadingPackingSummaryList: false,
        packingSummaryList: null,
        error: { ...state.error, packingSummaryListError: action.error },
      };

    case types.GET_PACKINGSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getPackingSummaryListSuccess: true,
        isLoadingPackingSummaryList: false,
        packingSummaryList: action.data,
      };

    case types.GET_PACKINGBYID_ASYNC.PENDING:
      return {
        ...state,
        getPackingByIdSuccess: false,
        isLoadingPackingById: true,
        packingById: null,
      };

    case types.GET_PACKINGBYID_ASYNC.ERROR:
      return {
        ...state,
        getPackingByIdSuccess: false,
        isLoadingPackingById: false,
        packingById: null,
        error: { ...state.error, packingByIdError: action.error },
      };

    case types.GET_PACKINGBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getPackingByIdSuccess: true,
        isLoadingPackingById: false,
        packingById: action.data,
      };

    case types.GET_PACKINGBYORDERNUM_ASYNC.PENDING:
      return {
        ...state,
        getPackingByOrderNumSuccess: false,
        isLoadingPackingByOrderNum: true,
        packingByOrderNum: null,
      };

    case types.GET_PACKINGBYORDERNUM_ASYNC.ERROR:
      return {
        ...state,
        getPackingByOrderNumSuccess: false,
        isLoadingPackingByOrderNum: false,
        packingByOrderNum: null,
        error: { ...state.error, packingByOrderNumError: action.error },
      };

    case types.GET_PACKINGBYORDERNUM_ASYNC.SUCCESS:
      return {
        ...state,
        getPackingByOrderNumSuccess: true,
        isLoadingPackingByOrderNum: false,
        packingByOrderNum: action.data,
      };

    case types.POST_SUBMITPACKING_ASYNC.PENDING:
      return {
        ...state,
        postSubmitPackingSuccess: false,
        isLoadingSubmitPacking: true,
        submitPacking: null,
      };

    case types.POST_SUBMITPACKING_ASYNC.ERROR:
      return {
        ...state,
        postSubmitPackingSuccess: false,
        isLoadingSubmitPacking: false,
        submitPacking: null,
        error: { ...state.error, submitPackingError: action.error },
      };

    case types.POST_SUBMITPACKING_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitPackingSuccess: true,
        isLoadingSubmitPacking: false,
        submitPacking: action.data,
      };

    default:
      return state;
  }
};
