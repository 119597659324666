import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/appointmentConstants";

export const getAppointmentList = {
  pending: (data) =>
    createAction(types.GET_APPOINTMENTLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_APPOINTMENTLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_APPOINTMENTLIST_ASYNC.ERROR, { error }),
};

export const postSubmitAppointment = {
  pending: (data) =>
    createAction(types.POST_SUBMITAPPOINTMENT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITAPPOINTMENT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITAPPOINTMENT_ASYNC.ERROR, { error }),
};

export const postAppointmentPhysicians = {
  pending: (data) =>
    createAction(types.POST_APPOINTMENTPHYSICIANS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_APPOINTMENTPHYSICIANS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_APPOINTMENTPHYSICIANS_ASYNC.ERROR, { error }),
};

export const getAppointmentBranch = {
  pending: (data) =>
    createAction(types.GET_APPOINTMENTBRANCH_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_APPOINTMENTBRANCH_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_APPOINTMENTBRANCH_ASYNC.ERROR, { error }),
};

export const getAppointmentTimeslotList = {
  pending: (data) =>
    createAction(types.GET_APPOINTMENTTIMESLOTLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_APPOINTMENTTIMESLOTLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_APPOINTMENTTIMESLOTLIST_ASYNC.ERROR, { error }),
};

export const getAppointmentBranchPhysician = {
  pending: (data) =>
    createAction(types.GET_APPOINTMENTBRANCHPHYSICIAN_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_APPOINTMENTBRANCHPHYSICIAN_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_APPOINTMENTBRANCHPHYSICIAN_ASYNC.ERROR, { error }),
};

export const getAppointmentUserList = {
  pending: (data) =>
    createAction(types.GET_APPOINTMENTUSERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_APPOINTMENTUSERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_APPOINTMENTUSERLIST_ASYNC.ERROR, { error }),
};

export const getAppointmentTempUserList = {
  pending: (data) =>
    createAction(types.GET_APPOINTMENTTEMPUSERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_APPOINTMENTTEMPUSERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_APPOINTMENTTEMPUSERLIST_ASYNC.ERROR, { error }),
};
