//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import moment from "moment";

//Actions
import {
  getSystemScheduleList,
  getSystemScheduleById,
} from "../../../../actions/systemScheduleActions";

//Component

import { EditSystemSchedulePage } from "./EditSystemSchedulePage"; //old: EditUserRolePage
import { ReactComponent as EditLogo } from "../../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import * as pageConfigure from "../../../../_constants/pageConstants";
import * as generalConstants from "../../../../_constants/generalConstants";

export default function SystemScheduleListTable({ setInformationView }) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const common = useSelector((state) => state.common);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.ADMIN_SYSTEM_SCHEDULE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        updateAccess ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
      sortable: false,
    },
    {
      Header: t("translation:Message"),
      accessor: "message",
      sortable: false,
    },
    {
      Header: t("translation:Start Time"),
      accessor: "start_timestamp",
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.start_timestamp)
          return moment
            .utc(row.original.start_timestamp)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:End Time"),
      accessor: "end_timestamp",
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.end_timestamp)
          return moment
            .utc(row.original.end_timestamp)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Status"),
      sortable: false,
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_id_value: null,
    search_status_type_value: null,
    search_name_value: null,
  });

  const dispatch = useDispatch();

  const systemSchedule = useSelector((state) => state.systemSchedule);

  //Init
  const [editMsgView, setEditMsgView] = React.useState(false);
  const [msgDetailView, setMsgDetailView] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  //Onclick event
  const handleRowEdit = (value) => {
    dispatch(getSystemScheduleById.pending(value));
    setEditMsgView(true);
  };

  const handleRowDetail = (value) => {
    dispatch(getSystemScheduleById.pending(value));
    setMsgDetailView(true);
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_name_value: null,
      });
    }
  };

  //useEffects
  useEffect(() => {
    if (msgDetailView === true || editMsgView === true) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [msgDetailView, editMsgView, setInformationView]);

  useEffect(() => {
    if (!editMsgView && !msgDetailView) {
      dispatch(getSystemScheduleList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editMsgView, msgDetailView]);

  if (
    systemSchedule.getSystemScheduleListIsSuccess === false &&
    systemSchedule.isLoadingGetSystemScheduleList === false
  ) {
    return (
      <Alert severity="error">
        {systemSchedule.error.getSystemScheduleListError}
      </Alert>
    );
  } else if (common.generalStatusList && !editMsgView && !msgDetailView) {
    return (
      <SortingSearchTable
        tableHeading={""}
        searchPlaceholder={t("translation:Name")}
        searchValue={searchValue}
        columns={columns}
        statusPlaceholder={t("translation:Status Type")}
        statusOption={common.generalStatusList.status_general}
        banStatusIdList={[]}
        data={
          systemSchedule.getSystemScheduleList?.system_schedules !== undefined
            ? systemSchedule.getSystemScheduleList?.system_schedules
            : []
        }
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        pageCount={
          systemSchedule.getSystemScheduleList
            ? systemSchedule.getSystemScheduleList.total_pages
            : 0
        }
        loading={systemSchedule.isLoadingGetSystemScheduleList}
        totalItems={
          systemSchedule.getSystemScheduleList
            ? systemSchedule.getSystemScheduleList.total_items
            : 0
        }
        handleSearch={handleSearch}
      />
    );
  } else if (
    systemSchedule.getSystemScheduleById &&
    msgDetailView &&
    systemSchedule.getSystemScheduleByIdIsSuccess === true
  ) {
    return (
      <EditSystemSchedulePage
        systemScheduleByid={
          systemSchedule.getSystemScheduleById.system_schedule
        }
        setMsgDetailView={setMsgDetailView}
        viewOnly
      />
    );
  } else if (
    systemSchedule.getSystemScheduleById &&
    editMsgView &&
    systemSchedule.getSystemScheduleByIdIsSuccess === true
  ) {
    return (
      <EditSystemSchedulePage
        systemScheduleByid={
          systemSchedule.getSystemScheduleById.system_schedule
        }
        setMsgDetailView={setEditMsgView}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
