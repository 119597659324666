import * as generalConstants from "../../../../../_constants/generalConstants";
const submisionJSON = (
  editOrderInfo,
  amountHeader,
  price,
  discount,
  extraAmt,
  costingHeader,
  groupOption,
  acupunctureGroupOption,
  miscList,
  treatmentList,
  visitNotesDetails,
  payment,
  uploadFileObjects
) => {
  // storeHeaderCheck update the unit_selling_price and dosage
  const storeHeaderCheck = groupOption.map((groupOptionItem) => {
    let store_medicine_detailsList =
      groupOptionItem?.store_medicine_details?.map(
        (store_medicine_detailsItem) => {
          let medicineList = store_medicine_detailsItem;
          delete medicineList.sub_total;
          medicineList = {
            ...medicineList,
            dosage: medicineList.branch_medicine.medicines.dosage,
            unit_selling_price:
              medicineList.branch_medicine.medicines.unit_selling_price,
          };
          return medicineList;
        }
      );

    return {
      ...groupOptionItem,
      store_medicine_details: store_medicine_detailsList
        ? store_medicine_detailsList
        : [],
    };
  });
  const submission = {
    store_header: {
      order_number: editOrderInfo.order_number,
      id: editOrderInfo.id,
      is_consultation_price_included: true,
      ...amountHeader,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
      organisation: editOrderInfo.organisation,
      medicine_total_price: Number(price.medicineTab),
      medicine_discount_amt: Number(discount.medicineTab),
      medicine_extra_amt: Number(extraAmt.medicineTab),
      brewing_total_price_for_costing: Number(price.brewingFee),
      brewing_discount_amt: Number(discount.brewingFee),
      brewing_extra_amt: Number(extraAmt.brewingFee),
      delivery_total_price_for_costing: Number(price.deliveryFee),
      delivery_discount_amt: Number(discount.deliveryFee),
      delivery_extra_amt: Number(extraAmt.deliveryFee),
      acupuncture_discount_amt: Number(discount.acupuncturePrice),
      acupuncture_extra_amt: Number(extraAmt.acupuncturePrice),
      acupuncture_total_price: Number(price.acupuncturePrice),
      consultation_discount_amt: Number(discount.consultationFee),
      consultation_extra_amt: Number(extraAmt.consultationFee),
      consultation_total_price: Number(price.consultationFee),
      treatment_discount_amt: Number(discount.treatmentPrice),
      treatment_extra_amt: Number(extraAmt.treatmentPrice),
      treatment_total_price: Number(price.treatmentPrice),
      misc_discount_amt: Number(discount.miscPrice),
      misc_extra_amt: Number(extraAmt.miscPrice),
      misc_total_price: Number(price.miscPrice),
      medicine_price_for_costing: Number(price.medicineFee),
      vendor_total_gst_amount_for_costing: Number(price.vendorGstAmt),
      store_medicine_costing_headers: costingHeader,
      store_medicine_headers: storeHeaderCheck,
      visit_acupuncture_headers: acupunctureGroupOption,
      visit_misc_details: miscList,
      visit_treatment_details: treatmentList,
      visit_notes_details: [visitNotesDetails],
      staff: editOrderInfo.staff,
      physician: editOrderInfo.physician,
      referral_code: payment.referral_code,
      discount_in_percent: payment.discountInPrecent,
      payment_term: payment.paymentTerm,
      payment_method: payment.paymentMethod,
      visit_attachments: uploadFileObjects,
    },
  };
  return submission;
};

export default submisionJSON;
