import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/prescriptionMsgConstants";

export const getPrescriptionMsgById = {
  pending: (data) =>
    createAction(types.GET_PRESCRIPTIONMSGBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PRESCRIPTIONMSGBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_PRESCRIPTIONMSGBYID_ASYNC.ERROR, { error }),
};

export const getPrescriptionMsgList = {
  pending: (data) =>
    createAction(types.GET_PRESCRIPTIONMSGLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PRESCRIPTIONMSGLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_PRESCRIPTIONMSGLIST_ASYNC.ERROR, { error }),
};

export const postCreatePrescriptionMsg = {
  pending: (data) =>
    createAction(types.POST_CREATEPRESCRIPTIONMSG_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEPRESCRIPTIONMSG_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEPRESCRIPTIONMSG_ASYNC.ERROR, { error }),
};

export const putUpdatePrescriptionMsg = {
  pending: (data) =>
    createAction(types.PUT_UPDATEPRESCRIPTIONMSG_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEPRESCRIPTIONMSG_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEPRESCRIPTIONMSG_ASYNC.ERROR, { error }),
};
