import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MedicineFixedSellingPriceDetailsSummaryPage from "./MedicineFixedSellingPriceDetailsSummary/MedicineFixedSellingPriceDetailsSummaryPage";
export default function MedicineFixedSellingPriceDetailsPage() {
  return (
    <Switch>
      <Route
        path="/medicineFixedSellingPriceDetails/medicineFixedSellingPriceDetailsSummary"
        component={MedicineFixedSellingPriceDetailsSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
