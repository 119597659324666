//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { MuiColorInput, matchIsValidColor } from "mui-color-input";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { Typography } from "@mui/material";
//actions
import {
  getFulfillmentSummaryList,
  putFulfillmentColourHexCode,
} from "../../../actions/fulfillmentActions";
import { getWaterAmount } from "../../../actions/commonActions";
//components
import { SortingSearchTable } from "../../../components/SortingSearchTable";

import ConfirmDialog from "../../../components/ConfirmDialog";
import * as generalConstants from "../../../_constants/generalConstants";
export default function FulfillmentListTable() {
  //variables
  const { t } = useTranslation();

  const [pageInfo, setPageInfo] = useState({
    search_dispensary_wip_end_value: moment()
      .add(1, "days")
      .format("YYYY-MM-DD"),
    search_dispensary_wip_start_value: moment().format("YYYY-MM-DD"),
  });

  const dispatch = useDispatch();
  const fulfillment = useSelector((state) => state.fulfillment);
  const common = useSelector((state) => state.common);

  const [searchValue, setSearchValue] = React.useState("");
  const prevSetColorStatus = useRef();
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = React.useState([]);
  const [editColorPickerOpen, setEditColorPickerOpen] = useState(false);
  const [colorValueJson, setColorValueJson] = React.useState(null);

  //functions

  const handleEditColorPicker = (newValue) => {
    setEditColorPickerOpen(true);
    setColorValueJson(() => {
      return {
        id: newValue.id,
        row_color_hex_code: newValue.row_color_hex_code
          ? newValue.row_color_hex_code
          : "#000000",
      };
    });
  };

  const handleChangeColorPicker = (newValue) => {
    if (matchIsValidColor(newValue)) {
      setColorValueJson((curVal) => {
        return {
          id: curVal.id,
          row_color_hex_code: newValue,
        };
      });
    }
  };

  const handleConfirmColorPicker = () => {
    dispatch(
      putFulfillmentColourHexCode.pending({ dispensary_header: colorValueJson })
    );
  };

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_FULFILLMENT_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: t("translation:S/N"),
      accessor: "COL_NAME_1",

      //adjust sort
      sortable: false,
    },
    {
      textAlign: "left",
      id: "colorpicker",
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        if (updateAccess) {
          return (
            <Button
              onClick={() =>
                handleEditColorPicker(row.original.dispensary_header)
              }>
              <ColorLensIcon
                sx={{ color: "rgba(77, 77, 77)" }}
                title={t("translation:Color Picker")}
              />
            </Button>
          );
        } else return <></>;
      },
    },
    {
      Header: t("translation:Staff Name"),
      accessor: "COL_NAME_2",

      //adjust sort
      sortable: false,
    },
    {
      Header: t("translation:Physician"),
      accessor: "COL_NAME_3",

      //adjust sort
      sortable: false,
    },
    {
      Header: t("translation:Medicine Type"),
      accessor: "COL_NAME_4",

      //adjust sort
      sortable: false,
    },
    {
      Header: t("translation:Amount of Water"),
      accessor: "COL_NAME_5",

      Cell: ({ row }) => {
        if (row.original.COL_NAME_5) {
          if (row.original.COL_NAME_5 !== generalConstants.WATERAMOUNT_200CC) {
            return (
              <Typography variant="h6">
                {
                  getOpObj(
                    row.original.COL_NAME_5,
                    common.waterAmountList.water_amount_select_field
                  ).name
                }
              </Typography>
            );
          } else {
            return getOpObj(
              row.original.COL_NAME_5,
              common.waterAmountList.water_amount_select_field
            ).name;
          }
        } else return "";
      },

      //adjust sort
      sortable: false,
    },

    {
      Header: t("translation:Packs"),
      accessor: "COL_NAME_6",

      //adjust sort
      sortable: false,
    },

    {
      Header: t("translation:Store Order No"),
      accessor: "COL_NAME_7",

      //adjust sort
      sortable: false,
    },

    {
      Header: t("translation:Link Delivery Services"),
      accessor: "COL_NAME_8",

      //adjust sort
      sortable: false,
    },

    {
      Header: t("translation:Delivery Closed Date"),
      accessor: "COL_NAME_9",

      Cell: ({ row }) => {
        if (row.original.COL_NAME_9)
          return moment
            .utc(row.original.COL_NAME_9)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },

      sortable: false,
    },

    {
      Header: t("translation:Courier"),
      accessor: "COL_NAME_10",

      //adjust sort
      sortable: false,
    },
  ];
  //useEffects
  useEffect(() => {
    prevSetColorStatus.current = null;
  }, []);

  useEffect(() => {
    dispatch(getWaterAmount.pending());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getFulfillmentSummaryList.pending(pageInfo));
  }, [dispatch, pageInfo]);

  useEffect(() => {
    if (
      fulfillment.isLoadingPutFulfillmentColourHexCode !==
      prevSetColorStatus.current
    ) {
      //check the previous ref with current state
      prevSetColorStatus.current =
        fulfillment.isLoadingPutFulfillmentColourHexCode;

      if (
        fulfillment.putFulfillmentColourHexCodeIsSuccess === true &&
        fulfillment.isLoadingPutFulfillmentColourHexCode === false
      ) {
        dispatch(getFulfillmentSummaryList.pending(pageInfo));
        window.scrollTo(0, 0);
      }
      if (
        fulfillment.putFulfillmentColourHexCodeIsSuccess === false &&
        fulfillment.isLoadingPutFulfillmentColourHexCode === false
      ) {
        setErrorAlarm(true);
        setMsgAlarm(fulfillment.error.putFulfillmentColourHexCodeError);
        window.scrollTo(0, 0);
      }
    }
  }, [fulfillment]);

  if (
    fulfillment.getFulfillmentSummaryListSuccess === false &&
    fulfillment.isLoadingGetFulfillmentSummaryList === false
  ) {
    return (
      <Alert severity="error">
        {fulfillment.error.getFulfillmentSummaryListError}
      </Alert>
    );
  } else if (
    common.getGeneralStatusIsSuccess === true &&
    common.waterAmountList !== null
  ) {
    return (
      <>
        {editColorPickerOpen && (
          <ConfirmDialog
            Yes={t("translation:Yes")}
            No={t("translation:No")}
            title={t("translation:Edit Row Color")}
            open={editColorPickerOpen}
            setOpen={setEditColorPickerOpen}
            onConfirm={handleConfirmColorPicker}>
            <MuiColorInput
              format="hex"
              readOnly
              fullWidth
              value={
                colorValueJson?.row_color_hex_code
                  ? colorValueJson?.row_color_hex_code
                  : "#000000"
              }
              onChange={handleChangeColorPicker}
            />
          </ConfirmDialog>
        )}

        <Collapse in={errorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        <SortingSearchTable
          tableHeading=""
          disabledSearch
          disabledFooter
          searchValue={searchValue}
          columns={columns}
          data={
            fulfillment.getFulfillmentSummaryList
              ? fulfillment.getFulfillmentSummaryList.result
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={0}
          loading={fulfillment.isLoadingFulfillmentSummaryList}
          totalItems={0}
          handleSearch={() => null}
          showSerialNo={false}
          searchDispensaryDateOnly={true}
          searchDispensaryDateOnlyPlaceholder={t("translation:Creation Date")}
        />
      </>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
