import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/cloudStorageConstants";

export const postCreatePresignedUrl = {
  pending: (data) =>
    createAction(types.POST_CREATEPRESIGNEDURL_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEPRESIGNEDURL_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEPRESIGNEDURL_ASYNC.ERROR, { error }),
};

export const postCreatePresignedJson = {
  pending: (data) =>
    createAction(types.POST_CREATEPRESIGNEDJSON_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEPRESIGNEDJSON_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEPRESIGNEDJSON_ASYNC.ERROR, { error }),
};

export const postCreatePresignedUrlPhoto = {
  pending: (data) =>
    createAction(types.POST_CREATEPRESIGNEDURLPHOTO_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEPRESIGNEDURLPHOTO_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEPRESIGNEDURLPHOTO_ASYNC.ERROR, { error }),
};

export const postCreatePresignedJsonPhoto = {
  pending: (data) =>
    createAction(types.POST_CREATEPRESIGNEDJSONPHOTO_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEPRESIGNEDJSONPHOTO_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEPRESIGNEDJSONPHOTO_ASYNC.ERROR, { error }),
};

export const postCreatePresignedUrlSignature = {
  pending: (data) =>
    createAction(types.POST_CREATEPRESIGNEDURLSIGNATURE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_CREATEPRESIGNEDURLSIGNATURE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_CREATEPRESIGNEDURLSIGNATURE_ASYNC.ERROR, { error }),
};

export const postCreatePresignedJsonSignature = {
  pending: (data) =>
    createAction(types.POST_CREATEPRESIGNEDJSONSIGNATURE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_CREATEPRESIGNEDJSONSIGNATURE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_CREATEPRESIGNEDJSONSIGNATURE_ASYNC.ERROR, {
      error,
    }),
};

export const postCreatePresignedUrlOrganisationLogo = {
  pending: (data) =>
    createAction(types.POST_CREATEPRESIGNEDURLORGANISATIONLOGO_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_CREATEPRESIGNEDURLORGANISATIONLOGO_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_CREATEPRESIGNEDURLORGANISATIONLOGO_ASYNC.ERROR, {
      error,
    }),
};

export const postCreatePresignedJsonDeliveryTracking = {
  pending: (data) =>
    createAction(types.POST_CREATEPRESIGNEDJSONDELIVERYTRACKING_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_CREATEPRESIGNEDJSONDELIVERYTRACKING_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_CREATEPRESIGNEDJSONDELIVERYTRACKING_ASYNC.ERROR, {
      error,
    }),
};

export const postCreatePresignedJsonInvoice = {
  pending: (data) =>
    createAction(types.POST_CREATEPRESIGNEDJSONINVOICE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_CREATEPRESIGNEDJSONINVOICE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_CREATEPRESIGNEDJSONINVOICE_ASYNC.ERROR, {
      error,
    }),
};
