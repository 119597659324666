import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getEmailStoreAccountList,
  getEmailStoreAccountById,
  postCreateEmailStoreAccount,
  putUpdateEmailStoreAccount,
} from "../actions/emailStoreAccountActions";
import * as types from "../_constants/emailStoreAccountConstants";
import * as notification from "../_constants/notificationConstants";
import emailStoreAccountApis from "../_apis/emailStoreAccountApis";
export default function* emailStoreAccountSagas() {
  yield all([
    takeLatest(
      types.GET_EMAILSTOREACCOUNTLIST_ASYNC.PENDING,
      getEmailStoreAccountListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_EMAILSTOREACCOUNTBYID_ASYNC.PENDING,
      getEmailStoreAccountByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATEEMAILSTOREACCOUNT_ASYNC.PENDING,
      postCreateEmailStoreAccountCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEEMAILSTOREACCOUNT_ASYNC.PENDING,
      putUpdateEmailStoreAccountCall
    ),
  ]);
}

function* getEmailStoreAccountListCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.getEmailStoreAccountListApi,
      searchModel.data
    );
    yield put(getEmailStoreAccountList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getEmailStoreAccountList.error(error.response?.data?.message));
    else
      yield put(getEmailStoreAccountList.error(notification.CONST_NOINTERNET));
  }
}

function* getEmailStoreAccountByIdCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.getEmailStoreAccountByIdApi,
      searchModel.data
    );
    yield put(getEmailStoreAccountById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getEmailStoreAccountById.error(error.response?.data?.message));
    else
      yield put(getEmailStoreAccountById.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateEmailStoreAccountCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.postCreateEmailStoreAccountApi,
      searchModel.data
    );
    yield put(postCreateEmailStoreAccount.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postCreateEmailStoreAccount.error(error.response?.data?.message)
      );
    else
      yield put(
        postCreateEmailStoreAccount.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateEmailStoreAccountCall(searchModel) {
  try {
    const data = yield call(
      emailStoreAccountApis.putUpdateEmailStoreAccountApi,
      searchModel.data
    );
    yield put(putUpdateEmailStoreAccount.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateEmailStoreAccount.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdateEmailStoreAccount.error(notification.CONST_NOINTERNET)
      );
  }
}
