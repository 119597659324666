//lib
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
//actions
import { postLogOut } from "../../../actions/authActions";

export default function Auth2LoginPage() {
  //variables
  const dispatch = useDispatch();

  //useEffects
  //useEffect(() => {}, [dispatch]);

  return <>loading...</>;
}
