//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
//actions
import { putUpdateMedicineFixedSellingPrice } from "../../../../actions/medicineFixedSellingPriceActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";

export const EditMedicineFixedSellingPricePage = ({
  medicineFixedSellingPrice,
  setEditMedicineFixedSellingPriceView,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  const [msgAlarm, setMsgAlarm] = useState("");
  const medicineFixedSellingPriceData = useSelector(
    (state) => state.medicineFixedSellingPrice
  );
  const common = useSelector((state) => state.common);
  const prevSubmitStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };
  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const submitJson = {
      medicine_fixed_selling_price_header: {
        ...medicineFixedSellingPrice,
        ...data,
      },
    };
    delete submitJson.medicine_fixed_selling_price_header.organisation;
    dispatch(putUpdateMedicineFixedSellingPrice.pending(submitJson));
  };

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      id: medicineFixedSellingPrice?.id,
      name: medicineFixedSellingPrice?.name,
      status_type: medicineFixedSellingPrice?.status_type,
    },
  });
  useEffect(() => {
    //set ref when first render
    prevSubmitStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      medicineFixedSellingPriceData.isLoadingPutUpdateMedicineFixedSellingPrice !==
      prevSubmitStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitStatus.current =
        medicineFixedSellingPriceData.isLoadingPutUpdateMedicineFixedSellingPrice;

      if (
        medicineFixedSellingPriceData.putUpdateMedicineFixedSellingPriceIsSuccess ===
          true &&
        medicineFixedSellingPriceData.isLoadingPutUpdateMedicineFixedSellingPrice ===
          false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...medicineFixedSellingPriceData.putUpdateMedicineFixedSellingPrice
            .medicine_fixed_selling_price_header,
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        medicineFixedSellingPriceData.putUpdateMedicineFixedSellingPriceIsSuccess ===
          false &&
        medicineFixedSellingPriceData.isLoadingPutUpdateMedicineFixedSellingPrice ===
          false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(
          medicineFixedSellingPriceData.error
            .putUpdateMedicineFixedSellingPriceError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [medicineFixedSellingPriceData, reset]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:Medicine Fixed Selling Price Details")
          : t("translation:Edit Medicine Fixed Selling Price")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Medicine Fixed Selling Price has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <input hidden readOnly {...register("id")} />

        <Grid className={classes.root} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              required
              name="name"
              label={t("translation:Name")}
              variant="outlined"
              {...register("name")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly}
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>

          <>
            <RequiredNote />

            {temViewOnly ? (
              <PageOnlyBackAction
                setView={() => setEditMedicineFixedSellingPriceView(false)}
              />
            ) : (
              <PageSubmitWBackAction
                setView={() => setEditMedicineFixedSellingPriceView(false)}
              />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};
