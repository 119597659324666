//lib
import React, { useState, useEffect } from "react";
import { Button, Grid, Link, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Close from "@mui/icons-material/Close";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//components
import { SortingSearchTable } from "../../../../components/SortingSearchTable";

import { MultiFieldSortingSearchTable } from "../../../../components/MultiFieldSortingSearchTable";
import * as pageConfigure from "../../../../_constants/pageConstants";
import StyledRadioCheckbox from "../../../../components/StyledRadioCheckbox";
import { useStyles } from "../../../../components/globalStyles";
import noEnterSubmitForm from "../../../../components/functions/noEnterSubmitForm";
//actions
import { getAppointmentUserList } from "../../../../actions/appointmentActions";

//style

export default function SearchExistingPatient({ setPatientInfoField }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appointment = useSelector((state) => state.appointment);
  const [searchValue, setSearchValue] = React.useState({
    name: null,
    contactNo: null,
    dateofBirth: null,
    identificationNo: null,
  });
  const [patient, setPatient] = useState(null);
  const [saveConfirmAlarm, setConfirmErrorAlarm] = useState(false);

  const [open, setOpen] = useState(false);
  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });

  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_contact_number_value: null,
    search_date_of_birth_value: null,
    search_identification_number_value: null,
    search_name_value: null,
    search_username_value: null,
  });

  const handleSearch = (value) => {
    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      search_contact_number_value: searchValue.contactNo,
      search_date_of_birth_value: searchValue.dateofBirth,
      search_identification_number_value: searchValue.identificationNo,
      search_name_value: searchValue.name,
    });
  };

  const handlePatientCheck = (e, row) => {
    if (e.target.checked) {
      setPatient(row.original);
    } else {
      setPatient(null);
    }
  };

  const handlePatientConfirm = () => {
    if (patient) {
      setConfirmErrorAlarm(false);
      setOpen(false);
      const staff = { staff: patient };
      setPatientInfoField(staff);
    } else {
      setConfirmErrorAlarm(true);
    }
  };

  const columns = [
    {
      Header: "",
      accessor: "checkPatientRow",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <>
            <StyledRadioCheckbox
              checked={
                patient !== null && patient.id === row.original.id
                  ? true
                  : false
              }
              name="checkPatientRowName"
              onChange={(e) => handlePatientCheck(e, row)}
            />
          </>
        );
      },
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Contact Number"),
      accessor: "contact_number",
    },
    {
      Header: t("translation:Identification Number"),
      accessor: "identification_number",
    },
  ];
  const viewColumns = [
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Contact Number"),
      accessor: "contact_number",
    },
    {
      Header: t("translation:Identification Number"),
      accessor: "identification_number",
    },
  ];

  useEffect(() => {
    dispatch(getAppointmentUserList.pending(pageInfo));
  }, [dispatch, pageInfo]);

  useEffect(() => {
    //reset patient when dialog is opened
    if (open) {
      setPatient(null);
      setConfirmErrorAlarm(false);
    }
  }, [open]);

  return (
    <>
      <Grid item xs={12}>
        <Link className={classes.boldMaroon075}>
          <Button
            className={classes.boldMaroon075}
            onClick={() => setOpen(true)}>
            {t("translation:EXISTING PATIENT")}
          </Button>
        </Link>
      </Grid>

      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpen(false);
          }
        }}
        aria-labelledby="confirm-dialog"
        fullWidth
        maxWidth="xl">
        <Close
          style={{ position: "absolute", right: "15px" }}
          onClick={() => setOpen(false)}
        />
        <DialogContent>
          <Collapse in={saveConfirmAlarm}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setConfirmErrorAlarm(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {t("translation:Missing Patient")}
            </Alert>
          </Collapse>

          <form onKeyDown={(e) => noEnterSubmitForm(e)}>
            <Grid container>
              {patient && (
                <Grid item xs={12}>
                  <SortingSearchTable
                    disabledSearch
                    columns={viewColumns}
                    data={[patient]}
                    pageInfo={viewPageInfo}
                    setPageInfo={setViewPageInfo}
                    totalItems={1}
                    disabledFooter
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <MultiFieldSortingSearchTable
                  searchPlaceholder={""}
                  searchValue={searchValue}
                  columns={columns}
                  data={
                    appointment.appointmentUserList
                      ? appointment.appointmentUserList.users
                      : []
                  }
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                  pageCount={
                    appointment.appointmentUserList
                      ? appointment.appointmentUserList.total_pages
                      : 0
                  }
                  loading={appointment.isLoadingAppointmentUserList}
                  totalItems={
                    appointment.appointmentUserList
                      ? appointment.appointmentUserList.total_items
                      : 0
                  }
                  handleSearch={handleSearch}>
                  <TextField
                    className={classes.userFieldColor}
                    label={t("translation:Name")}
                    variant="outlined"
                    value={searchValue.name ? searchValue.name : ""}
                    onChange={(e) =>
                      setSearchValue({ ...searchValue, name: e.target.value })
                    }
                  />

                  <TextField
                    className={classes.userFieldColor}
                    label={t("translation:Contact Number")}
                    variant="outlined"
                    value={searchValue.contactNo ? searchValue.contactNo : ""}
                    onChange={(e) =>
                      setSearchValue({
                        ...searchValue,
                        contactNo: e.target.value,
                      })
                    }
                  />

                  <TextField
                    className={classes.userFieldColor}
                    label={t("translation:Identification Number")}
                    variant="outlined"
                    autoComplete="off"
                    value={
                      searchValue.identificationNo
                        ? searchValue.identificationNo
                        : ""
                    }
                    onChange={(e) =>
                      setSearchValue({
                        ...searchValue,
                        identificationNo: e.target.value,
                      })
                    }
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className={classes.userFieldColor}
                      inputVariant="outlined"
                      id="date"
                      label={t("translation:Date of Birth")}
                      autoOk={true}
                      slotProps={{
                        actionBar: {
                          actions: ["clear"],
                        },
                      }}
                      value={
                        searchValue.dateofBirth
                          ? dayjs(searchValue.dateofBirth)
                          : null
                      }
                      format="DD MMM YYYY"
                      onChange={(e) =>
                        setSearchValue({
                          ...searchValue,
                          dateofBirth: dayjs(e, true).isValid()
                            ? dayjs(e).format("YYYY-MM-DD")
                            : null,
                        })
                      }
                    />
                  </LocalizationProvider>
                </MultiFieldSortingSearchTable>
              </Grid>
            </Grid>

            <DialogActions>
              <Link className={classes.boldMaroon075}>
                <Button
                  onClick={() => {
                    handlePatientConfirm();
                    setOpen(false);
                  }}
                  className={classes.boldMaroon075}
                  cy_data="confirmButton">
                  {t("translation:Yes")}
                </Button>
              </Link>

              <Link className={classes.boldMaroon075}>
                <Button
                  className={classes.boldMaroon075}
                  onClick={() => setOpen(false)}
                  cy_data="rejectButton">
                  {t("translation:No")}
                </Button>
              </Link>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
