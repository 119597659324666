import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import EmailStoreSummaryPage from "./EmailStoreSummary/EmailStoreSummaryPage";
export default function EmailStorePage() {
  return (
    <Switch>
      <Route
        path="/emailStore/emailStoreSummary"
        component={EmailStoreSummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
