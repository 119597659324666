//lib
import React, { useEffect } from "react";

import { usePDF } from "@react-pdf/renderer";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";

import moment from "moment";
// //components
// import NotoSansHansRegular from "./NotoSansHans-Regular-2.ttf";

//style

// Font.register({
//   family: "NotoSansSChinese",
//   fonts: [{ src: NotoSansHansRegular, fontWeight: 300 }],
// });
// Font.registerHyphenationCallback((word) =>
//   word.length === 1 ? [word] : Array.from(word).map((char) => char)
// );

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  flexRow: {
    flexDirection: "row",
    padding: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  flexRow1: {
    flexDirection: "row",
    alignItems: "center",
  },
  text1: {
    fontSize: "4mm",
    fontFamily: "NotoSansSChinese",
    fontWeight: "bold",
    padding: 5,
  },
  text2: {
    fontSize: "5mm",
    fontFamily: "NotoSansSChinese",
    fontWeight: "bold",
  },
  text3: {
    fontSize: "3.5mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
  },

  pageNumber: {
    fontSize: "3mm",
    right: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  pagePrinted: {
    fontSize: "3mm",
    left: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  table: {
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    width: "100%",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1: {
    width: "7%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: "4.583%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol3: {
    width: "3%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableSummaryCol1: {
    width: "8%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableSummaryCol2: {
    width: "5.6%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableSummaryCol3: {
    width: "4%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCell: {
    fontSize: "2.5mm",
    fontFamily: "NotoSansSChinese",
    bottom: "-1mm",
    margin: "0.2mm",
    padding: "1mm",
  },

  maroon075: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,0.75)",
  },
  grey: {
    fontWeight: "bold",
    color: "rgba(77, 77, 77)",
  },
});

const DetailsTable = ({ orderDetailInfo, statusOption }) => {
  const Rows = orderDetailInfo.map((item, index) => {
    const receiptStatus = statusOption.find(
      (status) => status.id === item.receipt_header?.status_type
    );

    return (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tableCol3}>
          <Text style={styles.tableCell}>{index + 1}</Text>
        </View>
        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {moment
              .utc(item.store_header.created_on)
              .local()
              .format("DD-MMM-YYYY")}
          </Text>
        </View>
        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>{item.store_header.order_number}</Text>
        </View>

        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {item.store_header.physician_name}
          </Text>
        </View>
        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>{item.store_header.staff_name}</Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {(
              item.store_header.medicine_total_price -
              item.store_header.medicine_discount_amt +
              item.store_header.medicine_extra_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {(
              item.store_header.delivery_total_price_for_costing -
              item.store_header.delivery_discount_amt +
              item.store_header.delivery_extra_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {(
              item.store_header.brewing_total_price_for_costing -
              item.store_header.brewing_discount_amt +
              item.store_header.brewing_extra_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {(
              item.store_header.acupuncture_total_price -
              item.store_header.acupuncture_discount_amt +
              item.store_header.acupuncture_extra_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {(
              item.store_header.treatment_total_price -
              item.store_header.treatment_discount_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {(
              item.store_header.misc_total_price -
              item.store_header.misc_discount_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {(
              item.store_header.consultation_total_price -
              item.store_header.consultation_discount_amt +
              item.store_header.consultation_extra_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {item.store_header.amt_with_gst.toFixed(2)}
          </Text>
        </View>
        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {item.store_header.discount_in_percent}
          </Text>
        </View>
        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {item.invoice_details?.payment_amount.toFixed(2)}
          </Text>
        </View>
        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {" "}
            {item.receipt_header?.order_number}
          </Text>
        </View>
        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {receiptStatus ? receiptStatus.name : ""}
          </Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {item.invoice_details?.payment_method}
          </Text>
        </View>
        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>
            {" "}
            {item.store_header?.referral_code}
          </Text>
        </View>
      </View>
    );
  });

  return (
    <View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>No</Text>
          </View>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Date</Text>
          </View>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Order No</Text>
          </View>

          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Physician</Text>
          </View>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Patient</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Medicine</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Delivery</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Brewing</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Acupuncture</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Treatment</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Misc</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Consultation</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Amt(Inc. GST)</Text>
          </View>
          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Discount (%)</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Payment</Text>
          </View>

          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Receipt No</Text>
          </View>
          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Receipt Status</Text>
          </View>
          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Payment Method</Text>
          </View>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Referral code</Text>
          </View>
        </View>
        {Rows}
      </View>
    </View>
  );
};

const SummaryTable = ({ orderDetailInfo, statusOption }) => {
  const Rows = orderDetailInfo.map((item, index) => {
    return (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tableSummaryCol3}>
          <Text style={styles.tableCell}>{index + 1}</Text>
        </View>
        <View style={styles.tableSummaryCol1}>
          <Text style={styles.tableCell}>
            {moment
              .utc(item.store_header.created_on)
              .local()
              .format("DD-MMM-YYYY")}
          </Text>
        </View>
        <View style={styles.tableSummaryCol1}>
          <Text style={styles.tableCell}>{item.store_header.order_number}</Text>
        </View>

        <View style={styles.tableSummaryCol1}>
          <Text style={styles.tableCell}>
            {item.store_header.physician_name}
          </Text>
        </View>
        <View style={styles.tableSummaryCol1}>
          <Text style={styles.tableCell}>{item.store_header.staff_name}</Text>
        </View>

        <View style={styles.tableSummaryCol2}>
          <Text style={styles.tableCell}>
            {(
              item.store_header.medicine_total_price -
              item.store_header.medicine_discount_amt +
              item.store_header.medicine_extra_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableSummaryCol2}>
          <Text style={styles.tableCell}>
            {(
              item.store_header.delivery_total_price_for_costing -
              item.store_header.delivery_discount_amt +
              item.store_header.delivery_extra_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableSummaryCol2}>
          <Text style={styles.tableCell}>
            {(
              item.store_header.brewing_total_price_for_costing -
              item.store_header.brewing_discount_amt +
              item.store_header.brewing_extra_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableSummaryCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {(
              item.store_header.acupuncture_total_price -
              item.store_header.acupuncture_discount_amt +
              item.store_header.acupuncture_extra_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableSummaryCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {(
              item.store_header.treatment_total_price -
              item.store_header.treatment_discount_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableSummaryCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {(
              item.store_header.misc_total_price -
              item.store_header.misc_discount_amt
            ).toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableSummaryCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {(
              item.store_header.consultation_total_price -
              item.store_header.consultation_discount_amt +
              item.store_header.consultation_extra_amt
            ).toFixed(2)}
          </Text>
        </View>
        <View style={styles.tableSummaryCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {item.store_header.discount_in_percent}
          </Text>
        </View>

        <View style={styles.tableSummaryCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {item.store_header.amt_with_gst.toFixed(2)}
          </Text>
        </View>

        <View style={styles.tableSummaryCol2}>
          <Text style={styles.tableCell}>
            {" "}
            {item.store_header.total_payment_amount.toFixed(2)}
          </Text>
        </View>
        <View style={styles.tableSummaryCol1}>
          <Text style={styles.tableCell}>
            {item.store_header.referral_code}
          </Text>
        </View>
      </View>
    );
  });

  return (
    <View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableSummaryCol3}>
            <Text style={styles.tableCell}>No</Text>
          </View>
          <View style={styles.tableSummaryCol1}>
            <Text style={styles.tableCell}>Date</Text>
          </View>
          <View style={styles.tableSummaryCol1}>
            <Text style={styles.tableCell}>Order No</Text>
          </View>

          <View style={styles.tableSummaryCol1}>
            <Text style={styles.tableCell}>Physician</Text>
          </View>
          <View style={styles.tableSummaryCol1}>
            <Text style={styles.tableCell}>Patient</Text>
          </View>

          <View style={styles.tableSummaryCol2}>
            <Text style={styles.tableCell}>Medicine</Text>
          </View>

          <View style={styles.tableSummaryCol2}>
            <Text style={styles.tableCell}>Delivery</Text>
          </View>

          <View style={styles.tableSummaryCol2}>
            <Text style={styles.tableCell}>Brewing</Text>
          </View>

          <View style={styles.tableSummaryCol2}>
            <Text style={styles.tableCell}>Acupuncture</Text>
          </View>

          <View style={styles.tableSummaryCol2}>
            <Text style={styles.tableCell}>Treatment</Text>
          </View>

          <View style={styles.tableSummaryCol2}>
            <Text style={styles.tableCell}>Misc</Text>
          </View>

          <View style={styles.tableSummaryCol2}>
            <Text style={styles.tableCell}>Consultation</Text>
          </View>

          <View style={styles.tableSummaryCol2}>
            <Text style={styles.tableCell}>Discount (%)</Text>
          </View>

          <View style={styles.tableSummaryCol2}>
            <Text style={styles.tableCell}>Amt(Inc. GST)</Text>
          </View>

          <View style={styles.tableSummaryCol2}>
            <Text style={styles.tableCell}>Total Payment Amount</Text>
          </View>
          <View style={styles.tableSummaryCol1}>
            <Text style={styles.tableCell}>Referral Code</Text>
          </View>
        </View>
        {Rows}
      </View>
    </View>
  );
};

const PDFPage = ({
  orderDetailInfo,
  statusOption,
  pageInfo,
  title,
  summaryMode,
}) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.body}>
        <View style={styles.flexRow}>
          <Text style={styles.text2}>{title}</Text>
        </View>
        <View style={styles.flexRow1}>
          <Text style={styles.text1}>{`Period  `}</Text>
          <Text style={styles.text3}>{`${
            moment(pageInfo.search_start_date_value).format("DD-MMM-YYYY") !==
            "Invalid date"
              ? moment(pageInfo.search_start_date_value).format("DD-MMM-YYYY")
              : ""
          }`}</Text>
          <Text style={styles.text1}>{` to`}</Text>
          <Text style={styles.text3}>{`${
            moment(pageInfo.search_end_date_value).format("DD-MMM-YYYY") !==
            "Invalid date"
              ? moment(pageInfo.search_end_date_value).format("DD-MMM-YYYY")
              : ""
          }`}</Text>
        </View>
        {summaryMode ? (
          <SummaryTable
            orderDetailInfo={orderDetailInfo}
            statusOption={statusOption}
          />
        ) : (
          <DetailsTable
            orderDetailInfo={orderDetailInfo}
            statusOption={statusOption}
          />
        )}

        <Text style={styles.pagePrinted} fixed>
          {`${"Printed on:"} ${moment().format("DD-MMM-YYYY")}`}
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
let printNum = 0;
let printSummaryNum = 0;
const VisitInvoiceReportPDF = ({
  orderDetailInfo,
  statusOption,
  t,
  pageInfo,
  title,
  summaryMode = false,
  print = 0,
  printSummary = 0,
}) => {
  if (print !== 0 && print !== printNum) {
    const [instance, updateInstance] = usePDF({
      document: (
        <PDFPage
          orderDetailInfo={orderDetailInfo}
          statusOption={statusOption}
          pageInfo={pageInfo}
          title={title}
          summaryMode={summaryMode}
        />
      ),
    });

    useEffect(() => {
      if (!instance.loading && instance.url && print !== printNum) {
        printNum = print;
        window.open(instance.url, "_blank", "noreferrer");
      }
    }, [instance.loading, instance.url, printNum, print]);

    return <></>;
    // return (
    //   <BlobProvider
    //     document={
    //       <PDFPage
    //         orderDetailInfo={orderDetailInfo}
    //         statusOption={statusOption}
    //         pageInfo={pageInfo}
    //         title={title}
    //         summaryMode={summaryMode}
    //       />
    //     }>
    //     {({ blob, url, loading, error }) => {
    //       // var file = new blob();
    //       var fileURL = [];
    //       if (!loading && blob && print !== printNum) {
    //         printNum = print;
    //         fileURL = URL.createObjectURL(blob);
    //         window.open(fileURL, "_blank", "noreferrer");
    //       }

    //       // Do whatever you need with blob here
    //       return <></>;
    //     }}
    //   </BlobProvider>
    // );
  }
  if (printSummary !== 0 && printSummary !== printSummaryNum) {
    const [instance, updateInstance] = usePDF({
      document: (
        <PDFPage
          orderDetailInfo={orderDetailInfo}
          statusOption={statusOption}
          pageInfo={pageInfo}
          title={title}
          summaryMode={summaryMode}
        />
      ),
    });

    useEffect(() => {
      if (
        !instance.loading &&
        instance.url &&
        printSummary !== printSummaryNum
      ) {
        printNum = print;
        window.open(instance.url, "_blank", "noreferrer");
      }
    }, [instance.loading, instance.url, printSummary, printSummaryNum]);
    return <></>;
    // return (
    //   <BlobProvider
    //     document={
    //       <PDFPage
    //         orderDetailInfo={orderDetailInfo}
    //         statusOption={statusOption}
    //         pageInfo={pageInfo}
    //         title={title}
    //         summaryMode={summaryMode}
    //       />
    //     }>
    //     {({ blob, url, loading, error }) => {
    //       // var file = new blob();
    //       var fileURL = [];
    //       if (!loading && blob && printSummary !== printSummaryNum) {
    //         printSummaryNum = printSummary;
    //         fileURL = URL.createObjectURL(blob);
    //         window.open(fileURL, "_blank", "noreferrer");
    //       }

    //       // Do whatever you need with blob here
    //       return <></>;
    //     }}
    //   </BlobProvider>
    // );
  } else return <></>;
};

export default VisitInvoiceReportPDF;
