//lib
import React from "react";
import { Button } from "@mui/material";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

//components
import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";
import EditableCell from "../../../../components/EditableCell";
import * as generalConstants from "../../../../_constants/generalConstants";
import { useStyles } from "../../../../components/globalTableStyles";
import MedicineListDetailTable from "./MedicineListDetailTable";
import organisationPriceRounding from "../../../../components/functions/organisationPriceRounding";

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({
  columns,
  data,
  updateMyData,
  disablePageResetOnDataChange,
  t,
}) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        updateMyData,
        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();

  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    <TableCell className={paginationClasses.narrowCellStyle}>
                      {i + 1}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={paginationClasses.narrowCellStyle}
                          {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: cell.column.editable,
                            editableNum: cell.column.editableNum,
                            editableWholeNum: cell.column.editableWholeNum,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const MedicineListTable = ({
  details_price_rmt = generalConstants.ROUND_OFF_RMT,
  details_price_rpvt = generalConstants.TEN_THOUSANDTH_RPVT,
  viewOnly = false,
  data = [],
  setData = () => null,
  t,
}) => {
  const columns = [
    {
      id: "detail",
      textAlign: "left",
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleRowDialog(row.original.qty_received_details)}>
            <DetailLogo />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Medicine"),
      accessor: "medicine_name",
      sortable: false,
    },
    {
      Header: t("translation:Package Content"),
      accessor: "package_content",
      sortable: false,
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? Number(cell.value).toFixed(4)
          : "";
      },
    },
    {
      Header: t("translation:Unit Conversion"),
      accessor: "unit_conversion",
      sortable: false,
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? Number(cell.value).toFixed(4)
          : "";
      },
    },
    {
      id: "NewUnitCostPrice",
      Header: t("translation:New Cost Price/ Unit"),
      sortable: false,
      accessor: "newReceivedDetail.medicine_in_out.new_unit_cost_price",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },
    {
      id: "NewUnitSellingPrice",
      Header: t("translation:New Selling Price/ Unit"),
      sortable: false,
      accessor: "newReceivedDetail.medicine_in_out.new_unit_selling_price",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },
    {
      id: "changePercentage",
      Header: t("translation:Selling Price Changes %"),
      sortable: false,
      accessor: "newReceivedDetail.changePercent",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? cell.value
          : Number(0).toFixed(2);
      },
    },
    {
      Header: t("translation:Quantity"),
      accessor: "quantity",
      sortable: false,
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },

    {
      id: "receivedQuantity",
      Header: t("translation:Total Received Quantity"),
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      accessor: "newReceivedDetail.totalQtyReceived",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },

    {
      editableNum: true,
      Header: t("translation: Received Quantity"),
      sortable: false,
      accessor: "newReceivedDetail.qty_received",
    },
  ];

  const columnsViewOnly = [
    {
      id: "detail",
      textAlign: "left",
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleRowDialog(row.original.qty_received_details)}>
            <DetailLogo />
          </Button>
        </>
      ),
    },
    {
      Header: t("translation:Medicine"),
      accessor: "medicine_name",
      sortable: false,
    },
    {
      Header: t("translation:Package Content"),
      accessor: "package_content",
      sortable: false,
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? Number(cell.value).toFixed(4)
          : "";
      },
    },
    {
      Header: t("translation:Unit Conversion"),
      accessor: "unit_conversion",
      sortable: false,
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },
    {
      Header: t("translation:Quantity"),
      accessor: "quantity",
      sortable: false,
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },

    {
      id: "receivedQuantity",
      Header: t("translation:Total Received Quantity"),
      sortable: false,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      accessor: "newReceivedDetail.totalQtyReceived",
      Cell: ({ cell }) => {
        return cell.value || cell.value === 0
          ? organisationPriceRounding(
              cell.value,
              details_price_rmt,
              details_price_rpvt
            )
          : organisationPriceRounding(0, details_price_rmt, details_price_rpvt);
      },
    },
  ];

  const [openDetailDialog, setOpenDetailDialog] = React.useState(false);
  const [detailDialogInfo, setDetailDialogInfo] = React.useState(null);
  const handleRowDialog = (value) => {
    setOpenDetailDialog(true);
    setDetailDialogInfo(value);
  };

  return (
    <>
      <MedicineListDetailTable
        data={detailDialogInfo}
        open={openDetailDialog}
        setOpen={setOpenDetailDialog}
        t={t}
      />

      <Table
        columns={viewOnly ? columnsViewOnly : columns}
        data={data}
        updateMyData={setData}
        t={t}
      />
    </>
  );
};

export default MedicineListTable;
