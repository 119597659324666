import { createAsyncTypes } from "../_helper/Utilities";

export const GET_FORMULAMEDICINEFORMULABYID_ASYNC = createAsyncTypes(
  "GET_FORMULAMEDICINEFORMULABYID"
);

export const GET_FORMULAMEDICINEFORMULALIST_ASYNC = createAsyncTypes(
  "GET_FORMULAMEDICINEFORMULALIST"
);

export const PUT_UPDATEFORMULAMEDICINEFORMULA_ASYNC = createAsyncTypes(
  "PUT_UPDATEFORMULAMEDICINEFORMULA"
);

export const GET_FORMULAMEDICINEMEDICINELIST_ASYNC = createAsyncTypes(
  "GET_FORMULAMEDICINEMEDICINELIST"
);
