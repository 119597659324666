import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import StyledCheckbox from "../../../../../components/StyledCheckbox";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../components/globalStyles";

const EditMcIssued = ({
  visitNotesDetails = [],
  setVisitNotesDetails = () => null,
  viewOnly = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedVisitNote, setSelectedVisitNote] = useState([]);
  const handleOnChangeKeyIn = (event, value) => {
    setSelectedVisitNote((p) => ({
      ...p,
      [event.target.name]: event.target.value ? event.target.value : null,
    }));
  };
  const handleOnBlur = (event, value) => {
    setVisitNotesDetails((p) => ({
      ...p,
      [event.target.name]: event.target.value ? event.target.value : null,
    }));
  };

  //useEffects
  useEffect(() => {
    setSelectedVisitNote(visitNotesDetails);
  }, [visitNotesDetails, setSelectedVisitNote]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.userInfoField}>
        <FormControlLabel
          control={
            <StyledCheckbox
              disabled={viewOnly}
              checked={selectedVisitNote.is_mc_issued ? true : false}
              onChange={(e) => {
                setVisitNotesDetails((p) => ({
                  ...p,
                  is_mc_issued: e.target.checked,
                  mc_number_of_days: null,
                  mc_reason: null,
                }));
              }}
              name="is_mc_issued"
            />
          }
          label={t("translation:Mc")}
        />
      </Grid>

      {selectedVisitNote.is_mc_issued === true && (
        <>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              required
              disabled={viewOnly}
              className={classes.userFieldColor}
              value={
                selectedVisitNote.mc_number_of_days !== null
                  ? selectedVisitNote.mc_number_of_days
                  : ""
              }
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "-" ||
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                setVisitNotesDetails((p) => ({
                  ...p,
                  mc_number_of_days: Number(e.target.value),
                }));
              }}
              id="mcDays"
              name="mcDays"
              label={t("translation:Day")}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={viewOnly}
              className={classes.userFieldColor}
              inputProps={{
                maxLength: 256,
              }}
              value={
                selectedVisitNote.mc_reason ? selectedVisitNote.mc_reason : ""
              }
              onChange={(e) => {
                handleOnChangeKeyIn(e);
              }}
              onBlur={(e) => {
                handleOnBlur(e);
              }}
              multiline
              rows={3}
              name="mc_reason"
              label={
                t("translation:Mc Reasons") +
                ` ${
                  visitNotesDetails.mc_reason
                    ? visitNotesDetails.mc_reason.length
                    : 0
                }/256`
              }
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default EditMcIssued;
