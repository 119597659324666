import * as types from "../_constants/branchPhysicianAdhocScheduleConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_BRANCHPHYSICIANADHOCSCHEDULELIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchPhysicianAdhocScheduleListIsSuccess: false,
        isLoadingBranchPhysicianAdhocScheduleList: true,
        branchPhysicianAdhocScheduleList: null,
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULELIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchPhysicianAdhocScheduleListIsSuccess: false,
        isLoadingBranchPhysicianAdhocScheduleList: false,
        branchPhysicianAdhocScheduleList: null,
        error: {
          ...state.error,
          branchPhysicianAdhocScheduleListError: action.error,
        },
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchPhysicianAdhocScheduleListIsSuccess: true,
        isLoadingBranchPhysicianAdhocScheduleList: false,
        branchPhysicianAdhocScheduleList: action.data,
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULEBYID_ASYNC.PENDING:
      return {
        ...state,
        getBranchPhysicianAdhocScheduleByIdIsSuccess: false,
        isLoadingBranchPhysicianAdhocScheduleById: true,
        branchPhysicianAdhocScheduleById: null,
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULEBYID_ASYNC.ERROR:
      return {
        ...state,
        getBranchPhysicianAdhocScheduleByIdIsSuccess: false,
        isLoadingBranchPhysicianAdhocScheduleById: false,
        branchPhysicianAdhocScheduleById: null,
        error: {
          ...state.error,
          branchPhysicianAdhocScheduleByIdError: action.error,
        },
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchPhysicianAdhocScheduleByIdIsSuccess: true,
        isLoadingBranchPhysicianAdhocScheduleById: false,
        branchPhysicianAdhocScheduleById: action.data,
      };

    case types.POST_BRANCHPHYSICIANADHOCSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC
      .PENDING:
      return {
        ...state,
        postBranchPhysicianAdhocScheduleByBranchIdIdIsSuccess: false,
        isLoadingPostBranchPhysicianAdhocScheduleByBranchId: true,
        postBranchPhysicianAdhocScheduleByBranchId: null,
      };

    case types.POST_BRANCHPHYSICIANADHOCSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC
      .ERROR:
      return {
        ...state,
        postBranchPhysicianAdhocScheduleByBranchIdIdIsSuccess: false,
        isLoadingPostBranchPhysicianAdhocScheduleByBranchId: false,
        postBranchPhysicianAdhocScheduleByBranchId: null,
        error: {
          ...state.error,
          postBranchPhysicianAdhocScheduleByBranchIdError: action.error,
        },
      };

    case types.POST_BRANCHPHYSICIANADHOCSCHEDULEBYBRANCHIDPHYSICIANID_ASYNC
      .SUCCESS:
      return {
        ...state,
        postBranchPhysicianAdhocScheduleByBranchIdIdIsSuccess: true,
        isLoadingPostBranchPhysicianAdhocScheduleByBranchId: false,
        postBranchPhysicianAdhocScheduleByBranchId: action.data,
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULEBRANCHLIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchPhysicianAdhocScheduleBranchListIsSuccess: false,
        isLoadingBranchPhysicianAdhocScheduleBranchList: true,
        branchPhysicianAdhocScheduleBranchList: null,
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULEBRANCHLIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchPhysicianAdhocScheduleBranchListIsSuccess: false,
        isLoadingBranchPhysicianAdhocScheduleBranchList: false,
        branchPhysicianAdhocScheduleBranchList: null,
        error: {
          ...state.error,
          branchPhysicianAdhocScheduleBranchListError: action.error,
        },
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULEBRANCHLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchPhysicianAdhocScheduleBranchListIsSuccess: true,
        isLoadingBranchPhysicianAdhocScheduleBranchList: false,
        branchPhysicianAdhocScheduleBranchList: action.data,
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULEPHYSICIANLIST_ASYNC.PENDING:
      return {
        ...state,
        getBranchPhysicianAdhocSchedulePhysicianListIsSuccess: false,
        isLoadingBranchPhysicianAdhocSchedulePhysicianList: true,
        branchPhysicianAdhocSchedulePhysicianList: null,
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULEPHYSICIANLIST_ASYNC.ERROR:
      return {
        ...state,
        getBranchPhysicianAdhocSchedulePhysicianListIsSuccess: false,
        isLoadingBranchPhysicianAdhocSchedulePhysicianList: false,
        branchPhysicianAdhocSchedulePhysicianList: null,
        error: {
          ...state.error,
          branchPhysicianAdhocSchedulePhysicianListError: action.error,
        },
      };

    case types.GET_BRANCHPHYSICIANADHOCSCHEDULEPHYSICIANLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getBranchPhysicianAdhocSchedulePhysicianListIsSuccess: true,
        isLoadingBranchPhysicianAdhocSchedulePhysicianList: false,
        branchPhysicianAdhocSchedulePhysicianList: action.data,
      };

    default:
      return state;
  }
};
