import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getFormulaMedicineFormulaList,
  getFormulaMedicineFormulaById,
  putUpdateFormulaMedicineFormula,
  getFormulaMedicineMedicineList,
} from "../actions/formulaMedicineActions";
import * as types from "../_constants/formulaMedicineConstants";
import * as notification from "../_constants/notificationConstants";
import formulamedicineformulaApis from "../_apis/formulaMedicineApis";
export default function* formulaMedicineSagas() {
  yield all([
    takeLatest(
      types.GET_FORMULAMEDICINEFORMULALIST_ASYNC.PENDING,
      getFormulaMedicineFormulaListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_FORMULAMEDICINEFORMULABYID_ASYNC.PENDING,
      getFormulaMedicineFormulaByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_UPDATEFORMULAMEDICINEFORMULA_ASYNC.PENDING,
      putUpdateFormulaMedicineFormulaCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_FORMULAMEDICINEMEDICINELIST_ASYNC.PENDING,
      getFormulaMedicineMedicineListCall
    ),
  ]);
}

function* getFormulaMedicineFormulaListCall(searchModel) {
  try {
    const data = yield call(
      formulamedicineformulaApis.getFormulaMedicineFormulaListApi,
      searchModel.data
    );
    yield put(getFormulaMedicineFormulaList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getFormulaMedicineFormulaList.error(error.response?.data?.message)
      );
    else
      yield put(
        getFormulaMedicineFormulaList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getFormulaMedicineFormulaByIdCall(searchModel) {
  try {
    const data = yield call(
      formulamedicineformulaApis.getFormulaMedicineFormulaByIdApi,
      searchModel.data
    );
    yield put(getFormulaMedicineFormulaById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getFormulaMedicineFormulaById.error(error.response?.data?.message)
      );
    else
      yield put(
        getFormulaMedicineFormulaById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateFormulaMedicineFormulaCall(searchModel) {
  try {
    const data = yield call(
      formulamedicineformulaApis.putUpdateFormulaMedicineFormulaApi,
      searchModel.data
    );
    yield put(putUpdateFormulaMedicineFormula.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateFormulaMedicineFormula.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdateFormulaMedicineFormula.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getFormulaMedicineMedicineListCall(searchModel) {
  try {
    const data = yield call(
      formulamedicineformulaApis.getFormulaMedicineMedicineListApi,
      searchModel.data
    );
    yield put(getFormulaMedicineMedicineList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getFormulaMedicineMedicineList.error(error.response?.data?.message)
      );
    else
      yield put(
        getFormulaMedicineMedicineList.error(notification.CONST_NOINTERNET)
      );
  }
}
