import { createAsyncTypes } from "../_helper/Utilities";

export const GET_ORGANISATIONPHYSICIANBYID_ASYNC = createAsyncTypes(
  "GET_ORGANISATIONPHYSICIANBYID"
);

export const GET_ORGANISATIONPHYSICIANLIST_ASYNC = createAsyncTypes(
  "GET_ORGANISATIONPHYSICIANLIST"
);

export const PUT_UPDATEORGANISATIONPHYSICIAN_ASYNC = createAsyncTypes(
  "PUT_UPDATEORGANISATIONPHYSICIAN"
);

export const GET_ORGANISATIONPHYSICIANUSERLIST_ASYNC = createAsyncTypes(
  "GET_ORGANISATIONPHYSICIANUSERLIST"
);
