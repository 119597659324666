//lib
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Close from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Button, Grid, Link, TextField } from "@mui/material";

//components
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import { putUpdateUserMedicalHistory } from "../../../../../actions/visitOrderActions";
import noEnterSubmitForm from "../../../../../components/functions/noEnterSubmitForm";
import { useStyles } from "../../../../../components/globalStyles";
import SubmissionNotificationDialog from "../../../../../components/SubmissionNotificationDialog";
//style
export default function EditUserMedicalDialog({
  orderDetailInfo,
  setEditOrderInfo,
  editMedicalOpen,
  setEditMedicalOpen,
}) {
  const { handleSubmit: handleSubmit2 } = useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const prevUpdateUserMedicalStatus = useRef();

  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState(null);

  const [selectedGroup, setSelectedGroup] = useState(orderDetailInfo.staff);

  useEffect(() => {
    const selectedGroupResults = orderDetailInfo;

    if (selectedGroupResults.store_medicine_details) {
      setSelectedGroup(orderDetailInfo);
    }
  }, [orderDetailInfo]);

  const handleChangeMedical = (e) => {
    setSelectedGroup({
      ...selectedGroup,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const visitOrder = useSelector((state) => state.visitOrder);

  const onSubmit = () => {
    const submitJson = {
      user: {
        id: orderDetailInfo.staff.id,
        allergy: selectedGroup.allergy,
        medical_history: selectedGroup.medical_history,
      },
    };
    dispatch(putUpdateUserMedicalHistory.pending(submitJson));
  };

  const onConfirmSubmit = () => {
    setConfirmSubmitOpen(true);
  };

  useEffect(() => {
    prevUpdateUserMedicalStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      visitOrder.isLoadingpPutUpdateUserMedicalHistory !==
      prevUpdateUserMedicalStatus.current
    ) {
      //check the previous ref with current state
      prevUpdateUserMedicalStatus.current =
        visitOrder.isLoadingpPutUpdateUserMedicalHistory;

      if (
        visitOrder.putUpdateUserMedicalHistorySuccess === true &&
        visitOrder.isLoadingpPutUpdateUserMedicalHistory === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        const returnJson = {
          ...orderDetailInfo,
          staff: visitOrder.putUpdateUserMedicalHistory.user,
        };

        setEditOrderInfo(returnJson);
      }
      if (
        visitOrder.putUpdateUserMedicalHistorySuccess === false &&
        visitOrder.isLoadingpPutUpdateUserMedicalHistory === false
      ) {
        window.scrollTo(0, 0);
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(visitOrder.error.putUpdateUserMedicalHistoryError);
      }
    }
  }, [visitOrder, setEditOrderInfo, orderDetailInfo]);

  return (
    <Dialog
      open={editMedicalOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setEditMedicalOpen(false);
        }
      }}
      fullWidth
      maxWidth="lg">
      <Close
        style={{ position: "absolute", right: "15px" }}
        onClick={() => setEditMedicalOpen(false)}
      />
      <form
        key={2}
        onSubmit={handleSubmit2(onConfirmSubmit)}
        onKeyDown={(e) => noEnterSubmitForm(e)}>
        <SubmissionNotificationDialog open={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </SubmissionNotificationDialog>
        <SubmissionNotificationDialog open={submitSuccessAlarm}>
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Allergy & Medical History has been updated")}
          </Alert>
        </SubmissionNotificationDialog>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmSubmitOpen}
          setOpen={setConfirmSubmitOpen}
          onConfirm={onSubmit}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        {visitOrder.isLoadingpPutUpdateUserMedicalHistory !== true && (
          <>
            <DialogContent>
              <Grid container>
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={selectedGroup.allergy ? selectedGroup.allergy : ""}
                    multiline
                    rows={3}
                    name="allergy"
                    label={t("translation:Allergy")}
                    variant="outlined"
                    onChange={(e) => handleChangeMedical(e)}
                  />
                </Grid>
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      selectedGroup.medical_history
                        ? selectedGroup.medical_history
                        : ""
                    }
                    multiline
                    rows={3}
                    name="medical_history"
                    label={t("translation:Medical History")}
                    variant="outlined"
                    onChange={(e) => handleChangeMedical(e)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              name="submitButton"
              type="submit">
              {t("translation:SUBMIT")}
            </Button>
          </Link>

          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              name="backButton"
              onClick={() => setEditMedicalOpen(false)}>
              {t("translation:BACK")}
            </Button>
          </Link>
        </DialogActions>
        {/* <DialogActions>
          {" "}
          <PageSubmitWBackAction setView={setEditMedicalOpen} />
        </DialogActions> */}
      </form>
    </Dialog>
  );
}
