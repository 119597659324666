import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/wikiDataConstants";

export const getWikiDataById = {
  pending: (data) =>
    createAction(types.GET_WIKIDATABYID_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKIDATABYID_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKIDATABYID_ASYNC.ERROR, {
      error,
    }),
};

export const getWikiDataList = {
  pending: (data) =>
    createAction(types.GET_WIKIDATALIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKIDATALIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKIDATALIST_ASYNC.ERROR, {
      error,
    }),
};

export const getWikiDataPreviewList = {
  pending: (data) =>
    createAction(types.GET_WIKIDATAPREVIEWLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKIDATAPREVIEWLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKIDATAPREVIEWLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getWikiDataPathList = {
  pending: (data) =>
    createAction(types.GET_WIKIDATAPATHLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKIDATAPATHLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKIDATAPATHLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getWikiDataSubNodeList = {
  pending: (data) =>
    createAction(types.GET_WIKIDATASUBNODELIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKIDATASUBNODELIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKIDATASUBNODELIST_ASYNC.ERROR, {
      error,
    }),
};

export const postCreateWikiData = {
  pending: (data) =>
    createAction(types.POST_CREATEWIKIDATA_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_CREATEWIKIDATA_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_CREATEWIKIDATA_ASYNC.ERROR, {
      error,
    }),
};

export const putUpdateWikiData = {
  pending: (data) =>
    createAction(types.PUT_UPDATEWIKIDATA_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_UPDATEWIKIDATA_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_UPDATEWIKIDATA_ASYNC.ERROR, {
      error,
    }),
};

export const getWikiAcupointList = {
  pending: (data) =>
    createAction(types.GET_WIKIACUPOINTLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKIACUPOINTLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKIACUPOINTLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getWikiCustomWordList = {
  pending: (data) =>
    createAction(types.GET_WIKICUSTOMWORDLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKICUSTOMWORDLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKICUSTOMWORDLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getWikiTreatmentTypes = {
  pending: (data) =>
    createAction(types.GET_WIKITREATMENTTYPES_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKITREATMENTTYPES_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKITREATMENTTYPES_ASYNC.ERROR, {
      error,
    }),
};

export const getWikiTreatmentList = {
  pending: (data) =>
    createAction(types.GET_WIKITREATMENTLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKITREATMENTLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKITREATMENTLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getWikiMedicineTypes = {
  pending: (data) =>
    createAction(types.GET_WIKIMEDICINETYPES_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKIMEDICINETYPES_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKIMEDICINETYPES_ASYNC.ERROR, {
      error,
    }),
};

export const getWikiMedicineList = {
  pending: (data) =>
    createAction(types.GET_WIKIMEDICINELIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_WIKIMEDICINELIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_WIKIMEDICINELIST_ASYNC.ERROR, {
      error,
    }),
};
