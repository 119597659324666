import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getVisitDispensaryBranches,
  getVisitMedicineTypes,
  getVisitDispensaryBranchesAlliance,
  getVisitMedicineTypesAlliance,
  postVisitBranchMedicines,
  getVisitCurrentOrganisation,
  postVisitSaveStoreOrder,
  postVisitSubmitStoreOrder,
  getVisitStoreOrderList,
  getVisitStoreOrderSummaryList,
  getPatientVisitStoreOrderList,
  getVisitStoreOrderDashboardSearchList,
  putVisitStoreEditMode,
  postVisitFormulasAlliance,
  postVisitMedicineFormulasAlliance,
  getVisitAcupoints,
  getVisitTreatmentTypes,
  getVisitPaymentTerms,
  getVisitPaymentMethods,
  postVisitTreatments,
  postVisitEmptyVisitOrder,
  getVisitPhysicians,
  postVisitPhysicians,
  getVisitUserList,
  getVisitUserAdvancedSearch,
  postVisitCreateUser,
  putVisitUpdateUser,
  getVisitIsUserCurrentOrganisation,
  putVisitUpdateUserOrganisation,
  putVisitSetArrivalOn,
  putVisitSetDepartureOn,
  putVisitSetConsultOn,
  putVisitSetQueueNumber,
  putVisitUpdateVisitNotes,
  putVisitPrintDate,
  putUpdateUserMedicalHistory,
  getVisitPrescriptionMsgList,
  getVisitMedicineInfo,
  getVisitAcupointInfo,
  getVisitTreatmentInfo,
  getVisitStoreOrderById,
  getVisitStoreOrderByIdPatientHistory,
  postVisitChangeBranch,
  postVisitChangeBranchTreatmentOnly,
  postVisitChangeBranchAcupointOnly,
  getVisitStoreOrderListLinkedDelivery,
  getVisitStoreOrderSummaryListLinkedDelivery,
  getVisitStoreOrderByIdLinkedDelivery,
  putUpdateVisitAcupoints,
  getVisitPostalCodeSurchargeList,
  getVisitAvailableProceedOn,
  getVisitVerifyAddress,
  postVisitChangeBranchWholeMedicine,
} from "../actions/visitOrderActions";
import * as types from "../_constants/visitOrderConstants";
import * as notification from "../_constants/notificationConstants";
import visitOrderApis from "../_apis/visitOrderApis";
export default function* visitOrderSagas() {
  yield all([
    takeLatest(
      types.GET_VISITDISPENSARYBRANCHES_ASYNC.PENDING,
      getVisitDispensaryBranchesCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_VISITMEDICINETYPES_ASYNC.PENDING,
      getVisitMedicineTypesCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_VISITDISPENSARYBRANCHESALLIANCE_ASYNC.PENDING,
      getVisitDispensaryBranchesAllianceCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_VISITMEDICINETYPESALLIANCE_ASYNC.PENDING,
      getVisitMedicineTypesAllianceCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_VISITFORMULASALLIANCE_ASYNC.PENDING,
      postVisitFormulasAllianceCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_VISITMEDICINEFORMULASALLIANCE_ASYNC.PENDING,
      postVisitMedicineFormulasAllianceCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_VISITBRANCHMEDICINES_ASYNC.PENDING,
      postVisitBranchMedicinesCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_VISITCURRENTORGANISATION_ASYNC.PENDING,
      getVisitCurrentOrganisationCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_VISITSAVESTOREORDER_ASYNC.PENDING,
      postVisitSaveStoreOrderCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_VISITSUBMITSTOREORDER_ASYNC.PENDING,
      postVisitSubmitStoreOrderCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_VISITSTOREORDERLIST_ASYNC.PENDING,
      getVisitStoreOrderListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITSTOREORDERLISTLINKEDDELIVERY_ASYNC.PENDING,
      getVisitStoreOrderListLinkedDeliveryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITSTOREORDERSUMMARYLIST_ASYNC.PENDING,
      getVisitStoreOrderSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITSTOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.PENDING,
      getVisitStoreOrderSummaryListLinkedDeliveryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITSTOREORDERDASHBOARDSEARCHLIST_ASYNC.PENDING,
      getVisitStoreOrderDashboardSearchListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_PATIENTVISITSTOREORDERLIST_ASYNC.PENDING,
      getPatientVisitStoreOrderListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_VISITSTOREEDITMODE_ASYNC.PENDING,
      putVisitStoreEditModeCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_VISITACUPOINTS_ASYNC.PENDING, getVisitAcupointsCall),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITTREATMENTTYPES_ASYNC.PENDING,
      getVisitTreatmentTypesCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITPAYMENTTERMS_ASYNC.PENDING,
      getVisitPaymentTermsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITPAYMENTMETHODS_ASYNC.PENDING,
      getVisitPaymentMethodsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_VISITTREATMENTS_ASYNC.PENDING,
      postVisitTreatmentsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_VISITEMPTYORDER_ASYNC.PENDING,
      postVisitEmptyVisitOrderCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_VISITPHYSICIANS_ASYNC.PENDING, getVisitPhysiciansCall),
  ]);

  yield all([
    takeLatest(
      types.POST_VISITPHYSICIANS_ASYNC.PENDING,
      postVisitPhysiciansCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_VISITUSERLIST_ASYNC.PENDING, getVisitUserListCall),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITUSERADVANCEDSEARCH_ASYNC.PENDING,
      getVisitUserAdvancedSearchCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_VISITCREATEUSER_ASYNC.PENDING,
      postVisitCreateUserCall
    ),
  ]);

  yield all([
    takeLatest(types.PUT_VISITUPDATEUSER_ASYNC.PENDING, putVisitUpdateUserCall),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITISUSERCURRENTORGANISATION_ASYNC.PENDING,
      getVisitIsUserCurrentOrganisationCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_VISITUPDATEUSERORGANISATION_ASYNC.PENDING,
      putVisitUpdateUserOrganisationCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_VISITSETARRIVALON_ASYNC.PENDING,
      putVisitSetArrivalOnCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_VISITSETDEPARTUREON_ASYNC.PENDING,
      putVisitSetDepartureOnCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_VISITSETCONSULTON_ASYNC.PENDING,
      putVisitSetConsultOnCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_VISITSETQUEUENUMBER_ASYNC.PENDING,
      putVisitSetQueueNumberCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_VISITUPDATEVISITNOTES_ASYNC.PENDING,
      putVisitUpdateVisitNotesCall
    ),
  ]);

  yield all([
    takeLatest(types.PUT_VISITPRINTDATE_ASYNC.PENDING, putVisitPrintDateCall),
  ]);

  yield all([
    takeLatest(
      types.PUT_UPDATEUSERMEDICALHISTORY_ASYNC.PENDING,
      putUpdateUserMedicalHistoryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITPRESCRIPTIONMSGLIST_ASYNC.PENDING,
      getVisitPrescriptionMsgListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITMEDICINEINFO_ASYNC.PENDING,
      getVisitMedicineInfoCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITACUPOINTINFO_ASYNC.PENDING,
      getVisitAcupointInfoCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITTREATMENTINFO_ASYNC.PENDING,
      getVisitTreatmentInfoCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITSTOREORDERBYID_ASYNC.PENDING,
      getVisitStoreOrderByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITSTOREORDERBYIDLINKEDDELIVERY_ASYNC.PENDING,
      getVisitStoreOrderByIdLinkedDeliveryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITSTOREORDERBYIDPATIENTHISTORY_ASYNC.PENDING,
      getVisitStoreOrderByIdPatientHistoryCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_VISITCHANGEBRANCH_ASYNC.PENDING,
      postVisitChangeBranchCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_VISITCHANGEBRANCHHOLEMEDICINE_ASYNC.PENDING,
      postVisitChangeBranchWholeMedicineCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_VISITCHANGEBRANCHTREATMENTONLY_ASYNC.PENDING,
      postVisitChangeBranchTreatmentOnyCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_VISITCHANGEBRANCHACUPOINTONLY_ASYNC.PENDING,
      postVisitChangeBranchAcupointOnyCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_UPDATEVISITACUPOINTS_ASYNC.PENDING,
      putUpdateVisitAcupointsCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITPOSTALCODESURCHARGE_ASYNC.PENDING,
      getVisitPostalCodeSurchargeListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITAVAILABLEPROCEEDON_ASYNC.PENDING,
      getVisitAvailableProceedOnCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_VISITVERIFYADDRESS_ASYNC.PENDING,
      getVisitVerifyAddressCall
    ),
  ]);
}

function* getVisitDispensaryBranchesCall() {
  try {
    const data = yield call(visitOrderApis.getVisitDispensaryBranchesApi);
    yield put(getVisitDispensaryBranches.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitDispensaryBranches.error(error.response?.data?.message)
      );
    else
      yield put(
        getVisitDispensaryBranches.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getVisitMedicineTypesCall() {
  try {
    const data = yield call(visitOrderApis.getVisitMedicineTypesApi);
    yield put(getVisitMedicineTypes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitMedicineTypes.error(error.response?.data?.message));
    else yield put(getVisitMedicineTypes.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitDispensaryBranchesAllianceCall() {
  try {
    const data = yield call(
      visitOrderApis.getVisitDispensaryBranchesAllianceApi
    );
    yield put(getVisitDispensaryBranchesAlliance.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitDispensaryBranchesAlliance.error(error.response?.data?.message)
      );
    else
      yield put(
        getVisitDispensaryBranchesAlliance.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getVisitMedicineTypesAllianceCall(searchModel) {
  try {
    const data = yield call(
      visitOrderApis.getVisitMedicineTypesAllianceApi,
      searchModel.data
    );
    yield put(getVisitMedicineTypesAlliance.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitMedicineTypesAlliance.error(error.response?.data?.message)
      );
    else
      yield put(
        getVisitMedicineTypesAlliance.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postVisitFormulasAllianceCall(searchModel) {
  try {
    const data = yield call(
      visitOrderApis.postVisitFormulasAllianceApi,
      searchModel.data
    );
    yield put(postVisitFormulasAlliance.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVisitFormulasAlliance.error(error.response?.data?.message));
    else
      yield put(postVisitFormulasAlliance.error(notification.CONST_NOINTERNET));
  }
}

function* postVisitMedicineFormulasAllianceCall(searchModel) {
  try {
    const data = yield call(
      visitOrderApis.postVisitMedicineFormulasAllianceApi,
      searchModel.data
    );
    yield put(postVisitMedicineFormulasAlliance.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postVisitMedicineFormulasAlliance.error(error.response?.data?.message)
      );
    else
      yield put(
        postVisitMedicineFormulasAlliance.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postVisitBranchMedicinesCall(searchModel) {
  try {
    const data = yield call(
      visitOrderApis.postVisitBranchMedicinesApi,
      searchModel.data
    );
    yield put(postVisitBranchMedicines.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVisitBranchMedicines.error(error.response?.data?.message));
    else
      yield put(postVisitBranchMedicines.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitCurrentOrganisationCall() {
  try {
    const data = yield call(visitOrderApis.getVisitCurrentOrganisationApi);
    yield put(getVisitCurrentOrganisation.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitCurrentOrganisation.error(error.response?.data?.message)
      );
    else
      yield put(
        getVisitCurrentOrganisation.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postVisitSaveStoreOrderCall(model) {
  try {
    const data = yield call(
      visitOrderApis.postVisitSaveStoreOrderApi,
      model.data
    );
    yield put(postVisitSaveStoreOrder.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVisitSaveStoreOrder.error(error.response?.data?.message));
    else
      yield put(postVisitSaveStoreOrder.error(notification.CONST_NOINTERNET));
  }
}

function* postVisitSubmitStoreOrderCall(model) {
  try {
    const data = yield call(
      visitOrderApis.postVisitSubmitStoreOrderApi,
      model.data
    );
    yield put(postVisitSubmitStoreOrder.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVisitSubmitStoreOrder.error(error.response?.data?.message));
    else
      yield put(postVisitSubmitStoreOrder.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitStoreOrderListCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitStoreOrderListApi,
      model.data
    );
    yield put(getVisitStoreOrderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitStoreOrderList.error(error.response?.data?.message));
    else yield put(getVisitStoreOrderList.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitStoreOrderListLinkedDeliveryCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitStoreOrderListApi,
      model.data
    );
    yield put(getVisitStoreOrderListLinkedDelivery.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitStoreOrderListLinkedDelivery.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getVisitStoreOrderListLinkedDelivery.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getVisitStoreOrderSummaryListCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitStoreOrderSummaryListApi,
      model.data
    );
    yield put(getVisitStoreOrderSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitStoreOrderSummaryList.error(error.response?.data?.message)
      );
    else
      yield put(
        getVisitStoreOrderSummaryList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getVisitStoreOrderSummaryListLinkedDeliveryCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitStoreOrderSummaryListApi,
      model.data
    );
    yield put(getVisitStoreOrderSummaryListLinkedDelivery.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitStoreOrderSummaryListLinkedDelivery.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getVisitStoreOrderSummaryListLinkedDelivery.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getVisitStoreOrderByIdCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitStoreOrderByIdApi,
      model.data
    );
    yield put(getVisitStoreOrderById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitStoreOrderById.error(error.response?.data?.message));
    else yield put(getVisitStoreOrderById.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitStoreOrderByIdLinkedDeliveryCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitStoreOrderByIdApi,
      model.data
    );
    yield put(getVisitStoreOrderByIdLinkedDelivery.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitStoreOrderByIdLinkedDelivery.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getVisitStoreOrderByIdLinkedDelivery.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getVisitStoreOrderByIdPatientHistoryCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitStoreOrderByIdPatientHistoryApi,
      model.data
    );
    yield put(getVisitStoreOrderByIdPatientHistory.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitStoreOrderByIdPatientHistory.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getVisitStoreOrderByIdPatientHistory.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getVisitStoreOrderDashboardSearchListCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitStoreOrderListApi,
      model.data
    );
    yield put(getVisitStoreOrderDashboardSearchList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitStoreOrderDashboardSearchList.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        getVisitStoreOrderDashboardSearchList.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* getPatientVisitStoreOrderListCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getPatientVisitStoreOrderListApi,
      model.data
    );
    yield put(getPatientVisitStoreOrderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getPatientVisitStoreOrderList.error(error.response?.data?.message)
      );
    else
      yield put(
        getPatientVisitStoreOrderList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putVisitStoreEditModeCall(model) {
  try {
    const data = yield call(
      visitOrderApis.putVisitStoreEditModeApi,
      model.data
    );
    yield put(putVisitStoreEditMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putVisitStoreEditMode.error(error.response?.data?.message));
    else yield put(putVisitStoreEditMode.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitAcupointsCall() {
  try {
    const data = yield call(visitOrderApis.getVisitAcupointsApi);
    yield put(getVisitAcupoints.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitAcupoints.error(error.response?.data?.message));
    else yield put(getVisitAcupoints.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitTreatmentTypesCall() {
  try {
    const data = yield call(visitOrderApis.getVisitTreatmentTypesApi);
    yield put(getVisitTreatmentTypes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitTreatmentTypes.error(error.response?.data?.message));
    else yield put(getVisitTreatmentTypes.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitPaymentTermsCall() {
  try {
    const data = yield call(visitOrderApis.getVisitPaymentTermsApi);
    yield put(getVisitPaymentTerms.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitPaymentTerms.error(error.response?.data?.message));
    else yield put(getVisitPaymentTerms.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitPaymentMethodsCall() {
  try {
    const data = yield call(visitOrderApis.getVisitPaymentMethodsApi);
    yield put(getVisitPaymentMethods.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitPaymentMethods.error(error.response?.data?.message));
    else yield put(getVisitPaymentMethods.error(notification.CONST_NOINTERNET));
  }
}

function* postVisitTreatmentsCall(model) {
  try {
    const data = yield call(visitOrderApis.postVisitTreatmentsApi, model.data);
    yield put(postVisitTreatments.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVisitTreatments.error(error.response?.data?.message));
    else yield put(postVisitTreatments.error(notification.CONST_NOINTERNET));
  }
}

function* postVisitEmptyVisitOrderCall(model) {
  try {
    const data = yield call(
      visitOrderApis.postVisitEmptyVisitOrderApi,
      model.data
    );
    yield put(postVisitEmptyVisitOrder.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVisitEmptyVisitOrder.error(error.response?.data?.message));
    else
      yield put(postVisitEmptyVisitOrder.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitPhysiciansCall() {
  try {
    const data = yield call(visitOrderApis.getVisitPhysiciansApi);
    yield put(getVisitPhysicians.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitPhysicians.error(error.response?.data?.message));
    else yield put(getVisitPhysicians.error(notification.CONST_NOINTERNET));
  }
}

function* postVisitPhysiciansCall(model) {
  try {
    const data = yield call(visitOrderApis.postVisitPhysiciansApi, model.data);
    yield put(postVisitPhysicians.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVisitPhysicians.error(error.response?.data?.message));
    else yield put(postVisitPhysicians.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitUserListCall(model) {
  try {
    const data = yield call(visitOrderApis.getVisitUserListApi, model.data);
    yield put(getVisitUserList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitUserList.error(error.response?.data?.message));
    else yield put(getVisitUserList.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitUserAdvancedSearchCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitUserAdvancedSearchApi,
      model.data
    );
    yield put(getVisitUserAdvancedSearch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitUserAdvancedSearch.error(error.response?.data?.message)
      );
    else
      yield put(
        getVisitUserAdvancedSearch.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postVisitCreateUserCall(model) {
  try {
    const data = yield call(visitOrderApis.postVisitCreateUserApi, model.data);
    yield put(postVisitCreateUser.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVisitCreateUser.error(error.response?.data?.message));
    else yield put(postVisitCreateUser.error(notification.CONST_NOINTERNET));
  }
}

function* putVisitUpdateUserCall(model) {
  try {
    const data = yield call(visitOrderApis.putVisitUpdateUserApi, model.data);
    yield put(putVisitUpdateUser.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putVisitUpdateUser.error(error.response?.data?.message));
    else yield put(putVisitUpdateUser.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitIsUserCurrentOrganisationCall(model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitIsUserCurrentOrganisationApi,
      model.data
    );
    yield put(getVisitIsUserCurrentOrganisation.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitIsUserCurrentOrganisation.error(error.response?.data?.message)
      );
    else
      yield put(
        getVisitIsUserCurrentOrganisation.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putVisitUpdateUserOrganisationCall(model) {
  try {
    const data = yield call(
      visitOrderApis.putVisitUpdateUserOrganisationApi,
      model.data
    );
    yield put(putVisitUpdateUserOrganisation.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putVisitUpdateUserOrganisation.error(error.response?.data?.message)
      );
    else
      yield put(
        putVisitUpdateUserOrganisation.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putVisitSetArrivalOnCall(model) {
  try {
    const data = yield call(visitOrderApis.putVisitSetArrivalOnApi, model.data);
    yield put(putVisitSetArrivalOn.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putVisitSetArrivalOn.error(error.response?.data?.message));
    else yield put(putVisitSetArrivalOn.error(notification.CONST_NOINTERNET));
  }
}

function* putVisitSetDepartureOnCall(model) {
  try {
    const data = yield call(
      visitOrderApis.putVisitSetDepartureOnApi,
      model.data
    );
    yield put(putVisitSetDepartureOn.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putVisitSetDepartureOn.error(error.response?.data?.message));
    else yield put(putVisitSetDepartureOn.error(notification.CONST_NOINTERNET));
  }
}

function* putVisitSetConsultOnCall(model) {
  try {
    const data = yield call(visitOrderApis.putVisitSetConsultOnApi, model.data);
    yield put(putVisitSetConsultOn.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putVisitSetConsultOn.error(error.response?.data?.message));
    else yield put(putVisitSetConsultOn.error(notification.CONST_NOINTERNET));
  }
}

function* putVisitSetQueueNumberCall(model) {
  try {
    const data = yield call(
      visitOrderApis.putVisitSetQueueNumberApi,
      model.data
    );
    yield put(putVisitSetQueueNumber.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putVisitSetQueueNumber.error(error.response?.data?.message));
    else yield put(putVisitSetQueueNumber.error(notification.CONST_NOINTERNET));
  }
}

function* putVisitUpdateVisitNotesCall(model) {
  try {
    const data = yield call(
      visitOrderApis.putVisitUpdateVisitNotesApi,
      model.data
    );
    yield put(putVisitUpdateVisitNotes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putVisitUpdateVisitNotes.error(error.response?.data?.message));
    else
      yield put(putVisitUpdateVisitNotes.error(notification.CONST_NOINTERNET));
  }
}

function* putVisitPrintDateCall(Model) {
  try {
    const data = yield call(visitOrderApis.putVisitPrintDateApi, Model.data);
    yield put(putVisitPrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putVisitPrintDate.error(error.response?.data?.message));
    else yield put(putVisitPrintDate.error(notification.CONST_NOINTERNET));
  }
}

function* putUpdateUserMedicalHistoryCall(Model) {
  try {
    const data = yield call(
      visitOrderApis.putUpdateUserMedicalHistoryApi,
      Model.data
    );
    yield put(putUpdateUserMedicalHistory.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdateUserMedicalHistory.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdateUserMedicalHistory.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getVisitPrescriptionMsgListCall() {
  try {
    const data = yield call(visitOrderApis.getVisitPrescriptionMsgListApi);
    yield put(getVisitPrescriptionMsgList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitPrescriptionMsgList.error(error.response?.data?.message)
      );
    else
      yield put(
        getVisitPrescriptionMsgList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getVisitMedicineInfoCall(Model) {
  try {
    const data = yield call(visitOrderApis.getVisitMedicineInfoApi, Model.data);
    yield put(getVisitMedicineInfo.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitMedicineInfo.error(error.response?.data?.message));
    else yield put(getVisitMedicineInfo.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitAcupointInfoCall(Model) {
  try {
    const data = yield call(visitOrderApis.getVisitAcupointInfoApi, Model.data);
    yield put(getVisitAcupointInfo.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitAcupointInfo.error(error.response?.data?.message));
    else yield put(getVisitAcupointInfo.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitTreatmentInfoCall(Model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitTreatmentInfoApi,
      Model.data
    );
    yield put(getVisitTreatmentInfo.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitTreatmentInfo.error(error.response?.data?.message));
    else yield put(getVisitTreatmentInfo.error(notification.CONST_NOINTERNET));
  }
}

function* postVisitChangeBranchWholeMedicineCall(Model) {
  try {
    const data = yield call(
      visitOrderApis.postVisitChangeBranchApi,
      Model.data
    );
    yield put(postVisitChangeBranchWholeMedicine.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postVisitChangeBranchWholeMedicine.error(error.response?.data?.message)
      );
    else
      yield put(
        postVisitChangeBranchWholeMedicine.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postVisitChangeBranchCall(Model) {
  try {
    const data = yield call(
      visitOrderApis.postVisitChangeBranchApi,
      Model.data
    );
    yield put(postVisitChangeBranch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postVisitChangeBranch.error(error.response?.data?.message));
    else yield put(postVisitChangeBranch.error(notification.CONST_NOINTERNET));
  }
}

function* postVisitChangeBranchTreatmentOnyCall(Model) {
  try {
    const data = yield call(
      visitOrderApis.postVisitChangeBranchApi,
      Model.data
    );
    yield put(postVisitChangeBranchTreatmentOnly.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postVisitChangeBranchTreatmentOnly.error(error.response?.data?.message)
      );
    else
      yield put(
        postVisitChangeBranchTreatmentOnly.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postVisitChangeBranchAcupointOnyCall(Model) {
  try {
    const data = yield call(
      visitOrderApis.postVisitChangeBranchApi,
      Model.data
    );
    yield put(postVisitChangeBranchAcupointOnly.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postVisitChangeBranchAcupointOnly.error(error.response?.data?.message)
      );
    else
      yield put(
        postVisitChangeBranchAcupointOnly.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdateVisitAcupointsCall(Model) {
  try {
    const data = yield call(
      visitOrderApis.putUpdateVisitAcupointsApi,
      Model.data
    );
    yield put(putUpdateVisitAcupoints.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putUpdateVisitAcupoints.error(error.response?.data?.message));
    else
      yield put(putUpdateVisitAcupoints.error(notification.CONST_NOINTERNET));
  }
}

function* getVisitPostalCodeSurchargeListCall(Model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitPostalCodeSurchargeListApi,
      Model.data
    );
    yield put(getVisitPostalCodeSurchargeList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitPostalCodeSurchargeList.error(error.response?.data?.message)
      );
    else
      yield put(
        getVisitPostalCodeSurchargeList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getVisitAvailableProceedOnCall(Model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitAvailableProceedOnApi,
      Model.data
    );
    yield put(getVisitAvailableProceedOn.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getVisitAvailableProceedOn.error(error.response?.data?.message)
      );
    else
      yield put(
        getVisitAvailableProceedOn.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getVisitVerifyAddressCall(Model) {
  try {
    const data = yield call(
      visitOrderApis.getVisitVerifyAddressApi,
      Model.data
    );
    yield put(getVisitVerifyAddress.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getVisitVerifyAddress.error(error.response?.data?.message));
    else yield put(getVisitVerifyAddress.error(notification.CONST_NOINTERNET));
  }
}
