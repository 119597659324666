import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  postCreatePresignedJson,
  postCreatePresignedUrl,
  postCreatePresignedJsonPhoto,
  postCreatePresignedUrlPhoto,
  postCreatePresignedJsonSignature,
  postCreatePresignedUrlSignature,
  postCreatePresignedUrlOrganisationLogo,
  postCreatePresignedJsonDeliveryTracking,
  postCreatePresignedJsonInvoice,
} from "../actions/cloudStorageActions";
import * as types from "../_constants/cloudStorageConstants";
import * as notification from "../_constants/notificationConstants";
import cloudStorageApis from "../_apis/cloudStorageApis";
export default function* cloudStorageSagas() {
  yield all([
    takeLatest(
      types.POST_CREATEPRESIGNEDURL_ASYNC.PENDING,
      postCreatePresignedUrlCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_CREATEPRESIGNEDJSON_ASYNC.PENDING,
      postCreatePresignedJsonCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_CREATEPRESIGNEDURLPHOTO_ASYNC.PENDING,
      postCreatePresignedUrlPhotoCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_CREATEPRESIGNEDJSONPHOTO_ASYNC.PENDING,
      postCreatePresignedJsonPhotoCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_CREATEPRESIGNEDURLSIGNATURE_ASYNC.PENDING,
      postCreatePresignedUrlSignatureCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_CREATEPRESIGNEDJSONSIGNATURE_ASYNC.PENDING,
      postCreatePresignedJsonSignatureCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_CREATEPRESIGNEDURLORGANISATIONLOGO_ASYNC.PENDING,
      postCreatePresignedUrlOrganisationLogoCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_CREATEPRESIGNEDJSONDELIVERYTRACKING_ASYNC.PENDING,
      postCreatePresignedJsonDeliveryTrackingCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_CREATEPRESIGNEDJSONINVOICE_ASYNC.PENDING,
      postCreatePresignedJsonInvoiceCall
    ),
  ]);

  function* postCreatePresignedUrlCall(searchModel) {
    try {
      const data = yield call(
        cloudStorageApis.postCreatePresignedUrlApi,
        searchModel.data
      );
      yield put(postCreatePresignedUrl.success(data));
    } catch (error) {
      if (error.response?.data?.message)
        yield put(postCreatePresignedUrl.error(error.response?.data?.message));
      else
        yield put(postCreatePresignedUrl.error(notification.CONST_NOINTERNET));
    }
  }

  function* postCreatePresignedJsonCall(searchModel) {
    try {
      const data = yield call(
        cloudStorageApis.postCreatePresignedJsonApi,
        searchModel.data
      );
      yield put(postCreatePresignedJson.success(data));
    } catch (error) {
      if (error.response?.data?.message)
        yield put(postCreatePresignedJson.error(error.response?.data?.message));
      else
        yield put(postCreatePresignedJson.error(notification.CONST_NOINTERNET));
    }
  }

  function* postCreatePresignedUrlPhotoCall(searchModel) {
    try {
      const data = yield call(
        cloudStorageApis.postCreatePresignedUrlPhotoApi,
        searchModel.data
      );
      yield put(postCreatePresignedUrlPhoto.success(data));
    } catch (error) {
      if (error.response?.data?.message)
        yield put(
          postCreatePresignedUrlPhoto.error(error.response?.data?.message)
        );
      else
        yield put(
          postCreatePresignedUrlPhoto.error(notification.CONST_NOINTERNET)
        );
    }
  }

  function* postCreatePresignedJsonPhotoCall(searchModel) {
    try {
      const data = yield call(
        cloudStorageApis.postCreatePresignedJsonPhotoApi,
        searchModel.data
      );
      yield put(postCreatePresignedJsonPhoto.success(data));
    } catch (error) {
      if (error.response?.data?.message)
        yield put(
          postCreatePresignedJsonPhoto.error(error.response?.data?.message)
        );
      else
        yield put(
          postCreatePresignedJsonPhoto.error(notification.CONST_NOINTERNET)
        );
    }
  }

  function* postCreatePresignedUrlSignatureCall(searchModel) {
    try {
      const data = yield call(
        cloudStorageApis.postCreatePresignedUrlSignatureApi,
        searchModel.data
      );
      yield put(postCreatePresignedUrlSignature.success(data));
    } catch (error) {
      if (error.response?.data?.message)
        yield put(
          postCreatePresignedUrlSignature.error(error.response?.data?.message)
        );
      else
        yield put(
          postCreatePresignedUrlSignature.error(notification.CONST_NOINTERNET)
        );
    }
  }

  function* postCreatePresignedJsonSignatureCall(searchModel) {
    try {
      const data = yield call(
        cloudStorageApis.postCreatePresignedJsonSignatureApi,
        searchModel.data
      );
      yield put(postCreatePresignedJsonSignature.success(data));
    } catch (error) {
      if (error.response?.data?.message)
        yield put(
          postCreatePresignedJsonSignature.error(error.response?.data?.message)
        );
      else
        yield put(
          postCreatePresignedJsonSignature.error(notification.CONST_NOINTERNET)
        );
    }
  }

  function* postCreatePresignedUrlOrganisationLogoCall(searchModel) {
    try {
      const data = yield call(
        cloudStorageApis.postCreatePresignedUrlOrganisationLogoApi,
        searchModel.data
      );
      yield put(postCreatePresignedUrlOrganisationLogo.success(data));
    } catch (error) {
      if (error.response?.data?.message)
        yield put(
          postCreatePresignedUrlOrganisationLogo.error(
            error.response?.data?.message
          )
        );
      else
        yield put(
          postCreatePresignedUrlOrganisationLogo.error(
            notification.CONST_NOINTERNET
          )
        );
    }
  }

  function* postCreatePresignedJsonDeliveryTrackingCall(searchModel) {
    try {
      const data = yield call(
        cloudStorageApis.postCreatePresignedJsonDeliveryTrackingApi,
        searchModel.data
      );
      yield put(postCreatePresignedJsonDeliveryTracking.success(data));
    } catch (error) {
      if (error.response?.data?.message)
        yield put(
          postCreatePresignedJsonDeliveryTracking.error(
            error.response?.data?.message
          )
        );
      else
        yield put(
          postCreatePresignedJsonDeliveryTracking.error(
            notification.CONST_NOINTERNET
          )
        );
    }
  }

  function* postCreatePresignedJsonInvoiceCall(searchModel) {
    try {
      const data = yield call(
        cloudStorageApis.postCreatePresignedJsonInvoiceApi,
        searchModel.data
      );
      yield put(postCreatePresignedJsonInvoice.success(data));
    } catch (error) {
      if (error.response?.data?.message)
        yield put(
          postCreatePresignedJsonInvoice.error(error.response?.data?.message)
        );
      else
        yield put(
          postCreatePresignedJsonInvoice.error(notification.CONST_NOINTERNET)
        );
    }
  }
}
