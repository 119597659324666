//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import QRCode from "qrcode";
//action
import {
  putUpdateUserMfa,
  getRandomMfaSecretKey,
  getUserMfaUserList,
} from "../../../../actions/userMfaActions";

//components
import Panel from "../../../../components/Panel";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import allowOnlyNumericsOrDigits from "../../../../components/functions/allowOnlyNumericsOrDigits";
import { verifyTOTP } from "../../../../components/functions//otpHandler";
import MfaAuthNote from "../../../../components/MfaAuthNote";
import { SortingSearchTable } from "../../../../components/SortingSearchTable";
import { MultiFieldSortingSearchTable } from "../../../../components/MultiFieldSortingSearchTable";

import StyledRadioCheckbox from "../../../../components/StyledRadioCheckbox";

import * as pageConfigure from "../../../../_constants/pageConstants";

const AddUserMfaPage = ({ setListView }) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(false);

  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_contact_number_value: null,
    search_date_of_birth_value: null,
    search_identification_number_value: null,
    search_name_value: null,
    search_username_value: null,
  });
  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });
  const [searchValue, setSearchValue] = React.useState({
    name: null,
    contactNo: null,
    dateofBirth: null,
    identificationNo: null,
  });
  const handleSearch = (value) => {
    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      search_contact_number_value: searchValue.contactNo,
      search_date_of_birth_value: searchValue.dateofBirth,
      search_identification_number_value: searchValue.identificationNo,
      search_name_value: searchValue.name,
    });
  };
  const [msgAlarm, setMsgAlarm] = useState("");
  const userMfa = useSelector((state) => state.userMfa);
  const common = useSelector((state) => state.common);

  const prevPostCreateStatus = useRef();
  const prevGetRandomSecretKey = useRef();
  const [data, setData] = useState(null);
  const [qrCodeSrc, setQrCodeSrc] = useState(null);
  const { register, handleSubmit, reset, control, getValues } = useForm({
    defaultValues: {
      //   mfa_issuer_name: null,
      name: "",
      mfa_secret_key: "",
      status_type: null,
    },
  });

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  const [verifiedOTP, setVerifiedOTP] = useState(null);

  const [userInfo, setUserInfo] = useState({ user: null });
  const columns = [
    {
      Header: "",
      id: "checkUser",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <>
            <StyledRadioCheckbox
              checked={
                userInfo.user !== null && userInfo.user.id === row.original.id
                  ? true
                  : false
              }
              name="checkUser"
              onChange={(e) => handleUserCheck(e, row)}
            />
          </>
        );
      },
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Username"),
      accessor: "username",
      sortable: false,
    },
  ];

  const viewColumns = [
    {
      Header: t("translation:Name"),
      accessor: "user.name",
      sortable: false,
    },

    {
      Header: t("translation:Username"),
      accessor: "user.username",
      sortable: false,
    },
  ];

  //functions

  const handleUserCheck = (e, row) => {
    if (e.target.checked) {
      setUserInfo(() => {
        return {
          user: row.original,
        };
      });
    } else {
      setUserInfo(() => {
        return {
          user: null,
        };
      });
    }
  };

  const generateOtpUrl = (name) => {
    const dataValues = getValues();
    let otpAuth = null;
    if (dataValues.mfa_secret_key && name) {
      otpAuth = `otpauth://totp/${name}?secret=${dataValues.mfa_secret_key}`;

      QRCode.toDataURL(
        otpAuth,
        { errorCorrectionLevel: "H" },
        function (err, url) {
          if (!err) setQrCodeSrc(url);
        }
      );
      setData(otpAuth);
    }
  };

  const getOpObj = (option, list) => {
    if (!option?.id && list) option = list.find((op) => op.id === option);
    return option;
  };
  const onSubmit = (data) => {
    setVerifiedOTP(null);
    if (userInfo.user) {
      setJsonText(data);
      setConfirmSubmit(true);
    } else {
      setErrorAlarm(true);
      setMsgAlarm(t("translation:Missing User"));
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
    }
  };

  const onConfirmSubmit = (data) => {
    if (verifyTOTP(verifiedOTP, data.mfa_secret_key)) {
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
      const submitJson = {
        user_mfa: {
          id: 0,
          status_type: data.status_type,
          // mfa_issuer_name: data.mfa_issuer_name.trim(),
          name: data.name.trim(),
          mfa_secret_key: data.mfa_secret_key,
          user: {
            id: userInfo.user.id,
          },
        },
      };
      dispatch(putUpdateUserMfa.pending(submitJson));
    } else {
      setSuccessAlarm(false);
      setErrorAlarm(true);
      setMsgAlarm(t("translation:Invalid OTP Token"));
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
    }
  };

  //useEffects

  useEffect(() => {
    dispatch(getRandomMfaSecretKey.pending());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserMfaUserList.pending(pageInfo));
  }, [dispatch, pageInfo]);

  useEffect(() => {
    //set ref when first render
    prevPostCreateStatus.current = false;
    prevGetRandomSecretKey.current = false;
  }, [register]);

  useEffect(() => {
    if (
      userMfa.isLoadingGetRandomMfaSecretKey !== prevGetRandomSecretKey.current
    ) {
      //check the previous ref with current state
      prevGetRandomSecretKey.current = userMfa.isLoadingGetRandomMfaSecretKey;

      if (
        userMfa.getRandomMfaSecretKeyIsSuccess === true &&
        userMfa.isLoadingGetRandomMfaSecretKey === false
      ) {
        reset({
          ...userMfa.getRandomMfaSecretKey,
        });
      }
    }
  }, [userMfa, reset]);

  useEffect(() => {
    if (userMfa.isLoadingPutUpdateUserMfa !== prevPostCreateStatus.current) {
      //check the previous ref with current state
      prevPostCreateStatus.current = userMfa.isLoadingPutUpdateUserMfa;

      if (
        userMfa.putUpdateUserMfaIsSuccess === true &&
        userMfa.isLoadingPutUpdateUserMfa === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...userMfa.putUpdateUserMfa.user_mfa,
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        userMfa.putUpdateUserMfaIsSuccess === false &&
        userMfa.isLoadingPutUpdateUserMfa === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(userMfa.error.putUpdateUserMfaError);
        window.scrollTo(0, 0);
      }
    }
  }, [userMfa, reset]);

  return (
    <Panel heading={t("translation:User MFA Details")}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Please enter OTP token")}.
        <Grid item xs={12} className={classes.userInfoField}>
          <TextField
            disabled={temViewOnly}
            required
            className={classes.userFieldColor}
            name="OTP"
            onInput={allowOnlyNumericsOrDigits}
            InputProps={{
              inputProps: {
                minLength: 6,
                maxLength: 6,
              },
            }}
            label={t("translation:OTP")}
            variant="outlined"
            value={verifiedOTP}
            onChange={(e) => {
              setVerifiedOTP(e.target.value);
            }}
          />
        </Grid>
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:User MFA has been created")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>

      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.gridRoot} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              required
              className={classes.userFieldColor}
              name="name"
              label={t("translation:MFA Name")}
              variant="outlined"
              {...register("name")}
              onChange={(e) => {
                generateOtpUrl(e.target.value);
              }}
            />
          </Grid>

          {!temViewOnly && (
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                name="mfa_secret_key"
                label={t("translation:MFA Secret Key")}
                variant="outlined"
                {...register("mfa_secret_key")}
                InputProps={{
                  readOnly: true,
                  className: "Mui-disabled",
                }}
                InputLabelProps={{
                  shrink: true,
                  className: "Mui-disabled",
                }}
              />
            </Grid>
          )}

          {!temViewOnly && data !== null && (
            <Grid item xs={12} className={classes.userInfoField}>
              <img
                src={qrCodeSrc}
                height="400px"
                weight="400px"
                alt="physician"
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly}
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                    return value;
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>

          {userInfo.user && (
            <Grid item xs={12}>
              <SortingSearchTable
                disabledSearch
                columns={viewColumns}
                data={[userInfo]}
                pageInfo={viewPageInfo}
                setPageInfo={setViewPageInfo}
                totalItems={1}
                disabledFooter
              />
            </Grid>
          )}

          {!temViewOnly && (
            <Grid item xs={12}>
              <MultiFieldSortingSearchTable
                searchPlaceholder={""}
                searchValue={searchValue}
                columns={columns}
                data={
                  userMfa.getUserMfaUserList
                    ? userMfa.getUserMfaUserList.users
                    : []
                }
                pageInfo={pageInfo}
                setPageInfo={setPageInfo}
                pageCount={
                  userMfa.getUserMfaUserList
                    ? userMfa.getUserMfaUserList.total_pages
                    : 0
                }
                loading={userMfa.isLodingGetUserMfaUserList}
                totalItems={
                  userMfa.getUserMfaUserList
                    ? userMfa.getUserMfaUserList.total_items
                    : 0
                }
                handleSearch={handleSearch}>
                <TextField
                  className={classes.userFieldColor}
                  label={t("translation:Name")}
                  variant="outlined"
                  value={searchValue.name ? searchValue.name : ""}
                  onChange={(e) =>
                    setSearchValue({ ...searchValue, name: e.target.value })
                  }
                />

                <TextField
                  className={classes.userFieldColor}
                  label={t("translation:Contact Number")}
                  variant="outlined"
                  value={searchValue.contactNo ? searchValue.contactNo : ""}
                  onChange={(e) =>
                    setSearchValue({
                      ...searchValue,
                      contactNo: e.target.value,
                    })
                  }
                />

                <TextField
                  className={classes.userFieldColor}
                  label={t("translation:Identification Number")}
                  variant="outlined"
                  autoComplete="off"
                  value={
                    searchValue.identificationNo
                      ? searchValue.identificationNo
                      : ""
                  }
                  onChange={(e) =>
                    setSearchValue({
                      ...searchValue,
                      identificationNo: e.target.value,
                    })
                  }
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={classes.userFieldColor}
                    inputVariant="outlined"
                    id="date"
                    label={t("translation:Date of Birth")}
                    autoOk={true}
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    value={
                      searchValue.dateofBirth
                        ? dayjs(searchValue.dateofBirth)
                        : null
                    }
                    format="DD MMM YYYY"
                    onChange={(e) =>
                      setSearchValue({
                        ...searchValue,
                        dateofBirth: dayjs(e, true).isValid()
                          ? dayjs(e).format("YYYY-MM-DD")
                          : null,
                      })
                    }
                  />
                </LocalizationProvider>
              </MultiFieldSortingSearchTable>
            </Grid>
          )}

          {!temViewOnly && <MfaAuthNote />}
          <>
            <RequiredNote />
            {temViewOnly ? (
              <PageOnlyBackAction setView={() => setListView(true)} />
            ) : (
              <PageSubmitWBackAction setView={() => setListView(true)} />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};

export default AddUserMfaPage;
