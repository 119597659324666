import * as types from "../_constants/medicineConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_MEDICINEBYID_ASYNC.PENDING:
      return {
        ...state,
        getMedicineByIdIsSuccess: false,
        isLoadingGetMedicineById: true,
        getMedicineById: null,
      };

    case types.GET_MEDICINEBYID_ASYNC.ERROR:
      return {
        ...state,
        getMedicineByIdIsSuccess: false,
        isLoadingGetMedicineById: false,
        getMedicineById: null,
        error: { ...state.error, getMedicineByIdError: action.error },
      };

    case types.GET_MEDICINEBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineByIdIsSuccess: true,
        isLoadingGetMedicineById: false,
        getMedicineById: action.data,
      };

    case types.GET_MEDICINELIST_ASYNC.PENDING:
      return {
        ...state,
        getMedicineListIsSuccess: false,
        isLoadingGetMedicineList: true,
        getMedicineList: null,
      };

    case types.GET_MEDICINELIST_ASYNC.ERROR:
      return {
        ...state,
        getMedicineListIsSuccess: false,
        isLoadingGetMedicineList: false,
        getMedicineList: null,
        error: { ...state.error, getMedicineListError: action.error },
      };

    case types.GET_MEDICINELIST_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineListIsSuccess: true,
        isLoadingGetMedicineList: false,
        getMedicineList: action.data,
      };

    case types.POST_CREATEMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        postCreateMedicineIsSuccess: false,
        isLoadingPostCreateMedicine: true,
        postCreateMedicine: null,
      };

    case types.POST_CREATEMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        postCreateMedicineIsSuccess: false,
        isLoadingPostCreateMedicine: false,
        postCreateMedicine: null,
        error: { ...state.error, postCreateMedicineError: action.error },
      };

    case types.POST_CREATEMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateMedicineIsSuccess: true,
        isLoadingPostCreateMedicine: false,
        postCreateMedicine: action.data,
      };

    case types.PUT_UPDATEMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        putUpdateMedicineIsSuccess: false,
        isLoadingPutUpdateMedicine: true,
        putUpdateMedicine: null,
      };

    case types.PUT_UPDATEMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        putUpdateMedicineIsSuccess: false,
        isLoadingPutUpdateMedicine: false,
        putUpdateMedicine: null,
        error: { ...state.error, putUpdateMedicineError: action.error },
      };

    case types.PUT_UPDATEMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateMedicineIsSuccess: true,
        isLoadingPutUpdateMedicine: false,
        putUpdateMedicine: action.data,
      };

    case types.GET_MEDICINEMEDICINETYPES_ASYNC.PENDING:
      return {
        ...state,
        getMedicineMedicineTypesIsSuccess: false,
        isLoadingGetMedicineMedicineTypes: true,
        getMedicineMedicineTypes: null,
      };

    case types.GET_MEDICINEMEDICINETYPES_ASYNC.ERROR:
      return {
        ...state,
        getMedicineMedicineTypesIsSuccess: false,
        isLoadingGetMedicineMedicineTypes: false,
        getMedicineMedicineTypes: null,
        error: { ...state.error, getMedicineMedicineTypesError: action.error },
      };

    case types.GET_MEDICINEMEDICINETYPES_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineMedicineTypesIsSuccess: true,
        isLoadingGetMedicineMedicineTypes: false,
        getMedicineMedicineTypes: action.data,
      };

    default:
      return state;
  }
};
