import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getPurchaseOrderList,
  getPurchaseOrderById,
  getPurchaseOrderMedicineTypes,
  postPurchaseOrderMedicines,
  postSubmitPurchaseOrder,
  putPurchaseOrderWIPMode,
  putPurchaseOrderCancelled,
  putPurchaseOrderPrintedDate,
} from "../actions/purchaseFormActions";
import * as types from "../_constants/purchaseOrderConstants";
import * as notification from "../_constants/notificationConstants";
import purchaseOrderApis from "../_apis/purchaseOrderApis";
export default function* orderFormSagas() {
  yield all([
    takeLatest(
      types.GET_PURCHASEORDERLIST_ASYNC.PENDING,
      getPurchaseOrderListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_PURCHASEORDERBYID_ASYNC.PENDING,
      getPurchaseOrderByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_PURCHASEORDERMEDICINETYPES_ASYNC.PENDING,
      getPurchaseOrderMedicineTypesCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_PURCHASEORDERMEDICINES_ASYNC.PENDING,
      postPurchaseOrderMedicinesCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SUBMITPURCHASEORDER_ASYNC.PENDING,
      postSubmitPurchaseOrderCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_PURCHASEORDERWIPMODE_ASYNC.PENDING,
      putPurchaseOrderWIPModeCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_PURCHASEORDERCANCELLED_ASYNC.PENDING,
      putPurchaseOrderCancelledCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_PURCHASEORDERPRINTEDDATE_ASYNC.PENDING,
      putPurchaseOrderPrintedDateCall
    ),
  ]);
}

function* getPurchaseOrderListCall(Model) {
  try {
    const data = yield call(
      purchaseOrderApis.getPurchaseOrderListApi,
      Model.data
    );
    yield put(getPurchaseOrderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPurchaseOrderList.error(error.response?.data?.message));
    else yield put(getPurchaseOrderList.error(notification.CONST_NOINTERNET));
  }
}

function* getPurchaseOrderByIdCall(Model) {
  try {
    const data = yield call(
      purchaseOrderApis.getPurchaseOrderByIdApi,
      Model.data
    );
    yield put(getPurchaseOrderById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPurchaseOrderById.error(error.response?.data?.message));
    else yield put(getPurchaseOrderById.error(notification.CONST_NOINTERNET));
  }
}

function* getPurchaseOrderMedicineTypesCall(Model) {
  try {
    const data = yield call(
      purchaseOrderApis.getPurchaseOrderMedicineTypesApi,
      Model.data
    );
    yield put(getPurchaseOrderMedicineTypes.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getPurchaseOrderMedicineTypes.error(error.response?.data?.message)
      );
    else
      yield put(
        getPurchaseOrderMedicineTypes.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postPurchaseOrderMedicinesCall(Model) {
  try {
    const data = yield call(
      purchaseOrderApis.postPurchaseOrderMedicinesApi,
      Model.data
    );
    yield put(postPurchaseOrderMedicines.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postPurchaseOrderMedicines.error(error.response?.data?.message)
      );
    else
      yield put(
        postPurchaseOrderMedicines.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postSubmitPurchaseOrderCall(Model) {
  try {
    const data = yield call(
      purchaseOrderApis.postSubmitPurchaseOrderApi,
      Model.data
    );
    yield put(postSubmitPurchaseOrder.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitPurchaseOrder.error(error.response?.data?.message));
    else
      yield put(postSubmitPurchaseOrder.error(notification.CONST_NOINTERNET));
  }
}

function* putPurchaseOrderWIPModeCall(Model) {
  try {
    const data = yield call(
      purchaseOrderApis.putPurchaseOrderWIPModeApi,
      Model.data
    );
    yield put(putPurchaseOrderWIPMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putPurchaseOrderWIPMode.error(error.response?.data?.message));
    else
      yield put(putPurchaseOrderWIPMode.error(notification.CONST_NOINTERNET));
  }
}

function* putPurchaseOrderCancelledCall(Model) {
  try {
    const data = yield call(
      purchaseOrderApis.putPurchaseOrderCancelledApi,
      Model.data
    );
    yield put(putPurchaseOrderCancelled.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putPurchaseOrderCancelled.error(error.response?.data?.message));
    else
      yield put(putPurchaseOrderCancelled.error(notification.CONST_NOINTERNET));
  }
}

function* putPurchaseOrderPrintedDateCall(Model) {
  try {
    const data = yield call(
      purchaseOrderApis.putPurchaseOrderPrintedDateApi,
      Model.data
    );
    yield put(putPurchaseOrderPrintedDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putPurchaseOrderPrintedDate.error(error.response?.data?.message)
      );
    else
      yield put(
        putPurchaseOrderPrintedDate.error(notification.CONST_NOINTERNET)
      );
  }
}
