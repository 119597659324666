//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, TextField } from "@mui/material";
//import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import IconButton from "@mui/material/IconButton";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import moment from "moment";
//action

import {
  postCreateUserCredit,
  getUserCreditUserList,
  getUserCreditBalanceById,
} from "../../../../actions/userCreditActions";

//components
import Panel from "../../../../components/Panel";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import { useStyles } from "../../../../components/globalStyles";

import { SortingSearchTable } from "../../../../components/SortingSearchTable";

import { MultiFieldSortingSearchTable } from "../../../../components/MultiFieldSortingSearchTable";

import StyledRadioCheckbox from "../../../../components/StyledRadioCheckbox";

import * as pageConfigure from "../../../../_constants/pageConstants";

import * as generalConstants from "../../../../_constants/generalConstants";

const UserCreditComponentPage = ({
  setListView,
  userCreditInfoById = null,
  viewMode = false,
}) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  //const common = useSelector((state) => state.common);

  const userCredit = useSelector((state) => state.userCredit);
  const curUser = useSelector((state) => state.curUser);
  const common = useSelector((state) => state.common);
  const defaultOrganisation = curUser.organisationInfo.user_organisations.find(
    (item) => item.is_default === true
  )?.organisations;
  const [searchValue, setSearchValue] = React.useState({
    name: null,
    contactNo: null,
    dateofBirth: null,
    identificationNo: null,
  });
  const prevPostUserCreditStatus = useRef();

  const prevGetUserCreditBalanceStatus = useRef();

  const [isViewOnly, setIsViewOnly] = useState(false);

  const { handleSubmit } = useForm();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [userCreditInfo, setUserCreditInfo] = useState({
    credit_amt: null,
    credit_balance: null,
    organisation: null,
    user: null,
    user_credit_type: generalConstants.CREDIT_ADJUST_TYPE,
    remarks: null,
  });

  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });

  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_contact_number_value: null,
    search_date_of_birth_value: null,
    search_identification_number_value: null,
    search_name_value: null,
    search_username_value: null,
  });

  const handleSearch = (value) => {
    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      search_contact_number_value: searchValue.contactNo,
      search_date_of_birth_value: searchValue.dateofBirth,
      search_identification_number_value: searchValue.identificationNo,
      search_name_value: searchValue.name,
    });
  };

  const handleUserCheck = (e, row) => {
    if (e.target.checked) {
      //  setUserCreditInfo({ ...userCreditInfo, user: row.original });
      setUserCreditInfo((creditInfo) => {
        return {
          ...creditInfo,
          organisation: defaultOrganisation,
          user: row.original,
          credit_balance: null,
        };
      });
      const submissionJSON = {
        search_user_id_value: row.original.id,
      };
      dispatch(getUserCreditBalanceById.pending(submissionJSON));
    } else {
      setUserCreditInfo((creditInfo) => {
        return {
          ...creditInfo,
          organisation: defaultOrganisation,
          user: null,
          credit_balance: null,
        };
      });
    }
  };

  // const getCreditTypeOpObj = (option) => {
  //   if (!option.id)
  //     option = common.userCreditType?.user_credit_type_select_field.find(
  //       (op) => op.id === option
  //     );
  //   return option;
  // };

  const columns = [
    {
      Header: "",
      id: "checkUser",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <>
            <StyledRadioCheckbox
              checked={
                userCreditInfo.user !== null &&
                userCreditInfo.user.id === row.original.id
                  ? true
                  : false
              }
              name="checkUser"
              onChange={(e) => handleUserCheck(e, row)}
            />
          </>
        );
      },
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Contact Number"),
      accessor: "contact_number",
    },
    {
      Header: t("translation:Identification Number"),
      accessor: "identification_number",
    },
  ];

  const viewColumns = [
    {
      Header: t("translation:Name"),
      accessor: "user.name",
      sortable: false,
    },

    {
      Header: t("translation:Contact Number"),
      accessor: "user.contact_number",
      sortable: false,
    },
    {
      Header: t("translation:Identification Number"),
      accessor: "user.identification_number",
      sortable: false,
    },

    {
      Header: t("translation:Credit Balance"),
      accessor: "credit_balance",
      sortable: false,
      Cell: ({ cell }) => {
        return Number(cell.value).toFixed(2);
      },
    },
  ];
  //functions

  const onSubmit = () => {
    if (userCreditInfo.user) {
      setConfirmSubmit(true);
    } else {
      setSubmitErrorAlarm(true);
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
    }
  };

  const onConfirmSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    const result = {
      user_credit: {
        ...userCreditInfo,
        user: { id: userCreditInfo.user.id },
        user_credit_type: generalConstants.CREDIT_ADJUST_TYPE,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
        organisation: { id: defaultOrganisation.id },
      },
    };
    delete result.user_credit.credit_balance;
    dispatch(postCreateUserCredit.pending(result));
  };

  //useEffects

  useEffect(() => {
    //set ref when first render
    prevPostUserCreditStatus.current = false;
    prevGetUserCreditBalanceStatus.current = false;
  }, []);

  useEffect(() => {
    if (!viewMode) {
      dispatch(getUserCreditUserList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, viewMode]);

  useEffect(() => {
    if (
      userCredit.isLoadingPostCreateUserCredit !==
      prevPostUserCreditStatus.current
    ) {
      //check the previous ref with current state
      prevPostUserCreditStatus.current =
        userCredit.isLoadingPostCreateUserCredit;

      if (
        userCredit.postCreateUserCreditSuccess === true &&
        userCredit.isLoadingPostCreateUserCredit === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        setIsViewOnly(true);
        window.scrollTo(0, 0);
        setUserCreditInfo({
          ...userCredit.postCreateUserCredit.user_credit,
        });

        const submissionJSON = {
          search_user_id_value:
            userCredit.postCreateUserCredit.user_credit.user.id,
        };
        dispatch(getUserCreditBalanceById.pending(submissionJSON));
      }
      if (
        userCredit.postCreateUserCreditSuccess === false &&
        userCredit.isLoadingPostCreateUserCredit === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setIsViewOnly(false);
        setMsgAlarm(userCredit.error.postCreateUserCreditError);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, userCredit]);

  useEffect(() => {
    if (
      userCredit.isLoadingUserCreditBalanceById !==
      prevGetUserCreditBalanceStatus.current
    ) {
      //check the previous ref with current state
      prevGetUserCreditBalanceStatus.current =
        userCredit.isLoadingUserCreditBalanceById;

      if (
        userCredit.getUserCreditBalanceByIdIsSuccess === true &&
        userCredit.isLoadingUserCreditBalanceById === false
      ) {
        const defualtOrganisationCreditBalance =
          userCredit.userCreditBalanceById?.user_credits?.find(
            (item) => item.organisation.id === defaultOrganisation.id
          );
        setUserCreditInfo((u) => ({
          ...u,
          ...defualtOrganisationCreditBalance,
          organisation: { id: defaultOrganisation.id },
        }));
      }
    }
  }, [userCredit, defaultOrganisation]);

  useEffect(() => {
    if (userCreditInfoById) {
      setIsViewOnly(viewMode);
      setUserCreditInfo(userCreditInfoById);
    }
  }, [userCreditInfoById, viewMode]);

  useEffect(() => {
    if (viewMode && userCreditInfoById) {
      const submissionJSON = {
        search_user_id_value: userCreditInfoById.user.id,
      };
      dispatch(getUserCreditBalanceById.pending(submissionJSON));
    }
  }, [dispatch, viewMode, userCreditInfoById]);

  return (
    <Panel>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:User Credit has been submitted")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <Collapse in={submitErrorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSubmitErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Missing User")}
        </Alert>
      </Collapse>

      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.gridRoot} container>
          {/* <Grid xs={6} item className={classes.userInfoField}>
            <Autocomplete
              name="creditType"
              disabled={isViewOnly}
              disableClearable
              value={
                userCreditInfo.user_credit_type
                  ? userCreditInfo.user_credit_type
                  : ""
              }
              options={
                common.userCreditType
                  ? common.userCreditType.user_credit_type_select_field
                  : []
              }
              getOptionLabel={(option) => getCreditTypeOpObj(option)?.name}
              getOptionSelected={(option, value) => {
                if (option.id === value) return option;
              }}
              style={{ width: "100%" }}
              onChange={(e, option) =>
                setUserCreditInfo({
                  ...userCreditInfo,
                  user_credit_type: option.id,
                })
              }
              renderInput={(params) => (
                <TextField
                  required
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Credit Type")}
                  variant="outlined"
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              required
              disabled={isViewOnly}
              value={
                userCreditInfo.credit_amt !== null
                  ? userCreditInfo.credit_amt
                  : ""
              }
              type="number"
              onWheel={(event) => event.target.blur()}
              InputProps={{
                inputProps: {
                  step: "any",
                  style: { textAlign: "right" },
                },
              }}
              onKeyDown={(event) => {
                if (
                  event?.key === "+" ||
                  event?.key === "e" ||
                  event?.key === "E"
                ) {
                  event.preventDefault();
                }
              }}
              name="credit_amt"
              label={t("translation:Credit Amount")}
              variant="outlined"
              onChange={(e) =>
                setUserCreditInfo({
                  ...userCreditInfo,
                  credit_amt:
                    e.target.value !== "" ? Number(e.target.value) : "",
                })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              disabled
              value={
                common.userCreditType.user_credit_type_select_field.find(
                  (item) => item.id === userCreditInfo.user_credit_type
                ).name
              }
              name="credit_type"
              label={t("translation:Credit Type")}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              disabled={isViewOnly}
              value={
                userCreditInfo.remarks !== null ? userCreditInfo.remarks : ""
              }
              name="remarks"
              label={t("translation:Remarks")}
              variant="outlined"
              onChange={(e) =>
                setUserCreditInfo({
                  ...userCreditInfo,
                  remarks: e.target.value,
                })
              }
            />
          </Grid>
          {isViewOnly && (
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                disabled={true}
                value={
                  userCreditInfo.store_header !== null
                    ? userCreditInfo.store_header.order_number
                    : ""
                }
                name="orderNo"
                label={t("translation:Order No")}
                variant="outlined"
                onChange={(e) =>
                  setUserCreditInfo({
                    ...userCreditInfo,
                    remarks: e.target.value,
                  })
                }
              />
            </Grid>
          )}
          {userCreditInfo.user && (
            <Grid item xs={12}>
              <SortingSearchTable
                disabledSearch
                columns={viewColumns}
                data={[userCreditInfo]}
                pageInfo={viewPageInfo}
                setPageInfo={setViewPageInfo}
                totalItems={1}
                disabledFooter
              />
            </Grid>
          )}
          {!isViewOnly && (
            <Grid item xs={12}>
              <MultiFieldSortingSearchTable
                searchPlaceholder={""}
                searchValue={searchValue}
                columns={columns}
                data={
                  userCredit.userCreditUserList
                    ? userCredit.userCreditUserList.users
                    : []
                }
                pageInfo={pageInfo}
                setPageInfo={setPageInfo}
                pageCount={
                  userCredit.userCreditUserList
                    ? userCredit.userCreditUserList.total_pages
                    : 0
                }
                loading={userCredit.isLoadingUserCreditUserList}
                totalItems={
                  userCredit.userCreditUserList
                    ? userCredit.userCreditUserList.total_items
                    : 0
                }
                handleSearch={handleSearch}>
                <TextField
                  className={classes.userFieldColor}
                  label={t("translation:Name")}
                  variant="outlined"
                  value={searchValue.name ? searchValue.name : ""}
                  onChange={(e) =>
                    setSearchValue({ ...searchValue, name: e.target.value })
                  }
                />

                <TextField
                  className={classes.userFieldColor}
                  label={t("translation:Contact Number")}
                  variant="outlined"
                  value={searchValue.contactNo ? searchValue.contactNo : ""}
                  onChange={(e) =>
                    setSearchValue({
                      ...searchValue,
                      contactNo: e.target.value,
                    })
                  }
                />

                <TextField
                  className={classes.userFieldColor}
                  label={t("translation:Identification Number")}
                  variant="outlined"
                  autoComplete="off"
                  value={
                    searchValue.identificationNo
                      ? searchValue.identificationNo
                      : ""
                  }
                  onChange={(e) =>
                    setSearchValue({
                      ...searchValue,
                      identificationNo: e.target.value,
                    })
                  }
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={classes.userFieldColor}
                    inputVariant="outlined"
                    id="date"
                    label={t("translation:Date of Birth")}
                    autoOk={true}
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    value={
                      searchValue.dateofBirth
                        ? dayjs(searchValue.dateofBirth)
                        : null
                    }
                    format="DD MMM YYYY"
                    onChange={(e) =>
                      setSearchValue({
                        ...searchValue,
                        dateofBirth: dayjs(e, true).isValid()
                          ? dayjs(e).format("YYYY-MM-DD")
                          : null,
                      })
                    }
                  />
                </LocalizationProvider>
              </MultiFieldSortingSearchTable>
            </Grid>
          )}
          <RequiredNote />
          {!isViewOnly && (
            <PageSubmitWBackAction setView={() => setListView(true)} />
          )}
          {isViewOnly && (
            <PageOnlyBackAction setView={() => setListView(true)} />
          )}
        </Grid>
      </form>
    </Panel>
  );
};

export default UserCreditComponentPage;
