//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
//actions
import { getMedicineFixedSellingPriceHeader } from "../../../actions/reportingActions";
//component

import { useStyles } from "../../../components/globalStyles";

import ImportFixedMedicinePrice from "./components/ImportFixedMedicinePrice";

import SyncFixedMedicinePrice from "./components/SyncFixedMedicinePrice";
import * as pageConfigure from "../../../_constants/pageConstants";
import * as generalConstants from "../../../_constants/generalConstants";
import * as pageConstants from "../../../_constants/pageConstants";

const FixedMedicinePriceReportPage = () => {
  //variables

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const reporting = useSelector((state) => state.reporting);
  const [medicineFixedPriceHeader, setMedicineFixedPriceHeader] =
    React.useState(null);

  useEffect(() => {
    const submitJson = {
      is_pagination: false,
      order_by_direction: null,
      order_by_field: null,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      page_size: pageConfigure.DEFAULT_PAGESIZE,
      search_desc_value: null,
      search_id_value: null,
      search_name_value: null,
      search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
    };
    dispatch(getMedicineFixedSellingPriceHeader.pending(submitJson));
  }, [dispatch]);

  return (
    <Paper className={classes.summaryPaper} elevation={3}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={11}>
          <Typography className={classes.title} variant="h3" gutterBottom>
            {t("translation:Medicine Fixed Selling Price Report")}
          </Typography>
        </Grid>
      </Grid>
      <hr />
      <Grid item xs={4} className={classes.userInfoField}>
        {reporting.getMedicineFixedSellingPriceHeader
          ?.medicine_fixed_selling_price_headers && (
          <Autocomplete
            value={medicineFixedPriceHeader}
            name="name"
            options={
              reporting.getMedicineFixedSellingPriceHeader
                ?.medicine_fixed_selling_price_headers
            }
            getOptionLabel={(option) => option.name}
            style={{ width: "100%" }}
            onChange={(e, option) =>
              setMedicineFixedPriceHeader(option === null ? null : option)
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                required
                className={classes.userFieldColor}
                {...params}
                name="name"
                label={t("translation:Name")}
                variant="outlined"
              />
            )}
          />
        )}
      </Grid>

      {medicineFixedPriceHeader && (
        <>
          <SyncFixedMedicinePrice
            medicineFixedPriceHeader={medicineFixedPriceHeader}
          />
          <ImportFixedMedicinePrice
            medicineFixedPriceHeader={medicineFixedPriceHeader}
          />
        </>
      )}
    </Paper>
  );
};

export default FixedMedicinePriceReportPage;
