import * as types from "../_constants/workOrderConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_DISPENSARYBRANCHES_ASYNC.PENDING:
      return {
        ...state,
        getDispensaryBranchesIsSuccess: false,
        isLoadingGetDispensaryBranches: true,
        dispensaryBranches: null,
      };

    case types.GET_DISPENSARYBRANCHES_ASYNC.ERROR:
      return {
        ...state,
        getDispensaryBranchesIsSuccess: false,
        isLoadingGetDispensaryBranches: false,
        dispensaryBranches: null,
        error: { ...state.error, dispensaryBranchesError: action.error },
      };

    case types.GET_DISPENSARYBRANCHES_ASYNC.SUCCESS:
      return {
        ...state,
        getDispensaryBranchesIsSuccess: true,
        isLoadingGetDispensaryBranches: false,
        dispensaryBranches: action.data,
      };

    case types.GET_MEDICINETYPES_ASYNC.PENDING:
      return {
        ...state,
        getMedicineTypesIsSuccess: false,
        isLoadingGetMedicineTypes: true,
        medicineTypes: null,
      };

    case types.GET_MEDICINETYPES_ASYNC.ERROR:
      return {
        ...state,
        getMedicineTypesIsSuccess: false,
        isLoadingGetMedicineTypes: false,
        medicineTypes: null,
        error: { ...state.error, medicineTypesError: action.error },
      };

    case types.GET_MEDICINETYPES_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineTypesIsSuccess: true,
        isLoadingGetMedicineTypes: false,
        medicineTypes: action.data,
      };

    case types.GET_DISPENSARYBRANCHESALLIANCE_ASYNC.PENDING:
      return {
        ...state,
        getDispensaryBranchesAllianceIsSuccess: false,
        isLoadingGetDispensaryBranchesAlliance: true,
        dispensaryBranchesAlliance: null,
      };

    case types.GET_DISPENSARYBRANCHESALLIANCE_ASYNC.ERROR:
      return {
        ...state,
        getDispensaryBranchesAllianceIsSuccess: false,
        isLoadingGetDispensaryBranchesAlliance: false,
        dispensaryBranchesAlliance: null,
        error: {
          ...state.error,
          dispensaryBranchesAllianceError: action.error,
        },
      };

    case types.GET_DISPENSARYBRANCHESALLIANCE_ASYNC.SUCCESS:
      return {
        ...state,
        getDispensaryBranchesAllianceIsSuccess: true,
        isLoadingGetDispensaryBranchesAlliance: false,
        dispensaryBranchesAlliance: action.data,
      };

    case types.GET_MEDICINETYPESALLIANCE_ASYNC.PENDING:
      return {
        ...state,
        getMedicineTypesAllianceIsSuccess: false,
        isLoadingGetMedicineTypesAlliance: true,
        medicineTypesAlliance: null,
      };

    case types.GET_MEDICINETYPESALLIANCE_ASYNC.ERROR:
      return {
        ...state,
        getMedicineTypesAllianceIsSuccess: false,
        isLoadingGetMedicineTypesAlliance: false,
        medicineTypesAlliance: null,
        error: { ...state.error, medicineTypesAllianceError: action.error },
      };

    case types.GET_MEDICINETYPESALLIANCE_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineTypesAllianceIsSuccess: true,
        isLoadingGetMedicineTypesAlliance: false,
        medicineTypesAlliance: action.data,
      };
    case types.POST_FORMULASALLIANCE_ASYNC.PENDING:
      return {
        ...state,
        getFormulasAllianceIsSuccess: false,
        isLoadingGetFormulasAlliance: true,
        formulasAlliance: null,
      };

    case types.POST_FORMULASALLIANCE_ASYNC.ERROR:
      return {
        ...state,
        getFormulasAllianceIsSuccess: false,
        isLoadingGetFormulasAlliance: false,
        formulasAlliance: null,
        error: { ...state.error, formulasAllianceError: action.error },
      };

    case types.POST_FORMULASALLIANCE_ASYNC.SUCCESS:
      return {
        ...state,
        getFormulasAllianceIsSuccess: true,
        isLoadingGetFormulasAlliance: false,
        formulasAlliance: action.data,
      };

    case types.POST_MEDICINEFORMULASALLIANCE_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFormulasAllianceIsSuccess: false,
        isLoadingMedicineFormulasAlliance: true,
        //medicineFormulasAlliance will be null when loading
        medicineFormulasAlliance: null,
      };

    case types.POST_MEDICINEFORMULASALLIANCE_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFormulasAllianceIsSuccess: false,
        isLoadingMedicineFormulasAlliance: false,
        error: { ...state.error, medicineFormulasAllianceError: action.error },
        //medicineFormulasAlliance will be null when fail
        medicineFormulasAlliance: null,
      };

    case types.POST_MEDICINEFORMULASALLIANCE_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFormulasAllianceIsSuccess: true,
        isLoadingMedicineFormulasAlliance: false,
        medicineFormulasAlliance: action.data,
      };

    case types.POST_BRANCHMEDICINES_ASYNC.PENDING:
      return {
        ...state,
        postBranchMedicinesIsSuccess: false,
        isLoadingPostBranchMedicines: true,
        //branchMedicines will be null when loading
        branchMedicines: null,
      };

    case types.POST_BRANCHMEDICINES_ASYNC.ERROR:
      return {
        ...state,
        postBranchMedicinesIsSuccess: false,
        isLoadingPostBranchMedicines: false,
        //branchMedicines will be null when fail
        branchMedicines: null,
        error: { ...state.error, branchMedicinesError: action.error },
      };

    case types.POST_BRANCHMEDICINES_ASYNC.SUCCESS:
      return {
        ...state,
        postBranchMedicinesIsSuccess: true,
        isLoadingPostBranchMedicines: false,
        branchMedicines: action.data,
      };

    case types.GET_CURRENTORGANISATION_ASYNC.PENDING:
      return {
        ...state,
        getCurrentOrganisationIsSuccess: false,
        isLoadingGetCurrentOrganisation: true,
        currentOrganistion: null,
      };

    case types.GET_CURRENTORGANISATION_ASYNC.ERROR:
      return {
        ...state,
        getCurrentOrganisationIsSuccess: false,
        currentOrganistion: null,
        isLoadingGetCurrentOrganisation: false,
        error: { ...state.error, currentOrganistionError: action.error },
      };

    case types.GET_CURRENTORGANISATION_ASYNC.SUCCESS:
      return {
        ...state,
        getCurrentOrganisationIsSuccess: true,
        isLoadingGetCurrentOrganisation: false,
        currentOrganistion: action.data,
      };

    case types.POST_SAVESTOREORDER_ASYNC.PENDING:
      return {
        ...state,
        postSaveStoreOrderIsSuccess: false,
        isLoadingPostSaveStoreOrder: true,
        isLoadingSaveStoreOrder: true,
        saveStoreOrder: null,
      };

    case types.POST_SAVESTOREORDER_ASYNC.ERROR:
      return {
        ...state,
        postSaveStoreOrderIsSuccess: false,
        isLoadingPostSaveStoreOrder: false,
        isLoadingSaveStoreOrder: false,
        saveStoreOrder: null,
        error: { ...state.error, saveStoreOrderError: action.error },
      };

    case types.POST_SAVESTOREORDER_ASYNC.SUCCESS:
      return {
        ...state,
        postSaveStoreOrderIsSuccess: true,
        isLoadingPostSaveStoreOrder: false,
        isLoadingSaveStoreOrder: false,
        saveStoreOrder: action.data,
      };

    case types.POST_SUBMITSTOREORDER_ASYNC.PENDING:
      return {
        ...state,
        postSubmitStoreOrderIsSuccess: false,
        isLoadingSubmitStoreOrder: true,
        submitStoreOrder: null,
      };

    case types.POST_SUBMITSTOREORDER_ASYNC.ERROR:
      return {
        ...state,
        postSubmitStoreOrderIsSuccess: false,
        isLoadingSubmitStoreOrder: false,
        submitStoreOrder: null,
        error: { ...state.error, submitStoreOrderError: action.error },
      };

    case types.POST_SUBMITSTOREORDER_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitStoreOrderIsSuccess: true,
        isLoadingSubmitStoreOrder: false,
        submitStoreOrder: action.data,
      };

    case types.GET_STOREORDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderListIsSuccess: false,
        isLoadingStoreOrderList: true,
        storeOrderList: null,
      };

    case types.GET_STOREORDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderListIsSuccess: false,
        storeOrderList: null,
        isLoadingStoreOrderList: false,
        error: { ...state.error, getStoreOrderListError: action.error },
      };

    case types.GET_STOREORDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderListIsSuccess: true,
        isLoadingStoreOrderList: false,
        storeOrderList: action.data,
      };

    case types.GET_STOREORDERLISTLINKEDDELIVERY_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderListLinkedDeliveryIsSuccess: false,
        isLoadingStoreOrderListLinkedDelivery: true,
        //may have issue to submit order
        storeOrderListLinkedDelivery: null,
      };

    case types.GET_STOREORDERLISTLINKEDDELIVERY_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderListLinkedDeliveryIsSuccess: false,
        isLoadingStoreOrderListLinkedDelivery: false,
        storeOrderListLinkedDelivery: null,
        error: {
          ...state.error,
          getStoreOrderListLinkedDeliveryError: action.error,
        },
      };

    case types.GET_STOREORDERLISTLINKEDDELIVERY_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderListLinkedDeliveryIsSuccess: true,
        isLoadingStoreOrderListLinkedDelivery: false,
        storeOrderListLinkedDelivery: action.data,
      };

    case types.GET_STOREORDERSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderSummaryListIsSuccess: false,
        isLoadingStoreOrderSummaryList: true,
        //may have issue to submit order
        storeOrderSummaryList: null,
      };

    case types.GET_STOREORDERSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderSummaryListIsSuccess: false,
        isLoadingStoreOrderSummaryList: false,
        storeOrderSummaryList: null,
        error: { ...state.error, storeOrderSummaryListError: action.error },
      };

    case types.GET_STOREORDERSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderSummaryListIsSuccess: true,
        isLoadingStoreOrderSummaryList: false,
        storeOrderSummaryList: action.data,
      };

    case types.GET_STOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderSummaryListLinkedDeliveryIsSuccess: false,
        isLoadingStoreOrderSummaryListLinkedDelivery: true,
        //may have issue to submit order
        storeOrderSummaryListLinkedDelivery: null,
      };

    case types.GET_STOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderSummaryListLinkedDeliveryIsSuccess: false,
        isLoadingStoreOrderSummaryListLinkedDelivery: false,
        storeOrderSummaryListLinkedDelivery: null,
        error: {
          ...state.error,
          getStoreOrderSummaryListLinkedDeliveryError: action.error,
        },
      };

    case types.GET_STOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderSummaryListLinkedDeliveryIsSuccess: true,
        isLoadingStoreOrderSummaryListLinkedDelivery: false,
        storeOrderSummaryListLinkedDelivery: action.data,
      };

    case types.GET_STOREORDERBYID_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderByIdSuccess: false,
        isLoadingStoreOrderById: true,
        //may have issue to submit order
        storeOrderById: null,
      };

    case types.GET_STOREORDERBYID_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderByIdSuccess: false,
        isLoadingStoreOrderById: false,
        storeOrderById: null,
        error: { ...state.error, storeOrderByIdError: action.error },
      };

    case types.GET_STOREORDERBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderByIdSuccess: true,
        isLoadingStoreOrderById: false,
        storeOrderById: action.data,
      };

    case types.GET_STOREORDERBYIDLINKEDDELIVERY_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderByIdLinkedDeliveryIsSuccess: false,
        isLoadingStoreOrderByIdLinkedDelivery: true,
        storeOrderListByIdLinkedDelivery: null,
      };

    case types.GET_STOREORDERBYIDLINKEDDELIVERY_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderByIdLinkedDeliveryIsSuccess: false,
        storeOrderListByIdLinkedDelivery: null,
        isLoadingStoreOrderByIdLinkedDelivery: false,
        error: {
          ...state.error,
          getStoreOrderByIdLinkedDeliveryError: action.error,
        },
      };

    case types.GET_STOREORDERBYIDLINKEDDELIVERY_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderByIdLinkedDeliveryIsSuccess: true,
        isLoadingStoreOrderByIdLinkedDelivery: false,
        storeOrderListByIdLinkedDelivery: action.data,
      };
    case types.PUT_STOREEDITMODE_ASYNC.PENDING:
      return {
        ...state,
        putStockEditModeIsSuccess: false,
        isLoadingStockEditMode: true,
        storeEditMode: null,
      };

    case types.PUT_STOREEDITMODE_ASYNC.ERROR:
      return {
        ...state,
        putStockEditModeIsSuccess: false,
        isLoadingStockEditMode: false,
        storeEditMode: null,
        error: { ...state.error, putStockEditModeError: action.error },
      };

    case types.PUT_STOREEDITMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putStockEditModeIsSuccess: true,
        isLoadingStockEditMode: false,
        storeEditMode: action.data,
      };

    case types.PUT_STOREPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putStorePrintDateSuccess: false,
        isLoadingPutStorePrintDate: true,
        putStorePrintDate: null,
      };

    case types.PUT_STOREPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putStorePrintDateSuccess: false,
        isLoadingPutStorePrintDate: false,
        putStorePrintDate: null,
        error: { ...state.error, putStorePrintDateError: action.error },
      };

    case types.PUT_STOREPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putStorePrintDateSuccess: true,
        isLoadingPutStorePrintDate: false,
        putStorePrintDate: action.data,
      };

    case types.GET_STOREPRESCRIPTIONMSGLIST_ASYNC.PENDING:
      return {
        ...state,
        getStorePrescriptionMsgListIsSuccess: false,
        isLoadingGetStorePrescriptionMsgList: true,
        getStorePrescriptionMsgList: null,
      };

    case types.GET_STOREPRESCRIPTIONMSGLIST_ASYNC.ERROR:
      return {
        ...state,
        getStorePrescriptionMsgListIsSuccess: false,
        isLoadingGetStorePrescriptionMsgList: false,
        getStorePrescriptionMsgList: null,
        error: {
          ...state.error,
          getStorePrescriptionMsgListError: action.error,
        },
      };

    case types.GET_STOREPRESCRIPTIONMSGLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getStorePrescriptionMsgListIsSuccess: true,
        isLoadingGetStorePrescriptionMsgList: false,
        getStorePrescriptionMsgList: action.data,
      };

    case types.GET_STOREMEDICINEINFO_ASYNC.PENDING:
      return {
        ...state,
        getStoreMedicineInfoIsSuccess: false,
        isLoadingGetStoreMedicineInfo: true,
        getStoreMedicineInfo: null,
      };

    case types.GET_STOREMEDICINEINFO_ASYNC.ERROR:
      return {
        ...state,
        getStoreMedicineInfoIsSuccess: false,
        isLoadingGetStoreMedicineInfo: false,
        getStoreMedicineInfo: null,
        error: {
          ...state.error,
          getStoreMedicineInfoError: action.error,
        },
      };

    case types.GET_STOREMEDICINEINFO_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreMedicineInfoIsSuccess: true,
        isLoadingGetStoreMedicineInfo: false,
        getStoreMedicineInfo: action.data,
      };

    case types.GET_STOREPOSTALCODESURCHARGE_ASYNC.PENDING:
      return {
        ...state,
        getStorePostalCodeSurchargeListIsSuccess: false,
        isLoadingGetStorePostalCodeSurchargeList: true,
        getStorePostalCodeSurchargeList: null,
      };

    case types.GET_STOREPOSTALCODESURCHARGE_ASYNC.ERROR:
      return {
        ...state,
        getStorePostalCodeSurchargeListIsSuccess: false,
        isLoadingGetStorePostalCodeSurchargeList: false,
        getStorePostalCodeSurchargeList: null,
        error: {
          ...state.error,
          getStorePostalCodeSurchargeListError: action.error,
        },
      };

    case types.GET_STOREPOSTALCODESURCHARGE_ASYNC.SUCCESS:
      return {
        ...state,
        getStorePostalCodeSurchargeListIsSuccess: true,
        isLoadingGetStorePostalCodeSurchargeList: false,
        getStorePostalCodeSurchargeList: action.data,
      };

    case types.GET_STOREAVAILABLEPROCEEDON_ASYNC.PENDING:
      return {
        ...state,
        getStoreAvailableProceedOnIsSuccess: false,
        isLoadingGetStoreAvailableProceedOn: true,
        getStoreAvailableProceedOn: null,
      };

    case types.GET_STOREAVAILABLEPROCEEDON_ASYNC.ERROR:
      return {
        ...state,
        getStoreAvailableProceedOnIsSuccess: false,
        isLoadingGetStoreAvailableProceedOn: false,
        getStoreAvailableProceedOn: null,
        error: {
          ...state.error,
          getStoreAvailableProceedOnError: action.error,
        },
      };

    case types.GET_STOREAVAILABLEPROCEEDON_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreAvailableProceedOnIsSuccess: true,
        isLoadingGetStoreAvailableProceedOn: false,
        getStoreAvailableProceedOn: action.data,
      };

    case types.GET_STOREVERIFYADDRESS_ASYNC.PENDING:
      return {
        ...state,
        getStoreVerifyAddressIsSuccess: false,
        isLoadingGetStoreVerifyAddress: true,
        getStoreVerifyAddress: null,
      };

    case types.GET_STOREVERIFYADDRESS_ASYNC.ERROR:
      return {
        ...state,
        getStoreVerifyAddressIsSuccess: false,
        isLoadingGetStoreVerifyAddress: false,
        getStoreVerifyAddress: null,
        error: {
          ...state.error,
          getStoreVerifyAddressError: action.error,
        },
      };

    case types.GET_STOREVERIFYADDRESS_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreVerifyAddressIsSuccess: true,
        isLoadingGetStoreVerifyAddress: false,
        getStoreVerifyAddress: action.data,
      };

    case types.POST_STORECHANGEBRANCHWHOLEMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        postStoreChangeBranchWholeMedicineIsSuccess: false,
        isLoadingPostStoreChangeBranchWholeMedicine: true,
        postStoreChangeBranchWholeMedicine: null,
      };

    case types.POST_STORECHANGEBRANCHWHOLEMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        postStoreChangeBranchWholeMedicineIsSuccess: false,
        isLoadingPostStoreChangeBranchWholeMedicine: false,
        postStoreChangeBranchWholeMedicine: null,
        error: {
          ...state.error,
          postStoreChangeBranchWholeMedicineError: action.error,
        },
      };

    case types.POST_STORECHANGEBRANCHWHOLEMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        postStoreChangeBranchWholeMedicineIsSuccess: true,
        isLoadingPostStoreChangeBranchWholeMedicine: false,
        postStoreChangeBranchWholeMedicine: action.data,
      };

    default:
      return state;
  }
};
