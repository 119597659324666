//lib
import React from "react";
import { TextField } from "@mui/material";
import { useTable, useRowSelect } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../components/globalTableStyles";

//components

// Create an editable cell renderer
const EditableCell = ({
  cell: { value: initialValue },
  row: { index },
  column,
  row,
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  editableNum,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  // const onBlur = () => {
  //   updateMyData(index, id, value);
  // };

  const onChangeNum = (e) => {
    //discount amount cant more than charges

    setValue(e.target.value);
    updateMyData(row.original.id, column.id, Number(e.target.value));
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (editableNum) {
    return (
      <TextField
        style={{ width: "100%" }}
        value={value !== null ? value : ""}
        variant="standard"
        onChange={onChangeNum}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
            style: { textAlign: "left" },
          },
        }}
        onKeyDown={(event) => {
          if (
            event?.key === "-" ||
            event?.key === "+" ||
            event?.key === "e" ||
            event?.key === "E"
          ) {
            event.preventDefault();
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  if (!editable) {
    return `${initialValue}`;
  }

  return (
    <TextField
      value={value ? value : ""}
      variant="standard"
      onChange={onChange}
      type="text"
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

// Be sure to pass our updateMyData and the disablePageResetOnDataChange option
function Table({ columns, data, updateMyData, disablePageResetOnDataChange }) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        updateMyData,
        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = useStyles();
  const { t } = useTranslation();
  // Render the UI for your table
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              return (
                prepareRow(row) || (
                  <React.Fragment key={"empty" + rowIndex}>
                    <TableRow
                      key={"empty1" + rowIndex}
                      className={
                        rowIndex % 2 ? paginationClasses.rowBackground : ""
                      }>
                      <TableCell className={paginationClasses.narrowCellStyle}>
                        {rowIndex + 1}
                      </TableCell>
                      {row.cells.map((cell) => {
                        return (
                          <TableCell
                            className={paginationClasses.narrowCellStyle}
                            {...cell.getCellProps()}>
                            {cell.column.id !== "extra_Amount" &&
                              row.original.id === "treatmentPrice" &&
                              cell.render("Cell", {
                                editable: cell.column.editable,
                                editableNum: cell.column.editableNum,
                              })}
                            {cell.column.id !== "extra_Amount" &&
                              row.original.id === "miscPrice" &&
                              cell.render("Cell", {
                                editable: cell.column.editable,
                                editableNum: cell.column.editableNum,
                              })}
                            {row.original.id !== "miscPrice" &&
                              row.original.id !== "treatmentPrice" &&
                              cell.render("Cell", {
                                editable: cell.column.editable,
                                editableNum: cell.column.editableNum,
                              })}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {row.original.detail &&
                      row.original.detail.map((rowCell, detailRowIndex) => {
                        return (
                          <React.Fragment key={"rowCell" + detailRowIndex}>
                            <TableRow
                              key={"rowCells" + detailRowIndex}
                              className={paginationClasses.rowDetailBackground}>
                              <TableCell
                                className={
                                  paginationClasses.narrowCellStyle
                                }></TableCell>
                              {row.cells.map((cell, detailRowCellIndex) => {
                                return (
                                  <TableCell
                                    key={"detailRowCell" + detailRowCellIndex}
                                    className={
                                      paginationClasses.narrowCellStyle
                                    }>
                                    {rowCell[cell.column.id]}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                )
              );
            })}
          </TableBody>

          <caption>
            <span className={paginationClasses.blackColor}>
              {t("translation:Subtotal Amount")} = {t("translation:Amount")} +{" "}
              {t("translation:Surcharge")}
            </span>
          </caption>
        </MaUTable>
      </TableContainer>
    </>
  );
}
const PaymentTable = ({
  viewOnly,
  editPrice = false,
  price,
  discount,
  extraAmt,
  setPaymentData,
  treatmentDetail = null,
  miscDetail = null,
  t,
}) => {
  const discountData = [
    {
      id: "consultationFee",
      name: t("translation:Consultation"),
      amount: price.consultationFee,
      discount_Amount: discount.consultationFee,
      extra_Amount: extraAmt.consultationFee,
      subAmount: (
        Number(price.consultationFee) + Number(extraAmt.consultationFee)
      ).toFixed(2),
    },
    {
      id: "medicineTab",
      name: t("translation:Medicine"),
      amount: price.medicineTab,
      discount_Amount: discount.medicineTab,
      extra_Amount: extraAmt.medicineTab,
      subAmount: (
        Number(price.medicineTab) + Number(extraAmt.medicineTab)
      ).toFixed(2),
    },
    {
      id: "acupuncturePrice",
      name: t("translation:Acupuncture"),
      amount: price.acupuncturePrice,
      discount_Amount: discount.acupuncturePrice,
      extra_Amount: extraAmt.acupuncturePrice,
      subAmount: (
        Number(price.acupuncturePrice) + Number(extraAmt.acupuncturePrice)
      ).toFixed(2),
    },

    {
      id: "deliveryFee",
      name: t("translation:Delivery"),
      amount: price.deliveryFee,
      discount_Amount: discount.deliveryFee,
      extra_Amount: extraAmt.deliveryFee,
      subAmount: (
        Number(price.deliveryFee) + Number(extraAmt.deliveryFee)
      ).toFixed(2),
    },
    {
      id: "brewingFee",
      name: t("translation:Brewing"),
      amount: price.brewingFee,
      discount_Amount: discount.brewingFee,
      extra_Amount: extraAmt.brewingFee,
      subAmount: (
        Number(price.brewingFee) + Number(extraAmt.brewingFee)
      ).toFixed(2),
    },
    {
      id: "treatmentPrice",
      name: t("translation:Treatment"),
      amount: price.treatmentPrice,
      discount_Amount: discount.treatmentPrice,
      detail: treatmentDetail,
      extra_Amount: 0,
      subAmount: Number(price.treatmentPrice).toFixed(2),
    },
    {
      id: "miscPrice",
      name: t("translation:Misc"),
      amount: price.miscPrice,
      discount_Amount: discount.miscPrice,
      detail: miscDetail,
      extra_Amount: 0,
      subAmount: Number(price.miscPrice).toFixed(2),
    },
  ];
  const columns = [
    {
      Header: t("translation:Charges"),
      accessor: "name",
    },
    {
      Header: t("translation:Amount"),
      accessor: "amount",
      editableNum: editPrice,
    },
    {
      editableNum: true,
      Header: t("translation:Surcharge"),
      accessor: "extra_Amount",
    },

    {
      Header: t("translation:Subtotal Amount"),
      accessor: "subAmount",
    },
    {
      editableNum: true,
      Header: t("translation:Discount Amount"),
      accessor: "discount_Amount",
    },
  ];

  const columnsViewOnly = [
    {
      Header: t("translation:Charges"),
      accessor: "name",
    },
    {
      Header: t("translation:Amount"),
      accessor: "amount",
      Cell: ({ cell }) => {
        return <>{cell.value !== null ? cell.value.toFixed(2) : ""}</>;
      },
    },
    {
      Header: t("translation:Surcharge"),
      accessor: "extra_Amount",
      Cell: ({ cell }) => {
        return <>{cell.value !== null ? cell.value.toFixed(2) : ""}</>;
      },
    },

    {
      Header: t("translation:Subtotal Amount"),
      accessor: "subAmount",
    },

    {
      Header: t("translation:Discount Amount"),
      accessor: "discount_Amount",
      Cell: ({ cell }) => {
        return <>{cell.value !== null ? cell.value.toFixed(2) : ""}</>;
      },
    },
  ];

  return (
    <Table
      columns={viewOnly ? columnsViewOnly : columns}
      data={discountData}
      updateMyData={setPaymentData}
      t={t}
    />
  );
};

export default PaymentTable;
