import { createAsyncTypes } from "../_helper/Utilities";

export const GET_DASHBOARDAPPOINTMENTLIST_ASYNC = createAsyncTypes(
  "GET_DASHBOARDAPPOINTMENTLIST"
);

export const GET_DASHBOARDBREWINGLIST_ASYNC = createAsyncTypes(
  "GET_DASHBOARDBREWINGLIST"
);

export const GET_DASHBOARDDELIVERYORDERLIST_ASYNC = createAsyncTypes(
  "GET_DASHBOARDDELIVERYORDERLIST"
);

export const GET_DASHBOARDDISPENSARYLIST_ASYNC = createAsyncTypes(
  "GET_DASHBOARDDISPENSARYLIST"
);

export const GET_DASHBOARDINVOICELIST_ASYNC = createAsyncTypes(
  "GET_DASHBOARDINVOICELIST"
);

export const GET_DASHBOARDINVOICEMEDICINECOSTINGLIST_ASYNC = createAsyncTypes(
  "GET_DASHBOARDINVOICEMEDICINECOSTINGLIST"
);

export const GET_DASHBOARDSTOREORDERLIST_ASYNC = createAsyncTypes(
  "GET_DASHBOARDSTOREORDERLIST"
);

export const GET_DASHBOARDVISITORDERLIST_ASYNC = createAsyncTypes(
  "GET_DASHBOARDVISITORDERLIST"
);

export const GET_DASHBOARDALLAPPROVEDLOCALDISPENSARY_ASYNC = createAsyncTypes(
  "GET_DASHBOARDALLAPPROVEDLOCALDISPENSARY"
);

export const GET_DASHBOARDALLSTOREWDISPENSARYLIST_ASYNC = createAsyncTypes(
  "GET_DASHBOARDALLSTOREWDISPENSARYLIST"
);

export const GET_DASHBOARDCOUNTERVIEW_ASYNC = createAsyncTypes(
  "GET_DASHBOARDCOUNTERVIEW"
);
