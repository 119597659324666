import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAppointmentList,
  postSubmitAppointment,
  postAppointmentPhysicians,
  getAppointmentBranch,
  getAppointmentTimeslotList,
  getAppointmentBranchPhysician,
  getAppointmentUserList,
  getAppointmentTempUserList,
} from "../actions/appointmentActions";
import * as types from "../_constants/appointmentConstants";
import * as notification from "../_constants/notificationConstants";
import appointmentApis from "../_apis/appointmentApis";
export default function* appointmentSagas() {
  yield all([
    takeLatest(types.GET_APPOINTMENTLIST_ASYNC.PENDING, getAppointmentListCall),
  ]);

  yield all([
    takeLatest(
      types.POST_SUBMITAPPOINTMENT_ASYNC.PENDING,
      postSubmitAppointmentCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_APPOINTMENTPHYSICIANS_ASYNC.PENDING,
      postAppointmentPhysiciansCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_APPOINTMENTBRANCH_ASYNC.PENDING,
      getAppointmentBranchCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_APPOINTMENTTIMESLOTLIST_ASYNC.PENDING,
      getAppointmentTimeslotListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_APPOINTMENTBRANCHPHYSICIAN_ASYNC.PENDING,
      getAppointmentBranchPhysicianCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_APPOINTMENTUSERLIST_ASYNC.PENDING,
      getAppointmentUserListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_APPOINTMENTTEMPUSERLIST_ASYNC.PENDING,
      getAppointmentTempUserListCall
    ),
  ]);
}

function* getAppointmentListCall(searchModel) {
  try {
    const data = yield call(
      appointmentApis.getAppointmentListApi,
      searchModel.data
    );
    yield put(getAppointmentList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAppointmentList.error(error.response?.data?.message));
    else yield put(getAppointmentList.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitAppointmentCall(searchModel) {
  try {
    const data = yield call(
      appointmentApis.postSubmitAppointmentApi,
      searchModel.data
    );
    yield put(postSubmitAppointment.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitAppointment.error(error.response?.data?.message));
    else yield put(postSubmitAppointment.error(notification.CONST_NOINTERNET));
  }
}

function* postAppointmentPhysiciansCall(searchModel) {
  try {
    const data = yield call(
      appointmentApis.postAppointmentPhysiciansApi,
      searchModel.data
    );
    yield put(postAppointmentPhysicians.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postAppointmentPhysicians.error(error.response?.data?.message));
    else
      yield put(postAppointmentPhysicians.error(notification.CONST_NOINTERNET));
  }
}

function* getAppointmentBranchCall(searchModel) {
  try {
    const data = yield call(
      appointmentApis.getAppointmentBranchApi,
      searchModel.data
    );
    yield put(getAppointmentBranch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAppointmentBranch.error(error.response?.data?.message));
    else {
      yield put(getAppointmentBranch.error(notification.CONST_NOINTERNET));
    }
  }
}

function* getAppointmentTimeslotListCall(searchModel) {
  try {
    const data = yield call(
      appointmentApis.getAppointmentTimeslotListApi,
      searchModel.data
    );
    yield put(getAppointmentTimeslotList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getAppointmentTimeslotList.error(error.response?.data?.message)
      );
    else
      yield put(
        getAppointmentTimeslotList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getAppointmentBranchPhysicianCall(searchModel) {
  try {
    const data = yield call(
      appointmentApis.getAppointmentBranchPhysicianApi,
      searchModel.data
    );
    yield put(getAppointmentBranchPhysician.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getAppointmentBranchPhysician.error(error.response?.data?.message)
      );
    else
      yield put(
        getAppointmentBranchPhysician.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getAppointmentUserListCall(searchModel) {
  try {
    const data = yield call(
      appointmentApis.getAppointmentUserListApi,
      searchModel.data
    );
    yield put(getAppointmentUserList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getAppointmentUserList.error(error.response?.data?.message));
    else yield put(getAppointmentUserList.error(notification.CONST_NOINTERNET));
  }
}

function* getAppointmentTempUserListCall(searchModel) {
  try {
    const data = yield call(
      appointmentApis.getAppointmentTempUserListApi,
      searchModel.data
    );
    yield put(getAppointmentTempUserList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getAppointmentTempUserList.error(error.response?.data?.message)
      );
    else
      yield put(
        getAppointmentTempUserList.error(notification.CONST_NOINTERNET)
      );
  }
}
