import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getPostalCodeSurchargeList,
  getPostalCodeSurchargeById,
  postCreatePostalCodeSurcharge,
  putUpdatePostalCodeSurcharge,
} from "../actions/postalCodeSurchargeActions";
import * as types from "../_constants/postalCodeSurchargeConstants";
import * as notification from "../_constants/notificationConstants";
import postalCodeSurchargeApis from "../_apis/postalCodeSurchargeApis";
export default function* postalCodeSurchargeSagas() {
  yield all([
    takeLatest(
      types.GET_POSTALCODESURCHARGELIST_ASYNC.PENDING,
      getPostalCodeSurchargeListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_POSTALCODESURCHARGEBYID_ASYNC.PENDING,
      getPostalCodeSurchargeByIdCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_CREATEPOSTALCODESURCHARGE_ASYNC.PENDING,
      postCreatePostalCodeSurchargeCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_UPDATEPOSTALCODESURCHARGE_ASYNC.PENDING,
      putUpdatePostalCodeSurchargeCall
    ),
  ]);
}

function* getPostalCodeSurchargeListCall(searchModel) {
  try {
    const data = yield call(
      postalCodeSurchargeApis.getPostalCodeSurchargeListApi,
      searchModel.data
    );
    yield put(getPostalCodeSurchargeList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getPostalCodeSurchargeList.error(error.response?.data?.message)
      );
    else
      yield put(
        getPostalCodeSurchargeList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getPostalCodeSurchargeByIdCall(searchModel) {
  try {
    const data = yield call(
      postalCodeSurchargeApis.getPostalCodeSurchargeByIdApi,
      searchModel.data
    );
    yield put(getPostalCodeSurchargeById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getPostalCodeSurchargeById.error(error.response?.data?.message)
      );
    else
      yield put(
        getPostalCodeSurchargeById.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postCreatePostalCodeSurchargeCall(searchModel) {
  try {
    const data = yield call(
      postalCodeSurchargeApis.postCreatePostalCodeSurchargeApi,
      searchModel.data
    );
    yield put(postCreatePostalCodeSurcharge.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postCreatePostalCodeSurcharge.error(error.response?.data?.message)
      );
    else
      yield put(
        postCreatePostalCodeSurcharge.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putUpdatePostalCodeSurchargeCall(searchModel) {
  try {
    const data = yield call(
      postalCodeSurchargeApis.putUpdatePostalCodeSurchargeApi,
      searchModel.data
    );
    yield put(putUpdatePostalCodeSurcharge.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putUpdatePostalCodeSurcharge.error(error.response?.data?.message)
      );
    else
      yield put(
        putUpdatePostalCodeSurcharge.error(notification.CONST_NOINTERNET)
      );
  }
}
