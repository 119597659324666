import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/emailStoreSenderConstants";

export const getEmailStoreSenderById = {
  pending: (data) =>
    createAction(types.GET_EMAILSTORESENDERBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_EMAILSTORESENDERBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_EMAILSTORESENDERBYID_ASYNC.ERROR, { error }),
};

export const getEmailStoreSenderList = {
  pending: (data) =>
    createAction(types.GET_EMAILSTORESENDERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_EMAILSTORESENDERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_EMAILSTORESENDERLIST_ASYNC.ERROR, { error }),
};

export const postCreateEmailStoreSender = {
  pending: (data) =>
    createAction(types.POST_CREATEEMAILSTORESENDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEEMAILSTORESENDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEEMAILSTORESENDER_ASYNC.ERROR, { error }),
};

export const putUpdateEmailStoreSender = {
  pending: (data) =>
    createAction(types.PUT_UPDATEEMAILSTORESENDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEEMAILSTORESENDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEEMAILSTORESENDER_ASYNC.ERROR, { error }),
};

export const getEmailStoreSenderOrganisationBranch = {
  pending: (data) =>
    createAction(types.GET_EMAILSTORESENDERORGANISATIONBRANCH_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_EMAILSTORESENDERORGANISATIONBRANCH_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_EMAILSTORESENDERORGANISATIONBRANCH_ASYNC.ERROR, {
      error,
    }),
};

export const getEmailStoreSenderMedicineType = {
  pending: (data) =>
    createAction(types.GET_EMAILSTORESENDERMEDICINETYPE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_EMAILSTORESENDERMEDICINETYPE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_EMAILSTORESENDERMEDICINETYPE_ASYNC.ERROR, { error }),
};

export const getEmailStoreSenderOrganisationPhysician = {
  pending: (data) =>
    createAction(
      types.GET_EMAILSTORESENDERORGANISATIONPHYSICIAN_ASYNC.PENDING,
      { data }
    ),
  success: (data) =>
    createAction(
      types.GET_EMAILSTORESENDERORGANISATIONPHYSICIAN_ASYNC.SUCCESS,
      { data }
    ),
  error: (error) =>
    createAction(types.GET_EMAILSTORESENDERORGANISATIONPHYSICIAN_ASYNC.ERROR, {
      error,
    }),
};
