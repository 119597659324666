import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import UserSummaryPage from "./UserSummary/UserSummaryPage";
import RoleSummaryPage from "./RoleSummary/RoleSummaryPage";
import UserRoleSummaryPage from "./UserRoleSummary/UserRoleSummaryPage";
import RoleRightSummaryPage from "./RoleRightSummary/RoleRightSummaryPage";
import OrganisationPhysianSummaryPage from "./OrganisationPhysicianSummary/OrganisationPhysicianSummaryPage";
import UserMfaSummaryPage from "./UserMfaSummary/UserMfaSummaryPage";
export default function UserPage() {
  return (
    <Switch>
      <Redirect exact={true} from="/user" to="/user/UserSummary" />
      <Route path="/user/UserSummary" component={UserSummaryPage} />
      <Route path="/user/RoleSummary" component={RoleSummaryPage} />
      <Route path="/user/UserRoleSummary" component={UserRoleSummaryPage} />
      <Route path="/user/UserMfaSummary" component={UserMfaSummaryPage} />
      <Route path="/user/RoleRightSummary" component={RoleRightSummaryPage} />
      <Route
        path="/user/OrganisationPhysicianSummary"
        component={OrganisationPhysianSummaryPage}
      />
      <Redirect to="/error" />
    </Switch>
  );
}
