//lib
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

//action

import { postCreatePresignedUrlPhoto } from "../../../../actions/cloudStorageActions";

export default function CheckerPhoto({
  organisationCode = "default",
  folderPath = "default",
  uploadFileObjects = {},
  open,
  setOpen,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const prevCreatePresignedUrlStatus = useRef();
  const cloudStorage = useSelector((state) => state.cloudStorage);

  const [msgAlarm, setMsgAlarm] = React.useState(null);
  const [alertMsg, setAlertMsg] = React.useState(false);
  const [alertType, setAlertType] = React.useState(null);

  const [photoURL, setPhotoURL] = React.useState(null);

  useEffect(() => {
    prevCreatePresignedUrlStatus.current = false;
  }, []);

  useEffect(() => {
    if (uploadFileObjects.url !== null) {
      setAlertMsg(false);
      setMsgAlarm(null);
      setAlertType(null);
      const organisationCodePath = organisationCode + "/" + folderPath + "/";

      const extensionIndex =
        uploadFileObjects.url.lastIndexOf(organisationCodePath);
      const objectName = uploadFileObjects.url.substring(extensionIndex);

      const submitJson = {
        object_name: objectName,
      };

      dispatch(postCreatePresignedUrlPhoto.pending(submitJson));
    }
  }, [uploadFileObjects, folderPath, organisationCode, dispatch]);

  useEffect(() => {
    if (
      cloudStorage.isLoadingPostCreatePresignedUrlPhoto !==
      prevCreatePresignedUrlStatus.current
    ) {
      //check the previous ref with current state
      prevCreatePresignedUrlStatus.current =
        cloudStorage.isLoadingPostCreatePresignedUrlPhoto;

      if (
        cloudStorage.postCreatePresignedUrlPhotoIsSuccess === true &&
        cloudStorage.isLoadingPostCreatePresignedUrlPhoto === false
      ) {
        const organisationCodePath = organisationCode + "/" + folderPath + "/";

        const extensionIndex =
          cloudStorage.postCreatePresignedUrlPhoto.lastIndexOf(
            organisationCodePath
          );

        if (extensionIndex !== -1) {
          fetch(cloudStorage.postCreatePresignedUrlPhoto)
            .then((res) => {
              if (res.status === 200) {
                return res.blob();
              } else {
                throw new Error(res.statusText);
              }
            })
            .then((blob) => {
              var url = window.URL.createObjectURL(blob);
              setPhotoURL(url);
            })
            .catch((err) => {
              setMsgAlarm(err.message);
              setAlertMsg(true);
              setAlertType("error");
            });
        }
      }
      if (
        cloudStorage.postCreatePresignedUrlPhotoIsSuccess === false &&
        cloudStorage.isLoadingPostCreatePresignedUrlPhoto === false
      ) {
        setMsgAlarm(cloudStorage.error.postCreatePresignedUrlPhotoError);
        setAlertMsg(true);
        setAlertType("error");
      }
    }
  }, [cloudStorage, folderPath, organisationCode, t]);

  return (
    <Dialog
      open={open}
      maxWidth={"lg"}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setOpen(false);
        }
      }}
      aria-labelledby="confirm-dialog"
      fullWidth>
      <CloseIcon
        style={{ position: "absolute", right: "15px" }}
        onClick={() => setOpen(false)}
      />
      <DialogTitle id="confirm-dialog">
        {t("translation:Check Photo")}
      </DialogTitle>
      <Grid item xs={12} container justifyContent="center">
        {alertType !== null && alertMsg !== null && (
          <Collapse in={alertMsg}>
            <Alert
              severity={alertType}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertMsg(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlarm}
            </Alert>
          </Collapse>
        )}
        {alertMsg !== true &&
          uploadFileObjects.url !== null &&
          photoURL === null && <>{t("translation:Loading")}...</>}
        {photoURL !== null && (
          <img src={photoURL} alt="checkerPhoto" width="70%" height="70%" />
        )}

        {uploadFileObjects.url === null && (
          <>{t("translation:No Photo Found")}</>
        )}
      </Grid>
    </Dialog>
  );
}
