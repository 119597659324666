import React, { useEffect, useLayoutEffect, useContext, useRef } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import axios from "axios";
import i18n from "i18next";
import moment from "moment";
import { useLastLocation } from "react-router-last-location";
import { Grid, TextField } from "@mui/material";
import NewLayout from "../layout/NewLayout";
import NotFound from "../pages/errors/NotFound";
import HomePage from "../pages/HomePage";
import Registration from "../pages/auth/registration/RegistrationPage";
//import logIn from "../pages/user/logIn/LogInPage";
import SignIn from "../pages/auth/SignIn/SignInPage";
import Auth2Login from "../pages/auth/SignIn/Auth2LoginPage";
import ResetPasswordRequest from "../pages/auth/resetPassword/ResetPasswordRequestPage";
import ResetPassword from "../pages/auth/resetPassword/ResetPasswordPage";
import VerifyEmailPage from "../pages/auth/verifyEmail/VerifyEmailPage";

import * as lang from "../_constants/langConstants";
import NotificationDialog from "../components/NotificationDialog";
import NoInternet from "../pages/errors/NoInternet";
import allowOnlyNumericsOrDigits from "../components/functions/allowOnlyNumericsOrDigits";
import ConfirmDialog from "../components/ConfirmDialog";
import { useStyles } from "../components/globalStyles";

import {
  getAllRightAccess,
  getOrganisationInfo,
  getDefaultBranch,
  getAllAllianceOrganisation,
} from "../actions/curUserActions";
import { getGeneralStatus } from "../actions/commonActions";

import { SocketContext } from "../components/functions/SocketProvider";

import { postOtpCallback } from "../actions/authActions";

export const Routes = withRouter(({ history }) => {
  const classes = useStyles();
  const { socket } = useContext(SocketContext);
  const userAuth = useSelector((state) => state.userAuth);
  const curUser = useSelector((state) => state.curUser);
  const lastLocation = useLastLocation();
  const [userLastLocation, setUserLastLocation] = React.useState(null);
  const [userLastLocationOpen, setUserLastLocationOpen] = React.useState(true);
  const [noInternetNotification, setNoInternetNotification] =
    React.useState(false);
  const [noInternetCode, setNoInternetCode] = React.useState(null);
  const [confirmSubmit, setConfirmSubmit] = React.useState(false);
  const [verifiedOTP, setVerifiedOTP] = React.useState("");
  const { isAuthorized } = useSelector(
    () => ({
      isAuthorized:
        userAuth.logInSuccess === true &&
        userAuth.logOutSuccess !== true &&
        !userAuth.systemUserInfo.otp_request &&
        !Array.isArray(userAuth.systemUserInfo)
          ? true
          : false,
    }),
    shallowEqual
  );

  const prevlogInStatus = useRef();

  const dispatch = useDispatch();

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const submitJson = {
      otp_request: {
        otp_code: verifiedOTP,
        otp_token: userAuth.systemUserInfo?.otp_request.otp_token,
      },
    };
    dispatch(postOtpCallback.pending(submitJson));
  };

  const interceptor = (error) => {
    if (error.response?.status === 500) {
      setNoInternetNotification(true);
      setNoInternetCode(500);
    }

    if (error.response?.status === 501) {
      setNoInternetNotification(true);
      setNoInternetCode(501);
    }

    if (error.response?.status === 502) {
      setNoInternetNotification(true);
      setNoInternetCode(502);
    }

    if (error.response?.status === 503) {
      setNoInternetNotification(true);
      setNoInternetCode(503);
    }

    if (error.response?.status === 504) {
      setNoInternetNotification(true);
      setNoInternetCode(504);
    }
    return Promise.reject(error);
  };

  const resInterceptor = (response) => {
    return response;
  };
  //useEffects
  useEffect(() => {
    prevlogInStatus.current = false;
  }, []);
  useEffect(() => {
    if (userAuth.isLoadinglogIn !== prevlogInStatus.current) {
      prevlogInStatus.current = userAuth.isLoadinglogIn;
      if (userAuth.logInSuccess === true && userAuth.logOutSuccess !== true) {
        if (userAuth.systemUserInfo?.otp_request) {
          setConfirmSubmit(true);
          setVerifiedOTP("");
        } else {
          setConfirmSubmit(false);
          setVerifiedOTP("");
        }
      }
    }
  }, [userAuth]);

  useLayoutEffect(() => {
    axios.interceptors.response.use(resInterceptor, interceptor);
  }, []);

  useEffect(() => {
    if (
      lastLocation &&
      window.location.pathname !== "/home" &&
      window.location.pathname !== "/dashboard"
    ) {
      //disconnect socket and connect it inside component when needed.
      socket.disconnect();
    }
  }, [socket, lastLocation]);
  useLayoutEffect(() => {
    //set redirect into last path location into off when the user open new taband path is "/signIn" or  "/reSignIn"
    if (
      userLastLocation &&
      (lastLocation.pathname === "/signIn" ||
        lastLocation.pathname === "/reSignIn")
    ) {
      setUserLastLocationOpen(false);
    }
  }, [userLastLocation, lastLocation]);

  useLayoutEffect(() => {
    //useLayoutEffect is setState before render
    if (isAuthorized) {
      //set to previous path if user is logging.
      if (
        (lastLocation && window.location.pathname === "/home") ||
        window.location.pathname === "/signIn"
      ) {
        if (
          lastLocation.pathname !== "/" &&
          window.location.pathname !== "/reSignIn" &&
          lastLocation.pathname !== "/reSignIn"
        ) {
          setUserLastLocation(
            lastLocation.pathname ? lastLocation.pathname : null
          );
        }
      }
    }
  }, [dispatch, isAuthorized, lastLocation]);

  useEffect(() => {
    if (isAuthorized) {
      if (
        //set to previous path if user is logging.
        window.location.pathname &&
        window.location.pathname !== "/reSignIn" &&
        window.location.pathname !== "/signIn"
      ) {
        //calling these apis when log in
        setNoInternetNotification(false);
        dispatch(getAllRightAccess.pending());
        dispatch(getOrganisationInfo.pending());
        dispatch(getGeneralStatus.pending());
        dispatch(getDefaultBranch.pending());
        dispatch(getAllAllianceOrganisation.pending());
      }
    }
  }, [dispatch, isAuthorized]);

  useEffect(() => {
    if (
      userAuth.systemUserInfo &&
      userAuth.systemUserInfo.language &&
      userAuth.systemUserInfo.language.id === lang.LANGUAGE_ENGLISH
    ) {
      moment.locale(lang.CONST_LANGUAGE_ENGLISH);
      i18n.changeLanguage(lang.CONST_LANGUAGE_ENGLISH);
    } else if (isAuthorized) {
      moment.locale(lang.CONST_LANGUAGE_ENGLISH);
      i18n.changeLanguage(lang.CONST_LANGUAGE_CHINESE);
    } else {
      moment.locale(lang.CONST_LANGUAGE_ENGLISH);
      i18n.changeLanguage(lang.CONST_LANGUAGE_ENGLISH);
    }
  }, [userAuth, isAuthorized]);
  if (isAuthorized) {
    return (
      <Switch>
        {userLastLocationOpen && userLastLocation && (
          <Redirect to={userLastLocation} />
        )}
        <Route path="/verify_email" component={VerifyEmailPage} />
        <Route path="/error" component={NotFound} />
        <Route path="/registration" component={Registration} />
        <Route path="/resetRq" component={ResetPasswordRequest} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/auth2login" component={Auth2Login} />
        <Route path="/">
          {noInternetNotification && (
            <NotificationDialog
              ok={"ok"}
              open={noInternetNotification}
              setOpen={setNoInternetNotification}>
              <NoInternet noInternetCode={noInternetCode} />
            </NotificationDialog>
          )}
          <NewLayout userAuth={userAuth} accessRight={curUser.allRightAccess}>
            <HomePage />
          </NewLayout>
        </Route>
      </Switch>
    );
  } else {
    return (
      <>
        {confirmSubmit && (
          <ConfirmDialog
            Yes={"Yes"}
            No={"No"}
            title={"Confirmation"}
            textValue={""}
            open={confirmSubmit}
            setOpen={setConfirmSubmit}
            onConfirm={onConfirmSubmit}>
            Please enter OTP token.
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                required
                className={classes.userFieldColor}
                name="OTP"
                onInput={allowOnlyNumericsOrDigits}
                InputProps={{
                  inputProps: {
                    minLength: 6,
                    maxLength: 6,
                  },
                }}
                label={"OTP"}
                variant="outlined"
                onBlur={(e) => {
                  setVerifiedOTP(e.target.value);
                }}
              />
            </Grid>
          </ConfirmDialog>
        )}
        <Switch>
          <Route path="/verify_email" component={VerifyEmailPage} />
          <Redirect exact={true} from="/reSignIn" to="/signIn" />
          <Redirect exact={true} from="/" to="/signIn" />
          <Route path="/signIn" component={SignIn} />
          <Route path="/registration" component={Registration} />
          <Route path="/resetRq" component={ResetPasswordRequest} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/error" component={NotFound} />
          <Route path="/auth2login" component={Auth2Login} />
          {/*If last path location is null or other above path, it will redirect into signIn page*/}

          {userLastLocation === null && <Redirect to="/signIn" />}
          <Redirect to="/signIn" />
        </Switch>
      </>
    );
  }
});
