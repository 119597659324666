import * as types from "../_constants/emailStoreSenderConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_EMAILSTORESENDERBYID_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreSenderByIdIsSuccess: false,
        isLoadingGetEmailStoreSenderById: true,
        getEmailStoreSenderById: null,
      };

    case types.GET_EMAILSTORESENDERBYID_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreSenderByIdIsSuccess: false,
        isLoadingGetEmailStoreSenderById: false,
        getEmailStoreSenderById: null,
        error: {
          ...state.error,
          getEmailStoreSenderByIdError: action.error,
        },
      };

    case types.GET_EMAILSTORESENDERBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreSenderByIdIsSuccess: true,
        isLoadingGetEmailStoreSenderById: false,
        getEmailStoreSenderById: action.data,
      };

    case types.GET_EMAILSTORESENDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreSenderListIsSuccess: false,
        isLoadingGetEmailStoreSenderList: true,
        getEmailStoreSenderList: null,
      };

    case types.GET_EMAILSTORESENDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreSenderListIsSuccess: false,
        isLoadingGetEmailStoreSenderList: false,
        getEmailStoreSenderList: null,
        error: {
          ...state.error,
          getEmailStoreSenderListError: action.error,
        },
      };

    case types.GET_EMAILSTORESENDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreSenderListIsSuccess: true,
        isLoadingGetEmailStoreSenderList: false,
        getEmailStoreSenderList: action.data,
      };

    case types.POST_CREATEEMAILSTORESENDER_ASYNC.PENDING:
      return {
        ...state,
        postCreateEmailStoreSenderIsSuccess: false,
        isLoadingPostCreateEmailStoreSender: true,
        postCreateEmailStoreSender: null,
      };

    case types.POST_CREATEEMAILSTORESENDER_ASYNC.ERROR:
      return {
        ...state,
        postCreateEmailStoreSenderIsSuccess: false,
        isLoadingPostCreateEmailStoreSender: false,
        postCreateEmailStoreSender: null,
        error: {
          ...state.error,
          postCreateEmailStoreSenderError: action.error,
        },
      };

    case types.POST_CREATEEMAILSTORESENDER_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateEmailStoreSenderIsSuccess: true,
        isLoadingPostCreateEmailStoreSender: false,
        postCreateEmailStoreSender: action.data,
      };

    case types.PUT_UPDATEEMAILSTORESENDER_ASYNC.PENDING:
      return {
        ...state,
        putUpdateEmailStoreSenderIsSuccess: false,
        isLoadingPutUpdateEmailStoreSender: true,
        putUpdateEmailStoreSender: null,
      };

    case types.PUT_UPDATEEMAILSTORESENDER_ASYNC.ERROR:
      return {
        ...state,
        putUpdateEmailStoreSenderIsSuccess: false,
        isLoadingPutUpdateEmailStoreSender: false,
        putUpdateEmailStoreSender: null,
        error: {
          ...state.error,
          putUpdateEmailStoreSenderError: action.error,
        },
      };

    case types.PUT_UPDATEEMAILSTORESENDER_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateEmailStoreSenderIsSuccess: true,
        isLoadingPutUpdateEmailStoreSender: false,
        putUpdateEmailStoreSender: action.data,
      };

    case types.GET_EMAILSTORESENDERORGANISATIONBRANCH_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreSenderOrganisationBranchIsSuccess: false,
        isLoadingGetEmailStoreSenderOrganisationBranch: true,
        getEmailStoreSenderOrganisationBranch: null,
      };

    case types.GET_EMAILSTORESENDERORGANISATIONBRANCH_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreSenderOrganisationBranchIsSuccess: false,
        isLoadingGetEmailStoreSenderOrganisationBranch: false,
        getEmailStoreSenderOrganisationBranch: null,
        error: {
          ...state.error,
          getEmailStoreSenderOrganisationBranchError: action.error,
        },
      };

    case types.GET_EMAILSTORESENDERORGANISATIONBRANCH_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreSenderOrganisationBranchIsSuccess: true,
        isLoadingGetEmailStoreSenderOrganisationBranch: false,
        getEmailStoreSenderOrganisationBranch: action.data,
      };

    case types.GET_EMAILSTORESENDERMEDICINETYPE_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreSenderMedicineTypeIsSuccess: false,
        isLoadingGetEmailStoreSenderMedicineType: true,
        getEmailStoreSenderMedicineType: null,
      };

    case types.GET_EMAILSTORESENDERMEDICINETYPE_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreSenderMedicineTypeIsSuccess: false,
        isLoadingGetEmailStoreSenderMedicineType: false,
        getEmailStoreSenderMedicineType: null,
        error: {
          ...state.error,
          getEmailStoreSenderMedicineTypeError: action.error,
        },
      };

    case types.GET_EMAILSTORESENDERMEDICINETYPE_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreSenderMedicineTypeIsSuccess: true,
        isLoadingGetEmailStoreSenderMedicineType: false,
        getEmailStoreSenderMedicineType: action.data,
      };

    case types.GET_EMAILSTORESENDERORGANISATIONPHYSICIAN_ASYNC.PENDING:
      return {
        ...state,
        getEmailStoreSenderOrganisationPhysicianIsSuccess: false,
        isLoadingGetEmailStoreSenderOrganisationPhysician: true,
        getEmailStoreSenderOrganisationPhysician: null,
      };

    case types.GET_EMAILSTORESENDERORGANISATIONPHYSICIAN_ASYNC.ERROR:
      return {
        ...state,
        getEmailStoreSenderOrganisationPhysicianIsSuccess: false,
        isLoadingGetEmailStoreSenderOrganisationPhysician: false,
        getEmailStoreSenderOrganisationPhysician: null,
        error: {
          ...state.error,
          getEmailStoreSenderOrganisationPhysicianError: action.error,
        },
      };

    case types.GET_EMAILSTORESENDERORGANISATIONPHYSICIAN_ASYNC.SUCCESS:
      return {
        ...state,
        getEmailStoreSenderOrganisationPhysicianIsSuccess: true,
        isLoadingGetEmailStoreSenderOrganisationPhysician: false,
        getEmailStoreSenderOrganisationPhysician: action.data,
      };

    default:
      return state;
  }
};
