//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
//actions
import {
  putUpdateTreatment,
  getTreatmentTreatmentTypes,
} from "../../../../actions/treatmentActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import * as generalConstants from "../../../../_constants/generalConstants";

export const EditTreatmentPage = ({
  treatmentEdit,
  setDetailView,
  viewOnly = false,
}) => {
  //variables
  const { t } = useTranslation();

  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const common = useSelector((state) => state.common);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const treatment = useSelector((state) => state.treatment);
  const prevPutUpdateStatus = useRef();
  const dispatch = useDispatch();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  const [temViewOnly, setTemViewOnly] = useState(viewOnly);
  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const onSubmit = (data) => {
    setJsonText(data);
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    delete treatmentEdit.organisation;
    const submitJson = {
      treatment: {
        ...treatmentEdit,
        ...data,
        name: data.name.trim(),
        treatment_type: { id: data.treatment_type },
        unit_selling_price: data.unit_selling_price
          ? data.unit_selling_price
          : 0,
      },
    };

    dispatch(putUpdateTreatment.pending(submitJson));
  };

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      id: treatmentEdit.id,
      name: treatmentEdit.name,
      unit_selling_price: treatmentEdit.unit_selling_price
        ? treatmentEdit.unit_selling_price
        : 0,
      treatment_type: treatmentEdit.treatment_type.id,
      info1: treatmentEdit.info1,
      info2: treatmentEdit.info2,
      info3: treatmentEdit.info3,
      info_functionality: treatmentEdit.info_functionality,
      info_precaution: treatmentEdit.info_precaution,
      status_type: treatmentEdit.status_type,
    },
  });
  useEffect(() => {
    //set ref when first render
    prevPutUpdateStatus.current = false;
  }, []);

  useEffect(() => {
    const submitJson = {
      is_pagination: false,
      order_by_direction: null,
      order_by_field: null,
      page_number: null,
      page_size: null,
      search_desc_value: null,
      search_id_value: null,
      search_name_value: null,
      search_status_type_value: null,
    };
    dispatch(getTreatmentTreatmentTypes.pending(submitJson));
  }, [dispatch]);

  useEffect(() => {
    if (treatment.isLoadingPutUpdateTreatment !== prevPutUpdateStatus.current) {
      //check the previous ref with current state
      prevPutUpdateStatus.current = treatment.isLoadingPutUpdateTreatment;

      if (
        treatment.putUpdateTreatmentIsSuccess === true &&
        treatment.isLoadingPutUpdateTreatment === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);
        reset({
          ...treatment.putUpdateTreatment.treatment,
          treatment_type:
            treatment.putUpdateTreatment.treatment.treatment_type.id,
        });
        setTemViewOnly(true);
        window.scrollTo(0, 0);
      }
      if (
        treatment.putUpdateTreatmentIsSuccess === false &&
        treatment.isLoadingPutUpdateTreatment === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(treatment.error.putUpdateTreatmentError);
        window.scrollTo(0, 0);
      }
    }
  }, [treatment, reset]);

  return (
    <Panel
      heading={
        temViewOnly
          ? t("translation:Treatment Details")
          : t("translation:Edit Treatment")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Treatment has been updated")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      {treatment.getTreatmentTreatmentTypes !== null && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <input hidden readOnly {...register("id")} />

          <Grid className={classes.root} container>
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled={temViewOnly}
                className={classes.userFieldColor}
                required
                name="name"
                label={t("translation:Name")}
                variant="outlined"
                {...register("name")}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled={temViewOnly}
                className={classes.userFieldColor}
                type="number"
                onWheel={(event) => event.target.blur()}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: "any",
                    style: { textAlign: "right" },
                  },
                }}
                onKeyDown={(event) => {
                  if (
                    event?.key === "-" ||
                    event?.key === "+" ||
                    event?.key === "e" ||
                    event?.key === "E"
                  ) {
                    event.preventDefault();
                  }
                }}
                {...register("unit_selling_price")}
                cy_data="unit_selling_price"
                onFocus={(event) => {
                  event.target.select();
                }}
                name="unit_selling_price"
                required
                label={t("translation:Unit Selling Price")}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    disabled={temViewOnly}
                    {...field}
                    name="treatment_type"
                    id="treatment_type"
                    required
                    options={
                      treatment.getTreatmentTreatmentTypes?.treatment_types
                        ? treatment.getTreatmentTreatmentTypes?.treatment_types.filter(
                            (item) =>
                              item.status_type ===
                              generalConstants.APPROVED_STATUS_TYPE
                          )
                        : []
                    }
                    getOptionLabel={(option) =>
                      getOpObj(
                        option,
                        treatment.getTreatmentTreatmentTypes?.treatment_types
                      ).name
                    }
                    style={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) => {
                      if (option.id === value) {
                        return option;
                      }
                    }}
                    onChange={(e, option) =>
                      field.onChange(option == null ? null : option.id)
                    }
                    renderInput={(params) => (
                      <TextField
                        disabled={temViewOnly}
                        required
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Treatment Type")}
                        variant="outlined"
                      />
                    )}
                  />
                )}
                name="treatment_type"
                control={control}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled={temViewOnly}
                className={classes.userFieldColor}
                name="info1"
                label={t("translation:Info 1")}
                variant="outlined"
                {...register("info1")}
                multiline
                minRows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled={temViewOnly}
                className={classes.userFieldColor}
                name="info2"
                label={t("translation:Info 2")}
                variant="outlined"
                {...register("info2")}
                multiline
                minRows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled={temViewOnly}
                className={classes.userFieldColor}
                name="info3"
                label={t("translation:Info 3")}
                variant="outlined"
                {...register("info3")}
                multiline
                minRows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled={temViewOnly}
                className={classes.userFieldColor}
                name="info_functionality"
                label={t("translation:Info Functionality")}
                variant="outlined"
                {...register("info_functionality")}
                multiline
                minRows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                disabled={temViewOnly}
                className={classes.userFieldColor}
                name="info_precaution"
                label={t("translation:Info Precaution")}
                variant="outlined"
                {...register("info_precaution")}
                multiline
                minRows={3}
              />
            </Grid>

            <Grid item xs={12} className={classes.userInfoField}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    disabled={temViewOnly}
                    {...field}
                    name="status_type"
                    id="status_type"
                    required
                    options={
                      common.generalStatusList.status_general
                        ? common.generalStatusList.status_general
                        : []
                    }
                    getOptionLabel={(option) =>
                      getOpObj(option, common.generalStatusList.status_general)
                        .name
                    }
                    style={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) => {
                      if (option.id === value) {
                        return option;
                      }
                    }}
                    onChange={(e, option) =>
                      field.onChange(option == null ? null : option.id)
                    }
                    renderInput={(params) => (
                      <TextField
                        disabled={temViewOnly}
                        required
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Status Type")}
                        variant="outlined"
                      />
                    )}
                  />
                )}
                name="status_type"
                control={control}
              />
            </Grid>
            <>
              <RequiredNote />
              {temViewOnly ? (
                <PageOnlyBackAction setView={() => setDetailView(false)} />
              ) : (
                <PageSubmitWBackAction setView={() => setDetailView(false)} />
              )}
            </>
          </Grid>
        </form>
      )}

      {treatment.getTreatmentTreatmentTypes === null && (
        <div>{t("translation:Loading")}...</div>
      )}
    </Panel>
  );
};
