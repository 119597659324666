import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const branchAdhocScheduleApis = {
  getBranchAdhocScheduleListApi,
  getBranchAdhocScheduleByIdApi,
  postBranchAdhocScheduleByBranchIdApi,
  getBranchAdhocScheduleBranchListApi,
};

async function getBranchAdhocScheduleListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetBranchAdhocScheduleList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBranchAdhocScheduleByIdApi(model) {
  const url = apiConstants.apiGetBranchAdhocScheduleById + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postBranchAdhocScheduleByBranchIdApi(model) {
  const url =
    apiConstants.apiPostBranchAdhocScheduleByBranchId + model.branchId;
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getBranchAdhocScheduleBranchListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetBranchAdhocScheduleBranchList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default branchAdhocScheduleApis;
