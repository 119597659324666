//lib

import React, { useRef, useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Close from "@mui/icons-material/Close";
import { Grid, Link, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
//components

import { useStyles } from "../../../../../components/globalStyles";
import Panel from "../../../../../components/Panel";
import * as pageConfigure from "../../../../../_constants/pageConstants";
import NodeItemTable from "../Components/NodeItemTable";
//Actions
import { getWikiDataPreviewList } from "../../../../../actions/wikiDataActions";
// const wikidata = {
//   wiki_datas: [
//     {
//       acupoints: [],
//       desc: null,
//       id: 16,
//       is_root_node: true,
//       medicines: [],
//       name: "node10",
//       others: [],
//       physician_advice: [],
//       physician_diagnosis: [],
//       pulse_diagnosis: [],
//       review_notes: [],
//       status_type: 3,
//       subnodes: [
//         {
//           acupoints: [],
//           desc: null,
//           id: 27,
//           is_root_node: false,
//           medicines: [],
//           name: "approveNode",
//           others: [],
//           physician_advice: [],
//           physician_diagnosis: [],
//           pulse_diagnosis: [],
//           review_notes: [],
//           status_type: 3,
//           subnodes: [],
//           syndrome: [],
//           tongue_diagnosis: [],
//           treatments: [],
//           western_diagnosis: [],
//         },
//       ],
//       syndrome: [],
//       tongue_diagnosis: [],
//       treatments: [],
//       western_diagnosis: [],
//     },
//     {
//       acupoints: [],
//       desc: null,
//       id: 15,
//       is_root_node: true,
//       medicines: [
//         {
//           id: 5343,
//           name: "川芎 [庆] (CX)",
//         },
//       ],
//       name: "mainNode1",
//       others: [],
//       physician_advice: [],
//       physician_diagnosis: [],
//       pulse_diagnosis: [],
//       review_notes: [],
//       status_type: 3,
//       subnodes: [
//         {
//           acupoints: [],
//           desc: null,
//           id: 26,
//           is_root_node: false,
//           medicines: [],
//           name: "node10",
//           others: [],
//           physician_advice: [],
//           physician_diagnosis: [],
//           pulse_diagnosis: [],
//           review_notes: [],
//           status_type: 3,
//           subnodes: [
//             {
//               acupoints: [],
//               desc: null,
//               id: 28,
//               is_root_node: false,
//               medicines: [],
//               name: "node11",
//               others: [],
//               physician_advice: [],
//               physician_diagnosis: [],
//               pulse_diagnosis: [],
//               review_notes: [],
//               status_type: 3,
//               subnodes: [],
//               syndrome: [],
//               tongue_diagnosis: [],
//               treatments: [],
//               western_diagnosis: [],
//             },
//           ],
//           syndrome: [],
//           tongue_diagnosis: [],
//           treatments: [],
//           western_diagnosis: [],
//         },
//       ],
//       syndrome: [],
//       tongue_diagnosis: [],
//       treatments: [],
//       western_diagnosis: [],
//     },
//     {
//       acupoints: [],
//       desc: null,
//       id: 18,
//       is_root_node: true,
//       medicines: [],
//       name: "node root",
//       others: [],
//       physician_advice: [],
//       physician_diagnosis: [],
//       pulse_diagnosis: [],
//       review_notes: [],
//       status_type: 3,
//       subnodes: [],
//       syndrome: [],
//       tongue_diagnosis: [],
//       treatments: [],
//       western_diagnosis: [],
//     },
//   ],
// };

const WikiDataLoop = ({
  data,
  onHandleRetriveData,
  retrivedLoading,
  temWikiData,
}) => {
  return (
    <>
      {data.map((item, index) => (
        <Accordion key={item.id} disabled={retrivedLoading}>
          <AccordionSummary
            aria-controls={item.id}
            id={item.id}
            key={item.id}
            onClick={() => onHandleRetriveData(item)}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            title={item.name}>
            <Typography
              nowrap={1}
              sx={
                item.id === temWikiData?.id
                  ? {
                      color: "rgba(149, 0, 0,1)",
                      lineHeight: 1,
                    }
                  : {}
              }>
              {item.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
            }}>
            {item.subnodes?.length > 0 ? (
              <WikiDataLoop
                data={item.subnodes}
                onHandleRetriveData={onHandleRetriveData}
                retrivedLoading={retrivedLoading}
                temWikiData={temWikiData}
              />
            ) : (
              <></>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

function wikiDataInsert(data, subnode) {
  const updatedData = data.map((item, index) => {
    if (item.id === subnode.id) {
      item.subnodes = subnode.subnodes;
    }
    if (item.subnodes?.length) {
      wikiDataInsert(item.subnodes, subnode);
    }
    return item;
  });

  return updatedData;
}

export default function WikiDataPreview({}) {
  //variables
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const prevGetListStatus = useRef();
  const wikiDataData = useSelector((state) => state.wikiData);

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: "Asc",
    order_by_field: "name",
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    is_pagination: false,
    search_desc_value: null,
    search_id_value: null,
    search_name_value: "",
    search_root_node: true,
    search_status_type_value: null,
    search_subnode_value: null,
    view_format_value: pageConfigure.WIKI_LEVEL_VIEW,
  });
  const [previewWikidata, setPreviewWikidata] = React.useState([]);
  const [temWikiData, setTemWikiData] = useState(null);
  const [openWikiDataPreviewDialog, setOpenWikiDataPreviewDialog] =
    React.useState(false);

  const [retrivedById, setRetrivedById] = React.useState(false);

  const [retrivedLoading, setRetrivedLoading] = React.useState(false);

  const onHandleRetriveData = (data) => {
    setTemWikiData(data);
    if (data.is_root_node !== true) {
      setRetrivedLoading(true);
      setPageInfo((prev) => {
        return {
          ...prev,
          is_pagination: false,
          search_id_value: data.id,
          search_root_node: false,
          view_format_value: pageConfigure.WIKI_LEVEL_VIEW,
        };
      });

      setRetrivedById(true);
    }
  };
  //useEffect

  useEffect(() => {
    prevGetListStatus.current = false;
  }, []);
  useEffect(() => {
    if (openWikiDataPreviewDialog) {
      dispatch(getWikiDataPreviewList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, openWikiDataPreviewDialog]);

  useEffect(() => {
    if (
      wikiDataData.isLoadingGetWikiDataPreviewList !== prevGetListStatus.current
    ) {
      //check the previous ref with current state
      prevGetListStatus.current = wikiDataData.isLoadingGetWikiDataPreviewList;

      if (
        wikiDataData.getWikiDataPreviewListIsSuccess === true &&
        wikiDataData.isLoadingGetWikiDataPreviewList === false
      ) {
        setRetrivedLoading(false);
        if (retrivedById) {
          if (wikiDataData.getWikiDataPreviewList?.wiki_datas.length > 0) {
            const newJson = wikiDataInsert(
              previewWikidata,
              wikiDataData.getWikiDataPreviewList?.wiki_datas[0]
            );
            setTemWikiData(wikiDataData.getWikiDataPreviewList?.wiki_datas[0]);
            setPreviewWikidata(newJson);
          }
        } else {
          setPreviewWikidata(wikiDataData.getWikiDataPreviewList?.wiki_datas);
        }
      }
      if (
        wikiDataData.getWikiDataPreviewListIsSuccess === false &&
        wikiDataData.isLoadingGetWikiDataPreviewList === false
      ) {
        setRetrivedLoading(false);
      }
    }
  }, [wikiDataData]);

  return (
    <>
      <Link className={classes.boldMaroon075}>
        <Button
          name={"wikiDataPreviewOpenButton"}
          className={classes.boldMaroon075}
          onClick={() => {
            setOpenWikiDataPreviewDialog(true);
          }}>
          {t("translation:Wiki Data Preview")}
        </Button>
      </Link>
      <Grid className={classes.root} container>
        <Dialog
          open={openWikiDataPreviewDialog}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setOpenWikiDataPreviewDialog(false);
            }
          }}
          aria-labelledby="confirm-dialog"
          fullWidth
          maxWidth="xl">
          <Close
            style={{ position: "absolute", right: "15px" }}
            onClick={() => setOpenWikiDataPreviewDialog(false)}
          />

          <DialogContent>
            <Panel heading={t("translation:Wiki Data Details")}>
              <Grid className={classes.gridRoot} container>
                <Grid item xs={3} className={classes.gridPadding}>
                  <WikiDataLoop
                    data={previewWikidata}
                    onHandleRetriveData={onHandleRetriveData}
                    retrivedLoading={retrivedLoading}
                    temWikiData={temWikiData}
                  />
                </Grid>
                {temWikiData && (
                  <Grid item xs={9}>
                    <NodeItemTable
                      data={temWikiData}
                      setData={setTemWikiData}
                      temViewOnly={true}
                    />
                  </Grid>
                )}
              </Grid>
            </Panel>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
