//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
//action
import {
  postOverrideInvoiceAmount,
  getInvoiceById,
} from "../../../../actions/invoiceActions";
import { getPaymentMethod } from "../../../../actions/commonActions";
//components
import Panel from "../../../../components/Panel";
import PageCustomAction from "../../../../components/PageCustomAction";
import InvoiceDetailsTable from "./InvoiceDetailsTable";
import AmountTotal from "./AmountTotal";
import PaymentTable from "../../../visit/Consultation/components/editOrder/PaymentTable";
import ConfirmDialog from "../../../../components/ConfirmDialog";

import { useStyles } from "../../../../components/globalStyles";

import * as generalConstants from "../../../../_constants/generalConstants";

//style

const EditInvoicePage = ({
  orderProcessInfo,
  temPaidAmount,
  temPrice,
  temDiscount,
  temExtraAmt,
  temTreatmentDetail,
  temMiscDetail,
  temMemoInvoiceDetailsWoDeleted = [],
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const prevEditInvoiceStatus = useRef();
  const curUser = useSelector((state) => state.curUser);
  const [paidAmount, setPaidAmount] = React.useState(temPaidAmount);
  const [price, setPrice] = React.useState(temPrice);
  const [discount, setDiscount] = React.useState(temDiscount);
  const [extraAmt, setExtraAmt] = React.useState(temExtraAmt);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState(false);
  const invoice = useSelector((state) => state.invoice);

  const [editInvoiceDiagnosisOpen, setEditInvoiceDiagnosisOpen] =
    useState(false);
  const [amountHeader, setAmountHeader] = useState(
    orderProcessInfo.store_header
  );

  const editInvoicePermission = curUser.allRightAccess
    ? curUser.allRightAccess.user_add_permissions.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_INVOICE_RIGHT_TYPE &&
          item.add_permission_type ===
            generalConstants.INVOICE_OVERRIDE_PERMISSION_TYPE
      )
    : false;

  const handlePaymentOnSubmit = () => {
    const temAcunpture =
      orderProcessInfo.store_header.visit_acupuncture_headers.map((item) => {
        return {
          id: item.id,
          price: item.price,
          status_type: item.status_type,
        };
      });

    const temMisc = orderProcessInfo.store_header.visit_misc_details.map(
      (item) => {
        return {
          id: item.id,
          name: item.name,
          quantity: item.quantity,
          row_no: item.row_no,
          status_type: item.status_type,
          unit_selling_price: item.unit_selling_price,
        };
      }
    );

    const temTreatment =
      orderProcessInfo.store_header.visit_treatment_details.map((item) => {
        return {
          id: item.id,
          treatment_name: item.treatment_name,
          quantity: item.quantity,
          row_no: item.row_no,
          status_type: item.status_type,
          unit_selling_price: item.unit_selling_price,
        };
      });

    const invoiceResult = {
      invoice_header: {
        id: orderProcessInfo.id,
        order_number: orderProcessInfo.order_number,
        store_header: {
          acupuncture_discount_amt: discount.acupuncturePrice,
          acupuncture_extra_amt: extraAmt.acupuncturePrice,
          acupuncture_total_price: price.acupuncturePrice,
          amt_after_discount: amountHeader.amt_after_discount,
          amt_before_discount: amountHeader.amt_before_discount,
          amt_rounding: amountHeader.amt_rounding,
          amt_with_gst: amountHeader.amt_with_gst,
          amt_wo_gst: amountHeader.amt_wo_gst,
          brewing_discount_amt: discount.brewingFee,
          brewing_extra_amt: extraAmt.brewingFee,
          brewing_total_price_for_costing: price.brewingFee,
          consultation_discount_amt: discount.consultationFee,
          consultation_extra_amt: extraAmt.consultationFee,
          consultation_total_price: price.consultationFee,
          delivery_discount_amt: discount.deliveryFee,
          delivery_extra_amt: extraAmt.deliveryFee,
          delivery_total_price_for_costing: price.deliveryFee,
          discount_in_percent: amountHeader.discount_in_percent,
          gst_amt: amountHeader.gst_amt,
          gst_value: orderProcessInfo.store_header.gst_value,
          id: orderProcessInfo.store_header.id,
          medicine_discount_amt: discount.medicineTab,
          medicine_extra_amt: extraAmt.medicineTab,
          medicine_total_price: price.medicineTab,
          misc_discount_amt: discount.miscPrice,
          misc_extra_amt: orderProcessInfo.store_header.misc_extra_amt,
          misc_total_price: price.miscPrice,
          payment_method_name:
            orderProcessInfo.store_header.payment_method_name,
          payment_term_name: orderProcessInfo.store_header.payment_term_name,
          referral_code: amountHeader.referral_code,
          treatment_discount_amt: discount.treatmentPrice,
          treatment_extra_amt:
            orderProcessInfo.store_header.treatment_extra_amt,
          treatment_total_price: price.treatmentPrice,
          visit_acupuncture_headers: temAcunpture,
          visit_misc_details: temMisc,
          visit_treatment_details: temTreatment,
        },
      },
    };
    dispatch(postOverrideInvoiceAmount.pending(invoiceResult));
  };

  //useEffect

  useEffect(() => {
    prevEditInvoiceStatus.current = false;
  }, []);
  useEffect(() => {
    dispatch(getPaymentMethod.pending());
  }, [dispatch]);

  useEffect(() => {
    if (
      invoice.isLoadingPostOverrideInvoiceAmount !==
      prevEditInvoiceStatus.current
    ) {
      //
      //check the previous ref with current state
      prevEditInvoiceStatus.current =
        invoice.isLoadingPostOverrideInvoiceAmount;

      if (
        invoice.postOverrideInvoiceAmountIsSuccess === true &&
        invoice.isLoadingPostOverrideInvoiceAmount === false
      ) {
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);

        dispatch(getInvoiceById.pending(orderProcessInfo.id));
      }

      if (
        invoice.postOverrideInvoiceAmountIsSuccess === false &&
        invoice.isLoadingPostOverrideInvoiceAmount === false
      ) {
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(invoice.error.postOverrideInvoiceAmountError);
      }
    }
  }, [dispatch, invoice]);

  const setPaymentData = (id, column, value) => {
    if (column === "extra_Amount") {
      setExtraAmt((p) => ({
        ...p,
        [id]: value,
      }));
    }
    if (column === "discount_Amount") {
      setDiscount((p) => ({
        ...p,
        [id]: value,
      }));
    }
    if (column === "amount") {
      setPrice((p) => ({
        ...p,
        [id]: value,
      }));
    }
  };
  return (
    <>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handlePaymentOnSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <>
        {editInvoicePermission &&
          orderProcessInfo.status_type ===
            generalConstants.PENDING_STATUS_TYPE && (
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                onClick={() => setEditInvoiceDiagnosisOpen(true)}>
                {t("translation:EDIT INVOICE")}
              </Button>
            </Link>
          )}
        {editInvoiceDiagnosisOpen && (
          <Dialog
            open={editInvoiceDiagnosisOpen}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                setEditInvoiceDiagnosisOpen(false);
              }
            }}
            aria-labelledby="confirm-dialog"
            fullWidth
            maxWidth="lg">
            <CloseIcon
              style={{ position: "absolute", right: "15px" }}
              onClick={() => setEditInvoiceDiagnosisOpen(false)}
            />
            {invoice.isLoadingPostOverrideInvoiceAmount !== true && (
              <DialogContent>
                <Collapse in={submitSuccessAlarm}>
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setSubmitSuccessAlarm(false);
                        }}>
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }>
                    {t("translation:Invoice has been submitted")}
                  </Alert>
                </Collapse>

                <Collapse in={submitErrorAlarm}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setSubmitErrorAlarm(false);
                        }}>
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }>
                    {msgAlarm}
                  </Alert>
                </Collapse>
                <Typography display="block" variant="h4">
                  {orderProcessInfo.order_number}
                </Typography>

                <Panel>
                  <PaymentTable
                    viewOnly={false}
                    editPrice={true}
                    price={price}
                    discount={discount}
                    extraAmt={extraAmt}
                    treatmentDetail={temTreatmentDetail}
                    miscDetail={temMiscDetail}
                    t={t}
                    setPaymentData={setPaymentData}
                  />
                </Panel>
                <Panel>
                  <AmountTotal
                    amountHeader={amountHeader}
                    paidAmount={paidAmount}
                    setAmountHeader={setAmountHeader}
                    editAble={true}
                  />
                </Panel>
                {temMemoInvoiceDetailsWoDeleted.length > 0 && (
                  <Panel>
                    <InvoiceDetailsTable
                      data={temMemoInvoiceDetailsWoDeleted}
                      t={t}
                      viewOnly={true}
                    />
                  </Panel>
                )}

                <PageCustomAction
                  button1Name="submitButton"
                  button1label={t("translation:SUBMIT")}
                  button1Click={() => setConfirmOpen(true)}
                  button2Name="backButton"
                  button2label={t("translation:BACK")}
                  button2Click={() => setEditInvoiceDiagnosisOpen(false)}
                />
              </DialogContent>
            )}
            {invoice.isLoadingPostOverrideInvoiceAmount === true && (
              <>{t("translation:Loading")}...</>
            )}
          </Dialog>
        )}
      </>
    </>
  );
};

export default EditInvoicePage;
