import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const invoiceMedicineCostingApis = {
  putInvoiceMedicineCostingWIPModeApi,
  putInvoiceMedicineCostingPendingStatusApi,
  getInvoiceMedicineCostingListApi,
  getInvoiceMedicineCostingSummaryListApi,
  getInvoiceMedicineCostingByIdApi,
  getInvoiceMedicineCostingByOrderNumApi,
  postSubmitInvoiceMedicineCostingApi,
  putInvoiceMedicineCostingPrintDateApi,
};

async function putInvoiceMedicineCostingWIPModeApi(model) {
  const url = apiConstants.apiPutInvoiceMedicineCostingWIPMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function putInvoiceMedicineCostingPendingStatusApi(model) {
  const url = apiConstants.apiPutInvoiceMedicineCostingPendingStatus + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceMedicineCostingListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetInvoiceMedicineCostingList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceMedicineCostingSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetInvoiceMedicineCostingSummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceMedicineCostingByIdApi(model) {
  const url = apiConstants.apiGetInvoiceMedicineCostingById + model;

  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceMedicineCostingByOrderNumApi(model) {
  const url = apiConstants.apiGetInvoiceMedicineCostingByOrderNum + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitInvoiceMedicineCostingApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostSubmitInvoiceMedicineCosting,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putInvoiceMedicineCostingPrintDateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutInvoiceMedicineCostingPrintDate,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default invoiceMedicineCostingApis;
