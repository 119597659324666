import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import TopUpPage from "./TopUpPage";

export default function CurrentUserCreditPage() {
  return (
    <Switch>
      <Route path="/currentUserCredit/topUp" component={TopUpPage} />
      <Redirect to="/error" />
    </Switch>
  );
}
