import React, { useState, useEffect, useContext, useRef } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Refresh from "@mui/icons-material/Refresh";
import moment from "moment";
//components
import { SocketContext } from "../../../components/functions/SocketProvider";
import notificationSound from "../../../assets/notificationSound.mp3";
import SoundNotification from "../../../components/SoundNotification";
import * as generalConstants from "../../../_constants/generalConstants";

import { useStyles } from "../../../components/globalTableStyles";

//styles
export default function SocketNotification(props) {
  const {
    cellTitle,
    sockectWorkTypeId,
    setRefreshTimeStamp,
    localStorageName = null,
  } = props;

  const paginationClasses = useStyles();

  const prevSocketStatusTimestamp = useRef();
  const { socket } = useContext(SocketContext);

  //variable

  const [lastPong, setLastPong] = useState(null);

  const [openModule, setOpenModule] = React.useState(false);
  const [openFunction, setOpenFunction] = React.useState(false);

  //useEffects
  useEffect(() => {
    prevSocketStatusTimestamp.current = moment();
  }, []);

  useEffect(() => {
    socket.on("connect", () => {});

    socket.on("sio_response", (msg, cb) => {
      if (
        msg.code === generalConstants.SOCKET_SUCCESS_CODE &&
        msg.message.work_order_type_id === sockectWorkTypeId
      ) {
        setLastPong(msg);
      }

      if (
        msg.code === generalConstants.SOCKET_SUCCESS_CODE &&
        sockectWorkTypeId === generalConstants.COUNTER_VIEW_TYPE &&
        msg.message.room === generalConstants.COUNTER_VIEW_TYPE
      ) {
        setLastPong(msg);
      }
      if (
        msg.code === generalConstants.SOCKET_SUCCESS_CODE &&
        !msg.message.work_order_type_id
      ) {
        //! work_order_type_id == no new order
        setLastPong(null);
      }
    });
    socket.on("disconnect", () => {});

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("sio_response");
    };
  }, [socket, sockectWorkTypeId]);

  useEffect(() => {
    const socketStatusTimestamp = moment();
    if (
      //enable auto refresh
      generalConstants.SOCKET_CALL_API_ENANBLED &&
      socketStatusTimestamp.diff(prevSocketStatusTimestamp.current) >
        generalConstants.SOCKET_RECALL_API_MILISECOND
    ) {
      //check the previous ref with current state
      prevSocketStatusTimestamp.current = socketStatusTimestamp;
      if (lastPong) {
        setRefreshTimeStamp(moment());
      }
    }
  }, [lastPong, setRefreshTimeStamp]);

  // useEffect(() => {
  //   const socketStatusTimestamp = moment();
  //   if (
  //     generalConstants.SOCKET_CALL_API_ENANBLED &&
  //     socketStatusTimestamp.diff(prevSocketStatusTimestamp.current) >
  //       generalConstants.SOCKET_RECALL_API_MILISECOND
  //   ) {
  //     //check the previous ref with current state
  //     prevSocketStatusTimestamp.current = socketStatusTimestamp;
  //     if (lastPong) {
  //       dispatch(callAction.pending({ pageInfo }));

  //     }
  //   }
  // }, [lastPong, pageInfo, callAction, dispatch]);

  return (
    <React.Fragment>
      <TableRow className={paginationClasses.headStyle}>
        <TableCell className={paginationClasses.collapseIconStyle}>
          <IconButton
            className={paginationClasses.whiteBoldFont}
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpenModule(!openModule);
              setOpenFunction(!openFunction);
            }}>
            {openModule ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={paginationClasses.whiteBoldFont}>
          {cellTitle}
        </TableCell>

        <TableCell align="right" className={paginationClasses.whiteBoldFont}>
          <SoundNotification
            audio={notificationSound}
            play={lastPong}
            localStorageName={localStorageName}
          />
          {openFunction && (
            <IconButton
              className={paginationClasses.whiteBoldFont}
              aria-label="expand row"
              size="small"
              onClick={() => {
                setRefreshTimeStamp(moment());
              }}>
              <Refresh />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={paginationClasses.collapseStyle} colSpan={12}>
          <Collapse in={openFunction} timeout="auto" unmountOnExit>
            {props.children}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
