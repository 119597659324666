//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
//action

import { postSubmitInvoiceMedicineCosting } from "../../../actions/invoiceMedicineCostingActions";
import { getPaymentMethod } from "../../../actions/commonActions";
//components
import Panel from "../../../components/Panel";
import PageCustomAction from "../../../components/PageCustomAction";
import InvoiceDetailsTable from "../Invoice/components/InvoiceDetailsTable";
import PaymentTotal from "../Invoice/components/PaymentTotal";
import AmountTotal from "../Invoice/components/AmountTotal";
import * as generalConstants from "../../../_constants/generalConstants";
import PaymentTable from "./components/PaymentTable";
import ConfirmDialog from "../../../components/ConfirmDialog";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
//style

const ProceedPage = ({
  setOrderProcessView,
  orderProcessInfo,
  setOrderProcessInfo,
  submitSuccessAlarm,
  setSubmitSuccessAlarm,
  submitErrorAlarm,
  setSubmitErrorAlarm,
  msgAlarm,
  setMsgAlarm,
  viewOnly,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [payment, setPayment] = React.useState([]);
  const [paidAmount, setPaidAmount] = React.useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const curUser = useSelector((state) => state.curUser);
  const common = useSelector((state) => state.common);

  const deleteAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_INVOICE_MEDICINE_COSTING_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.DELETE_PERMISSION_TYPE
      )
    : false;

  const price = {
    medicineCosting: orderProcessInfo.store_medicine_costing_header
      .branch_medicine_price
      ? orderProcessInfo.store_medicine_costing_header.branch_medicine_price
      : 0,
    brewingFee: orderProcessInfo.store_medicine_costing_header
      .branch_brewing_price
      ? orderProcessInfo.store_medicine_costing_header.branch_brewing_price
      : 0,
    deliveryFee: orderProcessInfo.store_medicine_costing_header
      .branch_delivery_price
      ? orderProcessInfo.store_medicine_costing_header.branch_delivery_price
      : 0,
  };

  const handlePaymentOnConfirm = (event) => {
    event.currentTarget.form.reportValidity();
    var checkVal = event.currentTarget.form.checkValidity();
    if (checkVal) {
      // const invoiceResult = {
      //   invoice_header: {
      //     id: orderProcessInfo.id,
      //     invoice_details: [
      //       {
      //         id: 0,
      //         payment_amount: Number(Number(payment.payment_amount).toFixed(2)),
      //         payment_method: null,
      //         status_type: generalConstants.APPROVED_STATUS_TYPE,
      //       },
      //     ],
      //     order_number: orderProcessInfo.order_number,
      //   },
      // };
      // dispatch(postSubmitInvoiceMedicineCosting.pending(invoiceResult));

      const invoiceResult = {
        id: 0,
        payment_amount: Number(Number(payment.payment_amount).toFixed(2)),
        payment_method: payment.payment_method
          ? payment.payment_method
          : orderProcessInfo.store_header.payment_method,
        status_type: generalConstants.APPROVED_STATUS_TYPE,
      };

      let orderInfo = orderProcessInfo;
      orderInfo.invoice_medicine_costing_details.push(invoiceResult);

      setOrderProcessInfo({ ...orderInfo });
      setPayment([]);
    }
  };

  const handlePaymentOnSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (orderProcessInfo.invoice_medicine_costing_details.length) {
      const invoiceMedicineCostingHeader = {
        invoice_medicine_costing_header: {
          id: orderProcessInfo.id,
          invoice_medicine_costing_details:
            orderProcessInfo.invoice_medicine_costing_details,
          order_number: orderProcessInfo.order_number,
        },
      };
      dispatch(
        postSubmitInvoiceMedicineCosting.pending(invoiceMedicineCostingHeader)
      );
    } else {
      setSubmitErrorAlarm(true);
      setMsgAlarm(t("translation:Invalid Payment"));
    }
  };

  //set detail table data
  const setDetailData = (rowIndex, columnID) => {
    let invoiceDetail = { ...orderProcessInfo };

    if (columnID === "delete") {
      let result = [...invoiceDetail.invoice_medicine_costing_details];
      //check whether is a current existing medicine

      const currentInvoiceDetailWoDelete = result.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentInvoiceDetailWDelete = result.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      //find current DB reocrd
      const currentInvoiceDetailVerified = currentInvoiceDetailWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentInvoiceDetailVerified) {
        const currentInvoiceDetailVerifiedIndex = result.findIndex(
          (e) => e.id === currentInvoiceDetailVerified.id
        );
        result[currentInvoiceDetailVerifiedIndex] = {
          ...currentInvoiceDetailVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddInvoiceDetailVerified = currentInvoiceDetailWoDelete.filter(
          (e, index) => index !== rowIndex
        );

        result = [
          ...newAddInvoiceDetailVerified,
          ...currentInvoiceDetailWDelete,
        ];
      }
      setOrderProcessInfo({
        ...invoiceDetail,
        invoice_medicine_costing_details: result,
      });
      // oldArray[elementsIndex] = {
      //   ...oldArray[elementsIndex],
      //   store_medicine_details: result,
      // };
      // setGroupOption(oldArray);
    }
  };

  //useEffect

  useEffect(() => {
    dispatch(getPaymentMethod.pending());
  }, [dispatch]);

  useEffect(() => {
    if (orderProcessInfo.invoice_medicine_costing_details.length > 0) {
      const temAccAmount =
        orderProcessInfo.invoice_medicine_costing_details.reduce((sum, li) => {
          if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
            return parseFloat((sum + li.payment_amount).toFixed(2));
          }
          return parseFloat(sum.toFixed(2));
        }, 0);

      setPaidAmount(temAccAmount);
    }

    if (orderProcessInfo.invoice_medicine_costing_details.length === 0) {
      setPaidAmount(0);
    }
  }, [orderProcessInfo]);

  const invoiceDetailsWoDeleted = () => {
    return orderProcessInfo.invoice_medicine_costing_details.filter(
      (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
    );
  };

  const memoInvoiceDetailsWoDeleted = React.useMemo(invoiceDetailsWoDeleted, [
    orderProcessInfo,
  ]);

  return (
    <>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handlePaymentOnSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={submitSuccessAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSubmitSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Invoice has been submitted")}
        </Alert>
      </Collapse>

      <Collapse in={submitErrorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSubmitErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <Typography display="block" variant="h4">
        {orderProcessInfo.order_number}
      </Typography>

      <Panel>
        <PaymentTable viewOnly={true} price={price} t={t} />
      </Panel>
      <Panel>
        <AmountTotal
          amountHeader={orderProcessInfo.store_medicine_costing_header}
          payment={payment}
          setPayment={setPayment}
          paidAmount={paidAmount}
        />
      </Panel>
      {memoInvoiceDetailsWoDeleted.length > 0 && (
        <Panel>
          <InvoiceDetailsTable
            data={memoInvoiceDetailsWoDeleted}
            t={t}
            viewOnly={!deleteAccess}
            setData={setDetailData}
          />
        </Panel>
      )}
      {!viewOnly && common.paymentMethodList && (
        <Panel>
          <PaymentTotal
            amountHeader={orderProcessInfo.store_medicine_costing_header}
            payment={payment}
            setPayment={setPayment}
            paidAmount={paidAmount}
            handlePaymentOnConfirm={handlePaymentOnConfirm}
          />
        </Panel>
      )}

      {!viewOnly && (
        <PageCustomAction
          button1Name="submitButton"
          button1label={t("translation:SUBMIT")}
          button1Click={() => setConfirmOpen(true)}
          button2Name="backButton"
          button2label={t("translation:BACK")}
          button2Click={() => setOrderProcessView(false)}
        />
      )}

      {viewOnly && (
        <PageOnlyBackAction setView={() => setOrderProcessView(false)} />
      )}
    </>
  );
};

export default ProceedPage;
